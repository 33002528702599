import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TechnicalCategoryDto, TechnicalCategoryParameterDto, TextTypeDto } from '@btl/admin-bff';
import { AdminProductService, AdminTextTypeService, AppBlockerService } from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import { CategoryEditComponent } from '../category-edit.component';
import { environment } from '@environments/environment';
import { finalize, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import {
  ParameterDetailModalComponent
,
} from '@components/tickets/ticket-type/edit/parameters/parameter-detail-modal/parameter-detail-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TableListingFormComponent } from '@components/table-listing-form/table-listing-form.component';

@Component({
  selector: 'app-category-parameters-listing,[app-category-parameters-listing]',
  templateUrl: './category-parameters-listing.component.html',
  styleUrls: ['./category-parameters-listing.component.scss'],
})
export class CategoryParametersListingComponent implements OnInit, OnDestroy, OnChanges {
  locales = environment.localization.locales;
  parameterTextTypes: Array<TextTypeDto> = [];

  @Input()
  formArray: FormArray;

  @Input()
  editable: boolean;

  @Input()
  parameters: TechnicalCategoryParameterDto[];

  @Input()
  category: TechnicalCategoryDto;

  @Input()
  parentParametersMode = false;

  @Input()
  parentParameters: TechnicalCategoryParameterDto[];

  @Input()
  formGroup;

  @Input()
  enablePaginator: boolean;

  @Input()
  toggleArrow: boolean;

  @Input()
  selectMode: boolean;

  @Input()
  enableViewButton: boolean;

  @Output()
  readonly editParent: EventEmitter<TechnicalCategoryParameterDto> = new EventEmitter<TechnicalCategoryParameterDto>();

  @ViewChildren(TableListingFormComponent)
  tableListingFormComponents: QueryList<TableListingFormComponent>;

  @Input()
  editParentParameter: TechnicalCategoryParameterDto;

  private onDestroy$: Subject<void> = new Subject<void>();

  private generatedCategoryParameterId = 0;

  public static getCategoryParameterEditForm(formBuilder) {
    return {
      id: [null],
      name: [null, Validators.required],
      static: [null],
      type: [null, Validators.required],
      defaultValue: [null],
      localized: [null],
      localizedNames: [null],
      category: [null],
      validationRegex: [null],
      typeDetail: [null],
      integrationKey: [null],
      labelKey: [null],
      recordVersion: [null],
      metaParameters: [],
      texts: [],
    };
  }

  constructor(
    private textTypeService: AdminTextTypeService,
    private adminProductService: AdminProductService,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.loadCategoryParameterConfTexts();
  }

  loadCategoryParameterConfTexts() {
    this.textTypeService
      .getTextTypes('CategoryParameterConf')
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.parameterTextTypes = result;
      });
  }

  ngOnInit() {
    this.loadParameters();
  }

  ngOnChanges(changes: SimpleChanges) {
    const editParentParameter = changes['editParentParameter'];
    if (
      editParentParameter &&
      editParentParameter.currentValue &&
      editParentParameter.currentValue !== editParentParameter.previousValue
    ) {
      editParentParameter.currentValue['id'] = this.generateNewCategoryParameterId();
      editParentParameter.currentValue['category'] = this.category.id;

      let index = 0;
      let found = false;
      for (let i = 0; i < this.formArray.controls.length; i++) {
        if (this.formArray.controls[i].value.name === editParentParameter.currentValue.name) {
          found = true;
          break;
        } else {
          index++;
        }
      }
      if (!found) {
        this.addParameterToFormArray(editParentParameter.currentValue);
      }
    }
  }

  public loadParameters() {
    if (!this.parameters) {
      this.parameters = [];
    }
  }

  deleteCheckedParameters() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.parameters.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.getSelectedParameters().forEach(selectedParameter => {
        this.doDeleteParameter(selectedParameter);
      });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  deleteParameter(parameter: TechnicalCategoryParameterDto) {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.parameter.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.doDeleteParameter(parameter);
      confirmationDialogComponent.dialogReference.close();
    };
  }

  doDeleteParameter(parameter: TechnicalCategoryParameterDto) {
    for (let i = 0; i <= this.parameters.length; i++) {
      const param = this.parameters[i];
      if (param && param.id === parameter.id) {
        this.parameters.splice(i, 1);
        // break cycle when found and remove parameter
        break;
      }
    }
    for (let j = 0; j <= this.formArray.controls.length; j++) {
      const formGroup = this.formArray.controls[j] as FormGroup;
      if (formGroup && formGroup.get('id').value === parameter.id) {
        this.formArray.removeAt(j);
        // break cycle when remove formGroup
        break;
      }
    }
  }

  getSelectedParameters(): Array<TechnicalCategoryParameterDto> {
    let selectedParameters = [];
    this.tableListingFormComponents?.forEach(tableListingFormComponent => {
      if (tableListingFormComponent.selection.hasValue()) {
        selectedParameters = tableListingFormComponent.selection.selected;
      }
    });
    return selectedParameters;
  }

  addParameter() {
    const newParameter = {
      // : TechnicalCategoryParameterDto = {
      id: null,
      category: this.category.id,
      static: false,
      localized: false,
      texts: [],
    };
    this.editParameter(newParameter);
  }

  addParameterToFormArray(parameter) {
    const parameterFormGroup = this.formBuilder.group(
      CategoryParametersListingComponent.getCategoryParameterEditForm(this.formBuilder)
    );

    parameterFormGroup.patchValue(parameter);
    this.formArray.push(parameterFormGroup);
    this.parameters.push(parameter);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  /**
   * Generate fake id of category parameter, it's started with string 'new-'.
   * It is used for editing and deleting yet not saved parameters.
   * Before save category, this fake parameter's ids are set to the null.
   */
  public generateNewCategoryParameterId(): string {
    this.generatedCategoryParameterId++;
    return CategoryEditComponent.PREFIX_NEW_PARAMETER + this.generatedCategoryParameterId;
  }

  editParameter(parameter?) {
    const modalRef = this.dialog.open(ParameterDetailModalComponent, {
      panelClass: 'light-background',
    });
    const parameterDetailModalComponent = modalRef.componentInstance;
    parameterDetailModalComponent.module = 'pc';
    parameterDetailModalComponent.parentForm = this.formBuilder.group(
      CategoryParametersListingComponent.getCategoryParameterEditForm(this.formBuilder)
    );
    parameterDetailModalComponent.parameterType = 'category';
    parameterDetailModalComponent.enableAcl = false;
    parameterDetailModalComponent.parentParametersMode = this.parentParametersMode;
    if (parameter && parameter.id) {
      parameterDetailModalComponent.isNew = false;
      parameterDetailModalComponent.parameter = parameter;
      parameterDetailModalComponent.parentParameters = this.parentParameters;
      parameterDetailModalComponent.overwriteParent = this.parentParameters
        ? !!this.parentParameters?.find(param => param.name === parameter.name)
        : this.formArray?.value.find(param => param.name === parameter.name);
    } else {
      parameter.id = this.generateNewCategoryParameterId();
      parameterDetailModalComponent.parameter = parameter;
      parameterDetailModalComponent.isNew = true;
    }
    parameterDetailModalComponent.dialogRef = modalRef;
    parameterDetailModalComponent.selectHandler = (parameterForm: FormGroup, isNew, parentParametersMode) => {
      parameterForm.enable();
      if (parentParametersMode) {
        this.editParent.emit(parameterForm.getRawValue());
      } else {
        if (isNew) {
          this.formArray.push(parameterForm);
        } else {
          const index = this.formArray.value.findIndex(param => param.id === parameterForm.get('id').value);
          if (index !== -1) {
            this.formArray.removeAt(index);
            this.formArray.insert(index, parameterForm);
          }
        }
      }
    };
  }
}
