import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PagedTicketsDto } from '@btl/order-bff';
import { Subject } from 'rxjs';
import { Search, TicketingService } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ticket-mini-list',
  templateUrl: './ticket-mini-list.component.html',
  styleUrls: ['./ticket-mini-list.component.scss']
})
export class TicketMiniListComponent implements OnInit, OnDestroy {
  protected onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input()
  type;

  @Input()
  userProfile;

  pagedTicketsDto: PagedTicketsDto;

  currentDate = new Date();

  title;

  icon;
  typeIcon;

  ticketTypes = ['TASK', 'CUSTOMER', 'TROUBLE', 'OUTAGE', 'REMINDER'];

  constructor(private ticketingService: TicketingService) {
  }

  ngOnInit(): void {
    this.sortByInputType(this.type);
  }

  sortByInputType(inputType) {
    let search: Search = {
      filtering: [
        { column: 'assignedTo', compareType: 'IN', value: [this.userProfile.username] },
        { column: 'businessState', compareType: 'DIFFERENT', value: 'CANCELED' },
        { column: 'businessState', compareType: 'DIFFERENT', value: 'CLOSED' }
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: 3
      }
    };

    if (inputType === 'upcoming') {
      this.icon = 'clock';
      this.title = 'wc.admin.dashboard.tickets.upComing';
      search.sorting = [
        { column: 'slaDueDate', sortOrder: 'asc' },
        { column: 'priority', sortOrder: 'asc' }
      ];
    }

    if (this.type === 'highPriority') {
      this.icon = 'arrowUp';
      this.title = 'wc.admin.dashboard.tickets.highPriority';
      search.sorting = [{ column: 'priority', sortOrder: 'asc' }];
    }

    this.ticketingService
      .getTicketsByFilter(search)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.pagedTicketsDto = result;
        this.pagedTicketsDto.data.forEach(ticket => {
          if (ticket.slaDueDate) {
            ticket.slaDueDate = new Date(ticket.slaDueDate);
          }
        });
      });
  }

  checkTime(date) {
    return date ? date : 0;
  }

  getIcon(code) {
    if (this.ticketTypes.includes(code)) {
      return code;
    } else {
      return 'other';
    }
  }
}
