import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { RoleDto, RuleDto } from '@btl/admin-bff';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ServiceUtils } from '@btl/btl-fe-wc-common';
import { AclTableListComponent } from '@components/acl/components/acl-rules-table/list/acl-table-list.component';

@Component({
  selector: 'app-acl-table-list-item, [app-acl-table-list-item]',
  templateUrl: './acl-table-list-item.component.html',
  styleUrls: ['./acl-table-list-item.component.scss'],
})
export class AclTableListItemComponent implements OnInit, OnChanges {
  selected = false;

  ruleTypes = ['GRANT', 'REVOKE'];

  @Input()
  aclRole: RoleDto;

  @Input()
  aclRules: Array<RuleDto>;

  @Input()
  parentAclRules: Array<RuleDto>;

  @Input()
  aclPrivileges;

  @Input()
  parentForm: FormGroup;

  @Input()
  context;

  @Input()
  parameterName;

  @Input()
  category = false;

  @Input()
  editable = true;

  @Output()
  readonly test: EventEmitter<any> = new EventEmitter<any>();

  editRule: boolean = false;

  loaded: boolean = false;

  aclRulesToEdit = [];

  aclRulesEdit: Array<RuleDto>;

  previousParameterName: string;

  previousCode: string;

  get aclFormArray(): FormArray {
    return <FormArray>this.parentForm.get('acl');
  }

  public static createAclRuleForm() {
    return {
      id: [],
      privilege: [],
      ruleType: [],
      roleType: [],
      validFor: [],
      resourceIdentification: [],
      recordVersion: [],
    };
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.aclRulesToEdit.length === 0) {
      this.aclRules
        .filter(p => p.roleType === this.aclRole.id)
        .forEach(rule => {
          if (!rule.id) {
            this.addRuleToEdit(rule);
          }
        });
    }
    this.aclRulesEdit = this.filterRules(this.aclRole);
    if (this.context) {
      if (this.context?.code) {
        this.previousCode = this.context.code;
      }
      if (this.context?.parameter) {
        this.previousParameterName = this.context.parameter.name;
      }
    }
  }

  ngOnChanges(changes: any) {
    if (this.aclRules) {
      if (!this.aclRulesEdit) {
        this.aclRulesEdit = [];
      }
      this.filterRules(this.aclRole).forEach(rule => {
        const ruleDto = this.filterEditRules().find(editRule => this.rulesEquals(rule, editRule));
        if (!ruleDto) {
          this.aclRulesEdit.push(rule);
        } else {
          ruleDto.resourceIdentification = rule.resourceIdentification;
          ruleDto.ruleType = rule.ruleType;
          ruleDto.id = rule.id;
          ruleDto.validFor = rule.validFor;
          ruleDto.recordVersion = rule.recordVersion;
        }
      });
    }
    if (this.context?.parameter && !this.parameterName) {
      if (this.previousParameterName !== this.context.parameter.name) {
        this.reloadRuleType();
      }
    }
  }

  filterEditRules() {
    return this.aclRulesEdit.filter(rule => this.resourceIdentificationMatch(rule));
  }

  saveRuleType() {
    this.editRule = false;
    this.filterEditRules().forEach(ruleToEdit => {
      const tempRulesExist = this.parentForm.controls['acl'].value.findIndex(value =>
        this.rulesEquals(value, ruleToEdit)
      );
      ruleToEdit.resourceIdentification = this.getResourceIdentification(ruleToEdit);
      if (tempRulesExist === -1) {
        this.addRuleToEdit(ruleToEdit);
      } else {
        this.aclFormArray.at(tempRulesExist).patchValue(ruleToEdit);
      }
    });
  }

  resourceIdentificationMatch(rule) {
    return (
      (this.parameterName &&
        (!rule.resourceIdentification || rule.resourceIdentification.indexOf(`:"${this.parameterName}"`)) > -1) ||
      (!this.parameterName &&
        (!rule.resourceIdentification || rule.resourceIdentification.indexOf('"productParamName":') === -1))
    );
  }

  rulesEquals(rule1, rule2) {
    return (
      rule1.privilege.id === rule2.privilege.id &&
      rule1.roleType === rule2.roleType &&
      this.resourceIdentificationMatch(rule1) &&
      this.resourceIdentificationMatch(rule2)
    );
  }

  getResourceIdentification(rule) {
    if (rule.privilege.resourceType === 'PC_PRODUCT_PARAMETER') {
      return AclTableListComponent.getProductResourceIdentification(this.category, null, this.parameterName);
    } else {
      return ServiceUtils.setResourceIdentification(rule.privilege.resourceType, this.context);
    }
  }

  private addRuleToEdit(ruleToEdit: RuleDto) {
    const ruleForm = this.formBuilder.group(AclTableListItemComponent.createAclRuleForm());
    ruleForm.patchValue(ruleToEdit);
    this.aclFormArray.push(ruleForm);
  }

  reloadRuleType() {
    this.parentForm.controls['acl'].value.forEach(rule => {
      rule.resourceIdentification = this.getResourceIdentification(rule);
    });
    if (this.previousParameterName && this.previousParameterName !== this.context.parameter.name) {
      this.aclRulesEdit.forEach(rule => {
        if (rule.ruleType) {
          this.saveRuleType();
        }
      });
    }
  }

  setRuleType(rule, ruleType) {
    rule.ruleType = ruleType;
  }

  filterRules(role) {
    const rules = [];
    let currentRules = [];
    let notSavedRules = [];
    this.aclPrivileges.data.forEach(privilege => {
      currentRules = this.aclRules.filter(rule => rule.roleType === role.id && this.resourceIdentificationMatch(rule));
      notSavedRules = this.parentForm.controls['acl'].value;
      if (notSavedRules.length > 0) {
        notSavedRules.forEach((notSavedRule: RuleDto) => {
          if (notSavedRule.roleType === role.id && this.resourceIdentificationMatch(notSavedRule)) {
            currentRules.push(notSavedRule);
          }
        });
      }
      let newRuleDto = {
        privilege: privilege,
        roleType: role.id,
        ruleType: null,
        resourceIdentification: null,
      };

      newRuleDto.resourceIdentification = this.getResourceIdentification(newRuleDto);
      currentRules.forEach(rule => {
        if (rule.privilege.id === privilege.id) {
          newRuleDto = rule;
        }
      });
      rules.push(newRuleDto);
    });
    this.loaded = true;
    return rules;
  }

  getParentRuleType(rule: RuleDto) {
    if (this.parentAclRules && this.parentAclRules.length > 0) {
      const parentRule = this.parentAclRules.find(
        filterRule => filterRule.roleType === this.aclRole.id && filterRule.privilege.id === rule.privilege.id
      );
      if (parentRule) {
        return parentRule.ruleType;
      }
    }
    return null;
  }

  changeRuleType(rule: RuleDto, ruleType) {
    rule.ruleType = ruleType;
    this.saveRuleType();
  }

  setRuleTypeToAllRules(type) {
    this.filterEditRules().forEach(rule => {
      this.setRuleType(rule, type);
    });
    this.saveRuleType();
  }
}
