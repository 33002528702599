import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AbstractPageComponent,
  AclService,
  AdminContextRulesService,
  AdminRuleSetService,
  AppBlockerService,
  CompareType,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { RuleSetDto } from '@btl/admin-bff';
import { MatDialog } from '@angular/material/dialog';
import {
  RuleSetOverwriteModalComponent,
} from '@components/product-catalogue/rule-sets/rule-set-edit/rule-set-overwrite-modal/rule-set-overwrite-modal.component';
import { Animations } from '@helpers/animations';
import {
  RuleSetsListingComponent,
} from '@components/product-catalogue/rule-sets/rule-sets-listing/rule-sets-listing.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-rule-set-edit',
  templateUrl: './rule-set-edit.component.html',
  styleUrls: ['./rule-set-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: RuleSetEditComponent.PAGE_ID })
export class RuleSetEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'RuleSetEditComponent';

  pageId(): string {
    return RuleSetEditComponent.PAGE_ID;
  }

  savingRuleSetId;
  ruleSet: RuleSetDto;

  validateNameForReservedKeywords: ValidatorFn = function (control: AbstractControl) {
    let value: string = control.value;
    if (value) {
      value = value.trim().toUpperCase();
      const keywords = ['AND', 'NOT', 'OR'];
      if (keywords.includes(value)) {
        return { reservedKeyword: true };
      }
    }
    return null;
  };

  ruleSetForm: FormGroup = this.formBuilder.group({
    id: [null, Validators.required],
    name: [null, [this.validateNameForReservedKeywords]],
    description: [null],
    ruleSetExpression: [null, [Validators.required]],
    rules: [null],
  });

  contextRules;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private stickyMessageService: StickyMessageService,
    private adminRuleSetService: AdminRuleSetService,
    private adminContextRulesService: AdminContextRulesService,
    public aclService: AclService
  ) {
    super(router, route);

    this.adminContextRulesService
      .filterContextRules(
        {
          filtering: [],
          sorting: [{ column: 'id', sortOrder: 'asc' }],
          paging: {
            page: 1,
            pageSize: -1,
          },
        },
        ['data.id']
      )
      .subscribe(results => {
        this.contextRules = [];
        results.data.forEach(contextRul => {
          this.contextRules.push(contextRul.id);
        });
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const ruleSetId = this.params.id;
      if (ruleSetId && ruleSetId !== 'newRuleSet') {
        this.loadRuleSet(ruleSetId);
      } else {
        this.ruleSet = {
          id: null,
        };
        this.ruleSetForm.controls['id'].setValue('newRuleSet');
      }
    } else {
      this.ruleSet = undefined;
    }
  }

  loadRuleSet(ruleSetId) {
    this.adminRuleSetService
      .getRuleSet(ruleSetId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadRuleSet(result);
      });
  }

  reloadRuleSet(ruleSetDto: RuleSetDto) {
    this.ruleSet = ruleSetDto;
    this.ruleSetForm.reset(this.ruleSet);
    if (this.ruleSet.recordVersion) {
      this.ruleSetForm.controls['ruleSetExpression'].disable();
    }
  }

  public getRuleSetHandler = (ruleSetDto: RuleSetDto): void => {
    if (ruleSetDto) {
      if (ruleSetDto.id === this.savingRuleSetId) {
        this.reloadRuleSet(ruleSetDto);
      } else {
        this.reloadRuleSet(ruleSetDto);
        this.navigateSelf({ id: ruleSetDto.id });
      }
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  reset() {
    if (this.ruleSet && this.ruleSet.recordVersion) {
      this.loadRuleSet(this.ruleSet.id);
    } else {
      this.reloadRuleSet(this.ruleSet);
    }
  }

  delete() {
    this.appBlockerService.block();
    this.adminRuleSetService
      .deleteRuleSet(this.ruleSet.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.navigateSibling(RuleSetsListingComponent.PAGE_ID);
        this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
      });
  }

  duplicate() {
    this.ruleSetForm.controls.id.enable();
    this.ruleSetForm.controls.id.patchValue(`${this.ruleSet.id}copy`);
    this.ruleSet.recordVersion = null;
  }

  save() {
    if (this.saveRuleSetAttributes()) {
      this.appBlockerService.block();
      if (this.ruleSet.recordVersion) {
        this.savingRuleSetId = this.ruleSet.id;
        const ruleSetMap = {
          name: this.ruleSet.name,
          description: this.ruleSet.description,
          recordVersion: this.ruleSet.recordVersion,
        };
        this.adminRuleSetService
          .patchRuleSet(this.savingRuleSetId, ruleSetMap)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getRuleSetHandler);
      } else {
        this.savingRuleSetId = null;
        this.ruleSet.id = null;
        this.adminRuleSetService
          .createRuleSet(this.ruleSet)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            ruleSet => {
              this.getRuleSetHandler(ruleSet);
            },
            error => {
              if (error.status === 409 && error.error.failures[1].detail.startsWith('Rule set with expresion ')) {
                this.stickyMessageService.deleteStickyMessage(this.stickyMessageService.stickyMessages.length - 1);
                const search: Search = {
                  filtering: [
                    {
                      column: 'ruleSetExpression',
                      compareType: CompareTypeDtoEnum.EQUAL,
                      value: this.ruleSet.ruleSetExpression,
                    },
                  ],
                  sorting: [],
                  paging: {
                    page: 1,
                    pageSize: -1,
                  },
                };
                this.adminRuleSetService.filterRuleSets(search, null).subscribe(ruleSets => {
                  if (ruleSets && ruleSets.data.length === 1) {
                    const modalRef = this.dialog.open(RuleSetOverwriteModalComponent);
                    const groupsSelectModalComponent = modalRef.componentInstance;
                    groupsSelectModalComponent.dialogRef = modalRef;
                    groupsSelectModalComponent.ruleSet = ruleSets.data[0];

                    groupsSelectModalComponent.retHandler = ret => {
                      if (ret) {
                        const ruleSetMap = {
                          name: this.ruleSet.name,
                          description: this.ruleSet.description,
                          recordVersion: ruleSets.data[0].recordVersion,
                        };
                        this.adminRuleSetService
                          .patchRuleSet(ruleSets.data[0].id, ruleSetMap)
                          .pipe(finalize(this.appBlockerService.unblock))
                          .pipe(takeUntil(this.onDestroy$))
                          .subscribe(this.getRuleSetHandler);
                      }
                    };
                  }
                });
              }
            }
          );
      }
    }
  }

  saveRuleSetAttributes(): boolean {
    FormUtils.validateAllFormFields(this.ruleSetForm);
    if (this.ruleSetForm.valid) {
      if (this.ruleSet) {
        Object.keys(this.ruleSetForm.controls).forEach(field => {
          const control = this.ruleSetForm.get(field);
          this.ruleSet[field] = control.value;
        });
      }
      return true;
    }

    return false;
  }

  clearField(field: string) {
    this.ruleSetForm.controls[field].patchValue(null);
  }

  cancel() {
    this.navigateSibling(RuleSetsListingComponent.PAGE_ID);
  }
}
