<div class="page_wrapper">
  <div class="main__header">
    <h1 id="001_product_group_edit">{{ 'wc.admin.productGroups.groupEdit' | translate }}</h1>
    <div class="page__menu">
      <a
        id="002_product_group_edit"
        color="primary"
        mat-raised-button
        (click)="save()"
        *ngIf="aclService.pageEditAccess"
        [routerLink]=""
        class="btn -primary -xs icon stroke currentColor"
        ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
      >
      <a
        id="003_product_group_edit"
        color="primary"
        mat-stroked-button
        *ngIf="aclService.pageEditAccess"
        [routerLink]=""
        class="btn -primary -xs more-actions icon dropDownIcon currentColor"
        [matMenuTriggerFor]="menu"
        #t="matMenuTrigger"
        >{{ 'wc.common.more.actions.button' | translate }}
        <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
      </a>
      <mat-menu #menu="matMenu" class="module-actions">
        <a
          id="004_product_group_edit"
          mat-menu-item
          (click)="duplicate()"
          *ngIf="productGroup && productGroup.recordVersion"
          [routerLink]="">
          <mat-icon svgIcon="duplicate"></mat-icon>
          {{ 'wc.common.duplicate' | translate }}
        </a>
        <a id="005_product_group_edit" mat-menu-item (click)="reset()" [routerLink]="">
          <mat-icon svgIcon="reset"> </mat-icon>{{ 'wc.common.reset' | translate }}
        </a>
        <a
          id="006_product_group_edit"
          mat-menu-item
          (click)="delete()"
          *ngIf="productGroup && productGroup.recordVersion"
          [routerLink]="">
          <mat-icon svgIcon="delete"></mat-icon>
          {{ 'wc.common.delete' | translate }}
        </a>
        <a
          id="007_product_group_edit"
          mat-menu-item
          (click)="releases()"
          *ngIf="productGroup && productGroup.code"
          [routerLink]="">
          <mat-icon svgIcon="upload"></mat-icon>
          {{ 'wc.admin.releases' | translate }}
        </a>
      </mat-menu>
      <a id="008_product_group_edit" (click)="cancel()" class="btn cancel-button">{{
        'wc.common.cancel.button' | translate
      }}</a>
    </div>
  </div>

  <div *ngIf="productGroup" class="card__wrapper">
    <!-- card -->
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.productGroups' | translate }}
        </div>
        <div class="card__body">
          <p>
            <span *ngFor="let group of groupTree" [ngStyle]="{ 'font-weight': group.active ? 'bold' : 'normal' }">{{
              group.name
            }}</span>
          </p>
          <form *ngIf="productGroup" [formGroup]="groupForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="groupForm"
                    [controlName]="'name'"
                    id="009_product_group_edit"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.productGroups'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="groupForm"
                    [controlName]="'code'"
                    id="010_product_group_edit"
                    [inputType]="'text'"
                    [label]="'typeCode'"
                    [translationPrefix]="'wc.admin.productGroups'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'type'"
                    [options]="groupTypeNames"
                    id="011_product_group_edit"
                    (selectionChangeEmit)="groupTypeChanged($event)"
                    [translationPrefix]="'wc.admin.productGroups'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="groupForm"
                    [controlName]="'seoUrl'"
                    [inputType]="'text'"
                    id="012_product_group_edit"
                    [translationPrefix]="'wc.admin.productGroups'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="groupForm"
                    [controlName]="'priority'"
                    [inputType]="'number'"
                    id="013_product_group_edit"
                    [translationPrefix]="'wc.admin.productGroups'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-input-picker-form-field
                    [parentGroup]="groupForm"
                    [controlName]="'parentId'"
                    id="014_product_group_edit"
                    [valueField]="'id'"
                    labelField="code"
                    [pickerType]="PickerInputType.GROUP"
                    [selectMode]="false"
                    [label]="'parentGroup'"
                    [translationPrefix]="'wc.admin.productGroups'">
                  </app-input-picker-form-field>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [id]="'015_product_group_edit'"
                    [inputType]="'checkbox'"
                    [parentGroup]="groupForm"
                    [controlName]="'enabled'"
                    [label]="'wc.admin.productGroups.enabled'">
                  </app-custom-input>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <form [formGroup]="groupForm" class="card__wrapper">
      <app-form-texts
        class="card -full"
        [pcEntityType]="'ProdGroup'"
        id="016_product_group_edit"
        [textTypeTranslationPrefix]="'wc.admin.productGroups'"
        formControlName="texts">
      </app-form-texts>
    </form>

    <!-- card -->
    <!-- -->
    <div class="card__wrapper" [formGroup]="groupForm">
      <app-form-pictures
        class="card -full"
        *ngIf="productGroup && pictureTypes"
        id="017_product_group_edit"
        formControlName="pictures"
        [control]="groupForm.controls['pictures']"
        [types]="pictureTypes"
        [getFromGalleryEnabled]="false"
        extIdPrefix="PC_PICTURE"
        dmsType="GENERAL"
        headerTranslationKey="wc.admin.pictures">
      </app-form-pictures>
    </div>

    <div class="card__wrapper" [formGroup]="groupForm">
      <app-form-parameters
        class="card -full"
        [control]="groupForm.get('groupParams')"
        [excludeParams]="(productGroup && groupForm.getRawValue().type === GUI_CATEGORY) ? [BESTSELLING_LIST_PARAM_NAME, BESTSELLING_LIST_ENABLE_PARAM_NAME, BESTSELLING_LIST_MAX_COUNT_PARAM_NAME ] : null"
        [dictionaryValue]="true"
        formControlName="groupParams">
      </app-form-parameters>
    </div>

    <div class="card -full"
         *ngIf="productGroup && groupForm.getRawValue().type === GUI_CATEGORY && bestsellingListFormPatched">
      <div class="card__inner group-top-products" [formGroup]="bestsellingListForm">
        <div subsetName="bestsellingProducts"
             [formArray]="getBestsellingListFormArray()"
             [formGroup]="bestsellingListForm"
             [connectWithAllGroupProducts]="false"
             [allGroupProducts]="getProductFormArray().getRawValue()"
             [maxSubProducts]="bestsellingListForm.getRawValue()['bestsellingListMaxCount']"
             app-group-subset-products
             class="card__wrapper">
        </div>

        <div class="form__groups">
          <div class="form__group -flex">
            <div class="form__row -full">
              <div class="two-inputs">
                <app-custom-input
                  [id]="'018_product_group_edit'"
                  [inputType]="'checkbox'"
                  [parentGroup]="bestsellingListForm"
                  [controlName]="'bestsellingListEnables'"
                  [label]="'wc.admin.productGroups.bestsellingListEnables'">
                </app-custom-input>
                <app-custom-input
                  class="margin"
                  [parentGroup]="bestsellingListForm"
                  controlName="bestsellingListMaxCount"
                  [inputType]="'number'"
                  id="019_product_group_edit"
                  [translationPrefix]="'wc.admin.productGroups'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card -full">
      <div class="card__inner group-top-products">
        <div *ngIf="productGroup"
             subsetName="topProducts"
             [formArray]="getProductFormArray()"
             [formGroup]="groupForm"
             [allGroupProducts]="getProductFormArray().getRawValue()"
             [maxSubProducts]="MAX_TOP_PRODUCTS"
             app-group-subset-products
             class="card__wrapper">
        </div>
      </div>
    </div>

    <div
      *ngIf="productGroup"
      [formArray]="getProductFormArray()"
      [formGroup]="groupForm"
      [groupProducts]="productGroup.groupProducts"
      app-group-products-listing
      class="card__wrapper"></div>

    <div class="card -full">
      <div class="card__inner">
        <app-cart-events-listing
          *ngIf="productGroup"
          [cacheFilter]="false"
          [constantFilter]="getProductSourceFilter()"
          [editable]="false"
          [hideFilter]="true"
          [disableListingActions]="true"
          [disableActions]="true"
          [selectMode]="false"
          additionalHeaderLtKey="wc.admin.relationships.sourceType">
        </app-cart-events-listing>
      </div>
    </div>
  </div>
</div>
