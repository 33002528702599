import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { WidgetTemplateParameterDto } from '@btl/admin-bff';
import { EnumerationsService } from '@service/enumerations.service';

@Component({
  selector: 'app-cms-content-widget-modal-controls',
  templateUrl: './cms-content-widget-modal-controls.component.html',
  styleUrls: ['./cms-content-widget-modal-controls.component.scss'],
})
export class CmsContentWidgetModalControlsComponent implements OnInit {
  readonly SOURCE_NAME = EnumerationsService.CMS;

  @Input()
  formGroup: FormGroup;

  @Input()
  currentLocale: string;

  @Input()
  locale: string;

  @Input()
  index: number;

  @Input()
  seqIndex: number;

  @Input()
  widgetTemplateParameter: WidgetTemplateParameterDto;

  @Input()
  modalEditorConfig: AngularEditorConfig;

  @Output()
  readonly checkValidationEvent: EventEmitter<any> = new EventEmitter();

  title: string;

  imageSize: string;

  ngOnInit(): void {
    if (this.widgetTemplateParameter?.localizedNames) {
      this.title = `${this.widgetTemplateParameter?.localizedNames[this.currentLocale]} ${
        this.locale ? `[${this.locale}]` : ''
      }`;
    } else {
      this.title = this.widgetTemplateParameter?.name || this.formGroup.get('name').value;
    }

    if (this.widgetTemplateParameter.type === 'BOOLEAN') {
      this.formGroup.get('value').setValue(this.widgetTemplateParameter.value === 'true');
    }

    if (
      ['STRING', 'NUMBER', 'BOOLEAN', 'ENUM'].includes(this.widgetTemplateParameter.type) &&
      !this.formGroup.get('mandatory').value
    ) {
      this.formGroup.get('value').valueChanges.subscribe(() => {
        this.checkValidationEvent.emit();
      });
    }
  }

  /**
   * If is optional param (mandatory = false) we emit value change.
   * This is for removing errors on all optional parameters.
   */
  valueChanged(): void {
    if (!this.formGroup.get('mandatory').value) {
      this.checkValidationEvent.emit();
    }
  }

  addError(type, detailType) {
    if (['CODEBOOK', 'DATE'].includes(type) || ('STRING' === type && ['HTML', 'IMAGE'].includes(detailType))) {
      return true;
    }
    return false;
  }

  getLabel() {
    return this.widgetTemplateParameter.localizedNames[this.currentLocale]
      ? this.widgetTemplateParameter.localizedNames[this.currentLocale]
      : this.widgetTemplateParameter.name;
  }

  setImageSize = (value): void => {
    this.imageSize = value;
  }
}
