import { Component } from '@angular/core';
import { AbstractFilterComponent } from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { CustomAdminContextRulesService } from '@service/custom-admin-context-rules.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rule-sets-filter',
  templateUrl: './rule-sets-filter.component.html',
  styleUrls: ['./rule-sets-filter.component.scss'],
})
export class RuleSetsFilterComponent extends AbstractFilterComponent {
  mapFieldWithColumnName = new Map([['rulesRuleName', 'rules.ruleName']]);

  contextRules;

  constructor(private formBuilder: FormBuilder,
              private customAdminContextRulesService : CustomAdminContextRulesService) {
    super();
    this.filterForm = this.formBuilder.group({
      name: [],
      description: [],
      ruleSetExpression: [],
      rulesRuleName: [],
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.customAdminContextRulesService.getContextRulesList()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((results) => {
        this.contextRules = results;
    });
  }
}
