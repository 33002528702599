import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AdminTextTypeService, FormUtils } from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextDto, TextTypeDto } from '@btl/admin-bff';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-form-texts, [app-form-texts]',
  templateUrl: './form-texts.component.html',
  styleUrls: ['./form-texts.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormTextsComponent),
      multi: true,
    },
  ],
})
export class FormTextsComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input()
  pcEntityType;

  @Input()
  textTypeTranslationPrefix = 'wc.admin';

  @Input()
  textTypes: Array<TextTypeDto>;

  @Input()
  disabled;

  locales = environment.localization.locales;

  @Input()
  value: Array<TextDto>;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup = this.formBuilder.group({});

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private textTypeService: AdminTextTypeService) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  ngOnInit() {
    if (this.pcEntityType) {
      this.textTypeService
        .getTextTypes(this.pcEntityType)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.textTypes = result;
          this.valueChanged();
        });
    } else {
      this.valueChanged();
    }
  }

  valueChanged() {
    if (!this.value) {
      this.value = [];
    }

    this.form = this.formBuilder.group({});
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
    if (this.textTypes) {
      this.textTypes.forEach(textType => {
        const formGroup = this.formBuilder.group({});
        this.locales.forEach(locale => {
          const formControl = new FormControl();
          const productTextTypes = this.getTextFromDocumentTexts(textType.id, locale);
          if (productTextTypes) {
            formControl.patchValue(productTextTypes.message);
          }
          formGroup.addControl(locale, formControl);
        });
        this.form.addControl(textType.id, formGroup);
      });
    }
  }

  getTextFromDocumentTexts(textType: string, locale: string): TextDto {
    let text: TextDto;
    this.value.forEach(textloop => {
      if (textloop.textType === textType && textloop.locale === locale) {
        text = textloop;
      }
    });
    return text;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      const texts = [];

      Object.keys(this.form.controls).forEach(group => {
        const groupControl = this.form.get(group) as FormGroup;

        Object.keys(groupControl.controls).forEach(field => {
          const control = groupControl.get(field);
          const text = control.value;
          if (text && text !== '') {
            const groupTextDto: TextDto = {
              textType: group,
              locale: field,
              message: text,
            };
            texts.push(groupTextDto);
          }
        });
      });

      return texts;
    }

    return null;
  }

  parentGroup(textType: TextTypeDto): FormGroup {
    return this.form.controls[textType.id] as FormGroup;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
