<ng-container *ngIf="initialized && widgetTemplateDtoList">
  <div class="main__header">
    <div class="d-flex">
      <h1>{{ 'wc.admin.cms.content.edit' | translate }}</h1>
      <h1 class="cms_edit-status" *ngIf="contentVersionForm.get('code').value">
        - {{ contentVersionForm.get('code').value }}
      </h1>
    </div>
    <div class="page__menu">
      <a
        id="001_cms_contents_edit"
        color="primary"
        mat-raised-button
        (click)="save()"
        *ngIf="(!contentMasterDto.id || contentVersionForm.get('state').value === 'DRAFT') && aclService.pageEditAccess"
        [routerLink]=""
        class="btn -primary -xs icon stroke currentColor">
        <mat-icon svgIcon="save"></mat-icon>
        {{ 'wc.common.save' | translate }}</a
      >
      <a
        id="002_cms_contents_edit"
        color="primary"
        mat-raised-button
        (click)="saveAndApprove()"
        *ngIf="(!contentMasterId || contentVersionForm.get('state').value === 'DRAFT') && isBannerContent"
        [routerLink]=""
        class="btn -primary -xs"
        >{{ 'wc.admin.cms.content.saveAndApprove' | translate }}</a
      >
      <a
        id="003_cms_contents_edit"
        color="primary"
        mat-stroked-button
        *ngIf="aclService.pageEditAccess"
        [routerLink]=""
        class="btn -primary -xs more-actions icon dropDownIcon currentColor"
        [matMenuTriggerFor]="menu"
        #t="matMenuTrigger"
        >{{ 'wc.common.more.actions.button' | translate }}
        <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
      </a>
      <mat-menu #menu="matMenu" class="module-actions">
        <a
          id="004_cms_contents_edit"
          mat-menu-item
          (click)="newVersion()"
          *ngIf="
            contentVersionForm.get('state').value === 'ARCHIVED' || contentVersionForm.get('state').value === 'APPROVED'
          "
          [routerLink]=""
          >{{ 'wc.admin.cms.staticPage.newVersion' | translate }}
          <mat-icon svgIcon="plus"> </mat-icon>
        </a>
        <a
          id="005_cms_contents_edit"
          mat-menu-item
          (click)="duplicate()"
          *ngIf="contentMasterDto.id && contentVersionDto.id && !isBannerContent"
          [routerLink]=""
          >{{ 'wc.common.duplicate' | translate }}
          <mat-icon svgIcon="duplicate"> </mat-icon>
        </a>
        <a
          id="006_cms_contents_edit"
          mat-menu-item
          (click)="archive()"
          *ngIf="contentVersionDto.id && contentVersionForm.get('state').value === 'APPROVED'"
          [routerLink]=""
          >{{ 'wc.admin.cms.staticPage.archive' | translate }}
          <mat-icon svgIcon="archive"> </mat-icon>
        </a>
        <a
          id="007_cms_contents_edit"
          mat-menu-item
          (click)="approve()"
          *ngIf="
            !isBannerContent &&
            contentMasterDto.id &&
            contentVersionDto.id &&
            contentVersionForm.get('state').value === 'DRAFT'
          "
          [routerLink]=""
          >{{ 'wc.admin.cms.staticPage.approve' | translate }}
          <mat-icon svgIcon="accept"> </mat-icon>
        </a>
        <a
          id="008_cms_contents_edit"
          mat-menu-item
          (click)="reset()"
          *ngIf="!contentMasterDto.id || contentVersionForm.get('state').value === 'DRAFT'"
          [routerLink]=""
          >{{ 'wc.common.reset' | translate }}
          <mat-icon svgIcon="reset"> </mat-icon>
        </a>
        <a
          id="009_cms_contents_edit"
          mat-menu-item
          (click)="delete()"
          *ngIf="contentVersionDto.id && contentVersionForm.get('state').value === 'DRAFT'"
          [routerLink]=""
          >{{ 'wc.common.delete' | translate }}
          <mat-icon svgIcon="delete"> </mat-icon>
        </a>
      </mat-menu>
      <a id="010_cms_contents_edit" (click)="cancel()" class="btn cancel-button" *ngIf="!isBannerContent">{{
        'wc.common.cancel.button' | translate
      }}</a>
      <a id="011_cms_contents_edit" (click)="cancelAndClose()" class="btn cancel-button" *ngIf="isBannerContent">{{
        'wc.admin.cms.content.cancelAndClose' | translate
      }}</a>
    </div>
  </div>

  <div
    class="main__header"
    *ngIf="
      contentVersionForm.get('state').value === 'ARCHIVED' || contentVersionForm.get('state').value === 'APPROVED'
    ">
    <div class="warning">
      {{ 'wc.admin.cms.content.edit.warning' | translate }}
    </div>
  </div>

  <div class="card__wrapper">
    <!-- Content template - show only for new Content -->
    <div
      class="card -full"
      *ngIf="
        !isDuplicate && !isBannerContent && templateDto && templateDtoList && (isBannerContent || !contentMasterDto.id)
      ">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.cms.content.template' | translate }}
        </div>
        <div class="card__body">
          <form class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                    <mat-label>{{ 'wc.admin.cms.content.selectTemplate' | translate }}</mat-label>
                    <mat-select
                      id="012_cms_contents_edit"
                      disableOptionCentering
                      class="form__input"
                      panelClass="form-dropdown"
                      [value]="contentMasterDto.contentTemplateType"
                      (selectionChange)="templateChange($event.value)">
                      <!--<mat-option *ngIf="multipleSelect" [value]="null" (click)="handleSelectAll()">Select all</mat-option>-->
                      <mat-option [id]="template.type" *ngFor="let template of templateDtoList" [value]="template.type">
                        {{ template.type }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Content attributes -->
    <div class="card -full">
      <div class="card__inner" *ngIf="contentMasterDto">
        <div class="card__header">
          {{ 'wc.admin.cms.content.contentAttributes' | translate }}
        </div>
        <div class="card__body">
          <form [formGroup]="contentMasterForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [id]="'013_cms_contents_edit'"
                    [disabled]="true"
                    [label]="'wc.admin.cms.content.templateType'"
                    [simplifiedInput]="true"
                    [enteredValue]="templateDto.type">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="contentMasterForm"
                    [controlName]="'name'"
                    id="014_cms_contents_edit"
                    [inputType]="'text'"
                    [label]="'contentTitle'"
                    [translationPrefix]="'wc.admin.cms.content'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [id]="'015_cms_contents_edit'"
                    [inputType]="'checkbox'"
                    [parentGroup]="contentMasterForm"
                    [controlName]="'isInternal'"
                    [label]="'wc.admin.cms.content.internal'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="contentMasterForm"
                    [controlName]="'code'"
                    [inputType]="'text'"
                    id="016_cms_contents_edit"
                    [label]="'contentCode'"
                    (change)="handleContentMasterCode()"
                    [translationPrefix]="'wc.admin.cms.content'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'state'"
                    id="017_cms_contents_edit"
                    [options]="contentStates"
                    [label]="'contentState'"
                    [disableOptionsTranslate]="true"
                    [translationPrefix]="'wc.admin.cms.content'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">&nbsp;</div>
                <div class="form__row" formGroupName="validFor">
                  <app-input-date-form-field
                    [parentGroup]="contentMasterForm.controls['validFor']"
                    [controlName]="'startDateTime'"
                    id="018_cms_contents_edit"
                    [translationPrefix]="'wc.admin.cms.content'">
                  </app-input-date-form-field>
                </div>
                <div class="form__row" formGroupName="validFor">
                  <app-input-date-form-field
                    [parentGroup]="contentMasterForm.controls['validFor']"
                    [controlName]="'endDateTime'"
                    id="019_cms_contents_edit"
                    [translationPrefix]="'wc.admin.cms.content'">
                  </app-input-date-form-field>
                </div>
                <div class="form__row">&nbsp;</div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Content Parameters -->
    <div class="card -full content-parameters">
      <div class="card__inner" *ngIf="contentMasterDto?.parameters || contentMasterDto?.keys">
        <div class="card__header">
          {{ 'wc.admin.cms.content.content.parameters' | translate }}
        </div>
        <div class="card__body">
          <form [formGroup]="paramFormGroup" class="form" id="020_cms_contents_edit">
            <ng-container formArrayName="contentKeysFormArray">
              <div class="form__groups">
                <div class="form__group -flex">
                  <div class="form__row" *ngFor="let keyForm of contentKeysFormArray.controls; let i = index">
                    <ng-container [formGroupName]="i">
                      <app-cms-content-parameter
                        [formGroup]="keyForm"
                        controlValueName="keyValue"
                        [controlName]="keyForm.get('keyId')"
                        [controlValue]="keyForm.get('keyValue')"
                        [templateParameterDto]="templateDto.parameters[keyForm.get('index').value]"
                        [currentLocale]="currentLocale"
                        [index]="i"
                        labelPrefix="007">
                      </app-cms-content-parameter>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </form>
          <hr />
          <form [formGroup]="paramFormGroup" class="form" id="021_cms_contents_edit">
            <ng-container formArrayName="contentParameterFormArray">
              <div class="form__groups">
                <div class="form__group -flex">
                  <div
                    class="form__row"
                    *ngFor="let parameterForm of contentParameterFormArray.controls; let i = index">
                    <ng-container [formGroupName]="i">
                        <app-cms-content-parameter
                          [formGroup]="parameterForm"
                          [controlName]="parameterForm.get('name')"
                          [controlValue]="parameterForm.get('value')"
                          [templateParameterDto]="templateDto.parameters[parameterForm.get('index').value]"
                          [currentLocale]="currentLocale"
                          [index]="i"
                          labelPrefix="008">
                        </app-cms-content-parameter>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
      </div>
    </div>

    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          <div class="card-title-container">
            {{ 'wc.admin.products.attributes.localizedParameters' | translate }}
            <div class="card-title-dropdown-box">
              <div class="card-title-dropdown">
                <mat-select [(value)]="attributesLanguage" id="021_product_attributes">
                  <mat-option id="{{ locale }}" *ngFor="let locale of locales" [value]="locale.toLowerCase()">
                    {{ locale.toUpperCase() }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </div>
        <div class="card__body">
          <form [formGroup]="paramFormGroup" class="form" id="021b_cms_contents_edit">
            <ng-container formArrayName="contentLocalizedParameterFormArray">
              <div class="form__groups">
                <div class="form__group -flex">
                  <div
                    class="form__row"
                    *ngFor="let parameterForm of contentLocalizedParameterFormArray.controls; let i = index">
                    <ng-container [formGroupName]="i">
                      <ng-container *ngFor="let local of locales">
                        <app-cms-content-parameter
                          [formGroup]="parameterForm"
                          [controlName]="parameterForm.get('name')"
                          [controlValue]="parameterForm.get('localizedParameters').get(local)"
                          [templateParameterDto]="templateDto.parameters[parameterForm.get('index').value]"
                          [currentLocale]="currentLocale"
                          [local]="local"
                          *ngIf="local.toLowerCase() === attributesLanguage"
                          [index]="i"
                          labelPrefix="008">
                        </app-cms-content-parameter>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
      </div>
    </div>

    <!-- Version attributes -->
    <div class="card -full">
      <div class="card__inner" *ngIf="contentVersionDto">
        <div class="card__header">
          {{ 'wc.admin.cms.content.version.attributes' | translate }}
        </div>
        <div class="card__body">
          <form [formGroup]="contentVersionForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="contentVersionForm"
                    [controlName]="'version'"
                    id="022_cms_contents_edit"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.cms.content'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="contentVersionForm"
                    [controlName]="'priority'"
                    id="023_cms_contents_edit"
                    [inputType]="'number'"
                    [translationPrefix]="'wc.admin.cms.content.version'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="contentVersionForm"
                    [controlName]="'state'"
                    id="024_cms_contents_edit"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.cms.content.version'">
                  </app-custom-input>
                </div>

                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="contentVersionForm"
                    [controlName]="'verHash'"
                    [inputType]="'text'"
                    id="025_cms_contents_edit"
                    [generateButton]="
                      contentVersionDto.id &&
                      contentVersionForm.get('state').value === 'DRAFT' &&
                      !contentVersionForm.get('verHash').value
                    "
                    [deleteButton]="
                      contentVersionForm.get('state').value === 'DRAFT' && contentVersionForm.get('verHash').value
                    "
                    (generateButtonEvent)="generateVersionHash()"
                    (deleteButtonEvent)="deleteVersionHash()"
                    [translationPrefix]="'wc.admin.cms.content.version'">
                  </app-custom-input>
                </div>

                <div class="form__row" formGroupName="validFor">
                  <app-input-date-form-field
                    [parentGroup]="contentVersionForm.controls['validFor']"
                    [controlName]="'startDateTime'"
                    id="026_cms_contents_edit"
                    [translationPrefix]="'wc.admin.cms.content'">
                  </app-input-date-form-field>
                </div>
                <div class="form__row" formGroupName="validFor">
                  <app-input-date-form-field
                    [parentGroup]="contentVersionForm.controls['validFor']"
                    [controlName]="'endDateTime'"
                    id="027_cms_contents_edit"
                    [translationPrefix]="'wc.admin.cms.content'">
                  </app-input-date-form-field>
                </div>
                <div class="form__row">&nbsp;</div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="card -full">
    <div class="card__inner">
      <app-cms-content-widget
        (previewEvent)="widgetsPreview()"
        [currentLocale]="currentLocale"
        [contentMasterDto]="contentMasterDto"
        [contentTemplateDto]="templateDto"
        [contentParameterFormArray]="contentParameterFormArray"
        [contentVersionDto]="contentVersionDto">
      </app-cms-content-widget>
    </div>
  </div>
</ng-container>
