<h2 mat-dialog-title>
  {{ 'wc.admin.ticket.new' | translate }}
  <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<div class="modal-body">
  <div [formGroup]="newTicketForm" class="form" fxLayout="column" style="row-gap: 25px">
    <div class="form__row">
      <app-input-select-form-field
        id="001_create_ticket_modal"
        (selectionChangeEmit)="ticketAreaChange($event)"
        [formControlName]="'areaType'"
        [options]="areas"
        [labelOption]="'areaType'"
        [valueOption]="'areaType'"
        [translationPrefix]="'wc.admin.ticket'">
      </app-input-select-form-field>
    </div>
    <div class="form__row">
      <app-input-select-form-field
        [formControlName]="'code'"
        id="002_create_ticket_modal"
        [options]="codes"
        [labelOption]="'code'"
        [valueOption]="'code'"
        [translationPrefix]="'wc.admin.ticket'">
      </app-input-select-form-field>
    </div>
  </div>
</div>
<mat-dialog-actions align="end" style="margin-top: 20px">
  <button color="primary" mat-raised-button (click)="continue()" [routerLink]="">
    {{ 'wc.common.continue.button' | translate }}
  </button>
  <button color="primary" mat-raised-button (click)="cancel()" routerLink="/ticketing/tickets/">
    {{ 'wc.common.cancel.button' | translate }}
  </button>
</mat-dialog-actions>
