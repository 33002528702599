<td colspan="9">
  <table class="table -primary -striped table-sm">
    <thead>
      <th scope="col" class="content-desktop" [style.width.px]="75">
        {{ 'wc.admin.cms.content.version.list.code' | translate }}
      </th>
      <th scope="col">
        {{ 'wc.admin.cms.content.version.list.version' | translate }}
      </th>
      <th scope="col">
        {{ 'wc.admin.cms.content.version.list.versionState' | translate }}
      </th>
      <th scope="col">
        {{ 'wc.admin.cms.content.version.list.validFrom' | translate }}
      </th>
      <th scope="col" class="content-desktop">
        {{ 'wc.admin.cms.content.version.list.validTo' | translate }}
      </th>
      <th scope="col" class="content-desktop">
        {{ 'wc.admin.cms.content.version.list.created' | translate }}
      </th>
      <th scope="col" class="content-desktop">
        {{ 'wc.admin.cms.content.version.list.modified' | translate }}
      </th>
      <th scope="col" class="content-desktop">
        {{ 'wc.admin.cms.content.version.list.modifiedBy' | translate }}
      </th>
      <th scope="col">
        {{ 'wc.admin.cms.content.version.list.action' | translate }}
      </th>
    </thead>
    <tbody *ngIf="pagedContentVersionsDto?.data">
      <ng-container *ngFor="let contentVersionDto of pagedContentVersionsDto.data; let i = index">
        <tr
          [contentVersionDto]="contentVersionDto"
          [parentId]="'listing_' + i"
          [contentMasterDtoForVersioning]="contentMasterForVersioning"
          (dblclick)="show(contentVersionDto.id)"
          (archiveVersionEvent)="archive($event)"
          (approveVersionEvent)="approve($event)"
          (deleteVersionEvent)="delete($event)"
          class="table__row"
          [ngStyle]="{
            'color': getColor(contentVersionDto),
            'font-weight': isCandidateToShowOnGui(contentVersionDto) ? 'bold' : ''
          }"
          app-cms-content-versions-item></tr>
      </ng-container>
    </tbody>
  </table>
</td>
