import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ContentTemplateParameterDto } from '@btl/admin-bff';
import { EnumerationsService } from '@service/enumerations.service';

@Component({
  selector: 'app-cms-content-parameter',
  templateUrl: './cms-content-parameter.component.html',
  styleUrls: ['./cms-content-parameter.component.scss'],
})
export class CmsContentParameterComponent {
  readonly SOURCE_NAME = EnumerationsService.CMS;

  @Input()
  formGroup: FormGroup;

  @Input()
  controlName: AbstractControl;

  @Input()
  controlValueName: string;

  @Input()
  controlValue: AbstractControl;

  @Input()
  templateParameterDto: ContentTemplateParameterDto;

  @Input()
  currentLocale: string;

  @Input()
  index: number;

  @Input()
  local: string;

  @Input()
  labelPrefix: string;

  getLabel() {
    return this.templateParameterDto.localizedNames[this.currentLocale]
      ? this.templateParameterDto.localizedNames[this.currentLocale]
      : this.templateParameterDto.name;
  }
}
