import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AdminProductGroupService, AppBlockerService, CompareType, Search } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GroupDto } from '@btl/admin-bff';
import { MatDialog } from '@angular/material/dialog';
import { GroupsSelectModalComponent } from '@components/groups-select-modal/groups-select-modal.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-group-picker, [app-group-picker]',
  templateUrl: './group-picker.component.html',
  styleUrls: ['./group-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GroupPickerComponent),
      multi: true,
    },
  ],
})
export class GroupPickerComponent implements OnDestroy, ControlValueAccessor {
  @Output()
  readonly selectionChanged: EventEmitter<GroupDto> = new EventEmitter<GroupDto>();

  @Input()
  valueField;

  @Input()
  type;

  value;
  disable;
  group;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private adminProductGroupService: AdminProductGroupService,
    private dialog: MatDialog,
    private appBlockerService: AppBlockerService
  ) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  selectGroup() {
    const modalRef = this.dialog.open(GroupsSelectModalComponent);
    const groupsSelectModalComponent = modalRef.componentInstance;
    groupsSelectModalComponent.dialogRef = modalRef;
    if (this.type) {
      groupsSelectModalComponent.getSearch().filtering = [
        {
          column: 'type',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: this.type,
        },
      ];
    }

    groupsSelectModalComponent.selectHandler = group => {
      if (group) {
        this.value = group[this.valueField];
      } else {
        this.value = null;
      }

      this.group = group;
      this.propagateChange(this.value);
      this.propagateOnTouched();
      this.selectionChanged.emit(group);
    };
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (this.value) {
      if (this.valueField === 'id') {
        this.appBlockerService.block();
        this.adminProductGroupService
          .getGroupById(this.value)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            this.group = result;
          });
      }
      if (this.valueField === 'code') {
        const search: Search = {
          filtering: [
            {
              column: 'code',
              compareType: CompareTypeDtoEnum.EQUAL,
              value: this.value,
            },
          ],
          sorting: [],
          paging: {
            page: 1,
            pageSize: -1,
          },
        };
        this.appBlockerService.block();
        this.adminProductGroupService
          .getGroupsByFilter(search)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            if (result.data.length > 0) {
              this.group = result.data[0];
            }
          });
      }
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
