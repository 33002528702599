<td *ngIf="visible"><input [(ngModel)]="selected" type="checkbox" /></td>
<td *ngIf="visible && product">
  {{ product.description }}
</td>
<td *ngIf="visible">
  <a
    color="primary"
    mat-raised-button
    id="{{ parentId }}_002_group_products_listing_item"
    (click)="changeProduct()"
    [routerLink]=""
    class="btn -primary -xs"
    >{{ groupProduct.productMasterId }}</a
  >
</td>
