<td><input [(ngModel)]="selected" type="checkbox" /></td>
<td class="text-truncate">{{ parameter.name }}</td>
<td class="text-truncate">{{ parameter.dataType }}</td>
<td class="text-truncate">{{ parameter.defaultValue }}</td>
<td class="table__action" fxLayout="row">
  <button mat-icon-button (click)="$event.stopPropagation(); edit()" [routerLink]="">
    <mat-icon>create</mat-icon>
  </button>
  <button mat-icon-button (click)="$event.stopPropagation(); delete()" [routerLink]="">
    <mat-icon>delete</mat-icon>
  </button>
</td>
