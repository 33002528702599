import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Animations } from '@helpers/animations';
import {
  ComplexFilter,
  ControlFilterMiner,
  ControlStructFilterMiner,
  ControlStructSingletonFilterMiner,
} from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-ticket-type-filter',
  templateUrl: './ticket-type-filter.component.html',
  styleUrls: ['./ticket-type-filter.component.scss'],
  animations: [Animations.advancedFilterArrow],
})
export class TicketTypeFilterComponent extends ComplexFilter {

  filterForm = this.formBuilder.group({
    areaType: [],
    code: [],
    defaultSubject: [],
    system: [],
    parametersName: [],
    parametersValue: [],
    stepTemplateId: [],
    stepTemplateCode: [],
    stepTemplate: [],
    valid: [],
  });

  advancedFilterFields = ['parametersName', 'parametersValue', 'stepTemplate', 'valid'];

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.areaType, 'areaType'),
    new ControlFilterMiner(this.filterForm.controls.code, 'code'),
    new ControlFilterMiner(this.filterForm.controls.defaultSubject, 'defaultSubject'),
    new ControlFilterMiner(this.filterForm.controls.system, 'system'),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.parametersName, 'parameters', 'name', null),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.parametersValue, 'parameters', 'value', null),
    new ControlStructFilterMiner(this.filterForm.controls.stepTemplate, 'stepTemplate', 'code', null),
    new ControlFilterMiner(this.filterForm.controls.valid, 'valid'),
  ];

  constructor(private formBuilder: FormBuilder) {
    super();
    this.filterForm.controls.parametersName.valueChanges.subscribe(value => {
      if (value && value.length > 0) {
        this.filterForm.controls.parametersValue.enable();
      } else {
        this.filterForm.controls.parametersValue.disable();
      }
    });
  }
}
