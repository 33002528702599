<router-outlet></router-outlet>
<div class="main__header">
  <div class="header-box">
    <div class="icon-box CODEBOOK">
      <mat-icon svgIcon="codebookTypes"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.codebookTypes' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      *ngIf="aclService.pageEditAccess"
      id="001_codebook_type_listing"
      mat-raised-button
      color="primary"
      class="btn -primary -xs"
      [routerLink]=""
      [title]="'wc.admin.codebookType.new.title' | translate"
      (click)="newCodebookType()"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>
<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-codebook-type-filter
        [filtering]="search.filtering"
        (filterChanged)="handleFilterChange()"></app-codebook-type-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.codebookType.form.list' | translate }}</span>
          <div class="paginator__entries">
            {{
            'wc.common.pagination.showing'
              | translate
              : {
                from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                to:
                  search.paging.pageSize * search.paging.page > pagedCodebookTypesDto?.totalItems
                    ? pagedCodebookTypesDto?.totalItems
                    : search.paging.pageSize * search.paging.page,
                of: pagedCodebookTypesDto?.totalItems
              }
            }}
          </div>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="['name']"
          [pagedDto]="pagedCodebookTypesDto"
          (pageSizeEvent)="pageSizeChanged($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [fillIdBy]="'name'"
          [editable]="aclService.pageEditAccess"
          [selectMode]="false"
          [module]="'codebookTypes'"
          [translationPrefix]="'wc.admin.codebookType'"
          (editButtonEvent)="editCodebookType($event)"
          (editInNewTabButtonEvent)="editCodebookType($event, true)"
          (deleteButtonEvent)="deleteCodebookType($event)"
          (doubleClickEvent)="editCodebookType($event)">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
