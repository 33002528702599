<div class="card__wrapper">
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">
            {{ 'wc.admin.changes' | translate }}
          </span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="001_product_rating"
            color="primary"
            mat-raised-button
            (click)="editReview(null, true)"
            [routerLink]=""
            class="btn -primary icon -xs stroke"
            [disabled]="!this.productEditService.enableEditProduct">
            <mat-icon svgIcon="plus"></mat-icon>
            {{ 'wc.admin.new' | translate }}
          </button>
          <button
            id="007_product_rating"
            color="primary"
            mat-stroked-button
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor"
            (click)="deleteSelectedOperations()"
            [disabled]="!this.productEditService.enableEditProduct || !operationsTable?.selection.hasValue()">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <app-table-listing-form
        #operationsTable
        [columnName]="[
            'reviewedOn', 'rating', 'recommended', 'reviewSummary', 'extId', 'modified', 'modifiedBy'
          ]"
        [columnToTruncate]="['reviewSummary', 'modified', 'modifiedBy']"
        [dontShowOnMobile]="[
            'modified',
            'modifiedBy',
          ]"
        [fillIdBy]="'id'"
        [customData]="operations"
        [enablePaginator]="false"
        [disableEditInNewTabButton]="true"
        (editButtonEvent)="editReview($event)"
        (deleteButtonEvent)="deleteReview($event)"
        [editable]="true"
        [disableExpand]="true"
        [getRowClassCallback]="getRowClassCallback"
        suffix="operations"
        [translationPrefix]="'wc.admin.review'">
      </app-table-listing-form>
    </div>
  </div>


  <div class="card -full">
    <div class="card__inner">
      <app-product-ratings-filter
        (filterChanged)="handleFilterChange()"
        [filtering]="search.filtering"></app-product-ratings-filter>
    </div>
  </div>
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.reviews' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedReviews?.totalItems
                          ? pagedReviews?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedReviews?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="002_product_rating"
            color="primary"
            mat-stroked-button
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor"
            (click)="deleteSelectedReviews()"
            [disabled]="!this.productEditService.enableEditProduct || !reviewsTable?.selection.hasValue()">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          #reviewsTable
          [columnName]="['reviewedOn', 'rating', 'recommended', 'reviewSummary', 'extId', 'modified', 'modifiedBy']"
          [fillIdBy]="'id'"
          [editable]="true"
          (editButtonEvent)="editReview($event)"
          (deleteButtonEvent)="deleteReview($event)"
          [disableEditInNewTabButton]="true"
          [pagedDto]="pagedReviews"
          (doubleClickEvent)="editReview($event)"
          (sortChangeEvent)="onSortChange($event)"
          (pageSizeEvent)="pageSizeChanged($event)"
          [cacheFilter]="this.search"
          [getRowClassCallback]="getRowClassCallback"
          suffix="reviews"
          [translationPrefix]="'wc.admin.review'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
