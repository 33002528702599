<h2 mat-dialog-title>
  {{ 'wc.admin.ticket.new' | translate }}
    <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<div class="modal-body">
  <div [formGroup]="editStepForm" class="form" fxLayout="column" style="row-gap: 15px">
    <div class="form__row">
      <app-custom-input
        [parentGroup]="editStepForm"
        [controlName]="'code'"
        [inputType]="'text'"
        [label]="'name'"
        [translationPrefix]="'wc.admin.ticketType.steps'">
      </app-custom-input>
    </div>
    <div class="form__row">
      <app-custom-input
        [parentGroup]="editStepForm"
        [controlName]="'description'"
        [inputType]="'text'"
        [translationPrefix]="'wc.admin.ticketType.steps'">
      </app-custom-input>
    </div>
  </div>
</div>
<mat-dialog-actions align="end" style="margin-top: 20px">
  <button color="primary" mat-raised-button (click)="cancel()" [routerLink]="">
    {{ 'wc.common.cancel.button' | translate }}
  </button>
  <button color="primary" mat-raised-button (click)="continue()" *ngIf="aclService.pageEditAccess" [routerLink]="">
    {{ 'wc.common.save' | translate }}
  </button>
</mat-dialog-actions>
