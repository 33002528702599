import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  AbstractPageComponent,
  AclService,
  AdminDmsService,
  AdminDynamicEnumService,
  AdminTicketTypeService,
  AppBlockerService,
  CurrentLocaleService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService,
  TicketingService,
} from '@btl/btl-fe-wc-common';
import { TicketDto, TicketTypeDto } from '@btl/order-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CreateTicketModalComponent } from '@components/create-ticket-modal/create-ticket-modal.component';
import { DmsFileBulkOperationsRequestDto, PagedTicketTypesDto, TicketTypeParamDto } from '@btl/admin-bff';
import { Attachment, FormAttachmentsComponent } from '@components/form-attachments/form-attachments.component';
import { MatDialog } from '@angular/material/dialog';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import { TicketListComponent } from '@components/tickets/list/ticket-list.component';

@Component({
  selector: 'app-ticket-edit',
  templateUrl: './ticket-edit.component.html',
  styleUrls: ['./ticket-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: TicketEditComponent.PAGE_ID })
export class TicketEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'TicketEditComponent';

  pageId(): string {
    return TicketEditComponent.PAGE_ID;
  }

  private ticketSkeleton: TicketDto;
  readonly SOURCE_NAME = EnumerationsService.TICKET;
  readonly TICKET = 'Ticket';
  readonly TICKET_REF_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.TicketRefType';
  readonly TICKET_NOTE_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.TicketNoteType';
  readonly TICKET_EXTERNAL_ENTITY_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.ExternalEntityType';

  isCollapsed: boolean = false;
  stepCollapsed: boolean = false;
  noteCollaps: boolean = false;
  ticketDto: TicketDto;

  enableSteps: boolean = true;
  enableParameters: boolean = true;

  ticketBusinessState;
  ticketAssignmentStates;
  ticketNoteTypes = [];
  attachmentsTypes = [];
  parameters: Array<string> = [];

  bulkOperations: DmsFileBulkOperationsRequestDto = null;

  currentLocale;

  ticketForm: FormGroup = this.formBuilder.group({
    id: [{ value: null, disabled: true }],
    type: this.formBuilder.group({
      areaType: [{ value: null, disabled: true }],
      code: [{ value: null, disabled: true }],
    }),
    subject: [null, Validators.required],
    description: [null],
    solution: [null],
    businessState: [null, Validators.required],
    assignmentState: [null, Validators.required],
    assignmentStateFrom: [{ value: null, disabled: true }],
    assignedTo: [null],
    owner: [null],
    priority: [0, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
    extId: [null],
    created: [{ value: null, disabled: true }],
    followUpDate: [null],
    slaDueDate: [null],
    startFrom: [null],
    steps: this.formBuilder.array([]),
    parameters: this.formBuilder.array([]),
    staticParameters: this.formBuilder.array([]),
    references: [],
    attachments: [],
    notes: this.formBuilder.array([]),
  });

  addNoteForm: FormGroup = this.formBuilder.group({
    noteType: [null, Validators.required],
    note: [null, Validators.required],
  });

  createStepForm(): FormGroup {
    return this.formBuilder.group({
      config: this.formBuilder.group({
        code: [{ value: null, disabled: true }],
        description: [{ value: null, disabled: true }],
      }),
      stepSeqNo: [null],
      remark: [null],
      workStartDate: [null],
      workEndDate: [null],
      expectedDelay: [null],
      modifiedBy: [{ value: null, disabled: true }],
    });
  }

  createParameterForm(): FormGroup {
    return this.formBuilder.group({
      name: [null, Validators.required],
      value: [null],
      dataType: [{ value: null, disabled: true }],
      dataTypeDetail: [{ value: null, disabled: true }],
      staticType: [{ value: null, disabled: true }],
      integrationName: [{ value: null, disabled: true }],
      note: [{ value: null, disabled: true }],
      validationRegex: [{ value: null, disabled: true }],
      seq: [{ value: null, disabled: true }],
      hidden: [{ value: null, disabled: true }],
      localizedNames: this.formBuilder.group({}),
    });
  }

  createNoteForm(): FormGroup {
    return this.formBuilder.group({
      createdBy: [{ value: null, disabled: true }],
      created: [{ value: null, disabled: true }],
      noteType: [{ value: null, disabled: true }, Validators.required],
      note: [{ value: null, disabled: true }],
    });
  }

  get stepsFormArray(): FormArray {
    return <FormArray>this.ticketForm.get('steps');
  }

  get parametersFormArray(): FormArray {
    return <FormArray>this.ticketForm.get('parameters');
  }

  get staticParametersFormArray(): FormArray {
    return <FormArray>this.ticketForm.get('staticParameters');
  }

  paramFormAsArray(): FormArray {
    return this.ticketForm.get('parameters') as FormArray;
  }

  staticParamFormAsArray(): FormArray {
    return this.ticketForm.get('staticParameters') as FormArray;
  }

  get notesFormArray(): FormArray {
    return <FormArray>this.ticketForm.get('notes');
  }

  get stepForm() {
    return <FormGroup[]>(<FormArray>this.ticketForm.get('steps')).controls;
  }

  get parameterForm() {
    return <FormGroup[]>(<FormArray>this.ticketForm.get('parameters')).controls;
  }

  get staticParameterForm() {
    return <FormGroup[]>(<FormArray>this.ticketForm.get('staticParameters')).controls;
  }

  get noteForm() {
    return <FormGroup[]>(<FormArray>this.ticketForm.get('notes')).controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private ticketingService: TicketingService,
    protected router: Router,
    protected route: ActivatedRoute,
    private dialog: MatDialog,
    private adminTicketTypeService: AdminTicketTypeService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private stickyMessageService: StickyMessageService,
    private currentLocaleService: CurrentLocaleService,
    private adminDmsService: AdminDmsService,
    public aclService: AclService
  ) {
    super(router, route);

    this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const ticketId = this.params.id;
      const ticketTypeCode = this.params.typeCode;
      if (ticketId) {
        if (ticketId === '&') {
          if (ticketTypeCode) {
            this.getSkeletonByType(ticketTypeCode);
          } else if (!this.ticketDto) {
            this.createNewTicket();
          }
        } else {
          this.loadTicket(ticketId);
        }
      }
    } else {
      this.ticketDto = undefined;
    }
  }

  addNote() {
    if (this.addNoteForm.valid) {
      this.noteCollaps = false;
      const addNoteForm = this.createNoteForm();
      addNoteForm.patchValue({
        note: this.addNoteForm.controls['note'].value,
        noteType: this.addNoteForm.controls['noteType'].value,
      });
      this.notesFormArray.push(addNoteForm);
      this.addNoteForm.reset();
    }
  }

  removeNote(index) {
    this.notesFormArray.removeAt(index);
  }

  reloadTicket(ticket: TicketDto) {
    this.ticketDto = ticket;
    this.ticketForm.reset(this.ticketDto);
  }

  loadTicket(ticketId) {
    this.ticketingService
      .getTicketById(ticketId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(ticket => {
        this.setTicket(ticket, false);
      });
  }

  setTicket(ticket: TicketDto, isNew: boolean) {
    this.clearFormArrays();
    this.ticketDto = ticket;
    this.getBusinessStates();
    this.getAssignmentStates();
    this.getEnums();
    if (this.ticketDto.steps === null) {
      this.ticketForm.removeControl('steps');
      this.enableSteps = false;
    }
    if (ticket.parameters === null) {
      this.ticketForm.removeControl('parameters');
      this.enableParameters = false;
    }
    this.ticketForm.patchValue(this.ticketDto);
    this.loadTicketAttributes();
  }

  clearFormArrays() {
    if (this.stepsFormArray) {
      this.stepsFormArray.clear();
    }
    if (this.notesFormArray) {
      this.notesFormArray.clear();
    }
    if (this.paramFormAsArray()) {
      this.parametersFormArray.clear();
    }
    if (this.staticParamFormAsArray()) {
      this.staticParametersFormArray.clear();
    }
  }

  getEnums() {
    this.adminDynamicEnumService
      .getEnumEntries(this.SOURCE_NAME, this.TICKET_NOTE_TYPE_ENUM_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.ticketNoteTypes = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(this.SOURCE_NAME, 'com.emeldi.ecc.be.ticket.enums.TicketAttachmentType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.attachmentsTypes = result.data.map(value => value.name).sort();
      });
  }

  getBusinessStates() {
    this.ticketingService
      .getBusinessStates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(businessStates => {
        const businessTargets = businessStates.states.find(p => p.name === this.ticketDto.businessState)?.targets;
        this.ticketBusinessState = businessTargets ? businessTargets : [];
        if (this.ticketDto.businessState) {
          this.ticketBusinessState.push(this.ticketDto.businessState);
        }
      });
  }

  getAssignmentStates() {
    this.ticketingService
      .getAssignmentStates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(assignmentStates => {
        const assignmentTargets = assignmentStates.states.find(p => p.name === this.ticketDto.assignmentState)?.targets;
        this.ticketAssignmentStates = assignmentTargets ? assignmentTargets : [];
        if (this.ticketDto.assignmentState) {
          this.ticketAssignmentStates.push(this.ticketDto.assignmentState);
        }
      });
  }

  loadTicketAttributes() {
    if (this.ticketDto?.steps) {
      this.ticketDto.steps.forEach(steps => {
        const stepFrom = this.createStepForm();
        stepFrom.patchValue(steps);
        this.stepsFormArray.push(stepFrom);
      });
    }
    if (this.ticketDto?.parameters) {
      const search: Search = {
        filtering: [],
        sorting: [],
        paging: {
          page: 1,
          pageSize: 50,
        },
      };
      search.filtering = [
        {
          column: 'areaType',
          compareType: 'EQUAL',
          value: this.ticketDto.type.areaType,
        },
        {
          column: 'code',
          compareType: 'EQUAL',
          value: this.ticketDto.type.code,
        },
      ];

      this.adminTicketTypeService
        .filterTicketTypes(search, null)
        .subscribe((defaultTicketTypes: PagedTicketTypesDto) => {
          defaultTicketTypes.data[0].parameters.forEach((defaultTicketTypeParameter: TicketTypeParamDto) => {
            if (defaultTicketTypeParameter.staticType) {
              const staticParamForm = this.fillParameterFormWithDefaultParameter(defaultTicketTypeParameter);
              staticParamForm.disable();
              this.staticParametersFormArray.push(staticParamForm);
            } else {
              const parameterForm = this.fillParameterFormWithDefaultParameter(defaultTicketTypeParameter);
              this.parametersFormArray.push(parameterForm);
            }
          });
          this.paramFormAsArray().controls.sort((a, b) => (a.get('seq').value > b.get('seq').value ? 1 : -1));
        });
    }

    if (this.ticketDto?.notes) {
      this.ticketDto.notes.forEach(notes => {
        const noteForm = this.createNoteForm();
        noteForm.patchValue(notes);
        this.notesFormArray.push(noteForm);
      });
    }
  }

  fillParameterFormWithDefaultParameter(defaultTicketTypeParameter: TicketTypeParamDto) {
    const parameterForm = this.createParameterForm();
    if (defaultTicketTypeParameter.validationRegex) {
      FormUtils.setValidation(
        parameterForm.controls['value'],
        Validators.pattern(defaultTicketTypeParameter.validationRegex)
      );
    }
    parameterForm.patchValue(defaultTicketTypeParameter);

    defaultTicketTypeParameter.paramMetas.forEach(defaultTicketParamMeta => {
      if (defaultTicketParamMeta.name === ticketParamMeta.GUI_VISIBILITY && defaultTicketParamMeta.value === 'false') {
        parameterForm.controls['hidden'].setValue(true);
      }
      if (defaultTicketParamMeta.name === ticketParamMeta.GUI_SORT_ORDER) {
        parameterForm.controls['seq'].setValue(defaultTicketParamMeta.value);
      }
    });

    if (
      defaultTicketTypeParameter.localizedNames &&
      Object.keys(defaultTicketTypeParameter.localizedNames).length > 0
    ) {
      for (const localizedNamesKey in defaultTicketTypeParameter.localizedNames) {
        const localizedNamesGroup = parameterForm.controls['localizedNames'] as FormGroup;
        const controlMessage = new FormControl();
        controlMessage.patchValue(defaultTicketTypeParameter.localizedNames[localizedNamesKey]);
        localizedNamesGroup.addControl(localizedNamesKey, controlMessage);
      }
    }
    this.ticketDto.parameters.forEach(ticketDtoParameter => {
      if (ticketDtoParameter.name === defaultTicketTypeParameter.name) {
        parameterForm.patchValue(ticketDtoParameter);
        if (defaultTicketTypeParameter.dataType === 'BOOLEAN') {
          const booleanValue = ticketDtoParameter.value;
          if (booleanValue && booleanValue !== 'false') {
            parameterForm.controls['value'].patchValue(true);
          } else {
            parameterForm.controls['value'].patchValue(false);
          }
        }
      }
    });
    return parameterForm;
  }

  save() {
    if (this.saveTicket()) {
      this.appBlockerService.block();

      const savingTicketDto = { ...this.ticketDto };
      savingTicketDto.id = null;
      savingTicketDto.assignmentStateFrom = null;
      savingTicketDto.modified = null;
      savingTicketDto.created = null;
      savingTicketDto.modifiedBy = null;
      savingTicketDto.createdBy = null;
      savingTicketDto.businessStateFrom = null;
      savingTicketDto.notes.forEach(note => {
        note.createdBy = null;
        note.created = null;
      });

      const createList = [];
      const patchList = [];

      savingTicketDto.attachments.forEach((attachment: Attachment) => {
        if (attachment.dmsFile?.id) {
          if (attachment.delete) {
            patchList.push({
              id: attachment.dmsFile.id,
              delete: true,
              references: attachment.dmsFile.references,
              recordVersion: attachment.dmsFile.recordVersion,
            });
          } else {
            patchList.push({
              id: attachment.dmsFile.id,
              extId: attachment.dmsFile.extId,
              note: attachment.dmsFile.note,
              params: attachment.dmsFile.params,
              references: attachment.dmsFile.references,
              recordVersion: attachment.dmsFile.recordVersion,
            });
          }
        } else {
          createList.push(attachment.dmsFile);
        }
        delete attachment.dmsFile;
      });
      savingTicketDto.attachments = savingTicketDto.attachments.filter(attachment => !attachment['delete']);
      if (createList.length > 0 || patchList.length > 0) {
        this.bulkOperations = {
          bulkCreateList: createList,
          bulkUpdateList: [],
          bulkPatchList: patchList,
        };
      }

      if (this.ticketDto.recordVersion) {
        savingTicketDto.type = null;
        this.ticketingService
          .updateTicket(this.ticketDto.id, savingTicketDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getTicketHandler);
      } else {
        savingTicketDto.type.areaType = null;
        this.ticketingService
          .createTicket(savingTicketDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getTicketHandler);
      }
    }
  }

  saveTicket(): boolean {
    FormUtils.validateAllFormFields(this.ticketForm);
    if (this.ticketForm.valid) {
      if (this.ticketForm) {
        Object.keys(this.ticketForm.controls).forEach(field => {
          const control = this.ticketForm.get(field);
          if (field === 'parameters') {
            if (control instanceof FormArray) {
              // Set paramater value to null if empty string
              control.controls.forEach(parameter => {
                if (
                  parameter.get('value').value instanceof String &&
                  parameter.get('value').value !== null &&
                  parameter.get('value').value.trim() === ''
                ) {
                  parameter.get('value').setValue(null);
                }
              });
            }
          }
          this.ticketDto[field] = control.value;
        });
      }
      return true;
    }
    return false;
  }

  reset() {
    if (this.ticketDto && this.ticketDto.recordVersion) {
      this.loadTicket(this.ticketDto.id);
    } else {
      this.reloadTicket(this.ticketDto);
    }
  }

  cancel() {
    this.navigateSibling(TicketListComponent.PAGE_ID);
  }

  delete() {
    this.appBlockerService.block();
    this.ticketingService
      .deleteTicket(this.ticketDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        const patchList = [];
        this.ticketForm.controls.attachments.value.forEach((attachment: Attachment) => {
          if (attachment.dmsFile?.id) {
            patchList.push({
              id: attachment.dmsFile.id,
              references: attachment.dmsFile.references?.filter(dmsRef => dmsRef.entityType !== this.TICKET),
              recordVersion: attachment.dmsFile.recordVersion,
            });
          }
        });

        if (patchList.length > 0) {
          const bulkOperations: DmsFileBulkOperationsRequestDto = {
            bulkCreateList: [],
            bulkUpdateList: [],
            bulkPatchList: patchList,
          };
          this.adminDmsService.changeFiles(bulkOperations).subscribe(() => {
            this.backToListingAfterDelete();
          });
        }
        this.backToListingAfterDelete();
      });
  }

  backToListingAfterDelete() {
    this.navigateSibling(TicketListComponent.PAGE_ID);
    this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
  }

  duplicate() {
    this.ticketForm.controls.id.enable();
    this.ticketForm.controls.id.patchValue(`${this.ticketDto.id}copy`);
    this.ticketDto.recordVersion = null;
  }

  getTicketHandler = (ticket: TicketDto): void => {
    if (ticket) {
      if (this.bulkOperations) {
        this.bulkOperations.bulkPatchList.forEach(attachment => {
          this.addTicketParametersToFile(attachment, ticket);
        });
        this.bulkOperations.bulkCreateList.forEach(attachment => {
          this.addTicketParametersToFile(attachment, ticket);
        });
        this.adminDmsService.changeFiles(this.bulkOperations).subscribe(() => {
          this.bulkOperations = null;
          this.navigateToTicket(ticket);
        });
      } else {
        this.navigateToTicket(ticket);
      }
    }
  };

  private addTicketParametersToFile(attachment, ticket: TicketDto) {
    FormAttachmentsComponent.handleDmsReference(attachment, this.TICKET, ticket.id);

    ticket.references.forEach(reference => {
      const entityTypeSlit = reference.entityType.split('.');
      FormAttachmentsComponent.handleDmsReference(
        attachment,
        entityTypeSlit[entityTypeSlit.length - 1],
        reference.entityId
      );
    });
    let references = [];
    attachment.references.forEach(dmsReference => {
      if (
        ticket.references.find(
          reference => reference.entityType === dmsReference.entityType && reference.entityId === dmsReference.entityId
        )
      ) {
        references = [dmsReference];
      }
    });
    attachment.references = references;
  }

  private navigateToTicket(ticket) {
    if (ticket.id === this.ticketDto.id) {
      this.setTicket(ticket, true);
    } else {
      this.setTicket(ticket, true);
      this.navigateSelf({ id: ticket.id });
    }
    this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
  }

  public createNewTicket() {
    const modalRef = this.dialog.open(CreateTicketModalComponent);
    const createTicketModalComponent = modalRef.componentInstance;
    createTicketModalComponent.dialogRef = modalRef;
    createTicketModalComponent.createTicketEvent.subscribe((ticketTypeDto: TicketTypeDto) => {
      if (ticketTypeDto) {
        this.navigateSelf({ id: '&', typeCode: ticketTypeDto.code });
      }
    });
  }

  public getSkeletonByType(type: string) {
    this.ticketingService
      .getTicketSkeletonByTypeCode(type)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        ticketSkeleton => {
          this.ticketSkeleton = ticketSkeleton;
          this.setTicket(ticketSkeleton, true);
          this.navigateSelf({ id: '&' });
        },
        err => {
          if (err.status === 403 && err.error.failures[1]) {
            this.stickyMessageService.addStickyErrorMessage(err.error.failures[1].detail);
          }
          this.navigateSibling(TicketListComponent.PAGE_ID);
        }
      );
  }

  getLabel(parameterForm) {
    return parameterForm.controls['localizedNames'].value[this.currentLocale]
      ? parameterForm.controls['localizedNames'].value[this.currentLocale]
      : parameterForm.controls['name'].value;
  }
}

export enum ticketParamMeta {
  IS_NOT_NULL_CREATE = 'isNotNullCreate',
  IS_NOT_NULL_CLOSE = 'isNotNullClose',
  GUI_VISIBILITY = 'guiVisible',
  GUI_ELEMENT_TYPE = 'guiElementType',
  GUI_SORT_ORDER = 'guiSortOrder',
}
