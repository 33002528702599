import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CmsContentWidgetModalComponent } from './widget-modal/cms-content-widget-modal.component';
import { CmsContentsComponent } from '@components/cms/contents/cms-contents.component';
import { AdminWidgetTemplateService, AppBlockerService, Search, StickyMessageService } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  ContentMasterDto,
  ContentParameterDto,
  ContentTemplateDto,
  ContentVersionDto,
  ContentWidgetDto,
  PagedWidgetTemplatesDto,
  WidgetTemplateDto
} from '@btl/admin-bff';
import {
  CmsContentBannerModalComponent,
} from '@components/cms/contents/edit/widget/banner-modal/cms-content-banner-modal.component';
import { CmsContentEditComponent } from '@components/cms/contents/edit/cms-content-edit.component';
import { FormArray } from '@angular/forms';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { MatDialog } from '@angular/material/dialog';
import {
  CmsContentWidgetAddModalComponent,
} from '@components/cms/contents/edit/widget/widget-add-modal/cms-content-widget-add-modal.component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import {
  CmsContentBannerAddModalComponent,
} from '@components/cms/contents/edit/widget/banner-add-modal/cms-content-banner-add-modal.component';

@Component({
  selector: 'app-cms-content-widget',
  templateUrl: './cms-content-widget.component.html',
  styleUrls: ['./cms-content-widget.component.scss'],
})
export class CmsContentWidgetComponent implements OnInit, OnChanges, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild('widgetSetup') widgetSetup;
  @ViewChild('widgetsList') widgetsList;
  @ViewChild('bannersList') bannersList;

  @Input()
  contentMasterDto: ContentMasterDto;

  @Input()
  currentLocale: string;

  @Input()
  contentTemplateDto: ContentTemplateDto = null;

  @Input()
  contentParameterFormArray: FormArray;

  @Input()
  contentVersionDto: ContentVersionDto;

  @Output()
  readonly previewEvent: EventEmitter<any> = new EventEmitter();

  initialized = false;
  widgetTemplateDtoList: Array<WidgetTemplateDto> = [];
  bannerTemplateDtoList: Array<WidgetTemplateDto> = [];
  contentTemplateType: string;
  editEnabled = false;
  maxUndoSteps: number;
  historyIndex = 0;
  widgetIconMap: string[];
  private history = [];

  maxCardinality = -1;

  static initIconMap(): string[] {
    const widgetIconMap = [];
    widgetIconMap['PAGE_IMAGE'] = 'widgetPageimage';
    widgetIconMap['TITLE'] = 'widgetHeading';
    widgetIconMap['WYSIWYG'] = 'widgetWysiwyg';
    widgetIconMap['ZIG_ZAG'] = 'widgetZigzag';
    widgetIconMap['PRODUCT_LIST'] = 'widgetProductlist';
    widgetIconMap['VIDEO'] = 'widgetVideo';
    widgetIconMap['VIDEO_LIST'] = 'widgetVideolist';
    widgetIconMap['ICON_LIST'] = 'widgetIconlist';
    widgetIconMap['BOX_IMAGE'] = 'widgetBoximage';
    widgetIconMap['TIP_IMAGE'] = 'widgetTipimage';
    widgetIconMap['FULL_IMAGE'] = 'widgetFullimage';
    widgetIconMap['BANNER'] = 'widgetProductlist';
    widgetIconMap['BN_FULL_IMAGE'] = 'widgetFullimage';
    widgetIconMap['BN_HALF_IMAGE'] = 'widgetPageimage';
    widgetIconMap['BN_DETAIL_SEC'] = 'widgetPageimage';
    widgetIconMap['BN_OVERLAY'] = 'widgetFullimage';
    widgetIconMap['BUTTON'] = 'widgetButton';
    widgetIconMap['ACCORDION'] = 'widgetAccordion';
    return widgetIconMap;
  }

  constructor(
    private appBlockerService: AppBlockerService,
    private stickyMessageService: StickyMessageService,
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private adminWidgetTemplateService: AdminWidgetTemplateService
  ) {
    this.getWidgetTemplates();
    this.propertyAccessorLocalService
      .getCmsMaxUndoSteps()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: number) => {
        if (result) {
          this.maxUndoSteps = result;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit(): void {
    this.widgetIconMap = CmsContentWidgetComponent.initIconMap();
    if (this.contentVersionDto.widgets && this.contentVersionDto.widgets.length) {
      this.contentVersionDto.widgets.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
    } else {
      this.contentVersionDto.widgets = [];
    }
    this.contentTemplateType = this.contentTemplateDto.type;
    if (this.contentVersionDto.widgets.length > 0) {
      this.history.push(CmsContentsComponent.cloneDeep(this.contentVersionDto.widgets || []));
    }
    this.historyIndex = 0;
    this.initialized = true;

    const maxCardinalityParam = this.contentTemplateDto.parameters.find(param => param.name === 'maxCardinality');
    if (maxCardinalityParam) {
      this.maxCardinality = Number.parseInt(maxCardinalityParam.value);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes.contentMasterDto || changes.contentVersionDto)) {
      this.checkEditable();
    } else if (
      changes &&
      changes.contentTemplateDto &&
      changes.contentTemplateDto.currentValue.type !== this.contentTemplateType
    ) {
      this.contentTemplateType = this.contentTemplateDto.type;
      this.getWidgetTemplates();
      this.contentVersionDto.widgets = [];
      this.history = [];
      this.historyIndex = 0;

      const maxCardinalityParam = this.contentTemplateDto.parameters.find(param => param.name === 'maxCardinality');
      if (maxCardinalityParam) {
        this.maxCardinality = Number.parseInt(maxCardinalityParam.value);
      }
    }
  }

  addWidget(index: number): void {
    const modalRef = this.dialog.open(CmsContentWidgetAddModalComponent);
    const widgetAddModalComponent = modalRef.componentInstance;
    widgetAddModalComponent.widgetTemplateDtoList = this.widgetTemplateDtoList;
    widgetAddModalComponent.bannerTemplateDtoList = this.bannerTemplateDtoList;
    widgetAddModalComponent.contentTemplateType = this.contentTemplateDto.type;

    widgetAddModalComponent.selectItemEvent.subscribe((selectedItem: WidgetTemplateDto) => {
      if (selectedItem.type === 'BANNER_NEW') {
        this.editBannerContent(selectedItem, index);
      } else if (selectedItem.type === 'BANNER') {
        this.editBannerList(selectedItem, index);
      } else {
        this.editWidgetItem(selectedItem, index, true);
      }
      modalRef.close();
    });
  }

  dropWidget(event: CdkDragDrop<any>): void {
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    const maxCardinality = this.getMaxCardinality();
    if (maxCardinality > -1 && this.contentVersionDto.widgets.length >= maxCardinality) {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.widget.maxCardinality');
      return;
    }

    moveItemInArray(this.contentVersionDto.widgets, event.previousIndex, event.currentIndex);
    // move item change position in array, we must recalculate the sequence
    this.updateSequence();
    this.addHistory();
  }

  editWidgetItem(
    item: WidgetTemplateDto | ContentWidgetDto,
    itemPosition: number,
    isNewWidget: boolean,
    type?: string
  ): void {
    const modalRef = this.dialog.open(CmsContentWidgetModalComponent, { minWidth: '25vw' });
    const createDragAndDropModalComponent = modalRef.componentInstance;
    createDragAndDropModalComponent.dialogRef = modalRef;
    createDragAndDropModalComponent.currentLocale = this.currentLocale;
    createDragAndDropModalComponent.itemPosition = itemPosition;
    createDragAndDropModalComponent.totalItems = isNewWidget ? itemPosition : this.contentVersionDto.widgets.length - 1;
    createDragAndDropModalComponent.isNewWidget = isNewWidget;
    createDragAndDropModalComponent.displayControls = this.editEnabled;
    createDragAndDropModalComponent.extContent = this.contentParameterFormArray
      .getRawValue()
      .find(param => param.name === 'extContent')?.value;
    createDragAndDropModalComponent.contentWidgetDtos = CmsContentsComponent.cloneDeep(this.contentVersionDto.widgets);
    if (isNewWidget) {
      createDragAndDropModalComponent.contentWidgetDto = null;
      createDragAndDropModalComponent.widgetTemplateDto = CmsContentsComponent.cloneDeep(item);
    } else if (type === 'BANNER') {
      createDragAndDropModalComponent.contentWidgetDto = CmsContentsComponent.cloneDeep(item);
      const widgetTemplateDto = this.bannerTemplateDtoList.find(widget => widget.type === type);
      createDragAndDropModalComponent.widgetTemplateDto = CmsContentsComponent.cloneDeep(widgetTemplateDto);
    } else {
      createDragAndDropModalComponent.contentWidgetDto = CmsContentsComponent.cloneDeep(item);
      const widgetTemplateDto = this.widgetTemplateDtoList.find(
        widget => widget.type === (item as ContentWidgetDto).widgetTemplateType
      );
      createDragAndDropModalComponent.widgetTemplateDto = CmsContentsComponent.cloneDeep(widgetTemplateDto);
    }

    modalRef.componentInstance.saveWidgetEvent.subscribe((contentWidgetDto: ContentWidgetDto) => {
      const maxCardinality = this.getMaxCardinality();
      let widgetsCount = this.contentVersionDto.widgets.length;
      if (isNewWidget) {
        widgetsCount++;
      }
      if (maxCardinality > -1 && widgetsCount > maxCardinality) {
        this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.widget.maxCardinality');
      } else {
        if (isNewWidget) {
          this.contentVersionDto.widgets.splice(itemPosition, 0, contentWidgetDto);
          this.addHistory();
        } else {
          this.contentVersionDto.widgets[itemPosition] = contentWidgetDto;
        }
        this.updateSequence();
      }
      createDragAndDropModalComponent.dialogRef.close();
    });

    modalRef.componentInstance.cancelWidgetEvent.subscribe(() => {
      createDragAndDropModalComponent.dialogRef.close();
    });

    modalRef.componentInstance.deleteWidgetEvent.subscribe(() => {
      this.contentVersionDto.widgets.splice(itemPosition, 1);
      this.updateSequence();
      this.addHistory();
    });
  }

  editBannerList(item: WidgetTemplateDto | ContentWidgetDto, itemPosition: number): void {
    const modalRef = this.dialog.open(CmsContentBannerModalComponent);
    const createBannerModalComponent = modalRef.componentInstance;
    createBannerModalComponent.dialogRef = modalRef;
    createBannerModalComponent.contentTemplateType = 'BANNER';
    createBannerModalComponent.displayControls = this.editEnabled;

    modalRef.componentInstance.saveBannerEvent.subscribe((contentMasterDto: ContentMasterDto) => {
      const maxCardinality = this.getMaxCardinality();
      if (maxCardinality > -1 && this.contentVersionDto.widgets.length >= maxCardinality) {
        this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.widget.maxCardinality');
      } else {
        this.contentVersionDto.widgets[itemPosition] = {
          widgetTemplateType: 'BANNER',
          sequence: itemPosition,
          name: contentMasterDto.code,
          parameters: [
            {
              name: 'contentId',
              value: contentMasterDto.id,
              seq: 0,
            },
          ],
        };
        createBannerModalComponent.dialogRef.close();
      }
    });
  }

  editBannerContent(item: WidgetTemplateDto | ContentWidgetDto, itemPosition: number): void {
    const modalRef = this.dialog.open(CmsContentBannerAddModalComponent);

    const editContentModalComponent = <CmsContentEditComponent>modalRef.componentInstance;
    editContentModalComponent.dialogRef = modalRef;
    editContentModalComponent.isBannerContent = true;

    modalRef.componentInstance.saveBannerEvent.subscribe((contentWidgetDto: ContentWidgetDto) => {
      const maxCardinality = this.getMaxCardinality();
      if (maxCardinality > -1 && this.contentVersionDto.widgets.length >= maxCardinality) {
        this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.widget.maxCardinality');
      } else {
        contentWidgetDto.sequence = itemPosition;
        this.contentVersionDto.widgets[itemPosition] = contentWidgetDto;
        editContentModalComponent.dialogRef.close();
      }
    });
  }

  deleteWidget(itemPosition: number): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.cms.content.widget.modal.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.contentVersionDto.widgets.splice(itemPosition, 1);
      this.updateSequence();
      this.addHistory();
      confirmationDialogComponent.dialogReference.close();
    };
  }

  preview(): void {
    this.previewEvent.emit();
  }

  undo(): void {
    this.historyIndex--;
    if (this.historyIndex < 0) {
      this.historyIndex = 0;
    }
    if (!this.history[this.historyIndex]) {
      return;
    }
    this.contentVersionDto.widgets = CmsContentsComponent.cloneDeep(this.history[this.historyIndex]);
  }

  redo(): void {
    this.historyIndex++;
    if (this.historyIndex > this.maxUndoSteps - 1) {
      this.historyIndex = this.maxUndoSteps - 1;
    }
    this.contentVersionDto.widgets = CmsContentsComponent.cloneDeep(this.history[this.historyIndex]);
  }

  private addHistory(): void {
    if (this.historyIndex + 1 < this.history.length) {
      this.history.splice(this.historyIndex + 1, this.history.length - (this.historyIndex + 1));
    }
    if (!this.history.length) {
      this.history.push([]);
    }
    this.history.push(CmsContentsComponent.cloneDeep(this.contentVersionDto.widgets));
    this.historyIndex++;
    if (this.historyIndex > this.maxUndoSteps - 1) {
      this.history.shift();
      this.historyIndex--;
    }
  }

  private checkEditable(): void {
    this.editEnabled = !this.contentMasterDto.id || this.contentVersionDto.state === 'DRAFT';
  }

  private updateSequence(): void {
    let sequence = 0;
    for (const widget of this.contentVersionDto.widgets) {
      widget.sequence = sequence++;
    }
  }

  private getMaxCardinality(): number {
    const contentParameters = this.contentParameterFormArray.getRawValue() as ContentParameterDto[];
    const maxCardinalityItem = contentParameters.find(item => item.name === 'maxCardinality');
    return maxCardinalityItem ? +maxCardinalityItem.value : -1;
  }

  private getWidgetTemplates(): void {
    const widgetSearch: Search = {
      filtering: [],
      paging: { page: 1, pageSize: 50 },
      sorting: [{ column: 'type', sortOrder: 'asc' }],
    };

    this.appBlockerService.block();
    this.adminWidgetTemplateService
      .filterWidgetTemplates(widgetSearch)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PagedWidgetTemplatesDto) => {
        if (result.data && result.data.length) {
          this.widgetTemplateDtoList = result.data.filter(
            item => item.contentTemplateType === this.contentTemplateDto.type && item.type !== 'BANNER'
          );
          this.bannerTemplateDtoList = result.data.filter(
            item => item.contentTemplateType === this.contentTemplateDto.type && item.type === 'BANNER'
          );
        } else {
          this.widgetTemplateDtoList = [];
          this.bannerTemplateDtoList = [];
        }
      });
  }
}
