<h2 mat-dialog-title>
  {{ 'wc.common.choose' | translate }}
    <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content class="modal-body">
  <div [formGroup]="form" class="form" fxLayout="column" style="row-gap: 15px; overflow: hidden">
    <div class="form__row">
      <app-input-date-form-field
        [parentGroup]="form"
        [controlName]="'startDateTime'"
        id="001_account_entity_roles_modal"
        [label]="'validFrom'"
        [translationPrefix]="'wc.admin.accountEntity'">
      </app-input-date-form-field>
    </div>
    <div class="form__row">
      <app-input-date-form-field
        [parentGroup]="form"
        [controlName]="'endDateTime'"
        [label]="'validFrom'"
        id="002_account_entity_roles_modal"
        [translationPrefix]="'wc.admin.accountEntity'">
      </app-input-date-form-field>
    </div>
    <div class="form__row">
      <app-custom-input
        [id]="'003_account_entity_roles_modal'"
        [inputType]="'checkbox'"
        [parentGroup]="form"
        [controlName]="'canDelegate'"
        [label]="'wc.admin.accountEntity.canDelegate'">
      </app-custom-input>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="primary" mat-raised-button (click)="continue()" [routerLink]="" id="004_account_entity_roles_modal">
    {{ 'wc.common.ok.button' | translate }}
  </button>
  <button
    color="primary"
    mat-raised-button
    (click)="cancel()"
    routerLink="/access/account-entity/"
    id="005_account_entity_roles_modal">
    {{ 'wc.common.cancel.button' | translate }}
  </button>
</mat-dialog-actions>
