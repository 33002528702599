<td><input id="{{ parentId }}_001_product_price_listing_item" [(ngModel)]="selected" type="checkbox" /></td>
<td>{{ price.priceType }}</td>
<td>{{ price.priority }}</td>
<td>{{ price.price | price }}</td>
<td [title]="price.ruleSetExpression" class="text-truncate">{{ price.ruleSetExpression }}</td>
<td>{{ price.validFor.startDateTime | date: 'HH:mm dd.MM.yyyy' }}</td>
<td>{{ price.validFor.endDateTime | date: 'HH:mm dd.MM.yyyy' }}</td>
<td class="table__action">
  <button
    aria-expanded="false"
    aria-haspopup="true"
    class="btn -empty table__action-button"
    data-toggle="dropdown"
    id="{{ parentId }}_dropdownUserInfo"
    type="button">
    <i class="fas fa-ellipsis-v"></i>
  </button>
  <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right">
    <a id="{{ parentId }}_002_product_price_listing_item" (click)="edit()" [routerLink]="" class="dropdown-item">{{
      (aclService.pageEditAccess ? 'wc.admin.edit' : 'wc.admin.view') | translate
    }}</a>
    <a
      id="{{ parentId }}_004_product_price_listing_item"
      (click)="editInNewTab()"
      [routerLink]=""
      class="dropdown-item"
      >{{ (aclService.pageEditAccess ? 'wc.admin.editInNewTab' : 'wc.admin.viewInNewTab') | translate }}</a
    >
    <a
      id="{{ parentId }}_003_product_price_listing_item"
      (click)="delete()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="dropdown-item"
      >{{ 'wc.admin.delete' | translate }}</a
    >
  </div>
</td>
