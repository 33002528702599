import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AbstractFilterComponent, AdminContextRulesService, CompareType } from '@btl/btl-fe-wc-common';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-dynamic-context-rules-filter',
  templateUrl: './dynamic-context-rules-filter.component.html',
})
export class DynamicContextRulesFilterComponent extends AbstractFilterComponent {
  mapFieldWithCompareType = new Map([
    ['id', CompareTypeDtoEnum.LIKE],
    ['applicationType', CompareTypeDtoEnum.LIKE],
    ['implementationType', CompareTypeDtoEnum.EQUAL],
    ['expression', CompareTypeDtoEnum.LIKE],
    ['arguments', CompareTypeDtoEnum.LIKE],
  ]);

  implementationTypes;
  applicationTypes;

  constructor(private formBuilder: FormBuilder, private adminContextRuleService: AdminContextRulesService) {
    super();
    // TODO  implement asynchronous loading enumerations from DCR MS through EnumsService; then, when will be supported
    this.implementationTypes = this.adminContextRuleService.getImplementationTypes().sort();
    this.applicationTypes = this.adminContextRuleService.getApplicationTypes().sort();
    this.filterForm = this.formBuilder.group({
      id: [],
      applicationType: [],
      implementationType: [],
      expression: [],
      arguments: [],
    });
  }
}
