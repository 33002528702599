<div class="localized_text">
  <ng-container [ngSwitch]="contentType">
    <mat-form-field *ngSwitchDefault class="form__field" appearance="outline" floatLabel="always">
      <mat-label>{{ translationPrefix + locale | translate }}</mat-label>
      <input
        class="form__input"
        matInput
        type="text"
        [formControl]="parentGroup.controls[locale]"
        [id]="textTypeId + '-' + locale"
        [readonly]="disabled" />
      <mat-icon
        *ngIf="contentType !== 'PLAINTEXT_SINGLE_LINE' && !disabled"
        matSuffix
        style="cursor: pointer"
        [id]="textTypeId + '-' + locale + '-' + 'openModal'"
        (click)="openModalEdit(editorDialog)"
        >open_in_new</mat-icon
      >
    </mat-form-field>
    <mat-form-field *ngSwitchCase="'PLAINTEXT_MULTI_LINE'" class="text-area-with-open-in-new-dialog" appearance="outline" floatLabel="always">
      <mat-label>{{ translationPrefix + locale | translate }}</mat-label>
      <mat-icon matSuffix class="text-area-open-in-new-dialog" (click)="openModalEdit(editorDialog)">open_in_new</mat-icon>
      <textarea
        matTextareaAutosize
        class="form__input"
        matInput
        rows="5"
        type="text"
        [id]="textTypeId + '-' + locale"
        [formControl]="parentGroup.controls[locale]"
        [readonly]="disabled"></textarea>
    </mat-form-field>
    <div *ngSwitchCase="'HTML_FRAGMENT'">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        {{ translationPrefix + locale | translate }}
        <mat-icon matSuffix style="cursor: pointer" (click)="openModalEdit(editorDialog)">open_in_new</mat-icon>
      </div>
      <angular-editor
        *ngSwitchCase="'HTML_FRAGMENT'"
        class="form__input"
        [config]="editorConfig"
        [formControl]="parentGroup.controls[locale]"
        [disabled]="disabled"
        [id]="textTypeId + '-' + locale" />
    </div>
  </ng-container>
</div>

<ng-template #editorDialog let-c="close" let-d="dismiss">
  <div>
    <div>
      <h2 mat-dialog-title>
        {{ 'wc.common.edit' | translate }}
        <mat-icon
          class="default-icon-gray-fill no-stroke"
          svgIcon="close"
          mat-dialog-close
          matTooltip="{{ 'wc.common.close' | translate }}" />
      </h2>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="form">
      <div class="form-row">
        <ng-container [ngSwitch]="contentType">
          <mat-form-field *ngSwitchCase="'PLAINTEXT_MULTI_LINE'" appearance="outline" floatLabel="always">
            <textarea
              rows="5"
              class="form__input"
              matInput
              type="text"
              [id]="textTypeId + '-' + locale"
              [disabled]="disabled"
              [(ngModel)]="editDialogValue"></textarea>
          </mat-form-field>

          <angular-editor
            *ngSwitchCase="'HTML_FRAGMENT'"
            class="form__input"
            [config]="getModalEditorConfig()"
            [id]="textTypeId + '-' + locale"
            [(ngModel)]="editDialogValue" />

          <input *ngSwitchDefault class="form__input" type="text" [disabled]="disabled" [(ngModel)]="editDialogValue" />
        </ng-container>
      </div>
      <span *ngIf="editDialogValue && editDialogValue.length > 30000" style="color: red">{{
        translationPrefix + 'tooLong' | translate
      }}</span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="primary" mat-raised-button [routerLink]="" (click)="dialogRef.close()">
      {{ 'wc.common.cancel.button' | translate }}
    </button>
    <button [disabled]="disabled" color="primary" mat-raised-button [routerLink]="" (click)="closeEditDialog()">
      {{ 'wc.common.save' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
