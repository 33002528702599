<mat-form-field
  class="form__field"
  appearance='outline' floatLabel='always'
  [formGroup]="formGroup"
  *ngIf="templateParameterDto?.type && templateParameterDto.type !== 'BOOLEAN' && templateParameterDto.type !== 'ENUM'">
  <mat-label *ngIf="templateParameterDto?.localizedNames">
    {{ templateParameterDto.localizedNames[currentLocale] }}
    {{ local ? '[' + local + ']' : '' }}
  </mat-label>
  <mat-label *ngIf="!templateParameterDto?.localizedNames">
    {{ controlName.value }}
  </mat-label>
  <ng-container [ngSwitch]="templateParameterDto.type">
    <!-- Default types for ['STRING', URL'] -->
    <input
      *ngSwitchDefault
      class="form__input"
      matInput
      type="text"
      [id]="templateParameterDto.name"
      [formControl]="controlValue" />
    <input
      *ngSwitchCase="'NUMBER'"
      class="form__input"
      matInput
      type="number"
      [id]="templateParameterDto.name"
      [formControl]="controlValue" />
  </ng-container>
</mat-form-field>

<app-custom-input
  *ngIf="templateParameterDto?.type === 'BOOLEAN'"
  [id]="templateParameterDto.name"
  [inputType]="'checkbox'"
  [parentGroup]="formGroup"
  [controlName]="'controlValue'"
  [label]="templateParameterDto.localizedNames[currentLocale]">
</app-custom-input>

<div [formGroup]="formGroup">
  <app-input-select-form-field
    *ngIf="templateParameterDto?.type === 'ENUM'"
    [formControlName]="controlValueName ? controlValueName : 'value'"
    id="templateParameterDto.name"
    [inputType]="'enum'"
    [sourceName]="SOURCE_NAME"
    [enumName]="templateParameterDto.typeDetail"
    [showEmptyOption]="true"
    [label]="getLabel()">
  </app-input-select-form-field>
</div>

