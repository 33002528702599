import { Component } from '@angular/core';
import {
  AclService,
  AdminAclService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  Search,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { PagedPrivilegesDto, PrivilegeDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { PrivilegeEditComponent } from '@components/acl/privileges/privilege-edit/privilege-edit.component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';

import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-privileges-listing',
  templateUrl: './privileges-listing.component.html',
  styleUrls: ['./privileges-listing.component.scss'],
})
@EnableDynamicLoading({ customName: PrivilegesListingComponent.PAGE_ID })
export class PrivilegesListingComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'PrivilegesListingComponent';

  pageId(): string {
    return PrivilegesListingComponent.PAGE_ID;
  }

  pagedPrivilegesDto: PagedPrivilegesDto;

  constructor(
    private adminAclService: AdminAclService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    public aclService: AclService,
    protected confirmationDialogService: ConfirmationDialogService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  delete(privilegeDto: PrivilegeDto) {
    this.appBlockerService.block();

    const ruleSearch: Search = {
      filtering: [{ column: 'privilege.id', compareType: 'EQUAL', value: privilegeDto.id }],
      sorting: [],
      paging: {
        page: 1,
        pageSize: 1,
      },
    };

    this.adminAclService
      .filterRules(ruleSearch, null)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        if (result?.data.length > 0) {
          const confirmationDialogComponent = this.confirmationDialogService.openDialog([
            'wc.admin.privileges.deleteUsed.confirmation.text',
          ]);
          confirmationDialogComponent.confirmationHandler = dialogReference => {
            this.deleteData(privilegeDto, true);
            confirmationDialogComponent.dialogReference.close();
          };
        } else {
          this.deleteData(privilegeDto);
        }
      });
  }

  editPrivilege(privilegeDto, inNewTab: boolean = false) {
    this.navigateSibling(PrivilegeEditComponent.PAGE_ID, { id: privilegeDto.id }, inNewTab);
  }

  newPrivilege() {
    this.editPrivilege({
      id: 'newPrivilege',
    });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminAclService
        .filterPrivileges(this.search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedPrivilegesDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminAclService.deletePrivilege(rowDataId);
  }
}
