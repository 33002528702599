import { Component, Input, ViewChild } from '@angular/core';
import { Search } from '@btl/btl-fe-wc-common';
import { SocketListComponent } from '@components/product-catalogue/socket/list/socket-list.component';

@Component({
  selector: 'app-socket-select-modal',
  templateUrl: './sockets-select-modal.component.html',
  styleUrls: ['./sockets-select-modal.component.scss'],
})
export class SocketsSelectModalComponent {
  @ViewChild(SocketListComponent, { static: true })
  socketListingComponent: SocketListComponent;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  @Input()
  disableActions = false;

  @Input()
  disableTableActions = false;

  @Input()
  selectMode: false;

  @Input()
  isModal: boolean = true;

  multiSelectMode = false;

  singleSelectMode = true;

  excludeStickerIds = [];

  activeMultiSelectMode() {
    this.multiSelectMode = true;
    this.singleSelectMode = false;
  }

  selectEvent() {
    if (this.singleSelectMode) {
      const stickerDto = this.socketListingComponent.getSelectedData()[0];
      if (stickerDto) {
        this.selectHandler(stickerDto);
        this.dialogRef.close();
      }
    } else {
      const stickerDtos = this.socketListingComponent.getSelectedData();
      if (stickerDtos && stickerDtos.length > 0) {
        this.selectHandler(stickerDtos);
        this.dialogRef.close();
      }
    }
  }

  singleSelect(selectedDto) {
    this.selectHandler(selectedDto);
    this.dialogRef.close();
  }

  getSearch(): Search {
    return this.socketListingComponent.search;
  }

  removeSelection() {
    if (this.singleSelectMode) {
      this.selectHandler(null);
    } else {
      this.selectHandler([]);
    }
    this.dialogRef.close();
  }
}
