import { Component } from '@angular/core';
import {
  AbstractPageComponent,
  AclService,
  AdminDataTypeEnumService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { DataTypeEnumDto, DataTypeEnumItemDto } from '@btl/admin-bff';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { Animations } from '@helpers/animations';
import { Sort } from '@btl/btl-fe-wc-common/lib/models/search';
import { MatSort } from '@angular/material/sort';
import { DataTypeEnumComponent } from '@components/data-type-enum/data-type-enum.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTypeEnumListComponent } from '@components/data-type-enum/list/data-type-enum-list.component';
import { DataTypeEnumItemEditComponent } from '@components/data-type-enum/edit/item/data-type-enum-item-edit.component';

@Component({
  selector: 'app-data-type-enum-edit',
  templateUrl: './data-type-enum-edit.component.html',
  styleUrls: ['./data-type-enum-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: DataTypeEnumEditComponent.PAGE_ID })
export class DataTypeEnumEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'DataTypeEnumEditComponent';

  dataTypeEnum: DataTypeEnumDto = {};
  sortedDataTypeEnumItems: DataTypeEnumItemDto[];
  sorting: Sort[] = [];
  isNew = true;

  dataTypeEnumForm: FormGroup = this.formBuilder.group({
    name: [{ value: null, disabled: true }, [Validators.required, Validators.maxLength(200)]],
    recordVersion: [null],
  });

  constructor(
    private adminDataTypeEnumService: AdminDataTypeEnumService,
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  pageId(): string {
    return DataTypeEnumEditComponent.PAGE_ID;
  }

  sourceName(): string {
    return DataTypeEnumComponent.paramFromUrl('source', this.url);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const enumId = this.params.id;
      if (enumId) {
        if (enumId === '&') {
          this.setDataTypeEnum(this.dataTypeEnum, true);
        } else {
          this.loadDataTypeEnum(enumId);
        }
      }
    } else {
      this.dataTypeEnum = undefined;
    }
  }

  setDataTypeEnum(dataTypeEnum: DataTypeEnumDto, isNew: boolean) {
    this.dataTypeEnum = dataTypeEnum;
    if (!this.dataTypeEnum.items) {
      this.dataTypeEnum['items'] = [];
    }
    this.dataTypeEnumForm.patchValue(this.dataTypeEnum);
    this.sortDataTypeEnumItems();
    this.isNew = isNew;
    if (this.isNew) {
      this.dataTypeEnumForm.controls.name.enable();
    } else {
      this.dataTypeEnumForm.controls.name.disable();
    }
  }

  onSortChange(sorting: MatSort) {
    if (sorting.direction !== '') {
      this.sorting = [{ column: sorting.active, sortOrder: sorting.direction }];
    } else {
      this.sorting = [];
    }
    this.sortDataTypeEnumItems();
  }

  sortDataTypeEnumItems() {
    this.sortedDataTypeEnumItems = DataTypeEnumComponent.sortData(this.dataTypeEnum.items, this.sorting);
  }

  deleteDataTypeEnumItem(dataTypeEnumItem: DataTypeEnumItemDto) {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.dataTypeEnumItem.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminDataTypeEnumService
        .deleteEnumItem(this.sourceName(), this.dataTypeEnum.name, dataTypeEnumItem.item)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.loadDataTypeEnum(this.dataTypeEnum.name);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  editDataTypeEnumItem(dataTypeEnumItem: DataTypeEnumItemDto, inNewTab: boolean = false) {
    this.navigateSibling(
      DataTypeEnumItemEditComponent.PAGE_ID,
      {
        id: this.dataTypeEnum.name,
        item: dataTypeEnumItem.item,
      },
      inNewTab
    );
  }

  newDataTypeEnumItem() {
    this.navigateSibling(DataTypeEnumItemEditComponent.PAGE_ID, { id: this.dataTypeEnum.name, item: '&' });
  }

  private loadDataTypeEnum(enumName: string) {
    this.appBlockerService.block();

    this.adminDataTypeEnumService
      .getEnumByName(this.sourceName(), enumName)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        this.setDataTypeEnum(result, false);
      });
  }

  saveEnum(): boolean {
    FormUtils.validateAllFormFields(this.dataTypeEnumForm);
    if (this.dataTypeEnumForm.valid) {
      const dataTypeEnum = this.dataTypeEnum;
      if (dataTypeEnum) {
        Object.keys(this.dataTypeEnumForm.controls).forEach(field => {
          const control = this.dataTypeEnumForm.get(field);
          dataTypeEnum[field] = control.value;
        });
      }
      return true;
    }
    return false;
  }

  save() {
    if (this.saveEnum()) {
      this.appBlockerService.block();
      this.dataTypeEnum.created = null;
      this.dataTypeEnum.createdBy = null;
      this.dataTypeEnum.modified = null;
      this.dataTypeEnum.modifiedBy = null;
      this.dataTypeEnum.items?.forEach(item => {
        item.created = null;
        item.createdBy = null;
        item.modified = null;
        item.modifiedBy = null;
      });
      if (this.isNew) {
        this.adminDataTypeEnumService
          .createEnum(this.sourceName(), this.dataTypeEnum)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getEnumHandler);
      } else {
        this.adminDataTypeEnumService
          .updateEnum(this.sourceName(), this.dataTypeEnum.name, this.dataTypeEnum)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getEnumHandler);
      }
    }
  }

  public getEnumHandler = (dataTypeEnum: DataTypeEnumDto): void => {
    let saving = false;
    if (this.dataTypeEnum) {
      saving = true;
    }
    if (dataTypeEnum && this.dataTypeEnum) {
      if (dataTypeEnum.name === this.dataTypeEnum.name) {
        this.setDataTypeEnum(dataTypeEnum, this.isNew);
        this.navigateSelf({ id: dataTypeEnum.name });
      } else {
        this.setDataTypeEnum(dataTypeEnum, this.isNew);
        this.navigateSelf({ id: dataTypeEnum.name });
      }
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  public duplicate() {
    const newEnum = DataTypeEnumComponent.duplicateDataTypeEnum(this.dataTypeEnum, '_copy');
    this.setDataTypeEnum(newEnum, true);
    this.navigateSelf({ id: '&' });
  }

  public reset() {
    if (!this.isNew) {
      this.loadDataTypeEnum(this.dataTypeEnum.name);
    } else {
      this.dataTypeEnumForm.reset(this.dataTypeEnumForm);
      this.setDataTypeEnum(this.dataTypeEnum, this.isNew);
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.dataTypeEnum.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminDataTypeEnumService
        .deleteEnum(this.sourceName(), this.dataTypeEnum.name)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(DataTypeEnumListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  cancel() {
    this.navigateSibling(DataTypeEnumListComponent.PAGE_ID);
  }
}
