<div class="card__wrapper" [formGroup]="form">
  <!-- card -->
  <app-eligibility-expression
    [value]="product.ruleSetExpression"
    [parentGroup]="form"
    formControlName="ruleSetExpression"
    class="card -full"
    [disabled]="!this.productEditService.enableEditProduct">
  </app-eligibility-expression>
</div>
