<td colspan="8">
  <table class="table -primary table-sm">
    <thead>
      <th scope="col">
        <app-sortable-column localizationKey="wc.admin.socket.item.type"> </app-sortable-column>
      </th>
      <th scope="col">
        <app-sortable-column localizationKey="wc.admin.socket.item.code"> </app-sortable-column>
      </th>
      <th scope="col">
        <app-sortable-column localizationKey="wc.admin.socket.item.name"> </app-sortable-column>
      </th>
      <th scope="col">
        <app-sortable-column localizationKey="wc.admin.socket.item.min"> </app-sortable-column>
      </th>
      <th scope="col">
        <app-sortable-column localizationKey="wc.admin.socket.item.max"> </app-sortable-column>
      </th>
      <th scope="col">
        <app-sortable-column localizationKey="wc.admin.socket.item.def"> </app-sortable-column>
      </th>
    </thead>
    <tbody *ngIf="socket">
      <ng-container *ngFor="let socketContent of socket.contents; let i = index">
        <tr>
          <td>{{ socketContent.entityType }}</td>
          <td>{{ socketContent.entityId }}</td>
          <td>{{ socketContent.entityId }}</td>
          <td>{{ socketContent.contentQty.min }}</td>
          <td>{{ socketContent.contentQty.max }}</td>
          <td>{{ socketContent.contentQty.def }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</td>
