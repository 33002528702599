<div class="main__header">
  <h1>{{ 'wc.admin.ticket.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="001_ticket_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="002_ticket_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="003_ticket_edit" mat-menu-item (click)="duplicate()" *ngIf="ticketDto?.id" [routerLink]=""
      >
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="004_ticket_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="005_ticket_edit" mat-menu-item (click)="delete()" *ngIf="ticketDto?.id" [routerLink]=""
      >
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="006_ticket_edit" (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
  </div>
</div>

<router-outlet></router-outlet>

<div class="card__wrapper">
  <!-- card with main ticket attributes -->
  <div class="card_flex-two">
    <div class="card_flex-item flex-2">
      <div class="card -two">
        <div class="card__inner">
          <div class="card__header">
            {{ 'wc.admin.ticket.header' | translate }}
          </div>
          <div class="card__body">
            <form class="form" [formGroup]="ticketForm">
              <div class="form__groups">
                <div class="form__group -flex">
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="ticketForm"
                      [controlName]="'id'"
                      id="007_ticket_edit"
                      [inputType]="'text'"
                      [translationPrefix]="'wc.admin.ticket'">
                    </app-custom-input>
                  </div>
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="ticketForm.controls['type']"
                      [controlName]="'areaType'"
                      id="008_ticket_edit"
                      [inputType]="'text'"
                      [translationPrefix]="'wc.admin.ticket'">
                    </app-custom-input>
                  </div>
                  <div class="form__row" formGroupName="type">
                    <app-custom-input
                      [parentGroup]="ticketForm.controls['type']"
                      [controlName]="'code'"
                      id="009_ticket_edit"
                      [inputType]="'text'"
                      [translationPrefix]="'wc.admin.ticket'">
                    </app-custom-input>
                  </div>
                </div>
                <div class="form__group -full">
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="ticketForm"
                      [controlName]="'subject'"
                      id="010_ticket_edit"
                      [inputType]="'text'"
                      [translationPrefix]="'wc.admin.ticket'">
                    </app-custom-input>
                  </div>
                  <div class="form__row">
                    <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                      <mat-label>{{ 'wc.admin.ticket.description.value' | translate }}</mat-label>
                      <textarea
                        id="011_ticket_edit"
                        class="form__input"
                        matInput
                        type="text"
                        formControlName="description"></textarea>
                    </mat-form-field>
                  </div>
                  <div class="form__row">
                    <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                      <mat-label>{{ 'wc.admin.ticket.solution.value' | translate }}</mat-label>
                      <textarea
                        id="012_ticket_edit"
                        class="form__input"
                        matInput
                        type="text"
                        formControlName="solution"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- card with main steps -->
      <div class="card -two" *ngIf="enableSteps">
        <div class="card__inner">
          <div class="card__header">
            <div fxLayout="row" fxLayoutAlign="space-between">
              {{ 'wc.admin.ticket.step.header' | translate }}
              <mat-icon
                (click)="stepCollapsed = !stepCollapsed"
                color="primary"
                svgIcon="{{ stepCollapsed ? 'hide' : 'show' }}"
                style="cursor: pointer"></mat-icon>
            </div>
          </div>
          <ng-container *ngFor="let ticketFormGroup of stepForm; let i = index">
            <div [hidden]="stepCollapsed" class="card__body">
              <form
                class="form table-bordered p-3 rounded"
                style="padding: 1rem; border-radius: 0.25rem; border: 1px solid #dee2e6"
                [formGroup]="ticketFormGroup"
                id="012_ticket_edit_{{ i }}">
                <div class="form__groups">
                  <div class="form__group -full">
                    <ng-container>
                      <div class="form__row">
                        <app-custom-input
                          [parentGroup]="ticketFormGroup.controls['config']"
                          [controlName]="'code'"
                          id="013_ticket_edit_{{ i }}"
                          [inputType]="'text'"
                          [label]="'value'"
                          [translationPrefix]="'wc.admin.ticket.stepName'">
                        </app-custom-input>
                      </div>
                      <div class="form__row" formGroupName="config">
                        <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                          <mat-label>{{ 'wc.admin.ticket.stepDescription.value' | translate }}</mat-label>
                          <textarea
                            id="014_ticket_edit_{{ i }}"
                            class="form__input"
                            matInput
                            type="text"
                            formControlName="description"></textarea>
                        </mat-form-field>
                      </div>
                    </ng-container>
                    <div class="form__row">
                      <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                        <mat-label>{{ 'wc.admin.ticket.remark.value' | translate }}</mat-label>
                        <textarea
                          id="015_ticket_edit_{{ i }}"
                          class="form__input"
                          matInput
                          type="text"
                          formControlName="remark"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form__group -full" style="flex-direction: row; column-gap: 15px">
                    <div class="form__row">
                      <app-input-date-form-field
                        [parentGroup]="ticketFormGroup"
                        [controlName]="'workStartDate'"
                        [label]="'value'"
                        id="016_ticket_edit_{{ i }}"
                        [translationPrefix]="'wc.admin.ticket.step.workStartDate'">
                      </app-input-date-form-field>
                    </div>
                    <div class="form__row">
                      <app-input-date-form-field
                        [parentGroup]="ticketFormGroup"
                        [controlName]="'workEndDate'"
                        [label]="'value'"
                        id="017_ticket_edit_{{ i }}"
                        [translationPrefix]="'wc.admin.ticket.step.workEndDate'">
                      </app-input-date-form-field>
                    </div>
                    <div class="form__row">
                      <app-input-date-form-field
                        [parentGroup]="ticketFormGroup"
                        [controlName]="'expectedDelay'"
                        id="018_ticket_edit_{{ i }}"
                        [label]="'value'"
                        [translationPrefix]="'wc.admin.ticket.step.expectedDelay'">
                      </app-input-date-form-field>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="ticketFormGroup"
                        [controlName]="'modifiedBy'"
                        id="019_ticket_edit_{{ i }}"
                        [inputType]="'text'"
                        [translationPrefix]="'wc.admin.ticket.step'">
                      </app-custom-input>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="card -two" [formGroup]="ticketForm" *ngIf="ticketDto">
        <app-form-attachments
          class="card -flex"
          [typeChange]="true"
          [noteChange]="true"
          [control]="ticketForm.get('attachments')"
          [types]="attachmentsTypes"
          extIdPrefix="TICKET_DOC_"
          dmsType="TICKET_GENERAL"
          headerTranslationKey="wc.admin.ticket.attachments.header"
          formControlName="attachments">
        </app-form-attachments>
      </div>
      <!-- card with reference -->
      <div class="card -two" [formGroup]="ticketForm" *ngIf="ticketDto">
        <app-form-references
          class="card -full"
          [sourceName]="SOURCE_NAME"
          [entityTypeEnumName]="TICKET_EXTERNAL_ENTITY_TYPE_ENUM_NAME"
          [refTypeEnumName]="TICKET_REF_TYPE_ENUM_NAME"
          [collapsed]="false"
          [control]="ticketForm.get('references')"
          formControlName="references">
        </app-form-references>
      </div>
      <!-- card with notes -->
      <div class="card -two">
        <div class="card__inner">
          <div class="card__header">
            <div fxLayout="row" fxLayoutAlign="space-between">
              {{ 'wc.admin.ticket.notes.header' | translate }}
              <mat-icon
                (click)="noteCollaps = !noteCollaps"
                color="primary"
                svgIcon="{{ noteCollaps ? 'hide' : 'show' }}"
                style="cursor: pointer"></mat-icon>
            </div>
          </div>
          <ng-container *ngFor="let noteFormGroup of noteForm; let i = index">
            <div [hidden]="noteCollaps" class="card__body">
              <form class="form" [formGroup]="noteFormGroup" id="026_ticket_edit_{{ i }}">
                <div class="form__groups">
                  <div class="form__group -flex">
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="noteFormGroup"
                        [controlName]="'createdBy'"
                        id="027_ticket_edit_{{ i }}"
                        [inputType]="'text'"
                        [translationPrefix]="'wc.admin.ticket.notes'">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-input-date-form-field
                        [parentGroup]="noteFormGroup"
                        [controlName]="'created'"
                        id="028_ticket_edit_{{ i }}"
                        [translationPrefix]="'wc.admin.ticket.notes'">
                      </app-input-date-form-field>
                    </div>
                    <div class="form__row">
                      <app-input-select-form-field
                        [options]="ticketNoteTypes"
                        id="029_ticket_edit_{{ i }}"
                        [formControlName]="'noteType'"
                        [translationPrefix]="'wc.admin.ticket.notes'">
                      </app-input-select-form-field>
                    </div>
                  </div>
                  <div class="form__group -full">
                    <div class="form__row">
                      <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                        <mat-label>{{ 'wc.admin.ticket.notes.note' | translate }}</mat-label>
                        <textarea
                          id="030_ticket_edit_{{ i }}"
                          class="form__input"
                          matInput
                          type="text"
                          formControlName="note"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form__group -flex text-right justify-content-end">
                    <mat-icon id="031_ticket_edit_{{ i }}" (click)="removeNote(i)" style="cursor: pointer"
                      >delete</mat-icon
                    >
                    <label class="form__label"></label>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>
          <div>
            <div class="card__body">
              <form class="form" [formGroup]="addNoteForm">
                <div
                  fxLayout="row"
                  style="column-gap: 20px; padding: 15px; border: 1px solid #2028301a; border-radius: 5px">
                  <div style="display: flex; flex: 1 1 auto; column-gap: 30px">
                    <app-input-select-form-field
                      [options]="ticketNoteTypes"
                      [formControlName]="'noteType'"
                      id="032_ticket_edit"
                      [translationPrefix]="'wc.admin.ticket.notes'">
                    </app-input-select-form-field>

                    <app-custom-input
                      [parentGroup]="addNoteForm"
                      [controlName]="'note'"
                      [inputType]="'text'"
                      id="033_ticket_edit"
                      [translationPrefix]="'wc.admin.ticket.notes'">
                    </app-custom-input>
                  </div>
                  <div style="display: flex; align-items: center">
                    <button
                      id="034_ticket_edit"
                      class="btn -primary -xs"
                      (click)="addNote()"
                      mat-raised-button
                      color="primary">
                      {{ 'wc.admin.ticket.notes.addNote.button' | translate }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card_flex-item">
      <!-- card with main core attributes -->
      <div class="card -two">
        <div class="card__inner">
          <div class="card__header">
            {{ 'wc.admin.ticket.coreAttribute' | translate }}
          </div>
          <div class="card__body">
            <form class="form" [formGroup]="ticketForm">
              <div class="form__groups">
                <div class="form__group -full">
                  <div class="form__row">
                    <app-input-select-form-field
                      [formControlName]="'businessState'"
                      [options]="ticketBusinessState"
                      id="035_ticket_edit"
                      [translationPrefix]="'wc.admin.ticket'">
                    </app-input-select-form-field>
                  </div>
                  <div class="form__row">
                    <app-input-select-form-field
                      [formControlName]="'assignmentState'"
                      [options]="ticketAssignmentStates"
                      id="036_ticket_edit"
                      [translationPrefix]="'wc.admin.ticket'">
                    </app-input-select-form-field>
                  </div>
                  <div class="form__row">
                    <app-input-date-form-field
                      [parentGroup]="ticketForm"
                      [controlName]="'assignmentStateFrom'"
                      [label]="'value'"
                      id="037_ticket_edit"
                      [translationPrefix]="'wc.admin.ticket.assignmentStateFrom'">
                    </app-input-date-form-field>
                  </div>
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="ticketForm"
                      [controlName]="'assignedTo'"
                      [inputType]="'text'"
                      id="038_ticket_edit"
                      [label]="'value'"
                      [translationPrefix]="'wc.admin.ticket.assignedTo'">
                    </app-custom-input>
                  </div>
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="ticketForm"
                      [controlName]="'owner'"
                      id="039_ticket_edit"
                      [label]="'value'"
                      [inputType]="'text'"
                      [translationPrefix]="'wc.admin.ticket.owner'">
                    </app-custom-input>
                  </div>
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="ticketForm"
                      [controlName]="'priority'"
                      [inputType]="'number'"
                      id="040_ticket_edit"
                      [label]="'value'"
                      [translationPrefix]="'wc.admin.ticket.priority'">
                    </app-custom-input>
                  </div>
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="ticketForm"
                      [controlName]="'extId'"
                      id="041_ticket_edit"
                      [inputType]="'text'"
                      [label]="'value'"
                      [translationPrefix]="'wc.admin.ticket.extID'">
                    </app-custom-input>
                  </div>
                  <div class="form__row">
                    <app-input-date-form-field
                      [parentGroup]="ticketForm"
                      [controlName]="'created'"
                      id="042_ticket_edit"
                      [label]="'value'"
                      [translationPrefix]="'wc.admin.ticket.created'">
                    </app-input-date-form-field>
                  </div>
                  <div class="form__row">
                    <app-input-date-form-field
                      [parentGroup]="ticketForm"
                      [controlName]="'followUpDate'"
                      id="043_ticket_edit"
                      [label]="'value'"
                      [translationPrefix]="'wc.admin.ticket.followUpDate'">
                    </app-input-date-form-field>
                  </div>
                  <div class="form__row">
                    <app-input-date-form-field
                      [parentGroup]="ticketForm"
                      [controlName]="'slaDueDate'"
                      id="044_ticket_edit"
                      [label]="'value'"
                      [translationPrefix]="'wc.admin.ticket.slaDueDate'">
                    </app-input-date-form-field>
                  </div>
                  <div class="form__row">
                    <app-input-date-form-field
                      [parentGroup]="ticketForm"
                      [controlName]="'startFrom'"
                      [label]="'value'"
                      id="045_ticket_edit"
                      [translationPrefix]="'wc.admin.ticket.startFrom'">
                    </app-input-date-form-field>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- card with parameters -->
      <div class="card -two" *ngIf="parameterForm.length > 0">
        <div class="card__inner">
          <div class="card__header" style="cursor: pointer">
            {{ 'wc.admin.ticket.parameter.header' | translate }}
          </div>
          <ng-container *ngFor="let parameterFormGroup of parameterForm; let i = index">
            <div class="card__body p-0" *ngIf="!parameterFormGroup.controls['hidden'].value">
              <form [formGroup]="parameterFormGroup" class="form" id="046_ticket_edit_{{ i }}">
                <div class="card__body">
                  <div class="form__groups">
                    <div class="form__group -full">
                      <div class="form__row">
                        <ng-container [ngSwitch]="parameterFormGroup.controls['dataType'].value" *ngIf="currentLocale">
                          <app-custom-input
                            *ngSwitchCase="'BOOLEAN'"
                            [id]="'047_ticket_edit_{{ i }}'"
                            [inputType]="'checkbox'"
                            [parentGroup]="parameterFormGroup"
                            [controlName]="'value'"
                            [label]="getLabel(parameterFormGroup)">
                          </app-custom-input>

                          <app-input-date-form-field
                            [parentGroup]="parameterFormGroup"
                            *ngSwitchCase="'DATE'"
                            id="048_ticket_edit_{{ i }}"
                            [label]="getLabel(parameterFormGroup)"
                            [controlName]="'value'">
                          </app-input-date-form-field>

                          <app-custom-input
                            [parentGroup]="parameterFormGroup"
                            [controlName]="'value'"
                            *ngSwitchCase="'DECIMAL'"
                            id="049_ticket_edit_{{ i }}"
                            [inputType]="'text'"
                            [label]="getLabel(parameterFormGroup)">
                          </app-custom-input>

                          <app-custom-input
                            [parentGroup]="parameterFormGroup"
                            [controlName]="'value'"
                            *ngSwitchCase="'INTEGER'"
                            [inputType]="'number'"
                            id="050_ticket_edit_{{ i }}"
                            [label]="getLabel(parameterFormGroup)">
                          </app-custom-input>
                          <app-input-select-form-field
                            *ngSwitchCase="'CODEBOOK'"
                            [formControlName]="'value'"
                            id="051_ticket_edit_{{ i }}"
                            [inputType]="'codebook'"
                            [codebookType]="parameterFormGroup.controls['dataTypeDetail'].value"
                            [label]="getLabel(parameterFormGroup)">
                          </app-input-select-form-field>
                          <app-input-select-form-field
                            *ngSwitchCase="'ENUM'"
                            [formControlName]="'value'"
                            id="051_ticket_edit_{{ i }}"
                            [inputType]="'enum'"
                            [sourceName]="SOURCE_NAME"
                            [enumName]="parameterFormGroup.controls['dataTypeDetail'].value"
                            [showEmptyOption]="true"
                            [label]="getLabel(parameterFormGroup)">
                          </app-input-select-form-field>
                          <app-custom-input
                            [parentGroup]="parameterFormGroup"
                            [controlName]="'value'"
                            id="052_ticket_edit_{{ i }}"
                            [label]="getLabel(parameterFormGroup)"
                            *ngSwitchDefault
                            [inputType]="'text'">
                          </app-custom-input>
                        </ng-container>
                        <div *ngIf="!parameterFormGroup.valid && parameterFormGroup.touched" class="alert alert-danger">
                          <div *ngIf="parameterFormGroup.controls['value']?.errors.pattern">
                            {{
                              'wc.admin.products.attributes.productParameter.regex.error.pattern'
                                | translate: { regex: parameterFormGroup.controls['validationRegex'].value }
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- card with staic parameters -->
      <div class="card -two" *ngIf="staticParameterForm.length > 0">
        <div class="card__inner">
          <div class="card__header" style="cursor: pointer">
            {{ 'wc.admin.ticket.staticParameter.header' | translate }}
          </div>
          <ng-container *ngFor="let staticParameterFormGroup of staticParameterForm; let i = index">
            <div class="card__body p-0" *ngIf="!staticParameterFormGroup.controls['hidden'].value">
              <form [formGroup]="staticParameterFormGroup" class="form" id="053_ticket_edit_{{ i }}">
                <div class="card__body">
                  <div class="form__groups">
                    <div class="form__group -full">
                      <div class="form__row">
                        <ng-container
                          [ngSwitch]="staticParameterFormGroup.controls['dataType'].value"
                          *ngIf="currentLocale">
                          <app-custom-input
                            *ngSwitchCase="'BOOLEAN'"
                            [id]="'054_ticket_edit_{{ i }}'"
                            [inputType]="'checkbox'"
                            [parentGroup]="staticParameterFormGroup"
                            [controlName]="'value'"
                            [label]="getLabel(staticParameterFormGroup)">
                          </app-custom-input>
                          <app-input-date-form-field
                            [parentGroup]="staticParameterFormGroup"
                            *ngSwitchCase="'DATE'"
                            id="055_ticket_edit_{{ i }}"
                            [label]="getLabel(staticParameterFormGroup)"
                            [controlName]="'value'">
                          </app-input-date-form-field>
                          <app-custom-input
                            [parentGroup]="staticParameterFormGroup"
                            [controlName]="'value'"
                            *ngSwitchCase="'DECIMAL'"
                            id="056_ticket_edit_{{ i }}"
                            [inputType]="'text'"
                            [label]="getLabel(staticParameterFormGroup)">
                          </app-custom-input>
                          <app-custom-input
                            [parentGroup]="staticParameterFormGroup"
                            [controlName]="'value'"
                            *ngSwitchCase="'INTEGER'"
                            [inputType]="'number'"
                            id="057_ticket_edit_{{ i }}"
                            [label]="getLabel(staticParameterFormGroup)">
                          </app-custom-input>
                          <app-input-select-form-field
                            *ngSwitchCase="'CODEBOOK'"
                            [formControlName]="'value'"
                            id="058_ticket_edit_{{ i }}"
                            [inputType]="'codebook'"
                            [codebookType]="staticParameterFormGroup.controls['dataTypeDetail'].value"
                            [label]="getLabel(staticParameterFormGroup)">
                          </app-input-select-form-field>
                          <app-custom-input
                            [parentGroup]="staticParameterFormGroup"
                            [controlName]="'value'"
                            id="059_ticket_edit_{{ i }}"
                            [label]="getLabel(staticParameterFormGroup)"
                            *ngSwitchDefault
                            [inputType]="'text'">
                          </app-custom-input>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
