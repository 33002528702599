<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'releaseCode'"
            [inputType]="'text'"
            id="001_releases_filter"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.releases'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-date-form-field
            [parentGroup]="filterForm"
            [controlName]="'releaseDateFrom'"
            [label]="'releaseDateFrom'"
            id="002_releases_filter"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.releases'">
          </app-input-date-form-field>
          <app-input-date-form-field
            [parentGroup]="filterForm"
            [controlName]="'releaseDateTo'"
            [label]="'releaseDateTo'"
            id="003_releases_filter"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.releases'">
          </app-input-date-form-field>
          <app-input-select-form-field
            (selectionChangeEmit)="clearField('code')"
            [formControlName]="'memberType'"
            id="004_releases_filter"
            [options]="memberTypes"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.releases'">
          </app-input-select-form-field>
          <ng-container id="entityId" [ngSwitch]="filterForm.controls['memberType'].value">
            <app-input-select-form-field
              *ngSwitchCase="'CatTech'"
              [formControlName]="'code'"
              [options]="techCategories"
              [showEmptyOption]="true"
              id="005_releases_filter"
              [label]="'entityId'"
              (keydown.enter)="handleChange()"
              [translationPrefix]="'wc.admin.releases'">
            </app-input-select-form-field>
            <app-input-picker-form-field
              *ngSwitchCase="'ProdCode'"
              [parentGroup]="filterForm"
              [controlName]="'code'"
              id="006_releases_filter"
              [valueField]="'productCode'"
              [pickerType]="PickerInputType.PRODUCT"
              [selectMode]="false"
              [label]="'entityId'"
              (keydown.enter)="handleChange()"
              [translationPrefix]="'wc.admin.releases'">
            </app-input-picker-form-field>
            <app-input-picker-form-field
              *ngSwitchCase="'ProdGroup'"
              [parentGroup]="filterForm"
              id="007_releases_filter"
              [controlName]="'code'"
              [valueField]="'code'"
              [pickerType]="PickerInputType.GROUP"
              [selectMode]="false"
              [label]="'entityId'"
              (keydown.enter)="handleChange()"
              [translationPrefix]="'wc.admin.releases'">
            </app-input-picker-form-field>
            <app-input-picker-form-field
              *ngSwitchCase="'Sticker'"
              [parentGroup]="filterForm"
              id="008_releases_filter"
              [controlName]="'code'"
              [valueField]="'code'"
              [pickerType]="PickerInputType.STICKER"
              [selectMode]="false"
              [label]="'entityId'"
              (keydown.enter)="handleChange()"
              [translationPrefix]="'wc.admin.releases'">
            </app-input-picker-form-field>
            <app-input-picker-form-field
              *ngSwitchCase="'Promotion'"
              [parentGroup]="filterForm"
              id="009_releases_filter"
              [controlName]="'code'"
              [valueField]="'code'"
              [pickerType]="PickerInputType.PROMOTION"
              [selectMode]="false"
              [label]="'entityId'"
              (keydown.enter)="handleChange()"
              [translationPrefix]="'wc.admin.releases'">
            </app-input-picker-form-field>
            <app-input-picker-form-field
              *ngSwitchCase="'AclRule'"
              [parentGroup]="filterForm"
              id="010_releases_filter"
              [controlName]="'code'"
              [valueField]="'id'"
              [pickerType]="PickerInputType.RULE"
              [selectMode]="false"
              [label]="'entityId'"
              (keydown.enter)="handleChange()"
              [translationPrefix]="'wc.admin.releases'">
            </app-input-picker-form-field>
            <app-custom-input
              [controlName]="'code'"
              *ngSwitchDefault
              id="011_releases_filter"
              [inputType]="'text'"
              [label]="'entityId'"
              (keydown.enter)="handleChange()"
              [translationPrefix]="'wc.admin.releases'"
              [parentGroup]="filterForm">
            </app-custom-input>
          </ng-container>

          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
