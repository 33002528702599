import { Component } from '@angular/core';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tickets',
  template: '<router-outlet></router-outlet>',
})
@EnableDynamicLoading({ customName: TicketsComponent.PAGE_ID })
export class TicketsComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'TicketsComponent';

  pageId(): string {
    return TicketsComponent.PAGE_ID;
  }

  constructor(protected router: Router, protected route: ActivatedRoute) {
    super(router, route);
  }
}
