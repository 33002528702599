<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'code'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.notification.type'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'remark'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.notification.type'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            [formControlName]="'productionEnabled'"
            [options]="enabledTypes"
            [labelOption]="'labelKey'"
            [valueOption]="'value'"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.notification.type'">
          </app-input-select-form-field>
          <app-input-select-form-field
            [formControlName]="'sendingEnabled'"
            [options]="enabledTypes"
            [labelOption]="'labelKey'"
            [valueOption]="'value'"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.notification.type'">
          </app-input-select-form-field>
          <app-input-select-form-field
            [formControlName]="'gatewayCode'"
            [options]="gatewayCodeTypes"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.notification.type'">
          </app-input-select-form-field>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-custom-input
          [controlName]="'parametersName'"
          [inputType]="'text'"
          [translationPrefix]="'wc.admin.notification.type'"
          [parentGroup]="filterForm">
        </app-custom-input>

        <app-custom-input
          [controlName]="'parametersValue'"
          [inputType]="'text'"
          [translationPrefix]="'wc.admin.notification.type'"
          [parentGroup]="filterForm">
        </app-custom-input>

        <app-input-select-form-field
          [formControlName]="'locale'"
          [options]="locales"
          [showEmptyOption]="true"
          [translationPrefix]="'wc.admin.notification.type'">
        </app-input-select-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</form>
