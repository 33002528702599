import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminDataTypeEnumService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { DataTypeEnumItemDto } from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { Animations } from '@helpers/animations';
import { DataTypeEnumComponent } from '@components/data-type-enum/data-type-enum.component';
import { DataTypeEnumEditComponent } from '@components/data-type-enum/edit/data-type-enum-edit.component';

@Component({
  selector: 'app-data-type-enum-item-edit',
  templateUrl: './data-type-enum-item-edit.component.html',
  styleUrls: ['./data-type-enum-item-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: DataTypeEnumItemEditComponent.PAGE_ID })
export class DataTypeEnumItemEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'DataTypeEnumItemEditComponent';

  routerOutlet: any;

  private dataTypeEnumItem: DataTypeEnumItemDto = {
    parameters: [],
  };

  private dataTypeEnumItemId: string;
  private enumId: string;
  isNew = true;

  locales = environment.localization.locales;

  dataTypeEnumForm: FormGroup = this.formBuilder.group({
    name: [{ value: null, disabled: true }, [Validators.required]],
  });

  dataTypeEnumItemForm: FormGroup = this.formBuilder.group({
    item: [{ value: null, disabled: true }, [Validators.required, Validators.maxLength(200)]],
    seq: [0, [Validators.required, Validators.min(-1), Validators.max(9999)]],
    recordVersion: [null],
    parameters: [],
    localizedTexts: [],
  });

  get itemControl(): AbstractControl {
    return this.dataTypeEnumItemForm.controls.item;
  }

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private adminDataTypeEnumService: AdminDataTypeEnumService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  pageId(): string {
    return DataTypeEnumItemEditComponent.PAGE_ID;
  }

  sourceName(): string {
    return DataTypeEnumComponent.paramFromUrl('source', this.url);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      this.enumId = this.params.id;
      this.dataTypeEnumForm.controls.name.patchValue(this.enumId);
      const enumItem = this.params.item;
      if (this.enumId && enumItem) {
        if (enumItem === '&') {
          this.setDataTypeEnumItem(this.dataTypeEnumItem, true);
        } else {
          this.loadDataTypeEnumItem(this.enumId, enumItem);
        }
      }
    } else {
      this.dataTypeEnumItem = undefined;
    }
  }

  loadDataTypeEnumItem(enumId: string, enumItem: string) {
    if (enumId && enumItem) {
      this.adminDataTypeEnumService
        .getEnumItemByNameAndItem(this.sourceName(), enumId, enumItem)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.setDataTypeEnumItem(result, false);
          this.dataTypeEnumItemId = this.dataTypeEnumItem.item;
        });
    }
  }

  setDataTypeEnumItem(dataTypeEnumItem: DataTypeEnumItemDto, isNew: boolean) {
    this.dataTypeEnumItem = dataTypeEnumItem;
    if (!this.dataTypeEnumItem.localizedTexts) {
      this.dataTypeEnumItem.localizedTexts = [];
    }
    this.dataTypeEnumItemForm.patchValue(this.dataTypeEnumItem);
    this.isNew = isNew;
    if (this.isNew) {
      this.dataTypeEnumItemForm.controls.item.enable();
    } else {
      this.dataTypeEnumItemForm.controls.item.disable();
    }
  }

  saveEnumItem(): boolean {
    FormUtils.validateAllFormFields(this.dataTypeEnumItemForm);
    if (this.dataTypeEnumItemForm.valid) {
      const dataTypeEnumItem = this.dataTypeEnumItem;
      if (dataTypeEnumItem) {
        Object.keys(this.dataTypeEnumItemForm.controls).forEach(field => {
          const control = this.dataTypeEnumItemForm.get(field);
          dataTypeEnumItem[field] = control.value;
        });
      }
      return true;
    }
    return false;
  }

  save() {
    if (this.saveEnumItem()) {
      this.appBlockerService.block();
      this.dataTypeEnumItem.created = null;
      this.dataTypeEnumItem.createdBy = null;
      this.dataTypeEnumItem.modified = null;
      this.dataTypeEnumItem.modifiedBy = null;
      if (!(this.dataTypeEnumItemId === null || this.dataTypeEnumItemId === undefined)) {
        this.adminDataTypeEnumService
          .updateEnumItem(this.sourceName(), this.enumId, this.dataTypeEnumItem.item, this.dataTypeEnumItem)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getEnumItemHandler);
      } else {
        this.adminDataTypeEnumService
          .createEnumItem(this.sourceName(), this.enumId, this.dataTypeEnumItem)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getEnumItemHandler);
      }
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditDataTypeEnumItem(): DataTypeEnumItemDto {
    return this.dataTypeEnumItem;
  }

  public reset() {
    if (this.dataTypeEnumItem && this.dataTypeEnumItem.item) {
      this.loadDataTypeEnumItem(this.enumId, this.dataTypeEnumItem.item);
    } else {
      this.dataTypeEnumItemForm.reset(this.dataTypeEnumItemForm);
      this.setDataTypeEnumItem(this.dataTypeEnumItem, this.isNew);
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.dataTypeEnumItem.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminDataTypeEnumService
        .deleteEnumItem(this.sourceName(), this.enumId, this.dataTypeEnumItem.item)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(DataTypeEnumEditComponent.PAGE_ID, {id: this.enumId});
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  public duplicate() {
    const newDataTypeEnumItem = DataTypeEnumComponent.duplicateDataTypeEnumItem(this.dataTypeEnumItem, '_copy');
    this.setDataTypeEnumItem(newDataTypeEnumItem, true);
    this.dataTypeEnumItemId = null;
    this.navigateSelf({ id: this.enumId, item: '&' });
  }

  public getEnumItemHandler = (dataTypeEnumItem: DataTypeEnumItemDto): void => {
    let saving = false;
    if (this.dataTypeEnumItem) {
      saving = true;
    }
    if (dataTypeEnumItem && this.dataTypeEnumItem) {
      if (dataTypeEnumItem.item === this.dataTypeEnumItem.item) {
        this.setDataTypeEnumItem(dataTypeEnumItem, this.isNew);
        this.navigateSelf({ id: this.enumId, item: dataTypeEnumItem.item });
      } else {
        this.setDataTypeEnumItem(dataTypeEnumItem, this.isNew);
        this.dataTypeEnumItemId = dataTypeEnumItem.item;
        this.navigateSelf({ id: this.enumId, item: dataTypeEnumItem.item });
      }
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  cancel() {
    this.navigateSibling(DataTypeEnumEditComponent.PAGE_ID, { id: this.enumId });
  }
}
