<div *ngIf="textTypes" [formGroup]="form">
  <div *ngFor="let textType of textTypes">
    <div class="card__inner">
      <div class="card__header" style="text-align: left">
        {{ textTypeTranslationPrefix + '.texts.type.' + textType.id | translate }}
      </div>
      <div class="card__body">
        <div formGroupName="{{ textType.id }}" fxLayout="row" style="flex: 1 1 auto; column-gap: 20px">
          <div
            *ngFor="let locale of locales"
            [locale]="locale"
            [parentGroup]="parentGroup(textType)"
            [textTypeId]="textType.id"
            [contentType]="textType.contentType"
            [disabled]="disabled"
            uploadUrl="/api/bff/admin/v1/files/content"
            form-text
            class="form__row"
            style="flex: 1"></div>
        </div>
      </div>
    </div>
  </div>
</div>
