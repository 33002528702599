<div class="page_wrapper" [ngStyle]="{ 'margin-right': nodeOpened ? '300px' : '0px' }">
  <div class="main__header">
    <div class="product_edit-header">
      <div class="product_edit-label" id="001_promotion_edit">
        {{ promotionDto.name }}
      </div>
      <div class="product_edit-status">
        <a
          id="023_promotion_edit"
          *ngIf="showDesign"
          color="primary"
          mat-stroked-button
          (click)="addRootProduct()"
          [routerLink]=""
          >{{ 'wc.admin.promotion.addRootProduct' | translate }}</a
        >
      </div>
    </div>
    <div class="page__menu">
      <ng-container *ngIf="!showDesign; else showDesignButton">
        <a
          id="002_promotion_edit"
          color="primary"
          mat-raised-button
          (click)="save()"
          *ngIf="aclService.pageEditAccess"
          [routerLink]=""
          class="btn -primary -xs icon stroke currentColor"
          ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
        >
        <a
          color="primary"
          mat-stroked-button
          *ngIf="aclService.pageEditAccess"
          [routerLink]=""
          class="btn -primary -xs more-actions icon dropDownIcon currentColor"
          [matMenuTriggerFor]="menu"
          #t="matMenuTrigger"
          >{{ 'wc.common.more.actions.button' | translate }}
          <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
        </a>
        <mat-menu #menu="matMenu" class="module-actions">
          <a (click)="duplicate(duplicateDialog)" [routerLink]="" id="006_promotion_edit" mat-menu-item
            ><mat-icon svgIcon="duplicate"></mat-icon>{{ 'wc.common.duplicate' | translate }}</a
          >
          <a id="003_promotion_edit" mat-menu-item (click)="reset()" [routerLink]=""
            ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
          >
          <a id="015_promotion_edit" mat-menu-item (click)="delete()" *ngIf="!isNewPromotion()" [routerLink]=""
            ><mat-icon svgIcon="delete"></mat-icon>{{ 'wc.common.delete' | translate }}</a
          >
          <a mat-menu-item (click)="releases()" [routerLink]="" *ngIf="promotionDto && promotionDto.code"
            ><mat-icon svgIcon="upload"></mat-icon>{{ 'wc.admin.releases' | translate }}</a
          >
        </mat-menu>
        <a id="004_promotion_edit" (click)="cancel()" class="btn cancel-button">{{
          'wc.common.cancel.button' | translate
        }}</a>
      </ng-container>
      <ng-template #showDesignButton>
        <a
          id="016_promotion_edit"
          color="primary"
          mat-raised-button
          (click)="saveDesign()"
          [routerLink]=""
          class="btn -primary -xs"
          >{{ 'wc.admin.promotion.view.save' | translate }}</a
        >
        <a
          id="017_promotion_edit"
          color="primary"
          mat-raised-button
          (click)="design()"
          [routerLink]=""
          class="btn -primary -xs"
          >{{ 'wc.admin.promotion.backToEdit' | translate }}</a
        >
      </ng-template>
    </div>
  </div>

  <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  <div *ngIf="showDesign && promotionDto.id && sockets">
    <div>
      <app-promotion-design
        (selectedNodeEvent)="$event === true ? (nodeOpened = true) : (nodeOpened = false)"
        [promotionDto]="promotionDto"
        [sockets]="sockets">
      </app-promotion-design>
    </div>
  </div>

  <div *ngIf="!showDesign" class="card__wrapper">
    <!-- card with main promotion attributes -->
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.promotions.attributes' | translate }}
        </div>
        <div class="card__body">
          <form *ngIf="getEditPromotion()" [formGroup]="promotionForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="promotionForm"
                    [controlName]="'code'"
                    [inputType]="'text'"
                    id="011_promotion_edit"
                    [translationPrefix]="'wc.admin.promotion'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="promotionForm"
                    [controlName]="'name'"
                    [inputType]="'text'"
                    id="012_promotion_edit"
                    [translationPrefix]="'wc.admin.promotion'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <a
                    id="018_promotion_edit"
                    (click)="design()"
                    [routerLink]=""
                    mat-raised-button
                    color="primary"
                    class="btn -primary -sm"
                    [disabled]="!promotionDto.id"
                    style="height: 100%; display: inline-flex; justify-content: center; align-items: center">
                    {{ 'wc.admin.promotion.design' | translate }}
                  </a>
                </div>
                <ng-container formGroupName="validFor">
                  <div class="form__row">
                    <app-input-date-form-field
                      [parentGroup]="promotionForm.controls['validFor']"
                      [controlName]="'startDateTime'"
                      [label]="'validFrom'"
                      id="013_promotion_edit"
                      [translationPrefix]="'wc.admin.promotion'">
                    </app-input-date-form-field>
                  </div>
                  <div class="form__row">
                    <app-input-date-form-field
                      [parentGroup]="promotionForm.controls['validFor']"
                      [controlName]="'endDateTime'"
                      [label]="'validTo'"
                      id="014_promotion_edit"
                      [translationPrefix]="'wc.admin.promotion'">
                    </app-input-date-form-field>
                  </div>
                  <div class="form__row">
                  </div>
                </ng-container>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.promotions.socket.listing' | translate }}
        </div>
        <div class="card__body" [formGroup]="filterForm">
          <form [formGroup]="filterForm" class="form">
            <div class="form__row">
              <div class="input__group">
                <app-custom-input
                  [controlName]="'entityId'"
                  [inputType]="'text'"
                  id="019_promotion_edit"
                  (keydown.enter)="search()"
                  [translationPrefix]="'wc.admin.products.attributes'"
                  [parentGroup]="filterForm">
                </app-custom-input>
                <div class="form__field-actions advanced">
                  <a
                    color="primary"
                    mat-raised-button
                    (click)="search()"
                    id="020_promotion_edit"
                    class="btn -primary -xs"
                    [routerLink]=""
                    >{{ 'wc.common.search.button' | translate }}</a
                  >
                  <a
                    color="primary"
                    mat-raised-button
                    (click)="expandAll()"
                    [routerLink]=""
                    id="021_promotion_edit"
                    class="btn -primary -xs"
                    >{{ 'wc.common.expandAll' | translate }}</a
                  >
                  <a
                    color="primary"
                    mat-raised-button
                    (click)="collapseAll()"
                    [routerLink]=""
                    id="022_promotion_edit"
                    class="btn -primary -xs"
                    >{{ 'wc.common.collapseAll' | translate }}</a
                  >
                </div>
              </div>
            </div>
          </form>

          <app-table-listing-form
            *ngIf="sockets"
            [module]="'promotionSockets'"
            [parentColumnName]="['code', 'productCardinalities', 'name', 'socketCategory', 'cardinalities', 'contents.length']"
            [childColumnName]="[
              'entityType',
              'entityId',
              'cardinalities',
              'validFor.startDateTime',
              'validFor.endDateTime'
            ]"
            [fillIdBy]="'id'"
            [getChildDataCallback]="getChildDataCallback"
            [nestedTable]="true"
            [disabledParentClickEvent]="true"
            [customData]="sockets"
            [enablePaginator]="false"
            [selectMode]="false"
            [disableActions]="true"
            [multiExpandable]="true"
            [translationPrefix]="'wc.admin.promotion.socket'">
          </app-table-listing-form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #duplicateDialog let-c="close" let-d="dismiss">
  <div>
    <div>
      <h2 mat-dialog-title>
        {{ 'wc.admin.promotion.duplicate.options' | translate }}
        <mat-icon
          class="default-icon-gray-fill no-stroke"
          svgIcon="close"
          mat-dialog-close
          matTooltip="{{ 'wc.common.close' | translate }}">
        </mat-icon>
      </h2>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div [formGroup]="duplicateDialogForm" class="form">
      <div class="form-row">
        <div class="input">
          <div class="radio">
            <div>
              <input [value]="1" formControlName="duplicateOption" id="duplicateOption1" type="radio" />
              <label for="duplicateOption1"> {{ 'wc.admin.promotion.duplicatePromotion.label' | translate }}</label>
            </div>
            <div>
              <input [value]="2" formControlName="duplicateOption" id="duplicateOption2" type="radio" />
              <label for="duplicateOption2"> {{ 'wc.admin.promotion.duplicatePromotionTree.label' | translate }}</label>
            </div>
            <div>
              <input [value]="3" formControlName="duplicateOption" id="duplicateOption3" type="radio" />
              <label for="duplicateOption3">
                {{ 'wc.admin.promotion.duplicatePromotionTreeWithSockets.label' | translate }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="primary" mat-dialog-close mat-raised-button>{{ 'wc.common.cancel.button' | translate }}</button>
    <button (click)="duplicateByOption()" cdkFocusInitial color="primary" mat-dialog-close mat-raised-button>
      {{ 'wc.common.duplicate.button' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
