<div class="main__header">
  <h1>{{ 'wc.admin.localizedTexts.form.editLocales' | translate }}</h1>
  <div class="page__menu">
    <a
      id="001_localized_text_edit_locales"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="002_localized_text_edit_locales"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="003_localized_text_edit_locales" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
    </mat-menu>
    <a
      id="004_localized_text_edit_locales"
      (click)="cancel()"
      *ngIf="aclService.pageEditAccess"
      class="btn cancel-button"
      >{{ 'wc.common.cancel.button' | translate }}</a
    >
  </div>
</div>

<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.localizedTexts.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="getEditLocalizedTexts()" [formGroup]="localizedTextForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'module'"
                  id="005_localized_text_edit_locales"
                  [options]="modules"
                  [disableOptionsTranslate]="true"
                  [translationPrefix]="'wc.admin.localizedText'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="localizedTextForm"
                  [controlName]="'key'"
                  id="006_localized_text_edit_locales"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.localizedText'">
                </app-custom-input>
              </div>
              <div class="form__row"></div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div *ngFor="let localeFormGroup of localeForms; let i = index">
      <div class="card__inner">
        <form
          *ngIf="getEditLocalizedTexts()"
          [formGroup]="localeFormGroup"
          class="form"
          id="007_localized_text_edit_locales_{{ i }}">
          <div class="card__header">
            {{ 'wc.admin.locale.' + localeFormGroup.controls.locale.value | translate }}
          </div>
          <div class="card__body">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="localeFormGroup"
                    [controlName]="'locale'"
                    [inputType]="'text'"
                    id="008_localized_text_edit_locales_{{ i }}"
                    [translationPrefix]="'wc.admin.localizedText'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                    <mat-label>{{ 'wc.admin.localizedText.defaultValue' | translate }}</mat-label>
                    <textarea
                      id="009_localized_text_edit_locales_{{ i }}"
                      matTextareaAutosize
                      class="form__input"
                      matInput
                      disabled
                      type="text"
                      formControlName="defaultValue"></textarea>
                  </mat-form-field>
                </div>
                <div class="form__row">
                  <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                    <mat-label>{{ 'wc.admin.localizedText.customValue' | translate }}</mat-label>
                    <textarea
                      id="010_localized_text_edit_locales_{{ i }}"
                      matTextareaAutosize
                      class="form__input"
                      matInput
                      type="text"
                      formControlName="customValue"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
