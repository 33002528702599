<!--<td scope="row"><input [(ngModel)]="selected" type="checkbox"/></td>-->
<td>{{ review.reviewedOn | date: 'HH:mm dd.MM.yyyy' }}</td>
<td>{{ review.rating }}</td>
<td>
  <input id="{{ parentId }}_001_product_ratings_item" [checked]="review.recommended" type="checkbox" readonly="true" />
</td>
<td>{{ review.reviewSummary }}</td>
<td>{{ review.extId }}</td>
<td *ngIf="showActions && !singleSelectMode && !multiSelectMode" class="table__action">
  <button
    aria-expanded="false"
    aria-haspopup="true"
    class="btn -empty table__action-button"
    data-toggle="dropdown"
    id="{{ parentId }}_dropdownUserInfo"
    type="button">
    <i class="fas fa-ellipsis-v"></i>
  </button>
  <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right">
    <a id="{{ parentId }}_002_product_ratings_item" (click)="edit()" [routerLink]="" class="dropdown-item">{{
      'wc.admin.edit' | translate
    }}</a>
    <a id="{{ parentId }}_004_product_ratings_item" (click)="editInNewTab()" [routerLink]="" class="dropdown-item">{{
      'wc.admin.editInNewTab' | translate
    }}</a>
    <a id="{{ parentId }}_003_product_ratings_item" (click)="delete()" [routerLink]="" class="dropdown-item">{{
      'wc.admin.delete' | translate
    }}</a>
  </div>
</td>
