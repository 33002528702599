<h2 mat-dialog-title>
  {{ 'wc.admin.accountEntity.createDelegation' | translate }}
  <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content class="modal-body">
  <div class=".mat-drawer-container">
    <div class="mat-sidenav-content">
      <div class="main_content" [formGroup]="delegateForm">
        <div class="card__wrapper">
          <div class="card -full">
            <div class="card__inner">
              <div class="card__header">
                {{ 'wc.admin.accountEntity.delegatedFrom' | translate }}
              </div>
              <div class="card__body">
                <div class="form__groups">
                  <div class="form__group -flex">
                    <div class="form__row">
                      <app-custom-input
                        [id]="'001_account_entity_modal'"
                        [disabled]="true"
                        [label]="'wc.admin.accountEntity.login'"
                        [simplifiedInput]="true"
                        [enteredValue]="selectedAccountEntityRoleDto?.account?.login">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [id]="'002_account_entity_modal'"
                        [disabled]="true"
                        [label]="'wc.admin.accountEntity.delegated'"
                        [simplifiedInput]="true"
                        [enteredValue]="selectedAccountEntityRoleDto?.created | date: 'dd.MM.yyyy HH:mm'">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [id]="'003_account_entity_modal'"
                        [disabled]="true"
                        [label]="'wc.admin.accountEntity.entityType'"
                        [simplifiedInput]="true"
                        [enteredValue]="selectedAccountEntityRoleDto?.entityType">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [id]="'004_account_entity_modal'"
                        [disabled]="true"
                        [label]="'wc.admin.accountEntity.entityId'"
                        [simplifiedInput]="true"
                        [enteredValue]="selectedAccountEntityRoleDto?.entityId">
                      </app-custom-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card__wrapper">
          <div class="card -full">
            <div class="card__inner">
              <div class="card__header">
                {{ 'wc.admin.accountEntity.account' | translate }}
              </div>
              <div class="card__body">
                <div class="form__groups">
                  <div class="form__group -flex">
                    <div class="form__row">
                      <app-input-picker-form-field
                        [parentGroup]="delegateForm.controls['account']"
                        [controlName]="'accountId'"
                        [labelField]="'login'"
                        id="005_account_entity_modal"
                        [valueField]="'id'"
                        [pickerType]="PickerInputType.ACCOUNT"
                        (selectionChanged)="accountChanged($event)"
                        (customEvent)="setAccount($event)"
                        [selectMode]="false"
                        [label]="'accountId'"
                        [translationPrefix]="'wc.admin.accountEntity'">
                      </app-input-picker-form-field>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [id]="'006_account_entity_modal'"
                        [disabled]="true"
                        [label]="'wc.admin.account.lastName'"
                        [simplifiedInput]="true"
                        [enteredValue]="getAccountParamByName('lastName')">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [id]="'007_account_entity_modal'"
                        [disabled]="true"
                        [label]="'wc.admin.account.employeeId'"
                        [simplifiedInput]="true"
                        [enteredValue]="getAccountParamByName('employeeId')">
                      </app-custom-input>
                    </div>

                    <div class="form__row">
                      <app-custom-input
                        [id]="'008_account_entity_modal'"
                        [disabled]="true"
                        [label]="'wc.admin.account.state'"
                        [simplifiedInput]="true"
                        [enteredValue]="account?.state">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <mat-checkbox id="009_account_entity_modal" [disabled]="true" [checked]="account?.external">{{
                        'wc.admin.account.external' | translate
                        }}</mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card__wrapper">
          <div class="card -full">
            <div class="card__inner">
              <div class="card__header">
                {{ 'wc.admin.accountEntity.entity' | translate }}
              </div>
              <div class="card__body">
                <div class="form__groups">
                  <div class="form__group -flex">
                    <div class="form__row">
                      <app-input-select-form-field
                        [formControlName]="'entityType'"
                        id="010_account_entity_modal"
                        [options]="entityTypes"
                        [disableOptionsTranslate]="true"
                        [labelOption]="'name'"
                        [valueOption]="'name'"
                        [translationPrefix]="'wc.admin.accountEntity'">
                      </app-input-select-form-field>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="delegateForm"
                        [controlName]="'entityId'"
                        id="011_account_entity_modal"
                        [inputType]="'text'"
                        [translationPrefix]="'wc.admin.accountEntity'">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-input-select-form-field
                        [formControlName]="'roleType'"
                        [options]="rolesToSelect"
                        id="012_account_entity_modal"
                        [labelOption]="'id'"
                        [valueOption]="'id'"
                        [translationPrefix]="'wc.admin.accountEntity'">
                      </app-input-select-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card__wrapper">
          <div class="card -full">
            <div class="card__inner">
              <div class="card__header">
                {{ 'wc.admin.accountEntity.entityRoleDetail' | translate }}
              </div>
              <div class="card__body">
                <div class="form__groups">
                  <div class="form__group -flex">
                    <div class="form__row">
                      <app-input-date-form-field
                        [parentGroup]="delegateForm"
                        [controlName]="'startDateTime'"
                        [label]="'validFrom'"
                        id="013_account_entity_modal"
                        [translationPrefix]="'wc.admin.accountEntity'">
                      </app-input-date-form-field>
                    </div>
                    <div class="form__row">
                      <app-input-date-form-field
                        [parentGroup]="delegateForm"
                        [controlName]="'endDateTime'"
                        id="014_account_entity_modal"
                        [label]="'validTo'"
                        [translationPrefix]="'wc.admin.accountEntity'">
                      </app-input-date-form-field>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [id]="'015_account_entity_modal'"
                        [inputType]="'checkbox'"
                        [parentGroup]="delegateForm"
                        [controlName]="'canDelegate'"
                        [label]="'wc.admin.accountEntity.canDelegate'">
                      </app-custom-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 20px">
  <button color="primary" mat-raised-button (click)="cancel()">
    {{ 'wc.common.cancel.button' | translate }}
  </button>
  <button color="primary" mat-raised-button (click)="continue()" [routerLink]="">
    {{ 'wc.common.continue.button' | translate }}
  </button>
</mat-dialog-actions>
