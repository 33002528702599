import { Component, EventEmitter, Output } from '@angular/core';
import { PagedStepTemplatesDto, StepTemplateDto } from '@btl/admin-bff';
import {
  AclService,
  AdminStepTemplateService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { takeUntil } from 'rxjs/operators';
import {
  TicketStepTemplateEditComponent,
} from '@components/tickets/ticket-step-template/edit/ticket-step-template-edit.component';
import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-ticket-step-template-list',
  templateUrl: './ticket-step-template-list.component.html',
  styleUrls: ['./ticket-step-template-list.component.scss'],
})
@EnableDynamicLoading({ customName: TicketStepTemplateListComponent.PAGE_ID })
export class TicketStepTemplateListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'TicketStepTemplateListComponent';

  pageId(): string {
    return TicketStepTemplateListComponent.PAGE_ID;
  }

  pagedStepTemplatesDto: PagedStepTemplatesDto;

  @Output()
  readonly editTickets: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    private adminStepTemplateService: AdminStepTemplateService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminStepTemplateService
        .filterStepTemplates(this.search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedStepTemplatesDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminStepTemplateService.deleteStepTemplate(rowDataId);
  }

  newTicketStepTemplate() {
    this.editTicketStepTemplate({ id: '&' });
  }

  editTicketStepTemplate(stepTemplate: StepTemplateDto, inNewTab: boolean = false) {
    this.navigateSibling(TicketStepTemplateEditComponent.PAGE_ID, { id: stepTemplate.id }, inNewTab);
  }
}
