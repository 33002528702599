import { Component, Input, ViewChild } from '@angular/core';
import {
  AclService,
  AdminAccountEntityRoleService,
  AppBlockerService,
  CompareType,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  AccountEntityRoleBulkOperationsRequestDto,
  AccountEntityRoleDto,
  PagedAccountEntityRolesDto
} from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { AccountEntityFilterComponent } from '@components/account-entity/list/filter/account-entity-filter.component';
import {
  BulkUpdateAccountEntityRolesModalComponent,
} from '@components/account-entity/list/bulk-update-account_entity-roles-modal/bulk-update-account-entity-roles-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AccountEntityEditComponent } from '@components/account-entity/edit/account-entity-edit.component';
import { DelegateModalComponent } from '@components/account-entity/delegate-modal/delegate-modal.component';
import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-account-entity-list',
  templateUrl: './account-entity-list.component.html',
  styleUrls: ['./account-entity-list.component.scss'],
})
@EnableDynamicLoading({ customName: AccountEntityListComponent.PAGE_ID })
export class AccountEntityListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'AccountEntityListComponent';

  pageId(): string {
    return AccountEntityListComponent.PAGE_ID;
  }

  private static readonly DELEGATED_BY_ACCOUNT_ID = 'delegatedByAccount.accountId';
  public static readonly ACCOUNT_ID_PARAM = 'account.accountId';
  public static readonly ENTITY_TYPE_PARAM = 'entityType';
  public static readonly ENTITY_ID_PARAM = 'entityId';
  public static readonly ROLE_TYPE_PARAM = 'roleType';
  public static readonly GRANT_MODE_PARAM = 'grantMode';
  public static readonly DELEGATED_ACCOUNT_ENTITY_ROLE_ID_PARAM = 'delegatedAccountEntityRoleId';

  @Input()
  accountId;

  @ViewChild(AccountEntityFilterComponent, { static: true })
  accountEntityFilterComponent: AccountEntityFilterComponent;

  pagedAccountEntityRolesDto: PagedAccountEntityRolesDto;

  grantMode = true;

  constructor(
    private adminAccountEntityRoleService: AdminAccountEntityRoleService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    private dialog: MatDialog,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  editAccountEntityRole(accountEntityRoleDto) {
    if (this.editable) {
      const queryParams: Params = {};
      queryParams[AccountEntityListComponent.GRANT_MODE_PARAM] = this.grantMode;
      queryParams.id = this.route;
      this.navigateSibling(AccountEntityEditComponent.PAGE_ID, { id: accountEntityRoleDto.id });
    }
  }

  new() {
    const queryParams: Params = {};
    let filter = this.accountEntityFilterComponent.getFormValue()['accountId'];
    if (filter) {
      queryParams[AccountEntityListComponent.ACCOUNT_ID_PARAM] = filter;
    }

    this.addEntityTypeAndEntityIdToQueryParams(queryParams);

    filter = this.accountEntityFilterComponent.getFormValue()[AccountEntityListComponent.ROLE_TYPE_PARAM];
    if (filter) {
      queryParams[AccountEntityListComponent.ROLE_TYPE_PARAM] = filter;
    }
    queryParams[AccountEntityListComponent.GRANT_MODE_PARAM] = this.grantMode;
    queryParams.id = '&';
    this.navigateSibling(AccountEntityEditComponent.PAGE_ID, queryParams);
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      if (!this.accountId) {
        let delegatedFilter = this.search.filtering.find(
          filter => filter.column === AccountEntityListComponent.DELEGATED_BY_ACCOUNT_ID,
        );
        if (!delegatedFilter) {
          delegatedFilter = {
            column: AccountEntityListComponent.DELEGATED_BY_ACCOUNT_ID,
            compareType: CompareType.CompareTypeDtoEnum.EQUAL,
            value: null,
          };
          this.search.filtering.push(delegatedFilter);
        }
        if (delegatedFilter.value === null) {
          if (this.grantMode) {
            delegatedFilter.compareType = CompareType.CompareTypeDtoEnum.EQUAL;
          } else {
            delegatedFilter.compareType = CompareType.CompareTypeDtoEnum.DIFFERENT;
          }
        } else {
          this.grantMode = false;
        }
      } else {
        this.search.filtering = this.search.filtering.filter(
          filter =>
            filter.column !== AccountEntityListComponent.DELEGATED_BY_ACCOUNT_ID &&
            filter.column !== AccountEntityListComponent.ACCOUNT_ID_PARAM,
        );
        this.search.filtering.push({
          column: this.grantMode
            ? AccountEntityListComponent.ACCOUNT_ID_PARAM
            : AccountEntityListComponent.DELEGATED_BY_ACCOUNT_ID,
          compareType: CompareType.CompareTypeDtoEnum.EQUAL,
          value: this.accountId,
        });
      }

      this.adminAccountEntityRoleService.filterAccountEntityRoles(this.search, null).subscribe(result => {
        if (result) {
          this.pagedAccountEntityRolesDto = result;
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  setGrantedMode(matTabEvent: MatTabChangeEvent) {
    this.grantMode = matTabEvent.tab.textLabel === 'GRANTED';
    this.loadData();
  }

  delegateAccountEntityRole(accountEntityRoleDto: AccountEntityRoleDto) {
    if (!accountEntityRoleDto) {
      accountEntityRoleDto = this.getSelectedData()[0];
    }
    if (accountEntityRoleDto) {
      const modalRef = this.dialog.open(DelegateModalComponent);
      const selectModalComponent = modalRef.componentInstance;
      selectModalComponent.dialogRef = modalRef;
      selectModalComponent.selectedAccountEntityRoleDto = accountEntityRoleDto;
      selectModalComponent.grantMode = false;
    }
  }

  private addEntityTypeAndEntityIdToQueryParams(queryParams: {}) {
    let filter = this.accountEntityFilterComponent.getFormValue()[AccountEntityListComponent.ENTITY_TYPE_PARAM];
    if (filter) {
      queryParams[AccountEntityListComponent.ENTITY_TYPE_PARAM] = filter;
    }
    filter = this.accountEntityFilterComponent.getFormValue()[AccountEntityListComponent.ENTITY_ID_PARAM];
    if (filter) {
      queryParams[AccountEntityListComponent.ENTITY_ID_PARAM] = filter;
    }
  }

  bulkUpdate() {
    const modalRef = this.dialog.open(BulkUpdateAccountEntityRolesModalComponent);
    const modalComponent = modalRef.componentInstance;
    modalComponent.dialogRef = modalRef;
    modalComponent.handler = bulkUpdate => {
      const bulkOperations: AccountEntityRoleBulkOperationsRequestDto = {
        bulkUpdateList: [],
      };
      this.getSelectedData().forEach(accountEntity => {
        accountEntity.startDateTime = bulkUpdate.startDateTime;
        accountEntity.endDateTime = bulkUpdate.endDateTime;
        accountEntity.canDelegate = bulkUpdate.canDelegate;
        bulkOperations.bulkUpdateList.push(accountEntity);
      });
      this.adminAccountEntityRoleService
        .changeAccountEntityRoles(bulkOperations)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.loadData();
        });
    };
  }
}
