import { Component } from '@angular/core';
import {
  AclService,
  AdminResourceService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { SortDto } from '@btl/order-bff';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { BulkDeleteResourceListDto, PagedResourcesDto, ResourceDataDto, ResourceListDto } from '@btl/admin-bff';
import { ResourceResourcesEditComponent } from '@components/resource/resources/edit/resource-resources-edit.component';
import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-resource-resources-list, [app-resource-resources-list]',
  templateUrl: './resource-resources-list.component.html',
})
@EnableDynamicLoading({ customName: ResourceResourcesListComponent.PAGE_ID })
export class ResourceResourcesListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'ResourceResourcesListComponent';

  pageId(): string {
    return ResourceResourcesListComponent.PAGE_ID;
  }

  pagedResourcesDto: PagedResourcesDto;

  initSorting = [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }];

  constructor(
    public aclService: AclService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    private adminResourceService: AdminResourceService,
    protected appBlockerService: AppBlockerService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  ngOnInit(): void {
    sessionStorage.removeItem(ResourceResourcesEditComponent.RESOURCE_TYPE);
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.appBlockerService.block();
      this.adminResourceService
        .filterResources(this.search)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: PagedResourcesDto) => {
          if (result) {
            this.pagedResourcesDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  newResource(): void {
    this.editResource({
      id: 'newResource',
      type: null,
    });
  }

  editResource(resourceDto: ResourceDataDto, inNewTab: boolean = false): void {
    this.navigateSibling(
      ResourceResourcesEditComponent.PAGE_ID,
      {
        type: resourceDto.type,
        id: resourceDto.id,
      },
      inNewTab
    );
  }

  deleteResource(resourceDataDto: ResourceDataDto): void {
    if (!ResourceResourcesEditComponent.isDeletableResource(resourceDataDto)) {
      const localizationParameters = {
        itemId: resourceDataDto.id
      };
      this.stickyMessageService.addStickyErrorMessage('wc.admin.resource.delete.error.text', localizationParameters);
      return;
    }

    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.resource.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminResourceService
        .deleteResource(resourceDataDto.type, resourceDataDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: ResourceDataDto) => {
          this.loadData();
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  deleteCheckedResources(): void {
    let notDeletableId = null;
    const bulkDeleteListDto: BulkDeleteResourceListDto = {
      bulkDeleteList: [],
    };
    this.getSelectedData().forEach((resource: ResourceDataDto) => {
      if (ResourceResourcesEditComponent.isDeletableResource(resource)) {
        bulkDeleteListDto.bulkDeleteList.push({
          type: resource.type,
          id: resource.id,
        });
      } else {
        notDeletableId = resource.id;
      }
    });

    if (notDeletableId) {
      const localizationParameters = {
        itemId: notDeletableId
      };
      this.stickyMessageService.addStickyErrorMessage('wc.admin.resource.delete.error.text', localizationParameters);
      return;
    }

    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.resource.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();

      this.adminResourceService
        .bulkDeleteResources(bulkDeleteListDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: ResourceListDto) => {
          this.loadData();
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });

      confirmationDialogComponent.dialogReference.close();
    };
  }
}
