<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <div class="form__field">
            <mat-checkbox
              id="001_export_filter"
              class="form__input"
              formControlName="onlyMine"
              (ngModelChange)="handleOnlyMineChange($event)">
              <mat-label>{{ 'wc.admin.exports.onlyMine' | translate }}</mat-label>
            </mat-checkbox>
          </div>
          <app-input-date-form-field
            [parentGroup]="filterForm"
            [controlName]="'createdFrom'"
            id="002_export_filter"
            [label]="'createdFrom'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.exports'">
          </app-input-date-form-field>
          <app-input-date-form-field
            [parentGroup]="filterForm"
            [controlName]="'createdTo'"
            id="003_export_filter"
            [label]="'createdTo'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.exports'">
          </app-input-date-form-field>

          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
