import { Component, Input, ViewChild } from '@angular/core';
import { Search } from '@btl/btl-fe-wc-common';
import { RulesListingComponent } from '@components/acl/roles/role-edit/rules-listing/rules-listing.component';

@Component({
  selector: 'app-rules-select-modal',
  templateUrl: './rules-select-modal.component.html',
  styleUrls: ['./rules-select-modal.component.scss'],
})
export class RulesSelectModalComponent {
  @ViewChild(RulesListingComponent, { static: true })
  rulesListingComponent: RulesListingComponent;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  multiSelectMode = false;

  singleSelectMode = true;

  excludeRuleIds = [];

  @Input()
  disableActions: boolean = false;

  @Input()
  disableListingActions: boolean = false;

  activeMultiSelectMode() {
    this.multiSelectMode = true;
    this.singleSelectMode = false;
  }

  selectEvent() {
    if (this.singleSelectMode) {
      const ruleDto = this.rulesListingComponent.getSelectedRules()[0];
      if (ruleDto) {
        this.selectHandler(ruleDto);
        this.dialogRef.close();
      }
    } else {
      const ruleDtos = this.rulesListingComponent.getSelectedRules();
      if (ruleDtos && ruleDtos.length > 0) {
        this.selectHandler(ruleDtos);
        this.dialogRef.close();
      }
    }
  }

  singleSelect(selectedDto) {
    this.selectHandler(selectedDto);
    this.dialogRef.close();
  }

  getSearch(): Search {
    return this.rulesListingComponent.search;
  }

  removeSelection() {
    if (this.singleSelectMode) {
      this.selectHandler(null);
    } else {
      this.selectHandler([]);
    }
    this.dialogRef.close();
  }
}
