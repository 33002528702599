import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AdminGatewayService, AppBlockerService, Search } from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import { GatewayDto, PagedGatewaysDto } from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notification-type-gateways-modal',
  templateUrl: './notification-type-gateways-modal.component.html',
  styleUrls: ['./notification-type-gateways-modal.component.scss'],
})
export class NotificationTypeGatewaysModalComponent implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  dialogRef;

  @Output()
  readonly selectGatewayEvent: EventEmitter<GatewayDto> = new EventEmitter();

  pagedGatewaysDto: PagedGatewaysDto;

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };

  constructor(private appBlockerService: AppBlockerService, private adminGatewayService: AdminGatewayService) {
    this.loadGateways(this.search);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  loadGateways(search: Search): void {
    this.appBlockerService.block();
    this.adminGatewayService
      .filterGateways(search)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PagedGatewaysDto) => {
        this.pagedGatewaysDto = result;
      });
  }

  onSelectGateway(gatewayDto: GatewayDto): void {
    this.selectGatewayEvent.emit(gatewayDto);
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadGateways(this.search);
  }
}
