<div *ngIf="!simpleMode">
  <h2 mat-dialog-title>
    {{ 'wc.admin.files.uploadFiles' | translate }} {{ this.selectedType ? ' - ' + this.selectedType.id : '' }}
    <div class="buttons">
      <mat-icon
        *ngIf="selectedType"
        (click)="resetSelectedType()"
        matTooltip="{{ 'wc.common.reset' | translate }}"
        class="default-icon-gray-fill no-stroke"
        svgIcon="widgetUndo">
      </mat-icon>
      <mat-icon
        class="default-icon-gray-fill no-stroke"
        svgIcon="close"
        mat-dialog-close
        matTooltip="{{ 'wc.common.close' | translate }}">
      </mat-icon>
    </div>
  </h2>
  <div *ngIf="wrongFileFormat"></div>
</div>
<mat-dialog-content class="mat-typography picture-modal" [ngClass]="{'margin-right-20': simpleMode }">
  <div class="card__wrapper">
    <div class="card__body" [ngClass]="{ 'padding-20-0': simpleMode}">
      <div class="picture-wrapper">
        <ng-container *ngIf="uploadStep; else uploading">
          <div class="upload-title">
            {{ 'wc.admin.files.selectFileType' | translate }}
          </div>
          <div class="upload-select-box">
            <div class="upload-select-item" *ngFor="let pictureType of pictureTypes" (click)="select(pictureType)">
              <div class="upload-select-item-wrapper">
                <div fxLayout="column" class="upload-select-item-container">
                  <div class="upload-select-item-portrait">
                    <div class="picture-portrait-header">
                      <div class="portrait-icon {{ pictureType?.id }}">
                        <mat-icon [svgIcon]="getIconType(pictureType?.id)"></mat-icon>
                      </div>
                    </div>
                  </div>
                  <div fxLayout="column" class="upload-select-item-title">
                    <div class="picture-type-header">{{ pictureType.id }}</div>
                    <div *ngIf="pictureType.width && pictureType.height">
                      {{ 'wc.admin.files.ImageSizeShouldBe' | translate }}
                    </div>
                    <span *ngIf="pictureType.width && pictureType.height"
                    >{{ pictureType.width }} x{{ pictureType.height }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #uploading>
          <div class="uploading">
            <div class="container">
              <input
                type="file"
                #fileDropRef
                id="fileDropRef"
                [multiple]="multiple"
                (change)="fileBrowseHandler($event.target.files)"/>
              <mat-icon>cloud_upload</mat-icon>
              <h3>{{ 'wc.admin.files.dragAndDropFiles' | translate }}</h3>
              <h3>{{ 'wc.admin.files.or' | translate }}</h3>
              <button color="primary" mat-raised-button [routerLink]="" class="btn -primary -xs edit">
                {{ 'wc.admin.files.chooseFile' | translate }}
              </button>
            </div>
            <div class="files-list" *ngIf="files.length > 0">
              <div class="single-file" *ngFor="let file of files; let i = index">
                <mat-icon>pause</mat-icon>
                <div class="info">
                  <div class="name">
                    {{ file?.name }}
                    <div class="uploading-bar">
                      <mat-progress-bar
                        *ngIf="isUploading"
                        class="file-progress"
                        mode="indeterminate"></mat-progress-bar>
                    </div>
                  </div>
                  <p class="size"></p>
                </div>
              </div>
            </div>
            <div *ngIf="wrongFileFormat" class="alert">
              <mat-icon>info</mat-icon>
              {{ 'wc.admin.products.product.pictures.wrongFileFormat' | translate }}
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</mat-dialog-content>
