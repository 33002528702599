<mat-toolbar>
  <mat-toolbar-row>
    <a class="header__brand" href="index.html">
      <img
        *ngIf="logoIconDmsFile; else defaultLogo"
        id="001_top_menu"
        [src]="logoIconDmsFile.contentHref"
      />
      <ng-template #defaultLogo>
        <img id="010_top_menu" src="/assets/img/logo.png"/>
      </ng-template>
    </a>
    <div class="top-menu">
      <ng-container
        *ngFor="let codebookModule of mainModules | slice: 0:4; let i = index"
      >
        <ng-container
          *ngIf="hasModules(codebookModule).length > 0; else noChildModules"
          class="top-menu-modules"
        >
          <button
            id="002_top_menu_{{ i }}"
            mat-button
            [matMenuTriggerFor]="mainMenu.childMenu"
          >
            <mat-icon [svgIcon]="codebookModule.cssClass"></mat-icon>
            {{ pageModules.getModuleText(codebookModule.code) }}
          </button>
          <app-menu-item
            #mainMenu
            [module]="codebookModule"
            [modules]="modules"
          > </app-menu-item>
        </ng-container>
        <ng-template #noChildModules>
          <button
            mat-button
            id="003_top_menu_{{ i }}"
            [routerLink]="[
              codebookModule.parentUrlPattern + codebookModule.urlPattern
            ]"
          >
            <mat-icon [svgIcon]="codebookModule.cssClass"></mat-icon>
            {{ pageModules.getModuleText(codebookModule.code) }}
          </button>
        </ng-template>
      </ng-container>
      <ng-container>
        <button mat-button [matMenuTriggerFor]="moreMenu" (click)="moreMenuCollapsed = !moreMenuCollapsed">
          {{'wc.common.more' | translate}}
          <mat-icon [@indicatorRotate]="moreMenuCollapsed ? 'collapsed' : 'expanded'"> expand_more</mat-icon>
        </button>
        <mat-menu #moreMenu="matMenu" class="more-menu">
          <ng-container
            *ngFor="let codebookModule of mainModules; let i = index"
          >
            <ng-container *ngIf="i > 3">
              <ng-container
                *ngIf="
                  hasModules(codebookModule).length > 0;
                  else noChildModules
                "
              >
                <button
                  mat-menu-item
                  id="004_top_menu_{{ i }}"
                  [matMenuTriggerFor]="menu.childMenu"
                  (click)="$event.stopPropagation()"
                >
                  <mat-icon [svgIcon]="codebookModule.cssClass"></mat-icon>
                  {{ pageModules.getModuleText(codebookModule.code) }}
                </button>
                <app-menu-item
                  #menu
                  [module]="codebookModule"
                  [modules]="modules"
                  [openPosition]="'right'"
                >
                </app-menu-item>
              </ng-container>
              <ng-template #noChildModules>
                <button
                  mat-menu-item
                  id="005_top_menu_{{ i }}"
                  [routerLink]="
                    codebookModule.parentUrlPattern + codebookModule.urlPattern
                  "
                >
                  <mat-icon [svgIcon]="codebookModule.cssClass"></mat-icon>
                  {{ pageModules.getModuleText(codebookModule.code) }}
                </button>
              </ng-template>
            </ng-container>
          </ng-container>
        </mat-menu>
      </ng-container>
    </div>

    <span class="top-menu-spacer"></span>
    <div class="user-panel" *ngIf="isLoggedIn && userProfile">
      <span class="user-panel-email">{{ userProfile.email }}</span>
      <button
        [disableRipple]="true"
        class="btn square-hover"
        type="button"
        id="005_top_menu"
        mat-icon-button
        [matMenuTriggerFor]="settings"
      >
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #settings="matMenu" class="user-setting">
        <div (click)="$event.stopPropagation()">
          <div>
            <mat-card appearance="outlined">
              <mat-card-header>
                <img mat-card-avatar src="/assets/img/user.png" alt="" />
                <mat-card-title
                >{{ userProfile.firstName }}
                  {{ userProfile.lastName }}</mat-card-title
                >
                <mat-card-subtitle>({{ userProfile.email }})</mat-card-subtitle>
                <mat-card-title *ngIf="getTenantId()">{{
                  getTenantId()
                  }}</mat-card-title>
              </mat-card-header>
            </mat-card>
          </div>
          <div>
            <mat-list>
              <mat-list-item id="006_top_menu">
                <div class="user-option-name">
                  <mat-icon mat-list-icon>dark_mode</mat-icon>
                  Dark Mode
                </div>
                <mat-slide-toggle
                  id="006_top_menu_{{ selectedTheme }}"
                  [(ngModel)]="selectedTheme"
                  (change)="toggleTheme()"
                ></mat-slide-toggle>
              </mat-list-item>
              <mat-list-item id="007_top_menu">
                <div class="user-option-name">
                  <mat-icon mat-list-icon>language</mat-icon>
                  Language
                </div>
                <mat-label hidden></mat-label>
                <mat-select id="008_top_menu" [(value)]="selectedLanguage">
                  <mat-option
                    id="008_top_menu_{{ locale }}"
                    *ngFor="let locale of locales"
                    (click)="languageChange(locale)"
                    [value]="locale.toUpperCase()"
                  >
                    {{ locale.toUpperCase() }}
                  </mat-option>
                </mat-select>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item id="009_top_menu">
                <div class="user-option-name href">
                  <mat-icon mat-list-icon>exit_to_app</mat-icon>
                    <a title="{{'wc.common.navigation.logout.button' | translate}}"
                      id="009_top_menu_exit"
                      (click)="logoutAuth()">
                      {{ 'wc.common.navigation.logout.button' | translate }}
                    </a>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
