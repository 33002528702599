<div class="border-bot">
  <div>
    <h2 mat-dialog-title >
      {{ 'wc.admin.cms.staticPage.widget.modal.parameters' | translate }}
      <mat-icon
        class="default-icon-gray-fill no-stroke"
        svgIcon="close"
        mat-dialog-close
        matTooltip="{{ 'wc.common.close' | translate }}">
      </mat-icon>
    </h2>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div class=".mat-drawer-container">
    <div class="mat-sidenav-content">
      <div class="main_content">
        <div [formGroup]="widgetForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row widget">
                <app-custom-input
                  [parentGroup]="widgetForm"
                  [controlName]="'name'"
                  id="001_cms_contents_widget_modal_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.cms.content.widget.modal'">
                </app-custom-input>
              </div>
              <div class="form__row widget">
                <app-custom-input
                  [parentGroup]="widgetForm"
                  [controlName]="'widgetTemplateType'"
                  id="002_cms_contents_widget_modal_edit"
                  [label]="'type'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.cms.content.widget.modal'">
                </app-custom-input>
              </div>
            </div>
          </div>

          <div formArrayName="parameters" class="parameters">
            <!-- Is single param -->
            <ng-container
              *ngIf="widgetMultiValueMap.singleValueIndexes && widgetMultiValueMap.singleValueIndexes.length">
              <div class="form__groups">
                <div class="form__group -flex">
                  <ng-container *ngFor="let singleValueIndex of widgetMultiValueMap.singleValueIndexes">
                    <ng-container *ngIf="!parametersFormArray.at(singleValueIndex).at(0).get('localizedParameters')">
                      <div
                        class="form__row widget"
                        [class.full]="
                            fullClass(
                              widgetTemplateDto.parameters[singleValueIndex]?.type,
                              widgetTemplateDto.parameters[singleValueIndex]?.typeDetail
                            )
                          ">
                        <app-cms-content-widget-modal-controls
                          (checkValidationEvent)="clearErrorsOnOptionalParams()"
                          [formGroup]="parametersFormArray.at(singleValueIndex).at(0)"
                          [currentLocale]="currentLocale"
                          [index]="singleValueIndex"
                          [seqIndex]="0"
                          [modalEditorConfig]="modalEditorConfig"
                          [widgetTemplateParameter]="widgetTemplateDto.parameters[singleValueIndex]">
                        </app-cms-content-widget-modal-controls>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="parametersFormArray.at(singleValueIndex).at(0).get('localizedParameters')">
                      <ng-container *ngFor="let local of locales">
                        <div
                          class="form__row widget"
                          [class.full]="
                              fullClass(
                                widgetTemplateDto.parameters[singleValueIndex]?.type,
                                widgetTemplateDto.parameters[singleValueIndex]?.typeDetail
                              )
                            ">
                          <app-cms-content-widget-modal-controls
                            (checkValidationEvent)="clearErrorsOnOptionalParams()"
                            [formGroup]="parametersFormArray.at(singleValueIndex).at(0)"
                            [currentLocale]="currentLocale"
                            [locale]="local"
                            [index]="singleValueIndex"
                            [seqIndex]="0"
                            [modalEditorConfig]="modalEditorConfig"
                            [widgetTemplateParameter]="widgetTemplateDto.parameters[singleValueIndex]">
                          </app-cms-content-widget-modal-controls>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <!-- Is multivalue -->
            <ng-container
              *ngIf="widgetMultiValueMap.multiValueIndexes && widgetMultiValueMap.multiValueIndexes.length">
              <ng-container *ngFor="let countIndex of widgetMultiValueCountArray">
                <div class="card -full" *ngIf="multiParamVisibleIndexes.includes(countIndex)">
                  {{ 'wc.admin.cms.content.widget.modal.countIndex' | translate }}: {{ countIndex + 1 }}
                  <div class="card__inner widget">
                    <div class="card__body widget">
                      <div
                        class="form__groups"
                        *ngIf="
                            countIndex !== 0 &&
                            multiParamVisibleIndexes.length &&
                            countIndex !== multiParamVisibleIndexes[0] &&
                            countIndex === multiParamVisibleIndexes[multiParamVisibleIndexes.length - 1]
                          ">
                        <div class="form__group group-buttons -full">
                          <button color="primary" mat-raised-button (click)="removeMultiValueGroup(countIndex)">
                            {{ 'wc.admin.cms.content.widget.modal.removeItem' | translate }}
                          </button>
                        </div>
                      </div>

                      <div class="form__groups">
                        <div class="form__group -flex">
                          <ng-container *ngFor="let multiValueIndex of widgetMultiValueMap.multiValueIndexes">
                            <ng-container
                              *ngIf="
                                  !parametersFormArray.at(multiValueIndex).at(countIndex).get('localizedParameters')
                                ">
                              <div
                                class="form__row widget"
                                [class.full]="
                                    fullClass(
                                      widgetTemplateDto.parameters[multiValueIndex]?.type,
                                      widgetTemplateDto.parameters[multiValueIndex]?.typeDetail
                                    )
                                  ">
                                <app-cms-content-widget-modal-controls
                                  (checkValidationEvent)="clearErrorsOnOptionalParams()"
                                  [formGroup]="parametersFormArray.at(multiValueIndex).at(countIndex)"
                                  [currentLocale]="currentLocale"
                                  [index]="multiValueIndex"
                                  [seqIndex]="countIndex"
                                  [modalEditorConfig]="modalEditorConfig"
                                  [widgetTemplateParameter]="widgetTemplateDto.parameters[multiValueIndex]">
                                </app-cms-content-widget-modal-controls>
                              </div>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                  parametersFormArray.at(multiValueIndex).at(countIndex).get('localizedParameters')
                                ">
                              <ng-container *ngFor="let local of locales">
                                <div
                                  class="form__row widget"
                                  [class.full]="
                                      fullClass(
                                        widgetTemplateDto.parameters[singleValueIndex]?.type,
                                        widgetTemplateDto.parameters[singleValueIndex]?.typeDetail
                                      )
                                    ">
                                  <app-cms-content-widget-modal-controls
                                    (checkValidationEvent)="clearErrorsOnOptionalParams()"
                                    [formGroup]="parametersFormArray.at(multiValueIndex).at(countIndex)"
                                    [currentLocale]="currentLocale"
                                    [locale]="local"
                                    [index]="multiValueIndex"
                                    [seqIndex]="countIndex"
                                    [modalEditorConfig]="modalEditorConfig"
                                    [widgetTemplateParameter]="widgetTemplateDto.parameters[multiValueIndex]">
                                  </app-cms-content-widget-modal-controls>
                                </div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="card__inner widget margin-top-20px"
                    *ngIf="
                        (widgetMultiValueCount === -1 && countIndex + 1 === widgetMultiValueCountArray.length) ||
                        (multiParamVisibleIndexes.includes(countIndex) &&
                          countIndex + 1 < widgetMultiValueCountArray.length &&
                          countIndex === multiParamVisibleIndexes[multiParamVisibleIndexes.length - 1])
                      ">
                    <div class="card__body widget" style="padding: 0">
                      <div class="form__groups">
                        <div class="form__group group-buttons -flex">
                          <button
                            color="primary"
                            mat-raised-button
                            (click)="addMultiValueGroup(null, countIndex + 1, true)">
                            {{ 'wc.admin.cms.content.widget.modal.addItem' | translate }}
                            <ng-container *ngIf="widgetMultiValueCount > -1">
                              {{ countIndex + 2 }} {{ 'wc.admin.cms.content.widget.modal.of.label' | translate }}
                              {{ widgetMultiValueCount }}
                            </ng-container>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    *ngIf="displayControls"
    color="primary"
    mat-raised-button
    (click)="save()"
    [routerLink]=""
    class="btn -primary -xs icon stroke currentColor"
  ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</button
  >
  <button
    color="primary"
    mat-stroked-button
    (click)="delete()"
    *ngIf="displayControls"
    [routerLink]=""
    class="btn -primary -xs icon delete currentColor">
    <mat-icon svgIcon="delete"></mat-icon>
    {{ 'wc.admin.delete' | translate }}
  </button>
  <a mat-button (click)="cancel()" class="btn cancel-button">
    {{ 'wc.common.cancel.button' | translate }}
  </a>
</mat-dialog-actions>
