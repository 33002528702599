import { Component, Input } from '@angular/core';
import {
  AclService,
  AdminStockService,
  AdminStoreService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  Search,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { PagedStoresDto, StoreDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { StoreEditComponent } from '@components/resource/store/store-edit/store-edit.component';
import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-stores-listing',
  templateUrl: './stores-listing.component.html',
})
@EnableDynamicLoading({ customName: StoresListingComponent.PAGE_ID })
export class StoresListingComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'StoresListingComponent';

  pageId(): string {
    return StoresListingComponent.PAGE_ID;
  }

  @Input()
  selectedStores = [];

  pagedStoresDto: PagedStoresDto;

  constructor(
    private adminStoreService: AdminStoreService,
    private adminStockService: AdminStockService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    public aclService: AclService,
    protected confirmationDialogService: ConfirmationDialogService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  delete(storeDto: StoreDto) {
    this.appBlockerService.block();

    const stockSearch: Search = {
      filtering: [{ column: 'store.id', compareType: 'EQUAL', value: storeDto.id }],
      sorting: [],
      paging: {
        page: 1,
        pageSize: 1,
      },
    };

    this.adminStockService
      .filterStocks(stockSearch)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        if (result?.data.length > 0) {
          const confirmationDialogComponent = this.confirmationDialogService.openDialog([
            'wc.admin.stores.deleteUsed.confirmation.text',
          ]);
          confirmationDialogComponent.confirmationHandler = dialogReference => {
            this.deleteData(storeDto, true);
            confirmationDialogComponent.dialogReference.close();
          };
        } else {
          this.deleteData(storeDto);
        }
      });
  }

  editStore(storeDto, inNewTab: boolean = false) {
    this.navigateSibling(StoreEditComponent.PAGE_ID, { id: storeDto.id }, inNewTab);
  }

  newStore() {
    this.editStore({
      id: 'newStore',
    });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminStoreService
        .filterStores(this.search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedStoresDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminStoreService.deleteStore(rowDataId);
  }
}
