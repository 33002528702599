import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TicketTypeParamDto } from '@btl/admin-bff';

@Component({
  selector: 'app-ticket-type-parameters-item, [app-ticket-type-parameters-item]',
  templateUrl: './ticket-type-parameters-item.component.html',
  styleUrls: ['./ticket-type-parameters-item.component.scss'],
})
export class TicketTypeParametersItemComponent {
  selected = false;

  @Input()
  parameter: TicketTypeParamDto;

  @Output()
  readonly editTicketTypeParameterEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly deleteTicketTypeParameterEvent: EventEmitter<any> = new EventEmitter<any>();

  edit() {
    this.editTicketTypeParameterEvent.emit(this.parameter);
  }

  delete() {
    this.deleteTicketTypeParameterEvent.emit(this.parameter);
  }
}
