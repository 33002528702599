import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminAclService,
  AdminStepTemplateService,
  AdminTicketTypeService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService,
  TicketingService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { StepTemplateDto } from '@btl/admin-bff';
import { Animations } from '@helpers/animations';
import {
  TicketStepTemplateListComponent
} from '@components/tickets/ticket-step-template/list/ticket-step-template-list.component';

@Component({
  selector: 'app-ticket-step-template-edit',
  templateUrl: './ticket-step-template-edit.component.html',
  styleUrls: ['./ticket-step-template-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: TicketStepTemplateEditComponent.PAGE_ID })
export class TicketStepTemplateEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'TicketStepTemplateEditComponent';

  pageId(): string {
    return TicketStepTemplateEditComponent.PAGE_ID;
  }

  stepTemplateForm: FormGroup = this.formBuilder.group({
    code: [null, Validators.required],
    description: [],
    steps: this.formBuilder.array([]),
  });
  stepTemplateDto: StepTemplateDto = {};
  stepTemplateUsedAt = [];

  get stepsFormArray(): FormArray {
    return <FormArray>this.stepTemplateForm.get('steps');
  }

  createStepsForm(): FormGroup {
    return this.formBuilder.group({
      stepSeqNo: [],
      code: [],
      description: [],
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private ticketingService: TicketingService,
    private adminTicketTypeService: AdminTicketTypeService,
    private adminStepTemplateService: AdminStepTemplateService,
    private adminAclService: AdminAclService,
    protected router: Router,
    protected route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,
    public stickyMessageService: StickyMessageService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const stepTemplateId = this.params.id;
      if (stepTemplateId) {
        if (stepTemplateId === '&') {
          this.setStepTemplate(this.stepTemplateDto);
        } else {
          this.adminStepTemplateService
            .getStepTemplateById(stepTemplateId, null)
            .pipe(takeUntil(this.onDestroy$))
            .pipe(finalize(this.appBlockerService.unblock))
            .subscribe(stepTemplate => {
              this.appBlockerService.block();
              this.setStepTemplate(stepTemplate);
            });
        }
      }
    } else {
      this.stepTemplateDto = undefined;
    }
  }

  setStepTemplate(stepTemplate: StepTemplateDto) {
    this.stepsFormArray.clear();
    this.stepTemplateDto = stepTemplate;
    if (this.stepTemplateDto?.steps?.length > 0) {
      this.stepTemplateDto.steps.forEach(step => {
        const stepForm = this.createStepsForm();
        stepForm.patchValue(step);
        this.stepsFormArray.push(stepForm);
      });
    }
    if (this.stepTemplateDto?.usedByTicketTypes?.length > 0) {
      this.stepTemplateDto.usedByTicketTypes.forEach(usedTicketType => {
        this.stepTemplateUsedAt.push(usedTicketType.code);
      });
    }
    this.stepTemplateForm.patchValue(stepTemplate);
  }

  save() {
    if (this.saveStepTemplate()) {
      const stepTemplateId = this.stepTemplateDto.id;
      this.stepTemplateDto.id = null;
      this.stepTemplateDto.modifiedBy = null;
      this.stepTemplateDto.modified = null;
      this.stepTemplateDto.createdBy = null;
      this.stepTemplateDto.created = null;
      delete this.stepTemplateDto.usedByTicketTypes;
      if (stepTemplateId) {
        this.adminStepTemplateService
          .updateStepTemplate(stepTemplateId, this.stepTemplateDto)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(stepTemplate => this.getStepTemplateHandler(stepTemplate));
      } else {
        this.adminStepTemplateService
          .createStepTemplate(this.stepTemplateDto)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(stepTemplate => this.getStepTemplateHandler(stepTemplate));
      }
    }
  }

  saveStepTemplate(): boolean {
    FormUtils.validateAllFormFields(this.stepTemplateForm);
    if (this.stepTemplateForm.valid) {
      if (this.stepTemplateForm) {
        Object.keys(this.stepTemplateForm.controls).forEach(field => {
          const control = this.stepTemplateForm.get(field);
          this.stepTemplateDto[field] = control.value;
        });
      }
      return true;
    }
    return false;
  }

  loadTicketType(stepTemplate: StepTemplateDto) {
    this.adminStepTemplateService
      .getStepTemplateById(stepTemplate.id, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setStepTemplate(result);
      });
  }

  reset() {
    this.stepTemplateForm.reset();
    if (this.stepTemplateDto && this.stepTemplateDto.recordVersion) {
      this.loadTicketType(this.stepTemplateDto);
    } else {
      this.setStepTemplate(this.stepTemplateDto);
    }
  }

  cancel() {
    this.navigateSibling(TicketStepTemplateListComponent.PAGE_ID);
  }

  delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.stepTemplate.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminStepTemplateService
        .deleteStepTemplate(this.stepTemplateDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(TicketStepTemplateListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  duplicate() {
    this.stepTemplateDto.id = null;
    this.stepTemplateForm.controls['code'].patchValue(`${this.stepTemplateDto.code}copy`);
    this.stepTemplateDto.recordVersion = null;
  }

  getStepTemplateHandler = (stepTemplate: StepTemplateDto): void => {
    if (stepTemplate) {
      if (stepTemplate.id === this.stepTemplateDto.id) {
        this.setStepTemplate(stepTemplate);
      } else {
        this.setStepTemplate(stepTemplate);
        this.navigateSelf({ id: stepTemplate.id });
      }
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };
}
