import { Component, Input, ViewChild } from '@angular/core';
import { StoresListingComponent } from '@components/resource/store/stores-listing/stores-listing.component';

@Component({
  selector: 'app-products-select-modal',
  templateUrl: './stores-select-modal.component.html',
})
export class StoresSelectModalComponent {
  @ViewChild(StoresListingComponent, { static: true })
  storesSelectModalComponent: StoresListingComponent;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  @Input()
  selectMode: boolean = true;

  @Input()
  disableActions: boolean = false;

  @Input()
  disableListingActions: boolean = false;

  @Input()
  disableExpand: boolean = false;

  @Input()
  nestedTable: boolean = false;

  @Input()
  toggleArrow: boolean = false;

  @Input()
  selectedStores = [];

  @Input()
  isModal: boolean = false;

  multiSelectMode = false;

  singleSelectMode = true;

  selectEvent() {
    if (!this.selectMode) {
      const product = this.storesSelectModalComponent.getSelectedData()[0];
      if (product) {
        this.selectHandler(product);
      }
    } else {
      const products = this.storesSelectModalComponent.getSelectedData();
      if (products && products.length > 0) {
        this.selectHandler(products);
        this.dialogRef.close();
      } else {
        this.selectHandler(null);
        this.dialogRef.close();
      }
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  singleSelect(productDto) {
    this.selectHandler(productDto);
    this.dialogRef.close();
  }
}
