<div>
  <h2 mat-dialog-title>
    {{ 'wc.admin.resource.resources.resourceRelationship' | translate }}
    <button [attr.aria-label]="'wc.common.close' | translate" mat-dialog-close type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </h2>

  <mat-dialog-content class="mat-typography">
    <div class=".mat-drawer-container">
      <div class="mat-sidenav-content">
        <div class="main_content">
          <div *ngIf="relationDirection === 'From'">
            <p>{{ 'wc.admin.resource.resources.resourceRelationship.directionInfo' | translate }}</p>
          </div>
          <div *ngIf="!resourceExist">
            <p class="mat-error">{{ 'wc.admin.resource.resources.resourceRelationship.doesNotExist' | translate }}</p>
          </div>
          <form [formGroup]="formGroup" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="formGroup"
                    [controlName]="'id'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.resource.resources.resourceRelationship'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'relationshipType'"
                    [inputType]="'codebook'"
                    [codebookType]="'RESOURCE_RELATIONSHIP_TYPES'"
                    [showEmptyOption]="true"
                    [translationPrefix]="'wc.admin.resource.resources.resourceRelationship'">
                  </app-input-select-form-field>
                </div>

                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'type'"
                    [options]="types"
                    [showEmptyOption]="true"
                    [translationPrefix]="'wc.admin.resource.resources.resourceRelationship'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'direction'"
                    [options]="directions"
                    [labelOption]="'labelKey'"
                    [valueOption]="'value'"
                    [showEmptyOption]="true"
                    [translationPrefix]="'wc.admin.resource.resources.resourceRelationship'">
                  </app-input-select-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      color="primary"
      mat-raised-button
      (click)="save()"
      [routerLink]=""
      [disabled]="relationDirection === 'From'">
      {{ 'wc.common.save' | translate }}
    </button>
    <button color="primary" mat-raised-button (click)="cancel()" [routerLink]="">
      {{ 'wc.common.cancel.button' | translate }}
    </button>
  </mat-dialog-actions>
</div>
