import { Component, OnInit } from '@angular/core';
import {
  BulkOperationsRequestDto,
  PagedRulesDto,
  RuleDto,
  TechnicalCategoryDto,
  TechnicalCategoryParameterDto,
  TextTypeDto,
} from '@btl/admin-bff';
import {
  AbstractPageComponent,
  AclService,
  AdminAclService,
  AdminTechnicalCategoryService,
  AdminTextTypeService,
  AppBlockerService,
  CompareType,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { forkJoin, of, timeout } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductEditService } from '@service/product-edit.service';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { AclTableListComponent } from '@components/acl/components/acl-rules-table/list/acl-table-list.component';
import { ReleasesSelectService } from '@components/releases-select/releases-select.service';
import { AclUtils } from '@helpers/ac-utils';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import {
  CategoryParametersListingComponent
} from '@components/product-catalogue/categories/category-edit/category-parameters-listing/category-parameters-listing.component';
import {
  CategoriesListingComponent
} from '@components/product-catalogue/categories/categories-listing/categories-listing.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;
import { GeneralModalComponent } from '@components/general-modal/general-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: CategoryEditComponent.PAGE_ID })
export class CategoryEditComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'CategoryEditComponent';

  pageId(): string {
    return CategoryEditComponent.PAGE_ID;
  }

  static readonly PREFIX_NEW_PARAMETER = 'new-';
  categories: Array<TechnicalCategoryDto> = [];
  categoriesHints: string[] = [];
  category: TechnicalCategoryDto;
  savingCategoryId;

  categoryForm: FormGroup = this.formBuilder.group({
    id: [null, [Validators.required, Validators.pattern('^[A-Z][A-Z0-9]*(_[A-Z0-9]+)*$')]],
    parentId: [null],
    localizationKey: [null],
    recordVersion: [null],
    texts: [],
    parameters: this.formBuilder.array([]),
  });

  aclForm = this.formBuilder.group({
    acl: this.formBuilder.array([]),
  });

  get aclFormArray(): FormArray {
    return this.aclForm.get('acl') as FormArray;
  }

  parentParametersFormArray: FormArray = this.formBuilder.array([]);

  parameterTextTypes: Array<TextTypeDto> = [];

  categoryAclRules: PagedRulesDto;
  parentCategoriesAclRules: Array<RuleDto>;

  categoryParametersAclRules: PagedRulesDto;
  parentCategoriesParametersAclRules: Array<RuleDto>;
  lastAclParameterName;
  editParentParameter: TechnicalCategoryParameterDto;

  public static isNewCategoryParameter(parameter: TechnicalCategoryParameterDto): boolean {
    return parameter && parameter.id && parameter.id.startsWith(CategoryEditComponent.PREFIX_NEW_PARAMETER);
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private releasesSelectService: ReleasesSelectService,
    private textTypeService: AdminTextTypeService,
    private formBuilder: FormBuilder,
    private productEditService: ProductEditService,
    private appBlockerService: AppBlockerService,
    private stickyMessageService: StickyMessageService,
    private enumerationsService: EnumerationsService,
    private adminTechnicalCategoryService: AdminTechnicalCategoryService,
    private confirmationDialogService: ConfirmationDialogService,
    private adminAclService: AdminAclService,
    public aclService: AclService,
    public dialog: MatDialog
  ) {
    super(router, route);

    this.loadCategoryParameterConfTexts();
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const categoryId = this.params.id;
      if (categoryId && categoryId !== 'newCategory' && (!this.category || this.category.id !== categoryId)) {
        this.adminTechnicalCategoryService
          .getTechnicalCategoryById(categoryId)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.reloadCategory(result);
          });
      } else {
        this.reloadCategory(this.category);
      }
    } else {
      this.category = undefined;
    }
  }

  ngOnInit() {
    this.enumerationsService
      .getProductTechnicalCategoryDtos()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.categories = result;
        this.categoriesHints = result.map(c => c.id);
      });
  }

  reloadCategory(categoryDto: TechnicalCategoryDto) {
    if (categoryDto) {
      this.category = categoryDto;
    } else {
      this.category = {
        id: null,
        parentId: null,
        texts: [],
        parameters: [],
      };
    }

    this.getParametersFormArray().clear();
    this.category.parameters.forEach(parameterDto => {
      this.addParametersFormArray(parameterDto);
    });

    this.categoryForm.reset(this.category);

    this.parentParametersFormArray.clear();
    if (this.category.parentsParameters) {
      this.category.parentsParameters.forEach(parameterDto => {
        const parameterFormByName = this.parentParametersFormArray.controls.find(
          form => form.get('name').value === parameterDto.name
        );
        if (parameterFormByName) {
          parameterFormByName.patchValue(parameterDto);
        } else {
          this.addParentParametersFormArray(parameterDto);
        }
      });
    }

    this.aclFormArray.clear();
    this.loadAcl();
    if (this.category.recordVersion) {
      this.categoryForm.controls['id'].disable();
    }
  }

  addParametersFormArray(parameterDto) {
    const parameterFromGroup = this.formBuilder.group(
      CategoryParametersListingComponent.getCategoryParameterEditForm(this.formBuilder)
    );
    parameterFromGroup.patchValue(parameterDto);
    this.getParametersFormArray().push(parameterFromGroup);
  }

  addParentParametersFormArray(parameterDto) {
    const parameterFromGroup = this.formBuilder.group(
      CategoryParametersListingComponent.getCategoryParameterEditForm(this.formBuilder)
    );
    parameterFromGroup.patchValue(parameterDto);
    this.parentParametersFormArray.push(parameterFromGroup);
  }

  public getCategoryHandler = (categoryDto): void => {
    if (categoryDto) {
      if (categoryDto.id === this.savingCategoryId) {
        this.reloadCategory(categoryDto);
      } else {
        this.reloadCategory(categoryDto);
        this.navigateSelf({ id: categoryDto.id });
      }
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  categoryChanged(category) {}

  changeRules() {
    let bulkOperationsRequestDto: BulkOperationsRequestDto = null;

    if (this.aclForm.controls['acl'].value.length > 0) {
      bulkOperationsRequestDto = AclTableListComponent.getBulkOperationFromRuleSet(
        // @ts-ignore
        this.aclForm.controls['acl'].value,
        this.category.id,
        true
      );
    }
    if (bulkOperationsRequestDto) {
      return this.adminAclService.changeRules(bulkOperationsRequestDto).pipe(takeUntil(this.onDestroy$));
    }
    return of(null);
  }

  save() {
    FormUtils.validateAllFormFields(this.categoryForm);
    if (this.categoryForm.valid) {
      this.category = this.categoryForm.getRawValue();
      this.category.parameters.forEach(parameter => {
        if (CategoryEditComponent.isNewCategoryParameter(parameter)) {
          parameter.id = null;
        }
      });
      this.appBlockerService.block();
      if (this.category.recordVersion) {
        this.savingCategoryId = this.category.id;
        this.adminTechnicalCategoryService
          .updateTechnicalCategory(this.category.id, this.category)
          .pipe(
            timeout(1000 * 40),
            catchError(err => {
              console.warn('Very long update category parameters');
              const modalRef = this.dialog.open(GeneralModalComponent);
              modalRef.componentInstance.dialogRef = modalRef;
              modalRef.componentInstance.headerTranslationKey = 'wc.admin.confirmation.header';
              modalRef.componentInstance.messageTranslationKey = 'wc.admin.technicalCategory.operationRequiresMoreTime.info';
              modalRef.componentInstance.showOkButton = true;
              modalRef.componentInstance.closeButtonHandler = () => {
                modalRef.componentInstance.dialogRef.close;
              };
              return err;
            })
          )
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            category => {
              this.changeRules()
                .pipe(finalize(this.appBlockerService.unblock))
                .subscribe(result => this.getCategoryHandler(category));
            },
            error => this.appBlockerService.unblock()
          );
      } else {
        this.adminTechnicalCategoryService
          .createTechnicalCategory(this.category)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            category => {
              this.changeRules()
                .pipe(finalize(this.appBlockerService.unblock))
                .subscribe(result => this.getCategoryHandler(category));
            },
            error => this.appBlockerService.unblock()
          );
      }
    }
  }

  reset() {
    if (this.category.recordVersion) {
      this.appBlockerService.block();
      this.adminTechnicalCategoryService
        .getTechnicalCategoryById(this.category.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(this.getCategoryHandler);
    } else {
      this.reloadCategory(this.category);
    }
  }

  cancel() {
    this.navigateSibling(CategoriesListingComponent.PAGE_ID);
  }

  delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.technicalcategory.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminTechnicalCategoryService
        .deleteTechnicalCategory(this.category.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.navigateSibling(CategoriesListingComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  releases() {
    if (this.category && this.category.id) {
      this.releasesSelectService.selectReleases('CatTech', this.category.id);
    }
  }

  getParametersFormArray() {
    return this.categoryForm.get('parameters') as FormArray;
  }

  loadCategoryParameterConfTexts() {
    this.textTypeService
      .getTextTypes('CategoryParameterConf')
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.parameterTextTypes = result;
      });
  }

  getProductSourceFilter() {
    return [
      {
        column: 'fromId',
        compareType: 'EQUAL',
        value: this.category.id,
      },
      {
        column: 'fromTypeId',
        compareType: 'EQUAL',
        value: 'CatTech',
      },
    ];
  }

  loadAcl() {
    this.getAclRules();
    this.getAclParentRules();
    if (!this.lastAclParameterName) {
      this.parameterChanged(null);
    }
  }

  getAllParameters() {
    const technicalCategoryParameterDtos = this.category.parameters
      ? this.category.parameters.concat(this.category.parentsParameters ? this.category.parentsParameters : [])
      : [];
    technicalCategoryParameterDtos.sort((a, b) => (a.name > b.name ? 1 : -1));
    return technicalCategoryParameterDtos;
  }

  private getAclRules() {
    this.adminAclService
      .filterRules(AclUtils.getCategoryResourceIdentificationFilter('PC_PRODUCT', this.category.id), null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(rules => {
        this.categoryAclRules = rules;
      });
  }

  private getAclParentRules() {
    const parentRulesCalls = [];

    if (this.category.parentId) {
      const parentCategories: Array<TechnicalCategoryDto> = [];
      this.getAllParentCategories(this.category, this.categories, parentCategories);
      parentCategories.forEach(parentCategory => {
        parentRulesCalls.push(
          this.adminAclService.filterRules(
            AclUtils.getCategoryResourceIdentificationFilter('PC_PRODUCT', parentCategory?.id),
            null
          )
        );
      });
    }
    parentRulesCalls.push(
      this.adminAclService.filterRules(AclUtils.getEmptyResourceIdentificationFilter('PC_PRODUCT'), null)
    );
    forkJoin(parentRulesCalls)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(results => {
        this.parentCategoriesAclRules = [];
        results.forEach((parentRules: PagedRulesDto) => {
          this.parentCategoriesAclRules = this.parentCategoriesAclRules.concat(parentRules.data);
        });
      });
  }

  getAllParentCategories(
    category: TechnicalCategoryDto,
    categories: Array<TechnicalCategoryDto>,
    parentCategories: Array<TechnicalCategoryDto>
  ) {
    if (category && category.parentId) {
      const parentCategory = categories.find(loopCategory => loopCategory.id === category.parentId);
      parentCategories.push(parentCategory);
      return this.getAllParentCategories(parentCategory, categories, parentCategories);
    }
    return;
  }

  resourceIdentificationContext = {
    id: null,
    parameter: {
      name: null,
    },
  };

  aclLoaded() {
    return this.categoryAclRules && this.parentCategoriesAclRules;
  }

  aclParametersLoaded() {
    return this.categoryParametersAclRules && this.parentCategoriesParametersAclRules;
  }

  private getAclParameterRules(parameterName: string) {
    const filter: Search = {
      filtering: [
        {
          column: 'resourceType',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: 'PC_PRODUCT_PARAMETER',
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    if (parameterName) {
      filter.filtering.push({
        column: 'resourceIdentification',
        compareType: CompareTypeDtoEnum.LIKE,
        value: JSON.stringify({
          category: this.category?.id ? this.category.id : '',
          productParamName: parameterName,
        }),
      });
    } else {
      filter.filtering.push({
        column: 'resourceIdentification',
        compareType: CompareTypeDtoEnum.LIKE,
        value: JSON.stringify({
          category: this.category?.id ? this.category.id : '',
        }),
      });
    }
    this.adminAclService
      .filterRules(filter, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(rules => {
        this.categoryParametersAclRules = rules;
      });
  }

  private getAclParameterParentRules(parameterName: string) {
    const parentRulesCalls = [];
    if (parameterName) {
      parentRulesCalls.push(
        this.adminAclService.filterRules(
          AclUtils.getCategoryResourceIdentificationFilter('PC_PRODUCT_PARAMETER', this.category.id),
          null
        )
      );
    }
    if (this.category.parentId) {
      const parentCategories: Array<TechnicalCategoryDto> = [];

      this.getAllParentCategories(this.category, this.categories, parentCategories);
      parentCategories.forEach(parentCategory => {
        if (parameterName) {
          const filter: Search = {
            filtering: [
              {
                column: 'resourceType',
                compareType: CompareTypeDtoEnum.EQUAL,
                value: 'PC_PRODUCT_PARAMETER',
              },
            ],
            sorting: [],
            paging: { page: 1, pageSize: -1 },
          };
          filter.filtering.push({
            column: 'resourceIdentification',
            compareType: CompareTypeDtoEnum.LIKE,
            value: JSON.stringify({
              category: parentCategory?.id ? parentCategory.id : '',
              productParamName: parameterName,
            }),
          });
          parentRulesCalls.push(this.adminAclService.filterRules(filter, null));
        }
        parentRulesCalls.push(
          this.adminAclService.filterRules(
            AclUtils.getCategoryResourceIdentificationFilter('PC_PRODUCT_PARAMETER', parentCategory?.id),
            null
          )
        );
      });
    }
    parentRulesCalls.push(
      this.adminAclService.filterRules(AclUtils.getEmptyResourceIdentificationFilter('PC_PRODUCT_PARAMETER'), null)
    );
    forkJoin(parentRulesCalls)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(results => {
        if (!this.parentCategoriesParametersAclRules) {
          this.parentCategoriesParametersAclRules = [];
        } else {
          this.parentCategoriesParametersAclRules.length = 0;
        }
        results.forEach((parentRules: PagedRulesDto) => {
          this.parentCategoriesParametersAclRules = this.parentCategoriesParametersAclRules.concat(parentRules.data);
        });
      });
  }

  parameterChanged(parameterName: string) {
    this.resourceIdentificationContext.parameter.name = parameterName;
    this.lastAclParameterName = parameterName;
    this.getAclParameterRules(parameterName);
    this.getAclParameterParentRules(parameterName);
  }

  editParent(parameter: TechnicalCategoryParameterDto) {
    this.editParentParameter = parameter;
  }
}
