import { Component } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AbstractPageComponent,
  AclService,
  AdminDmsService,
  AppBlockerService,
  CompareType,
  EnableDynamicLoading,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  NotificationAttachmentDto,
  NotificationDto,
  NotificationFrontendService,
  NotificationRecipientDto
} from '@btl/order-bff';
import { DmsFileDto } from '@btl/admin-bff';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotificationComponent } from '@components/notification/notification.component';
import {
  NotificationNotificationsListComponent,
} from '@components/notification/notifications/list/notification-notifications-list.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-notification-notifications-edit',
  templateUrl: './notification-notifications-edit.component.html',
  styleUrls: ['./notification-notifications-edit.component.scss'],
  providers: [NotificationFrontendService],
})
@EnableDynamicLoading({ customName: NotificationNotificationsEditComponent.PAGE_ID })
export class NotificationNotificationsEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'NotificationNotificationsEditComponent';

  pageId(): string {
    return NotificationNotificationsEditComponent.PAGE_ID;
  }

  notificationDto: NotificationDto;
  notificationAttachments: DmsFileDto[] = [];
  notificationInitialized = false;
  editorConfig: AngularEditorConfig = NotificationComponent.getDisabledEditorConfig();

  notificationForm: FormGroup = this.formBuilder.group({
    fromName: [],
    notificationType: [],
    state: [],
    subject: [],
    gateway: [],
    channelTypeId: [],
    sent: [],
    toBeSent: [],
    note: [],
    body: [],
    bodyAlternative: [],
    recipientTo: [],
    recipientCc: [],
    recipientBcc: [],
    partyRefNo: [],
  });

  get bodyAlternativeFormControl(): AbstractControl {
    return this.notificationForm.get('bodyAlternative');
  }

  constructor(
    public aclService: AclService,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    private adminDmsService: AdminDmsService,
    private httpClient: HttpClient,
    private notificationFrontendService: NotificationFrontendService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const notificationId = this.params.id;
      this.setNotificationDto(notificationId);
    }
  }

  setNotificationDto(notificationId: string, hard = false): void {
    if (
      notificationId &&
      notificationId !== '&' &&
      notificationId !== 'newNotificationType' &&
      (hard || !this.notificationDto || this.notificationDto.id !== notificationId)
    ) {
      this.appBlockerService.block();
      this.notificationFrontendService
        .getNotificationById(notificationId)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: NotificationDto) => {
          this.notificationDto = result;
          this.initForm();
        });
    } else {
      this.cancel();
    }
  }

  initForm(): void {
    this.notificationForm.patchValue(this.notificationDto);
    this.notificationForm.get('fromName').setValue(this.getFromValue());
    this.notificationForm.get('recipientTo').setValue(this.getRecipientValue('TO'));
    this.notificationForm.get('recipientCc').setValue(this.getRecipientValue('CC'));
    this.notificationForm.get('recipientBcc').setValue(this.getRecipientValue('BCC'));
    this.notificationForm.get('partyRefNo').setValue(this.getPartyValue());
    this.notificationForm.disable();
    this.initAttachments();
    this.notificationInitialized = true;
  }

  resend(): void {
    if (!['COMPLETED', 'CANCELLED', 'FAILED'].includes(this.notificationDto.state)) {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.notification.resend.error');
      return;
    }
    this.appBlockerService.block();
    this.notificationFrontendService
      .patchNotification(this.notificationDto.id, { state: 'INITIAL' })
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: NotificationDto) => {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        this.setNotificationDto(result.id);
      });
  }

  delete(): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.notification.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.notificationFrontendService
        .deleteNotification(this.notificationDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: NotificationDto) => {
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
          this.cancel();
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  cancel(): void {
    this.navigateSibling(NotificationNotificationsListComponent.PAGE_ID);
  }

  downloadAttachment(attachment: DmsFileDto): void {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    this.appBlockerService.block();
    this.httpClient
      .get(attachment.contentHref, httpOptions)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: any) => {
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = attachment.name;
        link.click();
      });
  }

  private getFromValue(): string {
    let value = '';
    if (this.notificationDto.fromName) {
      value += this.notificationDto.fromName;
    }
    if (this.notificationDto.fromName && this.notificationDto.fromAddress) {
      value += ` (${this.notificationDto.fromAddress})`;
    } else if (!this.notificationDto.fromName && this.notificationDto.fromAddress) {
      value += this.notificationDto.fromAddress;
    }
    return value;
  }

  private getRecipientValue(recipientType: string): string {
    let value = '';
    if (this.notificationDto?.recipients?.length) {
      const recipientList = this.notificationDto.recipients.filter(i => i.recipientType === recipientType) || [];
      recipientList.forEach((recipientDto: NotificationRecipientDto, index: number) => {
        if (recipientDto.recipientName) {
          value += recipientDto.recipientName;
        }
        if (recipientDto.recipientName && recipientDto.address) {
          value += ` (${recipientDto.address})`;
        } else if (!recipientDto.recipientName && recipientDto.address) {
          value += recipientDto.address;
        }
        if (value && index < recipientList.length - 1) {
          value += ', ';
        }
      });
    }
    return value;
  }

  private getPartyValue(): string {
    let value = '';
    if (this.notificationDto?.recipients?.length) {
      const recipients = this.notificationDto.recipients.filter(i => i.partyRefNo && i.partyRefNo.length);
      recipients.forEach((recipientDto: any, index: number) => {
        if (recipientDto.partyRefNo) {
          value += recipientDto.partyRefNo + (index < recipients.length - 1 ? ', ' : '');
        }
      });
    }
    return value;
  }

  private initAttachments(): void {
    this.notificationAttachments = [];
    if (this.notificationDto?.attachments?.length) {
      const extIds = [];
      const inlineExtIds = [];
      this.notificationDto.attachments.forEach((attachment: NotificationAttachmentDto) => {
        if (attachment.inline === true) {
          inlineExtIds.push(attachment.extId);
        } else {
          extIds.push(attachment.extId);
        }
      });
      if (extIds.length || inlineExtIds.length) {
        const search = ServiceUtils.getUnlimitedSearch();
        search.filtering.push({
          column: 'extId',
          compareType: CompareTypeDtoEnum.IN,
          value: [...extIds, ...inlineExtIds],
        });
        this.adminDmsService
          .filterFiles(search, null)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.notificationAttachments = result.data;
            this.addInlineAttachment(inlineExtIds);
          });
      }
    }
  }

  private addInlineAttachment(inlineExtIds: string[]): void {
    if (this.notificationAttachments.length && inlineExtIds.length) {
      const notificationInlineAttachments = this.notificationAttachments.filter(i => inlineExtIds.includes(i.extId));

      const httpClientCalls = [];
      notificationInlineAttachments.forEach((dmsFileDto: DmsFileDto) => {
        if (dmsFileDto.contentHref && dmsFileDto.mediaType.includes('image')) {
          httpClientCalls.push(this.httpClient.get(dmsFileDto.contentHref, { responseType: 'blob' as 'json' }));
        }
      });

      if (httpClientCalls.length) {
        this.appBlockerService.block();
        forkJoin(httpClientCalls)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(results => {
            if (results?.length) {
              results.forEach((result: Blob) => {
                result.text().then((text: string) => {
                  this.bodyAlternativeFormControl.setValue(`${(this.bodyAlternativeFormControl.value || '') + text}\n`);
                });
              });
            }
          });
      }
    }
  }
}
