import { Component } from '@angular/core';
import { AbstractFilterComponent, CompareType } from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Filter } from '@btl/btl-fe-wc-common/lib/models/search';
import {
  MemberTypes,
} from '@components/product-catalogue/import-export/release-edit/members-listing/members-listing.component';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-releases-filter',
  templateUrl: './releases-filter.component.html',
  styleUrls: ['./releases-filter.component.scss'],
})
export class ReleasesFilterComponent extends AbstractFilterComponent {
  PickerInputType = PickerInputType;

  techCategories: Array<string> = [];

  memberTypes = Object.keys(MemberTypes).map(key => MemberTypes[key]);

  mapFieldWithCompareType = new Map([
    ['prams', CompareTypeDtoEnum.EQUAL],
    ['memberDef.name', CompareTypeDtoEnum.EQUAL],
  ]);

  mapFieldWithColumnName = new Map([
    ['memberType', 'memberDef.name'],
    ['code', 'member.extId'],
  ]);

  constructor(private formBuilder: FormBuilder, private enumerationsService: EnumerationsService) {
    super();

    this.enumerationsService
      .getProductTechnicalCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.techCategories = result;
      });

    this.filterForm = this.formBuilder.group({
      releaseCode: [],
      memberType: [],
      releaseDateFrom: [],
      releaseDateTo: [],
      code: [],
    });
  }

  public handleChange(): void {
    this.handleParameterChange('params', 'releaseCode', this.filterForm.controls.releaseCode.value);
    this.handleField('memberType');
    this.handleField('code');
    this.handleParameterChange(
      'params',
      'releaseDate',
      this.filterForm.controls.releaseDateFrom.value,
      CompareTypeDtoEnum.BIGGEREQ
    );
    this.handleParameterChange(
      'params',
      'releaseDate',
      this.filterForm.controls.releaseDateTo.value,
      CompareTypeDtoEnum.SMALLEREQ
    );

    this.filterChanged.emit();
  }

  handleParameterChange(paramColumn: string, paramName: string, paramValue: any, compareType?: CompareTypeDtoEnum) {
    if (!compareType) {
      compareType = CompareTypeDtoEnum.EQUAL;
    }
    const filter: Filter = this.getFilterByColumnAndCompareType(paramColumn, CompareTypeDtoEnum.EQUAL);
    if (filter) {
      const filterParams = filter.value;
      const param = filterParams.find(
        filterParam => filterParam.name === paramName && compareType === filterParam.compareType
      );
      if (paramValue) {
        if (param) {
          param.value = paramValue;
        } else {
          filterParams.push({
            name: paramName,
            compareType: compareType,
            value: paramValue,
          });
        }
      } else if (param) {
        filterParams.splice(filterParams.indexOf(param), 1);
        if (filterParams.length === 0) {
          this.filtering.splice(this.filtering.indexOf(filter), 1);
        }
      }
    } else {
      if (paramValue) {
        this.filtering.push({
          column: paramColumn,
          compareType: CompareTypeDtoEnum.EQUAL,
          value: [
            {
              name: paramName,
              compareType: compareType,
              value: paramValue,
            },
          ],
        });
      }
    }
  }
}
