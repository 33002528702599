import { Component } from '@angular/core';
import {
  AclService,
  AdminStockService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { PagedStocksDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { StockEditComponent } from '@components/resource/stock/store-edit/stock-edit.component';
import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-stocks-listing',
  templateUrl: './stocks-listing.component.html',
})
@EnableDynamicLoading({ customName: StocksListingComponent.PAGE_ID })
export class StocksListingComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'StocksListingComponent';

  pageId(): string {
    return StocksListingComponent.PAGE_ID;
  }

  pagedStocksDto: PagedStocksDto;

  constructor(
    private adminStockService: AdminStockService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    public aclService: AclService,
    protected confirmationDialogService: ConfirmationDialogService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  editStock(stockDto, inNewTab: boolean = false) {
    this.navigateSibling(StockEditComponent.PAGE_ID, { id: stockDto.id }, inNewTab);
  }

  newStock() {
    this.editStock({
      id: 'newStock',
    });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminStockService
        .filterStocks(this.search)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedStocksDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminStockService.deleteStock(rowDataId);
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminStockService.deleteStocks(bulkDelete);
  }
}
