import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AbstractFilterComponent, CompareType } from '@btl/btl-fe-wc-common';
import { ProductStateDto } from '@btl/admin-bff';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  animations: [Animations.advancedFilterArrow],
})
export class ProductFilterComponent extends AbstractFilterComponent {

  versioningTypes = [
    { labelKey: 'wc.admin.products.product.versioning.SINGLE_ACTIVE', value: 'SINGLE_ACTIVE' },
    { labelKey: 'wc.admin.products.product.versioning.MULTI_ACTIVE', value: 'MULTI_ACTIVE' },
  ];
  states = Object.values(ProductStateDto);

  mapFieldWithCompareType = new Map([
    ['versioningType', CompareTypeDtoEnum.EQUAL],
    ['description', CompareTypeDtoEnum.LIKE],
    ['categoryId', CompareTypeDtoEnum.EQUAL],
    ['productCode', CompareTypeDtoEnum.LIKE],
    ['seoUrl', CompareTypeDtoEnum.LIKE],
    ['state', CompareTypeDtoEnum.IN],
    ['priceType', CompareTypeDtoEnum.EQUAL],
    ['priceFrom', CompareTypeDtoEnum.BIGGEREQ],
    ['priceTo', CompareTypeDtoEnum.SMALLER],
    ['priceParameterName', CompareTypeDtoEnum.EQUAL],
    ['priceParameterValue', CompareTypeDtoEnum.EQUAL],
    ['parameterName', CompareTypeDtoEnum.EQUAL],
    ['parameterValue', CompareTypeDtoEnum.EQUAL],
  ]);

  mapFieldWithColumnName = new Map([
    ['priceType', 'prices.priceType'],
    ['priceFrom', 'prices.price'],
    ['priceTo', 'prices.price'],
    ['priceParameterName', 'prices.priceParams.name'],
    ['priceParameterValue', 'prices.priceParams.value'],
    ['parameterName', 'productParameters.name'],
    ['parameterValue', 'productParameters.value'],
  ]);

  advancedFilterFields = ['versioningType', 'priceType', 'priceFrom', 'priceTo', 'priceParameterName', 'priceParameterValue', 'parameterName', 'parameterValue'];

  constructor(private formBuilder: FormBuilder) {
    super();

    this.filterForm = this.formBuilder.group({
      versioningType: [],
      description: [],
      categoryId: [null],
      productCode: [],
      seoUrl: [],
      state: [],
      priceType: [],
      priceFrom: [],
      priceTo: [],
      priceParameterName: [],
      priceParameterValue: [],
      parameterName: [],
      parameterValue: [],
    });
  }
}
