<div class="main__header">
  <h1 id="001_cart_event_edit">{{ 'wc.admin.cartEvents.cartEventEdit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_cart_event_edit"
      color="primary"
      mat-raised-button
      *ngIf="aclService.pageEditAccess"
      (click)="save()"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="003_cart_event_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_cart_event_edit" mat-menu-item (click)="duplicate()" *ngIf="cartEvent?.id" [routerLink]=""
      >
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="005_cart_event_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="006_cart_event_edit" mat-menu-item (click)="delete()" *ngIf="cartEvent?.id" [routerLink]=""
      >
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="007_cart_event_edit" (click)="cancel()" class="btn cancel-button">{{
      'wc.common.cancel.button' | translate
    }}</a>
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.cartEvents' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="cartEvent" [formGroup]="cartEventForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="cartEventForm"
                  [controlName]="'code'"
                  id="008_cart_event_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.cartEvents'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  id="009_cart_event_edit"
                  [formControlName]="'fromTypeId'"
                  [options]="productEntityTypes"
                  (selectionChangeEmit)="clearField('fromId')"
                  [translationPrefix]="'wc.admin.cartEvents'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <ng-container [ngSwitch]="cartEventForm.controls['fromTypeId'].value">
                  <app-input-select-form-field
                    *ngSwitchCase="'CatTech'"
                    id="010_cart_event_edit"
                    [formControlName]="'fromId'"
                    [options]="techCategories"
                    [translationPrefix]="'wc.admin.cartEvents'">
                  </app-input-select-form-field>
                  <app-input-select-form-field
                    *ngSwitchCase="'CatBsns'"
                    [formControlName]="'fromId'"
                    id="011_cart_event_edit"
                    [options]="businessCategories"
                    [translationPrefix]="'wc.admin.cartEvents'">
                  </app-input-select-form-field>
                  <app-input-picker-form-field
                    *ngSwitchCase="'ProdCode'"
                    [parentGroup]="cartEventForm"
                    id="012_cart_event_edit"
                    [controlName]="'fromId'"
                    [valueField]="'productCode'"
                    [pickerType]="PickerInputType.PRODUCT"
                    [selectMode]="false"
                    [translationPrefix]="'wc.admin.cartEvents'">
                  </app-input-picker-form-field>
                  <app-input-picker-form-field
                    *ngSwitchCase="'ProdGroup'"
                    [parentGroup]="cartEventForm"
                    [controlName]="'fromId'"
                    id="014_cart_event_edit"
                    valueField="id"
                    labelField="code"
                    [pickerType]="PickerInputType.GROUP"
                    [selectMode]="false"
                    [translationPrefix]="'wc.admin.cartEvents'">
                  </app-input-picker-form-field>
                  <app-custom-input
                    [parentGroup]="cartEventForm"
                    [controlName]="'fromId'"
                    [inputType]="'text'"
                    id="015_cart_event_edit"
                    *ngSwitchDefault
                    [translationPrefix]="'wc.admin.cartEvents'">
                  </app-custom-input>
                </ng-container>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'eventType'"
                  [options]="eventTypes"
                  id="016_cart_event_edit"
                  [translationPrefix]="'wc.admin.cartEvents'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-input-picker-form-field
                  [parentGroup]="cartEventForm"
                  [controlName]="'toProductMasterId'"
                  [valueField]="'productCode'"
                  id="017_cart_event_edit"
                  [pickerType]="PickerInputType.PRODUCT"
                  [selectMode]="false"
                  [label]="'targetId'"
                  [translationPrefix]="'wc.admin.cartEvents'">
                </app-input-picker-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="cartEventForm"
                  [controlName]="'priority'"
                  id="018_cart_event_edit"
                  [inputType]="'number'"
                  [translationPrefix]="'wc.admin.cartEvents'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'scopeType'"
                  id="019_cart_event_edit"
                  [options]="scopes"
                  [translationPrefix]="'wc.admin.cartEvents'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'connectionType'"
                  id="020_cart_event_edit"
                  [options]="productConnectionTypes"
                  [showEmptyOption]="true"
                  [translationPrefix]="'wc.admin.cartEvents'">
                </app-input-select-form-field>
              </div>

              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'connectionDirectionType'"
                  id="021_cart_event_edit"
                  [options]="productConnectionDirections"
                  [showEmptyOption]="true"
                  [translationPrefix]="'wc.admin.cartEvents'">
                </app-input-select-form-field>
              </div>

              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'connectionLinkType'"
                  id="022_cart_event_edit"
                  [options]="productLinkType"
                  [showEmptyOption]="true"
                  [translationPrefix]="'wc.admin.cartEvents'">
                </app-input-select-form-field>
              </div>
              <ng-container formGroupName="validFor">
                <div class="form__row">
                  <app-input-date-form-field
                    [parentGroup]="cartEventForm.controls['validFor']"
                    [controlName]="'startDateTime'"
                    id="023_cart_event_edit"
                    [label]="'validFrom'"
                    [translationPrefix]="'wc.admin.cartEvents'">
                  </app-input-date-form-field>
                </div>
                <div class="form__row">
                  <app-input-date-form-field
                    [parentGroup]="cartEventForm.controls['validFor']"
                    [label]="'validTo'"
                    id="024_cart_event_edit"
                    [controlName]="'endDateTime'"
                    [translationPrefix]="'wc.admin.cartEvents'">
                  </app-input-date-form-field>
                </div>
              </ng-container>

              <div class="form__row">
                <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                  <mat-label>{{ 'wc.admin.cartEvents.note' | translate }}</mat-label>
                  <textarea
                    id="025_cart_event_edit"
                    class="form__input"
                    matInput
                    type="text"
                    formControlName="note"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div [formGroup]="cartEventForm" class="card__wrapper">
    <app-eligibility-expression [parentGroup]="cartEventForm" class="card -full" formControlName="ruleSetExpression">
    </app-eligibility-expression>
  </div>
</div>
