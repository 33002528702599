<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <ng-container *ngIf="!hideAccountsFilters">
            <app-input-picker-form-field
              [parentGroup]="filterForm"
              [controlName]="'accountId'"
              [labelField]="'login'"
              id="001_accounts_entity_filter"
              [valueField]="'id'"
              [pickerType]="PickerInputType.ACCOUNT"
              [selectMode]="false"
              [label]="'accountId'"
              (change)="handleChange()"
              [translationPrefix]="'wc.admin.accountEntity'">
            </app-input-picker-form-field>
            <app-input-picker-form-field
              [parentGroup]="filterForm"
              [controlName]="'delegatedByAccountId'"
              [labelField]="'login'"
              id="002_accounts_entity_filter"
              [valueField]="'id'"
              [pickerType]="PickerInputType.ACCOUNT"
              [selectMode]="false"
              [label]="'delegatedByAccountId'"
              (change)="handleChange()"
              [translationPrefix]="'wc.admin.accountEntity'">
            </app-input-picker-form-field>
          </ng-container>
          <app-input-select-form-field
            [formControlName]="'entityType'"
            [options]="entityTypes"
            id="003_accounts_entity_filter"
            [labelOption]="'name'"
            [valueOption]="'name'"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.accountEntity'">
          </app-input-select-form-field>
          <app-custom-input
            [controlName]="'entityId'"
            [inputType]="'text'"
            id="004_accounts_entity_filter"
            [translationPrefix]="'wc.admin.accountEntity'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-input-date-form-field
          [parentGroup]="filterForm"
          id="007_accounts_entity_filter"
          [controlName]="'validAt'"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.accountEntity'">
        </app-input-date-form-field>
        <app-input-select-form-field
          [formControlName]="'roleType'"
          [options]="aclRoles"
          [labelOption]="'id'"
          id="008_accounts_entity_filter"
          [valueOption]="'id'"
          (keydown.enter)="handleChange()"
          [showEmptyOption]="true"
          [translationPrefix]="'wc.admin.accountEntity'">
        </app-input-select-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</form>
