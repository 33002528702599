import { Component } from '@angular/core';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-account',
  template: '<router-outlet></router-outlet>',
})
@EnableDynamicLoading({ customName: ImportExportComponent.PAGE_ID })
export class ImportExportComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'ImportExportComponent';

  pageId(): string {
    return ImportExportComponent.PAGE_ID;
  }

  constructor(protected router: Router, protected route: ActivatedRoute) {
    super(router, route);
  }
}
