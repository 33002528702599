<h2 mat-dialog-title>
  {{ 'wc.admin.cms.content.list' | translate }}
  <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>

<mat-dialog-content class="mat-typography">
  <div class=".mat-drawer-container">
    <div class="mat-sidenav-content">
      <div class="main_content">
        <div class="card__wrapper widget">
          <div class="card -full">
            <div class="card__inner widget">
              <div class="card__body widget">
                <app-cms-content-banner-modal-filter
                  [filtering]="search.filtering"
                  (filterChanged)="handleFilterChange()">
                </app-cms-content-banner-modal-filter>
              </div>
            </div>

            <div class="card__inner widget">
              <div class="card__header widget border-unset" fxLayout="row" fxLayoutAlign="space-between">
                <div fxLayout="column">
                  <span class="listing-header">{{ 'wc.admin.cms.staticPage.list' | translate }}</span>
                  <div class="paginator__entries">
                    {{
                    'wc.common.pagination.showing'
                      | translate
                      : {
                        from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                        to:
                          search.paging.pageSize * search.paging.page > pagedContentsDto?.totalItems
                            ? pagedContentsDto?.totalItems
                            : search.paging.pageSize * search.paging.page,
                        of: pagedContentsDto?.totalItems
                      }
                    }}
                  </div>
                </div>
              </div>

              <div class="card__body widget">
                <app-table-listing-form
                  [module]="'cms'"
                  [expandType]="'boxes'"
                  (doubleClickEvent)="onSelectContent($event)"
                  (getChildDataEvent)="onSelectContent($event)"
                  [parentColumnName]="[
                      'code',
                      'name',
                      'keys.keyValue',
                      'contentTemplateType',
                      'state',
                      'isInternal',
                      'created',
                      'modified',
                      'modifiedBy'
                    ]"
                  [fillIdBy]="'code'"
                  [columnToTruncate]="['created', 'modified', 'modifiedBy']"
                  [pagedDto]="pagedContentsDto"
                  (sortChangeEvent)="onSortChange($event)"
                  [cacheFilter]="this.search"
                  [translationPrefix]="'wc.admin.cms.content.list'">
                </app-table-listing-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
