import { Component, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminDynamicEnumService,
  AdminProductGroupService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EnumEntryDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '../../../../service/confirmation-dialog.service';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import { GroupTypeListingComponent } from '@components/product-catalogue/groupType/group-type-listing.component';

@Component({
  selector: 'app-group-type-edit',
  templateUrl: './group-type-edit.component.html',
  styleUrls: ['./group-type-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: GroupTypeEditComponent.PAGE_ID })
export class GroupTypeEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'GroupTypeEditComponent';

  pageId(): string {
    return GroupTypeEditComponent.PAGE_ID;
  }

  readonly SOURCE_NAME = EnumerationsService.PC;
  readonly ENUM_NAME = 'com.emeldi.ecc.be.pc.enums.ProductGroupType';

  routerOutlet: any;

  editGroupTypeChanged = new EventEmitter<EnumEntryDto>();

  private groupType: EnumEntryDto = {
    name: null,
    fields: null,
  };

  public groupTypeParameters: string[];

  private groupTypeName: string;
  isNew = true;

  groupTypeForm: FormGroup = this.formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(200)]],
    fields: [],
  });

  get nameControl(): AbstractControl {
    return this.groupTypeForm.get('name');
  }

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private adminProductGroupService: AdminProductGroupService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  loadGroupType(groupTypeName: string) {
    this.adminDynamicEnumService
      .getEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, groupTypeName, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setGroupType(result, false);
        this.groupTypeName = this.groupType.name;
      });
    this.adminProductGroupService
      .getGroupTypeUsedParameterNames(groupTypeName)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.groupTypeParameters = result;
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const groupTypeId = this.params.id;
      if (groupTypeId) {
        if (groupTypeId === '&') {
          this.setGroupType(this.groupType, true);
        } else {
          this.loadGroupType(groupTypeId);
        }
      }
    } else {
      this.groupType = undefined;
    }
  }

  setGroupType(enumEntryDto: EnumEntryDto, isNew: boolean) {
    this.groupType = enumEntryDto;
    this.groupTypeForm.patchValue(this.groupType);

    if (enumEntryDto) {
      this.editGroupTypeChanged.emit(this.groupType);
    }

    this.isNew = isNew;
    if (!this.isNew) {
      this.nameControl.disable();
    } else {
      this.nameControl.enable();
    }
  }

  saveGroupType(): boolean {
    FormUtils.validateAllFormFields(this.groupTypeForm);
    if (this.groupTypeForm.valid) {
      this.groupType = this.groupTypeForm.getRawValue();
      if (this.groupType.fields) {
        this.groupType.fields['name'] = this.groupType.name;
      }

      return true;
    }
    return false;
  }

  save() {
    if (this.saveGroupType()) {
      this.appBlockerService.block();
      if (!(this.groupTypeName === null || this.groupTypeName === undefined)) {
        this.adminDynamicEnumService
          .updateEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, this.groupTypeName, this.groupType)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getGroupTypeHandler);
      } else {
        this.adminDynamicEnumService
          .createEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, this.groupType)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getGroupTypeHandler);
      }
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditGroupType(): EnumEntryDto {
    return this.groupType;
  }

  public reset() {
    if (this.groupType && this.groupType.name) {
      this.appBlockerService.block();
      this.adminDynamicEnumService
        .getEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, this.groupType.name, true)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(this.getGroupTypeHandler);
    } else {
      this.groupTypeForm.reset(this.groupTypeForm);
      this.setGroupType(this.groupType, this.isNew);
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.groupType.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminDynamicEnumService
        .deleteEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, this.groupType.name)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(GroupTypeListingComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  public duplicate() {
    const newGroupType = this.duplicateEnumEntryDto(this.groupType);
    this.setGroupType(newGroupType, true);
    this.groupTypeName = null;
    this.navigateSelf({ id: '&' });
  }

  public getGroupTypeHandler = (enumEntryDto: EnumEntryDto): void => {
    let saving = false;
    if (this.groupType) {
      saving = true;
    }
    if (enumEntryDto && this.groupType) {
      if (enumEntryDto.name === this.groupType.name) {
        this.setGroupType(enumEntryDto, this.isNew);
        this.navigateSelf({ id: enumEntryDto.name });
      } else {
        this.setGroupType(enumEntryDto, this.isNew);
        this.groupTypeName = enumEntryDto.name;
        this.navigateSelf({ id: enumEntryDto.name });
      }
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  cancel() {
    this.navigateSibling(GroupTypeListingComponent.PAGE_ID);
  }

  duplicateEnumEntryDto(enumEntryDto: EnumEntryDto): EnumEntryDto {
    return {
      name: `${enumEntryDto.name}-copy`,
      fields: this.duplicateDictionary(enumEntryDto.fields),
    };
  }

  duplicateDictionary(dictionary: { [key: string]: any }): { [key: string]: any } {
    if (!dictionary) {
      return null;
    }
    const newDictionary: { [key: string]: any } = {};
    Object.keys(dictionary).forEach(key => {
      newDictionary[key] = dictionary[key];
    });
    return newDictionary;
  }
}
