import { Component } from '@angular/core';
import {
  AclService,
  AdminAclService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { PagedRolesDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { RoleEditComponent } from '@components/acl/roles/role-edit/role-edit.component';

import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';

@Component({
  selector: 'app-roles-listing',
  templateUrl: './roles-listing.component.html',
  styleUrls: ['./roles-listing.component.scss'],
})
@EnableDynamicLoading({ customName: RolesListingComponent.PAGE_ID })
export class RolesListingComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'RolesListingComponent';

  pageId(): string {
    return RolesListingComponent.PAGE_ID;
  }

  pagedRolesDto: PagedRolesDto;

  constructor(
    private adminAclService: AdminAclService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
    protected confirmationDialogService: ConfirmationDialogService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  editRole(roleDto, inNewTab: boolean = false) {
    this.navigateSibling(RoleEditComponent.PAGE_ID, { id: roleDto.id }, inNewTab);
  }

  newRole() {
    this.editRole({
      id: 'newRole',
    });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const accountFilter = this.search.filtering.find(filter => filter.column === 'accountId');
      if (accountFilter) {
        this.search.filtering = this.search.filtering.filter(filter => filter.column !== 'accountId');
      }
      this.adminAclService
        .filterRoles(this.search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedRolesDto = result;
            if (accountFilter) {
              this.search.filtering.push(accountFilter);
            }
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminAclService.deleteRoles(bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminAclService.deleteRole(rowDataId);
  }
}
