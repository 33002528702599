<div class="main__header">
  <div class="header-box">
    <div class="icon-box">
      <mat-icon svgIcon="ticketEnums"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.dataTypeEnums.' + sourceName() | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      id="001_dataTypeEnum_listing"
      (click)="newDataTypeEnum()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      color="primary"
      mat-raised-button
      [title]="'wc.admin.dataTypeEnums.new.title' | translate"
      class="btn -primary -xs"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.dataTypeEnums.dataTypeEnumListing' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: 1,
                      to: dataTypeEnums?.length,
                      of: dataTypeEnums?.length
                    }
            }}
          </div>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="['name', 'created', 'modified', 'modifiedBy']"
          [columnToTruncate]="['created', 'modified', 'modifiedBy']"
          [dontShowOnMobile]="['created', 'modified', 'modifiedBy']"
          [fillIdBy]="'name'"
          (editButtonEvent)="editDataTypeEnum($event)"
          (editInNewTabButtonEvent)="editDataTypeEnum($event, true)"
          (deleteButtonEvent)="deleteDataTypeEnum($event)"
          [customData]="dataTypeEnums"
          [enablePaginator]="false"
          [multiSelectMode]="false"
          [translationPrefix]="'wc.admin.dataTypeEnum'"
          (sortChangeEvent)="onSortChange($event)"
          [editable]="aclService.pageEditAccess"
          (doubleClickEvent)="editDataTypeEnum($event)">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
