import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-group-type-filter',
  templateUrl: './group-type-filter.component.html',
})
export class GroupTypeFilterComponent {
  @Output()
  readonly filterChanged: EventEmitter<string> = new EventEmitter<string>();

  filterForm = this.formBuilder.group({
    name: [],
  });

  constructor(private formBuilder: FormBuilder) {}

  filterByName(value: string) {
    this.filterChanged.emit(value);
  }
}
