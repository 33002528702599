<div class="page_wrapper">
  <div class="main__header" *ngIf="navigationMode">
    <h1 id="001_relationship_edit">{{ 'wc.admin.relationships.relationshipEdit' | translate }}</h1>
    <div class="page__menu">
      <a
        id="002_relationship_edit"
        color="primary"
        mat-raised-button
        (click)="save()"
        *ngIf="aclService.pageEditAccess"
        [routerLink]=""
        class="btn -primary -xs icon stroke currentColor"
        ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
      >
      <a
        id="016_relationship_edit"
        color="primary"
        mat-stroked-button
        *ngIf="aclService.pageEditAccess"
        [routerLink]=""
        class="btn -primary -xs more-actions icon dropDownIcon currentColor"
        [matMenuTriggerFor]="menu"
        #t="matMenuTrigger"
        >{{ 'wc.common.more.actions.button' | translate }}
        <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
      </a>
      <mat-menu #menu="matMenu" class="module-actions">
        <a id="006_relationship_edit" mat-menu-item (click)="duplicate()" *ngIf="relationship?.id" [routerLink]=""
        >
          <mat-icon svgIcon="duplicate"></mat-icon>
          {{ 'wc.common.duplicate' | translate }}</a
        >
        <a id="003_relationship_edit" mat-menu-item (click)="reset()" [routerLink]=""
          ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
        >
        <a id="005_relationship_edit" mat-menu-item (click)="delete()" *ngIf="relationship?.id" [routerLink]=""
        >
          <mat-icon svgIcon="delete"></mat-icon>
          {{ 'wc.common.delete' | translate }}</a
        >
      </mat-menu>
      <a id="004_relationship_edit" (click)="cancel()" class="btn cancel-button">{{
        'wc.common.cancel.button' | translate
      }}</a>
    </div>
  </div>
  <div class="card__wrapper">
    <!-- card -->
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header" *ngIf="navigationMode">
          {{ 'wc.admin.relationships' | translate }}
        </div>
        <div class="card__body">
          <form *ngIf="relationship" [formGroup]="relationshipForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="relationshipForm"
                    [controlName]="'code'"
                    [inputType]="'text'"
                    id="015_relationship_edit"
                    [translationPrefix]="'wc.admin.relationships'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="relationshipForm"
                    [controlName]="'priority'"
                    [inputType]="'number'"
                    id="007_relationship_edit"
                    [translationPrefix]="'wc.admin.relationships'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'type'"
                    [options]="relationshipTypes"
                    id="017_relationship_edit"
                    [disableOptionsTranslate]="true"
                    [translationPrefix]="'wc.admin.relationships'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'meaningType'"
                    [options]="meaningTypes"
                    id="018_relationship_edit"
                    [disableOptionsTranslate]="true"
                    [translationPrefix]="'wc.admin.relationships'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'scopeType'"
                    [options]="scopeTypes"
                    id="019_relationship_edit"
                    [disableOptionsTranslate]="true"
                    [translationPrefix]="'wc.admin.relationships'">
                  </app-input-select-form-field>
                </div>

                <!--              Source-->
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'sourceType'"
                    [options]="sourceProductEntityTypes"
                    id="020_relationship_edit"
                    (selectionChangeEmit)="clearField('sourceId'); clearField('sourceName')"
                    [disableOptionsTranslate]="true"
                    [translationPrefix]="'wc.admin.relationships'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <ng-container [ngSwitch]="relationshipForm.controls['sourceType'].value">
                    <app-input-select-form-field
                      *ngSwitchCase="'CatTech'"
                      [formControlName]="'sourceId'"
                      id="021_relationship_edit"
                      [options]="techCategories"
                      [translationPrefix]="'wc.admin.relationships'"
                      [disableOptionsTranslate]="true"
                      (selectionChangeEmit)="changeSourceNameCat($event)">
                    </app-input-select-form-field>
                    <app-input-picker-form-field
                      *ngSwitchCase="'ProdCode'"
                      [parentGroup]="relationshipForm"
                      [controlName]="'sourceId'"
                      [valueField]="'productCode'"
                      id="023_relationship_edit"
                      [pickerType]="PickerInputType.PRODUCT"
                      [singleSelect]="!!relationship.recordVersion || sourceTargetMode === 'source'"
                      [selectMode]="!relationship.recordVersion"
                      [showDescription]="true"
                      [translationPrefix]="'wc.admin.relationships'"
                      (selectionChanged)="changeSourceNameProd($event)">
                    </app-input-picker-form-field>
                    <app-input-picker-form-field
                      *ngSwitchCase="'ProdGroup'"
                      [parentGroup]="relationshipForm"
                      [controlName]="'sourceId'"
                      [valueField]="'id'"
                      labelField="code"
                      id="025_relationship_edit"
                      [pickerType]="PickerInputType.GROUP"
                      [selectMode]="false"
                      [translationPrefix]="'wc.admin.relationships'"
                      (selectionChanged)="changeSourceNameGroup($event)">
                    </app-input-picker-form-field>
                    <app-custom-input
                      *ngSwitchDefault
                      [parentGroup]="relationshipForm"
                      [controlName]="'sourceId'"
                      [inputType]="'text'"
                      id="008_relationship_edit"
                      [translationPrefix]="'wc.admin.relationships'">
                    </app-custom-input>
                  </ng-container>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="relationshipForm"
                    [controlName]="'sourceName'"
                    id="relationship_edit_sourceName"
                    [disabled]="true"
                    [label]="'sourceName'"
                    [translationPrefix]="'wc.admin.relationships'">
                  </app-custom-input>
                </div>
                <ng-container>
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="relationshipForm.controls['sourceCardinalityMinMax']"
                      [controlName]="'min'"
                      [inputType]="'number'"
                      id="009_relationship_edit"
                      [label]="'sourceCardinalityMin'"
                      [translationPrefix]="'wc.admin.relationships'">
                    </app-custom-input>
                  </div>
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="relationshipForm.controls['sourceCardinalityMinMax']"
                      [controlName]="'max'"
                      [inputType]="'number'"
                      id="010_relationship_edit"
                      [label]="'sourceCardinalityMax'"
                      [translationPrefix]="'wc.admin.relationships'">
                    </app-custom-input>
                  </div>
                </ng-container>
                <!--Target-->
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'targetType'"
                    [options]="targetProductEntityTypes"
                    id="026_relationship_edit"
                    (selectionChangeEmit)="clearField('targetId'); clearField('targetName')"
                    [disableOptionsTranslate]="true"
                    [translationPrefix]="'wc.admin.relationships'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <ng-container [ngSwitch]="relationshipForm.controls['targetType'].value">
                    <app-input-select-form-field
                      *ngSwitchCase="'CatTech'"
                      [formControlName]="'targetId'"
                      id="027_relationship_edit"
                      [options]="techCategories"
                      [translationPrefix]="'wc.admin.relationships'"
                      [disableOptionsTranslate]="true"
                      (selectionChangeEmit)="changeTargetNameCat($event)">
                    </app-input-select-form-field>
                    <app-input-picker-form-field
                      *ngSwitchCase="'ProdCode'"
                      [parentGroup]="relationshipForm"
                      id="029_relationship_edit"
                      [controlName]="'targetId'"
                      [valueField]="'productCode'"
                      [pickerType]="PickerInputType.PRODUCT"
                      [singleSelect]="!!relationship.recordVersion || sourceTargetMode === 'target'"
                      [selectMode]="!relationship.recordVersion"
                      [showDescription]="true"
                      [translationPrefix]="'wc.admin.relationships'"
                      (selectionChanged)="changeTargetNameProd($event)">
                    </app-input-picker-form-field>
                    <app-input-picker-form-field
                      *ngSwitchCase="'ProdGroup'"
                      [parentGroup]="relationshipForm"
                      [controlName]="'targetId'"
                      [valueField]="'id'"
                      labelField="code"
                      id="025_relationship_edit"
                      [pickerType]="PickerInputType.GROUP"
                      [selectMode]="false"
                      [translationPrefix]="'wc.admin.relationships'"
                      (selectionChanged)="changeTargetNameProd($event)">
                    </app-input-picker-form-field>
                    <app-custom-input
                      *ngSwitchDefault
                      [parentGroup]="relationshipForm"
                      [controlName]="'targetId'"
                      [inputType]="'text'"
                      id="011_relationship_edit"
                      [translationPrefix]="'wc.admin.relationships'">
                    </app-custom-input>
                  </ng-container>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="relationshipForm"
                    [controlName]="'targetName'"
                    id="relationship_edit_targetName"
                    [disabled]="true"
                    [label]="'targetName'"
                    [translationPrefix]="'wc.admin.relationships'">
                  </app-custom-input>
                </div>
                <ng-container>
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="relationshipForm.controls['targetCardinalityMinMax']"
                      [controlName]="'min'"
                      [inputType]="'number'"
                      id="012_relationship_edit"
                      [label]="'targetCardinalityMin'"
                      [translationPrefix]="'wc.admin.relationships'">
                    </app-custom-input>
                  </div>
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="relationshipForm.controls['targetCardinalityMinMax']"
                      [controlName]="'max'"
                      [inputType]="'number'"
                      id="013_relationship_edit"
                      [label]="'targetCardinalityMax'"
                      [translationPrefix]="'wc.admin.relationships'">
                    </app-custom-input>
                  </div>
                </ng-container>

                <ng-container formGroupName="validFor">
                  <div class="form__row">
                    <app-input-date-form-field
                      [parentGroup]="relationshipForm.controls['validFor']"
                      [controlName]="'startDateTime'"
                      id="032_relationship_edit"
                      [label]="'validFrom'"
                      [translationPrefix]="'wc.admin.relationships'">
                    </app-input-date-form-field>
                  </div>
                  <div class="form__row">
                    <app-input-date-form-field
                      [parentGroup]="relationshipForm.controls['validFor']"
                      [controlName]="'endDateTime'"
                      id="033_relationship_edit"
                      [label]="'validTo'"
                      [translationPrefix]="'wc.admin.relationships'">
                    </app-input-date-form-field>
                  </div>
                </ng-container>

                <div class="form__row">
                  <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                    <mat-label>{{ 'wc.admin.relationships.description' | translate }}</mat-label>
                    <textarea
                      id="014_relationship_edit"
                      class="form__input"
                      matInput
                      type="text"
                      formControlName="description"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div [formGroup]="relationshipForm" class="card__wrapper">
      <app-eligibility-expression
        class="card -full"
        [parentGroup]="relationshipForm"
        formControlName="ruleSetExpression">
      </app-eligibility-expression>
    </div>
  </div>
</div>
