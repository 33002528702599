import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AdminDynamicEnumService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  ControlStructSingletonFilterMiner,
} from '@btl/btl-fe-wc-common';
import { environment } from '@environments/environment';
import { EnumerationsService } from '@service/enumerations.service';
import { takeUntil } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-notification-type-filter',
  templateUrl: './notification-type-filter.component.html',
  animations: [
    trigger('indicatorRotate', [
      state('close', style({ transform: 'rotate(0deg)' })),
      state('open', style({ transform: 'rotate(180deg)' })),
      transition('close <=> open', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
})
export class NotificationTypeFilterComponent extends ComplexFilter {
  @Input()
  parametersList: Array<string> = [];

  enabledTypes = [
    { labelKey: 'wc.common.yes.button', value: true },
    { labelKey: 'wc.common.no.button', value: false },
  ];
  gatewayCodeTypes = [];
  locales = environment.localization.locales;

  filterForm = this.formBuilder.group({
    code: [],
    remark: [],
    productionEnabled: [],
    sendingEnabled: [],
    gatewayCode: [],
    parametersName: [],
    parametersValue: [],
    locale: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.code, 'notificationType'),
    new ControlFilterMiner(this.filterForm.controls.remark, 'remark', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.productionEnabled, 'productionEnabled'),
    new ControlFilterMiner(this.filterForm.controls.sendingEnabled, 'sendingEnabled'),
    new ControlFilterMiner(this.filterForm.controls.gatewayCode, 'notificationTypeGateways.gateway'),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.parametersName, 'parameters', 'name', null),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.parametersValue, 'parameters', 'value', null),
    new ControlFilterMiner(this.filterForm.controls.locale, 'notificationTypeGateways.locale'),
  ];

  advancedFilterFields = ['parametersName', 'parametersValue', 'locale'];

  constructor(private formBuilder: FormBuilder, private adminDynamicEnumService: AdminDynamicEnumService) {
    super();

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.NOTIFICATION, 'com.emeldi.ecc.be.notification.enums.GatewayType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.gatewayCodeTypes = result?.data ? result.data.map(value => value.name).sort() : [];
      });
  }
}
