import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LeftMenuComponent } from '../left-menu.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Animations } from '@helpers/animations';
import { ActivePageModule, PageModule, PageModuleService } from '@btl/btl-fe-wc-common';

@Component({
  selector: '[app-left-menu-item], app-left-menu-item',
  templateUrl: './left-menu-item.component.html',
  styleUrls: ['./left-menu-item.component.scss'],
  animations: [Animations.leftMenuExpandArrow],
})
export class LeftMenuItemComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  modules: PageModule[];

  @Input()
  module: PageModule;

  @Input()
  subModule = false;

  @Input()
  closed = false;

  subModules: PageModule[];

  selected = false;

  expanded = false;

  @Input()
  collapsed = false;

  @Input()
  depth: number;

  @Input()
  isChild = false;

  @Output()
  readonly expandedEvent = new EventEmitter<any>();

  activePageModule: ActivePageModule;

  constructor(public router: Router, public pageModules: PageModuleService) {}

  ngOnInit() {
    this.subModules = LeftMenuComponent.getCodebookModulesByParentCode(this.module.code, this.modules);
    PageModuleService.getActivePageModule().subscribe(activePageModule => {
      this.activePageModule = activePageModule;
      this.expanded = this.isActive();
    });
  }

  isActive(): boolean {
    return (
      this.module &&
      this.module.path &&
      this.activePageModule &&
      this.activePageModule.pageModule &&
      this.activePageModule.pageModule.path &&
      `${this.activePageModule.pageModule.path}/`.startsWith(`${this.module.path}/`)
    );
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  itemSelected() {
    if (this.subModules.length === 0) {
      if (this.module.urlPattern) {
        this.router.navigate([this.module.parentUrlPattern + this.module.urlPattern]);
      } else {
        console.error(`Empty urlPattern for admin module path: ${this.module.path}`);
      }
    }
    if (this.subModules.length > 0) {
      this.expanded = !this.expanded;
      this.expandedEvent.emit(this.module);
    }
  }

  hasModules() {
    return this.subModules.length > 0;
  }
}
