import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ProductSocketDto, PromotionDto, SocketContentDto, SocketDto } from '@btl/admin-bff';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { AdminPromotionService, AdminSocketService, AppBlockerService, ServiceUtils } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { ProductsSelectModalComponent } from '@components/products-select-modal/products-select-modal.component';
import {
  SelectedSocketContentComponent,
} from '@components/product-catalogue/promotion/edit/promotion-design/selected-socket-content/selected-socket-content.component';
import {
  SelectedSocketComponent,
} from '@components/product-catalogue/promotion/edit/promotion-design/selected-socket/selected-socket.component';
import {
  SelectedRootProductComponent,
} from '@components/product-catalogue/promotion/edit/promotion-design/selected-root-product/selected-root-product.component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { EnumerationsService } from '@service/enumerations.service';
import { MatDialog } from '@angular/material/dialog';
import { MoveNodeService } from '@components/product-catalogue/promotion/edit/promotion-design/move-node.service';
import { PanZoomAPI } from '@components/panzoom/panzoom-api';
import { PanZoomConfigOptions } from '@components/panzoom/types/panzoom-config-options';
import { PanZoomConfig } from '@components/panzoom/panzoom-config';

@Component({
  selector: 'app-promotion-design',
  templateUrl: './promotion-design.component.html',
  styleUrls: ['./promotion-design.component.scss'],
})
export class PromotionDesignComponent implements OnInit, OnDestroy {
  @ViewChild('selectedNodeComponent', {static: false})
  selectedNodeComponent: SelectedSocketContentComponent | SelectedSocketComponent | SelectedRootProductComponent;

  @Input()
  promotionDto: PromotionDto = {};

  @Input()
  sockets: Array<SocketDto>;

  nodes: any = [];
  direction: 'vertical' | 'horizontal' = 'horizontal';
  selectedNode;

  @Output()
  readonly selectedNodeEvent = new EventEmitter<boolean>();

  private onDestroy$: Subject<void> = new Subject<void>();
  private panZoomAPI: PanZoomAPI;
  private apiSubscription: Subscription;
  private panZoomConfigOptions: PanZoomConfigOptions = {
    zoomLevels: 6,
    initialZoomLevel: 3,
    scalePerZoomLevel: 0.5,
    zoomStepDuration: 0.2,
    freeMouseWheel: false,
    invertMouseWheel: false,
    dynamicContentDimensions: true,
    dragMouseButton: 'left',
  };
  panZoomConfig: PanZoomConfig = new PanZoomConfig(this.panZoomConfigOptions);

  productEntityTypes = [];

  techCategories: Array<string> = [];

  socketCategories: Array<string> = [];

  compactView = false;

  collapseAllEmitter = new EventEmitter<boolean>();

  constructor(
    private adminPromotionService: AdminPromotionService,
    private enumerationsService: EnumerationsService,
    private dialog: MatDialog,
    private adminSocketService: AdminSocketService,
    private appBlockerService: AppBlockerService,
    private confirmationDialogService: ConfirmationDialogService,
    private moveNodeService: MoveNodeService,
    private eRef: ElementRef
  ) {
    this.finishMoveMode();

    this.enumerationsService
      .getProductTechnicalCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.techCategories = result;
      });

    this.enumerationsService
      .getSocketItemEntityType()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.productEntityTypes = result;
      });

    this.enumerationsService
      .getProductSocketCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.socketCategories = result;
      });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.finishMoveMode();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.finishMoveMode();
  }

  finishMoveMode() {
    document.body.style.cursor = 'auto';
    this.moveNodeService.nodeToMove = null;
  }

  ngOnInit(): void {
    this.adminPromotionService
      .getPromotionProductSockets(this.promotionDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(productSockets => {
        this.loadPromotionProductSockets(productSockets);
      });

    this.apiSubscription = this.panZoomConfig.api.subscribe((api: PanZoomAPI) => (this.panZoomAPI = api));
  }

  loadPromotionProductSockets(productSockets) {
    const missingSockets = this.getMissingSockets(productSockets);
    if (missingSockets.length > 0) {
      forkJoin(missingSockets)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(results => {
          results.forEach(socket => this.sockets.push(socket));
          this.createTreeView(productSockets);
        });
    } else {
      this.createTreeView(productSockets);
    }
  }

  getMissingSockets(productSockets) {
    const socketsToLoad = [];

    productSockets.forEach(productSocket => {
      if (!this.sockets.find(socket => socket.id === productSocket.socketId)) {
        socketsToLoad.push(this.adminSocketService.getSocketById(productSocket.socketId));
      }

      if (
        productSocket.originatorSocketId &&
        !this.sockets.find(socket => socket.id === productSocket.originatorSocketId)
      ) {
        socketsToLoad.push(this.adminSocketService.getSocketById(productSocket.originatorSocketId));
      }
    });

    return socketsToLoad;
  }

  createTreeView(productSockets) {
    this.nodes.length = 0;
    const roots = productSockets.filter(productSocket => !productSocket.originatorSocketId);
    const rootSet = new Set(roots.map(productSocket => productSocket.productCode));
    rootSet.forEach(productCode => {
      const node = {
        id: ServiceUtils.getRandomId(),
        type: 'root',
        productCode: productCode,
        childs: [],
      };
      roots
        .filter(filterProductSocket => filterProductSocket.productCode === productCode)
        .forEach(productSocket => {
          node.childs.push(this.getSocket(productSocket, productSockets, productCode));
        });
      this.nodes.push(node);
    });
  }

  getSocket(productSocket, productSockets: Array<ProductSocketDto>, rootProductCode) {
    const node: INode = {
      id: ServiceUtils.getRandomId(),
      type: 'socket',
      productSocket: productSocket,
      socket: this.sockets.find(socket => socket.id === productSocket.socketId),
      childs: [],
      rootProductCode: rootProductCode,
    };

    node.socket.contents.forEach(socketContent => {
      const subProductSockets = productSockets.filter(
        filterProductSocket =>
          filterProductSocket.originatorSocketId === productSocket.socketId &&
          socketContent.entityType === 'ProdCode' &&
          filterProductSocket.productCode === socketContent.entityId
      );
      node.childs.push(
        this.getSocketContent(socketContent, subProductSockets, productSockets, rootProductCode, node.socket)
      );
    });

    return node;
  }

  getSocketContent(
    socketContent,
    subProductSockets: Array<ProductSocketDto>,
    productSockets: Array<ProductSocketDto>,
    rootProductCode,
    socket
  ) {
    const node: INode = {
      id: ServiceUtils.getRandomId(),
      type: 'socketItem',
      socket: socket,
      socketContent: socketContent,
      childs: subProductSockets.map(mapProductSocket =>
        this.getSocket(mapProductSocket, productSockets, rootProductCode)
      ),
    };

    return node;
  }

  save() {
    if (!this.selectedNodeComponent || this.selectedNodeComponent.validate()) {
      const confirmationDialogComponent = this.confirmationDialogService.openDialog([
        'wc.admin.promotion.save.confirmation.text',
      ]);

      confirmationDialogComponent.confirmationHandler = dialogReference => {
        confirmationDialogComponent.dialogReference.close();
        this.appBlockerService.block();

        const calls = [];
        const nodes = [];

        this.nodes.forEach(node => {
          this.saveNodeSockets(node, calls, nodes);
        });

        forkJoin(calls)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(results => {
            const productSockets = [];

            let nodeIndex = 0;
            results.forEach(socket => {
              nodes[nodeIndex].socket = socket;
              nodeIndex++;
              const socketIndex = this.sockets.findIndex(s => s.id === socket.id);
              if (socketIndex != -1) {
                this.sockets[socketIndex] = socket;
              }
            });

            this.nodes.forEach(node => {
              this.getProductSockets(node, productSockets, null);
            });

            this.adminPromotionService
              .replaceProductSocketsForPromotion(this.promotionDto.id, productSockets)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(productSockets => {
                this.loadPromotionProductSockets(productSockets);
              });
          });
      };
    }
  }

  saveNodeSockets(node, calls, nodes) {
    if (node.type === 'socket' && node.socket) {
      if (node.socket.id) {
        if (!nodes.find(findNode => findNode.socket.id === node.socket.id)) {
          nodes.push(node);
          calls.push(this.adminSocketService.updateSocket(node.socket.id, node.socket));
        }
      } else {
        nodes.push(node);
        calls.push(this.adminSocketService.createSocket(node.socket));
      }
      node.childs.forEach(subNode => this.saveNodeSockets(subNode, calls, nodes));
    } else {
      node.childs.forEach(subNode => this.saveNodeSockets(subNode, calls, nodes));
    }
  }

  getProductSockets(node, productSockets, lastSocket) {
    if (node.type === 'socket' && node.socket) {
      lastSocket = node.socket;
    }

    node.childs.forEach(subNode => {
      let productSocket: ProductSocketDto = subNode.productSocket;
      if (!productSocket) {
        productSocket = {};
      }
      productSocket.productCode = node.productCode
        ? node.productCode
        : node.socketContent
        ? node.socketContent.entityId
        : null;
      productSocket.socketId = subNode.socket ? subNode.socket.id : null;
      productSocket.originatorSocketId = lastSocket ? lastSocket.id : null;
      if (productSocket.productCode) {
        productSockets.promotionId = this.promotionDto.id;
        productSockets.push(productSocket);
      }
      this.getProductSockets(subNode, productSockets, lastSocket);
    });
  }

  onZoomInClicked(): void {
    this.panZoomAPI.zoomOut('viewCenter');
  }

  onZoomOutClicked(): void {
    this.panZoomAPI.zoomIn('viewCenter');
  }

  onResetViewClicked(): void {
    this.panZoomAPI.detectContentDimensions();
    this.panZoomAPI.resetView();
    this.panZoomAPI.centerContent(2);
  }

  nodeClicked($event: INode) {
    if (!this.selectedNode || !$event || this.selectedNode.id !== $event.id) {
      if (!this.selectedNodeComponent || !$event || this.selectedNodeComponent.validate()) {
        this.selectedNode = $event;
        this.selectedNodeEvent.emit(true);
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  deleteNode(deleteNode: INode, nodes?) {
    const confirm = !nodes && !this.moveNodeService.nodeToMove;
    if (!nodes) {
      nodes = this.nodes;
    }

    if (confirm) {
      const localizationParameters = {
        socket: deleteNode.socket?.name,
        root: deleteNode.rootProductCode,
      };

      const confirmationDialogComponent = this.confirmationDialogService.openDialog(
        ['wc.admin.promotion.removeSocket.confirmation'],
        null,
        localizationParameters
      );

      confirmationDialogComponent.confirmationHandler = dialogReference => {
        confirmationDialogComponent.dialogReference.close();
        const index = nodes.indexOf(deleteNode, 0);
        if (index > -1) {
          nodes.splice(index, 1);
        } else {
          this.deleteChildNode(nodes, deleteNode);
        }
        this.selectedNode = null;
        this.selectedNodeEvent.emit(null);
      };
    } else {
      const index = nodes.indexOf(deleteNode, 0);
      if (index > -1) {
        nodes.splice(index, 1);
      } else {
        this.deleteChildNode(nodes, deleteNode);
      }
      this.selectedNode = null;
      this.selectedNodeEvent.emit(null);
    }
  }

  deleteChildNode(nodes, deleteNode) {
    nodes.forEach(node => {
      if (node.socket?.contents?.length > 0 && deleteNode.socketContent) {
        const index = node.socket.contents.indexOf(deleteNode.socketContent);
        if (index > -1) {
          node.socket.contents.splice(index, 1);
        }
      }
      this.deleteNode(deleteNode, node.childs);
    });
  }

  addRootProduct() {
    const modalRef = this.dialog.open(ProductsSelectModalComponent);
    const productsSelectModalComponent = modalRef.componentInstance;
    productsSelectModalComponent.dialogRef = modalRef;
    productsSelectModalComponent.disableActions = true;
    productsSelectModalComponent.isModal = true;
    productsSelectModalComponent.disableListingActions = true;
    productsSelectModalComponent.disableExpand = true;
    productsSelectModalComponent.nestedTable = false;
    productsSelectModalComponent.selectMode = false;

    productsSelectModalComponent.selectHandler = product => {
      const newNode = {
        id: ServiceUtils.getRandomId(),
        type: 'root',
        productCode: product.productCode,
        childs: [],
      };

      this.nodes.push(newNode);
      this.selectedNode = newNode;
    };
  }

  changeViewType() {
    this.compactView = !this.compactView;
  }

  changeViewDirection() {
    if (this.direction === 'horizontal') {
      this.direction = 'vertical';
    } else {
      this.direction = 'horizontal';
    }
    this.panZoomAPI.resetView();
  }

  collapse() {
    this.collapseAllEmitter.emit(true);
    this.selectedNode = null;
    this.selectedNodeEvent.emit(null);
  }

  expand() {
    this.collapseAllEmitter.emit(false);
    this.selectedNode = null;
    this.selectedNodeEvent.emit(null);
  }
}

export interface INode {
  id: string;
  type: string;
  childs: INode[];
  productCode?: string;
  productSocket?: ProductSocketDto;
  socketContent?: SocketContentDto;
  socket?: SocketDto;
  rootProductCode?: string;
}
