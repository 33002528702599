import { Component } from '@angular/core';
import {
  AclService,
  AdminContextRulesService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PagedContextRulesDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import {
  DynamicContextRuleEditComponent,
} from '@components/product-catalogue/dynamic-context-rules/edit/dynamic-context-rule-edit.component';
import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-dynamic-context-rules-list',
  templateUrl: './dynamic-context-rules-list.component.html',
  styleUrls: ['./dynamic-context-rules-list.component.scss'],
})
@EnableDynamicLoading({ customName: DynamicContextRulesListComponent.PAGE_ID })
export class DynamicContextRulesListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'DynamicContextRulesListComponent';

  pageId(): string {
    return DynamicContextRulesListComponent.PAGE_ID;
  }


  pagedContextRulesDto: PagedContextRulesDto;

  constructor(
    private adminContextRulesService: AdminContextRulesService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  editContextRule(contextRuleDto, inNewTab: boolean = false) {
    this.navigateSibling(DynamicContextRuleEditComponent.PAGE_ID, { id: contextRuleDto.id }, inNewTab);
  }

  newContextRule() {
    this.navigateSibling(DynamicContextRuleEditComponent.PAGE_ID, { id: '&' });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminContextRulesService.filterContextRules(this.search, null).subscribe(result => {
        if (result) {
          this.pagedContextRulesDto = result;
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminContextRulesService.deleteContextRule(rowDataId);
  }
}
