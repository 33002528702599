import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComplexFilter } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-filter-buttons, [app-filter-buttons]',
  templateUrl: './filter-buttons.component.html',
})
export class FilterButtonsComponent {

  @Input()
  complexFilter: ComplexFilter;

  @Output()
  clearFilter: EventEmitter<null> = new EventEmitter<null>();
}
