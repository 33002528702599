<div class="card__inner">
  <div class="card__header">
    {{ 'wc.admin.productPrices.eligibility' | translate }}
  </div>

  <div class="card__body">
    <div class="price_parameters__container">
      <div class="group_products__menu">
        <a (click)="addNewRuleSet()" [routerLink]="" class="btn -primary -xs">{{ 'wc.common.new' | translate }}</a>
      </div>
      <div [formGroup]="form" class="price_parameters__main">
        <div *ngIf="value && ruleSets" class="price_parameters__panels" formArrayName="ruleSets">
          <table class="table -primary table-sm">
            <thead>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.eligibility.rulSet"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.eligibility.priority"> </app-sortable-column>
              </th>
              <th scope="col"></th>
            </thead>

            <tbody>
              <tr
                *ngFor="let ruleSetForm of getRuleSetsFormArray().controls"
                [formGroup]="ruleSetForm"
                class="table__row">
                <td>
                  <select (ngModelChange)="getValue()" class="form__select" formControlName="id">
                    <option *ngFor="let rulSet of ruleSets" [ngValue]="rulSet.id">
                      {{ rulSet.ruleSetExpression }}
                    </option>
                  </select>
                  <app-input-errors [control]="ruleSetForm.controls['id']" [translationPrefix]="'wc.admin.ruleSet.id'">
                  </app-input-errors>
                </td>
                <td>
                  <input (ngModelChange)="sortTable()" class="form__input" formControlName="priority" />
                  <app-input-errors
                    [control]="ruleSetForm.controls['priority']"
                    [translationPrefix]="'wc.admin.ruleSet.priority'">
                  </app-input-errors>
                </td>
                <td>
                  <a (click)="remove(ruleSetForm)" [routerLink]="" class="link"><i class="fas fa-trash"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
