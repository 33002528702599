import { Component, OnDestroy, ViewChildren, ViewEncapsulation } from '@angular/core';
import {
  AppBlockerService,
  CurrentLocaleService,
  IconService,
  StickyMessage,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LeftMenuComponent } from '@components/left-menu/left-menu.component';
import { ThemeService } from '@service/theme.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslatePipe } from '@ngx-translate/core';
import { BlockUiComponent } from '@components/block-ui/block-ui.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { StickyMessageComponent } from '@components/sticky-message/sticky-message.component';
import { AuthServiceSessionService } from '@service/auth-service-session.service';
import { Icons } from '@helpers/icons';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', '../styles.scss'],
  providers: [TranslatePipe],
})
export class AppComponent implements OnDestroy {
  @ViewChildren(LeftMenuComponent)
  leftMenuComponent: LeftMenuComponent;

  mainMenu = true;

  secondMenu = false;

  collapsed = false;

  selectedTheme;

  dashboard: boolean = false;

  title = 'btl-fe-wc-admin';
  mainBlockUIInstanceName = AppBlockerService.mainBlockUIInstanceName;
  blockTemplate = BlockUiComponent;
  closed: boolean;
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnInit() {
    this.themeService.getThemeColor().subscribe(theme => {
      this.selectedTheme = theme;
    });
    this.iconService.registerIcons(Object.values(Icons));
  }

  constructor(
    private currentLocaleService: CurrentLocaleService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute,
    private iconService: IconService,
    private themeService: ThemeService,
    private _snackBar: MatSnackBar,
    private translatePipe: TranslatePipe,
    public stickyMessageService: StickyMessageService,
    private authServiceSessionService: AuthServiceSessionService
  ) {
    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe(navigation => {
      if (navigation instanceof NavigationEnd) {
        this.dashboard = navigation.url.startsWith('/dashboard');
      }
    });
    this.stickyMessageService.stickyMessagesChange.subscribe(() => {
      this.openSnackBar();
    });
    this.currentLocaleService.setCurrentLanguage(this.currentLocaleService.getCurrentLanguage());
  }

  secondMenuChanged(secondMenu) {
    this.secondMenu = secondMenu;
    if (this.secondMenu) {
      this.collapsed = true;
      this.closed = true;
    } else {
      this.collapsed = false;
      this.closed = false;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  openSnackBar(): void {
    let lastMessage;
    this.stickyMessageService.stickyMessages.forEach((message: StickyMessage) => {
      if (message !== null) {
        lastMessage = message;
      }
    });

    const snackBark = this._snackBar.openFromComponent(StickyMessageComponent, {
      data: lastMessage,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 30000,
      panelClass: lastMessage.type,
    });

    snackBark.afterDismissed().subscribe(test => {
      //this.stickyMessageService.deleteStickyMessage(0)
    });
  }
}
