<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'code'"
            [inputType]="'text'"
            id="001_product_group_filter"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.productGroups'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            controlName="seoUrl"
            id="004_product_group_filter"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.productGroups'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'name'"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            id="002_product_group_filter"
            [translationPrefix]="'wc.admin.productGroups'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            (keydown.enter)="handleChange()"
            [formControlName]="'type'"
            [options]="groupTypes"
            id="003_product_group_filter"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.relationships'">
          </app-input-select-form-field>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
