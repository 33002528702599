import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef
,
} from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Failures } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-sticky-message',
  templateUrl: './sticky-message.component.html',
  styleUrls: ['./sticky-message.component.scss'],
})
export class StickyMessageComponent {
  constructor(
    public dialog: MatDialog,
    public snackBarRef: MatSnackBarRef<StickyMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  detail(detailDialog: any) {
    this.dialog.open(detailDialog);
  }

  getCausedByMessage() {
    let causedByMessage = '';
    if (this.data.detail) {
      const failures: Failures = this.data.detail.error;
      if (failures.failures) {
        if (failures.failures.length > 0) {
          causedByMessage = failures[failures.failures.length - 1]?.contextData['causedBy.message'];
        }
      }
    }
    return causedByMessage;
  }

  close() {
    this.dialog.closeAll();
  }
}
