<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'code'"
            [inputType]="'text'"
            id="001_codebook_filter"
            [translationPrefix]="'wc.admin.codebook'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            [formControlName]="'codebookType'"
            [options]="types"
            id="002_codebook_filter"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.codebook'">
          </app-input-select-form-field>
          <app-custom-input
            [controlName]="'priority'"
            [inputType]="'number'"
            id="003_codebook_filter"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.codebook'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
