import { Component, Input } from '@angular/core';
import { DmsFileDto } from '@btl/admin-bff';
import { Filter } from '@btl/btl-fe-wc-common/lib/models/search';
import { AbstractFilterComponent } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-dms-files-listing-item, [app-dms-files-listing-item]',
  templateUrl: './dms-files-listing-item.component.html',
  styleUrls: ['./dms-files-listing-item.component.scss'],
})
export class DmsFilesListingItemComponent {
  @Input()
  multiSelectMode = false;

  @Input()
  singleSelectMode = false;

  @Input()
  file: DmsFileDto;

  @Input()
  constantFilter: Array<Filter>;

  @Input()
  parentId = '';

  selected = false;

  public presentInFilter(column: string) {
    return AbstractFilterComponent.presentInFilter(this.constantFilter, column);
  }
}
