import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminCodebookService,
  AdminDocumentTemplateService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DocumentTemplateDto, LocaleTemplateDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { environment } from '@environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { Animations } from '@helpers/animations';
import { CodebookDto } from '@btl/admin-bff/model/codebookDto';
import { DocumentTemplateListComponent } from '@components/document-template/list/document-template-list.component';

@Component({
  selector: 'app-document-template-edit',
  templateUrl: './document-template-edit.component.html',
  styleUrls: ['./document-template-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: DocumentTemplateEditComponent.PAGE_ID })
export class DocumentTemplateEditComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'DocumentTemplateEditComponent';

  routerOutlet: any;

  private locales = environment.localization.locales;

  public documentTemplateDto: DocumentTemplateDto = { localeTemplates: [] };

  private documentTemplateId: string;

  documentTypes: string[] = [];
  documentTypeCodebookDtos: CodebookDto[] = [];
  duplicateMode = false;

  documentTemplateForm: FormGroup = this.formBuilder.group({
    id: [null, [Validators.required, Validators.pattern('[\\w-]+')]],
    recordVersion: [null],
    documentType: [null, Validators.required],
    description: [],
    editable: [false, Validators.required],
    signRequired: [false, Validators.required],
    localeTemplates: this.formBuilder.array([]),
  });

  get idControl(): AbstractControl {
    return this.documentTemplateForm.get('id');
  }

  get localeTemplatesFormArray(): FormArray {
    return this.documentTemplateForm.get('localeTemplates') as FormArray;
  }

  get localeTemplatesForms(): FormGroup[] {
    return this.localeTemplatesFormArray.controls as FormGroup[];
  }

  validateArrayUniqueKey = (key: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const group: FormGroup = control.parent as FormGroup;
      let ret: ValidationErrors = null;
      if (group) {
        const array: FormArray = group.parent as FormArray;
        if (array) {
          array.controls.forEach(validateGroup => {
            if (validateGroup !== group) {
              if (group.controls[key].value === (validateGroup as FormGroup).controls[key].value) {
                ret = { notUnique: true };
                (validateGroup as FormGroup).controls[key].markAllAsTouched();
              }
            }
          });
        }
      }
      return ret;
    };
  };

  createLocaleTemplateForm(): FormGroup {
    return this.formBuilder.group({
      locale: [undefined, [Validators.required, this.validateArrayUniqueKey('locale')]],
      template: [undefined, Validators.required],
    });
  }

  validateLocales() {
    this.localeTemplatesForms.forEach(loacaleTemplateGroup => {
      loacaleTemplateGroup.controls.locale.updateValueAndValidity();
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private dialog: MatDialog,
    private adminDocumentTemplateService: AdminDocumentTemplateService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    private adminCodebookService: AdminCodebookService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  pageId(): string {
    return DocumentTemplateEditComponent.PAGE_ID;
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const documentTemplateId = this.params.id;
      if (documentTemplateId) {
        if (documentTemplateId === '&') {
          this.setDocumentTemplate(this.documentTemplateDto);
        } else {
          this.loadDocumentTemplate(documentTemplateId);
        }
      }
    } else {
      this.documentTemplateDto = undefined;
    }
  }

  loadDocumentTemplate(documentTemplateId: string) {
    this.adminDocumentTemplateService
      .getDocumentTemplateById(documentTemplateId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setDocumentTemplate(result);
        this.documentTemplateId = this.documentTemplateDto.id;
      });
  }

  setDocumentTemplate(documentTemplate: DocumentTemplateDto) {
    this.documentTemplateDto = documentTemplate;
    this.localeTemplatesFormArray.clear();
    this.documentTemplateDto.localeTemplates.forEach(localeTemplate => {
      this.localeTemplatesFormArray.push(this.createLocaleTemplateForm());
    });
    this.documentTemplateForm.patchValue(this.documentTemplateDto);
    if (this.duplicateMode) {
      this.duplicateMode = false;
      this.documentTemplateForm.controls.id.patchValue(`${this.documentTemplateDto.id}-copy`);
      this.documentTemplateForm.controls.recordVersion.patchValue(null);
    }
  }

  saveDocumentTemplate(): boolean {
    FormUtils.validateAllFormFields(this.documentTemplateForm);
    if (this.documentTemplateForm.valid) {
      const documentTemplate = this.documentTemplateDto;
      if (documentTemplate) {
        Object.keys(this.documentTemplateForm.controls).forEach(field => {
          const control = this.documentTemplateForm.get(field);
          documentTemplate[field] = control.value;
        });
      }
      return true;
    }
    return false;
  }

  save() {
    if (this.saveDocumentTemplate()) {
      this.appBlockerService.block();
      this.documentTemplateDto.created = null;
      this.documentTemplateDto.createdBy = null;
      this.documentTemplateDto.modified = null;
      this.documentTemplateDto.modifiedBy = null;
      if (this.documentTemplateDto.localeTemplates) {
        this.documentTemplateDto.localeTemplates.forEach(localeTemplate => {
          localeTemplate.created = undefined;
          localeTemplate.createdBy = undefined;
          localeTemplate.modified = undefined;
          localeTemplate.modifiedBy = undefined;
        });
      }
      if (this.documentTemplateDto.recordVersion) {
        this.adminDocumentTemplateService
          .updateDocumentTemplate(this.documentTemplateId, this.documentTemplateDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getDocumentTemplateHandler);
      } else {
        this.adminDocumentTemplateService
          .createDocumentTemplate(this.documentTemplateDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getDocumentTemplateHandler);
      }
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditDocumentTemplate(): DocumentTemplateDto {
    return this.documentTemplateDto;
  }

  public reset() {
    this.setDocumentTemplate(this.documentTemplateDto);
    this.documentTemplateId = this.documentTemplateDto.id;
    if (this.documentTemplateId) {
      this.navigateSelf({ id: this.documentTemplateId });
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.documentTemplate.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminDocumentTemplateService
        .deleteDocumentTemplate(this.documentTemplateDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(DocumentTemplateListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  duplicate() {
    this.documentTemplateId = null;
    this.duplicateMode = true;
    this.navigateSelf({ id: '&' });
  }

  public getDocumentTemplateHandler = (documentTemplateDto: DocumentTemplateDto): void => {
    let saving = false;
    if (this.documentTemplateDto) {
      saving = true;
    }
    if (documentTemplateDto && this.documentTemplateDto) {
      if (documentTemplateDto.id !== this.documentTemplateDto.id) {
        this.documentTemplateId = documentTemplateDto.id;
      }
      this.setDocumentTemplate(documentTemplateDto);
      this.navigateSelf({ id: documentTemplateDto.id });
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  ngOnInit() {
    const search: Search = {
      filtering: [{ column: 'codebookType', compareType: 'EQUAL', value: 'DOCUMENT_TYPE' }],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    this.adminCodebookService.getCodebooksByFilter(search, ['data.code', 'data.localizedTexts']).subscribe(result => {
      this.documentTypeCodebookDtos = result.data;
      this.documentTypes = result.data.map(cb => cb.code);
    });
  }

  cancel() {
    this.navigateSibling(DocumentTemplateListComponent.PAGE_ID);
  }

  getEditLocaleTemplates(): LocaleTemplateDto[] {
    return this.documentTemplateDto.localeTemplates;
  }

  removeLocaleTemplate(i) {
    this.localeTemplatesFormArray.removeAt(i);
  }

  addLocaleTemplate() {
    const localeTemplate: LocaleTemplateDto = { locale: null, template: null };
    const localeTemplateForm = this.createLocaleTemplateForm();
    localeTemplateForm.patchValue(localeTemplate);
    this.localeTemplatesFormArray.push(localeTemplateForm);
  }
}
