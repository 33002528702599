import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INode } from '@components/product-catalogue/promotion/edit/promotion-design/promotion-design.component';
import { MinMaxDto, SocketContentDto } from '@btl/admin-bff';
import { MinMaxDefDto } from '@btl/admin-bff/model/minMaxDefDto';
import { ServiceUtils } from '@btl/btl-fe-wc-common';
import { MoveNodeService } from '@components/product-catalogue/promotion/edit/promotion-design/move-node.service';

@Component({
  selector: 'app-promotion-design-node',
  templateUrl: './promotion-design-node.component.html',
  styleUrls: ['./promotion-design-node.component.scss'],
})
export class PromotionDesignNodeComponent implements OnInit {
  @Input()
  node: INode;

  @Input()
  hasParent = false;

  @Input()
  direction: 'vertical' | 'horizontal' = 'vertical';

  @Input()
  compactView = false;

  @Input()
  collapseAllEmitter;

  collapsed = false;

  selectedItem;

  @Input()
  selectedNode;

  @Output() readonly nodeClick = new EventEmitter<INode>();
  @Output() readonly nodeRemove = new EventEmitter<INode>();
  @Output() readonly collapseEvent = new EventEmitter<boolean>();
  @Output() readonly selectedItemEvent = new EventEmitter<string>();

  constructor(public moveNodeService: MoveNodeService) {}

  ngOnInit() {
    if (this.node && this.node.type === 'socket' && this.node.childs && this.node.childs.length > 0) {
      const nodeToSelect = this.node.childs.find(
        child => child.socketContent.entityType === 'ProdCode' && child.childs?.length > 0
      );
      if (nodeToSelect) {
        this.selectItem(nodeToSelect.id);
      } else {
        this.selectItem(this.node.childs[0].id);
      }

      this.collapseEvent.emit(this.collapsed);
    }

    this.collapseAllEmitter.subscribe(collapse => {
      this.collapsed = collapse;
    });
  }

  collapse(itemName: string) {
    if (this.selectedItem !== itemName) {
      this.selectItem(itemName);
      this.collapsed = false;
      this.collapseEvent.emit(this.collapsed);
    } else {
      this.collapsed = !this.collapsed;
      this.collapseEvent.emit(this.collapsed);
    }
  }

  removeItem(itemToRemove) {
    this.node.childs = this.node.childs.filter(item => item.id !== itemToRemove.id);
    const index = this.node.socket.contents.indexOf(itemToRemove.socketContent);
    if (index > -1) {
      this.node.socket.contents.splice(index, 1);
      this.nodeClick.emit(null);
    }
    if (itemToRemove.id === this.selectedItem) {
      if (this.node && this.node.childs && this.node.childs.length > 0) {
        this.selectedItem = this.node.childs[0].id;
      } else {
        this.selectedItem = null;
      }
    }
    this.selectedItemEvent.emit(this.selectedItem);
  }

  getSelectedItem() {
    return this.node.childs.find(item => item.id === this.selectedItem);
  }

  addSocket() {
    const newNode = this.getNewSocket();

    this.node.childs.push(newNode);
    this.nodeClick.emit(newNode);
  }

  addProductSocket(socketItem: INode) {
    const newNode = this.getNewSocket();

    socketItem.childs.push(newNode);
    this.nodeClick.emit(newNode);
  }

  getNewSocket() {
    const contentQty: MinMaxDto = {
      min: 0,
      max: 0,
    };
    const newNode: INode = {
      id: ServiceUtils.getRandomId(),
      type: 'socket',
      socket: {
        id: null,
        name: null,
        code: null,
        params: null,
        socketCategory: null,
        recordVersion: null,
        contentQty: contentQty,
        contents: [],
      },
      childs: [],
    };

    return newNode;
  }

  addSocketContent() {
    const contentQty: MinMaxDefDto = {
      min: 0,
      max: 0,
      def: 0,
    };

    const socketContent: SocketContentDto = {
      id: null,
      entityType: null,
      entityId: null,
      recordVersion: null,
      validFor: {
        startDateTime: null,
        endDateTime: null,
      },
      contentQty: contentQty,
    };
    const newNode: INode = {
      id: ServiceUtils.getRandomId(),
      type: 'socketItem',
      socketContent: socketContent,
      childs: [],
    };
    this.node.socket.contents.push(socketContent);
    this.node.childs.push(newNode);
    this.nodeClick.emit(newNode);
  }

  getSocketItemChilds(socketItem: SocketContentDto) {
    return this.node.childs.find(
      child =>
        socketItem.entityType === 'ProdCode' &&
        child.childs &&
        child.childs.length &&
        child.childs[0].productSocket.productCode === socketItem.entityId
    );
  }

  selectItem(id) {
    if (!this.moveNodeService.nodeToMove) {
      this.selectedItem = id;
      this.selectedItemEvent.emit(this.selectedItem);
    }
  }

  emitNodeClicked(socketItem) {
    if (!this.moveNodeService.nodeToMove) {
      this.nodeClick.emit(socketItem);
    }
  }

  removeNode() {
    this.nodeRemove.emit(this.node);
  }

  moveNodeToItem(socketItem: INode) {
    if (this.canMove(socketItem)) {
      const sub = this.moveNodeService.addNewNode.subscribe(move => {
        socketItem.childs.push(this.moveNodeService.nodeToMove);
        this.nodeClick.emit(socketItem);
        this.moveNodeService.nodeToMove = null;
        sub.unsubscribe();
      });
      this.moveNodeService.deleteOldNode.emit(true);
    }
  }

  canMove(node): boolean {
    return this.moveNodeService.nodeToMove && node.type !== 'socket' && !this.moveToItself();
  }

  moveToItself(): boolean {
    return this.moveNodeService.nodeToMove?.socket?.id === this.node.socket?.id;
  }
}
