import { Component, ViewChildren } from '@angular/core';
import {
  AdminDynamicEnumService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  ControlStructFilterMiner,
  ControlStructSingletonFilterMiner, MultiInputComponent,
  TicketingService,
} from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { AssignmentStateDto, BusinessStateDto, TicketTypeDto } from '@btl/order-bff';
import { EnumEntryDto } from '@btl/admin-bff';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-ticket-filter',
  templateUrl: './ticket-filter.component.html',
  styleUrls: ['./ticket-filter.component.scss'],
  animations: [Animations.advancedFilterArrow],
})
export class TicketFilterComponent extends ComplexFilter {
  readonly SOURCE_NAME = EnumerationsService.TICKET;
  readonly TICKET_AREA_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.TicketAreaType';
  readonly EXTERNAL_ENTITY_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.ExternalEntityType';

  @ViewChildren(MultiInputComponent)
  multiInputs: Array<MultiInputComponent>;

  public ticketAreaTypes: EnumEntryDto[];
  public externalEntityTypes: EnumEntryDto[];
  public ticketTypes: TicketTypeDto[];
  public filteredByAreaTicketTypes: string[] = [];
  public assignmentStates: AssignmentStateDto[];
  public businessStates: BusinessStateDto[];

  filterForm = this.formBuilder.group({
    area: [],
    type: [],
    assignmentState: [],
    businessState: [],
    assignedTo: [],
    owner: [],
    ticketRefNo: [],
    createdFrom: [],
    createdTo: [],
    followUpDateFrom: [],
    followUpDateTo: [],
    entityType: [],
    entity: [],
    priority: [],
  });

  advancedFilterFields = ['assignedTo', 'owner', 'ticketRefNo', 'createdFrom', 'createdTo', 'followUpDateFrom', 'followUpDateTo', 'entityType', 'entity', 'priority'];

  filterMiners = [
    new ControlStructFilterMiner(this.filterForm.controls.type, 'type', 'code', null),
    new ControlStructFilterMiner(this.filterForm.controls.area, 'type', 'areaType', null),

    new ControlFilterMiner(this.filterForm.controls.assignmentState, 'assignmentState', CompareTypeDtoEnum.IN),
    new ControlFilterMiner(this.filterForm.controls.businessState, 'businessState', CompareTypeDtoEnum.IN),
    new ControlFilterMiner(this.filterForm.controls.assignedTo, 'assignedTo', CompareTypeDtoEnum.IN),

    new ControlFilterMiner(this.filterForm.controls.owner, 'owner', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.ticketRefNo, 'id'),

    new ControlFilterMiner(this.filterForm.controls.createdFrom, 'created', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.createdTo, 'created', CompareTypeDtoEnum.SMALLEREQ),
    new ControlFilterMiner(this.filterForm.controls.followUpDateFrom, 'followUpDate', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.followUpDateTo, 'followUpDate', CompareTypeDtoEnum.SMALLEREQ),

    new ControlStructSingletonFilterMiner(this.filterForm.controls.entityType, 'references', 'entityType', null),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.entity, 'references', 'entityId', null),
    new ControlFilterMiner(this.filterForm.controls.priority, 'priority'),
  ];

  constructor(
    private formBuilder: FormBuilder,
    private ticketingService: TicketingService,
    private adminDynamicEnumService: AdminDynamicEnumService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadTicketAreaTypes();
    this.loadExternalEntityTypes();
    this.loadTicketTypes();
    this.loadAssignmentStates();
    this.loadBusinessStates();
    super.ngOnInit();
  }

  private loadTicketAreaTypes() {
    this.adminDynamicEnumService.getEnumEntries(this.SOURCE_NAME, this.TICKET_AREA_TYPE_ENUM_NAME).subscribe(result => {
      this.ticketAreaTypes = result.data;
    });
  }

  private loadExternalEntityTypes() {
    this.adminDynamicEnumService
      .getEnumEntries(this.SOURCE_NAME, this.EXTERNAL_ENTITY_TYPE_ENUM_NAME)
      .subscribe(result => {
        this.externalEntityTypes = result.data;
      });
  }

  private loadTicketTypes() {
    this.ticketingService.getTicketTypes().subscribe(result => {
      this.ticketTypes = result;
      this.filterTicketTypesByArea(null);
    });
  }

  private loadAssignmentStates() {
    this.ticketingService.getAssignmentStates().subscribe(result => {
      this.assignmentStates = result.states;
    });
  }

  private loadBusinessStates() {
    this.ticketingService.getBusinessStates().subscribe(result => {
      this.businessStates = result.states;
    });
  }

  typeChanged(type) {
    if (type === null) return;
    let a = null;
    this.ticketTypes.forEach(t => {
      if (t.code === type) {
        a = t.areaType;
      }
    });
    this.filterForm.controls.area.patchValue(a);
    this.filterTicketTypesByArea(a);
  }

  areaChanged(area) {
    this.filterTicketTypesByArea(area);
  }

  filterTicketTypesByArea(area) {
    this.filteredByAreaTicketTypes = [];
    this.ticketTypes.forEach(t => {
      if (area === null || area === t.areaType) {
        this.filteredByAreaTicketTypes.push(t.code);
      }
    });
  }

  clearFilter() {
    this.resetFilterFields();
    this.multiInputs.forEach(inputFormField => {
      inputFormField.list = [];
    });
  }
}
