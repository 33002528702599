<router-outlet></router-outlet>
<div class="main__header">
  <div class="header-box">
    <div class="icon-box PC">
      <mat-icon svgIcon="groupTypes"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.groupTypes' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      (click)="newGroupType()"
      [routerLink]=""
      id="001_group_type_listing"
      mat-raised-button
      color="primary"
      *ngIf="aclService.pageEditAccess"
      [title]="'wc.admin.groupType.new.title' | translate"
      class="btn -primary -xs"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>
<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-group-type-filter (filterChanged)="filterChanged($event)"></app-group-type-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.groupType.form.list' | translate }}</span>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="['name']"
          [fillIdBy]="'name'"
          (editButtonEvent)="editGroupType($event)"
          (editInNewTabButtonEvent)="editGroupType($event, true)"
          (deleteButtonEvent)="deleteGroupType($event)"
          [pagedDto]="filteredEnumEntries"
          (doubleClickEvent)="editGroupType($event)"
          [editable]="aclService.pageEditAccess"
          [selectMode]="false"
          [module]="'groupTypes'"
          [translationPrefix]="'wc.admin.groupType'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
