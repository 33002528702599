import { AppAuthGuard, AuthenticationMethod, CodebookService, DynamicComponentRegistry } from '@btl/btl-fe-wc-common';
import { environment } from '@environments/environment';
import { AuthFactoryService } from '@btl/btl-fe-wc-common/lib/services/auth-factory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginRouteComponent } from "./login-route.component";

export function initializer(
  authFactory: AuthFactoryService,
  activatedRoute: ActivatedRoute,
  dynamicComponentRegistry: DynamicComponentRegistry,
  codebookService: CodebookService,
  router: Router
): () => Promise<any> {
  let isRoutingConfigured = false;

  return (): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        authFactory.setAuthenticationMethod(AuthenticationMethod[environment.authenticationMethod]);
        await authFactory.getAuthService().init({
          config: {
            url: environment.authenticationServerUri,
            realm: 'btl',
            clientId: 'btl-public',
          },
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
          },
          enableBearerInterceptor: false,
        });
        router.resetConfig([{
          path: '**',
          component: LoginRouteComponent,
          canActivate: [AppAuthGuard]
        }]);
        router.initialNavigation();
      } catch (error) {
        reject(error);
      }
      resolve(null);
    });
  };
}
