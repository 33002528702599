import { Component, OnInit } from '@angular/core';
import { ProductDto } from '@btl/admin-bff';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductEditService } from '@service/product-edit.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { Attachment, FormAttachmentsComponent } from '@components/form-attachments/form-attachments.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-product-documents',
  templateUrl: './product-documents.component.html',
  styleUrls: ['./product-documents.component.scss'],
})
@EnableDynamicLoading({ customName: ProductDocumentsComponent.PAGE_ID })
export class ProductDocumentsComponent extends AbstractPageComponent implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductDocumentsComponent';

  pageId(): string {
    return ProductDocumentsComponent.PAGE_ID;
  }

  form: FormGroup = this.formBuilder.group({
    documents: [],
  });

  product: ProductDto;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private productEditService: ProductEditService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit() {
    this.product = this.productEditService.getEditProduct();
    this.form.patchValue(this.product);
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(product => {
      this.product = product;
      this.form.patchValue(this.product);
    });
  }

  saveData(): boolean {
    if (!this.productEditService.canEdit()) {
      return true;
    }
    const canSave = true;
    const documents = [];

    if (canSave) {
      const createList = [];
      const patchList = [];

      const attachments = this.form.getRawValue().documents;
      attachments?.forEach((attachment: Attachment) => {
        if (attachment.dmsFile?.id) {
          if (attachment.delete) {
            delete attachment.dmsFile.params['language'];
            patchList.push({
              id: attachment.dmsFile.id,
              delete: true,
              params: attachment.dmsFile.params,
              references: [],
              recordVersion: attachment.dmsFile.recordVersion,
            });
          } else {
            FormAttachmentsComponent.addLanguageParam(attachment.dmsFile);
            patchList.push({
              id: attachment.dmsFile.id,
              extId: attachment.dmsFile.extId,
              name: attachment.dmsFile.name,
              params: attachment.dmsFile.params,
              references: attachment.dmsFile.references,
              texts: attachment.dmsFile.texts,
              recordVersion: attachment.dmsFile.recordVersion,
            });
          }
        } else if (attachment.dmsFile) {
          FormAttachmentsComponent.addLanguageParam(attachment.dmsFile);
          createList.push(attachment.dmsFile);
        }

        attachment['documentType'] = attachment.type;
        documents.push(attachment);
      });

      this.productEditService.getEditProduct().documents = documents.filter(attachment => !attachment['delete']);
      if (createList.length > 0 || patchList.length > 0) {
        this.productEditService.documentBulkOperations = {
          bulkCreateList: createList,
          bulkUpdateList: [],
          bulkPatchList: patchList,
        };
      }
    } else {
      return false;
    }

    return true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.productEditService.resetMode) {
      if (this.saveData()) {
        return true;
      } else {
        return false;
      }
    }

    this.productEditService.resetMode = false;

    return true;
  }
}
