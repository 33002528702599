<div class="card__wrapper" *ngIf="product">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">
            {{ 'wc.admin.changes' | translate }}
          </span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            color="primary"
            mat-raised-button
            (click)="newRelationships('target')"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary icon -xs stroke"
            [disabled]="!this.productEditService.enableEditProduct">
            <mat-icon svgIcon="plus"></mat-icon>
            {{ 'wc.admin.relationships.add.new.targets' | translate }}
          </button>
          <button
            color="primary"
            mat-raised-button
            (click)="newRelationships('source')"
            *ngIf="aclService.pageEditAccess "
            [routerLink]=""
            class="btn -primary icon -xs stroke"
            [disabled]="!this.productEditService.enableEditProduct">
            <mat-icon svgIcon="plus"></mat-icon>
            {{ 'wc.admin.relationships.add.new.sources' | translate }}
          </button>
          <button
            id="007_product_rating"
            color="primary"
            mat-stroked-button
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor"
            (click)="deleteSelectedOperations()"
            [disabled]="!this.productEditService.enableEditProduct || !operationsTable?.selection.hasValue()">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <app-table-listing-form
        #operationsTable
        [columnName]="[
            'type',
            'sourceType',
            'sourceId',
            'sourceName',
            'targetType',
            'targetId',
            'targetName',
            'modified',
            'validFor.endDateTime'
          ]"
        [columnToTruncate]="['type', 'modified', 'modifiedBy', 'sourceName', 'targetName', 'validFor.endDateTime']"
        [dontShowOnMobile]="[
            'code',
            'sourceType',
            'sourceId',
            'targetType',
            'targetId',
            'modified',
            'modifiedBy',
            'validFor.endDateTime'
          ]"
        [disabledSortColumns]="['operationType',
            'type',
            'sourceType',
            'sourceId',
            'sourceName',
            'targetType',
            'targetId',
            'targetName',
            'modified',
            'validFor.endDateTime']"
        [editable]="true"
        [fillIdBy]="'id'"
        [customData]="operations"
        [enablePaginator]="false"
        [disableEditInNewTabButton]="true"
        (editButtonEvent)="editRelationships('source', $event)"
        (deleteButtonEvent)="deleteRelationship($event)"
        [disableExpand]="true"
        [getRowClassCallback]="getRowClassCallback"
        suffix="operationsTable"
        [translationPrefix]="'wc.admin.relationships'">
      </app-table-listing-form>
    </div>

    <div class="card__wrapper">
      <!-- card -->
      <div class="card -full">
        <div class="card__inner">
          <app-relationships-listing
            #sourceRelationshipsTable
            [parentId]="'source'"
            [cacheFilter]="false"
            [constantFilter]="getProductSourceFilter()"
            [editable]="true"
            [disableSetValidToActions]="true"
            parentId="sourceRelationshipsTable"
            [sourceProductCode]="product.productCode"
            [replaceEditCallback]="editRelationshipCallback"
            [replaceDeleteCallback]="deleteRowCallback"
            [getRowClassCallback]="getRowClassCallback"
            [disabledSourceTarget]="!this.productEditService.enableEditProduct"
            additionalHeaderLtKey="wc.admin.relationships.sourceType">
          </app-relationships-listing>
        </div>
      </div>
    </div>
  </div>
  <div class="card -full">
    <div class="card__inner">
      <app-relationships-listing
        [cacheFilter]="false"
        [parentId]="'target'"
        [constantFilter]="getProductTargetFilter()"
        [editable]="true"
        [disableSetValidToActions]="true"
        parentId="targetRelationshipsTable"
        [targetProductCode]="product.productCode"
        [replaceEditCallback]="editRelationshipCallback"
        [replaceDeleteCallback]="deleteRowCallback"
        [getRowClassCallback]="getRowClassCallback"
        [disabledSourceTarget]="!this.productEditService.enableEditProduct"
        additionalHeaderLtKey="wc.admin.relationships.targetType">
      </app-relationships-listing>
    </div>
  </div>
</div>
