<td><input id="{{ parentId }}_001_product_edit_groups_item" [(ngModel)]="selected" type="checkbox" /></td>
<td>
  <input
    id="{{ parentId }}_002_product_edit_groups_item"
    (ngModelChange)="seqChanged($event)"
    [ngModel]="seq"
    class="form__input"
    [disabled]="disabled" />
</td>
<td>{{ group.id }}</td>
<td>{{ group.name }}</td>
<td>{{ group.priority }}</td>
<td>{{ group.type }}</td>
<td>{{ group.code }}</td>
<td>{{ group.created | date: 'dd.MM.yyyy HH:mm:ss' }}</td>
<td>{{ group.modified | date: 'dd.MM.yyyy HH:mm:ss' }}</td>
<td>{{ group.modifiedBy }}</td>
