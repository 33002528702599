import { Component } from '@angular/core';
import {
  AclService,
  AdminCodebookService,
  AdminDynamicEnumService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { EnumEntryDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { EnumerationsService } from '@service/enumerations.service';
import { CodebookTypeEditComponent } from '@components/codebook/codebookType/edit/codebook-type-edit.component';
import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-codebook-type-list',
  templateUrl: './codebook-type-list.component.html',
  styleUrls: ['./codebook-type-list.component.scss'],
})
@EnableDynamicLoading({ customName: CodebookTypeListComponent.PAGE_ID })
export class CodebookTypeListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'CodebookTypeListComponent';

  pageId(): string {
    return CodebookTypeListComponent.PAGE_ID;
  }

  public static readonly CODEBOOK_TYPE_FILTER = 'codebookTypeListFilter';
  readonly SOURCE_NAME = EnumerationsService.CODEBOOK;
  readonly ENUM_NAME = 'com.emeldi.ecc.be.cb.enums.CodebookType';

  pagedCodebookTypesDto: any;

  constructor(
    private adminDynamicEnumService: AdminDynamicEnumService,
    private adminCodebookService: AdminCodebookService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    protected modulePreferencesService: ModulePreferencesService,
    public aclService: AclService
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  deleteCodebookType(codebookType: EnumEntryDto) {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.codebookType.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminDynamicEnumService
        .deleteEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, codebookType.name)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.loadData();
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  editCodebookType(codebookType: EnumEntryDto, inNewTab: boolean = false) {
    this.navigateSibling(CodebookTypeEditComponent.PAGE_ID, { id: codebookType.name }, inNewTab);
  }

  newCodebookType() {
    this.navigateSibling(CodebookTypeEditComponent.PAGE_ID, { id: '&' });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {

      this.search.sorting.forEach(sort => {
        if (sort.column === 'name') {
          sort.column = 'codebookType';
        }
      });
      this.adminCodebookService.getCodebookTypes(this.search, null).subscribe(result => {
        if (result) {
          this.pagedCodebookTypesDto = result;
          const codebookTypes = [];

          result.data.forEach(codebookType =>
            codebookTypes.push({
              name: codebookType,
            }),
          );
          this.pagedCodebookTypesDto.data = codebookTypes;
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }
}
