<div class="dashboard_inner last-changes">
  <div class="dashboard_inner_header">
    {{ 'wc.admin.dashboard.lastChanges.title' | translate }}
  </div>
  <mat-list class="dashboard_inner_list">
    <mat-list-item class="lastChanges" *ngFor="let lastChange of lastChanges; let i = index">
      <div
        class="inner-item lastChanges"
        [ngStyle]="{ background: getColor(i) }"
        [matTooltip]="lastChange.name">
        {{ lastChange.shortName }}
      </div>
      <div class="dashboard_inner_list_text">
        <div class="ticket-subject"><a [href]="'pc/products/'+lastChange.product.id+'/attributes'"><span>{{ getProductName(lastChange.product) }}</span></a></div>
        <!--ToDo use time ego instead of showing just date time  -->
        <div class="ticket-description">{{ lastChange.module | translate }} {{ lastChange.product.modified | date: 'HH:mm dd.MM.yyyy' }}</div>
      </div>
    </mat-list-item>
  </mat-list>
</div>
