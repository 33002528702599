<h2 mat-dialog-title>
  {{ headerTranslationKey | translate }}
  <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    (click)="closeButtonHandler()"
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content>
  {{ messageTranslationEnable ? (messageTranslationKey | translate) : messageTranslationKey }}
</mat-dialog-content>
<mat-dialog-actions *ngIf="showOkButton" align="end">
  <button color="primary" mat-raised-button mat-dialog-close cdkFocusInitial>
    {{ 'wc.common.ok.button' | translate }}
  </button>
</mat-dialog-actions>
