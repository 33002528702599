import { Component, Input, ViewChild } from '@angular/core';
import { Search } from '@btl/btl-fe-wc-common';
import { PromotionListComponent } from '@components/product-catalogue/promotion/list/promotion-list.component';

@Component({
  selector: 'app-promotions-select-modal',
  templateUrl: './promotions-select-modal.component.html',
  styleUrls: ['./promotions-select-modal.component.scss'],
})
export class PromotionsSelectModalComponent {
  @ViewChild(PromotionListComponent, { static: true })
  promotionListingComponent: PromotionListComponent;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  multiSelectMode = false;

  singleSelectMode = true;

  excludePromotionIds = [];

  @Input()
  disableActions: boolean = false;

  @Input()
  disableListingActions: boolean = false;

  activeMultiSelectMode() {
    this.multiSelectMode = true;
    this.singleSelectMode = false;
  }

  selectEvent() {
    if (this.singleSelectMode) {
      const promotionDto = this.promotionListingComponent.getSelectedData()[0];
      if (promotionDto) {
        this.selectHandler(promotionDto);
        this.dialogRef.close();
      }
    } else {
      const promotionDtos = this.promotionListingComponent.getSelectedData();
      if (promotionDtos && promotionDtos.length > 0) {
        this.selectHandler(promotionDtos);
        this.dialogRef.close();
      }
    }
  }

  singleSelect(selectedDto) {
    this.selectHandler(selectedDto);
    this.dialogRef.close();
  }

  getSearch(): Search {
    return this.promotionListingComponent.search;
  }

  removeSelection() {
    if (this.singleSelectMode) {
      this.selectHandler(null);
    } else {
      this.selectHandler([]);
    }
    this.dialogRef.close();
  }
}
