import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AdminAclService, AppBlockerService, CompareType, Search } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  RulesSelectModalComponent
,
} from '@components/acl/components/acl-rules-select-modal/rules-select-modal.component';
import { RuleDto } from '@btl/admin-bff';
import { MatDialog } from '@angular/material/dialog';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-rule-picker, [app-rule-picker]',
  templateUrl: './rule-picker.component.html',
  styleUrls: ['./rule-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RulePickerComponent),
      multi: true,
    },
  ],
})
export class RulePickerComponent implements OnDestroy, ControlValueAccessor {
  @Output()
  readonly selectionChanged: EventEmitter<RuleDto> = new EventEmitter<RuleDto>();

  @Input()
  valueField;

  value;
  disable;
  rule;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private adminAclService: AdminAclService,
    private dialog: MatDialog,
    private appBlockerService: AppBlockerService
  ) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  selectRule() {
    const modalRef = this.dialog.open(RulesSelectModalComponent);
    const rulesSelectModalComponent = modalRef.componentInstance;
    rulesSelectModalComponent.dialogRef = modalRef;

    rulesSelectModalComponent.selectHandler = rule => {
      if (rule) {
        this.value = rule[this.valueField];
      } else {
        this.value = null;
      }

      this.rule = rule;
      this.propagateChange(this.value);
      this.propagateOnTouched();
    };
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (this.value) {
      if (this.valueField === 'id') {
        this.appBlockerService.block();
        this.adminAclService
          .getRule(this.value)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            this.rule = result;
          });
      }
      if (this.valueField === 'code') {
        const search: Search = {
          filtering: [
            {
              column: 'code',
              compareType: CompareTypeDtoEnum.EQUAL,
              value: this.value,
            },
          ],
          sorting: [],
          paging: {
            page: 1,
            pageSize: -1,
          },
        };
        this.appBlockerService.block();
        this.adminAclService
          .filterRules(search, null)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            if (result.data.length > 0) {
              this.rule = result.data[0];
            }
          });
      }
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
