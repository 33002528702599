<div *ngIf="userProfile" class="inner-component tickets">
  <mat-grid-list cols="2" rowHeight="215" gutterSize="35px">
    <mat-grid-tile>
      <app-ticket-mini-list
        [userProfile]="userProfile"
        [type]="'upcoming'">
      </app-ticket-mini-list>
    </mat-grid-tile>
    <mat-grid-tile>
      <app-ticket-mini-list
        [userProfile]="userProfile"
        [type]="'highPriority'">
      </app-ticket-mini-list>
    </mat-grid-tile>
  </mat-grid-list>
  <span class="tickets-view"
    ><a
      id="001_dashboard_ticket_list"
      routerLink="/ticketing/tickets/"
      [queryParams]="{ username: userProfile?.username }"
      >{{ 'wc.admin.dashboard.ticket.viewAll' | translate }}</a
    ></span
  >
</div>
