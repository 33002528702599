<div class="card -full">
  <div class="card__inner">
    <div *ngIf="!singleSelectMode && !multiSelectMode" class="card__header" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <span class="listing-header" *ngIf="!changesMode">{{ 'wc.admin.rules' | translate }}</span>
        <span class="listing-header" *ngIf="changesMode">{{ 'wc.admin.roles.changedRules' | translate }}</span>
      </div>
      <div fxLayoutGap="10px" class="listing-action" *ngIf="!changesMode && !disableActions">
        <button
          [id]="parentId ? parentId + '_' + '001_rules_listing' : '001_rules_listing'"
          color="primary"
          mat-raised-button
          (click)="addRule()"
          [routerLink]=""
          class="btn -primary -xs edit icon stroke">
          <mat-icon svgIcon="plus"></mat-icon>
          {{ 'wc.common.add' | translate }}
        </button>
        <mat-icon
          color="primary"
          (click)="isCollapsed = !isCollapsed"
          svgIcon="{{ isCollapsed ? 'hide' : 'show' }}"></mat-icon>
      </div>
    </div>

    <div [hidden]="isCollapsed" class="card__body">
      <div>
        <div
          *ngIf="role && !changesMode && pagedPrivilegesDto"
          [editable]="true"
          [changesMode]="true"
          [role]="role"
          [parentId]="0"
          [operations]="operations"
          (deleteRuleChangeEvent)="deleteRuleChange($event)"
          [pagedPrivilegesDto]="pagedPrivilegesDto"
          app-rules-listing
          class="card__wrapper"></div>
        <div class="rules-table">
          <div class="rules-filter-container">
            <app-rules-filter
              (filterChanged)="handleFilterChange()"
              (hideEvent)="toggleFilter()"
              [parentId]="0"
              *ngIf="baseFilterVisible && !hideFilter && !changesMode"
              [filtering]="search.filtering">
            </app-rules-filter>
            <div class="rules-filter-extra-buttons">
              <div
                fxLayoutGap="10px"
                class="listing-action"
                *ngIf="!changesMode && !disableListingActions && !disableActions">
                <button
                  (click)="deleteCheckedRules()"
                  [id]="parentId ? parentId + '_' + '002_rules_listing' : '002_rules_listing'"
                  mat-stroked-button
                  color="primary"
                  class="btn -primary -xs icon delete currentColor">
                  <mat-icon svgIcon="delete"></mat-icon>
                  {{ 'wc.admin.delete' | translate }}
                </button>
              </div>
            </div>
          </div>
          <table class="table -primary customTable" style="table-layout: fixed">
            <thead>
              <th *ngIf="!singleSelectMode && !multiSelectMode && !changesMode" [style.width.px]="30" scope="col"></th>
              <th *ngIf="changesMode" scope="col">{{ 'wc.admin.roles.operationType' | translate }}</th>
              <th scope="col">
                <app-sortable-column
                  (sortChanged)="onSortChange()"
                  [sorting]="search.sorting"
                  column="ruleType"
                  localizationKey="wc.admin.roles.ruleType">
                </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.roles.privilege"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.roles.privilege.resourceType"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.roles.validFrom"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.roles.validTo"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.roles.resourceIdentification"> </app-sortable-column>
              </th>
              <th class="content-desktop-cell" scope="col">
                <app-sortable-column localizationKey="wc.admin.products.product.created"> </app-sortable-column>
              </th>
              <th class="content-desktop-cell" scope="col">
                <app-sortable-column localizationKey="wc.admin.products.product.modified"> </app-sortable-column>
              </th>
              <th class="content-desktop-cell" scope="col">
                <app-sortable-column localizationKey="wc.admin.products.product.modifiedBy"> </app-sortable-column>
              </th>
              <th *ngIf="!singleSelectMode && !multiSelectMode && editable" [style.width.px]="75" scope="col">
                {{ 'wc.admin.products.product.action' | translate }}
              </th>
            </thead>
            <tbody>
              <ng-container *ngIf="!changesMode">
                <tr
                  (deleteRule)="deleteRule($event)"
                  (editModeChanged)="editedRuleChanged($event)"
                  (editRule)="editRule($event)"
                  *ngFor="let rule of pagedRulesDto?.data; let i = index"
                  [pagedPrivilegesDto]="pagedPrivilegesDto"
                  [operations]="operations"
                  [constantFilter]="constantFilter"
                  [editMode]="
                    editableRulesListingItemComponent && editableRulesListingItemComponent.rule.id === rule.id
                  "
                  [editable]="editable"
                  [parentId]="'listing_' + i"
                  [rule]="rule"
                  app-rules-listing-item
                  (click)="singleSelect(rule)"
                  (deselectOther)="deselectOther($event)"
                  [multiSelectMode]="multiSelectMode"
                  [singleSelectMode]="singleSelectMode"
                  (dblclick)="singleSelectMode || multiSelectMode ? singleSelect(rule) : null"
                  class="table__row {{
                    singleSelectMode
                      ? 'selectable'
                      : multiSelectMode
                      ? ''
                      : getRuleOperationTypeClass(this.operations, rule.id)
                  }}"></tr>
              </ng-container>
              <ng-container *ngIf="changesMode">
                <tr
                  (deleteRule)="deleteRule($event)"
                  (editModeChanged)="editedRuleChanged($event)"
                  (editRule)="editRule($event)"
                  *ngFor="let rule of operations; let i = index"
                  [rule]="rule"
                  [pagedPrivilegesDto]="pagedPrivilegesDto"
                  [operations]="operations"
                  [constantFilter]="constantFilter"
                  [editMode]="
                    (editableRulesListingItemComponent && editableRulesListingItemComponent.rule.id === rule.id) ||
                    (newRule && newRule.id === rule.id)
                  "
                  [editable]="editable"
                  [changesMode]="changesMode"
                  [parentId]="'listing_' + i"
                  app-rules-listing-item
                  class="table__row"></tr>
              </ng-container>
            </tbody>
          </table>
          <ng-container *ngIf="role">
            <mat-paginator
              *ngIf="!changesMode"
              [length]="pagedRulesDto?.totalItems"
              [pageSize]="50"
              (page)="pageSizeChanged($event)"
              showFirstLastButtons="true"
              [pageSizeOptions]="[10, 25, 50, 100]"
              color="primary"></mat-paginator>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
