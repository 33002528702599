import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ProductDto, ProductPriceDto } from '@btl/admin-bff';
import { Observable } from 'rxjs';
import { ProductEditService } from '@service/product-edit.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import { ProductPriceListingItemComponent } from '../product-price-listing-item/product-price-listing-item.component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { AbstractPageComponent, AclService, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { TableListingFormComponent } from '@components/table-listing-form/table-listing-form.component';
import { MatDialog } from '@angular/material/dialog';
import {
  ProductPriceEditComponent,
} from '@components/product-catalogue/products/product-edit/product-prices/product-price-edit/product-price-edit.component';

@Component({
  selector: 'app-product-price-listing',
  templateUrl: './product-price-listing.component.html',
  styleUrls: ['./product-price-listing.component.scss'],
})
@EnableDynamicLoading({ customName: ProductPriceListingComponent.PAGE_ID })
export class ProductPriceListingComponent extends AbstractPageComponent implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductPriceListingComponent';

  product: ProductDto = null;
  prices: ProductPriceDto[] = null;

  @ViewChildren(ProductPriceListingItemComponent)
  productPriceListItemComponents: Array<ProductPriceListingItemComponent>;

  @ViewChildren(TableListingFormComponent)
  tableListingFormComponents: QueryList<TableListingFormComponent>;

  constructor(
    private productEditService: ProductEditService,
    protected router: Router,
    private dialog: MatDialog,
    protected route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService
  ) {
    super(router, route);
    const product = this.productEditService.getEditProduct();
    if (product) {
      this.product = product;
      this.prices = this.product && this.product.prices ? this.product.prices : [];
    }
  }

  navigationSubscription(navigation: NavigationEnd) {}

  pageId(): string {
    return ProductPriceListingComponent.PAGE_ID;
  }

  ngOnInit() {
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      this.product = result;
      this.prices = this.product && this.product.prices ? this.product.prices : [];
    });
  }
  canDeactivate(): Observable<boolean> | boolean {
    this.productEditService.resetMode = false;
    return true;
  }

  saveData(): Observable<boolean> | boolean {
    return true;
  }

  editProductPrice(priceDto: ProductPriceDto, isNew: boolean = false) {
    const modalRef = this.dialog.open(ProductPriceEditComponent, {autoFocus: false});
    const productPriceEditComponent = modalRef.componentInstance;
    productPriceEditComponent.dialogRef = modalRef;
    productPriceEditComponent.productId = isNew ? '&' : priceDto.id;
    productPriceEditComponent.currentProductId = this.product.id;
    productPriceEditComponent.isNewPrice = isNew;
    productPriceEditComponent.selectHandler = () => this.productEditService.emitEditProductChange();
  }

  getSelectedProductPrices(): Array<ProductPriceDto> {
    let selectedProductPrices = [];
    this.tableListingFormComponents?.forEach(tableListingFormComponent => {
      if (tableListingFormComponent.selection.hasValue()) {
        selectedProductPrices = tableListingFormComponent.selection.selected;
      }
    });
    return selectedProductPrices;
  }

  deleteProductPrices() {
    this.deleteProductPricesAfterConfirmation(this.getSelectedProductPrices());
  }

  deleteProductPrice(priceDto: ProductPriceDto) {
    this.deleteProductPricesAfterConfirmation([priceDto]);
  }

  deleteProductPricesAfterConfirmation(selectedProductPrices: Array<ProductPriceDto>) {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.prices.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      selectedProductPrices.forEach(selectedProductPrice => {
        this.product.prices = this.product.prices.filter(price => price !== selectedProductPrice);
        this.productEditService.emitEditProductChange();
      });
      confirmationDialogComponent.dialogReference.close();
    };
  }
}
