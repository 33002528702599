import { Component, ViewChild } from '@angular/core';
import {
  AbstractPageComponent,
  AclService,
  AdminDynamicEnumService,
  AdminNotificationTypeService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import {
  GatewayDto,
  NotificationTypeDto,
  NotificationTypeGatewayAttachmentDto,
  NotificationTypeGatewayDto,
  NotificationTypeGatewayParamDto,
  NotificationTypeParamDto,
  PagedNotificationTypesDto,
  TextTypeDto
} from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationFrontendService, PagedNotificationsDto, SortDto } from '@btl/order-bff';
import { MatTabGroup } from '@angular/material/tabs';
import {
  NotificationTypeLocaleModalComponent,
} from '@components/notification/types/edit/local-modal/notification-type-locale-modal.component';
import { MatDialog } from '@angular/material/dialog';
import {
  NotificationTypeGatewaysModalComponent,
} from '@components/notification/types/edit/gateways-modal/notification-type-gateways-modal.component';
import { NotificationComponent } from '@components/notification/notification.component';
import { environment } from '@environments/environment';
import { EnumerationsService } from '@service/enumerations.service';
import { NotificationTypeListComponent } from '@components/notification/types/list/notification-type-list.component';

@Component({
  selector: 'app-notification-type-edit',
  templateUrl: './notification-type-edit.component.html',
  styleUrls: ['./notification-type-edit.component.scss'],
  providers: [NotificationFrontendService],
})
@EnableDynamicLoading({ customName: NotificationTypeEditComponent.PAGE_ID })
export class NotificationTypeEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'NotificationTypeEditComponent';

  pageId(): string {
    return NotificationTypeEditComponent.PAGE_ID;
  }

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  notificationTypeDto: NotificationTypeDto;
  notificationTypeInitialized = false;

  // Form Groups
  typeForm: FormGroup = this.formBuilder.group({
    notificationType: [null, [Validators.required, Validators.pattern('^([A-Z0-9_]+)$'), Validators.maxLength(100)]],
    remark: [null],
    productionEnabled: [false],
    sendingEnabled: [true],
  });

  selectedGroupGatewayTypeForm: FormGroup;
  selectedGroupGatewayTypeIndex: number;
  selectedGatewayTypeForm: FormGroup;
  selectedGatewayTypeIndex: number;
  textTypes: Array<TextTypeDto> = [];
  locales = environment.localization.locales;

  typeParamFormGroup = this.formBuilder.group({
    parameters: this.formBuilder.array([]),
  });

  typeGatewayFormGroup = this.formBuilder.group({
    groupedTypeGateways: this.formBuilder.array([]),
  });

  get typeParametersFormArray(): FormArray {
    return this.typeParamFormGroup.get('parameters') as FormArray;
  }

  get groupedTypeGatewaysFormArray(): FormArray {
    return this.typeGatewayFormGroup.get('groupedTypeGateways') as FormArray;
  }

  get codeFormControl(): AbstractControl {
    return this.typeForm.get('notificationType');
  }

  static clearAttributes(notificationTypeDto: NotificationTypeDto, clearCode: boolean): NotificationTypeDto {
    const clearedNotificationTypeDto = notificationTypeDto ? JSON.parse(JSON.stringify(notificationTypeDto)) : {};
    clearedNotificationTypeDto.id = null;
    clearedNotificationTypeDto.created = null;
    clearedNotificationTypeDto.createdBy = null;
    clearedNotificationTypeDto.modified = null;
    clearedNotificationTypeDto.modifiedBy = null;

    if (clearedNotificationTypeDto?.notificationTypeGateways) {
      for (const gateway of clearedNotificationTypeDto.notificationTypeGateways) {
        delete gateway.created;
        delete gateway.createdBy;
        delete gateway.modified;
        delete gateway.modifiedBy;
        if (clearCode) {
          delete gateway.code;
        }
      }
    }
    return clearedNotificationTypeDto;
  }

  constructor(
    public aclService: AclService,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private adminNotificationTypeService: AdminNotificationTypeService,
    private notificationService: NotificationFrontendService
  ) {
    super(router, route);

    this.appBlockerService.block();
    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.DMS, 'com.emeldi.ecc.be.dms.enums.TextType')
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.textTypes = [];
        result.data.forEach(textType => {
          this.textTypes.push({
            id: textType.name,
            entityType: '',
            contentType: textType.fields.contentType,
            priority: textType.fields.priority,
          });
        });
        this.textTypes.sort((a, b) => (a.priority > b.priority ? 1 : -1));
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const typeId = this.params.id;
      this.setNotificationTypeDto(typeId);
    }
  }

  setNotificationTypeDto(typeId: string, hard = false): void {
    if (hard) {
      this.notificationTypeInitialized = false;
    }

    if (
      typeId &&
      typeId !== '&' &&
      typeId !== 'newNotificationType' &&
      (hard || !this.notificationTypeDto || this.notificationTypeDto.id !== typeId)
    ) {
      this.appBlockerService.block();
      this.adminNotificationTypeService
        .getNotificationTypeById(typeId)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: NotificationTypeDto) => {
          this.notificationTypeDto = result;
          this.initForm();
        });
    } else {
      this.notificationTypeDto = {
        id: null,
        productionEnabled: false,
        sendingEnabled: true,
        parameters: [],
        notificationTypeGateways: [],
      };
      this.initForm();
    }
  }

  initForm(): void {
    this.typeForm.reset(this.notificationTypeDto);
    this.checkEditableCode();
    this.initParameters();
    this.initTypeGateways();
    this.notificationTypeInitialized = true;
  }

  save(): void {
    if (!this.isAllFormsValid()) {
      return;
    }
    this.updateModels();

    this.appBlockerService.block();
    if (!this.notificationTypeDto.id) {
      this.adminNotificationTypeService
        .createNotificationType(this.notificationTypeDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: NotificationTypeDto) => {
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          this.notificationTypeDto = result;
          this.initForm();
        });
    } else {
      const id = this.notificationTypeDto.id;
      const notificationTypeDto = NotificationTypeEditComponent.clearAttributes(this.notificationTypeDto, false);
      this.adminNotificationTypeService
        .updateNotificationType(id, notificationTypeDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: NotificationTypeDto) => {
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          this.notificationTypeDto = result;
          this.initForm();
        });
    }
  }

  cancel(): void {
    this.navigateSibling(NotificationTypeListComponent.PAGE_ID);
  }

  reset(): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.notification.reset.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
      this.refreshPage(true);
      confirmationDialogComponent.dialogReference.close();
    };
  }

  delete(): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.notification.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
      this.appBlockerService.block();
      this.adminNotificationTypeService
        .deleteNotificationType(this.notificationTypeDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: NotificationTypeDto) => {
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
          this.cancel();
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  duplicate(): void {
    // reset dto attributes
    this.notificationTypeDto = NotificationTypeEditComponent.clearAttributes(this.notificationTypeDto, true);
    this.notificationTypeDto.recordVersion = null;
    this.notificationTypeDto.notificationType = `${this.notificationTypeDto.notificationType}_COPY`;
    this.initForm();
  }

  initParameters(): void {
    this.typeParametersFormArray.controls = [];
    if (this.notificationTypeDto?.parameters && this.notificationTypeDto?.parameters.length) {
      this.notificationTypeDto?.parameters.forEach((param: NotificationTypeParamDto) => {
        this.addParamToFormArray(param);
      });
    }
  }

  addNewParameter(): void {
    this.addParamToFormArray({
      name: null,
      value: null,
      seq: this.typeParametersFormArray.controls.length,
      indexedValue: null,
    });
  }

  deleteParameter(index: number): void {
    this.typeParametersFormArray.removeAt(index);
  }

  checkUniqueCode(): void {
    if (!this.codeFormControl.valid) {
      this.codeFormControl.markAsTouched();
      return;
    }

    const search: Search = {
      filtering: [{ column: 'notificationType', compareType: 'EQUAL', value: this.codeFormControl.value }],
      sorting: [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }],
      paging: { page: 1, pageSize: 1 },
    };

    this.codeFormControl.setErrors(null);
    this.appBlockerService.block();
    this.adminNotificationTypeService
      .filterNotificationTypes(search)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PagedNotificationTypesDto) => {
        if (result?.data && result.data.length > 0) {
          this.codeFormControl.setErrors({ duplicate: true });
          this.codeFormControl.markAsTouched();
        }
      });
  }

  initTypeGateways(): void {
    if (!this.notificationTypeDto?.notificationTypeGateways?.length) {
      this.notificationTypeDto.notificationTypeGateways = [];
    }

    if (this.notificationTypeDto.notificationTypeGateways.length > 0) {
      const groupedGatewayByCode = NotificationComponent.groupBy(
        this.notificationTypeDto.notificationTypeGateways,
        typeGateway => typeGateway.gateway
      );

      this.groupedTypeGatewaysFormArray.controls = [];
      groupedGatewayByCode.forEach((typeGateways: NotificationTypeGatewayDto[], key: string) => {
        const formGroup = this.formBuilder.group({
          gateway: null,
          typeGateways: this.formBuilder.array([]),
        });
        for (const typeGatewayDto of typeGateways) {
          formGroup.get('gateway').setValue(typeGatewayDto.gateway);
          (formGroup.get('typeGateways') as FormArray).push(this.addTypeGatewayForm(typeGatewayDto));
        }
        this.groupedTypeGatewaysFormArray.push(formGroup);
      });

      this.selectedGroupGatewayTypeForm = this.groupedTypeGatewaysFormArray.at(0) as FormGroup;
      this.selectedGroupGatewayTypeIndex = 0;

      if ((this.selectedGroupGatewayTypeForm.get('typeGateways') as FormArray).controls.length) {
        this.selectGatewayType(
          (this.selectedGroupGatewayTypeForm.get('typeGateways') as FormArray).at(0) as FormGroup,
          0
        );
      }
    }
  }

  addGroupedTypeGateway(): void {
    const modalRef = this.dialog.open(NotificationTypeGatewaysModalComponent);
    const gatewaysModalComponent = modalRef.componentInstance;
    gatewaysModalComponent.dialogRef = modalRef;

    gatewaysModalComponent.selectGatewayEvent.subscribe((gatewayDto: GatewayDto) => {
      const groupedTypeGateways = this.groupedTypeGatewaysFormArray.getRawValue();

      let exist = false;
      groupedTypeGateways.forEach(groupedTypeGateway => {
        if (groupedTypeGateway.gateway === gatewayDto.code) {
          exist = true;
        }
      });

      if (exist) {
        modalRef.close();
        return;
      }

      this.groupedTypeGatewaysFormArray.push(
        this.formBuilder.group({
          gateway: gatewayDto.code,
          typeGateways: this.formBuilder.array([]),
        })
      );
      const index =
        this.groupedTypeGatewaysFormArray.controls.length - 1 < 0
          ? 0
          : this.groupedTypeGatewaysFormArray.controls.length - 1;
      this.selectGroupGatewayType(this.groupedTypeGatewaysFormArray.at(index) as FormGroup, index);
      this.selectGatewayType(null, null);
      modalRef.close();
    });
  }

  selectGroupGatewayType(selectedGroupGatewayType: FormGroup, index: number): void {
    this.selectedGroupGatewayTypeForm = selectedGroupGatewayType;
    this.selectedGroupGatewayTypeIndex = index;

    if ((this.selectedGroupGatewayTypeForm.get('typeGateways') as FormArray).controls.length) {
      this.selectGatewayType(
        (this.selectedGroupGatewayTypeForm.get('typeGateways') as FormArray).at(0) as FormGroup,
        0
      );
    } else {
      this.selectGatewayType(null, null);
    }
  }

  removeGroupedTypeGateway(index: number): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.notification.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
      this.groupedTypeGatewaysFormArray.removeAt(index);
      if (index > 0) {
        this.selectedGroupGatewayTypeForm = this.groupedTypeGatewaysFormArray.at(index - 1) as FormGroup;
        this.selectedGroupGatewayTypeIndex = index - 1;
        const selectedGroupGatewayType = this.selectedGroupGatewayTypeForm.getRawValue();
        if (selectedGroupGatewayType?.typeGateways?.length) {
          this.selectGatewayType(
            (this.selectedGroupGatewayTypeForm.get('typeGateways') as FormArray).at(0) as FormGroup,
            0
          );
        }
      } else {
        this.selectedGroupGatewayTypeForm = null;
        this.selectedGroupGatewayTypeIndex = null;
      }
      confirmationDialogComponent.dialogReference.close();
    };
  }

  selectGatewayType(selectedGatewayType: FormGroup, index: number): void {
    this.selectedGatewayTypeForm = selectedGatewayType;
    this.selectedGatewayTypeIndex = index;
  }

  deleteGatewayTypeLanguage(groupIndex: number, localeIndex: number): void {
    if ((this.selectedGroupGatewayTypeForm.get('typeGateways') as FormArray).controls.length === 0) {
      return;
    }
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.notification.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
      (this.groupedTypeGatewaysFormArray.at(groupIndex).get('typeGateways') as FormArray).removeAt(localeIndex);

      if (localeIndex - 1 >= 0) {
        this.selectGatewayType(
          (this.groupedTypeGatewaysFormArray.at(groupIndex).get('typeGateways') as FormArray).at(
            localeIndex - 1
          ) as FormGroup,
          localeIndex - 1
        );
      } else if ((this.groupedTypeGatewaysFormArray.at(groupIndex).get('typeGateways') as FormArray).controls.length) {
        this.selectGatewayType(
          (this.groupedTypeGatewaysFormArray.at(groupIndex).get('typeGateways') as FormArray).at(0) as FormGroup,
          0
        );
      } else {
        this.selectGatewayType(null, null);
      }
      confirmationDialogComponent.dialogReference.close();
    };
  }

  addGatewayTypeLanguage(groupIndex: number): void {
    if ((this.selectedGroupGatewayTypeForm.get('typeGateways') as FormArray).controls.length === this.locales.length) {
      return;
    }
    const modalRef = this.dialog.open(NotificationTypeLocaleModalComponent);
    const localeModalComponent = modalRef.componentInstance;
    localeModalComponent.dialogRef = modalRef;

    localeModalComponent.saveEvent.subscribe((locale: string) => {
      const groupedTypeGatewayFormGroup = this.groupedTypeGatewaysFormArray.at(groupIndex) as FormGroup;
      const groupedTypeGateways = groupedTypeGatewayFormGroup.getRawValue();
      const typeGatewayDto: NotificationTypeGatewayDto = {};

      if (groupedTypeGateways.typeGateways.length === environment.localization.locales.length) {
        modalRef.close();
        return;
      }

      let index = 0;
      if (groupedTypeGateways.typeGateways.length > 0) {
        if (!groupedTypeGateways.typeGateways.find(i => i.locale === locale)) {
          const typeGatewayDto: NotificationTypeGatewayDto = groupedTypeGateways.typeGateways[0];
          typeGatewayDto.code = null;
          typeGatewayDto.locale = locale;
          typeGatewayDto.templateSubject = null;
          typeGatewayDto.templateBody = null;
          typeGatewayDto.templateBodyAlternative = null;
          typeGatewayDto.attachments = [];
          typeGatewayDto.parameters.forEach(typeGatewayParam => {
            typeGatewayParam.value = null;
          });
          (groupedTypeGatewayFormGroup.get('typeGateways') as FormArray).push(this.addTypeGatewayForm(typeGatewayDto));
          index = 1;
        }
      } else {
        typeGatewayDto.gateway = groupedTypeGateways.gateway;
        typeGatewayDto.locale = locale;
        (groupedTypeGatewayFormGroup.get('typeGateways') as FormArray).push(this.addTypeGatewayForm(typeGatewayDto));
      }
      this.selectGatewayType(
        (groupedTypeGatewayFormGroup.get('typeGateways') as FormArray).at(index) as FormGroup,
        index
      );
      modalRef.close();
    });
  }

  private addTypeGatewayForm(notificationTypeGatewayDto?: NotificationTypeGatewayDto): FormGroup {
    const formGroup = this.formBuilder.group({
      code: [notificationTypeGatewayDto?.code || null],
      notificationType: [notificationTypeGatewayDto?.notificationType || null],
      gateway: [notificationTypeGatewayDto?.gateway || null],
      locale: [{ value: notificationTypeGatewayDto?.locale || null, disabled: true }],
      templateBody: [notificationTypeGatewayDto?.templateBody || null, Validators.required],
      templateBodyAlternative: [notificationTypeGatewayDto?.templateBodyAlternative || null],
      templateSubject: [notificationTypeGatewayDto?.templateSubject || null, Validators.required],
      parameters: this.formBuilder.array([]),
      attachments: this.formBuilder.array([]),
    });

    if (notificationTypeGatewayDto?.parameters?.length) {
      notificationTypeGatewayDto?.parameters.forEach((param: NotificationTypeGatewayParamDto) => {
        (formGroup.get('parameters') as FormArray).push(
          this.formBuilder.group({
            name: [param.name || null, Validators.required],
            seq: [param.seq ? param.seq : 0],
            value: [param.value || null],
            indexedValue: [param.indexedValue || null],
          })
        );
      });
    }

    if (notificationTypeGatewayDto?.attachments?.length) {
      notificationTypeGatewayDto?.attachments.forEach((attachment: NotificationTypeGatewayAttachmentDto) => {
        (formGroup.get('attachments') as FormArray).push(
          this.formBuilder.group({
            inline: [!!attachment.inline],
            name: [attachment.name || null, Validators.required],
            contentType: [attachment.contentType || null],
            extId: [attachment.extId || null],
          })
        );
      });
    }

    return formGroup;
  }

  private addParamToFormArray(param: NotificationTypeParamDto): void {
    this.typeParametersFormArray.push(
      this.formBuilder.group({
        name: [param.name, [Validators.required]],
        value: [param.value],
        seq: [{ value: param.seq, disabled: true }],
        indexedValue: [{ value: param.indexedValue, disabled: true }],
      })
    );
  }

  private isAllFormsValid(): boolean {
    FormUtils.validateAllFormFields(this.typeForm);
    FormUtils.validateAllFormFields(this.typeParamFormGroup);
    FormUtils.validateAllFormFields(this.typeGatewayFormGroup);

    const isValid = this.typeForm.valid && this.typeParamFormGroup.valid && this.typeGatewayFormGroup.valid;
    if (!isValid) {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.notification.validation.error');
    }
    return isValid;
  }

  private updateModels(): void {
    this.updateModelFromFormGroup(this.notificationTypeDto, this.typeForm);

    this.notificationTypeDto.parameters = [];
    this.typeParametersFormArray.controls.forEach((paramForm: FormGroup, index: number) => {
      const parameter = paramForm.getRawValue() as NotificationTypeParamDto;
      this.notificationTypeDto.parameters.push({
        name: parameter.name,
        value: parameter.value,
        seq: parameter.seq !== null ? parameter.seq : index,
        indexedValue: parameter.indexedValue || null,
      });
    });

    this.notificationTypeDto.notificationTypeGateways = [];
    const groupedTypeGateways = this.groupedTypeGatewaysFormArray.getRawValue();
    groupedTypeGateways.forEach(typeGatewaysItem => {
      typeGatewaysItem.typeGateways.forEach((typeGateway: NotificationTypeGatewayDto) => {
        this.notificationTypeDto.notificationTypeGateways.push(typeGateway);
      });
    });
  }

  private updateModelFromFormGroup(model: any, form: FormGroup): void {
    Object.keys(form.controls).forEach((field: string) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        model[field] = control.value;
      } else if (control instanceof FormGroup) {
        this.updateModelFromFormGroup(model[field], control);
      }
    });
  }

  private refreshPage(hard = false) {
    const typeId = this.notificationTypeDto.id ? this.notificationTypeDto.id : 'newNotificationType';
    this.setNotificationTypeDto(typeId, hard);
  }

  private checkEditableCode(): void {
    if (!this.codeFormControl.value) {
      return;
    }

    this.appBlockerService.block();
    const search: Search = {
      filtering: [{ column: 'notificationType', compareType: 'EQUAL', value: this.codeFormControl.value }],
      sorting: [],
      paging: {
        page: 1,
        pageSize: 1,
      },
    };
    const serializedFilter = JSON.stringify(search.filtering);
    const serializedSorting = ServiceUtils.getSerializedSorting(search.sorting);

    this.notificationService
      .filterNotifications(
        null,
        serializedFilter,
        serializedSorting,
        search.paging.page,
        search.paging.pageSize,
        search.fields
      )
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PagedNotificationsDto) => {
        if (result?.data && result.data.length > 0) {
          this.codeFormControl.disable();
        } else {
          this.codeFormControl.enable();
        }
      });
  }
}
