<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'id'"
            [inputType]="'text'"
            id="001_privileges_filter"
            [translationPrefix]="'wc.admin.privileges'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            *ngIf="resourceTypes"
            [formControlName]="'resourceType'"
            id="004_privileges_filter"
            [options]="resourceTypes.data"
            [showEmptyOption]="true"
            [labelOption]="'name'"
            [valueOption]="'name'"
            (keydown.enter)="handleChange()"
            [label]="'resourceType'"
            [disableOptionsTranslate]="true"
            [translationPrefix]="'wc.admin.privileges'">
          </app-input-select-form-field>
          <app-input-select-form-field
            id="009_accounts_filter"
            *ngIf="aclRoles"
            [translationPrefix]="'wc.admin.account'"
            [options]="aclRoles"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [labelOption]="'id'"
            [valueOption]="'id'"
            [multipleSelect]="true"
            [disableOptionsTranslate]="true"
            [formControlName]="'roles'">
          </app-input-select-form-field>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
