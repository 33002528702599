import { Component, Input, ViewChild } from '@angular/core';
import { NotificationTypeListComponent } from '@components/notification/types/list/notification-type-list.component';

@Component({
  selector: 'app-notification-select-modal',
  templateUrl: './notification-type-select-modal.component.html',
  styleUrls: ['./notification-type-select-modal.component.scss'],
})
export class NotificationTypeSelectModalComponent {
  @ViewChild(NotificationTypeListComponent, { static: true })
  notificationTypeListComponent: NotificationTypeListComponent;

  @Input()
  dialogRef;

  @Input()
  isModal: boolean = true;

  @Input()
  selectHandler;

  @Input()
  singleSelectMode = true;

  @Input()
  disableActions = false;

  @Input()
  disableListingActions = true;

  @Input()
  disableExpand = true;

  @Input()
  selectMode = false;

  excludePromotionIds = [];

  selectEvent() {
    if (this.singleSelectMode) {
      const cmsNotificationDto = this.notificationTypeListComponent.getSelectedData()[0];
      if (cmsNotificationDto) {
        this.selectHandler(cmsNotificationDto);
        this.dialogRef.close();
      }
    }
  }

  selectNothingEvent() {
    this.dialogRef.close();
  }

  singleSelect(notificationType) {
    this.selectHandler(notificationType);
    this.dialogRef.close();
  }
}
