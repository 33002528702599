<h2 mat-dialog-title>
  {{ 'wc.common.choose' | translate }}
  <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content class="mat-typography">
  <app-roles-listing
    [editable]="false"
    [disableActions]="disableActions"
    [disableListingActions]="disableListingActions"
    [cacheFilter]="false">
  </app-roles-listing>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button id="002_acl_roles_modal" color="primary" mat-raised-button (click)="cancelEvent()" [routerLink]="">
    {{ 'wc.common.cancel.button' | translate }}
  </button>
  <button id="003_acl_roles_modal" color="primary" mat-raised-button (click)="selectEvent()" [routerLink]="">
    {{ 'wc.common.choose' | translate }}
  </button>
</mat-dialog-actions>
