<div class="main__header">
  <h1 id="001_privileges_edit">{{ 'wc.admin.privileges.privilegeEdit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_privileges_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      [routerLink]=""
      *ngIf="aclService.pageEditAccess"
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="003_privileges_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_privileges_edit" mat-menu-item (click)="duplicate()" *ngIf="privilege?.id" [routerLink]=""
      >
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="005_privileges_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="006_privileges_edit" mat-menu-item (click)="delete()" *ngIf="privilege?.id" [routerLink]=""
      >
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="007_privileges_edit" (click)="cancel()" class="btn cancel-button">{{
      'wc.common.cancel.button' | translate
    }}</a>
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__body">
        <form *ngIf="privilege" [formGroup]="form" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="form"
                  [controlName]="'id'"
                  id="008_privileges_edit"
                  [inputType]="'text'"
                  [label]="'privilege'"
                  [translationPrefix]="'wc.admin.privileges'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  *ngIf="resourceTypes"
                  [formControlName]="'resourceType'"
                  id="004_privileges_filter"
                  [options]="resourceTypes.data"
                  [showEmptyOption]="true"
                  [labelOption]="'name'"
                  [valueOption]="'name'"
                  [label]="'resourceType'"
                  [translationPrefix]="'wc.admin.privileges.rule'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                  <mat-label>{{ 'wc.admin.privileges.description' | translate }}</mat-label>
                  <textarea
                    id="009_privileges_edit"
                    class="form__input"
                    matInput
                    type="text"
                    formControlName="description"
                    matTextareaAutosize></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card__wrapper" [formGroup]="form">
    <app-form-parameters class="card -full" [control]="form.get('parameters')" formControlName="parameters">
    </app-form-parameters>
  </div>

  <div class="card__wrapper" *ngIf="pagedRulesDto">
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
          <div fxLayout="column">
            {{ 'wc.admin.privileges.usedByRoles' | translate }}
          </div>
          <div fxLayoutGap="10px" class="listing-action">
            <mat-icon
              id="002_form_parameters"
              (click)="collapsedUsedByRoles = !collapsedUsedByRoles"
              color="primary"
              svgIcon="{{ collapsedUsedByRoles ? 'hide' : 'show' }}"></mat-icon>
          </div>
        </div>
        <div class="card__body" [hidden]="collapsedUsedByRoles">
          <app-table-listing-form
            [columnName]="['roleType', 'ruleType', 'resourceIdentification']"
            [fillIdBy]="'id'"
            [pagedDto]="pagedRulesDto"
            [disableActions]="true"
            (pageSizeEvent)="pageSizeChanged($event)"
            [editable]="false"
            [multiSelectMode]="false"
            [translationPrefix]="'wc.admin.roles'">
          </app-table-listing-form>
        </div>
      </div>
    </div>
  </div>
</div>
