import { Component, OnInit } from '@angular/core';
import {
  AclService,
  AdminDocumentTemplateService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PagedDocumentTemplatesDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { DocumentTemplateEditComponent } from '@components/document-template/edit/document-template-edit.component';
import { Observable } from 'rxjs';
import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';


@Component({
  selector: 'app-document-template-list',
  templateUrl: './document-template-list.component.html',
  styleUrls: ['./document-template-list.component.scss'],
})
@EnableDynamicLoading({ customName: DocumentTemplateListComponent.PAGE_ID })
export class DocumentTemplateListComponent extends AbstractListingPageComponent implements OnInit {
  public static readonly PAGE_ID = 'DocumentTemplateListComponent';

  pagedDocumentTemplatesDto: PagedDocumentTemplatesDto;

  constructor(
    private adminDocumentTemplateService: AdminDocumentTemplateService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    protected appBlockerService: AppBlockerService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  pageId(): string {
    return DocumentTemplateListComponent.PAGE_ID;
  }

  navigationSubscription(navigation: NavigationEnd) {}

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminDocumentTemplateService.deleteDocumentTemplate(rowDataId);
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.pagedDocumentTemplatesDto = null;
      this.adminDocumentTemplateService.filterDocumentTemplates(this.search, null).subscribe(result => {
        if (result) {
          this.pagedDocumentTemplatesDto = result;
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  newDocumentTemplate() {
    this.navigateSibling(DocumentTemplateEditComponent.PAGE_ID, { id: '&' });
  }

  editDocumentTemplate(documentTemplate, inNewTab: boolean = false) {
    this.navigateSibling(DocumentTemplateEditComponent.PAGE_ID, { id: documentTemplate.id }, inNewTab);
  }

}
