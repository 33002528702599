<div
  *ngIf="hasParent"
  (click)="removeNode()"
  class="promotion-design-border remove"
  ngClass="promotion-design-connector-{{ direction }}">
  <mat-icon>link_off</mat-icon>
</div>

<div
  (click)="moveNodeToItem(node)"
  [title]="
    moveNodeService.nodeToMove && node.type === 'root' ? ('wc.admin.promotion.clickToMoveSocket' | translate) : ''
  "
  class="{{ node.type === 'root' ? 'root-product' : 'socket' }} {{
    selectedNode && selectedNode.id === node.id ? 'selected' : ''
  }}">
  <div
    (click)="emitNodeClicked(node)"
    class="node-header {{ moveNodeService.nodeToMove && node.type !== 'root' ? 'noSelect' : '' }}">
    <div class="left-data">
      <ng-container *ngIf="node.type === 'root'">
        <div class="name">{{ node.productCode }}</div>
      </ng-container>
      <ng-container *ngIf="node.type === 'socket'">
        <div class="name">
          {{ node.socket.name }}
        </div>
        <div class="quantity">{{ node.socket.contentQty.min }}...{{ node.socket.contentQty.max }}</div>
      </ng-container>
    </div>
    <div class="right-data">
      <ng-container *ngIf="selectedNode && node.id === selectedNode.id">
        <div [title]="node.type === 'root' ? 'Add socket' : 'Add socket item'" class="add-socket">
          <mat-icon
            class="link"
            (click)="node.type === 'root' ? addSocket() : addSocketContent(); $event.stopPropagation()">
            add_circle
          </mat-icon>
        </div>
        <div [title]="node.type === 'root' ? 'Remove root product' : 'Remove socket'" class="remove-socket-content">
          <mat-icon class="link" (click)="removeNode()">remove_circle</mat-icon>
        </div>
      </ng-container>

      <div
        class="compact-view"
        *ngIf="node.type === 'socket'"
        [title]="compactView ? 'Full view' : 'Compact view'"
        (click)="compactView = !compactView; $event.stopPropagation()">
        <mat-icon class="link">{{ compactView ? 'expand_more' : 'expand_less' }}</mat-icon>
      </div>
    </div>
  </div>
  <ng-container *ngIf="node.type === 'socket' && !compactView">
    <div *ngFor="let socketItem of node.childs" class="socket-items">
      <div
        (click)="selectItem(socketItem.id)"
        (click)="moveNodeToItem(socketItem)"
        (click)="emitNodeClicked(socketItem)"
        [title]="
          moveNodeService.nodeToMove
            ? !moveToItself()
              ? 'wc.admin.promotion.clickToMoveSocket'
              : ('wc.admin.promotion.cantMoveSocketToItself' | translate)
            : ''
        "
        class="socket-item {{ selectedNode && socketItem.id === selectedNode.id ? 'selected' : '' }} {{
          moveNodeService.nodeToMove && !canMove(socketItem) ? 'noSelect' : ''
        }}">
        <div class="left-data">
          <div class="name">{{ socketItem.socketContent.entityType }}-{{ socketItem.socketContent.entityId }}</div>
          <div class="quantity">
            {{ socketItem.socketContent.contentQty.min }}-{{ socketItem.socketContent.contentQty.max }} -{{
              socketItem.socketContent.contentQty.def
            }}
          </div>
        </div>

        <div class="right-data">
          <div class="count-and-remove">
            <div class="item-child-count" *ngIf="socketItem.childs.length > 0">
              {{ socketItem.childs.length }}
            </div>
            <div class="add-socket" title="{{ 'wc.admin.promotion.addSocketItem' | translate }}">
              <mat-icon class="link" (click)="addProductSocket(socketItem); $event.stopPropagation()"
                >add_circle
              </mat-icon>
            </div>
            <div
              *ngIf="selectedNode && socketItem.id === selectedNode.id"
              class="remove-socket-content"
              title="{{ 'wc.admin.promotion.deleteSocketItem' | translate }}">
              <mat-icon class="link" (click)="removeItem(socketItem)">remove_circle</mat-icon>
            </div>
          </div>
        </div>
        <div class="selection">
          <div class="{{ socketItem.id === selectedItem ? 'selected-item' : '' }}"></div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div
  (click)="collapse(selectedItem)"
  *ngIf="(selectedItem && getSelectedItem()?.childs?.length) || (node.type === 'root' && node.childs?.length)"
  [title]="collapsed ? 'expand' : 'collapse'"
  class="selected-item collapsable">
  {{ collapsed ? '>' : '<' }}
</div>
<div
  *ngIf="(getSelectedItem()?.childs?.length || (node.type === 'root' && node.childs?.length)) && !collapsed"
  class="promotion-design-border"
  ngClass="promotion-design-connector-{{ direction }}"></div>
