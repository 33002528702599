import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SocketDto } from '@btl/admin-bff/model/socketDto';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { INode } from '@components/product-catalogue/promotion/edit/promotion-design/promotion-design.component';
import { SocketsSelectModalComponent } from '@components/sockets-select-modal/sockets-select-modal.component';
import { FormUtils, ServiceUtils } from '@btl/btl-fe-wc-common';
import { MatDialog } from '@angular/material/dialog';
import { MoveNodeService } from '@components/product-catalogue/promotion/edit/promotion-design/move-node.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { validateMax, validateSocketMin } from '@components/product-catalogue/socket/edit/socket-edit.component';

@Component({
  selector: 'app-selected-socket, [app-selected-socket]',
  templateUrl: './selected-socket.component.html',
  styleUrls: ['./selected-socket.component.scss'],
})
export class SelectedSocketComponent implements OnInit, OnChanges {
  @Input()
  node: INode;

  socket: SocketDto;

  @Input()
  socketCategories: Array<string> = [];

  @Output()
  readonly deleteNode = new EventEmitter<INode>();

  @Output()
  readonly closeEmitter = new EventEmitter<null>();

  form: FormGroup = this.formBuilder.group({
    id: [null],
    code: [null, [Validators.required]],
    name: [null, Validators.required],
    contentQty: this.formBuilder.group({
      min: [null],
      max: [null],
    }),
    socketCategory: [null, Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private moveNodeService: MoveNodeService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.patchForm();
    this.form.valueChanges.subscribe(valueChange => {
      ServiceUtils.copyWithExclude(valueChange, this.socket);
    });
  }

  ngOnChanges(changes: any) {
    this.patchForm();
  }

  validate() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      return true;
    } else {
      return false;
    }
  }

  patchForm() {
    this.socket = this.node.socket;
    this.form
      .get('contentQty')
      .get('min')
      .setValidators([Validators.required, validateSocketMin, validateMinWithContentsDef(this.socket)]);

    this.form
      .get('contentQty')
      .get('max')
      .setValidators([Validators.required, validateMax, validateMaxWithContentsDef(this.socket)]);

    if (this.socket) {
      this.form.patchValue(this.socket);
    }
  }

  delete() {
    this.deleteNode.emit(this.node);
  }

  selectSocket() {
    const modalRef = this.dialog.open(SocketsSelectModalComponent);
    const socketsSelectModalComponent = modalRef.componentInstance;
    socketsSelectModalComponent.dialogRef = modalRef;
    socketsSelectModalComponent.disableActions = true;
    socketsSelectModalComponent.disableTableActions = true;
    socketsSelectModalComponent.selectMode = false;
    socketsSelectModalComponent.isModal = true;
    socketsSelectModalComponent.selectHandler = socket => {
      this.node.socket = socket;
      this.node.childs.length = 0;
      socket.contents.forEach(socketContent => {
        this.node.childs.push({
          id: ServiceUtils.getRandomId(),
          type: 'socketItem',
          socket: socket,
          socketContent: socketContent,
          childs: [],
        });
      });
      this.patchForm();
    };
  }

  moveSocket() {
    this.translate.get('wc.admin.promotion.selectNewParentItem').subscribe((text: string) => {
      this.snackBar.open(text, null, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 5000,
      });
    });

    document.body.style.cursor = 'move';

    this.moveNodeService.nodeToMove = this.node;
    const sub = this.moveNodeService.deleteOldNode.subscribe(move => {
      this.deleteNode.emit(this.moveNodeService.nodeToMove);
      this.moveNodeService.addNewNode.emit(true);
      document.body.style.cursor = 'auto';
      sub.unsubscribe();
    });
  }
}

export const validateMinWithContentsDef = (socket: SocketDto) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const min = control.value;
    let sumDef = 0;
    socket.contents.forEach(content => (sumDef += content.contentQty.def ? Number(content.contentQty.def) : 0));

    if (min && min > sumDef) {
      return { minGtContentsDef: true };
    }
  };
  return null;
};

export const validateMaxWithContentsDef = socket => {
  return (control: AbstractControl): ValidationErrors | null => {
    const max = control.value;
    let sumDef = 0;
    socket.contents.forEach(content => (sumDef += content.contentQty.def ? Number(content.contentQty.def) : 0));

    if (max != null && max > -1 && max < sumDef) {
      return { maxGtContentsDef: true };
    }
  };
  return null;
};
