import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountDto } from '@btl/admin-bff';

@Component({
  selector: 'app-account-list-item, [app-account-list-item]',
  templateUrl: './account-list-item.component.html',
  styleUrls: ['./account-list-item.component.scss'],
})
export class AccountListItemComponent {
  @Input()
  multiSelectMode = false;

  @Input()
  singleSelectMode = false;

  @Input()
  account: AccountDto;

  @Input()
  parentId = '';

  @Output()
  readonly editAccount: EventEmitter<AccountDto> = new EventEmitter<AccountDto>();

  @Output()
  readonly editAccountInNewTab: EventEmitter<AccountDto> = new EventEmitter<AccountDto>();

  @Output()
  readonly deleteAccount: EventEmitter<AccountDto> = new EventEmitter<AccountDto>();

  selected = false;

  edit() {
    this.editAccount.emit(this.account);
  }

  editInNewTab() {
    this.editAccountInNewTab.emit(this.account);
  }

  delete() {
    this.deleteAccount.emit(this.account);
  }

  firstName(account: AccountDto): string {
    if (account.parameters) {
      const param = account.parameters.find(p => p.name === 'firstName');
      if (param) {
        return param.value;
      }
    }
    return null;
  }

  lastName(account: AccountDto): string {
    if (account.parameters) {
      const param = account.parameters.find(p => p.name === 'lastName');
      if (param) {
        return param.value;
      }
    }
    return null;
  }

  employeeId(account: AccountDto): string {
    if (account.parameters) {
      const param = account.parameters.find(p => p.name === 'employeeId');
      if (param) {
        return param.value;
      }
    }
    return null;
  }
}
