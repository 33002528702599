<div class="main__header">
  <h1 id="001_stocks_edit">{{ 'wc.admin.stocks.stockEdit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_stocks_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      [routerLink]=""
      *ngIf="aclService.pageEditAccess"
      class="btn -primary -xs icon stroke currentColor">
      <mat-icon svgIcon="save"></mat-icon>
      {{ 'wc.common.save' | translate }}</a
    >
    <a
      id="003_stocks_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_stocks_edit" mat-menu-item (click)="duplicate()" *ngIf="stock?.id" [routerLink]="">
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="005_stocks_edit" mat-menu-item (click)="reset()" [routerLink]="">
        <mat-icon svgIcon="reset"></mat-icon>
        {{ 'wc.common.reset' | translate }}</a
      >
      <a id="006_stocks_edit" mat-menu-item (click)="deleteStock()" *ngIf="stock?.id" [routerLink]="">
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="007_stocks_edit" (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__body">
        <form *ngIf="stock" [formGroup]="form" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-input-picker-form-field
                  [parentGroup]="form"
                  [controlName]="'productCode'"
                  [valueField]="'productCode'"
                  id="008_stocks_edit"
                  [pickerType]="PickerInputType.PRODUCT"
                  [selectMode]="false"
                  [showDescription]="true"
                  [translationPrefix]="'wc.admin.stocks'">
                </app-input-picker-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="form"
                  [controlName]="'amount'"
                  id="009_stocks_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.stocks'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  *ngIf="stockTypes"
                  [formControlName]="'type'"
                  id="010_stocks_edit"
                  [options]="stockTypes.data"
                  [showEmptyOption]="true"
                  [labelOption]="'name'"
                  [valueOption]="'name'"
                  [translationPrefix]="'wc.admin.stocks'">
                </app-input-select-form-field>
              </div>
            </div>
            <div class="form__group -flex">
              <div class="form__row" formGroupName="store">
                <app-input-picker-form-field
                  [parentGroup]="form.get('store')"
                  [controlName]="'id'"
                  [valueField]="'id'"
                  id="011_stocks_edit"
                  [pickerType]="PickerInputType.STORE"
                  (selectionChanged)="storeChanged($event, 'id')"
                  [selectMode]="false"
                  [showDescription]="true"
                  [translationPrefix]="'wc.admin.stocks.store'">
                </app-input-picker-form-field>
              </div>
              <div class="form__row" formGroupName="store">
                <app-input-select-form-field
                  *ngIf="pagedStoresDto"
                  [formControlName]="'extId'"
                  id="013_stocks_edit"
                  [options]="pagedStoresDto.data"
                  (selectionChangeEmit)="storeChanged($event, 'extId')"
                  [showEmptyOption]="true"
                  [labelOption]="'extId'"
                  [valueOption]="'extId'"
                  [translationPrefix]="'wc.admin.stocks.store'">
                </app-input-select-form-field>
              </div>
              <div class="form__row" formGroupName="store">
                <app-custom-input
                  [parentGroup]="form.get('store')"
                  [controlName]="'type'"
                  id="012_stocks_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.stocks.store'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
