<div class="main__header" *ngIf="!disableActions">
  <div class="header-box">
    <div class="icon-box PC">
      <mat-icon svgIcon="sockets"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.sockets' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      id="002_socket_list"
      (click)="newSocket()"
      [routerLink]=""
      [title]="'wc.admin.sockets.new.title' | translate"
      *ngIf="aclService.pageEditAccess"
      mat-raised-button
      color="primary"
      class="btn -primary -xs"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>
<div class="card__wrapper">
  <div class="card -full">
    <div class="card__inner">
      <app-socket-filter (filterChanged)="handleFilterChange()"
                         [cacheFilterUsed]="cacheFilterUsed"
                         [filtering]="search.filtering">
      </app-socket-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.socket.form.list' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedSocketsDto?.totalItems
                          ? pagedSocketsDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedSocketsDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action" *ngIf="!disableListingActions">
          <button
            id="003_socket_list"
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedData()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="[
            'code',
            'name',
            'socketCategory',
            'contentQty.min',
            'contentQty.max',
            'created',
            'modified',
            'modifiedBy'
          ]"
          [columnToTruncate]="['code', 'name', 'modifiedBy']"
          [dontShowOnMobile]="['created', 'modified', 'modifiedBy']"
          [fillIdBy]="'id'"
          [pagedDto]="pagedSocketsDto"
          (editButtonEvent)="editSocket($event)"
          (editInNewTabButtonEvent)="editSocket($event, true)"
          (deleteButtonEvent)="deleteData($event)"
          (pageSizeEvent)="pageSizeChanged($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [disableActions]="disableActions"
          [selectMode]="selectMode"
          (doubleClickEvent)="selectMode && !isModal ? editSocket($event) : singleSelect($event)"
          [editable]="aclService.pageEditAccess"
          [translationPrefix]="'wc.admin.socket'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
