import { Component } from '@angular/core';
import { AbstractFilterComponent, AdminDynamicEnumService, CompareType } from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { EnumerationsService } from '@service/enumerations.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-product-group-filter',
  templateUrl: './product-group-filter.component.html',
  styleUrls: ['./product-group-filter.component.scss'],
})
export class ProductGroupFilterComponent extends AbstractFilterComponent {
  groupTypes = [];

  mapFieldWithCompareType = new Map([
    ['code', CompareTypeDtoEnum.LIKE],
    ['seoUrl', CompareTypeDtoEnum.LIKE],
    ['name', CompareTypeDtoEnum.LIKE],
    ['type', CompareTypeDtoEnum.EQUAL],
  ]);

  constructor(private formBuilder: FormBuilder, private adminDynamicEnumService: AdminDynamicEnumService) {
    super();
    this.filterForm = this.formBuilder.group({
      code: [],
      seoUrl: [],
      name: [],
      type: [],
    });
    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductGroupType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.groupTypes = result.data.map(value => value.name).sort();
      });
  }

}
