<a
  *ngIf="complexFilter.haveAdvancedFilterFields()"
  color="primary"
  mat-stroked-button
  id="005_ticket_filter"
  class="btn-advanced"
  [routerLink]=""
  [matTooltip]="
    (complexFilter.isAdvancedFilterOpen
      ? 'wc.common.filter.showLessFields.tooltip'
      : 'wc.common.filter.showMoreFields.tooltip'
    ) | translate
  "
  (click)="complexFilter.toggleAdvancedFilter()">
  <mat-icon
    class="icon-advanced"
    svgIcon="doubleArrowDown"
    [@indicatorRotate]="complexFilter.isAdvancedFilterOpen ? 'open' : 'close'" />
</a>
<div *ngIf="complexFilter.changedFilterFieldsCount > 0" class="advancedFilterCount">
  {{ complexFilter.changedFilterFieldsCount }}
</div>
<a
  color="primary"
  mat-stroked-button
  class="btn-advanced"
  [matTooltip]="'wc.common.filter.resetFilter.tooltip' | translate"
  [routerLink]=""
  (click)="clearFilter.emit()">
  <mat-icon class="icon-filter-reset" svgIcon="filterReset" />
</a>
<a
  color="primary"
  mat-raised-button
  id="006_ticket_filter"
  class="btn -primary -xs"
  [routerLink]=""
  (click)="complexFilter.handleChange()"
>{{ 'wc.common.search.button' | translate }}
</a>
