import { Component, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminAccountEntityRoleService,
  AdminAclService,
  AdminDynamicEnumService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  ServiceUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AccountDto, AccountEntityRoleDto, EnumEntryDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { AccountRoleListItemComponent } from '@components/account/edit/role-item/account-role-list-item.component';
import { RoleDto } from '@btl/admin-bff/model/roleDto';
import { AccountEntityListComponent } from '@components/account-entity/list/account-entity-list.component';
import { EnumerationsService } from '@service/enumerations.service';
import {
  InputPickerFormFieldComponent,
  PickerInputType,
} from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { Animations } from '@helpers/animations';

@Component({
  selector: 'app-account-entity-edit',
  templateUrl: './account-entity-edit.component.html',
  styleUrls: ['./account-entity-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: AccountEntityEditComponent.PAGE_ID })
export class AccountEntityEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'AccountEntityEditComponent';
  PickerInputType = PickerInputType;

  pageId(): string {
    return AccountEntityEditComponent.PAGE_ID;
  }

  @ViewChildren(AccountRoleListItemComponent)
  accountRoleListItemComponents: Array<AccountRoleListItemComponent>;

  @ViewChild(InputPickerFormFieldComponent, { static: false })
  inputPickerFormFieldComponent: InputPickerFormFieldComponent;

  routerOutlet: any;

  public accountEntityRoleDto: AccountEntityRoleDto = {
    startDateTime: new Date(),
  };

  private accountEntityRoleId: string;

  grantMode: boolean = true;

  entityTypes: EnumEntryDto[] = [];

  aclRoles: RoleDto[] = [];

  rolesToSelect: RoleDto[] = [];

  form: FormGroup = this.formBuilder.group({
    id: [null],
    recordVersion: [null],
    account: this.formBuilder.group({
      accountId: [null, Validators.required],
    }),
    entityType: [null, Validators.required],
    entityId: [null, Validators.required],
    roleType: [null, Validators.required],
    startDateTime: [null, [Validators.required, FormUtils.validateOrderOfStartEnd()]],
    endDateTime: [null, FormUtils.validateOrderOfStartEnd()],
    canDelegate: [false, Validators.required],
    delegatedByAccount: this.formBuilder.group({
      accountId: [null],
      login: [null],
    }),
    delegatedFromId: [],
  });

  delegatedFrom: AccountEntityRoleDto;
  account: AccountDto;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected readonly route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private adminAccountEntityRoleService: AdminAccountEntityRoleService,
    private adminAclService: AdminAclService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    public aclService: AclService
  ) {
    super(router, route);
    this.adminAclService
      .filterRoles(ServiceUtils.getUnlimitedSearch(), null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.aclRoles = result.data;
        this.rolesToSelect = this.aclRoles.concat([]).sort((a, b) => a.id.localeCompare(b.id));
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.ACCOUNT, 'com.emeldi.ecc.be.account.enums.ExternalEntityType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.entityTypes = result.data;
      });

    this.route.queryParams.subscribe(queryParams => {
      const filterAccountId = queryParams[AccountEntityListComponent.ACCOUNT_ID_PARAM];
      const filterRoleType = queryParams[AccountEntityListComponent.ROLE_TYPE_PARAM];
      const filterEntityType = queryParams[AccountEntityListComponent.ENTITY_TYPE_PARAM];
      const filterEntityId = queryParams[AccountEntityListComponent.ENTITY_ID_PARAM];
      const delegatedAccountEntityRoleType =
        queryParams[AccountEntityListComponent.DELEGATED_ACCOUNT_ENTITY_ROLE_ID_PARAM];
      const grantMode = queryParams[AccountEntityListComponent.GRANT_MODE_PARAM];

      if (!this.accountEntityRoleDto.id) {
        if (filterAccountId) {
          this.form.controls.account.patchValue({ accountId: filterAccountId });
        }
        if (filterEntityType) {
          this.form.controls.entityType.patchValue(filterEntityType);
        }
        if (filterEntityId) {
          this.form.controls.entityId.patchValue(filterEntityId);
        }
        if (filterRoleType) {
          this.form.controls.roleType.patchValue(filterRoleType);
        }
      }
      if (grantMode) {
        this.grantMode = grantMode === 'true';
      }
      if (!this.grantMode && delegatedAccountEntityRoleType) {
        this.form.controls.delegatedFromId.patchValue(delegatedAccountEntityRoleType);
        this.accountEntityRoleDto.delegatedFromId = delegatedAccountEntityRoleType;
        this.getDelegatedFrom();
      }
    });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const accountEntityId = this.params.id;
      if (accountEntityId) {
        if (accountEntityId === '&') {
          this.setAccountEntityRole(this.accountEntityRoleDto);
        } else {
          this.loadAccountEntityRole(accountEntityId);
        }
      }
    } else {
      this.accountEntityRoleDto = undefined;
    }
  }

  loadAccountEntityRole(id: string) {
    this.appBlockerService.block();
    this.adminAccountEntityRoleService
      .getAccountEntityRoleById(id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setAccountEntityRole(result);
        this.accountEntityRoleId = this.accountEntityRoleDto.id;
        if (this.accountEntityRoleDto.delegatedFromId) {
          this.getDelegatedFrom();
        }
      });
  }

  getDelegatedFrom() {
    this.adminAccountEntityRoleService
      .getAccountEntityRoleById(this.accountEntityRoleDto.delegatedFromId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.delegatedFrom = result;
        if (this.delegatedFrom) {
          this.form.controls.delegatedFromId.patchValue(this.delegatedFrom.delegatedFromId);
          this.form.controls.delegatedByAccount.patchValue(this.delegatedFrom.account);
        }
      });
  }

  setAccountEntityRole(accountEntityRoleDto: AccountEntityRoleDto) {
    this.accountEntityRoleDto = accountEntityRoleDto;
    this.form.patchValue(this.accountEntityRoleDto);
    if (this.accountEntityRoleDto.recordVersion) {
      this.form.controls['entityType'].disable();
      this.form.controls['entityId'].disable();
      this.form.controls['roleType'].disable();
      this.form.get('delegatedByAccount.login').disable();
    }
    this.accountChanged(this.accountEntityRoleDto.account);
  }

  saveAccountEntityRole(): boolean {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      this.accountEntityRoleDto = this.form.getRawValue();
      return true;
    }
    return false;
  }

  save() {
    if (this.saveAccountEntityRole()) {
      this.appBlockerService.block();
      if (!(this.accountEntityRoleId === null || this.accountEntityRoleId === undefined)) {
        this.accountEntityRoleDto.entityType = null;
        this.accountEntityRoleDto.entityId = null;
        this.accountEntityRoleDto.roleType = null;
        this.accountEntityRoleDto.roleType = null;
        this.accountEntityRoleDto.delegatedByAccount.login = null;
        this.adminAccountEntityRoleService
          .updateAccountEntityRole(this.accountEntityRoleId, this.accountEntityRoleDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getAccountHandler);
      } else {
        this.adminAccountEntityRoleService
          .createAccountEntityRole(this.accountEntityRoleDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getAccountHandler);
      }
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public reset() {
    this.setAccountEntityRole(this.accountEntityRoleDto);
    this.accountEntityRoleId = this.accountEntityRoleDto.id;
    if (this.accountEntityRoleId) {
      this.navigateSelf({ id: this.accountEntityRoleId });
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.account.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminAccountEntityRoleService
        .deleteAccountEntityRole(this.accountEntityRoleDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(AccountEntityListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  public getAccountHandler = (accountEntityRoleDto: AccountEntityRoleDto): void => {
    let saving = false;
    if (this.accountEntityRoleDto) {
      saving = true;
    }
    if (accountEntityRoleDto && this.accountEntityRoleDto) {
      if (accountEntityRoleDto.id !== this.accountEntityRoleDto.id) {
        this.accountEntityRoleId = accountEntityRoleDto.id;
      }
      this.setAccountEntityRole(accountEntityRoleDto);
      this.navigateSelf({ id: accountEntityRoleDto.id });
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  cancel() {
    this.navigateSibling(AccountEntityListComponent.PAGE_ID);
  }

  roleChanged() {
    const role = this.aclRoles.find(role => role.id === this.form.controls.roleType.value);
    //ToDO handle role parameters when https://emeldi-commerce.atlassian.net/browse/TP2-796
  }

  accountChanged(account: any) {
    const accountId = account?.id ? account.id : account?.accountId ? account.accountId : null;
    if (accountId) {
      this.inputPickerFormFieldComponent.getAccountById(accountId);
    }
    this.rolesToSelect.length = 0;
    this.rolesToSelect = this.aclRoles
      ?.filter(role =>
        this.accountEntityRoleDto.recordVersion
          ? role?.id === this.accountEntityRoleDto.roleType
          : role?.external === account?.external
      )
      .sort((a, b) => a.id.localeCompare(b.id));
  }

  setAccount(account) {
    this.account = account;
  }
  getAccountParamByName(name: string): string {
    let value = null;
    if (this.account) {
      value = this.account.parameters?.find(param => param.name === name)?.value;
    }

    return value;
  }

  isNewAccountEntity() {
    return !this.accountEntityRoleDto.id || this.accountEntityRoleDto.id === '&';
  }
}
