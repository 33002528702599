<h2 mat-dialog-title>
  {{ 'wc.common.choose' | translate }}
  <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content class="mat-typography">
  <app-rules-listing
    (singleSelectEvent)="singleSelect($event)"
    [cacheFilter]="false"
    [multiSelectMode]="multiSelectMode"
    [disableActions]="disableActions"
    [disableListingActions]="disableListingActions"
    [singleSelectMode]="singleSelectMode">
  </app-rules-listing>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button id="002_rules_modal" color="primary" mat-raised-button (click)="selectEvent()" [routerLink]="">
    {{ 'wc.common.choose' | translate }}
  </button>
</mat-dialog-actions>
