import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ProductEditService } from '@service/product-edit.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import {
  AbstractPageComponent,
  AdminStickerService,
  AppBlockerService,
  CompareType,
  EnableDynamicLoading,
  Search
} from '@btl/btl-fe-wc-common';
import { StickerDto } from '@btl/admin-bff';
import { StickersSelectModalComponent } from '@components/stickers-select-modal/stickers-select-modal.component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { TableListingFormComponent } from '@components/table-listing-form/table-listing-form.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-product-stickers',
  templateUrl: './product-stickers.component.html',
  styleUrls: ['./product-stickers.component.scss'],
})
@EnableDynamicLoading({ customName: ProductStickersComponent.PAGE_ID })
export class ProductStickersComponent extends AbstractPageComponent implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductStickersComponent';

  pageId(): string {
    return ProductStickersComponent.PAGE_ID;
  }

  public stickers = [];

  private loading = false;

  @ViewChildren(TableListingFormComponent)
  tableListingFormComponents: QueryList<TableListingFormComponent>;
  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };
  private product;

  constructor(
    public productEditService: ProductEditService,
    protected router: Router,
    protected route: ActivatedRoute,
    private dialog: MatDialog,
    private appBlockerService: AppBlockerService,
    private adminStickerService: AdminStickerService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super(router, route);
    const product = this.productEditService.getEditProduct();
    if (product) {
      this.product = product;
      this.loadStickers();
    }
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit() {
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if (result) {
        this.product = result;
        this.loadStickers();
      }
    });
  }

  loadStickers() {
    this.appBlockerService.block();
    const stickersIds = this.product.stickers.map(stickerId => stickerId);
    if (stickersIds.length > 0) {
      this.search.filtering = [
        {
          column: 'id',
          compareType: CompareTypeDtoEnum.IN,
          value: stickersIds,
        },
      ];
      this.loading = true;
      this.adminStickerService
        .getStickersByFilter(this.search)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.stickers.length = 0;
            result.data.forEach(sticker => {
              if (sticker) {
                this.stickers.push(sticker);
                this.stickers = [...this.stickers];
              }
            });
            this.loading = false;
          }
        });
    } else {
      this.stickers.length = 0;
      this.appBlockerService.unblock();
    }
  }

  getSelectedStickers(): Array<StickerDto> {
    let selectedStickers = [];
    this.tableListingFormComponents?.forEach(tableListingFormComponent => {
      if (tableListingFormComponent.selection.hasValue()) {
        selectedStickers = tableListingFormComponent.selection.selected;
      }
    });
    return selectedStickers;
  }

  getSickerById(stickerId: string): StickerDto {
    const sticker = null;
    this.stickers.forEach(sticker => {
      if (sticker.id === stickerId) {
        sticker = sticker;
      }
    });

    return sticker;
  }

  addSticker() {
    const modalRef = this.dialog.open(StickersSelectModalComponent);
    const stickersSelectModalComponent = modalRef.componentInstance;
    stickersSelectModalComponent.dialogRef = modalRef;
    stickersSelectModalComponent.disableFilter = true;
    stickersSelectModalComponent.disableActions = true;
    stickersSelectModalComponent.activeMultiSelectMode();

    stickersSelectModalComponent.selectHandler = stickers => {
      stickers.forEach(sticker => {
        if (this.getSickerById(sticker.productCode) === null) {
          this.stickers.push(sticker);
          this.stickers = [...this.stickers];
        }
      });
    };
  }

  deleteStickers() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.stickers.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.getSelectedStickers().forEach(selectedSticker => {
        this.stickers = this.stickers.filter(sticker => sticker !== selectedSticker);
      });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  deleteSticker(event) {
    this.stickers = this.stickers.filter(sticker => sticker !== event);
  }

  saveData(): boolean {
    if (!this.productEditService.canEdit() || this.loading) {
      return true;
    }
    this.product.stickers = this.stickers.map(sticker => sticker.id);
    return true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.productEditService.resetMode) {
      if (this.saveData()) {
        return true;
      } else {
        return false;
      }
    }

    this.productEditService.resetMode = false;

    return true;
  }
}
