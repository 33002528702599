<div class="card__header widget border-unset" fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="column" class="card__header_label">
    <span class="listing-header">{{ 'wc.admin.cms.content.widget.label' | translate }}</span>
  </div>
  <div fxLayoutGap="10px" class="listing-action">
    <button
      id="001_cms_content_widget"
      color="primary"
      mat-raised-button
      [disabled]="
        !(contentVersionDto.widgets && contentVersionDto.widgets.length && (editEnabled || contentVersionDto.verHash))
      "
      (click)="preview()"
      [routerLink]=""
      class="btn -primary -xs no-scale edit icon">
      <mat-icon class="default-icon">find_in_page</mat-icon>{{ 'wc.admin.cms.content.widget.preview' | translate }}
    </button>
  </div>
</div>
<div class="card__body" style="padding-top: 0">
  <div class="widget-container" *ngIf="initialized">
    <div class="widget-history-buttons">
      <div *ngIf="editEnabled">
        <button
          id="002_cms_content_widget"
          color="primary"
          mat-raised-button
          class="btn -primary -xs no-scale edit icon"
          (click)="undo()"
          [routerLink]=""
          [disabled]="historyIndex <= 0">
          <mat-icon>undo</mat-icon>{{ 'wc.admin.cms.content.widget.undo' | translate }}
        </button>
        <button
          id="003_cms_content_widget"
          color="primary"
          mat-raised-button
          class="btn -primary -xs no-scale edit icon"
          (click)="redo()"
          [routerLink]=""
          [disabled]="history.length === 0 || historyIndex >= history.length - 1">
          <mat-icon class="default-icon">redo</mat-icon>{{ 'wc.admin.cms.content.widget.redo' | translate }}
        </button>
      </div>
    </div>

    <div
      *ngIf="editEnabled"
      cdkDropList
      #widgetsList="cdkDropList"
      [cdkDropListData]="widgetTemplateDtoList"
      [cdkDropListConnectedTo]="[widgetSetup]"
      (cdkDropListDropped)="dropWidget($event)">
      <ng-container *ngFor="let item of contentVersionDto.widgets; let i = index">
        <div class="widget-add-row-btn" (click)="addWidget(i)"
             *ngIf="this.contentVersionDto.widgets.length < maxCardinality || maxCardinality === -1">
          <div class="widget-add-row-btn-border"></div>
          <div class="widget-add-row-btn-icon">
            <ng-container [ngTemplateOutlet]="widgetAddIcon"></ng-container>
          </div>
        </div>

        <div class="widget-add-row-item" cdkDrag>
          <div class="widget-add-row-item-drag">
            <mat-icon>drag_indicator</mat-icon>
          </div>
          <div class="widget-add-row-item-img">
            <mat-icon
              class="default-icon-currentColor"
              [svgIcon]="
                widgetIconMap[item.widgetTemplateType] ? widgetIconMap[item.widgetTemplateType] : 'widgetNew'
              "></mat-icon>
          </div>
          <div class="widget-add-row-item-type">
            {{ item.widgetTemplateType }}
          </div>
          <div class="widget-add-row-item-name">
            {{ item.name }}
          </div>
          <div class="widget-add-row-item-buttons">
            <button
              id="004_cms_content_widget"
              type="button"
              class="edit icon"
              (click)="editWidgetItem(item, i, false, item.widgetTemplateType)">
              <mat-icon class="default-icon-gray-fill default-icon-small no-stroke" svgIcon="edit"></mat-icon>
              {{ 'wc.admin.cms.content.widget.edit' | translate }}
            </button>
            <button id="005_cms_content_widget" type="button" class="remove icon" (click)="deleteWidget(i)">
              <mat-icon class="default-icon-gray-fill default-icon-small no-stroke" svgIcon="delete"></mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="!editEnabled">
      <ng-container *ngFor="let item of contentVersionDto.widgets; let i = index">
        <div class="widget-add-row-item disabled">
          <div class="widget-add-row-item-drag">
            <mat-icon>drag_indicator</mat-icon>
          </div>
          <div class="widget-add-row-item-img">
            <mat-icon
              class="default-icon-currentColor"
              [svgIcon]="
                widgetIconMap[item.widgetTemplateType] ? widgetIconMap[item.widgetTemplateType] : 'widgetNew'
              "></mat-icon>
          </div>
          <div class="widget-add-row-item-type">
            {{ item.widgetTemplateType }}
          </div>
          <div class="widget-add-row-item-name">
            {{ item.name }}
          </div>
          <div class="widget-add-row-item-buttons">
            <button
              id="006_cms_content_widget"
              type="button"
              class="edit"
              (click)="editWidgetItem(item, i, false, item.widgetTemplateType)">
              <mat-icon svgIcon="edit"></mat-icon>
              {{ 'wc.admin.cms.content.widget.edit' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>

    <div
      id="007_cms_content_widget"
      class="widget-add-btn"
      (click)="addWidget(contentVersionDto.widgets.length)"
      *ngIf="editEnabled && (this.contentVersionDto.widgets.length < maxCardinality || maxCardinality === -1)">
      <div class="widget-add-btn-icon">
        <ng-container [ngTemplateOutlet]="widgetAddIcon"></ng-container>
      </div>
      <div class="fa fa-angle-double-down"></div>
      <div class="widget-add-btn-label">{{ 'wc.admin.cms.content.widget.add' | translate }}</div>
    </div>

    <div class="empty-state_container" *ngIf="!contentVersionDto.widgets?.length">
      <div class="empty-state_img">
        <mat-icon style="width: 100px; height: 105px" svgIcon="widgetEmpty"></mat-icon>
      </div>

      <div class="empty-state_text">
        <div>{{ 'wc.admin.cms.content.widget.empty.title' | translate }}</div>
        <div>{{ 'wc.admin.cms.content.widget.empty.text' | translate }}</div>
      </div>
    </div>
  </div>
</div>
<ng-template #widgetAddIcon>
  <mat-icon svgIcon="widgetPlusSquareRegular" class="plus-icon"></mat-icon>
  <mat-icon svgIcon="widgetPlusSquareSolid" class="plus-icon-hover"></mat-icon>
</ng-template>
