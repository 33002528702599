import { Component, Input } from '@angular/core';
import { ContentMasterDto } from '@btl/admin-bff';

@Component({
  selector: '[app-cms-contents-item]',
  templateUrl: './cms-contents-item.component.html',
  styleUrls: ['./cms-contents-item.component.scss'],
})
export class CmsContentsItemComponent {
  @Input()
  contentMasterDto: ContentMasterDto;

  @Input()
  isCollapsed;

  @Input()
  parentId: string = '';

  getInternalText(isInternal: boolean): string {
    if (isInternal) {
      return 'wc.common.yes.button';
    } else {
      return 'wc.common.no.button';
    }
  }
}
