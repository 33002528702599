import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TechnicalCategoryParameterDto } from '@btl/admin-bff';
import { ProductParameterDto } from '@btl/admin-bff/model/productParameterDto';
import { EnumerationsService } from '@service/enumerations.service';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  AdminCodebookService,
  AdminDynamicEnumService,
  AppBlockerService,
  Search,
  ServiceUtils
} from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-category-parameter',
  templateUrl: './category-parameter.component.html',
  styleUrls: ['./category-parameter.component.scss'],
})
export class CategoryParameterComponent implements OnInit {
  private onDestroy$: Subject<void> = new Subject<void>();
  readonly SOURCE_NAME = EnumerationsService.PC;

  @Input()
  module: string;

  @Input()
  formArray: FormArray;

  @Input()
  parentParametersMode = false;

  @Input()
  parentId = '';

  @Input()
  parentForm: FormGroup;

  @Input()
  overwriteParent = false;

  @Input()
  editParentParameter: TechnicalCategoryParameterDto;

  @Input()
  parentParameters: ProductParameterDto[];

  @Input()
  parameter;

  lastConfirmedName = null;

  parameterTypes = [];

  @Input()
  enableLocalizedNames: boolean = true;

  @Input()
  enableMetaParameters: boolean = true;

  @Input()
  enableAcl: boolean = true;

  enums = [];
  enumItems = [];
  previousEnumName;
  enumItemsLoading = false;

  codebooksTypes = [];
  codebooks = [];
  previousCodebookName;

  codebooksLoading: boolean = false;

  inputBoolean = ['true', 'false'];

  constructor(
    private formBuilder: FormBuilder,
    private enumerationsService: EnumerationsService,
    private appBlockerService: AppBlockerService,
    private adminCodebookService: AdminCodebookService,
    private adminDynamicEnumService: AdminDynamicEnumService
  ) {
    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductParameterType')
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        this.parameterTypes = result.data;
      });
  }

  ngOnInit(): void {
    this.disableFormInputs();
    this.loadCodebookTypes();
    this.loadEnums();
    this.staticTypeChanged(this.parentForm.controls['static'].value);
  }

  nameChanged(event) {
    const parent = this.parentParameters?.find(param => param.name === event);
    if (parent && event !== this.lastConfirmedName) {
      this.lastConfirmedName = event;
      this.overwriteParent = true;
      parent.id = this.parameter.id;
      parent.category = this.parameter.category;
      this.parentForm.patchValue(parent);
      this.disableFormInputs();
    }
  }

  disableFormInputs() {
    Object.keys(this.parentForm.controls).forEach(field => {
      const control = this.parentForm.get(field);
      if (this.parentParametersMode || this.overwriteParent) {
        if (field !== 'defaultValue') {
          control.disable();
        }
      }
    });
  }

  loadCodebookTypes() {
    this.adminCodebookService
      .getCodebookTypes(ServiceUtils.getUnlimitedSearch(), null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.codebooksTypes = result.data;
        this.loadCodebookHints();
      });
  }

  getCodebooksByFilter() {
    this.codebooksLoading = true;
    const search: Search = {
      filtering: [{ column: 'codebookType', compareType: 'LIKE', value: this.parentForm.controls['typeDetail'].value }],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    this.adminCodebookService
      .getCodebooksByFilter(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.codebooks = result.data.map(c => c.code);
        this.codebooksLoading = false;
      });
  }

  dataTypeChanged() {
    this.parentForm.controls['defaultValue'].patchValue(null);
    this.parentForm.controls['typeDetail'].patchValue(null);
  }

  staticTypeChanged(isChecked: boolean) {
    if (isChecked) {
      this.parentForm.controls['localized'].enable();
    } else {
      this.parentForm.controls['localized'].patchValue(null);
      this.parentForm.controls['localized'].disable();
    }
  }

  loadCodebookHints() {
    const isListed = this.codebooksTypes.find(cb => cb === this.parentForm.controls['typeDetail'].value);
    if (this.codebooks.length === 0 && this.parentForm.controls['typeDetail'].value && isListed) {
      this.getCodebooksByFilter();
    }
  }

  codeBookChanged(codebookValue) {
    if (this.previousCodebookName) {
      this.parentForm.controls['defaultValue'].patchValue(null);
      this.codebooks = [];
      this.previousCodebookName = codebookValue;
    }
  }

  loadEnums() {
    this.enumerationsService
      .getDataTypeEnums(this.SOURCE_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.enums = result;
        this.loadEnumItems();
      });
  }

  enumChanged(enumValue) {
    if (this.previousEnumName !== enumValue) {
      this.parentForm.controls.defaultValue.patchValue(null);
      this.enumItems = [];
      this.previousEnumName = enumValue;
    }
  }

  loadEnumItems() {
    const isListed = this.enums.find(e => e === this.parentForm.controls.typeDetail.value);
    if (this.enumItems.length === 0 && this.parentForm.controls.typeDetail.value && isListed) {
      this.enumerationsService
        .getDataTypeEnumItems(this.SOURCE_NAME, this.parentForm.controls.typeDetail.value)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.enumItems = result;
        });
    }
  }
}
