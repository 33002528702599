import { Component, Input } from '@angular/core';
import {
  AclService,
  AdminPromotionService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PagedPromotionsDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { PromotionEditComponent } from '@components/product-catalogue/promotion/edit/promotion-edit.component';
import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-promotion-list, [app-promotion-list]',
  templateUrl: './promotion-list.component.html',
  styleUrls: ['./promotion-list.component.scss'],
})
@EnableDynamicLoading({ customName: PromotionListComponent.PAGE_ID })
export class PromotionListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'PromotionListComponent';

  pageId(): string {
    return PromotionListComponent.PAGE_ID;
  }

  @Input()
  disableClearFilterButton: boolean = false;

  @Input()
  isImported: boolean = false;

  pagedPromotionsDto: PagedPromotionsDto;

  constructor(
    private adminPromotionService: AdminPromotionService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  editPromotion(promotionDto, inNewTab: boolean = false) {
    if (this.editable) {
      this.navigateSibling(PromotionEditComponent.PAGE_ID, { id: promotionDto.id }, inNewTab);
    }
  }

  designPromotion(promotionDto) {
    this.navigateSibling(PromotionEditComponent.PAGE_ID, { id: promotionDto.id, show: 'design' });
  }

  newPromotion() {
    this.navigateSibling(PromotionEditComponent.PAGE_ID, { id: '&' });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminPromotionService.getPromotionsByFilter(this.search).subscribe(result => {
        if (result) {
          this.pagedPromotionsDto = result;
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminPromotionService.deletePromotions(bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminPromotionService.deletePromotion(rowDataId);
  }
}
