import { Component, EventEmitter, Input, OnDestroy, Output, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppBlockerService, Search, StickyMessageService } from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import {
  TicketTypeParametersItemComponent,
} from '@components/tickets/ticket-type/edit/parameters/list/item/ticket-type-parameters-item.component';
import {
  ParameterDetailModalComponent,
} from '@components/tickets/ticket-type/edit/parameters/parameter-detail-modal/parameter-detail-modal.component';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-ticket-type-parameters-list',
  templateUrl: './ticket-type-parameters-list.component.html',
  styleUrls: ['./ticket-type-parameters-list.component.scss'],
})
export class TicketTypeParametersListComponent implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  count: number;

  @Input()
  paramDataTypes;

  @ViewChildren(TicketTypeParametersItemComponent)
  ticketTypeParametersItemComponent: Array<TicketTypeParametersItemComponent>;

  @Input()
  ticketTypeId;

  @Input()
  ticketTypeDto;

  @Input()
  parentForm: FormGroup;

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };

  @Output()
  readonly createParameter = new EventEmitter<any>();

  @Output()
  readonly updateParameter = new EventEmitter<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private stickyMessageService: StickyMessageService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  newTicketTypeParameter() {
    this.editTicketTypeParameter();
  }

  deleteTicketTypeParameter(event) {
    const parameters = this.parentForm.controls['parameters'] as FormArray;
    parameters.removeAt(parameters.value.findIndex(parameter => parameter.name === event.name));
  }

  editTicketTypeParameter(ticketTypeParameter?, index?) {
    const modalRef = this.dialog.open(ParameterDetailModalComponent, {
      panelClass: 'light-background',
    });
    const parameterDetailModalComponent = modalRef.componentInstance;
    parameterDetailModalComponent.parentForm = this.parentForm;
    parameterDetailModalComponent.ticketTypeDto = this.ticketTypeDto;
    parameterDetailModalComponent.parameterType = 'ticket';
    if (ticketTypeParameter) {
      parameterDetailModalComponent.parameter = ticketTypeParameter;
      parameterDetailModalComponent.isNew = false;
    }
    parameterDetailModalComponent.dialogRef = modalRef;
    parameterDetailModalComponent.selectHandler = (parameterForm, isNew, context) => {
      if (isNew) {
        this.createParameter.emit({ parameterDetail: parameterForm, context });
      } else {
        this.updateParameter.emit({ parameterDetail: parameterForm, index: index, context });
      }
    };
  }

  editCheckedTicketTypeParameter() {
    let selectedTicketTypeParameter;
    this.ticketTypeParametersItemComponent.forEach(ticketListItemComponents => {
      if (!selectedTicketTypeParameter && ticketListItemComponents.selected) {
        selectedTicketTypeParameter = ticketListItemComponents.parameter;
      }
    });
    this.editTicketTypeParameter(selectedTicketTypeParameter);
  }

  deleteCheckedTicketTypeParameter() {
    this.getSelectedTicketTypeParameter().forEach(selectedParameter => {
      this.deleteTicketTypeParameter(selectedParameter);
    });
  }

  getSelectedTicketTypeParameter(): Array<any> {
    const selectedTickets = [];
    this.ticketTypeParametersItemComponent.forEach(ticketListItemComponents => {
      if (ticketListItemComponents.selected) {
        selectedTickets.push(ticketListItemComponents.parameter);
      }
    });
    return selectedTickets;
  }
}
