import { Injectable } from '@angular/core';
import { BasePropertyAccessorLocalService, PropertyAccessorService } from '@btl/btl-fe-wc-common';
import { Constants } from '../constants';
import { Observable } from 'rxjs';

/**
 * Service for accessing properties from property accessor while caching them to localStorage
 */
@Injectable({
  providedIn: 'root',
})
export class PropertyAccessorLocalService extends BasePropertyAccessorLocalService {
  private static readonly MODULE_NAME: string = 'btl-fe-wc-admin';
  private static readonly STORAGE_NAME: string = 'propertyAccessor';

  private static readonly GLOBAL_BASE_URL: string = 'global.baseUrl';
  private static readonly CMS_PREVIEW_URL_PATH: string = 'cms.previewUrlPath';
  private static readonly CMS_MAX_UNDO_STEPS: string = 'gui.admin-cms.content-detail.maxUndoSteps';
  private static readonly PRODUCT_PREVIEW_URL_PATH: string = 'previewUrl';
  private static readonly GLOBAL_EXT_USER_EMAIL_REQUIRED: string = 'global.extUserEmailRequired';
  private static readonly GLOBAL_INT_USER_EMAIL_REQUIRED: string = 'global.intUserEmailRequired';
  private static readonly RESOUCE_ID_NORMALIZED_FORMAT: string = 'gui.global.resourceIdNormalizedFormat.type';

  constructor(protected propertyAccessorService: PropertyAccessorService) {
    super(
      PropertyAccessorLocalService.MODULE_NAME,
      PropertyAccessorLocalService.STORAGE_NAME,
      Constants.PROPERTIES_TTL,
      propertyAccessorService
    );
  }

  getGlobalBaseUrl(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.GLOBAL_BASE_URL);
  }

  getCmsPreviewUrlPath(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.CMS_PREVIEW_URL_PATH);
  }

  getCmsMaxUndoSteps(): Observable<number> {
    return this.getNumberProperty(PropertyAccessorLocalService.CMS_MAX_UNDO_STEPS);
  }

  getProductPreviewUrlPath(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.PRODUCT_PREVIEW_URL_PATH);
  }

  getGlobalExternalUserEmailRequired(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.GLOBAL_EXT_USER_EMAIL_REQUIRED);
  }

  getGlobalInternalUserEmailRequired(): Observable<string> {
    return this.getStringProperty(PropertyAccessorLocalService.GLOBAL_INT_USER_EMAIL_REQUIRED);
  }

  getResouceIdNormalizedFormat(type: string): Observable<string> {
    return this.getStringProperty(`${PropertyAccessorLocalService.RESOUCE_ID_NORMALIZED_FORMAT}.${type}`);
  }
}
