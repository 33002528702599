<div class="inner-component">
  <div class="inner_card dashboard">
    <div class="inner_card_header" [formGroup]="filterForm">
      <div class="header__title">{{ 'wc.admin.dashboard.topProducts.title' | translate }}</div>
      <app-input-select-form-field
        formControlName="date"
        labelOption="labelKey"
        valueOption="value"
        [noLabel]="true"
        [sortOptions]="false"
        [options]="dates"
        [translationPrefix]="'wc.common.date'"
        (selectionChangeEmit)="periodChange($event)" />
    </div>
    <div class="inner_card_content" style="flex-flow: row; justify-content: space-between">
      <mat-list class="dashboard_inner_list dashboard-top-products">
        <mat-list-item *ngFor="let productCount of topProductCounts; let i = index">
          <div class="dashboard_inner_list-item">
            <div class="dashboard_inner_list-item-iconBox" [ngStyle]="{ background: getColor(i) }"></div>
            <div class="dashboard_inner_list-item-name"><a [href]="'pc/products/'+productCount.product.id+'/attributes'"><span>{{ getProductName(productCount.product) }}</span></a></div>
          </div>
          <div class="dashboard_inner_list-item-description">
            x {{ productCount.count }}
          </div>
        </mat-list-item>
      </mat-list>
      <div style="width: 150px">
        <div class="pieContainer">
          <canvas #doughnutCanvas></canvas>
        </div>
      </div>
    </div>
  </div>
</div>
