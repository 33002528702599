import { Component, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminCodebookService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { CodebookDto, CodebookParamDto, CodebookParentDto, LocalizedTextPairDto } from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CodebookComponent } from '../codebook.component';
import { environment } from '@environments/environment';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { CodebookListComponent } from '@components/codebook/list/codebook-list.component';
import { Animations } from '@helpers/animations';

@Component({
  selector: 'app-codebook-edit',
  templateUrl: './codebook-edit.component.html',
  styleUrls: ['./codebook-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: CodebookEditComponent.PAGE_ID })
export class CodebookEditComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'CodebookEditComponent';

  pageId(): string {
    return CodebookEditComponent.PAGE_ID;
  }

  routerOutlet: any;

  editCodebookChanged = new EventEmitter<CodebookDto>();

  private codebookDto: CodebookDto = {
    parameters: [],
    visible: true,
    priority: 0,
  };

  getEditParameters(): CodebookParamDto[] {
    return this.codebookDto.parameters;
  }

  private codebookId: string;
  public isNew: boolean = true;

  types: string[] = [];
  parents: Array<CodebookParentDto> = [];
  codebooks: Array<CodebookDto> = [];
  parameters: Array<string> = [];
  locales = environment.localization.locales;

  codebookForm: FormGroup = this.formBuilder.group({
    id: [{ value: null, disabled: true }],
    codebookType: [{ value: null, disabled: true }, [Validators.required, Validators.maxLength(100)]],
    code: [{ value: null, disabled: true }, [Validators.required, Validators.maxLength(200)]],
    codeAlt: [null, [Validators.maxLength(200)]],
    priority: [0, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
    visible: [true],
    parent: this.formBuilder.group({
      id: [],
      code: [],
    }),
    recordVersion: [null],
    parameters: this.formBuilder.array([]),
    localizedTexts: this.formBuilder.group([]),
  });

  get idControl(): AbstractControl {
    return this.codebookForm.get('id');
  }

  get parametersFormArray(): FormArray {
    return <FormArray>this.codebookForm.get('parameters');
  }

  get parameterForms() {
    return <FormGroup[]>(<FormArray>this.codebookForm.get('parameters')).controls;
  }

  get localizedTextsFormGroup(): FormGroup {
    return <FormGroup>this.codebookForm.get('localizedTexts');
  }

  codebookTexts: Map<string, LocalizedTextPairDto> = new Map<string, LocalizedTextPairDto>();

  createParameterForm(codebookIdControl: AbstractControl): FormGroup {
    return this.formBuilder.group({
      name: [, Validators.required],
      value: [, Validators.required],
      recordVersion: [0],
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private adminCodebookService: AdminCodebookService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const codebookId = this.params.id;
      if (codebookId) {
        if (codebookId === '&') {
          this.setCodebook(this.codebookDto, true);
        } else {
          this.loadCodebook(codebookId);
        }
      }
    } else {
      this.codebookDto = undefined;
    }
  }

  loadCodebook(codebookId: string) {
    if (codebookId) {
      this.adminCodebookService
        .getCodebookById(codebookId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.setCodebook(result, false);
          this.codebookId = this.codebookDto.id;
        });
    }
  }

  loadCodebooksByType(codebookType: string) {
    if (codebookType) {
      const search: Search = {
        filtering: [{ column: 'codebookType', value: codebookType, compareType: 'EQUAL' }],
        sorting: [],
        paging: {
          page: 1,
          pageSize: -1,
        },
      };
      this.adminCodebookService
        .getCodebooksByFilter(search, null) //TODO after fix in BE ['codebook_type_id', 'code'])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.parents = [];
          this.codebooks.length = 0;
          result.data.forEach(codebook => {
            this.codebooks.push(codebook);
            this.parents.push({ id: codebook.id, code: codebook.code });
          });
        });
    }
  }

  loadParametersByCodebookType(codebookType: string) {
    if (codebookType) {
      this.adminCodebookService
        .getAggregatedCodebookTypeParameters(codebookType)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.parameters.length = 0;
          result.forEach(parameter => {
            this.parameters.push(parameter);
          });
        });
    }
  }

  setCodebook(codebook: CodebookDto, isNew: boolean) {
    this.codebookDto = codebook;
    if (!this.codebookDto.localizedTexts) {
      this.codebookDto.localizedTexts = [];
    }
    if (!this.codebookDto.parent) {
      this.codebookDto.parent = {
        id: null,
        code: null,
      };
    }
    this.codebookForm.patchValue(this.codebookDto);

    this.setCodebokTexts(this.codebookDto);

    this.parametersFormArray.clear();
    this.codebookDto.parameters.forEach(parameter => {
      const parameterForm = this.createParameterForm(this.idControl);
      parameterForm.patchValue(parameter);
      this.parametersFormArray.push(parameterForm);
    });

    if (codebook) {
      this.editCodebookChanged.emit(this.codebookDto);
    }

    this.isNew = isNew;
    if (this.isNew) {
      this.codebookForm.controls['codebookType'].enable();
      this.codebookForm.controls['code'].enable();
    }
  }

  setCodebokTexts(codebook: CodebookDto) {
    this.codebookTexts = new Map();

    if (codebook && codebook.localizedTexts) {
      codebook.localizedTexts.forEach(text => {
        this.codebookTexts.set(text.locale, text);
      });

      this.locales.forEach(locale => {
        if (this.codebookTexts.get(locale) === null || this.codebookTexts.get(locale) === undefined) {
          this.codebookTexts.set(locale, { locale: locale, text: null });
        }
      });
    } else {
      this.locales.forEach(locale => {
        this.codebookTexts.set(locale, { locale: locale, text: null });
      });
    }

    Object.keys(this.localizedTextsFormGroup.controls).forEach(field => {
      this.localizedTextsFormGroup.removeControl(field);
    });
    this.codebookTexts.forEach(p => {
      const formControl = new FormControl();
      formControl.patchValue(p.text);
      this.localizedTextsFormGroup.addControl(`label-${p.locale}`, formControl);
    });
  }

  saveCodebook(): boolean {
    console.log(this.codebookForm);
    FormUtils.validateAllFormFields(this.codebookForm);
    if (this.codebookForm.valid) {
      const codebook = this.codebookDto;
      if (codebook) {
        Object.keys(this.codebookForm.controls).forEach(field => {
          const control = this.codebookForm.get(field);
          codebook[field] = control.value;
        });

        codebook.localizedTexts = [];
        Object.keys(this.localizedTextsFormGroup.controls).forEach(field => {
          if (field.startsWith('label-')) {
            const locale = field.slice(6);
            const control = this.localizedTextsFormGroup.get(field);
            if (control.value) {
              const text = this.codebookTexts.get(locale);
              text.text = control.value;
              codebook.localizedTexts.push(text);
            }
          }
        });
      }
      return true;
    }
    return false;
  }

  save() {
    if (this.saveCodebook()) {
      this.appBlockerService.block();
      this.codebookDto.created = null;
      this.codebookDto.createdBy = null;
      this.codebookDto.modified = null;
      this.codebookDto.modifiedBy = null;
      if (!this.codebookDto.parent.id) {
        this.codebookDto.parent = null;
      }
      if (!(this.codebookId === null || this.codebookId === undefined)) {
        this.adminCodebookService
          .updateCodebook(this.codebookId, this.codebookDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getCodebookHandler);
      } else {
        this.adminCodebookService
          .createCodebook(this.codebookDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getCodebookHandler);
      }
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditCodebook(): CodebookDto {
    return this.codebookDto;
  }

  public reset() {
    if (this.codebookDto && this.codebookDto.id) {
      this.appBlockerService.block();
      this.adminCodebookService
        .getCodebookById(this.codebookDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(this.getCodebookHandler);
    } else {
      this.codebookForm.reset(this.codebookForm);
      this.setCodebook(this.codebookDto, this.isNew);
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.resource.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminCodebookService
        .deleteCodebook(this.codebookDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(CodebookListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  public duplicate() {
    const newCodebook = CodebookComponent.duplicateCodebook(this.codebookDto);
    this.setCodebook(newCodebook, true);
    this.codebookId = null;
    this.navigateSelf({ id: '&' });
  }

  public getCodebookHandler = (codebookDto: CodebookDto): void => {
    let saving = false;
    if (this.codebookDto) {
      saving = true;
    }
    if (codebookDto && this.codebookDto) {
      if (codebookDto.id === this.codebookDto.id) {
        this.setCodebook(codebookDto, this.isNew);
        this.navigateSelf({ id: codebookDto.id });
      } else {
        this.setCodebook(codebookDto, this.isNew);
        this.codebookId = codebookDto.id;
        this.navigateSelf({ id: codebookDto.id });
      }
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  ngOnInit() {
    this.adminCodebookService.getCodebookTypes(ServiceUtils.getUnlimitedSearch(), null).subscribe(result => {
      this.types = result.data;
    });
    this.codebookForm.get('codebookType').valueChanges.subscribe(value => {
      this.loadCodebooksByType(value);
      this.loadParametersByCodebookType(value);
    });
  }

  cancel() {
    this.navigateSibling(CodebookListComponent.PAGE_ID);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  removeParameter(i) {
    this.parametersFormArray.removeAt(i);
  }

  addParameter() {
    const parameter: CodebookParamDto = {};
    const parameterForm = this.createParameterForm(this.idControl);
    parameterForm.patchValue(parameter);
    this.parametersFormArray.push(parameterForm);
  }
}
