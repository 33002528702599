<td class="content-desktop">{{ contentVersionDto.code }}</td>
<td>{{ contentVersionDto.version }}</td>
<td>{{ 'wc.admin.cms.content.version.list.state.' + contentVersionDto.state | translate }}</td>
<td>{{ contentVersionDto.validFor.startDateTime | date: 'dd.MM.yyyy HH:mm' }}</td>
<td class="content-desktop">{{ contentVersionDto.validFor.endDateTime | date: 'dd.MM.yyyy HH:mm' }}</td>
<td class="content-desktop">{{ contentVersionDto.created | date: 'dd.MM.yyyy HH:mm' }}</td>
<td class="content-desktop">{{ contentVersionDto.modified | date: 'dd.MM.yyyy HH:mm' }}</td>
<td class="content-desktop">{{ contentVersionDto.modifiedBy }}</td>
<td class="table__action">
  <button
    aria-expanded="false"
    aria-haspopup="true"
    class="btn -empty table__action-button"
    data-toggle="dropdown"
    id="{{ parentId }} + dropdownUserInfo"
    type="button">
    <i class="fas fa-ellipsis-v"></i>
  </button>
  <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right">
    <a
      (click)="show()"
      href="javascript:void(0);"
      class="dropdown-item"
      title="{{ 'wc.admin.cms.content.version.list.show' | translate }}"
      >{{ 'wc.admin.cms.content.version.list.show' | translate }}</a
    >
    <a
      (click)="approve()"
      *ngIf="contentVersionDto.state === 'ARCHIVED' || contentVersionDto.state === 'DRAFT'"
      title="{{ 'wc.admin.cms.content.version.list.approve' | translate }}"
      href="javascript:void(0);"
      class="dropdown-item"
      >{{ 'wc.admin.cms.content.version.list.approve' | translate }}</a
    >
    <a
      (click)="archive()"
      *ngIf="contentVersionDto.state !== 'ARCHIVED' && contentVersionDto.state !== 'DRAFT'"
      title="{{ 'wc.common.archive' | translate }}"
      href="javascript:void(0);"
      class="dropdown-item"
      >{{ 'wc.common.archive' | translate }}</a
    >
    <a
      (click)="duplicate()"
      href="javascript:void(0);"
      title="{{ 'wc.common.duplicate' | translate }}"
      class="dropdown-item"
      >{{ 'wc.common.duplicate' | translate }}</a
    >
    <a
      *ngIf="contentVersionDto.state === 'DRAFT'"
      (click)="delete()"
      href="javascript:void(0);"
      title="{{ 'wc.common.delete' | translate }}"
      class="dropdown-item"
      >{{ 'wc.common.delete' | translate }}</a
    >
  </div>
</td>
