<ng-container *ngIf="product">
  <div class="card__wrapper">
    <!-- card -->
    <div class="card -full">
      <div class="card__inner">
        <app-cart-events-listing
          [cacheFilter]="false"
          [constantFilter]="getProductSourceFilter()"
          [disableListingActions]="true"
          [disableActions]="true"
          [editable]="false"
          [hideFilter]="true"
          additionalHeaderLtKey="wc.admin.relationships.sourceType">
        </app-cart-events-listing>
      </div>
    </div>
    <div class="card -full">
      <div class="card__inner">
        <app-cart-events-listing
          [cacheFilter]="false"
          [constantFilter]="getProductTargetFilter()"
          [disableListingActions]="true"
          [disableActions]="true"
          [editable]="false"
          [hideFilter]="true"
          additionalHeaderLtKey="wc.admin.relationships.targetType">
        </app-cart-events-listing>
      </div>
    </div>
  </div>
</ng-container>
