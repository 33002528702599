<h2 mat-dialog-title>
  {{
  relationship
    ? ('wc.admin.productPrices.priceAdd' | translate)
    : ('wc.admin.productPrices.priceEdit' | translate)
  }}
  <div mat-dialog-close [attr.aria-label]="'wc.common.close' | translate" class="mat-dialog-close">
    <span aria-hidden="true">&times;</span>
  </div>
</h2>
<mat-dialog-content class="modal-body">
  <div class=".mat-drawer-container">
    <div class="mat-sidenav-content">
      <div class="main_content">
        <app-relationship-edit
          [navigationMode]="false"
          [inputRelationship]="relationship"
          [sourceTargetMode]="sourceTargetMode"
          [resetEventEmitter]="resetEventEmitter"
          [product]="product">
        </app-relationship-edit>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a
    id="002_product_price_edit"
    color="primary"
    mat-raised-button
    (click)="save()"
    [routerLink]=""
    class="btn -primary -xs icon fill no-stroke"
    [disabled]="!enableEditProduct"
  >
    <mat-icon svgIcon="widgetConfirm"></mat-icon>
    {{ 'wc.common.confirm' | translate }}</a
  >
  <a
    id="003_product_price_edit"
    color="primary"
    mat-raised-button
    (click)="reset()"
    [routerLink]=""
    class="btn -primary -xs icon fill no-stroke"
    [disabled]="!enableEditProduct"
  >
    <mat-icon svgIcon="reset"></mat-icon>
    {{ 'wc.common.reset' | translate }}</a
  >
  <a
    id="0012_product_price_edit"
    color="primary"
    mat-raised-button
    *ngIf="relationship?.recordVersion"
    (click)="delete()"
    [routerLink]=""
    class="btn -primary -xs icon fill no-stroke">
    <mat-icon svgIcon="delete"></mat-icon>
    {{ 'wc.common.delete' | translate }}
  </a>
  <a
    id="004_product_price_edit"
    color="primary"
    mat-raised-button
    (click)="cancel()"
    [routerLink]=""
    class="btn -primary -xs"
  >{{ 'wc.common.cancel.button' | translate }}</a
  >
</mat-dialog-actions>
