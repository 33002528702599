<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" [ngClass]="{ 'border-unset': isCollapsed }">
        <div class="card-title-container">
          {{ 'wc.admin.products.attributes.coreAttributes' | translate }}
          <div
            class="iconCollapse"
            [ngClass]="isCollapsed ? '-collapsed' : '-opened'"
            (click)="isCollapsed = !isCollapsed">
            <mat-icon color="primary" svgIcon="{{ isCollapsed ? 'show' : 'hide' }}"></mat-icon>
          </div>
        </div>
      </div>
      <div class="card__body" [hidden]="isCollapsed">
        <form *ngIf="productEditService.getEditProduct()" [formGroup]="productForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="productForm"
                  id="001_product_attributes"
                  [controlName]="'productCode'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.products.attributes'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'category'"
                  [options]="categoriesHints"
                  id="002_product_attributes"
                  [disableOptionsTranslate]="true"
                  (selectionChangeEmit)="categoryChanged($event)"
                  [translationPrefix]="'wc.admin.products.attributes'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="productForm"
                  [controlName]="'seoUrl'"
                  [inputType]="'text'"
                  id="003_product_attributes"
                  [translationPrefix]="'wc.admin.products.attributes'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="productForm"
                  [controlName]="'description'"
                  id="004_product_attributes"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.products.attributes'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <div class="two-inputs">
                  <app-input-date-form-field
                    [parentGroup]="productForm.controls['masterAvailableFor']"
                    [controlName]="'startDateTime'"
                    [label]="'masterAvailableFrom'"
                    id="005_product_attributes"
                    [translationPrefix]="'wc.admin.products.attributes'">
                  </app-input-date-form-field>
                  <mat-icon class="date-icon-arrow">arrow_forward</mat-icon>
                  <app-input-date-form-field
                    [parentGroup]="productForm.controls['masterAvailableFor']"
                    [controlName]="'endDateTime'"
                    id="006_product_attributes"
                    [label]="'masterAvailableTo'"
                    [translationPrefix]="'wc.admin.products.attributes'">
                  </app-input-date-form-field>
                </div>
              </div>
              <div class="form__row">
                <div class="two-inputs">
                  <app-input-date-form-field
                    [parentGroup]="productForm.controls['availableFor']"
                    [controlName]="'startDateTime'"
                    [label]="'availableFrom'"
                    id="007_product_attributes"
                    [translationPrefix]="'wc.admin.products.attributes'">
                  </app-input-date-form-field>
                  <mat-icon class="date-icon-arrow">arrow_forward</mat-icon>
                  <app-input-date-form-field
                    [parentGroup]="productForm.controls['availableFor']"
                    [controlName]="'endDateTime'"
                    [label]="'availableTo'"
                    id="008_product_attributes"
                    [translationPrefix]="'wc.admin.products.attributes'">
                  </app-input-date-form-field>
                </div>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="productForm"
                  [controlName]="'priority'"
                  [inputType]="'number'"
                  id="009_product_attributes"
                  [translationPrefix]="'wc.admin.products.attributes'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'versioningType'"
                  [options]="versioningTypes"
                  (change)="categoryChanged($event)"
                  [disableOptionsTranslate]="true"
                  id="010_product_attributes"
                  [label]="'versioningType'"
                  [translationPrefix]="'wc.admin.products.attributes'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'syncMode'"
                  [label]="'synchronizationMode'"
                  [options]="syncModes"
                  id="011_product_attributes"
                  [disableOptionsTranslate]="true"
                  [translationPrefix]="'wc.admin.products.attributes'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="productForm"
                  [controlName]="'professionalRating'"
                  [inputType]="'number'"
                  id="012_product_attributes"
                  [translationPrefix]="'wc.admin.products.attributes'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="productForm"
                  [controlName]="'popularity'"
                  id="013_product_attributes"
                  [inputType]="'number'"
                  [translationPrefix]="'wc.admin.products.attributes'">
                </app-custom-input>
              </div>
            </div>
            <div class="form__group -flex" style="padding-top: 20px">
              <div class="form__row">
                <app-custom-input
                  [id]="'014_product_attributes'"
                  [inputType]="'checkbox'"
                  [parentGroup]="productForm"
                  [controlName]="'main'"
                  [label]="'wc.admin.products.attributes.mainProduct'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <mat-checkbox id="015_product_attributes" formControlName="bundle" (change)="bundleChanged($event)">{{
                  'wc.admin.products.attributes.bundle' | translate
                }}</mat-checkbox>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'016_product_attributes'"
                  [inputType]="'checkbox'"
                  [parentGroup]="productForm"
                  [controlName]="'visible'"
                  [label]="'wc.admin.products.attributes.visible'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'017_product_attributes'"
                  [inputType]="'checkbox'"
                  [parentGroup]="productForm"
                  [controlName]="'orderable'"
                  [label]="'wc.admin.products.attributes.orderable'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'018_product_attributes'"
                  [inputType]="'checkbox'"
                  [parentGroup]="productForm"
                  [controlName]="'planningEnabled'"
                  [label]="'wc.admin.products.attributes.planningEnabled'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'019_product_attributes'"
                  [inputType]="'checkbox'"
                  [parentGroup]="productForm"
                  [controlName]="'multipleOrderEnabled'"
                  [label]="'wc.admin.products.attributes.multipleOrderEnabled'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'020_product_attributes'"
                  [inputType]="'checkbox'"
                  [parentGroup]="productForm"
                  [controlName]="'popularityCalculationEnabled'"
                  [label]="'wc.admin.products.attributes.automaticCalculationOfPopularity'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" [ngClass]="{ 'border-unset': isAttributeCollapsed }">
        <div class="card-title-container">
          {{ 'wc.admin.products.attributes.parameters' | translate }}
          <div
            class="iconCollapse"
            [ngClass]="isAttributeCollapsed ? '-collapsed' : '-opened'"
            (click)="isAttributeCollapsed = !isAttributeCollapsed">
            <mat-icon color="primary" svgIcon="{{ isAttributeCollapsed ? 'show' : 'hide' }}"></mat-icon>
          </div>
        </div>
      </div>
      <div class="card__body" [hidden]="isAttributeCollapsed">
        <form [formGroup]="productParametersForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div #tooltipFollowCursorContent class="tooltip-follow-cursor_content"></div>
              <div *ngFor="let productParameterConfig of productParametersDto; let i = index" class="form__row">
                <ng-container [ngSwitch]="productParameterConfig.type">
                  <ng-container *ngSwitchCase="'BOOLEAN'">
                    <mat-checkbox
                      [id]="productParameterConfig.name"
                      [name]="productParameterConfig.name"
                      [formControlName]="productParameterConfig.name"
                      (mouseenter)="setProductParameterTooltip(productParameterConfig.name)"
                      (mousemove)="showProductParameterTooltip($event)"
                      (mouseleave)="hideProductParameterTooltip()"
                      (focusin)="hideProductParameterTooltip()">
                      {{ getParameterLabel(productParameterConfig) }}
                    </mat-checkbox>
                  </ng-container>
                  <app-input-date-form-field
                    *ngSwitchCase="'DATE'"
                    [id]="productParameterConfig.name"
                    [parentGroup]="productParametersForm"
                    [controlName]="productParameterConfig.name"
                    (mouseenter)="setProductParameterTooltip(productParameterConfig.name)"
                    [label]="getParameterLabel(productParameterConfig)"
                    (mousemove)="showProductParameterTooltip($event)"
                    (mouseleave)="hideProductParameterTooltip()"
                    (focusin)="hideProductParameterTooltip()">
                  </app-input-date-form-field>
                  <app-input-select-form-field
                    *ngSwitchCase="'CODEBOOK'"
                    [id]="productParameterConfig.name"
                    [formControlName]="productParameterConfig.name"
                    [codebookType]="productParameterConfig.typeDetail"
                    (mouseenter)="setProductParameterTooltip(productParameterConfig.name)"
                    [label]="getParameterLabel(productParameterConfig)"
                    (mousemove)="showProductParameterTooltip($event)"
                    (mouseleave)="hideProductParameterTooltip()"
                    (focusin)="hideProductParameterTooltip()"
                    [inputType]="'codebook'">
                  </app-input-select-form-field>
                  <app-input-select-form-field
                    *ngSwitchCase="'ENUM'"
                    [id]="productParameterConfig.name"
                    [formControlName]="productParameterConfig.name"
                    [sourceName]="SOURCE_NAME"
                    [enumName]="productParameterConfig.typeDetail"
                    (mouseenter)="setProductParameterTooltip(productParameterConfig.name)"
                    [label]="getParameterLabel(productParameterConfig)"
                    (mousemove)="showProductParameterTooltip($event)"
                    (mouseleave)="hideProductParameterTooltip()"
                    (focusin)="hideProductParameterTooltip()"
                    [showEmptyOption]="true"
                    [inputType]="'enum'">
                  </app-input-select-form-field>
                  <app-custom-input
                    *ngSwitchDefault
                    [id]="productParameterConfig.name"
                    [parentGroup]="productParametersForm"
                    [controlName]="productParameterConfig.name"
                    (mouseenter)="setProductParameterTooltip(productParameterConfig.name)"
                    [label]="getParameterLabel(productParameterConfig)"
                    (mousemove)="showProductParameterTooltip($event)"
                    (mouseleave)="hideProductParameterTooltip()"
                    (focusin)="hideProductParameterTooltip()"
                    [inputType]="'text'">
                  </app-custom-input>
                </ng-container>
                <div
                  *ngIf="
                    !productParametersForm.controls[productParameterConfig.name].valid &&
                    productParametersForm.controls[productParameterConfig.name].touched
                  "
                  class="alert alert-danger">
                  <div *ngIf="productParametersForm.controls[productParameterConfig.name].errors.pattern">
                    {{
                      'wc.admin.products.attributes.productParameter.regex.error.pattern'
                        | translate: { regex: productParameterConfig.validationRegex }
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- card -->
  <div *ngIf="localizedProductParametersDto.length > 0" class="card -full">
    <div class="card__inner">
      <div class="card__header" [ngClass]="{ 'border-unset': isLocalizedParametersCollapsed }">
        <div class="card-title-container">
          {{ 'wc.admin.products.attributes.localizedParameters' | translate }}
          <div class="card-title-dropdown-box">
            <div class="card-title-dropdown">
              <mat-select [(value)]="attributesLanguage" id="021_product_attributes">
                <mat-option id="{{ locale }}" *ngFor="let locale of locales" [value]="locale.toLowerCase()">
                  {{ locale.toUpperCase() }}
                </mat-option>
              </mat-select>
            </div>
            <div
              class="iconCollapse"
              [ngClass]="isLocalizedParametersCollapsed ? '-collapsed' : '-opened'"
              (click)="isLocalizedParametersCollapsed = !isLocalizedParametersCollapsed">
              <mat-icon color="primary" svgIcon="{{ isLocalizedParametersCollapsed ? 'show' : 'hide' }}"></mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="card__body" [hidden]="isLocalizedParametersCollapsed" *ngIf="attributesLanguage">
        <form [formGroup]="localizedProductParametersForm" class="form">
          <div style="display: flex; flex-wrap: wrap; column-gap: 20px; row-gap: 30px">
            <div *ngFor="let productParameterConfig of localizedProductParametersDto; let i = index" style="flex: 45%">
              <!--TODO: ADD type to localizedProductParametersDto to handle if chips can be used-->
              <ng-container *ngIf="productParameterConfig.name === 'freeGiftLista'; else defaultInput">
                <mat-form-field
                  class="form__field"
                  appearance='outline' floatLabel='always'
                  formGroupName="{{ productParameterConfig.name }}">
                  <mat-label>{{ productParameterConfig.localizedNames[attributesLanguage] }}</mat-label>
                  <input
                    class="form__input"
                    [id]="productParameterConfig.name"
                    [matChipInputFor]="chipList"
                    (matChipInputTokenEnd)="add($event)"
                    matInput
                    [formControl]="attributesLanguage" />
                </mat-form-field>
                <div class="chips">
                  <mat-chip-grid #chipList>
                    <mat-chip-row color="primary" *ngFor="let item of list" (removed)="remove(item)">
                      {{ item.name }}
                      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip-row>
                  </mat-chip-grid>
                </div>
              </ng-container>
              <ng-template #defaultInput>
                <ng-container *ngIf="locales && attributesLanguage">
                  <ng-container *ngFor="let locale of locales">
                    <mat-form-field
                      class="form__field"
                      appearance='outline' floatLabel='always'
                      formGroupName="{{ productParameterConfig.name }}"
                      (focusin)="hideProductParameterTooltip()"
                      (mouseenter)="setProductParameterTooltip(productParameterConfig.name)"
                      (mouseleave)="hideProductParameterTooltip()"
                      (mousemove)="showProductParameterTooltip($event)"
                      *ngIf="locale.toLowerCase() === attributesLanguage">
                      <mat-label>{{ getParameterLabel(productParameterConfig) }}</mat-label>
                      <input
                        class="form__input"
                        [id]="productParameterConfig.name"
                        matInput
                        type="text"
                        [formControlName]="locale" />
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </ng-template>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
