import { Component } from '@angular/core';
import {
  AclService,
  AdminProductGroupService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { GroupDto, PagedGroupsDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import {
  ProductGroupEditComponent,
} from '@components/product-catalogue/product-group/product-group-edit/product-group-edit.component';

import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-group-listing',
  templateUrl: './product-group-listing.component.html',
  styleUrls: ['./product-group-listing.component.scss'],
})
@EnableDynamicLoading({ customName: ProductGroupListingComponent.PAGE_ID })
export class ProductGroupListingComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'ProductGroupListingComponent';

  pageId(): string {
    return ProductGroupListingComponent.PAGE_ID;
  }

  pagedGroupsDto: PagedGroupsDto;

  columnName: ['code', 'name', 'priority', 'type', 'created', 'modified', 'modifiedBy'];

  constructor(
    private adminProductGroupService: AdminProductGroupService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected confirmationDialogService: ConfirmationDialogService,
    protected stickyMessageService: StickyMessageService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  editProductGroup(groupDto, inNewTab: boolean = false) {
    this.navigateSibling(ProductGroupEditComponent.PAGE_ID, { id: groupDto.id }, inNewTab);
  }

  newProductGroup() {
    this.editProductGroup({
      id: 'newProductGroup',
    });
  }

  duplicateProductGroup(groupDto: GroupDto, refresh?: boolean) {
    groupDto.id = null;
    groupDto.recordVersion = null;
    groupDto.code = `${groupDto.code}copy`;

    this.appBlockerService.block();
    this.adminProductGroupService
      .createGroup(groupDto)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (refresh !== undefined) {
          if (refresh) {
            this.loadData();
            this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          }
        }
      });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminProductGroupService
        .getGroupsByFilter(this.search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedGroupsDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminProductGroupService.deleteGroups(bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminProductGroupService.deleteGroup(rowDataId);
  }
}
