import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import {
  MatTable,
  MatTableDataSource
,
} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CurrentLocaleService, PricePipe, Search } from '@btl/btl-fe-wc-common';
import { DatePipe } from '@angular/common';
import { Observable, of, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Animations } from '@helpers/animations';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-table-listing-form',
  templateUrl: './table-listing-form.component.html',
  styleUrls: ['./table-listing-form.component.scss'],
  animations: [Animations.tableDetailExpandArrow],
  providers: [PricePipe],
})
export class TableListingFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @ViewChildren('innerTables') innerTables: QueryList<MatTable<any>>;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;

  isLoading: boolean = true;

  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];
  dateTimeColumns = [
    'created',
    'modified',
    'reviewedOn',
    'startDateTime',
    'endDateTime',
    'validFor.startDateTime',
    'validFor.endDateTime',
    'finished',
    'responseDateTime',
    'releaseDate',
  ];
  commonColumnsTranslate = [
    'created',
    'createdBy',
    'modified',
    'modifiedBy',
    'reviewedOn',
    'startDateTime',
    'endDateTime',
    'validFor.startDateTime',
    'validFor.endDateTime',
    'accountEntity.endDateTime',
    'accountEntity.startDateTime',
    'cartEvents.startDateTime',
    'cartEvents.endDateTime',
  ];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<PeriodicElement>(true, []);

  @Input()
  translationPrefix: string;

  @Input()
  nestedTable: boolean = false;

  @Input()
  disableActions: boolean = false;

  @Input()
  disableExpand: boolean = false;

  @Input()
  disableEditButton: boolean = false;

  @Input()
  disableEditInNewTabButton: boolean = false;

  @Input()
  disableDeleteButton: boolean = false;

  @Input()
  singleSelectMode: boolean;

  @Input()
  multiSelectMode = true;

  @Input()
  fillIdBy: string;

  @Input()
  columnName: Array<string>;

  @Input()
  parentColumnName: Array<string>;

  @Input()
  highLightColumn: string;

  @Input()
  parentParameters: any;

  @Input()
  childColumnName: Array<string>;

  /**
   *
   * Columns that will eventually shrink and show complete column value on tooltip, column size is optional parametr that specifies table cell width attribute including measure units
   * Note that total column width consists not only specified width but also cell paddings, cell header and those are not included in the defined width
   * Input example: ['state;100px', 'created;7vw', 'createdBy']
   */
  @Input()
  columnToTruncate: Array<string>;

  @Input()
  dontShowOnMobile: Array<string>;

  @Input()
  additionalDateColumns: Array<string>;

  @Input()
  pagedDto: any;

  @Input()
  customData: any;

  @Input()
  module: string;

  @Input()
  selectedProducts = [];

  @Input()
  selectMode: boolean = true;

  @Input()
  toggleArrow: boolean = false;

  @Input()
  enablePaginator = true;

  @Input()
  expandType: string = 'table';

  @Input()
  backgroundColor: string;

  @Input()
  disabledParentClickEvent = false;

  @Input()
  editable: boolean;

  @Input()
  cacheFilter: Search;

  // Account entity;
  @Input()
  grantedMode: boolean;

  @Input()
  showCustomButtonCallback: (row: any) => boolean;

  @Input()
  getRowClassCallback: (row: any) => string;

  @Input()
  multiExpandable = false;

  @Input()
  getChildDataCallback: (row: any) => Observable<any> = (row: any): any => {
    return of({
      data: [],
    });
  };

  @Input()
  skeletonLoadingCount: number;

  skeletonLoadingCountLoop: Array<number>;

  @Input()
  columnToolTipCallBack: Map<string, any> = new Map<string, any>();

  @Input()
  disabledSortColumns: Array<string>;

  @Input()
  suffix: string = "";

  @Output()
  readonly doubleClickEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly editButtonEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly editInNewTabButtonEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly deleteButtonEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly duplicateButtonEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly archiveButtonEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly activeButtonEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly rejectButtonEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly approveButtonEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly customButtonEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly getChildDataEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly getBackGroundColorEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly pageSizeEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly sortChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  selected: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  expandedElements: Map<any, any> = new Map<any, any>();

  expanded = true;

  currentLocale: string;

  truncatedColumns: Array<{name: string, width: string}>;

  ngAfterViewInit() {
    if (this.selectMode) {
      this.columnsToDisplay.unshift('select');
    }
    if (!this.disableActions && !this.toggleArrow) {
      this.columnsToDisplay.push('actions');
    }
    if (this.toggleArrow) {
      this.columnsToDisplay.unshift('toggleArrow');
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.innerSort.changes.pipe(first()).subscribe(sort => {
      if (this.sort && this.cacheFilter?.sorting?.length) {
        const sorting = this.cacheFilter.sorting[0];
        this.dataSource.sort = this.sort;
        this.sort.active = sorting.column;
        this.sort.direction = sorting.sortOrder;
      }
    });
  }

  constructor(
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private pricePipe: PricePipe,
    private changeDetectorRefs: ChangeDetectorRef,
    private currentLocaleService: CurrentLocaleService
  ) {
    this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    });
  }

  ngOnInit(): void {
    this.additionalDateColumns?.forEach(column => {
      this.dateTimeColumns.push(column);
    });
    this.skeletonLoadingCountLoop = Array(this.skeletonLoadingCount)
      .fill(1)
      .map((x, i) => i);
    if (this.columnName) {
      this.displayedColumns = this.columnName.filter(col => col !== null);
      this.columnsToDisplay = this.displayedColumns.slice();
    } else {
      this.displayedColumns = this.parentColumnName;
      this.columnsToDisplay = this.displayedColumns.slice();
    }
    this.dataSource.sort = this.sort;
    if (this.columnToTruncate?.length > 0) {
      this.truncatedColumns = [];
      this.columnToTruncate.forEach(tableColumn => {
        if (tableColumn) {
          const splitted = tableColumn.split(';');
          this.truncatedColumns.push({ name: splitted[0], width: splitted[1] ? splitted[1] : null });
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pagedDto']) {
      this.selection.deselect(...this.dataSource.data);
      if (!changes['pagedDto'].currentValue) {
        this.isLoading = true;
        this.dataSource.data = [];
      }
      if (changes['pagedDto'].currentValue) {
        if (this.module === 'cms') {
          const contentMasterData = [];
          this.pagedDto.data.forEach(data => {
            contentMasterData.push(data.contentMaster);
          });
          this.dataSource = new MatTableDataSource(contentMasterData);
        } else if (this.module === 'category') {
          this.dataSource = new MatTableDataSource(this.pagedDto);
        } else {
          if (this.module === 'groupTypes') {
            this.pagedDto.data.sort((a, b) => a.name.localeCompare(b.name));
          }
          this.dataSource = new MatTableDataSource(this.pagedDto.data);
        }
        if (this.selectedProducts.length > 0) {
          this.dataSource.data.forEach(row => {
            this.selectedProducts.forEach(product => {
              if (product.id === row.id) {
                this.selection.select(row);
              }
            });
          });
        }
        this.isLoading = false;
      }
    }
    if (this.customData) {
      this.dataSource.data = this.customData;
      this.isLoading = false;
    }
    if (this.columnName) {
      this.displayedColumns = this.columnName.filter(col => col !== null);
      this.columnsToDisplay = this.displayedColumns.slice();
      if (this.selectMode) {
        this.columnsToDisplay.unshift('select');
      }
      if (!this.disableActions) {
        this.columnsToDisplay.push('actions');
      }
    }
    this.dataSource.sort = this.sort;
  }

  matSortChange() {
    this.dataSource.sort = this.sort;
    this.sortChangeEvent.emit(this.sort);
  }

  getDataSource(data) {
    return new MatTableDataSource(data.data);
  }

  matSortChangeChild(data) {
    if (this.innerSort['_results'][0]) {
      data.sort = this.innerSort['_results'][0];
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  onPageEvent(event) {
    this.pageSizeEvent.emit(event);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.deselect(...this.dataSource.data);
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  click(row) {
    if (
      row?.versioningType === 'MULTI_ACTIVE' ||
      row?.versioningType === 'SINGLE_ACTIVE' ||
      this.module === 'cms' ||
      this.module === 'promotionSockets'
    ) {
      this.nestedTable = true;
    } else {
      this.nestedTable = false;
    }
    this.nestedTable ? this.expand(row) : this.selection.toggle(row);
  }

  getLength() {
    if (this.module === 'groupTypes') {
      return this.pagedDto.data.length;
    }
    return this.pagedDto.totalItems ? this.pagedDto.totalItems : this.pagedDto.length;
  }

  getPageIndex(): number {
    return this.pagedDto?.currentPage ? this.pagedDto?.currentPage - 1 : 0;
  }

  doubleClick(row) {
    this.doubleClickEvent.emit(row);
  }

  edit(row) {
    this.editButtonEvent.emit(row);
  }

  editInNewTab(row) {
    this.editInNewTabButtonEvent.emit(row);
  }

  delete(row) {
    this.deleteButtonEvent.emit(row);
  }

  customEvent(row) {
    this.customButtonEvent.emit(row);
  }

  duplicate(row) {
    this.duplicateButtonEvent.emit(row);
  }

  active(row) {
    this.activeButtonEvent.emit(row);
  }

  approve(row) {
    this.approveButtonEvent.emit(row);
  }

  reject(row) {
    this.rejectButtonEvent.emit(row);
  }

  archive(row) {
    this.archiveButtonEvent.emit(row);
  }

  isRowTruncate(column) {
    return this.truncatedColumns?.length > 0 && this.truncatedColumns.findIndex(tColumn => tColumn.name === column) !== -1;
  }

  getTruncatedColumnWidth(column) {
    if (this.truncatedColumns?.length > 0) {
      const tColumn = this.truncatedColumns.find(tColumn => tColumn.name === column);
      return tColumn && tColumn.width ? tColumn.width : null;
    }
  }

  isRowDesktop(column) {
    if (this.dontShowOnMobile?.length > 0) {
      return this.dontShowOnMobile.includes(column);
    }
  }

  isDisabledSort(column) {
    if (this.disabledSortColumns?.length > 0) {
      return this.disabledSortColumns.includes(column);
    }
  }

  getElementTooTip(element, col) {
    const callBack = this.columnToolTipCallBack.get(col);
    if (callBack) {
      return callBack(element);
    } else {
      return this.getElement(element, col);
    }
  }

  getElement(element, col: string) {
    if (col.includes('.')) {
      const splitCol = col.split('.');
      if (this.dateTimeColumns.includes(col)) {
        return this.getDateValue(element[splitCol[0]][splitCol[1]]);
      }

      if (this.module === 'cms' || this.module === 'notification') {
        if (Array.isArray(element[splitCol[0]]) && element[splitCol[0]].length) {
          return element[splitCol[0]][0][splitCol[1]];
        } else {
          return element[splitCol[0]][splitCol[1]];
        }
      }
      for (const k of splitCol) {
        if (k === 'length') {
          return element[splitCol[0]].length as Array<any>;
        }
      }
      if (Array.isArray(element[splitCol[0]])) {
        for (let i = 0; i < element[splitCol[0]].length; i++) {
          if (this.currentLocale && element[splitCol[0]][i]['locale'] === this.currentLocale) {
            return element[splitCol[0]][i][splitCol[1]];
          }
          if (element[splitCol[0]][i]['name'] === splitCol[1]) {
            return element[splitCol[0]][i]['value'];
          }
        }
      }

      return element[splitCol[0]] !== null ? element[splitCol[0]][splitCol[1]] : '';
    }
    if (this.dateTimeColumns.includes(col) && element[col] != null) {
      return this.getDateValue(element[col]);
    }

    if (col === 'price') {
      return this.pricePipe.transform(element[col], null, '1.0-2');
    }

    if ((col === 'state' && this.module !== 'cms' && this.module !== 'notification') || col === 'stateType') {
      return element[col] === 'ACTIVE';
    }
    return element[col];
  }

  getDateValue(value) {
    if (value) {
      const date = new Date(value);
      return this.datePipe.transform(date, 'HH:mm dd.MM.yyyy');
    } else {
      return value;
    }
  }

  findByKey(obj, key) {
    let result;
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (obj[property] instanceof Array) {
          if (obj[property].length > 0) {
            for (let i = 0; i < obj[property].length; i++) {
              result = this.findByKey(obj[property][i], key);
              if (result) {
                break;
              }
            }
          }
        }
        if (typeof obj[property] === 'object') {
          result = this.findByKey(obj[property], key);

          if (typeof result !== 'undefined') {
            return result;
          }
        } else if (property === key) {
          return obj[key];
        }
      }
    }
  }

  isDefaultInput(element, col) {
    return !(
      typeof element[col] === 'boolean' ||
      ((col === 'state' || col === 'validateOnly') && this.module !== 'cms' && this.module !== 'notification') ||
      col === 'stateType'
    );
  }

  showAsText(element, col) {
    return !(col === 'versioningType');
  }

  showCustomButtonDelegate(row) {
    return !!row?.canDelegate;
  }

  getColumn(columnName) {
    if (this.commonColumnsTranslate.includes(columnName)) {
      return `wc.common.${columnName}`;
    }
    return `${this.translationPrefix}.${columnName}`;
  }

  expand(element) {
    if (!this.disableExpand) {
      this.expanded = !this.expanded;
      this.getChildDataEvent.emit(element);
      const isExpandedElement = this.expandedElement(element);
      if (!this.multiExpandable) {
        this.expandedElements.clear();
      }
      if (isExpandedElement) {
        this.expandedElements.delete(element);
      } else {
        this.getChildDataCallback(element).subscribe(result =>
          this.expandedElements.set(element, this.getDataSource(result))
        );
      }
    }
  }

  expandedElement(row): boolean {
    return this.expandedElements.get(row) ? true : false;
  }

  isExpandable(row) {
    if (
      this.module === 'products' &&
      (row.versioningType === 'MULTI_ACTIVE' || row.versioningType === 'SINGLE_ACTIVE')
    ) {
      return true;
    } else {
      return false;
    }
  }

  isHighLighted(element, col) {
    return this.parentParameters?.find(param => param.name === element?.name) && col === this.highLightColumn;
  }

  getBackgroundColor(state) {
    if (state?.state === 'ACTIVE') {
      return (this.backgroundColor = 'rgba(40, 167, 69, 0.1)');
    }
  }

  getVersionIcon(version) {
    let icon;
    switch (version) {
      case 'ACTIVE':
        icon = 'activeState';
        break;
      case 'OBSOLETE':
        icon = 'obsoleteState';
        break;
      case 'TEST':
        icon = 'testState';
        break;
      case 'DESIGN':
        icon = 'designState';
        break;
    }
    return icon;
  }

  public expandAll() {
    this.dataSource.data.forEach(row => {
      if (!this.expandedElement(row)) {
        this.getChildDataCallback(row).subscribe(result => {
          if (result) {
            this.expandedElements.set(row, this.getDataSource(result));
          }
        });
      }
    });
  }

  public collapseAll() {
    this.expandedElements.clear();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}

export enum TableListingExpandTypes {
  BOXES = 'boxes',
}
