<div *ngIf="!disableActions" class="main__header">
  <div class="header-box">
    <div class="icon-box PC">
      <mat-icon svgIcon="product"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.products.products' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      id="001_product_list"
      (click)="newProduct()"
      [routerLink]=""
      *ngIf="aclService.pageEditAccess"
      mat-raised-button
      color="primary"
      class="btn -primary icon -xs stroke"
      ><mat-icon svgIcon="plus"></mat-icon>{{ 'wc.admin.products.products.addProduct' | translate }}</a
    >
    <!-- TODO enable export products after developed
    <a (click)="exportCheckedProducts()" [routerLink]=""
       class="btn -primary -xs">{{'wc.admin.products.product.export' | translate}}</a>
    -->
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-product-filter
        (filterChanged)="handleFilterChange()"
        (hideEvent)="toggleFilter()"
        *ngIf="baseFilterVisible"
        [filtering]="search.filtering">
      </app-product-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.products.productListing' | translate }}</span>
          <div class="paginator__entries" *ngIf="pagedProductsDto?.currentPage > 0">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedProductsDto?.totalItems
                          ? pagedProductsDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedProductsDto?.totalItems
                    }
            }}
          </div>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [parentColumnName]="[
            'versioningType',
            'description',
            'category',
            'id',
            'state',
            'created',
            'modified',
            'modifiedBy'
          ]"
          [childColumnName]="[
            'version',
            'state',
            'availableFor.startDateTime',
            'availableFor.endDateTime',
            'created',
            'createdBy'
          ]"
          [columnToTruncate]="['versioningType', 'description', 'state', 'created', 'modified', 'modifiedBy']"
          [dontShowOnMobile]="['state', 'created', 'modifiedBy']"
          [fillIdBy]="'id'"
          [disableExpand]="disableExpand"
          [selectedProducts]="selectedProducts"
          [disableActions]="disableActions"
          [selectMode]="selectMode"
          [toggleArrow]="toggleArrow"
          [module]="'products'"
          [nestedTable]="nestedTable"
          [expandType]="'boxes'"
          [getChildDataCallback]="getChildDataCallback"
          [pagedDto]="pagedProductsDto"
          (doubleClickEvent)="!isModal ? editProduct($event) : singleSelect($event)"
          (editInNewTabButtonEvent)="editProduct($event, true)"
          (editButtonEvent)="editProduct($event)"
          (getBackGroundColorEvent)="getBackgroundColor($event)"
          [backgroundColor]="backgroundColor"
          (pageSizeEvent)="pageSizeChanged($event)"
          [editable]="aclService.pageEditAccess"
          (approveButtonEvent)="activateProduct($event)"
          (archiveButtonEvent)="archiveProduct($event)"
          (rejectButtonEvent)="rejectProduct($event)"
          (duplicateButtonEvent)="duplicateProduct($event)"
          (sortChangeEvent)="onSortChange($event)"
          [multiExpandable]="true"
          [translationPrefix]="'wc.admin.products.product'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
