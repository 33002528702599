import { Component, Input, ViewChild } from '@angular/core';
import { Search } from '@btl/btl-fe-wc-common';
import { AccountListComponent } from '@components/account/list/account-list.component';

@Component({
  selector: 'app-accounts-select-modal',
  templateUrl: './accounts-select-modal.component.html',
  styleUrls: ['./accounts-select-modal.component.scss'],
})
export class AccountsSelectModalComponent {
  @ViewChild(AccountListComponent, { static: true })
  accountListComponent: AccountListComponent;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  multiSelectMode: boolean = false;

  singleSelectMode: boolean = true;

  @Input()
  disableActions: boolean = true;

  @Input()
  disableListingActions: boolean = true;

  @Input()
  disableExpand: boolean = true;

  @Input()
  selectMode: boolean = true;

  activeMultiSelectMode() {
    this.multiSelectMode = true;
    this.singleSelectMode = false;
  }

  selectEvent() {
    if (this.singleSelectMode) {
      const accountDto = this.accountListComponent.getSelectedData()[0];
      if (accountDto) {
        this.selectHandler(accountDto);
        this.dialogRef.close();
      }
    } else {
      const accountDtos = this.accountListComponent.getSelectedData();
      if (accountDtos && accountDtos.length > 0) {
        this.selectHandler(accountDtos);
        this.dialogRef.close();
      }
    }
  }

  singleSelect(selectedDto) {
    this.selectHandler(selectedDto);
    this.dialogRef.close();
  }

  getSearch(): Search {
    return this.accountListComponent.search;
  }

  removeSelection() {
    if (this.singleSelectMode) {
      this.selectHandler(null);
    } else {
      this.selectHandler([]);
    }
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
