import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-prices',
  template: '<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>',
})
export class ProductPricesComponent {
  routerOutlet: any;

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.saveData()) {
      return true;
    }

    return false;
  }

  saveData(): Observable<boolean> | boolean {
    this.routerOutlet.saveData();
    return true;
  }
}
