import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AbstractFilterComponent, AdminDynamicEnumService } from '@btl/btl-fe-wc-common';
import { EnumerationsService } from '@service/enumerations.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notification-gateway-filter',
  templateUrl: './notification-gateway-filter.component.html',
})
export class NotificationGatewayFilterComponent extends AbstractFilterComponent {
  panelOpenState: boolean = false;

  channelTypes = [];

  filterForm = this.formBuilder.group({
    code: [],
    name: [],
    channelType: [],
  });

  constructor(private formBuilder: FormBuilder, private adminDynamicEnumService: AdminDynamicEnumService) {
    super();

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.NOTIFICATION, 'com.emeldi.ecc.be.notification.enums.Channel')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.channelTypes = result?.data ? result.data.map(value => value.name).sort() : [];
      });
  }
}
