<div class="card__inner" [formGroup]="form" *ngIf="textTypes">
  <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
    <div fxLayoutGap="10px" class="listing-action">
      {{ (headerTranslationKey ? headerTranslationKey : 'wc.admin.productGroups.attachments') | translate }}
    </div>
    <div fxLayoutGap="10px" class="listing-action">
      <button
        color="primary"
        mat-raised-button
        (click)="add(uploadImages)"
        [routerLink]=""
        id="001_form_attachments"
        class="btn -primary -xs edit icon stroke"
        [disabled]="disabled">
        <mat-icon svgIcon="plus"></mat-icon>
        {{ 'wc.common.add' | translate }}
      </button>
      <button
        *ngIf="getFromGalleryEnabled"
        color="primary"
        mat-raised-button
        (click)="chooseFromGallery(null, uploadImages)"
        [routerLink]=""
        id="002_form_attachments"
        class="btn -primary -xs edit"
        [disabled]="disabled">
        <mat-icon class="default-icon">file_copy</mat-icon>
        {{ 'wc.admin.products.product.documents.chooseFromGallery' | translate }}
      </button>
      <mat-icon
        (click)="collapsed = !collapsed"
        color="primary"
        svgIcon="{{ collapsed ? 'hide' : 'show' }}"
        style="cursor: pointer"></mat-icon>
    </div>
  </div>

  <div class="card__body pictures" *ngIf="!collapsed" formArrayName="attachments">
    <table mat-table [dataSource]="getDataSource()" class="table">
      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef>{{ 'wc.admin.files.fileName' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.value.dmsFile?.name }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'wc.admin.files.name' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ getElementName(element.value) }}</td>
      </ng-container>

      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef>{{ 'wc.admin.files.priority' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.value.priority }}</td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ 'wc.admin.files.id' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.value.dmsFile?.id }}</td>
      </ng-container>

      <ng-container matColumnDef="uploadData">
        <th mat-header-cell *matHeaderCellDef class="content-desktop" [style.width.px]="100">
          {{ 'wc.admin.files.created' | translate }}
        </th>
        <td mat-cell class="content-desktop" [style.width.px]="100" *matCellDef="let element">
          {{ element.value.dmsFile?.created | date: 'dd.MM.yyyy HH:mm' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef [style.width.px]="100">{{ 'wc.admin.files.actions' | translate }}</th>
        <td mat-cell *matCellDef="let element" [style.width.px]="100">
          <div class="picture_actions">
            <div class="actions-box">
              <mat-icon id="003_form_attachments" (click)="chooseFromGallery(element, null)">file_copy</mat-icon>
              <mat-icon id="004_form_attachments" (click)="uploaded(element)">cloud_upload</mat-icon>
              <mat-icon id="005_form_attachments" (click)="download(element)">cloud_download</mat-icon>
              <mat-icon id="006_form_attachments" (click)="edit(uploadImages, element)">create</mat-icon>
              <mat-icon id="007_form_attachments" (click)="remove(element)">delete</mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>

<ng-template #uploadImages let-c="close" let-d="dismiss">
  <div>
    <div>
      <h2 mat-dialog-title>
        {{ 'wc.admin.attachments.changeParameters' | translate }}
        <mat-icon
          class="default-icon-gray-fill no-stroke"
          svgIcon="close"
          mat-dialog-close
          matTooltip="{{ 'wc.common.close' | translate }}">
        </mat-icon>
      </h2>
    </div>
  </div>
  <mat-dialog-content class="mat-typography picture-modal">
    <div class="card__wrapper">
      <div class="card__body">
        <div class="form" *ngIf="editPictureForm" [formGroup]="editPictureForm">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row" formGroupName="dmsFile">
                <app-custom-input
                  [parentGroup]="editPictureForm.get('dmsFile')"
                  [controlName]="'name'"
                  id="008_form_attachments"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.attachments.fileName'">
                </app-custom-input>
              </div>
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    *ngIf="priorityChange"
                    [parentGroup]="editPictureForm"
                    [controlName]="'priority'"
                    [inputType]="'number'"
                    [translationPrefix]="'wc.admin.products.product.documents'">
                  </app-custom-input>
                </div>
              </div>
            </div>
            <div class="form__group -flex">
              <div class="form__row" formGroupName="dmsFile">
                <app-input-select-form-field
                  *ngIf="languageChange"
                  id="009_form_attachments"
                  formControlName="language"
                  [options]="locales"
                  [translationPrefix]="'wc.admin.products.product.documents'">
                </app-input-select-form-field>
              </div>
            </div>
            <div class="form__group -flex">
              <div class="form__row" *ngIf="typeChange">
                <app-input-select-form-field
                  formControlName="type"
                  id="010_form_attachments"
                  [options]="types"
                  [translationPrefix]="'wc.admin.attachments'">
                </app-input-select-form-field>
              </div>
            </div>
            <div class="form__group -flex">
              <div class="form__row" formGroupName="dmsFile" *ngIf="noteChange">
                <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                  <mat-label>{{ 'wc.admin.attachments.note' | translate }}</mat-label>
                  <textarea
                    id="011_form_attachments"
                    class="form__input"
                    matInput
                    type="text"
                    formControlName="note"></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="form__group -flex" formGroupName="dmsFile" *ngIf="textsChange">
              <app-form-texts
                class="card -full"
                [textTypes]="textTypes"
                [textTypeTranslationPrefix]="'wc.admin.products.product.documents'"
                formControlName="texts">
              </app-form-texts>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="primary" mat-raised-button (click)="cancel()" [routerLink]="">
      {{ 'wc.common.cancel.button' | translate }}
    </button>
    <button color="primary" mat-raised-button (click)="saveChanges()" [routerLink]="">
      {{ 'wc.common.save' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
