import { Component, Input } from '@angular/core';
import {
  AclService,
  AdminProductService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  Search,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { ObjectAsMapDto, PagedProductsDto, ProductDto, ProductStateDto } from '@btl/admin-bff';
import { ProductEditService } from '@service/product-edit.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import {
  DuplicateProductModalComponent
,
} from '@components/product-catalogue/products/duplicate-product-modal/duplicate-product-modal.component';
import { MatDialog } from '@angular/material/dialog';

import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.scss'],
})
@EnableDynamicLoading({ customName: ProductListingComponent.PAGE_ID })
export class ProductListingComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'ProductListingComponent';

  pageId(): string {
    return ProductListingComponent.PAGE_ID;
  }

  public static readonly SELECTED_PRODUCT = 'selectedProduct';

  @Input()
  nestedTable = true;

  @Input()
  excludeProductCodes = [];

  @Input()
  toggleArrow: boolean = true;

  @Input()
  productVersionSelection = false;

  @Input()
  selectedProducts = [];

  selectMode = false;

  disableExpand = false;

  pagedProductsDto: PagedProductsDto;

  backgroundColor;

  baseFilterVisible = true;

  constructor(
    private adminProductService: AdminProductService,
    private productEditService: ProductEditService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    public aclService: AclService,
    private dialog: MatDialog,
    protected modulePreferencesService: ModulePreferencesService,
    protected confirmationDialogService: ConfirmationDialogService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
    this.search.fields = [
      'currentPage',
      'totalItems',
      'totalPages',
      'pageSize',
      'data.id',
      'data.bundle',
      'data.state',
      'data.versioningType',
      'data.description',
      'data.category',
      'data.productCode',
      'data.created',
      'data.modified',
      'data.modifiedBy',
      'data.texts',
    ];
  }

  navigationSubscription(navigation: NavigationEnd) {}

  onSortChange(sorting: MatSort) {
    if (sorting.active === 'category') {
      sorting.active = 'categoryId';
    }
    super.onSortChange(sorting);
    if (sorting.active === 'categoryId') {
      sorting.active = 'category';
    }
  }

  rejectProduct(productDto: ProductDto) {
    this.appBlockerService.block();
    this.adminProductService
      .rejectProduct(productDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.loadData();
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      });
  }

  editProduct(productDto: ProductDto, inNewTab: boolean = false) {
    const urlTree = this.router.createUrlTree([productDto.id, 'attributes'], { relativeTo: this.route });
    if (inNewTab) {
      window.open(this.router.serializeUrl(urlTree), '_blank');
    } else {
      this.router.navigateByUrl(urlTree);
    }
  }

  newProduct() {
    this.productEditService.setProductToEdit(this.productEditService.getNewProduct());
    this.router.navigate(['newProduct', 'attributes'], { relativeTo: this.route });
  }

  duplicateProduct(productDto: ProductDto, refresh?: boolean) {
    this.adminProductService
      .getProductById(productDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        const modalRef = this.dialog.open(DuplicateProductModalComponent);
        const duplicateProductModalComponent = modalRef.componentInstance;
        duplicateProductModalComponent.duplicatedProductCode = result.productCode;
        duplicateProductModalComponent.duplicatedSeoUrl = result.seoUrl;
        duplicateProductModalComponent.dialogRef = modalRef;
        duplicateProductModalComponent.duplicateEvent.subscribe(duplicateValue => {
          if (duplicateValue) {
            this.router.navigate(['newProduct', 'attributes'], {
              relativeTo: this.route,
              queryParams: {
                duplicateProductId: productDto.id,
                duplicateProductCode: duplicateValue.productCode,
                duplicateSeoUrl: duplicateValue.seoUrl,
                duplicateOptions: duplicateValue.duplicateOptions,
              },
            });
          }
        });
      });
  }

  activateProduct(productDto: ProductDto, refresh?: boolean) {
    this.appBlockerService.block();
    this.adminProductService
      .getProductById(productDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(product => {
        const productAsMap: ObjectAsMapDto = {};
        productAsMap.state = ProductStateDto.ACTIVE;
        productAsMap.recordVersion = product.recordVersion;
        this.adminProductService
          .patchProduct(product.id, productAsMap)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.loadData();
            this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          });
      });
  }

  isProductMultiActive(product) {
    return this.productEditService.isProductMultiActive(product);
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminProductService
        .getProductsByFilter(this.search, !this.productVersionSelection)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedProductsDto = result;
            if (this.pagedProductsDto) {
              this.pagedProductsDto.data = !this.pagedProductsDto.data
                ? []
                : this.pagedProductsDto.data.filter(product => {
                  let filter = true;
                  this.excludeProductCodes.forEach(productCode => {
                    if (productCode === product.productCode) {
                      filter = false;
                    }
                  });
                  return filter;
                });
            }
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  archiveProduct(productDto: ProductDto) {
    this.appBlockerService.block();
    this.adminProductService
      .getProductById(productDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(product => {
        const productAsMap: ObjectAsMapDto = {};
        productAsMap.state = ProductStateDto.OBSOLETE;
        productAsMap.recordVersion = product.recordVersion;
        this.adminProductService
          .patchProduct(product.id, productAsMap)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.loadData();
            this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          });
      });
  }

  toggleFilter() {
    this.baseFilterVisible = !this.baseFilterVisible;
  }

  public getChildDataCallback = (product: any): any => {
    return this.adminProductService.getProductVersions(product.productCode, 1, -1).pipe(takeUntil(this.onDestroy$));
  };

  getBackgroundColor(state) {
    if (state?.state === 'ACTIVE') {
      this.backgroundColor = 'green';
    }
  }
}
