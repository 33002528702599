<div class="main__header">
  <h1 id="001_category_edit">{{ 'wc.admin.technicalCategory.technicalCategoryEdit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_product_group_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs"
      >{{ 'wc.common.save' | translate }}</a
    >
    <a
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="003_category_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="004_category_edit" mat-menu-item (click)="delete()" *ngIf="category?.id" [routerLink]=""
      >
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
      <a id="005_category_edit" mat-menu-item (click)="releases()" *ngIf="category?.id" [routerLink]=""
      >
        <mat-icon svgIcon="upload"></mat-icon>
        {{ 'wc.common.releases' | translate }}</a
      >
    </mat-menu>
    <a (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
  </div>
</div>

<div *ngIf="category" class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.technicalCategory' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="category" [formGroup]="categoryForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="categoryForm"
                  [controlName]="'id'"
                  id="006_category_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.technicalCategory'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'parentId'"
                  [options]="categoriesHints"
                  id="007_category_edit"
                  [showEmptyOption]="true"
                  [translationPrefix]="'wc.admin.technicalCategory'">
                </app-input-select-form-field>
              </div>
              <div class="form__row"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <form [formGroup]="categoryForm" class="card__wrapper">
    <app-form-texts
      class="card -full"
      [pcEntityType]="'CatTech'"
      [textTypeTranslationPrefix]="'wc.admin.technicalCategory'"
      formControlName="texts">
    </app-form-texts>
  </form>

  <div
    *ngIf="category"
    [editParentParameter]="editParentParameter"
    [category]="category"
    [formArray]="getParametersFormArray()"
    [formGroup]="categoryForm"
    [parameters]="category.parameters"
    [parentParameters]="category.parentsParameters"
    [toggleArrow]="false"
    [enablePaginator]="false"
    [selectMode]="true"
    [enableViewButton]="false"
    [editable]="true"
    app-category-parameters-listing
    class="card__wrapper"></div>

  <div
    *ngIf="category && parameterTextTypes.length > 0"
    [category]="category"
    [formArray]="parentParametersFormArray"
    [parentParametersMode]="true"
    (editParent)="editParent($event)"
    [toggleArrow]="false"
    [enablePaginator]="false"
    [selectMode]="false"
    [enableViewButton]="true"
    [editable]="false"
    app-category-parameters-listing
    class="card__wrapper"></div>

  <div class="card__wrapper">
    <div class="card -full">
      <div class="card__inner">
        <app-cart-events-listing
          *ngIf="category && category.id"
          [cacheFilter]="false"
          [constantFilter]="getProductSourceFilter()"
          [editable]="false"
          [hideFilter]="true"
          additionalHeaderLtKey="wc.admin.relationships.sourceType">
        </app-cart-events-listing>
      </div>
    </div>
  </div>

  <div class="card -full">
    <div class="card__inner">
      <app-acl-table
        *ngIf="aclLoaded()"
        resourceType="PC_PRODUCT"
        category="true"
        headerTranslateKey="wc.admin.technicalCategory.acl"
        [aclRules]="categoryAclRules"
        [parentAclRules]="parentCategoriesAclRules"
        [parentForm]="aclForm"
        [context]="resourceIdentificationContext">
      </app-acl-table>
    </div>
  </div>

  <div class="card -full" *ngIf="this.category.parameters">
    <div class="card__inner">
      <app-acl-table
        *ngIf="aclParametersLoaded() && this.category && this.category.parameters"
        resourceType="PC_PRODUCT_PARAMETER"
        category="true"
        headerTranslateKey="wc.admin.technicalCategory.acl.parameters"
        [parameters]="getAllParameters()"
        [parameterName]="lastAclParameterName"
        (parameterChanged)="parameterChanged($event)"
        [aclRules]="categoryParametersAclRules"
        [parentAclRules]="parentCategoriesParametersAclRules"
        [parentForm]="aclForm"
        [context]="resourceIdentificationContext">
      </app-acl-table>
    </div>
  </div>
</div>
