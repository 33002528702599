import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepTemplateConfigDto } from '@btl/admin-bff/model/stepTemplateConfigDto';

@Component({
  selector: 'app-ticket-type-steps-list-item, [app-ticket-type-steps-list-item]',
  templateUrl: './ticket-type-steps-list-item.component.html',
  styleUrls: ['./ticket-type-steps-list-item.component.scss'],
})
export class TicketTypeStepsListItemComponent {
  selected = false;

  @Input()
  step: StepTemplateConfigDto;

  @Input()
  position;

  @Input()
  readOnly;

  @Output()
  readonly stepMoveUp = new EventEmitter<any>();

  @Output()
  readonly stepMoveDown = new EventEmitter<any>();

  @Output()
  readonly deleteStep = new EventEmitter<any>();

  @Output()
  readonly editStep = new EventEmitter<any>();

  moveUp() {
    this.stepMoveUp.emit(this.step);
  }

  moveDown() {
    this.stepMoveDown.emit(this.step);
  }

  delete() {
    this.deleteStep.emit(this.step);
  }

  edit() {
    if (!this.readOnly) {
      this.editStep.emit(this.step);
    }
  }
}
