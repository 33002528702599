import { Injectable } from '@angular/core';
import { AdminDmsService, CompareType, Search } from '@btl/btl-fe-wc-common';
import { Observable } from 'rxjs';
import { DmsFileDto } from '@btl/admin-bff';
import { map } from 'rxjs/operators';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Injectable({
  providedIn: 'root',
})
export class NotificationAttachmentService {
  constructor(private adminDmsService: AdminDmsService) {}

  getDmsFileById(extId: string): Observable<DmsFileDto> {
    if (!extId) {
      return null;
    }

    const search: Search = { filtering: [], sorting: [], paging: { page: 1, pageSize: -1 } };
    search.filtering.push({
      column: 'extId',
      compareType: CompareTypeDtoEnum.EQUAL,
      value: extId,
    });
    return this.adminDmsService.filterFiles(search, null).pipe(
      map(result => {
        if (result?.data?.length) {
          return result.data[0];
        }
      })
    );
  }
}
