<div *ngIf="pickerType != PickerInputType.UPLOAD && singleSelect" [formGroup]="parentGroup">
  <input [formControlName]="controlName" class="form__input" matInput type="hidden" readonly />
  <app-input-select-form-field
    [labelOption]="labelField"
    [valueOption]="valueField"
    [placeHolder]="('wc.common.inputPicker.searchBy.tooltip' | translate) + ' ' + getFieldTranslation()"
    [matTooltip]="('wc.common.inputPicker.searchBy.tooltip' | translate) + ' ' + getFieldTranslation() + ' '+ ('wc.common.inputPicker.orUseAdvancedFilter.tooltip' | translate)"
    [translationPrefix]="translationPrefix"
    [options]="hints"
    [preSelectFirst]="false"
    [formControlName]="controlName"
    [externalFilter]="getHintsByPickerType"
    [externalFilterButtonAction]="select"
    (selectionFullObjectChangeEmit)="selectItem($event)">
  </app-input-select-form-field>
</div>
<mat-form-field
  *ngIf="pickerType === PickerInputType.UPLOAD || !singleSelect"
  class="form__field"
  appearance="outline"
  floatLabel="always"
  matTooltip="{{ (pickerType != PickerInputType.UPLOAD && singleSelect ? 'wc.common.inputPicker.tooltip' : '') | translate }}"
  [formGroup]="parentGroup">
  <mat-label>{{ translationPrefix + '.' + (label ? label : controlName) | translate }}</mat-label>
  <ng-container *ngIf="singleSelect; else multiSelect">
    <input *ngIf="pickerType === PickerInputType.UPLOAD" [formControlName]="controlName" [value]="showValue"
           class="form__input" matInput type="text" readonly />
  </ng-container>
  <ng-template #multiSelect>
    <input hidden="true" [formControlName]="controlName" class="form__input" matInput readonly />
    <div class="chips">
      <!--This is handling only productDto-->
      <mat-chip-grid #chipList>
        <mat-chip-row color="primary" *ngFor="let item of list" (removed)="remove(item)">
          {{ valueField === 'id' ? item?.id : item?.productCode }} {{ showDescription ? item?.description : '' }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input class="form__input" [matChipInputFor]="chipList" (matChipInputTokenEnd)="add($event)" matInput readonly/>
      </mat-chip-grid>
    </div>
  </ng-template>
  <button
    *ngIf="pickerType === PickerInputType.UPLOAD && !showValue"
    class="picker-button"
    mat-button
    matSuffix
    mat-stroked-button
    (click)="select()">
    {{ 'wc.admin.imports.chooseFile' | translate }}
  </button>
  <mat-icon
    *ngIf="pickerType === PickerInputType.UPLOAD && showValue"
    matSuffix
    (click)="clearInput()"
    style="cursor: pointer"
    svgIcon="delete"
    class="default-icon-gray-fill no-stroke"></mat-icon>
  <mat-icon *ngIf="pickerType !== PickerInputType.UPLOAD && parentGroup.controls[controlName].status !== 'DISABLED'"
            matSuffix (click)="select()" style="cursor: pointer">
  open_in_new
  </mat-icon
  >
  <mat-error *ngIf="parentGroup.controls[controlName].errors">
    <ng-container *ngFor="let keyError of getControlValidationErrors()">
      <ng-container *ngIf="keyError != 'incorrect'; else incorect">
        {{ (translationPrefix + '.' + (label ? label : controlName) + '.error.' + keyError | translate) + suffix }}
      </ng-container>
      <ng-template #incorect>
        {{ 'wc.common.error.incorrect' | translate }}
      </ng-template>
    </ng-container>
  </mat-error>
</mat-form-field>
