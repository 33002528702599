<div class="page__wrapper">
  <div class="main__header">
    <h1>{{ 'wc.admin.notification.gateway.edit' | translate }}</h1>
    <div class="page__menu">
      <a
        color="primary"
        mat-raised-button
        (click)="save()"
        [routerLink]=""
        class="btn -primary -xs"
        *ngIf="aclService.pageEditAccess"
        >{{ 'wc.common.save' | translate }}</a
      >
      <a
        color="primary"
        mat-stroked-button
        *ngIf="aclService.pageEditAccess"
        [routerLink]=""
        class="btn -primary -xs more-actions"
        [matMenuTriggerFor]="menu"
        >More actions</a
      >
      <mat-menu #menu="matMenu" class="module-actions">
        <a mat-menu-item (click)="reset()" [routerLink]="">{{ 'wc.common.reset' | translate }}</a>
        <a mat-menu-item *ngIf="gatewayDto?.id" (click)="delete()" [routerLink]="">{{
          'wc.common.delete' | translate
        }}</a>
      </mat-menu>
      <a (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
    </div>
  </div>

  <div class="card__wrapper" *ngIf="gatewayDto && gatewayInitialized">
    <!-- card -->
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.notification.gateway.coreAttributes' | translate }}
        </div>
        <div class="card__body">
          <form *ngIf="gatewayDto" [formGroup]="gatewayForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="gatewayForm"
                    (change)="checkUniqueCode()"
                    [controlName]="'code'"
                    [inputType]="'text'"
                    [label]="'code'"
                    [translationPrefix]="'wc.admin.notification.gateway'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="gatewayForm"
                    [controlName]="'name'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification.gateway'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'channelType'"
                    [options]="channelTypes"
                    [showEmptyOption]="true"
                    [translationPrefix]="'wc.admin.notification.gateway'">
                  </app-input-select-form-field>
                </div>

                <div class="form__row">
                  <app-custom-input
                    [inputType]="'checkbox'"
                    [parentGroup]="gatewayForm"
                    [controlName]="'supportDeliveryReport'"
                    [label]="'wc.admin.notification.gateway.supportDeliveryReport'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [inputType]="'checkbox'"
                    [parentGroup]="gatewayForm"
                    [controlName]="'loopbackEnabled'"
                    [label]="'wc.admin.notification.gateway.loopbackEnabled'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [inputType]="'checkbox'"
                    [parentGroup]="gatewayForm"
                    [controlName]="'temporaryDisabled'"
                    [label]="'wc.admin.notification.gateway.temporaryDisabled'">
                  </app-custom-input>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- card -->
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.notification.gateway.loopbacks' | translate }}
          <a color="primary" mat-raised-button (click)="addNewRecipient()" [routerLink]="" class="btn -primary -xs">
            {{ 'wc.admin.notification.gateway.recipient.add' | translate }}</a
          >
        </div>
        <div class="card__body">
          <form *ngIf="loopbacksFormArray.controls.length" [formGroup]="loopbacksFormGroup" class="form">
            <div class="form__groups" formArrayName="loopbacks">
              <div class="form__group -flex" *ngFor="let loopbackForm of loopbacksFormArray.controls; let i = index">
                <ng-container [formGroupName]="i">
                  <div class="form__row">
                    <app-custom-input
                      [inputType]="'checkbox'"
                      [parentGroup]="loopbackForm"
                      (checkboxChange)="onLoopbackCheckboxChange($event, i)"
                      [controlName]="'passEnabled'"
                      [label]="'wc.admin.notification.gateway.passEnabled'">
                    </app-custom-input>
                  </div>
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="loopbackForm"
                      [controlName]="'recipient'"
                      [inputType]="'text'"
                      [translationPrefix]="'wc.admin.notification.gateway'">
                    </app-custom-input>
                  </div>
                  <div class="form__row">
                    <a
                      color="primary"
                      mat-raised-button
                      (click)="deleteRecipient(i)"
                      [routerLink]=""
                      class="btn -primary -xs">
                      {{ 'wc.admin.notification.gateway.recipient.delete' | translate }}</a
                    >
                  </div>
                </ng-container>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- card -->
    <div class="card -full mat-sidenav-content">
      <div class="card__inner main_content">
        <div class="card__header">
          {{ 'wc.admin.notification.gateway.notificationTemplates' | translate }}
        </div>
        <div class="card__body">
          <div fxLayout="column" class="paginator-header">
            <span class="listing-header">{{ 'wc.admin.notification.gatewayListings' | translate }}</span>
            <div class="paginator__entries" *ngIf="pagedNotificationTypesDto?.currentPage > 0">
              {{
                'wc.common.pagination.showing'
                  | translate
                    : {
                        from:
                          notificationTypesSearch.paging.pageSize * notificationTypesSearch.paging.page -
                          notificationTypesSearch.paging.pageSize +
                          1,
                        to:
                          notificationTypesSearch.paging.pageSize * notificationTypesSearch.paging.page >
                          pagedNotificationTypesDto?.totalItems
                            ? pagedNotificationTypesDto?.totalItems
                            : notificationTypesSearch.paging.pageSize * notificationTypesSearch.paging.page,
                        of: pagedNotificationTypesDto?.totalItems
                      }
              }}
            </div>
          </div>
          <app-table-listing-form
            [columnName]="[
              'notificationType',
              'remark',
              'productionEnabled',
              'sendingEnabled',
              'created',
              'modified',
              'modifiedBy'
            ]"
            [dontShowOnMobile]="['productionEnabled', 'sendingEnabled', 'created', 'modified', 'modifiedBy']"
            [columnToTruncate]="['remark', 'created', 'modified', 'modifiedBy']"
            [fillIdBy]="'id'"
            [pagedDto]="pagedNotificationTypesDto"
            [editable]="false"
            [selectMode]="false"
            (editButtonEvent)="editNotificationType($event)"
            (editInNewTabButtonEvent)="editNotificationType($event, true)"
            (doubleClickEvent)="editNotificationType($event)"
            (pageSizeEvent)="pageSizeChanged($event)"
            [translationPrefix]="'wc.admin.notification.type'">
          </app-table-listing-form>
        </div>
      </div>
    </div>
  </div>
</div>
