import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminDynamicEnumService, Option } from '@btl/btl-fe-wc-common';
import { EnumEntriesDto, EnumEntryDto } from '@btl/admin-bff';

@Injectable({
  providedIn: 'root'
})
export class AdminInputSelectDynamicEnumService {
  constructor(private dynamicEnumService: AdminDynamicEnumService) {
  }

  public getOptions(sourceName: string, enumName: string): Observable<Array<Option>> {
    return this.dynamicEnumService.getEnumEntries(sourceName, enumName).pipe(
      map((enumEntriesDto: EnumEntriesDto) => {
        const options: Array<Option> = [];
        for (let i = 0; i < enumEntriesDto.data.length; i++) {
          const enumEntry: EnumEntryDto = enumEntriesDto.data[i];
          const option: Option = new Option(enumEntry.name, enumEntry.name);
          options.push(option);
        }
        return options;
      })
    );
  }
}
