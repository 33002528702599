import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AbstractPageComponent,
  AclService,
  AdminDynamicEnumService,
  AdminProductGroupService,
  AdminProductRelationshipService,
  AdminProductService,
  AppBlockerService,
  BusinessCategoryService,
  CurrentLocaleService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ProductDto, RelationshipDto, TechnicalCategoryAdminService, TechnicalCategoryDto } from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import {
  RelationshipsListingComponent,
} from '@components/product-catalogue/relationships/relationships-listing/relationships-listing.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-relationship-edit',
  templateUrl: './relationship-edit.component.html',
  styleUrls: ['./relationship-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({customName: RelationshipEditComponent.PAGE_ID})
export class RelationshipEditComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'RelationshipEditComponent';

  @Input() sourceTargetMode: string;
  @Input() navigationMode: boolean = true;
  @Input() inputRelationship: RelationshipDto;
  @Input() product: ProductDto;
  @Input() resetEventEmitter: EventEmitter<RelationshipDto>;

  pageId(): string {
    return RelationshipEditComponent.PAGE_ID;
  }

  PickerInputType = PickerInputType;

  savingProductRelationshipId;

  relationship: RelationshipDto;
  sourceType: string;
  sourceId: string;
  targetType: string;
  targetId: string;
  returnUrl: string;

  validFor: FormGroup = this.formBuilder.group({
    startDateTime: [null, Validators.required],
    endDateTime: [],
  });

  sourceCardinalityMinMax: FormGroup = this.formBuilder.group({
    min: [1, [Validators.required, Validators.pattern('^[0-9]*$')]],
    max: [1, [Validators.required, Validators.pattern('^-?[0-9]*$'), Validators.min(-1)]],
  });

  targetCardinalityMinMax: FormGroup = this.formBuilder.group({
    min: [1, [Validators.required, Validators.pattern('^[0-9]*$')]],
    max: [1, [Validators.required, Validators.pattern('^-?[0-9]*$'), Validators.min(-1)]],
  });

  meaningTypes = [];
  relationshipTypes = [];
  scopeTypes = [];
  productEntityTypes = [];
  sourceProductEntityTypes = [];
  targetProductEntityTypes = [];

  relationshipForm: FormGroup = this.formBuilder.group({
    id: [null],
    code: [null, Validators.required],
    meaningType: [this.meaningTypes[1], Validators.required],
    type: [this.relationshipTypes[0], Validators.required],
    priority: [0, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
    validFor: this.validFor,
    scopeType: [this.scopeTypes[0], Validators.required],
    description: [null],
    sourceType: [this.productEntityTypes[0], Validators.required],
    sourceId: [null, Validators.required],
    sourceName: [null],
    sourceCardinalityMinMax: this.sourceCardinalityMinMax,
    targetType: [this.productEntityTypes[0], Validators.required],
    targetId: [null, Validators.required],
    targetName: [null],
    targetCardinalityMinMax: this.targetCardinalityMinMax,
    ruleSetExpression: [null],
  });

  techCategories: Array<string> = [];
  businessCategories: Array<string> = [];

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: -1,
    },
  };

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private adminProductRelationshipService: AdminProductRelationshipService,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private enumerationsService: EnumerationsService,
    private businessCategoryService: BusinessCategoryService,
    private adminProductGroupService: AdminProductGroupService,
    private stickyMessageService: StickyMessageService,
    private adminProductService: AdminProductService,
    private currentLocaleService: CurrentLocaleService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    public aclService: AclService,
    private technicalCategoryAdminService: TechnicalCategoryAdminService
  ) {
    super(router, route);
  }

  ngOnInit() {
    if (this.resetEventEmitter) {
      this.resetEventEmitter
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(relationship => this.reloadRelationship(relationship));
    }

    const getProductTechnicalCategories$ = this.enumerationsService.getProductTechnicalCategories();

    const getBusinessCategories$ = this.businessCategoryService.getBusinessCategories();

    const getRelationshipMeaningType$ = this.adminDynamicEnumService.getEnumEntries(
      EnumerationsService.PC,
      'com.emeldi.ecc.be.pc.enums.RelationshipMeaningType'
    );

    const getRelationshipType$ = this.adminDynamicEnumService.getEnumEntries(
      EnumerationsService.PC,
      'com.emeldi.ecc.be.pc.enums.RelationshipType'
    );

    const getProductRelationshipScopeType$ = this.adminDynamicEnumService.getEnumEntries(
      EnumerationsService.PC,
      'com.emeldi.ecc.be.pc.enums.ProductRelationshipScopeType'
    );

    const getProductEntityType$ = this.adminDynamicEnumService.getEnumEntries(
      EnumerationsService.PC,
      'com.emeldi.ecc.be.pc.enums.ProductEntityType'
    );

    forkJoin([
      getProductTechnicalCategories$,
      getBusinessCategories$,
      getRelationshipMeaningType$,
      getRelationshipType$,
      getProductRelationshipScopeType$,
      getProductEntityType$,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        ([
           productTechnicalCategories,
           businessCategories,
           relationshipMeaningType,
           relationshipType,
           productRelationshipScopeType,
           productEntityType,
         ]) => {
          this.techCategories = productTechnicalCategories;

          businessCategories.forEach(businessCategory => {
            this.businessCategories.push(businessCategory.id);
          });

          this.meaningTypes = relationshipMeaningType.data.map(value => value.name).sort();
          this.relationshipForm.controls['meaningType'].patchValue(this.meaningTypes[1]);
          this.relationshipTypes = relationshipType.data.map(value => value.name).sort();
          this.scopeTypes = productRelationshipScopeType.data.map(value => value.name).sort();
          this.relationshipForm.controls['scopeType'].patchValue(this.scopeTypes[0]);
          this.productEntityTypes = ['ProdCode', 'CatTech', 'ProdGroup'];
          if (this.sourceTargetMode === 'source') {
            this.sourceProductEntityTypes = this.productEntityTypes.filter(
              name => name === 'ProdCode' || name === 'ProdVersionCode'
            );
            this.targetProductEntityTypes = this.productEntityTypes;
          } else if (this.sourceTargetMode === 'target') {
            this.targetProductEntityTypes = this.productEntityTypes.filter(
              name => name === 'ProdCode' || name === 'ProdVersionCode'
            );
            this.sourceProductEntityTypes = this.productEntityTypes;
          } else {
            this.targetProductEntityTypes = this.productEntityTypes;
            this.sourceProductEntityTypes = this.productEntityTypes;
          }

          if (!this.navigationMode) {
            if (!this.inputRelationship) {
              this.relationship = this.getDefaultRelationship();
              if (this.sourceTargetMode === 'source') {
                this.relationship.sourceType = 'ProdCode';
              } else if (this.sourceTargetMode === 'target') {
                this.relationship.targetType = 'ProdCode';
              }
            } else {
              this.relationship = this.inputRelationship;
            }
            if (this.sourceTargetMode === 'source') {
              this.relationshipForm.controls.sourceId.disable();
            } else if (this.sourceTargetMode === 'target') {
              this.relationshipForm.controls.targetId.disable();
            }
            this.relationshipForm.reset(this.relationship);
          }
        }
      );
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const relationshipId = this.params.id;
      const sourceProductCode = this.params.sourceProductCode;
      const targetProductCode = this.params.targetProductCode;
      const returnUrl = this.params.returnUrl;
      if (relationshipId === '&') {
        if (sourceProductCode) {
          this.sourceType = 'ProdCode';
          this.targetType = 'ProdCode';
          this.sourceId = sourceProductCode;
        }
        if (targetProductCode) {
          this.sourceType = 'ProdCode';
          this.targetType = 'ProdCode';
          this.targetId = targetProductCode;
        }
        if (returnUrl) {
          this.returnUrl = returnUrl;
        }
        this.navigateSelf({id: '&'});
      }
      if (relationshipId && relationshipId !== '&') {
        this.loadRelationship(relationshipId);
      } else {
        this.relationship = this.getDefaultRelationship();
        this.relationshipForm.reset(this.relationship);
        if (this.sourceId) {
          this.relationshipForm.controls.sourceId.setValue([this.sourceId]);
        }
        if (this.targetId) {
          this.relationshipForm.controls.targetId.setValue([this.targetId]);
        }
      }
    } else {
      this.relationship = undefined;
    }
  }

  getDefaultRelationship() {
    return {
      meaningType: this.meaningTypes[1],
      type: this.relationshipTypes[0],
      priority: 0,
      scopeType: this.scopeTypes[0],
      sourceType: this.sourceType ? this.sourceType : this.productEntityTypes[0],
      sourceId: null,
      sourceCardinalityMinMax: {
        min: 1,
        max: 1,
      },
      targetType: this.targetType ? this.targetType : this.productEntityTypes[0],
      targetId: null,
      targetCardinalityMinMax: {
        min: 1,
        max: 1,
      },
    };
  }

  loadRelationship(relationshipId) {
    this.adminProductRelationshipService
      .getRelationshipById(relationshipId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadRelationship(result);
      });
  }

  reloadRelationship(relationshipDto: RelationshipDto) {
    this.relationship = relationshipDto;
    this.relationshipForm.reset(this.relationship);
  }

  public getProductRelationshipHandler = (relationshipDto: RelationshipDto): void => {
    if (relationshipDto) {
      if (relationshipDto.id === this.savingProductRelationshipId) {
        this.reloadRelationship(relationshipDto);
      } else {
        this.reloadRelationship(relationshipDto);
        this.navigateSelf({id: relationshipDto.id});
      }
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  reset() {
    if (this.relationship && this.relationship.recordVersion) {
      this.loadRelationship(this.relationship.id);
    } else {
      this.reloadRelationship(this.relationship);
    }
  }

  delete() {
    this.appBlockerService.block();
    this.adminProductRelationshipService
      .deleteRelationship(this.relationship.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.navigateSibling(RelationshipsListingComponent.PAGE_ID);
        this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
      });
  }

  duplicate() {
    this.relationshipForm.controls.code.patchValue(`${this.relationship.code}copy`);
    this.relationship.recordVersion = null;
    this.relationshipForm.controls.id.patchValue(null);
    if (
      this.relationshipForm.controls.sourceType.value === 'ProdCode' ||
      this.relationshipForm.controls.sourceType.value === 'ProdVersionCode'
    ) {
      this.relationshipForm.controls.sourceId.patchValue([this.relationshipForm.controls.sourceId.value]);
    }
    if (
      this.relationshipForm.controls.targetType.value === 'ProdCode' ||
      this.relationshipForm.controls.targetType.value === 'ProdVersionCode'
    ) {
      this.relationshipForm.controls.targetId.patchValue([this.relationshipForm.controls.targetId.value]);
    }
  }

  save() {
    if (this.saveRelationshipAttributes()) {
      this.appBlockerService.block();
      if (this.relationship.recordVersion) {
        this.savingProductRelationshipId = this.relationship.id;
        this.adminProductRelationshipService
          .updateRelationship(this.savingProductRelationshipId, this.relationship)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getProductRelationshipHandler);
      } else {
        const relationships: RelationshipDto[] = this.getRelationshipsToSave();
        const calls = relationships.map(relationship =>
          this.adminProductRelationshipService.createRelationship(relationship)
        );

        forkJoin(calls)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(results => {
            if (results.length > 1) {
              if (this.returnUrl) {
                this.router.navigate([this.returnUrl]);
              } else {
                this.navigateSibling(RelationshipsListingComponent.PAGE_ID);
              }
            } else if (results.length == 1) {
              this.savingProductRelationshipId = null;
              this.relationship.id = null;
              this.getProductRelationshipHandler(results[0]);
            }
          });
      }
    }
  }

  getRelationshipsToSave(): RelationshipDto[] {
    if (
      this.relationship.sourceType === 'ProdCode' ||
      this.relationship.sourceType === 'ProdVersionCode' ||
      this.relationship.targetType === 'ProdCode' ||
      this.relationship.targetType === 'ProdVersionCode'
    ) {
      const relationships: RelationshipDto[] = [];
      let sources: string[] =
        this.relationship.sourceType === 'ProdCode' || this.relationship.sourceType === 'ProdVersionCode'
          ? this.relationshipForm.controls.sourceId.value
          : [this.relationshipForm.controls.sourceId.value];
      let targets: string[] =
        this.relationship.targetType === 'ProdCode' || this.relationship.targetType === 'ProdVersionCode'
          ? this.relationshipForm.controls.targetId.value
          : [this.relationshipForm.controls.targetId.value];

      let counter = 0;
      if (!(sources instanceof Array)) {
        sources = [sources];
      }
      if (!(targets instanceof Array)) {
        targets = [targets];
      }
      const len = sources.length * targets.length;
      sources.forEach(source => {
        targets.forEach(target => {
          const rel: RelationshipDto = Object.assign({}, this.relationship);
          rel.code = rel.code + (len === 1 ? '' : `_${counter++}`);
          rel.sourceId = source;
          rel.targetId = target;
          relationships.push(rel);
        });
      });
      return relationships;
    } else {
      return [this.relationship];
    }
  }

  saveRelationshipAttributes(): boolean {
    FormUtils.validateAllFormFields(this.relationshipForm);
    if (this.relationshipForm.valid) {
      if (this.relationship) {
        Object.keys(this.relationshipForm.controls).forEach(field => {
          const control = this.relationshipForm.get(field);
          this.relationship[field] = control.value;
        });
      }
      return true;
    }

    return false;
  }

  clearField(field) {
    this.relationshipForm.controls[field].patchValue(null);
  }

  cancel() {
    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    } else {
      this.navigateSibling(RelationshipsListingComponent.PAGE_ID);
    }
  }

  changeSourceNameProd($event: any) {
    if ($event instanceof Array) {
      const eventArray: Array<any> = $event;
      this.changeSourceName($event.map(e => e.description).join(', '));
    } else {
      this.changeSourceName($event?.description);
    }
  }

  changeSourceNameGroup($event: any) {
    this.changeSourceName($event?.name);
  }

  changeSourceName(sourceName: String) {
    this.relationshipForm.controls['sourceName'].patchValue(sourceName);
  }

  changeSourceNameCat($event: any) {
    this.changeNameCat($event, 'sourceName');
  }

  changeTargetNameProd($event: any) {
    if ($event instanceof Array) {
      const eventArray: Array<any> = $event;
      this.changeTargetName($event.map(e => e.description).join(', '));
    } else {
      this.changeTargetName($event?.description);
    }
  }

  changeTargetNameGroup($event: any) {
    this.changeTargetName($event?.name);
  }

  changeTargetNameCat($event: any) {
    this.changeNameCat($event, 'targetName');
  }

  private changeNameCat(technicalCategoryId: string, fieldName: string) {
    if (technicalCategoryId) {
      this.technicalCategoryAdminService
        .getTechnicalCategoryById(technicalCategoryId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((techCat: TechnicalCategoryDto) => {
          const textDto = techCat?.texts?.find(text => text.locale === this.currentLocaleService.getCurrentLanguage());
          const text = textDto?.message;
          this.relationshipForm.controls[fieldName].patchValue(text);
        });
    } else {
      this.clearField(fieldName);
    }
  }

  private changeTargetName(targetName: string) {
    this.relationshipForm.controls['targetName'].patchValue(targetName);
  }
}
