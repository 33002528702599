import { Component, Input, OnChanges } from '@angular/core';
import {
  AbstractFilterComponent,
  AdminAclService,
  AdminDynamicEnumService,
  CompareType,
  ServiceUtils,
} from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { EnumEntriesDto, PagedPrivilegesDto } from '@btl/admin-bff';
import { takeUntil } from 'rxjs/operators';
import { EnumerationsService } from '@service/enumerations.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-rules-filter',
  templateUrl: './rules-filter.component.html',
  styleUrls: ['./rules-filter.component.scss'],
})
export class RulesFilterComponent extends AbstractFilterComponent implements OnChanges {
  resourceTypes: EnumEntriesDto;

  @Input()
  parentId;

  pagedPrivilegesDto: PagedPrivilegesDto;

  privilegesHints = [];

  mapFieldWithCompareType = new Map([['resourceIdentification', CompareTypeDtoEnum.LIKE]]);

  mapFieldWithColumnName = new Map([['privilegeType', 'privilege.id']]);

  filterForm = this.formBuilder.group({
    resourceType: [],
    resourceIdentification: [],
    privilegeType: []
  });

  constructor(
    private formBuilder: FormBuilder,
    private adminAclService: AdminAclService,
    private adminDynamicEnumService: AdminDynamicEnumService
  ) {
    super();
    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.ACL, 'com.emeldi.ecc.be.acl.enums.ResourceType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        result.data.sort((a, b) => a.name.localeCompare(b.name));
        this.resourceTypes = result;
      });

    const search = ServiceUtils.getUnlimitedSearch();
    this.adminAclService
      .filterPrivileges(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        result.data.sort((a, b) => a.id.localeCompare(b.id));
        this.pagedPrivilegesDto = result;
        this.privilegesHints = this.pagedPrivilegesDto.data.map(c => c.id);
      });
  }

  ngOnChanges(changes: any) {
    if (changes.filtering && this.filtering !== changes.filtering.currentValue) {
      this.initForm();
    }
  }

}
