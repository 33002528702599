<div >
  <div>
    <h2 mat-dialog-title>
      {{ 'wc.admin.relationships.setValidTo.dialog.title' | translate }}
      <mat-icon
        class="default-icon-gray-fill no-stroke"
        svgIcon="close"
        mat-dialog-close
        matTooltip="{{ 'wc.common.close' | translate }}">
      </mat-icon>
    </h2>
  </div>
</div>
<div class="modal-body">
  <div [formGroup]="form" class="form" fxLayout="column">
    <app-input-date-form-field
      [parentGroup]="form"
      [controlName]="'validTo'"
      id="001_relationships_validTo"
      [label]="'validTo'"
      [translationPrefix]="'wc.admin.relationships'">
    </app-input-date-form-field>
  </div>
</div>
<mat-dialog-actions align="end">
  <button color="primary" mat-raised-button (click)="doSetValidTo()" [routerLink]="">
    {{ 'wc.common.confirm' | translate }}
  </button>
  <button color="primary" mat-raised-button (click)="cancel()" [routerLink]="">
    {{ 'wc.common.cancel.button' | translate }}
  </button>
</mat-dialog-actions>
