<h2 mat-dialog-title>
  {{ 'wc.admin.ruleSets.overwriteExistingRuleSet.header' | translate }}
  <button
    id="002_rule_set_overwrite_modal"
    [attr.aria-label]="'wc.common.close' | translate"
    mat-dialog-close
    color="primary"
    mat-raised-button
    type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</h2>
<mat-dialog-content class="mat-typography">
  {{ 'wc.admin.ruleSets.ruleSetWithSameExpresion.header' | translate }}

  <div class="group_parameters__item-header">
    <div class="form__row -inline">
      <label class="form__label">{{ 'wc.admin.ruleSets.name' | translate }}</label>
      <input id="003_rule_set_overwrite_modal" [value]="ruleSet.name" class="form__input" type="text" />
    </div>
  </div>
  <div class="group_parameters__item-body">
    <div class="form__row -inline">
      <label class="form__label">{{ 'wc.admin.ruleSets.description' | translate }}</label>
      <input id="004_rule_set_overwrite_modal" [value]="ruleSet.description" class="form__input" type="text" />
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button color="primary" mat-raised-button id="005_rule_set_overwrite_modal" (click)="overwrite()" [routerLink]="">
    {{ 'wc.admin.ruleSets.overwrite' | translate }}
  </button>
  <button color="primary" mat-raised-button id="006_rule_set_overwrite_modal" (click)="cancel()" [routerLink]="">
    {{ 'wc.admin.ruleSets.cancel' | translate }}
  </button>
</mat-dialog-actions>
