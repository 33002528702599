<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'id'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.resource.resources'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            [formControlName]="'type'"
            [options]="resourceType"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.resource.resources'">
          </app-input-select-form-field>
          <app-input-select-form-field
            [formControlName]="'category'"
            [options]="resourceCategories"
            [labelOption]="'label'"
            [valueOption]="'value'"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.resource.resources'">
          </app-input-select-form-field>
          <app-custom-input
            [controlName]="'name'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.resource.resources'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            [formControlName]="'resourceStatus'"
            [options]="resourceStates"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.resource.resources'">
          </app-input-select-form-field>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-input-select-form-field
          [formControlName]="'operationalState'"
          [options]="operationStates"
          [showEmptyOption]="true"
          [translationPrefix]="'wc.admin.resource.resources'">
        </app-input-select-form-field>
        <app-input-select-form-field
          [formControlName]="'usageState'"
          [options]="usagesStates"
          [showEmptyOption]="true"
          [translationPrefix]="'wc.admin.resource.resources'">
        </app-input-select-form-field>
        <app-input-select-form-field
          [formControlName]="'administrativeState'"
          [options]="administrativeStates"
          [showEmptyOption]="true"
          [translationPrefix]="'wc.admin.resource.resources'">
        </app-input-select-form-field>
        <app-custom-input
          [controlName]="'paramName'"
          [inputType]="'text'"
          [translationPrefix]="'wc.admin.resource.resources'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'paramValue'"
          [inputType]="'text'"
          [translationPrefix]="'wc.admin.resource.resources'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'placeId'"
          [inputType]="'text'"
          [translationPrefix]="'wc.admin.resource.resources'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'partyId'"
          [inputType]="'text'"
          [translationPrefix]="'wc.admin.resource.resources'"
          [parentGroup]="filterForm">
        </app-custom-input>
      </div>
    </div>
  </mat-expansion-panel>
</form>
