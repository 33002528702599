import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { FormUtils } from '@btl/btl-fe-wc-common';
import {
  ContentWidgetDto,
  ContentWidgetLocalizedParameterDto,
  ContentWidgetParameterDto,
  WidgetParameterMetaDto,
  WidgetTemplateDto,
  WidgetTemplateParameterDto,
} from '@btl/admin-bff';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from '@environments/environment';
import { CmsContentEditComponent } from '@components/cms/contents/edit/cms-content-edit.component';

@Component({
  selector: 'app-cms-content-widget-modal-new',
  templateUrl: './cms-content-widget-modal.component.html',
  styleUrls: ['./cms-content-widget-modal.component.scss'],
})
export class CmsContentWidgetModalComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  dialogRef;

  @Input()
  currentLocale: string;

  @Input()
  widgetTemplateDto: WidgetTemplateDto;

  @Input()
  contentWidgetDto: ContentWidgetDto;

  @Input()
  contentWidgetDtos: ContentWidgetDto[];

  @Input()
  itemPosition: number;

  @Input()
  totalItems: number;

  @Input()
  isNewWidget: boolean;

  @Input()
  displayControls: boolean;

  @Input()
  extContent: boolean;

  @Output()
  readonly saveWidgetEvent: EventEmitter<ContentWidgetDto> = new EventEmitter<any>();

  @Output()
  readonly newPositionEvent: EventEmitter<string> = new EventEmitter();

  @Output()
  readonly cancelWidgetEvent: EventEmitter<any> = new EventEmitter();

  @Output()
  readonly deleteWidgetEvent: EventEmitter<number> = new EventEmitter();

  widgetForm: FormGroup = this.formBuilder.group({
    widgetTemplateType: [{ value: null, disabled: true }],
    name: [null, [Validators.required]],
    parameters: this.formBuilder.array([]),
  });

  modalEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '300px',
    minHeight: '100px',
    maxHeight: '300px',
    placeholder: 'Enter text here...',
    translate: 'no',
    sanitize: false,
    toolbarPosition: 'top',
    uploadUrl: '/api/bff/admin/v1/files/content',
    toolbarHiddenButtons: [
      ['insertVideo', 'fontName', 'fontSize', 'subscript', 'superscript', 'strikeThrough', 'customClasses'],
    ],
  };
  widgetParameterMeta: CustomMetaParam[] = [];
  widgetMultiValueCount = 1;
  widgetMultiValueMap: MultiValueIndexes;
  widgetMultiValueCountArray: number[];
  initialized = false;
  locales = environment.localization.locales;
  multiParamVisibleIndexes: number[] = [];

  getValidatorsForParameter(param: WidgetTemplateParameterDto): Validators[] {
    const validators = [];
    if (param.isMandatory && this.checkMandatoryFields()) {
      validators.push(Validators.required);
    }
    if (param.validationRegExp) {
      validators.push(Validators.pattern(param.validationRegExp));
    }
    return validators;
  }

  get typeFormControl(): AbstractControl {
    return this.widgetForm.get('widgetTemplateType');
  }

  get nameFormControl(): AbstractControl {
    return this.widgetForm.get('name');
  }

  get parametersFormArray(): FormArray {
    return this.widgetForm.get('parameters') as FormArray;
  }

  constructor(private formBuilder: FormBuilder, private confirmationDialogService: ConfirmationDialogService) {}

  ngOnInit(): void {
    this.initCustomMetaParam();

    // init Form
    if (this.isNewWidget) {
      // if is new parameter from template
      this.updateFormFromTemplateWidget();
    } else {
      // if editing exist parameter
      this.updateFormFromContentWidget();
    }
    this.initialized = true;
  }

  checkMandatoryFields() {
    const validate = !(this.extContent && this.widgetTemplateDto.contentTemplateType === 'BANNER');
    return validate;
  }

  save(): void {
    FormUtils.validateAllFormFields(this.widgetForm);

    if (!this.isValidLocalizedParam()) {
      FormUtils.validateAllFormFields(this.widgetForm);
      return;
    }

    if (this.widgetForm.valid) {
      const formData = this.widgetForm.getRawValue();
      const contentWidgetDto1: ContentWidgetDto = {
        widgetTemplateType: formData.widgetTemplateType,
        name: formData.name,
        sequence: this.itemPosition,
        parameters: [],
        localizedParameters: [],
      };
      let paramIndex = 0;
      for (const paramParent of formData.parameters) {
        let index = 0;
        let indexLocal = 0;
        for (let i = 0; i < paramParent.length; i++) {
          if (
            this.widgetMultiValueMap.singleValueIndexes.includes(paramIndex) ||
            this.multiParamVisibleIndexes.includes(i)
          ) {
            const param = paramParent[i];
            if (!param.isStatic) {
              if (param.localizedParameters) {
                const localizedValues = {};
                let save = false;
                Object.keys(param.localizedParameters).forEach(key => {
                  localizedValues[key] = param.localizedParameters[key];
                  if (param.localizedParameters[key]) {
                    save = true;
                  }
                });
                if (save) {
                  contentWidgetDto1.localizedParameters.push({
                    name: param.name,
                    localizedValues: localizedValues,
                    seq: indexLocal++,
                  });
                }
              } else {
                const widgetTemplateParameterDto = this.widgetTemplateDto.parameters.find(
                  filterParam => filterParam.name === param.name
                );
                if (widgetTemplateParameterDto) {
                  let value = param.value;
                  if (widgetTemplateParameterDto.type === 'BOOLEAN') {
                    value = param.value ? 'true' : 'false';
                  }
                  contentWidgetDto1.parameters.push({
                    name: param.name,
                    value: value,
                    seq: index++,
                  });
                }
              }
            }
          }
        }
        paramIndex++;
      }
      if (this.isValidName(contentWidgetDto1.name)) {
        this.saveWidgetEvent.emit(contentWidgetDto1);
        this.dialogRef.close();
      }
    }
  }

  cancel(): void {
    if (!this.displayControls) {
      this.dialogRef.close();
    } else {
      const confirmationDialogComponent = this.confirmationDialogService.openDialog([
        'wc.admin.cms.content.widget.modal.cancel.confirmation.text',
      ]);
      confirmationDialogComponent.confirmationHandler = dialogReference => {
        // reset form to template
        this.cancelWidgetEvent.emit();
        confirmationDialogComponent.dialogReference.close();
        this.dialogRef.close();
      };
    }
  }

  delete(): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.cms.content.widget.modal.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      if (!this.isNewWidget) {
        this.deleteWidgetEvent.emit(this.itemPosition);
      }
      confirmationDialogComponent.dialogReference.close();
      this.dialogRef.close();
    };
  }

  up(): void {
    this.itemPosition = this.itemPosition - 1;
    if (!this.isNewWidget) {
      this.newPositionEvent.emit('up');
    }
  }

  down(): void {
    this.itemPosition = this.itemPosition + 1;
    if (!this.isNewWidget) {
      this.newPositionEvent.emit('down');
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private isValidName(name: string): boolean {
    let item = undefined;
    if (this.isNewWidget) {
      item = this.contentWidgetDtos.find(item => item.name === this.nameFormControl.value);
    } else {
      item = this.contentWidgetDtos.find(
        item => item.name === this.nameFormControl.value && item.sequence !== this.contentWidgetDto.sequence
      );
    }

    if (item !== undefined) {
      this.nameFormControl.setErrors({ unique: true });
      return false;
    } else if (!item && name.trim().length === 0 && !this.checkMandatoryFields()) {
      this.nameFormControl.setErrors({ required: true });
      this.widgetForm.get('name').setValue('');
      return false;
    } else if (!item && name.trim().length > 30) {
      this.nameFormControl.setErrors({ maxLength: true });
      return false;
    }
    return true;
  }

  isValidLocalizedParam(): boolean {
    const formData = this.widgetForm.getRawValue();
    let isValid = true;
    (this.widgetForm.get('parameters') as FormArray).controls.forEach((parametersFormArray: FormArray) => {
      parametersFormArray.controls.forEach((parameterForm: FormGroup) => {
        const localizedParametersForm = parameterForm.get('localizedParameters');

        if (parameterForm.enabled && localizedParametersForm && localizedParametersForm.enabled) {
          const keys = [];
          this.locales.forEach(local => {
            const value = localizedParametersForm.get(local).value;
            if (localizedParametersForm.get(local).enabled && (!value || value.trim() === '')) {
              keys.push(local);
            }
          });
          const mandatory = localizedParametersForm.parent.get('mandatory').value && this.checkMandatoryFields();
          if (keys.length > 0 && keys.length < this.locales.length) {
            isValid = false;
            keys.forEach(key => {
              localizedParametersForm.get(key).setValidators([Validators.required]);
              localizedParametersForm.get(key).setErrors({ required: true });
            });
            localizedParametersForm.updateValueAndValidity();
          } else if (!mandatory) {
            keys.forEach(key => {
              localizedParametersForm.get(key).clearValidators();
              localizedParametersForm.get(key).setErrors(null, { emitEvent: false });
            });
            localizedParametersForm.updateValueAndValidity();
          }
        }
      });
    });
    return isValid;
  }

  /**
   * Method for removing errors on optional params after value is changed.
   */
  clearErrorsOnOptionalParams(): void {
    const formData = this.widgetForm.getRawValue();
    for (let i = 0; i < formData.parameters.length; i++) {
      for (let j = 0; j < formData.parameters[i].length; j++) {
        const param = formData.parameters[i][j];
        const valueControl = ((this.widgetForm.get('parameters') as FormArray).at(i) as FormArray).at(j).get('value');
        if ((!param.mandatory || !this.checkMandatoryFields()) && !param.validationRegExp) {
          valueControl.setErrors(null, { emitEvent: false });
          valueControl.clearValidators();
        }
      }
    }
  }

  addMultiValueGroup(controlIndex: number, countIndex: number, multi: boolean = false): void {
    if (multi && !this.multiParamVisibleIndexes.includes(countIndex)) {
      this.multiParamVisibleIndexes.push(countIndex);
    }
    if (controlIndex !== null) {
      const formArray: FormArray = <FormArray>this.parametersFormArray.controls[controlIndex];
      let formGroup = formArray.at(countIndex);
      if (!formGroup) {
        formGroup = this.addNewFormGroup(formGroup, countIndex);
      }
      if (formGroup.get('localizedParameters')) {
        this.locales.forEach(local => {
          const mandatory = formGroup.get('localizedParameters').parent.get('mandatory').value;
          formGroup
            .get('localizedParameters')
            .get(local)
            .setValidators(mandatory ? [Validators.required] : []);
          formGroup.get('localizedParameters').get(local).enable();
        });
      } else {
        if (formGroup.value || this.checkMandatoryFields()) {
          formGroup.get('value').setValidators(formGroup.get('mandatory').value ? [Validators.required] : []);
        }
        formGroup.get('value').enable();
      }
    } else {
      this.parametersFormArray.controls
        .filter((formArray: FormArray, index: number) =>
          CmsContentEditComponent.getBoolean(this.widgetParameterMeta[index].isMultivalue)
        )
        .forEach((formArray: FormArray) => {
          let formGroup = formArray.at(countIndex);
          if (!formGroup) {
            formGroup = this.addNewFormGroup(formGroup, countIndex);
          }
          if (formGroup.get('localizedParameters')) {
            this.locales.forEach(local => {
              const mandatory = formGroup.get('localizedParameters').parent.get('mandatory').value;
              formGroup
                .get('localizedParameters')
                .get(local)
                .setValidators(mandatory ? [Validators.required] : []);
              formGroup.get('localizedParameters').get(local).enable();
            });
          } else {
            if (formGroup.value || this.checkMandatoryFields()) {
              formGroup.get('value').setValidators(formGroup.value.mandatory ? [Validators.required] : []);
            }
            formGroup.get('value').enable();
          }
        });
    }
  }

  private addNewFormGroup(formGroup, countIndex: number) {
    this.widgetTemplateDto.parameters.forEach((param: WidgetTemplateParameterDto, index: number) => {
      if (
        this.widgetParameterMeta[index] &&
        CmsContentEditComponent.getBoolean(this.widgetParameterMeta[index].isMultivalue)
      ) {
        const validators = this.getValidatorsForParameter(this.widgetTemplateDto.parameters[index]);
        const existingFormArray: FormArray = <FormArray>this.parametersFormArray.controls[index];
        formGroup = this.getParameterForGroup(param, countIndex, validators, true);
        existingFormArray.push(formGroup);
      }
    });
    this.widgetMultiValueCountArray.push(countIndex);
    return formGroup;
  }

  removeMultiValueGroup(countIndex: number): void {
    if (this.multiParamVisibleIndexes.includes(countIndex)) {
      this.multiParamVisibleIndexes.splice(this.multiParamVisibleIndexes.indexOf(countIndex), 1);
    }

    this.parametersFormArray.controls
      .map((formArray: FormArray): FormGroup => formArray.at(countIndex) as FormGroup)
      .filter((formGroup: FormGroup): boolean => !!formGroup?.get('isMultivalue')?.value)
      .forEach((formGroup: FormGroup) => {
        if (formGroup.get('localizedParameters')) {
          this.locales.forEach(local => {
            formGroup.get('localizedParameters').get(local).setValue('');
            formGroup.get('localizedParameters').get(local).clearValidators();
            formGroup.get('localizedParameters').get(local).updateValueAndValidity();
            formGroup.get('localizedParameters').get(local).disable();
          });
        } else {
          formGroup.get('value').setValue('');
          formGroup.get('value').clearValidators();
          formGroup.get('value').updateValueAndValidity();
          formGroup.get('value').disable();
        }
      });
  }

  /**
   * Init custom meta param for better work in html with meta attributes.
   * Index in this array is equal to index in WidgetTemplateDto.parameters array
   * widgetParameterMeta = CustomMetaParam[]: [{
   *     isMultivalue?: boolean;
   *     maxValueCount?: number;
   *     guiVisible?: boolean;
   *     guiElementType?: string;
   * }, ...]
   */
  private initCustomMetaParam(): void {
    this.widgetTemplateDto.parameters = this.widgetTemplateDto.parameters || [];
    //multi params must be first in order to be correctly indexed from 0
    this.widgetTemplateDto.parameters = this.sortMultiValueFirst(this.widgetTemplateDto.parameters);
    this.widgetTemplateDto.parameters.forEach((param: WidgetTemplateParameterDto) => {
      let metaParam = null;
      if (param.metaParameters) {
        metaParam = {};
        param.metaParameters.forEach((widgetParameterMetaDto: WidgetParameterMetaDto) => {
          let value;
          switch (widgetParameterMetaDto.name) {
            case 'isMultivalue':
            case 'guiVisible':
              value = widgetParameterMetaDto.value === 'true';
              break;
            default:
              value = widgetParameterMetaDto.value;
              break;
          }
          metaParam[widgetParameterMetaDto.name] = value;
        });
      }
      this.widgetParameterMeta.push(metaParam);
    });
  }

  private sortMultiValueFirst(parameters: Array<WidgetTemplateParameterDto>) {
    return parameters.sort((a, b) => {
      if (
        a.metaParameters.some(meta => meta.name === 'isMultivalue' && meta.value === 'true') &&
        !b.metaParameters.some(meta => meta.name === 'isMultivalue' && meta.value === 'true')
      ) {
        return -1;
      } else if (
        !a.metaParameters.some(meta => meta.name === 'isMultivalue' && meta.value === 'true') &&
        b.metaParameters.some(meta => meta.name === 'isMultivalue' && meta.value === 'true')
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  /**
   * Custom method to create parameter FormArray from ContentWidgetDto.
   * Because in ContentWidgetDto.parameters are together simple params with multivalue params, its hard to group
   * this multivalue params (params with seq attribute) in html. Also we create formArray which represents parameters,
   * which contains another array with FormGroup for parameter values.
   * If is simple param (WidgetParameterMetaDto.isMultivalue === false), then is in array only one FormGroup with parameter values;
   * if isMultivalue param then is there WidgetParameterDto.maxMultiValueCount FormGroups with repeated param.
   */
  private updateFormFromContentWidget(): void {
    this.typeFormControl.setValue(this.contentWidgetDto.widgetTemplateType);
    this.nameFormControl.setValue(this.contentWidgetDto.name);
    let maxValueCount = 1;

    const maxMultiValueCount = this.widgetTemplateDto.parameters.find(param => param.name === 'maxMultiValueCount');
    if (maxMultiValueCount) {
      maxValueCount = Number(maxMultiValueCount.value);
    }
    this.widgetTemplateDto.parameters.forEach((templateParam: WidgetTemplateParameterDto, index: number) => {
      const validators = this.getValidatorsForParameter(this.widgetTemplateDto.parameters[index]);
      const paramArray: FormArray = this.formBuilder.array([]);

      if (
        this.widgetParameterMeta[index] &&
        CmsContentEditComponent.getBoolean(this.widgetParameterMeta[index].isMultivalue)
      ) {
        this.widgetMultiValueCount = maxValueCount;
      }

      if (!templateParam.localized) {
        let contentWidgetParameters: ContentWidgetParameterDto[] = this.contentWidgetDto.parameters.filter(
          item => item.name === templateParam.name
        );

        if (!contentWidgetParameters || contentWidgetParameters.length === 0) {
          contentWidgetParameters = [
            {
              name: templateParam.name,
              value: templateParam.value,
              seq: null,
            },
          ];
        }

        if (maxValueCount === -1) {
          maxValueCount = contentWidgetParameters.length;
        }

        if (
          this.widgetParameterMeta[index].isMultivalue &&
          maxValueCount &&
          contentWidgetParameters.length < maxValueCount
        ) {
          for (let i = contentWidgetParameters.length; i < maxValueCount; i++) {
            contentWidgetParameters.push({
              name: templateParam.name,
              value: templateParam.value,
              seq: null,
            });
          }
        }

        contentWidgetParameters.forEach((contentWidgetParameter: ContentWidgetParameterDto) => {
          paramArray.push(
            this.getParamFormGroup(
              templateParam,
              contentWidgetParameter,
              validators,
              this.widgetParameterMeta[index] &&
              CmsContentEditComponent.getBoolean(this.widgetParameterMeta[index].isMultivalue)
            )
          );

          if (!maxValueCount) {
            this.widgetMultiValueCount =
              this.widgetMultiValueCount < contentWidgetParameter.seq
                ? contentWidgetParameter.seq + 1
                : this.widgetMultiValueCount;
          }
        });
      } else {
        let contentWidgetLocalParameters: ContentWidgetLocalizedParameterDto[] =
          this.contentWidgetDto.localizedParameters.filter(item => item.name === templateParam.name);

        if (!contentWidgetLocalParameters || contentWidgetLocalParameters.length === 0) {
          const localizedValues = {};
          this.locales.forEach(local => {
            localizedValues[local] = null;
          });
          contentWidgetLocalParameters = [
            {
              name: templateParam.name,
              localizedValues: localizedValues,
              seq: null,
            },
          ];
        }

        if (maxValueCount === -1) {
          maxValueCount = contentWidgetLocalParameters.length;
        }

        if (this.widgetParameterMeta[index].isMultivalue && maxValueCount && contentWidgetLocalParameters.length < maxValueCount) {
          const localizedValues = {};
          this.locales.forEach(local => {
            localizedValues[local] = null;
          });

          for (let i = contentWidgetLocalParameters.length; i < maxValueCount; i++) {
            contentWidgetLocalParameters.push({
              name: templateParam.name,
              localizedValues: localizedValues,
              seq: null,
            });
          }
        }

        contentWidgetLocalParameters.forEach((contentWidgetLocalizedParameter: ContentWidgetLocalizedParameterDto) => {
          const formGroup = this.getParamFormGroup(
            templateParam,
            contentWidgetLocalizedParameter,
            validators,
            this.widgetParameterMeta[index] &&
            CmsContentEditComponent.getBoolean(this.widgetParameterMeta[index].isMultivalue)
          );

          if (!maxValueCount) {
            this.widgetMultiValueCount =
              this.widgetMultiValueCount < contentWidgetLocalizedParameter.seq
                ? contentWidgetLocalizedParameter.seq + 1
                : this.widgetMultiValueCount;
          }

          this.getLocalParamFormGroup(formGroup, contentWidgetLocalizedParameter.localizedValues, templateParam.isMandatory);
          paramArray.push(formGroup);
        });
      }

      this.parametersFormArray.push(paramArray);
    });
    this.initMultiValueParams(maxValueCount);
  }

  /**
   * Custom method to create parameter FormArray from WidgetTemplateDto.
   * Because in WidgetTemplateDto.parameters are only one parameter with multivalue info in metaParameters,
   * we must create every FormGroup for given count (WidgetParameterDto.maxMultiValueCount).
   * Also we create formArray which represents parameters, which contains another array with FormGroup for parameter values.
   * If is simple param (WidgetParameterMetaDto.isMultivalue === false), then is in array only one FormGroup with parameter values;
   * if isMultivalueparam then is there WidgetParameterDto.maxMultiValueCount FormGroups with repeated param.
   */
  private updateFormFromTemplateWidget(): void {
    this.typeFormControl.setValue(this.widgetTemplateDto.type);
    this.nameFormControl.setValue(null);
    this.widgetTemplateDto.parameters.forEach((param: WidgetTemplateParameterDto, index: number) => {
      const validators = this.getValidatorsForParameter(this.widgetTemplateDto.parameters[index]);
      const paramArray: FormArray = this.formBuilder.array([]);

      if (
        this.widgetParameterMeta[index] &&
        CmsContentEditComponent.getBoolean(this.widgetParameterMeta[index].isMultivalue)
      ) {
        let maxValueCount = 1;
        const maxMultiValueCount = this.widgetTemplateDto.parameters.find(param => param.name === 'maxMultiValueCount');
        if (maxMultiValueCount) {
          maxValueCount = Number(maxMultiValueCount.value) === -1 ? 1 : Number(maxMultiValueCount.value);
          this.widgetMultiValueCount = Number(maxMultiValueCount.value);
        }

        for (let i = 0; i < maxValueCount; i++) {
          paramArray.push(this.getParameterForGroup(param, i, validators, true));
        }
      } else {
        paramArray.push(this.getParameterForGroup(param, null, validators));
      }
      this.parametersFormArray.push(paramArray);
    });
    this.initMultiValueParams();
  }

  getParameterForGroup(templateParam: WidgetTemplateParameterDto, seq, validators, isMultivalue = false) {

    const formGroup = this.getParamFormGroup(templateParam, {
      seq: seq,
      value: templateParam.value
    }, validators, isMultivalue);
    if (templateParam.localized) {
      this.getLocalParamFormGroup(formGroup, null, templateParam.isMandatory);
    }

    return formGroup;
  }

  private getParamFormGroup(
    param: WidgetTemplateParameterDto,
    contentWidgetParameter: ContentWidgetParameterDto,
    validators: Validators[],
    isMultivalue = false
  ): FormGroup {
    return this.formBuilder.group({
      name: [{value: param.name, disabled: true}],
      value: [{value: contentWidgetParameter.value || null, disabled: param.isStatic}, validators],
      seq: [{value: contentWidgetParameter.seq || 0, disabled: true}],
      mandatory: [{value: param.isMandatory && this.checkMandatoryFields(), disabled: true}],
      validationRegExp: [{value: param.validationRegExp, disabled: true}],
      isMultivalue: [{value: isMultivalue, disabled: true}],
      typeDetail: [{value: param.typeDetail || '', disabled: true}],
      isStatic: [{value: param.isStatic, disabled: true}],
    });
  }

  private getLocalParamFormGroup(formGroup: FormGroup, localizedValues: any, isMandatory: boolean): void {
    const localizedParametersGroup = this.formBuilder.group({});
    this.locales.forEach((localization: string) => {
      localizedParametersGroup.addControl(
        localization,
        new FormControl(
          !localizedValues ? null : localizedValues[localization],
          isMandatory ? [Validators.required] : []
        )
      );
    });
    formGroup.addControl('localizedParameters', localizedParametersGroup);
    formGroup.get('value').disable(); //done
  }

  /**
   * Method for groups parameters to single or multi value parameters.
   * It is for rendering multi value parameters in group by sequence number.
   */
  private initMultiValueParams(itemsCount?): void {
    this.widgetMultiValueMap = {
      singleValueIndexes: [],
      multiValueIndexes: [],
    };
    const parametersArray = this.parametersFormArray.getRawValue();
    this.widgetMultiValueCountArray = Array.from(
      Array(this.widgetMultiValueCount === -1 ? itemsCount : this.widgetMultiValueCount).keys()
    );

    const filledValues = [];
    parametersArray.forEach((params: any[], index: number) => {
      const formParameterFilledValues = [];
      for (let i = 0; i < params.length; i++) {
        if (params[i].value || params[i].localizedParameters) {
          if (formParameterFilledValues[i]) {
            formParameterFilledValues[i] = formParameterFilledValues[i] && this.isFilledValue(params[i]);
          } else {
            formParameterFilledValues[i] = this.isFilledValue(params[i]);
          }
        } else {
          formParameterFilledValues[i] = false;
        }
        if (!CmsContentEditComponent.getBoolean(params[i].isMultivalue)) {
          this.widgetMultiValueMap.singleValueIndexes.push(index);
        } else if (!this.widgetMultiValueMap.multiValueIndexes.includes(index)) {
          this.widgetMultiValueMap.multiValueIndexes.push(index);
        }
      }
      filledValues[index] = formParameterFilledValues;
    });
    const filledIndex = [];
    this.widgetMultiValueMap.multiValueIndexes.forEach(index => {
      const formParameterFilledValues = filledValues[index];
      for (let i = 0; i < parametersArray[index].length; i++) {
        if (formParameterFilledValues[i]) {
          this.addMultiValueGroup(index, i, true);
          filledIndex.push(i);
        }
      }
      if (filledValues[index].every(i => i === false)) {
        this.addMultiValueGroup(index, this.widgetMultiValueMap.multiValueIndexes[0], true);
        filledIndex.push(this.widgetMultiValueMap.multiValueIndexes[0]);
      }
    });
    this.widgetMultiValueCountArray
      .filter(index => !filledIndex.includes(index) && index !== this.multiParamVisibleIndexes[0])
      .forEach(index => this.removeMultiValueGroup(index));
  }

  private isFilledValue(param: any): boolean {
    const defaultValue = this.widgetTemplateDto.parameters.find(p => p.name === param.name)?.value;
    if (param.value !== defaultValue) {
      return true;
    }
    return !!param.localizedParameters && this.isFilledLocalizedParameter(param.localizedParameters);
  }

  private isFilledLocalizedParameter(localizedParameters: any[]): boolean {
    let filledValue = true;
    this.locales.forEach(local => {
      filledValue = filledValue && !!localizedParameters[local];
    });
    return filledValue;
  }

  fullClass(type, detailType) {
    if (['CODEBOOK'].includes(type) || ('STRING' === type && ['HTML', 'IMAGE'].includes(detailType))) {
      return true;
    }
    return false;
  }
}

export interface CustomMetaParam {
  isMultivalue?: any;
  maxValueCount?: number;
  guiVisible?: boolean;
  guiElementType?: string;
}

export interface MultiValueIndexes {
  singleValueIndexes: number[];
  multiValueIndexes: number[];
}
