<div class="card__wrapper" [formGroup]="form">
  <app-form-attachments
    class="card -full"
    *ngIf="product"
    formControlName="documents"
    [control]="form.controls['documents']"
    [types]="['PC_DOCUMENT']"
    [languageChange]="true"
    [nameChange]="true"
    [textsChange]="true"
    [getFromGalleryEnabled]="true"
    [disabled]="!this.productEditService.enableEditProduct"
    extIdPrefix="PC_DOCUMENT"
    dmsType="PC_DOCUMENT"
    id="001_product_document"
    headerTranslationKey="wc.admin.product.documents.header">
  </app-form-attachments>
</div>
