import { Component } from '@angular/core';
import {
  AclService,
  AdminReleaseService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { PagedReleasesDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { ReleaseEditComponent } from '@components/product-catalogue/import-export/release-edit/release-edit.component';

import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-releases-listing',
  templateUrl: './releases-listing.component.html',
  styleUrls: ['./releases-listing.component.scss'],
})
@EnableDynamicLoading({ customName: ReleasesListingComponent.PAGE_ID })
export class ReleasesListingComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'ReleasesListingComponent';

  pageId(): string {
    return ReleasesListingComponent.PAGE_ID;
  }

  pagedReleasesDto: PagedReleasesDto;

  constructor(
    private adminReleaseService: AdminReleaseService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected confirmationDialogService: ConfirmationDialogService,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  editRelease(releaseDto, inNewTab: boolean = false) {
    this.navigateSibling(ReleaseEditComponent.PAGE_ID, { id: releaseDto.id }, inNewTab);
  }

  newRelease() {
    this.editRelease({
      id: 'newRelease',
    });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminReleaseService
        .filterReleases(this.search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedReleasesDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminReleaseService.deleteReleases(bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminReleaseService.deleteRelease(rowDataId);
  }
}
