import { Component, Input, ViewChild } from '@angular/core';
import { ProductListingComponent } from '../product-catalogue/products/product-listing/product-listing.component';

@Component({
  selector: 'app-products-select-modal',
  templateUrl: './products-select-modal.component.html',
  styleUrls: ['./products-select-modal.component.scss'],
})
export class ProductsSelectModalComponent {
  @ViewChild(ProductListingComponent, { static: true })
  productListingComponent: ProductListingComponent;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  @Input()
  productVersionSelection: boolean = false;

  excludeProductCodes = [];

  @Input()
  selectMode: boolean = true;

  @Input()
  disableActions: boolean = false;

  @Input()
  disableListingActions: boolean = false;

  @Input()
  disableExpand: boolean = false;

  @Input()
  nestedTable: boolean = false;

  @Input()
  toggleArrow: boolean = false;

  @Input()
  selectedProducts = [];

  @Input()
  isModal: boolean = false;

  selectEvent() {
    if (!this.selectMode) {
      const product = this.productListingComponent.getSelectedData()[0];
      if (product) {
        this.selectHandler(product);
      }
    } else {
      const products = this.productListingComponent.getSelectedData();
      if (products && products.length > 0) {
        this.selectHandler(products);
        this.dialogRef.close();
      } else {
        this.selectHandler(null);
        this.dialogRef.close();
      }
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  singleSelect(productDto) {
    this.selectHandler(productDto);
    this.dialogRef.close();
  }
}
