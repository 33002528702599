import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { initializer } from './app-init';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import {
  AclService,
  AdminDynamicEnumService,
  AdminErrorHandler,
  AdminHttpErrorInterceptor,
  AdminProductGroupService,
  AdminProductService,
  AppAuthGuard,
  AppBlockerService,
  AuthFactoryService,
  AuthorizationHttpInterceptor,
  BlockTemplateComponent,
  CodebookService,
  CurrentLocaleService,
  DefaultErrorHandler,
  DefaultModule,
  DynamicComponentRegistry,
  ForwardedHostHttpInterceptor,
  getCurrentHost,
  HttpLoaderFactory,
  InputSelectDynamicEnumService,
  InputSelectEnumService,
  loadLocaleData,
  LocalizationService,
  LogErrorHandler,
  MissingTranslationHandlerFactory,
  MultiTenancyInterceptor,
  NcasService,
  PricePipe,
} from '@btl/btl-fe-wc-common';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LoggerModule, NGXLogger } from 'ngx-logger';
import { environment } from '@environments/environment';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TopMenuComponent } from '@components/top-menu/top-menu.component';
import { LeftMenuComponent } from '@components/left-menu/left-menu.component';
import { CodebookComponent } from '@components/codebook/codebook.component';
import { FormTextComponent } from '@components/form-text/form-text.component';
import { ProductsComponent } from '@components/product-catalogue/products/products.component';
import { LeftMenuItemComponent } from '@components/left-menu/left-menu-item/left-menu-item.component';
import { ProductFilterComponent } from '@components/product-catalogue/products/product-filter/product-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ProductAttributesComponent,
} from '@components/product-catalogue/products/product-edit/product-attributes/product-attributes.component';
import {
  ProductListingComponent,
} from '@components/product-catalogue/products/product-listing/product-listing.component';
import { RelationshipsComponent } from '@components/product-catalogue/relationships/relationships.component';
import { ProductCatalogueComponent } from '@components/product-catalogue/product-catalogue.component';
import {
  ProductPricesComponent,
} from '@components/product-catalogue/products/product-edit/product-prices/product-prices.component';
import { ProductEditComponent } from '@components/product-catalogue/products/product-edit/product-edit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { StickersComponent } from '@components/product-catalogue/stickers/stickers.component';
import {
  ProductTextsComponent,
} from '@components/product-catalogue/products/product-edit/product-texts/product-texts.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {
  ProductPicturesComponent,
} from '@components/product-catalogue/products/product-edit/product-pictures/product-pictures.component';
import { LocalizedTextComponent } from '@components/localized-text/localized-text.component';
import { ProductGroupComponent } from '@components/product-catalogue/product-group/product-group.component';
import {
  ProductGroupListingComponent,
} from '@components/product-catalogue/product-group/product-group-listing/product-group-listing.component';
import {
  ProductGroupFilterComponent,
} from '@components/product-catalogue/product-group/product-group-filter/product-group-filter.component';
import {
  ProductGroupEditComponent,
} from '@components/product-catalogue/product-group/product-group-edit/product-group-edit.component';
import {
  GroupProductsListingComponent,
} from '@components/product-catalogue/product-group/product-group-edit/group-products-listing/group-products-listing.component';
import {
  GroupProductsListingItemComponent,
} from '@components/product-catalogue/product-group/product-group-edit/group-products-listing/group-products-listing-item/group-products-listing-item.component';
import { ProductsSelectModalComponent } from '@components/products-select-modal/products-select-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  RelationshipsFilterComponent,
} from '@components/product-catalogue/relationships/relationships-filter/relationships-filter.component';
import {
  RelationshipsListingComponent,
} from '@components/product-catalogue/relationships/relationships-listing/relationships-listing.component';
import {
  RelationshipEditComponent,
} from '@components/product-catalogue/relationships/relationship-edit/relationship-edit.component';
import {
  ProductRelationshipsComponent,
} from '@components/product-catalogue/products/product-edit/product-relationships/product-relationships.component';
import {
  ProductBundlesComponent,
} from '@components/product-catalogue/products/product-edit/product-bundles/product-bundles.component';
import {
  ProductPriceListingItemComponent,
} from '@components/product-catalogue/products/product-edit/product-prices/product-price-listing-item/product-price-listing-item.component';
import { StickerListComponent } from '@components/product-catalogue/stickers/list/sticker-list.component';
import { StickerFilterComponent } from '@components/product-catalogue/stickers/list/filter/sticker-filter.component';
import { StickerEditComponent } from '@components/product-catalogue/stickers/edit/sticker-edit.component';
import { PictureSelectorComponent } from '@components/picture-selector/picture-selector.component';
import * as orderBff from '@btl/order-bff';
import * as adminBff from '@btl/admin-bff';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import localeSl from '@angular/common/locales/sl';
import { GroupsSelectModalComponent } from '@components/groups-select-modal/groups-select-modal.component';
import { GroupPickerComponent } from '@components/group-picker/group-picker.component';
import { LocalizedTextEditComponent } from '@components/localized-text/edit/localized-text-edit.component';
import { LocalizedTextFilterComponent } from '@components/localized-text/list/filter/localized-text-filter.component';
import { LocalizedTextListComponent } from '@components/localized-text/list/localized-text-list.component';
import {
  LocalizedTextEditLocalesComponent,
} from '@components/localized-text/edit-locales/localized-text-edit-locales.component';
import {
  ProductEditGroupsComponent,
} from '@components/product-catalogue/products/product-edit/product-edit-groups/product-edit-groups.component';
import {
  ProductEditGroupsItemComponent,
} from '@components/product-catalogue/products/product-edit/product-edit-groups/item/product-edit-groups-item.component';
import {
  ProductStickersComponent,
} from '@components/product-catalogue/products/product-edit/product-stickers/product-stickers.component';
import { StickersSelectModalComponent } from '@components/stickers-select-modal/stickers-select-modal.component';
import { CategoriesComponent } from '@components/product-catalogue/categories/categories.component';
import {
  CategoriesListingComponent,
} from '@components/product-catalogue/categories/categories-listing/categories-listing.component';
import { CodebookFilterComponent } from '@components/codebook/list/filter/codebook-filter.component';
import { CodebookListComponent } from '@components/codebook/list/codebook-list.component';
import { CodebookEditComponent } from '@components/codebook/edit/codebook-edit.component';
import { CategoryEditComponent } from '@components/product-catalogue/categories/category-edit/category-edit.component';
import {
  CategoryParametersListingComponent,
} from '@components/product-catalogue/categories/category-edit/category-parameters-listing/category-parameters-listing.component';
import { LocaleTextComponent } from '@components/locale-text/locale-text.component';
import {
  ProductPriceListingComponent,
} from '@components/product-catalogue/products/product-edit/product-prices/product-price-listing/product-price-listing.component';
import {
  ProductPriceEditComponent,
} from '@components/product-catalogue/products/product-edit/product-prices/product-price-edit/product-price-edit.component';
import { DcrRulePipe } from '@pipes/dcr-rule.pipe';
import {
  ProductRatingsComponent,
} from '@components/product-catalogue/products/product-edit/product-ratings/product-ratings.component';
import {
  ProductRatingsItemComponent,
} from '@components/product-catalogue/products/product-edit/product-ratings/item/product-ratings-item.component';
import {
  ProductRatingsFilterComponent,
} from '@components/product-catalogue/products/product-edit/product-ratings/filter/product-ratings-filter.component';
import {
  ProductRatingsReviewEditComponent,
} from '@components/product-catalogue/products/product-edit/product-ratings/review-edit/product-ratings-review-edit.component';
import {
  DynamicContextRulesFilterComponent,
} from '@components/product-catalogue/dynamic-context-rules/list/filter/dynamic-context-rules-filter.component';
import {
  DynamicContextRulesListComponent,
} from '@components/product-catalogue/dynamic-context-rules/list/dynamic-context-rules-list.component';
import {
  DynamicContextRuleEditComponent,
} from '@components/product-catalogue/dynamic-context-rules/edit/dynamic-context-rule-edit.component';
import {
  DynamicContextRulesComponent,
} from '@components/product-catalogue/dynamic-context-rules/dynamic-context-rules.component';
import { RuleSetsComponent } from '@components/product-catalogue/rule-sets/rule-sets.component';
import {
  RuleSetsListingComponent,
} from '@components/product-catalogue/rule-sets/rule-sets-listing/rule-sets-listing.component';
import { RuleSetEditComponent } from '@components/product-catalogue/rule-sets/rule-set-edit/rule-set-edit.component';
import {
  RuleSetsFilterComponent,
} from '@components/product-catalogue/rule-sets/rule-sets-filter/rule-sets-filter.component';
import {
  RuleSetOverwriteModalComponent,
} from '@components/product-catalogue/rule-sets/rule-set-edit/rule-set-overwrite-modal/rule-set-overwrite-modal.component';
import { EligibilityComponent } from '@components/eligibility/eligibility.component';
import {
  ProductEligibilityComponent,
} from '@components/product-catalogue/products/product-edit/product-eligibility/product-eligibility.component';
import {
  CartEventsFilterComponent,
} from '@components/product-catalogue/cart-events/cart-events-filter/cart-events-filter.component';
import {
  CartEventsListingComponent,
} from '@components/product-catalogue/cart-events/cart-events-listing/cart-events-listing.component';
import {
  CartEventEditComponent,
} from '@components/product-catalogue/cart-events/cart-event-edit/cart-event-edit.component';
import { CartEventsComponent } from '@components/product-catalogue/cart-events/cart-events.component';
import { EligibilityExpressionComponent } from '@components/eligibility-expression/eligibility-expression.component';
import {
  ProductCartEventsComponent,
} from '@components/product-catalogue/products/product-edit/product-cartEvent/product-cart-events.component';
import { PromotionEditComponent } from '@components/product-catalogue/promotion/edit/promotion-edit.component';
import { PromotionListComponent } from '@components/product-catalogue/promotion/list/promotion-list.component';
import {
  PromotionFilterComponent,
} from '@components/product-catalogue/promotion/list/filter/promotion-filter.component';
import { PromotionComponent } from '@components/product-catalogue/promotion/promotion.component';
import { SocketEditComponent } from '@components/product-catalogue/socket/edit/socket-edit.component';
import { SocketListComponent } from '@components/product-catalogue/socket/list/socket-list.component';
import { SocketFilterComponent } from '@components/product-catalogue/socket/list/filter/socket-filter.component';
import { SocketComponent } from '@components/product-catalogue/socket/socket.component';
import { CodebookTypeListComponent } from '@components/codebook/codebookType/codebook-type-list.component';
import { CodebookTypeEditComponent } from '@components/codebook/codebookType/edit/codebook-type-edit.component';
import { TicketsComponent } from '@components/tickets/tickets.component';
import { TicketListComponent } from '@components/tickets/list/ticket-list.component';
import { TicketFilterComponent } from '@components/tickets/list/filter/ticket-filter.component';
import { TicketEditComponent } from '@components/tickets/edit/ticket-edit.component';
import { CreateTicketModalComponent } from '@components/create-ticket-modal/create-ticket-modal.component';
import {
  SocketContentsComponent,
} from '@components/product-catalogue/socket/list/socket-contents/socket-contents.component';
import {
  PromotionDesignComponent,
} from '@components/product-catalogue/promotion/edit/promotion-design/promotion-design.component';
import {
  PromotionDesignNodeComponent,
} from '@components/product-catalogue/promotion/edit/promotion-design/promotion-design-node/promotion-design-node.component';
import {
  PromotionDesignConnectionsComponent,
} from '@components/product-catalogue/promotion/edit/promotion-design/promotion-design-connections/promotion-design-connections.component';
import {
  SelectedSocketComponent,
} from '@components/product-catalogue/promotion/edit/promotion-design/selected-socket/selected-socket.component';
import {
  SelectedSocketContentComponent,
} from '@components/product-catalogue/promotion/edit/promotion-design/selected-socket-content/selected-socket-content.component';
import {
  SelectedRootProductComponent,
} from '@components/product-catalogue/promotion/edit/promotion-design/selected-root-product/selected-root-product.component';
import { SocketsSelectModalComponent } from '@components/sockets-select-modal/sockets-select-modal.component';
import { AclComponent } from '@components/acl/acl.component';
import { RolesComponent } from '@components/acl/roles/roles.component';
import { RolesListingComponent } from '@components/acl/roles/roles-listing/roles-listing.component';
import { RoleEditComponent } from '@components/acl/roles/role-edit/role-edit.component';
import { RolesFilterComponent } from '@components/acl/roles/roles-filter/roles-filter.component';
import { RulesListingComponent } from '@components/acl/roles/role-edit/rules-listing/rules-listing.component';
import {
  RulesListingItemComponent,
} from '@components/acl/roles/role-edit/rules-listing/rules-listing-item/rules-listing-item.component';
import { RulesFilterComponent } from '@components/acl/roles/role-edit/rules-filter/rules-filter.component';
import { AccountEditComponent } from '@components/account/edit/account-edit.component';
import { AccountListItemComponent } from '@components/account/list/item/account-list-item.component';
import { AccountListComponent } from '@components/account/list/account-list.component';
import { AccountFilterComponent } from '@components/account/list/filter/account-filter.component';
import { AccountComponent } from '@components/account/account.component';
import { AccountRoleListItemComponent } from '@components/account/edit/role-item/account-role-list-item.component';
import {
  AclRolesSelectModalComponent,
} from '@components/acl/components/acl-roles-select-modal/acl-roles-select-modal.component';
import { TicketTypeComponent } from '@components/tickets/ticket-type/ticket-type.component';
import { TicketTypeEditComponent } from '@components/tickets/ticket-type/edit/ticket-type-edit.component';
import { TicketTypeListComponent } from '@components/tickets/ticket-type/list/ticket-type-list.component';
import { TicketTypeFilterComponent } from '@components/tickets/ticket-type/list/filter/ticket-type-filter.component';
import {
  TicketTypeParametersComponent,
} from '@components/tickets/ticket-type/edit/parameters/ticket-type-parameters.component';
import {
  TicketTypeParametersListComponent,
} from '@components/tickets/ticket-type/edit/parameters/list/ticket-type-parameters-list.component';
import {
  TicketTypeParametersItemComponent,
} from '@components/tickets/ticket-type/edit/parameters/list/item/ticket-type-parameters-item.component';
import {
  ParameterDetailModalComponent,
} from '@components/tickets/ticket-type/edit/parameters/parameter-detail-modal/parameter-detail-modal.component';
import { AclTableComponent } from '@components/acl/components/acl-rules-table/acl-table.component';
import { AclTableListComponent } from '@components/acl/components/acl-rules-table/list/acl-table-list.component';
import {
  AclTableListItemComponent,
} from '@components/acl/components/acl-rules-table/list/item/acl-table-list-item.component';
import {
  TicketTypeStepsListItemComponent,
} from '@components/tickets/ticket-step-template/edit/steps/list/item/ticket-type-steps-list-item.component';
import {
  TicketStepTemplateStepsListComponent,
} from '@components/tickets/ticket-step-template/edit/steps/list/ticket-step-template-steps-list.component';
import { LocalizationMockedService } from '@service/localization-mocked-service';
import { RoleAccountsComponent } from '@components/acl/roles/role-edit/accounts/role-accounts.component';
import {
  ReleasesListingComponent,
} from '@components/product-catalogue/import-export/releases-listing/releases-listing.component';
import {
  ReleasesFilterComponent,
} from '@components/product-catalogue/import-export/releases-filter/releases-filter.component';
import { ReleaseEditComponent } from '@components/product-catalogue/import-export/release-edit/release-edit.component';
import {
  MembersListingComponent,
} from '@components/product-catalogue/import-export/release-edit/members-listing/members-listing.component';
import {
  MembersListingItemComponent,
} from '@components/product-catalogue/import-export/release-edit/members-listing/members-listing-item/members-listing-item.component';
import { StickerPickerComponent } from '@components/sticker-picker/sticker-picker.component';
import { PromotionsSelectModalComponent } from '@components/promotions-select-modal/promotions-select-modal.component';
import { PromotionPickerComponent } from '@components/promotion-picker/promotion-picker.component';
import {
  RulesSelectModalComponent,
} from '@components/acl/components/acl-rules-select-modal/rules-select-modal.component';
import { RulePickerComponent } from '@components/acl/components/acl-rule-picker/rule-picker.component';
import { CategoriesSelectModalComponent } from '@components/categories-select-modal/categories-select-modal.component';
import { AccountEntityEditComponent } from '@components/account-entity/edit/account-entity-edit.component';
import { AccountEntityListComponent } from '@components/account-entity/list/account-entity-list.component';
import { AccountEntityFilterComponent } from '@components/account-entity/list/filter/account-entity-filter.component';
import { AccountEntityComponent } from '@components/account-entity/account-entity.component';
import { AccountsSelectModalComponent } from '@components/account-select-modal/accounts-select-modal.component';
import { AccountPickerComponent } from '@components/account-picker/account-picker.component';
import {
  BulkUpdateAccountEntityRolesModalComponent,
} from '@components/account-entity/list/bulk-update-account_entity-roles-modal/bulk-update-account-entity-roles-modal.component';
import {
  EditStepModalComponent,
} from '@components/tickets/ticket-step-template/edit/steps/edit-step-modal/edit-step-modal.component';
import { TicketStepTemplateComponent } from '@components/tickets/ticket-step-template/ticket-step-template.component';
import {
  TicketStepTemplateListComponent,
} from '@components/tickets/ticket-step-template/list/ticket-step-template-list.component';
import {
  TicketStepTemplateFilterComponent,
} from '@components/tickets/ticket-step-template/list/filter/ticket-step-template-filter.component';
import {
  TicketStepTemplateEditComponent,
} from '@components/tickets/ticket-step-template/edit/ticket-step-template-edit.component';
import { StepTemplatePickerComponent } from '@components/step-template-picker/step-template-picker.component';
import {
  StepTemplateSelectModalComponent,
} from '@components/step-template-select-modal/step-template-select-modal.component';
import { FormParametersComponent } from '@components/form-parameters/form-parameters.component';
import {
  ProductAclComponent,
} from '@components/product-catalogue/products/product-edit/product-acl/product-acl.component';
import {
  ProductDocumentsComponent,
} from '@components/product-catalogue/products/product-edit/product-documents/product-documents.component';
import { DmsFilesSelectModalComponent } from '@components/dms-file-select-modal/dms-files-select-modal.component';
import { DmsFilesFilterComponent } from '@components/dms-files-listing/dms-files-filter/dms-files-filter.component';
import {
  DmsFilesListingItemComponent,
} from '@components/dms-files-listing/dms-files-listing-item/dms-files-listing-item.component';
import { DmsFilesListingComponent } from '@components/dms-files-listing/dms-files-listing.component';
import { FormTextsComponent } from '@components/form-texts/form-texts.component';
import { CmsContentsComponent } from '@components/cms/contents/cms-contents.component';
import { CmsContentsListComponent } from '@components/cms/contents/list/cms-contents-list.component';
import { CmsContentsItemComponent } from '@components/cms/contents/list/item/cms-contents-item.component';
import { CmsContentsFilterComponent } from '@components/cms/contents/filter/cms-contents-filter.component';
import { CmsContentEditComponent } from '@components/cms/contents/edit/cms-content-edit.component';
import { CmsContentVersionsComponent } from '@components/cms/contents/list/version-list/cms-content-versions.component';
import {
  CmsContentVersionsItemComponent,
} from '@components/cms/contents/list/version-list/version-list-item/cms-content-versions-item.component';
import { CmsContentWidgetComponent } from '@components/cms/contents/edit/widget/cms-content-widget.component';
import {
  CmsContentWidgetModalComponent,
} from '@components/cms/contents/edit/widget/widget-modal/cms-content-widget-modal.component';
import {
  CmsContentWidgetModalControlsComponent,
} from '@components/cms/contents/edit/widget/widget-modal/controls/cms-content-widget-modal-controls.component';
import {
  CmsContentPreviewModalComponent,
} from '@components/cms/contents/edit/widget/preview-modal/cms-content-preview-modal.component';
import {
  CmsContentBannerModalComponent,
} from '@components/cms/contents/edit/widget/banner-modal/cms-content-banner-modal.component';
import {
  CmsContentBannerFilterComponent,
} from '@components/cms/contents/edit/widget/banner-modal/filter/cms-content-banner-filter.component';
import {
  CmsContentBannerItemComponent,
} from '@components/cms/contents/edit/widget/banner-modal/item/cms-content-banner-item.component';
import { FormAttachmentsComponent } from '@components/form-attachments/form-attachments.component';
import { ReleasesSelectModalComponent } from '@components/releases-select/releases-select-modal.component';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LayoutModule } from '@angular/cdk/layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InnerLeftMenuComponent } from '@components/left-menu/inner-left-menu/inner-left-menu.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuickActionsComponent } from '@components/dashboard/quick-actions/quick-actions.component';
import { TicketListsComponent } from '@components/dashboard/ticket-lists/ticket-lists.component';
import {
  TotalConfirmedOrdersComponent,
} from '@components/dashboard/total-confirmed-orders/total-confirmed-orders.component';
import { TopProductsComponent } from '@components/dashboard/top-products/top-products.component';
import { LastChangesComponent } from '@components/dashboard/last-changes/last-changes.component';
import { MenuItemComponent } from '@components/top-menu/menu-item/menu-item.component';
import {
  TicketMiniListComponent,
} from '@components/dashboard/ticket-lists/ticket-mini-list/ticket-mini-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import {
  InputDateFormFieldComponent,
} from '@components/input-form/input-date-form-field/input-date-form-field.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TableListingFormComponent } from '@components/table-listing-form/table-listing-form.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule ,
} from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatConfirmationDialogComponent } from '@components/mat-confirmation-dialog/mat-confirmation-dialog.component';
import {
  InputPickerFormFieldComponent,
} from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { CmsContentParameterComponent } from '@components/cms/contents/edit/parameter/cms-content-parameter.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  CmsContentWidgetAddModalComponent,
} from '@components/cms/contents/edit/widget/widget-add-modal/cms-content-widget-add-modal.component';
import {
  CmsContentBannerAddModalComponent,
} from '@components/cms/contents/edit/widget/banner-add-modal/cms-content-banner-add-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BlockUiComponent } from '@components/block-ui/block-ui.component';
import {
  GroupSubsetProductsComponent,
} from '@components/product-catalogue/product-group/product-group-edit/group-products-listing/group-subset-products/group-subset-products.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FilesAddModalComponent } from '@components/files-add-modal/files-add-modal.component';
import { FormPicturesComponent } from '@components/form-pictures/form-pictures.component';
import { ExportComponent } from '@components/import-export/export/export.component';
import { ErrorPageComponent } from '@components/error-page/error-page.component';
import { NotificationComponent } from '@components/notification/notification.component';
import {
  NotificationNotificationsComponent,
} from '@components/notification/notifications/notification-notifications.component';
import { NotificationTypesComponent } from '@components/notification/types/notification-types.component';
import { NotificationGatewaysComponent } from '@components/notification/gateways/notification-gateways.component';
import { NotificationTypeListComponent } from '@components/notification/types/list/notification-type-list.component';
import {
  NotificationTypeFilterComponent,
} from '@components/notification/types/filter/notification-type-filter.component';
import {
  NotificationGatewayListComponent,
} from '@components/notification/gateways/list/notification-gateway-list.component';
import {
  NotificationGatewayFilterComponent,
} from '@components/notification/gateways/filter/notification-gateway-filter.component';
import {
  NotificationGatewayEditComponent,
} from '@components/notification/gateways/edit/notification-gateway-edit.component';
import { NotificationTypeEditComponent } from '@components/notification/types/edit/notification-type-edit.component';
import {
  NotificationTypeTemplateComponent,
} from '@components/notification/types/edit/template/notification-type-template.component';
import {
  NotificationTypeLocaleModalComponent,
} from '@components/notification/types/edit/local-modal/notification-type-locale-modal.component';
import {
  NotificationTypeGatewaysModalComponent,
} from '@components/notification/types/edit/gateways-modal/notification-type-gateways-modal.component';
import {
  NotificationTypeAttachmentModalComponent,
} from '@components/notification/types/edit/attachment-modal/notification-type-attachment-modal.component';
import {
  NotificationNotificationsListComponent,
} from '@components/notification/notifications/list/notification-notifications-list.component';
import {
  NotificationNotificationsFilterComponent,
} from '@components/notification/notifications/filter/notification-notifications-filter.component';
import {
  NotificationNotificationsEditComponent,
} from '@components/notification/notifications/edit/notification-notifications-edit.component';
import { CampaignComponent } from '@components/campaign/campaign.component';
import { CampaignListComponent } from '@components/campaign/list/campaign-list.component';
import { CampaignEditComponent } from '@components/campaign/edit/campaign-edit.component';
import { CampaignFilterComponent } from '@components/campaign/list/filter/campaign-filter.component';
import { CmsSelectModalComponent } from '@components/cms-select-modal/cms-select-modal.component';
import {
  NotificationTypeSelectModalComponent,
} from '@components/notification-type-select-modal/notification-type-select-modal.component';
import { ExportsFilterComponent } from '@components/import-export/export/exports-filter/exports-filter.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportComponent } from '@components/import-export/import/import.component';
import { ImportsFilterComponent } from '@components/import-export/import/imports-filter/imports-filter.component';
import {
  TicketParameterComponent,
} from '@components/tickets/ticket-type/edit/parameters/parameter-detail-modal/ticket-parameter/ticket-parameter.component';
import {
  CategoryParameterComponent,
} from '@components/tickets/ticket-type/edit/parameters/parameter-detail-modal/category-parameter/category-parameter.component';
import {
  FormParametersEditModalComponent,
} from '@components/form-parameters-edit-modal/form-parameters-edit-modal.component';
import { CodebookTypeFilterComponent } from '@components/codebook/codebookType/filter/codebook-type-filter.component';
import { StickyMessageComponent } from './components/sticky-message/sticky-message.component';
import { AclLegendModalComponent } from './components/acl-legend-modal/acl-legend-modal.component';
import { PriviligesComponent } from '@components/acl/privileges/priviliges.component';
import { PrivilegesFilterComponent } from '@components/acl/privileges/privileges-filter/privileges-filter.component';
import { PrivilegesListingComponent } from '@components/acl/privileges/privileges-listing/privileges-listing.component';
import { PrivilegeEditComponent } from '@components/acl/privileges/privilege-edit/privilege-edit.component';
import {
  InputDragChipsFormFieldComponent,
} from './components/input-form/input-drag-chips-form-field/input-drag-chips-form-field.component';
import {
  EligibilityLegendModalComponent,
} from './components/eligibility-legend-modal/eligibility-legend-modal.component';
import { DelegateModalComponent } from './components/account-entity/delegate-modal/delegate-modal.component';
import { GroupTypeListingComponent } from '@components/product-catalogue/groupType/group-type-listing.component';
import { GroupTypeFilterComponent } from '@components/product-catalogue/groupType/filter/group-type-filter.component';
import { GroupTypeEditComponent } from '@components/product-catalogue/groupType/edit/group-type-edit.component';
import {
  DuplicateProductModalComponent,
} from '@components/product-catalogue/products/duplicate-product-modal/duplicate-product-modal.component';
import { DocumentTemplateEditComponent } from '@components/document-template/edit/document-template-edit.component';
import { DocumentTemplateListComponent } from '@components/document-template/list/document-template-list.component';
import {
  DocumentTemplateFilterComponent,
} from '@components/document-template/list/filter/document-template-filter.component';
import { DocumentTemplateComponent } from '@components/document-template/document-template.component';
import { DataTypeEnumListComponent } from '@components/data-type-enum/list/data-type-enum-list.component';
import { DataTypeEnumComponent } from '@components/data-type-enum/data-type-enum.component';
import { DataTypeEnumEditComponent } from '@components/data-type-enum/edit/data-type-enum-edit.component';
import { DataTypeEnumItemEditComponent } from '@components/data-type-enum/edit/item/data-type-enum-item-edit.component';
import { FormLocalizedTextsComponent } from '@components/form-localized-texts/form-localized-texts.component';
import { DcrComponent } from '@components/dcr/dcr.component';
import { ImportExportComponent } from '@components/import-export/import-export.component';
import { ResourcesComponent } from '@components/resource/resources.component';
import { ResourceResourcesListComponent } from '@components/resource/resources/list/resource-resources-list.component';
import {
  ResourceResourcesFilterComponent,
} from '@components/resource/resources/filter/resource-resources-filter.component';
import { ResourceResourcesEditComponent } from '@components/resource/resources/edit/resource-resources-edit.component';
import {
  ResourcesResourceRelationshipModalComponent,
} from '@components/resource/resources/edit/resource-relationship-modal/resources-resource-relationship-modal.component';
import {
  ResourcesPartyRelationModalComponent,
} from '@components/resource/resources/edit/party-relation-modal/resources-party-relation-modal.component';
import {
  ResourcesParameterModalComponent,
} from '@components/resource/resources/edit/parameter-modal/resources-parameter-modal.component';
import { StoreEditComponent } from '@components/resource/store/store-edit/store-edit.component';
import { StoresFilterComponent } from '@components/resource/store/stores-filter/stores-filter.component';
import { StoresListingComponent } from '@components/resource/store/stores-listing/stores-listing.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { StocksListingComponent } from '@components/resource/stock/stores-listing/stocks-listing.component';
import { StocksFilterComponent } from '@components/resource/stock/stores-filter/stocks-filter.component';
import { StockEditComponent } from '@components/resource/stock/store-edit/stock-edit.component';
import { StoresSelectModalComponent } from '@components/stores-select-modal/stores-select-modal.component';
import { CrmComponent } from '@components/crm/crm.component';
import {
  AgreementTypesListingComponent,
} from '@components/crm/agreement-type/agreement-type-listing/agreement-types-listing.component';
import {
  AgreementTypeEditComponent,
} from '@components/crm/agreement-type/agreement-type-edit/agreement-type-edit.component';
import {
  AgreementTypesFilterComponent,
} from '@components/crm/agreement-type/agreement-type-filter/agreement-types-filter.component';
import { FormReferencesComponent } from '@components/form-references/form-references.component';
import { MetaDescriptionComponent } from '@components/meta-description/meta-description.component';
import { MetaProfileComponent } from '@components/meta-description/meta-profile/meta-profile.component';
import { MetaProfileListComponent } from '@components/meta-description/meta-profile/list/meta-profile-list.component';
import {
  MetaProfileFilterComponent,
} from '@components/meta-description/meta-profile/list/filter/meta-profile-filter.component';
import { MetaProfileEditComponent } from '@components/meta-description/meta-profile/edit/meta-profile-edit.component';
import {
  MetaProfilesSelectModalComponent,
} from '@components/meta-profiles-select-modal/meta-profiles-select-modal.component';
import {
  RelationshipsValidToModalComponent,
} from '@components/product-catalogue/relationships/relationships-valid-to-modal/relationships-valid-to-modal.component';
import { LoginRouteComponent } from './login-route.component';
import { GeneralModalComponent } from './components/general-modal/general-modal.component';
import { PanZoomComponent } from '@components/panzoom/panzoom.component';
import { CanComponentDeactivateGuard } from '@helpers/can-component-deactivate-guard.service';
import {
  ProductRelationshipEditModalComponent
} from '@components/product-catalogue/products/product-edit/product-relationships/product-relationship-edit-modal/product-relationship-edit-modal.component';
import { AdminInputSelectDynamicEnumService } from '@service/admin-input-select-dynamic-enum.service';
import { EnumerationsService } from '@service/enumerations.service';
import { AdminInputSelectEnumService } from '@service/admin-input-select-enum.service';
import { provideNgxMask } from 'ngx-mask';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { FilterButtonsComponent } from '@components/filter-buttons/filter-buttons.component';

export const DATETIME_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYYTHH:mm:ssZ',
  },
  display: {
    dateInput: 'DD. MM. YYYY HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM-YYYY',
  },
};

loadLocaleData(environment.localization.localeData);

export const inputSelectEnumFactory = (dataTypeEnumAdminService: EnumerationsService) => {
  return new AdminInputSelectEnumService(dataTypeEnumAdminService);
};

export const inputSelectDynamicEnumFactory = (dataTypeEnumAdminService: AdminDynamicEnumService) => {
  return new AdminInputSelectDynamicEnumService(dataTypeEnumAdminService);
};

@NgModule({
  declarations: [
    AppComponent,
    CodebookComponent,
    DashboardComponent,
    FormTextComponent,
    LeftMenuComponent,
    LeftMenuItemComponent,
    LocalizedTextComponent,
    LocalizedTextComponent,
    ProductAttributesComponent,
    ProductCatalogueComponent,
    ProductEditComponent,
    ProductFilterComponent,
    ProductListingComponent,
    ProductPicturesComponent,
    ProductPricesComponent,
    ProductsComponent,
    ProductTextsComponent,
    RelationshipsComponent,
    StickersComponent,
    ProductGroupComponent,
    TopMenuComponent,
    ProductGroupListingComponent,
    ProductGroupFilterComponent,
    ProductGroupEditComponent,
    GroupProductsListingComponent,
    GroupProductsListingItemComponent,
    GroupSubsetProductsComponent,
    ProductsSelectModalComponent,
    RelationshipsFilterComponent,
    RelationshipsListingComponent,
    RelationshipEditComponent,
    ProductRelationshipsComponent,
    ProductBundlesComponent,
    ProductPriceListingItemComponent,
    StickerListComponent,
    StickerFilterComponent,
    StickerEditComponent,
    PictureSelectorComponent,
    GroupsSelectModalComponent,
    GroupPickerComponent,
    LocalizedTextEditComponent,
    LocalizedTextFilterComponent,
    LocalizedTextListComponent,
    LocalizedTextEditLocalesComponent,
    ProductEditGroupsComponent,
    ProductEditGroupsItemComponent,
    ProductStickersComponent,
    StickersSelectModalComponent,
    CategoriesComponent,
    CategoriesListingComponent,
    CodebookFilterComponent,
    CodebookListComponent,
    CodebookEditComponent,
    CategoryEditComponent,
    CategoryParametersListingComponent,
    LocaleTextComponent,
    ProductPriceEditComponent,
    ProductPriceListingComponent,
    DcrRulePipe,
    ProductRatingsComponent,
    ProductRatingsFilterComponent,
    ProductRatingsItemComponent,
    ProductRatingsReviewEditComponent,
    DynamicContextRuleEditComponent,
    DynamicContextRulesListComponent,
    DynamicContextRulesFilterComponent,
    DynamicContextRulesComponent,
    RuleSetsComponent,
    RuleSetsListingComponent,
    RuleSetEditComponent,
    RuleSetsFilterComponent,
    RuleSetOverwriteModalComponent,
    ProductRatingsReviewEditComponent,
    EligibilityComponent,
    ProductEligibilityComponent,
    DynamicContextRulesComponent,
    CartEventsComponent,
    CartEventsFilterComponent,
    CartEventsListingComponent,
    CartEventEditComponent,
    EligibilityExpressionComponent,
    ProductCartEventsComponent,
    PromotionEditComponent,
    PromotionListComponent,
    PromotionFilterComponent,
    PromotionComponent,
    SocketEditComponent,
    SocketListComponent,
    SocketFilterComponent,
    SocketComponent,
    CodebookTypeListComponent,
    CodebookTypeEditComponent,
    CodebookTypeFilterComponent,
    ProductCartEventsComponent,
    TicketsComponent,
    TicketListComponent,
    TicketFilterComponent,
    TicketEditComponent,
    CreateTicketModalComponent,
    SocketContentsComponent,
    PromotionComponent,
    PromotionDesignComponent,
    PromotionDesignConnectionsComponent,
    PromotionDesignNodeComponent,
    SelectedSocketComponent,
    SelectedSocketContentComponent,
    SelectedRootProductComponent,
    SocketsSelectModalComponent,
    AclComponent,
    RolesComponent,
    RolesListingComponent,
    RoleEditComponent,
    RoleAccountsComponent,
    RolesFilterComponent,
    RulesListingComponent,
    RulesListingItemComponent,
    RulesFilterComponent,
    AccountEditComponent,
    AccountListItemComponent,
    AccountListComponent,
    AccountFilterComponent,
    AccountComponent,
    AccountRoleListItemComponent,
    AclRolesSelectModalComponent,
    TicketTypeComponent,
    TicketTypeEditComponent,
    TicketTypeListComponent,
    TicketTypeFilterComponent,
    TicketTypeParametersComponent,
    TicketTypeParametersListComponent,
    TicketTypeParametersItemComponent,
    ParameterDetailModalComponent,
    AclTableComponent,
    AclTableListComponent,
    AclTableListItemComponent,
    TicketTypeStepsListItemComponent,
    TicketStepTemplateStepsListComponent,
    InnerLeftMenuComponent,
    QuickActionsComponent,
    TicketListsComponent,
    TotalConfirmedOrdersComponent,
    TopProductsComponent,
    LastChangesComponent,
    MenuItemComponent,
    TicketMiniListComponent,
    AclRolesSelectModalComponent,
    RulesFilterComponent,
    ReleasesListingComponent,
    ReleasesFilterComponent,
    ReleaseEditComponent,
    MembersListingComponent,
    MembersListingItemComponent,
    StickerPickerComponent,
    PromotionsSelectModalComponent,
    PromotionPickerComponent,
    RulesSelectModalComponent,
    RulePickerComponent,
    CategoriesSelectModalComponent,
    AccountEntityEditComponent,
    AccountEntityListComponent,
    AccountEntityFilterComponent,
    AccountEntityComponent,
    AccountsSelectModalComponent,
    AccountPickerComponent,
    BulkUpdateAccountEntityRolesModalComponent,
    EditStepModalComponent,
    FormParametersComponent,
    TicketStepTemplateComponent,
    TicketStepTemplateListComponent,
    TicketStepTemplateFilterComponent,
    TicketStepTemplateEditComponent,
    StepTemplatePickerComponent,
    StepTemplateSelectModalComponent,
    ProductAclComponent,
    ProductDocumentsComponent,
    DmsFilesFilterComponent,
    DmsFilesListingItemComponent,
    DmsFilesListingComponent,
    DmsFilesSelectModalComponent,
    FormTextsComponent,
    CmsContentsComponent,
    CmsContentsListComponent,
    CmsContentsItemComponent,
    CmsContentsFilterComponent,
    CmsContentEditComponent,
    CmsContentVersionsComponent,
    CmsContentVersionsItemComponent,
    CmsContentWidgetComponent,
    CmsContentWidgetModalComponent,
    CmsContentWidgetModalControlsComponent,
    CmsContentPreviewModalComponent,
    CmsContentBannerModalComponent,
    CmsContentBannerFilterComponent,
    CmsContentBannerItemComponent,
    FormAttachmentsComponent,
    ReleasesSelectModalComponent,
    InputDateFormFieldComponent,
    TableListingFormComponent,
    MatConfirmationDialogComponent,
    InputPickerFormFieldComponent,
    CmsContentParameterComponent,
    FilesAddModalComponent,
    CmsContentWidgetAddModalComponent,
    CmsContentBannerAddModalComponent,
    BlockUiComponent,
    FormPicturesComponent,
    ExportComponent,
    ErrorPageComponent,
    FormPicturesComponent,
    NotificationComponent,
    NotificationNotificationsComponent,
    NotificationTypesComponent,
    NotificationGatewaysComponent,
    NotificationTypeListComponent,
    NotificationTypeFilterComponent,
    NotificationGatewayListComponent,
    NotificationGatewayFilterComponent,
    NotificationGatewayEditComponent,
    NotificationGatewayFilterComponent,
    NotificationTypeEditComponent,
    NotificationTypeTemplateComponent,
    NotificationTypeLocaleModalComponent,
    NotificationTypeGatewaysModalComponent,
    NotificationTypeAttachmentModalComponent,
    NotificationTypeEditComponent,
    NotificationNotificationsListComponent,
    NotificationNotificationsFilterComponent,
    NotificationNotificationsEditComponent,
    CampaignComponent,
    CampaignListComponent,
    CampaignEditComponent,
    CampaignFilterComponent,
    CmsSelectModalComponent,
    NotificationTypeSelectModalComponent,
    ExportsFilterComponent,
    ImportComponent,
    ImportsFilterComponent,
    TicketParameterComponent,
    CategoryParameterComponent,
    FormParametersEditModalComponent,
    StickyMessageComponent,
    AclLegendModalComponent,
    PriviligesComponent,
    PrivilegesFilterComponent,
    PrivilegesListingComponent,
    PrivilegeEditComponent,
    InputDragChipsFormFieldComponent,
    EligibilityLegendModalComponent,
    DelegateModalComponent,
    EligibilityLegendModalComponent,
    GroupTypeListingComponent,
    GroupTypeFilterComponent,
    GroupTypeEditComponent,
    DuplicateProductModalComponent,
    DocumentTemplateEditComponent,
    DocumentTemplateListComponent,
    DocumentTemplateFilterComponent,
    DocumentTemplateComponent,
    DataTypeEnumComponent,
    DataTypeEnumListComponent,
    DataTypeEnumEditComponent,
    DataTypeEnumItemEditComponent,
    FormLocalizedTextsComponent,
    DcrComponent,
    ImportExportComponent,
    ResourcesComponent,
    ResourceResourcesListComponent,
    ResourceResourcesFilterComponent,
    ResourceResourcesEditComponent,
    ResourcesResourceRelationshipModalComponent,
    ResourcesPartyRelationModalComponent,
    ResourcesParameterModalComponent,
    StoresListingComponent,
    StoresFilterComponent,
    StoreEditComponent,
    SkeletonLoaderComponent,
    StocksListingComponent,
    StocksFilterComponent,
    StockEditComponent,
    StoresSelectModalComponent,
    CrmComponent,
    AgreementTypesListingComponent,
    AgreementTypeEditComponent,
    AgreementTypesFilterComponent,
    FormReferencesComponent,
    MetaDescriptionComponent,
    MetaProfileComponent,
    MetaProfileListComponent,
    MetaProfileFilterComponent,
    MetaProfileEditComponent,
    MetaProfilesSelectModalComponent,
    RelationshipsValidToModalComponent,
    LoginRouteComponent,
    GeneralModalComponent,
    PanZoomComponent,
    ProductRelationshipEditModalComponent,
    FilterButtonsComponent,
  ],
  imports: [
    DefaultModule.forRoot({
      defaultLanguage: environment.localization.defaultLocale,
      localization: environment.localization,
      currency: environment.currency,
    }),
    AngularEditorModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [
          environment.mockedLocalization
            ? LocalizationMockedService // overlay localization for runtime testing in development
            : LocalizationService,
        ],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: MissingTranslationHandlerFactory,
        deps: [NGXLogger],
      },
      useDefaultLang: environment.useDefaultLanguageForMissingTranslation,
    }),
    LoggerModule.forRoot({
      level: environment.browserLoggingLevel,
      serverLogLevel: environment.remoteLoggingLevel,
    }),
    FormsModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent,
    }),
    HttpClientModule,
    LayoutModule,
    MatButtonModule,
    MatCardModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatToolbarModule,
    NgbModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatChipsModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatAutocompleteModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    NgxMatMomentModule,
    MatTabsModule,
    MatTreeModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatNativeDateModule,
    ClipboardModule,
    InlineSVGModule.forRoot(),
    DefaultModule,
  ],
  providers: [
    AclService,
    AdminErrorHandler,
    AdminProductGroupService,
    AdminProductService,
    AppAuthGuard,
    AppBlockerService,
    AuthFactoryService,
    CanComponentDeactivateGuard,
    CodebookService,
    CurrentLocaleService,
    DatePipe,
    DefaultErrorHandler,
    KeycloakService,
    LocalizationService,
    LogErrorHandler,
    NcasService,
    {
      provide: orderBff.BASE_PATH,
      useValue: getCurrentHost(),
    },
    {
      provide: adminBff.BASE_PATH,
      useValue: getCurrentHost(),
    },
    {
      provide: LOCALE_ID,
      useValue: environment.localization.defaultLocale,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForwardedHostHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdminHttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MultiTenancyInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [AuthFactoryService, ActivatedRoute, DynamicComponentRegistry, CodebookService, Router, AclService],
    },
    {
      provide: InputSelectEnumService,
      deps: [EnumerationsService],
      useFactory: inputSelectEnumFactory,
    },
    {
      provide: InputSelectDynamicEnumService,
      deps: [AdminDynamicEnumService],
      useFactory: inputSelectDynamicEnumFactory,
    },
    provideNgxMask()
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    // TODO: we should avoid registration of new locales manually in the code.
    // Should be pure configuration adn dynamically loaded.
    // See: https://stackoverflow.com/questions/61035621/how-do-i-dynamically-import-locales-in-angular-9
    registerLocaleData(localeCs, 'cs');
    registerLocaleData(localeSl, 'sl');
    PricePipe.setCurrency(environment.currency);
  }
}
