<div class="main__header">
  <div class="header-box">
    <div class="icon-box CMS">
      <mat-icon svgIcon="notificationsGateway"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.notification.gateways' | translate }}</div>
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-notification-gateway-filter
        (filterChanged)="handleFilterChange()"
        [filtering]="search.filtering"></app-notification-gateway-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.notification.gatewayListings' | translate }}</span>
          <div class="paginator__entries" *ngIf="pagedGatewaysDto">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedGatewaysDto?.totalItems
                          ? pagedGatewaysDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedGatewaysDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedGateways()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="[
            'code',
            'name',
            'channelType',
            'supportDeliveryReport',
            'loopbackEnabled',
            'created',
            'modified',
            'modifiedBy'
          ]"
          [dontShowOnMobile]="['supportDeliveryReport', 'loopbackEnabled', 'created', 'modified', 'modifiedBy']"
          [columnToTruncate]="['created', 'modified', 'modifiedBy']"
          [fillIdBy]="'id'"
          [pagedDto]="pagedGatewaysDto"
          [editable]="aclService.pageEditAccess"
          [disableActions]="disableActions"
          [cacheFilter]="search"
          (editButtonEvent)="editGateway($event)"
          (editInNewTabButtonEvent)="editGateway($event, true)"
          (doubleClickEvent)="editGateway($event)"
          (deleteButtonEvent)="deleteData($event)"
          (pageSizeEvent)="pageSizeChanged($event)"
          (sortChangeEvent)="onSortChange($event)"
          [translationPrefix]="'wc.admin.notification.gateway'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
