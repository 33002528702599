import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminPromotionService,
  AdminSocketService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProductSocketDto, PromotionDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { SocketDto } from '@btl/admin-bff/model/socketDto';
import { ReleasesSelectService } from '@components/releases-select/releases-select.service';
import {
  PromotionDesignComponent
,
} from '@components/product-catalogue/promotion/edit/promotion-design/promotion-design.component';
import { forkJoin, of } from 'rxjs';
import { TableListingFormComponent } from '@components/table-listing-form/table-listing-form.component';
import { MatDialog } from '@angular/material/dialog';
import { Animations } from '@helpers/animations';
import { PromotionListComponent } from '@components/product-catalogue/promotion/list/promotion-list.component';

@Component({
  selector: 'app-promotion-edit',
  templateUrl: './promotion-edit.component.html',
  styleUrls: ['./promotion-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: PromotionEditComponent.PAGE_ID })
export class PromotionEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'PromotionEditComponent';

  pageId(): string {
    return PromotionEditComponent.PAGE_ID;
  }

  @ViewChild(PromotionDesignComponent)
  private promotionDesignComponent: PromotionDesignComponent;

  @ViewChild(TableListingFormComponent, { static: false })
  private tableListingFormComponent: TableListingFormComponent;

  routerOutlet: any;

  public promotionDto: PromotionDto = {};
  public sockets: Array<SocketDto>;
  public productSockets: Array<ProductSocketDto>;

  public duplicateDialogForm: FormGroup = this.formBuilder.group({
    duplicateOption: [2],
  });

  private promotionId: string;

  showDesign = false;

  nodeOpened = false;

  childData;

  validateOrderOfStartEnd: ValidatorFn = function (control: AbstractControl) {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      const startControl = group.get('startDateTime');
      const endControl = group.get('endDateTime');
      const start = startControl.value;
      const end = endControl.value;
      if (start && end && start > end) {
        return { startIsLaterThanEnd: true };
      } else {
        if (control === startControl) {
          PromotionEditComponent.deleteControlError(endControl, 'startIsLaterThanEnd');
        } else if (control === endControl) {
          PromotionEditComponent.deleteControlError(startControl, 'startIsLaterThanEnd');
        }
      }
    }
    return null;
  };

  static deleteControlError(control: AbstractControl, errorKey: string) {
    if (control.errors) {
      delete control.errors[errorKey];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  validFor: FormGroup = this.formBuilder.group({
    startDateTime: [null, [Validators.required, this.validateOrderOfStartEnd]],
    endDateTime: [null, this.validateOrderOfStartEnd],
  });

  promotionForm: FormGroup = this.formBuilder.group({
    code: [null, [Validators.required]],
    name: [null, Validators.required],
    validFor: this.validFor,
    created: [],
    createdBy: [],
    modified: [],
    modifiedBy: [],
  });

  get idControl(): AbstractControl {
    return this.promotionForm.get('id');
  }

  constructor(
    private formBuilder: FormBuilder,
    private releasesSelectService: ReleasesSelectService,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private adminPromotionService: AdminPromotionService,
    private adminSocketService: AdminSocketService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    private dialog: MatDialog
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const promotionId = this.params.id;
      const show = this.params.show;
      this.showDesign = show === 'design';
      if (promotionId) {
        if (promotionId === '&') {
          this.setPromotion(this.promotionDto);
        } else {
          this.loadPromotion(promotionId);
        }
      }
    } else {
      this.promotionDto = undefined;
    }
  }

  loadPromotion(promotionId: string) {
    this.adminPromotionService
      .getPromotionById(promotionId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setPromotion(result);
        this.promotionId = this.promotionDto.id;
      });
  }

  setPromotion(promotion: PromotionDto) {
    this.productSockets = null;
    this.promotionDto = promotion;
    this.promotionForm.patchValue(this.promotionDto);
    this.loadSockets(this.promotionDto.code);
  }

  savePromotion(): boolean {
    FormUtils.validateAllFormFields(this.promotionForm);
    if (this.promotionForm.valid) {
      const promotion = this.promotionDto;
      if (promotion) {
        Object.keys(this.promotionForm.controls).forEach(field => {
          const control = this.promotionForm.get(field);
          promotion[field] = control.value;
        });
      }
      return true;
    }
    return false;
  }

  save() {
    if (this.savePromotion()) {
      this.appBlockerService.block();
      if (this.promotionDto.recordVersion) {
        this.adminPromotionService
          .updatePromotion(this.promotionId, this.promotionDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getPromotionHandler);
      } else {
        this.adminPromotionService
          .createPromotion(this.promotionDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(
            map((promotion: PromotionDto) => {
              if (this.productSockets) {
                this.productSockets.forEach(productSockets => {
                  productSockets.id = null;
                  productSockets.recordVersion = null;
                  productSockets.promotionId = promotion.id;
                });

                this.adminPromotionService
                  .replaceProductSocketsForPromotion(promotion.id, this.productSockets)
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(() => {
                    this.getPromotionHandler(promotion);
                  });
              } else {
                this.getPromotionHandler(promotion);
              }
            })
          )
          .subscribe();
      }
    }
  }

  addRootProduct() {
    this.promotionDesignComponent.addRootProduct();
  }

  saveDesign() {
    this.promotionDesignComponent.save();
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditPromotion(): PromotionDto {
    return this.promotionDto;
  }

  public reset() {
    if (this.promotionDto && this.promotionDto.id) {
      this.appBlockerService.block();
      this.adminPromotionService
        .getPromotionById(this.promotionDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(this.getPromotionHandler);
    } else {
      this.promotionForm.reset(this.promotionForm);
      this.setPromotion(this.promotionDto);
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.promotion.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminPromotionService
        .deletePromotion(this.promotionDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(PromotionListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  duplicate(content) {
    this.dialog.open(content);
  }

  duplicateByOption() {
    this.appBlockerService.block();
    const duplicatedPromotionId = this.promotionDto.id;
    this.promotionId = undefined;
    this.promotionForm.controls.code.patchValue(`${this.promotionDto.code}-copy`);
    this.promotionDto.code = `${this.promotionDto.code}-copy`;
    this.promotionDto.id = null;
    this.promotionDto.recordVersion = null;

    if (this.duplicateDialogForm.value.duplicateOption === 1) {
      this.sockets = null;
      this.appBlockerService.unblock();
    } else if (this.duplicateDialogForm.value.duplicateOption === 2) {
      this.adminPromotionService
        .getPromotionProductSockets(duplicatedPromotionId)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(productSockets => {
          this.productSockets = productSockets;
        });
    } else {
      this.adminPromotionService
        .createPromotion(this.promotionDto)
        .pipe(
          map((promotion: PromotionDto) => {
            this.adminPromotionService
              .getPromotionProductSockets(duplicatedPromotionId)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                productSockets => {
                  this.productSockets = productSockets;
                  if (this.productSockets) {
                    const socketMap = new Map<string, SocketDto>();

                    this.sockets.forEach(socket => {
                      socketMap.set(socket.id, socket);
                      socket.id = null;
                      socket.code = `${socket.code}-${promotion.id}`;
                      socket.recordVersion = null;
                      socket.contents.forEach(content => {
                        content.id = null;
                        content.recordVersion = null;
                      });
                    });

                    const socketsCalls = [];
                    Array.from(socketMap.values()).forEach(socket =>
                      socketsCalls.push(this.adminSocketService.createSocket(socket))
                    );
                    forkJoin(socketsCalls)
                      .pipe(takeUntil(this.onDestroy$))
                      .subscribe(
                        responses => {
                          responses.forEach((socket: SocketDto) => {
                            for (const entry of Array.from(socketMap.entries())) {
                              if (entry[1].code === socket.code) {
                                socketMap.set(entry[0], socket);
                                break;
                              }
                            }
                          });

                          this.productSockets.forEach(productSocket => {
                            productSocket.id = null;
                            if (productSocket.socketId) {
                              productSocket.socketId = socketMap.get(productSocket.socketId)?.id;
                            }

                            if (productSocket.originatorSocketId) {
                              productSocket.originatorSocketId = socketMap.get(productSocket.originatorSocketId)?.id;
                            }
                            productSocket.recordVersion = null;
                            productSocket.promotionId = promotion.id;
                          });

                          this.adminPromotionService
                            .replaceProductSocketsForPromotion(promotion.id, this.productSockets)
                            .pipe(finalize(this.appBlockerService.unblock))
                            .pipe(takeUntil(this.onDestroy$))
                            .subscribe(
                              () => {
                                this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
                                this.getPromotionHandler(promotion);
                              },
                              error => this.appBlockerService.unblock()
                            );
                        },
                        error => this.appBlockerService.unblock()
                      );
                  } else {
                    this.appBlockerService.unblock();
                    this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
                    this.getPromotionHandler(promotion);
                  }
                },
                error => this.appBlockerService.unblock()
              );
          })
        )
        .subscribe(
          () => {},
          error => this.appBlockerService.unblock()
        );
    }
  }

  public getPromotionHandler = (promotionDto: PromotionDto): void => {
    let saving = false;
    if (this.promotionDto) {
      saving = true;
    }
    if (promotionDto && this.promotionDto) {
      if (promotionDto.id !== this.promotionDto.id) {
        this.promotionId = promotionDto.id;
      }
      this.setPromotion(promotionDto);
      this.navigateSelf({ id: promotionDto.id });
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  loadSockets(promotionCode: string) {
    if (promotionCode) {
      const search: Search = {
        filtering: [{ column: 'promotionCode', value: promotionCode, compareType: 'EQUAL' }],
        sorting: [],
        paging: {
          page: 1,
          pageSize: -1,
        },
      };

      this.adminPromotionService
        .getPromotionProductSockets(this.promotionDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(productSocketResult => {
          this.adminSocketService
            .getSocketsByFilter(search)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(result => {
              this.sockets = result.data.filter(socket =>
                this.containsEntityId(socket, this.filterForm.value.entityId)
              );
              this.sockets.forEach(socket => {
                socket['cardinalities'] = `${socket.contentQty.min}|${socket.contentQty.max}`;
                socket['contents.length'] = `${socket.contents.length}`;
                const matchByID = productSocketResult.find(item => item.socketId === socket.id);
                if (matchByID !== null) {
                  socket[
                    'productCardinalities'
                  ] = `${matchByID.socketQty.min}|${matchByID.socketQty.max}|${matchByID.socketQty.def}`;
                }
                socket.contents.forEach(content => {
                  content[
                    'cardinalities'
                  ] = `${content.contentQty.min}|${content.contentQty.max}|${content.contentQty.def}`;
                });
              });
            });
        });
    }
  }

  private containsEntityId(socket: SocketDto, entityId): boolean {
    if (!entityId) {
      return true;
    }
    return socket.contents.find(content => content.entityId.indexOf(entityId) > -1) ? true : false;
  }

  public loadContentVersions(socket) {
    this.childData = {
      data: socket.contents,
    };
  }

  private getChildDataCallback = (row: any): any => {
    return of({
      data: row.contents,
    });
  };

  design() {
    if (this.promotionDto.id) {
      if (this.showDesign) {
        this.nodeOpened = false;
        this.loadSockets(this.promotionDto.code);
      }
      this.showDesign = !this.showDesign;
    }
  }

  cancel() {
    this.navigateSibling(PromotionListComponent.PAGE_ID);
  }

  releases() {
    if (this.promotionDto && this.promotionDto.code) {
      this.releasesSelectService.selectReleases('Promotion', this.promotionDto.code);
    }
  }

  expandAll() {
    this.tableListingFormComponent.expandAll();
  }

  collapseAll() {
    this.tableListingFormComponent.collapseAll();
  }

  filterForm: FormGroup = this.formBuilder.group({
    entityId: [null],
  });

  search() {
    this.loadSockets(this.promotionDto.code);
  }

  isNewPromotion() {
    return !this.promotionDto.id || this.promotionDto.id === '&';
  }
}
