<div class="main__header">
  <h1>{{ 'wc.admin.codebookType.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="001_codebook_type_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="002_codebook_type_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="003_codebook_type_edit" *ngIf="!isNew" mat-menu-item (click)="duplicate()" [routerLink]=""
      >
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="004_codebook_type_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="005_codebook_type_edit" *ngIf="!isNew" mat-menu-item (click)="delete()" [routerLink]=""
      >
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
  </div>
</div>

<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

<div class="card__wrapper">
  <!-- card with main codebook attributes -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.codebookType.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="getEditCodebookType()" [formGroup]="codebookTypeForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="codebookTypeForm"
                  [controlName]="'name'"
                  id="006_codebook_type_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.codebookType'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- card with main codebook parameters -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.codebookType.parameters' | translate }}
      </div>
      <div class="card__body">
        <mat-selection-list [multiple]="false">
          <mat-list-option *ngFor="let param of codebookTypeParameters; let i = index" [value]="param" [id]="param">{{
            param
          }}</mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>
</div>
