<div class="card__wrapper">
  <!-- card -->
  <div class="card">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.ticketType.parameters.header' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="001_ticket_type_parameter_listing"
            color="primary"
            mat-raised-button
            (click)="newTicketTypeParameter()"
            [routerLink]=""
            class="btn -primary -xs edit icon stroke">
            <mat-icon svgIcon="plus"></mat-icon>{{ 'wc.admin.new' | translate }}
          </button>
          <button
            id="002_ticket_type_parameter_listing"
            color="primary"
            mat-raised-button
            *ngIf="this.parentForm.controls['parameters'].value.length > 0"
            (click)="editCheckedTicketTypeParameter()"
            [routerLink]=""
            class="btn -primary -xs edit">
            <mat-icon class="default-icon">create</mat-icon>{{ 'wc.admin.edit' | translate }}
          </button>
          <button
            id="003_ticket_type_parameter_listing"
            color="primary"
            mat-stroked-button
            *ngIf="this.parentForm.controls['parameters'].value.length > 0"
            (click)="deleteCheckedTicketTypeParameter()"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>{{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>

      <div class="card__body">
        <table class="table -primary table-sm" style="table-layout: fixed">
          <thead>
            <th scope="col" [style.width.px]="30"></th>
            <th scope="col">
              {{ 'wc.admin.ticketType.parameters.name' | translate }}
            </th>
            <th scope="col">
              {{ 'wc.admin.ticketType.parameters.type' | translate }}
            </th>
            <th scope="col">
              {{ 'wc.admin.ticketType.parameters.defaultValue' | translate }}
            </th>
            <th scope="col" [style.width.px]="100">{{ 'wc.admin.ticketType.action' | translate }}</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let parameter of this.parentForm.controls['parameters'].value; let i = index">
              <tr
                app-ticket-type-parameters-item
                class="table__row"
                [parameter]="parameter"
                (editTicketTypeParameterEvent)="editTicketTypeParameter($event, i)"
                (deleteTicketTypeParameterEvent)="deleteTicketTypeParameter($event)"
                (dblclick)="editTicketTypeParameter(parameter, i)"></tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
