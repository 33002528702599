import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-resource-resources-party-relation-modal',
  templateUrl: './resources-party-relation-modal.component.html',
  styleUrls: ['./resources-party-relation-modal.component.scss'],
})
export class ResourcesPartyRelationModalComponent implements OnInit {
  @Input()
  dialogRef;

  @Input()
  formGroup: FormGroup;

  @Output()
  readonly saveEvent: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.formGroup.enable();
  }

  save(): void {
    const data = this.formGroup.getRawValue();
    if (data.id && data.role && data.referredType) {
      data.href = `https://server:port/v1/parties/${data.id}`;
      this.saveEvent.emit(data);
    } else {
      this.formGroup.get('id').markAsTouched();
      this.formGroup.get('role').markAsTouched();
      this.formGroup.get('referredType').markAsTouched();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
