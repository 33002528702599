<form *ngIf="productCode" [formGroup]="form" class="node-form">
  <div class="node-header">
    <div class="header-label">
      {{ 'wc.admin.promotion.selectedSocket.editing' | translate }}
    </div>
    <div class="node-form-close" (click)="this.closeEmitter.emit()" [attr.aria-label]="'wc.common.close' | translate">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="node-body">
    <div class="body-info">
      <div class="node-inputs">
        <app-input-picker-form-field
          [parentGroup]="form"
          controlName="productCode"
          valueField="productCode"
          [pickerType]="PickerInputType.PRODUCT"
          [selectMode]="false"
          translationPrefix="wc.admin.socket">
        </app-input-picker-form-field>
      </div>
      <div class="node-form-actions">
        <a (click)="delete()" [routerLink]="" class="action-delete">
          {{ 'wc.admin.promotion.delete' | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="node-footer">
    <div></div>
  </div>
</form>
