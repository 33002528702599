import { Component } from '@angular/core';
import {
  AbstractFilterComponent,
  AdminDynamicEnumService,
  BusinessCategoryService,
  CompareType,
} from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-cartEvents-filter',
  templateUrl: './cart-events-filter.component.html',
  styleUrls: ['./cart-events-filter.component.scss'],
  animations: [Animations.advancedFilterArrow],
})
export class CartEventsFilterComponent extends AbstractFilterComponent {
  PickerInputType = PickerInputType;

  eventTypes = [];
  productEntityTypes = [];
  productConnectionDirections = [];
  productConnectionTypes = [];
  productLinkType = [];
  scopes = [];

  techCategories: Array<string> = [];
  businessCategories: Array<string> = [];
  mapFieldWithCompareType = new Map([
    ['validFrom', CompareTypeDtoEnum.BIGGEREQ],
    ['validTo', CompareTypeDtoEnum.SMALLEREQ],
  ]);
  mapFieldWithColumnName = new Map([
    ['validFrom', 'validFor.startDateTime'],
    ['validTo', 'validFor.endDateTime'],
  ]);

  advancedFilterFields = ['validFrom', 'validTo', 'scopeType', 'connectionType', 'connectionDirectionType', 'connectionLinkType'];

  constructor(
    private formBuilder: FormBuilder,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private enumerationsService: EnumerationsService,
    private businessCategoryService: BusinessCategoryService
  ) {
    super();
    this.filterForm = this.formBuilder.group({
      code: [],
      fromTypeId: [],
      fromId: [],
      eventType: [],
      toProductMasterId: [],
      validFrom: [],
      validTo: [],
      scopeType: [],
      connectionType: [],
      connectionDirectionType: [],
      connectionLinkType: [],
    });

    this.enumerationsService
      .getProductTechnicalCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.techCategories = result;
      });

    this.businessCategoryService
      .getBusinessCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        result.forEach(businessCategory => {
          this.businessCategories.push(businessCategory.id);
        });
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductCartEventType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.eventTypes = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductEntityType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.productEntityTypes = result.data.map(value => value.name)
          .filter(name => '[ProdCode, CatTech, ProdGroup, CatBsns]'.indexOf(name) > -1)
          .sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductCartEventScopeType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.scopes = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductLinkType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.productLinkType = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductConnectionDirection')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.productConnectionDirections = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductConnectionType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.productConnectionTypes = result.data.map(value => value.name).sort();
      });
  }
}
