<div class="main__header">
  <div class="header-box">
    <div class="icon-box ACCESS">
      <mat-icon svgIcon="resources" />
    </div>
    <div class="header-label">{{ 'wc.admin.resource.resources' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      *ngIf="aclService.pageEditAccess"
      mat-raised-button
      color="primary"
      class="btn -primary -xs"
      [routerLink]=""
      (click)="newResource()"
      >{{ 'wc.common.new' | translate }}</a
    >
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-resource-resources-filter
        [cacheFilterUsed]="cacheFilterUsed"
        [filtering]="search.filtering"
        (filterChanged)="handleFilterChange()" />
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.resource.resourceListings' | translate }}</span>
          <div *ngIf="pagedResourcesDto" class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedResourcesDto?.totalItems
                          ? pagedResourcesDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedResourcesDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            *ngIf="aclService.pageEditAccess"
            color="primary"
            mat-stroked-button
            type="button"
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedResources()"
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="[
            'id',
            'type',
            'category',
            'name',
            'resourceStatus',
            'usageState',
            'startOperatingDate',
            'endOperatingDate'
          ]"
          [additionalDateColumns]="['startOperatingDate', 'endOperatingDate']"
          [module]="'resource'"
          [pagedDto]="pagedResourcesDto"
          [editable]="aclService.pageEditAccess"
          [disableActions]="disableActions"
          [cacheFilter]="search"
          [fillIdBy]="'id'"
          [translationPrefix]="'wc.admin.resource.resources'"
          (editButtonEvent)="editResource($event)"
          (editInNewTabButtonEvent)="editResource($event, true)"
          (doubleClickEvent)="editResource($event)"
          (deleteButtonEvent)="deleteResource($event)"
          (pageSizeEvent)="pageSizeChanged($event)"
          (sortChangeEvent)="onSortChange($event)" />
      </div>
    </div>
  </div>
</div>
