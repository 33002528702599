<ng-container *ngIf="picture.href; else noFile">
  <ng-container *ngIf="pictureSize.has(widgetTemplateType); else defaultView">
    <div class="card__body upload-pictures">
      <form [formGroup]="pictureForm">
        <img *ngIf="pictureForm?.controls.href.value" src="{{ pictureForm.controls.href.value }}"/>
        <input *ngIf="controlName" [formControl]="formControl" type="hidden"/>
        <div class="button-container">
          <div class="priority">
            {{ pictureForm?.controls.priority.value }}
          </div>
          <div class="product-pictures-inner">
            <div *ngIf="pictureTypes">
              {{ pictureForm?.controls.pictureType.value }}
            </div>
            <div class="picture_actions">
              <div class="actions-box">
                <button color="primary" mat-raised-button [routerLink]="" (click)="uploaded()" class="btn -primary -xs edit">
                  {{ 'wc.common.choose' | translate }}
                </button>
                <button color="primary" mat-raised-button [routerLink]="" (click)="download()" class="btn -primary -xs edit">
                  {{ 'wc.admin.export.download' | translate }}
                </button>
                <a (click)="remove()" class="btn cancel-button">
                  {{'wc.common.delete' | translate}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-template #defaultView>
    <div class="card__body pictures">
      <div class="product_pictures-item">
        <form [formGroup]="pictureForm" class="product_pictures-item-inner">
          <input *ngIf="controlName" [formControl]="formControl" type="hidden" />
          <div class="product-pictures">
            <div class="priority">
              {{ pictureForm?.controls.priority.value }}
            </div>
            <div class="product-pictures-inner">
              <div class="picture_img">
                <img *ngIf="pictureForm?.controls.href.value" src="{{ pictureForm.controls.href.value }}" />
              </div>
              <div *ngIf="pictureTypes">
                {{ pictureForm?.controls.pictureType.value }}
              </div>
              <div class="picture_actions">
                <div class="actions-box">
                  <mat-icon (click)="uploaded()">cloud_upload</mat-icon>
                  <mat-icon (click)="download()">cloud_download</mat-icon>
                  <mat-icon (click)="remove()">delete</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #noFile>
  <app-files-add-modal [uploadStep]="false"
                       [multiple]="false"
                       [uploadedHandler]="handleUpload"
                       [simpleMode]="true">
  </app-files-add-modal>
</ng-template>

