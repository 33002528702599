<td class="content-desktop">{{ contentMasterDto.code }}</td>
<td class="text-truncate">{{ contentMasterDto.name }}</td>
<td class="text-truncate">{{ contentMasterDto.keys[0].keyValue }}</td>
<td class="text-truncate">
  {{ 'wc.admin.cms.content.templateType.' + contentMasterDto.contentTemplateType | translate }}
</td>
<td class="text-truncate">{{ 'wc.admin.cms.content.state.' + contentMasterDto.state | translate }}</td>
<td class="text-truncate">{{ getInternalText(contentMasterDto.isInternal) | translate }}</td>
<td class="text-truncate content-desktop">{{ contentMasterDto.created | date: 'dd.MM.yyyy HH:mm' }}</td>
<td class="text-truncate content-desktop">{{ contentMasterDto.modified | date: 'dd.MM.yyyy HH:mm' }}</td>
<td class="text-truncate content-desktop">{{ contentMasterDto.modifiedBy }}</td>

<td class="table__action text-truncate">
  <div class="{{ isCollapsed ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down' }}"></div>
</td>
