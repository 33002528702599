<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.products.productBundles' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            color="primary"
            mat-raised-button
            *ngIf="aclService.pageEditAccess"
            [disabled]="!this.productEditService.enableEditProduct"
            (click)="addProduct()"
            [routerLink]=""
            class="btn -primary -xs edit icon stroke">
            <mat-icon svgIcon="plus"></mat-icon>
            {{ 'wc.common.add' | translate }}
          </button>
          <button
            color="primary"
            mat-stroked-button
            *ngIf="aclService.pageEditAccess"
            [disabled]="!this.productEditService.enableEditProduct"
            (click)="deleteCheckedProduct()"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <div class="card__body">
          <app-table-listing-form
            [columnName]="['description', 'productCode']"
            [fillIdBy]="'productCode'"
            [customData]="productBundles"
            [enablePaginator]="false"
            [disableActions]="true"
            [translationPrefix]="'wc.admin.productGroups'">
          </app-table-listing-form>
        </div>
      </div>
    </div>
  </div>
</div>
