import { AbstractControl } from '@angular/forms';
import { CompareType, ControlFilterMiner, Filter } from '@btl/btl-fe-wc-common';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

/**
 * Example of value:
 *
 * [
 *   {
 *     "column": "resourceCharacteristics",
 *     "value": [
 *       {
 *         "column": "name",
 *         "compareType": "EQUAL",
 *         "value": "characteristic1"
 *       }
 *     ]
 *   }
 * ]
 */
export class ResourceControlFilterMinerComponent extends ControlFilterMiner {
  field: string;

  constructor(
    control: AbstractControl,
    column: string,
    field: string,
    compareType: CompareTypeDtoEnum | AbstractControl = CompareTypeDtoEnum.EQUAL
  ) {
    super(control, column, compareType);
    this.field = field;
  }

  filterValue(): Filter {
    if (this.isValidValue(this.control.value)) {
      return {
        column: this.field,
        compareType: this.compareType as any,
        value: this.control.value,
      };
    }
    return null;
  }

  patchFilters(filters: ResourceFilter[]): void {
    const filterIdx = this.searchFilterColumn(filters);
    if (filterIdx === -1) {
      const value = this.filterValue();
      if (value) {
        const newFilter: any = { column: this.column, value: [value] };
        if (this.isValid(newFilter)) {
          filters.push(newFilter);
          this.modifyFilterValue(newFilter);
        }
      }
    } else {
      const filter = filters[filterIdx];
      let filterValueIdx = this.searchFilter(filter.value);
      const value = this.filterValue();
      if (value) {
        if (filterValueIdx === -1) {
          filter.value.push(value);
          filterValueIdx = 0;
        } else {
          filter.value[filterValueIdx] = value;
        }

        if (this.isValid(filter.value[filterValueIdx])) {
          this.modifyFilterValue(filter.value[filterValueIdx]);
        } else {
          filter.value.splice(filterValueIdx, 1);
        }
      } else {
        filters.splice(filterIdx, 1);
      }
    }
  }

  patchControls(filters: Filter[]): void {
    const filterIdx = this.searchFilterColumn(filters);
    if (filterIdx === -1) {
      this.control.patchValue(null);
    } else {
      const filter = filters[filterIdx];
      const filterValueIdx = this.searchFilter(filter.value);
      if (filterValueIdx > -1) {
        this.control.patchValue(
          this.modifiedControlValue(filter.value[filterValueIdx].value, filter.value[filterValueIdx].compareType)
        );
        if (this.compareType instanceof AbstractControl) {
          this.compareType.patchValue(filter.value[filterValueIdx].compareType);
        }
      }
    }
  }

  searchFilterColumn(filters: ResourceFilter[]): number {
    return filters.findIndex(filter => filter && filter.column === this.column);
  }

  searchFilter(filters: Filter[]): number {
    return filters.findIndex(filter => filter && filter.column === this.field);
  }
}

export interface ResourceFilter {
  column: string;
  value: Filter[];
}
