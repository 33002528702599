import { Component, EventEmitter, TemplateRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminContextRulesService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ContextRuleDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { Animations } from '@helpers/animations';
import { DynamicContextRulesListComponent } from '@components/product-catalogue/dynamic-context-rules/list/dynamic-context-rules-list.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dynamic-context-rule-edit',
  templateUrl: './dynamic-context-rule-edit.component.html',
  styleUrls: ['./dynamic-context-rule-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: DynamicContextRuleEditComponent.PAGE_ID })
export class DynamicContextRuleEditComponent extends AbstractPageComponent {

  public static readonly PAGE_ID = 'DynamicContextRuleEditComponent';

  pageId(): string {
    return DynamicContextRuleEditComponent.PAGE_ID;
  }

  routerOutlet: any;

  editContextRuleChanged = new EventEmitter<ContextRuleDto>();

  private contextRuleDto: ContextRuleDto = {};

  private contextRuleId: string;
  private isNew: boolean = true;

  implementationTypes;
  applicationTypes;

  validateIdForReservedKeywords: ValidatorFn = function (control: AbstractControl) {
    let value: string = control.value;
    if (value) {
      value = value.trim().toUpperCase();
      const keywords = ['AND', 'NOT', 'OR'];
      if (keywords.includes(value)) {
        return { reservedKeyword: true };
      }
    }
    return null;
  };
  contextRuleForm: FormGroup = this.formBuilder.group({
    id: [null, [Validators.required, Validators.pattern('[A-Za-z0-9_-]+'), this.validateIdForReservedKeywords]],
    applicationType: [null, Validators.required],
    implementationType: [null, Validators.required],
    abstractRule: [false],
    expression: [null, Validators.required],
    arguments: [],
    documentation: [],
  });

  get idControl(): AbstractControl {
    return this.contextRuleForm.get('id');
  }

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private adminContextRuleService: AdminContextRulesService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    private dialog: MatDialog
  ) {
    super(router, route);
    // TODO  implement asynchronous loading enumerations from DCR MS through EnumsService; then, when will be supported
    this.implementationTypes = this.adminContextRuleService.getImplementationTypes().sort();
    this.applicationTypes = this.adminContextRuleService.getApplicationTypes().sort();
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const contextRuleId = this.params.id;
      if (contextRuleId) {
        if (contextRuleId === '&') {
          this.setContextRule(this.contextRuleDto, true);
        } else {
          this.loadContextRule(contextRuleId);
        }
      }
    } else {
      this.contextRuleDto = undefined;
    }
  }

  loadContextRule(contextRuleId: string) {
    this.adminContextRuleService
      .getContextRule(contextRuleId, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setContextRule(result, false);
        this.contextRuleId = this.contextRuleDto.id;
      });
  }

  setContextRule(contextRule: ContextRuleDto, isNew: boolean) {
    this.contextRuleDto = contextRule;
    this.contextRuleForm.patchValue(this.contextRuleDto);
    if (contextRule) {
      this.editContextRuleChanged.emit(this.contextRuleDto);
    }
    this.isNew = isNew;
  }

  saveContextRule(): boolean {
    FormUtils.validateAllFormFields(this.contextRuleForm);
    if (this.contextRuleForm.valid) {
      const contextRule = this.contextRuleDto;
      if (contextRule) {
        Object.keys(this.contextRuleForm.controls).forEach(field => {
          const control = this.contextRuleForm.get(field);
          contextRule[field] = control.value;
        });
      }
      return true;
    }
    return false;
  }

  save() {
    if (this.saveContextRule()) {
      this.appBlockerService.block();
      if (!(this.contextRuleId === null || this.contextRuleId === undefined)) {
        this.adminContextRuleService
          .updateContextRule(this.contextRuleId, this.contextRuleDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getContextRuleHandler);
      } else {
        this.adminContextRuleService
          .createContextRule(this.contextRuleDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getContextRuleHandler);
      }
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditContextRule(): ContextRuleDto {
    return this.contextRuleDto;
  }

  public reset() {
    if (this.contextRuleDto && this.contextRuleDto.id) {
      this.appBlockerService.block();
      this.adminContextRuleService
        .getContextRule(this.contextRuleDto.id, null)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(this.getContextRuleHandler);
    } else {
      this.contextRuleForm.reset(this.contextRuleForm);
      this.setContextRule(this.contextRuleDto, this.isNew);
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.contextrule.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminContextRuleService
        .deleteContextRule(this.contextRuleDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(DynamicContextRulesListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  duplicate() {
    this.contextRuleId = undefined;
    this.contextRuleForm.controls.id.patchValue(`${this.contextRuleDto.id}-copy`);
    this.contextRuleDto.recordVersion = null;
  }

  public getContextRuleHandler = (contextRuleDto: ContextRuleDto): void => {
    let saving = false;
    if (this.contextRuleDto) {
      saving = true;
    }
    if (contextRuleDto && this.contextRuleDto) {
      if (contextRuleDto.id === this.contextRuleDto.id) {
        this.setContextRule(contextRuleDto, this.isNew);
        this.navigateSelf({ id: contextRuleDto.id });
      } else {
        this.setContextRule(contextRuleDto, this.isNew);
        this.contextRuleId = contextRuleDto.id;
        this.navigateSelf({ id: contextRuleDto.id });
      }
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  cancel() {
    this.navigateSibling(DynamicContextRulesListComponent.PAGE_ID);
  }

  isNewDcr() {
    return !this.contextRuleDto.id || this.contextRuleDto.id === '&';
  }

  dialogRef;
  editDialogField;
  editDialogValue;
  editDialogValueDisabled;

  openModalEdit(content, field) {
    this.editDialogField = field;
    this.editDialogValue = this.contextRuleForm.controls[this.editDialogField].value;
    this.editDialogValueDisabled = this.contextRuleForm.controls[this.editDialogField].disabled;
    this.dialogRef = this.dialog.open(content, {panelClass: 'form-text-dialog-container'});
  }

  closeEditDialog() {
    this.contextRuleForm.controls[this.editDialogField].patchValue(this.editDialogValue);
    this.dialogRef.close();
  }
}
