<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'campaignCode'"
            [inputType]="'text'"
            id="001_campaign_filter"
            [translationPrefix]="'wc.admin.campaign'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            [formControlName]="'campaignChannel'"
            [options]="campaignChannels"
            id="002_campaign_filter"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.campaign'">
          </app-input-select-form-field>
          <app-input-select-form-field
            [formControlName]="'campaignType'"
            id="003_campaign_filter"
            [options]="campaignTypes"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.campaign'">
          </app-input-select-form-field>
          <app-input-date-form-field
            [parentGroup]="filterForm"
            id="004_campaign_filter"
            [controlName]="'validAt'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.campaign'">
          </app-input-date-form-field>
          <app-input-select-form-field
            [formControlName]="'state'"
            id="005_campaign_filter"
            [options]="campaignStates"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.campaign'">
          </app-input-select-form-field>
          <div class="form__field-actions advanced">
            <div class="form__field-actions advanced"
                 app-filter-buttons
                 [complexFilter]="this"
                 (clearFilter)="resetFilterFields()">
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-input-select-form-field
          [formControlName]="'campaignDirection'"
          id="008_campaign_filter"
          [options]="campaignDirections"
          [showEmptyOption]="true"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.campaign'">
        </app-input-select-form-field>
        <app-input-select-form-field
          [formControlName]="'campaignSlot'"
          id="009_campaign_filter"
          [options]="campaignSlots"
          [showEmptyOption]="true"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.campaign'">
        </app-input-select-form-field>
        <app-input-picker-form-field
          [parentGroup]="filterForm"
          [translationPrefix]="'wc.admin.campaign'"
          id="010_campaign_filter"
          [pickerType]="PickerInputType.CMS"
          [labelField]="'code'"
          [valueField]="'code'"
          [selectMode]="false"
          (keydown.enter)="handleChange()"
          [controlName]="'campaignCmsContent'">
        </app-input-picker-form-field>
        <app-input-picker-form-field
          [parentGroup]="filterForm"
          [translationPrefix]="'wc.admin.campaign'"
          [pickerType]="PickerInputType.NOTIFICATION_TYPE"
          [selectMode]="false"
          [labelField]="'notificationType'"
          [valueField]="'notificationType'"
          (keydown.enter)="handleChange()"
          id="011_campaign_filter"
          [controlName]="'campaignNotifType'">
        </app-input-picker-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</form>
