<form class="form" [formGroup]="filterForm">
  <mat-expansion-panel disabled hideToggle style="height: 100%" [expanded]="isAdvancedFilterOpen">
    <mat-expansion-panel-header style="height: 100%">
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'login'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.account'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            id="002_accounts_filter"
            [formControlName]="'state'"
            [showEmptyOption]="true"
            [options]="accountStateTypes"
            [valueOption]="'name'"
            [labelOption]="'name'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.account'">
          </app-input-select-form-field>
          <app-custom-input
            [controlName]="'lastName'"
            id="003_accounts_filter"
            [controlName]="'lastName'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.account'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            id="004_accounts_filter"
            labelOption="labelKey"
            valueOption="value"
            [translationPrefix]="'wc.admin.account'"
            [yesNoOptions]="true"
            [showEmptyOption]="true"
            [formControlName]="'external'"
            (keydown.enter)="handleChange()" />
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-custom-input
          [controlName]="'extId'"
          id="007_accounts_filter"
          [controlName]="'extId'"
          [inputType]="'text'"
          [translationPrefix]="'wc.admin.account'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'employeeId'"
          id="008_accounts_filter"
          [controlName]="'employeeId'"
          [inputType]="'text'"
          [translationPrefix]="'wc.admin.account'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-input-select-form-field
          *ngIf="aclRoles"
          id="009_accounts_filter"
          [translationPrefix]="'wc.admin.account'"
          [options]="aclRoles"
          [showEmptyOption]="true"
          [labelOption]="'id'"
          [valueOption]="'id'"
          [multipleSelect]="true"
          [formControlName]="'roles'"
          (keydown.enter)="handleChange()" />
      </div>
    </div>
  </mat-expansion-panel>
</form>
