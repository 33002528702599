import { Component, Input } from '@angular/core';
import { AbstractFilterComponent } from '@btl/btl-fe-wc-common';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-bulk-update-account-entity-roles-modal',
  templateUrl: './bulk-update-account-entity-roles-modal.component.html',
  styleUrls: ['./bulk-update-account-entity-roles-modal.component.scss'],
})
export class BulkUpdateAccountEntityRolesModalComponent extends AbstractFilterComponent {
  @Input()
  dialogRef;

  @Input()
  handler;

  form: FormGroup = this.formBuilder.group({
    startDateTime: [new Date()],
    endDateTime: [],
    canDelegate: [false],
  });

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  continue() {
    this.handler(this.form.getRawValue());
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
