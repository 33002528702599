<div class="card__inner" [formGroup]="form" *ngIf="refTypes && entityTypes">
  <div class="card__header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column">
      <span>{{ 'wc.admin.references.header' | translate }}</span>
    </div>
    <div fxLayoutGap="10px" class="listing-action" fxLayout="row" style="align-items: center">
      <button
        id="020_form_references"
        color="primary"
        mat-raised-button
        (click)="add()"
        [routerLink]=""
        class="btn -primary -xs edit icon stroke">
        <mat-icon svgIcon="plus"></mat-icon>
        {{ 'wc.common.add' | translate }}
      </button>
      <mat-icon
        (click)="collapsed = !collapsed"
        color="primary"
        svgIcon="{{ collapsed ? 'hide' : 'show' }}"
        style="cursor: pointer"></mat-icon>
    </div>
  </div>

  <ng-container *ngFor="let referenceFormGroup of getFormArray().controls; let i = index">
    <div [hidden]="collapsed" class="card__body">
      <form class="form" [formGroup]="referenceFormGroup" id="021_form_references_{{ i }}">
        <div fxLayout="row" class="reference">
          <div class="reference-inner">
            <app-input-select-form-field
              [options]="refTypes"
              id="022_form_references_{{ i }}"
              [formControlName]="'refType'"
              [translationPrefix]="'wc.admin.references'">
            </app-input-select-form-field>
            <app-input-select-form-field
              [options]="entityTypes"
              id="023_form_references_{{ i }}"
              [formControlName]="'entityType'"
              [translationPrefix]="'wc.admin.references'">
            </app-input-select-form-field>
            <app-custom-input
              [hidden]="
                !(
                  entityIdMandatory ||
                  entitiesWithEntityIdMandatory.includes(referenceFormGroup.get('entityType').value)
                )
              "
              [parentGroup]="referenceFormGroup"
              [controlName]="'entityId'"
              id="024_form_references_{{ i }}"
              [inputType]="'text'"
              [translationPrefix]="'wc.admin.references'">
            </app-custom-input>
            <div
              class="empty"
              *ngIf="
                !(
                  entityIdMandatory ||
                  entitiesWithEntityIdMandatory.includes(referenceFormGroup.get('entityType').value)
                )
              "></div>
          </div>
          <div style="display: flex; align-items: center">
            <mat-icon id="025_form_references_{{ i }}" (click)="remove(referenceFormGroup)" style="cursor: pointer"
              >delete
            </mat-icon>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
</div>
