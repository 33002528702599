import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MemberDto } from '@btl/admin-bff';
import { FormUtils, ServiceUtils } from '@btl/btl-fe-wc-common';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  MembersListingComponent,
  MemberTypes,
  OperationType,
} from '@components/product-catalogue/import-export/release-edit/members-listing/members-listing.component';
import { takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';

@Component({
  selector: 'app-members-listing-item, [app-members-listing-item]',
  templateUrl: './members-listing-item.component.html',
  styleUrls: ['./members-listing-item.component.scss'],
})
export class MembersListingItemComponent implements OnInit, OnDestroy {
  PickerInputType = PickerInputType;

  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  public member: MemberDto;

  memberBackup = {};

  @Input()
  editable = true;

  @Input()
  editMode = false;

  @Input()
  parentId = '';

  @Input()
  operations;

  @Input()
  changesMode = false;

  selected = false;

  @Output()
  readonly editMember: EventEmitter<MemberDto> = new EventEmitter<MemberDto>();

  @Output()
  readonly deleteMember: EventEmitter<MemberDto> = new EventEmitter<MemberDto>();

  @Output()
  readonly duplicateMember: EventEmitter<MemberDto> = new EventEmitter<MemberDto>();

  @Output()
  readonly editModeChanged: EventEmitter<MembersListingItemComponent> = new EventEmitter<MembersListingItemComponent>();

  techCategories: Array<string> = [];

  memberTypes = Object.keys(MemberTypes).map(key => MemberTypes[key]);

  memberForm: FormGroup = this.formBuilder.group({
    id: [null, Validators.required],
    memberType: [this.memberTypes[0], Validators.required],
    code: [null, Validators.required],
    includePromotion: [null, null],
    name: [{ value: null, disabled: true }],
  });

  constructor(private formBuilder: FormBuilder, private enumerationsService: EnumerationsService) {
    this.enumerationsService
      .getProductTechnicalCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.techCategories = result;
      });
  }

  static deleteControlError(control: AbstractControl, errorKey: string) {
    if (control.errors) {
      delete control.errors[errorKey];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  ngOnInit() {
    ServiceUtils.copyWithExclude(this.member, this.memberBackup);
    this.memberForm.patchValue(this.member);
    this.memberForm.valueChanges.subscribe(valueChange => {
      ServiceUtils.copyWithExclude(valueChange, this.member);
      this.validate();
    });
  }

  public restoreBackup() {
    ServiceUtils.copyWithExclude(this.memberBackup, this.member);
    this.memberForm.patchValue(this.member);
  }

  clicked() {
    this.edit();
  }

  public isNew() {
    return this.member[MembersListingComponent.OPERATION_TYPE] === OperationType.CREATE;
  }

  public validate() {
    FormUtils.validateAllFormFields(this.memberForm);
    if (this.memberForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  public save() {
    if (this.validate()) {
      const member = this.operations.find(member => member.id === this.member.id);

      if (member) {
        ServiceUtils.copyWithExclude(this.member, member);
      } else {
        if (!ServiceUtils.equalsWithExclude(this.member, this.memberBackup, MembersListingComponent.OPERATION_TYPE)) {
          this.member[MembersListingComponent.OPERATION_TYPE] = OperationType.UPDATE;
          this.operations.push(this.member);
        }
      }
      return true;
    }
    return false;
  }

  delete() {
    this.deleteMember.emit(this.member);
  }

  duplicate() {
    this.duplicateMember.emit(this.member);
  }

  edit() {
    this.editModeChanged.emit(this);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  clearField(memberType) {
    this.memberForm.controls.code.patchValue(null);
    this.memberForm.controls.name.patchValue(null);
    if (memberType === MemberTypes.PROD_CODE || memberType === MemberTypes.CAT_TECH) {
      this.memberForm.controls.includePromotion.patchValue(false);
    } else {
      this.memberForm.controls.includePromotion.patchValue(null);
    }
  }

  showName(): boolean {
    const type = [MemberTypes.PROD_CODE, MemberTypes.PROD_GROUP, MemberTypes.PROMOTION].find(
      find => find === this.memberForm.controls.memberType.value
    );

    if (type) {
      return true;
    }
    return false;
  }

  showIncludePromotions(): boolean {
    const type = [MemberTypes.PROD_CODE, MemberTypes.CAT_TECH].find(
      find => find === this.memberForm.controls.memberType.value
    );

    if (type) {
      return true;
    }
    return false;
  }
}
