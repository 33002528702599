import { Component } from '@angular/core';
import { AbstractFilterComponent, AdminCodebookService, CompareType, ServiceUtils } from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-codebook-filter',
  templateUrl: './codebook-filter.component.html',
})
export class CodebookFilterComponent extends AbstractFilterComponent {
  types: Array<string> = [];

  mapFieldWithCompareType = new Map([
    ['code', CompareTypeDtoEnum.LIKE],
    ['codebookType', CompareTypeDtoEnum.LIKE],
    ['priority', CompareTypeDtoEnum.EQUAL],
  ]);

  constructor(private formBuilder: FormBuilder, private adminCodebookService: AdminCodebookService) {
    super();
    this.filterForm = this.formBuilder.group({
      code: [],
      codebookType: [],
      // parent: [],
      priority: [],
    });
  }

  ngOnInit() {
    this.adminCodebookService.getCodebookTypes(ServiceUtils.getUnlimitedSearch(), null).subscribe(result => {
      this.types = result.data;
    });
    super.ngOnInit();
  }

  handleChange() {
    const priority = this.filterForm.value.priority;
    if (priority) {
      this.filterForm.controls.priority.patchValue(Number(priority));
    }
    super.handleChange();
  }
}
