import { Component, Input, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { GroupProductsListingItemComponent } from './group-products-listing-item/group-products-listing-item.component';
import { ProductsSelectModalComponent } from '@components/products-select-modal/products-select-modal.component';
import {
  AdminProductService,
  AppBlockerService,
  CompareType,
  PaginationComponent,
  Search
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GroupProductDto, PagedProductsDto, ProductDto } from '@btl/admin-bff';
import paginate from 'jw-paginate';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import {
  GroupSubsetProductsComponent,
} from '@components/product-catalogue/product-group/product-group-edit/group-products-listing/group-subset-products/group-subset-products.component';
import { MatPaginator } from '@angular/material/paginator';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-group-products-listing, [app-group-products-listing]',
  templateUrl: './group-products-listing.component.html',
})
export class GroupProductsListingComponent implements OnInit, OnDestroy {
  productMap = new Map();

  @Input()
  formArray: FormArray;

  @Input()
  groupProducts: GroupProductDto[];

  @ViewChildren(GroupProductsListingItemComponent)
  groupProductsListingItemComponents: Array<GroupProductsListingItemComponent>;

  isCollapsed = false;

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 50,
    },
    fields: ['currentPage', 'totalItems', 'totalPages', 'pageSize', 'data.description', 'data.productCode'],
  };

  private onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild(PaginationComponent, { static: true })
  paginationComponent: PaginationComponent;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  pagedGroupProducts: GroupProductDto[];

  pagedProductsDto: PagedProductsDto;

  constructor(
    private dialog: MatDialog,
    private adminProductService: AdminProductService,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  ngOnInit() {
    this.loadProducts();
  }

  public loadProducts() {
    if (!this.groupProducts) {
      this.groupProducts = [];
      this.pagedGroupProducts = [];
    } else {
      const page = this.search.paging.page;
      const pager = paginate(this.groupProducts.length, page, this.search.paging.pageSize);
      this.paginator.pageIndex = pager.currentPage - 1;
      this.pagedGroupProducts = this.groupProducts.slice(pager.startIndex, pager.endIndex + 1);
      const productCodes = this.pagedGroupProducts.map(groupProductDto => groupProductDto.productMasterId);
      if (productCodes.length > 0) {
        this.search.filtering = [
          {
            column: 'productCode',
            compareType: CompareTypeDtoEnum.IN,
            value: productCodes,
          },
        ];

        this.appBlockerService.block();
        this.search.paging.page = 1;
        this.adminProductService
          .getProductsByFilter(this.search)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.pagedProductsDto = result;
            this.productMap = new Map();
            result.data.forEach(product => {
              this.productMap.set(product.productCode, product);
            });
          });
        this.search.paging.page = page;
      }
    }
  }

  isOnPage(groupProductDto): boolean {
    return this.productMap.get(groupProductDto.controls.productMasterId.value) ? true : false;
  }

  deleteCheckedProduct() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.products.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.getSelectedGroupProducts().forEach(selectedGroupProduct => {
        for (let i = 0; i <= this.groupProducts.length; i++) {
          const stickerProductDto = this.groupProducts[i];
          if (stickerProductDto.productMasterId === selectedGroupProduct.productMasterId) {
            this.groupProducts.splice(i, 1);
            for (let j = 0; j <= this.formArray.controls.length; j++) {
              const formGroup = this.formArray.controls[j] as FormGroup;
              if (formGroup.get('productMasterId').value === selectedGroupProduct.productMasterId) {
                this.formArray.removeAt(j);
                break;
              }
            }
            break;
          }
        }
      });
      this.loadProducts();
      confirmationDialogComponent.dialogReference.close();
    };
  }

  getSelectedGroupProducts(): Array<GroupProductDto> {
    const selectedProductGroups = [];
    this.groupProductsListingItemComponents.forEach(groupProductsListingItemComponent => {
      if (groupProductsListingItemComponent.selected) {
        selectedProductGroups.push(groupProductsListingItemComponent.groupProduct);
      }
    });
    return selectedProductGroups;
  }

  addProduct() {
    this.isCollapsed = false;
    const modalRef = this.dialog.open(ProductsSelectModalComponent);
    const productsSelectModalComponent = modalRef.componentInstance;
    productsSelectModalComponent.dialogRef = modalRef;
    productsSelectModalComponent.isModal = true;
    productsSelectModalComponent.disableActions = true;
    productsSelectModalComponent.disableListingActions = true;
    productsSelectModalComponent.disableExpand = true;
    productsSelectModalComponent.nestedTable = false;
    productsSelectModalComponent.selectMode = true;
    productsSelectModalComponent.selectHandler = products => {
      products.forEach(product => {
        if (this.getGroupProductComponent(product.productCode) === null) {
          const lastSeq = this.groupProducts.length > 0 ? this.groupProducts[this.groupProducts.length - 1].seq : 0;
          const newGroupProduct = {
            seq: lastSeq < GroupSubsetProductsComponent.GROUP_PRODUCT_UNSET_PRIORITY ? GroupSubsetProductsComponent.GROUP_PRODUCT_UNSET_PRIORITY : lastSeq + 1,
            productMasterId: product.productCode,
            productLabel: product.description,
          };
          this.groupProducts.push(newGroupProduct);
          this.addProductToFormArray(newGroupProduct);
          this.pagedGroupProducts.push(newGroupProduct);
          this.productMap.set(product.productCode, product);
        }
      });
      this.loadProducts();
    };
  }

  addProductToFormArray(product) {
    const productFromGroup = this.formBuilder.group(GroupProductsListingItemComponent.getFormControlConfig());
    productFromGroup.patchValue(product);
    this.formArray.push(productFromGroup);
  }

  getGroupProductComponent(productMasterId: string): GroupProductsListingItemComponent {
    let retGroupProductsListingItemComponent = null;
    this.groupProductsListingItemComponents.forEach(groupProductsListingItemComponent => {
      if (productMasterId === groupProductsListingItemComponent.groupProductForm.controls.productMasterId.value) {
        retGroupProductsListingItemComponent = groupProductsListingItemComponent;
      }
    });

    return retGroupProductsListingItemComponent;
  }

  onNext() {
    this.search.paging.page++;
    this.loadProducts();
  }

  goToPage(pageNo: number) {
    this.search.paging.page = pageNo;
    this.loadProducts();
  }

  onPrev() {
    this.search.paging.page--;
    this.loadProducts();
  }

  pageSizeChanged(page): void {
    this.search.paging.page = page.pageIndex + 1;
    this.search.paging.pageSize = page.pageSize;
    this.loadProducts();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  productChanged(product: ProductDto) {
    this.productMap.set(product.productCode, product);
  }
}
