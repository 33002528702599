import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractFilterComponent, CompareType } from '@btl/btl-fe-wc-common';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-product-ratings-filter',
  templateUrl: './product-ratings-filter.component.html',
})
export class ProductRatingsFilterComponent extends AbstractFilterComponent {

  mapFieldWithCompareType = new Map([
    ['reviewedFrom', CompareTypeDtoEnum.BIGGEREQ],
    ['reviewedTo', CompareTypeDtoEnum.SMALLEREQ],
    ['ratingFrom', CompareTypeDtoEnum.BIGGEREQ],
    ['ratingTo', CompareTypeDtoEnum.SMALLEREQ],
    ['recommended', CompareTypeDtoEnum.EQUAL],
    ['extId', CompareTypeDtoEnum.LIKE],
  ]);

  mapFieldWithColumnName = new Map([
    ['reviewedFrom', 'reviewedOn'],
    ['reviewedTo', 'reviewedOn'],
    ['ratingFrom', 'rating'],
    ['ratingTo', 'rating'],
  ]);

  constructor(private formBuilder: FormBuilder) {
    super();
    this.filterForm = this.formBuilder.group({
      reviewedFrom: [],
      reviewedTo: [],
      ratingFrom: [, Validators.pattern('^[0-9]*$')],
      ratingTo: [, Validators.pattern('^[0-9]*$')],
      recommended: [],
      extId: [],
    });
  }
}
