import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppBlockerService, ErrorService, Failure, Failures } from '@btl/btl-fe-wc-common';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  providers: [Location, Clipboard],
})
export class ErrorPageComponent implements OnInit {
  error: HttpErrorResponse;
  errorCode = 404;
  failures: Array<Failure> = new Array<Failure>();
  causedByMessage;
  showDetail: boolean = false;

  routeSubscribe;

  constructor(
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private appBlockerService: AppBlockerService,
    private location: Location,
    protected errorService: ErrorService
  ) {}

  ngOnInit() {
    //BTL-3594 Unblock UI when redirected to error-page
    this.appBlockerService.unblock();
    this.routeSubscribe = this.route.queryParams.subscribe(params => {
      this.showDetail = false;
      const errorJson = this.errorService.getLastError().error;
      if (errorJson) {
        this.error = errorJson;
        this.errorCode = this.error.status;
        const failures: Failures = this.error.error;
        if (failures) {
          this.failures = failures.failures;
          if (this.failures && this.failures.length > 0) {
            if (this.failures[this.failures.length - 1].contextData) {
              this.causedByMessage = this.failures[this.failures.length - 1].contextData['causedBy.message'];
            } else {
              this.causedByMessage = false;
            }
          }
        }
      } else {
        const failure: Failure = <Failure>{
          code: 404,
          detail: 'Page not found',
          localizedDescription: "Page you're looking for is not available now.",
        };
        this.failures.push(failure);
      }
    });
  }

  getFailures() {
    this.clipboard.copy(document.getElementById('failures').innerText);
  }

  getIcon(errorCode) {
    let errorIcon;
    switch (errorCode) {
      case 401:
        errorIcon = 'error401';
        break;
      case 403:
        errorIcon = 'error401';
        break;
      case 404:
        errorIcon = 'error404';
        break;
      default:
        errorIcon = 'error5xx';
        break;
    }
    return errorIcon;
  }

  goBack(): void {
    this.location.back();
  }
}
