<div class="main-table-listing">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    multiTemplateDataRows
    (matSortChange)="matSortChange()"
    class="table"
    [ngClass]="{ 'table-box': expandType === 'boxes' }">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="30" [hidden]="!multiSelectMode">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [id]="'000_table-listing-checkbox' + suffix"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" [style.width.px]="30" [hidden]="!multiSelectMode">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          [id]="row[fillIdBy] + '_table-listing-checkbox' + suffix"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- Toggle arrow column -->
    <ng-container matColumnDef="toggleArrow">
      <th mat-header-cell *matHeaderCellDef [style.width.px]="50"></th>
      <td mat-cell *matCellDef="let row" [style.width.px]="50">
        <button
          mat-icon-button
          (click)="$event.stopPropagation(); expand(row)"
          (dblclick)="$event.stopPropagation()"
          [routerLink]="">
          <mat-icon *ngIf="isExpandable(row)">{{
            expandedElement(row) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
          }}</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Rest of columns -->
    <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isDisabledSort(col)"
        [ngClass]="{ 'content-desktop': isRowDesktop(col) }">
        {{ getColumn(col) | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="{{'col-count-' + displayedColumns.length}}"
        [ngClass]="{ 'text-truncate': isRowTruncate(col), 'default-max-width': !getTruncatedColumnWidth(col), 'content-desktop': isRowDesktop(col) }">
        <ng-container *ngIf="showAsText(element, col); else showIcon">
          <ng-container *ngIf="isDefaultInput(element, col); else checkBox">
            <div [ngClass]="{ 'border-highlight': isHighLighted(element, col) }">
              <span
                [matTooltip]="getElementTooTip(element, col)"
                matTooltipClass="table-listing-form-cell-tooltip"
                matTooltipShowDelay="700"
                [style.max-width]="getTruncatedColumnWidth(col)">
                {{ getElement(element, col) }}
              </span>
              <div class="border-highlight-icon">
                <mat-icon
                  *ngIf="isHighLighted(element, col)"
                  svgIcon="duplicity"
                  matTooltip="Overwrite parent value"></mat-icon>
              </div>
            </div>
          </ng-container>
          <ng-template #checkBox>
            <span [style.max-width]="getTruncatedColumnWidth(col)">
              <mat-checkbox disabled [checked]="getElement(element, col)"></mat-checkbox>
            </span>
          </ng-template>
        </ng-container>
        <ng-template #showIcon>
          <div class="table-icon">
            <mat-icon
              svgIcon="{{ getElement(element, col).toLocaleString().toLowerCase() }}"
              matTooltip="{{ getElement(element, col) }}"></mat-icon>
          </div>
        </ng-template>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail" *ngIf="nestedTable">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <ng-container *ngIf="expandedElement(element)">
          <div
            class="element-detail"
            *ngIf="this.expandedElements.get(element) as childData"
            [@detailExpand]="expandedElement(element) ? 'expanded' : 'collapsed'">
            <ng-container *ngIf="expandType === 'table'; else boxes">
              <div class="inner-table mat-elevation-z8" *ngIf="expandedElement(element)">
                <table
                  #innerTables
                  mat-table
                  #innerSort="matSort"
                  [dataSource]="childData"
                  matSort
                  multiTemplateDataRows
                  (matSortChange)="matSortChangeChild(childData)">
                  <ng-container matColumnDef="{{ innerColumn }}" *ngFor="let innerColumn of childColumnName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ getColumn(innerColumn) | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ getElement(element, innerColumn) }}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="childColumnName"></tr>
                  <tr mat-row *matRowDef="let row; columns: childColumnName" (dblclick)="doubleClick(row)"></tr>
                </table>
              </div>
            </ng-container>
            <ng-template #boxes>
              <!--Dynamic boxes-->
              <ng-container>
                <div *ngIf="module === 'products'; else defaultBoxes" class="listing-box">
                  <div
                    *ngFor="let data of childData.data"
                    class="box-item"
                    [ngStyle]="{ background: getBackgroundColor(data) }"
                    (dblclick)="edit(data)">
                    <div class="box-item-inner">
                      <div class="item-version">{{ data.version }}</div>
                      <div class="item-state">
                        <div class="item-state__icon">
                          <div class="border-icon">
                            <mat-icon
                              [svgIcon]="getVersionIcon(data.state)"
                              [ngClass]="{ versionActive: data.state === 'ACTIVE' }"></mat-icon>
                          </div>
                        </div>
                        {{ data.state }}
                      </div>
                      <div class="item-description-box">
                        <div class="dates-box">
                          <div>{{ data.availableFor.startDateTime | date: 'dd.MM.yyyy HH:mm' }}</div>
                          <mat-icon>arrow_forward </mat-icon>
                          <div>{{ data.availableFor.endDateTime | date: 'dd.MM.yyyy HH:mm' }}</div>
                        </div>
                        <div class="item-description">
                          <div>{{ data.modified | date: 'dd.MM.yyyy HH:mm' }}</div>
                          <div>{{ data.modifiedBy }}</div>
                        </div>
                      </div>
                    </div>
                    <div (click)="$event.stopPropagation()" (dblclick)="$event.stopPropagation()" class="no-selection">
                      <button [matMenuTriggerFor]="menu" mat-icon-button class="table-action-menu-icon">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu" class="module-actions">
                        <button mat-menu-item (click)="edit(data)" [routerLink]="">
                          <mat-icon svgIcon="show"></mat-icon>
                          <span>{{ 'wc.admin.products.product.show' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="editInNewTab(data)" [routerLink]="">
                          <mat-icon svgIcon="externalLink"></mat-icon>
                          <span>{{ 'wc.admin.editInNewTab' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="approve(data)" *ngIf="data.state === 'TEST'" [routerLink]="">
                          <mat-icon svgIcon="accept"></mat-icon>
                          <span>{{ 'wc.admin.products.product.approve' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="reject(data)" *ngIf="data.state !== 'ACTIVE'" [routerLink]="">
                          <mat-icon svgIcon="refuse"></mat-icon>
                          <span>{{ 'wc.admin.products.product.reject' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="duplicate(data)" [routerLink]="">
                          <mat-icon svgIcon="duplicate"></mat-icon>
                          <span>{{ 'wc.admin.duplicate' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="archive(data)" *ngIf="data.state === 'ACTIVE'" [routerLink]="">
                          <mat-icon svgIcon="archive"></mat-icon>
                          <span>{{ 'wc.common.archive' | translate }}</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
                <ng-template #defaultBoxes>
                  <div *ngFor="let data of childData.data" class="box-item">
                    <div *ngFor="let innerColumn of childColumnName">
                      {{ getElement(data, innerColumn) }}
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </ng-template>
          </div>
        </ng-container>
      </td>
    </ng-container>
    <!-- Row -->
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnsToDisplay"
      [hidden]="isLoading"
      class="{{getRowClassCallback ? getRowClassCallback(row) : ''}}"
      [class.element-row]="row"
      [class.expanded-row]="expandedElement(row)"
      (click)="click(row)"
      (dblclick)="!disabledParentClickEvent ? doubleClick(row) : null"></tr>
    <ng-container *ngIf="nestedTable">
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
        [ngClass]="{ 'row-expanded': expandedElement(row) }"></tr>
    </ng-container>
    <!-- Actions column -->
    <ng-container matColumnDef="actions" *ngIf="!disableActions">
      <th mat-header-cell *matHeaderCellDef class="table-actions"></th>
      <ng-container *ngIf="expandType === 'table'; else boxes">
        <td mat-cell *matCellDef="let row" class="table-actions">
          <div class="table-actions-container">
            <div class="actions-menu">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  *ngIf="module !== 'export' && !disableEditButton"
                  mat-menu-item
                  class="table-actions-button"
                  (click)="$event.stopPropagation(); edit(row)"
                  [routerLink]=""
                  matTooltip="{{ 'wc.admin.edit' | translate }}">
                  <mat-icon
                    *ngIf="editable"
                    class="default-icon-gray-fill default-icon-small no-stroke"
                    svgIcon="edit"></mat-icon>
                  <mat-icon
                    *ngIf="!editable"
                    class="default-icon-gray-fill default-icon-small no-stroke"
                    svgIcon="show"></mat-icon>
                  {{ 'wc.admin.edit' | translate }}
                </button>
                <ng-container *ngIf="module !== 'accountEntity' && module !== 'export' && !disableEditInNewTabButton">
                  <button
                    class="table-actions-button"
                    mat-menu-item
                    (click)="$event.stopPropagation(); editInNewTab(row)"
                    [routerLink]="">
                    <mat-icon class="default-icon-gray-fill default-icon-small no-stroke" svgIcon="externalLink"
                      >open_in_new</mat-icon
                    >
                    {{ 'wc.admin.editInNewTab' | translate }}
                  </button>
                </ng-container>
                <ng-container *ngIf="module === 'export'">
                  <button
                    class="table-actions-button"
                    mat-menu-item
                    *ngIf="!showCustomButtonCallback || showCustomButtonCallback(row)"
                    (click)="$event.stopPropagation(); customEvent(row)"
                    [routerLink]=""
                    matTooltip="{{ 'wc.admin.export.download' | translate }}">
                    <mat-icon class="default-icon-gray-fill default-icon-small no-stroke" svgIcon="download"></mat-icon>
                    {{ 'wc.admin.export.download' | translate }}
                  </button>
                </ng-container>
                <ng-container *ngIf="module === 'accountEntity'">
                  <button
                    class="table-actions-button"
                    *ngIf="grantedMode"
                    mat-menu-item
                    (click)="$event.stopPropagation(); customEvent(row)"
                    [routerLink]=""
                    matTooltip="{{ 'wc.admin.accountEntity.delegate' | translate }}">
                    <mat-icon>people</mat-icon>
                    {{ 'wc.admin.accountEntity.delegate' | translate }}
                  </button>
                </ng-container>
                <button
                  class="table-actions-button"
                  mat-menu-item
                  *ngIf="editable && module !== 'export' && !disableDeleteButton"
                  (click)="$event.stopPropagation(); delete(row)"
                  [routerLink]=""
                  matTooltip="{{ 'wc.admin.delete' | translate }}">
                  <mat-icon class="default-icon-gray-fill default-icon-small no-stroke" svgIcon="delete"></mat-icon>
                  {{ 'wc.admin.delete' | translate }}
                </button>
              </mat-menu>
            </div>
            <div class="actions-list">
              <button
                *ngIf="module !== 'export' && !disableEditButton"
                mat-icon-button
                class="table-actions-button"
                (click)="$event.stopPropagation(); edit(row)"
                [routerLink]=""
                matTooltip="{{ 'wc.admin.edit' | translate }}">
                <mat-icon
                  *ngIf="editable"
                  class="default-icon-gray-fill default-icon-small no-stroke"
                  svgIcon="edit"></mat-icon>
                <mat-icon
                  *ngIf="!editable"
                  class="default-icon-gray-fill default-icon-small no-stroke"
                  svgIcon="show"></mat-icon>
              </button>
              <ng-container *ngIf="module !== 'accountEntity' && module !== 'export' && !disableEditInNewTabButton">
                <button
                  class="table-actions-button"
                  mat-icon-button
                  (click)="$event.stopPropagation(); editInNewTab(row)"
                  matTooltip="{{ 'wc.admin.editInNewTab' | translate }}"
                  [routerLink]="">
                  <mat-icon
                    class="default-icon-gray-fill default-icon-small no-stroke"
                    svgIcon="externalLink"></mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="module === 'export'">
                <button
                  class="table-actions-button"
                  mat-icon-button
                  *ngIf="!showCustomButtonCallback || showCustomButtonCallback(row)"
                  (click)="$event.stopPropagation(); customEvent(row)"
                  [routerLink]=""
                  matTooltip="{{ 'wc.admin.export.download' | translate }}">
                  <mat-icon class="default-icon-gray-fill default-icon-small no-stroke" svgIcon="download"></mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="module === 'accountEntity'">
                <button
                  class="table-actions-button"
                  *ngIf="grantedMode && showCustomButtonDelegate(row)"
                  mat-icon-button
                  (click)="$event.stopPropagation(); customEvent(row)"
                  [routerLink]=""
                  matTooltip="{{ 'wc.admin.accountEntity.delegate' | translate }}">
                  <mat-icon>people</mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="module === 'notification'">
                <button
                  class="table-actions-button"
                  mat-icon-button
                  (click)="$event.stopPropagation(); customEvent(row)"
                  [routerLink]="">
                  <mat-icon>send</mat-icon>
                </button>
              </ng-container>
              <button
                class="table-actions-button"
                mat-icon-button
                *ngIf="editable && module !== 'export' && !disableDeleteButton"
                (click)="$event.stopPropagation(); delete(row)"
                [routerLink]=""
                matTooltip="{{ 'wc.admin.delete' | translate }}">
                <mat-icon class="default-icon-gray-fill default-icon-small no-stroke" svgIcon="delete"></mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-template #boxes>
        <td mat-cell *matCellDef="let row" [style.width.px]="160">
          <button
            mat-icon-button
            (click)="$event.stopPropagation(); expand(row)"
            (dblclick)="$event.stopPropagation()"
            [routerLink]="">
            <mat-icon style="font-size: 15px; line-height: 15px; width: 18px; height: 18px" svgIcon="show"></mat-icon>
          </button>
        </td>
      </ng-template>
    </ng-container>
    <!-- Empty row -->
    <ng-container *matNoDataRow>
      <td class="table-loading" [colSpan]="columnsToDisplay.length">
        <ng-container *ngIf="isLoading; else loadedNoResult">
          <app-skeleton-loader
            class="skeleton-loading"
            [count]="skeletonLoadingCount"
            loadingType="table"
            animation="progress-dark"></app-skeleton-loader>
        </ng-container>
        <ng-template #loadedNoResult>
          <div *ngIf="this.dataSource.data.length === 0">
            <div class="table-no-records">
              <div class="table-no-records-icon">
                <mat-icon>cloud_off</mat-icon>
              </div>
              <div class="table-no-records">
                <div class="table-no-records-title">{{ 'wc.admin.table-listing.noRecords.title' | translate }}</div>
                <div class="table-no-records-description">
                  {{ 'wc.admin.table-listing.noRecords.description' | translate }}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </td>
    </ng-container>
  </table>
  <mat-paginator
    *ngIf="enablePaginator && !isLoading && this.dataSource.data.length > 0"
    [length]="getLength()"
    [pageSize]="cacheFilter?.paging?.pageSize ? cacheFilter?.paging?.pageSize : 50"
    (page)="onPageEvent($event)"
    [pageIndex]="getPageIndex()"
    showFirstLastButtons="true"
    [pageSizeOptions]="[10, 25, 50, 100]"
    color="primary">
  </mat-paginator>
</div>
