import { Component, OnInit } from '@angular/core';
import { PictureTypeDto, ProductDto } from '@btl/admin-bff';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductEditService } from '@service/product-edit.service';
import { AbstractPageComponent, AdminPictureTypeService, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import { FormPicturesComponent } from '@components/form-pictures/form-pictures.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-product-pictures',
  templateUrl: './product-pictures.component.html',
  styleUrls: ['./product-pictures.component.scss'],
})
@EnableDynamicLoading({ customName: ProductPicturesComponent.PAGE_ID })
export class ProductPicturesComponent extends AbstractPageComponent implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductPicturesComponent';

  pageId(): string {
    return ProductPicturesComponent.PAGE_ID;
  }

  form: FormGroup = this.formBuilder.group({
    pictures: [],
  });

  product: ProductDto;
  pictureTypes: Array<PictureTypeDto>;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private productEditService: ProductEditService,
    private adminPictureTypeService: AdminPictureTypeService
  ) {
    super(router, route);
    this.adminPictureTypeService
      .getPictureTypes()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(pictureTypes => {
        this.pictureTypes = pictureTypes.filter(pictureTypeDto => pictureTypeDto.id.startsWith('PRODUCT_'));
        if (this.pictureTypes.length === 0) {
          this.pictureTypes = pictureTypes;
        }
      });
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit() {
    this.product = this.productEditService.getEditProduct();
    this.form.patchValue(this.product);
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(product => {
      this.product = product;
      this.form.patchValue(this.product);
    });
  }

  saveData(): boolean {
    if (!this.productEditService.canEdit()) {
      return true;
    }

    const canSave = true;

    if (canSave) {
      const pictures = this.form.getRawValue().pictures;
      this.productEditService.pictureBulkOperations = FormPicturesComponent.getDmsBulk(pictures);
      this.productEditService.getEditProduct().pictures = pictures;
    } else {
      return false;
    }

    return true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.productEditService.resetMode) {
      if (this.saveData()) {
        return true;
      } else {
        return false;
      }
    }

    this.productEditService.resetMode = false;

    return true;
  }
}
