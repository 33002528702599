<div class="main__header" *ngIf="!simpleVersion">
  <div class="header-box">
    <div class="icon-box TICKETING">
      <mat-icon svgIcon="tickets"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.tickets' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      (click)="newTicket()"
      [routerLink]=""
      id="001_ticket_listing"
      [title]="'wc.common.new' | translate"
      *ngIf="aclService.pageEditAccess"
      mat-raised-button
      color="primary"
      class="btn -primary -xs"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>
<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-ticket-filter
        *ngIf="!simpleVersion"
        [cacheFilterUsed]="cacheFilterUsed"
        (filterChanged)="handleFilterChange()"
        [filtering]="search.filtering"></app-ticket-filter>
    </div>
    <div class="card__inner">
      <div class="card__body">
        <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
          <div fxLayout="column">
            <span class="listing-header">{{ 'wc.admin.tickets.form.list' | translate }}</span>
            <div class="paginator__entries">
              {{
                'wc.common.pagination.showing'
                  | translate
                    : {
                        from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                        to:
                          search.paging.pageSize * search.paging.page > pagedTicketsDto?.totalItems
                            ? pagedTicketsDto?.totalItems
                            : search.paging.pageSize * search.paging.page,
                        of: pagedTicketsDto?.totalItems
                      }
              }}
            </div>
          </div>
          <div fxLayoutGap="10px" class="listing-action">
            <button
              id="002_ticket_listing"
              color="primary"
              mat-stroked-button
              [disabled]="getSelectedData().length === 0"
              (click)="deleteCheckedTickets()"
              *ngIf="aclService.pageEditAccess"
              [routerLink]=""
              class="btn -primary -xs icon delete currentColor">
              <mat-icon svgIcon="delete"></mat-icon>
              {{ 'wc.admin.delete' | translate }}
            </button>
          </div>
        </div>
        <div class="card__body">
          <app-table-listing-form
            [columnName]="['type.code', 'id', 'subject', 'priority', 'businessState', 'created', 'assignedTo']"
            [columnToTruncate]="['type.code', 'id', 'created']"
            [dontShowOnMobile]="['created']"
            [fillIdBy]="'id'"
            [pagedDto]="pagedTicketsDto"
            (editButtonEvent)="editTicket($event)"
            (editInNewTabButtonEvent)="editTicket($event, true)"
            (deleteButtonEvent)="deleteTicket($event)"
            (doubleClickEvent)="editTicket($event)"
            (pageSizeEvent)="pageSizeChanged($event)"
            (sortChangeEvent)="onSortChange($event)"
            [cacheFilter]="this.search"
            [editable]="aclService.pageEditAccess"
            [translationPrefix]="'wc.admin.ticket'">
          </app-table-listing-form>
        </div>
      </div>
    </div>
  </div>
</div>
