import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { GroupProductDto } from '@btl/admin-bff';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Search, StickyMessageService } from '@btl/btl-fe-wc-common';
import { Clipboard } from '@angular/cdk/clipboard';
import { SortDto } from '@btl/order-bff';
import { MatDialog } from '@angular/material/dialog';
import {
  GroupProductsListingItemComponent,
} from '@components/product-catalogue/product-group/product-group-edit/group-products-listing/group-products-listing-item/group-products-listing-item.component';

interface SelectedSubProductItem {
  productCode: string;
  isDuplicity: boolean;
  isNewInGroup: boolean;
  productLabel?: string;
}

interface HintProduct {
  productCode: string;
  productLabel?: string;
}

@Component({
  selector: 'app-group-subset-products, [app-group-subset-products]',
  templateUrl: './group-subset-products.component.html',
})
export class GroupSubsetProductsComponent implements OnInit, OnChanges {
  public static readonly GROUP_PRODUCT_UNSET_PRIORITY: number = 999;
  private static readonly HINT_PRODUCTS_MAX_COUNT: number = 10;
  private static readonly LIST_VALUE_SEPARATOR = ',';

  private static GROUP_PRODUCT_COMPARATOR = (a: GroupProductDto, b: GroupProductDto): number => {
    if (!b.seq && b.seq !== 0) {
      return -1;
    }
    if (b.seq === GroupSubsetProductsComponent.GROUP_PRODUCT_UNSET_PRIORITY) {
      return -1;
    }
    return a.seq - b.seq;
  };

  @Input()
  subsetName: string;

  @Input()
  allGroupProducts: GroupProductDto[];

  @Input()
  connectWithAllGroupProducts = true;

  @Input()
  formArray: FormArray;

  @Input()
  maxSubProducts: number;

  originalGroupProducts;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  topProductsControl = new FormControl();
  selectedSubProductItems: SelectedSubProductItem[] = [];
  hintProducts: HintProduct[] = [];

  @ViewChild('topProductsInput') topProductsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  search: Search = {
    filtering: [],
    sorting: [{ column: 'priority', sortOrder: SortDto.SortOrderDtoEnum.Asc }],
    paging: {
      page: 1,
      pageSize: GroupSubsetProductsComponent.HINT_PRODUCTS_MAX_COUNT,
    },
    fields: ['data.productCode'],
  };

  constructor(
    private clipboard: Clipboard,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private stickyMessageService: StickyMessageService
  ) {
  }

  ngOnInit(): void {
    this.originalGroupProducts = [...this.allGroupProducts];
    this.initSubProducts(this.formArray.getRawValue());
  }

  ngOnChanges(changes: SimpleChanges) {
    const allGroupProductsChanges = changes['allGroupProducts'];
    if (
      allGroupProductsChanges &&
      allGroupProductsChanges.currentValue &&
      allGroupProductsChanges.currentValue.length !== allGroupProductsChanges.previousValue?.length
    ) {
      setTimeout(() => this.loadHintProducts(), 100);
    }
  }

  addSubProductCode(event: MatChipInputEvent): void {
    this.pushSelectedSubProductCode(event.value, event.value);
    this.loadHintProducts();
    this.handleSubProductsChange();
    event.input.value = '';
    this.topProductsControl.setValue('');
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.pushSelectedSubProductCode(event.option.value.productCode, event.option.value.productLabel);
    this.topProductsInput.nativeElement.value = '';
    this.topProductsControl.setValue('');
    this.loadHintProducts();
    this.handleSubProductsChange();
  }

  onPaste(event: ClipboardEvent) {
    const pasteValue = event.clipboardData.getData('text');
    if (pasteValue) {
      if (pasteValue.includes(GroupSubsetProductsComponent.LIST_VALUE_SEPARATOR)) {
        pasteValue
          .split(GroupSubsetProductsComponent.LIST_VALUE_SEPARATOR)
          .forEach(value => this.pushSelectedSubProductCode(value, null));
      } else {
        this.pushSelectedSubProductCode(pasteValue, null);
      }

      this.loadHintProducts();
      this.handleSubProductsChange();
    }

    this.topProductsInput.nativeElement.value = '';
    this.topProductsControl.setValue('');
    event.preventDefault();
  }

  acceptNewProduct(acceptedProductCode: string) {
    if (this.isNewProductInGroup(acceptedProductCode)) {
      this.selectedSubProductItems
        .filter(i => i.productCode === acceptedProductCode)
        .forEach(i => (i.isNewInGroup = false));
      this.handleSubProductsChange();
    }
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.selectedSubProductItems, event.previousContainer.data.index, event.container.data.index);
    if (!this.connectWithAllGroupProducts) {
      moveItemInArray(this.formArray.controls, event.previousContainer.data.index, event.container.data.index);
    }
    this.markSubProductsDuplicities();
    this.handleSubProductsChange();
  }

  removeSubProductCode(index: number): void {
    this.selectedSubProductItems.splice(index, 1);
    if (!this.connectWithAllGroupProducts) {
      this.formArray.removeAt(index);
    }
    this.loadHintProducts();
    this.markSubProductsDuplicities();
    this.handleSubProductsChange();
  }

  onInputChange(inputValue: string) {
    this.loadHintProducts(inputValue);
  }

  copySubProducts() {
    if (this.selectedSubProductItems) {
      const copySubProducts = [...new Set(this.selectedSubProductCodes)].join(
        GroupSubsetProductsComponent.LIST_VALUE_SEPARATOR
      );
      const copied = this.clipboard.copy(copySubProducts);
      if (copied) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.productGroups.topProducts.copied');
      }
    }
  }

  clearSubProductItems(clearOnlyDuplicities = false) {
    if (clearOnlyDuplicities) {
      this.selectedSubProductItems = this.selectedSubProductItems.filter(i => !i.isDuplicity);
    } else {
      this.selectedSubProductItems = [];
    }
    this.loadHintProducts();
    this.handleSubProductsChange();
  }

  highlightAutocomplete(optionItem: string): string {
    const highlightValue = this.topProductsControl.value;
    if (highlightValue) {
      return optionItem.replace(highlightValue, `<b>${highlightValue}</b>`);
    }
    return optionItem;
  }

  get selectedSubProductCodes(): string[] {
    return this.selectedSubProductItems.map(i => i.productCode);
  }

  existsAnyDuplicityItem(): boolean {
    return this.selectedSubProductItems.some(i => i.isDuplicity);
  }

  private isNewProductInGroup(productCode: string): boolean {
    return !this.allGroupProducts.some(gp => gp.productMasterId === productCode);
  }

  private initSubProducts(groupProducts: GroupProductDto[]) {
    this.selectedSubProductItems.length = 0;
    let filteredProductGroups = groupProducts;
    if (this.connectWithAllGroupProducts) {
      filteredProductGroups = filteredProductGroups
        .filter(gp => GroupSubsetProductsComponent.isValidSeqNumber(gp.seq))
        .sort(GroupSubsetProductsComponent.GROUP_PRODUCT_COMPARATOR);
    }
    filteredProductGroups.forEach(gp => this.pushSelectedSubProductCode(gp.productMasterId, gp.productLabel));
    if (JSON.stringify(this.originalGroupProducts) !== JSON.stringify(groupProducts)) {
      // e.g. Add/Remove product in "Used products in group"
      const productCodes = new Set<string>(groupProducts.map(gp => gp.productMasterId));
      this.selectedSubProductItems
        .filter(topPc => productCodes.has(topPc.productCode))
        .forEach(topPc => (topPc.isNewInGroup = false));
    }

    this.handleSubProductsChange();
    this.loadHintProducts();
  }

  private handleSubProductsChange() {
    const newGroupProducts = this.selectedSubProductItems
      .filter(topPc => !topPc.isDuplicity)
      .filter(topPc => !topPc.isNewInGroup)
      .map((topPc, index): GroupProductDto => {
        return {
          seq: index,
          productMasterId: topPc.productCode,
          productLabel: topPc.productLabel,
        };
      });
    const newGroupProductsSet = new Set<string>(newGroupProducts.map(gp => gp.productMasterId));

    if (this.connectWithAllGroupProducts) {
      let startSeq = GroupSubsetProductsComponent.GROUP_PRODUCT_UNSET_PRIORITY;
      this.allGroupProducts
        .filter(gp => !newGroupProductsSet.has(gp.productMasterId))
        .forEach(gp => {
          gp.seq = startSeq;
          startSeq++;
          newGroupProducts.push(gp);
        });
    }

    this.formArray.controls = [];
    newGroupProducts.forEach(gp => {
      const productFromGroup = this.formBuilder.group(GroupProductsListingItemComponent.getFormControlConfig(gp));
      this.formArray.controls.push(productFromGroup);
    });
  }

  private loadHintProducts(likeValue?: string) {
    this.hintProducts.length = 0;
    if (!(this.selectedSubProductCodes?.length < this.maxSubProducts)) {
      return;
    }

    // hint products from group at first
    const selectedProductCodes = new Set<string>(this.selectedSubProductCodes);
    this.allGroupProducts
      .filter(gp => !selectedProductCodes.has(gp.productMasterId))
      .filter(
        gp =>
          !likeValue ||
          gp.productMasterId.toLowerCase().includes(likeValue.toLowerCase()) ||
          gp.productLabel.toLowerCase().includes(likeValue.toLowerCase())
      )
      .sort(GroupSubsetProductsComponent.GROUP_PRODUCT_COMPARATOR)
      // .slice(0, GroupSubProductsComponent.HINT_PRODUCTS_MAX_COUNT)
      .forEach(gp => this.hintProducts.push({productCode: gp.productMasterId, productLabel: gp.productLabel}));

    // if (this.hintProductCodes.length < GroupSubProductsComponent.HINT_PRODUCTS_MAX_COUNT) {
    //   // search and hint products outside the group
    //   this.search.filtering = [];
    //   this.search.paging.pageSize = GroupSubProductsComponent.HINT_PRODUCTS_MAX_COUNT - this.hintProductCodes.length;
    //   if (likeValue) {
    //     this.search.filtering.push({
    //       column: 'productCode',
    //       compareType: CompareTypeDtoEnum.LIKE,
    //       value: `*${likeValue}*`
    //     });
    //   }
    //   // todo: BE doesn't yet support the 'DIFFERENT' filter criteria list
    //   // if (this.selectedSubProductItems?.length > 0) {
    //   //   this.selectedSubProductItems.forEach(selectedProductCode => {
    //   //     this.search.filtering.push({
    //   //       column: 'productCode',
    //   //       compareType: CompareTypeDtoEnum.DIFFERENT,
    //   //       value: `${selectedProductCode}`
    //   //     });
    //   //   });
    //   // }
    //   this.adminProductService.getProductsByFilter(this.search)
    //     .pipe(takeUntil(this.onDestroy$))
    //     .subscribe(result => this.hintProductCodes.push(...result.data?.map(product => product.productCode)));
    // }
  }

  private static isValidSeqNumber(seqNo: number): boolean {
    return seqNo === 0 || (seqNo && seqNo < GroupSubsetProductsComponent.GROUP_PRODUCT_UNSET_PRIORITY);
  }

  private pushSelectedSubProductCode(value: string, label: string) {
    const productCode = value?.trim();
    const productLabel = label
      ? label
      : this.allGroupProducts.find(gp => gp.productMasterId === productCode)?.productLabel;
    if (productCode) {
      this.selectedSubProductItems.push({
        productCode,
        productLabel,
        isDuplicity: this.selectedSubProductCodes.indexOf(productCode) >= 0,
        isNewInGroup: this.isNewProductInGroup(productCode),
      });
    }
  }

  private markSubProductsDuplicities() {
    const preventDuplicitySet = new Set<string>();
    this.selectedSubProductItems.forEach(tp => {
      if (preventDuplicitySet.has(tp.productCode)) {
        tp.isDuplicity = true;
      } else {
        tp.isDuplicity = false;
        preventDuplicitySet.add(tp.productCode);
      }
    });
  }
}
