import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent implements OnInit {
  @Input()
  animation: 'progress' | 'progress-dark' | 'pulse';

  @Input()
  loadingType: 'table' | 'default';

  @Input()
  count: number;

  defaultCount: number = 5;

  items: Array<number> = [];

  ngOnInit(): void {
    this.items.length = this.count || this.defaultCount;
  }
}
