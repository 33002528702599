import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PagedPrivilegesDto, RuleDto } from '@btl/admin-bff';
import { Filter } from '@btl/btl-fe-wc-common/lib/models/search';
import { AbstractFilterComponent, AdminAclService, FormUtils, ServiceUtils } from '@btl/btl-fe-wc-common';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  OperationType,
  RulesListingComponent
} from '@components/acl/roles/role-edit/rules-listing/rules-listing.component';
import * as moment_ from 'moment';
import RuleTypeDtoEnum = RuleDto.RuleTypeDtoEnum;

@Component({
  selector: 'app-rules-listing-item, [app-rules-listing-item]',
  templateUrl: './rules-listing-item.component.html',
  styleUrls: ['./rules-listing-item.component.scss'],
})
export class RulesListingItemComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  multiSelectMode = false;

  @Input()
  singleSelectMode = false;

  @Input()
  public rule: RuleDto;

  ruleBackup = {};

  @Input()
  editable = true;

  @Input()
  editMode = false;

  @Input()
  constantFilter: Array<Filter>;

  @Input()
  parentId = '';

  @Input()
  operations;

  @Input()
  changesMode = false;

  selected = false;

  @Output()
  readonly editRule: EventEmitter<RuleDto> = new EventEmitter<RuleDto>();

  @Output()
  readonly deleteRule: EventEmitter<RuleDto> = new EventEmitter<RuleDto>();

  @Output()
  readonly duplicateRule: EventEmitter<RuleDto> = new EventEmitter<RuleDto>();

  @Output()
  readonly editModeChanged: EventEmitter<RulesListingItemComponent> = new EventEmitter<RulesListingItemComponent>();

  @Output()
  readonly deselectOther: EventEmitter<RuleDto> = new EventEmitter<RuleDto>();

  @Input()
  pagedPrivilegesDto: PagedPrivilegesDto;

  ruleTypes: RuleTypeDtoEnum[] = Object.values(this.adminAclService.getRuleTypeDict());

  privilegeForm: FormGroup = this.formBuilder.group({
    id: [null, Validators.required],
  });

  validateOrderOfStartEnd: ValidatorFn = function (control: AbstractControl) {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      const startControl = group.get('startDateTime');
      const endControl = group.get('endDateTime');
      let start = null;
      if (startControl.value instanceof Date) {
        start = moment_(startControl.value).format();
      } else {
        start = startControl.value;
      }
      const end = endControl.value;
      if (start && end && start > end) {
        return { startIsLaterThanEnd: true };
      } else {
        if (control === startControl) {
          RulesListingItemComponent.deleteControlError(endControl, 'startIsLaterThanEnd');
        } else if (control === endControl) {
          RulesListingItemComponent.deleteControlError(startControl, 'startIsLaterThanEnd');
        }
      }
    }
    return null;
  };

  validForForm: FormGroup = this.formBuilder.group({
    startDateTime: [null, [Validators.required, this.validateOrderOfStartEnd]],
    endDateTime: [null, this.validateOrderOfStartEnd],
  });

  ruleForm: FormGroup = this.formBuilder.group({
    id: [null, Validators.required],
    ruleType: [null, Validators.required],
    privilege: this.privilegeForm,
    validFor: this.validForForm,
    resourceIdentification: ['{}'],
  });

  constructor(private formBuilder: FormBuilder, private adminAclService: AdminAclService) {}

  static deleteControlError(control: AbstractControl, errorKey: string) {
    if (control.errors) {
      delete control.errors[errorKey];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  prevRuleChange = null;

  ngOnInit() {
    if (!this.rule.id) {
      this.rule.id = ServiceUtils.getRandomId();
      this.edit();
    }
    ServiceUtils.copyWithExclude(this.rule, this.ruleBackup);
    this.ruleForm.patchValue(this.rule);
    this.validate();
    this.prevRuleChange = this.ruleForm.value;
    this.ruleForm.valueChanges.subscribe(valueChange => {
      if (!ServiceUtils.equalsWithExclude(this.prevRuleChange, valueChange)) {
        ServiceUtils.copyWithExclude(valueChange, this.rule);
        const privilege = this.pagedPrivilegesDto.data.find(privilege => privilege.id === valueChange.privilege.id);
        this.rule.privilege.resourceType = privilege.resourceType;
        this.prevRuleChange = valueChange;
      }
    });
  }

  public restoreBackup() {
    ServiceUtils.copyWithExclude(this.ruleBackup, this.rule);
    this.ruleForm.patchValue(this.rule);
  }

  clicked() {
    this.edit();
  }

  public validate() {
    FormUtils.validateAllFormFields(this.ruleForm);
    if (this.ruleForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  public save() {
    if (this.validate()) {
      const rule = this.operations.find(rule => rule.id === this.rule.id);

      if (rule) {
        ServiceUtils.copyWithExclude(this.rule, rule);
      } else {
        if (!ServiceUtils.equalsWithExclude(this.rule, this.ruleBackup, RulesListingComponent.OPERATION_TYPE)) {
          this.rule[RulesListingComponent.OPERATION_TYPE] = OperationType.UPDATE;
          this.operations.push(this.rule);
        }
      }
      return true;
    }
    return false;
  }

  delete() {
    this.deleteRule.emit(this.rule);
  }

  duplicate() {
    this.duplicateRule.emit(this.rule);
  }

  public presentInFilter(column: String) {
    return AbstractFilterComponent.presentInFilter(this.constantFilter, column);
  }

  edit() {
    this.editModeChanged.emit(this);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  select() {
    if (this.singleSelectMode && this.selected) {
      this.deselectOther.emit(this.rule);
    }
  }
}
