import { Component, EventEmitter, Input, Output } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import {
  AclService,
  AdminAclService,
  AdminTicketTypeService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { BulkDeleteListDto, BulkOperationsRequestDto, PagedTicketTypesDto, TicketTypeDto } from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { TicketTypeEditComponent } from '@components/tickets/ticket-type/edit/ticket-type-edit.component';
import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';


@Component({
  selector: 'app-ticket-type-list',
  templateUrl: './ticket-type-list.component.html',
  styleUrls: ['./ticket-type-list.component.scss'],
})
@EnableDynamicLoading({ customName: TicketTypeListComponent.PAGE_ID })
export class TicketTypeListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'TicketTypeListComponent';

  pageId(): string {
    return TicketTypeListComponent.PAGE_ID;
  }

  @Input()
  count: number;

  pagedTicketTypesDto: PagedTicketTypesDto;

  @Input()
  tickets;

  @Input()
  simpleVersion: boolean;

  @Output()
  readonly editTickets: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    private adminTicketTypeService: AdminTicketTypeService,
    private adminAclService: AdminAclService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminTicketTypeService
        .filterTicketTypes(this.search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedTicketTypesDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  newTicketType() {
    this.editTicketType({ id: '&' });
  }

  editTicketType(ticketType, inNewTab: boolean = false) {
    this.navigateSibling(TicketTypeEditComponent.PAGE_ID, { id: ticketType.id }, inNewTab);
  }

  deleteTicketType(ticketType: TicketTypeDto) {
    this.adminAclService.getTicketTypeRules(ticketType.code).subscribe(rulesToDelete => {
      this.adminAclService.changeRules(rulesToDelete).subscribe(() => {
        this.adminTicketTypeService
          .deleteTicketType(ticketType.id)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.loadData();
            this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
          });
      });
    });
  }

  deleteCheckedTicketType() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.ticket.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      const ticketTypes: BulkDeleteListDto = {
        bulkDeleteList: [],
      };

      const bulkOperations: Observable<BulkOperationsRequestDto>[] = [];
      this.getSelectedData().forEach(ticketType => {
        bulkOperations.push(this.adminAclService.getTicketTypeRules(ticketType.code));
        ticketTypes.bulkDeleteList.push({
          id: ticketType.id,
        });
      });

      forkJoin(bulkOperations).subscribe(operations => {
        const rulesToDelete: BulkOperationsRequestDto = {
          bulkDeleteList: [],
        };
        operations.forEach(operation => {
          rulesToDelete.bulkDeleteList.push(...operation.bulkDeleteList);
        });
        this.adminAclService.changeRules(rulesToDelete).subscribe(() => {
          this.adminTicketTypeService
            .deleteTicketTypes(ticketTypes)
            .pipe(finalize(this.appBlockerService.unblock))
            .subscribe(() => {
              this.loadData();
              this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
            });
        });
      });
      confirmationDialogComponent.dialogReference.close();
    };
  }
}
