<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-input-date-form-field
            [parentGroup]="filterForm"
            [controlName]="'reviewedFrom'"
            id="001_product_ratings_filter"
            [translationPrefix]="'wc.admin.ratings'">
          </app-input-date-form-field>
          <app-input-date-form-field
            [parentGroup]="filterForm"
            [controlName]="'reviewedTo'"
            id="002_product_ratings_filter"
            [translationPrefix]="'wc.admin.ratings'">
          </app-input-date-form-field>
          <app-custom-input
            [controlName]="'ratingFrom'"
            [inputType]="'number'"
            id="003_product_ratings_filter"
            [translationPrefix]="'wc.admin.ratings'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'ratingTo'"
            [inputType]="'number'"
            id="004_product_ratings_filter"
            [translationPrefix]="'wc.admin.ratings'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            [translationPrefix]="'wc.admin.ratings'"
            [yesNoOptions]="true"
            labelOption="labelKey"
            valueOption="value"
            id="005_product_ratings_filter"
            [formControlName]="'recommended'">
          </app-input-select-form-field>
          <app-custom-input
            [controlName]="'extId'"
            [inputType]="'text'"
            id="006_product_ratings_filter"
            [translationPrefix]="'wc.admin.ratings'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <div class="form__field-actions">
            <a
              id="007_product_ratings_filter"
              color="primary"
              mat-raised-button
              (click)="handleChange()"
              style="width: 100%"
              class="btn -primary -xs"
              [routerLink]=""
            >{{ 'wc.common.search.button' | translate }}</a
            >
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
