import { Component, Input } from '@angular/core';
import {
  AdminMetaProfileService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  ControlStructSingletonFilterMiner,
  Search,
} from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-meta-profile-filter',
  templateUrl: './meta-profile-filter.component.html',
  animations: [
    trigger('indicatorRotate', [
      state('close', style({ transform: 'rotate(0deg)' })),
      state('open', style({ transform: 'rotate(180deg)' })),
      transition('close <=> open', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
})
export class MetaProfileFilterComponent extends ComplexFilter {
  @Input()
  module: string;

  profileIds: string[] = [];
  profileNames: string[] = [];
  parameterNames: string[] = [];

  filterForm = this.formBuilder.group({
    id: [],
    name: [],
    metaParameterName: [],
    metaParameterValue: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.id, 'id', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.name, 'name', CompareTypeDtoEnum.LIKE),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.metaParameterName, 'metaParameters', 'name', null),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.metaParameterValue, 'metaParameters', 'value', null),
  ];

  constructor(private formBuilder: FormBuilder, private adminMetaProfileService: AdminMetaProfileService) {
    super();
  }

  ngOnInit() {
    const searchProfiles: Search = {
      filtering: [],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
      fields: ['data.id', 'data.name'],
    };

    this.adminMetaProfileService.filterMetaProfiles(this.module, searchProfiles).subscribe(result => {
      this.profileIds = result.data?.map(value => value.id);
      this.profileNames = result.data?.map(value => value.name);
    });

    this.adminMetaProfileService.getMetaProfilesParameterNames(this.module, null, null).subscribe(result => {
      this.parameterNames = result;
    });

    super.ngOnInit();
  }
}
