import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { BulkOperationsRequestDto, PagedPrivilegesDto, PagedRolesDto, PagedRulesDto, RuleDto } from '@btl/admin-bff';
import {
  AclTableListItemComponent
,
} from '@components/acl/components/acl-rules-table/list/item/acl-table-list-item.component';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AclLegendModalComponent } from '@components/acl-legend-modal/acl-legend-modal.component';

@Component({
  selector: 'app-acl-table-list, [app-acl-table-list]',
  templateUrl: './acl-table-list.component.html',
  styleUrls: ['./acl-table-list.component.scss'],
})
export class AclTableListComponent implements OnInit {
  @ViewChildren(AclTableListItemComponent)
  aclTableListItemComponents: Array<AclTableListItemComponent>;

  @Input()
  aclRoles: PagedRolesDto;

  @Input()
  aclRules: PagedRulesDto;

  @Input()
  parentAclRules: Array<RuleDto>;

  @Input()
  aclPrivileges: PagedPrivilegesDto;

  @Input()
  context;

  @Input()
  parameterName;

  @Input()
  parameters: Array<any>;

  @Input()
  category = false;

  @Input()
  editable = true;

  @Output()
  readonly parameterChanged: EventEmitter<string> = new EventEmitter<string>();

  selectedAllRoles = false;

  @Input()
  parentForm;

  defaultAclRoles;

  filterForm = this.formBuilder.group({
    roleName: [],
  });

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.defaultAclRoles = [...this.aclRoles.data];
    this.filterForm.valueChanges.subscribe(filter => {
      if (filter.roleName === null) {
        this.aclRoles.data = this.defaultAclRoles;
      } else {
        this.aclRoles.data = this.defaultAclRoles.filter(
          role => role.id.toLowerCase().indexOf(filter.roleName.toLowerCase()) !== -1
        );
      }
    });
  }

  getAllParameters() {
    const parameters = [];
    this.parameters.forEach(parameter => {
      parameters.push(parameter.name);
    });
    return Array.from(new Set(parameters));
  }

  aclGrantAll() {
    const selectedRules = [];
    this.aclTableListItemComponents.forEach(aclTableListItemComponent => {
      if (aclTableListItemComponent) {
        selectedRules.push(aclTableListItemComponent);
      }
    });
    selectedRules.forEach((selectedRule: AclTableListItemComponent) => {
      selectedRule.aclRulesEdit.forEach(rule => {
        selectedRule.setRuleType(rule, 'GRANT');
      });
      selectedRule.saveRuleType();
    });
  }

  aclRevokeAll() {
    const selectedRules = [];
    this.aclTableListItemComponents.forEach(aclTableListItemComponent => {
      if (aclTableListItemComponent) {
        selectedRules.push(aclTableListItemComponent);
      }
    });
    selectedRules.forEach((selectedRule: AclTableListItemComponent) => {
      selectedRule.aclRulesEdit.forEach(rule => {
        selectedRule.setRuleType(rule, 'REVOKE');
      });
      selectedRule.saveRuleType();
    });
  }

  parameterChange(value: any) {
    this.parameterName = value;
    this.parameterChanged.emit(this.parameterName);
  }

  public static getProductResourceIdentification(category?: boolean, id?: string, parameterName?: string) {
    if (!category) {
      if (parameterName) {
        return JSON.stringify({
          productCode: id,
          productParamName: parameterName ? parameterName : '',
        });
      } else {
        return JSON.stringify({
          productCode: id,
        });
      }
    } else {
      if (parameterName) {
        return JSON.stringify({
          category: id,
          productParamName: parameterName ? parameterName : '',
        });
      } else {
        return JSON.stringify({
          category: id,
        });
      }
    }
  }

  public static getBulkOperationFromRuleSet(
    rules: Array<RuleDto>,
    id: string,
    category?: boolean
  ): BulkOperationsRequestDto {
    const createList = [];
    const updateList = [];
    const deleteList = [];

    if (rules) {
      rules.forEach((rule: RuleDto) => {
        if (rule.privilege.resourceType === 'PC_PRODUCT') {
          if (!category) {
            rule.resourceIdentification = JSON.stringify({ productCode: id });
          } else {
            rule.resourceIdentification = JSON.stringify({ category: id });
          }
        } else if (rule.privilege.resourceType === 'PC_PRODUCT_PARAMETER') {
          let indexStart = rule.resourceIdentification.indexOf('productParamName":"');
          let parameterName;
          if (indexStart > 0) {
            indexStart = indexStart + 19;
            parameterName = rule.resourceIdentification.substr(
              indexStart,
              rule.resourceIdentification.length - (indexStart + 2)
            );
          }
          rule.resourceIdentification = AclTableListComponent.getProductResourceIdentification(
            category,
            id,
            parameterName
          );
        }

        if (rule.ruleType) {
          if (!rule.id) {
            createList.push(rule);
          } else {
            updateList.push(rule);
          }
        } else if (rule.id) {
          deleteList.push(rule);
        }
      });
    }

    let bulkOperationsRequestDto: BulkOperationsRequestDto = null;
    if (createList.length > 0 || updateList.length > 0 || deleteList.length > 0) {
      bulkOperationsRequestDto = {
        bulkCreateList: createList,
        bulkUpdateList: updateList,
        bulkDeleteList: deleteList,
      };
      bulkOperationsRequestDto.bulkCreateList.forEach(rule => {
        rule.validFor = { startDateTime: new Date() };
      });
    }

    return bulkOperationsRequestDto;
  }

  showLegend() {
    const modalRef = this.dialog.open(AclLegendModalComponent);
    const aclLegendModalComponent = modalRef.componentInstance;
    aclLegendModalComponent.dialogRef = modalRef;
  }
}
