import { Component } from '@angular/core';
import { AuthFactoryService, CompareType, ComplexFilter, ControlFilterMiner } from '@btl/btl-fe-wc-common';
import { from } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import moment_ from 'moment/moment';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-exports-filter',
  templateUrl: './exports-filter.component.html',
})
export class ExportsFilterComponent extends ComplexFilter {
  PickerInputType = PickerInputType;

  filterForm = this.formBuilder.group({
    onlyMine: [],
    createdBy: [],
    createdFrom: [],
    createdTo: [],
  });

  loggedInUsername;
  moment = moment_;

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.createdBy, 'createdBy'),
    new ControlFilterMiner(this.filterForm.controls.createdFrom, 'createdFrom', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.createdTo, 'createdTo', CompareTypeDtoEnum.SMALLEREQ),
  ];

  constructor(private formBuilder: FormBuilder, private authFactoryService: AuthFactoryService) {
    super();
    from(this.authFactoryService.getAuthService().getUserInfo())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(userProfile => {
        this.loggedInUsername = userProfile.username;
      });

    this.filterForm.controls.createdBy.valueChanges.subscribe(value => {
      if (value) {
        this.filterForm.controls.onlyMine.patchValue(true);
      } else {
        this.filterForm.controls.onlyMine.patchValue(false);
      }
    });
  }

  handleOnlyMineChange(event) {
    if (event) {
      this.filterForm.controls.createdBy.patchValue(this.loggedInUsername);
    } else {
      this.filterForm.controls.createdBy.patchValue('');
    }
  }

  protected getDefaultFilterValues() {
    const now = new Date();
    now.setUTCHours(23, 59, 59, 999);
    const fromDate = this.moment(now).add(-90, 'd').toDate();
    fromDate.setUTCHours(0, 0, 0, 0);

    return {
      onlyMine: null,
      createdBy: null,
      createdFrom: this.moment(fromDate, 'DD.MM.YYYY').utc(false).format(),
      createdTo: this.moment(now, 'DD.MM.YYYY').utc(false).format(),
    };
  }
}
