import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { StepTemplateDto } from '@btl/admin-bff';
import { Subject } from 'rxjs';
import { AdminStepTemplateService, AppBlockerService, CompareType, Search } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  StepTemplateSelectModalComponent
,
} from '@components/step-template-select-modal/step-template-select-modal.component';
import { MatDialog } from '@angular/material/dialog';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-step-template-picker, [app-step-template-picker]',
  templateUrl: './step-template-picker.component.html',
  styleUrls: ['./step-template-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StepTemplatePickerComponent),
      multi: true,
    },
  ],
})
export class StepTemplatePickerComponent implements OnDestroy, ControlValueAccessor {
  @Output()
  readonly selectionChanged: EventEmitter<StepTemplateDto> = new EventEmitter<StepTemplateDto>();

  @Input()
  valueField;

  value;
  disable;
  stepTemplate;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private adminStepTemplateService: AdminStepTemplateService,
    private dialog: MatDialog,
    private appBlockerService: AppBlockerService
  ) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  selectStepTemplate() {
    const modalRef = this.dialog.open(StepTemplateSelectModalComponent);
    const stepTemplateSelectModalComponent = modalRef.componentInstance;
    stepTemplateSelectModalComponent.dialogRef = modalRef;

    stepTemplateSelectModalComponent.selectHandler = stepTemplate => {
      if (stepTemplate) {
        this.value = stepTemplate[this.valueField];
      } else {
        this.value = null;
      }

      this.stepTemplate = stepTemplate;
      this.propagateChange(this.value);
      this.propagateOnTouched();
      this.selectionChanged.emit(stepTemplate);
    };
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (this.value) {
      if (this.valueField === 'id') {
        this.appBlockerService.block();
        this.adminStepTemplateService
          .getStepTemplateById(this.value, null)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            this.stepTemplate = result;
          });
      }
      if (this.valueField === 'code') {
        const search: Search = {
          filtering: [
            {
              column: 'code',
              compareType: CompareTypeDtoEnum.EQUAL,
              value: this.value,
            },
          ],
          sorting: [],
          paging: {
            page: 1,
            pageSize: -1,
          },
        };
        this.appBlockerService.block();
        this.adminStepTemplateService
          .filterStepTemplates(search, null)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            if (result.data.length > 0) {
              this.stepTemplate = result.data[0];
            }
          });
      }
    } else {
      this.stepTemplate = null;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
