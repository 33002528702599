<div class="main__header">
  <div class="header-box">
    <div class="icon-box TICKETING">
      <mat-icon svgIcon="ticketTypes"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.ticketType' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      *ngIf="aclService.pageEditAccess"
      id="001_ticket_type_listing"
      (click)="newTicketType()"
      [routerLink]=""
      [title]="'wc.common.new' | translate"
      mat-raised-button
      color="primary"
      class="btn -primary -xs"
      >{{ 'wc.common.new' | translate }}</a
    >
  </div>
</div>
<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-ticket-type-filter
        (filterChanged)="handleFilterChange()"
        [cacheFilterUsed]="cacheFilterUsed"
        [filtering]="search.filtering">
      </app-ticket-type-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.ticketType.form.list' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedTicketTypesDto?.totalItems
                          ? pagedTicketTypesDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedTicketTypesDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="002_ticket_type_listing"
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedTicketType()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="['areaType', 'code', 'defaultSubject', 'stepTemplate.code']"
          [fillIdBy]="'id'"
          [pagedDto]="pagedTicketTypesDto"
          (editButtonEvent)="editTicketType($event)"
          (editInNewTabButtonEvent)="editTicketType($event, true)"
          (deleteButtonEvent)="editTicketType($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          (doubleClickEvent)="editTicketType($event)"
          (pageSizeEvent)="pageSizeChanged($event)"
          [editable]="aclService.pageEditAccess"
          [translationPrefix]="'wc.admin.ticketType'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
