<div class="border-bot">
  <div>
    <h2 mat-dialog-title>
      {{ 'wc.admin.eligibility.legend.header' | translate }}
      <mat-icon
        class="default-icon-gray-fill no-stroke"
        svgIcon="close"
        mat-dialog-close
        matTooltip="{{ 'wc.common.close' | translate }}">
      </mat-icon>
    </h2>
  </div>
</div>
<mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutGap="15px">
  <div fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span>{{ 'wc.admin.eligibility.legend.possibleRules' | translate }}</span>
      <mat-chip-option class="-chip-item -standard" color="primary">
        <span class="mat-chip-label modal-label">segmentResident</span>
      </mat-chip-option>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span>{{ 'wc.admin.eligibility.legend.logical.AndNot' | translate }}</span>
      <mat-chip-option class="-chip-item -logical-and-not" color="primary">
        <span class="mat-chip-label modal-label">AND</span>
      </mat-chip-option>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span>{{ 'wc.admin.eligibility.legend.logical.Or' | translate }}</span>
      <mat-chip-option class="-chip-item -logical-or" color="primary">
        <span class="mat-chip-label modal-label">OR</span>
      </mat-chip-option>
    </div>
    <span></span>
  </div>
  <div fxLayout="column" fxLayoutGap="5px">
    <div class="eligibility-example-label" fxLayout="row" fxLayoutAlign="start center">
      <span>{{ 'wc.admin.eligibility.legend.exampleAllowedInput' | translate }}</span>
    </div>
    <div fxLayout="row" fxLayoutGap="5px">
      <div>
        <div>
          <mat-chip-option class="-chip-item" color="primary">
            <span class="mat-chip-label modal-label">Rule1</span>
          </mat-chip-option>
          <mat-chip-option class="-chip-item -logical-and-not" color="primary">
            <span class="mat-chip-label modal-label">AND</span>
          </mat-chip-option>
          <mat-chip-option class="-chip-item -logical-and-not" color="primary">
            <span class="mat-chip-label modal-label">NOT</span>
          </mat-chip-option>
          <mat-chip-option class="-chip-item" color="primary">
            <span class="mat-chip-label modal-label">Rule2</span>
          </mat-chip-option>
        </div>
        <div class="eligibility-example">
          <div class="eligibility-example-border"></div>
          <span class="eligibility-example-ruleSet">{{ 'wc.admin.eligibility.legend.firstRuleSet' | translate }}</span>
        </div>
      </div>
      <mat-chip-option class="-chip-item -logical-or" color="primary">
        <span class="mat-chip-label modal-label">OR</span>
      </mat-chip-option>
      <div>
        <div>
          <mat-chip-option class="-chip-item -logical-and-not" color="primary">
            <span class="mat-chip-label modal-label">NOT</span>
          </mat-chip-option>
          <mat-chip-option class="-chip-item" color="primary">
            <span class="mat-chip-label modal-label">Rule3</span>
          </mat-chip-option>
          <mat-chip-option class="-chip-item -logical-and-not" color="primary">
            <span class="mat-chip-label modal-label">AND</span>
          </mat-chip-option>
          <mat-chip-option class="-chip-item" color="primary">
            <span class="mat-chip-label modal-label">Rule4</span>
          </mat-chip-option>
        </div>
        <div class="eligibility-example">
          <div class="eligibility-example-border"></div>
          <span class="eligibility-example-ruleSet">{{ 'wc.admin.eligibility.legend.secondRuleSet' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
