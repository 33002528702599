import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbstractPageComponent, AuthFactoryService, AuthService, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { environment } from '@environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
@EnableDynamicLoading({ customName: DashboardComponent.PAGE_ID })
export class DashboardComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'DashboardComponent';

  pageId(): string {
    return DashboardComponent.PAGE_ID;
  }

  userProfile;
  roles;
  totalTickets: number;

  disableTickets = environment.disableTickets;

  eshopUrl: string = null;

  quickActions = [];

  private authService: AuthService;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private authFactoryService: AuthFactoryService,
    private propertyAccessorLocalService: PropertyAccessorLocalService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit() {
    this.authService = this.authFactoryService.getAuthService();

    this.propertyAccessorLocalService.getGlobalBaseUrl()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.eshopUrl = result;
      });
    from(this.authService.getUserInfo())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(userProfile => {
        this.userProfile = userProfile;
        this.roles = this.authService.getUserRoles().join(', ');
      });
  }

  setTotalTickets(totalTickets) {
    this.totalTickets = totalTickets;
  }

  goShopping() {
    window.location.href = this.eshopUrl;
  }
}
