<ng-container *ngIf="!editMode">
  <td *ngIf="!changesMode">
    <input [(ngModel)]="selected" id="{{ parentId }}_001_releases_listing_item" type="checkbox" />
  </td>
  <td *ngIf="changesMode">{{ 'wc.admin.release.operationType.' + member['operationType'] | translate }}</td>
  <td (click)="clicked()">{{ member.memberType }}</td>
  <td (click)="clicked()">{{ member.code }}</td>
  <td (click)="clicked()">
    <input *ngIf="showIncludePromotions()" [checked]="member.includePromotion" type="checkbox" readonly />
  </td>
  <td (click)="clicked()">{{ showName() ? member.name : '' }}</td>
  <td (click)="clicked()" class="text-truncate content-desktop">{{ member.created | date: 'HH:mm dd.MM.yyyy' }}</td>
  <td (click)="clicked()" class="text-truncate content-desktop">{{ member.modified | date: 'HH:mm dd.MM.yyyy' }}</td>
  <td (click)="clicked()" class="text-truncate content-desktop">{{ member.modifiedBy }}</td>
  <td *ngIf="editable && !changesMode" class="table__action">
    <button (click)="edit()" [routerLink]="" mat-icon-button class="dropdown-item"><mat-icon>edit</mat-icon></button>
    <button (click)="delete()" [routerLink]="" mat-icon-button class="dropdown-item">
      <mat-icon>delete</mat-icon>
    </button>
  </td>
  <td *ngIf="changesMode">
    <a (click)="delete()" [routerLink]="" class="btn -primary -xs" id="{{ parentId }}_004_releases_listing_item">X</a>
  </td>
</ng-container>
<ng-container *ngIf="editMode">
  <td colspan="11" style="cursor: default">
    <!-- card -->
    <div class="card -full">
      <div class="card__inner">
        <div (click)="clicked()" class="card__header" style="cursor: pointer" fxLayout="row" fxFlexAlign="start">
          <div>
            <mat-icon
              color="primary"
              svgIcon="doubleArrowLeft"
              style="transform: rotate(0.25turn) scale(0.7); font-size: 18px; line-height: 24px"></mat-icon>
          </div>
          <a
            (click)="delete()"
            *ngIf="editable"
            [routerLink]=""
            class="link"
            id="{{ parentId }}_007_members_listing_item"
            ><mat-icon>delete</mat-icon></a
          >
        </div>
        <div class="card__body" [ngClass]="{ editable: editable }">
          <form *ngIf="member" [formGroup]="memberForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-input-select-form-field
                    [options]="memberTypes"
                    [formControlName]="'memberType'"
                    (selectionChangeEmit)="clearField($event)"
                    [translationPrefix]="'wc.admin.release.member'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <ng-container [ngSwitch]="memberForm.controls['memberType'].value">
                    <app-input-select-form-field
                      *ngSwitchCase="'CatTech'"
                      [formControlName]="'code'"
                      [options]="techCategories"
                      [label]="'code'"
                      [translationPrefix]="'wc.admin.release.member'">
                    </app-input-select-form-field>
                    <app-input-picker-form-field
                      *ngSwitchCase="'ProdCode'"
                      [parentGroup]="memberForm"
                      [controlName]="'code'"
                      [valueField]="'productCode'"
                      [pickerType]="PickerInputType.PRODUCT"
                      [selectMode]="false"
                      (selectionChanged)="memberForm.controls.name.patchValue($event.description)"
                      [translationPrefix]="'wc.admin.releases.member'">
                    </app-input-picker-form-field>
                    <app-input-picker-form-field
                      *ngSwitchCase="'ProdGroup'"
                      [parentGroup]="memberForm"
                      [controlName]="'code'"
                      [valueField]="'code'"
                      (selectionChanged)="memberForm.controls.name.patchValue($event.name)"
                      [pickerType]="PickerInputType.GROUP"
                      [selectMode]="false"
                      [translationPrefix]="'wc.admin.releases.member'">
                    </app-input-picker-form-field>
                    <app-input-picker-form-field
                      *ngSwitchCase="'Sticker'"
                      [parentGroup]="memberForm"
                      [controlName]="'code'"
                      [valueField]="'code'"
                      [pickerType]="PickerInputType.STICKER"
                      [selectMode]="false"
                      [translationPrefix]="'wc.admin.releases.member'">
                    </app-input-picker-form-field>
                    <app-input-picker-form-field
                      *ngSwitchCase="'Promotion'"
                      [parentGroup]="memberForm"
                      [controlName]="'code'"
                      [valueField]="'code'"
                      [pickerType]="PickerInputType.PROMOTION"
                      [selectMode]="false"
                      (selectionChanged)="memberForm.controls.name.patchValue($event.name)"
                      [translationPrefix]="'wc.admin.releases.member'">
                    </app-input-picker-form-field>
                    <app-input-picker-form-field
                      *ngSwitchCase="'AclRule'"
                      [parentGroup]="memberForm"
                      [controlName]="'code'"
                      [valueField]="'id'"
                      [pickerType]="PickerInputType.RULE"
                      [selectMode]="false"
                      [translationPrefix]="'wc.admin.releases.member'">
                    </app-input-picker-form-field>
                    <app-custom-input
                      [parentGroup]="memberForm"
                      *ngSwitchDefault
                      [controlName]="'code'"
                      [inputType]="'text'"
                      [translationPrefix]="'wc.admin.releases.member'">
                    </app-custom-input>
                  </ng-container>
                </div>
                <div *ngIf="showIncludePromotions()" class="form__row">
                  <app-custom-input
                    [inputType]="'checkbox'"
                    [parentGroup]="memberForm"
                    [controlName]="'includePromotion'"
                    [label]="'wc.admin.release.member.includePromotion'">
                  </app-custom-input>
                </div>
                <div *ngIf="showName()" class="form__row">
                  <app-custom-input
                    [parentGroup]="memberForm"
                    [controlName]="'name'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.releases.member'">
                  </app-custom-input>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </td>
</ng-container>
