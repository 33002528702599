<div class="inner-component quick-action">
  <h3>{{ 'wc.admin.dashboard.quickAction.title' | translate }}</h3>
  <mat-grid-list cols="3" rowHeight="70px" gutterSize="20px">
    <mat-grid-tile
      [id]="module.code"
      *ngFor="let module of modules"
      [routerLink]="module.parentUrlPattern + module.urlPattern">
      <div class="dashboard-icon {{ module.parentCode ? module.parentCode : module.cssClass }}">
        <mat-icon svgIcon="{{ module.cssClass }}"></mat-icon>
      </div>
      <a>
        <span>{{ pageModules.modulesTexts[module.code] }}</span>
      </a>
      <mat-icon class="icon-arrow">keyboard_arrow_right</mat-icon>
    </mat-grid-tile>
  </mat-grid-list>
</div>
