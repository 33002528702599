<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'name'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.ruleSets'"
            id="001_rule_set_filter"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'description'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.ruleSets'"
            id="002_rule_set_filter"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            id="003_rule_set_filter"
            [formControlName]="'ruleSetExpression'"
            [options]="contextRules"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [label]="'expression'"
            [translationPrefix]="'wc.admin.ruleSets'">
          </app-input-select-form-field>
          <app-custom-input
            [controlName]="'rulesRuleName'"
            id="004_rule_set_filter"
            [inputType]="'text'"
            [label]="'contextRuleName'"
            [translationPrefix]="'wc.admin.ruleSets'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
