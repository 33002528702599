import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-input-date-form-field',
  templateUrl: './input-date-form-field.component.html',
})
export class InputDateFormFieldComponent implements OnInit, OnChanges {
  @Input()
  id: string;

  @Input()
  disableErrors: boolean = false;

  @ViewChild('picker') picker: any;

  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  @Input()
  parentGroup: FormGroup;

  @Input()
  controlName;

  @Input()
  disableError: boolean = false;

  @Input()
  inputType;

  @Input()
  label;

  @Input()
  disabledInput;

  @Input()
  suffix: string = '';

  @Input()
  translationPrefix: string;

  @Input()
  hideTime = false;

  onDateChange(event) {
    const newValue = this.hideTime
      ? event.value?.hours(0).minutes(0).seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ssZ')
      : event.value?.seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ssZ');
    this.dateFormControl.patchValue(newValue);
  }

  get dateFormControl(): FormControl {
    return this.parentGroup.get(this.controlName) as FormControl;
  }

  ngOnInit(): void {
    if (this.disabledInput) {
      this.parentGroup.controls[this.controlName].disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabledInput === null || this.disabledInput === false) {
      this.parentGroup.controls[this.controlName].enable();
    }
  }

  getControlValidationErrors(): Array<string> {
    const errors: Array<string> = [];

    const controlErrors: ValidationErrors = this.parentGroup.controls[this.controlName].errors;
    if (controlErrors) {
      Object.keys(controlErrors).forEach(keyError => {
        errors.push(keyError);
      });
    }

    return errors;
  }
}
