<app-promotion-design-node
  (collapseEvent)="collapsed = $event"
  (nodeClick)="nodeClick.emit($event)"
  (nodeRemove)="nodeRemove.emit($event)"
  (selectedItemEvent)="selectItem($event)"
  *ngIf="node"
  [compactView]="compactView"
  [collapseAllEmitter]="collapseAllEmitter"
  [selectedNode]="selectedNode"
  [direction]="direction"
  [hasParent]="hasParent"
  [node]="node"
  ngClass="promotion-design-{{ direction }}">
</app-promotion-design-node>
<div
  *ngIf="node?.childs?.length && !collapsed"
  class="promotion-design-reports"
  ngClass="promotion-design-reports-{{ direction }}">
  <ng-container *ngIf="node.type === 'root'">
    <ng-container *ngFor="let childNode of node?.childs; first as isFirst; last as isLast">
      <div class="promotion-design-container" ngClass="promotion-design-container-{{ direction }}">
        <div
          class="promotion-design-connector-container"
          ngClass="promotion-design-connector-container-{{ direction }}">
          <div [style.border-color]="isFirst ? 'transparent' : ''" class="promotion-design-connector"></div>
          <div class="promotion-design-border"></div>
          <div [style.border-color]="isLast ? 'transparent' : ''" class="promotion-design-connector"></div>
        </div>
        <app-promotion-design-connections
          (nodeClick)="nodeClick.emit($event)"
          (nodeRemove)="nodeRemove.emit($event)"
          [compactView]="compactView"
          [collapseAllEmitter]="collapseAllEmitter"
          [direction]="direction"
          [hasParent]="true"
          [node]="childNode"
          [selectedNode]="selectedNode">
        </app-promotion-design-connections>
      </div>
    </ng-container>
  </ng-container>
  <div *ngIf="direction === 'vertical'" style="height: 30px"></div>
  <ng-container *ngIf="node.type === 'socket'">
    <ng-container *ngFor="let node of node?.childs">
      <ng-container *ngFor="let childNode of node.childs; first as isFirst; last as isLast">
        <div
          *ngIf="selectedItem === node.id"
          class="promotion-design-container"
          ngClass="promotion-design-container-{{ direction }}">
          <div
            class="promotion-design-connector-container"
            ngClass="promotion-design-connector-container-{{ direction }}">
            <div [style.border-color]="isFirst ? 'transparent' : ''" class="promotion-design-connector"></div>
            <div class="promotion-design-border"></div>
            <div [style.border-color]="isLast ? 'transparent' : ''" class="promotion-design-connector"></div>
          </div>
          <app-promotion-design-connections
            (nodeClick)="nodeClick.emit($event)"
            (nodeRemove)="nodeRemove.emit($event)"
            [compactView]="compactView"
            [collapseAllEmitter]="collapseAllEmitter"
            [direction]="direction"
            [selectedNode]="selectedNode"
            [hasParent]="true"
            [node]="childNode">
          </app-promotion-design-connections>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
