import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ProductPriceDto } from '@btl/admin-bff';
import { Subject } from 'rxjs';
import { AclService } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-product-price-listing-item, [app-product-price-listing-item]',
  templateUrl: './product-price-listing-item.component.html',
  styleUrls: ['./product-price-listing-item.component.scss'],
})
export class ProductPriceListingItemComponent implements OnDestroy {
  @Input()
  price: ProductPriceDto;

  @Input()
  parentId = '';

  @Output()
  readonly editProductPrice: EventEmitter<ProductPriceDto> = new EventEmitter<ProductPriceDto>();

  @Output()
  readonly editProductPriceInNewTab: EventEmitter<ProductPriceDto> = new EventEmitter<ProductPriceDto>();

  @Output()
  readonly deleteProductPrice: EventEmitter<ProductPriceDto> = new EventEmitter<ProductPriceDto>();

  selected;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(public aclService: AclService) {}

  edit() {
    this.editProductPrice.emit(this.price);
  }

  editInNewTab() {
    this.editProductPriceInNewTab.emit(this.price);
  }

  delete() {
    this.deleteProductPrice.emit(this.price);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
