<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle style="height: 100%">
    <mat-expansion-panel-header style="height: 100%">
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'areaType'"
            [inputType]="'text'"
            id="001_ticket_type_filter"
            [label]="'area'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.ticketType'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'code'"
            [inputType]="'text'"
            id="002_ticket_type_filter"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.ticketType'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'defaultSubject'"
            [inputType]="'text'"
            id="003_ticket_type_filter"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.ticketType'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            [formControlName]="'system'"
            [label]="'isSystem'"
            id="004_ticket_type_filter"
            (keydown.enter)="handleChange()"
            [yesNoOptions]="true"
            labelOption="labelKey"
            valueOption="value"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.ticketType'">
          </app-input-select-form-field>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-custom-input
          [controlName]="'parametersName'"
          [inputType]="'text'"
          [label]="'paramName'"
          id="007_ticket_type_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.ticketType'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'parametersValue'"
          [inputType]="'text'"
          [label]="'paramValue'"
          id="008_ticket_type_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.ticketType'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'stepTemplate'"
          [inputType]="'text'"
          id="009_ticket_type_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.ticketType'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-input-select-form-field
          [formControlName]="'valid'"
          [label]="'isValid'"
          id="010_ticket_type_filter"
          [yesNoOptions]="true"
          labelOption="labelKey"
          valueOption="value"
          (keydown.enter)="handleChange()"
          [showEmptyOption]="true"
          [translationPrefix]="'wc.admin.ticketType'">
        </app-input-select-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</form>
