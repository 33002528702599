<ng-container *ngIf="loadingType === 'table'; else defaultSkeletonLoader">
  <ng-container *ngFor="let i of items">
    <span
      class="skeleton-loader"
      aria-busy="true"
      aria-valuemin="0"
      aria-valuemax="100"
      tabindex="0"
      [ngClass]="{
        'progress': animation === 'progress',
        'progress-dark': animation === 'progress-dark',
        'pulse': animation === 'pulse'
      }">
    </span>
  </ng-container>
</ng-container>
<ng-template #defaultSkeletonLoader>
  <span
    class="skeleton-loader"
    aria-busy="true"
    aria-valuemin="0"
    aria-valuemax="100"
    tabindex="0"
    [ngClass]="{
      'progress': animation === 'progress',
      'progress-dark': animation === 'progress-dark',
      'pulse': animation === 'pulse'
    }">
  </span>
</ng-template>
