import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AdminStickerService, AppBlockerService, CompareType, Search } from '@btl/btl-fe-wc-common';
import { StickersSelectModalComponent } from '../stickers-select-modal/stickers-select-modal.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-sticker-picker, [app-sticker-picker]',
  templateUrl: './sticker-picker.component.html',
  styleUrls: ['./sticker-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StickerPickerComponent),
      multi: true,
    },
  ],
})
export class StickerPickerComponent implements OnDestroy, ControlValueAccessor {
  @Input()
  valueField;

  value;
  disable;
  sticker;

  @Input()
  selectMode: boolean = true;

  @Input()
  disableActions: boolean = false;

  @Input()
  disableListingActions: boolean = false;

  @Input()
  disableExpand: boolean = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private adminStickerService: AdminStickerService,
    private dialog: MatDialog,
    private appBlockerService: AppBlockerService
  ) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  selectSticker() {
    const modalRef = this.dialog.open(StickersSelectModalComponent);
    const stickersSelectModalComponent = modalRef.componentInstance;
    stickersSelectModalComponent.dialogRef = modalRef;
    stickersSelectModalComponent.selectHandler = sticker => {
      if (sticker) {
        this.value = sticker[this.valueField];
      } else {
        this.value = null;
      }

      this.sticker = sticker;
      this.propagateChange(this.value);
      this.propagateOnTouched();
    };
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (this.value) {
      if (this.valueField === 'id') {
        this.appBlockerService.block();
        this.adminStickerService
          .getStickerById(this.value)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            this.sticker = result;
          });
      }
      if (this.valueField === 'code') {
        const search: Search = {
          filtering: [
            {
              column: 'code',
              compareType: CompareTypeDtoEnum.EQUAL,
              value: this.value,
            },
          ],
          sorting: [],
          paging: {
            page: 1,
            pageSize: -1,
          },
        };
        this.appBlockerService.block();
        this.adminStickerService
          .getStickersByFilter(search)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            if (result.data.length > 0) {
              this.sticker = result.data[0];
            }
          });
      }
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
