<div class="main__header" *ngIf="!singleSelectMode">
  <div class="header-box">
    <div class="icon-box TICKETING">
      <mat-icon svgIcon="ticketSteps"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.stepTemplates' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      *ngIf="aclService.pageEditAccess"
      id="001_ticket_step_template_listing"
      (click)="newTicketStepTemplate()"
      [routerLink]=""
      [title]="'wc.common.new' | translate"
      mat-raised-button
      color="primary"
      class="btn -primary -xs"
      >{{ 'wc.common.new' | translate }}</a
    >
  </div>
</div>
<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-ticket-step-template-filter
        (filterChanged)="handleFilterChange()"
        [cacheFilterUsed]="cacheFilterUsed"
        [filtering]="search.filtering">
      </app-ticket-step-template-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.stepTemplate.form.list' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedStepTemplatesDto?.totalItems
                          ? pagedStepTemplatesDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedStepTemplatesDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action" *ngIf="!disableListingActions">
          <button
            id="002_accounts_list"
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedData()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="['code', 'description', 'created', 'modified', 'modifiedBy']"
          [columnToTruncate]="['created', 'modified', 'modifiedBy']"
          [dontShowOnMobile]="['modifiedBy']"
          [fillIdBy]="'id'"
          (editButtonEvent)="editTicketStepTemplate($event)"
          (editInNewTabButtonEvent)="editTicketStepTemplate($event, true)"
          (deleteButtonEvent)="deleteData($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [pagedDto]="pagedStepTemplatesDto"
          [selectMode]="selectMode"
          (pageSizeEvent)="pageSizeChanged($event)"
          [disableActions]="disableActions"
          (doubleClickEvent)="singleSelectMode ? singleSelect($event) : editTicketStepTemplate($event)"
          [editable]="aclService.pageEditAccess"
          [translationPrefix]="'wc.admin.stepTemplate'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
