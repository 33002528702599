import { Component, OnInit } from '@angular/core';
import { AbstractPageComponent, AppBlockerService, EnableDynamicLoading, FormUtils } from '@btl/btl-fe-wc-common';
import { ProductDto } from '@btl/admin-bff';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductEditService } from '@service/product-edit.service';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-product-texts',
  templateUrl: './product-texts.component.html',
  styleUrls: ['./product-texts.component.scss'],
})
@EnableDynamicLoading({ customName: ProductTextsComponent.PAGE_ID })
export class ProductTextsComponent extends AbstractPageComponent implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductTextsComponent';

  pageId(): string {
    return ProductTextsComponent.PAGE_ID;
  }

  product: ProductDto;

  productTextsForm: FormGroup = this.formBuilder.group({
    texts: [null],
  });

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    public productEditService: ProductEditService,
    private appBlockerService: AppBlockerService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit() {
    this.setProduct(this.productEditService.getEditProduct());

    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      this.setProduct(result);
    });
  }

  setProduct(product) {
    this.product = product;
    this.productTextsForm.patchValue(this.product);
  }

  saveData(): boolean {
    if (!this.productEditService.canEdit()) {
      return true;
    }
    FormUtils.validateAllFormFields(this.productTextsForm);
    if (this.productTextsForm.valid) {
      this.productEditService.getEditProduct().texts = this.productTextsForm.controls.texts.value;
      return true;
    }
    return false;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.productEditService.resetMode) {
      if (this.saveData()) {
        return true;
      } else {
        return false;
      }
    }

    this.productEditService.resetMode = false;

    return true;
  }
}
