import { Component, QueryList, ViewChildren } from '@angular/core';
import {
  AbstractPageComponent,
  AclService,
  AdminDataTypeEnumService,
  AppBlockerService,
  EnableDynamicLoading,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { DataTypeEnumDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { TableListingFormComponent } from '@components/table-listing-form/table-listing-form.component';
import { MatSort } from '@angular/material/sort';
import { Sort } from '@btl/btl-fe-wc-common/lib/models/search';
import { DataTypeEnumComponent } from '@components/data-type-enum/data-type-enum.component';
import { DataTypeEnumEditComponent } from '@components/data-type-enum/edit/data-type-enum-edit.component';

@Component({
  selector: 'app-data-type-enum-list',
  templateUrl: './data-type-enum-list.component.html',
  styleUrls: ['./data-type-enum-list.component.scss'],
})
@EnableDynamicLoading({ customName: DataTypeEnumListComponent.PAGE_ID })
export class DataTypeEnumListComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'DataTypeEnumListComponent';

  @ViewChildren(TableListingFormComponent)
  tableListingFormComponents: QueryList<TableListingFormComponent>;

  dataTypeEnums: DataTypeEnumDto[];
  sortedDataTypeEnums: DataTypeEnumDto[];

  sorting: Sort[] = [];

  constructor(
    private adminDataTypeEnumService: AdminDataTypeEnumService,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  pageId(): string {
    return DataTypeEnumListComponent.PAGE_ID;
  }

  sourceName(): string {
    return DataTypeEnumComponent.paramFromUrl('source', this.url);
  }

  protected navigationSubscription(navigation: NavigationEnd) {
    this.loadDataTypeEnums();
  }

  onSortChange(sorting: MatSort) {
    if (sorting.direction !== '') {
      this.sorting = [{ column: sorting.active, sortOrder: sorting.direction }];
    } else {
      this.sorting = [];
    }
    this.sortDataTypeEnums();
  }

  sortDataTypeEnums() {
    this.sortedDataTypeEnums = DataTypeEnumComponent.sortData(this.dataTypeEnums, this.sorting);
  }

  deleteDataTypeEnum(dataTypeEnum: DataTypeEnumDto) {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.dataTypeEnum.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminDataTypeEnumService
        .deleteEnum(this.sourceName(), dataTypeEnum.name)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.loadDataTypeEnums();
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  editDataTypeEnum(dataTypeEnumDto: DataTypeEnumDto, inNewTab: boolean = false) {
    this.navigateSibling(DataTypeEnumEditComponent.PAGE_ID, { id: dataTypeEnumDto.name }, inNewTab);
  }

  newDataTypeEnum() {
    this.navigateSibling(DataTypeEnumEditComponent.PAGE_ID, { id: '&' });
  }

  private loadDataTypeEnums() {
    this.appBlockerService.block();

    this.adminDataTypeEnumService
      .getEnums(this.sourceName(), ['name', 'created', 'modified', 'createdBy', 'modifiedBy'])
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        this.dataTypeEnums = result;
        this.sortDataTypeEnums();
      });
  }
}
