<div class="card__body">
  <form class="form" [formGroup]="parentForm">
    <div class="main__header">
      <h1></h1>
      <div class="parameters" *ngIf="parameters && parameters.length > 0">
        <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
          <mat-label>{{ 'wc.admin.acl.parameters' | translate }}</mat-label>
          <mat-select
            disableOptionCentering
            id="001_acl_table_list"
            class="form__input"
            panelClass="form-dropdown"
            [(value)]="parameterName"
            (selectionChange)="parameterChange($event.value)">
            <mat-option [value]="null"> </mat-option>
            <mat-option [id]="option" *ngFor="let option of getAllParameters()" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="page__menu" *ngIf="editable">
        <a
          (click)="aclGrantAll()"
          [routerLink]=""
          [title]="'wc.common.acl.grantAll' | translate"
          mat-raised-button
          color="primary"
          id="002_acl_table_list"
          class="btn -primary -xs"
          >{{ 'wc.common.acl.grantAll' | translate }}</a
        >
        <a
          (click)="aclRevokeAll()"
          [routerLink]=""
          [title]="'wc.common.acl.revokeAll' | translate"
          mat-raised-button
          color="primary"
          id="003_acl_table_list"
          class="btn -primary -xs"
          >{{ 'wc.common.acl.revokeAll' | translate }}</a
        >
      </div>
    </div>

    <div class="card__wrapper">
      <!-- card -->
      <div class="card">
        <div class="card__inner">
          <div class="card__body">
            <div class="table-outer">
              <div class="table-inner">
                <table class="table-acl -primary table-sm">
                  <tr>
                    <th>
                      <app-custom-input
                        [parentGroup]="filterForm"
                        [controlName]="'roleName'"
                        [inputType]="'text'"
                        [translationPrefix]="'wc.admin.acl.filterForm'">
                      </app-custom-input>
                    </th>
                    <ng-container *ngFor="let aclPrivilege of aclPrivileges.data">
                      <td>
                        {{ aclPrivilege.id }}
                      </td>
                    </ng-container>
                  </tr>
                  <ng-container *ngFor="let role of aclRoles.data">
                    <tr
                      app-acl-table-list-item
                      class="table__row"
                      [parentForm]="parentForm"
                      [context]="context"
                      [parameterName]="parameterName"
                      [category]="category"
                      [aclPrivileges]="aclPrivileges"
                      [aclRules]="aclRules.data"
                      [parentAclRules]="parentAclRules"
                      [aclRole]="role"
                      [editable]="editable"></tr>
                  </ng-container>
                </table>
              </div>
            </div>
          </div>
          <div class="help-container">
            <a class="help-link" (click)="showLegend()">{{ 'wc.common.acl.legend.show' | translate }}</a>
            <button class="help-button" (click)="showLegend()" mat-fab>
              <mat-icon svgIcon="help"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
