<ng-container *ngIf="!editMode">
  <td *ngIf="!singleSelectMode && !changesMode">
    <input [(ngModel)]="selected" id="{{ parentId }}_001_roles_listing_item" type="checkbox" />
  </td>
  <td (click)="clicked()" *ngIf="changesMode">
    {{ 'wc.admin.role.operationType.' + rule['operationType'] | translate }}
  </td>
  <td (click)="clicked()">{{ rule.ruleType }}</td>
  <td (click)="clicked()">{{ rule.privilege.id }}</td>
  <td (click)="clicked()">{{ rule.privilege.resourceType }}</td>
  <td (click)="clicked()">{{ rule.validFor.startDateTime | date: 'HH:mm dd.MM.yyyy' }}</td>
  <td (click)="clicked()">{{ rule.validFor.endDateTime | date: 'HH:mm dd.MM.yyyy' }}</td>
  <td (click)="clicked()">{{ rule.resourceIdentification }}</td>
  <td (click)="clicked()" class="text-truncate content-desktop-cell">{{ rule.created | date: 'HH:mm dd.MM.yyyy' }}</td>
  <td (click)="clicked()" class="text-truncate content-desktop-cell">{{ rule.modified | date: 'HH:mm dd.MM.yyyy' }}</td>
  <td (click)="clicked()" class="text-truncate content-desktop-cell">{{ rule.modifiedBy }}</td>
  <td
    *ngIf="!singleSelectMode && !multiSelectMode && editable && !changesMode"
    class="table__action-button"
    fxLayout="row">
    <button (click)="edit()" [routerLink]="" mat-icon-button class="dropdown-item"><mat-icon>edit</mat-icon></button>
    <button (click)="delete()" [routerLink]="" mat-icon-button class="dropdown-item">
      <mat-icon>delete</mat-icon>
    </button>
  </td>
  <td *ngIf="changesMode">
    <a (click)="delete()" [routerLink]="" class="btn -primary -xs" id="{{ parentId }}_004_roles_listing_item">X</a>
  </td>
</ng-container>
<ng-container *ngIf="editMode">
  <td colspan="11" style="cursor: default">
    <!-- card -->
    <div class="card -full">
      <div class="card__inner">
        <div (click)="clicked()" class="card__header" style="cursor: pointer">
          <div class="fa fa-angle-double-up"></div>
          {{ ruleForm.controls['id'].value }}
          <a (click)="delete()" *ngIf="editable" [routerLink]="" class="link" id="{{ parentId }}_007_rules_listing_item"
            ><i class="fas fa-trash"></i
          ></a>
        </div>
        <div class="card__body">
          <form *ngIf="rule" [formGroup]="ruleForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'ruleType'"
                    [options]="ruleTypes"
                    [label]="'privilege'"
                    [translationPrefix]="'wc.admin.role.rule'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row" [formGroup]="ruleForm.controls.privilege">
                  <app-input-select-form-field
                    [formControlName]="'id'"
                    [options]="pagedPrivilegesDto.data"
                    [label]="'privilege'"
                    [valueOption]="'id'"
                    [labelOption]="'id'"
                    [translationPrefix]="'wc.admin.role.rule'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <app-input-date-form-field
                    [parentGroup]="ruleForm.controls['validFor']"
                    [controlName]="'startDateTime'"
                    [translationPrefix]="'wc.admin.role.rule'">
                  </app-input-date-form-field>
                </div>
                <div class="form__row">
                  <app-input-date-form-field
                    [parentGroup]="ruleForm.controls['validFor']"
                    [controlName]="'endDateTime'"
                    [translationPrefix]="'wc.admin.role.rule'">
                  </app-input-date-form-field>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="ruleForm"
                    [controlName]="'resourceIdentification'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.role.rule'">
                  </app-custom-input>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </td>
</ng-container>
