import { Component, ViewEncapsulation } from '@angular/core';
import {
  AbstractFilterComponent,
  AdminAclService,
  AdminDynamicEnumService,
  CompareType,
  ServiceUtils,
} from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { EnumEntriesDto } from '@btl/admin-bff';
import { takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import { RoleDto } from '@btl/admin-bff/model/roleDto';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-privileges-filter',
  templateUrl: './privileges-filter.component.html',
  styleUrls: ['./privileges-filter.component.scss'],
  animations: [Animations.advancedFilterArrow],
  encapsulation: ViewEncapsulation.None,
})
export class PrivilegesFilterComponent extends AbstractFilterComponent {
  PickerInputType = PickerInputType;

  resourceTypes: EnumEntriesDto;
  aclRoles: RoleDto[] = [];

  mapFieldWithCompareType = new Map([
    ['id', CompareTypeDtoEnum.EQUAL],
    ["roles", CompareTypeDtoEnum.IN],
    ['resourceType', CompareTypeDtoEnum.EQUAL],
  ]);

  mapFieldWithColumnName = new Map([["id", "id"], ["roles", "role"]]);

  filterForm = this.formBuilder.group({
    id: [],
    roles: [],
    resourceType: [],
  });

  constructor(
    private formBuilder: FormBuilder,
    private adminAclService: AdminAclService,
    private adminDynamicEnumService: AdminDynamicEnumService
  ) {
    super();

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.ACL, 'com.emeldi.ecc.be.acl.enums.ResourceType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.resourceTypes = result;
        this.resourceTypes.data = this.resourceTypes.data.sort((a, b) => a.name.localeCompare(b.name));
      });

    const search = ServiceUtils.getUnlimitedSearch();
    search.sorting.push({ column: 'id', sortOrder: 'asc' });
    this.adminAclService.filterRoles(search, null).subscribe(result => {
      this.aclRoles = result.data;
    });
  }
}
