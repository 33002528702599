import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ContentMasterDto, PagedContentsDto } from '@btl/admin-bff';
import { Subject } from 'rxjs';
import { AdminContentService, AppBlockerService, Search } from '@btl/btl-fe-wc-common';
import { SortDto } from '@btl/order-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-cms-content-banner-modal',
  templateUrl: './cms-content-banner-modal.component.html',
  styleUrls: ['./cms-content-banner-modal.component.scss'],
})
export class CmsContentBannerModalComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  dialogRef;

  @Input()
  contentTemplateType: string;

  @Input()
  displayControls: boolean;

  @Output()
  readonly saveBannerEvent: EventEmitter<ContentMasterDto> = new EventEmitter<ContentMasterDto>();

  search: Search = {
    filtering: [
      { column: 'contentTemplateType', compareType: 'EQUAL', value: null },
      { column: 'state', compareType: 'EQUAL', value: 'ACTIVE' },
    ],
    sorting: [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }],
    paging: { page: 1, pageSize: 50 },
  };

  pagedContentsDto: PagedContentsDto;

  constructor(private appBlockerService: AppBlockerService, private adminContentService: AdminContentService) {}

  ngOnInit(): void {
    this.search.filtering[0].value = this.contentTemplateType;
    this.loadContentMaster(this.search);
  }

  loadContentMaster(search: Search) {
    this.appBlockerService.block();
    this.adminContentService
      .getContentsByFilter(search)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PagedContentsDto) => {
        if (result) {
          this.pagedContentsDto = result;
        } else {
          this.pagedContentsDto = null;
        }
      });
  }

  onSelectContent(contentMasterDto: ContentMasterDto) {
    this.saveBannerEvent.emit(contentMasterDto);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onSortChange(sorting: MatSort) {
    if (sorting.direction !== '') {
      this.search.sorting = [{ column: sorting.active, sortOrder: sorting.direction }];
    } else {
      this.search.sorting = [];
    }
    this.loadContentMaster(this.search);
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadContentMaster(this.search);
  }

  goToPage(pageNo: number): void {
    this.search.paging.page = pageNo;
    this.loadContentMaster(this.search);
  }

  onNext(): void {
    this.search.paging.page++;
    this.loadContentMaster(this.search);
  }

  onPrev(): void {
    this.search.paging.page--;
    this.loadContentMaster(this.search);
  }

  pageSizeChanged(page): void {
    this.search.paging.page = page.pageIndex + 1;
    this.search.paging.pageSize = page.pageSize;
    this.loadContentMaster(this.search);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
