<div class="main__header">
  <h1 id="001_roles_edit">{{ 'wc.admin.roles.roleEdit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_roles_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      [routerLink]=""
      *ngIf="aclService.pageEditAccess"
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="003_roles_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_roles_edit" mat-menu-item (click)="duplicate()" *ngIf="role?.id" [routerLink]=""
      >
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="005_roles_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="006_roles_edit" mat-menu-item (click)="delete()" *ngIf="role?.id" [routerLink]=""
      >
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="007_roles_edit" (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.roles' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="role" [formGroup]="roleForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="roleForm"
                  [disabled]="!!role.recordVersion"
                  [controlName]="'id'"
                  id="008_roles_edit"
                  [inputType]="'text'"
                  [label]="'role'"
                  [translationPrefix]="'wc.admin.roles'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                  <mat-label>{{ 'wc.admin.roles.description' | translate }}</mat-label>
                  <textarea
                    id="009_roles_edit"
                    class="form__input"
                    matInput
                    type="text"
                    formControlName="description"
                    matTextareaAutosize></textarea>
                </mat-form-field>
              </div>
              <div class="form__row"></div>
              <div class="form__row">
                <app-custom-input
                  [id]="'010_roles_edit'"
                  [inputType]="'checkbox'"
                  [parentGroup]="roleForm"
                  [controlName]="'external'"
                  [label]="'wc.admin.roles.external'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card__wrapper" [formGroup]="roleForm">
    <app-form-parameters class="card -full" [control]="roleForm.get('parameters')" formControlName="parameters">
    </app-form-parameters>
  </div>

  <div
    *ngIf="role"
    [editable]="true"
    [role]="role"
    [operations]="operations"
    [cacheFilter]="false"
    app-rules-listing
    class="card__wrapper"></div>

  <div *ngIf="accountsRoleId" [editable]="true" [roleId]="accountsRoleId" app-role-accounts class="card__wrapper"></div>
</div>
