import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatConfirmationDialogComponent } from '@components/mat-confirmation-dialog/mat-confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(public dialog: MatDialog) {}

  public openDialog(texts: string[], heading?: string, localizationParameters?): MatConfirmationDialogComponent {
    return this.dialog.open(MatConfirmationDialogComponent, {
      width: '700px',
      data: {
        headerLocalizationKey: heading ? heading : 'wc.admin.confirmation.header',
        localizationKeys: texts,
        localizationParameters: localizationParameters,
      },
    }).componentInstance;
  }
}
