<div *ngIf="editable" class="main__header">
  <div class="header-box">
    <div class="icon-box ACCESS">
      <mat-icon svgIcon="privileges"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.privileges' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      (click)="newPrivilege()"
      [routerLink]=""
      class="btn -primary -xs"
      *ngIf="aclService.pageEditAccess"
      mat-raised-button
      color="primary"
      id="001_privileges_listing"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>
<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-privileges-filter
        (filterChanged)="handleFilterChange()"
        [filtering]="search.filtering">
      </app-privileges-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.privileges.privilegeListing' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedPrivilegesDto?.totalItems
                          ? pagedPrivilegesDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedPrivilegesDto?.totalItems
                    }
            }}
          </div>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="['id', 'description', 'created', 'modified', 'modifiedBy']"
          [columnToTruncate]="['created', 'modified', 'modifiedBy']"
          [dontShowOnMobile]="['created', 'modified', 'modifiedBy']"
          [fillIdBy]="'id'"
          [pagedDto]="pagedPrivilegesDto"
          (editInNewTabButtonEvent)="editPrivilege($event, true)"
          (editButtonEvent)="editPrivilege($event)"
          [disableActions]="disableActions"
          (deleteButtonEvent)="delete($event)"
          (pageSizeEvent)="pageSizeChanged($event)"
          (doubleClickEvent)="editPrivilege($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [editable]="aclService.pageEditAccess"
          [multiSelectMode]="false"
          [translationPrefix]="'wc.admin.privileges'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
