import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { ChoicesHandler } from '@btl/btl-fe-wc-common';
import { MatDialog } from '@angular/material/dialog';
import {
  EligibilityLegendModalComponent,
} from '@components/eligibility-legend-modal/eligibility-legend-modal.component';
import { CustomAdminContextRulesService } from '@service/custom-admin-context-rules.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-eligibility-expression',
  templateUrl: './eligibility-expression.component.html',
  styleUrls: ['./eligibility-expression.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EligibilityExpressionComponent),
      multi: true,
    },
  ],
})
export class EligibilityExpressionComponent implements OnInit, ControlValueAccessor, OnDestroy, ChoicesHandler {
  @Input()
  value;

  @Input()
  disabled = false;

  @Input()
  parentGroup;

  contextRules;

  form: FormGroup = this.formBuilder.group({
    ruleSetExpression: [null],
  });

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private customAdminContextRulesService: CustomAdminContextRulesService
  ) {
    this.customAdminContextRulesService.getContextRulesList()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((results) => {
        this.contextRules = results;
      });
  }

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  ngOnInit() {
    this.form.controls['ruleSetExpression'].setValue(this.value);
    this.form.valueChanges.subscribe(() => {
      this.propagateChange(this.getValue());
    });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.form.controls['ruleSetExpression'].setValue(value);
  }

  getValue() {
    return this.form.controls['ruleSetExpression'].value;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  findChoices(searchText: string): any[] {
    if (this.contextRules) {
      return this.contextRules.filter(item => item.toLowerCase().includes(searchText.toLowerCase()));
    }
    return [];
  }

  getChoiceLabel(choice: string): string {
    return `${choice} `;
  }

  showLegend() {
    const modalRef = this.dialog.open(EligibilityLegendModalComponent);
    const eligibilityLegendModalComponent = modalRef.componentInstance;
    eligibilityLegendModalComponent.dialogRef = modalRef;
  }
}
