import { Component, OnInit } from '@angular/core';
import { ProductEditService } from '@service/product-edit.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { ProductStateDto } from '@btl/admin-bff';
import { ReleasesSelectService } from '@components/releases-select/releases-select.service';
import { AbstractPageComponent, AclService, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { filter, takeUntil } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { InnerLeftMenuComponent } from '@components/left-menu/inner-left-menu/inner-left-menu.component';
import { Animations } from '@helpers/animations';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import {
  DuplicateProductModalComponent
} from '@components/product-catalogue/products/duplicate-product-modal/duplicate-product-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: ProductEditComponent.PAGE_ID })
export class ProductEditComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'ProductEditComponent';

  pageId(): string {
    return ProductEditComponent.PAGE_ID;
  }

  routerOutlet: any;

  newProduct = true;

  previewUrl: string;

  allowSaveAnyTab = false;

  tabs = [];
  tabIndex = 0;

  checkAllowSaveAnyTabHandler(result) {
    if (result && result.editable) {
      this.allowSaveAnyTab = true;
    } else {
      if (this.tabIndex < this.tabs.length - 1) {
        this.aclService.pageAccess('admin', `pc/products/edit/${this.tabs[this.tabIndex]}`).subscribe(
          result => {
            this.checkAllowSaveAnyTabHandler(result);
          },
          error => {
            this.checkAllowSaveAnyTabHandler(result);
            this.tabIndex++;
          }
        );
      }
    }
  }

  constructor(
    private productEditService: ProductEditService,
    private releasesSelectService: ReleasesSelectService,
    protected router: Router,
    protected route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private dialog: MatDialog
  ) {
    super(router, route);

    this.tabs = InnerLeftMenuComponent.innerMenuItems;

    this.checkAllowSaveAnyTabHandler(null);

    if (this.productEditService.getEditProduct()) {
      this.newProduct = this.productEditService.isNewProduct(this.productEditService.getEditProduct().id);
    }

    this.productEditService.editProductChanged.subscribe(product => {
      if (product) {
        this.newProduct = this.productEditService.isNewProduct(product.id);
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.navigationSubscription(event);
      }
    });
  }

  navigationSubscription(navigation: NavigationEnd) {
    this.productEditService.navigate(navigation);
  }

  ngOnInit() {
    const getPropertyCalls = [
      this.propertyAccessorLocalService.getGlobalBaseUrl(),
      this.propertyAccessorLocalService.getProductPreviewUrlPath(),
    ];
    forkJoin(getPropertyCalls)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((results: string[]) => {
        if (results && results.length === 2) {
          this.previewUrl = results[0];
          this.previewUrl += results[1];
        }
      });
  }

  editProduct() {
    return this.productEditService.getEditProduct();
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  save() {
    if (this.routerOutlet.saveData()) {
      this.productEditService.save();
    }
  }

  patchAvailableFor() {
    if (this.routerOutlet.saveData()) {
      this.productEditService.patchAvailableFor();
    }
  }

  reset() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.reset.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
      if (this.productEditService.getEditProduct().recordVersion) {
        this.productEditService.reset();
      } else {
        this.productEditService.setProductToEdit(this.productEditService.getEditProduct());
      }
      confirmationDialogComponent.dialogReference.close();
    };
  }

  approve() {
    this.productEditService.approve();
  }

  reject() {
    this.productEditService.reject();
  }

  duplicate() {
    const modalRef = this.dialog.open(DuplicateProductModalComponent);
    const duplicateProductModalComponent = modalRef.componentInstance;
    duplicateProductModalComponent.duplicatedProductCode = this.productEditService.getEditProduct().productCode;
    duplicateProductModalComponent.duplicatedSeoUrl = this.productEditService.getEditProduct().seoUrl;
    duplicateProductModalComponent.dialogRef = modalRef;
    duplicateProductModalComponent.duplicateEvent.subscribe(duplicateValue => {
      if (duplicateValue) {
        this.productEditService.duplicate(
          duplicateValue.duplicateOptions,
          duplicateValue.productCode,
          duplicateValue.seoUrl,
          this.productEditService.getEditProduct().id
        );
      }
    });
  }

  cancel() {
    this.router.navigate(['pc/products']);
  }

  isProductReadOnly() {
    return this.isActive() || this.isOsolete() || this.isTest();
  }

  isProductMultiActive() {
    return this.productEditService.isProductMultiActive(this.editProduct());
  }

  archive() {
    this.productEditService.archive();
  }

  test() {
    this.productEditService.test();
  }

  design() {
    this.productEditService.design();
  }

  getState() {
    if (this.editProduct().state === ProductStateDto.ACTIVE) {
      return 'wc.admin.products.productListing.state.active';
    } else if (this.editProduct().state === ProductStateDto.OBSOLETE) {
      return 'wc.admin.products.productListing.state.obsolete';
    } else if (this.editProduct().state === ProductStateDto.DESIGN) {
      return 'wc.admin.products.productListing.state.design';
    } else if (this.editProduct().state === ProductStateDto.TEST) {
      return 'wc.admin.products.productListing.state.test';
    } else {
      return 'wc.admin.products.productListing.state.unknown';
    }
  }

  getIconState() {
    const iconMap = new Map([
      ['ACTIVE', 'activeState'],
      ['OBSOLETE', 'obsoleteState'],
      ['DESIGN', 'designState'],
      ['TEST', 'testState'],
    ]);
    return iconMap.get(this.editProduct().state);
  }

  getActiveState() {
    if (this.editProduct().state === ProductStateDto.ACTIVE) {
      return ProductEditService.productActiveState(this.editProduct());
    }
    return null;
  }

  isMaxDraftAndActiveReached() {
    return this.productEditService.maxDraftAndActiveReached;
  }

  isDesign() {
    return this.editProduct().state === ProductStateDto.DESIGN;
  }

  isTest() {
    return this.editProduct().state === ProductStateDto.TEST;
  }

  isActive() {
    return this.editProduct().state === ProductStateDto.ACTIVE;
  }

  isOsolete() {
    return this.editProduct().state === ProductStateDto.OBSOLETE;
  }

  enableTest(): boolean {
    return this.isDesign() && !this.newProduct;
  }

  enableDesign(): boolean {
    return this.isTest();
  }

  enableApprove(): boolean {
    return !this.newProduct && this.isTest();
  }

  enableArchive(): boolean {
    return this.isActive() && this.isProductMultiActive();
  }

  enableReject(): boolean {
    return !this.newProduct && !this.isActive() && !this.isOsolete();
  }

  enableWarning(): boolean {
    return this.isProductReadOnly() && !this.newProduct && !this.isNewVersion();
  }

  warningKey(): string {
    if (this.isMaxDraftAndActiveReached()) {
      return 'wc.admin.products.product.maxDraftOrApprovedVersionsReached.warning';
    } else if (this.isTest()) {
      return 'wc.admin.products.product.test.warning';
    } else if (this.isNewVersion()) {
      return 'wc.admin.products.product.newVersion.warning';
    } else {
      return 'wc.admin.products.product.patchAvailableFor.warning';
    }
  }

  enableSave(): boolean {
    return (
      !(this.isProductReadOnly() && this.isMaxDraftAndActiveReached()) &&
      (this.isNewVersion() || this.newProduct || this.isDesign())
    );
  }

  enablePatchAvailableFor(): boolean {
    return this.enableSwitchToNewVersion();
  }

  enableSwitchToNewVersion(): boolean {
    return (this.isActive() || this.isOsolete()) && !this.isNewVersion();
  }

  isNewVersion(): boolean {
    return this.productEditService.newVersion;
  }

  setNewVersion(newVersion: boolean): boolean {
    this.productEditService.newVersion = newVersion;
    this.productEditService.enableEditProduct = this.productEditService.isEnabledEditProduct();
    this.productEditService.productVersionChanged.emit(newVersion);
    return this.productEditService.newVersion;
  }

  releases() {
    if (this.editProduct().productCode) {
      this.releasesSelectService.selectReleases('ProdCode', this.editProduct().productCode);
    }
  }

  preview() {
    this.productEditService.preview(this.previewUrl);
  }
}
