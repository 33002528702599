<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'code'"
            [inputType]="'text'"
            [id]="parentId ? parentId + '_' + '001_relationships_filter' : '001_relationships_filter'"
            [translationPrefix]="'wc.admin.productGroups'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            [formControlName]="'type'"
            [id]="parentId ? parentId + '_' + '002_relationships_filter' : '002_relationships_filter'"
            [options]="relationshipTypes"
            (keydown.enter)="handleChange()"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.relationships'">
          </app-input-select-form-field>
          <app-input-date-form-field
            [parentGroup]="filterForm"
            (keydown.enter)="handleChange()"
            [id]="parentId ? parentId + '_' + '003_relationships_filter' : '003_relationships_filter'"
            [controlName]="'validAt'"
            [translationPrefix]="'wc.admin.cartEvents'">
          </app-input-date-form-field>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-input-select-form-field
          [formControlName]="'sourceType'"
          [id]="parentId ? parentId + '_' + '006_relationships_filter' : '006_relationships_filter'"
          [options]="productEntityTypes"
          (keydown.enter)="handleChange()"
          (selectionChangeEmit)="clearField('sourceId')"
          [showEmptyOption]="true"
          [translationPrefix]="'wc.admin.relationships'">
        </app-input-select-form-field>
        <ng-container [ngSwitch]="filterForm.controls['sourceType'] && filterForm.controls['sourceType'].value">
          <app-input-select-form-field
            *ngSwitchCase="'CatTech'"
            [formControlName]="'sourceId'"
            (keydown.enter)="handleChange()"
            [options]="techCategories"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.relationships'">
          </app-input-select-form-field>
          <app-input-picker-form-field
            *ngSwitchCase="'ProdCode'"
            [parentGroup]="filterForm"
            [controlName]="'sourceId'"
            [valueField]="'productCode'"
            (keydown.enter)="handleChange()"
            [pickerType]="PickerInputType.PRODUCT"
            [selectMode]="false"
            [translationPrefix]="'wc.admin.relationships'">
          </app-input-picker-form-field>
          <app-input-picker-form-field
            *ngSwitchCase="'ProdGroup'"
            [parentGroup]="filterForm"
            [controlName]="'sourceId'"
            [valueField]="'id'"
            labelField="code"
            (keydown.enter)="handleChange()"
            [pickerType]="PickerInputType.GROUP"
            [selectMode]="false"
            [translationPrefix]="'wc.admin.relationships'">
          </app-input-picker-form-field>
          <app-custom-input
            *ngSwitchDefault
            [parentGroup]="filterForm"
            [controlName]="'sourceId'"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.relationships'">
          </app-custom-input>
        </ng-container>
        <app-input-select-form-field
          [formControlName]="'targetType'"
          [options]="productEntityTypes"
          [id]="parentId ? parentId + '_' + '007_relationships_filter' : '007_relationships_filter'"
          [showEmptyOption]="true"
          (keydown.enter)="handleChange()"
          (selectionChangeEmit)="clearField('targetId')"
          [translationPrefix]="'wc.admin.relationships'">
        </app-input-select-form-field>
        <ng-container [ngSwitch]="filterForm.controls['targetType'] && filterForm.controls['targetType'].value">
          <app-input-select-form-field
            *ngSwitchCase="'CatTech'"
            [formControlName]="'targetId'"
            (keydown.enter)="handleChange()"
            [options]="techCategories"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.relationships'">
          </app-input-select-form-field>
          <app-input-picker-form-field
            *ngSwitchCase="'ProdCode'"
            [parentGroup]="filterForm"
            [controlName]="'targetId'"
            (keydown.enter)="handleChange()"
            [valueField]="'productCode'"
            [selectMode]="false"
            [pickerType]="PickerInputType.PRODUCT"
            [translationPrefix]="'wc.admin.relationships'">
          </app-input-picker-form-field>
          <app-input-picker-form-field
            *ngSwitchCase="'ProdGroup'"
            [parentGroup]="filterForm"
            [controlName]="'targetId'"
            [valueField]="'id'"
            labelField="code"
            (keydown.enter)="handleChange()"
            [pickerType]="PickerInputType.GROUP"
            [selectMode]="false"
            [translationPrefix]="'wc.admin.relationships'">
          </app-input-picker-form-field>
          <app-custom-input
            *ngSwitchDefault
            [parentGroup]="filterForm"
            [controlName]="'targetId'"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.relationships'">
          </app-custom-input>
        </ng-container>
      </div>
    </div>
  </mat-expansion-panel>
</form>
