import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentWidgetDto, WidgetTemplateDto } from '@btl/admin-bff';
import { CmsContentWidgetComponent } from '@components/cms/contents/edit/widget/cms-content-widget.component';

@Component({
  selector: 'app-cms-content-widget-add-modal',
  templateUrl: './cms-content-widget-add-modal.component.html',
  styleUrls: ['./cms-content-widget-add-modal.component.scss'],
})
export class CmsContentWidgetAddModalComponent {
  @Input()
  widgetTemplateDtoList: Array<WidgetTemplateDto> = [];

  @Input()
  bannerTemplateDtoList: Array<WidgetTemplateDto> = [];

  @Input()
  contentTemplateType: string;

  @Output()
  readonly selectItemEvent: EventEmitter<ContentWidgetDto> = new EventEmitter<any>();

  widgetIconMap: string[];

  constructor() {
    this.widgetIconMap = CmsContentWidgetComponent.initIconMap();
  }

  selectItem(item: WidgetTemplateDto | 'BANNER_NEW'): void {
    if (item === 'BANNER_NEW') {
      item = { type: 'BANNER_NEW' };
    }
    this.selectItemEvent.emit(item);
  }
}
