import { Component, ViewEncapsulation } from '@angular/core';
import {
  AbstractFilterComponent,
  AdminAclService,
  AdminDynamicEnumService,
  CompareType,
  ServiceUtils,
} from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { AccountAdminService, EnumEntriesDto, PagedPrivilegesDto, RuleDto } from '@btl/admin-bff';
import { takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import RuleTypeDtoEnum = RuleDto.RuleTypeDtoEnum;
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-roles-filter',
  templateUrl: './roles-filter.component.html',
  styleUrls: ['./roles-filter.component.scss'],
  animations: [Animations.advancedFilterArrow],
  encapsulation: ViewEncapsulation.None,
})
export class RolesFilterComponent extends AbstractFilterComponent {
  PickerInputType = PickerInputType;

  panelOpenState: boolean = false;

  ruleTypes: RuleTypeDtoEnum[] = Object.values(this.adminAclService.getRuleTypeDict());
  external = [
    { labelKey: 'wc.common.yes.button', value: true },
    { labelKey: 'wc.common.no.button', value: false },
  ];
  resourceTypes: EnumEntriesDto;
  pagedPrivilegesDto: PagedPrivilegesDto;
  privilegesHints = [];

  mapFieldWithCompareType = new Map([
    ['id', CompareTypeDtoEnum.EQUAL],
    ['ids', CompareTypeDtoEnum.IN],
    ['resourceIdentification', CompareTypeDtoEnum.LIKE],
  ]);

  mapFieldWithColumnName = new Map([
    ['id', 'id'],
    ['ids', 'id'],
  ]);

  filterForm = this.formBuilder.group({
    id: [],
    ruleType: [],
    privilegeType: [],
    resourceType: [],
    resourceIdentification: [],
    validAt: [],
    external: [],
    ids: [],
    accountId: [],
  });

  advancedFilterFields = ['resourceIdentification', 'validAt', 'external', 'ids'];

  constructor(
    private formBuilder: FormBuilder,
    private adminAclService: AdminAclService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private accountAdminService: AccountAdminService
  ) {
    super();

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.ACL, 'com.emeldi.ecc.be.acl.enums.ResourceType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.resourceTypes = result;
        this.resourceTypes.data = this.resourceTypes.data.sort((a, b) => a.name.localeCompare(b.name));
      });

    const search = ServiceUtils.getUnlimitedSearch();
    this.adminAclService
      .filterPrivileges(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        result.data.sort((a, b) => a.id.localeCompare(b.id));
        this.pagedPrivilegesDto = result;
        this.privilegesHints = this.pagedPrivilegesDto.data.map(c => c.id);
      });
  }

  handleChange() {
    if (this.filterForm.controls.accountId.value) {
      Object.keys(this.filterForm.controls).forEach(fieldName => {
        this.handleField(fieldName);
      });
      this.accountAdminService.getAccountById(this.filterForm.controls.accountId.value).subscribe(account => {
        this.filterForm.controls.ids.patchValue(account.roles.map(role => role.roleName));
        super.handleChange();
      });
    } else {
      this.filterForm.controls.ids.patchValue(null);
      super.handleChange();
    }
  }
}
