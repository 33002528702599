import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentMasterDto } from '@btl/admin-bff';

@Component({
  selector: '[app-cms-content-banner-item]',
  templateUrl: './cms-content-banner-item.component.html',
  styleUrls: ['./cms-content-banner-item.component.scss'],
})
export class CmsContentBannerItemComponent {
  @Output()
  readonly selectEvent: EventEmitter<ContentMasterDto> = new EventEmitter<ContentMasterDto>();

  @Input()
  contentMasterDto: ContentMasterDto;

  @Input()
  parentId: string = '';

  selectAndClose() {
    this.selectEvent.emit(this.contentMasterDto);
  }

  getInternalText(isInternal: boolean): string {
    if (isInternal) {
      return 'wc.common.yes.button';
    } else {
      return 'wc.common.no.button';
    }
  }
}
