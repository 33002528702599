import { Component, Input, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EnumerationsService } from '@service/enumerations.service';

@Component({
  selector: 'app-categories-select-modal',
  templateUrl: './categories-select-modal.component.html',
  styleUrls: ['./categories-select-modal.component.scss'],
})
export class CategoriesSelectModalComponent implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  multiSelectMode = false;

  singleSelectMode = true;

  @Input()
  disableActions = false;

  @Input()
  disableTableActions = false;

  form: FormGroup = this.formBuilder.group({
    categories: [null],
  });

  techCategories = [];

  constructor(private formBuilder: FormBuilder, private enumerationsService: EnumerationsService) {
    this.enumerationsService
      .getProductTechnicalCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.techCategories = result;
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  activeMultiSelectMode() {
    this.multiSelectMode = true;
    this.singleSelectMode = false;
  }

  selectEvent() {
    const categories = this.form.controls.categories.value;
    if (categories && categories.length > 0) {
      this.selectHandler(categories);
      this.dialogRef.close();
    }
  }

  removeSelection() {
    if (this.singleSelectMode) {
      this.selectHandler(null);
    } else {
      this.selectHandler([]);
    }
    this.dialogRef.close();
  }
}
