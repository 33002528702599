<div class="main__header">
  <h1 id="001_agreementTypes_edit">{{ 'wc.admin.agreementTypes.agreementTypeEdit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_agreementTypes_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      [routerLink]=""
      *ngIf="aclService.pageEditAccess"
      class="btn -primary -xs icon stroke currentColor">
      <mat-icon svgIcon="save"></mat-icon>
      {{ 'wc.common.save' | translate }}</a
    >
    <a
      id="003_agreementTypes_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_agreementTypes_edit" mat-menu-item (click)="duplicate()" *ngIf="agreementType?.id" [routerLink]="">
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="005_agreementTypes_edit" mat-menu-item (click)="reset()" [routerLink]="">
        <mat-icon svgIcon="reset"></mat-icon>
        {{ 'wc.common.reset' | translate }}</a
      >
      <a id="006_agreementTypes_edit" mat-menu-item (click)="delete()" *ngIf="agreementType?.id" [routerLink]="">
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="007_agreementTypes_edit" (click)="cancel()" class="btn cancel-button">{{
      'wc.common.cancel.button' | translate
    }}</a>
  </div>
</div>

<div class="card__wrapper" *ngIf="agreementType">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__body">
        <form [formGroup]="form" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="form"
                  [controlName]="'code'"
                  id="008_agreementTypes_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.agreementTypes'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="form"
                  [controlName]="'name'"
                  id="009_agreementTypes_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.agreementTypes'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'010_agreementTypes_edit'"
                  [inputType]="'checkbox'"
                  [parentGroup]="form"
                  [controlName]="'mandatory'"
                  [label]="'wc.admin.agreementTypes.mandatory'">
                </app-custom-input>
              </div>
              <ng-container formGroupName="validFor">
                <div class="form__row">
                  <app-input-date-form-field
                    [parentGroup]="form.controls['validFor']"
                    [controlName]="'startDateTime'"
                    id="023_cart_event_edit"
                    [label]="'validFrom'"
                    [translationPrefix]="'wc.admin.cartEvents'">
                  </app-input-date-form-field>
                </div>
                <div class="form__row">
                  <app-input-date-form-field
                    [parentGroup]="form.controls['validFor']"
                    [label]="'validTo'"
                    id="024_cart_event_edit"
                    [controlName]="'endDateTime'"
                    [translationPrefix]="'wc.admin.cartEvents'">
                  </app-input-date-form-field>
                </div>
              </ng-container>
            </div>
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [disabled]="true"
                  [label]="'wc.common.created'"
                  [simplifiedInput]="true"
                  [enteredValue]="agreementType.created | date: 'dd.MM.yyyy HH:mm'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [disabled]="true"
                  [label]="'wc.common.createdBy'"
                  [simplifiedInput]="true"
                  [enteredValue]="agreementType.createdBy">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card__wrapper" [formGroup]="form">
    <app-form-references
      class="card -full"
      sourceName="crm"
      entityTypeEnumName="com.emeldi.ecc.be.crm.enums.AgreementTypeRefEntityType"
      refTypeEnumName="com.emeldi.ecc.be.crm.enums.AgreementTypeRefRefType"
      [entityIdMandatory]="false"
      [entitiesWithEntityIdMandatory]="['com.emeldi.ecc.be.pc.dto.productcatalog.Product']"
      [collapsed]="false"
      [control]="form.get('references')"
      formControlName="references">
    </app-form-references>
  </div>

  <div class="card -two" [formGroup]="attachmentsForm">
    <app-form-attachments
      [control]="attachmentsForm.get('attachments')"
      formControlName="attachments"
      class="card -flex"
      [getFromGalleryEnabled]="true"
      [priorityChange]="false"
      [languageChange]="true"
      [textsChange]="true"
      [entityType]="AGREEMENT_TYPE"
      [entityId]="agreementType.id"
      [types]="['CRM_AGREEMENT']"
      extIdPrefix="CRM_AGREEMENT_"
      dmsType="CRM_AGREEMENT"
      headerTranslationKey="wc.admin.ticket.attachments.header">
    </app-form-attachments>
  </div>

  <form [formGroup]="form" *ngIf="textTypes" class="card__wrapper">
    <app-form-texts
      class="card -full"
      [textTypes]="textTypes"
      [textTypeTranslationPrefix]="'wc.admin.agreementType'"
      formControlName="texts">
    </app-form-texts>
  </form>

  <div class="card__wrapper" [formGroup]="form">
    <app-form-parameters
      class="card -full"
      [dictionaryValue]="true"
      [control]="form.get('params')"
      formControlName="params">
    </app-form-parameters>
  </div>
</div>
