<div class="card -full">
  <div class="card__inner">
    <div class="card__header" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <span>{{ 'wc.admin.productGroups.usedForProducts' | translate }}</span>
      </div>
      <div fxLayoutGap="10px" class="listing-action" fxLayout="row" style="align-items: center">
        <button
          id="001_group_products_listing"
          color="primary"
          mat-raised-button
          (click)="addProduct()"
          [routerLink]=""
          class="btn -primary -xs edit icon stroke">
          <mat-icon svgIcon="plus"></mat-icon>{{ 'wc.common.add' | translate }}
        </button>
        <button
          id="002_group_products_listing"
          color="primary"
          mat-stroked-button
          (click)="deleteCheckedProduct()"
          [routerLink]=""
          class="btn -primary -xs icon delete currentColor">
          <mat-icon svgIcon="delete"></mat-icon>{{ 'wc.admin.delete' | translate }}
        </button>
        <mat-icon
          (click)="isCollapsed = !isCollapsed"
          color="primary"
          svgIcon="{{ isCollapsed ? 'hide' : 'show' }}"
          style="cursor: pointer"></mat-icon>
      </div>
    </div>
    <div [hidden]="isCollapsed" class="card__body">
      <div class="group_products__container">
        <table class="table -primary customTable no-spacing">
          <thead>
            <th scope="col"></th>
            <th scope="col">
              <app-sortable-column localizationKey="wc.admin.productGroups.name"> </app-sortable-column>
            </th>
            <th scope="col">
              <app-sortable-column localizationKey="wc.admin.products.product.productCode"> </app-sortable-column>
            </th>
          </thead>
          <tbody *ngIf="productMap">
            <tr
              *ngFor="let groupProduct of formArray.controls; let i = index"
              [product]="productMap.get(groupProduct.get('productMasterId').value)"
              [groupProductForm]="groupProduct"
              (productChangedEvent)="productChanged($event)"
              [visible]="isOnPage(groupProduct)"
              app-group-products-listing-item
              [parentId]="'listing_' + i"></tr>
          </tbody>
        </table>
        <mat-paginator
          [length]="groupProducts?.length"
          [pageSize]="50"
          (page)="pageSizeChanged($event)"
          showFirstLastButtons="true"
          [pageSizeOptions]="[10, 25, 50, 100]"
          color="primary">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
