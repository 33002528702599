import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
,
} from '@angular/material/dialog';
import { BlockTemplateComponent } from '@btl/btl-fe-wc-common';

export interface DialogData {
  headerLocalizationKey: string;
  localizationKeys: Array<string>;
  localizationParameters: any;
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './mat-confirmation-dialog.component.html',
  styleUrls: ['./mat-confirmation-dialog.component.scss'],
})
export class MatConfirmationDialogComponent {
  blockTemplate = BlockTemplateComponent;

  public confirmationHandler;
  public declineHandler;

  constructor(
    public dialogReference: MatDialogRef<MatConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
