<div class="main__header">
  <h1 id="001_sticker_edit">{{ 'wc.admin.stickers.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_sticker_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="003_sticker_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_sticker_edit" mat-menu-item (click)="duplicate()" *ngIf="stickerDto?.id" [routerLink]=""
      >
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="005_sticker_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="006_sticker_edit" mat-menu-item (click)="delete()" *ngIf="stickerDto?.id" [routerLink]=""
      >
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
      <a id="007_sticker_edit" mat-menu-item (click)="releases()" *ngIf="stickerDto?.code" [routerLink]=""
      >
        <mat-icon svgIcon="upload"></mat-icon>
        {{ 'wc.admin.releases' | translate }}</a
      >
    </mat-menu>
    <a id="012_sticker_edit" (click)="cancel()" class="btn cancel-button">{{
      'wc.common.cancel.button' | translate
    }}</a>
  </div>
</div>

<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

<div *ngIf="stickerDto" class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.stickers.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="getEditSticker()" [formGroup]="stickerForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="stickerForm"
                  [controlName]="'code'"
                  [inputType]="'text'"
                  id="008_sticker_edit"
                  [label]="'id'"
                  [translationPrefix]="'wc.admin.sticker'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="stickerForm"
                  [controlName]="'priority'"
                  id="009_sticker_edit"
                  [inputType]="'number'"
                  [translationPrefix]="'wc.admin.sticker'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  *ngIf="stickerTypes"
                  id="010_sticker_edit"
                  [formControlName]="'type'"
                  [options]="stickerTypes"
                  [showEmptyOption]="true"
                  [translationPrefix]="'wc.admin.sticker'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="stickerForm"
                  [controlName]="'grp'"
                  id="011_sticker_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.sticker'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- card -->
  <form [formGroup]="stickerForm" class="card__wrapper">
    <app-form-texts
      class="card -full"
      [pcEntityType]="'ProdSticker'"
      [textTypeTranslationPrefix]="'wc.admin.sticker'"
      formControlName="texts">
    </app-form-texts>
  </form>

  <div class="card__wrapper" [formGroup]="stickerForm">
    <app-form-pictures
      class="card -full"
      *ngIf="stickerDto && pictureTypes"
      formControlName="pictures"
      [control]="stickerForm.controls['pictures']"
      [types]="pictureTypes"
      [getFromGalleryEnabled]="false"
      [singlePictureForType]="true"
      extIdPrefix="PC_PICTURE"
      dmsType="GENERAL"
      headerTranslationKey="wc.admin.pictures">
    </app-form-pictures>
  </div>
</div>
