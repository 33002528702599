import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppBlockerService, FormUtils } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ProductPriceDto } from '@btl/admin-bff';
import { ProductEditService } from '@service/product-edit.service';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-product-price-edit',
  templateUrl: './product-price-edit.component.html',
  styleUrls: ['./product-price-edit.component.scss'],
})
export class ProductPriceEditComponent implements OnInit, CanComponentDeactivate {
  private onDestroy$: Subject<void> = new Subject<void>();

  dialogRef: MatDialogRef<ProductPriceEditComponent>;
  selectHandler: () => void;

  productPrice: ProductPriceDto = null;
  isCanceling: boolean = false;
  parameters: Array<any> = [];

  @Input()
  productId: string;
  @Input()
  currentProductId: string;
  @Input()
  isNewPrice: boolean;

  static deleteControlError(control: AbstractControl, errorKey: string) {
    if (control.errors) {
      delete control.errors[errorKey];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    public productEditService: ProductEditService,
  ) {
  }

  reloadDisabledFields(form: FormGroup, parentForm?: FormGroup, formControl?) {
    Object.keys(form.controls).forEach(formControlName => {
      formControlName = formControl ? `${formControl}.${formControlName}` : formControlName;
      const controlForm = parentForm ? parentForm.get(formControlName) : form.get(formControlName);
      if (controlForm instanceof FormControl) {
        this.disableControl(parentForm ? parentForm : form, formControlName);
      }
      if (controlForm instanceof FormGroup) {
        this.reloadDisabledFields(controlForm, form, formControlName);
      }
    });
  }

  disableControl(form, controlName) {
    if (this.productEditService.isEnabledEditProduct()) {
      form.get(controlName).enable();
    } else {
      form.get(controlName).disable();
    }
    if (controlName === 'priceAppType' && !this.productEditService.getEditProduct().productParameters['isDiscount']) {
      form.get(controlName).disable();
    }
  }

  validateOrderOfStartEnd: ValidatorFn = function (control: AbstractControl) {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      const startControl = group.get('startDateTime');
      const endControl = group.get('endDateTime');
      const start = startControl.value;
      const end = endControl.value;
      if (start && end && start > end) {
        return { startIsLaterThanEnd: true };
      } else {
        if (control === startControl) {
          ProductPriceEditComponent.deleteControlError(endControl, 'startIsLaterThanEnd');
        } else if (control === endControl) {
          ProductPriceEditComponent.deleteControlError(startControl, 'startIsLaterThanEnd');
        }
      }
    }
    return null;
  };

  priceForm: FormGroup = this.formBuilder.group({
    id: [null],
    priceType: [null, Validators.required],
    priority: [0, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
    price: [0, [Validators.required, Validators.pattern('^[0-9]*.[0-9]*$')]],
    code: [null, [Validators.pattern('[A-Za-z0-9_-]+')]],
    priceAppType: [null, Validators.required],
    tax: null,
    validFor: this.formBuilder.group({
      startDateTime: [null, [Validators.required, this.validateOrderOfStartEnd]],
      endDateTime: [, this.validateOrderOfStartEnd],
    }),
    ruleSetExpression: [null],
  });

  priceParams: FormGroup = this.formBuilder.group({
    parameters: [],
  });

  ngOnInit() {
    this.productEditService.setProductIdPriceToEdit(this.productId, true);
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if (result) {
        this.reloadProductPrice(this.productEditService.getEditProductPrice());
      }
    });
    this.productEditService.productVersionChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if (result) {
        this.reloadProductPrice(this.productEditService.getEditProductPrice());
      }
    });
    this.reloadProductPrice(this.productEditService.getEditProductPrice());
  }

  reloadProductPrice(price: ProductPriceDto) {
    if (price) {
      this.productPrice = price;
      this.parameters = [];
      Object.keys(this.productPrice.priceParams).forEach(paramKey => {
        this.parameters.push({
          name: paramKey,
          value: this.productPrice.priceParams[paramKey],
        });
      });
      this.priceForm.patchValue(this.productPrice);
      this.priceParams.patchValue({ parameters: this.parameters });
      this.reloadDisabledFields(this.priceForm);
    }
  }

  reset() {
    this.reloadProductPrice(this.productEditService.getEditProductPrice());
  }

  cancel() {
    this.isCanceling = true;
    this.dialogRef.close();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.isCanceling) {
      this.isCanceling = false;
      return true;
    }
    if (this.saveData()) {
      return true;
    }
    return false;
  }

  saveData(): Observable<boolean> | boolean {
    if (!this.productEditService.canEdit()) {
      return true;
    }
    const saved = this.savePriceAttributes() && this.savePriceParameters();
    if (saved) {
      this.productEditService.addProductPriceIfIsNew();
    }
    return saved;
  }

  savePriceAttributes(): boolean {
    const productPrice = this.productEditService.getEditProductPrice();
    if (!productPrice) {
      return true;
    }
    FormUtils.validateAllFormFields(this.priceForm);
    if (this.priceForm.valid) {
      Object.keys(this.priceForm.controls).forEach(field => {
        productPrice[field] = this.priceForm.get(field).value;
      });
      return true;
    }
    return false;
  }

  savePriceParameters(): boolean {
    if (!this.productEditService.getEditProductPrice()) {
      return true;
    }
    const parameters = {};
    this.priceParams.controls['parameters'].value.forEach(param => {
      parameters[param.name] = param.value;
    });
    this.productEditService.getEditProductPrice().priceParams = parameters;
    return true;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.saveData()) {
      this.selectHandler();
      this.dialogRef.close();
    }
  }
}
