<div class="main__header">
  <h1>{{ 'wc.admin.stepTemplate.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="001_ticket_step_template_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="002_ticket_step_template_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="003_ticket_step_template_edit" mat-menu-item (click)="duplicate()" *ngIf="stepTemplateDto?.id"
         [routerLink]=""
      >
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="004_ticket_step_template_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="005_ticket_step_template_edit" mat-menu-item (click)="delete()" *ngIf="stepTemplateDto.id" [routerLink]=""
      >
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="009_ticket_step_template_edit" (click)="cancel()" class="btn cancel-button">{{
      'wc.common.cancel.button' | translate
    }}</a>
  </div>
</div>

<router-outlet></router-outlet>

<div class="card__wrapper">
  <div class="card -full">
    <div class="card__inner">
      <div class="card__body">
        <form class="form" [formGroup]="stepTemplateForm">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="stepTemplateForm"
                  [controlName]="'code'"
                  id="006_ticket_step_template_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.stepTemplate'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                  <mat-label>{{ 'wc.admin.stepTemplate.stepTemplateNote.value' | translate }}</mat-label>
                  <textarea
                    id="007_ticket_step_template_edit"
                    class="form__input"
                    matInput
                    type="text"
                    formControlName="description"></textarea>
                </mat-form-field>
              </div>
              <div class="form__row"></div>
            </div>
            <div class="form__group -flex" *ngIf="stepTemplateDto?.usedByTicketTypes?.length > 0">
              <div class="form__row">
                <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                  <mat-label>{{ 'wc.admin.ticketType.stepTemplateUsed.value' | translate }}</mat-label>
                  <textarea
                    id="008_ticket_step_template_edit"
                    class="form__input"
                    matInput
                    type="text"
                    disabled
                    [(ngModel)]="stepTemplateUsedAt"
                    [ngModelOptions]="{ standalone: true }"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card -full">
    <form class="form">
      <app-ticket-step-template-steps-list *ngIf="stepTemplateDto" [parentGroup]="stepTemplateForm" [readOnly]="false">
      </app-ticket-step-template-steps-list>
    </form>
  </div>
</div>
