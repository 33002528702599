<div >
  <div>
    <h2 mat-dialog-title>
      {{ 'wc.admin.product.duplicate.options' | translate }}
      <mat-icon
        class="default-icon-gray-fill no-stroke"
        svgIcon="close"
        mat-dialog-close
        matTooltip="{{ 'wc.common.close' | translate }}">
      </mat-icon>
    </h2>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <div [formGroup]="form" class="form">
    <div class="form__groups">
      <div class="form__group -flex">
        <div class="form__row one-input">
          <app-custom-input
            id="001_duplicate_product"
            [parentGroup]="form"
            [controlName]="'productCode'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.products.attributes'">
          </app-custom-input>
        </div>
        <div class="form__row one-input">
          <app-custom-input
            id="002_duplicate_product"
            [parentGroup]="form"
            [controlName]="'seoUrl'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.products.attributes'">
          </app-custom-input>
        </div>
      </div>
      <div class="form__group">
        <div class="form__row">
          <div>
            <div *ngFor="let control of formArray.controls; let i = index" class="form-row">
              <input [formControl]="control" [id]="'duplicateOptions-' + i" type="checkbox" />
              <label [for]="'duplicateOptions-' + i">{{
                'wc.admin.product.duplicate.options.' + items[i] | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="primary" mat-dialog-close mat-raised-button>{{ 'wc.common.cancel.button' | translate }}</button>
  <button (click)="continue()" cdkFocusInitial color="primary" mat-raised-button>
    {{ 'wc.common.duplicate.button' | translate }}
  </button>
</mat-dialog-actions>
