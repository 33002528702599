<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.products.prices' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="001_product_price_listing"
            color="primary"
            mat-raised-button
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            (click)="editProductPrice(null, true)"
            class="btn -primary -xs edit icon stroke"
            [disabled]="!this.productEditService.enableEditProduct">
            <mat-icon svgIcon="plus"></mat-icon>{{ 'wc.admin.products.new.price' | translate }}
          </button>
          <button
            id="002_product_price_listing"
            color="primary"
            mat-stroked-button
            *ngIf="aclService.pageEditAccess && getSelectedProductPrices().length > 0"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor"
            [disabled]="!this.productEditService.enableEditProduct">
            <mat-icon svgIcon="delete"></mat-icon>{{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          *ngIf="prices"
          [customData]="prices"
          [enablePaginator]="false"
          (doubleClickEvent)="editProductPrice($event)"
          [disableEditInNewTabButton]="true"
          (editButtonEvent)="editProductPrice($event)"
          [disableEditButton]="!this.productEditService.enableEditProduct"
          (deleteButtonEvent)="deleteProductPrice($event)"
          [disableDeleteButton]="!this.productEditService.enableEditProduct"
          [editable]="aclService.pageEditAccess"
          [columnToTruncate]="['price']"
          [columnName]="[
            'priceType',
            'priority',
            'price',
            'ruleSetExpression',
            'validFor.startDateTime',
            'validFor.endDateTime'
          ]"
          [fillIdBy]="'id'"
          [translationPrefix]="'wc.admin.products.prices'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
