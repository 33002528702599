import { Component, OnInit } from '@angular/core';
import {
  AbstractPageComponent,
  AclService,
  AdminDynamicEnumService,
  AppBlockerService,
  EnableDynamicLoading,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { EnumEntriesDto, EnumEntryDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { EnumerationsService } from '@service/enumerations.service';
import { GroupTypeEditComponent } from '@components/product-catalogue/groupType/edit/group-type-edit.component';

@Component({
  selector: 'app-group-type-listing',
  templateUrl: './group-type-listing.component.html',
  styleUrls: ['./group-type-listing.component.scss'],
})
@EnableDynamicLoading({ customName: GroupTypeListingComponent.PAGE_ID })
export class GroupTypeListingComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'GroupTypeListingComponent';

  pageId(): string {
    return GroupTypeListingComponent.PAGE_ID;
  }

  readonly SOURCE_NAME = EnumerationsService.PC;
  readonly ENUM_NAME = 'com.emeldi.ecc.be.pc.enums.ProductGroupType';

  filteredEnumEntries: EnumEntriesDto;
  allEnumEntries: EnumEntriesDto;

  constructor(
    private adminDynamicEnumService: AdminDynamicEnumService,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit() {
    this.loadGroupTypes();
  }

  deleteGroupType(groupType: EnumEntryDto) {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.groupType.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminDynamicEnumService
        .deleteEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, groupType.name)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.loadGroupTypes();
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  editGroupType(groupType: EnumEntryDto, inNewTab: boolean = false) {
    this.navigateSibling(GroupTypeEditComponent.PAGE_ID, { id: groupType.name }, inNewTab);
  }

  newGroupType() {
    this.navigateSibling(GroupTypeEditComponent.PAGE_ID, { id: '&' });
  }

  private loadGroupTypes() {
    this.adminDynamicEnumService.getEnumEntries(this.SOURCE_NAME, this.ENUM_NAME, true).subscribe(result => {
      this.filteredEnumEntries = result;
      this.allEnumEntries = result;
    });
  }

  filterChanged(filterValue: string) {
    this.filteredEnumEntries = JSON.parse(JSON.stringify(this.allEnumEntries));

    if (filterValue) {
      this.filteredEnumEntries.data = this.filteredEnumEntries.data.filter(enumEntry =>
        enumEntry.name.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
  }
}
