import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AdminAclService,
  AdminDynamicEnumService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  ControlStructSingletonFilterMiner,
  Search,
} from '@btl/btl-fe-wc-common';
import { EnumEntryDto } from '@btl/admin-bff';
import { RoleDto } from '@btl/admin-bff/model/roleDto';
import { takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';

@Component({
  selector: 'app-account-entity-filter',
  templateUrl: './account-entity-filter.component.html',
  animations: [Animations.advancedFilterArrow],
})
export class AccountEntityFilterComponent extends ComplexFilter {
  PickerInputType = PickerInputType;

  @Input()
  hideAccountsFilters = false;

  entityTypes: EnumEntryDto[] = [];
  aclRoles: RoleDto[] = [];

  filterForm = this.formBuilder.group({
    accountId: [],
    delegatedByAccountId: [],
    entityType: [],
    entityId: [],
    validAt: [],
    roleType: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.accountId, 'account.accountId'),
    new ControlFilterMiner(this.filterForm.controls.delegatedByAccountId, 'delegatedByAccount.accountId'),
    new ControlFilterMiner(this.filterForm.controls.validAt, 'validAt'),
    new ControlFilterMiner(this.filterForm.controls.roleType, 'roleType'),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.entityType, 'entities', 'entityType', 'EQUAL'),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.entityId, 'entities', 'entityId', 'EQUAL'),
  ];

  advancedFilterFields = ['validAt', 'roleType'];

  constructor(
    private formBuilder: FormBuilder,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private adminAclService: AdminAclService
  ) {
    super();
    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.ACCOUNT, 'com.emeldi.ecc.be.account.enums.ExternalEntityType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.entityTypes = result.data;
      });

    const search: Search = { filtering: [], sorting: [], paging: { page: 1, pageSize: -1 } };
    this.adminAclService
      .filterRoles(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.aclRoles = result.data.sort((a, b) => a.id.localeCompare(b.id));
      });
  }

  public getFormValue() {
    return this.filterForm.getRawValue();
  }
}
