import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  defaultTheme;

  private colorTheme = new BehaviorSubject(
    sessionStorage.getItem('theme') ? sessionStorage.getItem('theme') : 'darkMode'
  );
  isTheme = this.colorTheme.asObservable();

  constructor() {
    this.initTheme();
  }

  initTheme() {
    const themeColor = sessionStorage.getItem('theme');
    this.setThemeColor(themeColor ? themeColor : 'darkMode');
  }

  setThemeColor(themeColor) {
    sessionStorage.setItem('theme', themeColor);
    this.colorTheme.next(themeColor);
  }

  getThemeColor() {
    return this.colorTheme;
  }

  isColorTheme() {
    return this.colorTheme;
  }
}
