<h2 mat-dialog-title>
  {{ 'wc.admin.cms.content.widget.add' | translate }}
  <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content class="mat-typography">
  <div class="widget-add-modal-title">{{ 'wc.admin.cms.content.widget.label' | translate }}</div>
  <div>
    <div
      class="widget-add-modal-item"
      *ngFor="let item of widgetTemplateDtoList; let i = index"
      (click)="selectItem(item)"
      [id]="'001_cms_contents_widget_add_modal_' + i">
      <div class="widget-add-modal-item-img">
        <mat-icon
          class="default-icon-currentColor"
          [svgIcon]="widgetIconMap[item.type] ? widgetIconMap[item.type] : 'widgetNew'"></mat-icon>
      </div>
      <div class="widget-add-modal-item-label">{{ item.type }}</div>
    </div>
    <div style="clear: both"></div>
  </div>
  <ng-container *ngIf="contentTemplateType !== 'BANNER'">
    <div class="widget-add-modal-title">{{ 'wc.admin.cms.content.banner.label' | translate }}</div>
    <div>
      <div
        class="widget-add-modal-item"
        *ngFor="let item of bannerTemplateDtoList; let i = index"
        (click)="selectItem(item)"
        [id]="'002_cms_contents_widget_add_modal_' + i">
        <div class="widget-add-modal-item-img">
          <mat-icon
            class="default-icon-currentColor"
            [svgIcon]="widgetIconMap[item.type] ? widgetIconMap[item.type] : 'widgetProductlist'"></mat-icon>
        </div>
        <div class="widget-add-modal-item-label">{{ item.type }}</div>
      </div>

      <div
        class="widget-add-modal-item"
        (click)="selectItem('BANNER_NEW')"
        [id]="'003_cms_contents_widget_add_modal'">
        <div class="widget-add-modal-item-img">
          <mat-icon class="default-icon-currentColor" svgIcon="widgetIconlist"></mat-icon>
        </div>
        <div class="widget-add-modal-item-label">{{ 'wc.admin.cms.content.banner.new' | translate }}</div>
      </div>
      <div style="clear: both"></div>
    </div>
  </ng-container>
</mat-dialog-content>

