<div class="main__header">
  <h1 id="001_dynamic_context_rule_edit">{{ 'wc.admin.contextRules.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      *ngIf="aclService.pageEditAccess"
      id="002_dynamic_context_rule_edit"
      color="primary"
      mat-raised-button
      class="btn -primary -xs icon stroke currentColor"
      [routerLink]=""
      (click)="save()"
      ><mat-icon svgIcon="save" />{{ 'wc.common.save' | translate }}</a
    >
    <a
      *ngIf="aclService.pageEditAccess"
      #t="matMenuTrigger"
      id="003_dynamic_context_rule_edit"
      color="primary"
      mat-stroked-button
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [routerLink]=""
      [matMenuTriggerFor]="menu"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'" />
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_dynamic_context_rule_edit" mat-menu-item [routerLink]="" (click)="duplicate()"
        ><mat-icon svgIcon="duplicate" />{{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="005_dynamic_context_rule_edit" mat-menu-item [routerLink]="" (click)="reset()"
        ><mat-icon svgIcon="reset" />{{ 'wc.common.reset' | translate }}</a
      >
      <a *ngIf="!isNewDcr()" id="006_dynamic_context_rule_edit" mat-menu-item [routerLink]="" (click)="delete()"
        ><mat-icon svgIcon="delete" />{{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="007_dynamic_context_rule_edit" class="btn cancel-button" (click)="cancel()">{{
      'wc.common.cancel.button' | translate
    }}</a>
  </div>
</div>

<router-outlet (activate)="onRouterOutletActivate($event)" />

<div class="card__wrapper">
  <!-- card with main contextRule attributes -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.contextRules.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="getEditContextRule()" class="form" [formGroup]="contextRuleForm">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  id="008_dynamic_context_rule_edit"
                  [parentGroup]="contextRuleForm"
                  [controlName]="'id'"
                  [label]="'name'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.contextRule'" />
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  id="009_dynamic_context_rule_edit"
                  [formControlName]="'applicationType'"
                  [options]="applicationTypes"
                  [translationPrefix]="'wc.admin.contextRule'" />
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  id="010_dynamic_context_rule_edit"
                  [formControlName]="'implementationType'"
                  [options]="implementationTypes"
                  [translationPrefix]="'wc.admin.contextRule'" />
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'011_dynamic_context_rule_edit'"
                  [inputType]="'checkbox'"
                  [parentGroup]="contextRuleForm"
                  [controlName]="'abstractRule'"
                  [label]="'wc.admin.contextRule.abstractRule'" />
              </div>
              <div class="form__row"></div>
              <div class="form__row"></div>
              <div class="form__row">
                <mat-form-field
                  class="form__field text-area-with-open-in-new-dialog"
                  appearance="outline"
                  floatLabel="always">
                  <mat-label>{{ 'wc.admin.contextRule.expression' | translate }}</mat-label>
                  <mat-icon
                    matSuffix
                    class="text-area-open-in-new-dialog"
                    (click)="openModalEdit(editorDialog, 'expression')"
                    >open_in_new</mat-icon
                  >
                  <textarea
                    id="012_dynamic_context_rule_edit"
                    class="form__input"
                    rows="10"
                    matInput
                    type="text"
                    formControlName="expression"></textarea>
                </mat-form-field>
              </div>
              <div class="form__row">
                <mat-form-field
                  class="form__field text-area-with-open-in-new-dialog"
                  appearance="outline"
                  floatLabel="always">
                  <mat-label>{{ 'wc.admin.contextRule.arguments' | translate }}</mat-label>
                  <mat-icon
                    matSuffix
                    class="text-area-open-in-new-dialog"
                    (click)="openModalEdit(editorDialog, 'arguments')"
                    >open_in_new</mat-icon
                  >
                  <textarea
                    id="013_dynamic_context_rule_edit"
                    class="form__input"
                    rows="10"
                    matInput
                    type="text"
                    formControlName="arguments"></textarea>
                </mat-form-field>
              </div>
              <div class="form__row">
                <mat-form-field
                  class="form__field text-area-with-open-in-new-dialog"
                  appearance="outline"
                  floatLabel="always">
                  <mat-label>{{ 'wc.admin.contextRule.documentation' | translate }}</mat-label>
                  <mat-icon
                    matSuffix
                    class="text-area-open-in-new-dialog"
                    [matTooltip]="'' | translate"
                    (click)="openModalEdit(editorDialog, 'documentation')"
                    >open_in_new</mat-icon
                  >
                  <textarea
                    id="014_dynamic_context_rule_edit"
                    class="form__input"
                    rows="10"
                    matInput
                    type="text"
                    formControlName="documentation"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #editorDialog let-c="close" let-d="dismiss">
  <div>
    <div>
      <h2 mat-dialog-title>
        {{ 'wc.common.edit' | translate }}
        <mat-icon
          class="default-icon-gray-fill no-stroke"
          svgIcon="close"
          mat-dialog-close
          [matTooltip]="'wc.common.close' | translate" />
      </h2>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="form">
      <div class="form-row">
        <mat-form-field appearance="outline" floatLabel="always">
          <textarea rows="5" class="form__input" matInput type="text" [(ngModel)]="editDialogValue"></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="primary" mat-raised-button [routerLink]="" (click)="dialogRef.close()">
      {{ 'wc.common.cancel.button' | translate }}
    </button>
    <button
      *ngIf="!editDialogValueDisabled"
      color="primary"
      mat-raised-button
      [routerLink]=""
      (click)="closeEditDialog()">
      {{ 'wc.common.save' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
