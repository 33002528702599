<div class="dashboard_inner">
  <div class="dashboard_inner_header">
    <span>
      <mat-icon [ngClass]="type" [svgIcon]="icon"></mat-icon>
      {{ title | translate }}
    </span>
  </div>
  <mat-list *ngIf="pagedTicketsDto?.data" class="dashboard_inner_list ticket-item">
    <mat-list-item *ngFor="let ticket of pagedTicketsDto.data; let i = index">
      <div class="ticket-icon">
        <mat-icon [svgIcon]="getIcon(ticket.type.code)"></mat-icon>
      </div>
      <div class="dashboard_inner_list_text">
        <a
          id="001_dashboard_ticket_{{ type }}_mini_list_{{ i }}"
          class="ticket-subject"
          [routerLink]="'/ticketing/tickets/' + ticket.id"
        >{{ ticket.subject }}
        </a>
        <div class="ticket-description"
             [ngStyle]="{ color: (ticket.slaDueDate?.getTime() < currentDate.getTime()) ? 'rgba(253, 60, 74, 1)' : '' }">
          {{ ticket.slaDueDate | date: 'HH:mm dd.MM.yyyy' }}
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</div>
