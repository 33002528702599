import { Component, OnInit } from '@angular/core';
import { ContentDto } from '@btl/admin-bff';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-contents-pages',
  template: '<router-outlet></router-outlet>',
})
@EnableDynamicLoading({ customName: CmsContentsComponent.PAGE_ID })
export class CmsContentsComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'CmsContentsComponent';

  pageId(): string {
    return CmsContentsComponent.PAGE_ID;
  }

  constructor(protected router: Router, protected route: ActivatedRoute) {
    super(router, route);
  }

  /**
   * Method for deep clone of given object.
   * @param array any
   * @return deep clone
   */
  static cloneDeep(array: any): any {
    return array ? JSON.parse(JSON.stringify(array)) : array;
  }

  /**
   * Method for reset contentDto attributes when called updateVersion service.
   * @param contentDto
   * @return contentDto
   */
  static handleContentDtoRequest(contentDto: ContentDto): ContentDto {
    contentDto.contentMaster.id = null;
    contentDto.contentMaster.created = null;
    contentDto.contentMaster.createdBy = null;
    contentDto.contentMaster.modified = null;
    contentDto.contentMaster.modifiedBy = null;
    contentDto.contentVersion.id = null;
    contentDto.contentVersion.code = null;
    contentDto.contentVersion.created = null;
    contentDto.contentVersion.createdBy = null;
    contentDto.contentVersion.modified = null;
    contentDto.contentVersion.modifiedBy = null;

    // reset widget attributes
    for (const widget of contentDto.contentVersion.widgets) {
      delete widget.id;
      delete widget.created;
      delete widget.createdBy;
      delete widget.modified;
      delete widget.modifiedBy;
    }

    return contentDto;
  }
}
