import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, Observable, Subscriber } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ContentDto,
  ContentLocalizedParameterDto,
  ContentMasterDto,
  ContentTemplateDto,
  ContentTemplateParameterDto,
  ContentTemplatesDto,
  ContentVersionDto,
  ContentWidgetDto,
  PagedContentsDto,
  PagedContentVersionsDto,
  PagedWidgetTemplatesDto
} from '@btl/admin-bff';
import {
  AbstractPageComponent,
  AclService,
  AdminContentService,
  AdminContentTemplateService,
  AdminContentVersionService,
  AdminWidgetTemplateService,
  AppBlockerService,
  CurrentLocaleService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { SortDto } from '@btl/order-bff';
import { CmsContentsComponent } from '@components/cms/contents/cms-contents.component';
import {
  CmsContentPreviewModalComponent,
} from '@components/cms/contents/edit/widget/preview-modal/cms-content-preview-modal.component';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { Animations } from '@helpers/animations';
import { CmsContentsListComponent } from '@components/cms/contents/list/cms-contents-list.component';

@Component({
  selector: 'app-cms-content-edit',
  templateUrl: './cms-content-edit.component.html',
  styleUrls: ['./cms-content-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: CmsContentEditComponent.PAGE_ID })
export class CmsContentEditComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'CmsContentEditComponent';

  pageId(): string {
    return CmsContentEditComponent.PAGE_ID;
  }

  @Input()
  dialogRef;

  @Input()
  isBannerContent = false;

  @Output()
  readonly saveBannerEvent: EventEmitter<ContentWidgetDto> = new EventEmitter();

  initialized = false;
  contentMasterDto: ContentMasterDto;
  contentMasterId: string;
  contentVersionDto: ContentVersionDto;
  templateDto: ContentTemplateDto;
  templateDtoList: Array<ContentTemplateDto>;
  isDuplicate = false;
  isNewVersion = false;
  previewUrl = '';
  currentLocale: string;
  locales = environment.localization.locales;
  attributesLanguage;

  contentStates = ['ACTIVE', 'INACTIVE'];

  static deleteControlError(control: AbstractControl, errorKey: string) {
    if (control.errors) {
      delete control.errors[errorKey];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  static getBoolean(value: any): boolean {
    if (value === undefined || value === null) {
      return false;
    } else if (typeof value === 'boolean') {
      return value;
    } else {
      return value === 'true';
    }
  }

  contentMasterForm = this.formBuilder.group({
    contentTemplateType: [{ value: null, disabled: true }],
    name: [null, Validators.required],
    code: [null, Validators.required],
    state: [null as string, Validators.required],
    isInternal: [false],
    validFor: this.formBuilder.group({
      startDateTime: [null as Date, [Validators.required, FormUtils.validateOrderOfStartEnd()]],
      endDateTime: [null as Date, FormUtils.validateOrderOfStartEnd()],
    }),
  });

  contentVersionForm = this.formBuilder.group({
    version: [{ value: null, disabled: true }],
    priority: [null],
    verHash: [{ value: null, disabled: true }],
    state: [{ value: null, disabled: true }],
    code: [{ value: null, disabled: true }],
    validFor: this.formBuilder.group({
      startDateTime: [null as Date, [Validators.required, FormUtils.validateOrderOfStartEnd()]],
      endDateTime: [null as Date, FormUtils.validateOrderOfStartEnd()],
    }),
  });

  paramFormGroup = this.formBuilder.group({
    contentParameterFormArray: this.formBuilder.array([]),
    contentKeysFormArray: this.formBuilder.array([]),
    contentLocalizedParameterFormArray: this.formBuilder.array([]),
  });

  get contentParameterFormArray(): FormArray {
    return this.paramFormGroup.get('contentParameterFormArray') as FormArray;
  }

  get contentKeysFormArray(): FormArray {
    return this.paramFormGroup.get('contentKeysFormArray') as FormArray;
  }

  get contentLocalizedParameterFormArray(): FormArray {
    return this.paramFormGroup.get('contentLocalizedParameterFormArray') as FormArray;
  }

  get verHashFormControl(): AbstractControl {
    return this.contentVersionForm.get('verHash');
  }

  get codeFormControl(): AbstractControl {
    return this.contentMasterForm.get('code');
  }

  widgetTemplateDtoList = null;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private stickyMessageService: StickyMessageService,
    private dialog: MatDialog,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private adminContentService: AdminContentService,
    private adminContentVersionService: AdminContentVersionService,
    private adminContentTemplateService: AdminContentTemplateService,
    private currentLocaleService: CurrentLocaleService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    private adminWidgetTemplateService: AdminWidgetTemplateService
  ) {
    super(router, route);

    this.adminWidgetTemplateService
      .filterWidgetTemplates(ServiceUtils.getUnlimitedSearch())
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PagedWidgetTemplatesDto) => {
        if (result.data && result.data.length) {
          this.widgetTemplateDtoList = result.data;
        } else {
          this.widgetTemplateDtoList = [];
        }
      });

    const getPropertyCalls = [
      this.propertyAccessorLocalService.getGlobalBaseUrl(),
      this.propertyAccessorLocalService.getCmsPreviewUrlPath(),
    ];
    forkJoin(getPropertyCalls)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((results: string[]) => {
        if (results && results.length === 2) {
          this.previewUrl = results[0];
          this.previewUrl += results[1];
        }
      });

    this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    });
    this.attributesLanguage = this.currentLocale;
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const versionId = this.params.id;
      const state = this.router.getCurrentNavigation()?.extras.state;
      this.isDuplicate = state?.duplicate;
      const templateType = state?.templateType;
      this.initComponent(versionId, templateType);
    }
  }

  ngOnInit(): void {
    if (this.isBannerContent) {
      this.isDuplicate = false;
      this.initComponent(null);
    }
  }

  initComponent(versionId: string, templateType?: string) {
    if (versionId === 'newContentMaster') {
      this.contentMasterId = 'newContentMaster';
    } else if (versionId === 'null') {
      versionId = null;
    }

    if (versionId && versionId !== 'newContentMaster') {
      this.appBlockerService.block();
      this.adminContentVersionService
        .getContentVersionById(versionId)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: ContentDto) => {
          this.contentMasterDto = result.contentMaster;
          this.contentVersionDto = result.contentVersion;
          this.contentMasterId = this.contentMasterDto.id;

          // get templateDto or templateDtoList
          if (this.isDuplicate) {
            this.resetAttributesForDuplicate();
            this.adminContentTemplateService
              .getContentTemplates()
              .pipe(finalize(this.appBlockerService.unblock))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((result: ContentTemplatesDto) => {
                this.templateDtoList = result.data;
                this.contentMasterId = 'newContentMaster';
                this.contentVersionDto.id = null;
                this.initForm();
              });
          } else if (templateType || this.contentMasterDto?.contentTemplateType) {
            this.appBlockerService.block();
            this.adminContentTemplateService
              .getContentTemplateByType(templateType ? templateType : this.contentMasterDto.contentTemplateType)
              .pipe(finalize(this.appBlockerService.unblock))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((result: ContentTemplateDto) => {
                this.templateDto = result;
                this.initForm();
              });
          } else {
            this.initForm();
          }
        });
    } else {
      this.contentMasterDto = {
        id: null,
        contentTemplateType: this.isBannerContent ? 'BANNER' : null,
        state: 'ACTIVE',
        isInternal: false,
        validFor: {
          startDateTime: new Date(),
        },
        parameters: [],
        keys: [],
      };
      this.contentVersionDto = {
        id: null,
        version: 1,
        state: 'DRAFT',
        validFor: {
          startDateTime: new Date(),
        },
        widgets: [],
      };
      this.templateDto = {
        type: templateType ? templateType : null,
        parameters: [],
      };
      this.appBlockerService.block();
      this.adminContentTemplateService
        .getContentTemplates()
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: ContentTemplatesDto) => {
          this.templateDtoList = result.data;
          this.initForm();
        });
    }
  }

  initForm() {
    this.appBlockerService.block();
    if (this.templateDtoList && !this.templateDto?.parameters.length) {
      const templateType = this.contentMasterDto?.contentTemplateType || this.templateDto?.type;
      this.templateDto = templateType
        ? this.templateDtoList.find(template => template.type === templateType)
        : this.templateDtoList[0];
    }

    this.contentMasterDto.contentTemplateType = this.contentMasterDto?.contentTemplateType || this.templateDto.type;
    // @ts-ignore
    this.contentMasterForm.reset(this.contentMasterDto);
    // @ts-ignore
    this.contentVersionForm.reset(this.contentVersionDto);

    if (this.codeFormControl.value && this.contentMasterId !== 'newContentMaster') {
      this.codeFormControl.disable();
      this.codeFormControl.setErrors(null);
    }

    this.addParameters(false);
    this.initialized = true;
    this.appBlockerService.unblock();
  }

  save(refresh = true) {
    this.saveObserver(refresh).pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  saveObserver(refresh: boolean): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      if (!this.isAllFormsValid()) {
        observer.next(false);
        observer.complete();
      } else {
        this.updateModels();
        this.contentVersionDto.widgets.forEach(widgetContent => {
          const widgetTemplate = this.widgetTemplateDtoList.find(
            findWidgetTemplate => findWidgetTemplate.type === widgetContent.widgetTemplateType
          );
          widgetContent.parameters = widgetContent.parameters
            .filter(parameter => !!parameter.value)
            .filter(
              parameter =>
                !widgetTemplate.parameters.find(paramTemplate => paramTemplate.name === parameter.name)?.isStatic
            );
        });
        if (!this.contentMasterDto.id && !this.contentVersionDto.id) {
          // is new page or duplicate
          this.appBlockerService.block();
          this.adminContentService
            .createContent({
              contentMaster: this.contentMasterDto,
              contentVersion: this.contentVersionDto,
            })
            .pipe(finalize(this.appBlockerService.unblock))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              (result: ContentDto) => {
                this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
                this.contentMasterDto = { ...result.contentMaster };
                this.contentVersionDto = { ...result.contentVersion };
                this.contentMasterId = this.contentMasterDto.id;
                if (refresh && !this.isBannerContent) {
                  this.refreshPage();
                } else {
                  // @ts-ignore
                  this.contentMasterForm.patchValue(this.contentMasterDto);
                  // @ts-ignore
                  this.contentVersionForm.patchValue(this.contentVersionDto);
                }
                observer.next(true);
              },
              error => {
                observer.next(false);
              }
            )
            .add(() => observer.complete());
        } else if (this.contentMasterDto.id && !this.contentVersionDto.id) {
          // is new version
          this.appBlockerService.block();
          this.adminContentVersionService
            .createContentVersion(this.contentMasterDto.id, this.contentVersionDto)
            .pipe(finalize(this.appBlockerService.unblock))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              (result: ContentVersionDto) => {
                this.contentVersionDto = { ...result };

                // after create new version, we must update parameters/keys on contentMaster
                const contentDto: ContentDto = {
                  contentMaster: this.contentMasterDto,
                  contentVersion: this.contentVersionDto,
                };
                this.appBlockerService.block();
                this.adminContentVersionService
                  .updateContentVersion(
                    this.contentVersionDto.id,
                    CmsContentsComponent.handleContentDtoRequest(contentDto)
                  )
                  .pipe(finalize(this.appBlockerService.unblock))
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe((result: ContentDto) => {
                    this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
                    this.contentMasterDto = { ...result.contentMaster };
                    this.contentVersionDto = { ...result.contentVersion };
                    this.contentMasterId = this.contentMasterDto.id;
                    if (refresh && !this.isBannerContent) {
                      this.refreshPage();
                    } else {
                      // @ts-ignore
                      this.contentMasterForm.patchValue(this.contentMasterDto);
                      // @ts-ignore
                      this.contentVersionForm.patchValue(this.contentVersionDto);
                    }
                    observer.next(true);
                  });
              },
              error => {
                observer.next(false);
              }
            )
            .add(() => observer.complete());
        } else {
          // edited existing version
          const contentDto: ContentDto = {
            contentMaster: CmsContentsComponent.cloneDeep(this.contentMasterDto),
            contentVersion: CmsContentsComponent.cloneDeep(this.contentVersionDto),
          };

          this.appBlockerService.block();
          this.adminContentVersionService
            .updateContentVersion(this.contentVersionDto.id, CmsContentsComponent.handleContentDtoRequest(contentDto))
            .pipe(finalize(this.appBlockerService.unblock))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              (result: ContentDto) => {
                this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
                this.contentMasterDto = { ...result.contentMaster };
                this.contentVersionDto = { ...result.contentVersion };
                this.contentMasterId = this.contentMasterDto.id;
                if (refresh && !this.isBannerContent) {
                  this.refreshPage();
                } else {
                  // @ts-ignore
                  this.contentMasterForm.patchValue(this.contentMasterDto);
                  // @ts-ignore
                  this.contentVersionForm.patchValue(this.contentVersionDto);
                }
                observer.next(true);
              },
              error => {
                observer.next(false);
              }
            )
            .add(() => observer.complete());
        }
      }
    });
  }

  reset() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog(['wc.admin.cms.content.edit.reset']);

    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
      this.refreshPage(false, this.contentMasterDto.contentTemplateType);
      confirmationDialogComponent.dialogReference.close();
    };
  }

  cancel() {
    this.navigateSibling(CmsContentsListComponent.PAGE_ID);
  }

  cancelAndClose() {
    this.onDestroy$.next();
    this.dialogRef.close();
  }

  duplicate() {
    this.refreshPage(true);
  }

  newVersion() {
    this.updateModels();
    this.appBlockerService.block();
    this.adminContentVersionService
      .getContentVersionsByContentMasterId(this.contentMasterDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response: PagedContentVersionsDto) => {
        const newContentVersion = CmsContentsComponent.cloneDeep(this.contentVersionDto);
        newContentVersion.id = null;
        newContentVersion.recordVersion = null;
        newContentVersion.created = null;
        newContentVersion.createdBy = null;
        newContentVersion.modified = null;
        newContentVersion.modifiedBy = null;
        newContentVersion.code = null;
        newContentVersion.state = 'DRAFT';
        newContentVersion.version = response.data[0].version + 1;
        newContentVersion.verHash = null;
        this.contentVersionDto = newContentVersion;
        // @ts-ignore
        this.contentVersionForm.patchValue(this.contentVersionDto);
        this.isNewVersion = true;
      });
  }

  archive() {
    this.appBlockerService.block();
    this.adminContentVersionService
      .getContentVersionsByContentMasterId(this.contentMasterDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response: PagedContentVersionsDto) => {
        const existOtherApprovedVersion =
          response.data.filter(
            (version: ContentVersionDto) => version.id !== this.contentVersionDto.id && version.state === 'APPROVED'
          ).length > 0;

        const redirectUrlItem = this.contentMasterDto.parameters.find(param => param.name === 'redirectUrl');
        if (
          this.contentMasterDto.contentTemplateType !== 'BANNER' &&
          !existOtherApprovedVersion &&
          (!redirectUrlItem || !redirectUrlItem.value)
        ) {
          this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.version.list.archive.error');
        } else {
          this.contentVersionDto.state = 'ARCHIVED';
          if (!existOtherApprovedVersion) {
            this.contentMasterDto.state = 'INACTIVE';
          }
          this.appBlockerService.block();
          const contentDto: ContentDto = {
            contentMaster: CmsContentsComponent.cloneDeep(this.contentMasterDto),
            contentVersion: CmsContentsComponent.cloneDeep(this.contentVersionDto),
          };

          contentDto.contentMaster.parameters = contentDto.contentMaster.parameters.filter(
            parameter =>
              !this.templateDto.parameters.find(paramTemplate => paramTemplate.name === parameter.name)?.isStatic
          );

          this.adminContentVersionService
            .updateContentVersion(this.contentVersionDto.id, CmsContentsComponent.handleContentDtoRequest(contentDto))
            .pipe(finalize(this.appBlockerService.unblock))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((result: ContentDto) => {
              this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
              this.contentMasterDto = {...result.contentMaster};
              this.contentVersionDto = {...result.contentVersion};
              this.refreshPage();
            });
        }
      });
  }

  approve() {
    if (this.contentVersionDto.state !== 'DRAFT') {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.version.list.approve.error');
    } else if (this.isAllFormsValid()) {
      const attributes = {
        state: 'APPROVED',
      };
      this.appBlockerService.block();
      this.adminContentVersionService
        .patchContentVersion(this.contentVersionDto.id, attributes)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: ContentVersionDto) => {
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          this.contentVersionDto = { ...result };
          this.refreshPage();
        });
    }
  }

  saveAndApprove() {
    this.contentVersionForm.get('state').setValue('APPROVED');
    this.saveObserver(false)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((success: boolean) => {
        if (success) {
          const newWidget: ContentWidgetDto = {
            widgetTemplateType: this.contentMasterForm.get('contentTemplateType').value,
            name: this.codeFormControl.value,
            parameters: [
              {
                name: 'contentId',
                value: this.contentMasterDto.id,
                seq: 0,
              },
            ],
          };
          this.saveBannerEvent.emit(newWidget);
          this.onDestroy$.next();
        } else {
          this.contentVersionForm.get('state').setValue('DRAFT');
        }
      });
  }

  delete() {
    if (this.contentVersionDto.state !== 'DRAFT') {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.version.list.delete.error');
    } else {
      this.appBlockerService.block();
      this.adminContentVersionService
        .getContentVersionsByContentMasterId(this.contentMasterDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: PagedContentVersionsDto) => {
          if (result && result.data?.length > 1) {
            this.appBlockerService.block();
            this.adminContentVersionService
              .deleteContentVersion(this.contentVersionDto.id)
              .pipe(finalize(this.appBlockerService.unblock))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((result: ContentVersionDto) => {
                this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
                this.cancel();
              });
          } else {
            this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.version.list.delete.count.error');
          }
        });
    }
  }

  templateChange(contentTemplateType: string) {
    // Display blank page detail form
    this.contentMasterForm.reset({
      // @ts-ignore
      id: null,
      state: 'ACTIVE',
      isInternal: false,
      validFor: {
        startDateTime: new Date(),
      },
      parameters: [],
      keys: [],
    });
    this.contentVersionForm.reset({
      // @ts-ignore
      id: null,
      version: 1,
      state: 'DRAFT',
      validFor: {
        startDateTime: new Date(),
      },
      widgets: [],
    });
    this.contentVersionForm.get('state').setValue('DRAFT');
    this.contentVersionForm.get('version').setValue('1');

    this.templateDto = this.templateDtoList.find(template => template.type === contentTemplateType);
    this.contentMasterForm.get('contentTemplateType').setValue(contentTemplateType);
    this.contentMasterDto.contentTemplateType = contentTemplateType;
    this.addParameters(true);
  }

  generateVersionHash() {
    this.generateVersionHashObserver().pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  generateVersionHashObserver(): Observable<boolean> {
    this.appBlockerService.block();
    return new Observable((observer: Subscriber<boolean>) => {
      this.adminContentVersionService
        .updateContentVersionWithHash(this.contentVersionDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (result: ContentVersionDto) => {
            this.contentVersionDto.recordVersion = result.recordVersion;
            this.contentVersionDto.modified = result.modified;
            this.contentVersionDto.modifiedBy = result.modifiedBy;
            this.contentVersionDto.verHash = result.verHash;
            this.verHashFormControl.setValue(result.verHash);
            this.stickyMessageService.addStickySuccessMessage('wc.admin.cms.content.version.verHash.saved');
            observer.next(true);
          },
          error => {
            observer.next(false);
          }
        )
        .add(() => observer.complete());
    });
  }

  deleteVersionHash() {
    this.appBlockerService.block();
    this.adminContentVersionService
      .deleteContentVersionHash(this.contentVersionDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: any) => {
        this.verHashFormControl.setValue(null);
        this.stickyMessageService.addStickySuccessMessage('wc.admin.cms.content.version.verHash.deleted');
      });
  }

  handleContentMasterCode() {
    if (!this.codeFormControl.valid) {
      this.codeFormControl.markAsTouched();
      return;
    }

    const search: Search = {
      filtering: [{ column: 'code', compareType: 'EQUAL', value: this.codeFormControl.value }],
      sorting: [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }],
      paging: { page: 1, pageSize: 1 },
    };

    this.codeFormControl.setErrors(null);
    this.appBlockerService.block();
    this.adminContentService
      .getContentsByFilter(search)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PagedContentsDto) => {
        if (result?.data && result.data.length > 0) {
          this.codeFormControl.setErrors({ duplicate: true });
          this.codeFormControl.markAsTouched();
        }
      });
  }

  widgetsPreview() {
    if (this.contentVersionDto.state === 'DRAFT' && this.isAllFormsValid()) {
      this.saveObserver(false)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((success: boolean) => {
          if (success) {
            if (!this.contentVersionDto.verHash) {
              this.generateVersionHashObserver()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((success: boolean) => {
                  if (success) {
                    this.openPreviewModal();
                  }
                });
            } else {
              this.openPreviewModal();
            }
          }
        });
    } else if (
      (this.contentVersionDto.state === 'ARCHIVED' || this.contentVersionDto.state === 'APPROVED') &&
      this.contentVersionDto.verHash
    ) {
      this.openPreviewModal();
    }
  }

  private openPreviewModal() {
    const modalRef = this.dialog.open(CmsContentPreviewModalComponent, { width: '1000px', height: '85vh' });
    const previewModalComponent = modalRef.componentInstance;
    previewModalComponent.dialogRef = modalRef;
    previewModalComponent.previewUrl = `${this.previewUrl}${
      this.contentVersionDto.verHash
    }?locale=${this.currentLocaleService.getCurrentLanguage()}`;

    modalRef.componentInstance.cancelPreviewEvent.subscribe(() => {
      previewModalComponent.dialogRef.close();
      if (this.isBannerContent !== true) {
        this.refreshPage(false);
      }
    });
  }

  private addParameters(initFromTemplate: boolean) {
    this.contentParameterFormArray.controls = [];
    this.contentKeysFormArray.controls = [];
    this.contentLocalizedParameterFormArray.controls = [];

    // if is initFromTemplate we reset array, because we need setup parameters|keys from template
    this.contentMasterDto.parameters =
      initFromTemplate || !this.contentMasterDto?.parameters ? [] : this.contentMasterDto.parameters;
    this.contentMasterDto.keys = initFromTemplate || !this.contentMasterDto?.keys ? [] : this.contentMasterDto.keys;

    if (this.templateDto && this.templateDto.parameters) {
      this.templateDto.parameters.forEach((parameterDto: ContentTemplateParameterDto, paramIndex: number) => {
        let isContentKey = false;
        let guiVisible = true;
        if (parameterDto?.metaParameters && parameterDto.metaParameters.length) {
          const isContentKeyItem = parameterDto.metaParameters.find(item => item.name === 'isContentKey');
          isContentKey = isContentKeyItem ? isContentKeyItem.value === 'true' : false;
          const guiVisibleItem = parameterDto.metaParameters.find(item => item.name === 'guiVisible');
          guiVisible = guiVisibleItem ? guiVisibleItem.value === 'true' : false;
        }
        if (!guiVisible) return;

        if (isContentKey) {
          const keyContentItem = this.contentMasterDto.keys.find(item => item.keyId === parameterDto.name);
          this.contentKeysFormArray.push(
            this.getContentKeysFormGroup(
              parameterDto,
              keyContentItem && keyContentItem.keyValue ? keyContentItem.keyValue : parameterDto.value,
              paramIndex
            )
          );
        } else {
          const paramContentItem = this.contentMasterDto.parameters.find(item => item.name === parameterDto.name);
          if (!parameterDto.localized) {
            this.contentParameterFormArray.push(
              this.getContentParameterFormGroup(
                parameterDto,
                paramContentItem && paramContentItem.value ? paramContentItem.value : parameterDto.value,
                paramIndex
              )
            );
          } else {
            const localParamContentItem = this.contentMasterDto.localizedParameters
              ? this.contentMasterDto.localizedParameters.find(item => item.name === parameterDto.name)
              : null;
            this.contentLocalizedParameterFormArray.push(
              this.getContentLocalizedParameterFormGroup(parameterDto, localParamContentItem, paramIndex)
            );
          }
        }
      });
    }
  }

  private updateModels() {
    // page
    this.updateModelFromFormGroup(this.contentMasterDto, this.contentMasterForm);

    // version
    this.updateModelFromFormGroup(this.contentVersionDto, this.contentVersionForm);

    // version widgets - remove necessary attributes
    for (const widget of this.contentVersionDto.widgets) {
      delete widget.id;
      delete widget.created;
      delete widget.createdBy;
      delete widget.modified;
      delete widget.modifiedBy;
    }
    this.contentVersionDto.widgets.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));

    // parameters
    this.contentMasterDto.parameters = [];
    this.contentParameterFormArray.controls.forEach((parameterForm: FormGroup) => {
      const parameter = parameterForm.getRawValue();
      if (!parameter.isStatic) {
        if (parameter.type === 'BOOLEAN' && parameterForm.get('value').value === null) {
          parameter.value = false;
        }
        if (parameter.type === 'NUMBER' && parameterForm.get('value').value === null) {
          parameter.value = 0;
        }
        if (
          parameter.type === 'BOOLEAN' ||
          parameter.type === 'NUMBER' ||
          (parameter.value && parameter.value.trim().length)
        ) {
          this.contentMasterDto.parameters.push({
            name: parameter.name,
            value: parameter.value,
          });
        }
      }
    });

    //localized params
    this.contentMasterDto.localizedParameters = [];
    this.contentLocalizedParameterFormArray.controls.forEach((parameterForm: FormGroup) => {
      const parameter = parameterForm.getRawValue();
      if (!parameter.isStatic) {
        if (parameter.localizedParameters) {
          const localizedValues = {};
          Object.keys(parameter.localizedParameters).forEach(key => {
            localizedValues[key] = parameter.localizedParameters[key];
          });
          this.contentMasterDto.localizedParameters.push({
            name: parameter.name,
            localizedValues: localizedValues,
          });
        }
      }
    });

    // keys
    this.contentMasterDto.keys = [];
    this.contentKeysFormArray.controls.forEach((keyForm: FormGroup) => {
      const key = keyForm.getRawValue();
      if (key.type === 'BOOLEAN' && key.value === null) {
        key.value = false;
      }
      if (key.type === 'NUMBER' && key.value === null) {
        key.value = 0;
      }
      if (key.type === 'BOOLEAN' || key.type === 'NUMBER' || (key.keyValue && key.keyValue.trim().length)) {
        this.contentMasterDto.keys.push({
          keyId: key.keyId,
          keyValue: key.keyValue,
        });
      }
    });
  }

  private updateModelFromFormGroup(model: any, form: FormGroup) {
    Object.keys(form.controls).forEach((field: string) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        model[field] = control.value;
      } else if (control instanceof FormGroup) {
        this.updateModelFromFormGroup(model[field], control);
      }
    });
  }

  private getContentParameterFormGroup(
    parameterDto: ContentTemplateParameterDto,
    value: any,
    index: number
  ): FormGroup {
    let validators = [];
    if (parameterDto.isMandatory) {
      validators.push(Validators.required);
    }
    if (parameterDto.validationRegExp) {
      validators.push(Validators.pattern(parameterDto.validationRegExp));
    }
    if (parameterDto.type === 'BOOLEAN') {
      validators = [];
      value = CmsContentEditComponent.getBoolean(value);
    } else {
      value = value || null;
    }
    return this.formBuilder.group({
      name: [{ value: parameterDto.name || null, disabled: true }],
      value: [{ value: value, disabled: parameterDto.isStatic }, validators],
      type: [{ value: parameterDto.type || null, disabled: true }],
      index: [{ value: index, disabled: true }],
      isStatic: [{ value: parameterDto.isStatic, disabled: true }],
    });
  }

  private getContentLocalizedParameterFormGroup(
    parameterDto: ContentTemplateParameterDto,
    localParamDto: ContentLocalizedParameterDto,
    index: number
  ): FormGroup {
    const validators = [];
    if (parameterDto.isMandatory) {
      validators.push(Validators.required);
    }
    if (parameterDto.validationRegExp) {
      validators.push(Validators.pattern(parameterDto.validationRegExp));
    }

    const formGroup = this.formBuilder.group({
      name: [{ value: parameterDto.name || null, disabled: true }],
      value: [{ value: null, disabled: true }],
      type: [{ value: parameterDto.type || null, disabled: true }],
      index: [{ value: index, disabled: true }],
      isStatic: [{ value: parameterDto.isStatic, disabled: true }],
    });
    const localizedParametersGroup = this.formBuilder.group({});
    this.locales.forEach((localization: string) => {
      localizedParametersGroup.addControl(
        localization,
        new FormControl(
          !localParamDto?.localizedValues ? null : localParamDto.localizedValues[localization],
          validators
        )
      );
    });
    // @ts-ignore
    formGroup.addControl('localizedParameters', localizedParametersGroup);
    return formGroup;
  }

  private getContentKeysFormGroup(parameterDto: ContentTemplateParameterDto, keyValue: any, index: number): FormGroup {
    let validators = [];
    if (parameterDto.isMandatory && parameterDto.type !== 'BOOLEAN') {
      validators.push(Validators.required);
    }
    if (parameterDto.validationRegExp) {
      validators.push(Validators.pattern(parameterDto.validationRegExp));
    }
    if (parameterDto.type === 'BOOLEAN') {
      validators = [];
      keyValue = CmsContentEditComponent.getBoolean(keyValue);
    } else {
      keyValue = keyValue || null;
    }
    return this.formBuilder.group({
      keyId: [{ value: parameterDto.name || null, disabled: true }],
      keyValue: [{ value: keyValue, disabled: parameterDto.isStatic }, validators],
      index: [{ value: index, disabled: true }],
    });
  }

  private isAllFormsValid(): boolean {
    FormUtils.validateAllFormFields(this.contentMasterForm);
    FormUtils.validateAllFormFields(this.contentVersionForm);
    FormUtils.validateAllFormFields(this.paramFormGroup);
    const isValid = this.contentMasterForm.valid && this.contentVersionForm.valid && this.paramFormGroup.valid;
    if (!isValid) {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.form.validation.error');
    }
    return isValid;
  }

  private refreshPage(duplicate = false, templateType?: string) {
    this.isDuplicate = duplicate;
    const versionId = this.contentVersionDto.id ? this.contentVersionDto.id : 'newContentMaster';
    this.initComponent(versionId, templateType);
  }

  private resetAttributesForDuplicate() {
    this.contentMasterId = 'newContentMaster';
    this.codeFormControl.enable();
    // reset dto attributes
    this.contentMasterDto.id = null;
    this.contentMasterDto.recordVersion = null;
    this.contentMasterDto.created = null;
    this.contentMasterDto.createdBy = null;
    this.contentMasterDto.modified = null;
    this.contentMasterDto.modifiedBy = null;
    this.contentMasterDto.name = `${this.contentMasterDto.name}copy`;
    this.contentMasterDto.code = `${this.contentMasterDto.code}copy`;
    if (this.contentMasterDto.keys) {
      const keyItem = this.contentMasterDto.keys.find(item => item.keyId === 'seoUrl');
      if (keyItem) {
        keyItem.keyValue = `${keyItem.keyValue}copy`;
      }
    }
    this.contentVersionDto.id = null;
    this.contentVersionDto.recordVersion = null;
    this.contentVersionDto.created = null;
    this.contentVersionDto.createdBy = null;
    this.contentVersionDto.modified = null;
    this.contentVersionDto.modifiedBy = null;
    this.contentVersionDto.code = null;
    this.contentVersionDto.state = 'DRAFT';
    this.contentVersionDto.verHash = null;
    this.contentVersionDto.version = 1;
  }
}
