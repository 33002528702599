import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AbstractFilterComponent, CompareType } from '@btl/btl-fe-wc-common';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-sticker-filter',
  templateUrl: './sticker-filter.component.html',
})
export class StickerFilterComponent extends AbstractFilterComponent {
  mapFieldWithCompareType = new Map([
    ['code', CompareTypeDtoEnum.LIKE],
  ]);

  constructor(private formBuilder: FormBuilder) {
    super();
    this.filterForm = this.formBuilder.group({
      code: [],
    });
  }
}
