import { Component, OnDestroy } from '@angular/core';
import { AuthFactoryService } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { from, Subject } from 'rxjs';

@Component({
  selector: 'app-ticket-lists',
  templateUrl: './ticket-lists.component.html',
  styleUrls: ['./ticket-lists.component.scss']
})
export class TicketListsComponent implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  userProfile;

  constructor(
    private authFactoryService: AuthFactoryService
  ) {
    from(this.authFactoryService.getAuthService().getUserInfo())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(userProfile => {
        this.userProfile = userProfile;
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
