import { Injectable } from '@angular/core';
import { Option } from '@btl/btl-fe-wc-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnumerationsService } from './enumerations.service';

@Injectable({
  providedIn: 'root',
})
export class AdminInputSelectEnumService {
  constructor(private enumDataTypeService: EnumerationsService) {
  }

  public getOptions(sourceName: string, enumName: string): Observable<Array<Option>> {
    return this.enumDataTypeService.getDataTypeEnumItemsMap(sourceName, enumName).pipe(
      map(itemTexts => {
        const options: Array<Option> = [];
        for (const item of Object.keys(itemTexts)) {
          const option: Option = new Option(itemTexts[item], item);
          options.push(option);
        }
        return options;
      })
    );
  }
}
