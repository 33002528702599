<div class="card__wrapper">
  <div class="card -full">
    <div class="card__inner">
      <app-acl-table
        *ngIf="aclLoaded()"
        resourceType="PC_PRODUCT"
        headerTranslateKey="wc.admin.technicalCategory.acl"
        [aclRules]="aclRules"
        [parentAclRules]="parentAclRules"
        [parentForm]="aclForm"
        [context]="resourceIdentificationContext"
        [editable]="this.productEditService.canEdit() && this.productEditService.enableEditProduct">
      </app-acl-table>
    </div>
  </div>

  <div class="card -full" *ngIf="aclParametersLoaded() && getAllParameters()">
    <div class="card__inner">
      <app-acl-table
        resourceType="PC_PRODUCT_PARAMETER"
        headerTranslateKey="wc.admin.technicalCategory.acl.parameters"
        [parameters]="getAllParameters()"
        [parameterName]="lastAclParameterName"
        (parameterChanged)="parameterChanged($event)"
        [aclRules]="parametersAclRules"
        [parentAclRules]="parentParametersAclRules"
        [parentForm]="aclForm"
        [context]="resourceIdentificationContext"
        [editable]="this.productEditService.canEdit() && this.productEditService.enableEditProduct">
      </app-acl-table>
    </div>
  </div>
</div>
