<form *ngIf="socket" [formGroup]="form" class="node-form">
  <div class="node-header">
    <div class="header-label">
      {{ 'wc.admin.promotion.selectedSocket.editing.socket' | translate }}
    </div>
    <div class="node-form-close" (click)="this.closeEmitter.emit()" [attr.aria-label]="'wc.common.close' | translate">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="node-body">
    <div class="body-info">
      <div class="node-inputs">
        <app-custom-input
          [parentGroup]="form"
          controlName="name"
          inputType="text"
          translationPrefix="wc.admin.socket">
        </app-custom-input>
        <app-custom-input
          [parentGroup]="form"
          controlName="code"
          inputType="text"
          translationPrefix="wc.admin.socket">
        </app-custom-input>
        <app-input-select-form-field
          formControlName="socketCategory"
          [options]="socketCategories"
          translationPrefix="wc.admin.socket">
        </app-input-select-form-field>
        <div class="node-contents" formGroupName="contentQty">
          <app-custom-input
            [parentGroup]="form.controls['contentQty']"
            controlName="min"
            inputType="number"
            translationPrefix="wc.admin.socket">
          </app-custom-input>
          <app-custom-input
            [parentGroup]="form.controls['contentQty']"
            controlName="max"
            inputType="number"
            translationPrefix="wc.admin.socket">
          </app-custom-input>
        </div>
      </div>
      <div class="node-form-actions">
        <a (click)="delete()" [routerLink]="" class="action-delete">
          {{ 'wc.admin.promotion.delete' | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="node-footer">
    <a (click)="selectSocket()" [routerLink]="" color="primary" mat-raised-button>
      {{ 'wc.admin.promotion.selectSocket' | translate }}
    </a>

    <a (click)="moveSocket()" [routerLink]="" color="primary" mat-raised-button>
      {{ 'wc.admin.promotion.moveSocket' | translate }}
    </a>
  </div>
</form>
