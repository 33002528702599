<div class="mat-simple-snackbar">
  <span>{{ data.messageKey | translate : data.messageParameters}} {{ data.detail?.error ? '' : data.detail?.message }}</span>
  <button *ngIf="data.detail?.error" (click)="detail(detailDialog)" class="btn btn-default" type="button">
    {{ 'wc.common.errorDetail' | translate }}
  </button>

  <button mat-button="" (click)="snackBarRef.dismiss()" class="close-button">
    <span class="mat-button-wrapper">x</span>
  </button>
</div>

<ng-template #detailDialog let-c="close" let-d="dismiss">
  <div>
    <h2 mat-dialog-title>
      {{ 'wc.common.errorDetail' | translate }}
      <mat-icon
        class="default-icon-gray-fill no-stroke"
        svgIcon="close"
        mat-dialog-close
        matTooltip="{{ 'wc.common.close' | translate }}">
      </mat-icon>
    </h2>
  </div>
  <div mat-dialog-content>
    <div class="form">
      <div class="form-row">
        <div class="error__page container">
          <div *ngIf="data.detail.error">
            <h2 class="error__page-error-code">{{ getCausedByMessage() }}</h2>
          </div>
          <h2 class="error__page-error-code">{{ data.detail.error.status }}</h2>
          <ng-container *ngFor="let failure of data.detail.error.failures">
            <h3 class="error_page-error-name">{{ failure.code + ' - ' + failure.detail.error }}</h3>
            <p class="error_page-error-description">
              {{ failure.localizedDescription ? failure.localizedDescription : failure.detail }}
            </p>
            <p>
              {{ failure.stackTrace }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
