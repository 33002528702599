<div>
  <div class="form__label widget">{{ title }} {{ imageSize }}</div>

  <ng-container *ngIf="!widgetTemplateParameter?.localized">
    <ng-container [ngSwitch]="widgetTemplateParameter.type" [formGroup]="formGroup">
      <app-custom-input
        *ngSwitchDefault
        [parentGroup]="formGroup"
        [controlName]="'value'"
        [inputType]="'text'"
        [id]="'001_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
        [translationPrefix]="'wc.admin.cms.content.widget.modal'">
      </app-custom-input>

      <ng-container *ngSwitchCase="'STRING'">
        <ng-container [ngSwitch]="widgetTemplateParameter.typeDetail">
          <app-custom-input
            *ngSwitchDefault
            [parentGroup]="formGroup"
            [controlName]="'value'"
            [inputType]="'text'"
            [id]="'002_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
            [translationPrefix]="'wc.admin.cms.content.widget.modal'">
          </app-custom-input>

          <picture-selector
            *ngSwitchCase="'IMAGE'"
            [controlName]="'value'"
            (onUniquenessChange)="valueChanged()"
            [parentGroup]="formGroup"
            [id]="'003_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
            [useDMS]="true"
            [widgetTemplateType]="widgetTemplateParameter.widgetTemplateType"
            (imageSize)="setImageSize($event)">
          </picture-selector>

          <angular-editor
            *ngSwitchCase="'HTML'"
            [config]="modalEditorConfig"
            (blur)="valueChanged()"
            [formControlName]="'value'"
            [formGroup]="formGroup"
            [id]="'004_cms_contents_widget_modal_edit_' + index + '_' + seqIndex">
          </angular-editor>
        </ng-container>
      </ng-container>

      <app-custom-input
        *ngSwitchCase="'NUMBER'"
        [parentGroup]="formGroup"
        [controlName]="'value'"
        [inputType]="'number'"
        [id]="'005_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
        [translationPrefix]="'wc.admin.cms.content.widget.modal'">
      </app-custom-input>

      <app-custom-input
        *ngSwitchCase="'BOOLEAN'"
        [parentGroup]="formGroup"
        [controlName]="'value'"
        [inputType]="'checkbox'"
        [id]="'006_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
        [translationPrefix]="'wc.admin.cms.content.widget.modal'">
      </app-custom-input>

      <!-- (ngModelChange) because (change) is sometime not emitting change on value changes -->
      <app-input-date-form-field
        [parentGroup]="formGroup"
        *ngSwitchCase="'DATE'"
        [id]="'007_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
        [controlName]="'value'"
        (ngModelChange)="valueChanged()">
      </app-input-date-form-field>

      <codebook-selection
        *ngSwitchCase="'CODEBOOK'"
        (change)="valueChanged()"
        [showEmptyOption]="!this.formGroup.get('mandatory').value"
        [id]="'008_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
        [formGroup]="formGroup"
        [formControlName]="'value'"
        [type]="widgetTemplateParameter.typeDetail">
      </codebook-selection>

      <app-input-select-form-field
        *ngSwitchCase="'ENUM'"
        [formControlName]="'value'"
        [id]="'009_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
        [inputType]="'enum'"
        [sourceName]="SOURCE_NAME"
        [enumName]="widgetTemplateParameter.typeDetail"
        [showEmptyOption]="!this.formGroup.get('mandatory').value"
        [label]="getLabel()">
      </app-input-select-form-field>

      <mat-error
        *ngIf="
          addError(widgetTemplateParameter.type, widgetTemplateParameter.typeDetail) &&
          formGroup.controls['value'].touched &&
          formGroup.controls['value'].validator &&
          formGroup.controls['value'].errors
        ">
        <ng-container *ngFor="let error of formGroup.controls['value'].errors | keyvalue">
          {{ 'wc.admin.cms.content.widget.modal.value.error.' + error.key | translate }}
        </ng-container>
      </mat-error>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="widgetTemplateParameter.localized">
    <ng-container [ngSwitch]="widgetTemplateParameter.type" [formGroup]="formGroup.get('localizedParameters')">
      <app-custom-input
        *ngSwitchDefault
        [parentGroup]="formGroup.get('localizedParameters')"
        [controlName]="locale"
        [inputType]="'text'"
        [label]="'value'"
        [id]="'010_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
        [translationPrefix]="'wc.admin.cms.content.widget.modal'">
      </app-custom-input>

      <ng-container *ngSwitchCase="'STRING'">
        <ng-container [ngSwitch]="widgetTemplateParameter.typeDetail">
          <app-custom-input
            *ngSwitchDefault
            [parentGroup]="formGroup.get('localizedParameters')"
            [id]="'011_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
            [controlName]="locale"
            [inputType]="'text'"
            [label]="'value'"
            [translationPrefix]="'wc.admin.cms.content.widget.modal'">
          </app-custom-input>

          <picture-selector
            *ngSwitchCase="'IMAGE'"
            [controlName]="locale"
            (onUniquenessChange)="valueChanged()"
            [parentGroup]="formGroup.get('localizedParameters')"
            [id]="'012_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
            [useDMS]="true"
            [widgetTemplateType]="widgetTemplateParameter.widgetTemplateType"
            (imageSize)="setImageSize($event)">
          </picture-selector>

          <angular-editor
            *ngSwitchCase="'HTML'"
            [config]="modalEditorConfig"
            (blur)="valueChanged()"
            [id]="'013_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
            [formControlName]="locale"
            [formGroup]="formGroup.get('localizedParameters')"
            id="007_edit_widget_modal_{{ index }}_{{ seqIndex }}">
          </angular-editor>
        </ng-container>
      </ng-container>

      <app-custom-input
        *ngSwitchCase="'NUMBER'"
        [parentGroup]="formGroup.get('localizedParameters')"
        [controlName]="locale"
        [inputType]="'number'"
        [id]="'014_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
        [label]="'value'"
        [translationPrefix]="'wc.admin.cms.content.widget.modal'">
      </app-custom-input>

      <app-custom-input
        *ngSwitchCase="'BOOLEAN'"
        [parentGroup]="formGroup.get('localizedParameters')"
        [controlName]="locale"
        [id]="'015_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
        [inputType]="'checkbox'"
        [label]="'value'"
        [translationPrefix]="'wc.admin.cms.content.widget.modal'">
      </app-custom-input>

      <!-- (ngModelChange) because (change) is sometime not emitting change on value changes -->
      <ng-container [formGroup]="formGroup.get('localizedParameters')">
        <date-picker
          *ngSwitchCase="'DATE'"
          [formControlName]="locale"
          (ngModelChange)="valueChanged()"
          [id]="'016_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
          [showTime]="true"
          class="input__group">
        </date-picker>
      </ng-container>

      <codebook-selection
        *ngSwitchCase="'CODEBOOK'"
        (change)="valueChanged()"
        [id]="'017_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
        [formControlName]="locale"
        [formGroup]="formGroup.get('localizedParameters')"
        [type]="widgetTemplateParameter.typeDetail">
      </codebook-selection>

      <app-input-select-form-field
        *ngSwitchCase="'ENUM'"
        [formControlName]="locale"
        [id]="'018_cms_contents_widget_modal_edit_' + index + '_' + seqIndex"
        [inputType]="'enum'"
        [sourceName]="SOURCE_NAME"
        [enumName]="widgetTemplateParameter.typeDetail"
        [showEmptyOption]="!this.formGroup.get('mandatory').value"
        [translationPrefix]="'wc.admin.cms.content.widget.modal'"
        [label]="getLabel()">
      </app-input-select-form-field>

      <mat-error
        *ngIf="
          addError(widgetTemplateParameter.type, widgetTemplateParameter.typeDetail) &&
          formGroup.get('localizedParameters').controls[locale].touched &&
          formGroup.get('localizedParameters').controls[locale].validator &&
          formGroup.get('localizedParameters').controls[locale].errors
        ">
        <ng-container *ngFor="let error of formGroup.get('localizedParameters').controls[locale].errors | keyvalue">
          {{ 'wc.admin.cms.content.widget.modal.value.error.' + error.key | translate }}
        </ng-container>
      </mat-error>
    </ng-container>
  </ng-container>
</div>
