import {
  AbstractPageComponent,
  AppBlockerService,
  Filter,
  ModulePreferencesService,
  Search,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { forkJoin, Observable, of } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { TableListingFormComponent } from '@components/table-listing-form/table-listing-form.component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';

@Component({ standalone: true, template: '' })
export abstract class AbstractListingPageComponent extends AbstractPageComponent implements OnInit {
  @Input()
  additionalHeaderLtKey: string;

  @Input()
  cacheFilter = true;

  @Input()
  constantFilter: Array<Filter> = [];

  @Input()
  multiSelectMode = false;

  @Input()
  singleSelectMode = false;

  @Input()
  disableActions = false;

  @Input()
  selectMode = true;

  @Input()
  disableListingActions = false;

  @Input()
  disableExpand = true;

  @Input()
  isModal: boolean = false;

  @Input()
  hideFilter = false;

  @Input()
  editable = true;

  @Output()
  readonly singleSelectEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(TableListingFormComponent)
  tableListingFormComponents: QueryList<TableListingFormComponent>;

  cacheFilterUsed = false;

  initSorting = null;

  protected deleteDataConfirmationTextKey = 'wc.admin.resource.delete.confirmation.text';
  protected deleteMultipleDateConfirmationTextKey = 'wc.admin.notification.delete.confirmation.text';

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };

  protected constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected modulePreferencesService: ModulePreferencesService,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
  ) {
    super(router, route);
  }

  ngOnInit(): void {
    if (this.cacheFilter) {
      const sessionSearch = this.modulePreferencesService.getPreferences(this.pageId());
      if (sessionSearch) {
        this.search = sessionSearch;
        this.cacheFilterUsed = true;
      }
    }

    if (!this.cacheFilterUsed && this.initSorting) {
      this.search.sorting.push(...this.initSorting);
    }
  }

  protected loadData() {
    this.appBlockerService.block();
    this.filterData()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          if (this.cacheFilter) {
            this.modulePreferencesService.setPreferences(this.pageId(), this.search);
          }
        } else {
          this.modulePreferencesService.setPreferences(this.pageId(), null);
        }
        this.appBlockerService.unblock();
      });
  }

  abstract filterData(): Observable<boolean>;

  getSelectedData(): Array<any> {
    let selectedData = [];
    this.tableListingFormComponents?.forEach(tableListingFormComponent => {
      if (tableListingFormComponent.selection.hasValue()) {
        selectedData = tableListingFormComponent.selection.selected;
      }
    });

    const uniqList = [];
    for (const data of selectedData) {
      if (!uniqList.find(i => i.id === data.id)) {
        uniqList.push(data);
      }
    }
    return uniqList;
  }

  singleSelect(group) {
    if (this.singleSelectMode) {
      this.singleSelectEvent.emit(group);
    }
  }

  deleteData(data, withoutConfirm?: boolean) {
    if (!withoutConfirm) {
      const confirmationDialogComponent = this.confirmationDialogService.openDialog([this.deleteDataConfirmationTextKey]);
      confirmationDialogComponent.confirmationHandler = dialogReference => {
        this.appBlockerService.block();
        this.deleteSingleRow(data.id)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            this.loadData();
            this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
          });
        confirmationDialogComponent.dialogReference.close();
      };
    } else {
      this.appBlockerService.block();
      this.deleteSingleRow(data.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.loadData();
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
    }
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    this.appBlockerService.unblock;
    throw new Error('deleteFunction not implemented.');
    return of(null);
  }

  deleteCheckedData() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      this.deleteMultipleDateConfirmationTextKey,
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      const bulkDeleteData: BulkDelete = {
        bulkDeleteList: [],
      };

      this.getSelectedData().forEach(selectedData => {
        bulkDeleteData.bulkDeleteList.push({
          id: selectedData.id,
        });
      });

      this.deleteMultipleRows(bulkDeleteData)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(() => {
          this.loadData();
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    this.appBlockerService.unblock;
    const deletedDateItems: Observable<any>[] = [];
    bulkDelete.bulkDeleteList.forEach(dataItem => {
      deletedDateItems.push(this.deleteSingleRow(dataItem.id));
    });
    return forkJoin(deletedDateItems);
  }

  onSortChange(sorting: MatSort) {
    if (sorting.direction !== '') {
      this.search.sorting = [{ column: sorting.active, sortOrder: sorting.direction }];
    } else {
      this.search.sorting = [];
    }
    this.loadData();
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadData();
  }

  goToPage(pageNo: number): void {
    this.search.paging.page = pageNo;
    this.loadData();
  }

  onNext(): void {
    this.search.paging.page++;
    this.loadData();
  }

  onPrev(): void {
    this.search.paging.page--;
    this.loadData();
  }

  pageSizeChanged(page) {
    this.search.paging.page = page.pageIndex + 1;
    this.search.paging.pageSize = page.pageSize;
    this.loadData();
  }
}

export interface BulkDeleteId {
  id: string;
}

export interface BulkDelete {
  bulkDeleteList: Array<BulkDeleteId>;
}
