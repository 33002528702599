import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { PagedRulesDto, ProductDto, RuleDto, TechnicalCategoryDto } from '@btl/admin-bff';
import {
  AbstractPageComponent,
  AdminAclService,
  AppBlockerService,
  CompareType,
  EnableDynamicLoading,
  Search,
} from '@btl/btl-fe-wc-common';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { ProductEditService } from '@service/product-edit.service';
import { FormArray, FormBuilder } from '@angular/forms';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AclUtils } from '@helpers/ac-utils';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;
import { EnumerationsService } from '@service/enumerations.service';

@Component({
  selector: 'app-product-acl',
  templateUrl: './product-acl.component.html',
  styleUrls: ['./product-acl.component.scss'],
})
@EnableDynamicLoading({ customName: ProductAclComponent.PAGE_ID })
export class ProductAclComponent extends AbstractPageComponent implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductAclComponent';

  pageId(): string {
    return ProductAclComponent.PAGE_ID;
  }

  product: ProductDto;
  aclRules: PagedRulesDto;
  parentAclRules: Array<RuleDto>;

  parametersAclRules: PagedRulesDto;
  parentParametersAclRules: Array<RuleDto>;
  lastAclParameterName;

  aclForm = this.formBuilder.group({
    acl: this.formBuilder.array([]),
  });

  get aclFormArray(): FormArray {
    return <FormArray>this.aclForm.get('acl');
  }

  private categories: TechnicalCategoryDto[];
  private categoriesLoaded = new ReplaySubject<void>();

  constructor(
    private productEditService: ProductEditService,
    protected router: Router,
    protected route: ActivatedRoute,
    private adminAclService: AdminAclService,
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private enumerationsService: EnumerationsService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit(): void {
    this.productEditService.duplicateProductEmitter.subscribe(() => {
      this.aclRules = {
        currentPage: 0,
        pageSize: 0,
        totalItems: 0,
        totalPages: 0,
        data: this.productEditService.aclRules,
      };
      this.parametersAclRules = {
        currentPage: 0,
        pageSize: 0,
        totalItems: 0,
        totalPages: 0,
        data: this.productEditService.aclParametersRules,
      };
    });

    this.enumerationsService
      .getProductTechnicalCategoryDtos()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.categories = result;
        this.categoriesLoaded.next();
        this.categoriesLoaded.complete();
      });

    this.product = this.productEditService.getEditProduct();
    if (this.product) {
      this.loadProductParametersConfigs();
      if (!this.productEditService.aclForm) {
        this.productEditService.aclForm = this.aclForm;
      } else {
        this.aclForm = this.productEditService.aclForm;
      }
    }

    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      this.product = result;
      if (this.product) {
        this.loadProductParametersConfigs();
        this.productEditService.aclForm = this.aclForm;
      }
    });
  }

  saveData(): boolean {
    if (!this.productEditService.canEdit()) {
      return true;
    }
    return true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.productEditService.resetMode) {
      if (this.saveData()) {
        return true;
      } else {
        return false;
      }
    }

    this.productEditService.resetMode = false;

    return true;
  }

  resourceIdentificationContext = {
    id: null,
    parameter: {
      name: null,
    },
  };

  loadAcl() {
    this.aclFormArray.clear();
    this.getAclRules();
    this.getAclParentRules();

    if (!this.lastAclParameterName) {
      this.parameterChanged(null);
    }
  }

  loadProductParametersConfigs() {
    this.categoriesLoaded
      .pipe(
        switchMap(() => this.productEditService.loadProductParametersConfigs(this.categories, this.product.category))
      )
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.loadAcl();
      });
  }

  getAllParameters() {
    return this.productEditService.categoryProductParameters;
  }

  aclLoaded() {
    return this.aclRules && this.parentAclRules;
  }

  aclParametersLoaded() {
    return this.parametersAclRules && this.parentParametersAclRules;
  }

  private getAclRules() {
    if (!this.productEditService.aclRules) {
      this.productEditService
        .getAclRules(this.product.productCode)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(rules => {
          this.aclRules = rules;
        });
    } else {
      this.aclRules = {
        currentPage: 0,
        pageSize: 0,
        totalItems: 0,
        totalPages: 0,
        data: this.productEditService.aclRules,
      };
    }
  }

  private getAclParentRules() {
    this.categoriesLoaded.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      const category = this.productEditService.getTechnicalCategoryById(this.product.category, this.categories);
      if (category) {
        const parentCategories: Array<TechnicalCategoryDto> = [];
        const parentRulesCalls = [];
        parentCategories.push(category);
        this.getAllParentCategories(category, this.categories, parentCategories);
        parentCategories.forEach(parentCategory => {
          parentRulesCalls.push(
            this.adminAclService.filterRules(
              AclUtils.getCategoryResourceIdentificationFilter('PC_PRODUCT', parentCategory.id),
              null
            )
          );
        });

        parentRulesCalls.push(
          this.adminAclService.filterRules(AclUtils.getEmptyResourceIdentificationFilter('PC_PRODUCT'), null)
        );
        forkJoin(parentRulesCalls)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(results => {
            this.parentAclRules = [];
            results.forEach((parentRules: PagedRulesDto) => {
              this.parentAclRules = this.parentAclRules.concat(parentRules.data);
            });
          });
      } else {
        this.parentAclRules = [];
      }
    });
  }

  getAllParentCategories(
    category: TechnicalCategoryDto,
    categories: Array<TechnicalCategoryDto>,
    parentCategories: Array<TechnicalCategoryDto>
  ) {
    if (category && category.parentId) {
      const parentCategory = categories.find(loopCategory => loopCategory.id === category.parentId);
      parentCategories.push(parentCategory);
      return this.getAllParentCategories(parentCategory, categories, parentCategories);
    }
    return;
  }

  private getAclParameterRules(parameterName: string) {
    if (!this.productEditService.aclRules) {
      this.productEditService
        .getAclParameterRules(this.product.productCode, this.lastAclParameterName, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(rules => {
          this.parametersAclRules = rules;
        });
    } else {
      this.parametersAclRules = {
        currentPage: 0,
        pageSize: 0,
        totalItems: 0,
        totalPages: 0,
        data: this.productEditService.aclParametersRules,
      };
    }
  }

  private getAclParameterParentRules(parameterName: string) {
    this.categoriesLoaded.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      const parentRulesCalls = [];
      if (parameterName) {
        parentRulesCalls.push(
          this.adminAclService.filterRules(
            AclUtils.getProductResourceIdentificationFilter('PC_PRODUCT_PARAMETER', this.product.productCode),
            null
          )
        );
      }
      const category = this.productEditService.getTechnicalCategoryById(this.product.category, this.categories);
      if (category) {
        const parentCategories: Array<TechnicalCategoryDto> = [];
        parentCategories.push(category);
        this.getAllParentCategories(category, this.categories, parentCategories);
        parentCategories.forEach(parentCategory => {
          if (parameterName) {
            const filter: Search = {
              filtering: [
                {
                  column: 'resourceType',
                  compareType: CompareTypeDtoEnum.EQUAL,
                  value: 'PC_PRODUCT_PARAMETER',
                },
              ],
              sorting: [],
              paging: { page: 1, pageSize: -1 },
            };
            filter.filtering.push({
              column: 'resourceIdentification',
              compareType: CompareTypeDtoEnum.LIKE,
              value: JSON.stringify({
                category: parentCategory.id,
                productParamName: parameterName,
              }),
            });
            parentRulesCalls.push(this.adminAclService.filterRules(filter, null));
          }
          parentRulesCalls.push(
            this.adminAclService.filterRules(
              AclUtils.getCategoryResourceIdentificationFilter('PC_PRODUCT_PARAMETER', parentCategory.id),
              null
            )
          );
        });
        parentRulesCalls.push(
          this.adminAclService.filterRules(AclUtils.getEmptyResourceIdentificationFilter('PC_PRODUCT_PARAMETER'), null)
        );
        forkJoin(parentRulesCalls)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(results => {
            if (!this.parentParametersAclRules) {
              this.parentParametersAclRules = [];
            } else {
              this.parentParametersAclRules.length = 0;
            }
            results.forEach((parentRules: PagedRulesDto) => {
              this.parentParametersAclRules = this.parentParametersAclRules.concat(parentRules.data);
            });
          });
      } else {
        this.parentParametersAclRules = [];
      }
    });
  }

  parameterChanged(parameterName: string) {
    this.resourceIdentificationContext.id = this.product.productCode;
    this.resourceIdentificationContext.parameter.name = parameterName;
    this.lastAclParameterName = parameterName;
    this.getAclParameterRules(parameterName);
    this.getAclParameterParentRules(parameterName);
  }
}
