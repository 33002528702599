<div *ngIf="editable" class="main__header">
  <div class="header-box">
    <div class="icon-box ACCESS">
      <mat-icon svgIcon="agreementTypes"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.agreementTypes' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      (click)="newAgreementType()"
      [routerLink]=""
      class="btn -primary -xs"
      *ngIf="aclService.pageEditAccess"
      mat-raised-button
      color="primary"
      id="001_agreementTypes_listing"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>
<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-agreementTypes-filter
        (filterChanged)="handleFilterChange()"
        [cacheFilterUsed]="cacheFilterUsed"
        [filtering]="search.filtering">
      </app-agreementTypes-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.agreementTypes.agreementTypeListing' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedAgreementTypesDto?.totalItems
                          ? pagedAgreementTypesDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedAgreementTypesDto?.totalItems
                    }
            }}
          </div>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="[
            'id',
            'name',
            'mandatory',
            'validFor.startDateTime',
            'validFor.endDateTime',
            'created',
            'createdBy'
          ]"
          [columnToTruncate]="['created', 'createdBy']"
          [dontShowOnMobile]="['created', 'createdBy']"
          [fillIdBy]="'id'"
          [pagedDto]="pagedAgreementTypesDto"
          (doubleClickEvent)="!isModal ? editAgreementType($event) : singleSelect($event)"
          (editInNewTabButtonEvent)="editAgreementType($event, true)"
          (editButtonEvent)="editAgreementType($event)"
          [disableActions]="disableActions"
          (deleteButtonEvent)="deleteData($event)"
          (pageSizeEvent)="pageSizeChanged($event)"
          (doubleClickEvent)="editAgreementType($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [editable]="aclService.pageEditAccess"
          [multiSelectMode]="false"
          [translationPrefix]="'wc.admin.agreementTypes'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
