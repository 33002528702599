<div class="card -full">
  <div class="card__inner">
    <div class="card__header" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        <span class="listing-header" *ngIf="!changesMode">{{ 'wc.admin.members' | translate }}</span>
        <span class="listing-header -small" *ngIf="changesMode">{{
          'wc.admin.releases.changedMembers' | translate
        }}</span>
      </div>
      <div fxLayoutGap="10px" class="listing-action" *ngIf="!changesMode" fxLayout="row" style="align-items: center">
        <div>
          <form class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <mat-form-field class='form__field' appearance='outline' floatLabel='always' *ngIf='editable'>
                    <mat-select
                      id="001_release_member_listing"
                      disableOptionCentering
                      class="form__input"
                      panelClass="form-dropdown"
                      [(ngModel)]="memberType"
                      [ngModelOptions]="{ standalone: true }">
                      <mat-option [value]="null"></mat-option>
                      <mat-option [id]="memberType" *ngFor="let memberType of memberTypes" [value]="memberType">
                        {{ memberType }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <button
            id="002_release_member_listing"
            color="primary"
            mat-raised-button
            (click)="addMember()"
            [routerLink]=""
            class="btn -primary -xs edit icon stroke">
            <mat-icon svgIcon="plus"></mat-icon>{{ 'wc.common.add' | translate }}
          </button>
          <mat-icon
            color="primary"
            (click)="isCollapsed = !isCollapsed"
            svgIcon="{{ isCollapsed ? 'hide' : 'show' }}"></mat-icon>
        </div>
      </div>
    </div>

    <div [hidden]="isCollapsed" class="card__body">
      <div>
        <div
          *ngIf="release && !changesMode"
          [editable]="true"
          [changesMode]="true"
          [release]="release"
          [operations]="operations"
          (deleteMemberChangeEvent)="deleteMemberChange($event)"
          app-members-listing
          class="card__wrapper"></div>
        <div style="width: 100%">
          <div style="width: 100%; text-align: right">
            <div fxLayoutGap="10px" class="listing-action" *ngIf="!changesMode">
              <button
                id="003_release_member_listing"
                color="primary"
                mat-stroked-button
                (click)="deleteCheckedMembers()"
                [routerLink]=""
                class="btn -primary -xs icon delete currentColor">
                <mat-icon svgIcon="delete"></mat-icon>{{ 'wc.admin.delete' | translate }}
              </button>
            </div>
          </div>
          <table class="table -primary customTable" style="table-layout: fixed">
            <thead>
              <th *ngIf="!changesMode" [style.width.px]="30" scope="col"></th>
              <th *ngIf="changesMode" scope="col">{{ 'wc.admin.releases.operationType' | translate }}</th>
              <th class="content-desktop" scope="col">
                <app-sortable-column localizationKey="wc.admin.releases.member.memberType"> </app-sortable-column>
              </th>
              <th class="content-desktop" scope="col">
                <app-sortable-column
                  *ngIf="!changesMode"
                  (sortChanged)="onSortChange()"
                  [sorting]="search.sorting"
                  column="extId"
                  localizationKey="wc.admin.releases.member.code">
                </app-sortable-column>
                <app-sortable-column *ngIf="changesMode" localizationKey="wc.admin.releases.member.code">
                </app-sortable-column>
              </th>
              <th class="content-desktop" scope="col">
                <app-sortable-column localizationKey="wc.admin.releases.member.inludePromotion"> </app-sortable-column>
              </th>
              <th class="content-desktop" scope="col">
                <app-sortable-column localizationKey="wc.admin.releases.member.name"> </app-sortable-column>
              </th>
              <th class="content-desktop" scope="col">
                <app-sortable-column
                  *ngIf="!changesMode"
                  (sortChanged)="onSortChange()"
                  [sorting]="search.sorting"
                  column="created"
                  localizationKey="wc.admin.products.product.created">
                </app-sortable-column>
                <app-sortable-column *ngIf="changesMode" localizationKey="wc.admin.products.product.created">
                </app-sortable-column>
              </th>
              <th class="content-desktop" scope="col">
                <app-sortable-column
                  *ngIf="!changesMode"
                  (sortChanged)="onSortChange()"
                  [sorting]="search.sorting"
                  column="modified"
                  localizationKey="wc.admin.products.product.modified">
                </app-sortable-column>

                <app-sortable-column *ngIf="changesMode" localizationKey="wc.admin.products.product.modified">
                </app-sortable-column>
              </th>
              <th class="content-desktop" scope="col">
                <app-sortable-column
                  *ngIf="!changesMode"
                  (sortChanged)="onSortChange()"
                  [sorting]="search.sorting"
                  column="modifiedBy"
                  localizationKey="wc.admin.products.product.modifiedBy">
                </app-sortable-column>

                <app-sortable-column *ngIf="changesMode" localizationKey="wc.admin.products.product.modifiedBy">
                </app-sortable-column>
              </th>
              <th *ngIf="editable" [style.width.px]="75" scope="col">
                {{ 'wc.admin.products.product.action' | translate }}
              </th>
            </thead>
            <tbody>
              <ng-container *ngIf="!changesMode">
                <tr
                  (deleteMember)="deleteMember($event)"
                  (editModeChanged)="editedMemberChanged($event)"
                  (editMember)="editMember($event)"
                  *ngFor="let member of pagedMembersDto?.data; let i = index"
                  [operations]="operations"
                  [editMode]="
                    editableMembersListingItemComponent && editableMembersListingItemComponent.member.id === member.id
                  "
                  [editable]="editable"
                  [parentId]="'listing_' + i"
                  [member]="member"
                  app-members-listing-item
                  class="table__row {{ getMemberOperationTypeClass(this.operations, member.id) }}"></tr>
              </ng-container>
              <ng-container *ngIf="changesMode">
                <tr
                  (deleteMember)="deleteMember($event)"
                  (editModeChanged)="editedMemberChanged($event)"
                  (editMember)="editMember($event)"
                  *ngFor="let member of operations; let i = index"
                  [operations]="operations"
                  [editMode]="
                    (editableMembersListingItemComponent &&
                      editableMembersListingItemComponent.member.id === member.id) ||
                    (newMember && newMember.id === member.id)
                  "
                  [editable]="editable"
                  [changesMode]="changesMode"
                  [parentId]="'listing_' + i"
                  [member]="member"
                  app-members-listing-item
                  class="table__row"></tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="release" [hidden]="isCollapsed" class="card__footer">
      <mat-paginator
        *ngIf="!changesMode"
        [length]="pagedMembersDto?.totalItems"
        [pageSize]="50"
        (page)="pageSizeChanged($event)"
        showFirstLastButtons="true"
        [pageSizeOptions]="[10, 25, 50, 100]"
        color="primary">
      </mat-paginator>
    </div>
  </div>
</div>
