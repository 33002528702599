<mat-form-field class="form__field -chips-form-field" appearance="outline" floatLabel="always">
  <mat-label>{{ 'wc.admin.ruleSets.ruleSetExpression' | translate }}</mat-label>
  <mat-chip-grid #chipList class="-chip-list ruleExpression">
    <ng-container *ngFor="let chipItem of ruleExpression; let i = index">
      <div cdkDropList [cdkDropListData]="{ item: chipItem, index: i }" (cdkDropListDropped)="drop($event)">
        <mat-chip-row
          cdkDrag
          class="-chip-item"
          color="primary"
          [id]="chipItem"
          [ngClass]="{ '-logical-and-not': checkLogicalAndNot(chipItem), '-logical-or': checkLogicalOr(chipItem) }"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="removeRuleExpression(i)">
          <mat-icon *ngIf="removable" svgIcon="dragAndDrop" class="mat-chip-drag-and-drop" />
          <span class="mat-chip-label">{{ chipItem }}</span>
          <mat-icon *ngIf="removable" matChipRemove svgIcon="close" />
        </mat-chip-row>
      </div>
    </ng-container>
    <input
      #ruleExpressionInput
      [formControl]="ruleExpressionControl"
      [disabled]="disabled"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addRuleExpression($event)"
      (input)="onInputChange($event.target.value)" />
    <mat-autocomplete
      #auto="matAutocomplete"
      id="001_rule_set_autocomplete"
      class="mat-chips-autocomplete"
      [style.left.px]="75"
      (optionSelected)="selected($event)">
      <ng-container *ngIf="showAutoComplete">
        <mat-option
          *ngFor="let rules of contextRules"
          [value]="rules"
          [id]="rules"
          [innerHTML]="highlightAutocomplete(rules) | safeHtml" />
      </ng-container>
    </mat-autocomplete>
  </mat-chip-grid>
</mat-form-field>
