<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'id'"
            [inputType]="'text'"
            id="001_dynamic_context_rules_filter"
            [translationPrefix]="'wc.admin.contextRule'"
            [label]="'name'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            [formControlName]="'applicationType'"
            id="002_dynamic_context_rules_filter"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [options]="applicationTypes"
            [translationPrefix]="'wc.admin.contextRule'">
          </app-input-select-form-field>
          <app-input-select-form-field
            [formControlName]="'implementationType'"
            id="003_dynamic_context_rules_filter"
            [options]="implementationTypes"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.contextRule'">
          </app-input-select-form-field>
          <app-custom-input
            [controlName]="'expression'"
            [inputType]="'text'"
            id="004_dynamic_context_rules_filter"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.contextRule'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'arguments'"
            [inputType]="'text'"
            id="005_dynamic_context_rules_filter"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.contextRule'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
