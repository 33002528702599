<div class="main__header">
  <div class="header-box">
    <div class="icon-box ENUMS">
      <mat-icon svgIcon="dataTypeEnums"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.dataTypeEnum.' + sourceName() | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      id="001_data_type_enum_edit"
      (click)="newDataTypeEnumItem()"
      *ngIf="aclService.pageEditAccess && !isNew"
      [routerLink]=""
      color="primary"
      mat-raised-button
      [title]="'wc.admin.dataTypeEnum.newItem.title' | translate"
      class="btn -primary -xs"
      >{{ 'wc.admin.dataTypeEnum.newItem' | translate }}</a
    >
    <a
      id="002_data_type_enum_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor">
      <mat-icon svgIcon="save"></mat-icon>
      {{ 'wc.common.save' | translate }}</a
    >
    <a
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="003_data_type_enum_edit" mat-menu-item (click)="duplicate()" *ngIf="!isNew" [routerLink]="">
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="004_data_type_enum_edit" mat-menu-item (click)="reset()" [routerLink]="">
        <mat-icon svgIcon="reset"></mat-icon>
        {{ 'wc.common.reset' | translate }}</a
      >
      <a id="005_data_type_enum_edit" mat-menu-item (click)="delete()" *ngIf="!isNew" [routerLink]="">
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.dataTypeEnum.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="dataTypeEnum" [formGroup]="dataTypeEnumForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="dataTypeEnumForm"
                  [controlName]="'name'"
                  [inputType]="'text'"
                  id="006_data_type_enum_edit"
                  [translationPrefix]="'wc.admin.dataTypeEnum'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.dataTypeEnum.itemListing' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: 1,
                      to: dataTypeEnum.items?.length,
                      of: dataTypeEnum.items?.length
                    }
            }}
          </div>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="['item', 'seq', 'created', 'modified', 'modifiedBy']"
          [columnToTruncate]="['created', 'modified', 'modifiedBy']"
          [dontShowOnMobile]="['created', 'modified', 'modifiedBy']"
          [fillIdBy]="'item'"
          (editButtonEvent)="editDataTypeEnumItem($event)"
          (editInNewTabButtonEvent)="editDataTypeEnumItem($event, true)"
          (deleteButtonEvent)="deleteDataTypeEnumItem($event)"
          [customData]="dataTypeEnum.items"
          [enablePaginator]="false"
          [multiSelectMode]="false"
          [translationPrefix]="'wc.admin.dataTypeEnumItem'"
          (sortChangeEvent)="onSortChange($event)"
          [editable]="aclService.pageEditAccess"
          (doubleClickEvent)="editDataTypeEnumItem($event)">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
