import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AbstractFilterComponent, CompareType } from '@btl/btl-fe-wc-common';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-codebook-type-filter',
  templateUrl: './codebook-type-filter.component.html',
})
export class CodebookTypeFilterComponent extends AbstractFilterComponent {
  @Output()
  readonly filterChanged: EventEmitter<string> = new EventEmitter<string>();

  mapFieldWithColumnName = new Map([
    ['name', 'codebookType']
  ]);

  mapFieldWithCompareType = new Map([
    ['name', CompareTypeDtoEnum.LIKE],
  ]);

  filterForm = this.formBuilder.group({
    name: [],
  });

  constructor(private formBuilder: FormBuilder) {
    super();
  }

}
