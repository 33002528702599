import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountService, AdminProductService, CurrentLocaleService, Search } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountDto, ProductDto } from '@btl/admin-bff';
import { AbstractDashboardWidgetComponent } from '@components/dashboard/abstract-dashboard-widget.component';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-last-changes',
  templateUrl: './last-changes.component.html',
  styleUrls: ['./last-changes.component.scss'],
})
export class LastChangesComponent extends AbstractDashboardWidgetComponent implements OnInit {

  lastChanges = [];

  icon;
  typeIcon;

  constructor(
    protected formBuilder: FormBuilder,
    protected currentLocaleService: CurrentLocaleService,
    private adminProductService: AdminProductService,
    private accountService: AccountService,
  ) {
    super(formBuilder, currentLocaleService);
  }

  ngOnInit(): void {
    const search: Search = {
      filtering: [],
      sorting: [{ column: 'modified', sortOrder: 'desc' }],
      paging: {
        page: 1,
        pageSize: 3,
      },
    };

    this.adminProductService
      .getProductsByFilter(search)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(products => {
        if (products?.data && products.data.length > 0) {
          const users = products.data.map(product => product.modifiedBy);
          const accountSearch: Search = {
            filtering: [{ column: 'login', compareType: 'IN', value: users }],
            sorting: [],
            paging: {
              page: 1,
              pageSize: 3,
            },
          };
          this.accountService
            .filterAccounts(accountSearch, null)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(accounts => {
              const usersMap = new Map<string, AccountDto>();
              accounts.data.forEach(account => usersMap.set(account.login, account));
              this.lastChanges.length = 0;
              products.data.forEach((product: ProductDto) => {
                const firstName = usersMap
                  .get(product.modifiedBy)
                  ?.parameters?.find(param => param.name === 'firstName')?.value;
                const lastName = usersMap.get(product.modifiedBy)?.parameters?.find(param => param.name === 'lastName')
                  ?.value;
                this.lastChanges.push({
                  product: product,
                  shortName: (firstName ? firstName.charAt(0) : '') + (lastName ? lastName.charAt(0) : ''),
                  name: `${firstName} ${lastName}`,
                  module: 'wc.admin.products.products',
                });
              });
            });
        }
      });
  }
}
