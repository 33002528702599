import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LeftMenuComponent } from '@components/left-menu/left-menu.component';
import { PageModule, PageModuleService } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  @ViewChild('childMenu', { static: true }) public childMenu;

  @Input()
  modules: PageModule[];

  @Input()
  module: PageModule;

  @Input()
  openPosition: string;

  subModules: PageModule[];

  constructor(public pageModules: PageModuleService) {}

  ngOnInit(): void {
    this.subModules = LeftMenuComponent.getCodebookModulesByParentCode(this.module.code, this.modules);
  }
}
