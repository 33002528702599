<div class="main__header">
  <div class="header-box">
    <div class="header-label">
      {{ 'wc.admin.files.fileListing' | translate }}
      {{ additionalHeaderLtKey ? (additionalHeaderLtKey | translate).toLowerCase() : '' }}
    </div>
  </div>
</div>
<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-dms-files-filter
        (filterChanged)="handleFilterChange()"
        (hideEvent)="toggleFilter()"
        *ngIf="baseFilterVisible && !hideFilter"
        [filtering]="search.filtering">
      </app-dms-files-filter>
    </div>
    <div class="card__inner">
      <div class="card__body">
        <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
          <div fxLayout="column">
            <div class="paginator__entries">
              {{
                'wc.common.pagination.showing'
                  | translate
                    : {
                        from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                        to:
                          search.paging.pageSize * search.paging.page > pagedDmsFilesDto?.totalItems
                            ? pagedDmsFilesDto?.totalItems
                            : search.paging.pageSize * search.paging.page,
                        of: pagedDmsFilesDto?.totalItems
                      }
              }}
            </div>
          </div>
        </div>
        <div class="card__body">
          <app-table-listing-form
            [columnName]="['name', 'extId', 'type', 'mediaType', 'note', 'created', 'modified', 'modifiedBy']"
            [fillIdBy]="'id'"
            [pagedDto]="pagedDmsFilesDto"
            [disableActions]="disabledActions"
            (pageSizeEvent)="pageSizeChanged($event)"
            (doubleClickEvent)="singleSelect($event)"
            [selectMode]="selectMode"
            (sortChangeEvent)="onSortChange($event)"
            [cacheFilter]="this.search"
            [translationPrefix]="'wc.admin.files'">
          </app-table-listing-form>
        </div>
      </div>
    </div>
  </div>
</div>
