<h2 mat-dialog-title>
  {{ 'wc.common.choose' | translate }}
    <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content class="mat-typography">
  <app-notification-type-list
    [disableActions]="disableActions"
    [selectMode]="selectMode"
    [disableListingActions]="disableListingActions"
    [singleSelectMode]="singleSelectMode"
    [isModal]="isModal"
    (singleSelectEvent)="singleSelect($event)">
  </app-notification-type-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a (click)="selectNothingEvent()" [routerLink]="" color="primary" mat-raised-button
    >{{ 'wc.common.cancel.button' | translate }}
  </a>
</mat-dialog-actions>
