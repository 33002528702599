import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentWidgetDto } from '@btl/admin-bff';

@Component({
  selector: 'app-banner-add-modal',
  templateUrl: './cms-content-banner-add-modal.component.html',
  styleUrls: ['./cms-content-banner-add-modal.component.scss'],
})
export class CmsContentBannerAddModalComponent {
  @Input()
  dialogRef;

  @Input()
  isBannerContent = false;

  @Output()
  readonly saveBannerEvent: EventEmitter<ContentWidgetDto> = new EventEmitter();
}
