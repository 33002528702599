<ng-container *ngIf="editProduct()">
  <div class="page_wrapper">
    <div class="main__header">
      <div class="product_edit-header">
        <div class="product_edit-label">
          {{ editProduct().id }}
        </div>
        <div class="product_edit-status" [ngClass]="editProduct().state">
          <mat-icon [svgIcon]="getIconState()" matTooltip="{{ getState() | translate }}" />
        </div>
      </div>
      <div class="page__menu">
        <a
          *ngIf="enablePatchAvailableFor() && aclService.pageEditAccess"
          id="001_product_edit"
          color="primary"
          mat-raised-button
          class="btn -primary -xs icon stroke currentColor"
          [routerLink]=""
          (click)="patchAvailableFor()">
          <mat-icon svgIcon="save" />
          {{ 'wc.common.save' | translate }}
        </a>
        <a
          *ngIf="enableSave() && allowSaveAnyTab"
          id="002_product_edit"
          color="primary"
          mat-raised-button
          class="btn -primary -xs icon stroke currentColor"
          [routerLink]=""
          (click)="save()"
          ><mat-icon [svgIcon]="isProductReadOnly() && !newProduct ? 'plus' : 'save'" />{{
            (isProductReadOnly() && !newProduct ? 'wc.common.newVersion' : 'wc.common.save') | translate
          }}
        </a>
        <a
          *ngIf="enableSwitchToNewVersion()"
          id="004_product_edit"
          color="primary"
          mat-stroked-button
          class="btn -primary stroked -xs icon currentColor"
          [routerLink]=""
          (click)="setNewVersion(true)">
          <mat-icon svgIcon="switchToNewVersion" />
          {{ 'wc.admin.product.edit.switchToNewVersion' | translate }}
        </a>
        <a
          *ngIf="enableApprove()"
          id="008_product_edit"
          color="primary"
          mat-raised-button
          class="btn -primary -xs green icon"
          [routerLink]=""
          (click)="approve()">
          <mat-icon svgIcon="accept" />
          {{
            (isActive() ? 'wc.admin.products.product.approve' : 'wc.admin.products.product.approveVersion') | translate
          }}
        </a>
        <a
          *ngIf="enableTest()"
          id="005_product_edit"
          color="primary"
          mat-stroked-button
          class="btn -primary stroked -xs icon currentColor"
          [routerLink]=""
          (click)="test()">
          <mat-icon svgIcon="testState" />
          {{ 'wc.admin.product.edit.test' | translate }}
        </a>
        <a
          *ngIf="enableDesign()"
          id="006_product_edit"
          color="primary"
          mat-stroked-button
          class="btn -primary stroked -xs icon currentColor"
          [routerLink]=""
          (click)="design()">
          <mat-icon svgIcon="designState" />
          {{ 'wc.admin.product.edit.design' | translate }}
        </a>
        <a
          *ngIf="aclService.pageEditAccess"
          #t="matMenuTrigger"
          id="003_product_edit"
          color="primary"
          mat-stroked-button
          class="btn -primary -xs more-actions icon dropDownIcon currentColor"
          [routerLink]=""
          [matMenuTriggerFor]="menu"
          >{{ 'wc.common.more.actions.button' | translate }}
          <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'" />
        </a>
        <mat-menu #menu="matMenu" class="module-actions">
          <a
            *ngIf="!newProduct"
            id="010_product_edit"
            mat-menu-item
            [routerLink]=""
            title="{{ 'wc.admin.products.product.duplicateTitle' | translate }}"
            (click)="duplicate()">
            <mat-icon svgIcon="duplicate" />
            {{ 'wc.common.duplicate' | translate }}</a
          >
          <a *ngIf="editProduct().productCode" id="012_product_edit" mat-menu-item [routerLink]="" (click)="releases()">
            <mat-icon svgIcon="upload" />
            {{ 'wc.admin.releases' | translate }}
          </a>
          <a id="007_product_edit" mat-menu-item [routerLink]="null" (click)="reset()">
            <mat-icon svgIcon="reset" />
            {{ 'wc.common.reset' | translate }}
          </a>
          <mat-divider />
          <a *ngIf="!newProduct && previewUrl" id="013_product_edit" mat-menu-item [routerLink]="" (click)="preview()">
            <mat-icon svgIcon="preview" />
            {{ 'wc.common.preview' | translate }}
          </a>
          <mat-divider />
          <a *ngIf="enableArchive()" id="011_product_edit" mat-menu-item [routerLink]="" (click)="archive()">
            <mat-icon svgIcon="archive" />
            {{ 'wc.common.archive' | translate }}
          </a>
          <a *ngIf="enableReject()" id="009_product_edit" mat-menu-item [routerLink]="" (click)="reject()">
            <mat-icon svgIcon="{{ isActive() ? 'refuse' : 'delete' }}" />
            {{ (isActive() ? 'wc.admin.products.product.reject' : 'wc.admin.products.product.delete') | translate }}</a
          >
        </mat-menu>
        <a id="014_product_edit" class="btn cancel-button" [routerLink]="" (click)="cancel()">
          {{ 'wc.common.cancel.button' | translate }}</a
        >
      </div>
    </div>

    <div *ngIf="enableWarning()" class="warning" fxLayoutAlign="center center">
      <div>
        {{ warningKey() | translate }}
      </div>
    </div>
    <div *ngIf="productEditService.categoryChangedWarning" class="warning" fxLayoutAlign="center center">
      <div>
        {{ 'wc.admin.products.product.categoryChangedWarning' | translate }}
      </div>
    </div>
    <div *ngIf="!aclService.pageEditAccess" class="warning" fxLayoutAlign="center center">
      <div>
        {{ 'wc.admin.products.product.dataWillNotBeSaved' | translate }}
      </div>
    </div>
    <router-outlet (activate)="onRouterOutletActivate($event)" />
  </div>
</ng-container>
