<div class="main__header">
  <div class="header-box">
    <div class="icon-box CMS">
      <mat-icon svgIcon="notifications"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.notification.notifications' | translate }}</div>
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-notification-notifications-filter (filterChanged)="handleFilterChange()" [cacheFilterUsed]="cacheFilterUsed"
                                             [filtering]="search.filtering">
      </app-notification-notifications-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.notification.notificationListings' | translate }}</span>
          <div class="paginator__entries" *ngIf="pagedNotificationsDto">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedNotificationsDto?.totalItems
                          ? pagedNotificationsDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedNotificationsDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            color="primary"
            mat-raised-button
            *ngIf="aclService.pageEditAccess"
            [disabled]="getSelectedData().length === 0"
            (click)="resendCheckedNotifications()"
            [routerLink]=""
            class="btn -primary -xs edit">
            <mat-icon style="font-size: 18px; line-height: 24px">send</mat-icon>
            {{ 'wc.admin.notification.resend' | translate }}
          </button>
          <button
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedData()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="[
            'recipients.recipientName',
            'recipients.address',
            'notificationType',
            'recipients.partyRefNo',
            'gateway',
            'channelTypeId',
            'state',
            'sent',
            'created',
            'createdBy'
          ]"
          [additionalDateColumns]="['sent']"
          [dontShowOnMobile]="['recipients.recipientName', 'created', 'createdBy']"
          [columnToTruncate]="['notificationType', 'gateway;120px', 'channelTypeId;120px', 'state']"
          [fillIdBy]="'id'"
          [module]="'notification'"
          [pagedDto]="pagedNotificationsDto"
          [editable]="aclService.pageEditAccess"
          [disableActions]="disableActions"
          [cacheFilter]="search"
          (editButtonEvent)="editNotification($event)"
          (editInNewTabButtonEvent)="editNotification($event, true)"
          (doubleClickEvent)="editNotification($event)"
          (deleteButtonEvent)="deleteData($event)"
          (pageSizeEvent)="pageSizeChanged($event)"
          (sortChangeEvent)="onSortChange($event)"
          (customButtonEvent)="resendNotification($event)"
          [translationPrefix]="'wc.admin.notification'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
