<div class="promotion-design" fxLayout="column">
  <div class="tree-container">
    <div
      class="tree-view"
      [ngClass]="{ rightMenu: selectedNode }"
      onmouseout="document.body.style.overflow='auto';"
      onmouseover="document.body.style.overflow='hidden';">
      <pan-zoom [config]="panZoomConfig">
        <div *ngIf="nodes?.length">
          <ng-container *ngFor="let node of nodes">
            <div class="promotion-design-self-{{ direction }}">
              <app-promotion-design-connections
                (nodeClick)="nodeClicked($event)"
                (nodeRemove)="deleteNode($event)"
                [compactView]="compactView"
                [collapseAllEmitter]="collapseAllEmitter"
                [direction]="direction"
                [node]="node"
                [selectedNode]="selectedNode">
              </app-promotion-design-connections>
            </div>
          </ng-container>
        </div>
      </pan-zoom>
      <div class="top-buttons">
        <div class="button-border">
          <div class="button-container">
            <button
              (click)="changeViewDirection()"
              class="icon"
              color="primary"
              mat-icon-button
              title="{{
                direction === 'vertical'
                  ? 'wc.admin.promotion.view.horizontal'
                  : ('wc.admin.promotion.view.vertical' | translate)
              }}">
              <mat-icon>{{ direction === 'vertical' ? 'panorama_horizontal' : 'panorama_vertical' }}</mat-icon>
            </button>
            <button
              (click)="changeViewType()"
              class="icon"
              color="primary"
              mat-icon-button
              title="{{
                compactView ? 'wc.admin.promotion.view.full' : ('wc.admin.promotion.view.compact' | translate)
              }}">
              <mat-icon>{{ compactView ? 'view_list' : 'view_stream' }}</mat-icon>
            </button>
          </div>
          <div class="button-container">
            <button
              (click)="collapse()"
              class="icon"
              color="primary"
              mat-icon-button
              title="{{ 'wc.admin.promotion.collapse' | translate }}">
              <mat-icon>fullscreen_exit</mat-icon>
            </button>
            <button
              (click)="expand()"
              class="icon"
              color="primary"
              mat-icon-button
              title="{{ 'wc.admin.promotion.expand' | translate }}">
              <mat-icon>fullscreen</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="bottom-buttons">
        <div fxLayout="row">
          <button
            (click)="onResetViewClicked()"
            class="icon"
            color="primary"
            mat-icon-button
            title="{{ 'wc.admin.promotion.resetView' | translate }}">
            <mat-icon>home</mat-icon>
          </button>
          <button
            (click)="onZoomOutClicked()"
            class="icon"
            color="primary"
            mat-icon-button
            title="{{ 'wc.admin.promotion.zoomOut' | translate }}">
            <mat-icon>zoom_out</mat-icon>
          </button>
          <button
            (click)="onZoomInClicked()"
            class="icon"
            color="primary"
            mat-icon-button
            title="{{ 'wc.admin.promotion.zoomIn' | translate }}">
            <mat-icon>zoom_in</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    (deleteNode)="deleteNode($event)"
    (closeEmitter)="selectedNode = null; this.selectedNodeEvent.emit(null)"
    *ngIf="selectedNode && selectedNode.type === 'socket'"
    [node]="selectedNode"
    [socketCategories]="socketCategories"
    app-selected-socket
    #selectedNodeComponent
    class="socket-edit"></div>

  <div
    (deleteNode)="deleteNode($event)"
    (closeEmitter)="selectedNode = null; this.selectedNodeEvent.emit(null)"
    *ngIf="selectedNode && selectedNode.type === 'root'"
    [node]="selectedNode"
    #selectedNodeComponent
    app-selected-root-product
    class="socket-edit"></div>

  <div
    (deleteNode)="deleteNode($event)"
    (closeEmitter)="selectedNode = null; this.selectedNodeEvent.emit(null)"
    *ngIf="selectedNode && selectedNode.type === 'socketItem'"
    [node]="selectedNode"
    [productEntityTypes]="productEntityTypes"
    [techCategories]="techCategories"
    #selectedNodeComponent
    app-selected-socket-content
    class="socket-edit"></div>
</div>
