<div>
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="cancel()">
    <mat-icon aria-label="Side nav toggle icon">chevron_left</mat-icon>
  </button>
  <h2>{{ 'wc.admin.products.products' | translate }}</h2>
</div>
<mat-nav-list>
  <ng-container *ngFor="let menuItem of getInnerMenuItems()">
    <a
      mat-list-item
      routerLink="{{ router.createUrlTree(['/pc/products/', productId, menuItem]) }}"
      [ngClass]="menuItem === this.urlName ? '-active' : ''">
      {{ this.getName(menuItem) | translate }}
    </a>
  </ng-container>
  <a
    mat-list-item
    routerLink="{{ router.createUrlTree(['/pc/products/', productId, 'documents']) }}"
    [ngClass]="'documents' === this.urlName ? '-active' : ''">
    {{ this.getName('documents') | translate }}
  </a>
</mat-nav-list>
