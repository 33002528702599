<div class="card__inner" [formGroup]="form">
  <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
    <div class="title-button">
      <span>{{
        (headerTranslationKey ? headerTranslationKey : 'wc.admin.productGroups.attachments') | translate
      }}</span>
      <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
        <mat-select
          id="001_form_pictures"
          [(value)]="selectedType"
          disableOptionCentering
          class="form__input"
          panelClass="form-dropdown"
          (selectionChange)="change($event)">
          <mat-option id="002_form_pictures" value="all">{{ 'wc.admin.all' | translate }}</mat-option>
          <mat-option [id]="type.id" *ngFor="let type of getParentTypes()" [value]="type.id">
            {{ type.id }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayoutGap="10px" class="listing-action">
      <button
        color="primary"
        mat-raised-button
        (click)="add(uploadImages)"
        [routerLink]=""
        class="btn -primary -xs edit icon stroke"
        [disabled]="disabled">
        <mat-icon svgIcon="plus"></mat-icon>
        {{ 'wc.admin.productGroups.attachments.new.image' | translate }}
      </button>
      <mat-icon
        *ngIf="!disableCollapseButton"
        (click)="collapsed = !collapsed"
        color="primary"
        svgIcon="{{ collapsed ? 'hide' : 'show' }}"
        style="cursor: pointer"></mat-icon>
    </div>
  </div>

  <div class="card__body pictures" *ngIf="!collapsed" formArrayName="attachments" cdkDropListGroup>
    <ng-container *ngFor="let subForm of getFormArray().controls; let i = index" [formGroup]="subForm">
      <div
        class="product_pictures-item"
        *ngIf="!subForm.get('delete').value && (selectedType === 'all' || subForm.get('type').value === selectedType)"
        cdkDropList
        cdkDropListOrientation="horizontal"
        [cdkDropListData]="{ item: subForm, index: i }"
        (cdkDropListDropped)="dropPicture($event)">
        <div class="product_pictures-item-inner" cdkDrag>
          <div class="product-pictures">
            <div class="priority" *ngIf="!singlePictureForType">
              {{ asFormGroup(subForm).controls.priority.value }}.
            </div>
            <div class="product-pictures-inner">
              <div class="picture_img">
                <img
                  [src]="
                    asFormGroup(subForm).controls.href.value
                      ? asFormGroup(subForm).controls.href.value
                      : asFormGroup(subForm).value.dmsFile?.contentHref
                  " />
              </div>
              <div class="pictures-info">
                <div class="picture_title">
                  {{ asFormGroup(subForm).value.dmsFile?.name }}
                </div>
                <div class="picture_types {{ asFormGroup(subForm).controls.type.value }}">
                  <label>{{ asFormGroup(subForm).controls.type.value }}</label>
                </div>
              </div>
              <div class="picture_actions">
                <div class="actions-box">
                  <div *ngIf="!disabled" class="actions-button-box" (click)="uploaded(subForm)"
                       [matTooltip]="'wc.admin.productGroups.attachments.upload.image' | translate">
                    <mat-icon svgIcon="upload"></mat-icon>
                  </div>
                  <div class="actions-button-box" (click)="download(subForm)"
                       [matTooltip]="'wc.admin.productGroups.attachments.download.image' | translate">
                    <mat-icon svgIcon="download"></mat-icon>
                  </div>
                  <div *ngIf="!disabled" class="actions-button-box" (click)="edit(uploadImages, subForm)"
                       [matTooltip]="'wc.admin.productGroups.attachments.edit.image' | translate">
                    <mat-icon svgIcon="edit"></mat-icon>
                  </div>
                  <div *ngIf="!disabled" class="actions-button-box" (click)="remove(subForm)"
                       [matTooltip]="'wc.admin.productGroups.attachments.delete.image' | translate">
                    <mat-icon svgIcon="delete"></mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="empty-state_container" *ngIf="getFormArray().controls.length === 0">
      <div class="empty-state_img">
        <mat-icon style="width: 100px; height: 105px" svgIcon="widgetEmpty"></mat-icon>
      </div>

      <div class="empty-state_text">
        <div>{{ 'wc.admin.products.product.pictures.empty.title' | translate }}</div>
        <div>{{ 'wc.admin.products.product.pictures.empty.text' | translate }}</div>
      </div>
    </div>
  </div>
</div>

<ng-template #uploadImages let-c="close" let-d="dismiss">
  <div class="dialog-title-unset-margin-bottom">
    <div>
      <h2 mat-dialog-title>
        {{ 'wc.admin.uploadImg.changeParam' | translate }}
        <mat-icon
          class="default-icon-gray-fill no-stroke"
          svgIcon="close"
          mat-dialog-close
          matTooltip="{{ 'wc.common.close' | translate }}">
        </mat-icon>
      </h2>
    </div>
  </div>
  <mat-dialog-content class="mat-typography picture-modal">
    <div class="card__wrapper">
      <div class="card__body">
        <div class="picture-wrapper edit" *ngIf="editPictureForm" [formGroup]="editPictureForm">
          <div class="picture_img">
            <img
              [src]="
                editPictureForm.controls.href.value
                  ? editPictureForm.controls.href.value
                  : editPictureForm.value.dmsFile?.contentHref
              " />
          </div>
          <div class="picture_info-box" formArrayName="childPictures">
            <div class="picture_title_box">
              {{ 'wc.admin.uploadImg.type' | translate }}
              <div class="picture_title">
                {{ editPictureForm?.controls.type.value }}
              </div>
            </div>
            <mat-divider *ngIf="getChildPicturesFormArray().length > 0"></mat-divider>
            <div class="picture_checkbox_box">
              <div
                class="checkbox-border"
                *ngFor="let picture of getChildPicturesFormArray().controls; index as i"
                [formGroup]="picture">
                <mat-checkbox formControlName="use" [id]="picture + i">{{
                  'wc.admin.products.product.pictures.' + asFormGroup(picture).controls.type.value | translate
                }}</mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-actions">
    <a (click)="cancel()" class="btn cancel-button" [routerLink]="">{{ 'wc.common.cancel.button' | translate }}</a>
    <button
      color="primary"
      class="btn -primary -xs icon fill no-stroke"
      mat-raised-button
      (click)="saveChanges()"
      [routerLink]="">
      <mat-icon svgIcon="widgetConfirm"></mat-icon>
      {{ 'wc.common.save' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>
