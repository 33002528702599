<h2 mat-dialog-title class="border-bot">
  {{ 'wc.common.acl.legend.header' | translate }}
  <div
    mat-dialog-close
    id="001_aclLegend_modal"
    [attr.aria-label]="'wc.common.close' | translate"
    class="mat-dialog-close">
    <span aria-hidden="true">&times;</span>
  </div>
</h2>
<mat-dialog-content class="mat-typography">
  <table style="border-spacing: 20px">
    <thead>
      <tr>
        <th></th>
        <th>{{ 'wc.common.acl.legend.child.revoke' | translate }}</th>
        <th>{{ 'wc.common.acl.legend.child.unset' | translate }}</th>
        <th>{{ 'wc.common.acl.legend.child.grant' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th>{{ 'wc.common.acl.legend.parent.unset' | translate }}</th>
        <td class="rule-cell">
          <div class="rule-main">
            <div class="rule REVOKE">
              {{ 'wc.common.acl.legend.revoke' | translate }}
            </div>
          </div>
        </td>
        <td class="rule-cell">
          <div class="rule-main">
            <div class="rule">
              {{ 'wc.common.acl.legend.unset' | translate }}
            </div>
          </div>
        </td>
        <td class="rule-cell">
          <div class="rule-main">
            <div class="rule GRANT">
              {{ 'wc.common.acl.legend.grant' | translate }}
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>{{ 'wc.common.acl.legend.parent.grant' | translate }}</th>
        <td class="rule-cell">
          <div class="rule-main GRANT">
            <div class="rule REVOKE">
              {{ 'wc.common.acl.legend.revoke' | translate }}
            </div>
          </div>
        </td>
        <td class="rule-cell">
          <div class="rule-main GRANT">
            <div class="rule">
              {{ 'wc.common.acl.legend.unset' | translate }}
            </div>
          </div>
        </td>
        <td class="rule-cell">
          <div class="rule-main GRANT">
            <div class="rule GRANT">
              {{ 'wc.common.acl.legend.grant' | translate }}
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>{{ 'wc.common.acl.legend.parent.revoke' | translate }}</th>
        <td class="rule-cell">
          <div class="rule-main REVOKE">
            <div class="rule REVOKE">
              {{ 'wc.common.acl.legend.revoke' | translate }}
            </div>
          </div>
        </td>
        <td class="rule-cell">
          <div class="rule-main REVOKE">
            <div class="rule">
              {{ 'wc.common.acl.legend.unset' | translate }}
            </div>
          </div>
        </td>
        <td class="rule-cell">
          <div class="rule-main REVOKE">
            <div class="rule GRANT">
              {{ 'wc.common.acl.legend.grant' | translate }}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</mat-dialog-content>
