import { EventEmitter, Injectable } from "@angular/core";
import {
  BulkOperationsRequestDto,
  DmsFileBulkOperationsRequestDto,
  ObjectAsMapDto,
  PagedRulesDto,
  ProductDto,
  ProductParameterDto,
  ProductPriceDto,
  ProductStateDto,
  ReviewDto,
  RuleDto,
  TechnicalCategoryDto
} from "@btl/admin-bff";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
  AclService,
  AdminAccountEntityRoleService,
  AdminAclService,
  AdminDmsService,
  AdminGroupsProductsService,
  AdminProductBundleService,
  AdminProductParameterService,
  AdminProductRatingService,
  AdminProductRelationshipService,
  AdminProductService,
  AdminSettingsService,
  AppBlockerService,
  CompareType,
  Search,
  ServiceUtils,
  StickyMessageService
} from "@btl/btl-fe-wc-common";
import { catchError, finalize, map, switchMap, tap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { AclTableListComponent } from "@components/acl/components/acl-rules-table/list/acl-table-list.component";
import { FormPicturesComponent } from "@components/form-pictures/form-pictures.component";
import { ConfirmationDialogService } from "@service/confirmation-dialog.service";
import { EnumerationsService } from "@service/enumerations.service";
import {
  DuplicateProductModalComponent,
} from "@components/product-catalogue/products/duplicate-product-modal/duplicate-product-modal.component";
import {
  OperationType,
  RulesListingComponent
} from '@components/acl/roles/role-edit/rules-listing/rules-listing.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Injectable({
  providedIn: 'root',
})
export class ProductEditService {
  static readonly PREFIX_NEW = 'new-';
  isBundleChanged = new EventEmitter<boolean>();
  editProductChanged = new EventEmitter<ProductDto>();
  productVersionChanged = new EventEmitter<boolean>();
  duplicateProductEmitter = new EventEmitter<ProductDto>();
  productEditMenuChanged = new EventEmitter<string>();
  savingProductId;
  originalBundle;
  originalCategory;
  categoryChangedWarning = false;
  private productDto: ProductDto;
  private productEditMenu;
  bundleComponents = null;
  documentBulkOperations: DmsFileBulkOperationsRequestDto = null;
  pictureBulkOperations: DmsFileBulkOperationsRequestDto = null;
  groupsProductsActions = [];
  relationshipOperations = [];
  reviewsOperations = [];
  groups = null;
  resetMode = false;

  categoryProductParameters: Array<ProductParameterDto>;

  productVersions;

  maxDraftAndActive;

  maxDraftAndActiveReached;

  private productPriceDto: ProductPriceDto;
  private generatedProductPriceId = 0;

  aclForm;

  aclRules: Array<RuleDto>;
  aclParametersRules: Array<RuleDto>;

  newVersion = false;
  enableEditProduct = true;

  duplicateProductId;
  duplicateFromProductCode;
  duplicateProductCode;
  duplicateSeoUrl;
  duplicateOptions;

  static truncateDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }

  static activeState(from: Date, to: Date, now = new Date(), today = this.truncateDate(now)): ProductActiveState {
    if (from) {
      if (from > now) {
        return ProductActiveState.PILOT;
      } else if (!to || to >= today) {
        return ProductActiveState.LAUNCHED;
      }
    }
    if (to && to < today) {
      return ProductActiveState.RETIRED;
    }
    return null;
  }

  static productActiveState(product: ProductDto, now = new Date(), today = this.truncateDate(now)): ProductActiveState {
    const from =
      product.availableFor && product.availableFor.startDateTime ? new Date(product.availableFor.startDateTime) : null;
    const to =
      product.availableFor && product.availableFor.endDateTime ? new Date(product.availableFor.endDateTime) : null;
    return ProductEditService.activeState(from, to, now, today);
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,
    private adminProductService: AdminProductService,
    private appBlockerService: AppBlockerService,
    private enumerationsService: EnumerationsService,
    private adminProductBundleService: AdminProductBundleService,
    private stickyMessageService: StickyMessageService,
    private adminSettingsService: AdminSettingsService,
    private adminProductRatingService: AdminProductRatingService,
    private adminProductParameterService: AdminProductParameterService,
    private adminGroupsProductsService: AdminGroupsProductsService,
    private adminAclService: AdminAclService,
    private adminDmsService: AdminDmsService,
    private adminProductRelationshipService: AdminProductRelationshipService,
    private aclService: AclService,
    private adminAccountEntityRoleService: AdminAccountEntityRoleService
  ) {
    this.adminSettingsService
      .getPropertySetting('product-catalog', 'versioning.max-draft-approved-versions')
      .subscribe(result => {
        if (result) {
          this.maxDraftAndActive = +result;
        }
      });

    this.route.queryParams.subscribe(queryParams => {
      this.duplicateProductId = queryParams['duplicateProductId'];
      this.duplicateOptions = queryParams['duplicateOptions'];
      this.duplicateProductCode = queryParams['duplicateProductCode'];
      this.duplicateSeoUrl = queryParams['duplicateSeoUrl'];
    });

    // fix of left sub-menu for product detail was still displayed when detail was leaved
    this.router.events.subscribe(navigation => {
      if (navigation instanceof NavigationEnd) {
        if (!navigation.url.startsWith('/pc/products/')) {
          this.setProductToEdit(undefined);
        }
      }
    });
  }

  // TODO logic of this method should be moved to methods #navigationSubscription of each product edit sub-forms;
  //      this will be necessary for refactor product edit form to admin modules supported dynamic routing
  navigate(navigation: NavigationEnd) {
    if (navigation.url.startsWith('/pc/products/')) {
      const splitUrl = navigation.url.split('/');
      const state = this.router.lastSuccessfulNavigation.extras.state;
      this.productVersions = null;
      if (state) {
        this.productVersions = state.productVersions;
      }
      if (splitUrl.length >= 5) {
        let newProductEditMenu = splitUrl[4];
        const paramsIndex = newProductEditMenu.indexOf('?');
        if (paramsIndex > 0) {
          newProductEditMenu = newProductEditMenu.substr(0, paramsIndex);
        }
        if (!this.productEditMenu || this.productEditMenu !== newProductEditMenu) {
          this.setProductEditMenu(newProductEditMenu);
        }
      } else {
        this.setProductEditMenu('attributes');
      }

      const productId = splitUrl[3];
      const newProduct = this.isNewProduct(productId);
      if (productId && !newProduct && (!this.productDto || this.productDto.id !== productId)) {
        this.adminProductService.getProductById(productId).subscribe(result => {
          this.originalBundle = result.bundle;
          this.setProductToEdit(result);
          this.setPartOfProductToEdit(splitUrl);
        });
      } else if (
        !this.productDto ||
        (newProduct &&
          productId !== this.productDto.id &&
          (!this.productDto.productCode || !this.productDto.productCode.endsWith('copy')))
      ) {
        if (this.duplicateProductId) {
          this.adminProductService.getProductById(this.duplicateProductId).subscribe(result => {
            this.originalBundle = result.bundle;
            this.setProductToEdit(result);

            this.setPartOfProductToEdit(splitUrl);
            this.duplicate();
          });
        } else {
          this.setProductToEdit(this.getNewProduct());
        }
      } else {
        this.editProductChanged.emit(this.productDto);
      }
      this.setPartOfProductToEdit(splitUrl);
    } else {
      this.setProductToEdit(undefined);
    }
  }

  private setPartOfProductToEdit(splitUrl: string[], manualEdit: boolean = false) {
    if ((splitUrl.length >= 6 && this.productEditMenu === 'prices') || manualEdit) {
      const priceIdStr = manualEdit ? splitUrl : splitUrl[5];
      if (priceIdStr === '&') {
        this.productPriceDto = this.getNewProductPrice();
      } else {
        this.productPriceDto = null;
        if (priceIdStr && this.productDto && this.productDto.prices) {
          this.productDto.prices.forEach(price => {
            if (price && price.id === priceIdStr) {
              this.productPriceDto = price;
            }
          });
        }
      }
    }
  }

  public setProductIdPriceToEdit(id, manualEdit) {
    this.setPartOfProductToEdit(id, manualEdit);
  }

  public emitEditProductChange() {
    this.editProductChanged.emit(this.productDto);
  }

  public isMaxDraftAndActiveReached(): boolean {
    if (
      this.isProductMultiActive(this.productDto) &&
      this.maxDraftAndActive &&
      this.maxDraftAndActive > 0 &&
      this.productVersions
    ) {
      let count = 0;
      this.productVersions.data.forEach(product => {
        if (product.state !== ProductStateDto.OBSOLETE) {
          count++;
        }
      });

      if (count >= this.maxDraftAndActive) {
        return true;
      }
    }

    return false;
  }

  public isNewProduct(productId?: string): boolean {
    if (!productId || productId === 'newProduct') {
      return true;
    }
    return false;
  }

  public setProductEditMenu(productEditMenu) {
    this.productEditMenu = productEditMenu;
    this.productEditMenuChanged.emit(this.productEditMenu);
  }

  public setProductToEdit(productDto) {
    this.newVersion = false;
    this.categoryChangedWarning = false;
    this.productDto = productDto;
    if (productDto) {
      this.originalBundle = productDto.bundle;
      this.originalCategory = productDto.category;
    } else {
      this.originalCategory = undefined;
      this.originalBundle = undefined;
    }
    this.aclForm = null;
    this.aclRules = null;
    this.aclParametersRules = null;
    this.bundleComponents = null;
    this.documentBulkOperations = null;
    this.pictureBulkOperations = null;
    this.groupsProductsActions = [];
    this.relationshipOperations = [];
    this.reviewsOperations = [];
    this.groups = null;
    this.categoryProductParameters = null;

    if (this.productDto && !this.isNewProduct(this.productDto.id)) {
      if (this.productVersions) {
        this.maxDraftAndActiveReached = this.isMaxDraftAndActiveReached();
      } else {
        this.getProductVersionsAndCheckMaxDraftAndActive();
      }
    }
    this.enableEditProduct = this.isEnabledEditProduct();
    this.editProductChanged.emit(this.productDto);
  }

  public getProductVersionsAndCheckMaxDraftAndActive() {
    this.adminProductService.getProductVersions(this.productDto.productCode, 1, -1).subscribe(result => {
      this.productVersions = result;
      this.maxDraftAndActiveReached = this.isMaxDraftAndActiveReached();
    });
  }

  public getEditProduct(): ProductDto {
    return this.productDto;
  }

  public isEnabledEditProduct() {
    return (
      this.productDto &&
      ((this.newVersion && this.productDto.state === ProductStateDto.ACTIVE) ||
        this.productDto.state === ProductStateDto.DESIGN ||
        this.productDto.id === 'newProduct' ||
        !this.productDto.id)
    );
  }

  public setEditProductPrice(productPriceDto) {
    this.productPriceDto = productPriceDto;
  }

  public getEditProductPrice(): ProductPriceDto {
    return this.productPriceDto;
  }

  /**
   * Generate fake id of product price, by pattern: incremented integer with prefix 'new-'.
   * It is used for editing and deleting yet not saved prices.
   * Before save product, price's ids matched pattern are set to the null.
   */
  public generateNewProductPriceId(): string {
    this.generatedProductPriceId++;
    return ProductEditService.PREFIX_NEW + this.generatedProductPriceId;
  }

  public isNewProductPrice(price): boolean {
    return price && price.id.startsWith(ProductEditService.PREFIX_NEW);
  }

  public addProductPriceIfIsNew() {
    if (
      this.getEditProduct() &&
      this.isNewProductPrice(this.getEditProductPrice()) &&
      this.getEditProduct().prices.indexOf(this.getEditProductPrice()) === -1
    ) {
      this.getEditProduct().prices = [...this.getEditProduct().prices, this.getEditProductPrice()];
    }
  }

  save() {
    this.productDto.pictures = FormPicturesComponent.clearAttachmentList(this.productDto.pictures);
    this.productDto.documents = FormPicturesComponent.clearAttachmentList(this.productDto.documents);
    this.appBlockerService.block();
    if (this.pictureBulkOperations) {
      this.adminDmsService.changeFiles(this.pictureBulkOperations).subscribe(() => {
        this.saveProduct();
      });
    } else {
      this.saveProduct();
    }
  }

  saveProduct() {
    if (this.originalBundle && !this.productDto.bundle) {
      this.adminProductBundleService
        .updateProductBundleComponents(this.productDto.id, [])
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe();
    }
    this.productDto.created = null;
    this.productDto.createdBy = null;
    this.productDto.modified = null;
    this.productDto.modifiedBy = null;
    if (this.productDto.recordVersion) {
      this.savingProductId = this.productDto.id;
      const clone =
        this.isProductMultiActive(this.productDto) &&
        (this.productDto.state === ProductStateDto.ACTIVE || this.productDto.state === ProductStateDto.OBSOLETE);
      this.productDto.prices.forEach(price => {
        if (this.isNewProductPrice(price)) {
          price.id = null;
        }
      });
      this.adminProductService.updateProduct(this.productDto.id, this.productDto, false, clone).subscribe(
        product => {
          this.handleProductAndBundles(product);
        },
        error => this.appBlockerService.unblock()
      );
    } else {
      this.productDto.id = null;
      this.productDto.prices.forEach(price => {
        price.id = null;
      });

      const cloneFromId: string = this.duplicateProductId;
      let cloneRelationships: boolean = null;
      let cloneCartEvents: boolean = null;
      let cloneGroups: boolean = null;
      if (cloneFromId) {
        cloneRelationships =
          this.duplicateOptions.find(option => option === DuplicateProductModalComponent.RELATIONSHIPS) != null;
        cloneCartEvents =
          this.duplicateOptions.find(option => option === DuplicateProductModalComponent.CART_EVENTS) != null;
        cloneGroups = this.duplicateOptions.find(option => option === DuplicateProductModalComponent.GROUPS) != null;
      }
      this.adminProductService
        .createProduct(this.productDto, cloneFromId, cloneRelationships, cloneCartEvents, cloneGroups)
        .subscribe(
          product => {
            const calls = [];
            if (this.duplicateOptions?.find(option => option === DuplicateProductModalComponent.RATINGS)) {
              calls.push(this.adminProductRatingService.cloneReviews(this.duplicateProductId, product.id));
            }
            if (this.duplicateOptions?.find(option => option === DuplicateProductModalComponent.ENTITY_ACL)) {
              calls.push(
                this.adminAccountEntityRoleService.cloneAccountEntityRoles(
                  'com.emeldi.ecc.be.pc.dto.ProductMaster',
                  this.duplicateFromProductCode,
                  product.productCode
                )
              );
            }

            if (calls.length > 0) {
              forkJoin(calls).subscribe(
                result => {
                  this.handelCreateProduct(product);
                },
                error => {
                  this.stickyMessageService.addStickyErrorMessage('Error when cloning product', null, error);
                  this.handelCreateProduct(product);
                }
              );
            } else {
              this.handelCreateProduct(product);
            }
          },
          error => this.appBlockerService.unblock()
        );
    }
  }

  private handelCreateProduct(product: ProductDto) {
    this.handleProductAndBundles(product);
    this.duplicateFromProductCode = null;
    this.duplicateProductCode = null;
    this.duplicateSeoUrl = null;
    this.duplicateOptions = null;
    this.duplicateProductId = null;
  }

  patchAvailableFor() {
    this.appBlockerService.block();
    const productAsMap: ObjectAsMapDto = {};
    productAsMap.recordVersion = this.productDto.recordVersion;
    productAsMap.availableFor = {
      startDateTime: this.productDto.availableFor.startDateTime,
      endDateTime: this.productDto.availableFor.endDateTime,
    };
    this.savingProductId = this.productDto.id;
    this.adminProductService
      .patchProduct(this.productDto.id, productAsMap, true)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(this.getProductHandler);
  }

  getBundleRequest(productDto) {
    if (productDto && productDto.bundle && this.bundleComponents != null) {
      const bundles = [];
      (this.bundleComponents as Array<ProductDto>).forEach(product => {
        bundles.push(product.productCode);
      });
      return this.adminProductBundleService.updateProductBundleComponents(productDto.id, bundles);
    }

    return of(null);
  }

  getGroupRequest(productDto) {
    if (productDto && this.groupsProductsActions?.length > 0) {
      return this.adminGroupsProductsService.updateGroup(this.groupsProductsActions);
    }
    return of(null);
  }

  getAclRequest(productDto) {
    if (productDto) {
      let bulkOperationsRequestDto: BulkOperationsRequestDto = null;

      if (!this.aclRules && this.aclForm) {
        if (this.aclForm.controls['acl'].value.length > 0) {
          bulkOperationsRequestDto = AclTableListComponent.getBulkOperationFromRuleSet(
            this.aclForm.controls['acl'].value,
            productDto.productCode
          );
        }
      } else if (this.aclRules) {
        bulkOperationsRequestDto = AclTableListComponent.getBulkOperationFromRuleSet(
          this.aclRules.concat(this.aclParametersRules),
          productDto.productCode
        );
      }

      if (bulkOperationsRequestDto) {
        return this.adminAclService.changeRules(bulkOperationsRequestDto).pipe(
          catchError(err => {
            if (this.aclRules || this.aclParametersRules) {
              this.stickyMessageService.addStickyWarningMessage('wc.admin.messages.sticky.ok');
              this.aclRules = null;
              this.aclParametersRules = null;
              return of(null);
            } else {
              return err;
            }
          })
        );
      }
    }
    return of(null);
  }

  handleProductAndBundles(productDto: ProductDto) {
    const calls = [];
    calls.push(this.getBundleRequest(productDto));
    calls.push(this.getGroupRequest(productDto));
    calls.push(this.getAclRequest(productDto));
    if (productDto && this.documentBulkOperations) {
      calls.push(this.adminDmsService.changeFiles(this.documentBulkOperations));
    }
    if (productDto && this.relationshipOperations.length > 0) {
      const relationshipBulkOperationsRequestDto = this.getBulkOperationsRequestDto(this.relationshipOperations);
      relationshipBulkOperationsRequestDto.bulkCreateList.forEach(relationship => {
        relationship.id = null;
        if (!relationship.sourceId) {
          if (relationship.sourceType === 'ProdCode') {
            relationship.sourceId = productDto.productCode;
          } else {
            relationship.sourceId = productDto.id;
          }
        }
        if (!relationship.targetId) {
          if (relationship.targetType === 'ProdCode') {
            relationship.targetId = productDto.productCode;
          } else {
            relationship.targetId = productDto.id;
          }
        }
      })
      calls.push(this.adminProductRelationshipService.changeRelationships(relationshipBulkOperationsRequestDto));
    }
    if (productDto && this.reviewsOperations.length > 0) {
      const bulkOperationsRequestDto = this.getBulkOperationsRequestDto(this.reviewsOperations);
      bulkOperationsRequestDto.bulkCreateList.forEach((review: ReviewDto) => {
        review.id = null;
        if (!review.productId) {
          review.productId = productDto.productCode;
        }
      })
      calls.push(this.adminProductRatingService.changeReviews(bulkOperationsRequestDto));
    }
    forkJoin(calls)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(() => this.getProductHandler(productDto));
  }

  getBulkOperationsRequestDto(operations) {
    let bulkOperations = {
      bulkCreateList: operations.filter(
        relationship => relationship[RulesListingComponent.OPERATION_TYPE] === OperationType.CREATE
      ),
      bulkDeleteList: operations.filter(
        relationship => relationship[RulesListingComponent.OPERATION_TYPE] === OperationType.DELETE
      ),
      bulkUpdateList: operations.filter(
        relationship => relationship[RulesListingComponent.OPERATION_TYPE] === OperationType.UPDATE
      )
    }
    bulkOperations.bulkCreateList.forEach(operation => delete operation[RulesListingComponent.OPERATION_TYPE]);
    bulkOperations.bulkUpdateList.forEach(operation => delete operation[RulesListingComponent.OPERATION_TYPE]);
    bulkOperations.bulkDeleteList.forEach(operation => delete operation[RulesListingComponent.OPERATION_TYPE]);
    return bulkOperations;
  }

  public static getProductText(product: ProductDto, textType: string, locale: string): string {
    let text = '';
    if (product) {
      product.texts.forEach(textloop => {
        if (textloop.textType === textType && textloop.locale === locale) {
          text = textloop.message;
        }
      });
    }

    return text;
  }

  public reset() {
    this.resetMode = true;
    this.newVersion = false;
    this.appBlockerService.block();
    this.adminProductService
      .getProductById(this.productDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(this.getProductHandler);
  }

  public test() {
    this.appBlockerService.block();
    const productAsMap: ObjectAsMapDto = {};
    productAsMap.state = ProductStateDto.TEST;
    productAsMap.recordVersion = this.productDto.recordVersion;
    this.savingProductId = this.productDto.id;
    this.adminProductService
      .patchProduct(this.productDto.id, productAsMap)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(this.getProductHandler);
  }

  public design() {
    this.appBlockerService.block();
    const productAsMap: ObjectAsMapDto = {};
    productAsMap.state = ProductStateDto.DESIGN;
    productAsMap.recordVersion = this.productDto.recordVersion;
    this.savingProductId = this.productDto.id;
    this.adminProductService
      .patchProduct(this.productDto.id, productAsMap)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(this.getProductHandler);
  }

  private approveProduct() {
    this.appBlockerService.block();
    const productAsMap: ObjectAsMapDto = {};
    productAsMap.state = ProductStateDto.ACTIVE;
    productAsMap.recordVersion = this.productDto.recordVersion;
    this.savingProductId = this.productDto.id;
    this.adminProductService
      .patchProduct(this.productDto.id, productAsMap)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(this.getProductHandler);
  }

  public approve() {
    if (this.isProductMultiActive(this.productDto)) {
      this.approveProduct();
    } else {
      this.adminProductService
        .getProductVersions(this.productDto.productCode, 1, -1)
        .subscribe(pagedProductVersions => {
          if (
            pagedProductVersions.data &&
            pagedProductVersions.data.filter(value => value.state === 'ACTIVE').length > 0
          ) {
            const confirmationDialogComponent = this.confirmationDialogService.openDialog([
              'wc.admin.product.approve.savm.confirmation.text',
            ]);
            confirmationDialogComponent.confirmationHandler = dialogReference => {
              this.approveProduct();
              confirmationDialogComponent.dialogReference.close();
            };
          } else {
            this.approveProduct();
          }
        });
    }
  }

  public archive() {
    this.appBlockerService.block();
    const productAsMap: ObjectAsMapDto = {};
    productAsMap.state = ProductStateDto.OBSOLETE;
    productAsMap.recordVersion = this.productDto.recordVersion;
    this.savingProductId = this.productDto.id;
    this.adminProductService
      .patchProduct(this.productDto.id, productAsMap)
      .pipe(
        finalize(() => {
          this.getProductVersionsAndCheckMaxDraftAndActive();
          this.appBlockerService.unblock();
        })
      )
      .subscribe(this.getProductHandler);
  }

  public static getLocaleProductNameOrDescription(product: ProductDto, locale: string) {
    let name = this.getProductText(product, TextType.PRODUCT_NAME, locale);
    if (product && name === '') {
      name = product.description;
    }
    return name;
  }

  public reject() {
    this.appBlockerService.block();
    this.adminProductService
      .rejectProduct(this.productDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        this.router.navigate(['pc/products']);
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      });
  }

  public duplicateProduct(product: ProductDto, newVersion?: boolean): ProductDto {
    this.duplicateFromProductCode = product.productCode;
    if (!newVersion) {
      product.id = 'newProduct';
      product.recordVersion = null;
      product.productCode = this.duplicateProductCode;
      product.seoUrl = this.duplicateSeoUrl ? this.duplicateSeoUrl : null;
      product.state = ProductStateDto.DESIGN;
      product.version = -1;
      product.verHash = null;
    }

    if (product.prices) {
      product.prices.forEach(price => {
        if (price) {
          price.id = null;
        }
      });
    }

    this.enumerationsService
      .getProductTechnicalCategoryDtos()
      .pipe(switchMap(categories => this.loadProductParametersConfigs(categories, product.category)))
      .subscribe(() => {
        const calls = [this.getAclRules(this.duplicateFromProductCode)];
        if (this.categoryProductParameters) {
          const chunkSize = 20;
          const paramsChunks: Array<Array<ProductParameterDto>> = [];
          for (let i = 0, len = this.categoryProductParameters.length; i < len; i += chunkSize) {
            paramsChunks.push(this.categoryProductParameters.slice(i, i + chunkSize));
          }
          paramsChunks.forEach((paramsChunk: ProductParameterDto[]) => {
            const paramNames = paramsChunk.map((param: ProductParameterDto) => param.name);
            calls.push(this.getAclParameterRules(this.duplicateFromProductCode, null, paramNames));
          });
        }

        forkJoin(calls).subscribe(results => {
          if (results) {
            let i = 0;
            this.aclParametersRules = [];
            results.forEach((result: PagedRulesDto) => {
              if (i === 0) {
                this.aclRules = result.data;
                this.aclRules.forEach(rule => (rule.id = null));
              } else {
                this.aclParametersRules = this.aclParametersRules.concat(result.data);
                this.aclParametersRules.forEach(rule => (rule.id = null));
              }
              i++;
            });
          }
          this.duplicateProductEmitter.emit(product);
          if (this.duplicateOptions && this.duplicateOptions.length > 0) {
            this.appBlockerService.block();
            this.saveProduct();
          } else {
            this.router.navigate(['pc/products', 'newProduct']);
          }
        });
      });

    return product;
  }

  loadProductParametersConfigs(allCategories: TechnicalCategoryDto[], categoryId?: string) {
    if (!categoryId) {
      categoryId = this.productDto?.category;
    }
    if (categoryId && !this.categoryProductParameters) {
      let category = this.getTechnicalCategoryById(categoryId, allCategories);
      const filterCategories = [];
      if (category) {
        filterCategories.push(category.id);
        while (category.parentId != null) {
          category = this.getTechnicalCategoryById(category.parentId, allCategories);
          filterCategories.push(category.id);
        }
      }

      if (category) {
        const search = ServiceUtils.getUnlimitedSearch();
        search.filtering = [
          {
            column: 'category',
            compareType: 'IN',
            value: filterCategories,
          },
        ];

        return this.adminProductParameterService
          .getProductParametersByFilter(search)
          .pipe(
            map(result => (this.categoryProductParameters = this.sortCategoryParameters(result.data, filterCategories)))
          );
      }
    }

    return of(null);
  }

  private sortCategoryParameters(parameters: ProductParameterDto[], orderCategories: string[]): ProductParameterDto[] {
    const order = Object.assign({}, ...orderCategories.map((category, index) => ({ [category]: index })));
    return parameters
      .filter(p => order[p.category] !== undefined) // to sure that will be sorted only parameters of categories from ordered list
      .sort((a, b) => order[a.category] - order[b.category]);
  }

  public getAclRules(productCode) {
    const filter: Search = {
      filtering: [
        {
          column: 'resourceType',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: 'PC_PRODUCT',
        },
        {
          column: 'resourceIdentification',
          compareType: CompareTypeDtoEnum.LIKE,
          value: JSON.stringify({ productCode: productCode ? productCode : '' }).slice(1, -1), //BTL-3535 Remove { } brackets to allow filtering of more complicated identifiers
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    return this.adminAclService.filterRules(filter, null);
  }

  public getAclParameterRules(productCode, parameterName: string, paramNames: string[]) {
    const filter: Search = {
      filtering: [
        {
          column: 'resourceType',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: 'PC_PRODUCT_PARAMETER',
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    if (parameterName) {
      filter.filtering.push({
        column: 'resourceIdentification',
        compareType: CompareTypeDtoEnum.LIKE,
        value: JSON.stringify({
          productCode: productCode ? productCode : '',
          productParamName: parameterName,
        }).slice(1, -1), //BTL-3535 Remove { } brackets to allow filtering of more complicated identifiers
      });
    }
    if (paramNames?.length) {
      const filterValue = paramNames
        .map(
          pName =>
            JSON.stringify({
              productCode: productCode ? productCode : '',
              productParamName: pName,
            }).slice(1, -1) //BTL-3535 Remove { } brackets to allow filtering of more complicated identifiers
        )
        .map(filterObj => [filterObj]);
      filter.filtering.push({
        column: 'resourceIdentificationQuery',
        compareType: CompareTypeDtoEnum.LIKE,
        value: filterValue,
      });
    } else {
      filter.filtering.push({
        column: 'resourceIdentification',
        compareType: CompareTypeDtoEnum.LIKE,
        value: JSON.stringify({
          productCode: productCode ? productCode : '',
        }).slice(1, -1), //BTL-3535 Remove { } brackets to allow filtering of more complicated identifiers
      });
    }
    return this.adminAclService.filterRules(filter, null);
  }

  public getTechnicalCategoryById(categoryId: string, allCategories: TechnicalCategoryDto[]): TechnicalCategoryDto {
    let retCategory = null;
    allCategories.forEach(category => {
      if (!retCategory && category.id === categoryId) {
        retCategory = category;
      }
    });

    return retCategory;
  }

  public duplicate(duplicateOptions?, duplicateProductCode?, duplicateSeoUrl?, productId?: string) {
    if (duplicateOptions) {
      this.duplicateOptions = duplicateOptions;
    }
    if (duplicateProductCode) {
      this.duplicateProductCode = duplicateProductCode;
    }
    if (duplicateSeoUrl) {
      this.duplicateSeoUrl = duplicateSeoUrl;
    }
    if (productId) {
      this.duplicateProductId = productId;
    }

    this.adminProductService.getProductById(this.getEditProduct().id).subscribe(result => {
      this.setProductToEdit(this.duplicateProduct(result, null));
      this.originalCategory = null;
    });
  }

  public getProductHandler = (productDto: ProductDto): void => {
    this.bundleComponents = null;
    this.documentBulkOperations = null;
    this.pictureBulkOperations = null;
    if (this.savingProductId) {
      this.productDto.id = this.savingProductId;
      this.savingProductId = null;
    }

    if (productDto) {
      if (productDto.id === this.productDto.id) {
        this.setProductToEdit(productDto);
        if (this.productPriceDto) {
          const priceId = this.productPriceDto.id;
          this.productPriceDto = null;
          if (priceId && this.productDto && this.productDto.prices) {
            this.productDto.prices.forEach(price => {
              if (price && price.id === priceId) {
                this.productPriceDto = price;
              }
            });
          }
        }
        if (!this.productPriceDto && this.productEditMenu === 'prices') {
          this.router.navigate(['pc/products', productDto.id, this.productEditMenu]);
        }
      } else {
        if (!this.productEditMenu) {
          this.setProductEditMenu('attributes');
        }
        this.router.navigate(['pc/products', productDto.id, this.productEditMenu]);
      }
      this.resetMode = false;
    }
    this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
  };

  getNewProductPrice(): ProductPriceDto {
    return {
      id: this.generateNewProductPriceId(),
      code: null,
      priceType: null,
      priceAppType: null,
      price: 0.0,
      priority: 0,
      validFor: {
        startDateTime: new Date(),
        endDateTime: null,
      },
      tax: null,
      ruleSetExpression: null,
      priceParams: {},
    };
  }

  public isProductMultiActive(product) {
    if (product.versioningType === VersioningTypeEnum.MULTI_ACTIVE) {
      return true;
    }
    return false;
  }

  /**
   * OPne new tab with product preview. If product has no version hash, then the hash is generated first.
   * @param previewUrl
   * @param product
   */
  public preview(previewUrl: string, product?: ProductDto) {
    if (!product) {
      product = this.getEditProduct();
    }
    if (!product.verHash) {
      this.appBlockerService.block();
      this.savingProductId = this.productDto.id;
      this.adminProductService
        .generateProductVersionHash(this.productDto.id)
        .pipe(
          tap(productDto => {
            this.openPreviewTab(productDto, previewUrl);
          }),
          finalize(this.appBlockerService.unblock)
        )
        .subscribe(this.getProductHandler);
    } else {
      this.openPreviewTab(product, previewUrl);
    }
  }

  private openPreviewTab(productDto, previewUrl: string) {
    if (window && productDto && previewUrl) {
      const productPreviewUrl = previewUrl
        .replace('{seoUrl}', productDto.seoUrl)
        .replace('{verHash}', productDto.verHash);
      window.open(productPreviewUrl, '_blank');
    }
  }

  public getNewProduct(): ProductDto {
    return {
      id: 'newProduct',
      versioningType: VersioningTypeEnum.SINGLE_ACTIVE,
      productCode: null,
      servicePartner: 'INTERNAL',
      category: null,
      seoUrl: null,
      main: false,
      bundle: false,
      availableFor: {
        startDateTime: new Date(),
        endDateTime: null,
      },
      masterAvailableFor: {
        startDateTime: new Date(),
        endDateTime: null,
      },
      visible: false,
      orderable: false,
      planningEnabled: false,
      multipleOrderEnabled: false,
      priority: 0,
      syncMode: 'doNotSynchronize',
      professionalRating: null,
      popularityCalculationEnabled: false,
      popularity: null,
      description: null,
      segments: [],
      productParameters: {},
      localizedProductParameters: {},
      prices: [],
      //ruleSets: [],
      texts: [],
      agreements: [],
      stickers: [],
      reviews: [],
      businessCategories: [],
      pictures: [],
    };
  }

  canEdit() {
    if (!this.aclService.pageEditAccess) {
      return false;
    }
    return true;
  }
}

export enum TextType {
  PRODUCT_NAME = 'PRODUCT_NAME',
}

export enum VersioningTypeEnum {
  SINGLE_ACTIVE = 'SINGLE_ACTIVE',
  MULTI_ACTIVE = 'MULTI_ACTIVE',
}

export enum ProductActiveState {
  PILOT = 'PILOT',
  LAUNCHED = 'LAUNCHED',
  RETIRED = 'RETIRED',
}
