import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrentLocaleService } from '@btl/btl-fe-wc-common';
import { ProductEditService } from '@service/product-edit.service';
import { GroupProductActionDto } from '@btl/admin-bff';
import ActionDtoEnum = GroupProductActionDto.ActionDtoEnum;

@Component({
  selector: 'app-product-edit-group-list-item, [app-product-edit-group-list-item]',
  templateUrl: './product-edit-groups-item.component.html',
  styleUrls: ['./product-edit-groups-item.component.scss'],
})
export class ProductEditGroupsItemComponent implements OnInit {
  @Output()
  readonly seqUpdated: EventEmitter<GroupProductActionDto> = new EventEmitter<GroupProductActionDto>();

  @Input()
  group;

  @Input()
  disabled = false;

  @Input()
  parentId = '';

  selected = false;

  seq;

  constructor(private currentLocaleService: CurrentLocaleService, private productEditService: ProductEditService) {}

  ngOnInit() {
    this.seq = this.getProductSeqInGroup();
  }

  getProductSeqInGroup() {
    let seq = 0;
    this.group.groupProducts.forEach(groupProduct => {
      if (groupProduct.productMasterId === this.productEditService.getEditProduct().productCode) {
        seq = groupProduct.seq;
      }
    });
    return seq;
  }

  seqChanged($event) {
    this.seq = $event;
    this.seqUpdated.emit({
      action: ActionDtoEnum.UPDATE,
      seq: this.seq,
      groupId: this.group.id,
      groupRecordVersion: this.group.recordVersion,
    });
  }
}
