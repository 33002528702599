import { Component, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminLocalizedTextService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { LocalizedTextDto } from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalizedTextComponent } from '../localized-text.component';
import { environment } from '@environments/environment';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { Animations } from '@helpers/animations';
import { EnumerationsService } from '@service/enumerations.service';
import { LocalizedTextListComponent } from '@components/localized-text/list/localized-text-list.component';

@Component({
  selector: 'app-localized-text-edit',
  templateUrl: './localized-text-edit.component.html',
  styleUrls: ['./localized-text-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: LocalizedTextEditComponent.PAGE_ID })
export class LocalizedTextEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'LocalizedTextEditComponent';

  pageId(): string {
    return LocalizedTextEditComponent.PAGE_ID;
  }

  routerOutlet: any;

  editLocalizedTextChanged = new EventEmitter<LocalizedTextDto>();

  private localizedTextDto: LocalizedTextDto = {};

  localizedTextId: number;

  modules: string[] = [];

  locales = environment.localization.locales;
  duplicateMode = false;

  localizedTextForm: FormGroup = this.formBuilder.group({
    id: [null],
    module: [null, [Validators.required, Validators.maxLength(100)]],
    locale: [null, [Validators.required, Validators.maxLength(5)]],
    key: [null, [Validators.required, Validators.maxLength(200)]],
    defaultValue: [{value: '', disabled: true}],
    customValue: [],
    recordVersion: [null],
    // created: [],
    // modified: [],
    // createdBy: [],
    // modifiedBy: []
  });

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private adminLocalizedTextService: AdminLocalizedTextService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    private enumerationsService: EnumerationsService,
    public aclService: AclService
  ) {
    super(router, route);
    this.enumerationsService
      .getLocalizedTextModules()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.modules = result;
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const localizedTextId = this.params.id;
      if (
        localizedTextId &&
        localizedTextId !== '&' &&
        (!this.localizedTextDto || this.localizedTextDto.id !== +localizedTextId)
      ) {
        this.adminLocalizedTextService
          .getLocalizedTextById(+localizedTextId)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.setLocalizedText(result);

            this.localizedTextId = this.localizedTextDto.id;
          });
      } else {
        this.setLocalizedText(this.localizedTextDto);
      }
    } else {
      this.localizedTextId = undefined;
      this.localizedTextDto = undefined;
    }
  }

  setLocalizedText(localizedText: LocalizedTextDto) {
    this.localizedTextDto = localizedText;
    this.localizedTextForm.patchValue(this.localizedTextDto);
    if (this.duplicateMode) {
      this.duplicateMode = false;
      this.setLocalizedText(LocalizedTextComponent.duplicateLocalizedText(this.localizedTextDto));
    }
    if (localizedText) {
      this.editLocalizedTextChanged.emit(this.localizedTextDto);
    }
    if (this.localizedTextDto.recordVersion) {
      this.localizedTextForm.controls['module'].disable();
      this.localizedTextForm.controls['key'].disable();
      this.localizedTextForm.controls['locale'].disable();
    } else {
      this.localizedTextForm.controls['module'].enable();
      this.localizedTextForm.controls['key'].enable();
      this.localizedTextForm.controls['locale'].enable();
    }
  }

  saveLocalizedText(): boolean {
    FormUtils.validateAllFormFields(this.localizedTextForm);
    if (this.localizedTextForm.valid) {
      const localizedText = this.localizedTextDto;

      if (localizedText) {
        Object.keys(this.localizedTextForm.controls).forEach(field => {
          const control = this.localizedTextForm.get(field);
          localizedText[field] = control.value;
        });
        if (!localizedText.defaultValue) {
          localizedText.defaultValue = '';
        }
        if (localizedText.customValue && localizedText.customValue.trim() === '') {
          localizedText.customValue = null;
        }
      }
      return true;
    }
    return false;
  }

  save() {
    if (this.saveLocalizedText()) {
      this.appBlockerService.block();
      if (!(this.localizedTextId === null || this.localizedTextId === undefined)) {
        this.adminLocalizedTextService
          .updateLocalizedText(this.localizedTextId, this.localizedTextDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getLocalizedTextHandler);
      } else {
        this.adminLocalizedTextService
          .createLocalizedText(this.localizedTextDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getLocalizedTextHandler);
      }
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditLocalizedText(): LocalizedTextDto {
    return this.localizedTextDto;
  }

  public reset() {
    this.appBlockerService.block();
    this.adminLocalizedTextService
      .getLocalizedTextById(this.localizedTextDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(this.getLocalizedTextHandler);
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.localizedtexts.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminLocalizedTextService
        .deleteLocalizedText(this.localizedTextDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(LocalizedTextListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  public duplicate() {
    this.localizedTextId = null;
    this.duplicateMode = true;
    this.navigateSelf({ id: '&' });
  }

  public getLocalizedTextHandler = (localizedTextDto: LocalizedTextDto): void => {
    if (localizedTextDto && this.localizedTextDto) {
      if (localizedTextDto.id === this.localizedTextDto.id) {
        this.localizedTextDto = localizedTextDto;
        this.setLocalizedText(localizedTextDto);
        this.localizedTextId = localizedTextDto.id;
        this.navigateSelf({ id: localizedTextDto.id });
      } else {
        this.localizedTextId = localizedTextDto.id;
        this.navigateSelf({ id: localizedTextDto.id });
      }
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  cancel() {
    this.navigateSibling(LocalizedTextListComponent.PAGE_ID);
  }
}
