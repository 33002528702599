<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.stickers' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="001_product_stickers"
            color="primary"
            mat-raised-button
            (click)="addSticker()"
            [routerLink]=""
            class="btn -primary -xs edit icon stroke"
            [disabled]="!this.productEditService.enableEditProduct">
            <mat-icon svgIcon="plus"></mat-icon>{{ 'wc.common.add' | translate }}
          </button>
          <button
            id="002_product_stickers"
            color="primary"
            mat-stroked-button
            (click)="deleteStickers()"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor"
            [disabled]="!this.productEditService.enableEditProduct">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="['code', 'texts.message', 'priority', 'type', 'grp', 'created', 'modified', 'modifiedBy']"
          [fillIdBy]="'id'"
          [suffix]="'-chosen'"
          [customData]="stickers"
          [enablePaginator]="false"
          (deleteButtonEvent)="deleteSticker($event)"
          [disableDeleteButton]="!this.productEditService.enableEditProduct"
          [disableActions]="true"
          [translationPrefix]="'wc.admin.sticker'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
