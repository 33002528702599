import { Component } from '@angular/core';
import {
  AclService,
  AdminStickerService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { PagedStickersDto, StickerDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { StickerEditComponent } from '@components/product-catalogue/stickers/edit/sticker-edit.component';

import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sticker-list',
  templateUrl: './sticker-list.component.html',
  styleUrls: ['./sticker-list.component.scss'],
})
@EnableDynamicLoading({ customName: StickerListComponent.PAGE_ID })
export class StickerListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'StickerListComponent';

  pageId(): string {
    return StickerListComponent.PAGE_ID;
  }

  pagedStickersDto: PagedStickersDto;

  constructor(
    private adminStickerService: AdminStickerService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  editSticker(stickerDto: StickerDto, inNewTab: boolean = false) {
    this.navigateSibling(StickerEditComponent.PAGE_ID, { id: stickerDto.id }, inNewTab);
  }

  newSticker() {
    this.editSticker({
      id: '&',
      type: null,
      grp: null,
      priority: null,
      texts: [],
      pictures: [],

      recordVersion: null,
      created: null,
      modified: null,
      createdBy: null,
      modifiedBy: null,
    });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminStickerService.getStickersByFilter(this.search).subscribe(result => {
        if (result) {
          this.pagedStickersDto = result;
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminStickerService.deleteStickers(bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminStickerService.deleteSticker(rowDataId);
  }
}
