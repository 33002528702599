<form class="form" [formGroup]="filterForm">
  <mat-expansion-panel disabled hideToggle [expanded]="isAdvancedFilterOpen">
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-input-select-form-field
            id="001_ticket_filter"
            [formControlName]="'area'"
            [options]="ticketAreaTypes"
            [labelOption]="'name'"
            [valueOption]="'name'"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.ticket'"
            (keydown.enter)="handleChange()"
            (selectionChangeEmit)="areaChanged($event)" />
          <app-input-select-form-field
            id="002_ticket_filter"
            [formControlName]="'type'"
            [showEmptyOption]="true"
            [options]="filteredByAreaTicketTypes"
            [translationPrefix]="'wc.admin.ticket'"
            (selectionChangeEmit)="typeChanged($event)"
            (keydown.enter)="handleChange()" />
          <app-input-select-form-field
            id="003_ticket_filter"
            [formControlName]="'assignmentState'"
            [options]="assignmentStates"
            [labelOption]="'name'"
            [valueOption]="'name'"
            [translationPrefix]="'wc.admin.ticket'"
            (keydown.enter)="handleChange()" />
          <app-input-select-form-field
            id="004_ticket_filter"
            [formControlName]="'businessState'"
            [options]="businessStates"
            [labelOption]="'name'"
            [valueOption]="'name'"
            [translationPrefix]="'wc.admin.ticket'"
            (keydown.enter)="handleChange()" />
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="clearFilter()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-multi-input
          [controlName]="'assignedTo'"
          [label]="'assignee'"
          id="007_ticket_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.ticket'"
          [parentGroup]="filterForm">
        </app-multi-input>
        <app-custom-input
          [controlName]="'owner'"
          [inputType]="'text'"
          [translationPrefix]="'wc.admin.ticket'"
          id="008_ticket_filter"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'ticketRefNo'"
          [inputType]="'text'"
          [translationPrefix]="'wc.admin.ticket'"
          id="009_ticket_filter"
          (keydown.enter)="handleChange()"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-input-date-form-field
          id="010_ticket_filter"
          [parentGroup]="filterForm"
          [translationPrefix]="'wc.admin.ticket'"
          [controlName]="'createdFrom'"
          (keydown.enter)="handleChange()" />
        <app-input-date-form-field
          id="011_ticket_filter"
          [parentGroup]="filterForm"
          [translationPrefix]="'wc.admin.ticket'"
          [controlName]="'createdTo'"
          (keydown.enter)="handleChange()" />
        <app-input-date-form-field
          id="012_ticket_filter"
          [parentGroup]="filterForm"
          [translationPrefix]="'wc.admin.ticket'"
          [controlName]="'followUpDateFrom'"
          (keydown.enter)="handleChange()" />
        <app-input-date-form-field
          id="013_ticket_filter"
          [parentGroup]="filterForm"
          [translationPrefix]="'wc.admin.ticket'"
          [controlName]="'followUpDateTo'"
          (keydown.enter)="handleChange()" />
        <app-input-select-form-field
          id="014_ticket_filter"
          [formControlName]="'entityType'"
          [options]="externalEntityTypes"
          [labelOption]="'name'"
          [valueOption]="'name'"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.ticket'">
        </app-input-select-form-field>
        <app-custom-input
          [controlName]="'entity'"
          [inputType]="'text'"
          [translationPrefix]="'wc.admin.ticket'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'priority'"
          [inputType]="'number'"
          [translationPrefix]="'wc.admin.ticket'"
          [parentGroup]="filterForm">
        </app-custom-input>
      </div>
    </div>
  </mat-expansion-panel>
</form>
