<div *blockUI="data.headerLocalizationKey; template: blockTemplate">
  <h2 mat-dialog-title>{{ data.headerLocalizationKey | translate: data.localizationParameters }}</h2>
  <mat-dialog-content class="mat-typography">
    <mat-label *ngFor="let localizationKey of data.localizationKeys">
      {{ localizationKey | translate: data.localizationParameters }}
    </mat-label>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      color="primary"
      (click)="declineHandler ? declineHandler() : null; dialogReference.close()"
      mat-raised-button>
      {{ 'wc.common.no.button' | translate }}
    </button>
    <button color="primary" mat-raised-button (click)="confirmationHandler()" cdkFocusInitial>
      {{ 'wc.common.yes.button' | translate }}
    </button>
  </mat-dialog-actions>
</div>
