import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ProductsSelectModalComponent } from '@components/products-select-modal/products-select-modal.component';
import {
  AbstractPageComponent,
  AclService,
  AdminProductBundleService,
  AppBlockerService,
  EnableDynamicLoading
} from '@btl/btl-fe-wc-common';
import { ProductDto } from '@btl/admin-bff';
import { ProductEditService } from '@service/product-edit.service';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { TableListingFormComponent } from '@components/table-listing-form/table-listing-form.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-product-bundels',
  templateUrl: './product-bundles.component.html',
  styleUrls: ['./product-bundles.component.scss'],
})
@EnableDynamicLoading({ customName: ProductBundlesComponent.PAGE_ID })
export class ProductBundlesComponent extends AbstractPageComponent implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductBundlesComponent';

  pageId(): string {
    return ProductBundlesComponent.PAGE_ID;
  }

  @Input()
  productBundles: Array<ProductDto>;

  @ViewChildren(TableListingFormComponent)
  tableListingFormComponents: QueryList<TableListingFormComponent>;

  product: ProductDto;

  constructor(
    private dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    private adminProductBundleService: AdminProductBundleService,
    private productEditService: ProductEditService,
    private appBlockerService: AppBlockerService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService
  ) {
    super(router, route);
    this.product = this.productEditService.getEditProduct();
    if (this.product) {
      this.loadProductBundles();
    }
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit() {
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      this.product = result;
      if (this.product) {
        this.loadProductBundles();
      }
    });
  }

  loadProductBundles() {
    this.productBundles = [];

    if (this.productEditService.originalBundle && !this.productEditService.bundleComponents) {
      this.appBlockerService.block();
      this.adminProductBundleService
        .getProductBundleComponents(this.product.id, [
          {
            column: 'productCode',
            sortOrder: 'asc',
          },
        ])
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          result.forEach(product => {
            if (product) {
              this.productBundles.push(product);
              this.productBundles = [...this.productBundles];
            }
          });
        });
    } else {
      if (this.productEditService.bundleComponents) {
        this.productBundles = this.productEditService.bundleComponents;
      }
    }
  }

  deleteCheckedProduct() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.productbundle.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.getSelectedProductBundles().forEach(selectedProductBundle => {
        this.productBundles = this.productBundles.filter(productBundle => productBundle !== selectedProductBundle);
      });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  getSelectedProductBundles(): Array<string> {
    let selectedProductBundles = [];
    this.tableListingFormComponents?.forEach(tableListingFormComponent => {
      if (tableListingFormComponent.selection.hasValue()) {
        selectedProductBundles = tableListingFormComponent.selection.selected;
      }
    });
    return selectedProductBundles;
  }

  saveData(): boolean {
    if (!this.productEditService.canEdit()) {
      return true;
    }

    const productBundles = [];
    let canSave = true;

    this.productBundles.forEach((product: ProductDto) => {
      if (product.productCode) {
        productBundles.push(product);
      } else {
        canSave = false;
      }
    });

    if (!canSave) {
      return false;
    } else {
      this.productEditService.bundleComponents = this.productBundles;
      return true;
    }
  }

  addProduct() {
    const modalRef = this.dialog.open(ProductsSelectModalComponent);
    const productsSelectModalComponent = modalRef.componentInstance;
    productsSelectModalComponent.dialogRef = modalRef;
    productsSelectModalComponent.isModal = true;
    productsSelectModalComponent.disableActions = true;
    productsSelectModalComponent.disableListingActions = true;
    productsSelectModalComponent.disableExpand = true;
    productsSelectModalComponent.excludeProductCodes.push(this.product.productCode);
    productsSelectModalComponent.selectHandler = products => {
      products.forEach(product => {
        if (this.getBundleProductComponent(product.productCode) === null) {
          this.productBundles.push(product);
          this.productBundles = [...this.productBundles];
        }
      });
    };
  }

  getBundleProductComponent(productMasterId: string) {
    let retProductBundleItem = null;
    this.tableListingFormComponents.find(tableListingForm =>
      tableListingForm.customData.forEach(product => {
        if (productMasterId === product.productCode) {
          retProductBundleItem = product;
        }
      })
    );
    return retProductBundleItem;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.productEditService.resetMode) {
      if (this.saveData()) {
        return true;
      } else {
        return false;
      }
    }

    this.productEditService.resetMode = false;

    return true;
  }
}
