import { trigger, state, style, transition, animate } from '@angular/animations';

export class Animations {
  public static dropDownArrow = trigger('indicatorRotate', [
    state('collapsed', style({ transform: 'rotate(0deg) scale(0.4)' })),
    state('expanded', style({ transform: 'rotate(180deg) scale(0.4)' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
  ]);

  public static advancedFilterArrow = trigger('indicatorRotate', [
    state('close', style({ transform: 'rotate(0deg)' })),
    state('open', style({ transform: 'rotate(180deg)', margin: '10px 0 0' })),
    transition('open <=> close', animate('0ms cubic-bezier(0.4,0.0,0.2,1)')),
  ]);

  public static tableDetailExpandArrow = trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]);

  public static menuExpandArrow = trigger('indicatorRotate', [
    state('expanded', style({ transform: 'rotate(0deg)' })),
    state('collapsed', style({ transform: 'rotate(180deg)' })),
    transition('expanded <=> collapsed', animate('0ms cubic-bezier(0.4,0.0,0.2,1)')),
  ]);

  public static leftMenuExpandArrow = trigger('indicatorRotate', [
    state('collapsed', style({ transform: 'rotate(0deg)' })),
    state('expanded', style({ transform: 'rotate(180deg)', padding: '0 0 10px' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
  ]);
}
