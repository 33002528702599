import { Component, Input, ViewChild } from '@angular/core';
import { Search } from '@btl/btl-fe-wc-common';
import {
  ProductGroupListingComponent,
} from '@components/product-catalogue/product-group/product-group-listing/product-group-listing.component';

@Component({
  selector: 'app-groups-select-modal',
  templateUrl: './groups-select-modal.component.html',
  styleUrls: ['./groups-select-modal.component.scss'],
})
export class GroupsSelectModalComponent {
  @ViewChild(ProductGroupListingComponent, {static: true})
  productGroupListingComponent: ProductGroupListingComponent;

  @Input() dialogRef;

  @Input()
  selectHandler;

  @Input()
  disableListingActions: boolean = false;

  @Input()
  selectMode: boolean = false;

  @Input()
  disableActions: boolean = false;

  multiSelectMode: boolean = false;

  singleSelectMode: boolean = true;

  activeMultiSelectMode() {
    this.multiSelectMode = true;
    this.singleSelectMode = false;
  }

  selectEvent() {
    if (this.singleSelectMode) {
      const groupDto = this.productGroupListingComponent.getSelectedData()[0];
      if (groupDto) {
        this.selectHandler(groupDto);
        this.dialogRef.close();
      }
    } else {
      const groupDtos = this.productGroupListingComponent.getSelectedData();
      if (groupDtos && groupDtos.length > 0) {
        this.selectHandler(groupDtos);
        this.dialogRef.close();
      }
    }
  }

  singleSelect(selectedDto) {
    this.selectHandler(selectedDto);
    this.dialogRef.close();
  }

  getSearch(): Search {
    return this.productGroupListingComponent.search;
  }

  removeSelection() {
    if (this.singleSelectMode) {
      this.selectHandler(null);
    } else {
      this.selectHandler([]);
    }
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
