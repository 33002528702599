<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle style="height: 100%">
    <mat-expansion-panel-header style="height: 100%">
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'id'"
            [inputType]="'text'"
            id="001_documentTemplates_filter"
            [translationPrefix]="'wc.admin.documentTemplate'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'description'"
            id="003_documentTemplates_filter"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.documentTemplate'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            id="009_documentTemplates_filter"
            *ngIf="documentTypes"
            [translationPrefix]="'wc.admin.documentTemplate'"
            [options]="documentTypes"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [multipleSelect]="true"
            [formControlName]="'documentType'">
          </app-input-select-form-field>

          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group"></div>
    </div>
  </mat-expansion-panel>
</form>
