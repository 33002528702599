import { Component } from '@angular/core';
import { EnumEntriesDto, PagedRulesDto, PrivilegeDto } from '@btl/admin-bff';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminAclService,
  AdminDynamicEnumService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { EnumerationsService } from '@service/enumerations.service';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { Animations } from '@helpers/animations';
import { PrivilegesListingComponent } from '@components/acl/privileges/privileges-listing/privileges-listing.component';

@Component({
  selector: 'app-privilege-edit',
  templateUrl: './privilege-edit.component.html',
  styleUrls: ['./privilege-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: PrivilegeEditComponent.PAGE_ID })
export class PrivilegeEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'PrivilegeEditComponent';

  collapsedUsedByRoles = false;

  pageId(): string {
    return PrivilegeEditComponent.PAGE_ID;
  }

  savingPrivilegeId;
  privilege: PrivilegeDto;
  resourceTypes: EnumEntriesDto;

  form: FormGroup = this.formBuilder.group({
    id: [null, Validators.required],
    description: [null, Validators.required],
    resourceType: [null, Validators.required],
    recordVersion: [null],
    parameters: [],
  });

  pagedRulesDto: PagedRulesDto;
  ruleSearch: Search = {
    filtering: [],
    sorting: [
      { column: 'roleType', sortOrder: 'desc' },
      { column: 'resourceIdentification', sortOrder: 'desc' },
    ],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private adminAclService: AdminAclService,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private stickyMessageService: StickyMessageService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    public aclService: AclService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super(router, route);

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.ACL, 'com.emeldi.ecc.be.acl.enums.ResourceType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.resourceTypes = result;
        this.resourceTypes.data = this.resourceTypes.data.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const privilegeId = this.params.id;
      if (privilegeId && privilegeId !== 'newPrivilege') {
        this.loadPrivilege(privilegeId);
      } else {
        this.privilege = { id: null, resourceType: null };
      }
    } else {
      this.privilege = undefined;
    }
  }

  loadPrivilege(privilegeId) {
    this.adminAclService
      .getPrivilege(privilegeId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadPrivilege(result);
      });
  }

  reloadPrivilege(privilegeDto: PrivilegeDto) {
    this.privilege = privilegeDto;
    this.form.reset(this.privilege);

    if (this.privilege.id) {
      this.ruleSearch.filtering.length = 0;
      this.ruleSearch.filtering.push({ column: 'privilege.id', compareType: 'EQUAL', value: this.privilege.id });
      this.loadRules(this.ruleSearch);
    }
  }

  pageSizeChanged(page): void {
    this.ruleSearch.paging.page = page.pageIndex + 1;
    this.ruleSearch.paging.pageSize = page.pageSize;
    this.loadRules(this.ruleSearch);
  }

  public loadRules(search: Search) {
    this.appBlockerService.block();

    this.adminAclService
      .filterRules(search, null)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.pagedRulesDto = result;
      });
  }

  public getPrivilegeHandler = (privilegeDto: PrivilegeDto): void => {
    if (privilegeDto) {
      if (privilegeDto.id === this.savingPrivilegeId) {
        this.reloadPrivilege(privilegeDto);
      } else {
        this.reloadPrivilege(privilegeDto);
        this.navigateSelf({ id: privilegeDto.id });
      }
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  reset() {
    if (this.privilege && this.privilege.recordVersion) {
      this.loadPrivilege(this.privilege.id);
    } else {
      this.reloadPrivilege(this.privilege);
    }
  }

  delete() {
    if (this.pagedRulesDto?.data.length > 0) {
      const confirmationDialogComponent = this.confirmationDialogService.openDialog([
        'wc.admin.privileges.deleteUsed.confirmation.text',
      ]);
      confirmationDialogComponent.confirmationHandler = dialogReference => {
        this.deletePrivilege();
        confirmationDialogComponent.dialogReference.close();
      };
    } else {
      this.deletePrivilege();
    }
  }

  deletePrivilege() {
    this.appBlockerService.block();
    this.adminAclService
      .deletePrivilege(this.privilege.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.navigateSibling(PrivilegesListingComponent.PAGE_ID);
        this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
      });
  }

  duplicate() {
    this.form.controls.id.patchValue(`${this.privilege.id}copy`);
    this.privilege.recordVersion = null;
    this.form.controls.id.enable();
  }

  save() {
    this.savingPrivilegeId = this.privilege.id;
    if (this.savePrivilegeAttributes()) {
      this.appBlockerService.block();
      if (this.privilege.recordVersion) {
        this.adminAclService
          .updatePrivilege(this.savingPrivilegeId, this.privilege)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getPrivilegeHandler);
      } else {
        this.savingPrivilegeId = null;
        this.adminAclService
          .createPrivilege(this.privilege)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getPrivilegeHandler);
      }
    }
  }

  savePrivilegeAttributes(): boolean {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      if (this.privilege) {
        this.privilege = this.form.getRawValue();
      }
      return true;
    }

    return false;
  }

  clearField(field: string) {
    this.form.controls[field].patchValue(null);
  }

  cancel() {
    this.navigateSibling(PrivilegesListingComponent.PAGE_ID);
  }
}
