<div
  #frameElement
  class="pan-zoom-frame">

  <div
    #panElement
    class="pan-element">

    <div
      #zoomElement
      class="zoom-element">

      <ng-content></ng-content>

    </div>

  </div>

</div>

<div
  #panzoomOverlay
  class="pan-zoom-overlay">
</div>
