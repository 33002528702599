import { Component } from '@angular/core';

@Component({
  selector: 'app-block-ui',
  template:
    '<div class="wrapper">\n' +
    '  <div class="loading-spinner">\n' +
    '    <mat-progress-spinner color="primary" mode="indeterminate">\n' +
    '    </mat-progress-spinner>\n' +
    '    <div></div>\n' +
    '    <div></div>\n' +
    '    <div></div>\n' +
    '    <div></div>\n' +
    '  </div>\n' +
    '</div>\n',
})
export class BlockUiComponent {}
