import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Filter, Sort } from '@btl/btl-fe-wc-common/lib/models/search';
import {
  AbstractFilterComponent,
  AdminAclService,
  AppBlockerService,
  ModulePreferencesService,
  PaginationComponent,
  Search,
  ServiceUtils,
} from '@btl/btl-fe-wc-common';
import { PagedPrivilegesDto, PagedRulesDto, RuleDto } from '@btl/admin-bff';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {
  RulesListingItemComponent,
} from '@components/acl/roles/role-edit/rules-listing/rules-listing-item/rules-listing-item.component';


@Component({
  selector: 'app-rules-listing, [app-rules-listing]',
  templateUrl: './rules-listing.component.html',
  styleUrls: ['./rules-listing.component.scss'],
})
export class RulesListingComponent implements OnInit, OnDestroy, OnChanges {
  public static readonly OPERATION_TYPE = 'operationType';
  private static readonly MODULE_ID = 'rulesListingComponent';

  @Input()
  parentId;

  @Input()
  cacheFilter = true;

  @Input()
  multiSelectMode = false;

  @Input()
  singleSelectMode = false;

  @Input()
  disableActions: boolean = false;

  @Input()
  disableListingActions: boolean = false;

  @Output()
  readonly singleSelectEvent: EventEmitter<RuleDto> = new EventEmitter<RuleDto>();

  @Input()
  constantFilter: Array<Filter> = [];

  @Input()
  constantSort: Array<Sort> = [];

  @Input()
  additionalHeaderLtKey;

  @Input()
  role;

  @Input()
  changesMode = false;

  @Input()
  hideFilter = false;

  @Input()
  operations;

  @ViewChild(PaginationComponent, { static: true })
  paginationComponent: PaginationComponent;

  rulesListingComponent: RulesListingComponent;

  @ViewChild(RulesListingComponent, { static: false }) set content(content: RulesListingComponent) {
    if (content) {
      this.rulesListingComponent = content;
    }
  }

  @ViewChildren(RulesListingItemComponent)
  rulesListingItemComponents: Array<RulesListingItemComponent>;

  baseFilterVisible = true;

  @Input()
  editable = true;

  @Output()
  readonly deleteRuleChangeEvent: EventEmitter<RuleDto> = new EventEmitter<RuleDto>();

  pagedRulesDto: PagedRulesDto;

  search: Search = {
    filtering: [],
    sorting: [{ column: 'ruleType', sortOrder: 'desc' }],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };

  ruleTypes;

  isCollapsed = false;

  editableRulesListingItemComponent;

  newRule;

  @Input()
  pagedPrivilegesDto: PagedPrivilegesDto;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private adminAclService: AdminAclService,
    private router: Router,
    private route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private modulePreferencesService: ModulePreferencesService
  ) {}

  ngOnInit() {
    if (this.role?.recordVersion) {
      if (this.cacheFilter) {
        const sessionSearch = this.modulePreferencesService.getPreferences(RulesListingComponent.MODULE_ID);
        if (sessionSearch) {
          this.search = sessionSearch;
        }
      }
    }

    if (!this.pagedPrivilegesDto) {
      this.adminAclService
        .filterPrivileges(ServiceUtils.getUnlimitedSearch(), null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          result.data.sort((a, b) => a.id.localeCompare(b.id));
          this.pagedPrivilegesDto = result;
          this.loadRules(this.search);
        });
    }
  }

  toggleFilter() {
    this.baseFilterVisible = !this.baseFilterVisible;
  }

  ngOnChanges(changes: any) {
    if (this.role) {
      if (this.role.id) {
        const roleTypeFilter = this.search.filtering.find(filter => filter.column === 'roleType');
        if (roleTypeFilter) {
          roleTypeFilter.value = this.role.id;
        } else {
          this.search.filtering.push({ column: 'roleType', value: this.role.id, compareType: 'EQUAL' });
        }
      }
      if (this.search.filtering.length > 0) {
        this.loadRules(this.search);
      }
    }
  }

  onSortChange() {
    this.loadRules(this.search);
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadRules(this.search);
  }

  goToPage(pageNo: number): void {
    this.search.paging.page = pageNo;
    this.loadRules(this.search);
  }

  onNext(): void {
    this.search.paging.page++;
    this.loadRules(this.search);
  }

  onPrev(): void {
    this.search.paging.page--;
    this.loadRules(this.search);
  }

  pageSizeChanged(page): void {
    this.search.paging.page = page.pageIndex + 1;
    this.search.paging.pageSize = page.pageSize;
    this.loadRules(this.search);
  }

  deleteRule(ruleDto: RuleDto) {
    const rule = this.operations.find(rule => rule.id === ruleDto.id);
    if (rule) {
      this.operations.splice(this.operations.indexOf(rule), 1);
      this.deleteRuleChangeEvent.emit(rule);
    } else {
      ruleDto[RulesListingComponent.OPERATION_TYPE] = OperationType.DELETE;
      this.operations.push(ruleDto);
    }
  }

  getRuleOperationTypeClass(where, id) {
    const ret = where.find(rule => rule.id === id);
    if (ret) {
      if (ret[RulesListingComponent.OPERATION_TYPE] === OperationType.DELETE) {
        return 'for_delete';
      } else if (ret[RulesListingComponent.OPERATION_TYPE] === OperationType.UPDATE) {
        return 'for_update';
      }
    }
    return '';
  }

  editRule(roleDto, inNewTab: boolean = false) {
    const urlTree = this.router.createUrlTree([roleDto.id], { relativeTo: this.route });
    if (inNewTab) {
      window.open(this.router.serializeUrl(urlTree), '_blank');
    } else {
      this.router.navigateByUrl(urlTree);
    }
  }

  deleteCheckedRules() {
    this.getSelectedRules().forEach(rule => {
      this.deleteRule(rule);
    });
  }

  getSelectedRules(): Array<RuleDto> {
    const selectedRules = [];
    this.rulesListingItemComponents.forEach(ruleListingItemComponent => {
      if (ruleListingItemComponent.selected) {
        selectedRules.push(ruleListingItemComponent.rule);
        ruleListingItemComponent.selected = false;
      }
    });
    return selectedRules;
  }

  editCheckedRule() {
    let selectedRule;
    this.rulesListingItemComponents.forEach(productListingItemComponent => {
      if (!selectedRule && productListingItemComponent.selected) {
        selectedRule = productListingItemComponent.rule;
      }
    });
    this.editRule(selectedRule);
  }

  public loadRules(search: Search) {
    this.pagedRulesDto = null;
    if (this.singleSelectMode || this.multiSelectMode || (this.role?.recordVersion && !this.changesMode)) {
      if (this.cacheFilter) {
        this.modulePreferencesService.setPreferences(RulesListingComponent.MODULE_ID, this.search);
      }

      if (!search) {
        search = this.search;
        search.sorting = this.constantSort;
      }

      this.adminAclService
        .filterRules(search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.pagedRulesDto = result;
        });
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public presentInFilter(column: String) {
    return AbstractFilterComponent.presentInFilter(this.constantFilter, column);
  }

  addRule() {
    const newRule: RuleDto = {
      id: null,
      ruleType: 'GRANT',
      roleType: this.role.id,
      privilege: {
        id: 'MODULE_ACCESS',
        resourceType: 'MODULE',
      },
      validFor: {
        startDateTime: new Date(),
        endDateTime: null,
      },
    };
    newRule[RulesListingComponent.OPERATION_TYPE] = OperationType.CREATE;
    this.operations.push(newRule);

    this.newRule = newRule;
  }

  editedRuleChanged($event: RulesListingItemComponent) {
    if (!this.singleSelectMode && !this.multiSelectMode) {
      this.newRule = null;
      if (!this.editableRulesListingItemComponent || this.editableRulesListingItemComponent.save()) {
        if (this.editableRulesListingItemComponent === $event) {
          this.editableRulesListingItemComponent = null;
        } else {
          this.editableRulesListingItemComponent = $event;
        }
      }
    }
  }

  save() {
    if (this.editableRulesListingItemComponent && !this.editableRulesListingItemComponent.save()) {
      return false;
    }

    if (
      this.rulesListingComponent &&
      this.rulesListingComponent.editableRulesListingItemComponent &&
      !this.rulesListingComponent.editableRulesListingItemComponent.save()
    ) {
      return false;
    }

    return true;
  }

  deleteRuleChange($event: RuleDto) {
    this.rulesListingItemComponents.forEach(ruleListingItemComponent => {
      if (ruleListingItemComponent.rule.id === $event.id) {
        ruleListingItemComponent.restoreBackup();
      }
    });
  }

  singleSelect(select) {
    if (this.singleSelectMode) {
      this.singleSelectEvent.emit(select);
    }
  }

  deselectOther(ruleDto: RuleDto) {
    this.rulesListingItemComponents.forEach(rulesListingItemComponent => {
      if (rulesListingItemComponent.rule.id !== ruleDto.id) {
        rulesListingItemComponent.selected = false;
      }
    });
  }
}

export enum OperationType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}
