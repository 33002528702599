import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ContentDto, ContentMasterDto, ContentVersionDto, PagedContentVersionsDto } from '@btl/admin-bff';
import { AdminContentVersionService, AppBlockerService, StickyMessageService } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { CmsContentsComponent } from '@components/cms/contents/cms-contents.component';

@Component({
  selector: '[app-cms-content-versions]',
  templateUrl: './cms-content-versions.component.html',
  styleUrls: ['./cms-content-versions.component.scss'],
})
export class CmsContentVersionsComponent implements OnInit {
  @Input()
  contentMasterForVersioning: ContentMasterDto;

  @Input()
  parentId: string = '';

  pagedContentVersionsDto: PagedContentVersionsDto;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private stickyMessageService: StickyMessageService,
    private appBlockerService: AppBlockerService,
    private adminContentVersionService: AdminContentVersionService
  ) {}

  ngOnInit(): void {
    this.loadContentVersions();
  }

  getColor(contentVersionDto: ContentVersionDto): string {
    let sum: number = 0;
    const totalItems = this.pagedContentVersionsDto.totalItems;
    if (totalItems > 1) {
      this.pagedContentVersionsDto.data.forEach(versionItem => {
        if (versionItem.state === 'APPROVED') {
          sum = sum + 1;
        }
      });
      if (contentVersionDto.state === 'APPROVED' && sum > 1) {
        return '#880088';
      } else if (
        contentVersionDto.state !== 'APPROVED' &&
        contentVersionDto.state !== 'ARCHIVED' &&
        contentVersionDto.version === this.pagedContentVersionsDto.data[0].version
      ) {
        return '#a30f02';
      } else if (contentVersionDto.state === 'ARCHIVED') {
        return '#707070';
      } else {
        return '#707070';
      }
    } else if (this.pagedContentVersionsDto.totalItems === 1) {
      if (contentVersionDto.state === 'APPROVED') {
        return '#000000';
      } else if (contentVersionDto.state === 'ARCHIVED') {
        return '#707070';
      } else {
        return '#a30f02';
      }
    }
  }

  isCandidateToShowOnGui(contentVersionDto: ContentVersionDto): boolean {
    let lastActiveVersion: number = 0;
    //let highestPriority: number = 0;
    const currentDate = new Date();
    const startTime = contentVersionDto.validFor.startDateTime;
    const endTime = contentVersionDto.validFor.endDateTime;

    this.pagedContentVersionsDto.data.forEach(versionItem => {
      if (versionItem.state === 'APPROVED') {
        if (versionItem.version > lastActiveVersion) {
          lastActiveVersion = versionItem.version;
        }
      }
    });
    if ((currentDate >= startTime && currentDate <= endTime) || endTime == null) {
      // display highest priority - product.priority == highestPriority
      if (contentVersionDto.state !== 'ARCHIVED' && contentVersionDto.version == lastActiveVersion) {
        return true;
      }
    }
    return false;
  }

  show(contentVersionId: string) {
    const navigationExtras: NavigationExtras = {
      state: {
        duplicate: false,
      },
      relativeTo: this.route,
    };
    this.router.navigate([contentVersionId], navigationExtras);
  }

  archive(contentVersionDto: ContentVersionDto) {
    const existOtherApprovedVersion =
      this.pagedContentVersionsDto.data.filter(
        (version: ContentVersionDto) => version.id !== contentVersionDto.id && version.state == 'APPROVED'
      ).length > 0;

    const redirectUrlItem = this.contentMasterForVersioning.parameters.find(param => param.name === 'redirectUrl');
    if (!existOtherApprovedVersion && (!redirectUrlItem || !redirectUrlItem.value)) {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.version.list.archive.error');
    } else {
      contentVersionDto.state = 'ARCHIVED';
      if (!existOtherApprovedVersion) {
        this.contentMasterForVersioning.state = 'INACTIVE';
      }
      this.appBlockerService.block();
      const contentDto: ContentDto = {
        contentMaster: CmsContentsComponent.cloneDeep(this.contentMasterForVersioning),
        contentVersion: CmsContentsComponent.cloneDeep(contentVersionDto),
      };

      this.adminContentVersionService
        .updateContentVersion(contentVersionDto.id, CmsContentsComponent.handleContentDtoRequest(contentDto))
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: ContentDto) => {
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          this.loadContentVersions();
        });
    }
  }

  approve(contentVersionDto: ContentVersionDto) {
    if (contentVersionDto.state !== 'DRAFT') {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.version.list.approve.error');
    } else {
      const attributes = {
        state: 'APPROVED',
      };
      this.appBlockerService.block();
      this.adminContentVersionService
        .patchContentVersion(contentVersionDto.id, attributes)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: ContentVersionDto) => {
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          this.loadContentVersions();
        });
    }
  }

  delete(contentVersionDto: ContentVersionDto) {
    if (contentVersionDto.state !== 'DRAFT') {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.version.list.delete.error');
    } else {
      this.appBlockerService.block();
      this.adminContentVersionService
        .getContentVersionsByContentMasterId(this.contentMasterForVersioning.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: PagedContentVersionsDto) => {
          if (result && result.data?.length > 1) {
            this.appBlockerService.block();
            this.adminContentVersionService
              .deleteContentVersion(contentVersionDto.id)
              .pipe(finalize(this.appBlockerService.unblock))
              .pipe(takeUntil(this.onDestroy$))
              .subscribe((result: ContentVersionDto) => {
                this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
                this.loadContentVersions();
              });
          } else {
            this.stickyMessageService.addStickyErrorMessage('wc.admin.cms.content.version.list.delete.count.error');
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private loadContentVersions() {
    this.appBlockerService.block();
    this.adminContentVersionService
      .getContentVersionsByContentMasterId(this.contentMasterForVersioning.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PagedContentVersionsDto) => {
        this.pagedContentVersionsDto = result;
      });
  }
}
