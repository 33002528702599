import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { AdminReleaseService, AppBlockerService } from '@btl/btl-fe-wc-common';
import { Search } from '@btl/btl-fe-wc-common/lib/models/search';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { ReleaseDto } from '@btl/admin-bff/model/releaseDto';
import { DatePipe } from '@angular/common';
import { ReleaseMemberBulkOperationsRequestDto } from '@btl/admin-bff';
import { ReleaseMemberDto } from '@btl/admin-bff/model/releaseMemberDto';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-releases-select-modal, [app-releases-select-modal]',
  templateUrl: './releases-select-modal.component.html',
  styleUrls: ['./releases-select-modal.component.scss'],
})
export class ReleasesSelectModalComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  dialogRef;

  @Input()
  memberType: string;

  @Input()
  memberCode: string;

  releases: ReleaseDto[];
  selected: boolean[] = [];
  originalSelected: boolean[] = [];

  releasesMembers: ReleaseMemberDto[];

  static selectReleases(dialog: MatDialog, memberType: string, memberCode: string) {
    const modalRef = dialog.open(ReleasesSelectModalComponent);
    const releasesSelectModalComponent = modalRef.componentInstance;
    releasesSelectModalComponent.dialogRef = modalRef;
    releasesSelectModalComponent.memberType = memberType;
    releasesSelectModalComponent.memberCode = memberCode;
  }

  constructor(
    private adminReleaseService: AdminReleaseService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private appBlockerService: AppBlockerService
  ) {}

  ngOnInit() {
    const search: Search = {
      filtering: [
        {
          column: 'params',
          compareType: 'EQUAL',
          value: [{ name: 'releaseDate', compareType: 'BIGGEREQ', value: this.truncateDate(new Date()) }],
        },
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: -1,
      },
    };
    const searchMemberReleases: Search = {
      filtering: [
        { column: 'code', compareType: 'EQUAL', value: this.memberCode },
        { column: 'memberType', compareType: 'EQUAL', value: this.memberType },
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: -1,
      },
    };
    this.appBlockerService.block();
    const observableReleases = this.adminReleaseService.filterReleases(search, null);
    const observableMembers = this.adminReleaseService.filterReleasesMembers(searchMemberReleases, null);
    forkJoin([observableReleases, observableMembers])
      .pipe(
        map(responses => {
          this.releases = responses[0].data.sort((a, b) =>
            a.releaseCode === b.releaseCode ? 0 : a.releaseCode > b.releaseCode ? 1 : -1
          );
          this.releasesMembers = responses[1].data;
        })
      )
      .pipe(takeUntil(this.onDestroy$))
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(value => {
        for (let i = 0; i < this.releases.length; i++) {
          const idx = this.releasesMembers.findIndex(releaseMember => releaseMember.releaseId === this.releases[i].id);
          this.originalSelected[i] = this.selected[i] = idx !== -1;
        }
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  close() {
    this.dialog.closeAll();
  }

  save() {
    if (this.releases) {
      const bulkOperationRequest: ReleaseMemberBulkOperationsRequestDto = {
        bulkCreateList: [],
        bulkDeleteList: [],
      };
      for (let i = 0; i < this.releases.length; i++) {
        if (this.selected[i] === true && this.originalSelected[i] === false) {
          const releaseMember: ReleaseMemberDto = {
            memberType: this.memberType,
            code: this.memberCode,
            releaseId: this.releases[i].id,
            state: 'N/A',
            startDateTime: new Date(),
          };
          bulkOperationRequest.bulkCreateList.push(releaseMember);
        }
        if (this.selected[i] === false && this.originalSelected[i] === true) {
          this.releasesMembers.forEach(releaseMember => {
            if (releaseMember.releaseId === this.releases[i].id) {
              bulkOperationRequest.bulkDeleteList.push({ id: releaseMember.id });
            }
          });
        }
      }
      if (bulkOperationRequest.bulkCreateList.length > 0 || bulkOperationRequest.bulkDeleteList.length > 0) {
        this.appBlockerService.block();
        this.adminReleaseService
          .modifyMembers(bulkOperationRequest)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(
            finalize(() => {
              this.appBlockerService.unblock();
              this.dialogRef.close();
            })
          )
          .subscribe(result =>
            console.debug(
              `Saved releases members - created: ${
                result.bulkCreatedList ? result.bulkCreatedList.length : null
              }; deleted: ${result.bulkDeletedList ? result.bulkDeletedList.length : null}`
            )
          );
      } else {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  formatReleaseDate(releaseDate: Date): string {
    if (!releaseDate) {
      return null;
    }
    const date = new Date(releaseDate);
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  truncateDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  }
}
