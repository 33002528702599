import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import {
  AbstractPageComponent,
  AclService,
  AdminCampaignService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { CampaignDto, PagedCampaignMembersDto } from '@btl/admin-bff';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { Chart, registerables } from 'chart.js';
import { Animations } from '@helpers/animations';
import { CampaignListComponent } from '@components/campaign/list/campaign-list.component';

@Component({
  selector: 'app-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: CampaignEditComponent.PAGE_ID })
export class CampaignEditComponent extends AbstractPageComponent implements OnInit, OnDestroy, AfterViewInit {
  public static readonly PAGE_ID = 'CampaignEditComponent';

  pageId(): string {
    return CampaignEditComponent.PAGE_ID;
  }
  PickerInputType = PickerInputType;
  routerOutlet: any;

  @ViewChild('lineCanvas', { static: true })
  lineCanvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('doughnutCanvas', { static: true })
  doughnutCanvas: ElementRef<HTMLCanvasElement>;
  lineChart: any;
  doughnutChart: any;

  campaignDto: CampaignDto = { id: '&' };
  pagedCampaignMembers: PagedCampaignMembersDto;
  defaultPagedCampaignMembers: PagedCampaignMembersDto;

  //TEMPORARY DATA;
  campaignChannels = ['WEB', 'NOTIFICATION', 'CALL'];
  campaignTypes = ['ACQUISITION', 'DEVELOPMENT', 'RETENTION'];
  campaignStates = ['ACTIVATED', 'DEACTIVATED'];
  campaignDirections = ['INBOUND', 'OUTBOUND'];
  campaignSlots = [
    'CUSTOMER_BANNER',
    'CUSTOMER_ACQUSITION',
    'CUSTOMER_ACTIVETARIFF',
    'CUSTOMER_ACCOUNT_INVOICES',
    'SUBSCRIPTION',
    'SUBSCRIPTION_CHANGETARIFF',
    'ORDER_PRODUCT',
  ];
  campaignResponseCode = ['SOLD', 'OFFERED', 'DISLIKE'];

  audienceListColumnNames = ['responseDateTime', 'responseCode', 'note', 'responseOperator'];

  campaignForm: FormGroup = this.formBuilder.group({
    id: [],
    campaignCode: [null, Validators.required],
    note: [],
    campaignType: [null, Validators.required],
    campaignDirection: [null, Validators.required],
    campaignOfferCode: [],
    state: [undefined, Validators.required],
    validFrom: [null, Validators.required],
    validTo: [],
    extId: [],
    campaignChannel: [null, Validators.required],
    campaignSlot: [],
    campaignCmsContent: [],
    campaignNotifType: [],
    campaignSkills: [],
  });

  savingCampaignId;
  operations = [];

  selectedResponseCode;
  selectedReportDate;
  selectedViewReportDate;
  viewDates = ['Last year', 'Last 6 months', 'Last month'];
  dates = ['Overall', 'wc.common.date.today', 'wc.common.date.lastWeek', 'wc.common.date.lastMonth'];

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    private formBuilder: FormBuilder,
    private adminCampaignService: AdminCampaignService,
    protected router: Router,
    protected route: ActivatedRoute,
    private stickyMessageService: StickyMessageService,
    private appBlockerService: AppBlockerService
  ) {
    super(router, route);
    Chart.register(...registerables);
  }
  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const releaseId = this.params.id;
      if (releaseId && releaseId !== '&') {
        this.loadCampaign(releaseId);
      } else {
        this.campaignDto = { id: null };
      }
    } else {
      this.campaignDto = undefined;
    }
  }
  ngAfterViewInit(): void {
    this.initCharts();
  }
  ngOnInit(): void {
    this.selectedReportDate = this.dates[0];
    this.selectedViewReportDate = this.dates[0];
  }
  loadCampaign(campaignId) {
    this.adminCampaignService
      .getCampaign(campaignId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadCampaign(result);
      });
  }
  loadMembers(campaignId) {
    this.adminCampaignService
      .filterCampaignMembers(campaignId, this.search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.pagedCampaignMembers = result;
        this.defaultPagedCampaignMembers = result;
      });
  }
  reloadCampaign(campaignDto: CampaignDto) {
    this.operations = [];
    this.campaignDto = campaignDto;
    this.campaignForm.reset(this.campaignDto);
    if (campaignDto.id) {
      this.loadMembers(campaignDto.id);
    }
  }

  setCampaign(campaignDto) {
    this.campaignDto = campaignDto;
  }

  filterByResponseCode(event) {
    this.pagedCampaignMembers = {...this.defaultPagedCampaignMembers};
    if (event.value !== null) {
      this.pagedCampaignMembers.data = this.pagedCampaignMembers.data.filter(
        member => member.responseCode === event.value
      );
    }
    this.pagedCampaignMembers = {...this.pagedCampaignMembers};
  }
  pageSizeChanged(page) {
    this.selectedResponseCode = null;
    this.search.paging.page = page.pageIndex + 1;
    this.search.paging.pageSize = page.pageSize;
    this.loadMembers(this.campaignDto.id);
  }
  saveCampaign() {
    if (this.campaignForm.controls.campaignChannel.value === 'WEB') {
      FormUtils.setValidation(this.campaignForm.controls.campaignSlot, [Validators.required]);
      FormUtils.setValidation(this.campaignForm.controls.campaignCmsContent, [Validators.required]);
      FormUtils.setValidation(this.campaignForm.controls.campaignNotifType, null);
    } else if (this.campaignForm.controls.campaignChannel.value === 'NOTIFICATION') {
      FormUtils.setValidation(this.campaignForm.controls.campaignSlot, null);
      FormUtils.setValidation(this.campaignForm.controls.campaignCmsContent, null);
      FormUtils.setValidation(this.campaignForm.controls.campaignNotifType, [Validators.required]);
    } else {
      FormUtils.setValidation(this.campaignForm.controls.campaignSlot, null);
      FormUtils.setValidation(this.campaignForm.controls.campaignCmsContent, null);
      FormUtils.setValidation(this.campaignForm.controls.campaignNotifType, null);
    }
    FormUtils.validateAllFormFields(this.campaignForm);
    if (this.campaignForm.valid) {
      if (this.campaignDto) {
        Object.keys(this.campaignForm.controls).forEach(field => {
          const control = this.campaignForm.get(field);
          this.campaignDto[field] = control.value;
        });
      }
      return true;
    }

    return false;
  }

  save() {
    if (this.saveCampaign()) {
      this.appBlockerService.block();
      this.campaignDto.created = null;
      this.campaignDto.createdBy = null;
      this.campaignDto.modified = null;
      this.campaignDto.modifiedBy = null;
      if (this.campaignDto.id) {
        this.savingCampaignId = this.campaignDto.id;
        this.adminCampaignService
          .updateCampaign(this.savingCampaignId, this.campaignDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getCampaignHandler);
      } else {
        this.savingCampaignId = null;
        this.adminCampaignService
          .createCampaign(this.campaignDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getCampaignHandler);
      }
    }
  }

  public getCampaignHandler = (campaignDto: CampaignDto): void => {
    const bulkOperationsRequestDto = {
      bulkCreateList: [],
      bulkDeleteList: [],
      bulkUpdateList: [],
    };

    this.pagedCampaignMembers?.data.forEach(campaignMember => {
      if (campaignMember.id) {
        bulkOperationsRequestDto.bulkUpdateList.push(campaignMember);
      } else {
        bulkOperationsRequestDto.bulkCreateList.push(campaignMember);
      }
    });

    bulkOperationsRequestDto.bulkCreateList.forEach(member => {
      member.id = null;
      this.removeNullValues(member);
    });
    bulkOperationsRequestDto.bulkUpdateList.forEach(member => {
      this.removeNullValues(member);
    });

    this.adminCampaignService.changeMembers(campaignDto?.id, bulkOperationsRequestDto).subscribe(() => {
      if (campaignDto) {
        if (campaignDto.id === this.savingCampaignId) {
          this.reloadCampaign(campaignDto);
        } else {
          this.reloadCampaign(campaignDto);
          this.navigateSelf({ id: campaignDto.id });
        }
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    });
  };

  removeNullValues(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  cancel() {
    this.navigateSibling(CampaignListComponent.PAGE_ID);
  }

  setAudienceListColumnNames(direction) {
    if (direction === 'OUTBOUND') {
      this.audienceListColumnNames = this.audienceListColumnNames.filter(
        col => col !== 'entityTypeId' && col !== 'entityId' && col !== 'displayCount'
      );
      this.audienceListColumnNames.unshift('contactDate', 'entityContact');
      this.audienceListColumnNames = [...this.audienceListColumnNames];
    }
    if (direction === 'INBOUND') {
      this.audienceListColumnNames = this.audienceListColumnNames.filter(
        col => col !== 'contactDate' && col !== 'entityContact'
      );
      this.audienceListColumnNames.unshift('entityTypeId', 'entityId', 'displayCount');
      this.audienceListColumnNames = [...this.audienceListColumnNames];
    }
  }

  delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.resource.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminCampaignService
        .deleteCampaign(this.campaignDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.navigateSibling(CampaignListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  reset() {
    if (this.campaignDto && this.campaignDto.id) {
      this.loadCampaign(this.campaignDto.id);
    } else {
      this.campaignForm.reset(this.campaignForm);
      this.setCampaign(this.campaignDto);
    }
  }

  duplicate() {
    this.campaignDto.campaignCode = `${this.campaignDto.campaignCode}copy`;
    this.campaignForm.controls.campaignCode.patchValue(this.campaignDto.campaignCode);
    this.campaignForm.controls.id.patchValue(null);
    this.campaignDto.id = null;
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditCampaign() {
    return this.campaignDto;
  }

  campaignChannelChanged(value) {
    if (value) {
      this.campaignForm.controls['campaignSlot'].patchValue(null);
      this.campaignForm.controls['campaignCmsContent'].patchValue(null);
      this.campaignForm.controls['campaignNotifType'].patchValue(null);
    }
  }

  initCharts() {
    this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Views',
            fill: {
              target: 'origin',
              above: 'rgba(106, 118, 196, 0.15)',
            },
            borderColor: 'rgba(106, 118, 196, 0.8)',
            yAxisID: 'A',
            data: [1500, 2300, 2100, 1600, 2100, 3000, 2400, 2700, 3200, 3000, 2800, 4000],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          A: {
            type: 'linear',
            position: 'right',
          },
        },
      },
    });

    this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
      type: 'doughnut',
      data: {
        labels: ['Did Not See Campaig Yet', 'Saw Campaign', 'Accepted Campaign', 'Dislike Campaign'],
        datasets: [
          {
            data: [426321, 209651, 32508, 8021],
            backgroundColor: [
              'rgba(252, 172, 18, 1)',
              'rgba(0, 116, 232, 1)',
              'rgba(40, 167, 69, 1)',
              'rgba(253, 60, 74, 1)',
            ],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
}
