import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AclService,
  AdminProductRelationshipService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  PageModuleService,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { PagedRelationshipsDto, RelationshipBulkOperationsRequestDto, RelationshipDto } from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import {
  RelationshipEditComponent
} from '@components/product-catalogue/relationships/relationship-edit/relationship-edit.component';
import {
  RelationshipsValidToModalComponent
} from '@components/product-catalogue/relationships/relationships-valid-to-modal/relationships-valid-to-modal.component';
import { MatDialog } from '@angular/material/dialog';

import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-relationships-listing',
  templateUrl: './relationships-listing.component.html',
  styleUrls: ['./relationships-listing.component.scss'],
})
@EnableDynamicLoading({customName: RelationshipsListingComponent.PAGE_ID})
export class RelationshipsListingComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'RelationshipsListingComponent';

  pageId(): string {
    return RelationshipsListingComponent.PAGE_ID;
  }

  @Input()
  parentId;

  @Input()
  disableSetValidToActions: boolean = false;

  @Input()
  sourceProductCode: string = null;

  @Input()
  targetProductCode: string = null;

  @Input()
  disabledSourceTarget = false;

  @Input()
  operations;

  @Input()
  changesMode = false;

  @Input()
  getRowClassCallback: (row: any) => string;

  @Output()
  readonly deleteChangeEvent: EventEmitter<RelationshipDto> = new EventEmitter<RelationshipDto>();

  @Input()
  replaceEditCallback: (mode: string, relationshipDto: RelationshipDto) => void;

  @Input()
  replaceDeleteCallback: (relationshipDto: RelationshipDto) => void;

  pagedRelationshipsDto: PagedRelationshipsDto;

  constructor(
    private adminRelationshipService: AdminProductRelationshipService,
    protected router: Router,
    protected route: ActivatedRoute,
    private dialog: MatDialog,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  editRelationship(relationshipDto, inNewTab: boolean = false, queryParamsValue = null) {
    if (this.replaceEditCallback) {
      this.replaceEditCallback(this.targetProductCode ? 'target' : 'source', relationshipDto);
    } else {
      const params: Params = queryParamsValue ? queryParamsValue : {};
      params.id = relationshipDto.id;
      if (queryParamsValue) {
        this.setModulePath(PageModuleService.find(RelationshipsListingComponent.PAGE_ID).path);
        this.navigateSibling(RelationshipEditComponent.PAGE_ID, params, inNewTab);
      } else {
        this.navigateSibling(RelationshipEditComponent.PAGE_ID, params, inNewTab);
      }
    }
  }

  newRelationship() {
    this.editRelationship({ id: '&' });
  }

  newRelationships() {
    const queryParams = this.sourceProductCode
      ? {
          sourceProductCode: this.sourceProductCode,
          returnUrl: this.router.url,
        }
      : this.targetProductCode
      ? { targetProductCode: this.targetProductCode, returnUrl: this.router.url }
      : null;
    this.editRelationship({ id: '&' }, false, queryParams);
  }

  /**
   * Bulk sets validTo (validFor.endDateTime) for checked relationships.
   */
  setValidToForCheckedRelationships() {
    const modalRef = this.dialog.open(RelationshipsValidToModalComponent);
    const relationshipsValidToModalComponent = modalRef.componentInstance;
    relationshipsValidToModalComponent.dialogRef = modalRef;
    relationshipsValidToModalComponent.setRelationshipsValidToEvent.subscribe(validTo => {
      this.appBlockerService.block();
      const bulkOperations: RelationshipBulkOperationsRequestDto = {
        bulkCreateList: [],
        bulkPatchList: [],
        bulkUpdateList: [],
        bulkDeleteList: [],
      };
      this.getSelectedData().forEach(relationship => {
        bulkOperations.bulkPatchList.push({
          id: relationship.id,
          recordVersion: relationship.recordVersion,
          validFor: {
            startDateTime: relationship.validFor.startDateTime,
            endDateTime: validTo ? validTo : null,
          },
        });
      });
      this.adminRelationshipService
        .changeRelationships(bulkOperations)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.loadData();
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        });
    });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminRelationshipService
        .getRelationshipsByFilter(this.search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedRelationshipsDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminRelationshipService.deleteRelationships(bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminRelationshipService.deleteRelationship(rowDataId);
  }
}
