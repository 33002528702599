import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminDynamicEnumService } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-form-parameters-edit-modal',
  templateUrl: './form-parameters-edit-modal.component.html',
  styleUrls: ['./form-parameters-edit-modal.component.scss'],
})
export class FormParametersEditModalComponent implements OnInit {
  @Input()
  dialogRef;

  @Input()
  parentGroup: FormGroup;

  @Input()
  selectHandler;

  @Input()
  parameter;

  @Input()
  paramNames: string[];

  @Output()
  readonly createParameterEvent: EventEmitter<any> = new EventEmitter<any>();

  newParameterForm: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    value: [null, Validators.required],
  });

  constructor(private formBuilder: FormBuilder, private adminDynamicEnumService: AdminDynamicEnumService) {}

  ngOnInit(): void {
    if (this.parameter) {
      this.newParameterForm.patchValue(this.parameter);
    }
  }

  save() {
    if (this.newParameterForm.valid) {
      this.selectHandler(this.newParameterForm);
      this.dialogRef.close();
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
