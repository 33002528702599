<div class="card__inner">
  <div class="card__header">
    {{ 'wc.admin.products.eligibility' | translate }}
  </div>
  <div class="card__body">
    <form [formGroup]="form" class="form">
      <div class="form__groups">
        <div class="form__group -full no-flex-wrap">
          <div class="form__row" cdkDropListGroup>
            <app-input-drag-chips-form-field
              [parentGroup]="form"
              *ngIf="contextRules"
              id="009_rule_set_edit"
              [disabled]="disabled"
              [value]="value"
              [translationPrefix]="'wc.admin.ruleSets'"
              [contextRules]="contextRules"
              [controlName]="'ruleSetExpression'">
            </app-input-drag-chips-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="help-container">
    <a class="help-link" (click)="showLegend()">{{ 'wc.common.eligibility.legend.show' | translate }}</a>
    <button class="help-button" (click)="showLegend()" mat-fab>
      <mat-icon svgIcon="help"></mat-icon>
    </button>
  </div>
</div>
