<div class="main__header">
  <h1 id="001_account_entity_edit">{{ 'wc.admin.accountEntity.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_account_entity_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="003_account_entity_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_account_entity_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="005_account_entity_edit" mat-menu-item (click)="delete()" *ngIf="!isNewAccountEntity()" [routerLink]=""
        ><mat-icon svgIcon="delete"></mat-icon>{{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="006_account_entity_edit" (click)="cancel()" class="btn cancel-button">{{
      'wc.common.cancel.button' | translate
    }}</a>
  </div>
</div>

<form *ngIf="accountEntityRoleDto" [formGroup]="form" class="form">
  <div class="card__wrapper">
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.accountEntity.account' | translate }}
        </div>
        <div class="card__body">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-input-picker-form-field
                  [parentGroup]="form.controls['account']"
                  [controlName]="'accountId'"
                  [labelField]="'login'"
                  id="007_account_entity_edit"
                  [valueField]="'id'"
                  [pickerType]="PickerInputType.ACCOUNT"
                  (selectionChanged)="accountChanged($event)"
                  (customEvent)="setAccount($event)"
                  [selectMode]="false"
                  [label]="'accountId'"
                  [translationPrefix]="'wc.admin.accountEntity'">
                </app-input-picker-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'008_account_entity_edit'"
                  [disabled]="true"
                  [label]="'wc.admin.account.lastName'"
                  [simplifiedInput]="true"
                  [enteredValue]="getAccountParamByName('lastName')">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'009_account_entity_edit'"
                  [disabled]="true"
                  [label]="'wc.admin.account.employeeId'"
                  [simplifiedInput]="true"
                  [enteredValue]="getAccountParamByName('employeeId')">
                </app-custom-input>
              </div>

              <div class="form__row">
                <app-custom-input
                  [id]="'010_account_entity_edit'"
                  [disabled]="true"
                  [label]="'wc.admin.account.state'"
                  [simplifiedInput]="true"
                  [enteredValue]="account?.state">
                </app-custom-input>
              </div>
              <div class="form__row">
                <mat-checkbox id="011_account_entity_edit" [disabled]="true" [checked]="account?.external">{{
                  'wc.admin.account.external' | translate
                }}</mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card__wrapper">
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.accountEntity.entity' | translate }}
        </div>
        <div class="card__body">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'entityType'"
                  id="012_account_entity_edit"
                  [options]="entityTypes"
                  [disableOptionsTranslate]="true"
                  [labelOption]="'name'"
                  [valueOption]="'name'"
                  [translationPrefix]="'wc.admin.accountEntity'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="form"
                  [controlName]="'entityId'"
                  id="013_account_entity_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.accountEntity'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'roleType'"
                  [options]="rolesToSelect"
                  id="014_account_entity_edit"
                  [labelOption]="'id'"
                  [valueOption]="'id'"
                  [translationPrefix]="'wc.admin.accountEntity'">
                </app-input-select-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card__wrapper">
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.accountEntity.entityRoleDetail' | translate }}
        </div>
        <div class="card__body">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-input-date-form-field
                  [parentGroup]="form"
                  [controlName]="'startDateTime'"
                  [label]="'validFrom'"
                  id="015_account_entity_edit"
                  [translationPrefix]="'wc.admin.accountEntity'">
                </app-input-date-form-field>
              </div>
              <div class="form__row">
                <app-input-date-form-field
                  [parentGroup]="form"
                  [controlName]="'endDateTime'"
                  id="016_account_entity_edit"
                  [label]="'validTo'"
                  [translationPrefix]="'wc.admin.accountEntity'">
                </app-input-date-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'017_account_entity_edit'"
                  [inputType]="'checkbox'"
                  [parentGroup]="form"
                  [controlName]="'canDelegate'"
                  [label]="'wc.admin.accountEntity.canDelegate'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card__wrapper" *ngIf="accountEntityRoleDto.recordVersion || !grantMode">
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.accountEntity.delegatedFrom' | translate }}
        </div>
        <div class="card__body">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="form.controls['delegatedByAccount']"
                  [controlName]="'login'"
                  [inputType]="'text'"
                  id="018_account_entity_edit"
                  [translationPrefix]="'wc.admin.accountEntity'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'019_account_entity_edit'"
                  [disabled]="true"
                  [label]="'wc.admin.accountEntity.delegated'"
                  [simplifiedInput]="true"
                  [enteredValue]="delegatedFrom?.created | date: 'dd.MM.yyyy'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'020_account_entity_edit'"
                  [disabled]="true"
                  [label]="'wc.admin.accountEntity.entityType'"
                  [simplifiedInput]="true"
                  [enteredValue]="delegatedFrom?.entityType">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'021_account_entity_edit'"
                  [disabled]="true"
                  [label]="'wc.admin.accountEntity.entityId'"
                  [simplifiedInput]="true"
                  [enteredValue]="delegatedFrom?.entityType">
                </app-custom-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
