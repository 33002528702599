<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.products.groups.groupListing' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="001_product_edit_groups"
            color="primary"
            mat-raised-button
            (click)="addGroup()"
            [routerLink]=""
            class="btn -primary -xs edit icon stroke"
            [disabled]="!this.productEditService.enableEditProduct">
            <mat-icon svgIcon="plus"></mat-icon>{{ 'wc.common.add' | translate }}
          </button>
          <button
            id="002_product_edit_groups"
            color="primary"
            mat-stroked-button
            (click)="deleteGroup()"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor"
            [disabled]="!this.productEditService.enableEditProduct">
            <mat-icon svgIcon="delete"></mat-icon>{{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <div class="group_products__container">
          <table class="table -primary customTable">
            <thead>
              <th scope="col"></th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.productGroups.sequence"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.products.groups.id"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.products.groups.label"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.products.groups.priority"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.products.groups.type"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.products.groups.grp"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.products.groups.created"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.products.groups.modified"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.products.groups.modifiedBy"> </app-sortable-column>
              </th>
            </thead>
            <tbody>
              <tr
                (dblclick)="goToGroup(group)"
                (seqUpdated)="seqChanged($event)"
                *ngFor="let group of groups; let i = index"
                [group]="group"
                [disabled]="!this.productEditService.enableEditProduct"
                app-product-edit-group-list-item
                class="table__row"
                [parentId]="'listing_' + i"></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
