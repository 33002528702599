<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'id'"
            [inputType]="'text'"
            id="001_stores_filter"
            [translationPrefix]="'wc.admin.stores'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            *ngIf="storeTypes"
            [formControlName]="'type'"
            id="004_stores_filter"
            [options]="storeTypes.data"
            [showEmptyOption]="true"
            [labelOption]="'name'"
            [valueOption]="'name'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.stores'">
          </app-input-select-form-field>
          <app-custom-input
            [controlName]="'extId'"
            [inputType]="'text'"
            id="007_stores_filter"
            [translationPrefix]="'wc.admin.stores'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'ouRefNo'"
            [inputType]="'text'"
            id="008_stores_filter"
            [translationPrefix]="'wc.admin.stores'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
