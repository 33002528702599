<div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
  {{ (headerTranslateKey ? headerTranslateKey : 'wc.admin.ticketType.parameter-acl.header') | translate }}
  <mat-icon
    class="default-icon"
    (click)="collapsed = !collapsed"
    color="primary"
    svgIcon="{{ collapsed ? 'hide' : 'show' }}"></mat-icon>
</div>

<app-acl-table-list
  *ngIf="aclRoles && aclPrivileges"
  [editable]="editable"
  [hidden]="collapsed"
  [parentForm]="parentForm"
  [context]="context"
  [aclRoles]="aclRoles"
  [aclRules]="aclRules"
  [parameterName]="parameterName"
  [category]="category"
  [parameters]="parameters"
  (parameterChanged)="parameterChanged.emit($event)"
  [parentAclRules]="parentAclRules"
  [aclPrivileges]="aclPrivileges">
</app-acl-table-list>
