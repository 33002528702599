<h2 mat-dialog-title>
  {{ 'wc.common.choose' | translate }}
    <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<div class="modal-body" [formGroup]="form">
  <app-input-select-form-field
    [translationPrefix]="'wc.admin.products.attributes'"
    [options]="techCategories"
    [showEmptyOption]="true"
    [multipleSelect]="true"
    [label]="'category'"
    [formControlName]="'categories'">
  </app-input-select-form-field>
</div>
<mat-dialog-actions align="end">
  <button color="primary" mat-raised-button (click)="selectEvent()" [routerLink]="">
    {{ 'wc.common.choose' | translate }}
  </button>
</mat-dialog-actions>
