import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-acl-legend-modal',
  templateUrl: './acl-legend-modal.component.html',
  styleUrls: ['./acl-legend-modal.component.scss'],
})
export class AclLegendModalComponent {
  @Input()
  dialogRef;
}
