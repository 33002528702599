import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AclService,
  AdminDmsService,
  AppBlockerService,
  AuthFactoryService,
  AuthService,
  CompareType,
  EnableDynamicLoading,
  ModulePreferencesService,
  ServiceUtils,
  StickyMessageService,
  TicketingService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CreateTicketModalComponent } from '@components/create-ticket-modal/create-ticket-modal.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { BulkDeleteListDto, TicketDto } from '@btl/order-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { TicketEditComponent } from '@components/tickets/edit/ticket-edit.component';

import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss'],
})
@EnableDynamicLoading({ customName: TicketListComponent.PAGE_ID })
export class TicketListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'TicketListComponent';

  pageId(): string {
    return TicketListComponent.PAGE_ID;
  }

  @Output()
  readonly totalItems: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  userProfile;

  @Input()
  count: number;

  pagedTicketsDto: PagedTicketsDto;

  @Input()
  tickets;

  @Input()
  simpleVersion: boolean;

  @Output()
  readonly editTickets: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    private dialog: MatDialog,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    private ticketingService: TicketingService,
    private authFactoryService: AuthFactoryService,
    private authService: AuthService,
    private adminDmsService: AdminDmsService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const username = this.params.username;
      if (username) {
        this.search.filtering = [
          {
            column: 'assignedTo',
            compareType: CompareTypeDtoEnum.IN,
            value: [username],
          },
        ];
      }
    }
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.pagedTicketsDto = null;

      this.ticketingService.getTicketsByFilter(this.search).subscribe(result => {
        if (result) {
          this.pagedTicketsDto = result;
          this.totalItems.emit(this.pagedTicketsDto.totalItems);
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  newTicket() {
    const modalRef = this.dialog.open(CreateTicketModalComponent, { autoFocus: false });
    const createTicketModalComponent = modalRef.componentInstance;
    createTicketModalComponent.dialogRef = modalRef;
    createTicketModalComponent.createTicketEvent.subscribe(ticketForm => {
      if (ticketForm) {
        this.navigateSibling(TicketEditComponent.PAGE_ID, { id: '&', typeCode: ticketForm.code });
      }
    });
  }

  deleteTicket(ticket) {
    this.deleteTickets([ticket]);
  }

  deleteCheckedTickets() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.ticket.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.deleteTickets(this.getSelectedData());
      confirmationDialogComponent.dialogReference.close();
    };
  }

  private deleteTickets(tickets: Array<TicketDto>) {
    this.appBlockerService.block();
    const bulkDeleteList: BulkDeleteListDto = {
      bulkDeleteList: [],
    };

    const extIds = [];

    tickets.forEach(ticket => {
      ticket.attachments.forEach(ticketAttachment => extIds.push(ticketAttachment.extId));
      bulkDeleteList.bulkDeleteList.push({
        id: ticket.id,
      });
    });

    this.ticketingService
      .deleteTickets(bulkDeleteList)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(() => {
        if (extIds.length > 0) {
          const search = ServiceUtils.getUnlimitedSearch();
          search.filtering.push({
            column: 'extId',
            compareType: CompareTypeDtoEnum.IN,
            value: extIds,
          });
          this.adminDmsService
            .filterFiles(search, null)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(result => {
              const patchList = [];
              result.data.forEach(file => {
                patchList.push({
                  id: file.id,
                  references: file.references?.filter(dmsRef => dmsRef.entityType !== 'Ticket'),
                  recordVersion: file.recordVersion,
                });
              });
              if (patchList.length > 0) {
                const bulkOperations = {
                  bulkCreateList: null,
                  bulkUpdateList: [],
                  bulkPatchList: patchList,
                };
                this.adminDmsService.changeFiles(bulkOperations).subscribe(() => {
                  this.handleDelete();
                });
              } else {
                this.handleDelete();
              }
            });
        } else {
          this.handleDelete();
        }
      });
  }

  private handleDelete() {
    this.loadData();
    this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
  }

  editTicket(ticketDto, inNewTab: boolean = false) {
    this.navigateSibling(TicketEditComponent.PAGE_ID, { id: ticketDto.id }, inNewTab);
  }
}

export interface PagedTicketsDto {
  totalItems: number;
  totalPages: number;
  pageSize: number;
  currentPage: number;
  data?: Array<any>;
}
