<div class="main__header">
  <div class="header-box">
    <div class="icon-box CODEBOOK">
      <mat-icon svgIcon="codebooks"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.codebooks' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      id="001_codebook_listing"
      (click)="newCodebook()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      color="primary"
      mat-raised-button
      [title]="'wc.admin.codebooks.new.title' | translate"
      class="btn -primary -xs"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-codebook-filter (filterChanged)="handleFilterChange()" [filtering]="search.filtering"></app-codebook-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.codebooks.form.list' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedCodebooksDto?.totalItems
                          ? pagedCodebooksDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedCodebooksDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="002_codebook_listing"
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedData()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="['code', 'codebookType', 'priority', 'parent.id', 'created', 'modified', 'modifiedBy']"
          [columnToTruncate]="['code', 'codebookType', 'created', 'modified', 'modifiedBy']"
          [dontShowOnMobile]="['created', 'modified', 'modifiedBy']"
          [fillIdBy]="'id'"
          (editButtonEvent)="editCodebook($event)"
          (editInNewTabButtonEvent)="editCodebook($event, true)"
          (deleteButtonEvent)="deleteData($event)"
          [pagedDto]="pagedCodebooksDto"
          (pageSizeEvent)="pageSizeChanged($event)"
          [translationPrefix]="'wc.admin.codebook'"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [editable]="aclService.pageEditAccess"
          (doubleClickEvent)="editCodebook($event)">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
