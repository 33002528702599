import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReviewDto } from '@btl/admin-bff';

@Component({
  selector: 'app-product-ratings-item, [app-product-ratings-item]',
  templateUrl: './product-ratings-item.component.html',
  styleUrls: ['./product-ratings-item.component.scss'],
})
export class ProductRatingsItemComponent {
  @Input()
  multiSelectMode = false;

  @Input()
  singleSelectMode = false;

  @Input()
  review: ReviewDto;

  @Output()
  readonly editReview: EventEmitter<ReviewDto> = new EventEmitter<ReviewDto>();

  @Output()
  readonly editReviewInNewTab: EventEmitter<ReviewDto> = new EventEmitter<ReviewDto>();

  @Output()
  readonly deleteReview: EventEmitter<ReviewDto> = new EventEmitter<ReviewDto>();

  @Output()
  readonly exportReview: EventEmitter<ReviewDto> = new EventEmitter<ReviewDto>();

  @Input()
  showActions = true;

  @Input()
  parentId = '';

  selected = false;

  edit() {
    this.editReview.emit(this.review);
  }

  editInNewTab() {
    this.editReviewInNewTab.emit(this.review);
  }

  delete() {
    this.deleteReview.emit(this.review);
  }

  export() {
    this.exportReview.emit(this.review);
  }
}
