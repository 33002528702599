<div [ngClass]="selectedTheme">
  <header>
    <app-top-menu></app-top-menu>
  </header>
  <block-ui [name]="mainBlockUIInstanceName" [template]="blockTemplate">
    <mat-sidenav-container class="sidenav-container" autosize>
      <mat-sidenav
        class="main-sidenav"
        #mainNav
        [fixedInViewport]="false"
        mode="side"
        position="start"
        [opened]="mainMenu">
        <app-left-menu
          (secondMenuEvent)="secondMenuChanged($event)"
          [closed]="closed"
          [collapsed]="collapsed"
          [secondMenu]="secondMenu">
        </app-left-menu>
      </mat-sidenav>
      <mat-sidenav-content>
        <mat-sidenav-container class="sidenav-container" autosize>
          <mat-sidenav
            class="inner-sidenav"
            #innerNav
            mode="side"
            [fixedInViewport]="false"
            position="start"
            [opened]="secondMenu">
            <app-inner-left-menu> </app-inner-left-menu>
          </mat-sidenav>
          <mat-sidenav-content class="main_content {{ secondMenu ? 'opened' : 'closed' }}">
            <div class="main__inner">
              <app-page-module-breadcrumb [allowBootstrap]="false"></app-page-module-breadcrumb>
              <router-outlet></router-outlet>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </block-ui>
</div>
