<div class="main__header">
  <h1 id="001_releases_edit">{{ 'wc.admin.releases.releaseEdit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_releases_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="003_releases_edit"
      color="primary"
      mat-raised-button
      (click)="checkValidity(editorDialog)"
      *ngIf="release && release.created"
      [routerLink]=""
      class="btn -primary -xs"
      >{{ 'wc.common.checkValidity' | translate }}</a
    >
    <a
      id="004_releases_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="005_releases_edit" mat-menu-item *ngIf="release && release.created" (click)="duplicate()" [routerLink]=""
        ><mat-icon svgIcon="duplicate"></mat-icon>{{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="006_releases_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="007_releases_edit" mat-menu-item *ngIf="release && release.created" (click)="delete()" [routerLink]=""
        ><mat-icon svgIcon="delete"></mat-icon>{{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="008_releases_edit" (click)="cancel()" class="btn cancel-button">{{
      'wc.common.cancel.button' | translate
    }}</a>
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.releases' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="release" [formGroup]="releaseForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="releaseForm"
                  id="009_releases_edit"
                  [controlName]="'releaseCode'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.releases'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-date-form-field
                  [parentGroup]="releaseForm"
                  [controlName]="'releaseDate'"
                  id="010_releases_edit"
                  [translationPrefix]="'wc.admin.releases'">
                </app-input-date-form-field>
              </div>
              <div class="form__row">
                <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                  <mat-label>{{ 'wc.admin.releases.releaseNote' | translate }}</mat-label>
                  <textarea
                    id="011_releases_edit"
                    class="form__input"
                    matInput
                    type="text"
                    formControlName="releaseNote"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div
    *ngIf="release"
    [editable]="true"
    [release]="release"
    [operations]="operations"
    app-members-listing
    class="card__wrapper"></div>
</div>

<ng-template #editorDialog let-c="close" let-d="dismiss">
  <div>
    <div>
      <h2 mat-dialog-title>
        {{ 'wc.admin.releases.validationResult' | translate }}
        <mat-icon
          class="default-icon-gray-fill no-stroke"
          svgIcon="close"
          mat-dialog-close
          matTooltip="{{ 'wc.common.close' | translate }}">
        </mat-icon>
      </h2>
    </div>

  </div>
  <mat-dialog-content class="mat-typography">
    {{ 'wc.admin.releases.validationResult.' + validationResult.valid | translate }}
    <div *ngIf="validationResult && !validationResult.valid">
      <div *ngFor="let code of validationResult.notValidCodes">
        {{ code }}
      </div>
    </div>
  </mat-dialog-content>
</ng-template>
