import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CompareType, ComplexFilter, ControlFilterMiner, ControlStructFilterMiner } from '@btl/btl-fe-wc-common';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-ticket-step-template-filter',
  templateUrl: './ticket-step-template-filter.component.html',
  styleUrls: ['./ticket-step-template-filter.component.scss'],
})
export class TicketStepTemplateFilterComponent extends ComplexFilter {
  filterForm = this.formBuilder.group({
    stepTemplateCode: [],
    stepTemplateDescription: [],
    stepsCode: [],
    stepsDescription: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.stepTemplateCode, 'code', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.stepTemplateDescription, 'description', CompareTypeDtoEnum.LIKE),
    new ControlStructFilterMiner(this.filterForm.controls.stepsCode, 'steps', 'code', null),
    new ControlStructFilterMiner(this.filterForm.controls.stepsDescription, 'steps', 'description', null),
  ];

  constructor(private formBuilder: FormBuilder) {
    super();
  }
}
