import { Inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { DefaultErrorHandler, LocalizationService } from '@btl/btl-fe-wc-common';
import { LocalizedTextFrontendService, LocalizedTextMapDto } from '@btl/order-bff';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LocalizationMockedService extends LocalizationService {
  constructor(
    @Inject('config') config,
    bffSimplifiedLocalizedTextService: LocalizedTextFrontendService,
    protected errorHandler: DefaultErrorHandler,
    private http: HttpClient
  ) {
    super(config, bffSimplifiedLocalizedTextService, errorHandler);
  }

  wcAdminLocalizedTexts: LocalizedTextMapDto = {
    // banners localized texts
    ['banners']: 'Banners',
    ['banners.new']: 'New',
    ['banners.edit']: 'Edit',
    ['banners.delete']: 'Delete',
    ['banners.duplicate']: 'Duplicate',
    ['banners.export']: 'Export',
    ['banners.save']: 'Save',
    ['banners.reset']: 'Reset',

    ['banners.action']: 'Action',
    ['banners.attributes']: 'Banner Attributes',
    ['banners.parameters']: 'Banner Parameters',
    ['banners.template']: 'Banner Template',

    ['banners.form.list']: 'Banner list',
    ['banners.form.edit']: 'Banner edit',

    ['banner.id']: 'Id',
    ['banner.name']: 'Name',
    ['banner.templateId']: 'Template',
    ['banner.stateType']: 'State',
    ['banner.priority']: 'Priority',
    ['banner.startDateTime']: 'Available from',
    ['banner.endDateTime']: 'Available to',
    ['banner.availableFrom']: 'Available from',
    ['banner.availableTo']: 'Available to',
    ['banner.recordVersion']: 'Record Version',

    ['banner.stateType.error.required']: 'State is required.',
    ['banner.templateId.error.required']: 'Template is required.',
    ['banner.name.error.required']: 'Name is required.',
    ['banner.startDateTime.error.required']: 'Available from is required.',
    ['banner.startDateTime.error.startIsLaterThanEnd']: 'Available from is later than available to.',
    ['banner.endDateTime.error.startIsLaterThanEnd']: 'Available from is later than available to.',

    ['bannerTemplate.id']: 'Id',
    ['bannerTemplate.name']: 'Name',
    ['bannerTemplate.targetArea']: 'Target Area',
    ['bannerTemplate.content']: 'Content',

    // stickers localized texts
    ['stickers']: 'Stickers',
    ['stickers.new']: 'New',
    ['stickers.edit']: 'Edit',
    ['stickers.delete']: 'Delete',
    ['stickers.duplicate']: 'Duplicate',
    ['stickers.export']: 'Export',
    ['stickers.save']: 'Save',
    ['stickers.reset']: 'Reset',

    ['stickers.action']: 'Action',
    ['stickers.list']: 'List',
    ['stickers.attributes']: 'Attributes',
    ['stickers.pictures']: 'Pictures',

    ['sticker.id']: 'Code',
    ['sticker.label']: 'Label',
    ['sticker.priority']: 'Priority',
    ['sticker.type']: 'Type',
    ['sticker.grp']: 'Group',
    ['sticker.created']: 'Created',
    ['sticker.modified']: 'Modified',
    ['sticker.modifiedBy']: 'Modified By',
    ['sticker.createdBy']: 'Created By',
    ['sticker.recordVersion']: 'Record Version',
    ['stickers.labels']: 'Labels',

    // localizedTexts localized texts
    ['localizedTexts']: 'Localized texts',
    ['localizedTexts.new']: 'New',
    ['localizedTexts.newLocales']: 'New+',
    ['localizedTexts.edit']: 'Edit',
    ['localizedTexts.editLocales']: 'Edit+',
    ['localizedTexts.delete']: 'Delete',
    ['localizedTexts.duplicate']: 'Duplicate',
    ['localizedTexts.export']: 'Export',
    ['localizedTexts.save']: 'Save',
    ['localizedTexts.reset']: 'Reset',

    ['localizedTexts.new.title']: 'New localized text',
    ['localizedTexts.newLocales.title']: 'New all locales for module/key',
    ['localizedTexts.edit.title']: 'Edit localized text',
    ['localizedTexts.editLocales.title']: 'Edit all locales for module/key',
    ['localizedTexts.delete.title']: 'Delete localized texts',

    ['localizedTexts.action']: 'Action',
    ['localizedTexts.attributes']: 'Localized text attributes',

    ['localizedTexts.form.list']: 'Localized text list',
    ['localizedTexts.form.edit']: 'Localized text edit',
    ['localizedTexts.form.editLocales']: 'Localized text edit locales',

    ['localizedText.id']: 'Id',
    ['localizedText.module']: 'Module',
    ['localizedText.locale']: 'Locale',
    ['localizedText.key']: 'Key',
    ['localizedText.defaultValue']: 'Default value',
    ['localizedText.customValue']: 'Custom value',
    ['localizedText.value']: 'Value',
    ['localizedText.created']: 'Created',
    ['localizedText.createdBy']: 'Created by',
    ['localizedText.modified']: 'Modified',
    ['localizedText.modifiedBy']: 'Modified by',
    ['localizedText.recordVersion']: 'Record Version',
    ['localizedText.module.error.required']: 'Module is required.',
    ['localizedText.locale.error.required']: 'Locale is required.',
    ['localizedText.key.error.required']: 'Key is required.',
    ['localizedText.defaultValue.error.required']: 'Default value is required.',

    ['codebooks']: 'Codebooks',
    ['codebooks.new']: 'New',
    ['codebooks.edit']: 'Edit',
    ['codebooks.delete']: 'Delete',
    ['codebooks.duplicate']: 'Duplicate',
    ['codebooks.export']: 'Export',
    ['codebooks.save']: 'Save',
    ['codebooks.reset']: 'Reset',

    ['codebooks.new.title']: 'New codebook',
    ['codebooks.edit.title']: 'Edit codebook',
    ['codebooks.delete.title']: 'Delete codebooks',

    ['codebooks.action']: 'Action',
    ['codebooks.attributes']: 'Codebook attributes',

    ['codebooks.form.list']: 'Codebook list',
    ['codebooks.form.edit']: 'Codebook edit',

    ['codebook.id']: 'Id',
    ['codebook.code']: 'Code',
    ['codebook.codeAlt']: 'Alt. code',
    ['codebook.codeBookType']: 'Type',
    ['codebook.localizedTextKey']: 'Localized text key',
    ['codebook.priority']: 'Priority',
    ['codebook.parent']: 'Parent',
    ['codebook.visible']: 'Visible',
    ['codebook.created']: 'Created',
    ['codebook.createdBy']: 'Created by',
    ['codebook.modified']: 'Modified',
    ['codebook.modifiedBy']: 'Modified by',
    ['codebook.recordVersion']: 'Record Version',
    ['codebook.labels']: 'Labels',
    ['codebook.parameters']: 'Parameters',
    ['codebook.parameter']: 'Parameter',
    ['codebook.parameter.name']: 'Name',
    ['codebook.parameter.value']: 'Value',
    ['codebook.parameter.seq']: 'Seq',

    ['codebook.codeBookType.error.required']: 'Type is required.',
    ['codebook.code.error.required']: 'Code is required.',
    ['codebook.localizedTextKey.error.required']: 'Localized text key is required.',
    ['codebook.priority.error.required']: 'Priority is required.',
    ['codebook.priority.error.min']: 'Priority minimum is -9999',
    ['codebook.priority.error.max']: 'Priority maximum is 9999',

    ['codebook.parameter.name.error.required']: 'Name is required.',
    ['codebook.parameter.value.error.required']: 'Value is required.',
    ['codebook.parameter.seq.error.required']: 'Seq is required.',
    ['codebook.parameter.seq.error.min']: 'Seq minimum is 0',
    ['codebook.parameter.seq.error.max']: 'Seq maximum is 9999',

    ['technicalCategory.technicalCategoryEdit']: 'Technical category edit',
    ['technicalCategory']: 'Technical category',

    ['technicalCategory.id']: 'Name',
    ['technicalCategory.parentId']: 'Parent',
    ['technicalCategory.parameters']: 'Parameters',
    ['technicalCategory.parentsParameters']: 'Parents parameters',
    ['productParameters.name']: 'Name',
    ['productParameters.defaultValue']: 'Default value',
    ['productParameters.validationRegex']: 'Validation regex',
    ['productParameters.type']: 'Type',
    ['productParameters.typeDetail']: 'Type detail',
    ['productParameters.labelKey']: 'Label key',
    ['productParameters.integrationKey']: 'Integration key',
    ['productParameters.static']: 'Static',
    ['technicalCategory.texts.type.PARAMETER_NAME']: 'Parameter name',

    ['productPrices.attributes']: 'Attributes',
    ['productPrices.priceAdd']: 'Adding new price',
    ['productPrices.priceEdit']: 'Price edit',
    ['productPrices.type']: 'Type',
    ['productPrices.priority']: 'Priority',
    ['productPrices.price']: 'Price',
    ['productPrices.tax']: 'Tax',
    ['productPrices.validForFrom']: 'Valid from',
    ['productPrices.validForTo']: 'Valid to',
    ['productPrices.code']: 'Code',
    ['productPrices.appType']: 'Application type',
    ['productPrices.parameters']: 'Parameters',
    ['productPrices.priceType.error.required']: 'Required',
    ['productPrices.priority.error.required']: 'Required',
    ['productPrices.price.error.required']: 'Required',
    ['productPrices.validForFrom.error.required']: 'Required',
    ['productPrices.validForFrom.error.startIsLaterThanEnd']: 'Start is later than end',
    ['productPrices.validForTo.error.startIsLaterThanEnd']: 'Start is later than end',
    ['products.prices.delete.confirmation.text']: 'Are You sure You want to delete selected product prices?',

    ['productParameters.action']: 'Action',
    // ['']: '',
    // ['']: '',

    ['products.ratings']: 'Ratings',
    ['review']: 'Review',
    ['reviews']: 'Reviews',
    ['review.reviewedOn']: 'Reviewed on',
    ['review.rating']: 'Rating',
    ['review.recommended']: 'Recommended',
    ['review.reviewSummary']: 'Summary',
    ['review.extId']: 'Ext.Id',
    ['reviews.edit']: 'Edit',
    ['reviews.delete']: 'Delete',
    ['reviews.export']: 'Export',
    ['ratings']: 'Ratings',
    ['ratings.add']: 'Add',
    ['ratings.save']: 'Save',
    ['ratings.action']: 'Action',
    ['ratings.removeFiltered']: 'Remove filtered',
    ['ratings.reviewedFrom']: 'Reviewed from',
    ['ratings.reviewedTo']: 'Reviewed to',
    ['ratings.ratingFrom']: 'Rating from',
    ['ratings.ratingTo']: 'Rating to',
    ['ratings.recommended']: 'Recommended',
    ['ratings.extId']: 'Ext.Id',
    ['ratings.recommended.yes']: 'Yes',
    ['ratings.recommended.no']: 'No',
    ['ratings.review.reviewEdit']: 'Review edit',
    ['ratings.review.id']: 'Id',
    ['ratings.review.productId']: 'Product Id',
    ['ratings.review.extId']: 'Ext.Id',
    ['ratings.review.reviewedOn']: 'Reviewed on',
    ['ratings.review.rating']: 'Rating',
    ['ratings.review.recommended']: 'Recommended',
    ['ratings.review.reviewSummary']: 'Review summary',
    ['ratings.review.reviewPros']: 'Pros',
    ['ratings.review.reviewCons']: 'Cons',
    ['ratings.review.created']: 'Created',
    ['ratings.review.createdBy']: 'Created by',
    ['ratings.review.modified']: 'Modified',
    ['ratings.review.modifiedBy']: 'Modified by',
    ['ratings.review.attributes']: 'Attributes',
    ['ratings.review.reviewedOn.error.required']: 'Required',
    ['ratings.review.rating.error.required']: 'Required',

    ['contextRule']: 'Context rule',
    ['contextRule.name']: 'Name',
    ['contextRule.applicationType']: 'Application type',
    ['contextRule.implementationType']: 'Implementation type',
    ['contextRule.expression']: 'Expression',
    ['contextRule.abstractRule']: 'Abstract',
    ['contextRule.arguments']: 'Arguments',
    ['contextRule.documentation']: 'Documentation',
    ['contextRules.form.list']: 'Context rules list',
    ['contextRules.form.edit']: 'Context rule edit',
    ['contextRules.attributes']: 'Attributes',
    ['contextRules.action']: 'Action',
    ['contextRules.new']: 'New',
    ['contextRules']: 'Context rules',
    ['contextRules.edit']: 'Edit',
    ['contextRules.delete']: 'Delete',
    ['contextRules.save']: 'Save',
    ['contextRules.reset']: 'Reset',
    ['contextRule.id.error.required']: 'Required',
    ['contextRule.applicationType.error.required']: 'Required',
    ['contextRule.implementationType.error.required']: 'Required',
    ['contextRule.expression.error.required']: 'Required',

    ['promotions']: 'Promotions',
    ['promotions.new']: 'New',
    ['promotions.edit']: 'Edit',
    ['promotions.design']: 'Design',
    ['promotions.save']: 'Save',
    ['promotions.reset']: 'Reset',
    ['promotions.delete']: 'Delete',
    ['promotions.action']: 'Action',
    ['promotions.form.list']: 'Promotions list',
    ['promotions.form.edit']: 'Promotion edit',
    ['promotions.attributes']: 'Attributes',
    ['promotion']: 'Promotion',
    ['promotion.backToEdit']: 'Back to edit',
    ['promotion.name']: 'Name',
    ['promotion.code']: 'Code',
    ['promotion.validFrom']: 'Valid from',
    ['promotion.validTo']: 'Valid to',
    ['promotion.created']: 'Created',
    ['promotion.createdBy']: 'Created by',
    ['promotion.modified']: 'Modified',
    ['promotion.modifiedBy']: 'Modified by',
    ['promotion.validAt']: 'Valid at',
    ['promotion.productCode']: 'Product code',
    ['promotion.productCategory']: 'Product category',
    ['promotion.socketId']: 'Socket id',
    ['promotion.code.error.required']: 'Required',
    ['promotion.name.error.required']: 'Required',
    ['promotion.validFrom.error.required']: 'Required',
    ['promotion.validTo.error.startIsLaterThanEnd']: 'Start is later than end',
    ['promotion.validFrom.error.startIsLaterThanEnd']: 'Start is later than end',
    ['promotion.delete.confirmation.text']: 'Are You sure You want to delete selected promotion?',
    ['promotions.delete.confirmation.text']: 'Are You sure You want to delete selected promotions?',

    ['promotion.design']: 'Design',
    ['promotions.socket.listing']: 'Sockets listing',
    ['promotion.socket.code']: 'Code',
    ['promotion.socket.name']: 'Name',
    ['promotion.socket.category']: 'Category',
    ['promotion.socket.minCard']: 'Card.min',
    ['promotion.socket.maxCard']: 'Card.max',
    ['promotion.socket.promotionsCount']: 'Count',

    ['codebookTypes']: 'Codebook types',
    // ['codebookType']: 'Codebook type',
    ['codebookType.form.list']: 'Codebook type list',
    ['codebookType.form.edit']: 'Codebook type edit',
    ['codebookType.attributes']: 'Attributes',
    ['codebookType.parameters']: 'Parameters',
    ['codebookType.name']: 'Name',
    ['codebookType.new']: 'New',
    ['codebookType.edit']: 'Edit',
    ['codebookType.delete']: 'Delete',
    ['codebookType.duplicate']: 'Duplicate',
    ['codebookType.save']: 'Save',
    ['codebookType.reset']: 'Reset',
    ['codebookType.action']: 'Action',
    ['codebookType.delete.confirmation.text']: 'Are You sure You want to delete codebook type?',

    ['ticket.category']: 'Category',
    ['ticket.ticketRefNo']: 'Ref No',
    ['ticket.subject']: 'Subject',
    ['ticket.priority']: 'Priority',
    ['ticket.businessState']: 'Business State',
    ['ticket.created']: 'Created',
    ['ticket.assignee']: 'Assignee',
    ['ticket.action']: 'Action',
    ['ticket.owner']: 'Owner',
    ['ticket.area']: 'Area',
    ['ticket.type']: 'Type',
    ['ticket.assignmentState']: 'Assignment State',
    ['ticket.createdFrom']: 'Created from',
    ['ticket.createdTo']: 'Created to',
    ['ticket.followUpDateFrom']: 'Follow up date from',
    ['ticket.followUpDateTo']: 'Follow up date to',
    ['ticket.entityType']: 'Entity Type',
    ['ticket.entity']: 'Entity',

    ['tickets']: 'Tickets',
    ['tickets.form.list']: 'Tickets List',
    ['ticket.new']: 'New',
    ['tickets.edit']: 'Edit',
    ['tickets.delete']: 'Delete',

    ['accounts']: 'Accounts',
    ['accounts.new']: 'New',
    ['accounts.edit']: 'Edit',
    ['accounts.editInNewTab']: 'Edit in new tab',
    ['accounts.design']: 'Design',
    ['accounts.save']: 'Save',
    ['accounts.reset']: 'Reset',
    ['accounts.delete']: 'Delete',
    ['accounts.action']: 'Action',
    ['accounts.form.list']: 'Accounts list',
    ['accounts.form.edit']: 'Account edit',
    ['accounts.attributes']: 'Attributes',
    ['account']: 'Account',
    ['account.firstName']: 'First name',
    ['account.lastName']: 'Last name',
    ['account.login']: 'Login',
    ['account.external']: 'External',
    ['account.extId']: 'Employee id',
    ['account.state']: 'State',
    ['account.loginAttemptLast']: 'Last login',
    ['account.parentId']: 'Parent',
    ['account.locale']: 'Locale',
    ['account.created']: 'Created',
    ['account.createdBy']: 'Created by',
    ['account.modified']: 'Modified',
    ['account.modifiedBy']: 'Modified by',
    ['account.roles']: 'Roles',
    ['account.external.yes']: 'Yes',
    ['account.external.no']: 'No',
    ['account.delete.confirmation.text']: 'Are You sure You want to delete selected account?',
    ['accounts.delete.confirmation.text']: 'Are You sure You want to delete selected accounts?',
    ['account.preferences']: 'Preferences',
    ['account.preferences.add']: 'Add preference',
    ['account.preference']: 'Preference',
    ['account.preference.group']: 'Group',
    ['account.preference.name']: 'Name',
    ['account.preference.value']: 'Value',
    ['account.role.created']: 'Created',
    ['account.role.createdBy']: 'Created by',
    ['account.role.modified']: 'Modified',
    ['account.role.modifiedBy']: 'Modified by',
    ['account.role.name']: 'Name',
    ['account.role.action']: 'Action',
    ['account.roles.delete']: 'Delete',
    ['account.roles.add']: 'Add',
    ['account.roles.delete.confirmation.text']: 'Are You sure You want to delete selected account roles?',
    ['account.login.error.required']: 'Required',
    ['account.locale.error.required']: 'Required',
    ['account.state.error.required']: 'Required',

    ['roles']: 'Roles',
    ['roles.roleListing']: 'List of roles',
    ['roles.role']: 'Role',
    ['roles.rule.ruleType']: 'Rule type',
    ['roles.rule.privilege']: 'Privilege',
    ['roles.rule.resourceType']: 'Resource type',
    ['roles.rule.resourceIdentification']: 'Resource identification',
    ['roles.rule.validAt']: 'Valid at',
    ['roles.rule.external']: 'External',
    ['roles.description']: 'Description',

    // CMS
    ['cms.content']: 'CMS Content List',
    ['cms.content.attributes']: 'Content attributes',
    ['cms.content.template']: 'Template',
    ['cms.content.selectTemplate']: 'Select template',
    ['cms.content.ACTIVE']: 'ACTIVE',
    ['cms.content.INACTIVE']: 'INACTIVE',
    ['cms.content.edit']: 'Content edit',
    ['cms.content.edit.warning']:
      'Edited version of product is read-only. Once you change some parameters, you can click on "New version" button to save changes as a new product version.',
    ['cms.content.edit.reset']: 'Are You sure You want to reset content data? Not saved data will be lost!',
    ['cms.content.approve']: 'Approve',
    ['cms.content.archive']: 'Archive',
    ['cms.content.newVersion']: 'New version',
    ['cms.content.approved']: 'APPROVED',
    ['cms.content.archived']: 'ARCHIVED',
    ['cms.content.draft']: 'DRAFT',
    ['cms.content.templateName']: 'Template name',
    ['cms.content.templateId']: 'Template id',
    ['cms.content.templateType']: 'Template type',
    ['cms.content.contentTitle']: 'Content title',
    ['cms.content.contentCode']: 'Content code',
    ['cms.content.parameters']: 'Content parameters',
    ['cms.content.name']: 'Name',
    ['cms.content.state']: 'State',
    ['cms.content.seoUrl']: 'Seo url',
    ['cms.content.internal']: 'Internal only',
    ['cms.content.startDateTime']: 'Content valid from',
    ['cms.content.endDateTime']: 'Content valid to',
    ['cms.content.version']: 'Version',
    ['cms.content.version.attributes']: 'Version attributes',
    ['cms.content.version.priority']: 'Version priority',
    ['cms.content.version.state']: 'Version state',
    ['cms.content.version.verHash']: 'Hash code',
    ['cms.content.version.verHash.saved']: 'Hash code was saved.',
    ['cms.content.version.verHash.deleted']: 'Hash code was deleted',
    ['cms.content.version.startDateTime']: 'Version valid from',
    ['cms.content.version.endDateTime']: 'Version valid to',
    ['cms.content.version.generate']: 'Generate',
    ['cms.content.version.delete']: 'Delete',
    ['cms.content.version.parameters']: 'Version parameters',
    ['cms.content.version.parameter.name']: 'Parameter name',
    ['cms.content.version.parameter.type']: 'Parameter type',
    ['cms.content.version.parameter.value']: 'Parameter value',
    ['cms.content.version.parameter.delete']: 'Delete parameter',
    ['cms.content.version.parameter.add']: 'New parameter',
    ['cms.content.list']: 'Cms list',
    ['cms.content.list.id']: 'Id',
    ['cms.content.list.code']: 'Code',
    ['cms.content.list.name']: 'Name',
    ['cms.content.list.contentKeyId']: 'Content key name',
    ['cms.content.list.contentKeyValue']: 'Content key value',
    ['cms.content.list.contentType']: 'Content type',
    ['cms.content.list.contentState']: 'Content State',
    ['cms.content.list.contentKey']: 'Content key',
    ['cms.content.list.internal']: 'Internal only',
    ['cms.content.list.created']: 'Created',
    ['cms.content.list.modified']: 'Modified',
    ['cms.content.list.modifiedBy']: 'Modified by',
    ['cms.content.list.version']: 'Version',
    ['cms.content.list.versionState']: 'Version state',
    ['cms.content.list.validFrom']: 'Valid from',
    ['cms.content.list.validTo']: 'Valid to',
    ['cms.content.list.approve']: 'Approve',
    ['cms.content.list.state.approved']: 'APPROVED',
    ['cms.content.list.state.archived']: 'ARCHIVED',
    ['cms.content.list.state.draft']: 'DRAFT',
    ['cms.content.list.delete.confirmation.text']: 'Are you sure you want to delete this item?',
    ['cms.content.list.edit']: 'Edit form',
    ['cms.content.list.action']: 'Action',
    ['cms.content.version.list.id']: 'Id',
    ['cms.content.version.list.code']: 'Code',
    ['cms.content.version.list.version']: 'Version',
    ['cms.content.version.list.versionState']: 'Version state',
    ['cms.content.version.list.validFrom']: 'Valid from',
    ['cms.content.version.list.validTo']: 'Valid to',
    ['cms.content.version.list.created']: 'Created',
    ['cms.content.version.list.modified']: 'Modified',
    ['cms.content.version.list.modifiedBy']: 'Modified by',
    ['cms.content.version.list.action']: 'Action',
    ['cms.content.version.list.approve']: 'Approve',
    ['cms.content.version.list.approved']: 'APPROVED',
    ['cms.content.version.list.archived']: 'ARCHIVED',
    ['cms.content.version.list.draft']: 'DRAFT',
    ['cms.content.version.list.show']: 'Show',
    ['cms.content.version.list.archive.error']: "Last approved version can't be archived.",
    ['cms.content.version.list.approve.error']: 'Only version in DRAFT state can be approved.',
    ['cms.content.version.list.delete.error']: 'Only version in DRAFT state can be deleted.',
    ['cms.content.form.validation.error']: 'Form is not valid.',
    ['cms.content.form.duplicate.error']: 'This value already exist, please change to new unique value.',
    ['cms.content.widget.label']: 'Widgets',
    ['cms.content.widget.undo']: 'undo',
    ['cms.content.widget.redo']: 'redo',
    ['cms.content.widget.preview']: 'Preview',
    ['cms.content.widget.modal.parameters']: 'Widget parameters',
    ['cms.content.widget.modal.type']: 'Type',
    ['cms.content.widget.modal.name']: 'Name',
    ['cms.content.widget.modal.up']: 'Up',
    ['cms.content.widget.modal.down']: 'Down',
    ['cms.content.widget.modal.cancel.confirmation.text']: 'Are you sure you want to cancel adding the new widget?',
    ['cms.content.widget.modal.delete.confirmation.text']: 'Are You sure You want to delete widget?',
    ['cms.content.widget.modal.value.error.required']: 'Required item.',
    ['cms.content.widget.modal.value.error.pattern']: 'Invalid format.',

    ['created']: 'Created',
    ['createdBy']: 'Created by',
    ['modified']: 'Modified',
    ['modifiedBy']: 'Modified by',
    ['new']: 'New',
    ['edit']: 'Edit',
    ['editInNewTab']: 'Edit in new tab',
    ['design']: 'Design',
    ['save']: 'Save',
    ['reset']: 'Reset',
    ['delete']: 'Delete',
    ['action']: 'Action',
    ['duplicate']: 'Duplicate',

    // maybe in this module?
    // ['locale.en']: 'English',
    // ['locale.cs']: 'Czech',
    // ['locale.sk']: 'Slovak',
    //
  };

  wcCommonLocalizedTexts: LocalizedTextMapDto = {
    ['search.button']: 'Search',
    ['confirm']: 'Confirm',
  };

  codebookLocalizedTexts: LocalizedTextMapDto = {
    ['codebook.ADMIN_MODULE.BANNER']: 'Banner',
    ['codebook.ADMIN_MODULE.BANNERS']: 'Banners',
    ['codebook.ADMIN_MODULE.BANNER_GROUPS']: 'Banner Groups',
    ['codebook.ADMIN_MODULE.BANNER_TEMPLATES']: 'Banner Templates',
    ['codebook.ADMIN_MODULE.RATINGS']: 'Ratings',
    ['codebook.ADMIN_MODULE.ACCESS']: 'Access',
    ['codebook.ADMIN_MODULE.ROLES']: 'Roles',
    ['codebook.ADMIN_MODULE.ACCOUNTS']: 'Accounts',
    ['codebook.ADMIN_MODULE.CMS']: 'Cms',
    ['codebook.ADMIN_MODULE.CMS_CONTENTS']: 'Content list',
    ['locale.en']: 'English',
    ['locale.cs']: 'Czech',
    ['locale.sk']: 'Slovak',
    ['locale.bg']: 'Bulgarian',
  };

  localizationDict: { [key: string]: string } = {
    ['en']: 'en_GB',
    ['cs']: 'cs_CZ',
  };

  /**
   * Get localization for the given locale and the given module.
   *
   * param {string} locale The target locale.
   * param {string} module The target module.
   * returns {Observable<{}> | Observable<Localization>} The observable localization.
   */
  public getSimplifiedLocalization(locale: string, module: string): Observable<{}> | Observable<LocalizedTextMapDto> {
    const localizedTextMap: Observable<LocalizedTextMapDto> = super.getSimplifiedLocalization(locale, module);
    const errorMessage = `Loading localization data for module '${module}' and locale '${locale}' failed.`;

    const localizedTexts: LocalizedTextMapDto = {};
    let overrideLocalizedTexts: LocalizedTextMapDto; // = {};
    // switch (module) {
    //   case 'wc.admin':
    //     overrideLocalizedTexts = this.wcAdminLocalizedTexts;
    //     break;
    //   case 'wc.common':
    //     overrideLocalizedTexts = this.wcCommonLocalizedTexts;
    //     break;
    //   case 'codebook':
    //     overrideLocalizedTexts = this.codebookLocalizedTexts;
    //     break;
    //   default:
    //     overrideLocalizedTexts = {};
    // }
    //
    // for (let key in overrideLocalizedTexts) {
    //   localizedTexts[key] = overrideLocalizedTexts[key];
    // }
    //
    // let lts = localizedTextMap.subscribe((texts: LocalizedTextMapDto) => {
    //   for (let key in texts) {
    //     localizedTexts[key] = texts[key];
    //   }
    //   for (let key in overrideLocalizedTexts) {
    //     localizedTexts[key] = overrideLocalizedTexts[key];
    //   }
    // });

    this.http
      .get(`assets/txt/${module}_${this.localizationDict[locale]}.properties`, { responseType: 'text' })
      .subscribe(data => {
        overrideLocalizedTexts = {};
        const lines = data.split('\n');
        lines.forEach(line => {
          const idx = line.indexOf('=');
          const key = idx === -1 ? null : line.substr(0, idx).trim();
          const value = idx === -1 ? null : line.substr(idx + 1).trim();
          // const texts = line.split('=');
          // const key = texts[0].trim();
          // const value = texts.length > 1 ? texts[1].trim() : null;
          if (key && key !== '' && !key.startsWith('!') && !key.startsWith('#')) {
            overrideLocalizedTexts[key] = value === '' ? null : value;
          }
        });
        for (const key in overrideLocalizedTexts) {
          localizedTexts[key] = overrideLocalizedTexts[key];
        }

        const lts = localizedTextMap.subscribe((texts: LocalizedTextMapDto) => {
          for (const key in texts) {
            localizedTexts[key] = texts[key];
          }
          for (const key in overrideLocalizedTexts) {
            localizedTexts[key] = overrideLocalizedTexts[key];
          }
        });
      });

    const lt = of(localizedTexts); //.pipe(delay(3000));

    //return forkJoin(localizedTextMap, lt) // this case wait for response from super service
    return (
      forkJoin([localizedTextMap, lt])
        .pipe(
          map(([x, merged]) => {
            return merged;
          })
        )
        //.pipe(delay(2000))
        .pipe(catchError(this.handleError(errorMessage, {})))
    );
  }
}
