import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminSocketService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { SocketDto } from '@btl/admin-bff/model/socketDto';
import { environment } from '@environments/environment';
import { SocketContentDto } from '@btl/admin-bff/model/socketContentDto';
import { EnumerationsService } from '@service/enumerations.service';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { Animations } from '@helpers/animations';
import { SocketListComponent } from '@components/product-catalogue/socket/list/socket-list.component';

@Component({
  selector: 'app-socket-edit',
  templateUrl: './socket-edit.component.html',
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: SocketEditComponent.PAGE_ID })
export class SocketEditComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'SocketEditComponent';

  pageId(): string {
    return SocketEditComponent.PAGE_ID;
  }

  static readonly PREFIX_NEW_PARAMETER = 'new-';

  PickerInputType = PickerInputType;
  @Input()
  parentId = '';

  @Input()
  editable = true;

  locales = environment.localization.locales;

  routerOutlet: any;

  private socketDto: SocketDto = {};

  private socketId: string;

  categories = [];
  itemTypes = [];
  techCats = [];

  selectedItemType = null;
  duplicateMode = false;

  socketForm: FormGroup = this.formBuilder.group({
    id: [null],
    recordVersion: [null],
    code: [null, [Validators.required]],
    name: [null, Validators.required],
    contentQty: this.formBuilder.group({
      min: [null],
      max: [null],
    }),
    socketCategory: [null, Validators.required],
    contents: this.formBuilder.array([]),
    parameters: this.formBuilder.array([]),
    texts: [null],
  });

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private adminSocketService: AdminSocketService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    private enumerationsService: EnumerationsService,
    public aclService: AclService
  ) {
    super(router, route);

    this.enumerationsService
      .getProductTechnicalCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.techCats = result;
      });

    this.enumerationsService
      .getProductSocketCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.categories = result;
      });

    this.enumerationsService
      .getSocketItemEntityType()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.itemTypes = result;
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const socketId = this.params.id;
      if (socketId) {
        if (socketId === '&') {
          this.setSocket(this.socketDto);
        } else {
          this.loadSocket(socketId);
        }
      }
    } else {
      this.socketDto = undefined;
    }
  }

  static validateOrderOfStartEnd: ValidatorFn = function (control: AbstractControl): ValidationErrors | null {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      const startControl = group.get('startDateTime');
      const endControl = group.get('endDateTime');
      const start = startControl.value;
      const end = endControl.value;
      if (start && end && start > end) {
        return { startIsLaterThanEnd: true };
      } else {
        if (control === startControl) {
          SocketEditComponent.deleteControlError(endControl, 'startIsLaterThanEnd');
        } else if (control === endControl) {
          SocketEditComponent.deleteControlError(startControl, 'startIsLaterThanEnd');
        }
      }
    }
    return null;
  };

  static deleteControlError(control: AbstractControl, errorKey: string) {
    if (control.errors) {
      delete control.errors[errorKey];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  get idControl(): AbstractControl {
    return this.socketForm.get('id');
  }

  get paramsFormArray(): FormArray {
    return this.socketForm.get('parameters') as FormArray;
  }

  get paramForms() {
    return this.paramsFormArray.controls as FormGroup[];
  }

  createParamForm(): FormGroup {
    return this.formBuilder.group({
      name: [, Validators.required],
      value: [, Validators.required],
    });
  }

  get contentsFormArray(): FormArray {
    return this.socketForm.get('contents') as FormArray;
  }

  get contentsItemEditForms() {
    return this.contentsFormArray.controls as FormGroup[];
  }

  createContentsItemEditForm(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      recordVersion: [null],
      entityType: [
        null,
        [
          Validators.required,
          validateEntityTypeDef,
          validateDuplicatedEntitiesInContents(this.socketForm.get('contents') as FormArray),
        ],
      ],
      entityId: [
        null,
        [Validators.required, validateDuplicatedEntitiesInContents(this.socketForm.get('contents') as FormArray)],
      ],
      contentQty: this.formBuilder.group({
        min: [null, [Validators.required, validateContentRange, validateMinMax, validateMinMaxDef]],
        max: [null, [Validators.required, validateMax, validateMinMax, validateMinMaxDef]],
        def: [
          null,
          [
            Validators.required,
            validateContentRange,
            validateMinMaxDef,
            validateEntityTypeDef,
            validateMinWithContentsDef(this.socketForm.get('contents') as FormArray),
            validateMaxWithContentsDef(this.socketForm.get('contents') as FormArray),
          ],
        ],
      }),
      validFor: this.formBuilder.group({
        startDateTime: [
          null,
          [
            Validators.required,
            SocketEditComponent.validateOrderOfStartEnd,
            validateDuplicatedEntitiesInContents(this.socketForm.get('contents') as FormArray),
          ],
        ],
        endDateTime: [
          null,
          [
            SocketEditComponent.validateOrderOfStartEnd,
            validateDuplicatedEntitiesInContents(this.socketForm.get('contents') as FormArray),
          ],
        ],
      }),
    });
  }

  loadSocket(socketId: string) {
    this.adminSocketService
      .getSocketById(socketId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setSocket(result);
        this.socketId = this.socketDto.id;
      });
  }

  setSocket(socket: SocketDto) {
    this.socketDto = socket;
    this.socketForm.patchValue(this.socketDto);
    if (this.duplicateMode) {
      this.duplicateMode = false;
      this.socketForm.controls.code.patchValue(`${this.socketDto.code}-copy`);
      this.socketForm.controls.id.patchValue(null);
      this.socketForm.controls.recordVersion.patchValue(null);
    }
    this.paramsFormArray.clear();
    if (this.socketDto.params) {
      for (const [key, val] of Object.entries(this.socketDto.params)) {
        const paramForm = this.createParamForm();
        paramForm.patchValue({ name: key, value: val });
        this.paramsFormArray.push(paramForm);
      }
    }
    this.contentsFormArray.clear();
    if (this.socketDto.contents) {
      this.socketDto.contents.forEach(item => {
        const contentsItemForm = this.createContentsItemEditForm();
        contentsItemForm.patchValue(item);
        this.contentsFormArray.push(contentsItemForm);
      });
    }
  }

  saveSocket(): boolean {
    FormUtils.validateAllFormFields(this.socketForm);
    if (this.socketForm.valid) {
      const socket = this.socketDto;
      if (socket) {
        Object.keys(this.socketForm.controls).forEach(field => {
          const control = this.socketForm.get(field);
          socket[field] = control.value;
        });
      }
      socket.params = {};
      this.paramForms.forEach(formGroup => {
        const key = formGroup.controls.name.value;
        const val = formGroup.controls.value.value;
        socket.params[key] = val;
      });
      return true;
    }
    return false;
  }

  save() {
    if (this.saveSocket()) {
      delete this.socketDto['parameters'];
      this.appBlockerService.block();
      if (!(this.socketId === null || this.socketId === undefined)) {
        this.socketDto.id = null;
        this.adminSocketService
          .updateSocket(this.socketId, this.socketDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getSocketHandler);
      } else {
        this.socketDto.contents.forEach(item => {
          item.id = null;
          item.recordVersion = null;
        });
        this.adminSocketService
          .createSocket(this.socketDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getSocketHandler);
      }
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditSocket(): SocketDto {
    return this.socketDto;
  }

  public reset() {
    this.setSocket(this.socketDto);
    this.socketId = this.socketDto.id;
    if (this.socketId) {
      this.navigateSelf({ id: this.socketId });
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.sockets.contextrule.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminSocketService
        .deleteSocket(this.socketDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(SocketListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  duplicate() {
    this.socketId = null;
    this.duplicateMode = true;
    this.navigateSelf({ id: '&' });
  }

  clearField(field: string) {
    this.socketForm.controls[field].patchValue(null);
  }

  public getSocketHandler = (socketDto: SocketDto): void => {
    let saving = false;
    if (this.socketDto) {
      saving = true;
    }
    if (socketDto && this.socketDto) {
      if (socketDto.id !== this.socketDto.id) {
        this.socketId = socketDto.id;
      }
      this.setSocket(socketDto);
      this.navigateSelf({ id: socketDto.id });
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  ngOnInit() {
    this.socketForm
      .get('contentQty')
      .get('min')
      .setValidators([
        Validators.required,
        validateSocketMin,
        validateMinWithContentsDef(this.socketForm.get('contents') as FormArray),
      ]);

    this.socketForm
      .get('contentQty')
      .get('max')
      .setValidators([
        Validators.required,
        validateMax,
        validateMaxWithContentsDef(this.socketForm.get('contents') as FormArray),
      ]);
  }

  cancel() {
    this.navigateSibling(SocketListComponent.PAGE_ID);
  }

  getEditContentsItems(): SocketContentDto[] {
    if (!this.socketDto.contents) {
      this.socketDto.contents = [];
    }
    return this.socketDto.contents;
  }

  removeContentsItem(i) {
    this.contentsFormArray.removeAt(i);
  }

  addContentsItem() {
    const item: SocketContentDto = {
      id: null,
      entityType: null,
      contentQty: { min: null, max: null, def: null },
      validFor: { startDateTime: null, endDateTime: null },
    };
    const itemForm = this.createContentsItemEditForm();
    itemForm.patchValue(item);
    this.contentsFormArray.push(itemForm);
  }

  getEditParams(): { [key: string]: string } {
    if (!this.socketDto.params) {
      this.socketDto.params = {};
    }
    return this.socketDto.params;
  }

  removeParam(i) {
    this.paramsFormArray.removeAt(i);
  }

  addParam() {
    const param = { name: null, value: null };
    const paramForm = this.createParamForm();
    paramForm.patchValue(param);
    this.paramsFormArray.push(paramForm);
  }

  itemTypeChanged(selectedItemType, contentsItemFormGroup: FormGroup) {
    contentsItemFormGroup.controls.entityId.patchValue(null);
    this.selectedItemType = selectedItemType;
  }

  formGroup(parent: FormGroup, item: string): FormGroup {
    return parent.controls[item] as FormGroup;
  }

  public static socketContentTimeCollision(validFrom, validTo, filterValidFrom, filterValidTo): boolean {
    if (
      filterValidFrom &&
      validFrom &&
      filterValidFrom &&
      ((!validTo && !filterValidTo) ||
        (validTo &&
          filterValidFrom.getTime() <= validTo.getTime() &&
          filterValidFrom.getTime() >= validFrom.getTime()) ||
        (filterValidTo &&
          validFrom.getTime() <= filterValidTo.getTime() &&
          validFrom.getTime() >= filterValidFrom.getTime()))
    ) {
      return true;
    } else {
      return false;
    }
  }

  isNewSocket() {
    return !this.socketDto.id || this.socketDto.id === '&';
  }
}

export const validateSocketMin: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const min = control.value;
  if (min != null && min != 0 && min != 1) {
    return { wrongSocketMin: true };
  } else {
    return null;
  }
};

export const validateContentRange: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (value != null && (value < 0 || value > 9999)) {
    return { wrongContentRange: true };
  } else {
    return null;
  }
};

export const validateMax: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const max = control.value;
  if (max != null && (max < -1 || max > 9999 || max === 0)) {
    return { wrongMax: true };
  } else {
    return null;
  }
};

export const validateMinMax: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const group: FormGroup = control.parent as FormGroup;
  if (group) {
    const minControl = group.get('min');
    const maxControl = group.get('max');
    const min = minControl.value;
    const max = maxControl.value;
    if (min != null && max != null && min > max && max != -1) {
      return { minGtMax: true };
    } else {
      if (control === minControl) {
        SocketEditComponent.deleteControlError(maxControl, 'minGtMax');
      } else if (control === maxControl) {
        SocketEditComponent.deleteControlError(minControl, 'minGtMax');
      }
    }
  }
  return null;
};

export const validateMinMaxDef: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const group: FormGroup = control.parent as FormGroup;
  if (group) {
    const minControl = group.get('min');
    const maxControl = group.get('max');
    const defControl = group.get('def');
    const min = minControl.value;
    const max = maxControl.value;
    const def = defControl.value;
    if (min != null && max != null && def != null && ((def > max && max != -1) || def < min)) {
      return { defNotBetweenMinMax: true };
    } else {
      if (control === minControl) {
        SocketEditComponent.deleteControlError(maxControl, 'defNotBetweenMinMax');
        SocketEditComponent.deleteControlError(defControl, 'defNotBetweenMinMax');
      } else if (control === maxControl) {
        SocketEditComponent.deleteControlError(minControl, 'defNotBetweenMinMax');
        SocketEditComponent.deleteControlError(defControl, 'defNotBetweenMinMax');
      } else if (control === defControl) {
        SocketEditComponent.deleteControlError(minControl, 'defNotBetweenMinMax');
        SocketEditComponent.deleteControlError(maxControl, 'defNotBetweenMinMax');
      }
    }
  }
  return null;
};

export const validateEntityTypeDef: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const group: FormGroup = control.parent as FormGroup;
  if (group) {
    let defControl = null;
    let entityTypeControl = null;
    if (group.get('def')) {
      defControl = group.get('def');
      entityTypeControl = group.parent.get('entityType');
    } else {
      defControl = group.get('contentQty').get('def');
      entityTypeControl = group.get('entityType');
    }

    const def = defControl.value;
    const entityType = entityTypeControl.value;
    if (entityType && def && entityType !== 'ProdCode' && def != 0) {
      return { entityTypeDefZero: true };
    } else {
      if (control === defControl) {
        SocketEditComponent.deleteControlError(entityTypeControl, 'entityTypeDefZero');
      } else if (control === entityTypeControl) {
        SocketEditComponent.deleteControlError(defControl, 'entityTypeDefZero');
      }
    }
  }
  return null;
};

export const validateMinWithContentsDef = (contents: FormArray) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      let minControl = null;
      const defControls = [];
      contents.controls.forEach(contentControl => defControls.push(contentControl.get('contentQty').get('def')));
      if (group.parent.get('code')) {
        minControl = group.get('min');
      } else {
        minControl = group.parent?.parent?.parent?.get('contentQty')?.get('min');
      }
      const min = minControl?.value;
      let sumDef = 0;
      defControls.forEach(defControl => (sumDef += defControl.value ? Number(defControl.value) : 0));

      if (min && min > sumDef) {
        return { minGtContentsDef: true };
      } else {
        if (control === minControl) {
          defControls.forEach(defControl => SocketEditComponent.deleteControlError(defControl, 'minGtContentsDef'));
        } else if (minControl) {
          SocketEditComponent.deleteControlError(minControl, 'minGtContentsDef');
        }
      }
    }
    return null;
  };
};

export const validateMaxWithContentsDef = (contents: FormArray) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      let maxControl = null;
      const defControls = [];
      contents.controls.forEach(contentControl => defControls.push(contentControl.get('contentQty').get('def')));
      if (group.parent.get('code')) {
        maxControl = group.get('max');
      } else {
        maxControl = group.parent?.parent?.parent?.get('contentQty').get('max');
      }
      const max = maxControl?.value;
      let sumDef = 0;
      defControls.forEach(defControl => (sumDef += defControl.value ? Number(defControl.value) : 0));

      if (max != null && max > -1 && max < sumDef) {
        return { maxGtContentsDef: true };
      } else {
        if (control === maxControl) {
          defControls.forEach(defControl => SocketEditComponent.deleteControlError(defControl, 'maxGtContentsDef'));
        } else if (maxControl) {
          SocketEditComponent.deleteControlError(maxControl, 'maxGtContentsDef');
        }
      }
    }
    return null;
  };
};

export const validateDuplicatedEntitiesInContents = (contents: FormArray) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      let entityType = null;
      let entityId = null;
      let validFrom = null;
      let validTo = null;

      if (group.get('entityType')) {
        entityType = group.get('entityType').value;
        entityId = group.get('entityId').value;
        validFrom = group.get('validFor').get('startDateTime').value;
        validTo = group.get('validFor').get('endDateTime').value;
      } else {
        entityType = group.parent.get('entityType').value;
        entityId = group.parent.get('entityId').value;
        validFrom = group.get('startDateTime').value;
        validTo = group.get('endDateTime').value;
      }

      if (validFrom != null && !(validFrom instanceof Date)) {
        validFrom = new Date(validFrom);
      }

      if (validTo != null && !(validTo instanceof Date)) {
        validTo = new Date(validTo);
      }

      const duplicatedControls = contents.controls.filter(contentControl => {
        const filterEntityType = contentControl.get('entityType').value;
        const filterEntityId = contentControl.get('entityId').value;
        let filterValidFrom = contentControl.get('validFor').get('startDateTime').value;
        let filterValidTo = contentControl.get('validFor').get('endDateTime').value;

        if (filterValidFrom != null && !(filterValidFrom instanceof Date)) {
          filterValidFrom = new Date(filterValidFrom);
        }

        if (filterValidTo != null && !(filterValidTo instanceof Date)) {
          filterValidTo = new Date(filterValidTo);
        }

        if (
          entityType === filterEntityType &&
          entityId === filterEntityId &&
          SocketEditComponent.socketContentTimeCollision(validFrom, validTo, filterValidFrom, filterValidTo)
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (entityType != null && entityId != null && validFrom != null && duplicatedControls.length > 1) {
        return { duplicatedEntities: true };
      } else {
        contents.controls.forEach(contentControl => {
          SocketEditComponent.deleteControlError(contentControl.get('entityType'), 'duplicatedEntities');
          SocketEditComponent.deleteControlError(contentControl.get('entityId'), 'duplicatedEntities');
          SocketEditComponent.deleteControlError(
            contentControl.get('validFor').get('startDateTime'),
            'duplicatedEntities'
          );
          SocketEditComponent.deleteControlError(
            contentControl.get('validFor').get('endDateTime'),
            'duplicatedEntities'
          );
        });
      }
    }
    return null;
  };
};
