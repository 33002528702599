import { Component } from '@angular/core';
import { EnumEntriesDto, StoreDto } from '@btl/admin-bff';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminDynamicEnumService,
  AdminStockService,
  AdminStoreService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { EnumerationsService } from '@service/enumerations.service';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { Animations } from '@helpers/animations';
import { StoresListingComponent } from '@components/resource/store/stores-listing/stores-listing.component';

@Component({
  selector: 'app-store-edit',
  templateUrl: './store-edit.component.html',
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: StoreEditComponent.PAGE_ID })
export class StoreEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'StoreEditComponent';

  pageId(): string {
    return StoreEditComponent.PAGE_ID;
  }

  savingStoreId;
  store: StoreDto;
  storeTypes: EnumEntriesDto;

  form: FormGroup = this.formBuilder.group({
    id: [null],
    type: [null, Validators.required],
    extId: [null, Validators.required],
    ouRefNo: [null, Validators.required],
    recordVersion: [null],
    params: [],
  });

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private adminStoreService: AdminStoreService,
    private adminStockService: AdminStockService,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private stickyMessageService: StickyMessageService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    public aclService: AclService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super(router, route);

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.STOCK, 'com.emeldi.ecc.be.stock.enums.StoreType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.storeTypes = result;
        this.storeTypes.data = this.storeTypes.data.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const storeId = this.params.id;
      if (storeId && storeId !== 'newStore') {
        this.loadStore(storeId);
      } else {
        this.store = { type: '', id: null };
      }
    } else {
      this.store = undefined;
    }
  }

  loadStore(storeId) {
    this.adminStoreService
      .getStore(storeId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadStore(result);
      });
  }

  reloadStore(storeDto: StoreDto) {
    this.store = storeDto;
    this.form.reset(this.store);
  }

  public getStoreHandler = (storeDto: StoreDto): void => {
    if (storeDto) {
      if (storeDto.id === this.savingStoreId) {
        this.reloadStore(storeDto);
      } else {
        this.reloadStore(storeDto);
        this.navigateSelf({ id: storeDto.id });
      }
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  reset() {
    if (this.store && this.store.recordVersion) {
      this.loadStore(this.store.id);
    } else {
      this.reloadStore(this.store);
    }
  }

  delete() {
    this.appBlockerService.block();

    const stockSearch: Search = {
      filtering: [{ column: 'store.id', compareType: 'EQUAL', value: this.store.id }],
      sorting: [],
      paging: {
        page: 1,
        pageSize: 1,
      },
    };

    this.adminStockService
      .filterStocks(stockSearch)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        if (result?.data.length > 0) {
          const confirmationDialogComponent = this.confirmationDialogService.openDialog([
            'wc.admin.stores.deleteUsed.confirmation.text',
          ]);
          confirmationDialogComponent.confirmationHandler = dialogReference => {
            this.deleteStore();
            confirmationDialogComponent.dialogReference.close();
          };
        } else {
          this.deleteStore();
        }
      });
  }

  deleteStore() {
    this.appBlockerService.block();
    this.adminStoreService
      .deleteStore(this.store.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.navigateSibling(StoresListingComponent.PAGE_ID);
        this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
      });
  }

  duplicate() {
    this.form.controls.extId.patchValue(`${this.store.extId}copy`);
    this.form.controls.recordVersion.patchValue(null);
    this.form.controls.id.patchValue(null);
  }

  save() {
    this.savingStoreId = this.store.id;
    if (this.saveStoreAttributes()) {
      this.appBlockerService.block();
      if (this.store.recordVersion) {
        this.adminStoreService
          .updateStore(this.savingStoreId, this.store)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getStoreHandler);
      } else {
        this.savingStoreId = null;
        this.adminStoreService
          .createStore(this.store)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getStoreHandler);
      }
    }
  }

  saveStoreAttributes(): boolean {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      if (this.store) {
        this.store = this.form.getRawValue();
      }
      return true;
    }

    return false;
  }

  clearField(field: string) {
    this.form.controls[field].patchValue(null);
  }

  cancel() {
    this.navigateSibling(StoresListingComponent.PAGE_ID);
  }
}
