import { PictureTypeDto } from '@btl/admin-bff';

export class ProductUtils {
  public static getPictureTypeDto(pictureTypes, pictureTypeName: string): PictureTypeDto {
    let pictureTypeDto: PictureTypeDto;
    if (pictureTypes) {
      pictureTypes.forEach(pictureType => {
        if (pictureType.id === pictureTypeName) {
          pictureTypeDto = pictureType;
        }
      });
    }

    return pictureTypeDto;
  }
}
