import { Injectable } from '@angular/core';
import { ReleasesSelectModalComponent } from '@components/releases-select/releases-select-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ReleasesSelectService {
  constructor(private dialog: MatDialog) {}

  selectReleases(memberType: string, memberCode: string) {
    ReleasesSelectModalComponent.selectReleases(this.dialog, memberType, memberCode);
  }
}
