import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { INode } from '@components/product-catalogue/promotion/edit/promotion-design/promotion-design.component';
import { FormUtils } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-selected-root-product, [app-selected-root-product]',
  templateUrl: './selected-root-product.component.html',
  styleUrls: ['./selected-root-product.component.scss'],
})
export class SelectedRootProductComponent implements OnInit, OnChanges {
  @Input()
  node: INode;

  @Output()
  readonly deleteNode = new EventEmitter<INode>();

  @Output()
  readonly closeEmitter = new EventEmitter<null>();

  productCode: string;

  PickerInputType = PickerInputType;

  form: FormGroup = this.formBuilder.group({
    productCode: [null, [Validators.required]],
  });

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.patchForm();
    this.form.valueChanges.subscribe(valueChange => {
      this.node.productCode = valueChange.productCode;
    });
  }

  ngOnChanges(changes: any) {
    this.patchForm();
  }

  validate() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      return true;
    } else {
      return false;
    }
  }

  patchForm() {
    this.productCode = this.node.productCode;
    if (this.productCode) {
      this.form.controls['productCode'].setValue(this.productCode);
    }
  }

  delete() {
    this.deleteNode.emit(this.node);
  }
}
