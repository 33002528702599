<div class="card__inner" [formGroup]="form">
  <div class="card__header" [ngClass]="{ 'border-unset': collapsed }">
    <div class="card-title-container">
      {{ headerTranslationKey ? headerTranslationKey : ('wc.admin.productGroups.parameters' | translate) }}
      <div class="card-title-dropdown-box">
        <button
          id="001_form_parameters"
          color="primary"
          mat-raised-button
          (click)="addParam()"
          [routerLink]=""
          [disabled]="disabled || (getNotUsedParameterNames() && getNotUsedParameterNames().length === 0)"
          class="btn -primary -xs edit icon stroke">
          <mat-icon svgIcon="plus"></mat-icon>
          {{ 'wc.admin.productGroups.parameter.new.parameter' | translate }}
        </button>
        <button
          id="011_form_parameters"
          color="primary"
          mat-raised-button
          *ngIf="module"
          (click)="addProfileMetaParameters()"
          [routerLink]=""
          [disabled]="disabled"
          class="btn -primary -xs edit icon stroke">
          <mat-icon svgIcon="plus"></mat-icon>
          {{ 'wc.common.addProfileMetaParameters' | translate }}
        </button>
        <div class="iconCollapse" [ngClass]="collapsed ? '-collapsed' : '-opened'" (click)="collapsed = !collapsed">
          <mat-icon id="002_form_parameters" color="primary" svgIcon="{{ collapsed ? 'show' : 'hide' }}"></mat-icon>
        </div>
      </div>
    </div>
  </div>

  <!--  <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">-->
  <!--    <div fxLayout="column">-->
  <!--      <span class="listing-header">{{headerTranslationKey ? headerTranslationKey : 'wc.admin.productGroups.parameters' | translate}}</span>-->
  <!--    </div>-->
  <!--    <div fxLayoutGap="10px" class="listing-action">-->
  <!--      <button id="001_form_parameters" color="primary" mat-raised-button (click)="addParam()" [routerLink]=""-->
  <!--              [disabled]="disabled" class="btn -primary -xs edit">-->
  <!--        <mat-icon class="default-icon">create</mat-icon>-->
  <!--        {{'wc.common.add' | translate}}-->
  <!--      </button>-->
  <!--      <button id="011_form_parameters" color="primary" mat-raised-button (click)="addProfileMetaParameters()" [routerLink]=""-->
  <!--              [disabled]="disabled" class="btn -primary -xs edit">-->
  <!--        <mat-icon class="default-icon">create</mat-icon>-->
  <!--        {{'wc.common.addProfileMetaParameters' | translate}}-->
  <!--      </button>-->
  <!--      <mat-icon id="002_form_parameters" (click)="collapsed = !collapsed" color="primary" svgIcon="{{collapsed ? 'hide' : 'show'}}"></mat-icon>-->
  <!--    </div>-->
  <!--  </div>-->

  <div class="card__body" [hidden]="collapsed">
    <div class="form-parameters-container">
      <ng-container *ngFor="let parameterForm of getFormArray().controls; let i = index">
        <form [formGroup]="parameterForm"
              [hidden]="hideParameter(parameterForm.get('name').value)"
              class="form-parameters-item-wrapper" id="003_form_parameters_{{ i }}">
          <div class="form-parameters-item-container">
            <div class="form-parameters-item-flex">
              <div class="form-parameters-item-box">
                <div class="form-parameters-item-name" id="004_form_parameters_{{ i }}">
                  {{ parameterForm.get('name').value }}
                </div>
                <div class="form-parameters-item-value" id="005_form_parameters_{{ i }}">
                  {{ parameterForm.get('value').value }}
                </div>
              </div>
              <div class="form-parameters-item-box" *ngIf="!disabled">
                <button id="006_form_parameters_{{ i }}" mat-icon-button (click)="edit(parameterForm)">
                  <mat-icon class="default-icon">edit</mat-icon>
                </button>
                <button id="007_form_parameters_{{ i }}" mat-icon-button (click)="remove(parameterForm)">
                  <mat-icon class="default-icon">delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </form>
      </ng-container>

      <div class="empty-state_container" *ngIf="getFormArray().controls.length === 0">
        <div class="empty-state_img">
          <mat-icon style="width: 100px; height: 105px" svgIcon="widgetEmpty"></mat-icon>
        </div>

        <div class="empty-state_text">
          <div>{{ 'wc.admin.cms.content.parameter.empty.title' | translate }}</div>
          <div>{{ 'wc.admin.cms.content.parameter.empty.text' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
