import { Component } from '@angular/core';
import { EnumEntriesDto, PagedStoresDto, StockDto } from '@btl/admin-bff';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminDynamicEnumService,
  AdminStockService,
  AdminStoreService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  ServiceUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import { StocksListingComponent } from '@components/resource/stock/stores-listing/stocks-listing.component';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';

@Component({
  selector: 'app-stock-edit',
  templateUrl: './stock-edit.component.html',
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: StockEditComponent.PAGE_ID })
export class StockEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'StockEditComponent';

  pageId(): string {
    return StockEditComponent.PAGE_ID;
  }

  savingStockId;
  duplicateStockId;
  stock: StockDto;
  stockTypes: EnumEntriesDto;
  pagedStoresDto: PagedStoresDto;

  PickerInputType = PickerInputType;

  form: FormGroup = this.formBuilder.group({
    id: [null],
    type: [null, Validators.required],
    productCode: [null, Validators.required],
    amount: [null, Validators.required],
    store: this.formBuilder.group({
      id: [null, Validators.required],
      extId: [null, Validators.required],
      type: [null, Validators.required],
    }),
    recordVersion: [null],
  });

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private adminStockService: AdminStockService,
    private adminStoreService: AdminStoreService,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private stickyMessageService: StickyMessageService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    public aclService: AclService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super(router, route);

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.STOCK, 'com.emeldi.ecc.be.stock.enums.StockType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.stockTypes = result;
        this.stockTypes.data = this.stockTypes.data.sort((a, b) => a.name.localeCompare(b.name));
      });

    this.adminStoreService
      .filterStores(ServiceUtils.getUnlimitedSearch())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.pagedStoresDto = result;
      });

    this.form.get('store').get('type').disable();
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const stockId = this.params.id;
      if (stockId && stockId !== 'newStock') {
        this.loadStock(stockId);
      } else {
        this.stock = { amount: 0, productCode: '', store: undefined, type: '', id: null };
      }
    } else {
      this.stock = undefined;
    }
  }

  loadStock(stockId) {
    this.adminStockService
      .getStock(stockId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadStock(result);
      });
  }

  reloadStock(stockDto: StockDto) {
    this.duplicateStockId = null;
    this.stock = stockDto;
    this.form.reset(this.stock);
  }

  public getStockHandler = (stockDto: StockDto): void => {
    if (stockDto) {
      if (stockDto.id === this.savingStockId || stockDto.id === this.duplicateStockId) {
        this.reloadStock(stockDto);
      } else {
        this.reloadStock(stockDto);
        this.navigateSelf({ id: stockDto.id });
      }
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  reset() {
    if (this.stock && this.stock.recordVersion) {
      this.loadStock(this.stock.id);
    } else {
      this.reloadStock(this.stock);
    }
  }

  deleteStock() {
    this.appBlockerService.block();
    this.adminStockService
      .deleteStock(this.stock.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.navigateSibling(StocksListingComponent.PAGE_ID);
        this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
      });
  }

  duplicate() {
    this.duplicateStockId = this.form.controls.id.value;
    this.form.controls.recordVersion.patchValue(null);
    this.form.controls.id.patchValue(null);
  }

  save() {
    this.savingStockId = this.stock.id;
    if (this.saveStockAttributes()) {
      this.appBlockerService.block();
      if (this.stock.recordVersion) {
        this.adminStockService
          .updateStock(this.savingStockId, this.stock)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getStockHandler);
      } else {
        this.savingStockId = null;

        const search = ServiceUtils.getUnlimitedSearch();
        search.filtering = [
          {
            column: 'productCode',
            compareType: 'EQUAL',
            value: this.stock.productCode,
          },
          {
            column: 'store.id',
            compareType: 'EQUAL',
            value: this.stock.store.id,
          },
        ];
        this.adminStockService
          .filterStocks(search)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            if (result.data.length === 0) {
              this.adminStockService
                .createStock(this.stock)
                .pipe(finalize(this.appBlockerService.unblock))
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(this.getStockHandler);
            } else {
              const confirmationDialogComponent = this.confirmationDialogService.openDialog([
                'wc.admin.resource.stock.updateExisting.confirmation.text',
              ]);

              confirmationDialogComponent.confirmationHandler = () => {
                this.adminStockService
                  .createStock(this.stock)
                  .pipe(finalize(this.appBlockerService.unblock))
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(this.getStockHandler);
                confirmationDialogComponent.dialogReference.close();
              };
            }
          });
      }
    }
  }

  saveStockAttributes(): boolean {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      if (this.stock) {
        this.stock = this.form.getRawValue();
      }
      return true;
    }

    return false;
  }

  clearField(field: string) {
    this.form.controls[field].patchValue(null);
  }

  cancel() {
    this.navigateSibling(StocksListingComponent.PAGE_ID);
  }

  storeChanged(store, field: string) {
    if (field === 'extId') {
      store = this.pagedStoresDto.data.find(filterStore => filterStore.extId === store);
    }
    if (!store) {
      this.form.get('store').get('id').patchValue(null);
      this.form.get('store').get('extId').patchValue(null);
      this.form.get('store').get('type').patchValue(null);
    } else {
      if (field === 'extId') {
        this.form.get('store').get('id').patchValue(store.id);
      } else if (field === 'id') {
        this.form.get('store').get('extId').patchValue(store.extId);
      }
      this.form.get('store').get('type').patchValue(store.type);
    }
  }
}
