<mat-menu #childMenu="matMenu" class="row-5" [overlapTrigger]="false">
  <span *ngFor="let subModule of subModules">
    <ng-container>
      <button mat-menu-item [routerLink]="[subModule.parentUrlPattern + subModule.urlPattern]">
        <mat-icon [svgIcon]="subModule.cssClass"></mat-icon>
        {{ pageModules.getModuleText(subModule.code) }}
      </button>
    </ng-container>
  </span>
</mat-menu>
