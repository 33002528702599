<mat-nav-list>
  <app-left-menu-item
    *ngFor="let codebookModule of mainModules"
    class="left-menu-item"
    [module]="codebookModule"
    [closed]="closed"
    [modules]="modules"
    [collapsed]="collapsed"></app-left-menu-item>
</mat-nav-list>

<button
  *ngIf="!secondMenu"
  class="toggle-menu"
  type="button"
  aria-label="Toggle sidenav"
  mat-icon-button
  (click)="toggleClick()">
  <mat-icon
    aria-label="Side nav toggle icon"
    [@indicatorRotate]="collapsed ? 'collapsed' : 'expanded'"
    svgIcon="chevronLeft">
  </mat-icon>
</button>
