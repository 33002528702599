import { Component, Input, ViewChild } from '@angular/core';
import { CmsContentsListComponent } from '@components/cms/contents/list/cms-contents-list.component';

@Component({
  selector: 'app-cms-select-modal',
  templateUrl: './cms-select-modal.component.html',
  styleUrls: ['./cms-select-modal.component.scss'],
})
export class CmsSelectModalComponent {
  @ViewChild(CmsContentsListComponent, { static: true })
  cmsContentsListComponent: CmsContentsListComponent;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  @Input()
  singleSelectMode = true;

  @Input()
  disableActions = false;

  @Input()
  disableListingActions = true;

  @Input()
  disableExpand = true;

  @Input()
  selectMode = false;

  @Input()
  isModal: boolean = true;

  excludePromotionIds = [];

  selectEvent() {
    if (this.singleSelectMode) {
      const cmsContentDto = this.cmsContentsListComponent.getSelectedData()[0];
      if (cmsContentDto) {
        this.selectHandler(cmsContentDto);
        this.dialogRef.close();
      }
    }
  }

  selectNothingEvent() {
    this.dialogRef.close();
  }

  singleSelect(cmsContent) {
    this.selectHandler(cmsContent);
    this.dialogRef.close();
  }
}
