import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss']
})
export class GeneralModalComponent implements OnInit {

  @Input()
  headerTranslationKey;

  @Input()
  messageTranslationKey;

  @Input()
  messageTranslationEnable = true;

  @Input()
  dialogRef;

  @Input()
  closeButtonHandler;

  @Input()
  showOkButton = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
