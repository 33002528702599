<h2 mat-dialog-title>
  {{ parameter?.name }}
  <ng-container *ngIf="overwriteParent" class="warning" fxLayoutAlign="center center">
    {{ 'wc.admin.productParameters.overwriteParent.warning' | translate }}
  </ng-container>

  <div class="buttons">
    <mat-icon
      *ngIf="parameter?.name"
      (click)="delete()"
      matTooltip="{{ 'wc.common.delete' | translate }}"
      class="default-icon-gray-fill no-stroke"
      svgIcon="delete">
    </mat-icon>

    <mat-icon
      class="default-icon-gray-fill no-stroke"
      svgIcon="close"
      mat-dialog-close
      matTooltip="{{ 'wc.common.close' | translate }}">
    </mat-icon>
  </div>
</h2>
<mat-dialog-content class="modal-body">
  <div class=".mat-drawer-container">
    <div class="mat-sidenav-content">
      <div class="main_content">
        <app-ticket-parameter
          style="display: contents"
          *ngIf="parameterType === 'ticket'"
          [parentForm]="parentForm"
          [ticketTypeDto]="ticketTypeDto"
          [parameter]="parameter"
          id="002_parameter_detail_modal"
          [enableLocalizedNames]="enableLocalizedNames"
          [enableMetaParameters]="enableMetaParameters"
          [enableAcl]="enableAcl">
        </app-ticket-parameter>

        <app-category-parameter
          style="display: contents"
          *ngIf="parameterType === 'category'"
          [module]="module"
          [parentParametersMode]="parentParametersMode"
          [parentParameters]="parentParameters"
          [overwriteParent]="overwriteParent"
          [parentForm]="parentForm"
          id="003_parameter_detail_modal"
          [enableLocalizedNames]="enableLocalizedNames"
          [enableMetaParameters]="enableMetaParameters"
          [enableAcl]="enableAcl"
          [parameter]="parameter">
        </app-category-parameter>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a id="004_parameter_detail_modal" (click)="close()" [routerLink]="" color="primary" mat-raised-button
    >{{ 'wc.common.cancel.button' | translate }}
  </a>
  <a
    id="005_parameter_detail_modal"
    (click)="save()"
    *ngIf="aclService.pageEditAccess"
    [routerLink]=""
    color="primary"
    mat-raised-button
    >{{ 'wc.common.save' | translate }}
  </a>
</mat-dialog-actions>
