import { Component, EventEmitter } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminLocalizedTextService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { LocalizedTextDto, PagedLocalizedTextsDto } from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Animations } from '@helpers/animations';
import { EnumerationsService } from '@service/enumerations.service';
import { LocalizedTextListComponent } from '@components/localized-text/list/localized-text-list.component';

@Component({
  selector: 'app-localized-text-edit-locales',
  templateUrl: './localized-text-edit-locales.component.html',
  styleUrls: ['./localized-text-edit-locales.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: LocalizedTextEditLocalesComponent.PAGE_ID })
export class LocalizedTextEditLocalesComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'LocalizedTextEditLocalesComponent';

  pageId(): string {
    return LocalizedTextEditLocalesComponent.PAGE_ID;
  }

  routerOutlet: any;

  editLocalizedTextChanged = new EventEmitter<LocalizedTextDto[]>();

  locales = environment.localization.locales;

  private isNew: boolean = true;
  private module: string;
  private key: string;

  private localizedTexts: LocalizedTextDto[] = [];
  private localeLocalizedTextMap = new Map<string, LocalizedTextDto>();

  modules: string[] = [];

  localizedTextForm: FormGroup = this.formBuilder.group({
    module: [null, [Validators.required, Validators.maxLength(100)]],
    key: [null, [Validators.required, Validators.maxLength(200)]],
    locales: this.formBuilder.array([]),
  });

  get moduleControl(): AbstractControl {
    return this.localizedTextForm.get('module');
  }

  get keyControl(): AbstractControl {
    return this.localizedTextForm.get('key');
  }

  get localesFormArray(): FormArray {
    return <FormArray>this.localizedTextForm.get('locales');
  }

  get localeForms() {
    return <FormGroup[]>(<FormArray>this.localizedTextForm.get('locales')).controls;
  }

  createLocaleForm(moduleControl: AbstractControl, keyControl: AbstractControl): FormGroup {
    return this.formBuilder.group({
      id: [null],
      module: [],
      key: [],
      locale: [, [Validators.required, Validators.maxLength(5)]],
      defaultValue: [''],
      customValue: [],
      recordVersion: [null],
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private adminLocalizedTextService: AdminLocalizedTextService,
    private stickyMessageService: StickyMessageService,
    private enumerationsService: EnumerationsService,
    public aclService: AclService
  ) {
    super(router, route);
    this.enumerationsService
      .getLocalizedTextModules()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.modules = result;
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const module = this.params.module;
      const key = this.params.key;
      if (module) {
        if (module === '&') {
          this.setLocalizedTexts(null, null, [], true);
        } else if (key) {
          this.loadLocalizedTexts(module, key);
        }
      }
    } else {
      this.localizedTexts = undefined;
    }
  }

  loadLocalizedTexts(module: string, key: string) {
    const search: Search = {
      filtering: [
        { column: 'module', value: module, compareType: 'EQUAL' },
        { column: 'key', value: key, compareType: 'EQUAL' },
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: -1,
      },
    };
    this.adminLocalizedTextService
      .getLocalizedTextsByFilter(search)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setLocalizedTexts(module, key, result.data, false);
      });
  }

  setLocalizedTexts(module: string, key: string, localizedTexts: LocalizedTextDto[], isNew: boolean) {
    this.localizedTexts = localizedTexts;
    this.module = module;
    this.key = key;
    this.isNew = isNew;

    this.localeLocalizedTextMap = new Map<string, LocalizedTextDto>();
    this.localizedTexts.forEach(lt => {
      this.localeLocalizedTextMap.set(lt.locale, lt);
    });

    this.locales.forEach(locale => {
      if (!this.localeLocalizedTextMap.get(locale)) {
        const newLocalizedText: LocalizedTextDto = {
          module: module,
          key: key,
          locale: locale,
        };
        this.localeLocalizedTextMap.set(locale, newLocalizedText);
      }
    });
    this.localizedTextForm.patchValue({ module: module, key: key });
    this.localizedTextForm.reset();
    if (module) {
      this.localizedTextForm.patchValue({ module: module });
    }
    if (key) {
      this.localizedTextForm.patchValue({ key: key });
    }

    this.localesFormArray.clear();

    this.localeLocalizedTextMap.forEach(locale => {
      const localeForm = this.createLocaleForm(this.moduleControl, this.keyControl);
      localeForm.controls['locale'].disable();
      if (!this.isNew) {
        localeForm.controls['defaultValue'].disable();
      }
      localeForm.patchValue(locale);
      this.localesFormArray.push(localeForm);
    });

    if (localizedTexts) {
      this.editLocalizedTextChanged.emit(this.localizedTexts);
    }

    if (!isNew) {
      this.localizedTextForm.controls['module'].disable();
      this.localizedTextForm.controls['key'].disable();
    }
  }

  saveLocalizedText(): boolean {
    FormUtils.validateAllFormFields(this.localizedTextForm);
    if (this.localizedTextForm.valid) {
      const module_val = this.localizedTextForm.get('module').value;
      const key_val = this.localizedTextForm.get('key').value;

      this.localesFormArray.controls.forEach(localeForm => {
        const locale_val = localeForm.get('locale').value;
        const defaultValue_val = localeForm.get('defaultValue').value;
        const customValue_val = localeForm.get('customValue').value;
        const dto = this.localeLocalizedTextMap.get(locale_val);
        dto.defaultValue = defaultValue_val ? defaultValue_val : '';
        dto.customValue = !customValue_val ? null : (customValue_val as string).trim() === '' ? null : customValue_val;
        dto.module = module_val;
        dto.key = key_val;
      });
      this.module = module_val;
      this.key = key_val;
      return true;
    }
    return false;
  }

  save() {
    if (this.saveLocalizedText()) {
      let countDown = this.localeLocalizedTextMap.size;
      const createDtos: LocalizedTextDto[] = [];
      const updateDtos: LocalizedTextDto[] = [];

      this.localeLocalizedTextMap.forEach(dto => {
        if (dto.id) {
          updateDtos.push(dto);
        } else {
          createDtos.push(dto);
        }
      });

      this.appBlockerService.block();

      createDtos.forEach(dto => {
        this.adminLocalizedTextService
          .createLocalizedText(dto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(() => {
            countDown--;
            if (countDown <= 0) {
              this.loadLocalizedTextsAfterSave();
            }
          });
      });

      updateDtos.forEach(dto => {
        this.adminLocalizedTextService
          .updateLocalizedText(dto.id, dto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(() => {
            countDown--;
            if (countDown <= 0) {
              this.loadLocalizedTextsAfterSave();
            }
          });
      });
    }
  }

  loadLocalizedTextsAfterSave() {
    this.loadLocalizedTexts(this.module, this.key);
    this.navigateSelf({ module: this.module, key: this.key });
    this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditLocalizedTexts(): LocalizedTextDto[] {
    return this.localizedTexts;
  }

  public reset() {
    this.setLocalizedTexts(this.module, this.key, this.localizedTexts, this.isNew);
  }

  public getLocalizedTextHandler = (pagedLocalizedTextsDto: PagedLocalizedTextsDto): void => {
    const localizedTextDtos: LocalizedTextDto[] = pagedLocalizedTextsDto.data;
    if (localizedTextDtos && this.localizedTexts) {
      this.setLocalizedTexts(this.module, this.key, localizedTextDtos, this.isNew);
      this.navigateSelf({ module: this.module, key: this.key });
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  // ngOnInit() {
  // maybe used in future
  // this.localizedTextForm.get('module').valueChanges.subscribe(value => {
  //   this.localeForms.forEach(localeForm => {
  //     localeForm.get('module').setValue(value);
  //   })
  // });
  // this.localizedTextForm.get('key').valueChanges.subscribe(value => {
  //   this.localeForms.forEach(localeForm => {
  //     localeForm.get('key').setValue(value);
  //   })
  // });
  // }

  cancel() {
    this.navigateSibling(LocalizedTextListComponent.PAGE_ID);
  }
}
