<div
  [ngClass]="{ expanded: hasModules() && expanded && !isChild, collapsed: collapsed }"
  *ngIf="pageModules.modulesTexts">
  <a
    mat-list-item
    (click)="itemSelected()"
    [ngStyle]="{ 'padding-left': depth * 12 + 'px' }"
    [matTooltip]="collapsed ? pageModules.getModuleText(module.code) : null"
    [matTooltipPosition]="'right'"
    matTooltipClass="left-menu-tooltip"
    [ngClass]="{ '-active': isActive(), 'expanded': expanded }">
    <mat-icon [svgIcon]="module.cssClass"></mat-icon>
    <span class="menu__item" *ngIf="!collapsed">{{ pageModules.getModuleText(module.code) }}</span>
    <span class="expand-arrow" *ngIf="hasModules() && !collapsed">
      <span></span>
      <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'"> expand_more </mat-icon>
    </span>
  </a>
  <div class="expanded-menu" *ngIf="expanded && subModules.length > 0">
    <app-left-menu-item
      *ngFor="let codebookModule of subModules"
      [module]="codebookModule"
      [modules]="modules"
      [isChild]="true"
      [collapsed]="collapsed"
      [subModule]="true">
    </app-left-menu-item>
  </div>
</div>
