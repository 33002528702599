import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-input-drag-chips-form-field',
  templateUrl: './input-drag-chips-form-field.component.html',
  styleUrls: ['./input-drag-chips-form-field.component.scss'],
})
export class InputDragChipsFormFieldComponent implements OnInit, OnChanges {
  @Input()
  id: string;

  @Input()
  controlName;

  @Input()
  parentGroup: FormGroup;

  @Input()
  contextRules;

  @Input()
  value;

  @Input()
  translationPrefix: string;

  @Input()
  disabled = false;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  ruleExpressionControl = new FormControl();
  ruleExpression = [];

  showAutoComplete: boolean = false;

  @ViewChild('ruleExpressionInput') ruleExpressionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  ngOnInit(): void {
    this.ruleExpression = this.value ? this.value.split(' ') : [];
  }

  ngOnChanges(changes: SimpleChanges) {
    const value = changes['value'];
    if (value.currentValue !== value.previousValue) {
      this.ruleExpression = this.value ? this.value.split(' ') : [];
    }
  }

  onInputChange(event) {
    this.showAutoComplete = event.trim().startsWith('@');
  }

  checkLogicalAndNot(chipItem) {
    return chipItem.toUpperCase() === 'AND' || chipItem.toUpperCase() === 'NOT';
  }

  checkLogicalOr(chipItem) {
    return chipItem.toUpperCase() === 'OR';
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.ruleExpression, event.previousContainer.data.index, event.container.data.index);
    this.handleRuleExpressionChange();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.ruleExpression.push(event.option.value);
    this.ruleExpressionInput.nativeElement.value = '';
    this.ruleExpressionControl.setValue('');
    this.showAutoComplete = false;
    this.handleRuleExpressionChange();
  }

  handleRuleExpressionChange() {
    //Reset value, set new
    this.parentGroup.controls[this.controlName].patchValue(null);
    this.parentGroup.controls[this.controlName].patchValue(this.ruleExpression.join(' '));
  }

  highlightAutocomplete(optionItem: string): string {
    const highlightValue = this.ruleExpressionControl.value;
    if (highlightValue) {
      return optionItem.replace(highlightValue, `<b>${highlightValue}</b>`);
    }
    return optionItem;
  }

  removeRuleExpression(index: number) {
    this.ruleExpression.splice(index, 1);
    this.handleRuleExpressionChange();
  }

  addRuleExpression(event) {
    const value = event.value.trim();
    if (value) {
      this.ruleExpression.push(value);
      event.input!.value = '';
      this.ruleExpressionControl.setValue('');
      this.handleRuleExpressionChange();
    }
    this.showAutoComplete = false;
  }
}
