import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  AdminReleaseService,
  AppBlockerService,
  ModulePreferencesService,
  PaginationComponent,
  Search,
  ServiceUtils
} from '@btl/btl-fe-wc-common';
import { MemberDto, PagedMembersDto } from '@btl/admin-bff';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {
  MembersListingItemComponent
,
} from '@components/product-catalogue/import-export/release-edit/members-listing/members-listing-item/members-listing-item.component';
import { StickersSelectModalComponent } from '@components/stickers-select-modal/stickers-select-modal.component';
import { ProductsSelectModalComponent } from '@components/products-select-modal/products-select-modal.component';
import { GroupsSelectModalComponent } from '@components/groups-select-modal/groups-select-modal.component';
import { PromotionsSelectModalComponent } from '@components/promotions-select-modal/promotions-select-modal.component';
import {
  RulesSelectModalComponent
,
} from '@components/acl/components/acl-rules-select-modal/rules-select-modal.component';
import { CategoriesSelectModalComponent } from '@components/categories-select-modal/categories-select-modal.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-members-listing, [app-members-listing]',
  templateUrl: './members-listing.component.html',
  styleUrls: ['./members-listing.component.scss'],
})
export class MembersListingComponent implements OnInit, OnDestroy, OnChanges {
  private static readonly MODULE_ID = 'membersListingComponent';
  public static readonly OPERATION_TYPE = 'operationType';

  @Input()
  cacheFilter = true;

  @Input()
  additionalHeaderLtKey;

  @Input()
  release;

  @Input()
  changesMode = false;

  @Input()
  hideFilter = false;

  @Input()
  operations;

  @ViewChild(PaginationComponent, { static: true })
  paginationComponent: PaginationComponent;

  @ViewChildren(MembersListingItemComponent)
  membersListingItemComponents: Array<MembersListingItemComponent>;

  baseFilterVisible = true;

  @Input()
  editable = true;

  @Output()
  readonly deleteMemberChangeEvent: EventEmitter<MemberDto> = new EventEmitter<MemberDto>();

  pagedMembersDto: PagedMembersDto;

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };

  memberTypes = Object.keys(MemberTypes).map(key => MemberTypes[key]);

  isCollapsed = false;

  editableMembersListingItemComponent;

  newMember;

  private onDestroy$: Subject<void> = new Subject<void>();
  memberType: any;

  constructor(
    private adminReleaseService: AdminReleaseService,
    private router: Router,
    private route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private dialog: MatDialog,
    private modulePreferencesService: ModulePreferencesService
  ) {}

  ngOnInit() {
    if (this.release?.recordVersion) {
      if (this.cacheFilter) {
        const sessionSearch = this.modulePreferencesService.getPreferences(MembersListingComponent.MODULE_ID);
        if (sessionSearch) {
          this.search = sessionSearch;
        }
      }
    }
  }

  toggleFilter() {
    this.baseFilterVisible = !this.baseFilterVisible;
  }

  ngOnChanges(changes: any) {
    if (this.release && this.release.id) {
      this.loadMembers(this.search);
    }
  }

  onSortChange() {
    this.loadMembers(this.search);
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadMembers(this.search);
  }

  goToPage(pageNo: number): void {
    this.search.paging.page = pageNo;
    this.loadMembers(this.search);
  }

  onNext(): void {
    this.search.paging.page++;
    this.loadMembers(this.search);
  }

  onPrev(): void {
    this.search.paging.page--;
    this.loadMembers(this.search);
  }

  pageSizeChanged(page): void {
    this.search.paging.page = page.pageIndex + 1;
    this.search.paging.pageSize = page.pageSize;
    this.loadMembers(this.search);
  }

  deleteMember(memberDto: MemberDto) {
    const member = this.operations.find(member => member.id === memberDto.id);
    if (member) {
      this.operations.splice(this.operations.indexOf(member), 1);
      this.deleteMemberChangeEvent.emit(member);
    } else {
      memberDto[MembersListingComponent.OPERATION_TYPE] = OperationType.DELETE;
      this.operations.push(memberDto);
    }
  }

  getMemberOperationTypeClass(where, id) {
    const ret = where.find(member => member.id === id);
    if (ret) {
      if (ret[MembersListingComponent.OPERATION_TYPE] === OperationType.DELETE) {
        return 'for_delete';
      } else if (ret[MembersListingComponent.OPERATION_TYPE] === OperationType.UPDATE) {
        return 'for_update';
      }
    }
    return '';
  }

  editMember(releaseDto) {
    this.router.navigate([releaseDto.id], { relativeTo: this.route });
  }

  deleteCheckedMembers() {
    this.getSelectedMembers().forEach(member => {
      this.deleteMember(member);
    });
  }

  getSelectedMembers(): Array<MemberDto> {
    const selectedMembers = [];
    this.membersListingItemComponents.forEach(memberListingItemComponent => {
      if (memberListingItemComponent.selected) {
        selectedMembers.push(memberListingItemComponent.member);
        memberListingItemComponent.selected = false;
      }
    });
    return selectedMembers;
  }

  editCheckedMember() {
    let selectedMember;
    this.membersListingItemComponents.forEach(productListingItemComponent => {
      if (!selectedMember && productListingItemComponent.selected) {
        selectedMember = productListingItemComponent.member;
      }
    });
    this.editMember(selectedMember);
  }

  public loadMembers(search: Search) {
    this.pagedMembersDto = null;
    if (this.release?.created && !this.changesMode) {
      if (this.cacheFilter) {
        this.modulePreferencesService.setPreferences(MembersListingComponent.MODULE_ID, this.search);
      }

      this.adminReleaseService
        .filterMembers(this.release.id, search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.pagedMembersDto = result;
        });
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  addMember() {
    let modalRef;

    switch (this.memberType) {
      case MemberTypes.STICKER:
        modalRef = this.dialog.open(StickersSelectModalComponent);

        const stickersSelectModalComponent = modalRef.componentInstance as StickersSelectModalComponent;
        stickersSelectModalComponent.dialogRef = modalRef;
        stickersSelectModalComponent.disableActions = true;
        stickersSelectModalComponent.disableTableActions = true;
        stickersSelectModalComponent.activeMultiSelectMode();

        stickersSelectModalComponent.selectHandler = stickers => {
          stickers.forEach(sticker => {
            this.addSelection(this.memberType, sticker.code, null);
          });
        };
        break;
      case MemberTypes.PROD_CODE:
        modalRef = this.dialog.open(ProductsSelectModalComponent);

        const productsSelectModalComponent = modalRef.componentInstance as ProductsSelectModalComponent;
        productsSelectModalComponent.dialogRef = modalRef;
        productsSelectModalComponent.isModal = true;
        productsSelectModalComponent.disableActions = true;
        productsSelectModalComponent.disableListingActions = true;
        productsSelectModalComponent.selectHandler = products => {
          products.forEach(product => {
            this.addSelection(this.memberType, product.productCode, product.description);
          });
        };
        break;
      case MemberTypes.PROMOTION:
        modalRef = this.dialog.open(PromotionsSelectModalComponent);

        const promotionsSelectModalComponent = modalRef.componentInstance as PromotionsSelectModalComponent;
        promotionsSelectModalComponent.dialogRef = modalRef;
        promotionsSelectModalComponent.disableListingActions = true;
        promotionsSelectModalComponent.disableActions = true;
        promotionsSelectModalComponent.activeMultiSelectMode();

        promotionsSelectModalComponent.selectHandler = promotions => {
          promotions.forEach(promotion => {
            this.addSelection(this.memberType, promotion.code, promotion.name);
          });
        };
        break;
      case MemberTypes.PROD_GROUP:
        modalRef = this.dialog.open(GroupsSelectModalComponent);

        const groupsSelectModalComponent = modalRef.componentInstance as GroupsSelectModalComponent;
        groupsSelectModalComponent.dialogRef = modalRef;
        groupsSelectModalComponent.disableListingActions = true;
        groupsSelectModalComponent.disableActions = true;
        groupsSelectModalComponent.activeMultiSelectMode();
        groupsSelectModalComponent.selectMode = true;

        groupsSelectModalComponent.selectHandler = groups => {
          groups.forEach(group => {
            this.addSelection(this.memberType, group.code, group.name);
          });
        };
        break;
      case MemberTypes.ACL_RULE:
        modalRef = this.dialog.open(RulesSelectModalComponent);

        const rulesSelectModalComponent = modalRef.componentInstance as RulesSelectModalComponent;
        rulesSelectModalComponent.dialogRef = modalRef;
        rulesSelectModalComponent.disableListingActions = true;
        rulesSelectModalComponent.disableActions = true;
        rulesSelectModalComponent.activeMultiSelectMode();

        rulesSelectModalComponent.selectHandler = rules => {
          rules.forEach(rule => {
            this.addSelection(this.memberType, rule.id, null);
          });
        };
        break;
      case MemberTypes.CAT_TECH:
        modalRef = this.dialog.open(CategoriesSelectModalComponent);

        const categoriesSelectModalComponent = modalRef.componentInstance as CategoriesSelectModalComponent;
        categoriesSelectModalComponent.dialogRef = modalRef;
        categoriesSelectModalComponent.activeMultiSelectMode();

        categoriesSelectModalComponent.selectHandler = categories => {
          categories.forEach(category => {
            this.addSelection(this.memberType, category, null);
          });
        };
        break;
      default:
        this.addSelection(null, null, null);
    }
  }

  addSelection(memberType, code, name) {
    const newMember: MemberDto = {
      id: ServiceUtils.getRandomId(),
      memberType: memberType,
      code: code,
      name: name,
    };

    if (memberType === MemberTypes.PROD_CODE || memberType === MemberTypes.CAT_TECH) {
      newMember['includePromotion'] = false;
    }

    newMember[MembersListingComponent.OPERATION_TYPE] = OperationType.CREATE;
    this.operations.push(newMember);

    if (!newMember.memberType) {
      this.newMember = newMember;
    }
  }

  editedMemberChanged($event: MembersListingItemComponent) {
    this.newMember = null;
    if (!this.editableMembersListingItemComponent || this.editableMembersListingItemComponent.save()) {
      if (this.editableMembersListingItemComponent === $event) {
        this.editableMembersListingItemComponent = null;
      } else {
        this.editableMembersListingItemComponent = $event;
      }
    }
  }

  deleteMemberChange($event: MemberDto) {
    this.membersListingItemComponents.forEach(memberListingItemComponent => {
      if (memberListingItemComponent.member.id === $event.id) {
        memberListingItemComponent.restoreBackup();
      }
    });
  }
}

export enum OperationType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum MemberTypes {
  PROD_CODE = 'ProdCode',
  CAT_TECH = 'CatTech',
  PROD_GROUP = 'ProdGroup',
  PROMOTION = 'Promotion',
  ACL_RULE = 'AclRule',
  STICKER = 'Sticker',
}
