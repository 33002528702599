import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { base64StringToBlob } from 'blob-util';
import { AdminDmsService, AppBlockerService } from '@btl/btl-fe-wc-common';
import { DmsFileDto } from '@btl/admin-bff';
import { finalize } from 'rxjs/operators';
import { ProductUtils } from '@helpers/product-utils';
import { FilesAddModalComponent } from '@components/files-add-modal/files-add-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'picture-selector, [picture-selector]',
  templateUrl: './picture-selector.component.html',
  styleUrls: ['./picture-selector.component.scss'],
})
export class PictureSelectorComponent implements OnInit, OnChanges {

  pictureSize: Map<string, string> = new Map([
    ['BN_HALF_IMAGE', '665x200'],
  ])

  @Input()
  formArray: FormArray;

  @Input()
  pictureSelectorComponents: QueryList<PictureSelectorComponent>;

  @Input()
  parentGroup: FormGroup;

  @Input()
  controlName: string;

  formControl: FormControl;

  @Input()
  picture: Picture;

  @Input()
  useDMS: boolean = true;

  @Input()
  pictureTypeVisible: boolean = false;

  @Input()
  pictureTypeEditable: boolean = false;

  @Input()
  pictureTypeRequired: boolean = false;

  @Input()
  priorityVisible: boolean = false;

  @Input()
  priorityEditable: boolean = true;

  @Input()
  priorityRequired: boolean = false;

  @Input()
  translate: boolean = true;

  @Output()
  readonly onUniquenessChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly removePicture: EventEmitter<Picture> = new EventEmitter<Picture>();

  @Input()
  pictureTypes: Array<PictureType>;

  @Input()
  pictureForm: FormGroup;

  @Output()
  imageSize: EventEmitter<any> = new EventEmitter();

  @Input()
  widgetTemplateType: string;

  wrongFileFormat = false;
  wrongPictureResolution = false;

  uploadedImage = null;
  pictureTypeDto = null;

  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  height: number;
  width: number;
  maxWidth = 360;
  maxHeight = 180;
  resizedHeight: number;
  resizedWidth: number;

  constructor(
    private formBuilder: FormBuilder,
    private adminDmsService: AdminDmsService,
    private appBlockerService: AppBlockerService,
    private dialog: MatDialog
  ) {
  }

  public static getFormControlConfig() {
    return {
      id: [null],
      href: [null],
      priority: [null],
      pictureType: [null],
      image: [],
      recordVersion: [],
      extId: [null],
    };
  }

  public static checkUniqueness(formArray) {
    let isError = false;
    const result = {};

    formArray.controls.forEach(pictureForm => {
      const key = `${pictureForm.controls.pictureType.value}-${pictureForm.controls.priority.value}`;
      const keyArray = result[key];
      if (keyArray) {
        keyArray.push(pictureForm);
      } else {
        result[key] = [pictureForm];
      }
    });

    for (const prop in result) {
      if (result[prop].length > 1) {
        isError = true;
        result[prop].forEach(item => {
          item.setErrors({ unique: true });
        });
      } else {
        result[prop][0].setErrors(null);
      }
    }
    if (isError) {
      return { unique: 'true' };
    }
  }

  isCorrectFileFormat(fileName: string) {
    const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    if (fileFormat === 'png' || fileFormat === 'jpg' || fileFormat === 'jpeg' || fileFormat === 'gif') {
      return true;
    }
    return false;
  }

  isCorrectPictureResolution(pictureType) {
    const height = this.uploadedImage.naturalHeight;
    const width = this.uploadedImage.naturalWidth;
    this.pictureTypeDto = ProductUtils.getPictureTypeDto(this.pictureTypes, pictureType);

    if (!this.pictureTypeDto || (this.pictureTypeDto.height === height && this.pictureTypeDto.width === width)) {
      this.wrongPictureResolution = false;
      return true;
    }
    this.wrongPictureResolution = true;
    return false;
  }

  ngOnInit() {
    if (!this.pictureForm) {
      this.pictureForm = this.formBuilder.group(PictureSelectorComponent.getFormControlConfig());
    }
    this.picture = this.pictureForm.getRawValue();
    this.uploadedImage = new Image();
    this.uploadedImage.onload = () => {
      this.height = this.uploadedImage.naturalHeight;
      this.width = this.uploadedImage.naturalWidth;
      let ratio = 1;
      if (this.width > this.maxWidth) {
        ratio = this.maxWidth / this.width;
        if (this.height * ratio > this.maxHeight) {
          ratio = this.maxHeight / this.height;
        }
      }
      this.resizedHeight = this.height * ratio;
      this.resizedWidth = this.width * ratio;
      this.isCorrectPictureResolution(this.pictureForm.controls.pictureType.value);
    };

    if (this.controlName) {
      this.formControl = this.parentGroup.controls[this.controlName] as FormControl;

      this.picture = {
        href: this.formControl.value,
        pictureType: 'BANNERS',
      };
    }

    this.pictureForm.patchValue(this.picture);
    if (this.picture.image) {
      const reader = new FileReader();
      reader.onload = e => {
        this.previewUrl = reader.result as string;
        this.uploadedImage.src = reader.result as string;
      };

      const magicHeader = this.magicHeaderOfBase64Content(this.picture.image);
      const mimeType = this.mimeType(magicHeader);

      reader.readAsDataURL(base64StringToBlob(this.picture.image, mimeType));
    } else if (this.picture && (this.picture.href || this.picture.extId)) {
      if (!this.picture.href && this.picture.extId) {
        this.picture.href = `/api/bff/admin/v1/files/${this.picture.extId}/content`;
      }
      this.pictureForm.controls.href.setValue(this.picture.href);
      this.uploadedImage.src = this.picture.href;
    }

    if (!!this.priorityRequired) {
      this.priorityVisible = true;
      this.priorityEditable = true;
      this.pictureForm.controls.priority.setValidators([Validators.pattern('^[0-9]*$'), Validators.required]);
      this.onUniquenessChange.emit();
    }
    if (!!this.pictureTypeRequired) {
      this.pictureTypeEditable = true;
      this.pictureTypeVisible = true;
      this.pictureForm.controls.pictureType.setValidators([Validators.required]);
    }

    if (this.useDMS) {
      this.pictureForm.controls.href.setValidators([Validators.required]);
    }
    this.imageSize.emit(this.pictureSize.get(this.widgetTemplateType));
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (this.picture && !this.picture.href && this.picture.extId) {
      this.picture.href = `/api/bff/admin/v1/files/${this.picture.extId}/content`;
      this.pictureForm.controls.href.setValue(this.picture.href);
    }
  }

  handleFileInput(files: FileList) {
    if (files?.length < 1) {
      return;
    }

    this.appBlockerService.block();
    const fileToUpload = files[0];
    if (this.isCorrectFileFormat(fileToUpload.name)) {
      this.wrongFileFormat = false;
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.previewUrl = reader.result;
        this.picture.image = (reader.result as string).split('base64,')[1];
        if (this.useDMS) {
          const newFile: DmsFileDto = {
            content: this.picture.image,
            name: fileToUpload.name,
            type: 'PC_PICTURE',
            mediaType: fileToUpload.type,
          };

          this.adminDmsService
            .createFile(newFile)
            .pipe(finalize(this.appBlockerService.unblock))
            .subscribe(file => {
              this.pictureForm.controls.extId.setValue(file.id);
              this.pictureForm.controls.href.setValue(file.contentHref);
              this.pictureForm.controls.href.patchValue(file.contentHref);
              if (this.formControl) {
                this.formControl.setValue(file.contentHref.replace('/api/bff/admin/', '/api/bff/order/'));
              }
            });
        } else {
          this.pictureForm.controls['image'].patchValue(this.picture.image);
          this.appBlockerService.unblock();
        }
        this.uploadedImage.src = reader.result as string;
      };
      reader.readAsDataURL(fileToUpload);
    } else {
      this.wrongFileFormat = true;
      this.appBlockerService.unblock();
    }
  }

  magicHeaderOfBase64Content(content: string): string {
    const headerBytes = atob(content).slice(0, 4);
    let headerString = '';
    for (let i = 0; i < headerBytes.length; i++) {
      const hex = Number(headerBytes.charCodeAt(i)).toString(16);
      headerString = headerString + hex;
    }
    return headerString;
  }

  mimeType(headerString: string): string {
    switch (headerString.toLowerCase()) {
      case '25504446':
        return 'application/pdf';
      case '89504e47':
        return 'image/png';
      case '47494638':
        return 'image/gif';
      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
      case 'ffd8ffdb':
        return 'image/jpeg';
      case '504b0304':
        return 'application/zip';
      default:
        return 'unknown';
    }
  }

  pictureTypeChanged(selectedPictureType) {
    this.isCorrectPictureResolution(selectedPictureType);
    this.onUniquenessChange.emit();
  }

  remove() {
    if (this.formArray) {
      this.formArray.controls = this.formArray.controls.filter(control => control !== this.pictureForm);
    } else {
      this.formControl.setValue(null);
      this.picture.href = null;
      this.pictureForm.controls.href.setValue(null);
      this.previewUrl = null;
    }
    this.removePicture.emit(this.picture);
  }

  uploaded() {
    const modalRef: MatDialogRef<FilesAddModalComponent> = this.dialog.open(FilesAddModalComponent, { width: '1000px' });
    const pictureAddModalComponent: FilesAddModalComponent = modalRef.componentInstance;
    pictureAddModalComponent.dialogRef = modalRef;
    pictureAddModalComponent.uploadStep = false;
    pictureAddModalComponent.multiple = false;
    pictureAddModalComponent.uploadedHandler = (newFile: DmsFileDto) => this.handleUpload(newFile, modalRef);
  }

  download() {
    window.open(this.picture.href);
  }

  handleUpload = (newFile: DmsFileDto, modalRef?): void => {
    newFile.type = 'GENERAL';
    newFile.contentHref = null;
    this.adminDmsService
      .createFile(newFile)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(file => {
        this.pictureForm.controls['href'].patchValue(file.contentHref);
        this.pictureForm.controls['extId'].patchValue(file.id);
        this.picture = this.pictureForm.getRawValue();
        if (this.formControl) {
          this.formControl.setValue(file.contentHref.replace('/api/bff/admin/', '/api/bff/order/'));
        }
        if (modalRef) {
          modalRef.close();
        }
      });
  }
}

export interface PictureType {
  id: string;
  width?: number;
  height?: number;
  parentId?: string;
}

export interface Picture {
  id?: number;
  extId?: string;
  href?: string;
  priority?: number;
  pictureType?: string;
  recordVersion?: number;
  image?: string; //base64 bin
}
