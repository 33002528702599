import { Component } from '@angular/core';
import {
  AdminDynamicEnumService,
  AppBlockerService,
  CodebookService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner
} from '@btl/btl-fe-wc-common';
import { forkJoin } from 'rxjs';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EnumerationsService } from '@service/enumerations.service';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  ResourceControlFilterMinerComponent
} from '@components/resource/resources/filter/resource-control-filter-miner.component';
import { CodebookDto } from '@btl/order-bff';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-resource-resources-filter',
  templateUrl: './resource-resources-filter.component.html',
  animations: [
    trigger('indicatorRotate', [
      state('close', style({ transform: 'rotate(0deg)' })),
      state('open', style({ transform: 'rotate(180deg)' })),
      transition('open <=> close', animate('0ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
})
export class ResourceResourcesFilterComponent extends ComplexFilter {
  resourceType = [];
  categories = [];
  resourceStates = [];
  operationStates = [];
  usagesStates = [];
  administrativeStates = [];
  resourceCategories = [];

  filterForm = this.formBuilder.group({
    id: [],
    type: [],
    category: [{ value: null, disabled: true }],
    name: [],
    resourceStatus: [],
    operationalState: [],
    usageState: [],
    administrativeState: [],
    paramName: [],
    paramValue: [],
    placeId: [],
    partyId: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.id, 'id'),
    new ControlFilterMiner(this.filterForm.controls.type, 'type'),
    new ControlFilterMiner(this.filterForm.controls.category, 'category'),
    new ControlFilterMiner(this.filterForm.controls.name, 'name', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.resourceStatus, 'resourceStatus'),
    new ControlFilterMiner(this.filterForm.controls.operationalState, 'operationalState'),
    new ControlFilterMiner(this.filterForm.controls.usageState, 'usageState'),
    new ControlFilterMiner(this.filterForm.controls.administrativeState, 'administrativeState'),
    new ResourceControlFilterMinerComponent(this.filterForm.controls.paramName, 'resourceCharacteristics', 'name'),
    new ResourceControlFilterMinerComponent(this.filterForm.controls.paramValue, 'resourceCharacteristics', 'value'),
    new ResourceControlFilterMinerComponent(this.filterForm.controls.placeId, 'place', 'id'),
    new ResourceControlFilterMinerComponent(this.filterForm.controls.partyId, 'relatedParties', 'id'),
  ];

  get typeControl(): AbstractControl {
    return this.filterForm.get('type');
  }

  get categoryControl(): AbstractControl {
    return this.filterForm.get('category');
  }

  advancedFilterFields = ['operationalState', 'usageState', 'administrativeState', 'paramName', 'paramValue', 'placeId', 'partyId'];

  constructor(
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private codebookService: CodebookService,
    private adminDynamicEnumService: AdminDynamicEnumService
  ) {
    super();

    this.appBlockerService.block();
    const adminDynamicEnumCalls = [
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.STOCK,
        'com.emeldi.ecc.be.stock.enums.ResourceAdministrativeStateType'
      ),
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.STOCK,
        'com.emeldi.ecc.be.stock.enums.ResourceOperationalStateType'
      ),
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.STOCK,
        'com.emeldi.ecc.be.stock.enums.ResourceUsageStateType'
      ),
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.STOCK,
        'com.emeldi.ecc.be.stock.enums.ResourceStatusType'
      ),
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.STOCK,
        'com.emeldi.ecc.be.stock.enums.LogicalResourceType'
      ),
    ];
    forkJoin(adminDynamicEnumCalls)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((results: any) => {
        if (results && results.length === 5) {
          this.administrativeStates = results[0]?.data ? results[0].data.map(value => value.name).sort() : [];
          this.operationStates = results[1]?.data ? results[1].data.map(value => value.name).sort() : [];
          this.usagesStates = results[2]?.data ? results[2].data.map(value => value.name).sort() : [];
          this.resourceStates = results[3]?.data ? results[3].data.map(value => value.name).sort() : [];
          this.resourceType = results[4]?.data ? results[4].data.map(value => value.name).sort() : [];

          super.ngOnInit();
          this.initTypeCategory();
        }
      });
  }

  ngOnInit(disableInitSearch?: boolean) {

  }

  initTypeCategory(): void {
    if (this.typeControl.value) {
      this.categoryControl.enable();
    }

    this.typeControl.valueChanges.subscribe(value => {
      this.initCategoryValues(value);
    });
  }

  private initCategoryValues(resourceType: string): void {
    if (resourceType) {
      this.appBlockerService.block();
      this.codebookService
        .getCodebooks('RESOURCE_CATEGORY')
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((codebooks: CodebookDto[]) => {
          this.resourceCategories = [];
          for (const codebook of codebooks) {
            if (
              codebook?.parameters?.length &&
              codebook.parameters.find(i => i.name === 'resourceType' && i.value === resourceType)
            ) {
              this.resourceCategories.push({
                label: codebook?.localizedTexts?.length ? codebook.localizedTexts[0].text : codebook.code,
                value: codebook.code,
              });
            }
          }
        });
      if (!this.resourceCategories.length) {
        this.categoryControl.setValue(null);
      }
      this.categoryControl.enable();
    } else {
      this.categoryControl.setValue(null);
      this.categoryControl.disable();
    }
  }
}
