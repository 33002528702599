import { Component } from '@angular/core';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-catalogue',
  template: '<router-outlet></router-outlet>',
})
@EnableDynamicLoading({ customName: ProductCatalogueComponent.PAGE_ID })
export class ProductCatalogueComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'ProductCatalogueComponent';

  pageId(): string {
    return ProductCatalogueComponent.PAGE_ID;
  }

  constructor(protected router: Router, protected route: ActivatedRoute) {
    super(router, route);
  }
}
