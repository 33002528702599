<div class="main__header">
  <h1 id="001_documentTemplate_edit">{{ 'wc.admin.documentTemplates.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_documentTemplate_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor">
      <mat-icon svgIcon="save"></mat-icon>
      {{ 'wc.common.save' | translate }}</a
    >
    <a
      id="003_documentTemplate_edit"
      color="primary"
      mat-stroked-button
      [routerLink]=""
      *ngIf="aclService.pageEditAccess"
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_documentTemplate_edit" mat-menu-item (click)="duplicate()" *ngIf="documentTemplateDto?.id"
         [routerLink]="">
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="005_documentTemplate_edit" mat-menu-item (click)="reset()" [routerLink]="">
        <mat-icon svgIcon="reset"></mat-icon>
        {{ 'wc.common.reset' | translate }}</a
      >
      <a id="006_documentTemplate_edit" mat-menu-item (click)="delete()" *ngIf="documentTemplateDto?.id"
         [routerLink]="">
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="007_documentTemplate_edit" (click)="cancel()" class="btn cancel-button">{{
      'wc.common.cancel.button' | translate
    }}</a>
  </div>
</div>

<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

<div class="card__wrapper">
  <!-- card with main documentTemplate attributes -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.documentTemplates.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="getEditDocumentTemplate()" [formGroup]="documentTemplateForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="documentTemplateForm"
                  [controlName]="'id'"
                  id="008_documentTemplate_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.documentTemplate'">
                </app-custom-input>
              </div>

              <div class="form__row">
                <app-custom-input
                  [id]="'011_documentTemplate_edit'"
                  [inputType]="'checkbox'"
                  [parentGroup]="documentTemplateForm"
                  [controlName]="'editable'"
                  [label]="'wc.admin.documentTemplate.editable'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'012_documentTemplate_edit'"
                  [inputType]="'checkbox'"
                  [parentGroup]="documentTemplateForm"
                  [controlName]="'signRequired'"
                  [label]="'wc.admin.documentTemplate.signRequired'">
                </app-custom-input>
              </div>

              <div class="form__row">
                <app-input-select-form-field
                  id="009_documentTemplate_edit"
                  *ngIf="documentTypes"
                  [translationPrefix]="'wc.admin.documentTemplate'"
                  [options]="documentTypes"
                  [showEmptyOption]="true"
                  [formControlName]="'documentType'">
                </app-input-select-form-field>
              </div>
            </div>

            <div class="form__group -full">
              <div class="form__row">
                <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                  <mat-label>{{ 'wc.admin.documentTemplate.description' | translate }}</mat-label>
                  <textarea
                    id="010_documentTemplate_edit"
                    class="form__input"
                    matInput
                    type="text"
                    formControlName="description"
                    rows="4"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- generated cards with localeTemplates -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.documentTemplate.localeTemplates' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="018_documentTemplate_edit"
            color="primary"
            mat-raised-button
            (click)="addLocaleTemplate()"
            [routerLink]=""
            class="btn -primary -xs edit icon stroke">
            <mat-icon svgIcon="plus"></mat-icon>
            {{ 'wc.common.add' | translate }}
          </button>
        </div>
      </div>

      <div class="card__body">
        <div class="card -full" *ngFor="let localeTemplateForm of localeTemplatesForms; let i = index">
          <div class="card__inner">
            <form
              *ngIf="getEditLocaleTemplates()"
              [formGroup]="localeTemplateForm"
              class="form"
              id="019_documentTemplate_edit_{{ i }}">
              <div class="card__header">
                {{ 'wc.admin.documentTemplate.localeTemplate' | translate }} -
                {{ localeTemplateForm.controls['locale'].value }}
                &nbsp;&nbsp;<a
                  (click)="removeLocaleTemplate(i)"
                  [routerLink]=""
                  class="link"
                  id="020_documentTemplate_edit_{{ i }}">
                  <mat-icon>delete</mat-icon>
                </a>
              </div>
              <div class="card__body">
                <div class="form__groups">
                  <div class="form__group -flex">
                    <div class="form__row">
                      <app-input-select-form-field
                        id="021_documentTemplate_edit_{{ i }}"
                        *ngIf="locales"
                        (selectionChangeEmit)="validateLocales()"
                        [translationPrefix]="'wc.admin.documentTemplate.localeTemplate'"
                        [options]="locales"
                        [showEmptyOption]="true"
                        [formControlName]="'locale'">
                      </app-input-select-form-field>
                    </div>
                  </div>
                  <div class="form__group -full">
                    <div class="form__row">
                      <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                        <mat-label>{{ 'wc.admin.documentTemplate.localeTemplate.template' | translate }}</mat-label>
                        <textarea
                          id="022_documentTemplate_edit_{{ i }}"
                          class="form__input"
                          matInput
                          type="text"
                          formControlName="template"
                          rows="16"></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
