<div class="page__wrapper">
  <div class="main__header">
    <h1>{{ 'wc.admin.notification.detail' | translate }}</h1>
    <div class="page__menu">
      <a
        color="primary"
        mat-stroked-button
        [routerLink]=""
        class="btn -primary -xs more-actions"
        [matMenuTriggerFor]="menu"
        >More actions</a
      >
      <mat-menu #menu="matMenu" class="module-actions">
        <a
          mat-menu-item
          *ngIf="
            aclService.pageEditAccess &&
            notificationDto?.state !== 'INITIAL' &&
            notificationDto?.state !== 'IN_PROGRESS'
          "
          (click)="resend()"
          [routerLink]=""
          >{{ 'wc.admin.notification.resend' | translate }}</a
        >
        <a mat-menu-item (click)="delete()" *ngIf="notificationDto?.id"
           [routerLink]="">{{ 'wc.common.delete' | translate }}</a>
      </mat-menu>
      <a (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
    </div>
  </div>

  <div class="card__wrapper" *ngIf="notificationDto && notificationInitialized">
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.notification.header' | translate }}
        </div>
        <div class="card__body">
          <form [formGroup]="notificationForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="notificationForm"
                    [controlName]="'fromName'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="notificationForm"
                    [controlName]="'notificationType'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="notificationForm"
                    [controlName]="'state'"
                    [inputType]="'text'"
                    [label]="'notificationState'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-custom-input>
                </div>
              </div>

              <div class="form__group -flex">
                <div class="form__row subject-row">
                  <app-custom-input
                    [parentGroup]="notificationForm"
                    [controlName]="'subject'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="notificationForm"
                    [controlName]="'channelTypeId'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-custom-input>
                </div>
              </div>

              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="notificationForm"
                    [controlName]="'gateway'"
                    [label]="'notificationGateway'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-input-date-form-field
                    [parentGroup]="notificationForm"
                    [controlName]="'sent'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-input-date-form-field>
                </div>
                <div class="form__row">
                  <app-input-date-form-field
                    [parentGroup]="notificationForm"
                    [controlName]="'toBeSent'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-input-date-form-field>
                </div>
              </div>

              <div class="form__group -full">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="notificationForm"
                    [controlName]="'note'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-custom-input>
                </div>
              </div>

              <div class="form__group -full">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="notificationForm"
                    [controlName]="'recipientTo'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-custom-input>
                </div>
              </div>
              <div class="form__group -full">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="notificationForm"
                    [controlName]="'recipientCc'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-custom-input>
                </div>
              </div>
              <div class="form__group -full">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="notificationForm"
                    [controlName]="'recipientBcc'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-custom-input>
                </div>
              </div>
              <div class="form__group -full">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="notificationForm"
                    [controlName]="'partyRefNo'"
                    [inputType]="'text'"
                    [label]="'customerPartyRefNo'"
                    [translationPrefix]="'wc.admin.notification'">
                  </app-custom-input>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.notification.attachments' | translate }}
        </div>
        <div class="card__body">
          <table class="table -primary table-sm">
            <thead>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.notification.attachment.extId"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.notification.attachment.name"> </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.notification.attachment.contentType">
                </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column localizationKey="wc.admin.notification.attachment.size"> </app-sortable-column>
              </th>
              <th scope="col">{{ 'wc.admin.notification.action' | translate }}</th>
            </thead>
            <tbody>
              <tr *ngFor="let attachment of notificationAttachments" class="table__row">
                <td>{{ attachment.extId }}</td>
                <td>{{ attachment.name }}</td>
                <td>{{ attachment.type }}</td>
                <td>{{ attachment.size }}</td>
                <td class="table__action">
                  <button
                    (click)="downloadAttachment(attachment)"
                    [routerLink]=""
                    mat-icon-button
                    class="dropdown-item">
                    <mat-icon>download</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.notification.body' | translate }}
        </div>
        <div class="card__body">
          <form [formGroup]="notificationForm" class="form">
            <div class="form__groups">
              <div class="form__group -full">
                <div class="form__row">
                  <angular-editor
                    [config]="editorConfig"
                    class="disabled-editor"
                    formControlName="body"
                    [formGroup]="notificationForm">
                  </angular-editor>
                </div>
              </div>
              <div class="form__group -full" *ngIf="notificationForm.get('bodyAlternative').value">
                <div class="form__row">
                  <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                    <mat-label>{{ 'wc.admin.notification.bodyAlternative' | translate }}</mat-label>
                    <textarea
                      class="form__input"
                      matInput
                      type="text"
                      formControlName="bodyAlternative"
                      rows="15"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
