import { Component, QueryList, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminDynamicEnumService,
  AdminResourceService,
  AppBlockerService,
  CodebookService,
  CurrentLocaleService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  CharacteristicDto,
  PagedResourcesDto,
  RelatedPartyDto,
  ResourceDataDto,
  ResourceRelationshipDto
} from '@btl/admin-bff';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import {
  ResourcesPartyRelationModalComponent,
} from '@components/resource/resources/edit/party-relation-modal/resources-party-relation-modal.component';
import {
  ResourcesParameterModalComponent,
} from '@components/resource/resources/edit/parameter-modal/resources-parameter-modal.component';
import { TableListingFormComponent } from '@components/table-listing-form/table-listing-form.component';
import {
  ResourcesResourceRelationshipModalComponent,
} from '@components/resource/resources/edit/resource-relationship-modal/resources-resource-relationship-modal.component';
import { ResourceResourcesListComponent } from '@components/resource/resources/list/resource-resources-list.component';
import { EnumerationsService } from '@service/enumerations.service';
import { forkJoin } from 'rxjs';
import { CodebookDto, SortDto } from '@btl/order-bff';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { Animations } from '@helpers/animations';

@Component({
  selector: 'app-resource-resources-edit',
  templateUrl: './resource-resources-edit.component.html',
  styleUrls: ['./resource-resources-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: ResourceResourcesEditComponent.PAGE_ID })
export class ResourceResourcesEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'ResourceResourcesEditComponent';
  public static readonly RESOURCE_TYPE = 'ResourceResourcesEditType';

  pageId(): string {
    return ResourceResourcesEditComponent.PAGE_ID;
  }

  @ViewChildren(TableListingFormComponent)
  tableListingFormComponents: QueryList<TableListingFormComponent>;

  @ViewChildren('relationshipTable')
  relationshipTableListingFormComponents: QueryList<TableListingFormComponent>;

  resourceDto: ResourceDataDto;
  pageInitialized = false;

  resourceStates = [];
  operationStates = [];
  usagesStates = [];
  adminStates = [];
  resourceTypes = [];
  resourceCategories = [];

  validateOrderOfStartEnd: ValidatorFn = function (control: AbstractControl) {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      const startControl = group.get('startOperatingDate');
      const endControl = group.get('endOperatingDate');
      const start = startControl.value;
      const end = endControl.value;
      if (start && end && start > end) {
        return { startIsLaterThanEnd: true };
      } else {
        if (control === startControl) {
          ResourceResourcesEditComponent.deleteControlError(endControl, 'startIsLaterThanEnd');
        } else if (control === endControl) {
          ResourceResourcesEditComponent.deleteControlError(startControl, 'startIsLaterThanEnd');
        }
      }
    }
    return null;
  };

  resourceForm: FormGroup = this.formBuilder.group({
    id: [null, Validators.required],
    name: [null, Validators.required],
    type: [null, Validators.required],
    baseType: ['LogicalResource'],
    description: [null],
    href: [null],
    category: [null],
    startOperatingDate: [null, this.validateOrderOfStartEnd],
    endOperatingDate: [null, this.validateOrderOfStartEnd],
    resourceStatus: [null, Validators.required],
    resourceVersion: [null],
    operationalState: [null, Validators.required],
    usageState: [null, Validators.required],
    administrativeState: [null, Validators.required],
  });

  resourcePlaceForm: FormGroup = this.formBuilder.group({
    id: [null],
    name: [null],
    referredType: [null, Validators.required],
    role: [null, Validators.required],
  });

  resourceCharacteristicsForm = this.formBuilder.group({
    resourceCharacteristics: this.formBuilder.array([]),
  });

  resourceRelatedPartiesForm = this.formBuilder.group({
    relatedParties: this.formBuilder.array([]),
  });

  resourceResourceRelationshipsForm = this.formBuilder.group({
    resourceRelationships: this.formBuilder.array([]),
  });

  pagedRelatedParties = { data: [] };
  pagedResourceRelationships = { data: [] };

  get resourceIdControl(): AbstractControl {
    return this.resourceForm.get('id');
  }

  get resourceTypeControl(): AbstractControl {
    return this.resourceForm.get('type');
  }

  get resourceCharacteristicsFormArray(): FormArray {
    return this.resourceCharacteristicsForm.get('resourceCharacteristics') as FormArray;
  }

  get resourcePartyRelationsFormArray(): FormArray {
    return this.resourceRelatedPartiesForm.get('relatedParties') as FormArray;
  }

  get resourceRelationshipsFormArray(): FormArray {
    return this.resourceResourceRelationshipsForm.get('resourceRelationships') as FormArray;
  }

  static deleteControlError(control: AbstractControl, errorKey: string) {
    if (control.errors) {
      delete control.errors[errorKey];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  static clearAttributes(resourceDto: ResourceDataDto): ResourceDataDto {
    const clearedResourceDto = resourceDto ? JSON.parse(JSON.stringify(resourceDto)) : ({} as ResourceDataDto);
    clearedResourceDto.id = null;
    clearedResourceDto.created = null;
    clearedResourceDto.createdBy = null;
    clearedResourceDto.modified = null;
    clearedResourceDto.modifiedBy = null;

    if (clearedResourceDto?.resourceCharacteristics?.length) {
      for (const resourceCharacteristic of clearedResourceDto.resourceCharacteristics) {
        delete resourceCharacteristic.id;
      }
    }

    if (clearedResourceDto?.notes?.length) {
      for (const note of clearedResourceDto.notes) {
        delete note.id;
      }
    }

    if (clearedResourceDto?.activationFeatures?.length) {
      for (const activationFeature of clearedResourceDto.activationFeatures) {
        delete activationFeature.id;

        if (activationFeature?.featureCharacteristics?.length) {
          for (const featureCharacteristic of activationFeature?.featureCharacteristics) {
            delete featureCharacteristic.id;
          }
        }
      }
    }

    return clearedResourceDto;
  }

  static isDeletableResource(resourceDto: ResourceDataDto): boolean {
    return (
      resourceDto.administrativeState !== 'locked' &&
      resourceDto.resourceStatus !== 'reserved' &&
      resourceDto.usageState === 'idle'
    );
  }

  constructor(
    public aclService: AclService,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    private dialog: MatDialog,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private codebookService: CodebookService,
    private currentLocaleService: CurrentLocaleService,
    private adminResourceService: AdminResourceService
  ) {
    super(router, route);

    this.appBlockerService.block();
    const adminDynamicEnumCalls = [
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.STOCK,
        'com.emeldi.ecc.be.stock.enums.ResourceAdministrativeStateType'
      ),
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.STOCK,
        'com.emeldi.ecc.be.stock.enums.ResourceOperationalStateType'
      ),
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.STOCK,
        'com.emeldi.ecc.be.stock.enums.ResourceUsageStateType'
      ),
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.STOCK,
        'com.emeldi.ecc.be.stock.enums.ResourceStatusType'
      ),
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.STOCK,
        'com.emeldi.ecc.be.stock.enums.LogicalResourceType'
      ),
    ];
    forkJoin(adminDynamicEnumCalls)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((results: any) => {
        if (results && results.length === 5) {
          this.adminStates = results[0]?.data ? results[0].data.map(value => value.name).sort() : [];
          this.operationStates = results[1]?.data ? results[1].data.map(value => value.name).sort() : [];
          this.usagesStates = results[2]?.data ? results[2].data.map(value => value.name).sort() : [];
          this.resourceStates = results[3]?.data ? results[3].data.map(value => value.name).sort() : [];
          this.resourceTypes = results[4]?.data ? results[4].data.map(value => value.name).sort() : [];
        }
      });

    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initResourceCategory();
    });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const resourceId = this.params.id;
      let resourceType = this.params.type;
      if (resourceType) {
        sessionStorage.setItem(ResourceResourcesEditComponent.RESOURCE_TYPE, resourceType);
      } else {
        resourceType = sessionStorage.getItem(ResourceResourcesEditComponent.RESOURCE_TYPE);
      }
      this.router.navigate([], { replaceUrl: true });
      this.setResourceDto(resourceType, resourceId);
    }
  }

  setResourceDto(resourceType: string, resourceId: string, hard = false): void {
    if (
      resourceId &&
      resourceId !== '&' &&
      resourceId !== 'newResource' &&
      (hard || !this.resourceDto || this.resourceDto.id !== resourceId)
    ) {
      this.appBlockerService.block();
      this.adminResourceService
        .getResourceById(resourceType, resourceId)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: ResourceDataDto) => {
          this.resourceDto = result;
          this.initForm();
        });
    } else if (resourceId === 'newResource') {
      this.resourceDto = {
        id: null,
        type: null,
        resourceCharacteristics: [],
        activationFeatures: [],
        notes: [],
        relatedParties: [],
        attachments: [],
        resourceRelationships: [],
        place: {},
      };
      this.initForm();
    } else {
      this.cancel();
    }
  }

  initForm(duplicate = false): void {
    this.resourceForm.patchValue(this.resourceDto);

    if (this.resourceDto.id) {
      this.resourceIdControl.disable();
      this.resourceTypeControl.disable();
    } else {
      if (duplicate) {
        this.resourceIdControl.enable();
        this.resourceTypeControl.enable();
        if (this.resourceTypeControl.value) {
          this.getIdPatternByType(this.resourceTypeControl.value);
        }
      }

      this.resourceIdControl.valueChanges.subscribe(value => {
        this.checkUniqueIdType();
      });
      this.resourceTypeControl.valueChanges.subscribe(value => {
        this.checkUniqueIdType();
        this.getIdPatternByType(value);
        this.initResourceCategory(value);
      });
    }

    if (this.resourceDto.place && this.resourceDto.place.id) {
      this.resourcePlaceForm.patchValue(this.resourceDto.place);
    } else {
      this.resourcePlaceForm.reset();
      this.resourceDto.place = {};
    }

    this.initResourceCharacteristic(this.resourceDto.resourceCharacteristics || []);
    this.initResourceRelatedParties(this.resourceDto.relatedParties || []);
    this.initResourceRelationships(this.resourceDto.resourceRelationships || []);
    this.initResourceCategory();
    this.pageInitialized = true;
  }

  save(): void {
    if (!this.isAllFormsValid()) {
      return;
    }
    const resourceId = this.resourceDto.id;
    this.updateModels();

    this.appBlockerService.block();
    if (!resourceId) {
      this.adminResourceService
        .createResource(this.resourceDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: ResourceDataDto) => {
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          this.resourceDto = result;
          this.initForm();
        });
    } else {
      const id = this.resourceDto.id;
      const type = this.resourceDto.type;
      const resourceDataDto = ResourceResourcesEditComponent.clearAttributes(this.resourceDto);
      this.adminResourceService
        .updateResource(type, id, resourceDataDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: ResourceDataDto) => {
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          this.resourceDto = result;
          this.initForm();
        });
    }
  }

  delete(): void {
    if (!ResourceResourcesEditComponent.isDeletableResource(this.resourceDto)) {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.resource.delete.error.text');
      return;
    }

    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.resource.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminResourceService
        .deleteResource(this.resourceDto.type, this.resourceDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: ResourceDataDto) => {
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
          this.cancel();
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  cancel(): void {
    this.navigateSibling(ResourceResourcesListComponent.PAGE_ID);
  }

  duplicate(): void {
    // reset dto attributes
    this.resourceDto = ResourceResourcesEditComponent.clearAttributes(this.resourceDto);
    this.resourceDto.recordVersion = null;
    this.resourceDto.name = `${this.resourceDto.name}_COPY`;
    this.initForm(true);
  }

  reset(): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.resource.reset.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
      this.setResourceDto(this.resourceDto.type, this.resourceDto.id, true);
      confirmationDialogComponent.dialogReference.close();
    };
  }

  addEditResourceCharacteristics(index?: number): void {
    const modalRef = this.dialog.open(ResourcesParameterModalComponent);
    const modalComponent = modalRef.componentInstance;
    modalComponent.dialogRef = modalRef;
    if (index !== undefined && index >= 0) {
      const resourceCharacteristics = this.resourceCharacteristicsFormArray.getRawValue();
      resourceCharacteristics.splice(index, 1);
      modalComponent.resourceCharacteristics = resourceCharacteristics;
      const data = (this.resourceCharacteristicsFormArray.at(index) as FormGroup).getRawValue();
      modalComponent.formGroup = this.formBuilder.group({
        name: [data.name, Validators.required],
        value: [data.value, Validators.required],
        valueType: ['string'],
      });
    } else {
      modalComponent.resourceCharacteristics = this.resourceCharacteristicsFormArray.getRawValue();
      modalComponent.formGroup = this.formBuilder.group({
        name: [null, Validators.required],
        value: [null, Validators.required],
        valueType: ['string'],
      });
    }

    modalComponent.saveEvent.subscribe((data: any) => {
      if (index !== undefined && index >= 0) {
        this.resourceCharacteristicsFormArray.at(index).patchValue(data);
      } else {
        this.resourceCharacteristicsFormArray.push(
          this.formBuilder.group({
            name: [data.name.trim(), Validators.required],
            value: [data.value.trim(), Validators.required],
            valueType: [data.valueType],
          })
        );
      }
      modalRef.close();
    });
  }

  deleteResourceCharacteristics(index: number): void {
    this.resourceCharacteristicsFormArray.removeAt(index);
  }

  addPartyRelation(): void {
    const modalRef = this.dialog.open(ResourcesPartyRelationModalComponent);
    const modalComponent = modalRef.componentInstance;
    modalComponent.dialogRef = modalRef;
    modalComponent.formGroup = this.formBuilder.group({
      id: [null, Validators.required],
      name: [null],
      role: [null, Validators.required],
      referredType: [null, Validators.required],
      href: [null],
    });

    modalComponent.saveEvent.subscribe((data: any) => {
      this.resourcePartyRelationsFormArray.push(
        this.formBuilder.group({
          id: [data.id.trim(), Validators.required],
          name: [data.name.trim()],
          role: [data.role, Validators.required],
          referredType: [data.referredType, Validators.required],
          href: [data.href],
        })
      );
      this.pagedRelatedParties = { data: this.resourcePartyRelationsFormArray.getRawValue() };
      modalRef.close();
    });
  }

  editPartyRelation(relatedPartyDto: RelatedPartyDto): void {
    const index = this.resourcePartyRelationsFormArray.getRawValue().findIndex(i => i.id === relatedPartyDto.id);
    if (index !== undefined && index >= 0) {
      const modalRef = this.dialog.open(ResourcesPartyRelationModalComponent);
      const modalComponent = modalRef.componentInstance;
      modalComponent.dialogRef = modalRef;
      modalComponent.formGroup = this.resourcePartyRelationsFormArray.at(index) as FormGroup;

      modalComponent.saveEvent.subscribe((data: any) => {
        this.resourcePartyRelationsFormArray.at(index).patchValue(data);
        this.pagedRelatedParties = { data: this.resourcePartyRelationsFormArray.getRawValue() };
        modalRef.close();
      });
    }
  }

  deletePartyRelation(relatedPartyDto: RelatedPartyDto): void {
    const index = this.resourcePartyRelationsFormArray.getRawValue().findIndex(i => i.id === relatedPartyDto.id);
    if (index !== undefined && index >= 0) {
      this.resourcePartyRelationsFormArray.removeAt(index);
      this.pagedRelatedParties = { data: this.resourcePartyRelationsFormArray.getRawValue() };
    }
  }

  getSelectedPartyRelation(): RelatedPartyDto[] {
    let selectedItems = [];
    this.tableListingFormComponents?.forEach(tableListingFormComponent => {
      if (tableListingFormComponent.selection.hasValue()) {
        selectedItems = tableListingFormComponent.selection.selected;
      }
    });
    const uniqList = [];
    for (const item of selectedItems) {
      if (!uniqList.find(i => i.id === item.id)) {
        uniqList.push(item);
      }
    }
    return uniqList;
  }

  deleteCheckedPartyRelation(): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.resource.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.getSelectedPartyRelation().forEach((partRelationDto: RelatedPartyDto) => {
        const index = this.resourcePartyRelationsFormArray.getRawValue().findIndex(i => i.id === partRelationDto.id);
        if (index !== undefined && index >= 0) {
          this.resourcePartyRelationsFormArray.removeAt(index);
        }
      });
      this.pagedRelatedParties = { data: this.resourcePartyRelationsFormArray.getRawValue() };
      confirmationDialogComponent.dialogReference.close();
    };
  }

  addResourceRelationship(): void {
    const modalRef = this.dialog.open(ResourcesResourceRelationshipModalComponent);
    const modalComponent = modalRef.componentInstance;
    modalComponent.dialogRef = modalRef;
    modalComponent.types = this.resourceTypes;
    modalComponent.formGroup = this.formBuilder.group({
      id: [null, Validators.required],
      relationshipType: [null, Validators.required],
      direction: ['To'],
      type: [null, Validators.required],
    });

    modalComponent.saveEvent.subscribe((data: any) => {
      this.resourceRelationshipsFormArray.push(
        this.formBuilder.group({
          id: [data.id.trim(), Validators.required],
          relationshipType: [data.relationshipType, Validators.required],
          direction: [data.direction],
          type: [data.type, Validators.required],
          name: [data.name],
        })
      );
      this.pagedResourceRelationships = { data: this.resourceRelationshipsFormArray.getRawValue() };
      modalRef.close();
    });
  }

  editResourceRelationship(resource: any): void {
    const index = this.resourceRelationshipsFormArray
      .getRawValue()
      .findIndex(
        i =>
          i.id === resource.id &&
          i.direction === resource.direction &&
          i.relationshipType === resource.relationshipType &&
          i.type === resource.type
      );
    if (index !== undefined && index >= 0) {
      const modalRef = this.dialog.open(ResourcesResourceRelationshipModalComponent);
      const modalComponent = modalRef.componentInstance;
      modalComponent.dialogRef = modalRef;
      modalComponent.types = this.resourceTypes;
      modalComponent.formGroup = this.resourceRelationshipsFormArray.at(index) as FormGroup;

      modalComponent.saveEvent.subscribe((data: any) => {
        this.resourceRelationshipsFormArray.at(index).patchValue(data);
        this.pagedResourceRelationships = { data: this.resourceRelationshipsFormArray.getRawValue() };
        modalRef.close();
      });
    }
  }

  deleteResourceRelationship(resourceRelationship: any): void {
    if (resourceRelationship.direction !== 'To') {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.resource.relationship.delete.error.text');
      return;
    }
    const index = this.resourceRelationshipsFormArray.getRawValue().findIndex(i => i.id === resourceRelationship.id);
    if (index !== undefined && index >= 0) {
      this.resourceRelationshipsFormArray.removeAt(index);
      this.pagedResourceRelationships = { data: this.resourceRelationshipsFormArray.getRawValue() };
    }
  }

  getSelectedResourceRelationship(): ResourceRelationshipDto[] {
    let selectedItems = [];
    this.relationshipTableListingFormComponents?.forEach(tableListingFormComponent => {
      if (tableListingFormComponent.selection.hasValue()) {
        selectedItems = tableListingFormComponent.selection.selected;
      }
    });
    const uniqList = [];
    for (const item of selectedItems) {
      if (!uniqList.find(i => i.id === item.id)) {
        uniqList.push(item);
      }
    }
    return uniqList;
  }

  deleteCheckedResourceRelationship(): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.resource.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.getSelectedPartyRelation().forEach((resourceRelationship: any) => {
        if (resourceRelationship.direction === 'To') {
          const index = this.resourceRelationshipsFormArray
            .getRawValue()
            .findIndex(i => i.id === resourceRelationship.id);
          if (index !== undefined && index >= 0) {
            this.resourceRelationshipsFormArray.removeAt(index);
          }
        }
      });
      this.pagedResourceRelationships = { data: this.resourceRelationshipsFormArray.getRawValue() };
      confirmationDialogComponent.dialogReference.close();
    };
  }

  checkUniqueIdType(): void {
    const id = this.resourceIdControl.value;
    const type = this.resourceTypeControl.value;
    if (id && type) {
      this.appBlockerService.block();
      const search: Search = {
        filtering: [
          { column: 'id', compareType: 'EQUAL', value: id },
          { column: 'type', compareType: 'EQUAL', value: type },
        ],
        sorting: [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }],
        paging: { page: 1, pageSize: 1 },
      };
      this.adminResourceService
        .filterResources(search)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: PagedResourcesDto) => {
          if (result.data && result.data.length) {
            this.resourceIdControl.setErrors({ notUniqueCombination: true });
            this.resourceTypeControl.setErrors({ notUniqueCombination: true });
          } else {
            ResourceResourcesEditComponent.deleteControlError(this.resourceIdControl, 'notUniqueCombination');
            ResourceResourcesEditComponent.deleteControlError(this.resourceTypeControl, 'notUniqueCombination');
          }
        });
    }
  }

  private isAllFormsValid(): boolean {
    FormUtils.validateAllFormFields(this.resourceForm);
    if (this.resourcePlaceForm.get('id').value) {
      this.resourcePlaceForm.get('referredType').setValidators(Validators.required);
      this.resourcePlaceForm.get('role').setValidators(Validators.required);
    } else {
      this.resourcePlaceForm.get('referredType').setValidators(null);
      this.resourcePlaceForm.get('role').setValidators(null);
    }
    this.resourcePlaceForm.get('referredType').updateValueAndValidity();
    this.resourcePlaceForm.get('role').updateValueAndValidity();
    FormUtils.validateAllFormFields(this.resourcePlaceForm);
    this.resourceCharacteristicsForm.enable();
    FormUtils.validateAllFormFields(this.resourceCharacteristicsForm);
    FormUtils.validateAllFormFields(this.resourceRelatedPartiesForm);

    const isValid =
      this.resourceForm.valid &&
      this.resourcePlaceForm.valid &&
      this.resourceCharacteristicsForm.valid &&
      this.resourceRelatedPartiesForm.valid;
    if (!isValid) {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.resource.validation.error');
    }
    return isValid;
  }

  private updateModels(): void {
    this.updateModelFromFormGroup(this.resourceDto, this.resourceForm);
    this.updateModelFromFormGroup(this.resourceDto.place, this.resourcePlaceForm);

    if (!this.resourceDto.href) {
      this.resourceDto.href = `http://server:port/resourceInventoryManagement/resource/${this.resourceDto.type}?id=${this.resourceDto.id}`;
    }

    if (this.resourceDto.place && !this.resourceDto.place.id) {
      this.resourceDto.place = null;
    } else if (!this.resourceDto.place.href) {
      this.resourceDto.place.href = `https://server:port/v1/places/${this.resourceDto.place.id}`;
    }

    this.resourceDto.resourceCharacteristics = [];
    this.resourceCharacteristicsFormArray.controls.forEach((paramForm: FormGroup) => {
      const parameter = paramForm.getRawValue() as CharacteristicDto;
      this.resourceDto.resourceCharacteristics.push({
        name: parameter.name,
        value: parameter.value,
        valueType: parameter.valueType,
      });
    });

    this.resourceDto.relatedParties = [];
    this.resourcePartyRelationsFormArray.controls.forEach((paramForm: FormGroup) => {
      const parameter = paramForm.getRawValue() as RelatedPartyDto;
      this.resourceDto.relatedParties.push({
        id: parameter.id,
        referredType: parameter.referredType,
        href: parameter.href,
        name: parameter.name,
        role: parameter.role,
      });
    });

    this.resourceDto.resourceRelationships = [];
    this.resourceRelationshipsFormArray.controls.forEach((paramForm: FormGroup) => {
      const parameter = paramForm.getRawValue();

      this.resourceDto.resourceRelationships.push({
        relationshipType: parameter.relationshipType,
        type: parameter.direction,
        resource: {
          id: parameter.id,
          type: parameter.type,
        },
      });
    });
  }

  private updateModelFromFormGroup(model: any, form: FormGroup): void {
    Object.keys(form.controls).forEach((field: string) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        model[field] = control.value;
      } else if (control instanceof FormGroup) {
        this.updateModelFromFormGroup(model[field], control);
      }
    });
  }

  private initResourceCharacteristic(characteristics: CharacteristicDto[]): void {
    this.resourceCharacteristicsFormArray.controls = [];
    characteristics.forEach((relatedPartyDto: CharacteristicDto) => {
      this.resourceCharacteristicsFormArray.push(
        this.formBuilder.group({
          id: [relatedPartyDto.id],
          name: [relatedPartyDto.name, Validators.required],
          value: [relatedPartyDto.value, Validators.required],
          valueType: [relatedPartyDto.valueType],
        })
      );
    });
  }

  private initResourceRelatedParties(relatedParties: RelatedPartyDto[]): void {
    this.pagedRelatedParties = { data: [] };
    this.resourcePartyRelationsFormArray.controls = [];
    relatedParties.forEach((relatedPartyDto: RelatedPartyDto) => {
      this.resourcePartyRelationsFormArray.push(
        this.formBuilder.group({
          id: [relatedPartyDto.id, Validators.required],
          name: [relatedPartyDto.name],
          role: [relatedPartyDto.role, Validators.required],
          referredType: [relatedPartyDto.referredType, Validators.required],
          href: [relatedPartyDto.href],
        })
      );
      this.pagedRelatedParties = { data: this.resourcePartyRelationsFormArray.getRawValue() };
    });
  }

  private initResourceRelationships(resourceRelationships: ResourceRelationshipDto[]): void {
    this.pagedResourceRelationships = { data: [] };
    this.resourceRelationshipsFormArray.controls = [];
    resourceRelationships.forEach((relationshipDto: ResourceRelationshipDto) => {
      this.resourceRelationshipsFormArray.push(
        this.formBuilder.group({
          id: [relationshipDto.resource.id],
          relationshipType: [relationshipDto.relationshipType],
          direction: [relationshipDto.type],
          type: [relationshipDto.resource.type],
          name: [relationshipDto.resource.name],
        })
      );
      this.pagedResourceRelationships = { data: this.resourceRelationshipsFormArray.getRawValue() };
    });
  }

  private getIdPatternByType(type: string): void {
    if (type) {
      this.appBlockerService.block();
      this.propertyAccessorLocalService
        .getResouceIdNormalizedFormat(type)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(value => {
          if (value) {
            this.resourceIdControl.setValidators([Validators.required, Validators.pattern(value)]);
            this.resourceIdControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
          }
        });
    }
  }

  private initResourceCategory(type?: string): void {
    const resourceType = type || this.resourceTypeControl.value;
    const categoryValue = this.resourceForm.get('category').value;
    this.appBlockerService.block();
    this.codebookService
      .getCodebooks('RESOURCE_CATEGORY')
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((codebooks: CodebookDto[]) => {
        this.resourceCategories = [];
        for (const codebook of codebooks) {
          if (
            codebook?.parameters?.length &&
            codebook.parameters.find(i => i.name === 'resourceType' && i.value === resourceType)
          ) {
            this.resourceCategories.push({
              label: codebook?.localizedTexts?.length ? codebook?.localizedTexts[0].text : codebook.code,
              value: codebook.code,
            });
          }
        }
        if (categoryValue && !this.resourceCategories.find(i => i.value === categoryValue)) {
          this.resourceForm.get('category').setValue(null);
        }
        this.resourceForm.get('category').setValidators(this.resourceCategories.length ? Validators.required : null);
        this.resourceForm.get('category').updateValueAndValidity();
      });
  }
}
