export enum Icons {
  dashboard = 'dashboard',
  relationships = 'relationships',
  categories = 'categories',
  product = 'product',
  banners = 'banners',
  codebooks = 'codebooks',
  localizedTexts = 'localizedTexts',
  stickers = 'stickers',
  ruleSets = 'ruleSets',
  cartEvent = 'cartEvent',
  dynamicContextRules = 'dynamicContextRules',
  groups = 'groups',
  groupTypes = 'groupTypes',
  importExport = 'importExport',
  accountEntity = 'accountEntity',
  accounts = 'accounts',
  codebookTypes = 'codebookTypes',
  promotions = 'promotions',
  roles = 'roles',
  privileges = 'privileges',
  sockets = 'sockets',
  ticketTypes = 'ticketTypes',
  tickets = 'tickets',
  ticketSteps = 'ticketSteps',
  cms = 'cms',
  help = 'help',
  leavePage = 'leavePage',
  editActiveProduct = 'editActiveProduct',
  calendar = 'calender',
  hide = 'hide',
  plus = 'plus',
  save = 'save',
  show = 'show',
  doubleArrowDown = 'doubleArrowDown',
  singleArrowDown = 'singleArrowDown',
  doubleArrowLeft = 'doubleArrowLeft',
  delete = 'delete',
  anonymize = 'anonymize',
  edit = 'edit',
  exit = 'exit',
  archive = 'archive',
  duplicate = 'duplicate',
  reset = 'reset',
  bug = 'bug',
  highPriority = 'highPriority',
  time = 'time',
  subtask = 'subtask',
  task = 'task',
  avatar = 'avatar',
  darkMode = 'darkMode',
  language = 'language',
  logout = 'logout',
  download = 'download',
  upload = 'upload',
  customer = 'customer',
  reminder = 'reminder',
  other = 'other',
  outage = 'outage',
  trouble = 'trouble',
  widgetBoximage = 'widgetBoximage',
  widgetConfirm = 'widgetConfirm',
  widgetDiscard = 'widgetDiscard',
  widgetEmpty = 'widgetEmpty',
  widgetFullimage = 'widgetFullimage',
  widgetHeading = 'widgetHeading',
  widgetIconlist = 'widgetIconlist',
  widgetNew = 'widgetNew',
  widgetPageimage = 'widgetPageimage',
  widgetProductlist = 'widgetProductlist',
  widgetRedo = 'widgetRedo',
  widgetTipimage = 'widgetTipimage',
  widgetUndo = 'widgetUndo',
  widgetUpload = 'widgetUpload',
  widgetVideo = 'widgetVideo',
  widgetVideolist = 'widgetVideolist',
  widgetWysiwyg = 'widgetWysiwyg',
  widgetZigzag = 'widgetZigzag',
  widgetButton = 'widgetButton',
  widgetAccordion = 'widgetAccordion',
  widgetPlusSquareRegular = 'widgetPlusSquareRegular',
  widgetPlusSquareSolid = 'widgetPlusSquareSolid',
  accept = 'accept',
  close = 'close',
  copy = 'copy',
  dragAndDrop = 'dragAndDrop',
  duplicity = 'duplicity',
  infoTooltip = 'infoTooltip',
  refuse = 'refuse',
  activeState = 'activeState',
  designState = 'designState',
  obsoleteState = 'obsoleteState',
  testState = 'testState',
  notification = 'notification',
  server = 'server',
  campaigns = 'campaigns',
  acceptedPeople = 'acceptedPeople',
  moneyBag = 'moneyBag',
  error5xx = 'error5xx',
  error401 = 'error401',
  error404 = 'error404',
  deleteGray = 'deleteGray',
  single_active = 'single_active',
  multi_active = 'multi_active',
  check = 'check',
  stop = 'stop',
  chevronLeft = 'chevronLeft',
  externalLink = 'externalLink',
  arrowUp = 'arrowUp',
  clock = 'clock',
  export = 'export',
  import = 'import',
  notifications = 'notifications',
  notificationsGateway = 'notificationsGateway',
  notificationsTypes = 'notificationsTypes',
  dropdownArrow = 'dropdownArrow',
  generalType = 'generalType',
  dimensionType = 'dimensionType',
  comparisonType = 'comparisonType',
  documentTemplates = 'documentTemplates',
  dataTypeEnums = 'dataTypeEnums',
  configuration = 'configuration',
  resources = 'resources',
  store = 'store',
  stock = 'stock',
  preview = 'preview',
  switchToNewVersion = 'switchToNewVersion',
  crm = 'crm',
  agreementTypes = 'agreementTypes',
  metaProfiles = 'metaProfiles',
  ticketEnums = 'ticketEnums',
  pcReleases = 'pcReleases',
  filterReset = 'filterReset',
}
