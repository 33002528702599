<div *ngIf="textTypeId" [formGroup]="form">
  <div class="card__inner">
    <div class="card__header" style="text-align: left">
      {{ headerTranslationKey | translate }}
    </div>
    <div class="card__body">
      <div fxLayout="row" style="flex: 1 1 auto; column-gap: 20px">
        <div
          *ngFor="let locale of locales"
          [locale]="locale"
          [parentGroup]="form"
          [contentType]="contentType"
          [textTypeId]="textTypeId"
          [disabled]="disabled"
          translationPrefix="wc.admin.locale."
          uploadUrl="/api/bff/admin/v1/files/content"
          form-text
          class="form__row"
          style="flex: 1"></div>
      </div>
    </div>
  </div>
</div>
