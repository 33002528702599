<div>
  <h2 mat-dialog-title>
    {{ 'wc.admin.notification.type.chooseLanguage' | translate }}
  </h2>

  <mat-dialog-content class="mat-typography">
    <div class=".mat-drawer-container">
      <div class="mat-sidenav-content">
        <div class="main_content" [formGroup]="formGroup">
          <app-input-select-form-field
            [formControlName]="'locale'"
            [options]="locales"
            [translationPrefix]="'wc.admin.notification.type'">
          </app-input-select-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button color="primary" mat-raised-button (click)="save()" [routerLink]="">
      {{ 'wc.common.save' | translate }}
    </button>
    <button color="primary" mat-raised-button (click)="cancel()" [routerLink]="">
      {{ 'wc.common.cancel.button' | translate }}
    </button>
  </mat-dialog-actions>
</div>
