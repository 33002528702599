import { Component } from '@angular/core';
import {
  AclService,
  AdminProductCartEventService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { PagedCartEventsDto } from '@btl/admin-bff';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  CartEventEditComponent,
} from '@components/product-catalogue/cart-events/cart-event-edit/cart-event-edit.component';

import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';

@Component({
  selector: 'app-cart-events-listing',
  templateUrl: './cart-events-listing.component.html',
  styleUrls: ['./cart-events-listing.component.scss'],
})
@EnableDynamicLoading({ customName: CartEventsListingComponent.PAGE_ID })
export class CartEventsListingComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'CartEventsListingComponent';

  pageId(): string {
    return CartEventsListingComponent.PAGE_ID;
  }

  baseFilterVisible = true;

  pagedCartEventsDto: PagedCartEventsDto;

  constructor(
    private adminCartEventService: AdminProductCartEventService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  toggleFilter() {
    this.baseFilterVisible = !this.baseFilterVisible;
  }

  editCartEvent(cartEventDto, inNewTab: boolean = false) {
    this.navigateSibling(CartEventEditComponent.PAGE_ID, { id: cartEventDto.id }, inNewTab);
  }

  newCartEvent() {
    this.editCartEvent({
      id: 'newProductCartEvent',
    });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminCartEventService
        .getCartEventsByFilter(this.search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedCartEventsDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminCartEventService.deleteCartEvents(bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminCartEventService.deleteCartEvent(rowDataId);
  }
}
