import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AbstractPageComponent,
  AclService,
  AdminDmsService,
  AdminDynamicEnumService,
  AdminPictureTypeService,
  AdminProductGroupService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupProductsListingComponent } from './group-products-listing/group-products-listing.component';
import { GroupDto, GroupProductDto } from '@btl/admin-bff';
import {
  GroupProductsListingItemComponent
} from './group-products-listing/group-products-listing-item/group-products-listing-item.component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { ReleasesSelectService } from '@components/releases-select/releases-select.service';
import { MatDialog } from '@angular/material/dialog';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { FormPicturesComponent } from '@components/form-pictures/form-pictures.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import {
  ProductGroupListingComponent
} from '@components/product-catalogue/product-group/product-group-listing/product-group-listing.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-product-group-edit',
  templateUrl: './product-group-edit.component.html',
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({customName: ProductGroupEditComponent.PAGE_ID})
export class ProductGroupEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'ProductGroupEditComponent';
  public readonly MAX_TOP_PRODUCTS: number = 100;
  private readonly GUI_CATEGORY = 'GUI_CATEGORY';

  private readonly BESTSELLING_LIST_PARAM_NAME = 'bestsellingList';
  private readonly BESTSELLING_LIST_ENABLE_PARAM_NAME = 'bestsellingEnabled';
  private readonly BESTSELLING_LIST_MAX_COUNT_PARAM_NAME = 'bestsellingMaxCount';

  pageId(): string {
    return ProductGroupEditComponent.PAGE_ID;
  }

  pcEnumerationService = EnumerationsService.PC;

  PickerInputType = PickerInputType;

  productGroup: GroupDto;

  savingProductGroupId;

  groupForm: FormGroup = this.formBuilder.group({
    id: [null],
    code: [null, Validators.required],
    type: [null, Validators.required],
    name: [null],
    priority: [0, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
    seoUrl: [null, [Validators.required, Validators.pattern('[A-Za-z0-9_-]+')]],
    enabled: [1, Validators.required],
    extId: [null],
    parentId: [null],
    description: [null],
    recordVersion: [null],
    groupProducts: this.formBuilder.array([]),
    texts: [null],
    pictures: [null],
    groupParams: [],
  });

  bestsellingListForm: FormGroup = this.formBuilder.group({
    groupProducts: this.formBuilder.array([]),
    bestsellingListEnables: [],
    bestsellingListMaxCount: [null, Validators.required],
  });

  bestsellingListFormPatched = false;
  groupTypeNames: Array<string> = [];

  selectedGroupTypeParamNames: Array<string> = [];

  @ViewChild(GroupProductsListingComponent, {static: false})
  groupProductsListingComponent: GroupProductsListingComponent;

  pictureTypes;

  groupTree = [];
  first = true;
  onParentChange$: Subject<void> = new Subject<void>();

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private releasesSelectService: ReleasesSelectService,
    private adminProductGroupService: AdminProductGroupService,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private stickyMessageService: StickyMessageService,
    private adminPictureTypeService: AdminPictureTypeService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    private adminDmsService: AdminDmsService
  ) {
    super(router, route);
    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductGroupType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.groupTypeNames = result.data.map(value => value.name).sort();
      });

    this.groupForm.get('parentId').valueChanges.subscribe((value: string) => {
      this.onParentChange$.next();
      this.groupTree = [];
      this.onParentChange$ = new Subject<void>();
      this.loadGroupTree(this.productGroup.id);
      this.first = true;
    });
  }

  groupTypeChanged(groupTypeName: any) {
    //ToDo Use list of possible parameters (This are only used parameters now)

    // return this.adminProductGroupService
    //   .getGroupTypeUsedParameterNames(groupTypeName)
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe(result => this.selectedGroupTypeParamNames = result);

    if (groupTypeName === this.GUI_CATEGORY) {
      let bestsellingProducts: GroupProductDto[] = [];
      let bestsellingProductsIds = this.productGroup.groupParams[this.BESTSELLING_LIST_PARAM_NAME]?.split(';');
      if (bestsellingProductsIds) {
        bestsellingProducts = bestsellingProductsIds.map(productId => this.productGroup.groupProducts.find(groupProduct => groupProduct.productMasterId === productId));
        bestsellingProducts.forEach(() => {
          this.getBestsellingListFormArray().push(this.formBuilder.group(GroupProductsListingItemComponent.getFormControlConfig()));
        });
      }

      const bestsellingListMaxCount = this.productGroup.groupParams[this.BESTSELLING_LIST_MAX_COUNT_PARAM_NAME];
      const bestsellingListEnablesParamValue = this.productGroup.groupParams[this.BESTSELLING_LIST_ENABLE_PARAM_NAME];
      let bestsellingListEnables = true;
      if (bestsellingListEnablesParamValue != null) {
        bestsellingListEnables = bestsellingListEnablesParamValue === 'true';
      }
      this.bestsellingListForm.patchValue({
        groupProducts: bestsellingProducts,
        bestsellingListMaxCount: bestsellingListMaxCount ? bestsellingListMaxCount : 3,
        bestsellingListEnables: bestsellingListEnables,
      });
      this.bestsellingListFormPatched = true;
    } else {
      delete this.productGroup.groupParams[this.BESTSELLING_LIST_MAX_COUNT_PARAM_NAME];
      delete this.productGroup.groupParams[this.BESTSELLING_LIST_ENABLE_PARAM_NAME];
      delete this.productGroup.groupParams[this.BESTSELLING_LIST_PARAM_NAME];

      this.groupForm.controls.groupParams.patchValue(this.productGroup.groupParams);
    }
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const groupId = this.params.id;
      if (groupId && groupId !== 'newProductGroup' && (!this.productGroup || this.productGroup.id !== groupId)) {
        this.adminProductGroupService
          .getGroupById(groupId)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.reloadProductGroup(result);
          });
      } else {
        this.reloadProductGroup(this.productGroup);
      }
      this.loadPictureTypes();
    } else {
      this.productGroup = undefined;
    }
  }

  loadPictureTypes() {
    this.adminPictureTypeService
      .getPictureTypes()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(pictureTypes => {
        //ToDo Add entity type filter to adminPictureTypeService.getPictureTypes()
        this.pictureTypes = pictureTypes.filter(pictureTypeDto => pictureTypeDto.id.startsWith('GROUP_'));
        if (this.pictureTypes.length === 0) {
          this.pictureTypes = pictureTypes;
        }
      });
  }

  reloadProductGroup(group: GroupDto) {
    this.appBlockerService.block();
    if (group) {
      this.productGroup = group;
    } else {
      this.productGroup = {
        priority: 0,
        enabled: true,
        pictures: [],
        texts: [],
        groupProducts: [],
        groupParams: {},
      };
    }

    this.getProductFormArray().clear();
    this.productGroup.groupProducts.forEach(groupProductDto => {
      this.addProductToFormArray(groupProductDto);
    });

    this.groupForm.reset(this.productGroup);
    if (this.groupProductsListingComponent) {
      this.groupProductsListingComponent.loadProducts();
    }

    if (this.productGroup.type) {
      this.groupTypeChanged(this.productGroup.type);
    }
    this.appBlockerService.unblock();
  }

  getProductFormArray(): FormArray {
    return this.groupForm.get('groupProducts') as FormArray;
  }

  getBestsellingListFormArray(): FormArray {
    return this.bestsellingListForm.get('groupProducts') as FormArray;
  }

  addProductToFormArray(product) {
    const productFromGroup = this.formBuilder.group(GroupProductsListingItemComponent.getFormControlConfig());
    productFromGroup.patchValue(product);
    this.getProductFormArray().push(productFromGroup);
  }

  public getProductGroupHandler = (groupDto: GroupDto): void => {
    if (groupDto) {
      if (groupDto.id === this.savingProductGroupId) {
        this.reloadProductGroup(groupDto);
      } else {
        this.reloadProductGroup(groupDto);
        this.navigateSelf({ id: groupDto.id });
      }
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  reset() {
    if (this.productGroup.recordVersion) {
      this.appBlockerService.block();
      this.adminProductGroupService
        .getGroupById(this.productGroup.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(this.getProductGroupHandler);
    } else {
      this.reloadProductGroup(this.productGroup);
    }
  }

  delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.productgroups.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminProductGroupService
        .deleteGroup(this.productGroup.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.navigateSibling(ProductGroupListingComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  duplicate() {
    this.productGroup.code = `${this.groupForm.controls.code.value}copy`;
    this.productGroup.seoUrl = `${this.groupForm.controls.seoUrl.value}copy`;
    this.productGroup.id = null;
    this.productGroup.recordVersion = null;
    this.groupForm.patchValue(this.productGroup);
  }

  save() {
    if (this.saveGroupAttributes()) {
      this.appBlockerService.block();

      const pictures = this.groupForm.getRawValue().pictures;
      const pictureBulkOperations = FormPicturesComponent.getDmsBulk(pictures);
      this.productGroup.pictures = FormPicturesComponent.clearAttachmentList(pictures);
      if (pictureBulkOperations) {
        this.adminDmsService.changeFiles(pictureBulkOperations).subscribe(() => {
          this.saveGroup();
        });
      } else {
        this.saveGroup();
      }
    }
  }

  saveGroup() {
    if (this.productGroup.type === this.GUI_CATEGORY) {
      let bestsellingFormValue = this.bestsellingListForm.getRawValue();
      this.productGroup.groupParams[this.BESTSELLING_LIST_PARAM_NAME] = bestsellingFormValue.groupProducts.map(groupProduct => groupProduct.productMasterId).join(';');
      this.productGroup.groupParams[this.BESTSELLING_LIST_ENABLE_PARAM_NAME] = bestsellingFormValue.bestsellingListEnables;
      this.productGroup.groupParams[this.BESTSELLING_LIST_MAX_COUNT_PARAM_NAME] = bestsellingFormValue.bestsellingListMaxCount;
    }

    if (this.productGroup.recordVersion) {
      this.savingProductGroupId = this.productGroup.id;
      this.adminProductGroupService
        .updateGroup(this.productGroup.id, this.productGroup)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(this.getProductGroupHandler);
    } else {
      this.productGroup.id = null;
      this.adminProductGroupService
        .createGroup(this.productGroup)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(this.getProductGroupHandler);
    }
  }

  saveGroupAttributes(): boolean {
    FormUtils.validateAllFormFields(this.groupForm);
    if (this.groupForm.valid) {
      this.productGroup = this.groupForm.getRawValue();
      return true;
    }

    return false;
  }

  cancel() {
    this.navigateSibling(ProductGroupListingComponent.PAGE_ID);
  }

  loadGroupTree(groupId: string): void {
    if (groupId) {
      this.adminProductGroupService
        .getGroupById(groupId)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(takeUntil(this.onParentChange$))
        .subscribe(group => {
          this.groupTree.push({
            id: group.id,
            name: group.name,
            active: false,
          });

          if (this.first && this.groupForm.get('parentId').value !== groupId) {
            this.loadGroupTree(this.groupForm.get('parentId').value);
            this.first = false;
          } else if (group.parentId) {
            this.first = false;
            this.loadGroupTree(group.parentId);
          } else {
            this.createBreadCrumb();
          }
        });
    }
  }

  createBreadCrumb(): void {
    this.groupTree.reverse();
    this.groupTree.forEach(group => {
      if (group.id === this.productGroup.id) {
        group.active = true;
      } else {
        group.name = `${group.name} / `;
        group.active = false;
      }
    });
  }

  getProductSourceFilter() {
    return [
      {
        column: 'fromId',
        compareType: 'EQUAL',
        value: this.productGroup.id,
      },
      {
        column: 'fromTypeId',
        compareType: 'EQUAL',
        value: 'ProdGroup',
      },
    ];
  }

  releases() {
    if (this.productGroup && this.productGroup.code) {
      this.releasesSelectService.selectReleases('ProdGroup', this.productGroup.code);
    }
  }
}
