import { Component } from '@angular/core';
import {
  AclService,
  AdminSocketService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PagedSocketsDto, SocketDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { SocketEditComponent } from '@components/product-catalogue/socket/edit/socket-edit.component';

import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-socket-list',
  templateUrl: './socket-list.component.html',
  styleUrls: ['./socket-list.component.scss'],
})
@EnableDynamicLoading({ customName: SocketListComponent.PAGE_ID })
export class SocketListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'SocketListComponent';

  pageId(): string {
    return SocketListComponent.PAGE_ID;
  }

  pagedSocketsDto: PagedSocketsDto;

  socketShowContent: SocketDto = null;

  constructor(
    private adminSocketService: AdminSocketService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  editSocket(socketDto, inNewTab: boolean = false) {
    this.navigateSibling(SocketEditComponent.PAGE_ID, { id: socketDto.id }, inNewTab);
  }

  newSocket() {
    this.router.navigate(['&'], { relativeTo: this.route });
    this.navigateSibling(SocketEditComponent.PAGE_ID, { id: '&' });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.pagedSocketsDto = null;
      this.adminSocketService.getSocketsByFilter(this.search).subscribe(result => {
        if (result) {
          this.pagedSocketsDto = result;
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminSocketService.deleteSockets(bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminSocketService.deleteSocket(rowDataId);
  }
}
