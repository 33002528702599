import { Component, ViewChild } from '@angular/core';
import { RoleDto } from '@btl/admin-bff';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminAclService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  PaginationComponent,
  Search,
  ServiceUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  OperationType,
  RulesListingComponent,
} from '@components/acl/roles/role-edit/rules-listing/rules-listing.component';
import { Animations } from '@helpers/animations';
import { RolesListingComponent } from '@components/acl/roles/roles-listing/roles-listing.component';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: RoleEditComponent.PAGE_ID })
export class RoleEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'RoleEditComponent';

  pageId(): string {
    return RoleEditComponent.PAGE_ID;
  }

  savingRoleId;
  role: RoleDto;
  accountsRoleId = null;

  roleForm: FormGroup = this.formBuilder.group({
    id: [null, Validators.required],
    description: [null, Validators.required],
    external: [false, Validators.required],
    parameters: [],
  });

  @ViewChild(RulesListingComponent, { static: false })
  rulesListingComponent: RulesListingComponent;

  @ViewChild(PaginationComponent, { static: true })
  paginationComponent: PaginationComponent;

  operations = [];

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: -1,
    },
  };

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private adminAclService: AdminAclService,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private stickyMessageService: StickyMessageService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const roleId = this.params.id;
      if (roleId && roleId !== 'newRole') {
        this.loadRole(roleId);
      } else {
        this.role = { id: null, external: false };
      }
    } else {
      this.role = undefined;
    }
  }

  loadRole(roleId) {
    this.adminAclService
      .getRole(roleId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadRole(result);
      });
  }

  reloadRole(roleDto: RoleDto) {
    this.operations = [];
    this.role = roleDto;
    this.roleForm.reset(this.role);
    this.accountsRoleId = roleDto.id;
  }

  public getRoleHandler = (roleDto: RoleDto): void => {
    const bulkOperationsRequestDto = {
      bulkCreateList: this.operations.filter(
        rule => rule[RulesListingComponent.OPERATION_TYPE] === OperationType.CREATE
      ),
      bulkDeleteList: this.operations.filter(
        rule => rule[RulesListingComponent.OPERATION_TYPE] === OperationType.DELETE
      ),
      bulkUpdateList: this.operations.filter(
        rule => rule[RulesListingComponent.OPERATION_TYPE] === OperationType.UPDATE
      ),
    };
    const bulkCreateListIds = [];
    bulkOperationsRequestDto.bulkCreateList.forEach(rule => {
      bulkCreateListIds.push(rule.id);
      rule.id = null;
      rule.roleType = roleDto.id;
    });

    this.adminAclService.changeRules(bulkOperationsRequestDto).subscribe(
      () => {
        if (roleDto) {
          if (roleDto.id === this.savingRoleId) {
            this.reloadRole(roleDto);
          } else {
            this.reloadRole(roleDto);
            this.navigateSelf({ id: roleDto.id });
          }
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        }
      },
      error => {
        let index = 0;
        bulkOperationsRequestDto.bulkCreateList.forEach(rule => {
          rule.id = bulkCreateListIds[index];
          index++;
        });
      }
    );
  };

  reset() {
    if (this.role && this.role.recordVersion) {
      this.loadRole(this.role.id);
    } else {
      this.reloadRole(this.role);
    }
  }

  delete() {
    this.appBlockerService.block();
    this.adminAclService
      .deleteRole(this.role.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.navigateSibling(RolesListingComponent.PAGE_ID);
        this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
      });
  }

  duplicate() {
    this.roleForm.controls.id.patchValue(`${this.role.id}copy`);
    this.role.recordVersion = null;
    this.accountsRoleId = null;
    this.roleForm.controls.id.enable();
    const search = ServiceUtils.getUnlimitedSearch();
    search.filtering.push({ column: 'roleType', value: this.role.id, compareType: 'EQUAL' });
    this.adminAclService
      .filterRules(search, null)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        result.data.forEach(rule => {
          const operationRule = this.operations.find(findRule => findRule.id === rule.id);
          if (operationRule) {
            if (operationRule[RulesListingComponent.OPERATION_TYPE] === OperationType.UPDATE) {
              operationRule[RulesListingComponent.OPERATION_TYPE] = OperationType.CREATE;
            } else if (operationRule[RulesListingComponent.OPERATION_TYPE] === OperationType.DELETE) {
              this.operations.splice(this.operations.indexOf(operationRule), 1);
            }
          } else {
            rule.id = null;
            rule.recordVersion = null;
            rule[RulesListingComponent.OPERATION_TYPE] = OperationType.CREATE;
            this.operations.push(rule);
          }
          this.role.id = `${this.role.id}copy`;
          this.rulesListingComponent.constantFilter.length = 0;
          this.rulesListingComponent.pagedRulesDto.data.length = 0;
        });
      });
  }

  save() {
    this.savingRoleId = this.role.id;
    if (this.saveRoleAttributes() && this.rulesListingComponent.save()) {
      this.appBlockerService.block();
      this.role.created = null;
      this.role.createdBy = null;
      this.role.modified = null;
      this.role.modifiedBy = null;
      if (this.role.recordVersion) {
        const filer = ServiceUtils.getUnlimitedSearch();
        filer.filtering = [{ column: 'roleType', value: this.role.id, compareType: 'EQUAL' }];
        this.adminAclService
          .filterRules(filer, null)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            if (
              this.operations.filter(rule => rule[RulesListingComponent.OPERATION_TYPE] === OperationType.DELETE)
                .length === result.totalItems &&
              this.operations.filter(rule => rule[RulesListingComponent.OPERATION_TYPE] === OperationType.CREATE)
                .length === 0
            ) {
              this.appBlockerService.unblock();
              this.stickyMessageService.addStickyErrorMessage(
                'wc.admin.roles.editRole.allPrivilegesRemoved.error.text'
              );
            } else {
              this.adminAclService
                .updateRole(this.savingRoleId, this.role)
                .pipe(finalize(this.appBlockerService.unblock))
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(this.getRoleHandler);
            }
          });
      } else {
        this.savingRoleId = null;
        if (
          this.operations.filter(rule => rule[RulesListingComponent.OPERATION_TYPE] === OperationType.CREATE).length ===
          0
        ) {
          this.appBlockerService.unblock();
          this.stickyMessageService.addStickyErrorMessage('wc.admin.roles.newRole.noPrivileges.error.text');
        } else {
          this.adminAclService
            .createRole(this.role)
            .pipe(finalize(this.appBlockerService.unblock))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(this.getRoleHandler);
        }
      }
    }
  }

  saveRoleAttributes(): boolean {
    FormUtils.validateAllFormFields(this.roleForm);
    if (this.roleForm.valid) {
      if (this.role) {
        Object.keys(this.roleForm.controls).forEach(field => {
          const control = this.roleForm.get(field);
          this.role[field] = control.value;
        });
      }
      return true;
    }

    return false;
  }

  clearField(field: string) {
    this.roleForm.controls[field].patchValue(null);
  }

  cancel() {
    this.navigateSibling(RolesListingComponent.PAGE_ID);
  }
}
