import { Component, ViewChild } from '@angular/core';
import { AgreementTypeDto, DmsAdminService, DmsFileBulkOperationsRequestDto } from '@btl/admin-bff';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminAgreementTypeService,
  AdminDynamicEnumService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import {
  AgreementTypesListingComponent,
} from '@components/crm/agreement-type/agreement-type-listing/agreement-types-listing.component';
import { Attachment, FormAttachmentsComponent } from '@components/form-attachments/form-attachments.component';

@Component({
  selector: 'app-agreementType-edit',
  templateUrl: './agreement-type-edit.component.html',
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: AgreementTypeEditComponent.PAGE_ID })
export class AgreementTypeEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'AgreementTypeEditComponent';

  pageId(): string {
    return AgreementTypeEditComponent.PAGE_ID;
  }

  readonly AGREEMENT_TYPE = 'com.emeldi.ecc.be.crm.dto.AgreementType';

  @ViewChild(FormAttachmentsComponent, { static: false })
  attachmentsComponent: FormAttachmentsComponent;

  savingAgreementTypeId;
  agreementType: AgreementTypeDto;
  textTypes: Array<any>;

  validForForm: FormGroup = this.formBuilder.group({
    startDateTime: [null, [Validators.required, FormUtils.validateOrderOfStartEnd()]],
    endDateTime: [null, FormUtils.validateOrderOfStartEnd()],
  });

  form: FormGroup = this.formBuilder.group({
    id: [null],
    code: [null, Validators.required],
    name: [null, Validators.required],
    mandatory: [false],
    validFor: this.validForForm,
    recordVersion: [null],
    params: [],
    references: [],
    texts: [],
  });

  attachmentsForm: FormGroup = this.formBuilder.group({
    attachments: [],
  });

  bulkOperations: DmsFileBulkOperationsRequestDto = null;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private adminAgreementTypeService: AdminAgreementTypeService,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private stickyMessageService: StickyMessageService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    public aclService: AclService,
    private adminDmsService: DmsAdminService
  ) {
    super(router, route);

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.CRM, 'com.emeldi.ecc.be.common.gentext.enums.TextReferenceType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.textTypes = [];
        result.data.forEach(textType => {
          if (textType.name.startsWith('AgreementType')) {
            this.textTypes.push({
              id: textType.name,
              entityType: '',
              contentType: textType.fields.contentType,
              priority: textType.fields.priority,
            });
          }
        });
        this.textTypes.sort((a, b) => (a.priority > b.priority ? 1 : -1));
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const agreementTypeId = this.params.id;
      if (agreementTypeId && agreementTypeId !== 'newAgreementType') {
        this.loadAgreementType(agreementTypeId);
      } else {
        this.agreementType = { id: null };
      }
    } else {
      this.agreementType = undefined;
    }
  }
  loadAgreementType(agreementTypeId) {
    this.agreementType = null;
    this.adminAgreementTypeService
      .getAgreementTypeById(agreementTypeId, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadAgreementType(result);
      });
  }

  reloadAgreementType(agreementTypeDto: AgreementTypeDto) {
    this.agreementType = agreementTypeDto;
    this.form.reset(this.agreementType);
  }

  public getAgreementTypeHandler = (agreementTypeDto: AgreementTypeDto): void => {
    if (agreementTypeDto) {
      if (this.bulkOperations) {
        this.bulkOperations.bulkPatchList.forEach(file => {
          if (file.delete) {
            file.references = file.references?.filter(fileRef => fileRef.entityType !== this.AGREEMENT_TYPE);
          } else {
            if (!file.references) {
              file.references = [];
            }
            if (!file.references?.find(ref => ref.entityId === agreementTypeDto.id && ref.entityType === this.AGREEMENT_TYPE
              && ref.refType === 'GENERAL')) {
              file.references.push({
                entityId: agreementTypeDto.id,
                entityType: this.AGREEMENT_TYPE,
                refType: 'GENERAL',
              });
            }
          }
          delete file.delete;
        });
        this.bulkOperations.bulkCreateList.forEach(file => {
          file.references.push({
            entityId: agreementTypeDto.id,
            entityType: this.AGREEMENT_TYPE,
            refType: 'GENERAL',
          });
        });
        this.adminDmsService.changeFiles(this.bulkOperations).subscribe(() => {
          this.bulkOperations = null;
          this.attachmentsForm.patchValue({ attachments: [] });
          this.navigateToAgreementType(agreementTypeDto);
        });
      } else {
        this.navigateToAgreementType(agreementTypeDto);
      }
    }
  };

  private navigateToAgreementType(agreementTypeDto) {
    if (agreementTypeDto.id === this.agreementType.id) {
      this.reloadAgreementType(agreementTypeDto);
    } else {
      this.reloadAgreementType(agreementTypeDto);
      this.navigateSelf({ id: agreementTypeDto.id });
    }
    this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
  }

  reset() {
    if (this.agreementType && this.agreementType.recordVersion) {
      this.loadAgreementType(this.agreementType.id);
    } else {
      this.reloadAgreementType(this.agreementType);
    }
  }

  delete() {
    this.appBlockerService.block();
    this.adminAgreementTypeService
      .deleteAgreementType(this.agreementType.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        const patchList = [];
        this.attachmentsForm.value.attachments?.forEach((attachment: Attachment) => {
          if (attachment.dmsFile?.id) {
            patchList.push({
              id: attachment.dmsFile.id,
              references: attachment.dmsFile.references?.filter(dmsRef => dmsRef.entityType !== this.AGREEMENT_TYPE),
              recordVersion: attachment.dmsFile.recordVersion,
            });
          }
        });

        if (patchList.length > 0) {
          const bulkOperations: DmsFileBulkOperationsRequestDto = {
            bulkCreateList: [],
            bulkUpdateList: [],
            bulkPatchList: patchList,
          };
          this.adminDmsService.changeFiles(bulkOperations).subscribe(() => {
            this.backToListingAfterDelete();
          });
        }
        this.backToListingAfterDelete();
      });
  }

  backToListingAfterDelete() {
    this.navigateSibling(AgreementTypesListingComponent.PAGE_ID);
    this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
  }

  duplicate() {
    this.form.controls.recordVersion.patchValue(null);
    this.form.controls.id.patchValue(null);
  }

  save() {
    const createList = [];
    const patchList = [];

    this.attachmentsForm.value.attachments?.forEach((attachment: Attachment) => {
      if (attachment.dmsFile?.id) {
        if (attachment.delete) {
          patchList.push({
            id: attachment.dmsFile.id,
            delete: true,
            references: [],
            recordVersion: attachment.dmsFile.recordVersion,
          });
        } else {
          FormAttachmentsComponent.addLanguageParam(attachment.dmsFile);
          patchList.push({
            id: attachment.dmsFile.id,
            extId: attachment.dmsFile.extId,
            name: attachment.dmsFile.name,
            params: attachment.dmsFile.params,
            references: attachment.dmsFile.references,
            texts: attachment.dmsFile.texts,
            recordVersion: attachment.dmsFile.recordVersion,
          });
        }
      } else {
        FormAttachmentsComponent.addLanguageParam(attachment.dmsFile);
        createList.push(attachment.dmsFile);
      }
      delete attachment.dmsFile;
    });

    if (createList.length > 0 || patchList.length > 0) {
      this.bulkOperations = {
        bulkCreateList: createList,
        bulkUpdateList: [],
        bulkPatchList: patchList,
      };
    }

    this.savingAgreementTypeId = this.agreementType.id;
    if (this.saveAgreementTypeAttributes()) {
      this.appBlockerService.block();
      if (this.agreementType.recordVersion) {
        this.adminAgreementTypeService
          .updateAgreementType(this.savingAgreementTypeId, this.agreementType)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getAgreementTypeHandler);
      } else {
        this.savingAgreementTypeId = null;
        this.adminAgreementTypeService
          .createAgreementType(this.agreementType)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getAgreementTypeHandler);
      }
    }
  }

  saveAgreementTypeAttributes(): boolean {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      if (this.agreementType) {
        this.agreementType = this.form.getRawValue();
      }
      return true;
    }

    return false;
  }

  cancel() {
    this.navigateSibling(AgreementTypesListingComponent.PAGE_ID);
  }
}
