<div class="card__wrapper">
  <!-- card -->
  <div class="card">
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.stepTemplate.steps.header' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action" *ngIf="!readOnly">
          <button
            id="001_ticket_step_template_listing"
            color="primary"
            mat-raised-button
            (click)="addStep()"
            [routerLink]=""
            class="btn -primary -xs edit icon stroke">
            <mat-icon svgIcon="plus"></mat-icon>{{ 'wc.common.add' | translate }}
          </button>
          <button
            id="002_ticket_step_template_listing"
            color="primary"
            mat-stroked-button
            (click)="deleteSteps()"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>{{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <table class="table -primary customTable dragDrop" style="table-layout: fixed">
          <thead>
            <th scope="col" [style.width.px]="30" *ngIf="!readOnly"></th>
            <th scope="col" [style.width.px]="100" *ngIf="!readOnly">
              {{ 'wc.admin.ticketType.steps.sequence' | translate }}
            </th>
            <th scope="col">
              {{ 'wc.admin.ticketType.steps.name' | translate }}
            </th>
            <th scope="col">
              {{ 'wc.admin.ticketType.steps.description' | translate }}
            </th>
            <th scope="col" [style.width.px]="75" *ngIf="!readOnly">{{ 'wc.admin.ticketType.action' | translate }}</th>
          </thead>
          <tbody cdkDropList #stepList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="readOnly">
            <tr
              *ngFor="let step of parentGroup.controls['steps'].value; let i = index"
              [step]="step"
              [position]="i"
              [readOnly]="readOnly"
              (deleteStep)="deleteStep($event, i)"
              (editStep)="editStep($event, i)"
              (dblclick)="!readOnly ? editStep(step, i) : ''"
              app-ticket-type-steps-list-item
              cdkDrag
              [cdkDragDisabled]="readOnly"
              cdkDragLockAxis="y"
              class="table__row"></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
