import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { Filter } from '@btl/btl-fe-wc-common/lib/models/search';
import {
  AbstractFilterComponent,
  AdminDmsService,
  AppBlockerService,
  ModulePreferencesService,
  PaginationComponent,
  Search,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { DmsFileDto, PagedDmsFilesDto } from '@btl/admin-bff';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {
  DmsFilesListingItemComponent,
} from '@components/dms-files-listing/dms-files-listing-item/dms-files-listing-item.component';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-dms-files-listing',
  templateUrl: './dms-files-listing.component.html',
  styleUrls: ['./dms-files-listing.component.scss'],
})
export class DmsFilesListingComponent implements OnInit, OnDestroy {
  private static readonly MODULE_ID = 'dmsFilesListingComponent';

  @Input()
  cacheFilter = true;

  @Input()
  constantFilter: Array<Filter> = [];

  @Input()
  additionalHeaderLtKey;

  @Input()
  hideFilter = false;

  @Input()
  multiSelectMode = false;

  @Input()
  singleSelectMode = false;

  @Input()
  disabledActions: boolean = false;

  @Input()
  selectMode: boolean = false;

  @Output()
  readonly singleSelectEvent: EventEmitter<DmsFileDto> = new EventEmitter<DmsFileDto>();

  @ViewChild(PaginationComponent, { static: true })
  paginationComponent: PaginationComponent;

  @ViewChildren(DmsFilesListingItemComponent)
  filesListingItemComponents: Array<DmsFilesListingItemComponent>;

  baseFilterVisible = true;

  @Input()
  editable = true;

  selected = false;

  pagedDmsFilesDto: PagedDmsFilesDto;
  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private adminDmsService: AdminDmsService,
    private router: Router,
    private route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private stickyMessageService: StickyMessageService,
    private modulePreferencesService: ModulePreferencesService
  ) {}

  ngOnInit() {
    if (this.cacheFilter) {
      const sessionSearch = this.modulePreferencesService.getPreferences(DmsFilesListingComponent.MODULE_ID);
      if (sessionSearch) {
        this.search = sessionSearch;
      }
    }

    if (this.constantFilter && this.constantFilter.length > 0) {
      this.search.filtering = this.constantFilter;
    }
    this.loadDmsFiles(this.search);
  }

  toggleFilter() {
    this.baseFilterVisible = !this.baseFilterVisible;
  }

  onSortChange(sorting: MatSort) {
    if (sorting.direction !== '') {
      this.search.sorting = [{ column: sorting.active, sortOrder: sorting.direction }];
    } else {
      this.search.sorting = [];
    }
    this.loadDmsFiles(this.search);
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadDmsFiles(this.search);
  }

  goToPage(pageNo: number): void {
    this.search.paging.page = pageNo;
    this.loadDmsFiles(this.search);
  }

  onNext(): void {
    this.search.paging.page++;
    this.loadDmsFiles(this.search);
  }

  onPrev(): void {
    this.search.paging.page--;
    this.loadDmsFiles(this.search);
  }

  pageSizeChanged(page): void {
    this.search.paging.page = page.pageIndex + 1;
    this.search.paging.pageSize = page.pageSize;
    this.loadDmsFiles(this.search);
  }

  getSelectedDmsFiles(): Array<DmsFileDto> {
    const selectedDmsFiles = [];
    this.filesListingItemComponents.forEach(productDmsFileListingItemComponent => {
      if (productDmsFileListingItemComponent.selected) {
        selectedDmsFiles.push(productDmsFileListingItemComponent.file);
      }
    });
    return selectedDmsFiles;
  }

  public loadDmsFiles(search: Search) {
    this.pagedDmsFilesDto = null;
    if (this.cacheFilter) {
      this.modulePreferencesService.setPreferences(DmsFilesListingComponent.MODULE_ID, this.search);
    }

    this.adminDmsService
      .filterFiles(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.pagedDmsFilesDto = result;
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public presentInFilter(column: String) {
    return AbstractFilterComponent.presentInFilter(this.constantFilter, column);
  }

  singleSelect(file) {
    if (this.singleSelectMode) {
      this.singleSelectEvent.emit(file);
    }
  }

  handleSelected() {
    this.filesListingItemComponents.forEach(filesListingItemComponent => {
      filesListingItemComponent.selected = this.selected;
    });
  }
}
