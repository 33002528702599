import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ProductEditService, VersioningTypeEnum } from '@service/product-edit.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AdminProductParameterService,
  AppBlockerService,
  CurrentLocaleService,
  EnableDynamicLoading,
  FormUtils,
  TechnicalCategoryService
} from '@btl/btl-fe-wc-common';
import { ProductDto, ProductParameterDto, ProductStateDto, TechnicalCategoryDto } from '@btl/admin-bff';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { EnumerationsService } from '@service/enumerations.service';

@Component({
  selector: 'app-product-attributes',
  templateUrl: './product-attributes.component.html',
  styleUrls: ['./product-attributes.component.scss'],
})
@EnableDynamicLoading({ customName: ProductAttributesComponent.PAGE_ID })
export class ProductAttributesComponent extends AbstractPageComponent implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductAttributesComponent';

  pageId(): string {
    return ProductAttributesComponent.PAGE_ID;
  }

  readonly SOURCE_NAME = EnumerationsService.PC;

  product: ProductDto;

  productParametersDto: Array<ProductParameterDto> = [];

  locales = environment.localization.locales;

  localizedProductParametersDto: Array<ProductParameterDto> = [];

  isCollapsed: boolean = false;
  isAttributeCollapsed: boolean = false;
  isLocalizedParametersCollapsed: boolean = false;

  syncModes = ['doNotSynchronize', 'synchronizeExceptExcludedParameters', 'synchronizeAllAttributes'];
  private categories: TechnicalCategoryDto[];
  private categoriesLoaded = new ReplaySubject<void>();
  categoriesHints: string[] = [];
  versioningTypes = Object.values(VersioningTypeEnum);

  currentLocale;

  attributesLanguage;
  locale;

  list = [];
  removable = true;

  @ViewChild('tooltipFollowCursorContent') tooltipFollowCursorContent: ElementRef<HTMLElement>;

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private renderer: Renderer2,
    public productEditService: ProductEditService,
    private technicalCategoryService: TechnicalCategoryService,
    private appBlockerService: AppBlockerService,
    private adminProductParameterService: AdminProductParameterService,
    private currentLocaleService: CurrentLocaleService,
    private enumerationsService: EnumerationsService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super(router, route);
    this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.currentLocale = this.currentLocaleService.getCurrentLanguage();
      this.attributesLanguage = this.currentLocale;
      this.sortProductParameters();
    });
  }

  setProductParameterTooltip(tooltipMessage: string) {
    this.tooltipFollowCursorContent.nativeElement.innerHTML = tooltipMessage;
    this.renderer.setStyle(this.tooltipFollowCursorContent.nativeElement, 'display', 'block');
  }

  showProductParameterTooltip(event: MouseEvent) {
    this.renderer.setStyle(this.tooltipFollowCursorContent.nativeElement, 'top', `${event.pageY + 15}px`);
    this.renderer.setStyle(this.tooltipFollowCursorContent.nativeElement, 'left', `${event.pageX + 15}px`);
  }

  hideProductParameterTooltip() {
    this.tooltipFollowCursorContent.nativeElement.innerHTML = '';
    this.renderer.setStyle(this.tooltipFollowCursorContent.nativeElement, 'display', 'none');
  }

  navigationSubscription(navigation: NavigationEnd) {}

  static deleteControlError(control: AbstractControl, errorKey: string) {
    if (control.errors) {
      delete control.errors[errorKey];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  productParametersForm: FormGroup = this.formBuilder.group({});

  localizedProductParametersForm: FormGroup = this.formBuilder.group({});

  category;
  filterCategories = [];

  validateOrderOfStartEnd: ValidatorFn = function (control: AbstractControl) {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      const startControl = group.get('startDateTime');
      const endControl = group.get('endDateTime');
      const start = startControl.value;
      const end = endControl.value;
      if (start && end && start > end) {
        return { startIsLaterThanEnd: true };
      } else {
        if (control === startControl) {
          ProductAttributesComponent.deleteControlError(endControl, 'startIsLaterThanEnd');
        } else if (control === endControl) {
          ProductAttributesComponent.deleteControlError(startControl, 'startIsLaterThanEnd');
        }
      }
    }
    return null;
  };

  productForm: FormGroup = this.formBuilder.group({
    productCode: [null, [Validators.required, Validators.pattern('[A-Za-z0-9_-]+')]],
    servicePartner: ['INTERNAL'],
    category: [null, Validators.required],
    seoUrl: [null, Validators.pattern('[A-Za-z0-9_-]+')],
    main: [false, Validators.required],
    bundle: [false, Validators.required],
    availableFor: this.formBuilder.group({
      startDateTime: [null, [Validators.required, this.validateOrderOfStartEnd]],
      endDateTime: [, [this.validateOrderOfStartEnd]],
    }),
    masterAvailableFor: this.formBuilder.group({
      startDateTime: [null, [Validators.required, this.validateOrderOfStartEnd]],
      endDateTime: [, this.validateOrderOfStartEnd],
    }),
    visible: [false, Validators.required],
    orderable: [false, Validators.required],
    planningEnabled: [false, Validators.required],
    multipleOrderEnabled: [false, Validators.required],
    priority: [0, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
    syncMode: ['doNotSynchronize', Validators.required],
    professionalRating: [null, Validators.pattern('^\\d+(\\.\\d{1,1})?$')],
    popularityCalculationEnabled: [false, Validators.required],
    popularity: [null, Validators.pattern('^[0-9]*$')],
    description: [null, Validators.required],
    versioningType: [null],
  });

  loadData() {
    if (this.product) {
      this.productForm.reset(this.product);
      this.reloadDisabledFields(this.productForm);
      this.loadProductParametersConfigs(this.product.category);
    }
  }

  ngOnInit() {
    this.enumerationsService
      .getProductTechnicalCategoryDtos()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.categories = result;
        this.categoriesLoaded.next();
        this.categoriesLoaded.complete();
        this.categoriesHints = this.categories ? this.categories.map(c => c.id) : [];
      });
    this.product = this.productEditService.getEditProduct();
    this.loadData();

    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      this.product = result;
      this.loadData();
    });

    this.productEditService.productVersionChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if (result) {
        this.reloadDisabledFields(this.productForm);
        this.reloadDisabledFields(this.productParametersForm);
        this.reloadDisabledFields(this.localizedProductParametersForm);
      }
    });
    this.attributesLanguage = this.currentLocale;
  }

  reloadDisabledFields(form: FormGroup, parentForm?: FormGroup, formControl?) {
    Object.keys(form.controls).forEach(formControlName => {
      formControlName = formControl ? `${formControl}.${formControlName}` : formControlName;
      const controlForm = parentForm ? parentForm.get(formControlName) : form.get(formControlName);
      if (controlForm instanceof FormControl) {
        this.disableControl(parentForm ? parentForm : form, formControlName);
      }
      if (controlForm instanceof FormGroup) {
        this.reloadDisabledFields(controlForm, form, formControlName);
      }
    });
  }

  shouldDisableControl(): boolean {
    return !((this.isNewVersion() && this.isActive()) || this.isDesign() || this.isNewProduct());
  }

  disableControl(form, controlName) {
    if (this.shouldDisableControl()) {
      form.get(controlName).disable();
    } else {
      form.get(controlName).enable();
    }
    if (controlName === 'productCode' && !this.isNewProduct()) {
      form.get('productCode').disable();
    }
    if (controlName === 'versioningType' && !this.isNewProduct()) {
      form.get('versioningType').disable();
    }
    if (!this.isNewVersion() && this.isActive()) {
      if (
        controlName === 'availableFor.startDateTime' &&
        this.editProduct().availableFor &&
        new Date(this.editProduct().availableFor.startDateTime) > new Date()
      ) {
        form.get(controlName).enable();
      } else if (controlName === 'availableFor.endDateTime') {
        form.get(controlName).enable();
      }
    }
  }

  loadProductParametersConfigs(category: string) {
    if (category && !this.productEditService.categoryProductParameters) {
      this.appBlockerService.block();
      this.categoriesLoaded
        .pipe(switchMap(() => this.productEditService.loadProductParametersConfigs(this.categories, category)))
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.reloadProductParameterForms();
        });
    } else if (!category) {
      this.productEditService.categoryProductParameters = null;
      this.reloadProductParameterForms();
    } else {
      this.reloadProductParameterForms();
    }
  }

  reloadProductParameterForms() {
    this.productParametersDto = [];
    this.productParametersForm = this.formBuilder.group({});

    this.localizedProductParametersDto = [];
    this.localizedProductParametersForm = this.formBuilder.group({});

    if (this.productEditService.categoryProductParameters) {
      this.productEditService.categoryProductParameters.forEach(productParameter => {
        if (
          !this.productParametersForm.get(productParameter.name) &&
          !this.localizedProductParametersForm.get(productParameter.name)
        ) {
          if (productParameter.localized) {
            const formGroup = this.formBuilder.group({});
            this.locales.forEach(locale => {
              const formControl = new FormControl();
              if (productParameter.validationRegex) {
                FormUtils.setValidation(formControl, Validators.pattern('^[1-9]\\d*$'));
              }
              const productParameterTexts = this.product.localizedProductParameters[productParameter.name];
              if (productParameterTexts) {
                formControl.patchValue(productParameterTexts[locale]);
              }
              formGroup.addControl(locale, formControl);
            });
            this.localizedProductParametersForm.addControl(productParameter.name, formGroup);
            this.localizedProductParametersDto.push(productParameter);
          } else {
            const formControl = new FormControl();
            if (productParameter.validationRegex) {
              FormUtils.setValidation(formControl, Validators.pattern(productParameter.validationRegex));
            }
            if (productParameter.type === 'BOOLEAN') {
              let booleanValue = this.product.productParameters[productParameter.name];
              if (!booleanValue && productParameter.defaultValue) {
                booleanValue = productParameter.defaultValue;
              }
              if (booleanValue && booleanValue !== 'false') {
                formControl.patchValue(true);
              } else {
                formControl.patchValue(false);
              }
            } else if (productParameter.type === 'ENUM') {
              const originalValue = this.product.productParameters[productParameter.name];
              if (originalValue) {
                this.appBlockerService.block();
                this.enumerationsService
                  .getDataTypeEnumItems(this.SOURCE_NAME, productParameter.typeDetail)
                  .pipe(finalize(this.appBlockerService.unblock))
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(values => {
                    if (values.includes(originalValue)) {
                      formControl.patchValue(originalValue);
                    } else if (productParameter.defaultValue) {
                      formControl.patchValue(productParameter.defaultValue);
                    } else {
                      formControl.patchValue(null);
                    }
                  });
              } else if (productParameter.defaultValue) {
                formControl.patchValue(productParameter.defaultValue);
              }
            } else {
              if (this.product.productParameters[productParameter.name]) {
                formControl.patchValue(this.product.productParameters[productParameter.name]);
              } else if (productParameter.defaultValue) {
                formControl.patchValue(productParameter.defaultValue);
              }
            }
            this.productParametersForm.addControl(productParameter.name, formControl);
            this.productParametersDto.push(productParameter);
          }
        }
      });
      this.sortProductParameters();
    }
    this.reloadDisabledFields(this.productParametersForm);
    this.reloadDisabledFields(this.localizedProductParametersForm);
  }

  sortProductParameters() {
    this.localizedProductParametersDto.sort((a, b) =>
      a.localizedNames[this.currentLocale] > b.localizedNames[this.currentLocale] ? 1 : -1
    );
    this.productParametersDto.sort((a, b) =>
      a.localizedNames[this.currentLocale] > b.localizedNames[this.currentLocale] ? 1 : -1
    );
  }

  saveData(): boolean {
    if (!this.productEditService.canEdit()) {
      return true;
    }
    this.productForm.updateValueAndValidity();
    FormUtils.validateAllFormFields(this.productForm);
    FormUtils.validateAllFormFields(this.productParametersForm);
    FormUtils.validateAllFormFields(this.localizedProductParametersForm);
    if (
      (this.productForm.valid || this.productForm.disabled) &&
      (this.productParametersForm.valid || this.productParametersForm.disabled) &&
      (this.localizedProductParametersForm.valid || this.localizedProductParametersForm.disabled)
    ) {
      const product = this.productEditService.getEditProduct();
      if (product) {
        Object.keys(this.productForm.controls).forEach(field => {
          const control = this.productForm.get(field);
          if (field === 'availableFor') {
            product[field] = this.productForm.getRawValue()[field];
          } else {
            product[field] = control.value;
          }
        });
      }
      product.productParameters = {};
      Object.keys(this.productParametersForm.controls).forEach(field => {
        const control = this.productParametersForm.get(field);
        product.productParameters[field] = control.value;
      });

      product.localizedProductParameters = {};

      Object.keys(this.localizedProductParametersForm.controls).forEach(group => {
        const groupControl = this.localizedProductParametersForm.get(group) as FormGroup;
        const localizedProductParameter = {};
        Object.keys(groupControl.controls).forEach(field => {
          const control = groupControl.get(field);
          localizedProductParameter[field] = control.value;
        });
        product.localizedProductParameters[group] = localizedProductParameter;
      });

      return true;
    }
    return false;
  }

  categoryChanged(category) {
    if (this.productEditService.originalCategory) {
      if (this.productEditService.originalCategory !== category && !this.productEditService.categoryChangedWarning) {
        const confirmationDialogComponent = this.confirmationDialogService.openDialog([
          'wc.admin.products.product.categoryChange.text',
        ]);

        confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
          this.productEditService.categoryChangedWarning = true;
          this.productEditService.categoryProductParameters = null;
          this.loadProductParametersConfigs(category);
          confirmationDialogComponent.dialogReference.close();
        };

        confirmationDialogComponent.declineHandler = (dialogReference: NgbModalRef) => {
          this.productForm.controls.category.setValue(this.productEditService.originalCategory);
          this.productEditService.categoryChangedWarning = false;
        };
      } else {
        this.productEditService.categoryChangedWarning = this.productEditService.originalCategory !== category;
        this.productEditService.categoryProductParameters = null;
        this.loadProductParametersConfigs(category);
      }
    } else {
      this.productEditService.categoryProductParameters = null;
      this.loadProductParametersConfigs(category);
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.saveData()) {
      return true;
    }

    return false;
  }

  bundleChanged(bundle) {
    this.productEditService.isBundleChanged.emit(bundle.checked);
  }

  editProduct() {
    return this.productEditService.getEditProduct();
  }

  isActive() {
    return this.editProduct().state === ProductStateDto.ACTIVE;
  }

  isActivePilot() {
    return (
      this.isActive() &&
      this.editProduct().availableFor &&
      this.editProduct().availableFor.startDateTime &&
      new Date(this.editProduct().availableFor.startDateTime) > new Date()
    );
  }

  isDesign() {
    return this.editProduct().state === ProductStateDto.DESIGN;
  }

  isTest() {
    return this.editProduct().state === ProductStateDto.TEST;
  }

  isObsolete() {
    return this.editProduct().state === ProductStateDto.OBSOLETE;
  }

  isNewVersion(): boolean {
    return this.productEditService.newVersion;
  }

  isNewProduct(): boolean {
    return this.editProduct().id === 'newProduct' || !this.editProduct().id;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.list.push({ name: value });
    }

    // Clear the input value

    if (input) {
      input.value = '';
    }
  }

  remove(fruit): void {
    const index = this.list.indexOf(fruit);

    if (index >= 0) {
      this.list.splice(index, 1);
    }
  }

  getParameterLabel(productParameterConfig: ProductParameterDto) {
    return productParameterConfig.localizedNames[this.currentLocale]
      ? productParameterConfig.localizedNames[this.currentLocale]
      : productParameterConfig.name;
  }
}
