import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdminDmsService, AppBlockerService, CompareType } from '@btl/btl-fe-wc-common';
import { FilesAddModalComponent } from '@components/files-add-modal/files-add-modal.component';
import { DmsFileDto, NotificationTypeGatewayAttachmentDto } from '@btl/admin-bff';
import { finalize } from 'rxjs/operators';
import { DmsFilesSelectModalComponent } from '@components/dms-file-select-modal/dms-files-select-modal.component';
import { NotificationAttachmentService } from '@service/notification-attachment.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-notification-type-attachment-modal',
  templateUrl: './notification-type-attachment-modal.component.html',
  styleUrls: ['./notification-type-attachment-modal.component.scss'],
})
export class NotificationTypeAttachmentModalComponent implements OnInit {
  @Input()
  dialogRef;

  @Input()
  attachment: NotificationTypeGatewayAttachmentDto;

  @Input()
  dmsType = 'NOTIF_GW_DOCUMENT';

  @Output()
  readonly saveEvent: EventEmitter<NotificationTypeAttachment> = new EventEmitter();

  formGroup = this.formBuilder.group({
    name: [null as string, Validators.required],
    inline: [false],
    contentType: [null],
    extId: [null],
  });

  uploadedFile: DmsFileDto;
  editMode = false;
  isSave = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private appBlockerService: AppBlockerService,
    private adminDmsService: AdminDmsService,
    private notificationAttachmentService: NotificationAttachmentService
  ) {}

  ngOnInit(): void {
    if (this.attachment) {
      this.formGroup.patchValue(this.attachment);
      this.editMode = true;
      this.appBlockerService.block();
      this.notificationAttachmentService
        .getDmsFileById(this.attachment.extId)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.uploadedFile = result;
        });
    }
  }

  uploadAttachment(): void {
    const modalRef = this.dialog.open(FilesAddModalComponent, { width: '1000px' });
    const pictureAddModalComponent = modalRef.componentInstance;
    pictureAddModalComponent.dialogRef = modalRef;
    pictureAddModalComponent.uploadStep = false;
    pictureAddModalComponent.multiple = false;
    pictureAddModalComponent.uploadedHandler = (newFile: DmsFileDto) => {
      this.formGroup.get('name').setValue(newFile.name);
      this.formGroup.get('contentType').setValue(newFile.mediaType);
      this.uploadedFile = newFile;
      modalRef.close();
    };
  }

  chooseFromGallery(): void {
    const modalRef = this.dialog.open(DmsFilesSelectModalComponent);
    const dmsFilesSelectModalComponent = modalRef.componentInstance;
    dmsFilesSelectModalComponent.dialogRef = modalRef;
    dmsFilesSelectModalComponent.addToConstantFilter({
      column: 'type',
      compareType: CompareTypeDtoEnum.EQUAL,
      value: this.dmsType,
    });

    dmsFilesSelectModalComponent.selectHandler = (dmsFile: DmsFileDto) => {
      this.formGroup.get('name').setValue(dmsFile.name);
      this.formGroup.get('contentType').setValue(dmsFile.mediaType);
      this.formGroup.get('extId').setValue(dmsFile.id);
      this.formGroup.get('name').setValue(dmsFile.name);
      this.uploadedFile = dmsFile;
      modalRef.close();
    };
  }

  save(): void {
    if (!this.formGroup.valid || this.isSave) {
      return;
    }

    this.isSave = true;
    if (this.editMode) {
      this.appBlockerService.block();
      this.uploadedFile.type = this.dmsType;
      this.uploadedFile.name = this.formGroup.get('name').value;
      this.uploadedFile.contentHref = null;

      this.adminDmsService
        .updateFile(this.attachment.extId, this.uploadedFile)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe((file: DmsFileDto) => {
          this.formGroup.get('extId').setValue(file.id);
          this.formGroup.get('name').setValue(file.name);
          this.saveEvent.emit({ attachmentDto: this.formGroup.getRawValue(), dmsFileDto: file });
        });
    } else if (!this.formGroup.get('extId').value) {
      this.appBlockerService.block();
      this.uploadedFile.type = this.dmsType;
      this.uploadedFile.name = this.formGroup.get('name').value;
      this.uploadedFile.contentHref = null;

      this.adminDmsService
        .createFile(this.uploadedFile)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe((file: DmsFileDto) => {
          this.formGroup.get('extId').setValue(file.id);
          this.formGroup.get('name').setValue(file.name);
          this.saveEvent.emit({ attachmentDto: this.formGroup.getRawValue(), dmsFileDto: file });
        });
    } else if (this.formGroup.get('extId').value) {
      this.saveEvent.emit({ attachmentDto: this.formGroup.getRawValue() });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}

export interface NotificationTypeAttachment {
  attachmentDto: NotificationTypeGatewayAttachmentDto;
  dmsFileDto?: DmsFileDto;
}
