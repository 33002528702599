import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountRoleDto } from '@btl/admin-bff';

@Component({
  selector: 'app-account-role-list-item, [app-account-role-list-item]',
  templateUrl: './account-role-list-item.component.html',
  styleUrls: ['./account-role-list-item.component.scss'],
})
export class AccountRoleListItemComponent {
  @Input()
  parentId = '';

  @Input()
  role: AccountRoleDto;

  @Output()
  readonly deleteRole: EventEmitter<AccountRoleDto> = new EventEmitter<AccountRoleDto>();

  selected = false;

  delete() {
    this.deleteRole.emit(this.role);
  }
}
