<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'code'"
            [inputType]="'text'"
            id="001_socket_filter"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.socket'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'name'"
            [inputType]="'text'"
            id="002_socket_filter"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.socket'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            id="003_socket_filter"
            [formControlName]="'socketCategory'"
            [options]="socketCategories"
            (keydown.enter)="handleChange()"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.socket'">
          </app-input-select-form-field>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="clearFilter()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-custom-input
          [controlName]="'promotionId'"
          [inputType]="'text'"
          id="014_socket_filter"
          (keydown.enter)="handleChange()"
          [label]="'promotionCode'"
          [translationPrefix]="'wc.admin.socket'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-input-picker-form-field
          [parentGroup]="filterForm"
          [controlName]="'productCode'"
          id="006_socket_filter"
          (keydown.enter)="handleChange()"
          [valueField]="'productCode'"
          [pickerType]="PickerInputType.PRODUCT"
          [selectMode]="false"
          [translationPrefix]="'wc.admin.socket'">
        </app-input-picker-form-field>
        <app-input-select-form-field
          [formControlName]="'productCategory'"
          id="007_socket_filter"
          [options]="techCategories"
          [showEmptyOption]="true"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.socket'">
        </app-input-select-form-field>
        <app-input-select-form-field
          id="008_socket_filter"
          (selectionChangeEmit)="changeCompareType('min', $event)"
          [formControlName]="'contentQty_min_compareType'"
          [options]="compareTypes"
          (keydown.enter)="handleChange()"
          [showEmptyOption]="true"
          [valueOption]="'value'"
          [labelOption]="'name'"
          [label]="'contentQtyMinCompareType'"
          [translationPrefix]="'wc.admin.socket'">
        </app-input-select-form-field>
        <app-custom-input
          [controlName]="'contentQty_min'"
          [inputType]="'number'"
          [label]="'contentQtyMin'"
          id="009_socket_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.socket'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-input-select-form-field
          id="010_socket_filter"
          [formControlName]="'contentQty_max_compareType'"
          (selectionChangeEmit)="changeCompareType('max', $event)"
          [options]="compareTypes"
          [showEmptyOption]="true"
          (keydown.enter)="handleChange()"
          [valueOption]="'value'"
          [labelOption]="'name'"
          [label]="'contentQtyMaxCompareType'"
          [translationPrefix]="'wc.admin.socket'">
        </app-input-select-form-field>
        <app-custom-input
          [controlName]="'contentQty_max'"
          id="011_socket_filter"
          [inputType]="'number'"
          [label]="'contentQtyMax'"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.socket'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-input-select-form-field
          [translationPrefix]="'wc.admin.socket'"
          id="012_socket_filter"
          [yesNoOptions]="true"
          labelOption="labelKey"
          valueOption="value"
          [showEmptyOption]=true
          (keydown.enter)="handleChange()"
          [formControlName]="'shared'">
        </app-input-select-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</form>
