import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProductEditService } from '@service/product-edit.service';

@Component({
  selector: 'app-inner-left-menu',
  templateUrl: './inner-left-menu.component.html',
  styleUrls: ['./inner-left-menu.component.scss'],
})
export class InnerLeftMenuComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  public static innerMenuItems = [
    'attributes',
    'prices',
    'eligibility',
    'texts',
    'pictures',
    'relationships',
    'cartEvents',
    'bundles',
    'groups',
    'stickers',
    'ratings',
    'acl',
  ];

  getInnerMenuItems() {
    return InnerLeftMenuComponent.innerMenuItems;
  }

  fixMenuName = new Map([
    ['pictures', 'pictures'],
    ['relationships', 'relationships'],
    ['cartEvents', 'cartEvents'],
    ['groups', 'products.group'],
    ['stickers', 'stickers'],
  ]);

  productId;
  selected;
  urlName;

  @Output()
  readonly closeMainMenu = new EventEmitter<boolean>();

  constructor(public router: Router, private productEditService: ProductEditService) {
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(product => {
      if (product && !product?.bundle) {
        InnerLeftMenuComponent.innerMenuItems = InnerLeftMenuComponent.innerMenuItems.filter(
          menuItem => menuItem !== 'bundles'
        );
      } else {
        if (!InnerLeftMenuComponent.innerMenuItems.find(innerItem => innerItem === 'bundles')) {
          InnerLeftMenuComponent.innerMenuItems.push('bundles');
        }
      }
    });

    this.productEditService.isBundleChanged.subscribe(isBundle => {
      if (isBundle) {
        InnerLeftMenuComponent.innerMenuItems.push('bundles');
      } else {
        InnerLeftMenuComponent.innerMenuItems = InnerLeftMenuComponent.innerMenuItems.filter(
          menuItem => menuItem !== 'bundles'
        );
      }
    });

    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe(navigation => {
      if (navigation instanceof NavigationEnd) {
        this.checkRouterUrl(navigation.url);

        if (navigation.url.startsWith('/pc/products/')) {
          const splitUrl = navigation.url.split('/');
          if (splitUrl.length >= 2) {
            this.productId = splitUrl[3];
            this.urlName = splitUrl[4];
            this.closeMainMenu.emit();
          }
        }
        this.checkRouterUrl(navigation.url);
      }
    });
  }

  ngOnInit(): void {
    this.checkRouterUrl(this.router.url);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  checkRouterUrl(url: string) {
    const splitUrl = url.split('/');
    if (splitUrl[4] === this.urlName) {
      this.selected = true;
    } else {
      this.selected = false;
    }
  }

  cancel() {
    this.router.navigate(['pc', 'products']);
  }

  getName(name) {
    if (this.fixMenuName.has(name)) {
      return `wc.admin.${this.fixMenuName.get(name)}`;
    } else {
      return `wc.admin.products.${name}`;
    }
  }
}
