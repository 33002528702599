import { Component, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import {
  AbstractPageComponent,
  AdminProductGroupService,
  AppBlockerService,
  EnableDynamicLoading,
  PaginationComponent,
  Search
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { GroupDto, GroupProductActionDto, ProductDto } from '@btl/admin-bff';
import { ProductEditService } from '@service/product-edit.service';
import { ProductEditGroupsItemComponent } from './item/product-edit-groups-item.component';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import { GroupsSelectModalComponent } from '@components/groups-select-modal/groups-select-modal.component';
import { SortDto } from '@btl/order-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import ActionDtoEnum = GroupProductActionDto.ActionDtoEnum;

@Component({
  selector: 'app-product-group-edit',
  templateUrl: './product-edit-groups.component.html',
  styleUrls: ['./product-edit-groups.component.scss'],
})
@EnableDynamicLoading({ customName: ProductEditGroupsComponent.PAGE_ID })
export class ProductEditGroupsComponent extends AbstractPageComponent implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductEditGroupsComponent';

  pageId(): string {
    return ProductEditGroupsComponent.PAGE_ID;
  }

  public static readonly LISTING_FILTER = 'productEditListingFilter';

  groups: GroupDto[] = [];

  groupsProductsActions: Array<GroupProductActionDto> = [];

  @Input()
  cacheFilter = true;

  @ViewChild(PaginationComponent, { static: true })
  paginationComponent: PaginationComponent;

  @ViewChildren(ProductEditGroupsItemComponent)
  productEditGroupsItems: Array<ProductEditGroupsItemComponent>;

  product: ProductDto;

  search: Search = {
    filtering: [],
    sorting: [
      {
        column: 'priority',
        sortOrder: SortDto.SortOrderDtoEnum.Asc,
      },
    ],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };

  constructor(
    private adminProductGroupService: AdminProductGroupService,
    private dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    public productEditService: ProductEditService,
    private appBlockerService: AppBlockerService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super(router, route);
    const product = this.productEditService.getEditProduct();
    if (product) {
      this.product = product;
      this.loadGroups(this.search);
    }
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit() {
    if (this.cacheFilter) {
      const sessionSearch = JSON.parse(sessionStorage.getItem(ProductEditGroupsComponent.LISTING_FILTER));
      if (sessionSearch) {
        this.search = sessionSearch;
      }
    }

    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if (!this.product || this.product.id !== result.id) {
        this.product = result;
        this.loadGroups(this.search);
      }
    });

    this.groupsProductsActions = this.productEditService.groupsProductsActions;
  }

  public loadGroups(search: Search) {
    if (!this.productEditService.groups) {
      this.groups.length = 0;
      this.search.filtering.push({
        column: 'groupProducts.productMasterId',
        compareType: 'EQUAL',
        value: this.product.productCode,
      });

      this.appBlockerService.block();

      if (this.cacheFilter) {
        sessionStorage.setItem(ProductEditGroupsComponent.LISTING_FILTER, JSON.stringify(this.search));
      }

      this.adminProductGroupService
        .getGroupsByFilter(search)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.groups.length = 0;
            result.data.forEach(group => {
              if (group) {
                this.groups.push(group);
              }
            });
          }
        });
    } else {
      this.groups = this.productEditService.groups;
    }
  }

  seqChanged(groupsProductsUpdateAction) {
    let updated = false;

    this.groupsProductsActions.forEach(groupsProductsAction => {
      if (groupsProductsAction.groupId === groupsProductsUpdateAction.groupId) {
        groupsProductsAction.seq = groupsProductsUpdateAction.seq;
        updated = true;
      }
    });

    if (!updated) {
      groupsProductsUpdateAction.productMasterId = this.product.productCode;
      this.groupsProductsActions.push(groupsProductsUpdateAction);
    }

    this.groups.forEach(group => {
      if (group.id === groupsProductsUpdateAction.groupId) {
        group.groupProducts.forEach(groupProduct => {
          if (groupProduct.productMasterId === this.product.productCode) {
            groupProduct.seq = groupsProductsUpdateAction.seq;
          }
        });
      }
    });
  }

  goToGroup(groupDto: GroupDto) {
    this.router.navigate(['pc/groups', groupDto.id]);
  }

  addGroup() {
    const modalRef = this.dialog.open(GroupsSelectModalComponent);
    const groupsSelectModalComponent = modalRef.componentInstance;
    groupsSelectModalComponent.dialogRef = modalRef;
    groupsSelectModalComponent.disableListingActions = true;
    groupsSelectModalComponent.disableActions = true;
    groupsSelectModalComponent.activeMultiSelectMode();
    groupsSelectModalComponent.selectMode = true;

    groupsSelectModalComponent.selectHandler = groups => {
      groups.forEach(group => {
        if (this.getGroupById(group.id) === null) {
          const groupsProductsAction = {
            action: ActionDtoEnum.ADD,
            productMasterId: this.product.productCode,
            groupId: group.id,
            groupRecordVersion: group.recordVersion,
            seq: 0,
          };
          this.groupsProductsActions.push(groupsProductsAction);
          if (!group.groupProducts) {
            group.groupProducts = [];
          }
          group.groupProducts.push({
            productMasterId: groupsProductsAction.productMasterId,
            seq: groupsProductsAction.seq,
          });
          this.groups.push(group);
        }
      });
    };
  }

  getGroupById(groupId: string): GroupDto {
    let group = null;
    this.groups.forEach(loopGroup => {
      if (loopGroup.id === groupId) {
        group = loopGroup;
      }
    });

    return group;
  }

  deleteGroup() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.groupsproductsactions.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.getSelectedGroups().forEach(selectedGroup => {
        const actionsLenght = this.groupsProductsActions.length;
        let addGroupsProductsAction = this.groupsProductsActions.find(
          groupsProductsAction => groupsProductsAction.groupId !== selectedGroup.id && groupsProductsAction.action === 'ADD'
        );

        this.groupsProductsActions = this.groupsProductsActions.filter(
          groupsProductsAction => groupsProductsAction.groupId !== selectedGroup.id
        );

        this.groups = this.groups.filter(group => group !== selectedGroup);

        if (!addGroupsProductsAction) {
          this.groupsProductsActions.push({
            action: ActionDtoEnum.REMOVE,
            productMasterId: this.product.productCode,
            groupId: selectedGroup.id,
            groupRecordVersion: selectedGroup.recordVersion,
          });
        }
      });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  getSelectedGroups(): Array<GroupDto> {
    const selectedGroups = [];
    this.productEditGroupsItems.forEach(productEditGroupsItem => {
      if (productEditGroupsItem.selected) {
        selectedGroups.push(productEditGroupsItem.group);
      }
    });
    return selectedGroups;
  }

  saveData(): boolean {
    if (!this.productEditService.canEdit()) {
      return true;
    }

    this.productEditService.groupsProductsActions = this.groupsProductsActions;
    this.productEditService.groups = this.groups;
    return true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.productEditService.resetMode) {
      if (this.saveData()) {
        return true;
      } else {
        return false;
      }
    }

    this.productEditService.resetMode = false;

    return true;
  }
}
