<ng-container *ngIf="loaded">
  <th>
    <div class="aclRole">
      <div>
        {{ aclRole.id }}
      </div>
      <div>
        <button (click)="setRuleTypeToAllRules('GRANT')" mat-icon-button>
          <mat-icon
            class="default-icon acl"
            svgIcon="check"
            matTooltip="{{ 'wc.common.acl.grantAll' | translate }}"></mat-icon>
        </button>
        <button (click)="setRuleTypeToAllRules('REVOKE')" mat-icon-button>
          <mat-icon
            class="default-icon acl"
            svgIcon="stop"
            matTooltip="{{ 'wc.common.acl.revokeAll' | translate }}"></mat-icon>
        </button>
      </div>
    </div>
  </th>
  <ng-container>
    <ng-container *ngFor="let rule of filterEditRules(); let i = index">
      <td class="rule-cell" [matMenuTriggerFor]="menu">
        <div class="rule-main {{ getParentRuleType(rule) }}">
          <div id="rule-text" class="rule {{ rule.ruleType }}">
            {{ rule.ruleType ? rule.ruleType : 'unset' }}
          </div>
        </div>
      </td>
      <ng-container>
        <mat-menu #menu="matMenu" class="acl-mat-menu">
          <button
            mat-menu-item
            class="table-actions-button"
            [routerLink]=""
            (click)="changeRuleType(rule, 'GRANT')"
            [disabled]="!editable">
            <mat-icon svgIcon="check"></mat-icon>
            {{ 'wc.common.acl.setGrant' | translate }}
          </button>
          <button
            mat-menu-item
            class="table-actions-button"
            [routerLink]=""
            (click)="changeRuleType(rule, 'REVOKE')"
            [disabled]="!editable">
            <mat-icon svgIcon="stop"></mat-icon>
            {{ 'wc.common.acl.setRevoke' | translate }}
          </button>
          <button
            mat-menu-item
            class="table-actions-button"
            [routerLink]=""
            (click)="changeRuleType(rule, null)"
            [disabled]="!editable">
            {{ 'wc.common.acl.unset' | translate }}
          </button>
        </mat-menu>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
