<div *ngIf="editable" class="main__header">
  <div class="header-box">
    <div class="icon-box PC">
      <mat-icon svgIcon="ruleSets"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.ruleSets' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      id="001_rule_sets_listing"
      (click)="newRuleSet()"
      [routerLink]=""
      color="primary"
      mat-raised-button
      *ngIf="aclService.pageEditAccess"
      class="btn -primary -xs"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>

<div class="card__wrapper">
  <div class="card__inner">
    <app-rule-sets-filter (filterChanged)="handleFilterChange()" [filtering]="search.filtering"></app-rule-sets-filter>
  </div>

  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.ruleSets.ruleSetsListing' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedRuleSetsDto?.totalItems
                          ? pagedRuleSetsDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedRuleSetsDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="002_rule_sets_listing"
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedData()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>

      <div class="card__body">
        <app-table-listing-form
          [columnName]="['name', 'ruleSetExpression', 'description']"
          [columnToTruncate]="['name', 'ruleSetExpression', 'description']"
          [dontShowOnMobile]="['description']"
          [fillIdBy]="'id'"
          (doubleClickEvent)="editRuleSet($event)"
          (editButtonEvent)="editRuleSet($event)"
          (editInNewTabButtonEvent)="editRuleSet($event, true)"
          (deleteButtonEvent)="deleteData($event)"
          [pagedDto]="pagedRuleSetsDto"
          (pageSizeEvent)="pageSizeChanged($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [editable]="aclService.pageEditAccess"
          [translationPrefix]="'wc.admin.ruleSets'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
