<div [formGroup]="textForm" class="localized_text">
  <div class="localized_text_header">
    <label>
      {{ 'wc.admin.products.attributes.localizedParameters.' + textForm.controls['locale'].value | translate }}
    </label>
  </div>
  <div class="localized_text_input">
    <input [readonly]="!editable" class="form__input w-100" formControlName="message" type="text" />
  </div>
</div>
