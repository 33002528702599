import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { AdminResourceService, AppBlockerService, Search } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SortDto } from '@btl/order-bff';
import { PagedResourcesDto } from '@btl/admin-bff';

@Component({
  selector: 'app-resources-resource-relationship-modal',
  templateUrl: './resources-resource-relationship-modal.component.html',
  styleUrls: ['./resources-resource-relationship-modal.component.scss'],
})
export class ResourcesResourceRelationshipModalComponent implements OnInit {
  protected onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  dialogRef;

  @Input()
  formGroup: FormGroup;

  @Input()
  types = [];

  @Output()
  readonly saveEvent: EventEmitter<any> = new EventEmitter();

  get directionControl(): AbstractControl {
    return this.formGroup.get('direction');
  }

  directions = [
    { labelKey: 'wc.admin.resources.resource.direction.from', value: 'From' },
    { labelKey: 'wc.admin.resources.resource.direction.to', value: 'To' },
  ];

  relationDirection: 'From' | 'To';
  resourceExist = true;

  constructor(
    private appBlockerService: AppBlockerService,
    private propertyAccessorLocalService: PropertyAccessorLocalService,
    private adminResourceService: AdminResourceService
  ) {}

  ngOnInit(): void {
    this.relationDirection = this.directionControl.value === 'To' ? 'To' : 'From';

    if (this.relationDirection === 'To') {
      this.formGroup.enable();
      this.directionControl.disable();

      this.formGroup.get('type').valueChanges.subscribe(value => {
        this.getIdPatternByType(value);
      });
    } else {
      this.formGroup.disable();
    }
  }

  save(): void {
    const data = this.formGroup.getRawValue();
    if (data.direction === 'From') {
      this.saveEvent.emit(data);
    } else if (data.id && this.formGroup.valid) {
      this.checkResource(data);
    } else {
      this.formGroup.get('id').markAsTouched();
      this.formGroup.get('relationshipType').markAsTouched();
      this.formGroup.get('type').markAsTouched();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  private getIdPatternByType(type: string): void {
    if (type) {
      this.appBlockerService.block();
      this.propertyAccessorLocalService
        .getResouceIdNormalizedFormat(type)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(value => {
          if (value) {
            this.formGroup.get('id').setValidators([Validators.required, Validators.pattern(value)]);
            this.formGroup.get('id').updateValueAndValidity({ emitEvent: false, onlySelf: true });
          }
        });
    }
  }

  private checkResource(data: any): void {
    const id = this.formGroup.get('id').value;
    const type = this.formGroup.get('type').value;
    if (id && type) {
      this.appBlockerService.block();
      const search: Search = {
        filtering: [
          { column: 'id', compareType: 'EQUAL', value: id },
          { column: 'type', compareType: 'EQUAL', value: type },
        ],
        sorting: [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }],
        paging: { page: 1, pageSize: 1 },
      };
      this.adminResourceService
        .filterResources(search)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: PagedResourcesDto) => {
          if (!result || !result.data || !result.data.length) {
            this.resourceExist = false;

            this.formGroup.get('id').valueChanges.subscribe(value => {
              this.resourceExist = true;
            });
          } else {
            this.saveEvent.emit(data);
          }
        });
    }
  }
}
