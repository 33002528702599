import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AclService,
  AdminCommandService,
  AdminDmsService,
  AdminReleaseService,
  AppBlockerService,
  CompareType,
  DmsService,
  EnableDynamicLoading,
  FormUtils,
  ModulePreferencesService,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { DmsFileBulkOperationsRequestDto, PagedProcessInfosDto, ProcessDefinitionDto } from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import moment_ from 'moment';
import { SortDto } from '@btl/order-bff';
import { MatDialog } from '@angular/material/dialog';
import { ExportComponent } from '@components/import-export/export/export.component';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';

import { Observable } from 'rxjs';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
@EnableDynamicLoading({ customName: ImportComponent.PAGE_ID })
export class ImportComponent extends AbstractListingPageComponent {
  PickerInputType = PickerInputType;
  public static readonly PAGE_ID = 'ImportComponent';

  pageId(): string {
    return ImportComponent.PAGE_ID;
  }

  moment = moment_;

  routerOutlet: any;

  processDefinitionDto: ProcessDefinitionDto;

  createdProcessIds: string[] = [];

  importTypes = ['FULL', 'PARTIAL'];

  form: FormGroup = this.formBuilder.group({
    validateOnly: [false],
    pcType: ['FULL'],
    aclType: ['FULL'],
    pcFile: [],
    cbFile: [],
    aclFile: [],
    dataVersion: [1, Validators.required],
  });

  pagedProcessInfosDto: PagedProcessInfosDto;

  constantFilter = [{ column: 'processKey', compareType: CompareTypeDtoEnum.EQUAL, value: 'IMPORT' }];
  initSorting = [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }];

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private adminCommandService: AdminCommandService,
    private adminReleaseService: AdminReleaseService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    private httpClient: HttpClient,
    private dmsService: DmsService,
    private dialog: MatDialog,
    private adminDmsService: AdminDmsService,
    protected appBlockerService: AppBlockerService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  anyFileChosen() {
    return (
      this.form.controls['pcFile'].value || this.form.controls['aclFile'].value || this.form.controls['cbFile'].value
    );
  }

  import() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid && this.anyFileChosen()) {
      this.appBlockerService.block();
      this.processDefinitionDto = {
        processKey: 'IMPORT',
        parameters: {},
      };
      const value = this.form.getRawValue();
      const services = [];
      const bulkOperations: DmsFileBulkOperationsRequestDto = {
        bulkCreateList: [],
        bulkDeleteList: [],
        bulkUpdateList: [],
        bulkPatchList: [],
      };
      if (value.pcFile) {
        this.processDefinitionDto.parameters.pcImportType = value.pcType;
        this.processDefinitionDto.parameters.pcDataVersion = value.dataVersion;
        this.processDefinitionDto.parameters.pcDmsFileId = value.pcFile.id;
        services.push('product-catalog');
      }
      if (value.aclFile) {
        this.processDefinitionDto.parameters.aclImportType = value.aclType;
        this.processDefinitionDto.parameters.aclDataVersion = value.dataVersion;
        this.processDefinitionDto.parameters.aclDmsFileId = value.aclFile.id;
        services.push('acl');
      }
      if (value.cbFile) {
        this.processDefinitionDto.parameters.codebookDataVersion = value.dataVersion;
        this.processDefinitionDto.parameters.codebookDmsFileId = value.cbFile.id;
        services.push('codebook');
      }

      this.processDefinitionDto.parameters.validateOnly = `${value.validateOnly}`;

      this.processDefinitionDto.parameters.serviceId = services;

      this.form.controls.pcFile.setValue(null);
      this.form.controls.aclFile.setValue(null);
      this.form.controls.cbFile.setValue(null);

      this.appBlockerService.unblock();

      this.adminCommandService
        .createProcess(this.processDefinitionDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(createdProcess => {
          this.createdProcessIds.push(createdProcess.instanceId);
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          this.loadData();
        });
    }
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.pagedProcessInfosDto = null;
      this.adminCommandService
        .filterProcesses(this.search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            result.data.forEach(process => {
              ExportComponent.addServicesColumn(process);
              process['exportState'] = process.state;
              process['validateOnly'] = process.parameters['validateOnly'] === 'true';
            });

            this.pagedProcessInfosDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }

        });
    });
  }

  download(row) {
    const search = ServiceUtils.getUnlimitedSearch();
    const dmsIds = Object.keys(row.parameters).map(key => {
      if (key.indexOf('DmsFileId') > -1) {
        return row.parameters[key];
      }
    });
    if (dmsIds && dmsIds.length > 0) {
      search.filtering.push({ column: 'id', compareType: CompareTypeDtoEnum.IN, value: dmsIds });
      this.dmsService
        .filterFiles(search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          result.data.forEach(file => {
            const httpOptions = {
              responseType: 'blob' as 'json',
            };
            this.httpClient.get(file.contentHref, httpOptions).subscribe((data: any) => {
              const blob = new Blob([data]);

              const downloadURL = window.URL.createObjectURL(data);
              const link = document.createElement('a');
              link.href = downloadURL;
              link.download = file.name;
              link.click();
            });
          });
        });
    }
  }

  public getColumnsToolTipCallBacks() {
    return ExportComponent.getColumnsToolTipCallBacks();
  }
}
