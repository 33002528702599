import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TextDto } from '@btl/admin-bff';

@Component({
  selector: 'app-locale-text, [app-locale-text]',
  templateUrl: './locale-text.component.html',
  styleUrls: ['./locale-text.component.scss'],
})
export class LocaleTextComponent implements OnInit {
  @Input()
  textForm: FormGroup;

  @Input()
  editable = true;

  text: TextDto;

  public static getFormControlConfig() {
    return {
      textType: [null],
      locale: [null],
      message: [null],
    };
  }

  ngOnInit() {
    this.text = this.textForm.getRawValue();
  }
}
