import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { AdminRuleSetService, FormUtils, Search } from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-eligibility, [app-eligibility]',
  templateUrl: './eligibility.component.html',
  styleUrls: ['./eligibility.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EligibilityComponent),
      multi: true,
    },
  ],
})
export class EligibilityComponent implements OnInit, ControlValueAccessor, OnDestroy {
  form: FormGroup = this.formBuilder.group({
    ruleSets: this.formBuilder.array([]),
  });
  @Input()
  value: Array<string>;
  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();
  ruleSets;
  priority = 0;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private adminRuleSetService: AdminRuleSetService) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  ngOnInit() {
    this.valueChanged();

    const search: Search = {
      filtering: [],
      paging: { page: 1, pageSize: -1 },
      sorting: [{ column: 'name', sortOrder: 'asc' }],
    };
    this.adminRuleSetService
      .filterRuleSets(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result) {
          this.ruleSets = result.data;
        }
      });
  }

  valueChanged() {
    if (!this.value) {
      this.value = [];
    }
    this.priority = 0;
    this.getRuleSetsFormArray().clear();
    this.value.forEach(ruleSetId => {
      this.addRuleSetToFormArray(ruleSetId);
    });
  }

  addRuleSetToFormArray(ruleSetId) {
    const rulSetFromGroup = this.formBuilder.group({
      id: [ruleSetId, Validators.required],
      priority: [this.priority++, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
    });

    this.getRuleSetsFormArray().push(rulSetFromGroup);
  }

  getRuleSetsFormArray(): FormArray {
    return this.form.get('ruleSets') as FormArray;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      this.value.length = 0;
      this.form
        .getRawValue()
        .ruleSets.map(ruleSet => ruleSet.id)
        .forEach(ruleSetId => this.value.push(ruleSetId));
      return this.value;
    }
    return null;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  sortTable() {
    this.getRuleSetsFormArray().controls.sort((a, b) => (a.value > b.value ? 1 : -1));
    this.getValue();
  }

  remove(ruleSetForm: AbstractControl) {
    this.getRuleSetsFormArray().controls = this.getRuleSetsFormArray().controls.filter(
      control => control !== ruleSetForm
    );
    this.getValue();
  }

  addNewRuleSet() {
    this.addRuleSetToFormArray(null);
    this.getValue();
  }
}
