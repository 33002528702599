import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductDto } from '@btl/admin-bff';
import { ProductEditService } from '@service/product-edit.service';
import { takeUntil } from 'rxjs/operators';
import { CartEventsListingComponent } from '@components/product-catalogue/cart-events/cart-events-listing/cart-events-listing.component';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-product-cart-events',
  templateUrl: './product-cart-events.component.html',
  styleUrls: ['./product-cart-events.component.scss'],
})
@EnableDynamicLoading({ customName: ProductCartEventsComponent.PAGE_ID })
export class ProductCartEventsComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'ProductCartEventsComponent';

  pageId(): string {
    return ProductCartEventsComponent.PAGE_ID;
  }

  product: ProductDto = null;

  @ViewChild(CartEventsListingComponent, { static: true })
  CartEventsListingComponent: CartEventsListingComponent;

  constructor(
    private productEditService: ProductEditService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(router, route);
    const product = this.productEditService.getEditProduct();
    if (product) {
      this.product = product;
    }
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit() {
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if (!this.product || this.product.id !== result.id) {
        this.product = result;
      }
    });
  }

  getProductSourceFilter() {
    return [
      {
        column: 'fromId',
        compareType: 'EQUAL',
        value: this.product.productCode,
      },
      {
        column: 'fromTypeId',
        compareType: 'EQUAL',
        value: 'ProdCode',
      },
    ];
  }

  getProductTargetFilter() {
    return [
      {
        column: 'toProductMasterId',
        compareType: 'EQUAL',
        value: this.product.productCode,
      },
    ];
  }
}
