import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductDto } from '@btl/admin-bff';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductEditService } from '@service/product-edit.service';
import { takeUntil } from 'rxjs/operators';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import { Observable } from 'rxjs';
import { EligibilityExpressionComponent } from '@components/eligibility-expression/eligibility-expression.component';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-product-eligibility',
  templateUrl: './product-eligibility.component.html',
  styleUrls: ['./product-eligibility.component.scss'],
})
@EnableDynamicLoading({ customName: ProductEligibilityComponent.PAGE_ID })
export class ProductEligibilityComponent extends AbstractPageComponent implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductEligibilityComponent';

  pageId(): string {
    return ProductEligibilityComponent.PAGE_ID;
  }

  @ViewChild(EligibilityExpressionComponent, { static: true })
  eligibilityComponent: EligibilityExpressionComponent;
  product: ProductDto;

  form: FormGroup = this.formBuilder.group({
    ruleSetExpression: [null],
  });

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    public productEditService: ProductEditService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit() {
    this.product = this.productEditService.getEditProduct();
    this.form.controls['ruleSetExpression'].patchValue(this.product.ruleSetExpression);
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(product => {
      this.product = product;
      this.form.controls['ruleSetExpression'].patchValue(this.product.ruleSetExpression);
    });
  }

  saveData(): boolean {
    if (!this.productEditService.canEdit()) {
      return true;
    }

    const ruleSetExpression = this.eligibilityComponent.getValue();

    this.productEditService.getEditProduct().ruleSetExpression = ruleSetExpression;

    return true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.productEditService.resetMode) {
      if (this.saveData()) {
        return true;
      } else {
        return false;
      }
    }

    this.productEditService.resetMode = false;

    return true;
  }
}
