import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AbstractFilterComponent, CompareType } from '@btl/btl-fe-wc-common';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { takeUntil } from 'rxjs/operators';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-promotion-filter',
  templateUrl: './promotion-filter.component.html',
  animations: [Animations.advancedFilterArrow],
})
export class PromotionFilterComponent extends AbstractFilterComponent {
  PickerInputType = PickerInputType;

  @Input()
  disableClearFilterButton: boolean = false;

  techCategories: string[] = [];

  mapFieldWithCompareType = new Map([
    ['code', CompareTypeDtoEnum.LIKE],
    ['name', CompareTypeDtoEnum.LIKE],
  ]);

  advancedFilterFields = ['productCategory', 'socketId'];

  constructor(private formBuilder: FormBuilder, private enumerationsService: EnumerationsService) {
    super();
    this.enumerationsService
      .getProductTechnicalCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.techCategories = result;
      });

    this.filterForm = this.formBuilder.group({
      code: [],
      name: [],
      validAt: [],
      productCode: [],
      productCategory: [],
      socketId: [],
    });
  }

  ngOnInit() {
    if (this.presentInConstantFilter('socketId')) {
      this.advancedFilterFields = this.advancedFilterFields.filter(filter => filter != 'socketId');
    }
    this.initAdvancedFilterChangeRegistry(this.advancedFilterFields);
    this.initForm();
  }
}
