<div class="main__header">
  <div class="header-box">
    <div class="icon-box PC">
      <mat-icon svgIcon="categories"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.products.product.category' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      (click)="newCategory()"
      [routerLink]=""
      color="primary"
      mat-raised-button
      *ngIf="aclService.pageEditAccess"
      class="btn -primary -xs"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__body">
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="List">
            <app-table-listing-form
              [enablePaginator]="false"
              [columnName]="['id', 'parentId']"
              [fillIdBy]="'id'"
              (editButtonEvent)="edit($event)"
              (editInNewTabButtonEvent)="edit($event, true)"
              (deleteButtonEvent)="delete($event)"
              [pagedDto]="technicalCategory"
              (doubleClickEvent)="edit($event)"
              [module]="'category'"
              [selectMode]="false"
              [editable]="aclService.pageEditAccess"
              [translationPrefix]="'wc.admin.products.product'">
            </app-table-listing-form>
          </mat-tab>
          <mat-tab label="Tree">
            <div class="tree-actions">
              <button mat-raised-button color="primary" (click)="treeControl.expandAll()">Expand All</button>
              <button mat-raised-button color="primary" (click)="treeControl.collapseAll()">Collapse All</button>
            </div>
            <mat-tree #tree [dataSource]="dataSource" [treeControl]="treeControl" class="category-tree">
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                <div (dblclick)="edit(node)" class="category-name">
                  {{ node.id }}
                </div>
                <div class="action">
                  <button mat-icon-button (click)="$event.stopPropagation(); edit(node)" [routerLink]="">
                    <mat-icon *ngIf="aclService.pageEditAccess" class="default-icon">create</mat-icon>
                    <mat-icon *ngIf="!aclService.pageEditAccess" class="default-icon" svgIcon="show"></mat-icon>
                  </button>
                  <button mat-icon-button (click)="$event.stopPropagation(); edit(node, true)" [routerLink]="">
                    <mat-icon class="default-icon">open_in_new</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    *ngIf="aclService.pageEditAccess"
                    (click)="$event.stopPropagation(); delete(node)"
                    [routerLink]="">
                    <mat-icon class="default-icon">delete</mat-icon>
                  </button>
                </div>
              </mat-tree-node>
              <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div class="mat-tree-node">
                  <div fxLayout="row">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                      <mat-icon class="mat-icon-rtl-mirror">
                        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                      </mat-icon>
                    </button>
                    <div (dblclick)="edit(node)" fxFlexAlign="center" class="category-name">
                      {{ node.id }}
                    </div>
                  </div>
                  <div class="action">
                    <button mat-icon-button (click)="$event.stopPropagation(); edit(node)" [routerLink]="">
                      <mat-icon *ngIf="aclService.pageEditAccess" class="default-icon">create</mat-icon>
                      <mat-icon *ngIf="!aclService.pageEditAccess" class="default-icon" svgIcon="show"></mat-icon>
                    </button>
                    <button mat-icon-button (click)="$event.stopPropagation(); edit(node, true)" [routerLink]="">
                      <mat-icon class="default-icon">open_in_new</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      *ngIf="aclService.pageEditAccess"
                      (click)="$event.stopPropagation(); delete(node)"
                      [routerLink]="">
                      <mat-icon sclass="default-icon">delete</mat-icon>
                    </button>
                  </div>
                </div>
                <div [class.category-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                  <ng-container matTreeNodeOutlet></ng-container>
                </div>
              </mat-nested-tree-node>
            </mat-tree>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
