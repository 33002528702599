export class IconMapUtils {
  //TODO: Change css of admin module
  private static iconMap = new Map([
    ['ADMIN_DASHBOARD', 'dashboard'],
    ['PRODUCTS', 'product'],
    ['CATEGORIES', 'categories'],
    ['RELATIONSHIPS', 'relationships'],
    ['BANNERS', 'banners'],
    ['CODEBOOKS', 'codebooks'],
    ['LOCALIZED_TEXTS', 'localizedTexts'],
    ['LOCALIZED_TEXT', 'localizedTexts'],
    ['STICKERS', 'stickers'],
    ['RULE_SETS', 'ruleSets'],
    ['CART_EVENTS', 'cartEvent'],
    ['DYNAMIC_CONTEXT_RULES', 'dynamicContextRules'],
    ['DCR', 'dynamicContextRules'],
    ['GROUPS', 'groups'],
    ['PC_GROUP_TYPES', 'groupTypes'],
    ['IMPORT_EXPORT', 'importExport'],
    ['TICKETS', 'tickets'],
    ['TICKET_TYPES', 'ticketTypes'],
    ['ACCOUNTS', 'accounts'],
    ['ROLES', 'roles'],
    ['ACCOUNT_ENTITIES', 'accountEntity'],
    ['PROMOTIONS', 'promotions'],
    ['SOCKETS', 'sockets'],
    ['CODEBOOK_TYPES', 'codebookTypes'],
    ['CODEBOOK', 'codebooks'],
    ['PC', 'product'],
    ['BANNER', 'banners'],
    ['TICKETING', 'tickets'],
    ['ACCESS', 'roles'],
    ['CMS', 'cms'],
    ['CMS_CONTENT', 'cms'],
    ['TICKET_STEPS', 'ticketSteps'],
    ['ADMIN_NOTIFICATION', 'notifications'],
    ['ADMIN_NOTIFICATIONS', 'notifications'],
    ['NOTIF_GATEWAYS', 'notificationsGateway'],
    ['NOTIF_TYPES', 'notificationsTypes'],
    ['PC_RELEASES', 'pcReleases'],
    ['CAMPAIGNS', 'campaigns'],
    ['CAMPAIGN', 'campaigns'],
    ['LT', 'localizedTexts'],
    ['CB', 'codebooks'],
    ['CBS', 'codebooks'],
    ['CB_TYPES', 'codebookTypes'],
    ['PRIVILEGES', 'privileges'],
    ['IO_IMPORT', 'import'],
    ['IO_EXPORT', 'export'],
    ['DOCGEN', 'documentTemplates'],
    ['DOCUMENT_TEMPLATES', 'documentTemplates'],
    ['ENUMS', 'dataTypeEnums'],
    ['CMS_ENUMS', 'dataTypeEnums'],
    ['PC_ENUMS', 'dataTypeEnums'],
    ['TICKET_ENUMS', 'ticketEnums'],
    ['CONFIGURATION', 'configuration'],
    ['META_PARAMS_PROFILES', 'configuration'],
    ['RESOURCES', 'resources'],
    ['RESOURCE', 'resources'],
    ['STORES', 'store'],
    ['STOCKS', 'stock'],
    ['CRM', 'crm'],
    ['AGREEMENT_TYPES', 'agreementTypes'],
    ['META_PROFILES', 'metaProfiles'],
    ['META_DESCRIPTION', 'metaProfiles'],
  ]);

  public static getIcon(iconName) {
    return this.iconMap.get(iconName);
  }
}
