import { Component, OnInit, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminAccountService,
  AdminAclService,
  AdminDynamicEnumService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationBehaviorOptions, NavigationEnd, Router } from '@angular/router';
import { AccountDto, AccountRoleDto, EnumEntryDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { AccountParameterDto } from '@btl/admin-bff/model/accountParameterDto';
import { environment } from '@environments/environment';
import { AccountPreferenceDto } from '@btl/admin-bff/model/accountPreferenceDto';
import { AccountRoleListItemComponent } from '@components/account/edit/role-item/account-role-list-item.component';
import {
  AclRolesSelectModalComponent,
} from '@components/acl/components/acl-roles-select-modal/acl-roles-select-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import { PropertyAccessorLocalService } from '@service/property-accessor-local.service';
import { AccountListComponent } from '@components/account/list/account-list.component';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: AccountEditComponent.PAGE_ID })
export class AccountEditComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'AccountEditComponent';

  pageId(): string {
    return AccountEditComponent.PAGE_ID;
  }

  @ViewChildren(AccountRoleListItemComponent)
  accountRoleListItemComponents: Array<AccountRoleListItemComponent>;

  routerOutlet: any;

  private locales = environment.localization.locales;

  public accountDto: AccountDto = { preferences: [] };

  private accountId: string;

  private duplicateAccountId: string = null;

  accountStateTypes: EnumEntryDto[] = [];

  selectedAllRoles = false;

  externalUserEmailRequired: boolean = null;
  internalUserEmailRequired: boolean = null;

  validateEmailRequired = (accountEditComponent: AccountEditComponent = this): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const group: FormGroup = control.parent as FormGroup;
      if (group) {
        const externalControl = group.get('external');
        const external = externalControl.value;
        const email = control.value;
        if (!email || email.trim() === '') {
          if (external === true) {
            if (accountEditComponent.externalUserEmailRequired) {
              return { extUserEmailRequired: true };
            }
          } else {
            if (accountEditComponent.internalUserEmailRequired) {
              return { intUserEmailRequired: true };
            }
          }
        }
      }
      return null;
    };
  };

  validatePasswordConfirmation = (control: AbstractControl): ValidationErrors | null => {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      const password: string = group.get('password')?.value as string;
      const passwordConfirmation: string = control.value as string;
      if (password && passwordConfirmation && password !== passwordConfirmation) {
        return { notMatch: true };
      }
    }
    return null;
  };

  accountForm: FormGroup = this.formBuilder.group({
    id: [null],
    recordVersion: [null],
    firstName: [],
    lastName: [],
    birthdate: [null, Validators.required],
    login: [null, Validators.required],
    locale: [null, Validators.required],
    external: [],
    extId: [],
    email: [null, [Validators.required, Validators.email, this.validateEmailRequired()]],
    emailVerified: [false, [Validators.required]],
    employeeId: [],
    state: [null, Validators.required],
    parentId: [],
    loginAttemptLast: [{ value: null, disabled: true }],
    preferences: this.formBuilder.array([]),
  });

  passwordSettingsForm: FormGroup = this.formBuilder.group({
    password: [null],
    passwordConfirmation: [null, this.validatePasswordConfirmation],
    temporaryPassword: ['true'],
  });

  get idControl(): AbstractControl {
    return this.accountForm.get('id');
  }

  get preferencesFormArray(): FormArray {
    return <FormArray>this.accountForm.get('preferences');
  }

  get preferenceForms() {
    return <FormGroup[]>(<FormArray>this.accountForm.get('preferences')).controls;
  }

  createPreferenceForm(): FormGroup {
    return this.formBuilder.group({
      group: [, Validators.required],
      name: [, Validators.required],
      value: [, Validators.required],
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private dialog: MatDialog,
    private adminAccountService: AdminAccountService,
    private adminAclService: AdminAclService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    public aclService: AclService,
    private propertyAccessorLocalService: PropertyAccessorLocalService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const accountId = this.params.id;
      if (accountId) {
        if (accountId === '&') {
          const state = this.router.lastSuccessfulNavigation.extras.state;
          this.setAccount(this.accountDto, state?.duplicateAccountId);
        } else {
          this.loadAccount(accountId);
        }
      }
    } else {
      this.accountDto = undefined;
    }
  }

  validateEmail() {
    this.accountForm.controls.email.updateValueAndValidity();
  }

  loadAccount(accountId: string) {
    this.adminAccountService
      .getAccountById(accountId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setAccount(result);
        this.accountId = this.accountDto.id;
      });
  }

  setAccount(account: AccountDto, duplicateAccountId: string = null) {
    this.duplicateAccountId = duplicateAccountId;
    this.accountDto = account;
    this.accountForm.patchValue(this.accountDto);
    if (duplicateAccountId) {
      this.accountForm.controls.login.patchValue(`${this.accountDto.login}-copy`);
      this.accountForm.controls.id.patchValue(null);
      this.accountForm.controls.recordVersion.patchValue(null);
    }
    this.accountForm.controls.firstName.patchValue(this.getAccountParam(this.accountDto, 'firstName')?.value);
    this.accountForm.controls.lastName.patchValue(this.getAccountParam(this.accountDto, 'lastName')?.value);
    this.accountForm.controls.employeeId.patchValue(this.getAccountParam(this.accountDto, 'employeeId')?.value);
    this.accountForm.controls.birthdate.patchValue(this.getAccountParam(this.accountDto, 'birthdate')?.value);
    const accountEmailVerified = this.getAccountParam(this.accountDto, 'emailVerified')?.value;
    if (accountEmailVerified && accountEmailVerified === 'true') {
      this.accountForm.controls.emailVerified.patchValue(accountEmailVerified);
    } else {
      this.accountForm.controls.emailVerified.patchValue(false);
    }
    this.preferencesFormArray.clear();
    this.accountDto.preferences.forEach(preference => {
      const preferenceForm = this.createPreferenceForm();
      preferenceForm.patchValue(preference);
      this.preferencesFormArray.push(preferenceForm);
    });
    if (account.recordVersion && !this.duplicateAccountId) {
      this.accountForm.get('login').disable();
    } else {
      this.accountForm.get('login').enable();
    }
  }

  saveAccount(): boolean {
    this.validateEmail();
    FormUtils.validateAllFormFields(this.accountForm);
    if (this.accountForm.valid) {
      if (this.accountDto) {
        Object.keys(this.accountForm.controls).forEach(field => {
          const control = this.accountForm.get(field);
          switch (field) {
            case 'firstName':
            case 'lastName':
            case 'employeeId':
            case 'birthdate':
            case 'emailVerified':
              // set Account parameter
              if (control.value != null && control.value != undefined) {
                this.setAccountParam(this.accountDto, field, control.value);
              } else {
                this.accountDto.parameters = this.accountDto.parameters.filter(param => param.name != field);
              }
              break;
            default:
              // set Account attribute
              this.accountDto[field] = control.value;
          }
        });

        FormUtils.validateAllFormFields(this.passwordSettingsForm);
        const passwordSettings: PasswordSettingsForm = this.passwordSettingsForm.getRawValue() as PasswordSettingsForm;
        if (this.passwordSettingsForm.valid && passwordSettings.password) {
          this.accountDto.passwordSettings = {
            password: passwordSettings.password,
            temporaryPassword: passwordSettings.temporaryPassword,
          };
        }
      }
      return true;
    }
    return false;
  }

  save() {
    if (this.saveAccount()) {
      this.appBlockerService.block();
      this.prepareAccountToSave(this.accountDto);
      if (!(this.accountId === null || this.accountId === undefined)) {
        this.accountDto.login = null;
        delete this.accountDto.external;
        this.adminAccountService
          .updateAccount(this.accountId, this.accountDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getAccountHandler);
      } else {
        this.adminAccountService
          .createAccount(this.accountDto, this.duplicateAccountId)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getAccountHandler);
      }
    }
  }

  prepareAccountToSave(account) {
    account.stateFrom = null;
    account.created = null;
    account.createdBy = null;
    account.modified = null;
    account.modifiedBy = null;
    account.loginAttemptLast = null;
    if (account.roles) {
      account.roles.forEach(role => {
        role.created = undefined;
        role.createdBy = undefined;
        role.modified = undefined;
        role.modifiedBy = undefined;
      });
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditAccount(): AccountDto {
    return this.accountDto;
  }

  public reset() {
    this.setAccount(this.accountDto);
    this.accountId = this.accountDto.id;
    if (this.accountId) {
      this.navigateSelf({ id: this.accountId });
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.account.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminAccountService
        .deleteAccount(this.accountDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.navigateSibling(AccountListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  duplicate() {
    const navigationExtras: NavigationBehaviorOptions = {
      state: {
        duplicateAccountId: this.accountId,
      },
    };
    this.accountId = null;
    this.navigateSelf({ id: '&' }, false, navigationExtras);
  }

  public getAccountHandler = (accountDto: AccountDto): void => {
    let saving = false;
    if (this.accountDto) {
      saving = true;
    }
    if (accountDto && this.accountDto) {
      if (accountDto.id !== this.accountDto.id) {
        this.accountId = accountDto.id;
      }
      this.setAccount(accountDto);
      this.navigateSelf({ id: accountDto.id });
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  ngOnInit() {
    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.ACCOUNT, 'com.emeldi.ecc.be.account.enums.AccountStateType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.accountStateTypes = result.data;
      });
    this.propertyAccessorLocalService
      .getGlobalExternalUserEmailRequired()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        required => (this.externalUserEmailRequired = required.toLowerCase() === 'true' ? true : false),
        error => {
          this.externalUserEmailRequired = null;
        }
      );
    this.propertyAccessorLocalService
      .getGlobalInternalUserEmailRequired()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        required => (this.internalUserEmailRequired = required.toLowerCase() === 'true' ? true : false),
        error => {
          this.internalUserEmailRequired = null;
        }
      );
  }

  cancel() {
    this.navigateSibling(AccountListComponent.PAGE_ID);
  }

  getAccountParam(account: AccountDto, paramName: string): AccountParameterDto {
    return account?.parameters?.find(p => p.name === paramName);
  }

  setAccountParam(account: AccountDto, paramName: string, paramValue: string) {
    if (!account.parameters) {
      account.parameters = [];
    }
    const param = this.getAccountParam(account, paramName);
    if (param) {
      param.value = paramValue;
    } else {
      account.parameters.push({
        name: paramName,
        value: paramValue,
      });
    }
  }

  getEditPreferences(): AccountPreferenceDto[] {
    return this.accountDto.preferences;
  }

  removePreference(i) {
    this.preferencesFormArray.removeAt(i);
  }

  addPreference() {
    const preference: AccountPreferenceDto = { group: null, name: null, value: null };
    const preferenceForm = this.createPreferenceForm();
    preferenceForm.patchValue(preference);
    this.preferencesFormArray.push(preferenceForm);
  }

  handleSelectedAllRoles() {
    this.accountRoleListItemComponents.forEach(accounRoletListItemComponent => {
      accounRoletListItemComponent.selected = this.selectedAllRoles;
    });
  }

  deleteRole(role: AccountRoleDto) {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.account.roles.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      const idx = this.accountDto.roles.findIndex(r => r.roleName === role.roleName);
      if (idx > -1) {
        this.accountDto.roles.splice(idx, 1);
      }
      confirmationDialogComponent.dialogReference.close();
    };
  }

  addRole() {
    const modalRef = this.dialog.open(AclRolesSelectModalComponent);
    const aclRolesSelectModalComponent = modalRef.componentInstance;
    aclRolesSelectModalComponent.dialogRef = modalRef;
    aclRolesSelectModalComponent.disableActions = true;
    aclRolesSelectModalComponent.disableListingActions = true;

    aclRolesSelectModalComponent.selectHandler = roles => {
      if (!this.accountDto.roles) {
        this.accountDto.roles = [];
      }
      roles.forEach(role => {
        const idx = this.accountDto.roles.findIndex(r => r.roleName === role.id);
        if (idx === -1) {
          this.accountDto.roles.push({ roleName: role.id });
        }
      });
    };
  }

  getSelectedRoles(): Array<AccountRoleDto> {
    const selectedRoles = [];
    this.accountRoleListItemComponents.forEach(accountRoleListItemComponent => {
      if (accountRoleListItemComponent.selected) {
        selectedRoles.push(accountRoleListItemComponent.role);
      }
    });
    return selectedRoles;
  }

  deleteCheckedRoles() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.account.roles.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.getSelectedRoles().forEach(role => {
        const idx = this.accountDto.roles.findIndex(r => r.roleName === role.roleName);
        if (idx > -1) {
          this.accountDto.roles.splice(idx, 1);
        }
      });
      this.selectedAllRoles = false;
      confirmationDialogComponent.dialogReference.close();
    };
  }

  isNewAccount() {
    return !this.accountDto.id || this.accountDto.id === '&';
  }

  anonymize() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.accounts.anonymize.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = () => {
      this.appBlockerService.block();
      this.adminAccountService
        .anonymizeAccounts([this.accountDto.id])
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  private readonly UPDATE_PASSWORD = 'UPDATE_PASSWORD';

  private readonly REQUIRE_ACRIONS_PARAM = 'requiredActions';

  resetPassword() {
    FormUtils.validateAllFormFields(this.passwordSettingsForm);
    const passwordSettings: PasswordSettingsForm = this.passwordSettingsForm.getRawValue() as PasswordSettingsForm;
    if (this.passwordSettingsForm.valid && passwordSettings.password) {
      this.adminAccountService
        .resetPassword(this.getEditAccount().id, {
          password: passwordSettings.password,
          temporaryPassword: passwordSettings.temporaryPassword,
        })
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.adminAccountService.getAccountById(this.getEditAccount().id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(account => {
              let requiredActionsParameter = null;
              if (!passwordSettings.temporaryPassword) {
                requiredActionsParameter = null;
                this.passwordSettingsForm.reset();
                this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
              } else {
                requiredActionsParameter = account.parameters.find(parm => parm.name === this.REQUIRE_ACRIONS_PARAM);
                if (!requiredActionsParameter) {
                  requiredActionsParameter = {
                    name: this.REQUIRE_ACRIONS_PARAM,
                    value: this.UPDATE_PASSWORD
                  };
                  account.parameters.push(requiredActionsParameter);
                } else if (requiredActionsParameter.value.indexOf(this.UPDATE_PASSWORD) < 0) {
                  requiredActionsParameter.value = requiredActionsParameter.value + ',' + this.UPDATE_PASSWORD;
                }
              }

              if (requiredActionsParameter) {
                this.prepareAccountToSave(account);
                account.login = null;
                account.external = null;
                this.adminAccountService.updateAccount(account.id, account).pipe(takeUntil(this.onDestroy$))
                  .subscribe((account) => {
                    this.getAccountHandler(account);
                    this.passwordSettingsForm.reset();
                    this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
                  });
              } else {
                this.getAccountHandler(account);
              }
            })
        });
    }
  }
}

export interface PasswordSettingsForm {
  password: string;
  passwordConfirmation: string;
  temporaryPassword: boolean;
}
