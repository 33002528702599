import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AdminAclService,
  AdminDynamicEnumService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  ControlStructKeyArrayFilterMiner,
  Search,
} from '@btl/btl-fe-wc-common';
import { EnumEntryDto } from '@btl/admin-bff';
import { RoleDto } from '@btl/admin-bff/model/roleDto';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-account-filter',
  templateUrl: './account-filter.component.html',
  animations: [Animations.advancedFilterArrow],
})
export class AccountFilterComponent extends ComplexFilter {
  accountStateTypes: EnumEntryDto[] = [];
  aclRoles: RoleDto[] = [];

  filterForm = this.formBuilder.group({
    login: [],
    state: [],
    lastName: [],
    external: [],
    extId: [],
    employeeId: [],
    roles: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.login, 'login', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.state, 'state'),
    new ControlStructKeyArrayFilterMiner(this.filterForm.controls.lastName, 'parameters', 'name', 'lastName', 'value'),
    new ControlFilterMiner(this.filterForm.controls.external, 'external'),
    new ControlFilterMiner(this.filterForm.controls.extId, 'extId'),
    new ControlStructKeyArrayFilterMiner(
      this.filterForm.controls.employeeId,
      'parameters',
      'name',
      'employeeId',
      'value'
    ),
    new ControlFilterMiner(this.filterForm.controls.roles, 'roles', CompareTypeDtoEnum.IN),
  ];

  advancedFilterFields = ['extId', 'employeeId', 'roles'];

  constructor(
    private formBuilder: FormBuilder,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private adminAclService: AdminAclService
  ) {
    super();
    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.ACCOUNT, 'com.emeldi.ecc.be.account.enums.AccountStateType')
      .subscribe(result => {
        this.accountStateTypes = result.data;
      });

    const search: Search = { filtering: [], sorting: [], paging: { page: 1, pageSize: -1 } };
    this.adminAclService.filterRoles(search, null).subscribe(result => {
      this.aclRoles = result.data;
    });
  }
}
