import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { DmsFileDto } from '@btl/admin-bff';
import { AdminDmsService, AppBlockerService, CompareType, FormUtils, ServiceUtils } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ProductUtils } from '@helpers/product-utils';
import { FilesAddModalComponent } from '@components/files-add-modal/files-add-modal.component';
import { HttpClient } from '@angular/common/http';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-form-pictures',
  templateUrl: './form-pictures.component.html',
  styleUrls: ['./form-pictures.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormPicturesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormPicturesComponent),
    },
  ],
})
export class FormPicturesComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();
  locales = environment.localization.locales;

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  value: Array<Attachment>;

  @Input()
  dmsType: string;

  @Input()
  extIdPrefix: string;

  @Input()
  collapsed = false;

  @Input()
  headerTranslationKey: any;

  @Input()
  getFromGalleryEnabled = false;

  @Input()
  control: AbstractControl;

  @Input()
  types: Array<any>;

  @Input()
  singlePictureForType = false;

  @Input()
  disabled = false;

  @Input()
  disableCollapseButton = false;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  parentPictureTypes: Array<string> = [];

  new: boolean;

  uploadedImage = null;

  editPictureForm;
  pictureForm;

  selectedType = 'all';

  form: FormGroup = this.formBuilder.group({
    attachments: this.formBuilder.array([]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private adminDmsService: AdminDmsService,
    private httpClient: HttpClient,
    private confirmationDialogService: ConfirmationDialogService,
    private appBlockerService: AppBlockerService
  ) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  discloseValidatorChange: any = () => {};

  ngOnInit(): void {
    if (this.control) {
      const self = this;
      const origFunc = this.control.markAsTouched;
      this.control.markAsTouched = function () {
        FormUtils.validateAllFormFields(self.form);
        if (!self.form.valid) {
          self.control.setErrors(self.form.errors);
          self.discloseValidatorChange();
        } else {
          self.control.setErrors(null);
        }
        origFunc.apply(this, arguments);
      };
    }

    this.valueChanged();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.new) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return { invalid: true };
      }
    }
    this.new = false;
    return null;
  }

  valueChanged() {
    if (!this.value) {
      this.value = [];

      this.getFormArray().clear();
    } else {
      const extIds = [];
      this.value
        .sort((a, b) => a['pictureType'].localeCompare(b['pictureType']) || a.priority - b.priority)
        .forEach(attachment => {
          if (attachment.extId) {
            extIds.push(attachment.extId);
          }
          if (attachment['pictureType']) {
            attachment.type = attachment['pictureType'];
          }
        });

      if (extIds.length > 0) {
        let search = ServiceUtils.getUnlimitedSearch();
        search.filtering.push({
          column: 'extId',
          compareType: CompareTypeDtoEnum.IN,
          value: extIds,
        });
        this.adminDmsService
          .filterFiles(search, null)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.value.forEach(attachment => {
              if (!attachment.dmsFile) {
                attachment.dmsFile = result.data.find(file => file.extId === attachment.extId);
              }
            });

            if (result.data.length < extIds.length) {
              search = ServiceUtils.getUnlimitedSearch();
              search.filtering.push({
                column: 'id',
                compareType: CompareTypeDtoEnum.IN,
                value: extIds,
              });
              this.adminDmsService
                .filterFiles(search, null)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(result => {
                  this.value.forEach(attachment => {
                    if (!attachment.dmsFile) {
                      attachment.dmsFile = result.data.find(file => file.id === attachment.extId);
                    }
                  });
                  this.createFormArray();
                });
            } else {
              this.createFormArray();
            }
          });
      }
    }
  }

  private createFormArray() {
    this.getFormArray().clear();

    if (this.value) {
      this.value.forEach(param => {
        const pictureTypeDto = ProductUtils.getPictureTypeDto(this.types, param.type);
        if (pictureTypeDto.parentId === null) {
          param.childPictures = [];
          this.getChildPicturesTypes(param.type).forEach(loopType => {
            let attachment = this.value.find(
              picture => picture.type === loopType.id && picture.priority === param.priority
            );
            if (attachment && attachment['use'] === undefined) {
              attachment['use'] = true;
            } else if (!attachment) {
              attachment = {};
            }
            param.childPictures.push(attachment);
          });

          this.addToFormArray(param);
        }
      });
    }
  }

  addToFormArray(value) {
    const newDataFormGroup = this.formBuilder.group(
      FormPicturesComponent.getSubFormControlConfig(this.formBuilder, value.type, this.types)
    );
    if (!value.dmsFile) {
      value.dmsFile = {};
    }

    newDataFormGroup.patchValue(value);
    if (value.id) {
      const childPictures = newDataFormGroup.get('childPictures') as FormArray;
      childPictures.controls.forEach(childForm => {
        if (childForm.get('extId').value) {
          childForm.get('use').patchValue(true);
        } else {
          childForm.get('use').patchValue(false);
        }
      });
    }

    this.getFormArray().push(newDataFormGroup);
    this.new = false;
  }

  getParentTypes() {
    return this.types?.filter(pictureType => !pictureType.parentId);
  }

  getFormArray() {
    const attachments = this.form.get('attachments') as FormArray;
    return attachments;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    if (this.new) {
      return null;
    }
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      let ret = this.form.get('attachments').value;
      const children = [];
      ret.forEach(attachment => {
        attachment['childPictures']?.forEach(child => {
          child.priority = attachment.priority;
          children.push(child);
        });
      });
      ret = ret.concat(children);
      return ret;
    }
    return null;
  }

  add(uploadImages: any) {
    let addTypes = this.getParentTypes();
    if (this.singlePictureForType) {
      const usedTypes = this.getFormArray()
        .controls.filter(p => !p.get('delete').value)
        .map(p => p.get('type').value);
      addTypes = addTypes.filter(pt => !usedTypes.includes(pt.id));
    }

    const modalRef = this.dialog.open(FilesAddModalComponent, { width: '800px' });
    const pictureAddModalComponent = modalRef.componentInstance;
    pictureAddModalComponent.dialogRef = modalRef;
    pictureAddModalComponent.pictureTypes = addTypes;
    pictureAddModalComponent.multiple = !this.singlePictureForType;
    pictureAddModalComponent.fileExtensions = ['png', 'jpg', 'jpeg', 'gif'];
    pictureAddModalComponent.uploadedHandler = (newFile: DmsFileDto) => {
      newFile.extId = this.extIdPrefix + ServiceUtils.getRandomId();
      const attachment = {
        extId: newFile.extId,
        href: null,
        type: pictureAddModalComponent.selectedType.id,
        priority: this.getMaxPriorityByType(pictureAddModalComponent.selectedType.id),
        dmsFile: newFile,
      };
      this.addToFormArray(attachment);
      modalRef.close();
      if (this.getChildPicturesTypes(attachment.type).length > 0) {
        this.edit(
          uploadImages,
          this.getFormArray().controls.find(control => control.get('extId').value === attachment.extId)
        );
      }
    };
  }

  getMaxPriorityByType(pictureTypeId) {
    let priority = 0;
    this.getFormArray().controls.forEach((formGroup: FormGroup) => {
      if (!formGroup.controls['delete'].value && formGroup.controls['type'].value === pictureTypeId) {
        formGroup.controls['priority'].patchValue(priority);
        priority++;
      }
    });

    return priority;
  }

  getChildPicturesTypes(pictureTypeId) {
    return this.types.filter(type => type.parentId === pictureTypeId);
  }

  remove(formToRemove: AbstractControl) {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.products.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      (formToRemove.get('childPictures') as FormArray).controls.forEach(control => {
        control.get('delete').patchValue(1);
      });
      formToRemove.get('delete').patchValue(1);
      confirmationDialogComponent.dialogReference.close();
    };
  }

  download(subForm: AbstractControl) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    this.httpClient.get(subForm.get('href').value, httpOptions).subscribe((data: any) => {
      const blob = new Blob([data]);

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = subForm.value.dmsFile.name;
      link.click();
    });
  }

  cancel() {
    this.editPictureForm = null;
    this.dialog.closeAll();
  }

  saveChanges() {
    this.pictureForm.patchValue(this.editPictureForm.getRawValue());
    this.pictureForm = null;
    this.editPictureForm = null;
    this.dialog.closeAll();
  }

  getChildPicturesFormArray() {
    const childPictures = this.editPictureForm.get('childPictures') as FormArray;
    return childPictures;
  }

  asFormGroup(formGroup) {
    return formGroup as FormGroup;
  }

  dropPicture(event: CdkDragDrop<any, any>) {
    this.getFormArray().controls[event.previousContainer.data.index] = event.container.data.item;
    this.getFormArray().controls[event.container.data.index] = event.previousContainer.data.item;
    this.reOrder();
  }

  getRealIndex(index) {
    let realIndex = 0;
    this.getFormArray().controls.forEach((formGroup: FormGroup) => {
      if (
        !formGroup.controls['delete'].value &&
        (this.selectedType === 'all' || formGroup.controls['type'].value === this.selectedType)
      ) {
        if (index >= 0) {
          index--;
        }
      }
      if (index >= 0) {
        realIndex++;
      }
    });

    return realIndex;
  }

  change(event: any) {
    this.selectedType = event.value;
  }

  reOrder() {
    const typesPriorities = new Map();

    this.getParentTypes().forEach(type => {
      typesPriorities.set(type.id, 0);
    });

    this.getFormArray().controls.forEach((formGroup: FormGroup) => {
      if (!formGroup.controls['delete'].value) {
        let priority = typesPriorities.get(formGroup.controls['type'].value);
        formGroup.controls['priority'].patchValue(priority);
        priority++;
        typesPriorities.set(formGroup.controls['type'].value, priority);
      }
    });
  }

  uploaded(subForm: AbstractControl) {
    const modalRef = this.dialog.open(FilesAddModalComponent, { width: '1000px' });
    const pictureAddModalComponent = modalRef.componentInstance;
    pictureAddModalComponent.dialogRef = modalRef;
    pictureAddModalComponent.uploadStep = false;
    pictureAddModalComponent.multiple = false;
    pictureAddModalComponent.fileExtensions = ['png', 'jpg', 'jpeg', 'gif'];
    pictureAddModalComponent.uploadedHandler = (newFile: DmsFileDto) => {
      const form = subForm as FormGroup;
      form.controls['href'].patchValue(newFile.contentHref);
      form.controls['id'].patchValue(newFile.id);
      form.controls['update'].patchValue(1);
      (form.controls['dmsFile'] as FormGroup).controls['content'].patchValue(newFile.content);
      (form.controls['dmsFile'] as FormGroup).controls['name'].patchValue(newFile.name);
      (form.controls['dmsFile'] as FormGroup).controls['mediaType'].patchValue(newFile.mediaType);
      (form.controls['dmsFile'] as FormGroup).controls['contentHref'].patchValue(newFile.contentHref);
      form.get('dmsFile').patchValue(newFile);
      let index = 0;
      (form.get('childPictures') as FormArray)?.controls.forEach(childControl => {
        (childControl as FormGroup).controls['id'].patchValue(null);
        (childControl as FormGroup).controls['extId'].patchValue(null);
      });
      console.log(this.form);
      modalRef.close();
    };
  }

  edit(uploadImages: any, subForm: AbstractControl) {
    this.pictureForm = subForm as FormGroup;
    this.editPictureForm = this.formBuilder.group(
      FormPicturesComponent.getSubFormControlConfig(this.formBuilder, this.pictureForm.getRawValue().type, this.types)
    );
    this.editPictureForm.patchValue(this.pictureForm.getRawValue());
    this.dialog.open(uploadImages);
  }

  public static clearAttachmentList(attachments) {
    attachments = attachments?.filter(att => (att['use'] === undefined || att['use']) && !att['delete']);
    attachments?.forEach(attachment => {
      attachment.href = null;
      delete attachment['dmsFile'];
      delete attachment.type;
      delete attachment.delete;
      delete attachment['use'];
      delete attachment.update;
      delete attachment['childPictures'];
    });

    return attachments;
  }

  public static getDmsBulk(attachments) {
    let pictureBulkOperations = null;
    const pictures = [];
    const createList = [];
    const updateList = [];

    attachments?.forEach((attachment: Attachment) => {
      if (!attachment.dmsFile?.id && attachment.dmsFile?.extId) {
        createList.push(attachment.dmsFile);
      } else if (attachment['update']) {
        updateList.push(attachment.dmsFile);
      }

      attachment['pictureType'] = attachment.type;
      pictures.push(attachment);
    });

    if (createList.length > 0 || updateList.length > 0) {
      pictureBulkOperations = {
        bulkCreateList: createList,
        bulkUpdateList: updateList,
        bulkPatchList: [],
        bulkDeleteList: [],
      };
    }

    return pictureBulkOperations;
  }

  public static getSubFormControlConfig(formBuilder, type, types: Array<any>) {
    const formGroup = {
      id: [null],
      href: [null],
      extId: [null],
      priority: [0, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
      type: [],
      delete: [false],
      update: [false],
      childPictures: formBuilder.array([]),
      dmsFile: formBuilder.group({
        id: [null],
        contentHref: [null],
        extId: [null],
        note: [null],
        type: ['PC_PICTURE'],
        code: [],
        name: [null],
        mediaType: [],
        size: [],
        extension: [],
        params: [],
        content: [],
        texts: [],
        recordVersion: [],
      }),
    };

    types.forEach(loopType => {
      if (loopType.parentId === type) {
        formGroup.childPictures.push(formBuilder.group(FormPicturesComponent.getChildFormControlConfig(loopType.id)));
      }
    });

    return formGroup;
  }

  public static getChildFormControlConfig(type) {
    return {
      id: [null],
      href: [null],
      priority: [0, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
      type: [type],
      extId: [null],
      delete: [false],
      use: [true],
    };
  }
}

export interface Attachment {
  id?: number;
  extId?: string;
  priority?: number;
  href?: string;
  name?: string;
  type?: string;
  content?: any;
  delete?: any;
  childPictures?: Array<Attachment>;
  dmsFile?: DmsFileDto;
}
