<form *ngIf="socketContent" [formGroup]="form" class="node-form">
  <div class="node-header">
    <div class="header-label">
      {{ 'wc.admin.promotion.selectedSocket.editing.socketItem' | translate }}
    </div>
    <div class="node-form-close" (click)="this.closeEmitter.emit()" [attr.aria-label]="'wc.common.close' | translate">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="node-body">
    <div class="body-info">
      <div class="node-inputs">
        <app-input-select-form-field
          [formControlName]="'entityType'"
          [options]="productEntityTypes"
          (selectionChangeEmit)="clearField('entityId')"
          [translationPrefix]="'wc.admin.socket'">
        </app-input-select-form-field>
        <ng-container [ngSwitch]="form.controls['entityType'].value">
          <app-input-select-form-field
            *ngSwitchCase="'CatTech'"
            formControlName="entityId"
            [options]="techCategories"
            translationPrefix="wc.admin.socket">
          </app-input-select-form-field>

          <app-input-picker-form-field
            *ngSwitchCase="'ProdCode'"
            [parentGroup]="form"
            controlName="entityId"
            valueField="productCode"
            [pickerType]="PickerInputType.PRODUCT"
            [selectMode]="false"
            translationPrefix="wc.admin.socket">
          </app-input-picker-form-field>
          <app-custom-input
            *ngSwitchDefault
            [parentGroup]="form"
            controlName="entityId"
            inputType="text"
            translationPrefix="wc.admin.socket">
          </app-custom-input>
        </ng-container>
        <div class="node-contents -triple" formGroupName="contentQty">
          <app-custom-input
            [parentGroup]="form.controls['contentQty']"
            controlName="min"
            inputType="number"
            translationPrefix="wc.admin.socket">
          </app-custom-input>
          <app-custom-input
            [parentGroup]="form.controls['contentQty']"
            controlName="max"
            inputType="number"
            translationPrefix="wc.admin.socket">
          </app-custom-input>
          <app-custom-input
            [parentGroup]="form.controls['contentQty']"
            controlName="def"
            inputType="number"
            translationPrefix="wc.admin.socket">
          </app-custom-input>
        </div>

        <ng-container formGroupName="validFor">
          <app-input-date-form-field
            [parentGroup]="form.controls['validFor']"
            controlName="startDateTime"
            label="validFrom"
            translationPrefix="wc.admin.promotion">
          </app-input-date-form-field>
          <app-input-date-form-field
            [parentGroup]="form.controls['validFor']"
            controlName="endDateTime"
            label="validTo"
            translationPrefix="wc.admin.promotion">
          </app-input-date-form-field>
        </ng-container>
      </div>
      <div class="node-form-actions">
        <a (click)="delete()" [routerLink]="" class="action-delete">
          {{ 'wc.admin.promotion.delete' | translate }}
        </a>
      </div>
    </div>
  </div>
</form>
