<div class="main__header">
  <h1 id="001_stores_edit">{{ 'wc.admin.stores.storeEdit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_stores_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      [routerLink]=""
      *ngIf="aclService.pageEditAccess"
      class="btn -primary -xs icon stroke currentColor">
      <mat-icon svgIcon="save"></mat-icon>
      {{ 'wc.common.save' | translate }}</a
    >
    <a
      id="003_stores_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_stores_edit" mat-menu-item (click)="duplicate()" *ngIf="store?.id" [routerLink]="">
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="005_stores_edit" mat-menu-item (click)="reset()" [routerLink]="">
        <mat-icon svgIcon="reset"></mat-icon>
        {{ 'wc.common.reset' | translate }}</a
      >
      <a id="006_stores_edit" mat-menu-item (click)="delete()" *ngIf="store?.id" [routerLink]="">
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="007_stores_edit" (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__body">
        <form *ngIf="store" [formGroup]="form" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="form"
                  [controlName]="'extId'"
                  id="008_stores_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.stores'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="form"
                  [controlName]="'ouRefNo'"
                  id="009_stores_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.stores'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  *ngIf="storeTypes"
                  [formControlName]="'type'"
                  id="004_stores_filter"
                  [options]="storeTypes.data"
                  [showEmptyOption]="true"
                  [labelOption]="'name'"
                  [valueOption]="'name'"
                  [translationPrefix]="'wc.admin.stores'">
                </app-input-select-form-field>
              </div>
            </div>
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [disabled]="true"
                  [label]="'wc.common.created'"
                  [simplifiedInput]="true"
                  [enteredValue]="store.created | date: 'dd.MM.yyyy HH:mm'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [disabled]="true"
                  [label]="'wc.common.createdBy'"
                  [simplifiedInput]="true"
                  [enteredValue]="store.createdBy">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card__wrapper" [formGroup]="form">
    <app-form-parameters
      class="card -full"
      [dictionaryValue]="true"
      [control]="form.get('params')"
      formControlName="params">
    </app-form-parameters>
  </div>
</div>
