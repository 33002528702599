import { Component, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminAclService,
  AdminDynamicEnumService,
  AdminStepTemplateService,
  AdminTicketTypeService,
  AppBlockerService,
  CompareType,
  EnableDynamicLoading,
  FormUtils,
  Search,
  ServiceUtils,
  StickyMessageService,
  TicketingService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { finalize, mergeMap, takeUntil } from 'rxjs/operators';
import { BulkOperationsRequestDto, PagedRulesDto, StepTemplateDto, TicketTypeDto } from '@btl/admin-bff';
import {
  AclTableListItemComponent,
} from '@components/acl/components/acl-rules-table/list/item/acl-table-list-item.component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import { TicketTypeListComponent } from '@components/tickets/ticket-type/list/ticket-type-list.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-ticket-type-edit',
  templateUrl: './ticket-type-edit.component.html',
  styleUrls: ['./ticket-type-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: TicketTypeEditComponent.PAGE_ID })
export class TicketTypeEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'TicketTypeEditComponent';

  pageId(): string {
    return TicketTypeEditComponent.PAGE_ID;
  }

  PickerInputType = PickerInputType;

  @ViewChildren(AclTableListItemComponent)
  aclTableListItemComponents: Array<AclTableListItemComponent>;

  readonly SOURCE_NAME = EnumerationsService.TICKET;
  readonly TICKET_AREA_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.TicketAreaType';

  selected: any;
  routerOutlet: any;
  stepTemplate: StepTemplateDto;
  defaultNoteTypes = ['GENERAL'];
  defaultAssignStates = [];
  defaultBussinesStates = [];
  stepTemplateUsedAt = [];
  ticketTypeAreaTypes = [];
  ticketParamDataTypes = [];
  defaultTicketTypeCode: string;
  resourceIdentificationContext = {
    code: null,
    parameter: {
      name: null,
    },
  };
  ticketTypeDuplicated: boolean = false;

  ticketTypeAclRules: PagedRulesDto;
  ticketTypeParamAclRules: PagedRulesDto;
  ticketTypeAcl = [];

  ticketTypeDto: TicketTypeDto = {
    areaType: null,
    code: null,
    system: false,
    valid: true,
  };

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };

  ticketTypeForm: FormGroup = this.formBuilder.group({
    id: [],
    areaType: [null, Validators.required],
    code: [null, Validators.required],
    defaultSubject: [],
    defaultNoteType: [],
    defaultNote: [],
    defaultAsgnState: [],
    defaultAsgnStateReason: [],
    defaultAsgnTo: [],
    defaultBsnsState: [],
    defaultBsnsStateReason: [],
    remark: [],
    system: [false],
    valid: [true],
    defaultFollowUp: [],
    defaultSla: [],
    slaNotify: [],
    autoClose: [],
    defaultPriority: [0],
    minPriority: [0],
    maxPriority: [0],
    stepTemplate: this.formBuilder.group({
      code: [],
      description: [{ value: null, disabled: true }],
      steps: this.formBuilder.array([]),
    }),
    acl: this.formBuilder.array([]),
    parameters: this.formBuilder.array([]),
  });

  get parametersFormArray(): FormArray {
    return <FormArray>this.ticketTypeForm.get('parameters');
  }

  get stepsFormArray(): FormArray {
    return <FormArray>this.ticketTypeForm.get('stepTemplate.steps');
  }

  get aclFormArray(): FormArray {
    return <FormArray>this.ticketTypeForm.get('acl');
  }

  createParameterForm(): FormGroup {
    return this.formBuilder.group({
      name: [],
      defaultValue: [],
      dataType: [],
      dataTypeDetail: [],
      staticType: [],
      integrationName: [],
      note: [],
      validationRegex: [],
      paramMetas: [],
      localizedNames: this.formBuilder.group({}),
      acl: this.formBuilder.array([]),
    });
  }

  createStepsForm(): FormGroup {
    return this.formBuilder.group({
      stepSeqNo: [],
      code: [],
      description: [],
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private ticketingService: TicketingService,
    private adminTicketTypeService: AdminTicketTypeService,
    private adminStepTemplateService: AdminStepTemplateService,
    private adminAclService: AdminAclService,
    protected router: Router,
    protected route: ActivatedRoute,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private confirmationDialogService: ConfirmationDialogService,
    public stickyMessageService: StickyMessageService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const ticketTypeId = this.params.id;
      if (ticketTypeId) {
        if (ticketTypeId === '&') {
          this.setTicketType(this.ticketTypeDto);
        } else {
          this.adminTicketTypeService
            .getTicketTypeById(ticketTypeId, null)
            .pipe(takeUntil(this.onDestroy$))
            .pipe(finalize(this.appBlockerService.unblock))
            .subscribe(ticketType => {
              this.appBlockerService.block();
              this.setTicketType(ticketType);
            });
        }
      }
    } else {
      this.ticketTypeDto = undefined;
    }
  }

  setTicketType(ticketTypeDto: TicketTypeDto) {
    this.resetAll();
    this.defaultTicketTypeCode = ticketTypeDto.code;
    this.ticketTypeDto = ticketTypeDto;
    this.getBusinessStates();
    this.getAssignmentStates();
    this.loadEnums();
    this.loadAcl();
    if (this.ticketTypeDto?.stepTemplate?.code) {
      this.loadSteps(this.ticketTypeDto.stepTemplate);
    }
    this.loadParameters();
    if (this.ticketTypeDto.stepTemplate === null) {
      this.ticketTypeDto.stepTemplate = {};
    }
    this.ticketTypeForm.patchValue(this.ticketTypeDto);
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  save() {
    if (this.saveTicketType()) {
      const sources = [];
      const ticketTypeDtoParams = this.ticketTypeDtoParams();
      if (this.ticketTypeDto?.id) {
        sources.push(this.updateTicketType(ticketTypeDtoParams));
      } else {
        sources.push(this.createTicketType(ticketTypeDtoParams));
      }
      if (
        this.ticketTypeDto.parameters.length > 0 &&
        this.defaultTicketTypeCode !== this.ticketTypeForm.controls['code'].value
      ) {
        sources.push(
          this.getParamAclRules().pipe(
            mergeMap(paramAclRules => {
              this.ticketTypeParamAclRules = paramAclRules;
              return this.changeRules();
            })
          )
        );
      } else {
        sources.push(this.changeRules());
      }
      forkJoin(sources)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(ticketType => this.getTicketTypeHandler(ticketType[0]));
    }
  }

  saveTicketType(): boolean {
    FormUtils.validateAllFormFields(this.ticketTypeForm);
    if (this.ticketTypeForm.valid) {
      if (this.ticketTypeForm) {
        Object.keys(this.ticketTypeForm.controls).forEach(field => {
          if (field !== 'acl' && field !== 'id') {
            const control = this.ticketTypeForm.get(field);
            this.ticketTypeDto[field] = control.value;
          }
        });
      }
      return true;
    }
    return false;
  }

  reset() {
    this.ticketTypeForm.reset(this.ticketTypeDto);
    if (this.ticketTypeDto && this.ticketTypeDto.recordVersion) {
      this.loadTicketType(this.ticketTypeDto);
    } else {
      this.setTicketType(this.ticketTypeDto);
    }
  }

  resetAll() {
    this.clearFormArrays();
    this.ticketTypeAclRules = null;
    this.stepTemplate = null;
    this.stepTemplateUsedAt = [];
  }

  clearFormArrays() {
    this.parametersFormArray.clear();
    this.stepsFormArray.clear();
    this.aclFormArray.clear();
  }

  private getParamAclRules() {
    const filter: Search = {
      filtering: [
        {
          column: 'resourceType',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: 'TICKET_PARAMETER',
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    return this.adminAclService.filterRules(filter, null);
  }

  changeRules() {
    const createList = [];
    const updateList = [];
    const deleteList = [];
    this.resourceIdentificationContext.code = this.ticketTypeDto?.code;
    const paramResourceId = { ...this.resourceIdentificationContext };
    if (this.ticketTypeForm.controls['acl'].value.length > 0) {
      this.ticketTypeForm.controls['acl'].value.forEach(rule => {
        if (rule.privilege.resourceType === 'TICKET') {
          rule.resourceIdentification = ServiceUtils.setResourceIdentification(
            rule.privilege.resourceType,
            this.resourceIdentificationContext
          );
        }
        if (rule.ruleType) {
          if (rule.id === null) {
            createList.push(rule);
          } else {
            updateList.push(rule);
          }
        } else if (rule.id) {
          deleteList.push(rule);
        }
      });
    }
    if (this.parametersFormArray.length > 0) {
      const array = this.parametersFormArray.value;
      array.forEach(param => {
        this.resourceIdentificationContext.parameter.name = param.name;
        if (param.acl.length > 0) {
          param.acl.forEach(paramRule => {
            if (paramRule.privilege.resourceType === 'TICKET_PARAMETER') {
              paramRule.resourceIdentification = ServiceUtils.setResourceIdentification(
                paramRule.privilege.resourceType,
                this.resourceIdentificationContext
              );
            }
            if (paramRule.ruleType) {
              if (paramRule.id === null) {
                createList.push(paramRule);
              } else {
                updateList.push(paramRule);
              }
            } else if (paramRule.id) {
              deleteList.push(paramRule);
            }
          });
        }
      });
    }

    if (this.defaultTicketTypeCode !== this.ticketTypeForm.controls['code'].value) {
      if (this.ticketTypeDto.parameters.length > 0) {
        this.ticketTypeDto.parameters.forEach(parameter => {
          paramResourceId.code = this.defaultTicketTypeCode;
          paramResourceId.parameter.name = parameter.name;
          this.ticketTypeParamAclRules?.data.forEach(rule => {
            if (rule.ruleType) {
              if (
                rule.resourceIdentification ===
                ServiceUtils.setResourceIdentification(rule.privilege.resourceType, paramResourceId)
              ) {
                rule.resourceIdentification = ServiceUtils.setResourceIdentification(
                  rule.privilege.resourceType,
                  this.resourceIdentificationContext
                );
                if (rule.ruleType) {
                  if (rule.id === null || this.ticketTypeDuplicated) {
                    if (this.ticketTypeDuplicated) {
                      rule.id = this.ticketTypeDuplicated ? null : rule.id;
                    }
                    createList.push(rule);
                  } else {
                    updateList.push(rule);
                  }
                }
              }
            }
          });
        });
      }
      this.ticketTypeAclRules?.data.forEach(rule => {
        if (rule.ruleType) {
          rule.resourceIdentification = ServiceUtils.setResourceIdentification(
            rule.privilege.resourceType,
            this.resourceIdentificationContext
          );
          if (rule.id === null || this.ticketTypeDuplicated) {
            if (this.ticketTypeDuplicated) {
              rule.id = this.ticketTypeDuplicated ? null : rule.id;
            }
            createList.push(rule);
          } else {
            updateList.push(rule);
          }
        }
      });
    }

    const bulkOperationsRequestDto: BulkOperationsRequestDto = {
      bulkCreateList: createList,
      bulkUpdateList: updateList,
      bulkDeleteList: deleteList,
    };

    bulkOperationsRequestDto.bulkCreateList.forEach(rule => {
      rule.validFor = { startDateTime: new Date() };
    });
    return this.adminAclService.changeRules(bulkOperationsRequestDto).pipe(takeUntil(this.onDestroy$));
  }

  cancel() {
    this.navigateSibling(TicketTypeListComponent.PAGE_ID);
  }

  delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.ticketTypes.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminAclService.getTicketTypeRules(this.ticketTypeDto.code).subscribe(rulesToDelete => {
        this.adminAclService.changeRules(rulesToDelete).subscribe(value => {
          this.adminTicketTypeService
            .deleteTicketType(this.ticketTypeDto.id)
            .pipe(finalize(this.appBlockerService.unblock))
            .subscribe(result => {
              this.navigateSibling(TicketTypeListComponent.PAGE_ID);
              this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
            });
        });
      });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  duplicate() {
    this.ticketTypeDuplicated = true;
    this.ticketTypeDto.id = null;
    this.ticketTypeForm.controls['code'].patchValue(`${this.ticketTypeDto.code}copy`);
    this.ticketTypeDto.recordVersion = null;
    this.ticketTypeDto.modified = null;
    this.ticketTypeDto.modifiedBy = null;
    this.ticketTypeDto.createdBy = null;
    this.ticketTypeDto.created = null;
  }

  loadStepTemplate(stepTemplate) {
    return stepTemplate;
  }

  ticketTypeDtoParams() {
    const ticketTypeDtoParams = { ...this.ticketTypeDto };
    ticketTypeDtoParams.id = null;
    ticketTypeDtoParams.modified = null;
    ticketTypeDtoParams.modifiedBy = null;
    ticketTypeDtoParams.createdBy = null;
    ticketTypeDtoParams.created = null;
    if (this.stepTemplate?.id) {
      ticketTypeDtoParams.stepTemplate.id = this.stepTemplate.id;
      ticketTypeDtoParams.stepTemplate.code = null;
      ticketTypeDtoParams.stepTemplate.description = null;
      ticketTypeDtoParams.stepTemplate.steps = null;
    } else {
      ticketTypeDtoParams.stepTemplate = null;
    }
    return ticketTypeDtoParams;
  }

  createTicketType(ticketTypeDtoParams) {
    return this.adminTicketTypeService.createTicketType(ticketTypeDtoParams);
  }

  updateTicketType(ticketTypeDtoParams) {
    return this.adminTicketTypeService.updateTicketType(this.ticketTypeDto.id, ticketTypeDtoParams);
  }

  loadTicketType(ticketType: TicketTypeDto) {
    this.adminTicketTypeService
      .getTicketTypeById(ticketType.id, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(ticketType => {
        this.setTicketType(ticketType);
      });
  }

  createTicketTypeParameter(emitValues) {
    const paramForm = emitValues.parameterDetail as FormGroup;
    if (emitValues.parameterDetail) {
      this.parametersFormArray.push(paramForm);
    }
  }

  updateTicketTypeParameter(emitValues) {
    const paramForm = emitValues.parameterDetail as FormGroup;
    if (emitValues.parameterDetail) {
      this.parametersFormArray.removeAt(emitValues.index);
      this.parametersFormArray.insert(emitValues.index, paramForm);
    }
  }

  getTicketTypeHandler = (ticketType: TicketTypeDto): void => {
    if (ticketType) {
      this.stepsFormArray.clear();
      this.ticketTypeDuplicated = false;
      this.stepTemplate = null;
      this.stepTemplateUsedAt = [];
      if (ticketType.id === this.ticketTypeDto.id) {
        this.setTicketType(ticketType);
      } else {
        this.setTicketType(ticketType);
        this.navigateSelf({ id: ticketType.id });
      }
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  getBusinessStates() {
    this.ticketingService
      .getBusinessStates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(businessStates => {
        this.defaultBussinesStates = businessStates.states;
      });
  }

  getAssignmentStates() {
    this.ticketingService
      .getAssignmentStates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(assignmentStates => {
        this.defaultAssignStates = assignmentStates.states;
      });
  }

  getTicketAreaTypes() {
    this.adminDynamicEnumService
      .getEnumEntries(this.SOURCE_NAME, this.TICKET_AREA_TYPE_ENUM_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.ticketTypeAreaTypes = result.data;
      });
  }

  setStepTemplate(stepTemplate) {
    this.stepsFormArray.clear();
    this.stepTemplate = null;
    this.stepTemplateUsedAt = [];
    if (stepTemplate?.code) {
      this.loadSteps(stepTemplate);
    } else {
      this.ticketTypeForm.controls['stepTemplate'].reset();
    }
  }

  loadSteps(stepTemplate?: StepTemplateDto) {
    if (stepTemplate) {
      this.stepTemplate = stepTemplate;
      if (stepTemplate.steps.length > 0) {
        stepTemplate.steps.forEach(step => {
          const stepForm = this.createStepsForm();
          stepForm.patchValue(step);
          this.stepsFormArray.push(stepForm);
        });
      }
      if (stepTemplate.usedByTicketTypes.length > 0) {
        stepTemplate.usedByTicketTypes.forEach(usedTicketType => {
          this.stepTemplateUsedAt.push(usedTicketType.code);
        });
      }
      this.ticketTypeForm.get('stepTemplate.description').setValue(this.stepTemplate.description);
    }
  }

  loadParameters() {
    this.parametersFormArray.clear();
    if (this.ticketTypeDto?.parameters) {
      this.ticketTypeDto.parameters.forEach(parameter => {
        const parameterForm = this.createParameterForm();
        this.loadParameterForm(parameterForm, parameter);
        if (parameter.localizedNames && Object.keys(parameter.localizedNames).length > 0) {
          for (const localizedNamesKey in parameter.localizedNames) {
            const localizedNamesGroup = parameterForm.controls['localizedNames'] as FormGroup;
            const controlMessage = new FormControl();
            controlMessage.patchValue(parameter.localizedNames[localizedNamesKey]);
            localizedNamesGroup.addControl(localizedNamesKey, controlMessage);
          }
        }
        this.parametersFormArray.push(parameterForm);
      });
    }
  }

  loadParameterForm(parameterForm, parameter) {
    parameterForm.patchValue(parameter);
  }

  loadEnums() {
    this.getTicketAreaTypes();
  }

  loadAcl() {
    this.getAclRules();
  }

  private getAclRules() {
    const filter: Search = {
      filtering: [
        {
          column: 'resourceType',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: 'TICKET',
        },
        {
          column: 'resourceIdentification',
          compareType: CompareTypeDtoEnum.LIKE,
          value: ServiceUtils.setResourceIdentification('TICKET', this.ticketTypeDto),
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    this.adminAclService
      .filterRules(filter, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(rules => {
        this.ticketTypeAclRules = rules;
      });
  }

  aclLoaded() {
    return this.ticketTypeAclRules;
  }
}
