<div class="page_wrapper">
  <div class="main__header">
    <h1>{{ 'wc.admin.localizedTexts.form.edit' | translate }}</h1>
    <div class="page__menu">
      <a
        id="001_localized_text_edit"
        color="primary"
        mat-raised-button
        (click)="save()"
        *ngIf="aclService.pageEditAccess"
        [routerLink]=""
        class="btn -primary -xs icon stroke currentColor"
        ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
      >
      <a
        id="002_localized_text_edit"
        color="primary"
        mat-stroked-button
        [routerLink]=""
        *ngIf="aclService.pageEditAccess"
        class="btn -primary -xs more-actions icon dropDownIcon currentColor"
        [matMenuTriggerFor]="menu"
        #t="matMenuTrigger"
        >{{ 'wc.common.more.actions.button' | translate }}
        <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
      </a>
      <mat-menu #menu="matMenu" class="module-actions">
        <a id="003_localized_text_edit" mat-menu-item (click)="duplicate()" [routerLink]=""
          ><mat-icon svgIcon="duplicate"></mat-icon>{{ 'wc.common.duplicate' | translate }}</a
        >
        <a id="004_localized_text_edit" mat-menu-item (click)="reset()" [routerLink]=""
          ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
        >
        <a id="005_localized_text_edit" mat-menu-item (click)="delete()" [routerLink]=""
          ><mat-icon svgIcon="delete"></mat-icon>{{ 'wc.common.delete' | translate }}</a
        >
      </mat-menu>
      <a id="006_localized_text_edit" (click)="cancel()" class="btn cancel-button">{{
        'wc.common.cancel.button' | translate
      }}</a>
    </div>
  </div>
  <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  <div class="card__wrapper">
    <!-- card -->
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.localizedTexts.attributes' | translate }}
        </div>
        <div class="card__body">
          <form *ngIf="getEditLocalizedText()" [formGroup]="localizedTextForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'module'"
                    [options]="modules"
                    [disableOptionsTranslate]="true"
                    id="007_localized_text_edit"
                    [translationPrefix]="'wc.admin.localizedText'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="localizedTextForm"
                    [controlName]="'key'"
                    id="008_localized_text_edit"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.localizedText'">
                  </app-custom-input>
                </div>
                <div class="form__row"></div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'locale'"
                    id="009_localized_text_edit"
                    [options]="locales"
                    [translationPrefix]="'wc.admin.localizedText'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                    <mat-label>{{ 'wc.admin.localizedText.defaultValue' | translate }}</mat-label>
                    <textarea
                      id="010_localized_text_edit"
                      matTextareaAutosize
                      class="form__input"
                      matInput
                      type="text"
                      formControlName="defaultValue"></textarea>
                  </mat-form-field>
                </div>
                <div class="form__row">
                  <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                    <mat-label>{{ 'wc.admin.localizedText.customValue' | translate }}</mat-label>
                    <textarea
                      id="011_localized_text_edit"
                      matTextareaAutosize
                      class="form__input"
                      matInput
                      type="text"
                      formControlName="customValue"></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
