<div *ngIf="editable" class="main__header">
  <div class="header-box">
    <div class="icon-box PC">
      <mat-icon svgIcon="pcReleases"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.releases' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      (click)="newRelease()"
      [routerLink]=""
      class="btn -primary -xs"
      *ngIf="aclService.pageEditAccess"
      mat-raised-button
      color="primary"
      id="001_releases_listing"
      >{{ 'wc.common.new' | translate }}</a
    >
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-releases-filter
        (filterChanged)="handleFilterChange()"
        *ngIf="!hideFilter"
        [filtering]="search.filtering">
      </app-releases-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.releases.releaseListing' | translate }}</span>
          <div class="paginator__entries" *ngIf="pagedReleasesDto?.currentPage > 0">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedReleasesDto?.totalItems
                          ? pagedReleasesDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedReleasesDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedData()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>

      <div class="card__body">
        <app-table-listing-form
          [columnName]="[
            'releaseCode',
            'releaseDate',
            'memberCount',
            'releaseNote',
            'created',
            'modified',
            'modifiedBy'
          ]"
          [columnToTruncate]="['code', 'name', 'modifiedBy']"
          [dontShowOnMobile]="['created', 'modified', 'modifiedBy']"
          [fillIdBy]="'id'"
          [pagedDto]="pagedReleasesDto"
          (doubleClickEvent)="editRelease($event)"
          (editButtonEvent)="editRelease($event)"
          (editInNewTabButtonEvent)="editRelease($event, true)"
          (pageSizeEvent)="pageSizeChanged($event)"
          (deleteButtonEvent)="deleteData($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [editable]="aclService.pageEditAccess"
          [translationPrefix]="'wc.admin.releases'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
