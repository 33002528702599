import { Component, OnDestroy, OnInit } from '@angular/core';
import { CodebookModuleDto } from '@btl/order-bff';
import { Subject } from 'rxjs';
import { IconMapUtils } from '@helpers/iconMap-utils';
import { PageModuleService } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.scss'],
})
export class QuickActionsComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  enabledQuickActions = [
    'PRODUCTS',
    'GROUPS',
    'RELATIONSHIPS',
    'CMS_CONTENT',
    'LOCALIZED_TEXTS',
    'TICKETS',
    'CODEBOOKS',
    'RESOURCES',
  ];
  modules: CodebookModuleDto[] = [];

  constructor(public pageModules: PageModuleService) {}

  ngOnInit(): void {
    PageModuleService.getPageModulesSortedByPriority().subscribe(pageModules => {
      pageModules.forEach(module => {
        const found = this.enabledQuickActions.some(el => el === module.code);
        if (found && this.modules.length < 6) {
          module.cssClass = IconMapUtils.getIcon(module.code);
          this.modules.push(module);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
