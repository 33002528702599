import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AbstractPageComponent,
  AclService,
  AdminProductService,
  AdminTechnicalCategoryService,
  AppBlockerService,
  EnableDynamicLoading,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { TechnicalCategoryDto } from '@btl/admin-bff';
import { arrayToTree } from 'performant-array-to-tree';
import { finalize, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { EnumerationsService } from '@service/enumerations.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CategoryEditComponent } from '@components/product-catalogue/categories/category-edit/category-edit.component';

@Component({
  selector: 'app-categories-listing',
  templateUrl: './categories-listing.component.html',
  styleUrls: ['./categories-listing.component.scss'],
})
@EnableDynamicLoading({ customName: CategoriesListingComponent.PAGE_ID })
export class CategoriesListingComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'CategoriesListingComponent';

  pageId(): string {
    return CategoriesListingComponent.PAGE_ID;
  }

  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();

  currentView: string = 'tree';
  @ViewChild('tree', { static: true }) tree;

  technicalCategory: Array<TechnicalCategoryDto>;
  categoryTree;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private adminProductService: AdminProductService,
    private appBlockerService: AppBlockerService,
    private enumerationsService: EnumerationsService,
    private adminTechnicalCategoryService: AdminTechnicalCategoryService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  ngOnInit() {
    this.getCategories();
  }

  getCategories() {
    this.enumerationsService
      .getProductTechnicalCategoryDtos()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.technicalCategory = result;
        this.categoryTree = arrayToTree(this.technicalCategory, { dataField: '' });
        this.dataSource.data = this.categoryTree;
        this.treeControl.dataNodes = this.categoryTree;
        this.treeControl.expandAll();
      });
  }

  edit(category: TechnicalCategoryDto, inNewTab: boolean = false) {
    this.navigateSibling(CategoryEditComponent.PAGE_ID, { id: category.id }, inNewTab);
  }

  delete(category) {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.category.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminTechnicalCategoryService
        .deleteTechnicalCategory(category.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.getCategories();
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  newCategory() {
    this.edit({ id: 'newCategory' });
  }
}
