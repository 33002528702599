import { Component } from '@angular/core';
import { AbstractFilterComponent, CompareType } from '@btl/btl-fe-wc-common';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-cms-contents-filter',
  templateUrl: './cms-contents-filter.component.html',
  animations: [Animations.advancedFilterArrow],
})
export class CmsContentsFilterComponent extends AbstractFilterComponent {
  contentKeyIds = [
    { labelKey: 'wc.admin.cms.content.seoUrl', value: 'seoUrl' },
    { labelKey: 'wc.admin.cms.content.targetArea', value: 'targetArea' },
  ];
  contentTypes = [
    { labelKey: 'wc.admin.cms.content.templateType.BLANK_PAGE', value: 'BLANK_PAGE' },
    { labelKey: 'wc.admin.cms.content.templateType.BANNER', value: 'BANNER' },
  ];
  contentStates = [
    { labelKey: 'wc.admin.cms.content.state.ACTIVE', value: 'ACTIVE' },
    { labelKey: 'wc.admin.cms.content.state.INACTIVE', value: 'INACTIVE' },
  ];
  internals = [
    { labelKey: 'wc.common.yes.button', value: true },
    { labelKey: 'wc.common.no.button', value: false },
  ];
  mapFieldWithCompareType = new Map([
    ['code', CompareTypeDtoEnum.LIKE],
    ['keys.keyId', CompareTypeDtoEnum.EQUAL],
    ['keys.keyValue', CompareTypeDtoEnum.EQUAL],
    ['name', CompareTypeDtoEnum.LIKE],
    ['contentTemplateType', CompareTypeDtoEnum.EQUAL],
    ['state', CompareTypeDtoEnum.EQUAL],
    ['isInternal', CompareTypeDtoEnum.EQUAL],
  ]);
  mapFieldWithColumnName = new Map([
    ['keyId', 'keys.keyId'],
    ['keyValue', 'keys.keyValue'],
  ]);

  handleContentKeyIdLoaded = false;
  handleContentTypeLoaded = false;

  get keyIdControl(): AbstractControl {
    return this.filterForm.get('keyId');
  }

  get contentTemplateTypeControl(): AbstractControl {
    return this.filterForm.get('contentTemplateType');
  }

  advancedFilterFields = ['contentTemplateType', 'state', 'isInternal'];
  constructor(private formBuilder: FormBuilder) {
    super();

    this.filterForm = this.formBuilder.group({
      code: [],
      keyId: [],
      keyValue: [],
      name: [],
      contentTemplateType: [],
      state: [],
      isInternal: [],
    });
  }

  handleContentKeyId(keyId) {
    if (!this.handleContentKeyIdLoaded) {
      this.handleContentKeyIdLoaded = true;
      if (keyId === 'seoUrl') {
        this.contentTemplateTypeControl.setValue('BLANK_PAGE');
      } else if (keyId === 'targetArea') {
        this.contentTemplateTypeControl.setValue('BANNER');
      } else {
        this.contentTemplateTypeControl.setValue(null);
      }
    }
  }

  handleContentType(type) {
    if (!this.handleContentTypeLoaded) {
      this.handleContentTypeLoaded = true;
      if (type === 'BLANK_PAGE') {
        this.keyIdControl.setValue('seoUrl');
      } else if (type === 'BANNER') {
        this.keyIdControl.setValue('targetArea');
      } else {
        this.keyIdControl.setValue(null);
      }
    }
  }
}
