import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductDto, RelationshipDto } from '@btl/admin-bff';
import { ProductEditService } from '@service/product-edit.service';
import { takeUntil } from 'rxjs/operators';
import {
  RelationshipsListingComponent
} from '@components/product-catalogue/relationships/relationships-listing/relationships-listing.component';
import { AclService, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  ProductRelationshipEditModalComponent
} from '@components/product-catalogue/products/product-edit/product-relationships/product-relationship-edit-modal/product-relationship-edit-modal.component';
import {
  AbstractChangeOperationsComponent
} from '@components/abstract-change-operations/abstract-change-operations.component';
import { TableListingFormComponent } from '@components/table-listing-form/table-listing-form.component';

@Component({
  selector: 'app-product-relationships',
  templateUrl: './product-relationships.component.html',
  styleUrls: ['./product-relationships.component.scss'],
})
@EnableDynamicLoading({customName: ProductRelationshipsComponent.PAGE_ID})
export class ProductRelationshipsComponent
  extends AbstractChangeOperationsComponent
  implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductRelationshipsComponent';

  pageId(): string {
    return ProductRelationshipsComponent.PAGE_ID;
  }

  product: ProductDto = null;

  @ViewChild('operationsTable', {static: false})
  operationsTable: TableListingFormComponent;

  @ViewChild(RelationshipsListingComponent, {static: true})
  relationshipsListingComponent: RelationshipsListingComponent;

  constructor(
    public productEditService: ProductEditService,
    protected router: Router,
    protected route: ActivatedRoute,
    public aclService: AclService,
    private dialog: MatDialog
  ) {
    super(router, route);
    const product = this.productEditService.getEditProduct();
    if (product) {
      this.product = product;
    }
  }

  navigationSubscription(navigation: NavigationEnd) {}

  ngOnInit() {
    this.operations = this.productEditService.relationshipOperations;
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if (!this.product || this.product.id !== result.id) {
        this.product = result;
      }

      this.operations = [];
    });
  }

  getProductSourceFilter() {
    return [
      {
        column: 'sourceId',
        compareType: 'EQUAL',
        value: this.product.productCode,
      },
      {
        column: 'sourceType',
        compareType: 'EQUAL',
        value: 'ProdCode',
      },
    ];
  }

  getProductTargetFilter() {
    return [
      {
        column: 'targetId',
        compareType: 'EQUAL',
        value: this.product.productCode,
      },
      {
        column: 'targetType',
        compareType: 'EQUAL',
        value: 'ProdCode',
      },
    ];
  }

  saveData(): boolean {
    if (!this.productEditService.canEdit()) {
      return true;
    }

    this.productEditService.relationshipOperations = this.operations;
    this.product = null;
    return true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.productEditService.resetMode) {
      if (this.saveData()) {
        return true;
      } else {
        return false;
      }
    }

    this.productEditService.resetMode = false;

    return true;
  }

  newRelationships(mode: string) {
    const modalRef = this.dialog.open(ProductRelationshipEditModalComponent);
    const productRelationshipEditModalComponent = modalRef.componentInstance;
    productRelationshipEditModalComponent.dialogRef = modalRef;
    productRelationshipEditModalComponent.enableEditProduct = this.productEditService.enableEditProduct;
    productRelationshipEditModalComponent.sourceTargetMode = mode;
    productRelationshipEditModalComponent.product = this.product;
    productRelationshipEditModalComponent.saveDataHandler = relationships => {
      relationships.forEach(relationship => {
        this.addRow(relationship);
      });
    };
  }

  editRelationships(mode: string, relationship: RelationshipDto) {
    if (relationship.id === '&') {
      this.newRelationships(mode);
    } else {
      const changedRelationship = this.operations.find(operation => operation.id === relationship.id);
      if (changedRelationship) {
        relationship = changedRelationship;
      }
      const modalRef = this.dialog.open(ProductRelationshipEditModalComponent);
      const productRelationshipEditModalComponent = modalRef.componentInstance;
      productRelationshipEditModalComponent.dialogRef = modalRef;
      productRelationshipEditModalComponent.enableEditProduct = this.productEditService.enableEditProduct;
      productRelationshipEditModalComponent.sourceTargetMode = mode;
      productRelationshipEditModalComponent.product = this.product;
      productRelationshipEditModalComponent.relationship = relationship;
      productRelationshipEditModalComponent.saveDataHandler = (relationships: RelationshipDto[]) => {
        relationships.forEach(relationship => {
          this.editRow(relationship, changedRelationship);
        });
      };
      productRelationshipEditModalComponent.deleteDataHandler = (relationship: RelationshipDto) =>
        this.deleteRelationship(relationship);
    }
  }

  public editRelationshipCallback = (mode: string, relationship: RelationshipDto): void => {
    this.editRelationships(mode, relationship);
  };

  deleteRelationship(relationship: RelationshipDto) {
    this.deleteRow(relationship);
  }

  deleteSelectedOperations() {
    if (this.operationsTable.selection.hasValue()) {
      this.operationsTable.selection.selected.forEach(selection =>
        this.deleteRelationship(selection as RelationshipDto)
      );
    }
    this.operationsTable.selection.clear();
  }
}
