<div class="main__header">
  <h1 id="001_account_edit">{{ 'wc.admin.accounts.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_account_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="003_account_edit"
      color="primary"
      mat-stroked-button
      [routerLink]=""
      *ngIf="aclService.pageEditAccess"
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_account_edit" mat-menu-item (click)="duplicate()" *ngIf="!isNewAccount()" [routerLink]="">
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a
        id="004_account_edit"
        mat-menu-item
        *ngIf="accountDto.id && accountDto.external && accountDto.state !== 'ACTIVE'"
        (click)="anonymize()"
        [routerLink]="">
        <mat-icon svgIcon="anonymize"></mat-icon>
        {{ 'wc.common.anonymize' | translate }}
      </a>
      <a id="005_account_edit" mat-menu-item (click)="reset()" [routerLink]="">
        <mat-icon svgIcon="reset"></mat-icon>
        {{ 'wc.common.reset' | translate }}</a
      >
      <a id="006_account_edit" mat-menu-item (click)="delete()" *ngIf="!isNewAccount()" [routerLink]="">
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="007_account_edit" (click)="cancel()" class="btn cancel-button">{{
      'wc.common.cancel.button' | translate
    }}</a>
  </div>
</div>

<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

<div class="card__wrapper">
  <!-- card with main account attributes -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.accounts.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="getEditAccount()" [formGroup]="accountForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="accountForm"
                  [controlName]="'firstName'"
                  id="008_account_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.account'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="accountForm"
                  [controlName]="'lastName'"
                  id="009_account_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.account'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="accountForm"
                  [controlName]="'login'"
                  [inputType]="'text'"
                  id="010_account_edit"
                  [translationPrefix]="'wc.admin.account'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'locale'"
                  id="011_account_edit"
                  [options]="locales"
                  [translationPrefix]="'wc.admin.account'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'012_account_edit'"
                  [inputType]="'checkbox'"
                  [disabled]="accountId"
                  [parentGroup]="accountForm"
                  (checkboxChange)="validateEmail()"
                  [controlName]="'external'"
                  [label]="'wc.admin.account.external'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'state'"
                  [options]="accountStateTypes"
                  id="013_account_edit"
                  [labelOption]="'name'"
                  valueOption="name"
                  [translationPrefix]="'wc.admin.account'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="accountForm"
                  [controlName]="'email'"
                  [inputType]="'email'"
                  id="035_account_edit"
                  [translationPrefix]="'wc.admin.account'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'036_account_edit'"
                  [inputType]="'checkbox'"
                  [parentGroup]="accountForm"
                  [controlName]="'emailVerified'"
                  [label]="'wc.admin.account.emailVerified'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="accountForm"
                  [controlName]="'parentId'"
                  id="014_account_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.account'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-date-form-field
                  [parentGroup]="accountForm"
                  id="040_account_edit"
                  [controlName]="'birthdate'"
                  [translationPrefix]="'wc.admin.account'"
                  [hideTime]="true">
                </app-input-date-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="accountForm"
                  [controlName]="'extId'"
                  [inputType]="'text'"
                  id="015_account_edit"
                  [translationPrefix]="'wc.admin.account'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="accountForm"
                  id="017_account_edit"
                  [controlName]="'employeeId'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.account'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-date-form-field
                  [parentGroup]="accountForm"
                  *ngIf="accountDto.loginAttemptLast"
                  id="016_account_edit"
                  [controlName]="'loginAttemptLast'"
                  [translationPrefix]="'wc.admin.account'">
                </app-input-date-form-field>
              </div>
              <div class="form__row"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">
            {{ 'wc.admin.accounts.passwordSettings' | translate }}
          </span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            *ngIf="getEditAccount()?.id"
            id="041_account_edit"
            color="primary"
            mat-raised-button
            (click)="resetPassword()"
            [routerLink]=""
            class="btn -primary -xs edit icon stroke">
            <mat-icon svgIcon="plus"></mat-icon>{{ 'wc.admin.accounts.resetPassword' | translate }}
          </button>
        </div>
      </div>

      <div class="card__body">
        <form [formGroup]="passwordSettingsForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="passwordSettingsForm"
                  id="037_account_edit"
                  [controlName]="'password'"
                  [inputType]="'password'"
                  [translationPrefix]="'wc.admin.account'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="passwordSettingsForm"
                  id="038_account_edit"
                  [controlName]="'passwordConfirmation'"
                  [inputType]="'password'"
                  [translationPrefix]="'wc.admin.account'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'039_account_edit'"
                  [inputType]="'checkbox'"
                  [parentGroup]="passwordSettingsForm"
                  [controlName]="'temporaryPassword'"
                  [label]="'wc.admin.account.temporaryPassword'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- generated cards with preferences -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.account.preferences' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="018_account_edit"
            color="primary"
            mat-raised-button
            (click)="addPreference()"
            [routerLink]=""
            class="btn -primary -xs edit icon stroke">
            <mat-icon svgIcon="plus"></mat-icon>{{ 'wc.common.add' | translate }}
          </button>
        </div>
      </div>

      <div class="card__body">
        <div class="card -full" *ngFor="let preferenceFormGroup of preferenceForms; let i = index">
          <div class="card__inner">
            <form
              *ngIf="getEditPreferences()"
              [formGroup]="preferenceFormGroup"
              class="form"
              id="019_account_edit_{{ i }}">
              <div class="card__header">
                {{ 'wc.admin.account.preference' | translate }} - {{ preferenceFormGroup.controls['group'].value }} /
                {{ preferenceFormGroup.controls['name'].value }} &nbsp;&nbsp;<a
                  (click)="removePreference(i)"
                  [routerLink]=""
                  class="link"
                  id="020_account_edit_{{ i }}">
                  <mat-icon>delete</mat-icon>
                </a>
              </div>
              <div class="card__body">
                <div class="form__groups">
                  <div class="form__group -flex">
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="preferenceFormGroup"
                        [controlName]="'group'"
                        [inputType]="'text'"
                        id="021_account_edit_{{ i }}"
                        [translationPrefix]="'wc.admin.account.preference'">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="preferenceFormGroup"
                        [controlName]="'name'"
                        [inputType]="'text'"
                        id="022_account_edit_{{ i }}"
                        [translationPrefix]="'wc.admin.account.preference'">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="preferenceFormGroup"
                        [controlName]="'value'"
                        [inputType]="'text'"
                        id="023_account_edit_{{ i }}"
                        [translationPrefix]="'wc.admin.account.preference'">
                      </app-custom-input>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.account.roles' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="024_account_edit"
            color="primary"
            mat-raised-button
            (click)="addRole()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs edit icon stroke">
            <mat-icon svgIcon="plus"></mat-icon>{{ 'wc.common.add' | translate }}
          </button>
          <button
            id="025_account_edit"
            color="primary"
            mat-stroked-button
            (click)="deleteCheckedRoles()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>{{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <table class="table -primary table-sm" style="table-layout: fixed">
          <thead>
            <th scope="col" style="width: 32px">
              <input
                id="026_accounts_list_item"
                [(ngModel)]="selectedAllRoles"
                type="checkbox"
                (change)="handleSelectedAllRoles()" />
            </th>
            <th scope="col">
              {{ 'wc.admin.account.role.name' | translate }}
            </th>
            <th scope="col">
              {{ 'wc.admin.account.role.created' | translate }}
            </th>
            <th scope="col" class="content-desktop">
              {{ 'wc.admin.account.role.createdBy' | translate }}
            </th>
            <th scope="col">
              {{ 'wc.admin.account.role.modified' | translate }}
            </th>
            <th scope="col">
              {{ 'wc.admin.account.role.modifiedBy' | translate }}
            </th>
            <th scope="col" [style.width.px]="75">{{ 'wc.admin.account.role.action' | translate }}</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let role of accountDto.roles; let i = index">
              <tr
                (deleteRole)="deleteRole($event)"
                [role]="role"
                [title]="role.roleName"
                app-account-role-list-item
                class="table__row"
                [parentId]="'roles_listing_' + i"></tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="card -full" *ngIf="accountDto.recordVersion">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.account.entityRoles' | translate }}
      </div>

      <div class="card__body">
        <app-account-entity-list
          [editable]="false"
          [accountId]="accountDto.id"
          [disableListingActions]="true"
          [disableActions]="true">
        </app-account-entity-list>
      </div>
    </div>
  </div>
</div>
