// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';
import { getCurrentHost, getEnvProperty, getLocales } from '@btl/btl-fe-wc-common';

export const environment = {
  production: true,
  browserLoggingLevel: NgxLoggerLevel.DEBUG,
  remoteLoggingLevel: NgxLoggerLevel.DEBUG,
  useDefaultLanguageForMissingTranslation: false,
  localization: {
    defaultLocale: getEnvProperty('${DEFAULT_LOCALE}', 'en'),
    locales: getLocales('${LOCALES}', ['en', 'cs', 'sl']),
    localeData: {},
    modules: ['wc.admin'],
  },
  mockedLocalization: false, // true - overlay localization for runtime testing in development
  disableTickets: false, // true - disables tickets on dashboard
  currency: getEnvProperty('${CURRENCY}', 'EUR'),
  //KEYCLOAK or NCAS
  authenticationMethod: getEnvProperty('${AUTH_METHOD}', 'KEYCLOAK'),
  authenticationServerUri: `${getEnvProperty('${AUTH_SERVER_URI}', getCurrentHost())}/auth/`,
  authenticationIdleTimeout: getEnvProperty('${AUTH_IDLE_TIMEOUT}', '30'),
  dynamicRoutesEnabled: getEnvProperty('${DYNAMIC_ROUTES_ENABLED}', 'true'),
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
