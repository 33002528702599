import { Component, OnInit } from "@angular/core";
import {
  AclService,
  AuthFactoryService,
  CodebookService,
  DynamicComponentRegistry,
  DynamicRoutesConfig,
  getCurrentHost,
  PageModuleService,
} from "@btl/btl-fe-wc-common";
import { Router } from "@angular/router";
import { environment } from "@environments/environment";
import { APP_ROUTES } from "./app-routing.module";
import { CodebookModuleDto } from "@btl/order-bff";
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  template: ``,
})
export class LoginRouteComponent implements OnInit {
  constructor(
    private authFactoryService: AuthFactoryService,
    private aclService: AclService,
    private dynamicComponentRegistry: DynamicComponentRegistry,
    private codebookService: CodebookService,
    private router: Router
  ) {
  }

  ngOnInit() {
    let dynamicRoutesEnabled: boolean =
      environment.dynamicRoutesEnabled.toLowerCase() === "true";

    const finishRoutesInit = () => {
      this.router.initialNavigation();
      this.router.navigate([this.router.url]);
    };
    console.info("-- APP-INIT dynamicRoutesEnabled:" + dynamicRoutesEnabled);
    if (dynamicRoutesEnabled) {
      this.codebookService.getCodebookModules("MODULE_PAGE").subscribe(
        (dynamicRoutes: CodebookModuleDto[]) => {
          const adminDynamicRoutes = dynamicRoutes.filter(
            (module) => module.moduleId === "admin"
          );

          this.aclService.moduleAccess("admin")
            .pipe(catchError(() => of(null)))
            .subscribe((result) => {
                if (adminDynamicRoutes.length === 0 || !result) {
                  setTimeout(() => {
                    this.authFactoryService?.getAuthService().logout(getCurrentHost());
                  }, 5000);
                }
              }
            );

          const pageDynamicRoutesConfig: DynamicRoutesConfig = {
            codebookModuleDtos: adminDynamicRoutes,
            dynamicComponentRegistry: this.dynamicComponentRegistry,
            router: this.router,
            appRoutes: APP_ROUTES,
            defaultPageTemplateComponentId:
            DynamicComponentRegistry.DEFAULT_PAGE_TEMPLATE_COMPONENT_NAME,
            defaultCanActivateGuardId:
            DynamicComponentRegistry.DEFAULT_CAN_ACTIVATE_GUARD,
            defaultCanDeactivateGuardId:
            DynamicComponentRegistry.DEFAULT_CAN_DEACTIVATE_GUARD,
          };
          PageModuleService.handleDynamicRoutes(pageDynamicRoutesConfig);
          finishRoutesInit();
        },
        (err) => {
          console.error(
            "Error occurred while loading dynamic routes. Some pages may not be displayed."
          );
          console.error(err);
          finishRoutesInit();
        }
      )
    } else {
      finishRoutesInit();
    }
  }
}
