<h2 mat-dialog-title>
  {{
    isNewPrice === true
      ? ('wc.admin.productPrices.priceAdd' | translate) + ' • ' + currentProductId
      : ('wc.admin.productPrices.priceEdit' | translate) + ' • ' + currentProductId
  }}
    <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content class="modal-body">
  <div class=".mat-drawer-container">
    <div class="mat-sidenav-content">
      <div class="main_content">
        <div class="card__wrapper">
          <!-- card -->
          <div class="card -full">
            <div class="card__inner">
              <div class="card__header">
                {{ 'wc.admin.productPrices.attributes' | translate }}
              </div>
              <div class="card__body">
                <form [formGroup]="priceForm" class="form">
                  <div class="form__groups">
                    <div class="form__group -flex">
                      <div class="form__row">
                        <app-input-select-form-field
                          [formControlName]="'priceType'"
                          inputType="dynamicEnum"
                          enumName="com.emeldi.ecc.be.pc.enums.PriceType"
                          sourceName="pc"
                          labelOption="name"
                          valueOption="name"
                          [label]="'type'"
                          [translationPrefix]="'wc.admin.productPrices'">
                        </app-input-select-form-field>
                      </div>
                      <div class="form__row">
                        <app-custom-input
                          [parentGroup]="priceForm"
                          [controlName]="'priority'"
                          [inputType]="'number'"
                          id="005_product_price_edit"
                          [translationPrefix]="'wc.admin.productPrices'">
                        </app-custom-input>
                      </div>
                      <div class="form__row">
                        <app-custom-input
                          [parentGroup]="priceForm"
                          [controlName]="'price'"
                          [inputType]="'number'"
                          id="006_product_price_edit"
                          [translationPrefix]="'wc.admin.productPrices'">
                        </app-custom-input>
                      </div>
                      <div class="form__row">
                        <div class="two-inputs">
                          <app-input-date-form-field
                            [parentGroup]="priceForm.controls['validFor']"
                            [controlName]="'startDateTime'"
                            [label]="'validForFrom'"
                            id="007_product_price_edit"
                            [translationPrefix]="'wc.admin.productPrices'">
                          </app-input-date-form-field>
                          <mat-icon style="display: flex; align-self: center">arrow_forward</mat-icon>
                          <app-input-date-form-field
                            [parentGroup]="priceForm.controls['validFor']"
                            [controlName]="'endDateTime'"
                            [label]="'validForTo'"
                            id="008_product_price_edit"
                            [translationPrefix]="'wc.admin.productPrices'">
                          </app-input-date-form-field>
                        </div>
                      </div>

                      <div class="form__row">
                        <app-custom-input
                          [parentGroup]="priceForm"
                          [controlName]="'tax'"
                          [inputType]="'number'"
                          id="009_product_price_edit"
                          [translationPrefix]="'wc.admin.productPrices'">
                        </app-custom-input>
                      </div>

                      <div class="form__row">
                        <app-custom-input
                          [parentGroup]="priceForm"
                          [controlName]="'code'"
                          [inputType]="'text'"
                          id="010_product_price_edit"
                          [translationPrefix]="'wc.admin.productPrices'">
                        </app-custom-input>
                      </div>
                      <div class="form__row">
                        <app-input-select-form-field
                          [formControlName]="'priceAppType'"
                          id="011_product_price_edit"
                          inputType="dynamicEnum"
                          enumName="com.emeldi.ecc.be.pc.enums.PriceAppType"
                          sourceName="pc"
                          labelOption="name"
                          valueOption="name"
                          [preSelectFirst]="true"
                          [label]="'appType'"
                          [translationPrefix]="'wc.admin.productPrices'">
                        </app-input-select-form-field>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="card__wrapper" [formGroup]="priceParams">
            <app-form-parameters
              class="card -full"
              [control]="priceParams.get('parameters')"
              [disabled]="!this.productEditService.enableEditProduct"
              formControlName="parameters">
            </app-form-parameters>
          </div>

          <div [formGroup]="priceForm" class="card__wrapper">
            <!-- card -->
            <app-eligibility-expression
              class="card -full"
              [parentGroup]="priceForm"
              formControlName="ruleSetExpression"
              [disabled]="!this.productEditService.enableEditProduct">
            </app-eligibility-expression>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a
    id="002_product_price_edit"
    color="primary"
    mat-raised-button
    (click)="save()"
    [routerLink]=""
    class="btn -primary -xs icon fill no-stroke"
    [disabled]="!this.productEditService.enableEditProduct"
    ><mat-icon svgIcon="widgetConfirm"></mat-icon>{{ 'wc.common.confirm' | translate }}</a
  >
  <a
    id="003_product_price_edit"
    color="primary"
    mat-raised-button
    (click)="reset()"
    [routerLink]=""
    class="btn -primary -xs icon fill no-stroke"
    [disabled]="!this.productEditService.enableEditProduct"
    ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
  >
  <a
    id="004_product_price_edit"
    color="primary"
    mat-raised-button
    (click)="cancel()"
    [routerLink]=""
    class="btn -primary -xs"
    >{{ 'wc.common.cancel.button' | translate }}</a
  >
</mat-dialog-actions>
