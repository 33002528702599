<div *ngIf="editable" class="main__header">
  <div class="header-box">
    <div class="icon-box PC">
      <mat-icon svgIcon="relationships"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.relationships' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      [id]="parentId ? parentId + '_' + '002_relationships_listing' : '002_relationships_listing'"
      color="primary"
      mat-raised-button
      (click)="newRelationship()"
      [routerLink]=""
      *ngIf="aclService.pageEditAccess"
      class="btn -primary icon -xs stroke">
      <mat-icon svgIcon="plus"></mat-icon>
      {{ 'wc.admin.new' | translate }}
    </a>
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-relationships-filter
        [parentId]="parentId"
        [constantFilter]="constantFilter"
        (filterChanged)="handleFilterChange()"
        [filtering]="search.filtering"></app-relationships-filter>
    </div>

    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">
            {{ 'wc.admin.relationships.relationshipListing' | translate }}
            {{ additionalHeaderLtKey ? (additionalHeaderLtKey | translate).toLowerCase() : '' }}
          </span>
          <div class="paginator__entries">
            {{
            'wc.common.pagination.showing'
              | translate
              : {
                from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                to:
                  search.paging.pageSize * search.paging.page > pagedRelationshipsDto?.totalItems
                    ? pagedRelationshipsDto?.totalItems
                    : search.paging.pageSize * search.paging.page,
                      of: pagedRelationshipsDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            [id]="parentId ? parentId + '_' + '006_relationships_listing' : '006_relationships_listing'"
            (click)="setValidToForCheckedRelationships()"
            *ngIf="!disableListingActions && !disableSetValidToActions && aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon class="default-icon">create</mat-icon>
            {{ 'wc.admin.relationships.setValidTo.button' | translate }}
          </button>
          <button
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            [id]="parentId ? parentId + '_' + '005_relationships_listing' : '005_relationships_listing'"
            (click)="deleteCheckedData()"
            *ngIf="!disableListingActions && aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="[
            'type',
            'sourceType',
            'sourceId',
            'sourceName',
            'targetType',
            'targetId',
            'targetName',
            'modified',
            'validFor.endDateTime'
          ]"
          [columnToTruncate]="['type', 'modifiedBy', 'sourceName', 'targetName']"
          [dontShowOnMobile]="[
            'code',
            'sourceType',
            'sourceId',
            'targetType',
            'targetId',
            'modified',
            'modifiedBy',
            'validFor.endDateTime'
          ]"
          [disabledSortColumns]="['sourceName', 'targetName']"
          [fillIdBy]="'id'"
          (doubleClickEvent)="editRelationship($event)"
          (editButtonEvent)="editRelationship($event)"
          (editInNewTabButtonEvent)="replaceEditCallback ? null : editRelationship($event, true)"
          (deleteButtonEvent)="deleteData($event)"
          [pagedDto]="pagedRelationshipsDto"
          (pageSizeEvent)="pageSizeChanged($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [editable]="aclService.pageEditAccess"
          [disableActions]="disableActions"
          [disabledParentClickEvent]="!editable"
          [disableEditInNewTabButton]="replaceEditCallback"
          [getRowClassCallback]="getRowClassCallback"
          [suffix]="parentId"
          [translationPrefix]="'wc.admin.relationships'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
