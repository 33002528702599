<h2 mat-dialog-title>
  {{ 'wc.common.choose' | translate }}
    <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content class="mat-typography">
  <app-dms-files-listing
    (singleSelectEvent)="singleSelect($event)"
    [cacheFilter]="false"
    [disabledActions]="true"
    [multiSelectMode]="multiSelectMode"
    [singleSelectMode]="singleSelectMode"
    [constantFilter]="constantFilter">
  </app-dms-files-listing>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="cancel()" [routerLink]="" color="primary" mat-raised-button>
    {{ 'wc.common.cancel.button' | translate }}
  </button>
  <button *ngIf="selectMode" (click)="selectEvent()" [routerLink]="" color="primary" mat-raised-button>
    {{ 'wc.common.choose' | translate }}
  </button>
</mat-dialog-actions>
