import { Component } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  template: '<router-outlet></router-outlet>',
})
@EnableDynamicLoading({ customName: NotificationComponent.PAGE_ID })
export class NotificationComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'NotificationComponent';

  pageId(): string {
    return NotificationComponent.PAGE_ID;
  }

  constructor(protected router: Router, protected route: ActivatedRoute) {
    super(router, route);
  }

  static getEditorConfig(): AngularEditorConfig {
    return {
      editable: true,
      spellcheck: true,
      height: '150px',
      minHeight: '100px',
      maxHeight: '300px',
      minWidth: '100%',
      placeholder: 'Enter text here...',
      translate: 'no',
      sanitize: false,
      toolbarPosition: 'top',
      uploadUrl: null,
      customClasses: [
        {
          name: 'Odstavec',
          class: 'notification-editor-indent',
          tag: 'div',
        },
        {
          name: 'Nadpis',
          class: 'notification-editor-typo--heading-h2',
          tag: 'h3',
        },
        {
          name: 'Zarovnaný text',
          class: 'notification-editor-text-content notification-editor-typo--align-center',
          tag: 'div',
        },
      ],
      toolbarHiddenButtons: [
        ['insertVideo', 'fontName', 'fontSize', 'subscript', 'superscript', 'strikeThrough', 'customClasses'],
      ],
    };
  }

  static getDisabledEditorConfig(): AngularEditorConfig {
    return {
      editable: false,
      height: '200px',
      minHeight: '100px',
      width: '100%',
      minWidth: '100%',
      enableToolbar: true,
      toolbarHiddenButtons: [
        [
          'undo',
          'redo',
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'subscript',
          'superscript',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          'insertUnorderedList',
          'insertOrderedList',
          'heading',
          'fontName',
        ],
        [
          'fontSize',
          'textColor',
          'backgroundColor',
          'customClasses',
          'link',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'toggleEditorMode',
        ],
      ],
    };
  }

  /**
   * Function groups array of objects to groups by given key
   * @param list array of objects
   * @param keyGetter attribute of object
   */
  static groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
}
