import { Component } from '@angular/core';
import {
  GatewayDto,
  LoopbackConfigurationDto,
  NotificationTypeDto,
  PagedGatewaysDto,
  PagedNotificationTypesDto
} from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import {
  AbstractPageComponent,
  AclService,
  AdminDynamicEnumService,
  AdminGatewayService,
  AdminNotificationTypeService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EnumerationsService } from '@service/enumerations.service';
import {
  NotificationGatewayListComponent,
} from '@components/notification/gateways/list/notification-gateway-list.component';
import { NotificationTypeEditComponent } from '@components/notification/types/edit/notification-type-edit.component';
import { NotificationFrontendService, PagedNotificationsDto, SortDto } from '@btl/order-bff';

@Component({
  selector: 'app-notification-gateway-edit',
  templateUrl: './notification-gateway-edit.component.html',
  styleUrls: ['./notification-gateway-edit.component.scss'],
  providers: [NotificationFrontendService],
})
@EnableDynamicLoading({ customName: NotificationGatewayEditComponent.PAGE_ID })
export class NotificationGatewayEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'NotificationGatewayEditComponent';

  pageId(): string {
    return NotificationGatewayEditComponent.PAGE_ID;
  }

  gatewayDto: GatewayDto;
  gatewayInitialized = false;
  channelTypes = [];

  // Form Groups
  gatewayForm: FormGroup = this.formBuilder.group({
    code: [null, Validators.required],
    name: [null],
    channelType: [null, Validators.required],
    supportDeliveryReport: [false],
    temporaryDisabled: [false],
    loopbackEnabled: [false],
  });

  loopbacksFormGroup = this.formBuilder.group({
    loopbacks: this.formBuilder.array([]),
  });

  get loopbacksFormArray(): FormArray {
    return this.loopbacksFormGroup.get('loopbacks') as FormArray;
  }

  get codeFormControl(): AbstractControl {
    return this.gatewayForm.get('code');
  }

  // Notif types table
  pagedNotificationTypesDto: PagedNotificationTypesDto;

  notificationTypesSearch: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };

  static clearAttributes(gatewayDto): GatewayDto {
    const clearedGatewayDto = gatewayDto ? JSON.parse(JSON.stringify(gatewayDto)) : {};
    clearedGatewayDto.id = null;
    clearedGatewayDto.created = null;
    clearedGatewayDto.createdBy = null;
    clearedGatewayDto.modified = null;
    clearedGatewayDto.modifiedBy = null;
    return clearedGatewayDto;
  }

  constructor(
    public aclService: AclService,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private adminNotificationTypeService: AdminNotificationTypeService,
    private adminGatewayService: AdminGatewayService,
    private notificationFrontendService: NotificationFrontendService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const gatewayId = this.params.id;
      this.setGatewayDto(gatewayId);

      this.adminDynamicEnumService
        .getEnumEntries(EnumerationsService.NOTIFICATION, 'com.emeldi.ecc.be.notification.enums.Channel')
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.channelTypes = result?.data ? result.data.map(value => value.name).sort() : [];
        });
    }
  }

  setGatewayDto(gatewayId: string, hard = false): void {
    if (
      gatewayId &&
      gatewayId !== '&' &&
      gatewayId !== 'newGateway' &&
      (hard || !this.gatewayDto || this.gatewayDto.id !== gatewayId)
    ) {
      this.appBlockerService.block();
      this.adminGatewayService
        .getGatewayById(gatewayId)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: GatewayDto) => {
          this.gatewayDto = result;
          this.initForm();
        });
    } else {
      this.cancel();
    }
  }

  initForm(): void {
    this.gatewayForm.reset(this.gatewayDto);
    this.checkEditableCode();
    this.loadNotificationTypes(this.gatewayDto.code);
    this.initLoopbacks();
    this.gatewayInitialized = true;
  }

  save(): void {
    if (!this.isAllFormsValid()) {
      return;
    }
    this.updateModels();

    this.appBlockerService.block();
    const id = this.gatewayDto.id;
    const gatewayDto = NotificationGatewayEditComponent.clearAttributes(this.gatewayDto);
    this.adminGatewayService
      .updateGateway(id, gatewayDto)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: GatewayDto) => {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        this.gatewayDto = result;
        this.initForm();
      });
  }

  cancel(): void {
    this.navigateSibling(NotificationGatewayListComponent.PAGE_ID);
  }

  reset(): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.notification.reset.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = (dialogReference: NgbModalRef) => {
      this.refreshPage();
      confirmationDialogComponent.dialogReference.close();
    };
  }

  delete(): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.notification.delete.confirmation.text',
    ]);
    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminGatewayService
        .deleteGateway(this.gatewayDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: GatewayDto) => {
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
          this.cancel();
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  initLoopbacks(): void {
    if (this.gatewayDto?.loopbacks && this.gatewayDto?.loopbacks.length) {
      this.loopbacksFormArray.controls = [];
      this.gatewayDto?.loopbacks.forEach((loopback: LoopbackConfigurationDto) => {
        this.addLoopbackToFormArray(loopback);
      });
    }
  }

  addNewRecipient(): void {
    this.addLoopbackToFormArray({
      gateway: null,
      recipient: null,
      passEnabled: false,
    });
  }

  deleteRecipient(index: number): void {
    this.loopbacksFormArray.removeAt(index);
  }

  onLoopbackCheckboxChange(checkboxChange: MatCheckboxChange, index: number): void {
    const recipientControl = this.loopbacksFormArray.at(index).get('recipient');
    if (checkboxChange.checked) {
      recipientControl.setValidators(Validators.required);
    } else {
      recipientControl.clearValidators();
      recipientControl.setErrors(null);
    }
    recipientControl.updateValueAndValidity();
  }

  checkUniqueCode(): void {
    if (!this.codeFormControl.valid) {
      this.codeFormControl.markAsTouched();
      return;
    } else if (this.codeFormControl.value === this.gatewayDto.code) {
      return;
    }

    const search: Search = {
      filtering: [{ column: 'code', compareType: 'EQUAL', value: this.codeFormControl.value }],
      sorting: [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }],
      paging: { page: 1, pageSize: 1 },
    };

    this.codeFormControl.setErrors(null);
    this.appBlockerService.block();
    this.adminGatewayService
      .filterGateways(search)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PagedGatewaysDto) => {
        if (result?.data && result.data.length > 0) {
          this.codeFormControl.setErrors({ duplicate: true });
          this.codeFormControl.markAsTouched();
        } else {
          this.loadNotificationTypes(this.codeFormControl.value);
        }
      });
  }

  pageSizeChanged(page): void {
    this.notificationTypesSearch.paging.page = page.pageIndex + 1;
    this.notificationTypesSearch.paging.pageSize = page.pageSize;
    this.loadNotificationTypes(this.codeFormControl.value);
  }

  editNotificationType(notificationTypeDto: NotificationTypeDto, inNewTab: boolean = false): void {
    this.navigateParentChild(NotificationTypeEditComponent.PAGE_ID, { id: notificationTypeDto.id }, inNewTab);
  }

  private addLoopbackToFormArray(loopback: LoopbackConfigurationDto): void {
    this.loopbacksFormArray.push(
      this.formBuilder.group({
        gateway: [loopback.gateway || null],
        recipient: [loopback.recipient || null, Validators.required],
        passEnabled: [loopback.passEnabled !== null ? loopback.passEnabled : false],
      })
    );
  }

  private isAllFormsValid(): boolean {
    FormUtils.validateAllFormFields(this.gatewayForm);
    FormUtils.validateAllFormFields(this.loopbacksFormGroup);
    const isValid = this.gatewayForm.valid && this.loopbacksFormGroup.valid;
    if (!isValid) {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.notification.validation.error');
    }
    return isValid;
  }

  private updateModels(): void {
    this.updateModelFromFormGroup(this.gatewayDto, this.gatewayForm);

    this.gatewayDto.loopbacks = [];
    this.loopbacksFormArray.controls.forEach((loopbackForm: FormGroup) => {
      const loopback = loopbackForm.getRawValue() as LoopbackConfigurationDto;
      if (loopback.recipient) {
        this.gatewayDto.loopbacks.push({
          gateway: loopback.gateway,
          recipient: loopback.recipient,
          passEnabled: loopback.passEnabled,
        });
      }
    });
  }

  private updateModelFromFormGroup(model: any, form: FormGroup): void {
    Object.keys(form.controls).forEach((field: string) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        model[field] = control.value;
      } else if (control instanceof FormGroup) {
        this.updateModelFromFormGroup(model[field], control);
      }
    });
  }

  private refreshPage(): void {
    this.setGatewayDto(this.gatewayDto.id, true);
  }

  private loadNotificationTypes(code: string): void {
    if (!code) {
      return;
    }

    this.appBlockerService.block();
    this.notificationTypesSearch.filtering = [
      {
        column: 'notificationTypeGateways.gateway',
        compareType: 'EQUAL',
        value: code,
      },
    ];
    this.adminNotificationTypeService
      .filterNotificationTypes(this.notificationTypesSearch)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PagedNotificationTypesDto) => {
        this.pagedNotificationTypesDto = result;
      });
  }

  private checkEditableCode(): void {
    if (!this.codeFormControl.value) {
      return;
    }

    this.appBlockerService.block();
    const search: Search = {
      filtering: [{ column: 'gateway', compareType: 'EQUAL', value: this.codeFormControl.value }],
      sorting: [],
      paging: {
        page: 1,
        pageSize: 1,
      },
    };
    const serializedFilter = JSON.stringify(search.filtering);
    const serializedSorting = ServiceUtils.getSerializedSorting(search.sorting);

    this.notificationFrontendService
      .filterNotifications(
        null,
        serializedFilter,
        serializedSorting,
        search.paging.page,
        search.paging.pageSize,
        search.fields
      )
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PagedNotificationsDto) => {
        if (result?.data && result.data.length > 0) {
          this.codeFormControl.disable();
        } else {
          search.filtering = [
            {
              column: 'notificationTypeGateways.gateway',
              compareType: 'EQUAL',
              value: this.codeFormControl.value,
            },
          ];
          this.adminNotificationTypeService
            .filterNotificationTypes(search)
            .pipe(finalize(this.appBlockerService.unblock))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((result: PagedNotificationTypesDto) => {
              if (result?.data && result.data.length > 0) {
                this.codeFormControl.disable();
              } else {
                this.codeFormControl.enable();
              }
            });
        }
      });
  }
}
