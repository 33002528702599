import { Pipe, PipeTransform } from '@angular/core';
import { RuleSetDto } from '@btl/admin-bff';

/**
 * Pipe for transforming Array<RuleSetDto> to formatted text
 */
@Pipe({
  name: 'dcrRule',
  pure: false,
})
export class DcrRulePipe implements PipeTransform {
  transform(ruleSets: string[], ruleSetDtos: Array<RuleSetDto>, charLimit: number = null): any {
    const expressions = ruleSets.map(
      ruleSet => ruleSetDtos.find(ruleSetDto => ruleSet === ruleSetDto.id).ruleSetExpression
    );
    const expressionsString = expressions.join(' OR ');

    if (!charLimit || expressionsString.length <= charLimit) {
      return expressionsString;
    } else {
      return `${expressionsString.substring(0, charLimit)}...`;
    }
  }
}
