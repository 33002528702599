<div class="main__header" *ngIf="!isModal">
  <div class="header-box">
    <div class="icon-box CMS">
      <mat-icon svgIcon="notificationsTypes"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.notification.templates' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      *ngIf="aclService.pageEditAccess"
      (click)="newType()"
      [routerLink]=""
      mat-raised-button
      color="primary"
      class="btn -primary -xs"
      >{{ 'wc.common.new' | translate }}</a
    >
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-notification-type-filter
        (filterChanged)="handleFilterChange()"
        [filtering]="search.filtering"
        [cacheFilterUsed]="cacheFilterUsed"
        [parametersList]="parametersList">
      </app-notification-type-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.notification.templateListings' | translate }}</span>
          <div class="paginator__entries" *ngIf="pagedNotificationTypesDto">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedNotificationTypesDto?.totalItems
                          ? pagedNotificationTypesDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedNotificationTypesDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action" *ngIf="!isModal">
          <button
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedTypes()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="[
            'notificationType',
            'remark',
            'productionEnabled',
            'sendingEnabled',
            'created',
            'modified',
            'modifiedBy'
          ]"
          [dontShowOnMobile]="['productionEnabled', 'sendingEnabled', 'created', 'modified', 'modifiedBy']"
          [columnToTruncate]="['notificationType;10vw', 'remark;14vw', 'modifiedBy', 'productionEnabled;30px', 'sendingEnabled;30px']"
          [fillIdBy]="'id'"
          [pagedDto]="pagedNotificationTypesDto"
          [editable]="aclService.pageEditAccess"
          [disableActions]="disableActions"
          [cacheFilter]="search"
          (editButtonEvent)="editType($event)"
          (editInNewTabButtonEvent)="editType($event, true)"
          (doubleClickEvent)="!isModal ? editType($event) : singleSelect($event)"
          (deleteButtonEvent)="deleteData($event)"
          (pageSizeEvent)="pageSizeChanged($event)"
          (sortChangeEvent)="onSortChange($event)"
          [selectMode]="selectMode"
          [translationPrefix]="'wc.admin.notification.type'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
