import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SocketDto } from '@btl/admin-bff/model/socketDto';

@Component({
  selector: 'app-socket-contents,[app-socket-contents]',
  templateUrl: './socket-contents.component.html',
  styleUrls: ['./socket-contents.component.scss'],
})
export class SocketContentsComponent implements OnDestroy {
  @Input()
  socket: SocketDto;

  @Input()
  parentId = '';

  private onDestroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
