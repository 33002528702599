import { Component, Input, ViewChild } from '@angular/core';
import { Search } from '@btl/btl-fe-wc-common';
import { DmsFilesListingComponent } from '@components/dms-files-listing/dms-files-listing.component';
import { Filter } from '@btl/btl-fe-wc-common/lib/models/search';

@Component({
  selector: 'app-dms-files-select-modal',
  templateUrl: './dms-files-select-modal.component.html',
  styleUrls: ['./dms-files-select-modal.component.scss'],
})
export class DmsFilesSelectModalComponent {
  @ViewChild(DmsFilesListingComponent, { static: true })
  fileListingComponent: DmsFilesListingComponent;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  @Input()
  selectMode;

  multiSelectMode = false;

  singleSelectMode = true;

  constantFilter: Array<Filter> = [];

  excludefileIds = [];

  activeMultiSelectMode() {
    this.multiSelectMode = true;
    this.singleSelectMode = false;
  }

  selectEvent() {
    if (this.singleSelectMode) {
      const fileDto = this.fileListingComponent.getSelectedDmsFiles()[0];
      if (fileDto) {
        this.selectHandler(fileDto);
        this.dialogRef.close();
      }
    } else {
      const fileDtos = this.fileListingComponent.getSelectedDmsFiles();
      if (fileDtos && fileDtos.length > 0) {
        this.selectHandler(fileDtos);
        this.dialogRef.close();
      }
    }
  }

  singleSelect(selectedDto) {
    this.selectHandler(selectedDto);
    this.dialogRef.close();
  }

  getSearch(): Search {
    return this.fileListingComponent.search;
  }

  removeSelection() {
    if (this.singleSelectMode) {
      this.selectHandler(null);
    } else {
      this.selectHandler([]);
    }
    this.dialogRef.close();
  }

  addToConstantFilter(filter: Filter) {
    this.constantFilter.push(filter);
  }

  cancel() {
    this.dialogRef.close();
  }
}
