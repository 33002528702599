import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cms-content-preview-modal',
  templateUrl: './cms-content-preview-modal.component.html',
  styleUrls: ['./cms-content-preview-modal.component.scss'],
})
export class CmsContentPreviewModalComponent {
  @Input()
  dialogRef;

  @Input()
  previewUrl: string;

  @Output()
  readonly cancelPreviewEvent: EventEmitter<any> = new EventEmitter();

  cancel(): void {
    this.cancelPreviewEvent.emit();
  }
}
