import { Component, Input, ViewChild } from '@angular/core';
import { PromotionListComponent } from '@components/product-catalogue/promotion/list/promotion-list.component';
import { Search } from '@btl/btl-fe-wc-common';
import {
  TicketStepTemplateListComponent,
} from '@components/tickets/ticket-step-template/list/ticket-step-template-list.component';

@Component({
  selector: 'app-step-template-select-modal',
  templateUrl: './step-template-select-modal.component.html',
  styleUrls: ['./step-template-select-modal.component.scss'],
})
export class StepTemplateSelectModalComponent {
  @ViewChild(PromotionListComponent, { static: true })
  ticketStepTemplateListComponent: TicketStepTemplateListComponent;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  @Input()
  singleSelectMode = true;

  @Input()
  disableActions = false;

  @Input()
  selectMode = false;

  excludePromotionIds = [];

  selectEvent() {
    if (this.singleSelectMode) {
      const stepTemplateDto = this.ticketStepTemplateListComponent.getSelectedData()[0];
      if (stepTemplateDto) {
        this.selectHandler(stepTemplateDto);
        this.dialogRef.close();
      }
    }
  }

  selectNothingEvent() {
    this.selectHandler(null);
    this.dialogRef.close();
  }

  singleSelect(selectedDto) {
    this.selectHandler(selectedDto);
    this.dialogRef.close();
  }

  getSearch(): Search {
    return this.ticketStepTemplateListComponent.search;
  }
}
