
    <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxLayoutAlign="center">
        <span>
          {{ 'wc.admin.productGroups.' + subsetName | translate }}
          <mat-icon
            [matTooltip]="'wc.admin.productGroups.topProducts.infoTooltip' | translate"
            class="mat-info-tooltip"
            [matTooltipPosition]="'above'"
            svgIcon="infoTooltip"></mat-icon>
        </span>
      </div>
      <div fxLayoutGap="10px" class="listing-action">
        <button
          id="001_group_top_products"
          color="primary"
          mat-raised-button
          [routerLink]=""
          (click)="copySubProducts()"
          class="btn -primary -xs edit"
          [disabled]="selectedSubProductItems?.length === 0">
          <mat-icon class="mat-icon-btn-copy" svgIcon="duplicate"></mat-icon>
          {{ 'wc.admin.productGroups.topProducts.copy' | translate }}
        </button>
      </div>
    </div>

    <div class="card__body">
      <div class="form__row" cdkDropListGroup>
        <mat-form-field class='form__field -chips-form-field' appearance='outline' floatLabel='always'>
          <mat-label>{{ 'wc.admin.productGroups.' + subsetName + '.label' | translate }}</mat-label>
          <mat-chip-grid #chipList class="-chip-list">
            <ng-container *ngFor="let chipItem of selectedSubProductItems.slice(0, maxSubProducts); let i = index">
              <div cdkDropList [cdkDropListData]="{ item: chipItem, index: i }" (cdkDropListDropped)="drop($event)">
                <mat-chip-row
                  cdkDrag
                  class="-chip-item"
                  color="primary"
                  [id]="chipItem.productCode"
                  [value]="chipItem.productCode"
                  [matTooltip]="chipItem.productCode"
                  [ngClass]="{
                    '-duplicity': chipItem.isDuplicity,
                    '-new-in-group': chipItem.isNewInGroup && !chipItem.isDuplicity
                  }"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="removeSubProductCode(i)">
                  <mat-icon svgIcon="dragAndDrop" *ngIf="removable" class="mat-chip-drag-and-drop"></mat-icon>
                  <span class="mat-chip-label">{{ chipItem.productLabel }}</span>
                  <mat-icon
                    *ngIf="chipItem.isDuplicity"
                    class="mat-chip-duplicity"
                    [matTooltip]="'wc.admin.productGroups.topProducts.duplicityTooltip' | translate"
                    [matTooltipPosition]="'above'"
                    svgIcon="duplicity"></mat-icon>
                  <mat-icon
                    id="002_group_top_products"
                    *ngIf="chipItem.isNewInGroup && !chipItem.isDuplicity"
                    class="mat-chip-agree"
                    svgIcon="accept"
                    (click)="acceptNewProduct(chipItem.productCode)"></mat-icon>
                  <mat-icon matChipRemove svgIcon="close" *ngIf="removable"></mat-icon>
                </mat-chip-row>
              </div>
            </ng-container>

            <input
              placeholder="{{
                (selectedSubProductItems?.length === 0
                  ? 'wc.admin.productGroups.topProducts.placeholder'
                  : '') | translate
              }}"
              #topProductsInput
              [formControl]="topProductsControl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addSubProductCode($event)"
              (input)="onInputChange($event.target.value)"
              (paste)="onPaste($event)" />

            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selected($event)"
              id="005_group_top_products"
              class="mat-chips-autocomplete"
              [style.left.px]="75">
              <mat-option
                *ngFor="let product of hintProducts"
                [value]="product"
                [id]="product.productCode"
                [innerHTML]="highlightAutocomplete(product.productLabel + ' ... ' + product.productCode) | safeHtml">
              </mat-option>
            </mat-autocomplete>
          </mat-chip-grid>
        </mat-form-field>
      </div>

      <div class="buttons">
        <a
          [routerLink]=""
          *ngIf="existsAnyDuplicityItem()"
          id="003_group_top_products"
          (click)="clearSubProductItems(true)"
          class="btn cancel-button"
          >{{ 'wc.admin.productGroups.topProducts.clearDuplicities' | translate }}</a
        >
        <a
          [routerLink]=""
          *ngIf="selectedSubProductItems?.length > 0"
          id="004_group_top_products"
          (click)="clearSubProductItems()"
          class="btn cancel-button"
          >{{ 'wc.admin.productGroups.topProducts.clearAll' | translate }}</a
        >
      </div>
    </div>
