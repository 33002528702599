import { Component, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminCodebookService,
  AdminDynamicEnumService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EnumEntryDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '../../../../service/confirmation-dialog.service';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import { CodebookTypeListComponent } from '@components/codebook/codebookType/codebook-type-list.component';

@Component({
  selector: 'app-codebook-type-edit',
  templateUrl: './codebook-type-edit.component.html',
  styleUrls: ['./codebook-type-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: CodebookTypeEditComponent.PAGE_ID })
export class CodebookTypeEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'CodebookTypeEditComponent';

  pageId(): string {
    return CodebookTypeEditComponent.PAGE_ID;
  }

  readonly SOURCE_NAME = EnumerationsService.CODEBOOK;
  readonly ENUM_NAME = 'com.emeldi.ecc.be.cb.enums.CodebookType';

  routerOutlet: any;

  editCodebookTypeChanged = new EventEmitter<EnumEntryDto>();

  private codebookType: EnumEntryDto = {
    name: null,
    fields: null,
  };

  public codebookTypeParameters: string[];

  private codebookTypeName: string;
  public isNew = true;

  codebookTypeForm: FormGroup = this.formBuilder.group({
    name: [null, [Validators.required, Validators.maxLength(200)]],
  });

  get nameControl(): AbstractControl {
    return this.codebookTypeForm.get('name');
  }

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private adminCodebookService: AdminCodebookService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  loadCodebookType(codebookTypeName: string) {
    this.adminDynamicEnumService
      .getEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, codebookTypeName, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setCodebookType(result, false);
        this.codebookTypeName = this.codebookType.name;
      });
    this.adminCodebookService
      .getAggregatedCodebookTypeParameters(codebookTypeName)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.codebookTypeParameters = result;
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const codebookTypeId = this.params.id;
      if (codebookTypeId) {
        if (codebookTypeId === '&') {
          this.setCodebookType(this.codebookType, true);
        } else {
          this.loadCodebookType(codebookTypeId);
        }
      }
    } else {
      this.codebookType = undefined;
    }
  }

  setCodebookType(enumEntryDto: EnumEntryDto, isNew: boolean) {
    this.codebookType = enumEntryDto;
    this.codebookTypeForm.patchValue(this.codebookType);

    if (enumEntryDto) {
      this.editCodebookTypeChanged.emit(this.codebookType);
    }

    this.isNew = isNew;
    if (!this.isNew) {
      this.nameControl.disable();
    } else {
      this.nameControl.enable();
    }
  }

  saveCodebookType(): boolean {
    FormUtils.validateAllFormFields(this.codebookTypeForm);
    if (this.codebookTypeForm.valid) {
      const codebookType = this.codebookType;
      if (codebookType) {
        Object.keys(this.codebookTypeForm.controls).forEach(field => {
          const control = this.codebookTypeForm.get(field);
          codebookType[field] = control.value;
        });
        if (codebookType.fields) {
          codebookType.fields['name'] = codebookType.name;
        }
      }
      return true;
    }
    return false;
  }

  save() {
    if (this.saveCodebookType()) {
      this.appBlockerService.block();
      if (!(this.codebookTypeName === null || this.codebookTypeName === undefined)) {
        this.adminDynamicEnumService
          .updateEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, this.codebookTypeName, this.codebookType)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getCodebookTypeHandler);
      } else {
        this.adminDynamicEnumService
          .createEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, this.codebookType)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getCodebookTypeHandler);
      }
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditCodebookType(): EnumEntryDto {
    return this.codebookType;
  }

  public reset() {
    if (this.codebookType && this.codebookType.name) {
      this.appBlockerService.block();
      this.adminDynamicEnumService
        .getEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, this.codebookType.name, true)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(this.getCodebookTypeHandler);
    } else {
      this.codebookTypeForm.reset(this.codebookTypeForm);
      this.setCodebookType(this.codebookType, this.isNew);
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.codebookType.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminDynamicEnumService
        .deleteEnumEntry(this.SOURCE_NAME, this.ENUM_NAME, this.codebookType.name)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(CodebookTypeListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  public duplicate() {
    const newCodebookType = this.duplicateEnumEntryDto(this.codebookType);
    this.setCodebookType(newCodebookType, true);
    this.codebookTypeName = null;
    this.navigateSelf({ id: '&' });
  }

  public getCodebookTypeHandler = (enumEntryDto: EnumEntryDto): void => {
    let saving = false;
    if (this.codebookType) {
      saving = true;
    }
    if (enumEntryDto && this.codebookType) {
      if (enumEntryDto.name === this.codebookType.name) {
        this.setCodebookType(enumEntryDto, this.isNew);
        this.navigateSelf({ id: enumEntryDto.name });
      } else {
        this.setCodebookType(enumEntryDto, this.isNew);
        this.codebookTypeName = enumEntryDto.name;
        this.navigateSelf({ id: enumEntryDto.name });
      }
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  cancel() {
    this.navigateSibling(CodebookTypeListComponent.PAGE_ID);
  }

  duplicateEnumEntryDto(enumEntryDto: EnumEntryDto): EnumEntryDto {
    return {
      name: `${enumEntryDto.name}-copy`,
      fields: this.duplicateDictionary(enumEntryDto.fields),
    };
  }

  duplicateDictionary(dictionary: { [key: string]: any }): { [key: string]: any } {
    if (!dictionary) {
      return null;
    }
    const newDictionary: { [key: string]: any } = {};
    Object.keys(dictionary).forEach(key => {
      newDictionary[key] = dictionary[key];
    });
    return newDictionary;
  }
}
