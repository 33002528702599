import { Component, Input } from '@angular/core';
import {
  AclService,
  AdminMetaProfileService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { MetaProfileDto, MetaProfileServicesDto, PagedMetaProfilesDto } from '@btl/admin-bff';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MetaProfileComponent } from '../meta-profile.component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { MetaProfileEditComponent } from '@components/meta-description/meta-profile/edit/meta-profile-edit.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-meta-profile-list',
  templateUrl: './meta-profile-list.component.html',
  styleUrls: ['./meta-profile-list.component.scss'],
})
@EnableDynamicLoading({ customName: MetaProfileListComponent.PAGE_ID })
export class MetaProfileListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'MetaProfileListComponent';

  pageId(): string {
    return MetaProfileListComponent.PAGE_ID;
  }

  public static readonly PROFILE_MODULE = 'metaProfileModule';

  pagedProfilesDto: PagedMetaProfilesDto;

  modules: string[] = Object.values(MetaProfileServicesDto);

  @Input()
  module = this.modules[0];

  moduleForm: FormGroup;

  constructor(
    private adminMetaProfileService: AdminMetaProfileService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    private formBuilder: FormBuilder,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
    this.moduleForm = this.formBuilder.group({
      module: [this.module],
    });

    this.search.fields = [
      'totalItems',
      'totalPages',
      'pageSize',
      'currentPage',
      'data.id',
      'data.name',
      'data.created',
      'data.modified',
      'data.modifiedBy',
    ];
  }

  ngOnInit() {
    if (this.cacheFilter) {
      const module = sessionStorage.getItem(MetaProfileListComponent.PROFILE_MODULE);
      if (module) {
        this.module = module;
      }
    }

    super.ngOnInit();
  }

  editProfile(profileDto, inNewTab: boolean = false) {
    this.navigateSibling(MetaProfileEditComponent.PAGE_ID, { module: this.module, id: profileDto.id }, inNewTab);
  }

  newProfile() {
    this.navigateSibling(MetaProfileEditComponent.PAGE_ID, { module: this.module, id: '&' });
  }

  duplicateProfile(profileDto: MetaProfileDto, refresh?: boolean) {
    this.appBlockerService.block();
    this.adminMetaProfileService
      .createMetaProfile(this.module, MetaProfileComponent.duplicateProfile(profileDto))
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        if (refresh !== undefined) {
          if (refresh) {
            this.loadData();
            this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          }
        }
      });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      if (this.cacheFilter) {
        sessionStorage.setItem(MetaProfileListComponent.PROFILE_MODULE, this.module);
      }

      this.adminMetaProfileService
        .filterMetaProfiles(this.module, this.search)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          if (result) {
            this.pagedProfilesDto = result;
            if (this.pagedProfilesDto && !this.pagedProfilesDto.data) {
              this.pagedProfilesDto.data = [];
            }
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminMetaProfileService.bulkDeleteMetaProfiles(this.module, bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminMetaProfileService.deleteMetaProfile(this.module, rowDataId);
  }

  setModule(value) {
    this.module = value;
  }
}
