import { Component } from '@angular/core';

@Component({
  selector: 'app-ticket-type-parameters',
  template: '<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>',
})
export class TicketTypeParametersComponent {
  routerOutlet: any;

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }
}
