<div class="page__wrapper">
  <div class="main__header">
    <h1>{{ 'wc.admin.resource.resources.edit' | translate }}</h1>
    <div class="page__menu">
      <a
        color="primary"
        mat-raised-button
        (click)="save()"
        [routerLink]=""
        class="btn -primary -xs icon stroke currentColor"
        *ngIf="aclService.pageEditAccess"
        ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
      >
      <a
        color="primary"
        mat-stroked-button
        *ngIf="aclService.pageEditAccess"
        [routerLink]=""
        class="btn -primary -xs more-actions icon dropDownIcon currentColor"
        [matMenuTriggerFor]="menu"
        #t="matMenuTrigger"
        >{{ 'wc.common.more.actions.button' | translate }}
        <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
      </a>
      <mat-menu #menu="matMenu" class="module-actions">
        <a mat-menu-item *ngIf="resourceDto?.id" (click)="duplicate()" [routerLink]="">{{
          'wc.common.duplicate' | translate
        }}</a>
        <a mat-menu-item (click)="reset()" [routerLink]="">{{ 'wc.common.reset' | translate }}</a>
        <a mat-menu-item *ngIf="resourceDto?.id" (click)="delete()" [routerLink]="">{{
          'wc.common.delete' | translate
        }}</a>
      </mat-menu>
      <a (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
    </div>
  </div>

  <div class="card__wrapper" *ngIf="resourceDto">
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.resource.resources.coreAttributes' | translate }}
        </div>
        <div class="card__body">
          <form [formGroup]="resourceForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="resourceForm"
                    [controlName]="'id'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.resource.resources'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="resourceForm"
                    [controlName]="'name'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.resource.resources'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'type'"
                    [options]="resourceTypes"
                    [showEmptyOption]="true"
                    [translationPrefix]="'wc.admin.resource.resources'">
                  </app-input-select-form-field>
                </div>

                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'category'"
                    [options]="resourceCategories"
                    [labelOption]="'label'"
                    [valueOption]="'value'"
                    [showEmptyOption]="true"
                    [translationPrefix]="'wc.admin.resource.resources'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <app-input-date-form-field
                    [parentGroup]="resourceForm"
                    [controlName]="'startOperatingDate'"
                    [translationPrefix]="'wc.admin.resource.resources'">
                  </app-input-date-form-field>
                </div>
                <div class="form__row">
                  <app-input-date-form-field
                    [parentGroup]="resourceForm"
                    [controlName]="'endOperatingDate'"
                    [translationPrefix]="'wc.admin.resource.resources'">
                  </app-input-date-form-field>
                </div>

                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'resourceStatus'"
                    [options]="resourceStates"
                    [showEmptyOption]="true"
                    [translationPrefix]="'wc.admin.resource.resources'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'operationalState'"
                    [options]="operationStates"
                    [showEmptyOption]="true"
                    [translationPrefix]="'wc.admin.resource.resources'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">&nbsp;</div>

                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'usageState'"
                    [options]="usagesStates"
                    [showEmptyOption]="true"
                    [translationPrefix]="'wc.admin.resource.resources'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    [formControlName]="'administrativeState'"
                    [options]="adminStates"
                    [showEmptyOption]="true"
                    [translationPrefix]="'wc.admin.resource.resources'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">&nbsp;</div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="card__inner">
      <div class="card__header">
        <div fxLayout="row" fxLayoutAlign="space-between">
          {{ 'wc.admin.resource.resources.description' | translate }}
        </div>
      </div>
      <div class="card__body">
        <form [formGroup]="resourceForm" class="form">
          <div class="form__groups">
            <div class="form__group -full">
              <div class="form__row">
                <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                  <mat-label>{{ 'wc.admin.resource.resources.description' | translate }}</mat-label>
                  <textarea class="form__input" matInput type="text" formControlName="description" rows="4"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.resource.resources.placeDetail' | translate }}
      </div>
      <div class="card__body">
        <form [formGroup]="resourcePlaceForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="resourcePlaceForm"
                  [controlName]="'id'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.resource.resources.place'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="resourcePlaceForm"
                  [controlName]="'name'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.resource.resources.place'">
                </app-custom-input>
              </div>
              <div class="form__row">&nbsp;</div>

              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'role'"
                  [inputType]="'codebook'"
                  [codebookType]="'RESOURCE_PLACE_ROLES'"
                  [showEmptyOption]="true"
                  [translationPrefix]="'wc.admin.resource.resources.place'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'referredType'"
                  [label]="'type'"
                  [inputType]="'codebook'"
                  [codebookType]="'RESOURCE_PLACE_TYPES'"
                  [showEmptyOption]="true"
                  [translationPrefix]="'wc.admin.resource.resources.place'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">&nbsp;</div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.resource.resources.parameters' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            color="primary"
            mat-raised-button
            (click)="addEditResourceCharacteristics()"
            [routerLink]=""
            class="add">
            <mat-icon style="font-size: 18px; line-height: 24px">add</mat-icon>
            {{ 'wc.admin.resource.resources.parameter.add' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <form
          *ngIf="resourceCharacteristicsFormArray?.controls?.length"
          [formGroup]="resourceCharacteristicsForm"
          class="form">
          <div class="form__groups" formArrayName="resourceCharacteristics">
            <div
              class="form__group -full"
              *ngFor="let paramForm of resourceCharacteristicsFormArray.controls; let i = index">
              <div
                fxLayout="row"
                style="column-gap: 20px; padding: 15px; border: 1px solid #2028301a; border-radius: 5px">
                <div style="display: flex; flex: 1 1 auto; column-gap: 30px">
                  <app-custom-input
                    [parentGroup]="paramForm"
                    [controlName]="'name'"
                    [inputType]="'text'"
                    [disabled]="true"
                    [translationPrefix]="'wc.admin.resource.resources.parameter'">
                  </app-custom-input>

                  <app-custom-input
                    [parentGroup]="paramForm"
                    [controlName]="'value'"
                    [inputType]="'text'"
                    [disabled]="true"
                    [translationPrefix]="'wc.admin.resource.resources.parameter'">
                  </app-custom-input>
                </div>
                <div style="display: flex; align-items: center">
                  <mat-icon
                    (click)="addEditResourceCharacteristics(i)"
                    style="font-size: 18px; line-height: 24px; cursor: pointer; margin-right: 5px"
                    >edit</mat-icon
                  >
                  <mat-icon
                    (click)="deleteResourceCharacteristics(i)"
                    style="font-size: 18px; line-height: 24px; cursor: pointer"
                    >delete</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.resource.resources.partyRelations' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button color="primary" mat-raised-button (click)="addPartyRelation()" [routerLink]="" class="add">
            <mat-icon style="font-size: 18px; line-height: 24px">add</mat-icon>
            {{ 'wc.admin.resource.resources.partyRelation.add' | translate }}
          </button>
          <button
            color="primary"
            mat-raised-button
            [disabled]="getSelectedPartyRelation().length === 0"
            (click)="deleteCheckedPartyRelation()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="delete">
            <mat-icon style="font-size: 18px; line-height: 24px">delete</mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body hide-new-tab-edit">
        <app-table-listing-form
          [columnName]="['id', 'name', 'role', 'referredType']"
          [pagedDto]="pagedRelatedParties"
          [editable]="aclService.pageEditAccess"
          [enablePaginator]="false"
          (editButtonEvent)="editPartyRelation($event)"
          (doubleClickEvent)="editPartyRelation($event)"
          (deleteButtonEvent)="deletePartyRelation($event)"
          [translationPrefix]="'wc.admin.resource.resources.partyRelation'">
        </app-table-listing-form>
      </div>
    </div>

    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.resource.resources.resourceRelationships' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button color="primary" mat-raised-button (click)="addResourceRelationship()" [routerLink]="" class="add">
            <mat-icon style="font-size: 18px; line-height: 24px">add</mat-icon>
            {{ 'wc.admin.resource.resources.partyRelation.add' | translate }}
          </button>
          <button
            color="primary"
            mat-raised-button
            [disabled]="getSelectedResourceRelationship().length === 0"
            (click)="deleteCheckedResourceRelationship()"
            *ngIf="aclService.pageEditAccess"
            class="delete">
            <mat-icon style="font-size: 18px; line-height: 24px">delete</mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body hide-new-tab-edit">
        <app-table-listing-form
          #relationshipTable
          [columnName]="['relationshipType', 'id', 'name', 'type', 'direction']"
          [pagedDto]="pagedResourceRelationships"
          [editable]="aclService.pageEditAccess"
          [enablePaginator]="false"
          (editButtonEvent)="editResourceRelationship($event)"
          (doubleClickEvent)="editResourceRelationship($event)"
          (deleteButtonEvent)="deleteResourceRelationship($event)"
          [translationPrefix]="'wc.admin.resource.resources.resourceRelationship'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
