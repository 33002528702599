<h2 mat-dialog-title>
  {{ 'wc.admin.ratings.review.reviewEdit' | translate }}
    <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content class="modal-body">
  <div class=".mat-drawer-container">
    <div class="mat-sidenav-content">
      <div class="main_content">
        <div class="card__wrapper">
          <!-- card -->
          <div class="card -full">
            <div class="card__inner">
              <div class="card__header">
                {{ 'wc.admin.ratings.review.attributes' | translate }}
              </div>
              <div class="card__body">
                <form *ngIf="this.review" [formGroup]="reviewForm" class="form">
                  <div class="form__groups">
                    <div class="form__group -flex">
                      <div class="form__row">
                        <app-custom-input
                          [parentGroup]="reviewForm"
                          id="006_product_ratings_review_edit"
                          [controlName]="'id'"
                          [inputType]="'text'"
                          [translationPrefix]="'wc.admin.ratings.review'">
                        </app-custom-input>
                      </div>
                      <div class="form__row">
                        <app-custom-input
                          [parentGroup]="reviewForm"
                          id="008_product_ratings_review_edit"
                          [controlName]="'extId'"
                          [inputType]="'text'"
                          [translationPrefix]="'wc.admin.ratings.review'">
                        </app-custom-input>
                      </div>
                      <div class="form__row">
                        <app-input-date-form-field
                          [parentGroup]="reviewForm"
                          [controlName]="'reviewedOn'"
                          id="009_product_ratings_review_edit"
                          [translationPrefix]="'wc.admin.ratings.review'">
                        </app-input-date-form-field>
                      </div>
                      <div class="form__row">
                        <app-custom-input
                          [parentGroup]="reviewForm"
                          [controlName]="'rating'"
                          id="010_product_ratings_review_edit"
                          [inputType]="'text'"
                          [translationPrefix]="'wc.admin.ratings.review'">
                        </app-custom-input>
                      </div>
                      <div class="form__row">
                        <app-input-select-form-field
                          [translationPrefix]="'wc.admin.ratings'"
                          [yesNoOptions]="true"
                          labelOption="labelKey"
                          valueOption="value"
                          id="011_product_ratings_review_edit"
                          [formControlName]="'recommended'">
                        </app-input-select-form-field>
                      </div>
                      <div class="form__row">
                        <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                          <mat-label>{{ 'wc.admin.ratings.review.reviewSummary' | translate }}</mat-label>
                          <textarea
                            id="012_product_ratings_review_edit"
                            class="form__input"
                            matInput
                            type="text"
                            formControlName="reviewSummary"></textarea>
                        </mat-form-field>
                      </div>
                      <div class="form__row">
                        <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                          <mat-label>{{ 'wc.admin.ratings.review.reviewPros' | translate }}</mat-label>
                          <textarea
                            id="013_product_ratings_review_edit"
                            class="form__input"
                            matInput
                            type="text"
                            formControlName="reviewPros"></textarea>
                        </mat-form-field>
                      </div>
                      <div class="form__row">
                        <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                          <mat-label>{{ 'wc.admin.ratings.review.reviewCons' | translate }}</mat-label>
                          <textarea
                            id="014_product_ratings_review_edit"
                            class="form__input"
                            matInput
                            type="text"
                            formControlName="reviewCons"></textarea>
                        </mat-form-field>
                      </div>
                      <div class="form__row">
                        <app-input-date-form-field
                          [parentGroup]="reviewForm"
                          [controlName]="'created'"
                          id="015_product_ratings_review_edit"
                          [translationPrefix]="'wc.admin.ratings.review'">
                        </app-input-date-form-field>
                      </div>
                      <div class="form__row">
                        <app-custom-input
                          [parentGroup]="reviewForm"
                          [controlName]="'createdBy'"
                          id="016_product_ratings_review_edit"
                          [inputType]="'text'"
                          [translationPrefix]="'wc.admin.ratings.review'">
                        </app-custom-input>
                      </div>
                      <div class="form__row"></div>
                      <div class="form__row">
                        <app-input-date-form-field
                          [parentGroup]="reviewForm"
                          [controlName]="'modified'"
                          id="017_product_ratings_review_edit"
                          [translationPrefix]="'wc.admin.ratings.review'">
                        </app-input-date-form-field>
                      </div>
                      <div class="form__row">
                        <app-custom-input
                          [parentGroup]="reviewForm"
                          [controlName]="'modifiedBy'"
                          id="018_product_ratings_review_edit"
                          [inputType]="'text'"
                          [translationPrefix]="'wc.admin.ratings.review'">
                        </app-custom-input>
                      </div>
                      <div class="form__row"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a
    id="002_product_ratings_review_edit"
    color="primary"
    mat-raised-button
    (click)="save()"
    *ngIf="aclService.pageEditAccess"
    [routerLink]=""
    class="btn -primary -xs icon fill no-stroke">
    <mat-icon svgIcon="widgetConfirm"></mat-icon>
    {{ 'wc.common.confirm' | translate }}
  </a>
  <a
    id="003_product_ratings_review_edit"
    color="primary"
    mat-raised-button
    (click)="reset()"
    *ngIf="aclService.pageEditAccess"
    [routerLink]=""
    class="btn -primary -xs icon fill no-stroke">
    <mat-icon svgIcon="reset"></mat-icon>
    {{ 'wc.common.reset' | translate }}
  </a>
  <a
    id="004_product_ratings_review_edit"
    color="primary"
    mat-raised-button
    (click)="delete()"
    *ngIf="aclService.pageEditAccess && review.recordVersion"
    [routerLink]=""
    class="btn -primary -xs icon fill no-stroke">
    <mat-icon svgIcon="delete"></mat-icon>
    {{ 'wc.common.delete' | translate }}
  </a>
  <a
    id="005_product_ratings_review_edit"
    color="primary"
    mat-raised-button
    (click)="cancel()"
    [routerLink]=""
    class="btn -primary -xs">
    {{ 'wc.common.cancel.button' | translate }}
  </a>
</mat-dialog-actions>
