import { Component, ViewEncapsulation } from '@angular/core';
import {
  AbstractFilterComponent,
  AdminDynamicEnumService,
  AdminStoreService,
  CompareType,
  ServiceUtils,
} from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { EnumEntriesDto, StoreDto } from '@btl/admin-bff';
import { takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-stores-filter',
  templateUrl: './stores-filter.component.html',
  animations: [Animations.advancedFilterArrow],
  encapsulation: ViewEncapsulation.None,
})
export class StoresFilterComponent extends AbstractFilterComponent {
  PickerInputType = PickerInputType;

  storeTypes: EnumEntriesDto;
  storeRoles: StoreDto[] = [];

  mapFieldWithCompareType = new Map([
    ['id', CompareTypeDtoEnum.EQUAL],
    ['type', CompareTypeDtoEnum.LIKE],
    ['extId', CompareTypeDtoEnum.LIKE],
    ['ouRefNo', CompareTypeDtoEnum.LIKE],
  ]);

  mapFieldWithColumnName = new Map([['id', 'id']]);

  filterForm = this.formBuilder.group({
    id: [],
    type: [],
    extId: [],
    ouRefNo: [],
  });

  constructor(
    private formBuilder: FormBuilder,
    private adminStoreService: AdminStoreService,
    private adminDynamicEnumService: AdminDynamicEnumService
  ) {
    super();

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.STOCK, 'com.emeldi.ecc.be.stock.enums.StoreType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.storeTypes = result;
        this.storeTypes.data = this.storeTypes.data.sort((a, b) => a.name.localeCompare(b.name));
      });

    const search = ServiceUtils.getUnlimitedSearch();
    search.sorting.push({ column: 'id', sortOrder: 'asc' });
    this.adminStoreService.filterStores(search).subscribe(result => {
      this.storeRoles = result.data;
    });
  }
}
