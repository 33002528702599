import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AdminPromotionService, AppBlockerService, CompareType, Search } from '@btl/btl-fe-wc-common';
import { PromotionsSelectModalComponent } from '../promotions-select-modal/promotions-select-modal.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PromotionDto } from '@btl/admin-bff';
import { MatDialog } from '@angular/material/dialog';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-promotion-picker, [app-promotion-picker]',
  templateUrl: './promotion-picker.component.html',
  styleUrls: ['./promotion-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PromotionPickerComponent),
      multi: true,
    },
  ],
})
export class PromotionPickerComponent implements OnDestroy, ControlValueAccessor {
  @Output()
  readonly selectionChanged: EventEmitter<PromotionDto> = new EventEmitter<PromotionDto>();

  @Input()
  valueField;

  value;
  disable;
  promotion;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private adminPromotionService: AdminPromotionService,
    private dialog: MatDialog,
    private appBlockerService: AppBlockerService
  ) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  selectPromotion() {
    const modalRef = this.dialog.open(PromotionsSelectModalComponent);
    const promotionsSelectModalComponent = modalRef.componentInstance;
    promotionsSelectModalComponent.dialogRef = modalRef;

    promotionsSelectModalComponent.selectHandler = promotion => {
      if (promotion) {
        this.value = promotion[this.valueField];
      } else {
        this.value = null;
      }

      this.promotion = promotion;
      this.propagateChange(this.value);
      this.propagateOnTouched();
      this.selectionChanged.emit(promotion);
    };
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (this.value) {
      if (this.valueField === 'id') {
        this.appBlockerService.block();
        this.adminPromotionService
          .getPromotionById(this.value)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            this.promotion = result;
          });
      }
      if (this.valueField === 'code') {
        const search: Search = {
          filtering: [
            {
              column: 'code',
              compareType: CompareTypeDtoEnum.EQUAL,
              value: this.value,
            },
          ],
          sorting: [],
          paging: {
            page: 1,
            pageSize: -1,
          },
        };
        this.appBlockerService.block();
        this.adminPromotionService
          .getPromotionsByFilter(search)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            if (result.data.length > 0) {
              this.promotion = result.data[0];
            }
          });
      }
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
