<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'seoUrl'"
            id="001_product_list_filter"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.products.attributes'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'description'"
            id="002_product_list_filter"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.products.product'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            id="003_product_list_filter"
            formControlName="categoryId"
            inputType="dynamicEnum"
            enumName="com.emeldi.ecc.be.pc.enums.CategoryType"
            sourceName="pc"
            label="category"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            translationPrefix="wc.admin.products.product">
          </app-input-select-form-field>
          <app-custom-input
            [controlName]="'productCode'"
            id="004_product_list_filter"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.products.product'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            id="005_product_list_filter"
            formControlName="state"
            label="states"
            [disableOptionsTranslate]="true"
            [options]="states"
            [showEmptyOption]="true"
            translationPrefix="wc.admin.products.product">
          </app-input-select-form-field>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-input-select-form-field
          formControlName="priceType"
          id="008_product_list_filter"
          label="priceTypes"
          inputType="dynamicEnum"
          enumName="com.emeldi.ecc.be.pc.enums.PriceType"
          sourceName="pc"
          [showEmptyOption]="true"
          translationPrefix="wc.admin.products.product">
        </app-input-select-form-field>
        <app-custom-input
          [controlName]="'priceFrom'"
          [inputType]="'number'"
          id="009_product_list_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.products.product'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'priceTo'"
          [inputType]="'number'"
          id="010_product_list_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.products.product'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'priceParameterName'"
          [inputType]="'text'"
          id="011_product_list_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.products.product'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'priceParameterValue'"
          [inputType]="'text'"
          id="012_product_list_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.products.product'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'parameterName'"
          [inputType]="'text'"
          id="013_product_list_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.products.product'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'parameterValue'"
          [inputType]="'text'"
          id="014_product_list_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.products.product'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-input-select-form-field
          formControlName="versioningType"
          [options]="versioningTypes"
          id="015_product_list_filter"
          label="versioning"
          labelOption="labelKey"
          valueOption="value"
          [showEmptyOption]="true"
          (keydown.enter)="handleChange()"
          translationPrefix="wc.admin.products.product">
        </app-input-select-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</form>
