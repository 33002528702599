import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { INode } from '@components/product-catalogue/promotion/edit/promotion-design/promotion-design.component';

@Component({
  selector: 'app-promotion-design-connections',
  templateUrl: './promotion-design-connections.component.html',
  styleUrls: ['./promotion-design-connections.component.scss'],
})
export class PromotionDesignConnectionsComponent implements OnInit {
  @Input()
  node: INode;

  @Input()
  hasParent = false;

  @Input()
  direction: 'vertical' | 'horizontal' = 'vertical';

  @Input()
  compactView = false;

  @Output()
  readonly nodeClick = new EventEmitter<INode>();

  @Output()
  readonly nodeRemove = new EventEmitter<INode>();

  @Input()
  selectedNode;

  @Input()
  collapseAllEmitter;

  collapsed = false;

  selectedItem;

  @HostBinding('style.flex-direction')
  get hostClass() {
    return this.direction === 'vertical' ? 'column' : '';
  }

  selectItem(selectedItem) {
    this.selectedItem = selectedItem;
  }

  ngOnInit(): void {
    this.collapseAllEmitter.subscribe(collapse => {
      if (!collapse && this.node?.childs?.length > 0) {
        this.selectedItem = this.node.childs[0].id;
      } else {
        this.selectedItem = null;
      }
      this.collapsed = collapse;
    });
  }
}
