<div class="main__header">
  <h1>{{ 'wc.admin.metaProfiles.form.edit' | translate }} - [{{ module }}]</h1>
  <div class="page__menu">
    <a
      id="001_product_group_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs"
      >{{ 'wc.common.save' | translate }}</a
    >
    <a
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions"
      [matMenuTriggerFor]="menu"
      >{{ 'wc.common.more.actions.button' | translate }}</a
    >
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="002_product_group_edit" mat-menu-item (click)="duplicate()" *ngIf="profileDto?.id" [routerLink]="">{{
        'wc.common.duplicate' | translate
        }}</a>
      <a id="003_product_group_edit" mat-menu-item (click)="reset()" [routerLink]="">{{
        'wc.common.reset' | translate
      }}</a>
      <a id="004_product_group_edit" mat-menu-item (click)="delete()" *ngIf="profileDto?.id" [routerLink]="">{{
        'wc.common.delete' | translate
        }}</a>
    </mat-menu>
    <a (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
  </div>
</div>

<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

<div class="card__wrapper">
  <!-- card with main profile attributes -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.metaProfiles.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="getEditProfile()" [formGroup]="profileForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="profileForm"
                  [controlName]="'id'"
                  [inputType]="'text'"
                  id="006_profile_edit"
                  [translationPrefix]="'wc.admin.metaProfile'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="profileForm"
                  [controlName]="'name'"
                  [inputType]="'text'"
                  id="007_profile_edit"
                  [translationPrefix]="'wc.admin.metaProfile'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.metaProfile.metaParameters.header' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="013_product_group_edit"
            color="primary"
            mat-raised-button
            (click)="addParameter()"
            [routerLink]=""
            class="btn -primary -xs edit icon stroke">
            <mat-icon svgIcon="plus"></mat-icon>
            {{ 'wc.common.add' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <!-- generated cards with profile specific parameters -->
        <div class="card -full" *ngFor="let metaParameterFormGroup of metaParameterForms; let i = index">
          <div class="card__inner">
            <form
              *ngIf="getEditMetaParameters()"
              [formGroup]="metaParameterFormGroup"
              class="form"
              [id]="metaParameterFormGroup.controls['name'].value">
              <div class="card__header" fxLayout="row" fxLayoutAlign="start center">
                {{ 'wc.admin.metaProfile.metaParameter' | translate }} -
                {{ metaParameterFormGroup.controls['name'].value }}
                <button (click)="removeParameter(i)" [routerLink]="" mat-icon-button class="dropdown-item">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div class="card__body">
                <div class="form__groups">
                  <div class="form__group -flex">
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="metaParameterFormGroup"
                        [controlName]="'name'"
                        [id]="metaParameterFormGroup.controls['name'].value"
                        [inputType]="'text'"
                        [hints]="metaParameters"
                        [translationPrefix]="'wc.admin.metaProfile.metaParameter'">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="metaParameterFormGroup"
                        [controlName]="'value'"
                        [id]="metaParameterFormGroup.controls['value'].value"
                        [inputType]="'text'"
                        [translationPrefix]="'wc.admin.metaProfile.metaParameter'">
                      </app-custom-input>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
