<td *ngIf="!singleSelectMode">
  <input [id]="parentId + '_000_accounts_list_item'" [(ngModel)]="selected" type="checkbox" />
</td>
<td>{{ firstName(account) }}</td>
<td>{{ lastName(account) }}</td>
<td>{{ account.login }}</td>
<td><input [checked]="account.external" type="checkbox" readonly /></td>
<td class="text-truncate">{{ account.extId }}</td>
<td>{{ employeeId(account) }}</td>
<td class="text-truncate">{{ account.state }}</td>
<td class="text-truncate content-desktop">{{ account.loginAttemptLast | date: 'dd.MM.yyyy HH:mm' }}</td>
<td class="text-truncate content-desktop">{{ account.created | date: 'dd.MM.yyyy HH:mm' }}</td>
<td class="text-truncate content-desktop">{{ account.modified | date: 'dd.MM.yyyy HH:mm' }}</td>
<td class="text-truncate content-desktop">{{ account.modifiedBy }}</td>
<td [style.width.px]="75" *ngIf="!singleSelectMode && !multiSelectMode" class="table__action">
  <button
    aria-expanded="false"
    aria-haspopup="true"
    class="btn -empty table__action-button"
    data-toggle="dropdown"
    id="{{ parentId }}_dropdownUserInfo"
    type="button">
    <i class="fas fa-ellipsis-v"></i>
  </button>
  <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right">
    <a id="{{ parentId }}_001_accounts_list_item" (click)="edit()" [routerLink]="" class="dropdown-item">{{
      'wc.admin.edit' | translate
    }}</a>
    <a id="{{ parentId }}_003_accounts_list_item" (click)="editInNewTab()" [routerLink]="" class="dropdown-item">{{
      'wc.admin.editInNewTab' | translate
    }}</a>
    <a id="{{ parentId }}_002_accounts_list_item" (click)="delete()" [routerLink]="" class="dropdown-item">{{
      'wc.admin.delete' | translate
    }}</a>
  </div>
</td>
