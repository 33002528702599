import { Component } from '@angular/core';
import {
  AdminDynamicEnumService,
  AppBlockerService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
} from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { EnumerationsService } from '@service/enumerations.service';
import { takeUntil } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-notification-notifications-filter',
  templateUrl: './notification-notifications-filter.component.html',
})
export class NotificationNotificationsFilterComponent extends ComplexFilter {
  gatewayTypes = [];
  channelTypes = [];
  notificationStates = [];
  notificationTypes = [];
  PickerInputType = PickerInputType;

  filterForm = this.formBuilder.group({
    rcptAddress: [],
    partyRefNo: [],
    notificationType: [],
    gateway: [],
    channelTypeId: [],
    state: [],
    sent: [],
    toBeSent: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.rcptAddress, 'rcptAddress', CompareTypeDtoEnum.EQUAL),
    new ControlFilterMiner(this.filterForm.controls.partyRefNo, 'partyRefNo'),
    new ControlFilterMiner(this.filterForm.controls.notificationType, 'notificationType'),
    new ControlFilterMiner(this.filterForm.controls.gateway, 'gateway'),
    new ControlFilterMiner(this.filterForm.controls.channelTypeId, 'channelTypeId'),
    new ControlFilterMiner(this.filterForm.controls.state, 'state'),
    new ControlFilterMiner(this.filterForm.controls.sent, 'sent', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.toBeSent, 'toBeSent', CompareTypeDtoEnum.SMALLEREQ),
  ];

  constructor(
    private formBuilder: FormBuilder,
    private appBlockerService: AppBlockerService,
    private adminDynamicEnumService: AdminDynamicEnumService
  ) {
    super();

    const adminDynamicEnumCalls = [
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.NOTIFICATION,
        'com.emeldi.ecc.be.notification.enums.GatewayType'
      ),
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.NOTIFICATION,
        'com.emeldi.ecc.be.notification.enums.Channel'
      ),
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.NOTIFICATION,
        'com.emeldi.ecc.be.notification.enums.NotificationState'
      ),
      this.adminDynamicEnumService.getEnumEntries(
        EnumerationsService.NOTIFICATION,
        'com.emeldi.ecc.be.notification.enums.NotificationTypeEnum'
      ),
    ];
    forkJoin(adminDynamicEnumCalls)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(results => {
        if (results && results.length === 4) {
          this.gatewayTypes = results[0]?.data ? results[0].data.map(value => value.name).sort() : [];
          this.channelTypes = results[1]?.data ? results[1].data.map(value => value.name).sort() : [];
          this.notificationStates = results[2]?.data ? results[2].data.map(value => value.name).sort() : [];
          this.notificationTypes = results[3]?.data ? results[3].data.map(value => value.name).sort() : [];
        }
        super.initForm();
      });
  }
}
