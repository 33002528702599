import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";
import { AdminDynamicEnumService, FormUtils, ServiceUtils } from "@btl/btl-fe-wc-common";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: 'app-form-references',
  templateUrl: './form-references.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormReferencesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormReferencesComponent),
    },
  ],
})
export class FormReferencesComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input()
  value: Array<Reference>;

  @Input()
  collapsed = false;

  @Input()
  headerTranslationKey: any;

  @Input()
  private control: AbstractControl;

  @Input()
  sourceName;

  @Input()
  refTypeEnumName;

  @Input()
  entityTypeEnumName;

  @Input()
  disabled;

  @Input()
  entityIdMandatory = true;

  @Input()
  entitiesWithEntityIdMandatory: Array<string> = [];

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  newReference: boolean;

  refTypes;
  entityTypes;

  form: FormGroup = this.formBuilder.group({
    references: this.formBuilder.array([]),
  });

  discloseValidatorChange = () => {};

  constructor(private formBuilder: FormBuilder, private adminDynamicEnumService: AdminDynamicEnumService) {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.newReference) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return { invalid: true };
      }
    }

    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  ngOnInit() {
    this.adminDynamicEnumService
      .getEnumEntries(this.sourceName, this.refTypeEnumName)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.refTypes = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(this.sourceName, this.entityTypeEnumName)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.entityTypes = result.data.map(value => value.name).sort();
      });

    const self = this;
    const origFunc = this.control.markAsTouched;
    this.control.markAsTouched = function () {
      self.newReference = false;
      FormUtils.validateAllFormFields(self.form);
      if (!self.form.valid) {
        self.control.setErrors(self.form.errors);
        self.discloseValidatorChange();
      } else {
        self.control.setErrors(null);
      }
      origFunc.apply(this, arguments);
    };

    this.valueChanged();
    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      if (!ServiceUtils.arraysEqualsWithExclude(this.control.value, value.references)) {
        this.propagateChange(this.getValue());
      }
    });
  }

  valueChanged() {
    if (!this.value) {
      this.value = [];
    }

    this.getFormArray().clear();

    if (this.value) {
      this.value.forEach(param => {
        this.addReferenceToFormArray(param);
      });
    }
  }

  getFormArray() {
    return this.form.get('references') as FormArray;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;

    this.valueChanged();
  }

  getValue() {
    if (this.form.valid) {
      const ret = this.form.get('references').value;
      return ret;
    }
    return null;
  }

  add() {
    this.newReference = true;
    this.collapsed = false;
    this.addReferenceToFormArray(null);
  }

  addReferenceToFormArray(reference) {
    const referenceDataFormGroup = this.formBuilder.group({
      entityId: [null, validateEntityId(this.entityIdMandatory, this.entitiesWithEntityIdMandatory)],
      entityType: [null, [Validators.required, makeValidateEntityId()]],
      refType: [null, Validators.required],
    });

    if (reference) {
      referenceDataFormGroup.patchValue(reference);
    }
    this.getFormArray().push(referenceDataFormGroup);
  }

  remove(referenceForm: AbstractControl) {
    if (!this.disabled) {
      let index = 1;
      let foundIndex = null;
      this.getFormArray().controls.forEach(control => {
        if (control === referenceForm) {
          foundIndex = index;
        }
        index++;
      });

      if (foundIndex) {
        this.getFormArray().removeAt(foundIndex - 1);
      }
    }
  }
}

export const validateEntityId = (entityIdMandatory: boolean, entitiesWithEntityIdMandatory: Array<string>) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      const entityType = group.get('entityType').value;
      if (!control.value && (entityIdMandatory || entitiesWithEntityIdMandatory.includes(entityType))) {
        return { require: true };
      }
    }
  };
  return null;
};

export const makeValidateEntityId = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      const entityIdControl = group.get('entityId');
      entityIdControl.updateValueAndValidity();
    }

    return null;
  };
  return null;
};

export interface Reference {
  entityId: string;
  entityType: string;
  refType: string;
}
