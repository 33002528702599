<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'code'"
            [inputType]="'text'"
            id="001_cms_content_banner_filter"
            [translationPrefix]="'wc.admin.cms.content.list'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            *ngIf="contentKeyIds"
            [formControlName]="'keyId'"
            [label]="'contentKeyId'"
            [options]="contentKeyIds"
            id="002_cms_content_banner_filter"
            [labelOption]="'labelKey'"
            [valueOption]="'value'"
            [translationPrefix]="'wc.admin.cms.content.list'">
          </app-input-select-form-field>
          <app-custom-input
            [controlName]="'keyValue'"
            [inputType]="'text'"
            [label]="'contentKeyValue'"
            id="003_cms_content_banner_filter"
            [translationPrefix]="'wc.admin.cms.content.list'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'name'"
            [inputType]="'text'"
            id="004_cms_content_banner_filter"
            [translationPrefix]="'wc.admin.cms.content.list'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            *ngIf="contentStates"
            [formControlName]="'state'"
            [label]="'contentState'"
            id="005_cms_content_banner_filter"
            [options]="contentStates"
            [labelOption]="'labelKey'"
            [valueOption]="'value'"
            [translationPrefix]="'wc.admin.cms.content.list'">
          </app-input-select-form-field>
          <app-input-select-form-field
            *ngIf="internals"
            [formControlName]="'isInternal'"
            [options]="internals"
            id="006_cms_content_banner_filter"
            [labelOption]="'labelKey'"
            [valueOption]="'value'"
            [translationPrefix]="'wc.admin.cms.content.list'">
          </app-input-select-form-field>
          <div class="form__field-actions">
            <a
              id="007_cms_content_banner_filter"
              color="primary"
              mat-raised-button
              (click)="handleChange()"
              style="width: 100%"
              class="btn -primary -xs"
              [routerLink]=""
            >{{ 'wc.common.search.button' | translate }}</a
            >
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
