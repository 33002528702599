<mat-form-field class='form__field' appearance='outline' floatLabel='always' [formGroup]='parentGroup'>
  <mat-label>{{
    (translationPrefix ? translationPrefix + '.' + (label ? label : controlName) + (suffix ? '.' + suffix : '') : label)
      | translate
  }}</mat-label>
  <input
    [id]="id"
    class="form__input"
    matInput
    [ngxMatDatetimePicker]="picker"
    [placeholder]="'wc.common.chooseDate' | translate"
    [formControl]="dateFormControl"
    [disabled]="disabled"
    (dateChange)="onDateChange($event)" />
  <mat-error *ngIf="parentGroup.controls[controlName].errors">
    <ng-container *ngFor="let keyError of getControlValidationErrors()">
      {{ (translationPrefix + '.' + (label ? label : controlName) + '.error.' + keyError | translate) + suffix }}
    </ng-container>
  </mat-error>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker
    #picker
    [showSpinners]="showSpinners"
    [showSeconds]="showSeconds"
    [hideTime]="hideTime"
    [stepHour]="stepHour"
    [stepMinute]="stepMinute"
    [stepSecond]="stepSecond"
    [touchUi]="touchUi"
    [color]="color"
    [enableMeridian]="enableMeridian">
  </ngx-mat-datetime-picker>
</mat-form-field>
