import { Component, ViewChild } from '@angular/core';
import { ReleaseDto, ReleaseValidationDto } from '@btl/admin-bff';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminReleaseService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  PaginationComponent,
  Search,
  ServiceUtils,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  MembersListingComponent,
  OperationType
} from '@components/product-catalogue/import-export/release-edit/members-listing/members-listing.component';
import { MatDialog } from '@angular/material/dialog';
import { Animations } from '@helpers/animations';
import {
  ReleasesListingComponent
,
} from '@components/product-catalogue/import-export/releases-listing/releases-listing.component';

@Component({
  selector: 'app-release-edit',
  templateUrl: './release-edit.component.html',
  styleUrls: ['./release-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: ReleaseEditComponent.PAGE_ID })
export class ReleaseEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'ReleaseEditComponent';

  pageId(): string {
    return ReleaseEditComponent.PAGE_ID;
  }

  savingReleaseId;
  release: ReleaseDto;
  validationResult: ReleaseValidationDto;
  releaseForm: FormGroup = this.formBuilder.group({
    id: [null],
    releaseCode: [null, Validators.required],
    releaseDate: [null],
    releaseNote: [null],
  });

  @ViewChild(MembersListingComponent, { static: false })
  membersListingComponent: MembersListingComponent;

  @ViewChild(PaginationComponent, { static: true })
  paginationComponent: PaginationComponent;

  operations = [];

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: -1,
    },
  };

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private adminReleaseService: AdminReleaseService,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private stickyMessageService: StickyMessageService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const releaseId = this.params.id;
      if (releaseId && releaseId !== 'newRelease') {
        this.loadRelease(releaseId);
      } else {
        this.release = { id: null };
      }
    } else {
      this.release = undefined;
    }
  }

  loadRelease(releaseId) {
    this.adminReleaseService
      .getRelease(releaseId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadRelease(result);
      });
  }

  reloadRelease(releaseDto: ReleaseDto) {
    this.operations = [];
    this.release = releaseDto;
    this.releaseForm.reset(this.release);
  }

  public getReleaseHandler = (releaseDto: ReleaseDto): void => {
    const bulkOperationsRequestDto = {
      bulkCreateList: this.operations.filter(
        member => member[MembersListingComponent.OPERATION_TYPE] === OperationType.CREATE
      ),
      bulkDeleteList: this.operations.filter(
        member => member[MembersListingComponent.OPERATION_TYPE] === OperationType.DELETE
      ),
      bulkUpdateList: this.operations.filter(
        member => member[MembersListingComponent.OPERATION_TYPE] === OperationType.UPDATE
      ),
    };
    bulkOperationsRequestDto.bulkCreateList.forEach(member => {
      member.id = null;
      this.removeNullValues(member);
    });
    bulkOperationsRequestDto.bulkUpdateList.forEach(member => {
      this.removeNullValues(member);
    });

    this.adminReleaseService.changeMembers(releaseDto.id, bulkOperationsRequestDto).subscribe(() => {
      if (releaseDto) {
        if (releaseDto.id === this.savingReleaseId) {
          this.reloadRelease(releaseDto);
        } else {
          this.reloadRelease(releaseDto);
          this.navigateSelf({ id: releaseDto.id });
        }
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    });
  };

  removeNullValues(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  reset() {
    if (this.release && this.release.created) {
      this.loadRelease(this.release.id);
    } else {
      this.reloadRelease(this.release);
    }
  }

  delete() {
    this.appBlockerService.block();
    this.adminReleaseService
      .deleteRelease(this.release.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.navigateSibling(ReleasesListingComponent.PAGE_ID);
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      });
  }

  duplicate() {
    this.release.releaseCode = `${this.release.releaseCode}copy`;
    this.releaseForm.controls.releaseCode.patchValue(this.release.releaseCode);

    const search = ServiceUtils.getUnlimitedSearch();
    this.adminReleaseService
      .filterMembers(this.release.id, search, null)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        result.data.forEach(member => {
          const operationMember = this.operations.find(findMember => findMember.id === member.id);
          if (operationMember) {
            if (operationMember[MembersListingComponent.OPERATION_TYPE] === OperationType.UPDATE) {
              operationMember[MembersListingComponent.OPERATION_TYPE] = OperationType.CREATE;
            } else if (operationMember[MembersListingComponent.OPERATION_TYPE] === OperationType.DELETE) {
              this.operations.splice(this.operations.indexOf(operationMember), 1);
            }
          } else {
            member.id = null;
            member[MembersListingComponent.OPERATION_TYPE] = OperationType.CREATE;
            this.operations.push(member);
          }

          this.membersListingComponent.pagedMembersDto.data.length = 0;
        });
      });

    this.releaseForm.controls.id.patchValue(null);
    this.release.id = null;
    this.release.created = null;
  }

  save() {
    if (this.saveReleaseAttributes()) {
      this.appBlockerService.block();
      if (this.release.created) {
        this.savingReleaseId = this.release.id;
        this.adminReleaseService
          .updateRelease(this.savingReleaseId, this.release)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getReleaseHandler);
      } else {
        this.savingReleaseId = null;
        this.adminReleaseService
          .createRelease(this.release)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getReleaseHandler);
      }
    }
  }

  saveReleaseAttributes(): boolean {
    FormUtils.validateAllFormFields(this.releaseForm);
    if (this.releaseForm.valid) {
      if (this.release) {
        Object.keys(this.releaseForm.controls).forEach(field => {
          const control = this.releaseForm.get(field);
          this.release[field] = control.value;
        });
      }
      return true;
    }

    return false;
  }

  clearField(field: string) {
    this.releaseForm.controls[field].patchValue(null);
  }

  cancel() {
    this.navigateSibling(ReleasesListingComponent.PAGE_ID);
  }

  checkValidity(dialog) {
    this.adminReleaseService
      .validateRelease(this.release.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.validationResult = result;
        this.dialog.open(dialog);
      });
  }
}
