import { Component } from '@angular/core';
import {
  AclService,
  AdminLocalizedTextService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { LocalizedTextDto, PagedLocalizedTextsDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { LocalizedTextComponent } from '../localized-text.component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import {
  LocalizedTextEditLocalesComponent,
} from '@components/localized-text/edit-locales/localized-text-edit-locales.component';
import { LocalizedTextEditComponent } from '@components/localized-text/edit/localized-text-edit.component';
import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-localized-text-list',
  templateUrl: './localized-text-list.component.html',
  styleUrls: ['./localized-text-list.component.scss'],
})
@EnableDynamicLoading({ customName: LocalizedTextListComponent.PAGE_ID })
export class LocalizedTextListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'LocalizedTextListComponent';

  pageId(): string {
    return LocalizedTextListComponent.PAGE_ID;
  }

  pagedLocalizedTextsDto: PagedLocalizedTextsDto;


  constructor(
    private adminLocalizedTextService: AdminLocalizedTextService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}


  editLocalizedText(localizedTextDto, inNewTab: boolean = false) {
    this.navigateSibling(LocalizedTextEditComponent.PAGE_ID, { id: localizedTextDto.id }, inNewTab);
  }

  newLocalesLocalizedText() {
    this.navigateSibling(LocalizedTextEditLocalesComponent.PAGE_ID, { module: '&', key: '&' });
  }

  duplicateLocalizedText(localizedTextDto: LocalizedTextDto, refresh?: boolean) {
    this.appBlockerService.block();
    this.adminLocalizedTextService
      .createLocalizedText(LocalizedTextComponent.duplicateLocalizedText(localizedTextDto))
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        if (refresh !== undefined) {
          if (refresh) {
            this.loadData();
            this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          }
        }
      });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminLocalizedTextService.getLocalizedTextsByFilter(this.search).subscribe(result => {
        if (result) {
          this.pagedLocalizedTextsDto = result;
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminLocalizedTextService.deleteLocalizedTexts(bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminLocalizedTextService.deleteLocalizedText(Number.parseInt(rowDataId));
  }
}
