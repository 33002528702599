<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'name'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.groupType'"
            (keyup)="filterByName($event.target.value)"
            [parentGroup]="filterForm">
          </app-custom-input>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
