import { Component, Input, ViewChild } from '@angular/core';
import { Search } from '@btl/btl-fe-wc-common';
import { RolesListingComponent } from '@components/acl/roles/roles-listing/roles-listing.component';

@Component({
  selector: 'app-acl-roles-select-modal',
  templateUrl: './acl-roles-select-modal.component.html',
  styleUrls: ['./acl-roles-select-modal.component.scss'],
})
export class AclRolesSelectModalComponent {
  @ViewChild(RolesListingComponent, { static: true })
  rolesListingComponent: RolesListingComponent;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  @Input()
  disableActions: boolean = false;

  @Input()
  disableListingActions: boolean = false;

  selectEvent() {
    const roleDtos = this.rolesListingComponent.getSelectedData();
    if (roleDtos && roleDtos.length > 0) {
      this.selectHandler(roleDtos);
      this.dialogRef.close();
    }
  }

  getSearch(): Search {
    return this.rolesListingComponent.search;
  }

  cancelEvent() {
    this.selectHandler([]);
    this.dialogRef.close();
  }
}
