<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'code'"
            [inputType]="'text'"
            id="001_cms_contents_filter"
            [translationPrefix]="'wc.admin.cms.content.list'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            (selectionChangeEmit)="handleContentKeyId($event)"
            *ngIf="contentKeyIds"
            id="002_cms_contents_filter"
            [formControlName]="'keyId'"
            [options]="contentKeyIds"
            [labelOption]="'labelKey'"
            [valueOption]="'value'"
            [label]="'contentKeyId'"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.cms.content.list'">
          </app-input-select-form-field>
          <app-custom-input
            [controlName]="'keyValue'"
            [inputType]="'text'"
            id="003_cms_contents_filter"
            [label]="'contentKeyValue'"
            [translationPrefix]="'wc.admin.cms.content.list'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'name'"
            [inputType]="'text'"
            id="004_cms_contents_filter"
            [translationPrefix]="'wc.admin.cms.content.list'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-input-select-form-field
          *ngIf="contentTypes"
          (selectionChangeEmit)="handleContentType($event)"
          [formControlName]="'contentTemplateType'"
          [options]="contentTypes"
          [labelOption]="'labelKey'"
          id="007_cms_contents_filter"
          [valueOption]="'value'"
          [showEmptyOption]="true"
          [label]="'contentType'"
          [translationPrefix]="'wc.admin.cms.content.list'">
        </app-input-select-form-field>
        <app-input-select-form-field
          *ngIf="contentStates"
          [formControlName]="'state'"
          [options]="contentStates"
          [labelOption]="'label'"
          id="008_cms_contents_filter"
          [valueOption]="'value'"
          [showEmptyOption]="true"
          [label]="'contentState'"
          [translationPrefix]="'wc.admin.cms.content.list'">
        </app-input-select-form-field>
        <app-input-select-form-field
          *ngIf="internals"
          [formControlName]="'isInternal'"
          id="009_cms_contents_filter"
          [options]="internals"
          [labelOption]="'labelKey'"
          [valueOption]="'value'"
          [showEmptyOption]="true"
          [label]="'internal'"
          [translationPrefix]="'wc.admin.cms.content.list'">
        </app-input-select-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</form>
