<div class="error-page-container">
  <div class="error-page-icon">
    <mat-icon [svgIcon]="getIcon(errorCode)"></mat-icon>
  </div>
  <div class="error-page-wrapper">
    <div class="error-page-box">
      <ng-container *ngIf="causedByMessage; else shortMessage">
        <div class="error-page-inner-head">
          <button class="stroked-button" mat-stroked-button color="primary" (click)="goBack()">
            {{ 'wc.admin.error.goBack' | translate }}
          </button>
        </div>
        <div class="error-page-inner-content">
          <h3>HTTP ERROR {{ errorCode }}</h3>
          <span>{{ causedByMessage }}</span>
          <div></div>
        </div>
        <div class="error-page-inner-footer">
          <button mat-raised-button color="primary" routerLink="/dashboard">
            {{ 'wc.admin.error.goToDashboard' | translate }}
          </button>
          <button class="stroked-button" mat-stroked-button color="primary" (click)="showDetail = !showDetail">
            {{ 'wc.admin.error.showDetails' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-template #shortMessage>
        <div class="error-page-inner-head">
          <button class="stroked-button" mat-stroked-button color="primary" (click)="goBack()">
            {{ 'wc.admin.error.goBack' | translate }}
          </button>
        </div>
        <div class="error-page-inner-content">
          <h3>HTTP ERROR {{ errorCode }}</h3>
          <ng-container *ngFor="let failure of failures">
            <span>{{ failure.detail }}</span>
            <div>
              {{ failure.localizedDescription ? failure.localizedDescription : failure.detail }}
            </div>
          </ng-container>
        </div>
        <div class="error-page-inner-footer">
          <button mat-raised-button color="primary" routerLink="/dashboard">
            {{ 'wc.admin.error.goToDashboard' | translate }}
          </button>
          <button class="stroked-button" mat-stroked-button color="primary" (click)="showDetail = !showDetail">
            {{ 'wc.admin.error.showDetails' | translate }}
          </button>
        </div>
      </ng-template>
      <div class="error-page-detail" *ngIf="showDetail">
        <div id="failures" *ngFor="let failure of failures">
          <h3>{{ failure.code + ' - ' + failure.detail }}</h3>
          <p>
            {{ failure.localizedDescription ? failure.localizedDescription : failure.detail }}
          </p>
          <p>
            {{ failure.stackTrace }}
          </p>
        </div>
        <div class="error-page-detail-copy">
          <button mat-raised-button color="primary" (click)="getFailures()">
            {{ 'wc.admin.error.copyToClipboard' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
