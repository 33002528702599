import { Component, ViewEncapsulation } from '@angular/core';
import {
  AdminDynamicEnumService,
  CompareType,
  ComplexFilter,
  ControlFilterMiner,
  ControlStructSingletonFilterMiner,
} from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { EnumEntriesDto } from '@btl/admin-bff';
import { takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-agreementTypes-filter',
  templateUrl: './agreement-types-filter.component.html',
  animations: [Animations.advancedFilterArrow],
  encapsulation: ViewEncapsulation.None,
})
export class AgreementTypesFilterComponent extends ComplexFilter {
  PickerInputType = PickerInputType;
  entityTypes: EnumEntriesDto;

  filterForm = this.formBuilder.group({
    id: [],
    name: [],
    valid: [],
    entityType: [],
    createdFrom: [],
    createdTo: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.id, 'id'),
    new ControlFilterMiner(this.filterForm.controls.name, 'name'),
    new ControlFilterMiner(this.filterForm.controls.valid, 'validOnly', CompareTypeDtoEnum.EQUAL),
    new ControlFilterMiner(this.filterForm.controls.createdFrom, 'created', CompareTypeDtoEnum.BIGGEREQ),
    new ControlFilterMiner(this.filterForm.controls.createdTo, 'created', CompareTypeDtoEnum.SMALLEREQ),
    new ControlStructSingletonFilterMiner(this.filterForm.controls.entityType, 'references', 'entityType', null),
  ];

  advancedFilterFields = ['createdFrom', 'createdTo', 'valid'];

  constructor(
    private formBuilder: FormBuilder,
    private adminDynamicEnumService: AdminDynamicEnumService
  ) {
    super();
    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.CRM, 'com.emeldi.ecc.be.crm.enums.AgreementTypeRefEntityType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.entityTypes = result;
        this.entityTypes.data = this.entityTypes.data.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  protected getDefaultFilterValues() {
    return {
      id: null,
      name: null,
      valid: false,
      entityType: null,
      createdFrom: null,
      createdTo: null,
    };
  }
}
