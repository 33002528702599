import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MoveNodeService {
  public nodeToMove;

  public deleteOldNode = new EventEmitter<boolean>();
  public addNewNode = new EventEmitter<boolean>();
}
