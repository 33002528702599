import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CompareType, ComplexFilter, ControlFilterMiner } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-socket-filter',
  templateUrl: './socket-filter.component.html',
  animations: [Animations.advancedFilterArrow],
})
export class SocketFilterComponent extends ComplexFilter {
  PickerInputType = PickerInputType;
  panelOpenState: boolean = false;
  socketCategories = [];
  techCategories: string[] = [];

  compareTypes = [
    { name: '=', value: CompareTypeDtoEnum.EQUAL },
    { name: '!=', value: CompareTypeDtoEnum.DIFFERENT },
    { name: '>', value: CompareTypeDtoEnum.BIGGER },
    { name: '>=', value: CompareTypeDtoEnum.BIGGEREQ },
    { name: '<', value: CompareTypeDtoEnum.SMALLER },
    { name: '<=', value: CompareTypeDtoEnum.SMALLEREQ }
  ];

  filterForm = this.formBuilder.group({
    code: [],
    name: [],
    socketCategory: [],
    promotionId: [],
    productCode: [],
    productCategory: [],
    shared: [],
    contentQty_min: [],
    contentQty_max: [],
    contentQty_min_compareType: [],
    contentQty_max_compareType: [],
  });

  advancedFilterFields = ['promotionId', 'productCode', 'productCategory', 'shared', 'contentQty_min', 'contentQty_max', 'contentQty_min_compareType', 'contentQty_max_compareType'];

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.code, 'code', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.name, 'name', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.socketCategory, 'socketCategory'),
    new ControlFilterMiner(this.filterForm.controls.promotionId, 'promotionId'),
    new ControlFilterMiner(this.filterForm.controls.productCode, 'productCode'),
    new ControlFilterMiner(this.filterForm.controls.productCategory, 'productCategory'),
    new ControlFilterMiner(this.filterForm.controls.shared, 'shared'),
    new ControlFilterMiner(
      this.filterForm.controls.contentQty_min,
      'contentQty.min',
      this.filterForm.controls.contentQty_min_compareType,
      true
    ),
    new ControlFilterMiner(
      this.filterForm.controls.contentQty_max,
      'contentQty.max',
      this.filterForm.controls.contentQty_max_compareType,
      true
    ),
  ];

  changeCompareType(contentQty: 'min' | 'max', value: Event) {
    if (contentQty === 'min') {
      this.enableDisableContentQty(value, this.filterForm.controls.contentQty_min);
      this.filterForm.controls.contentQty_min.patchValue(null);
    } else {
      this.enableDisableContentQty(value, this.filterForm.controls.contentQty_max);
      this.filterForm.controls.contentQty_max.patchValue(null);
    }
  }

  constructor(private formBuilder: FormBuilder, private enumerationsService: EnumerationsService) {
    super();
    this.enumerationsService
      .getProductSocketCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.socketCategories = result;
      });
    this.enumerationsService
      .getProductTechnicalCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.techCategories = result;
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.enableDisableContentQty(this.filterForm.controls.contentQty_min_compareType.value, this.filterForm.controls.contentQty_min);
    this.enableDisableContentQty(this.filterForm.controls.contentQty_max_compareType.value, this.filterForm.controls.contentQty_max);
  }

  enableDisableContentQty(compareTypeValue, contentQtyControl: FormControl) {
    if (!compareTypeValue) {
      contentQtyControl.disable();
    } else {
      contentQtyControl.enable();
    }
  }

  clearFilter() {
    this.filterForm.controls.contentQty_min.disable();
    this.filterForm.controls.contentQty_max.disable();
    this.resetFilterFields();
  }
}
