import { Component } from '@angular/core';
import { CompareType, ComplexFilter, ControlFilterMiner } from '@btl/btl-fe-wc-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-localized-text-filter',
  templateUrl: './localized-text-filter.component.html',
})
export class LocalizedTextFilterComponent extends ComplexFilter {

  filterForm: FormGroup = this.formBuilder.group({
    module: [],
    locale: [],
    key: [],
    defaultValue: [],
    customValue: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.module, 'module', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.locale, 'locale', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.key, 'key', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.defaultValue, 'defaultValue', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.customValue, 'customValue', CompareTypeDtoEnum.LIKE),
  ];

  constructor(private formBuilder: FormBuilder) {
    super();
  }
}
