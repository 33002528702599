<form [formGroup]="typeGatewayFormGroup" class="form">
  <div class="card -full">
    <div class="card__inner">
      <div class="card__body">
        <div class="form__groups">
          <div class="form__group -full">
            <div class="form__row">
              <app-custom-input
                [parentGroup]="typeGatewayFormGroup"
                [controlName]="'templateSubject'"
                [inputType]="'text'"
                [translationPrefix]="'wc.admin.notification.type.gateway'">
              </app-custom-input>
            </div>
          </div>

          <div class="form__group -full">
            <div class="form__row_label">{{ 'wc.admin.notification.type.gateway.templateBody' | translate }}</div>

            <div class="form__row">
              <angular-editor [config]="editorConfig" formControlName="templateBody" [formGroup]="typeGatewayFormGroup">
              </angular-editor>
            </div>
            <div class="form__row error">
              <mat-error
                *ngIf="
                  typeGatewayFormGroup.get('templateBody').touched &&
                  typeGatewayFormGroup.get('templateBody').validator &&
                  typeGatewayFormGroup.get('templateBody').errors
                ">
                <ng-container *ngFor="let error of typeGatewayFormGroup.get('templateBody').errors | keyvalue">
                  {{ 'wc.admin.notification.type.gateway.templateBody.error.' + error.key | translate }}
                </ng-container>
              </mat-error>
            </div>
          </div>

          <div class="form__group -full">
            <div class="form__row">
              <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                <mat-label>{{ 'wc.admin.notification.type.gateway.templateBodyAlternative' | translate }}</mat-label>
                <textarea
                  class="form__input"
                  matInput
                  type="text"
                  formControlName="templateBodyAlternative"
                  rows="10"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.notification.type.gateway.parameters' | translate }}
        <a color="primary" mat-raised-button (click)="addParameter()" [routerLink]="" class="btn -primary -xs">
          {{ 'wc.admin.notification.type.gateway.param.add' | translate }}</a
        >
      </div>
      <div class="card__body">
        <form *ngIf="parametersFormArray.controls.length" [formGroup]="typeGatewayFormGroup" class="form">
          <div class="form__groups" formArrayName="parameters">
            <div class="form__group -full" *ngFor="let paramForm of parametersFormArray.controls; let i = index">
              <div
                fxLayout="row"
                style="column-gap: 20px; padding: 15px; border: 1px solid #2028301a; border-radius: 5px">
                <div style="display: flex; flex: 1 1 auto; column-gap: 30px">
                  <app-custom-input
                    [parentGroup]="paramForm"
                    [controlName]="'name'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification.type.gateway.param'">
                  </app-custom-input>

                  <app-custom-input
                    [parentGroup]="paramForm"
                    [controlName]="'value'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.notification.type.gateway.param'">
                  </app-custom-input>
                </div>
                <div style="display: flex; align-items: center">
                  <mat-icon (click)="deleteParameter(i)" style="font-size: 18px; line-height: 24px; cursor: pointer"
                    >delete</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.notification.type.gateway.attachments' | translate }}
        <a color="primary" mat-raised-button (click)="addAttachment()" [routerLink]="" class="btn -primary -xs">
          {{ 'wc.admin.notification.type.gateway.attachment.add' | translate }}</a
        >
      </div>
      <div class="card__body">
        <table class="table -primary table-sm">
          <thead>
            <th scope="col">
              <app-sortable-column localizationKey="wc.admin.notification.type.gateway.attachment.name">
              </app-sortable-column>
            </th>
            <th scope="col">
              <app-sortable-column localizationKey="wc.admin.notification.type.gateway.attachment.id">
              </app-sortable-column>
            </th>
            <th scope="col">
              <app-sortable-column localizationKey="wc.admin.notification.type.gateway.attachment.inline">
              </app-sortable-column>
            </th>
            <th scope="col">{{ 'wc.admin.productParameters.action' | translate }}</th>
          </thead>
          <tbody>
            <tr *ngFor="let attachment of attachments; let i = index" class="table__row">
              <td class="table-file-name">
                <mat-icon class="attachment-color-{{ attachment.fileType }}">insert_drive_file</mat-icon>
                <div class="attachment-type">{{ attachment.fileType }}</div>
                <div>{{ attachment.name }}</div>
              </td>
              <td>{{ attachment.extId }}</td>
              <td><mat-checkbox disabled [checked]="attachment.inline"></mat-checkbox></td>
              <td class="table__action">
                <button (click)="editAttachment(attachment)" [routerLink]="" mat-icon-button class="dropdown-item">
                  <mat-icon>edit</mat-icon>
                </button>
                <button (click)="downloadAttachment(attachment)" [routerLink]="" mat-icon-button class="dropdown-item">
                  <mat-icon>download</mat-icon>
                </button>
                <button (click)="deleteAttachment(i)" [routerLink]="" mat-icon-button class="dropdown-item">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>
