<div class="inner-component">
  <div class="inner_card dashboard">
    <div class="inner_card_header" [formGroup]="filterForm">
      <div class="header__title">{{ 'wc.admin.dashboard.totalConfirmed.title' | translate }}</div>
      <app-input-select-form-field
        [sortOptions]="false"
        [noLabel] = true
        formControlName="date"
        [options]="dates"
        labelOption="labelKey"
        valueOption="value"
        (selectionChangeEmit)="periodChange($event)"
        [translationPrefix]="'wc.common.date'">
      </app-input-select-form-field>
    </div>
    <div class="inner_card_content">
      <div class="content-wrapper">
        <div class="confirmed-container">
          <span class="inner_card_text total">{{confirmedOrders}}</span>
          <span class="inner_card_text percent {{percentageChange > 0 ? 'green' : ''}}">{{percentageChange}}%</span>
        </div>
        <span class="inner_card_text bonus">+ {{createdOrders}} {{ 'wc.admin.dashboard.totalConfirmed.created' | translate }}</span>
      </div>
    </div>
  </div>
</div>
