<h2 mat-dialog-title>
  {{ 'wc.common.choose' | translate }}
    <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content class="mat-typography">
  <app-ticket-step-template-list
    (singleSelectEvent)="singleSelect($event)"
    [disableActions]="disableActions"
    [selectMode]="selectMode"
    [singleSelectMode]="singleSelectMode">
  </app-ticket-step-template-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a (click)="selectNothingEvent()" [routerLink]="" color="primary" mat-raised-button
    >{{ 'wc.common.choose.nothing' | translate }}
  </a>
  <a (click)="selectEvent()" [routerLink]="" color="primary" mat-raised-button>{{ 'wc.common.choose' | translate }} </a>
</mat-dialog-actions>
