<div>
  <h2 mat-dialog-title>
    {{
      editMode
        ? 'wc.admin.notification.type.gateway.attachment.edit'
        : ('wc.admin.notification.type.gateway.attachment.add' | translate)
    }}
  </h2>

  <mat-dialog-content class="mat-typography">
    <div class=".mat-drawer-container">
      <div class="mat-sidenav-content">
        <div class="main_content">
          <div class="card__body">
            <div class="attachment-action" *ngIf="!editMode">
              <button color="primary" mat-raised-button (click)="uploadAttachment()" [routerLink]="">
                {{ 'wc.admin.notification.type.gateway.attachment.add' | translate }}
              </button>
              <button color="primary" mat-raised-button (click)="chooseFromGallery()" [routerLink]="">
                {{ 'wc.admin.notification.type.gateway.attachment.chooseFromGallery' | translate }}
              </button>
              <div class="text" *ngIf="!uploadedFile">
                {{ 'wc.admin.notification.type.gateway.attachment.chooseAttachment' | translate }}
              </div>
            </div>
            <form *ngIf="uploadedFile || attachment" [formGroup]="formGroup" class="form">
              <div class="form__groups">
                <div class="form__group -full">
                  <div class="form__row">
                    <app-custom-input
                      [parentGroup]="formGroup"
                      [controlName]="'name'"
                      [inputType]="'text'"
                      [translationPrefix]="'wc.admin.notification.type.gateway.attachment'">
                    </app-custom-input>
                  </div>
                </div>

                <div class="form__group -full">
                  <div class="form__row">
                    <app-custom-input
                      [inputType]="'checkbox'"
                      [parentGroup]="formGroup"
                      [controlName]="'inline'"
                      [label]="'wc.admin.notification.type.gateway.attachment.inline'">
                    </app-custom-input>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button color="primary" mat-raised-button (click)="save()" [routerLink]="">
      {{ 'wc.common.save' | translate }}
    </button>
    <button color="primary" mat-raised-button (click)="cancel()" [routerLink]="">
      {{ 'wc.common.cancel.button' | translate }}
    </button>
  </mat-dialog-actions>
</div>
