import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-eligibility-legend-modal',
  templateUrl: './eligibility-legend-modal.component.html',
  styleUrls: ['./eligibility-legend-modal.component.scss'],
})
export class EligibilityLegendModalComponent {
  @Input()
  dialogRef;
}
