import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AdminCodebookService, CompareType, ComplexFilter, ControlFilterMiner, Search } from '@btl/btl-fe-wc-common';
import { Animations } from '@helpers/animations';
import { CodebookDto } from '@btl/admin-bff/model/codebookDto';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-document-template-filter',
  templateUrl: './document-template-filter.component.html',
  animations: [Animations.advancedFilterArrow],
})
export class DocumentTemplateFilterComponent extends ComplexFilter {
  documentTypes: string[] = [];
  documentTypeCodebookDtos: CodebookDto[] = [];

  filterForm = this.formBuilder.group({
    id: [],
    description: [],
    documentType: [],
  });

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.id, 'id'),
    new ControlFilterMiner(this.filterForm.controls.description, 'description', CompareTypeDtoEnum.LIKE),
    new ControlFilterMiner(this.filterForm.controls.documentType, 'documentType', CompareTypeDtoEnum.IN),
  ];

  constructor(private formBuilder: FormBuilder, private adminCodebookService: AdminCodebookService) {
    super();
    const search: Search = {
      filtering: [{ column: 'codebookType', compareType: 'EQUAL', value: 'DOCUMENT_TYPE' }],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    this.adminCodebookService.getCodebooksByFilter(search, ['data.code', 'data.localizedTexts']).subscribe(result => {
      this.documentTypeCodebookDtos = result.data;
      this.documentTypes = result.data.map(cb => cb.code);
    });
  }
}
