<div class="card__wrapper">
  <div class="card -full">
    <div class="card__inner">
      <div class="card__body">
        <form *ngIf="parameter" [formGroup]="parentForm" class="form" id="001_category_parameter">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="parentForm"
                  [controlName]="'name'"
                  id="002_category_parameter"
                  [inputType]="'text'"
                  (blur)="nameChanged($event.target.value)"
                  [translationPrefix]="'wc.admin.productParameters'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <mat-checkbox
                  formControlName="static"
                  (change)="staticTypeChanged($event.checked)"
                  id="003_category_parameter"
                  >{{ 'wc.admin.productParameters.static' | translate }}</mat-checkbox
                >
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'type'"
                  id="006_category_parameter"
                  [options]="parameterTypes"
                  [showEmptyOption]="false"
                  [labelOption]="'name'"
                  [valueOption]="'name'"
                  (selectionChangeEmit)="dataTypeChanged()"
                  [translationPrefix]="'wc.admin.productParameters'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="parentForm"
                  [controlName]="'validationRegex'"
                  [inputType]="'text'"
                  id="005_category_parameter"
                  [translationPrefix]="'wc.admin.productParameters'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'010_category_parameter'"
                  [inputType]="'checkbox'"
                  [parentGroup]="parentForm"
                  [controlName]="'localized'"
                  [label]="'wc.admin.productParameters.localized'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <ng-container [ngSwitch]="parentForm.controls['type'].value">
                  <app-custom-input
                    [parentGroup]="parentForm"
                    [controlName]="'typeDetail'"
                    [inputType]="'text'"
                    *ngSwitchDefault
                    [id]="'007_category_parameter'"
                    [translationPrefix]="'wc.admin.productParameters'">
                  </app-custom-input>
                  <app-input-select-form-field
                    *ngSwitchCase="'CODEBOOK'"
                    [id]="'007_category_parameter'"
                    [formControlName]="'typeDetail'"
                    [options]="codebooksTypes"
                    (selectionChangeEmit)='codeBookChanged($event.target.value)'
                    [translationPrefix]="'wc.admin.productParameters'">
                  </app-input-select-form-field>
                  <app-input-select-form-field
                    *ngSwitchCase="'ENUM'"
                    [id]="'007_ticket_parameter'"
                    [formControlName]="'typeDetail'"
                    [options]="enums"
                    (selectionChangeEmit)='enumChanged($event.target.value)'
                    [translationPrefix]="'wc.admin.productParameters'">
                  </app-input-select-form-field>
                </ng-container>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="parentForm"
                  [controlName]="'integrationKey'"
                  id="008_category_parameter"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.productParameters'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="parentForm"
                  [controlName]="'labelKey'"
                  id="009_category_parameter"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.productParameters'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <ng-container [ngSwitch]="parentForm.controls['type'].value">
                  <app-custom-input
                    [parentGroup]="parentForm"
                    *ngSwitchDefault
                    [controlName]="'defaultValue'"
                    [id]="'004_category_parameter'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.productParameters'">
                  </app-custom-input>
                  <app-custom-input
                    [parentGroup]="parentForm"
                    [controlName]="'defaultValue'"
                    *ngSwitchCase="'INTEGER'"
                    [id]="'004_category_parameter'"
                    [inputType]="'number'"
                    [translationPrefix]="'wc.admin.productParameters'">
                  </app-custom-input>
                  <app-custom-input
                    [parentGroup]="parentForm"
                    [controlName]="'defaultValue'"
                    *ngSwitchCase="'DECIMAL'"
                    [id]="'004_category_parameter'"
                    [inputType]="'number'"
                    [translationPrefix]="'wc.admin.productParameters'">
                  </app-custom-input>
                  <app-input-select-form-field
                    [formControlName]="'defaultValue'"
                    *ngSwitchCase="'BOOLEAN'"
                    [id]="'004_category_parameter'"
                    [options]="inputBoolean"
                    [translationPrefix]="'wc.admin.productParameters'">
                  </app-input-select-form-field>
                  <app-input-date-form-field
                    [parentGroup]="parentForm"
                    *ngSwitchCase="'DATE'"
                    [id]="'004_category_parameter'"
                    [translationPrefix]="'wc.admin.productParameters'"
                    [controlName]="'defaultValue'">
                  </app-input-date-form-field>
                  <ng-container *ngSwitchCase="'CODEBOOK'">
                    <app-input-select-form-field
                      [id]="'004_category_parameter'"
                      [formControlName]="'defaultValue'"
                      (focusin)="loadCodebookHints()"
                      [loading]="codebooksLoading"
                      [options]="codebooks"
                      [translationPrefix]="'wc.admin.productParameters'">
                    </app-input-select-form-field>
                  </ng-container>
                  <ng-container *ngSwitchCase="'ENUM'">
                    <app-input-select-form-field
                      *ngIf="enumItems"
                      [id]="'004_category_parameter'"
                      [formControlName]="'defaultValue'"
                      (focusin)="loadEnumItems()"
                      [options]="enumItems"
                      [loading]="enumItemsLoading"
                      [showEmptyOption]="true"
                      [translationPrefix]="'wc.admin.productParameters'">
                    </app-input-select-form-field>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<form class="card__wrapper" [formGroup]="parentForm" *ngIf="enableLocalizedNames">
  <app-form-texts
    class="card -full"
    style="width: 100%"
    [pcEntityType]="'CategoryParameterConf'"
    [disabled]="parentParametersMode || overwriteParent"
    [textTypeTranslationPrefix]="'wc.admin.technicalCategory'"
    formControlName="texts">
  </app-form-texts>
</form>
<div class="card__wrapper" [formGroup]="parentForm" *ngIf="enableMetaParameters">
  <app-form-parameters
    class="card -full"
    [module]="module"
    [collapsed]="false"
    [disabled]="parentParametersMode"
    [control]="parentForm.get('metaParameters')"
    formControlName="metaParameters">
  </app-form-parameters>
</div>
