import { Component, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminMetaProfileService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { MetaParameterDto, MetaProfileDto } from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MetaProfileComponent } from '../meta-profile.component';
import { environment } from '@environments/environment';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { MetaProfileListComponent } from '@components/meta-description/meta-profile/list/meta-profile-list.component';

@Component({
  selector: 'app-meta-profile-edit',
  templateUrl: './meta-profile-edit.component.html',
  styleUrls: ['./meta-profile-edit.component.scss'],
})
@EnableDynamicLoading({ customName: MetaProfileEditComponent.PAGE_ID })
export class MetaProfileEditComponent extends AbstractPageComponent implements OnInit {
  public static readonly PAGE_ID = 'MetaProfileEditComponent';

  routerOutlet: any;

  editProfileChanged = new EventEmitter<MetaProfileDto>();

  profileDto: MetaProfileDto = {
    id: null,
    name: null,
    metaParameters: [],
  };

  private profileId: string;
  private isNew = true;
  module: string;

  metaProfiles: Array<MetaProfileDto> = [];
  metaParameters: Array<string> = [];

  locales = environment.localization.locales;

  profileForm: FormGroup = this.formBuilder.group({
    id: [{ value: null, disabled: false }, Validators.required],
    name: [{ value: null, disabled: false }],
    metaParameters: this.formBuilder.array([]),
  });

  get idControl(): AbstractControl {
    return this.profileForm.get('id');
  }

  get metaParametersFormArray(): FormArray {
    return this.profileForm.get('metaParameters') as FormArray;
  }

  get metaParameterForms() {
    return (this.profileForm.get('metaParameters') as FormArray).controls as FormGroup[];
  }

  createMetaParameterForm(profileIdControl: AbstractControl): FormGroup {
    return this.formBuilder.group({
      name: [, Validators.required],
      value: [, Validators.required],
    });
  }

  getEditMetaParameters(): MetaParameterDto[] {
    return this.profileDto.metaParameters;
  }

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private adminMetaProfileService: AdminMetaProfileService,
    private stickyMessageService: StickyMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService
  ) {
    super(router, route);
  }

  pageId(): string {
    return MetaProfileEditComponent.PAGE_ID;
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const profileId = this.params.id;
      const module = this.params.module;
      this.module = module;
      if (profileId) {
        if (profileId === '&') {
          this.setProfile(this.profileDto, true);
        } else {
          this.loadProfile(profileId);
        }
      }
    } else {
      this.profileDto = undefined;
    }
  }

  loadProfile(profileId: string) {
    if (profileId) {
      this.adminMetaProfileService
        .getMetaProfileById(this.module, profileId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.setProfile(result, false);
          this.profileId = this.profileDto.id;
        });
    }
  }

  setProfile(profile: MetaProfileDto, isNew: boolean) {
    this.profileDto = profile;
    this.profileForm.patchValue(this.profileDto);

    this.metaParametersFormArray.clear();
    this.profileDto.metaParameters.forEach(metaParameter => {
      const metaParameterForm = this.createMetaParameterForm(this.idControl);
      metaParameterForm.patchValue(metaParameter);
      this.metaParametersFormArray.push(metaParameterForm);
    });

    if (profile) {
      this.editProfileChanged.emit(this.profileDto);
    }

    this.isNew = isNew;
    if (this.isNew) {
      this.profileForm.controls.id.enable();
      this.profileForm.controls.name.enable();
    }
  }

  saveProfile(): boolean {
    FormUtils.validateAllFormFields(this.profileForm);
    if (this.profileForm.valid) {
      const profile = this.profileDto;
      if (profile) {
        Object.keys(this.profileForm.controls).forEach(field => {
          const control = this.profileForm.get(field);
          profile[field] = control.value;
        });
      }
      return true;
    }
    return false;
  }

  save() {
    if (this.saveProfile()) {
      this.appBlockerService.block();
      if (!(this.profileId === null || this.profileId === undefined)) {
        this.profileDto.id = null;
        this.profileDto.created = null;
        this.profileDto.createdBy = null;
        this.profileDto.modified = null;
        this.profileDto.modifiedBy = null;
        this.adminMetaProfileService
          .updateMetaProfile(this.module, this.profileId, this.profileDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getProfileHandler);
      } else {
        this.adminMetaProfileService
          .createMetaProfile(this.module, this.profileDto)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getProfileHandler);
      }
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  public getEditProfile(): MetaProfileDto {
    return this.profileDto;
  }

  public reset() {
    if (this.profileDto && this.profileDto.id) {
      this.appBlockerService.block();
      this.adminMetaProfileService
        .getMetaProfileById(this.module, this.profileDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(this.getProfileHandler);
    } else {
      this.profileForm.reset(this.profileForm);
      this.setProfile(this.profileDto, this.isNew);
    }
  }

  public delete() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.metaProfile.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      this.adminMetaProfileService
        .deleteMetaProfile(this.module, this.profileDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.navigateSibling(MetaProfileListComponent.PAGE_ID);
          this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  public duplicate() {
    const newProfile = MetaProfileComponent.duplicateProfile(this.profileDto);
    this.setProfile(newProfile, true);
    this.profileId = null;
    this.navigateSelf({ module: this.module, id: '&' });
  }

  public getProfileHandler = (profileDto: MetaProfileDto): void => {
    let saving = false;
    if (this.profileDto) {
      saving = true;
    }
    if (profileDto && this.profileDto) {
      if (profileDto.id === this.profileDto.id) {
        this.setProfile(profileDto, this.isNew);
        this.navigateSelf({ module: this.module, id: profileDto.id });
      } else {
        this.setProfile(profileDto, this.isNew);
        this.profileId = profileDto.id;
        this.navigateSelf({ module: this.module, id: profileDto.id });
      }
      if (saving) {
        this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
      }
    }
  };

  ngOnInit() {
    this.adminMetaProfileService.getMetaProfilesParameterNames(this.module, null, null).subscribe(result => {
      this.metaParameters = result;
    });
  }

  cancel() {
    this.navigateSibling(MetaProfileListComponent.PAGE_ID);
  }

  removeParameter(i) {
    this.metaParametersFormArray.removeAt(i);
  }

  addParameter() {
    const metaParameter: MetaParameterDto = {};
    const metaParameterForm = this.createMetaParameterForm(this.idControl);
    metaParameterForm.patchValue(metaParameter);
    this.metaParametersFormArray.push(metaParameterForm);
  }
}
