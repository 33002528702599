import { Injectable } from '@angular/core';
import { AdminContextRulesService } from '@btl/btl-fe-wc-common';
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {ContextRuleDto} from "@btl/admin-bff";

@Injectable({
  providedIn: 'root'
})
export class CustomAdminContextRulesService {

  constructor(private adminContextRulesService: AdminContextRulesService) { }

  getContextRulesList() : Observable<ContextRuleDto[]> {
    return this.adminContextRulesService
      .filterContextRules(
        {
          filtering: [],
          sorting: [{ column: 'id', sortOrder: 'asc' }],
          paging: {
            page: 1,
            pageSize: -1,
          },
        },
        ['data.id']
      )
      .pipe(map(results => {
        const contextRules = [];
        results.data.forEach(contextRul => {
          contextRules.push(contextRul.id);
        });
        return contextRules;
      }));
  }
}
