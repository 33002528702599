<div class="main__header" *ngIf="!disableActions">
  <div class="header-box">
    <div class="icon-box CMS">
      <mat-icon svgIcon="cms"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.cms.content' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      *ngIf="aclService.pageEditAccess"
      id="001_cms_contents"
      (click)="newContentMaster()"
      [routerLink]=""
      mat-raised-button
      color="primary"
      class="btn -primary -xs"
      >{{ 'wc.common.new' | translate }}</a
    >
  </div>
</div>

<div class="card__wrapper">
  <div class="card -full">
    <div class="card__inner">
      <app-cms-contents-filter [filtering]="search.filtering" (filterChanged)="handleFilterChange()">
      </app-cms-contents-filter>
    </div>

    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.cms.staticPage.list' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedContentsDto?.totalItems
                          ? pagedContentsDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedContentsDto?.totalItems
                    }
            }}
          </div>
        </div>
      </div>

      <div class="card__body">
        <app-table-listing-form
          [module]="'cms'"
          [parentColumnName]="[
            'code',
            'name',
            'keys.keyValue',
            'contentTemplateType',
            'state',
            'isInternal',
            'created',
            'modified',
            'modifiedBy'
          ]"
          [childColumnName]="[
            'code',
            'version',
            'state',
            'validFor.startDateTime',
            'validFor.endDateTime',
            'created',
            'modified',
            'modifiedBy'
          ]"
          [columnToTruncate]="['keys.keyValue', 'modifiedBy']"
          [disabledSortColumns]="['keys.keyValue']"
          [fillIdBy]="'code'"
          [nestedTable]="true"
          [disabledParentClickEvent]="disabledParentClick"
          (doubleClickEvent)="!isModal ? showContentVersion($event) : singleSelect($event)"
          [selectMode]="selectMode"
          [getChildDataCallback]="getChildDataCallback"
          [disableExpand]="disableExpand"
          [disableActions]="true"
          [pagedDto]="pagedContentsDto"
          (pageSizeEvent)="pageSizeChanged($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [editable]="aclService.pageEditAccess"
          [translationPrefix]="'wc.admin.cms.content.list'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
