import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AdminAccountService, AppBlockerService } from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccountsSelectModalComponent } from '@components/account-select-modal/accounts-select-modal.component';
import { AccountDto } from '@btl/admin-bff/model/accountDto';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-account-picker, [app-account-picker]',
  templateUrl: './account-picker.component.html',
  styleUrls: ['./account-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountPickerComponent),
      multi: true,
    },
  ],
})
export class AccountPickerComponent implements OnDestroy, ControlValueAccessor {
  @Output()
  readonly selectionChanged: EventEmitter<AccountDto> = new EventEmitter<AccountDto>();

  @Input()
  disabled;

  value;
  account: AccountDto;

  @Input()
  label;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private adminAccountService: AdminAccountService,
    private dialog: MatDialog,
    private appBlockerService: AppBlockerService
  ) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  select() {
    const modalRef = this.dialog.open(AccountsSelectModalComponent);
    const selectModalComponent = modalRef.componentInstance;
    selectModalComponent.dialogRef = modalRef;
    selectModalComponent.disableActions = true;
    selectModalComponent.disableListingActions = true;

    selectModalComponent.selectHandler = account => {
      if (account) {
        this.value = account.id;
      } else {
        this.value = null;
      }

      this.account = account;
      this.propagateChange(this.value);
      this.propagateOnTouched();
      this.selectionChanged.emit(account);
    };
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (obj) {
      this.adminAccountService
        .getAccountById(obj)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(account => {
          this.account = account;
          this.selectionChanged.emit(account);
        });
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
