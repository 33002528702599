<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'code'"
            id="001_cart_events_filter"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.cartEvents'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            [formControlName]="'fromTypeId'"
            id="002_cart_events_filter"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [options]="productEntityTypes"
            (selectionChangeEmit)="clearField('fromId')"
            [translationPrefix]="'wc.admin.cartEvents'">
          </app-input-select-form-field>
          <ng-container [ngSwitch]="filterForm.controls['fromTypeId'].value" class="input__group mr-05" id="fromId">
            <app-input-select-form-field
              *ngSwitchCase="'CatTech'"
              id="003_cart_events_filter"
              [formControlName]="'fromId'"
              (keydown.enter)="handleChange()"
              [options]="techCategories"
              [showEmptyOption]="true"
              [translationPrefix]="'wc.admin.cartEvents'">
            </app-input-select-form-field>
            <app-input-select-form-field
              *ngSwitchCase="'CatBsns'"
              [formControlName]="'fromId'"
              id="004_cart_events_filter"
              (keydown.enter)="handleChange()"
              [options]="businessCategories"
              [translationPrefix]="'wc.admin.cartEvents'">
            </app-input-select-form-field>
            <app-input-picker-form-field
              *ngSwitchCase="'ProdCode'"
              [parentGroup]="filterForm"
              [controlName]="'fromId'"
              id="005_cart_events_filter"
              [valueField]="'productCode'"
              (keydown.enter)="handleChange()"
              [pickerType]="PickerInputType.PRODUCT"
              [selectMode]="false"
              [translationPrefix]="'wc.admin.cartEvents'">
            </app-input-picker-form-field>
            <app-input-picker-form-field
              *ngSwitchCase="'ProdGroup'"
              [parentGroup]="filterForm"
              [controlName]="'fromId'"
              [valueField]="'id'"
              labelField="code"
              id="025_relationship_edit"
              (keydown.enter)="handleChange()"
              [pickerType]="PickerInputType.GROUP"
              [selectMode]="false"
              [translationPrefix]="'wc.admin.cartEvents'">
            </app-input-picker-form-field>
            <app-custom-input
              [controlName]="'fromId'"
              [inputType]="'text'"
              id="008_cart_events_filter"
              (keydown.enter)="handleChange()"
              *ngSwitchDefault
              [translationPrefix]="'wc.admin.cartEvents'"
              [parentGroup]="filterForm">
            </app-custom-input>
            <app-input-select-form-field
              [formControlName]="'eventType'"
              [showEmptyOption]="true"
              id="009_cart_events_filter"
              [options]="eventTypes"
              (keydown.enter)="handleChange()"
              [translationPrefix]="'wc.admin.cartEvents'">
            </app-input-select-form-field>
            <app-input-picker-form-field
              [parentGroup]="filterForm"
              [controlName]="'toProductMasterId'"
              [valueField]="'productCode'"
              [pickerType]="PickerInputType.PRODUCT"
              [selectMode]="false"
              id="010_cart_events_filter"
              (keydown.enter)="handleChange()"
              [translationPrefix]="'wc.admin.cartEvents'">
            </app-input-picker-form-field>
          </ng-container>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-input-date-form-field
          [parentGroup]="filterForm"
          [controlName]="'validFrom'"
          id="013_cart_events_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.cartEvents'">
        </app-input-date-form-field>
        <app-input-date-form-field
          [parentGroup]="filterForm"
          [controlName]="'validTo'"
          id="014_cart_events_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.cartEvents'">
        </app-input-date-form-field>
        <app-input-select-form-field
          [formControlName]="'scopeType'"
          id="015_cart_events_filter"
          [showEmptyOption]="true"
          (keydown.enter)="handleChange()"
          [options]="scopes"
          [translationPrefix]="'wc.admin.cartEvents'">
        </app-input-select-form-field>
        <app-input-select-form-field
          [formControlName]="'connectionType'"
          id="016_cart_events_filter"
          [showEmptyOption]="true"
          (keydown.enter)="handleChange()"
          [options]="productConnectionTypes"
          [translationPrefix]="'wc.admin.cartEvents'">
        </app-input-select-form-field>
        <app-input-select-form-field
          [formControlName]="'connectionDirectionType'"
          id="017_cart_events_filter"
          [showEmptyOption]="true"
          (keydown.enter)="handleChange()"
          [label]="'connectionDirectionType'"
          [options]="productConnectionDirections"
          [translationPrefix]="'wc.admin.cartEvents'">
        </app-input-select-form-field>
        <app-input-select-form-field
          [formControlName]="'connectionLinkType'"
          id="018_cart_events_filter"
          [showEmptyOption]="true"
          [label]="'connectionLinkType'"
          [options]="productLinkType"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.cartEvents'">
        </app-input-select-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</form>
