import { Component, OnInit } from '@angular/core';
import {
  AclService,
  AdminAccountService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { PagedAccountsDto } from '@btl/admin-bff';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { AccountEditComponent } from '@components/account/edit/account-edit.component';

import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss'],
})
@EnableDynamicLoading({ customName: AccountListComponent.PAGE_ID })
export class AccountListComponent extends AbstractListingPageComponent implements OnInit {
  public static readonly PAGE_ID = 'AccountListComponent';

  pageId(): string {
    return AccountListComponent.PAGE_ID;
  }

  pagedAccountsDto: PagedAccountsDto;

  protected deleteDataConfirmationTextKey = 'wc.admin.account.delete.confirmation.text';
  protected deleteMultipleDateConfirmationTextKey = 'wc.admin.accounts.delete.confirmation.text';

  constructor(
    private adminAccountService: AdminAccountService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    protected appBlockerService: AppBlockerService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  editAccount(account, inNewTab: boolean = false) {
    this.navigateSibling(AccountEditComponent.PAGE_ID, { id: account.id }, inNewTab);
  }

  newAccount() {
    this.navigateSibling(AccountEditComponent.PAGE_ID, { id: '&' });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.adminAccountService.deleteAccounts(bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminAccountService.deleteAccount(rowDataId);
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminAccountService.filterAccounts(this.search, null).subscribe(result => {
        if (result) {
          this.pagedAccountsDto = result;
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  anonymizeAccounts() {
    const accountIds = [];
    let internalAccountFound = false;
    let activeAccountFound = false;
    this.getSelectedData().forEach(account => {
      if (!account.external) {
        internalAccountFound = true;
      } else if (account.state === 'ACTIVE') {
        activeAccountFound = true;
      } else {
        accountIds.push(account.id);
      }
    });

    if (internalAccountFound) {
      this.stickyMessageService.addStickyWarningMessage('wc.admin.accounts.anonymize.internalAccountFoundWarring.text');
      return;
    } else if (activeAccountFound) {
      this.stickyMessageService.addStickyWarningMessage('wc.admin.accounts.anonymize.activeAccountFoundWarring.text');
      return;
    }

    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.accounts.anonymize.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = () => {
      this.appBlockerService.block();

      this.adminAccountService
        .anonymizeAccounts(accountIds)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(() => {
          this.loadData();
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }
}
