import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SocketContentDto } from '@btl/admin-bff';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { INode } from '@components/product-catalogue/promotion/edit/promotion-design/promotion-design.component';
import { FormUtils, ServiceUtils } from '@btl/btl-fe-wc-common';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import {
  SocketEditComponent,
  validateContentRange,
  validateEntityTypeDef,
  validateMax,
  validateMinMax,
  validateMinMaxDef,
} from '@components/product-catalogue/socket/edit/socket-edit.component';
import { SocketDto } from '@btl/admin-bff/model/socketDto';

@Component({
  selector: 'app-selected-socket-content, [app-selected-socket-content]',
  templateUrl: './selected-socket-content.component.html',
  styleUrls: ['./selected-socket-content.component.scss'],
})
export class SelectedSocketContentComponent implements OnInit, OnChanges {
  @Input()
  node: INode;

  socketContent: SocketContentDto;

  socket: SocketDto;

  @Input()
  productEntityTypes = [];

  @Input()
  techCategories: Array<string> = [];

  @Output()
  readonly deleteNode = new EventEmitter<INode>();

  @Output()
  readonly closeEmitter = new EventEmitter<null>();

  PickerInputType = PickerInputType;

  form: FormGroup = this.formBuilder.group({
    id: [null],
    entityType: [this.productEntityTypes[0], [Validators.required]],
    entityId: [null, [Validators.required]],
    validFor: this.formBuilder.group({
      startDateTime: [null, Validators.required],
      endDateTime: [],
    }),
    contentQty: this.formBuilder.group({
      min: [null],
      max: [null],
      def: [null],
    }),
  });

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.patchForm();
    this.form.valueChanges.subscribe(valueChange => {
      ServiceUtils.copyWithExclude(valueChange, this.socketContent);
    });
  }

  ngOnChanges(changes: any) {
    this.patchForm();
  }

  validate() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      return true;
    } else {
      return false;
    }
  }

  patchForm() {
    this.socketContent = this.node.socketContent;
    this.socket = this.node.socket;

    this.form
      .get('entityType')
      .setValidators([
        Validators.required,
        validateEntityTypeDef,
        validateDuplicatedEntitiesInContents(this.socket, this.socketContent),
      ]);

    this.form
      .get('entityId')
      .setValidators([Validators.required, validateDuplicatedEntitiesInContents(this.socket, this.socketContent)]);

    this.form
      .get('contentQty')
      .get('min')
      .setValidators([Validators.required, validateContentRange, validateMinMax, validateMinMaxDef]);

    this.form
      .get('contentQty')
      .get('max')
      .setValidators([Validators.required, validateMax, validateMinMax, validateMinMaxDef]);

    this.form
      .get('contentQty')
      .get('def')
      .setValidators([
        Validators.required,
        validateContentRange,
        validateMinMaxDef,
        validateEntityTypeDef,
        validateMinWithContentsDef(this.socket, this.socketContent),
        validateMaxWithContentsDef(this.socket, this.socketContent),
      ]);

    this.form
      .get('validFor')
      .get('startDateTime')
      .setValidators([Validators.required, validateDuplicatedEntitiesInContents(this.socket, this.socketContent)]);

    this.form
      .get('validFor')
      .get('endDateTime')
      .setValidators([validateDuplicatedEntitiesInContents(this.socket, this.socketContent)]);

    if (this.socketContent) {
      this.form.patchValue(this.socketContent);
    }
  }

  clearField(field: string) {
    this.form.controls[field].patchValue(null);
  }

  delete() {
    this.deleteNode.emit(this.node);
  }
}

export const validateMinWithContentsDef = (socket: SocketDto, socketContent: SocketContentDto) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const min = socket?.contentQty.min;
    let sumDef = control.value ? Number(control.value) : 0;
    socket?.contents?.forEach(content => {
      if (content.id !== socketContent.id) {
        sumDef += content.contentQty.def ? Number(content.contentQty.def) : 0;
      }
    });

    if (min && min > sumDef) {
      return { minGtContentsDef: true };
    }
  };
  return null;
};

export const validateMaxWithContentsDef = (socket: SocketDto, socketContent: SocketContentDto) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const max = socket?.contentQty.max;
    let sumDef = control.value ? Number(control.value) : 0;
    socket?.contents?.forEach(content => {
      if (content.id !== socketContent.id) {
        sumDef += content.contentQty.def ? Number(content.contentQty.def) : 0;
      }
    });

    if (max != null && max > -1 && max < sumDef) {
      return { maxGtContentsDef: true };
    }
  };
  return null;
};

export const validateDuplicatedEntitiesInContents = (socket: SocketDto, socketContent: SocketContentDto) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const group: FormGroup = control.parent as FormGroup;
    if (group) {
      let entityTypeControl = null;
      let entityIdControl = null;
      let validFromControl = null;
      let validToControl = null;

      if (group.get('entityType')) {
        entityTypeControl = group.get('entityType');
        entityIdControl = group.get('entityId');
        validFromControl = group.get('validFor').get('startDateTime');
        validToControl = group.get('validFor').get('endDateTime');
      } else {
        entityTypeControl = group.parent.get('entityType');
        entityIdControl = group.parent.get('entityId');
        validFromControl = group.get('startDateTime');
        validToControl = group.get('endDateTime');
      }

      const entityType = entityTypeControl.value;
      const entityId = entityIdControl.value;
      let validFrom = validFromControl.value;
      let validTo = validToControl.value;

      if (validFrom && !(validFrom instanceof Date)) {
        validFrom = new Date(validFrom);
      }

      if (validTo && !(validTo instanceof Date)) {
        validTo = new Date(validTo);
      }

      const duplicatedControls = socket?.contents?.filter(content => {
        if (content.id != socketContent.id) {
          let filterValidFrom = content.validFor.startDateTime;
          let filterValidTo = content.validFor.endDateTime;

          if (filterValidFrom && !(filterValidFrom instanceof Date)) {
            filterValidFrom = new Date(filterValidFrom);
          }

          if (filterValidTo && !(filterValidTo instanceof Date)) {
            filterValidTo = new Date(filterValidTo);
          }

          if (
            entityType === content.entityType &&
            entityId === content.entityId &&
            SocketEditComponent.socketContentTimeCollision(validFrom, validTo, filterValidFrom, filterValidTo)
          ) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      });

      if (entityType && entityId && validFrom && duplicatedControls && duplicatedControls.length > 0) {
        return { duplicatedEntities: true };
      } else {
        SocketEditComponent.deleteControlError(entityTypeControl, 'duplicatedEntities');
        SocketEditComponent.deleteControlError(entityIdControl, 'duplicatedEntities');
        SocketEditComponent.deleteControlError(validFromControl, 'duplicatedEntities');
        SocketEditComponent.deleteControlError(validToControl, 'duplicatedEntities');
      }
    }
    return null;
  };
};
