import { Component, Input, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: '[form-text]',
  templateUrl: './form-text.component.html',
  styleUrls: ['./form-text.component.scss'],
})
export class FormTextComponent implements OnInit {
  @Input() textTypeId: string;
  @Input() contentType: string;
  @Input() translationPrefix = 'wc.admin.products.attributes.localizedParameters.';
  @Input() locale: string;
  @Input() parentGroup: FormGroup;
  @Input() uploadUrl;

  _disabled = false;
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    if (this.editorConfig) {
      this.editorConfig.editable = !this._disabled;
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  editDialogValue: string;
  editDialogValueDisabled: boolean = false;
  editorConfig: AngularEditorConfig;

  modalEditorConfig: AngularEditorConfig;

  constructor(config: NgbModalConfig, private dialog: MatDialog, private formBuilder: FormBuilder) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.editorConfig = {
      editable: !this.disabled,
      spellcheck: true,
      height: 'auto',
      minHeight: '100px',
      maxHeight: '600px',
      placeholder: 'Enter text here...',
      translate: 'no',
      sanitize: false,
      toolbarPosition: 'top',
      uploadUrl: this.uploadUrl,
      toolbarHiddenButtons: [
        [
          'insertVideo',
          'fontName',
          'fontSize',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          'subscript',
          'superscript',
          'strikeThrough',
          'customClasses',
        ],
      ],
    };

    this.modalEditorConfig = {
      editable: !this.disabled,
      spellcheck: true,
      height: '600px',
      minHeight: '100px',
      maxHeight: '600px',
      placeholder: 'Enter text here...',
      translate: 'no',
      sanitize: false,
      toolbarPosition: 'top',
      uploadUrl: this.uploadUrl,
      toolbarHiddenButtons: [
        ['insertVideo', 'fontName', 'fontSize', 'subscript', 'superscript', 'strikeThrough', 'customClasses'],
      ],
    };
  }

  getModalEditorConfig() {
    return {
      editable: !this.disabled,
      spellcheck: true,
      height: '600px',
      minHeight: '100px',
      maxHeight: '600px',
      placeholder: 'Enter text here...',
      translate: 'no',
      sanitize: false,
      toolbarPosition: 'top',
      uploadUrl: this.uploadUrl,
      toolbarHiddenButtons: [
        ['insertVideo', 'fontName', 'fontSize', 'subscript', 'superscript', 'strikeThrough', 'customClasses'],
      ],
    };
  }

  dialogRef;

  openModalEdit(content) {
    this.editDialogValue = this.parentGroup.controls[this.locale].value;
    this.editDialogValueDisabled = this.parentGroup.controls[this.locale].disabled;
    this.dialogRef = this.dialog.open(content, {panelClass: 'form-text-dialog-container'});
  }

  closeEditDialog() {
    this.parentGroup.controls[this.locale].patchValue(this.editDialogValue);
    this.dialogRef.close();
  }
}
