import { Component, Input } from '@angular/core';
import {
  AbstractFilterComponent,
  AdminDynamicEnumService,
  BusinessCategoryService,
  CompareType
} from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-relationships-filter',
  templateUrl: './relationships-filter.component.html',
  styleUrls: ['./relationships-filter.component.scss'],
  animations: [Animations.advancedFilterArrow],
})
export class RelationshipsFilterComponent extends AbstractFilterComponent {
  @Input()
  parentId;
  PickerInputType = PickerInputType;

  relationshipTypes = [];
  productEntityTypes = [];
  techCategories = [];
  businessCategories = [];

  users: string[] = [];

  mapFieldWithCompareType = new Map([
    ['code', CompareTypeDtoEnum.LIKE],
    ['type', CompareTypeDtoEnum.EQUAL],
    ['sourceType', CompareTypeDtoEnum.EQUAL],
    ['sourceId', CompareTypeDtoEnum.LIKE],
    ['targetType', CompareTypeDtoEnum.EQUAL],
    ['targetId', CompareTypeDtoEnum.LIKE],
    ['validAt', CompareTypeDtoEnum.EQUAL],
  ]);

  advancedFilterFields = ['sourceType', 'sourceId', 'targetType', 'targetId'];

  constructor(
    private formBuilder: FormBuilder,
    private enumerationsService: EnumerationsService,
    private businessCategoryService: BusinessCategoryService,
    private adminDynamicEnumService: AdminDynamicEnumService
  ) {
    super();

    this.filterForm = this.formBuilder.group({
      code: [],
      type: [],
      sourceType: [],
      sourceId: [],
      targetType: [],
      targetId: [],
      validAt: [],
    });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.RelationshipType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.relationshipTypes = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductEntityType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.productEntityTypes = result.data.map(value => value.name)
          .filter(name => '[ProdCode, CatTech, ProdGroup]'.indexOf(name) > -1)
          .sort();
      });

    this.enumerationsService
      .getProductTechnicalCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.techCategories = result;
      });

    this.businessCategoryService
      .getBusinessCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        result.forEach(businessCategory => {
          this.businessCategories.push(businessCategory.id);
        });
      });
  }
}
