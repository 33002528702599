import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ContentMasterDto, ContentVersionDto } from '@btl/admin-bff';

@Component({
  selector: '[app-cms-content-versions-item]',
  templateUrl: './cms-content-versions-item.component.html',
  styleUrls: ['./cms-content-versions-item.component.scss'],
})
export class CmsContentVersionsItemComponent {
  @Input()
  contentMasterDtoForVersioning: ContentMasterDto;

  @Input()
  contentVersionDto: ContentVersionDto;

  @Input()
  parentId: string = '';

  @Output()
  readonly approveVersionEvent: EventEmitter<ContentVersionDto> = new EventEmitter<ContentVersionDto>();

  @Output()
  readonly archiveVersionEvent: EventEmitter<ContentVersionDto> = new EventEmitter<ContentVersionDto>();

  @Output()
  readonly deleteVersionEvent: EventEmitter<ContentVersionDto> = new EventEmitter<ContentVersionDto>();

  constructor(private router: Router, private route: ActivatedRoute) {}

  show(duplicate = false) {
    const navigationExtras: NavigationExtras = {
      state: {
        duplicate: duplicate,
      },
      relativeTo: this.route,
    };
    this.router.navigate([this.contentVersionDto.id], navigationExtras);
  }

  archive() {
    this.archiveVersionEvent.emit(this.contentVersionDto);
  }

  approve() {
    this.approveVersionEvent.emit(this.contentVersionDto);
  }

  duplicate() {
    this.show(true);
  }

  delete() {
    this.deleteVersionEvent.emit(this.contentVersionDto);
  }
}
