<!-- TODO && !multiSelectMode-->
<div *ngIf="!singleSelectMode" class="main__header">
  <div class="header-box">
    <div class="icon-box META_PROFILES">
      <mat-icon svgIcon="metaProfiles"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.metaProfiles' | translate }}</div>
  </div>
  <div class="page__menu">
    <!--   TODO *ngIf="aclService.pageEditAccess"-->
    <a
      id="001_profile_listing"
      (click)="newProfile()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      color="primary"
      mat-raised-button
      [title]="'wc.admin.metaProfiles.new.title' | translate"
      class="btn -primary -xs"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>

<div class="card__wrapper">
  <!-- card with profile module attribute -->
  <div class="card -full" *ngIf="!disableListingActions">
    <div class="card__inner">
      <div class="card__body">
        <form [formGroup]="moduleForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-input-select-form-field
                  *ngIf="modules"
                  [formControlName]="'module'"
                  [options]="modules"
                  (selectionChangeEmit)="setModule($event)"
                  id="003_profile_listing"
                  [translationPrefix]="'wc.admin.metaProfile'">
                </app-input-select-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="card__wrapper">
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <app-meta-profile-filter
        (filterChanged)="handleFilterChange()"
        [filtering]="search.filtering"
        [module]="module"></app-meta-profile-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.metaProfiles.profileListing' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedProfilesDto?.totalItems
                          ? pagedProfilesDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedProfilesDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action" *ngIf="!disableListingActions">
          <button
            id="002_profile_listing"
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedData()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="['id', 'name', 'created', 'modified', 'modifiedBy']"
          [columnToTruncate]="['created', 'modified', 'modifiedBy']"
          [dontShowOnMobile]="['created', 'modified', 'modifiedBy']"
          (editButtonEvent)="editProfile($event)"
          (editInNewTabButtonEvent)="editProfile($event, true)"
          (deleteButtonEvent)="deleteData($event)"
          (duplicateButtonEvent)="duplicateProfile($event, true)"
          [pagedDto]="pagedProfilesDto"
          [disableActions]="disableActions"
          [selectMode]="selectMode"
          [singleSelectMode]="singleSelectMode"
          [multiSelectMode]="multiSelectMode"
          (pageSizeEvent)="pageSizeChanged($event)"
          [translationPrefix]="'wc.admin.metaProfile'"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [editable]="aclService.pageEditAccess"
          (doubleClickEvent)="singleSelectMode ? singleSelect($event) : editProfile($event)">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
