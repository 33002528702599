import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-notification-type-locale-modal',
  templateUrl: './notification-type-locale-modal.component.html',
  styleUrls: ['./notification-type-locale-modal.component.scss'],
})
export class NotificationTypeLocaleModalComponent {
  @Input()
  dialogRef;

  @Output()
  readonly saveEvent: EventEmitter<string> = new EventEmitter();

  formGroup = this.formBuilder.group({
    locale: [null, [Validators.required]],
  });

  locales = environment.localization.locales;

  constructor(private formBuilder: FormBuilder) {}

  save(): void {
    const value = this.formGroup.get('locale').value;
    if (value) {
      this.saveEvent.emit(value);
    } else {
      this.formGroup.get('locale').markAsTouched();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
