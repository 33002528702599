<div class="modal-header preview-modal-header">
  <h2 mat-dialog-title>
    {{ 'wc.admin.cms.content.widget.preview.modal' | translate }}
  </h2>

  <mat-dialog-content class="mat-typography">
    <iframe [src]="previewUrl | safeUrl"></iframe>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <label>{{ previewUrl }}</label>
    <button id="001_cms_contents_preview_edit" [cdkCopyToClipboard]="previewUrl" color="primary" mat-raised-button>
      {{ 'wc.common.copyToClipboard.button' | translate }}
    </button>
    <button id="002_cms_contents_preview_edit" color="primary" mat-raised-button (click)="cancel()">
      {{ 'wc.common.cancel.button' | translate }}
    </button>
  </mat-dialog-actions>
</div>
