import { Injectable } from '@angular/core';
import {
  AdminDataTypeEnumService,
  AdminDynamicEnumService,
  CurrentLocaleService,
  InputSelectEnumService
} from '@btl/btl-fe-wc-common';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TechnicalCategoryDto } from '@btl/admin-bff';

@Injectable({
  providedIn: 'root',
})
export class EnumerationsService {
  // source name constants for the AdminDynamicEnumService call in admin-bff
  public static readonly ACCOUNT = 'account';
  public static readonly ACL = 'acl';
  public static readonly BANNER = 'banner';
  public static readonly CODEBOOK = 'cb';
  public static readonly DCR = 'dcr';
  public static readonly DMS = 'dms';
  public static readonly LT = 'lt';
  public static readonly PC = 'pc';
  public static readonly PRODRATING = 'prodrating';
  public static readonly TICKET = 'ticket';
  public static readonly NOTIFICATION = 'notification';
  public static readonly CMS = 'cms';
  public static readonly CRM = 'crm';
  public static readonly STOCK = 'stock';

  constructor(
    private adminDynamicEnumService: AdminDynamicEnumService,
    private adminDataTypeEnumService: AdminDataTypeEnumService,
    private currentLocaleService: CurrentLocaleService
  ) {}

  getSocketItemEntityType(): Observable<Array<string>> {
    return this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductEntityType')
      .pipe(
        map(result => {
          return result.data
            .filter(entityType => entityType.name === 'ProdCode' || entityType.name === 'CatTech')
            .map(value => value.name)
            .sort();
        })
      );
  }

  getProductSocketCategories(): Observable<Array<string>> {
    return this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductSocketCategory')
      .pipe(
        map(result => {
          return result.data.map(value => value.name).sort();
        })
      );
  }

  getProductTechnicalCategories(): Observable<string[]> {
    return this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.CategoryType')
      .pipe(map(result => result.data.map(e => e.name).sort()));
  }

  getProductTechnicalCategoryDtos(): Observable<TechnicalCategoryDto[]> {
    return this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.CategoryType')
      .pipe(
        map(result =>
          result.data
            .map(techCatEnum => {
              return { id: techCatEnum.name, parentId: techCatEnum.fields.parentCategory as string };
            })
            .sort((a, b) => a.id.localeCompare(b.id))
        )
      );
  }

  getLocalizedTextModules(): Observable<string[]> {
    return this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.LT, 'com.emeldi.ecc.be.lt.enums.Module')
      .pipe(map(result => result.data.map(e => e.name).sort()));
  }

  getDataTypeEnumItemsMap(source: string, enumName: string): Observable<{ [key: string]: string }> {
    const currentLanguage = this.currentLocaleService.getCurrentLanguage();
    return this.adminDataTypeEnumService
      .getEnumByName(source, enumName)
      .pipe(map(value => InputSelectEnumService.dataTypeEnumItemTextMap(value.items, currentLanguage)));
  }

  getDataTypeEnums(source: string): Observable<string[]> {
    return this.adminDataTypeEnumService.getEnums(source, ['name']).pipe(map(result => result.map(e => e.name).sort()));
  }

  getDataTypeEnumItems(source: string, enumName: string): Observable<string[]> {
    return this.adminDataTypeEnumService
      .getEnumByName(source, enumName, ['items.item'])
      .pipe(map(result => result.items.map(i => i.item).sort()));
  }
}
