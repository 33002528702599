import { CompareType } from '@btl/btl-fe-wc-common';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

export class AclUtils {
  public static getEmptyResourceIdentificationFilter(resourceType: string) {
    return {
      filtering: [
        {
          column: 'resourceType',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: resourceType,
        },
        {
          column: 'resourceIdentification',
          compareType: CompareTypeDtoEnum.LIKE,
          value: JSON.stringify({}),
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
  }

  public static getCategoryResourceIdentificationFilter(resourceType: string, categoryId) {
    return {
      filtering: [
        {
          column: 'resourceType',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: resourceType,
        },
        {
          column: 'resourceIdentification',
          compareType: CompareTypeDtoEnum.LIKE,
          value: JSON.stringify({ category: categoryId }),
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
  }

  public static getProductResourceIdentificationFilter(resourceType: string, productCode) {
    return {
      filtering: [
        {
          column: 'resourceType',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: resourceType,
        },
        {
          column: 'resourceIdentification',
          compareType: CompareTypeDtoEnum.LIKE,
          value: JSON.stringify({ productCode: productCode }).slice(1, -1),
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
  }
}
