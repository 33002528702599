import { Component, OnInit } from '@angular/core';
import {
  AclService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  ServiceUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import {
  BulkPatchListDto,
  NotificationDto,
  NotificationFrontendService,
  NotificationListDto,
  PagedNotificationsDto,
  SortDto,
} from '@btl/order-bff';
import {
  NotificationNotificationsEditComponent,
} from '@components/notification/notifications/edit/notification-notifications-edit.component';

import { AbstractListingPageComponent, BulkDelete } from '@helpers/abstract-listing-page-component';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-notification-notifications-list',
  templateUrl: './notification-notifications-list.component.html',
  providers: [NotificationFrontendService],
})
@EnableDynamicLoading({ customName: NotificationNotificationsListComponent.PAGE_ID })
export class NotificationNotificationsListComponent extends AbstractListingPageComponent implements OnInit {
  public static readonly PAGE_ID = 'NotificationNotificationsListComponent';

  pageId(): string {
    return NotificationNotificationsListComponent.PAGE_ID;
  }

  pagedNotificationsDto: PagedNotificationsDto;

  initSorting = [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }];

  constructor(
    public aclService: AclService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    private notificationFrontendService: NotificationFrontendService,
    protected appBlockerService: AppBlockerService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  onSortChange(sorting: MatSort) {
    if (['recipients.recipientName', 'recipients.address', 'recipients.partyRefNo'].includes(sorting.active)) {
      return;
    }
    if (sorting.direction !== '') {
      this.search.sorting = [{ column: sorting.active, sortOrder: sorting.direction }];
    } else {
      this.search.sorting = [];
    }
    this.loadData();
  }

  filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.pagedNotificationsDto = null;

      const serializedFilter = JSON.stringify(this.search.filtering);
      const serializedSorting = ServiceUtils.getSerializedSorting(this.search.sorting);

      this.notificationFrontendService
        .filterNotifications(
          null,
          serializedFilter,
          serializedSorting,
          this.search.paging.page,
          this.search.paging.pageSize,
          this.search.fields,
        )
        .pipe(takeUntil(this.onDestroy$))
        .pipe(catchError(() => of(null)))
        .subscribe((result: PagedNotificationsDto) => {
          if (result) {
            this.pagedNotificationsDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteMultipleRows(bulkDelete: BulkDelete): Observable<any> {
    return this.notificationFrontendService.bulkDeleteNotification(bulkDelete);
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.notificationFrontendService.deleteNotification(rowDataId);
  }

  editNotification(notificationDto: NotificationDto, inNewTab: boolean = false): void {
    this.navigateSibling(NotificationNotificationsEditComponent.PAGE_ID, { id: notificationDto.id }, inNewTab);
  }

  resendNotification(notificationDto: NotificationDto): void {
    if (!['COMPLETED', 'CANCELLED', 'FAILED'].includes(notificationDto.state)) {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.notification.resend.error');
    } else {
      this.appBlockerService.block();
      this.notificationFrontendService
        .patchNotification(notificationDto.id, { state: 'INITIAL' })
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: NotificationDto) => {
          this.loadData();
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        });
    }
  }

  resendCheckedNotifications(): void {
    let isValid = true;
    const bulkPatchListDto: BulkPatchListDto = {
      bulkPatchList: [],
    };
    for (const notificationDto of this.getSelectedData()) {
      bulkPatchListDto.bulkPatchList.push({
        id: notificationDto.id,
        objectAsMap: { state: 'INITIAL' },
      });

      if (!['COMPLETED', 'CANCELLED', 'FAILED'].includes(notificationDto.state)) {
        isValid = false;
        break;
      }
    }

    if (isValid) {
      this.appBlockerService.block();
      this.notificationFrontendService
        .bulkPatchNotification(bulkPatchListDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: NotificationListDto) => {
          this.loadData();
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
        });
    } else {
      this.stickyMessageService.addStickyErrorMessage('wc.admin.notification.resend.error');
    }
  }
}
