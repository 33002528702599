<div>
  <h2 mat-dialog-title>
    {{ 'wc.admin.resource.resources.parameter' | translate }}
    <button [attr.aria-label]="'wc.common.close' | translate" mat-dialog-close type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </h2>

  <mat-dialog-content class="mat-typography">
    <div class=".mat-drawer-container">
      <div class="mat-sidenav-content">
        <div class="main_content">
          <form [formGroup]="formGroup" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="formGroup"
                    [controlName]="'name'"
                    [inputType]="'text'"
                    [disabled]="false"
                    [translationPrefix]="'wc.admin.resource.resources.parameter'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="formGroup"
                    [controlName]="'value'"
                    [inputType]="'text'"
                    [disabled]="false"
                    [translationPrefix]="'wc.admin.resource.resources.parameter'">
                  </app-custom-input>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button color="primary" mat-raised-button (click)="save()" [routerLink]="">
      {{ 'wc.common.save' | translate }}
    </button>
    <button color="primary" mat-raised-button (click)="cancel()" [routerLink]="">
      {{ 'wc.common.cancel.button' | translate }}
    </button>
  </mat-dialog-actions>
</div>
