import { Component, Input } from '@angular/core';
import {
  AclService,
  AdminAgreementTypeService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { PagedAgreementTypesDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {
  AgreementTypeEditComponent,
} from '@components/crm/agreement-type/agreement-type-edit/agreement-type-edit.component';
import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';

import { Observable } from 'rxjs';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';

@Component({
  selector: 'app-agreementTypes-listing',
  templateUrl: './agreement-types-listing.component.html',
})
@EnableDynamicLoading({ customName: AgreementTypesListingComponent.PAGE_ID })
export class AgreementTypesListingComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'AgreementTypesListingComponent';

  pageId(): string {
    return AgreementTypesListingComponent.PAGE_ID;
  }

  @Input()
  selectedAgreementTypes = [];

  pagedAgreementTypesDto: PagedAgreementTypesDto;

  constructor(
    private adminAgreementTypeService: AdminAgreementTypeService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    protected appBlockerService: AppBlockerService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminAgreementTypeService.deleteAgreementType(rowDataId);
  }

  editAgreementType(agreementTypeDto, inNewTab: boolean = false) {
    this.navigateSibling(AgreementTypeEditComponent.PAGE_ID, { id: agreementTypeDto.id }, inNewTab);
  }

  newAgreementType() {
    this.editAgreementType({
      id: 'newAgreementType',
    });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      let validOnly = false;
      const validOnlyFilter = this.search.filtering.find(filter => filter.column === 'validOnly');
      if (validOnlyFilter) {
        validOnly = validOnlyFilter.value;
        this.search.filtering = this.search.filtering.filter(filter => filter.column !== 'validOnly');
      }
      this.adminAgreementTypeService
        .filterAgreementTypes(this.search, null, validOnly)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedAgreementTypesDto = result;
            if (validOnlyFilter) {
              this.search.filtering.push(validOnlyFilter);
            }
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }
}
