<div class="main__header">
  <h1>{{ 'wc.admin.campaign.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="001_campaign_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      id="002_campaign_edit"
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="003_campaign_edit" mat-menu-item (click)="duplicate()" *ngIf="campaignDto?.id" [routerLink]=""
      >
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="004_campaign_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="005_campaign_edit" mat-menu-item (click)="delete()" *ngIf="campaignDto?.id" [routerLink]=""
      >
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="006_campaign_edit" (click)="cancel()" class="btn cancel-button">{{
      'wc.common.cancel.button' | translate
    }}</a>
  </div>
</div>

<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

<div class="card__wrapper">
  <!-- overview card -->
  <div class="card -full" [hidden]="campaignDto.id === null && pagedCampaignMembers?.data.length < 1">
    <div class="card-wrap">
      <div class="overview-container">
        <div class="chart-container -two">
          <div class="chart">
            <div class="inner_card_content" style="flex-flow: row; padding-top: 10px; column-gap: 25px">
              <div
                class="icon-box PC"
                style="width: 50px; height: 50px; border-radius: 10px; display: flex; align-items: center">
                <mat-icon svgIcon="moneyBag" style="width: 100%; color: rgba(255, 172, 18, 1)"></mat-icon>
              </div>
              <div fxLayout="column">
                <div style="font-size: 42px; line-height: 56px; font-weight: 600; white-space: nowrap">$12,824</div>
                <div style="font-size: 16px; line-height: 21px">Total revenue</div>
              </div>
            </div>
          </div>
          <div class="chart">
            <div class="inner_card_content" style="flex-flow: row; padding-top: 10px; column-gap: 25px">
              <div
                class="icon-box GREEN"
                style="width: 50px; height: 50px; border-radius: 10px; display: flex; align-items: center">
                <mat-icon svgIcon="acceptedPeople" style="width: 100%"></mat-icon>
              </div>
              <div fxLayout="column">
                <div style="font-size: 42px; line-height: 56px; font-weight: 600; white-space: nowrap">32,580</div>
                <div style="font-size: 16px; line-height: 21px">Customer accepted campaign</div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-container -full">
          <div class="chart">
            <div class="inner_card">
              <div class="inner_card_header">
                <div class="header__title" style="font-size: 16px; font-weight: 600">Total views</div>
                <mat-form-field class="inner_card_select">
                  <mat-select [(value)]="selectedViewReportDate" disableOptionCentering panelClass="dashboard-dropdown">
                    <mat-option *ngFor="let date of dates" [value]="date">{{ date }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="inner_card_content" style="flex-flow: row; justify-content: space-between; padding-top: 10px">
              <div style="width: 100%">
                <div class="chart-container-line" style="height: 220px; width: 25vw">
                  <canvas #lineCanvas></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-container -full">
          <div class="chart">
            <div class="inner_card">
              <div class="inner_card_header">
                <div class="header__title" style="font-size: 16px; font-weight: 600">Audience Response Total</div>
                <mat-form-field class="inner_card_select">
                  <mat-select [(value)]="selectedReportDate" disableOptionCentering panelClass="dashboard-dropdown">
                    <mat-option *ngFor="let date of dates" [value]="date">{{ date | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="inner_card_content" style="flex-flow: row; justify-content: space-between; padding-top: 10px">
              <div style="flex: 1">
                <mat-list class="dashboard_inner_list">
                  <mat-list-item>
                    <div class="dashboard_inner_list-item">
                      <div class="dashboard_inner_list-item-iconBox" style="background: rgba(252, 172, 18, 1)"></div>
                      <div class="dashboard_inner_list-item-name" style="white-space: nowrap">
                        Did Not See Campaig Yet
                      </div>
                    </div>
                    <div class="dashboard_inner_list-item-description">426,321 Customer + 57%</div>
                  </mat-list-item>
                  <mat-list-item>
                    <div class="dashboard_inner_list-item">
                      <div class="dashboard_inner_list-item-iconBox" style="background: rgba(0, 116, 232, 1)"></div>
                      <div class="dashboard_inner_list-item-name">Saw Campaign</div>
                    </div>
                    <div class="dashboard_inner_list-item-description">209,651 Customers - 43%</div>
                  </mat-list-item>
                  <mat-list-item>
                    <div class="dashboard_inner_list-item">
                      <div class="dashboard_inner_list-item-iconBox" style="background: rgba(40, 167, 69, 1)"></div>
                      <div class="dashboard_inner_list-item-name">Accepted Campaign</div>
                    </div>
                    <div class="dashboard_inner_list-item-description">32,508 Customers - 88%</div>
                  </mat-list-item>
                  <mat-list-item>
                    <div class="dashboard_inner_list-item">
                      <div class="dashboard_inner_list-item-iconBox" style="background: rgba(253, 60, 74, 1)"></div>
                      <div class="dashboard_inner_list-item-name">Dislike Campaign</div>
                    </div>
                    <div class="dashboard_inner_list-item-description">8,021 Customers - 12%</div>
                  </mat-list-item>
                </mat-list>
              </div>
              <div style="width: 100%; flex: 1">
                <div class="chart-container-dougnut" style="height: 130px; width: 10vw">
                  <canvas #doughnutCanvas></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- card with main core attributes -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.campaign.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="getEditCampaign()" [formGroup]="campaignForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="campaignForm"
                  id="007_campaign_edit"
                  [controlName]="'campaignCode'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.campaign'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'state'"
                  id="008_campaign_edit"
                  [options]="campaignStates"
                  [translationPrefix]="'wc.admin.campaign'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'campaignChannel'"
                  id="009_campaign_edit"
                  [options]="campaignChannels"
                  [translationPrefix]="'wc.admin.campaign'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="campaignForm"
                  [controlName]="'note'"
                  id="010_campaign_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.campaign'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-input-date-form-field
                  [parentGroup]="campaignForm"
                  [controlName]="'validFrom'"
                  id="011_campaign_edit"
                  [translationPrefix]="'wc.admin.campaign'">
                </app-input-date-form-field>
              </div>
              <div class="form__row">
                <ng-container
                  *ngIf="
                    campaignForm.get('campaignChannel').value === 'WEB' ||
                    campaignForm.get('campaignChannel').value === 'CALL'
                  ">
                  <app-input-select-form-field
                    [formControlName]="'campaignSlot'"
                    id="012_campaign_edit"
                    [options]="campaignSlots"
                    [showEmptyOption]="campaignForm.get('campaignChannel').value === 'CALL'"
                    [translationPrefix]="'wc.admin.campaign'">
                  </app-input-select-form-field>
                </ng-container>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'campaignType'"
                  [options]="campaignTypes"
                  id="013_campaign_edit"
                  [translationPrefix]="'wc.admin.campaign'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-input-date-form-field
                  [parentGroup]="campaignForm"
                  id="014_campaign_edit"
                  [controlName]="'validTo'"
                  [translationPrefix]="'wc.admin.campaign'">
                </app-input-date-form-field>
              </div>
              <div class="form__row">
                <ng-container
                  *ngIf="
                    campaignForm.get('campaignChannel').value === 'WEB' ||
                    campaignForm.get('campaignChannel').value === 'CALL'
                  ">
                  <app-input-picker-form-field
                    [parentGroup]="campaignForm"
                    [translationPrefix]="'wc.admin.campaign'"
                    id="015_campaign_edit"
                    [pickerType]="PickerInputType.CMS"
                    [labelField]="'code'"
                    [valueField]="'code'"
                    [selectMode]="false"
                    [controlName]="'campaignCmsContent'">
                  </app-input-picker-form-field>
                </ng-container>
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'campaignDirection'"
                  id="016_campaign_edit"
                  [options]="campaignDirections"
                  (selectionChangeEmit)="setAudienceListColumnNames($event)"
                  [translationPrefix]="'wc.admin.campaign'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="campaignForm"
                  [controlName]="'extId'"
                  id="017_campaign_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.campaign'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <ng-container
                  *ngIf="
                    campaignForm.get('campaignChannel').value === 'NOTIFICATION' ||
                    campaignForm.get('campaignChannel').value === 'CALL'
                  ">
                  <app-input-picker-form-field
                    [parentGroup]="campaignForm"
                    [translationPrefix]="'wc.admin.campaign'"
                    [pickerType]="PickerInputType.NOTIFICATION_TYPE"
                    [selectMode]="false"
                    id="018_campaign_edit"
                    [labelField]="'notificationType'"
                    [valueField]="'notificationType'"
                    [controlName]="'campaignNotifType'">
                  </app-input-picker-form-field>
                </ng-container>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="campaignForm"
                  [controlName]="'campaignOfferCode'"
                  id="019_campaign_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.campaign'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="campaignForm"
                  id="020_campaign_edit"
                  [controlName]="'campaignSkills'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.campaign'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- campaign audience card -->
  <div class="card -full" *ngIf="pagedCampaignMembers">
    <div class="card__inner">
      <div class="card__header" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.campaign.audience' | translate }}</span>
        </div>
      </div>
      <div class="card__actions">
        <form class="form">
          <div class="form__row">
            <div class="input__group">
              <div class="form__row">
                <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                  <mat-label>{{ 'wc.admin.campaign.responseCode' | translate }}</mat-label>
                  <mat-select
                    disableOptionCentering
                    class="form__input"
                    panelClass="form-dropdown"
                    [(value)]="selectedResponseCode"
                    (selectionChange)="filterByResponseCode($event)"
                    id="021_campaign_edit">
                    <mat-option id="021_campaign_edit_empty" [value]="null">NONE</mat-option>
                    <mat-option [id]="option" *ngFor="let option of campaignResponseCode" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="audienceListColumnNames"
          [module]="'campaign'"
          [pagedDto]="pagedCampaignMembers"
          [fillIdBy]="'id'"
          [selectMode]="false"
          [disableActions]="true"
          (pageSizeEvent)="pageSizeChanged($event)"
          [translationPrefix]="'wc.admin.campaign'"
          [editable]="aclService.pageEditAccess">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
