import { Component } from '@angular/core';
import { BulkDeleteListDto, GatewayDto, GatewayListDto, PagedGatewaysDto } from '@btl/admin-bff';
import {
  AclService,
  AdminGatewayService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { SortDto } from '@btl/order-bff';
import { TranslateService } from '@ngx-translate/core';
import {
  NotificationGatewayEditComponent,
} from '@components/notification/gateways/edit/notification-gateway-edit.component';

import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notification-gateway-list',
  templateUrl: './notification-gateway-list.component.html',
})
@EnableDynamicLoading({ customName: NotificationGatewayListComponent.PAGE_ID })
export class NotificationGatewayListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'NotificationGatewayListComponent';

  pageId(): string {
    return NotificationGatewayListComponent.PAGE_ID;
  }

  pagedGatewaysDto: PagedGatewaysDto;

  initSorting = [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }];

  constructor(
    public aclService: AclService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    private translateService: TranslateService,
    protected confirmationDialogService: ConfirmationDialogService,
    private adminGatewayService: AdminGatewayService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  newGateway(): void {
    this.editGateway({
      id: 'newGateway',
    });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminGatewayService
        .filterGateways(this.search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: PagedGatewaysDto) => {
          if (result) {
            this.pagedGatewaysDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminGatewayService.deleteGateway(rowDataId);
  }

  editGateway(gatewayDto: GatewayDto, inNewTab: boolean = false): void {
    this.navigateSibling(NotificationGatewayEditComponent.PAGE_ID, { id: gatewayDto.id }, inNewTab);
  }


  deleteCheckedGateways(): void {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.notification.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      this.appBlockerService.block();
      const bulkDeleteListDto: BulkDeleteListDto = {
        bulkDeleteList: [],
      };

      this.getSelectedData().forEach((gateway: GatewayDto) => {
        bulkDeleteListDto.bulkDeleteList.push({
          id: gateway.id,
        });
      });

      this.adminGatewayService
        .bulkDeleteGateway(bulkDeleteListDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: GatewayListDto) => {
          this.checkBulkDelete(bulkDeleteListDto, result);
        });
      confirmationDialogComponent.dialogReference.close();
    };
  }

  private checkBulkDelete(request: BulkDeleteListDto, response: GatewayListDto): void {
    this.loadData();
    if (request.bulkDeleteList.length === response?.data?.length) {
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    } else {
      const notRemovedIds = [];
      for (const item of request.bulkDeleteList) {
        if (!response.data.find(i => i.id === item.id)) {
          notRemovedIds.push(item.id);
        }
      }

      if (notRemovedIds.length) {
        const idsAsStr = `[${notRemovedIds.join(', ')}]`;
        const text = this.translateService.instant('wc.admin.notification.delete.bulk.error', { ids: idsAsStr });
        this.stickyMessageService.addStickyErrorMessage(text);
      }
    }
  }
}
