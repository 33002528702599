import { Component, Input, ViewChild } from '@angular/core';
import { Search } from '@btl/btl-fe-wc-common';
import { MetaProfileListComponent } from '@components/meta-description/meta-profile/list/meta-profile-list.component';

@Component({
  selector: 'app-meta-profiles-select-modal',
  templateUrl: './meta-profiles-select-modal.component.html',
  styleUrls: ['./meta-profiles-select-modal.component.scss'],
})
export class MetaProfilesSelectModalComponent {
  @ViewChild(MetaProfileListComponent, { static: true })
  profileListComponent: MetaProfileListComponent;

  @Input()
  module;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  @Input()
  disableListingActions = false;

  @Input()
  selectMode = false;

  @Input()
  disableActions = false;

  multiSelectMode = false;

  singleSelectMode = true;

  activeMultiSelectMode() {
    this.multiSelectMode = true;
    this.singleSelectMode = false;
  }

  selectEvent() {
    if (this.singleSelectMode) {
      const profileDto = this.profileListComponent.getSelectedData()[0];
      if (profileDto) {
        this.selectHandler(profileDto);
        this.dialogRef.close();
      }
    } else {
      const profileDtos = this.profileListComponent.getSelectedData();
      if (profileDtos && profileDtos.length > 0) {
        this.selectHandler(profileDtos);
        this.dialogRef.close();
      }
    }
  }

  singleSelect(selectedDto) {
    this.selectHandler(selectedDto);
    this.dialogRef.close();
  }

  getSearch(): Search {
    return this.profileListComponent.search;
  }

  cancel() {
    this.dialogRef.close();
  }
}
