import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-relationships-valid-to-modal',
  templateUrl: './relationships-valid-to-modal.component.html',
  styleUrls: ['./relationships-valid-to-modal.component.scss'],
})
export class RelationshipsValidToModalComponent {
  @Input()
  dialogRef;

  @Input()
  parentGroup: FormGroup;

  @Input()
  selectHandler;

  @Output()
  readonly setRelationshipsValidToEvent: EventEmitter<Date> = new EventEmitter<Date>();

  form: FormGroup = this.formBuilder.group({
    validTo: [null],
  });

  constructor(private formBuilder: FormBuilder) {}

  doSetValidTo() {
    if (this.form.valid) {
      this.setRelationshipsValidToEvent.emit(this.form.controls.validTo.value);
      this.dialogRef.close();
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
