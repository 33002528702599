import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProductEditService } from '@service/product-edit.service';
import { ProductDto } from '@btl/admin-bff';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconMapUtils } from '@helpers/iconMap-utils';
import { Animations } from '@helpers/animations';
import { PageModule, PageModuleService } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [Animations.menuExpandArrow],
})
export class LeftMenuComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  modules: PageModule[];
  mainModules: PageModule[];
  product: ProductDto;
  productEditMenu;

  @Input()
  closed: boolean;

  @Input()
  isHandset;

  @Output()
  readonly secondMenuEvent = new EventEmitter<boolean>();

  @Output()
  readonly toggleClickEvent = new EventEmitter<boolean>();

  @Output()
  readonly collapsedEvent = new EventEmitter<boolean>();

  @Input()
  secondMenu = false;

  @Input()
  collapsed = false;

  expanded = false;

  constructor(private productEditService: ProductEditService) {
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(product => {
      this.product = product;
      if (this.product) {
        this.secondMenuEvent.emit(true);
      } else {
        this.secondMenuEvent.emit(false);
      }
    });

    this.productEditService.productEditMenuChanged.pipe(takeUntil(this.onDestroy$)).subscribe(productEditMenu => {
      this.productEditMenu = productEditMenu;
    });
  }

  public static getCodebookModulesByParentCode(parentCode: string, modules: PageModule[]): PageModule[] {
    const retModules = [];
    if (modules) {
      modules.forEach(codebookModuleDto => {
        if (parentCode === codebookModuleDto.parentCode && this.isUsableInMenu(codebookModuleDto)) {
          retModules.push(codebookModuleDto);
        }
      });
    }
    return retModules;
  }

  private static isUsableInMenu(codebookModuleDto: PageModule): boolean {
    return !codebookModuleDto.priority || codebookModuleDto.priority >= 0;
  }

  ngOnInit() {
    PageModuleService.getPageModulesSortedByPriority().subscribe(pageModules => {
      pageModules.forEach(codeBook => {
        codeBook.cssClass = IconMapUtils.getIcon(codeBook.code);
      });
      this.modules = pageModules.filter(module => module.dropZones.indexOf('Menu') > -1);
      this.mainModules = LeftMenuComponent.getCodebookModulesByParentCode(null, this.modules);
    });
  }

  getCodebookModulesByParentCode(parentCode: string, modules: PageModule[]): PageModule[] {
    return LeftMenuComponent.getCodebookModulesByParentCode(parentCode, modules);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  toggleClick() {
    this.collapsed = !this.collapsed;
  }

  handleExpandedClick() {
    this.expanded = !this.expanded;
  }
}
