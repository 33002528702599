<div class="main__header">
  <div class="header-box">
    <div class="icon-box ACCESS">
      <mat-icon svgIcon="accountEntity"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.accountEntity' | translate }}</div>
  </div>
  <div *ngIf="editable" class="page__menu">
    <a
      *ngIf="grantMode && aclService.pageEditAccess"
      id="001_accounts_entity_list"
      (click)="new()"
      [routerLink]=""
      mat-raised-button
      color="primary"
      class="btn -primary -xs"
      >{{ 'wc.admin.new' | translate }}</a
    >
    <button
      id="002_accounts_entity_list"
      *ngIf="aclService.pageEditAccess"
      (click)="bulkUpdate()"
      [routerLink]=""
      mat-raised-button
      color="primary"
      [disabled]="getSelectedData().length === 0">
      {{ 'wc.admin.accountEntity.bulkUpdate' | translate }}
    </button>
  </div>
</div>

<div class="card__wrapper">
  <div class="card -full">
    <div class="card__inner">
      <app-account-entity-filter
        (filterChanged)="handleFilterChange()"
        [cacheFilterUsed]="cacheFilterUsed"
        [hideAccountsFilters]="accountId"
        [filtering]="search.filtering">
      </app-account-entity-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.accountEntity.form.list' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedAccountEntityRolesDto?.totalItems
                          ? pagedAccountEntityRolesDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedAccountEntityRolesDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action" *ngIf="!disableListingActions">
          <button
            id="003_accounts_entity_list"
            color="primary"
            (click)="deleteCheckedData()"
            mat-stroked-button
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor"
            [disabled]="getSelectedData().length === 0">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <mat-tab-group
          id="004_account-entity_list"
          animationDuration="0ms"
          (selectedTabChange)="setGrantedMode($event)">
          <mat-tab id="005_account-entity_list" label="GRANTED"></mat-tab>
          <mat-tab id="006_account-entity_list" label="DELEGATED"></mat-tab>
        </mat-tab-group>
        <app-table-listing-form
          [columnName]="[
            !this.grantMode ? 'delegatedByAccount.login' : null,
            'account.login',
            'entityType',
            'entityId',
            'roleType',
            'startDateTime',
            'endDateTime',
            'canDelegate',
            'created',
            'modified',
            'modifiedBy'
          ]"
          [pagedDto]="pagedAccountEntityRolesDto"
          [module]="'accountEntity'"
          [columnToTruncate]="[
            !this.grantMode ? 'delegatedByAccount.login' : null,
            'account.login',
            'entityType',
            'entityId',
            'roleType',
            'modifiedBy'
          ]"
          [dontShowOnMobile]="['startDateTime', 'endDateTime', 'canDelegate', 'created', 'modified', 'modifiedBy']"
          [fillIdBy]="'id'"
          [grantedMode]="grantMode"
          (editButtonEvent)="editAccountEntityRole($event)"
          (deleteButtonEvent)="deleteData($event)"
          (customButtonEvent)="delegateAccountEntityRole($event)"
          (doubleClickEvent)="editAccountEntityRole($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [disableActions]="disableActions"
          [editable]="aclService.pageEditAccess"
          [translationPrefix]="'wc.admin.accountEntity'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
