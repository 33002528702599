import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CharacteristicDto } from '@btl/admin-bff';

@Component({
  selector: 'app-resource-resources-parameter-modal',
  templateUrl: './resources-parameter-modal.component.html',
  styleUrls: ['./resources-parameter-modal.component.scss'],
})
export class ResourcesParameterModalComponent {
  @Input()
  dialogRef;

  @Input()
  formGroup: FormGroup;

  @Input()
  resourceCharacteristics: CharacteristicDto[];

  @Output()
  readonly saveEvent: EventEmitter<any> = new EventEmitter();

  save(): void {
    const data = this.formGroup.getRawValue();
    if (!data.name) {
      this.formGroup.get('name').markAsTouched();
      return;
    }
    if (!data.value) {
      this.formGroup.get('value').markAsTouched();
      return;
    }

    const existName = this.resourceCharacteristics.find(i => i.name === data.name);
    if (existName) {
      this.formGroup.get('name').setErrors({ unique: true });
      this.formGroup.get('name').markAsTouched();
      return;
    } else {
      this.saveEvent.emit(data);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
