<div class="card -full">
  <div class="card__inner">
    <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column">
        <span class="listing-header">{{
          (!parentParametersMode
            ? 'wc.admin.technicalCategory.parameters'
            : 'wc.admin.technicalCategory.parentsParameters'
          ) | translate
        }}</span>
      </div>
      <div fxLayoutGap="10px" class="listing-action" *ngIf="!parentParametersMode">
        <button
          color="primary"
          mat-raised-button
          (click)="addParameter()"
          [routerLink]=""
          class="btn -primary -xs edit icon stroke">
          <mat-icon svgIcon="plus"></mat-icon>
          {{ 'wc.common.add' | translate }}
        </button>
        <button
          color="primary"
          mat-stroked-button
          (click)="deleteCheckedParameters()"
          [routerLink]=""
          class="btn -primary -xs icon delete currentColor">
          <mat-icon svgIcon="delete"></mat-icon>
          {{ 'wc.admin.delete' | translate }}
        </button>
      </div>
    </div>
    <div class="card__body">
      <app-table-listing-form
        [parentColumnName]="['name', 'type', 'defaultValue']"
        [highLightColumn]="'defaultValue'"
        [fillIdBy]="'id'"
        [customData]="formArray.value"
        [parentParameters]="parentParameters"
        [enablePaginator]="enablePaginator"
        [selectMode]="selectMode"
        (editButtonEvent)="editParameter($event)"
        (deleteButtonEvent)="deleteParameter($event)"
        [editable]="editable"
        [disableEditInNewTabButton]="true"
        [translationPrefix]="'wc.admin.productParameters'">
      </app-table-listing-form>
    </div>
  </div>
</div>
