import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rule-set-overwrite-modal',
  templateUrl: './rule-set-overwrite-modal.component.html',
  styleUrls: ['./rule-set-overwrite-modal.component.scss'],
})
export class RuleSetOverwriteModalComponent {
  @Input()
  dialogRef;

  @Input()
  retHandler;

  @Input()
  ruleSet;

  overwrite() {
    this.retHandler(true);
    this.dialogRef.close();
  }

  cancel() {
    this.retHandler(false);
    this.dialogRef.close();
  }
}
