import { Component, EventEmitter, Input, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ProductDto, RelationshipDto } from '@btl/admin-bff';
import { MatDialogRef } from '@angular/material/dialog';
import {
  RelationshipEditComponent
} from '@components/product-catalogue/relationships/relationship-edit/relationship-edit.component';

@Component({
  selector: 'app-product-relationship-edit-modal',
  templateUrl: './product-relationship-edit-modal.component.html',
  styleUrls: ['./product-relationship-edit-modal.component.scss'],
})
export class ProductRelationshipEditModalComponent {
  @ViewChild(RelationshipEditComponent, {static: true})
  relationshipEditComponent: RelationshipEditComponent;

  dialogRef: MatDialogRef<ProductRelationshipEditModalComponent>;
  saveDataHandler: (relationships: RelationshipDto[]) => void;
  deleteDataHandler: (relationshipDto: RelationshipDto) => void;

  resetEventEmitter = new EventEmitter<RelationshipDto>();

  isCanceling: boolean = false;

  @Input()
  sourceTargetMode: string;

  @Input()
  relationship: RelationshipDto;

  @Input()
  product: ProductDto;

  @Input()
  enableEditProduct: boolean;

  static deleteControlError(control: AbstractControl, errorKey: string) {
    if (control.errors) {
      delete control.errors[errorKey];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  reset() {
    this.resetEventEmitter.emit(this.relationship);
  }

  cancel() {
    this.isCanceling = true;
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.relationshipEditComponent.saveRelationshipAttributes()) {
      this.saveDataHandler(this.relationshipEditComponent.getRelationshipsToSave());
      this.dialogRef.close();
    }
  }

  delete() {
    this.deleteDataHandler(this.relationship);
    this.dialogRef.close();
  }
}
