import { Component } from '@angular/core';
import { ComplexFilter, ControlFilterMiner, ControlStructKeyArrayFilterMiner } from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { Animations } from '@helpers/animations';

@Component({
  selector: 'app-campaign-filter',
  templateUrl: './campaign-filter.component.html',
  styleUrls: ['./campaign-filter.component.scss'],
  animations: [Animations.advancedFilterArrow],
})
export class CampaignFilterComponent extends ComplexFilter {
  PickerInputType = PickerInputType;

  filterForm = this.formBuilder.group({
    campaignCode: [],
    campaignChannel: [],
    campaignType: [],
    validAt: [],
    state: [],
    campaignDirection: [],
    campaignSlot: [],
    campaignCmsContent: [],
    campaignNotifType: [],
  });

  advancedFilterFields = ['campaignDirection', 'campaignSlot', 'campaignCmsContent', 'campaignNotifType'];

  filterMiners = [
    new ControlFilterMiner(this.filterForm.controls.campaignCode, 'code'),
    new ControlStructKeyArrayFilterMiner(
      this.filterForm.controls.campaignChannel,
      'params',
      'name',
      'campaignChannel',
      'value'
    ),
    new ControlStructKeyArrayFilterMiner(
      this.filterForm.controls.campaignType,
      'params',
      'name',
      'campaignType',
      'value'
    ),
    new ControlFilterMiner(this.filterForm.controls.validAt, 'validAt'),
    new ControlFilterMiner(this.filterForm.controls.state, 'state'),
    new ControlStructKeyArrayFilterMiner(
      this.filterForm.controls.campaignDirection,
      'params',
      'name',
      'campaignDirection',
      'value'
    ),
    new ControlStructKeyArrayFilterMiner(
      this.filterForm.controls.campaignSlot,
      'params',
      'name',
      'campaignSlot',
      'value'
    ),
    new ControlStructKeyArrayFilterMiner(
      this.filterForm.controls.campaignCmsContent,
      'params',
      'name',
      'campaignCmsContent',
      'value'
    ),
    new ControlStructKeyArrayFilterMiner(
      this.filterForm.controls.campaignNotifType,
      'params',
      'name',
      'campaignNotifType',
      'value'
    ),
  ];

  //TEMPORARY DATA;
  campaignChannels = ['WEB', 'NOTIFICATION', 'CALL'];
  campaignTypes = ['ACQUISITION', 'DEVELOPMENT', 'RETENTION'];
  campaignStates = ['ACTIVATED', 'DEACTIVATED'];
  campaignDirections = ['INBOUND', 'OUTBOUND'];
  campaignSlots = [
    'CUSTOMER_BANNER',
    'CUSTOMER_ACQUSITION',
    'CUSTOMER_ACTIVETARIFF',
    'CUSTOMER_ACCOUNT_INVOICES',
    'SUBSCRIPTION',
    'SUBSCRIPTION_CHANGETARIFF',
    'ORDER_PRODUCT',
  ];

  constructor(private formBuilder: FormBuilder) {
    super();
  }
}
