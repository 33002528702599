import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EnumerationsService } from '@service/enumerations.service';
import { takeUntil } from 'rxjs/operators';
import {
  AclService,
  AdminAclService,
  AdminCodebookService,
  AdminDynamicEnumService,
  CompareType,
  FormUtils,
  Search,
  ServiceUtils
} from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';
import {
  AclTableListItemComponent
} from '@components/acl/components/acl-rules-table/list/item/acl-table-list-item.component';
import { TicketTypeParamDto } from '@btl/admin-bff/model/ticketTypeParamDto';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-ticket-parameter',
  templateUrl: './ticket-parameter.component.html',
  styleUrls: ['./ticket-parameter.component.scss'],
})
export class TicketParameterComponent implements OnInit {
  private onDestroy$: Subject<void> = new Subject<void>();
  readonly SOURCE_NAME = EnumerationsService.TICKET;
  readonly TICKET_PARAM_DATA_TYPE_ENUM_NAME = 'com.emeldi.ecc.be.ticket.enums.TicketParamDataType';

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  @Input()
  parameterType: string;

  @Input()
  parameter: TicketTypeParamDto = {};

  locales = environment.localization.locales;

  @Input()
  ticketTypeDto;

  @Input()
  enableLocalizedNames = true;

  @Input()
  enableMetaParameters = true;

  @Input()
  enableAcl = true;

  ticketParamDataTypes;

  ticketTypeParameterAclRoles;
  ticketTypeParameterAclRules;
  ticketTypeParameterAclPrivileges;

  isNew = true;

  textsVisible = true;

  inputBoolean = ['true', 'false'];

  @Input()
  parentForm: FormGroup;

  codebooksTypes = [];

  codebooks = [];

  previousCodebookName;
  codebooksLoading = false;

  enums = [];
  enumItems = [];
  previousEnumName;
  enumItemsLoading = false;

  parameterForm: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    defaultValue: [],
    dataType: [null, Validators.required],
    dataTypeDetail: [],
    staticType: [true],
    isLocalized: [false],
    integrationName: [],
    note: [],
    validationRegex: [null],
    paramMetas: [],
    localizedNames: this.formBuilder.group({}),
    acl: this.formBuilder.array([]),
    texts: [],
  });

  context: any = {
    code: null,
    parameter: {
      name: null,
    },
  };

  get localizedNamesFormGroup(): FormGroup {
    return this.parameterForm.controls.localizedNames as FormGroup;
  }

  ticketEnumeration = EnumerationsService.TICKET;

  constructor(
    private formBuilder: FormBuilder,
    private enumerationsService: EnumerationsService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private adminAclService: AdminAclService,
    private adminCodebookService: AdminCodebookService,
    public aclService: AclService
  ) {
    this.adminDynamicEnumService
      .getEnumEntries(this.SOURCE_NAME, this.TICKET_PARAM_DATA_TYPE_ENUM_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.ticketParamDataTypes = result.data;
      });
  }

  ngOnInit(): void {
    const parameters = this.parentForm.controls.parameters?.value;
    parameters?.forEach(param => {
      if (param.name === this.parameter.name) {
        if (param.acl.length > 0) {
          param.acl.forEach(aclParam => {
            const aclRuleForm = this.formBuilder.group(AclTableListItemComponent.createAclRuleForm());
            const aclControl = this.parameterForm.controls.acl;
            aclRuleForm.patchValue(aclParam);
            if (aclControl instanceof FormArray) {
              aclControl.push(aclRuleForm);
            }
          });
        }
      }
    });
    this.context.code = this.ticketTypeDto?.code;
    this.context.parameter.name = this.parameterForm.controls.name.value;
    if (this.parameter?.name) {
      this.context.parameter = this.parameter;
      this.isNew = false;
      this.parameterForm.patchValue(this.parameter);
    }

    if (this.parameterForm.controls.dataType) {
      this.parameterForm.get('dataType')?.valueChanges.subscribe(value => {
        if (value === 'CODEBOOK' || value === 'ENUM') {
          FormUtils.setValidation(this.parameterForm.controls.dataTypeDetail, [Validators.required]);
        } else {
          FormUtils.setValidation(this.parameterForm.controls.dataTypeDetail, null);
        }
      });
    }
    this.staticTypeChanged(this.parameterForm.controls.staticType.value);
    this.loadLocalizedNames();
    this.loadAcl();
    this.loadCodebookTypes();
    this.loadEnums();
  }

  aclLoaded() {
    return (
      this.ticketTypeParameterAclRoles && this.ticketTypeParameterAclRules && this.ticketTypeParameterAclPrivileges
    );
  }

  loadCodebookTypes() {
    this.adminCodebookService
      .getCodebookTypes(ServiceUtils.getUnlimitedSearch(), null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.codebooksTypes = result.data;
        this.loadCodebookHints();
      });
  }

  getCodebooksByFilter() {
    this.codebooksLoading = true;
    const search: Search = {
      filtering: [
        {
          column: 'codebookType',
          compareType: 'LIKE',
          value: this.parameterForm.controls.dataTypeDetail.value,
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    this.adminCodebookService
      .getCodebooksByFilter(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.codebooks = result.data.map(c => c.code);
        this.codebooksLoading = false;
      });
  }

  codeBookChanged(codebookValue) {
    if (this.previousCodebookName !== codebookValue) {
      this.parameterForm.controls.defaultValue.patchValue(null);
      this.codebooks = [];
      this.previousCodebookName = codebookValue;
    }
  }

  loadCodebookHints() {
    const isListed = this.codebooksTypes.find(cb => cb === this.parameterForm.controls.dataTypeDetail.value);
    if (this.codebooks.length === 0 && this.parameterForm.controls.dataTypeDetail.value && isListed) {
      this.getCodebooksByFilter();
    }
  }

  loadEnums() {
    this.enumerationsService
      .getDataTypeEnums(this.SOURCE_NAME)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.enums = result;
        this.loadEnumItems();
      });
  }

  enumChanged(enumValue) {
    if (this.previousEnumName !== enumValue) {
      this.parameterForm.controls.defaultValue.patchValue(null);
      this.enumItems = [];
      this.previousEnumName = enumValue;
    }
  }

  loadEnumItems() {
    const isListed = this.enums.find(e => e === this.parameterForm.controls.dataTypeDetail.value);
    if (this.enumItems.length === 0 && this.parameterForm.controls.dataTypeDetail.value && isListed) {
      this.enumerationsService
        .getDataTypeEnumItems(this.SOURCE_NAME, this.parameterForm.controls.dataTypeDetail.value)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.enumItems = result;
        });
    }
  }

  loadLocalizedNames() {
    const parameterLocales: string[] =
      this.parameter && this.parameter.localizedNames ? Object.keys(this.parameter.localizedNames) : [];
    const mergedLocales = [...this.locales, ...parameterLocales];
    mergedLocales.forEach(locale => {
      const localizedNamesGroup = this.parameterForm.controls.localizedNames as FormGroup;
      const controlMessage = new FormControl();
      if (parameterLocales.length > 0) {
        controlMessage.patchValue(this.parameter.localizedNames[locale]);
      }
      localizedNamesGroup.addControl(locale, controlMessage);
    });
  }

  loadAcl() {
    this.getAclRoles();
    this.getAclRules();
    this.getAclPrivileges();
  }

  private getAclRoles() {
    this.adminAclService
      .filterRoles(ServiceUtils.getUnlimitedSearch(), null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(roles => {
        this.ticketTypeParameterAclRoles = roles;
      });
  }

  private getAclRules() {
    const filter: Search = {
      filtering: [
        {
          column: 'resourceType',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: 'TICKET_PARAMETER',
        },
        {
          column: 'resourceIdentification',
          compareType: CompareTypeDtoEnum.LIKE,
          value: ServiceUtils.setResourceIdentification('TICKET_PARAMETER', this.context),
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    this.adminAclService
      .filterRules(filter, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(rules => {
        this.ticketTypeParameterAclRules = rules;
      });
  }

  private getAclPrivileges() {
    const filter: Search = {
      filtering: [
        {
          column: 'resourceType',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: 'TICKET_PARAMETER',
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    this.adminAclService
      .filterPrivileges(filter, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(privileges => {
        this.ticketTypeParameterAclPrivileges = privileges;
      });
  }

  dataTypeChanged() {
    this.parameterForm.controls.defaultValue.patchValue(null);
    this.parameterForm.controls.dataTypeDetail.patchValue(null);
  }

  staticTypeChanged(isChecked: boolean) {
    if (isChecked) {
      this.parameterForm.controls.isLocalized.enable();
    } else {
      this.parameterForm.controls.isLocalized.patchValue(null);
      this.parameterForm.controls.isLocalized.disable();
    }
  }
}
