import { Component } from '@angular/core';
import { LocalizedTextDto } from '@btl/admin-bff';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-localized-text',
  template: '<router-outlet></router-outlet>',
})
@EnableDynamicLoading({ customName: LocalizedTextComponent.PAGE_ID })
export class LocalizedTextComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'LocalizedTextComponent';

  pageId(): string {
    return LocalizedTextComponent.PAGE_ID;
  }

  constructor(protected router: Router, protected route: ActivatedRoute) {
    super(router, route);
  }

  public static duplicateLocalizedText(localizedText: LocalizedTextDto): LocalizedTextDto {
    const newLocalizedText: LocalizedTextDto = {
      id: null, //'&',
      recordVersion: null, //localizedText.recordVersion,
      key: `${localizedText.key}-copy`,
      module: localizedText.module,
      locale: localizedText.locale,
      defaultValue: localizedText.defaultValue,
      customValue: localizedText.customValue,
    };
    return newLocalizedText;
  }
}
