import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { from, Subject } from 'rxjs';
import {
  AccountService,
  AclService,
  AuthFactoryService,
  AuthService,
  CodebookService,
  CurrentLocaleService,
  DmsService,
  DynamicComponentRegistry,
  getCurrentHost,
  PageModule,
  PageModuleService,
  ServiceUtils
} from '@btl/btl-fe-wc-common';
import { environment } from '@environments/environment';
import { takeUntil } from 'rxjs/operators';
import { LeftMenuComponent } from '@components/left-menu/left-menu.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ThemeService } from '@service/theme.service';
import { IconMapUtils } from '@helpers/iconMap-utils';
import { Animations } from '@helpers/animations';
import { AccountDto, DmsFileDto } from "@btl/order-bff";
import { Router } from "@angular/router";

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  animations: [Animations.menuExpandArrow],
  encapsulation: ViewEncapsulation.None,
})
export class TopMenuComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();
  isLoggedIn = false;

  userProfile;
  modules: PageModule[];
  mainModules: PageModule[];
  selectedLanguage;
  themeColor;
  selectedTheme;
  locales = environment.localization.locales;
  account: AccountDto;
  currentLocale;
  private authService: AuthService;
  logoIconDmsFile: DmsFileDto;
  moreMenuCollapsed = false;

  constructor(private authFactoryService: AuthFactoryService,
              private aclService: AclService,
              private overlayContainer: OverlayContainer,
              private themeService: ThemeService,
              private currentLocaleService: CurrentLocaleService,
              private accountService: AccountService,
              public pageModules: PageModuleService,
              private dmsService: DmsService,
              private dynamicComponentRegistry: DynamicComponentRegistry,
              private codebookService: CodebookService,
              private router: Router) {
    let search = ServiceUtils.getUnlimitedSearch();
    search.filtering.push({
      column: 'id',
      compareType: "EQUAL",
      value: 'TENANT_LOGO'
    })
    this.dmsService.filterFiles(search, null, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result.data.length > 0) {
          this.logoIconDmsFile = result.data[0]
        }
      });
  }

  ngOnInit() {
    this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    this.themeColor = this.themeService.getThemeColor();
    this.themeColor.subscribe(theme => {
      if (theme === 'darkMode') {
        this.selectedTheme = theme;
        this.overlayContainer.getContainerElement().classList.add(theme);
        this.overlayContainer.getContainerElement().classList.remove('lightMode');
      } else if (theme === 'lightMode') {
        theme = 'lightMode';
        this.overlayContainer.getContainerElement().classList.add(theme);
        this.overlayContainer.getContainerElement().classList.remove('darkMode');
      }
    });
    this.selectedLanguage = this.currentLocale.toUpperCase();
    this.authService = this.authFactoryService.getAuthService();
    from(this.isLoggedInAuth())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
      this.isLoggedIn = value;
      if (this.isLoggedIn) {
        from(this.authService.getUserInfo()).pipe(takeUntil(this.onDestroy$)).subscribe(userProfile => {
          this.userProfile = userProfile;
          if (!this.authService.account) {
            this.accountService.getAccountByLogin(this.authService.getUsername())
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(result => {
                this.authService.setAccount(result);
                this.currentLocaleService.setCurrentLanguage(result.locale, this.authService);

              });
          }
        });
      } else {
          this.userProfile = '';
        }
      });

    PageModuleService.getPageModulesSortedByPriority().subscribe(pageModules => {
      pageModules.forEach(codeBook => {
        codeBook.cssClass = IconMapUtils.getIcon(codeBook.code);
      });
      this.modules = pageModules.filter(module => module.dropZones.indexOf('Menu') > -1);
      this.mainModules = LeftMenuComponent.getCodebookModulesByParentCode(null, this.modules);
    });
  }

  logInOut() {
    if (this.isLoggedIn) {
      this.logoutAuth();
    } else {
      this.loginAuth();
    }
  }

  loginAuth() {
    this.aclService.emptyPrivilegesCache();
    this.authService
      .login()
      .then()
      .catch(reason => {
        throw new Error(`Cannot login: ${reason}`);
      });
  }

  logoutAuth() {
    this.aclService.emptyPrivilegesCache();
    sessionStorage.clear();
    this.authService
      .logout(getCurrentHost())
      .then()
      .catch(reason => {
        throw new Error(`Cannot logout: ${reason}`);
      });
  }

  isLoggedInAuth(): Promise<boolean> {
    return this.authService
      .isLoggedIn()
      .then(value => value)
      .catch(reason => {
        throw new Error(`Cannot check login status: ${reason}`);
      });
  }

  languageChange(locale: string) {
    this.currentLocaleService.setCurrentLanguage(locale, this.authService);
    this.currentLocale = this.currentLocaleService.getCurrentLanguage();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  hasModules(codebookModule) {
    return LeftMenuComponent.getCodebookModulesByParentCode(codebookModule.code, this.modules);
  }

  toggleTheme() {
    let theme: string;
    if (this.selectedTheme) {
      theme = 'darkMode';
      this.overlayContainer.getContainerElement().classList.add(theme);
      this.overlayContainer.getContainerElement().classList.remove('lightMode');
    } else {
      theme = 'lightMode';
      this.overlayContainer.getContainerElement().classList.add(theme);
      this.overlayContainer.getContainerElement().classList.remove('darkMode');
    }
    this.themeService.setThemeColor(theme);
  }

  getTenantId() {
    if (this.authService.account) {
      return this.authService.account.parameters.find(param => param.name === 'tenantId')?.value;
    }
    return '';
  }
}
