import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TicketTypeDto } from '@btl/order-bff';
import { AclService } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-edit-step-modal',
  templateUrl: './edit-step-modal.component.html',
  styleUrls: ['./edit-step-modal.component.scss'],
})
export class EditStepModalComponent {
  @Input()
  dialogRef;

  @Input()
  parentGroup: FormGroup;

  @Input()
  selectHandler;

  @Output()
  readonly createTicketEvent: EventEmitter<TicketTypeDto> = new EventEmitter<TicketTypeDto>();

  editStepForm: FormGroup = this.formBuilder.group({
    stepSeqNo: [],
    code: [null, Validators.required],
    description: [null, Validators.required],
  });

  constructor(private formBuilder: FormBuilder, public aclService: AclService) {}

  continue() {
    if (this.editStepForm.valid) {
      this.selectHandler(this.editStepForm);
      this.dialogRef.close();
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
