import { Component, Input } from '@angular/core';
import {
  AclService,
  AdminContentService,
  AdminContentVersionService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PagedContentsDto } from '@btl/admin-bff';
import { SortDto } from '@btl/order-bff';
import { takeUntil } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { CmsContentEditComponent } from '@components/cms/contents/edit/cms-content-edit.component';

import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cms-contents-list',
  templateUrl: './cms-contents-list.component.html',
  styleUrls: ['./cms-contents-list.component.scss'],
})
@EnableDynamicLoading({ customName: CmsContentsListComponent.PAGE_ID })
export class CmsContentsListComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'CmsContentsListComponent';

  pageId(): string {
    return CmsContentsListComponent.PAGE_ID;
  }

  pagedContentsDto: PagedContentsDto;

  @Input()
  disabledParentClick = true;

  selectMode = false;
  disableExpand = false;

  initSorting = [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    private adminContentVersionService: AdminContentVersionService,
    private adminContentService: AdminContentService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
    protected confirmationDialogService: ConfirmationDialogService,
    protected stickyMessageService: StickyMessageService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  onSortChange(sorting: MatSort) {
    if (sorting.active === 'keys.keyValue') {
      return;
    }
    super.onSortChange(sorting);
  }

  showContentVersion(pageDto) {
    this.navigateSibling(
      CmsContentEditComponent.PAGE_ID,
      { id: pageDto.id },
      null,
      { state: { duplicate: false } },
      false
    );
  }

  newContentMaster() {
    this.navigateSibling(CmsContentEditComponent.PAGE_ID, { id: 'newContentMaster' });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminContentService
        .getContentsByFilter(this.search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result: PagedContentsDto) => {
          if (result) {
            this.pagedContentsDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  public getChildDataCallback = (contentMaster: any): any => {
    return this.adminContentVersionService
      .getContentVersionsByContentMasterId(contentMaster.id)
      .pipe(takeUntil(this.onDestroy$));
  };
}
