<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'id'"
            [inputType]="'text'"
            id="001_roles_filter"
            [label]="'role'"
            [translationPrefix]="'wc.admin.roles'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            [formControlName]="'ruleType'"
            [options]="ruleTypes"
            id="002_roles_filter"
            [showEmptyOption]="true"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.roles.rule'">
          </app-input-select-form-field>
          <app-input-select-form-field
            *ngIf="pagedPrivilegesDto"
            [formControlName]="'privilegeType'"
            [options]="privilegesHints"
            id="003_roles_filter"
            (keydown.enter)="handleChange()"
            [label]="'privilege'"
            [translationPrefix]="'wc.admin.roles.rule'">
          </app-input-select-form-field>
          <app-input-select-form-field
            *ngIf="resourceTypes"
            [formControlName]="'resourceType'"
            id="004_roles_filter"
            [options]="resourceTypes.data"
            [showEmptyOption]="true"
            [labelOption]="'name'"
            [valueOption]="'name'"
            (keydown.enter)="handleChange()"
            [label]="'resourceType'"
            [translationPrefix]="'wc.admin.roles.rule'">
          </app-input-select-form-field>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-custom-input
          [controlName]="'resourceIdentification'"
          [inputType]="'text'"
          id="007_roles_filter"
          [label]="'resourceIdentification'"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.roles.rule'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-input-date-form-field
          [parentGroup]="filterForm"
          [controlName]="'validAt'"
          id="008_roles_filter"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.roles.rule'">
        </app-input-date-form-field>
        <app-input-select-form-field
          [formControlName]="'external'"
          id="009_roles_filter"
          (keydown.enter)="handleChange()"
          [yesNoOptions]="true"
          labelOption="labelKey"
          valueOption="value"
          [showEmptyOption]="true"
          [translationPrefix]="'wc.admin.roles.rule'">
        </app-input-select-form-field>
        <app-input-picker-form-field
          [parentGroup]="filterForm"
          [controlName]="'accountId'"
          [labelField]="'login'"
          id="010_roles_filter"
          [valueField]="'id'"
          [pickerType]="PickerInputType.ACCOUNT"
          [selectMode]="false"
          [label]="'user'"
          (change)="handleChange()"
          [translationPrefix]="'wc.admin.roles.rule'">
        </app-input-picker-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</form>
