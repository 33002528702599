import { Component } from '@angular/core';
import { MetaParameterDto, MetaProfileDto } from '@btl/admin-bff';

@Component({
  selector: 'app-meta-profile',
  template: '<router-outlet></router-outlet>',
})
export class MetaProfileComponent {
  public static duplicateProfile(profile: MetaProfileDto): MetaProfileDto {
    const newProfile: MetaProfileDto = {
      id: `${profile.id}-copy`,
      name: `${profile.name}-copy`,
      metaParameters: this.duplicateMetaParameters(profile.metaParameters),
    };
    return newProfile;
  }

  private static duplicateMetaParameters(metaParameters: MetaParameterDto[]): MetaParameterDto[] {
    if (!metaParameters) {
      return null;
    }
    const newMetaParameters = [];
    metaParameters.forEach(metaParameter => {
      const newMetaParameter: MetaParameterDto = {
        name: metaParameter.name,
        value: metaParameter.value,
      };
      newMetaParameters.push(newMetaParameter);
    });
    return newMetaParameters;
  }
}
