<div class="card -full">
  <div class="card__inner">
    <div class="card__header" fxLayoutAlign="space-between center">
      <div fxLayout="column">
        {{ 'wc.admin.role.accounts' | translate }}
      </div>
      <div fxLayoutGap="10px" class="listing-action">
        <mat-icon
          color="primary"
          (click)="isCollapsed = !isCollapsed"
          svgIcon="{{ isCollapsed ? 'hide' : 'show' }}"
          style="cursor: pointer"></mat-icon>
      </div>
    </div>
    <div [hidden]="isCollapsed" class="card__body">
      <div class="group_products__container">
        <div style="width: 100%">
          <table class="table -primary table-sm" style="table-layout: fixed">
            <thead>
              <th scope="col" style="width: 32px">
                <input
                  id="000_accounts_list_item"
                  [(ngModel)]="selectedAll"
                  type="checkbox"
                  (change)="handleSelectedAll()" />
              </th>
              <th scope="col">
                {{ 'wc.admin.account.firstName' | translate }}
              </th>
              <th scope="col">
                {{ 'wc.admin.account.lastName' | translate }}
              </th>
              <th scope="col">
                <app-sortable-column
                  (sortChanged)="onSortChange()"
                  [sorting]="search.sorting"
                  column="login"
                  localizationKey="wc.admin.account.login">
                </app-sortable-column>
              </th>
              <th scope="col">
                <app-sortable-column
                  (sortChanged)="onSortChange()"
                  [sorting]="search.sorting"
                  column="state"
                  localizationKey="wc.admin.account.state">
                </app-sortable-column>
              </th>
              <th scope="col" class="content-desktop" [style.width.px]="160">
                <app-sortable-column
                  (sortChanged)="onSortChange()"
                  [sorting]="search.sorting"
                  column="loginAttemptLast"
                  localizationKey="wc.admin.account.loginAttemptLast">
                </app-sortable-column>
              </th>
              <th scope="col" class="content-desktop">
                <app-sortable-column
                  (sortChanged)="onSortChange()"
                  [sorting]="search.sorting"
                  column="created"
                  localizationKey="wc.admin.created">
                </app-sortable-column>
              </th>
              <th scope="col" class="content-desktop">
                <app-sortable-column
                  (sortChanged)="onSortChange()"
                  [sorting]="search.sorting"
                  column="modified"
                  localizationKey="wc.admin.modified">
                </app-sortable-column>
              </th>
              <th scope="col" class="content-desktop">
                <app-sortable-column
                  (sortChanged)="onSortChange()"
                  [sorting]="search.sorting"
                  column="modifiedBy"
                  localizationKey="wc.admin.modifiedBy">
                </app-sortable-column>
              </th>
              <th *ngIf="editable" [style.width.px]="75" scope="col">
                {{ 'wc.admin.products.product.action' | translate }}
              </th>
            </thead>
            <tbody>
              <ng-container *ngFor="let account of pagedAccountsDto?.data; let i = index">
                <tr [title]="account.id ? account.id : '[null]'" class="table__row">
                  <td><input [(ngModel)]="selected[i]" type="checkbox" /></td>
                  <td>{{ firstName(account) }}</td>
                  <td>{{ lastName(account) }}</td>
                  <td>{{ account.login }}</td>
                  <!--                <td><input [checked]="account.external" type="checkbox" readonly/></td>-->
                  <!--                <td class="text-truncate">{{account.extId}}</td>-->
                  <td class="text-truncate">{{ account.state }}</td>
                  <td class="text-truncate content-desktop">
                    {{ account.loginAttemptLast | date: 'dd.MM.yyyy HH:mm' }}
                  </td>
                  <td class="text-truncate content-desktop">{{ account.created | date: 'dd.MM.yyyy HH:mm' }}</td>
                  <td class="text-truncate content-desktop">{{ account.modified | date: 'dd.MM.yyyy HH:mm' }}</td>
                  <td class="text-truncate content-desktop">{{ account.modifiedBy }}</td>
                  <td class="table__action-button" fxLayout="row">
                    <button (click)="deleteAccount(account)" [routerLink]="" mat-icon-button class="dropdown-item">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <mat-paginator
            [length]="pagedAccountsDto?.totalItems"
            [pageSize]="50"
            (page)="pageSizeChanged($event)"
            showFirstLastButtons="true"
            [pageSizeOptions]="[10, 25, 50, 100]"
            color="primary">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
