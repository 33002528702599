import { Component, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AbstractPageComponent,
  AclService,
  AdminDmsService,
  AdminDynamicEnumService,
  AdminPictureTypeService,
  AdminStickerService,
  AppBlockerService,
  EnableDynamicLoading,
  FormUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StickerDto } from '@btl/admin-bff';
import { ReleasesSelectService } from '@components/releases-select/releases-select.service';
import { MatDialog } from '@angular/material/dialog';
import { FormPicturesComponent } from '@components/form-pictures/form-pictures.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import { StickerListComponent } from '@components/product-catalogue/stickers/list/sticker-list.component';

@Component({
  selector: 'app-sticker-edit',
  templateUrl: './sticker-edit.component.html',
  styleUrls: ['./sticker-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: StickerEditComponent.PAGE_ID })
export class StickerEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'StickerEditComponent';

  pageId(): string {
    return StickerEditComponent.PAGE_ID;
  }

  routerOutlet: any;

  stickerId: string;

  editStickerChanged = new EventEmitter<StickerDto>();

  stickerDto: StickerDto;

  pictureTypes;

  stickerTypes = [];

  stickerForm: FormGroup = this.formBuilder.group({
    code: [null],
    priority: [0, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
    type: [null, Validators.required],
    grp: [0, Validators.required],
    recordVersion: [],
    texts: [null],
    pictures: [null],
  });

  constructor(
    private formBuilder: FormBuilder,
    private releasesSelectService: ReleasesSelectService,
    protected router: Router,
    protected route: ActivatedRoute,
    private dialog: MatDialog,
    private appBlockerService: AppBlockerService,
    private adminStickerService: AdminStickerService,
    private stickyMessageService: StickyMessageService,
    private adminPictureTypeService: AdminPictureTypeService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    public aclService: AclService,
    private adminDmsService: AdminDmsService
  ) {
    super(router, route);
    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.StickerType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.stickerTypes = result.data.map(type => type.name);
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const stickerId = this.params.id;
      if (stickerId && stickerId !== '&' && (!this.stickerDto || this.stickerDto.id !== stickerId)) {
        this.adminStickerService
          .getStickerById(stickerId)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(result => {
            this.setSticker(result);
            this.stickerId = this.stickerDto.id;
            this.loadPictureTypes();
          });
      } else {
        this.setSticker(this.stickerDto);
        this.loadPictureTypes();
      }
    } else {
      this.stickerId = undefined;
      this.stickerDto = undefined;
    }
  }

  loadPictureTypes() {
    this.adminPictureTypeService
      .getPictureTypes()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(pictureTypes => {
        //ToDo Add entity type filter to adminPictureTypeService.getPictureTypes()
        this.pictureTypes = pictureTypes.filter(pictureTypeDto => pictureTypeDto.id.startsWith('STICKER_'));
        if (this.pictureTypes.length === 0) {
          this.pictureTypes = pictureTypes;
        }
      });
  }

  setSticker(sticker: StickerDto) {
    if (sticker) {
      this.stickerDto = sticker;
    } else {
      this.stickerDto = {
        grp: 0,
        pictures: [],
      };
    }

    this.stickerForm.reset(this.stickerDto);

    if (sticker) {
      this.editStickerChanged.emit(this.stickerDto);
    }
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  saveStickerAttributes(): boolean {
    FormUtils.validateAllFormFields(this.stickerForm);
    if (this.stickerForm.valid) {
      this.stickerDto = this.stickerForm.getRawValue();
      return true;
    }
    return false;
  }

  public getEditSticker() {
    return this.stickerDto;
  }

  save() {
    if (this.saveStickerAttributes()) {
      this.appBlockerService.block();
      const pictures = this.stickerForm.getRawValue().pictures;
      const pictureBulkOperations = FormPicturesComponent.getDmsBulk(pictures);
      this.stickerDto.pictures = FormPicturesComponent.clearAttachmentList(pictures);
      if (pictureBulkOperations) {
        this.adminDmsService.changeFiles(pictureBulkOperations).subscribe(() => {
          this.saveSticker();
        });
      } else {
        this.saveSticker();
      }
    }
  }

  saveSticker() {
    if (!(this.stickerId === null || this.stickerId === undefined)) {
      this.adminStickerService
        .updateSticker(this.stickerId, this.stickerDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(this.getStickerHandler);
    } else {
      this.adminStickerService
        .createSticker(this.stickerDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(this.getStickerHandler);
    }
  }

  public reset() {
    if (this.stickerDto.recordVersion) {
      this.appBlockerService.block();
      this.adminStickerService
        .getStickerById(this.stickerDto.id)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(this.getStickerHandler);
    } else {
      this.setSticker(this.stickerDto);
    }
  }

  public cancel() {
    this.navigateSibling(StickerListComponent.PAGE_ID);
  }

  public delete() {
    this.appBlockerService.block();
    this.adminStickerService
      .deleteSticker(this.stickerDto.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        this.navigateSibling(StickerListComponent.PAGE_ID);
        this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
      });
  }

  public duplicate() {
    this.stickerId = null;
    this.stickerDto.code = `${this.stickerForm.controls.code.value}-copy`;

    this.stickerDto.id = null;
    this.stickerDto.recordVersion = null;
    this.stickerForm.patchValue(this.stickerDto);
  }

  public getStickerHandler = (stickerDto: StickerDto): void => {
    if (stickerDto && this.stickerDto) {
      if (stickerDto.id === this.stickerId) {
        this.setSticker(stickerDto);
        this.navigateSelf({ id: stickerDto.id });
      } else {
        this.stickerId = stickerDto.id;
        this.setSticker(stickerDto);
        this.navigateSelf({ id: stickerDto.id });
      }

      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  getProductStickerFilter() {
    return [
      {
        column: 'stickers.stickerId',
        compareType: 'EQUAL',
        value: this.stickerDto.id,
      },
    ];
  }

  releases() {
    if (this.stickerDto && this.stickerDto.code) {
      this.releasesSelectService.selectReleases('Sticker', this.stickerDto.code);
    }
  }
}
