<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'id'"
            [inputType]="'text'"
            id="001_agreementTypes_filter"
            [translationPrefix]="'wc.admin.agreementTypes'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'name'"
            [inputType]="'text'"
            id="002_agreementTypes_filter"
            [translationPrefix]="'wc.admin.agreementTypes'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-select-form-field
            *ngIf="entityTypes"
            [formControlName]="'entityType'"
            id="004_agreementTypes_filter"
            [options]="entityTypes.data"
            [showEmptyOption]="true"
            [labelOption]="'name'"
            [valueOption]="'name'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.agreementTypes'">
          </app-input-select-form-field>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-input-date-form-field
          [parentGroup]="filterForm"
          [translationPrefix]="'wc.admin.agreementTypes'"
          (keydown.enter)="handleChange()"
          id="010_agreementTypes_filter"
          [controlName]="'createdFrom'">
        </app-input-date-form-field>
        <app-input-date-form-field
          [parentGroup]="filterForm"
          [translationPrefix]="'wc.admin.agreementTypes'"
          id="011_agreementTypes_filter"
          (keydown.enter)="handleChange()"
          [controlName]="'createdTo'">
        </app-input-date-form-field>
        <app-input-select-form-field
          [formControlName]="'valid'"
          [label]="'isValid'"
          id="012_agreementTypes_filter"
          [yesNoOptions]="true"
          labelOption="labelKey"
          valueOption="value"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.agreementTypes'">
        </app-input-select-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</form>
