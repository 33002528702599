<h2 mat-dialog-title>
  {{ 'wc.common.choose' | translate }}
    <mat-icon
    class="default-icon-gray-fill no-stroke"
    svgIcon="close"
    mat-dialog-close
    matTooltip="{{ 'wc.common.close' | translate }}">
  </mat-icon>
</h2>
<mat-dialog-content class="mat-typography">
  <div fxLayout="column" style="padding-bottom: 15px">
    <ng-container *ngFor="let release of releases; let i = index">
      <mat-checkbox
        [title]="release.releaseCode + ' -> ' + release.releaseDate + '\n--------\n' + release.releaseNote"
        [(ngModel)]="selected[i]"
        >{{ release.releaseCode }} [{{ formatReleaseDate(release.releaseDate) }}]</mat-checkbox
      >
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div style="width: 100%; display: flex">{{ 'wc.admin.releases.note' | translate }}</div>
  <a (click)="close()" [routerLink]="" color="primary" mat-raised-button>{{ 'wc.admin.cancel' | translate }} </a>
  <a (click)="save()" [routerLink]="" color="primary" mat-raised-button>{{ 'wc.admin.save' | translate }} </a>
</mat-dialog-actions>
