import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from '@components/error-page/error-page.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: ErrorPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'ignore',
      initialNavigation: 'disabled', // routes will be updated and navigation initialized in app-init.ts
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
