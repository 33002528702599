<div class="border-bot">
  <div>
    <h2 mat-dialog-title>
      {{ 'wc.admin.productGroups.newMetaParam' | translate }}
      <mat-icon
        class="default-icon-gray-fill no-stroke"
        svgIcon="close"
        mat-dialog-close
        matTooltip="{{ 'wc.common.close' | translate }}">
      </mat-icon>
    </h2>
  </div>
</div>
<div class="modal-body">
  <div [formGroup]="newParameterForm" class="form" fxLayout="column" fxLayoutGap="25px">
    <div class="form__row">
      <app-custom-input
        *ngIf="!paramNames"
        [parentGroup]="newParameterForm"
        [controlName]="'name'"
        [inputType]="'text'"
        [translationPrefix]="'wc.admin.productGroups.parameter'">
      </app-custom-input>
      <app-input-select-form-field
        *ngIf="paramNames"
        [formControlName]="'name'"
        [options]="paramNames"
        [showEmptyOption]="false"
        [label]="'name'"
        [translationPrefix]="'wc.admin.productGroups.parameter'">
      </app-input-select-form-field>
    </div>
    <div class="form__row">
      <app-custom-input
        [parentGroup]="newParameterForm"
        [controlName]="'value'"
        [inputType]="'text'"
        [translationPrefix]="'wc.admin.productGroups.parameter'">
      </app-custom-input>
    </div>
  </div>
</div>
<mat-dialog-actions align="end" class="parameters-modal-actions">
  <a (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
  <button color="primary" mat-raised-button (click)="save()" [routerLink]="" class="btn -primary -xs edit">
    {{ 'wc.common.save' | translate }}
  </button>
</mat-dialog-actions>
