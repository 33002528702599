<div class="page__wrapper">
  <div class="main__header">
    <h1>{{ 'wc.admin.imports' | translate }}</h1>
    <div class="page__menu">
      <button
        id="001_import_edit"
        *ngIf="aclService.pageEditAccess"
        (click)="import()"
        [routerLink]=""
        mat-raised-button
        color="primary"
        [disabled]="!anyFileChosen()">
        {{ 'wc.admin.imports.runImport' | translate }}
      </button>
    </div>
  </div>

  <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

  <div class="card__wrapper">
    <!-- card with main codebook attributes -->
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.import.import' | translate }}
        </div>
        <div class="card__body">
          <div *ngIf="!anyFileChosen()" class="warning" fxLayoutAlign="center center">
            {{ 'wc.admin.imports.chooseOneFile' | translate }}
          </div>
          <form [formGroup]="form" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-custom-input
                    [id]="'002_import_edit'"
                    [inputType]="'checkbox'"
                    [parentGroup]="form"
                    [controlName]="'validateOnly'"
                    [label]="'wc.admin.imports.validateOnly'">
                  </app-custom-input>
                </div>
                <div class="form__row">
                  <app-input-picker-form-field
                    [parentGroup]="form"
                    [controlName]="'pcFile'"
                    id="003_import_edit"
                    [labelField]="'name'"
                    [label]="'productCatalog'"
                    [pickerType]="PickerInputType.UPLOAD"
                    [uploadType]="'pcFile'"
                    [translationPrefix]="'wc.admin.imports'">
                  </app-input-picker-form-field>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    formControlName="pcType"
                    id="005_import_edit"
                    [options]="importTypes"
                    [translationPrefix]="'wc.admin.import'">
                  </app-input-select-form-field>
                </div>

                <div class="form__row">
                  <app-custom-input
                    [parentGroup]="form"
                    [controlName]="'dataVersion'"
                    [inputType]="'number'"
                    id="006_import_edit"
                    [translationPrefix]="'wc.admin.import'">
                  </app-custom-input>
                </div>

                <div class="form__row">
                  <app-input-picker-form-field
                    [parentGroup]="form"
                    [controlName]="'aclFile'"
                    id="007_import_edit"
                    [labelField]="'name'"
                    [label]="'acl'"
                    [pickerType]="PickerInputType.UPLOAD"
                    [uploadType]="'aclFile'"
                    [translationPrefix]="'wc.admin.imports'">
                  </app-input-picker-form-field>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    formControlName="aclType"
                    id="009_import_edit"
                    [options]="importTypes"
                    [translationPrefix]="'wc.admin.import'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row"></div>
                <div class="form__row">
                  <app-input-picker-form-field
                    [parentGroup]="form"
                    [controlName]="'cbFile'"
                    id="010_import_edit"
                    [labelField]="'name'"
                    [label]="'codebooks'"
                    [pickerType]="PickerInputType.UPLOAD"
                    [uploadType]="'cbFile'"
                    [translationPrefix]="'wc.admin.imports'">
                  </app-input-picker-form-field>
                </div>
                <div class="form__row"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="card__wrapper">
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.import.importList' | translate }}
        </div>
        <div class="card__body">
          <app-imports-filter (filterChanged)="handleFilterChange()"
                              [cacheFilterUsed]="cacheFilterUsed"
                              [constantFilter]="constantFilter"
                              [filtering]="search.filtering">
          </app-imports-filter>
          <app-table-listing-form
            [columnName]="['instanceId', 'created', 'finished', 'services', 'validateOnly', 'exportState', 'createdBy']"
            [columnToTruncate]="['created', 'finished']"
            [fillIdBy]="'id'"
            [columnToolTipCallBack]="getColumnsToolTipCallBacks()"
            module="export"
            [selectMode]="false"
            (customButtonEvent)="download($event)"
            [pagedDto]="pagedProcessInfosDto"
            (pageSizeEvent)="pageSizeChanged($event)"
            (sortChangeEvent)="onSortChange($event)"
            [cacheFilter]="this.search"
            [translationPrefix]="'wc.admin.export'">
          </app-table-listing-form>
        </div>
      </div>
    </div>
  </div>
</div>
