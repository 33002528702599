import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AclService, FormUtils } from '@btl/btl-fe-wc-common';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';
import {
  TicketParameterComponent,
} from '@components/tickets/ticket-type/edit/parameters/parameter-detail-modal/ticket-parameter/ticket-parameter.component';

@Component({
  selector: 'app-parameter-detail-modal',
  templateUrl: './parameter-detail-modal.component.html',
  styleUrls: ['./parameter-detail-modal.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParameterDetailModalComponent),
      multi: true,
    },
  ],
})
export class ParameterDetailModalComponent implements OnInit, OnDestroy {
  @ViewChild(TicketParameterComponent)
  ticketParameterComponent: TicketParameterComponent;
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  module: string;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  @Input()
  parameterType: string;

  @Input()
  parentParameters;

  @Input()
  parameter = {
    name: null,
  };

  locales = environment.localization.locales;

  @Input()
  ticketTypeDto;

  @Input()
  enableLocalizedNames: boolean = true;

  @Input()
  parentParametersMode: boolean = false;

  @Input()
  overwriteParent: boolean = false;

  @Input()
  enableMetaParameters: boolean = true;

  @Input()
  enableAcl: boolean = true;

  isNew: boolean = true;

  @Input()
  parentForm: FormGroup;

  parameterForm: FormGroup;

  context: any = {
    code: null,
    parameter: {
      name: null,
    },
  };

  constructor(public aclService: AclService) {}

  ngOnInit(): void {
    if (this.parameter) {
      this.parentForm.patchValue(this.parameter);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let finalForm: FormGroup;
    if (this.parameterType === 'ticket') {
      finalForm = this.ticketParameterComponent.parameterForm;
    } else {
      finalForm = this.parentForm;
    }
    FormUtils.validateAllFormFields(finalForm);
    if (finalForm.valid) {
      if (this.parameterType === 'ticket') {
        this.context.code = this.ticketTypeDto.code;
        this.context.parameter.name = finalForm.controls['name'].value;
        this.context = Object.assign({}, this.context);
        this.selectHandler(finalForm, this.isNew, this.context);
      }
      if (this.parameterType === 'category') {
        this.selectHandler(finalForm, this.isNew, this.parentParametersMode);
      }
      this.dialogRef.close();
    }
  }

  delete() {
    const parameters = this.parentForm.controls['parameters'] as FormArray;
    if (parameters.value.findIndex(param => param.name === this.parameter.name) !== -1) {
      parameters.removeAt(parameters.value.findIndex(param => param.name === this.parameter.name));
      this.dialogRef.close();
    }
  }
}
