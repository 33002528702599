<div>
  <h2 mat-dialog-title>
    {{ 'wc.admin.notification.type.gateway.add' | translate }}
    <button [attr.aria-label]="'wc.common.close' | translate" mat-dialog-close type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </h2>

  <mat-dialog-content class="mat-typography">
    <div class=".mat-drawer-container">
      <div class="mat-sidenav-content">
        <div class="main_content">
          <div class="card__wrapper gateways">
            <div class="card -full">
              <div class="card__inner gateways" *ngIf="pagedGatewaysDto">
                <div class="card__body gateways">
                  <app-notification-gateway-filter
                    [filtering]="search.filtering"
                    (filterChanged)="handleFilterChange()">
                  </app-notification-gateway-filter>
                </div>
              </div>

              <div class="card__inner gateways" *ngIf="pagedGatewaysDto">
                <div class="card__header gateways border-unset" fxLayout="row" fxLayoutAlign="space-between">
                  <div fxLayout="column">
                    <span class="listing-header">{{ 'wc.admin.notification.gateways' | translate }}</span>
                    <div class="paginator__entries" *ngIf="pagedGatewaysDto">
                      {{
                        'wc.common.pagination.showing'
                          | translate
                            : {
                                from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                                to:
                                  search.paging.pageSize * search.paging.page > pagedGatewaysDto?.totalItems
                                    ? pagedGatewaysDto?.totalItems
                                    : search.paging.pageSize * search.paging.page,
                                of: pagedGatewaysDto?.totalItems
                              }
                      }}
                    </div>
                  </div>
                </div>

                <div class="card__body gateways">
                  <app-table-listing-form
                    [columnName]="[
                      'code',
                      'name',
                      'channelType',
                      'supportDeliveryReport',
                      'loopbackEnabled',
                      'created',
                      'modified',
                      'modifiedBy'
                    ]"
                    [dontShowOnMobile]="[
                      'supportDeliveryReport',
                      'loopbackEnabled',
                      'created',
                      'modified',
                      'modifiedBy'
                    ]"
                    [columnToTruncate]="['created', 'modified', 'modifiedBy']"
                    [fillIdBy]="'id'"
                    [pagedDto]="pagedGatewaysDto"
                    [expandType]="'boxes'"
                    (doubleClickEvent)="onSelectGateway($event)"
                    (getChildDataEvent)="onSelectGateway($event)"
                    [translationPrefix]="'wc.admin.notification.gateway'">
                  </app-table-listing-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
