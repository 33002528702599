import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { AdminAccountService, AppBlockerService, PaginationComponent, Search } from '@btl/btl-fe-wc-common';
import { PagedAccountsDto } from '@btl/admin-bff';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { AccountDto } from '@btl/admin-bff/model/accountDto';
import { Filter } from '@btl/btl-fe-wc-common/lib/models/search';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';

@Component({
  selector: 'app-role-accounts, [app-role-accounts]',
  templateUrl: './role-accounts.component.html',
  styleUrls: ['./role-accounts.component.scss'],
})
export class RoleAccountsComponent implements OnDestroy, OnChanges {
  @Input()
  constantFilter: Array<Filter> = [];

  @Input()
  roleId: string;

  @ViewChild(PaginationComponent, { static: true })
  paginationComponent: PaginationComponent;

  @Input()
  editable = true;

  @Output()
  readonly deleteAccountChangeEvent: EventEmitter<AccountDto> = new EventEmitter<AccountDto>();

  pagedAccountsDto: PagedAccountsDto;

  search: Search = {
    filtering: [],
    sorting: [{ column: 'login', sortOrder: 'asc' }],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };

  isCollapsed = false;

  selected: boolean[] = [];
  selectedAll = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private adminAccountService: AdminAccountService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,
    private appBlockerService: AppBlockerService
  ) {}

  ngOnChanges(changes: any) {
    if (this.roleId) {
      this.constantFilter.length = 0;
      this.constantFilter.push({ column: 'roles', compareType: 'EQUAL', value: this.roleId });
      this.search.filtering = this.constantFilter;
    }
    if (this.constantFilter.length > 0) {
      this.loadAccounts(this.search);
    }
  }

  onSortChange() {
    this.loadAccounts(this.search);
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadAccounts(this.search);
  }

  goToPage(pageNo: number): void {
    this.search.paging.page = pageNo;
    this.loadAccounts(this.search);
  }

  onNext(): void {
    this.search.paging.page++;
    this.loadAccounts(this.search);
  }

  onPrev(): void {
    this.search.paging.page--;
    this.loadAccounts(this.search);
  }

  pageSizeChanged(page): void {
    this.search.paging.page = page.pageIndex + 1;
    this.search.paging.pageSize = page.pageSize;
    this.loadAccounts(this.search);
  }

  deleteAccount(account: AccountDto) {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.role.account.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      const idx = account.roles.findIndex(role => role.roleName === this.roleId);
      if (idx !== -1) {
        account.roles.splice(idx, 1);
        account.stateFrom = null;
        account.created = null;
        account.createdBy = null;
        account.modified = null;
        account.modifiedBy = null;
        account.roles.forEach(role => {
          role.created = undefined;
          role.createdBy = undefined;
          role.modified = undefined;
          role.modifiedBy = undefined;
        });
        account.login = null;
        account.loginAttemptLast = null;
        this.appBlockerService.block();
        this.adminAccountService
          .updateAccount(account.id, account)
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(this.getAccountHandler);
        confirmationDialogComponent.dialogReference.close();
      }
    };
  }

  public getAccountHandler = (accountDto: AccountDto): void => {
    this.loadAccounts(this.search);
  };

  firstName(account: AccountDto): string {
    if (account.parameters) {
      const param = account.parameters.find(p => p.name === 'firstName');
      if (param) {
        return param.value;
      }
    }
    return null;
  }

  lastName(account: AccountDto): string {
    if (account.parameters) {
      const param = account.parameters.find(p => p.name === 'lastName');
      if (param) {
        return param.value;
      }
    }
    return null;
  }

  handleSelectedAll() {
    for (let i = 0; i < this.pagedAccountsDto.data.length; i++) {
      this.selected[i] = this.selectedAll;
    }
  }

  public loadAccounts(search: Search) {
    this.pagedAccountsDto = null;
    if (this.roleId) {
      this.selected = [];
      this.adminAccountService
        .filterAccounts(search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          this.pagedAccountsDto = result;
        });
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
