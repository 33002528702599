import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductEditService } from '@service/product-edit.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';
import {
  AdminProductRatingService,
  AppBlockerService,
  CompareType,
  EnableDynamicLoading,
  Search,
  StickyMessageService
} from '@btl/btl-fe-wc-common';
import { PagedReviewsDto, ReviewDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { MatSort } from '@angular/material/sort';
import {
  ProductRatingsReviewEditComponent,
} from '@components/product-catalogue/products/product-edit/product-ratings/review-edit/product-ratings-review-edit.component';
import { MatDialog } from '@angular/material/dialog';
import {
  AbstractChangeOperationsComponent
} from '@components/abstract-change-operations/abstract-change-operations.component';
import { TableListingFormComponent } from '@components/table-listing-form/table-listing-form.component';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-product-ratings',
  templateUrl: './product-ratings.component.html',
  styleUrls: ['./product-ratings.component.scss'],
})
@EnableDynamicLoading({customName: ProductRatingsComponent.PAGE_ID})
export class ProductRatingsComponent extends AbstractChangeOperationsComponent implements OnInit, CanComponentDeactivate {
  public static readonly PAGE_ID = 'ProductRatingsComponent';

  pageId(): string {
    return ProductRatingsComponent.PAGE_ID;
  }

  @ViewChild('reviewsTable', {static: false})
  reviewsTable: TableListingFormComponent;

  @ViewChild('operationsTable', {static: false})
  operationsTable: TableListingFormComponent;

  public reviews: ReviewDto[] = [];

  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: 50,
    },
  };
  private product;

  pagedReviews: PagedReviewsDto;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    public productEditService: ProductEditService,
    private dialog: MatDialog,
    private appBlockerService: AppBlockerService,
    private stickyMessageService: StickyMessageService,
    private adminProductRatingService: AdminProductRatingService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super(router, route);
    const product = this.productEditService.getEditProduct();
    if (product) {
      this.product = product;
      this.loadRatings();
    }
  }

  navigationSubscription(navigation: NavigationEnd) {
  }

  ngOnInit() {
    this.operations = this.productEditService.reviewsOperations;
    this.productEditService.editProductChanged.pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      if (!this.product || this.product.id !== result.id) {
        this.product = result;
        this.loadRatings();
      }

      this.operations = [];
    });
  }

  loadRatings() {
    this.reviews.length = 0;
    this.appBlockerService.block();
    const productId = this.product.productCode;
    if (productId) {
      this.search.filtering = [
        {
          column: 'productId',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: productId,
        },
      ];
      this.loadRatingsBySearch(this.search);
    }
  }

  loadRatingsBySearch(search: Search) {
    this.pagedReviews = null;
    this.adminProductRatingService
      .filterReviews(search)
      .pipe(takeUntil(this.onDestroy$))
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        this.pagedReviews = result;
        if (result) {
          this.reviews.length = 0;
          result.data.forEach(rating => {
            if (rating) {
              this.reviews.push(rating);
            }
          });
        }
      });
  }

  deleteReview(reviewDto: ReviewDto, doConfirm: boolean = true) {
    if (doConfirm) {
      const confirmationDialogComponent = this.confirmationDialogService.openDialog([
        'wc.admin.products.review.delete.confirmation.text',
      ]);

      confirmationDialogComponent.confirmationHandler = dialogReference => {
        this.deleteRow(reviewDto);
        confirmationDialogComponent.dialogReference.close();
      };
    } else {
      this.deleteRow(reviewDto);
    }
  }

  editReview(reviewDto: ReviewDto, isNew: boolean = false) {
    let changedReview = this.operations.find(operation => operation.id === reviewDto?.id);
    if (changedReview) {
      reviewDto = changedReview;
    }
    const modalRef = this.dialog.open(ProductRatingsReviewEditComponent);
    const productRatingsReviewEditComponent = modalRef.componentInstance;
    productRatingsReviewEditComponent.dialogRef = modalRef;
    productRatingsReviewEditComponent.review = reviewDto ? reviewDto : {};
    productRatingsReviewEditComponent.saveDataHandler = (review) => {
      if (isNew) {
        this.addRow(review);
      } else {
        this.editRow(review, changedReview);
      }
    };
    productRatingsReviewEditComponent.deleteDataHandler = (review) => {
      this.deleteReview(review);
    };
  }

  deleteSelectedReviews() {
    const confirmationDialogComponent = this.confirmationDialogService.openDialog([
      'wc.admin.products.reviews.delete.confirmation.text',
    ]);

    confirmationDialogComponent.confirmationHandler = dialogReference => {
      if (this.reviewsTable.selection.hasValue()) {
        this.reviewsTable.selection.selected.forEach(selection => this.deleteReview(selection as ReviewDto, false))
      }
      this.reviewsTable.selection.clear();
      confirmationDialogComponent.dialogReference.close();
    }
  }

  saveData(): boolean {
    if (!this.productEditService.canEdit()) {
      return true;
    }
    this.productEditService.reviewsOperations = this.operations;
    this.product = null;
    return true;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.productEditService.resetMode) {
      return this.saveData();
    }

    this.productEditService.resetMode = false;

    return true;
  }

  onSortChange(sorting: MatSort) {
    if (sorting.direction !== '') {
      this.search.sorting = [{column: sorting.active, sortOrder: sorting.direction}];
    } else {
      this.search.sorting = [];
    }
    this.loadRatingsBySearch(this.search);
  }

  public handleFilterChange(): void {
    this.search.paging.page = 1;
    this.loadRatingsBySearch(this.search);
  }

  goToPage(pageNo: number): void {
    this.search.paging.page = pageNo;
    this.loadRatingsBySearch(this.search);
  }

  onNext(): void {
    this.search.paging.page++;
    this.loadRatingsBySearch(this.search);
  }

  onPrev(): void {
    this.search.paging.page--;
    this.loadRatingsBySearch(this.search);
  }

  pageSizeChanged(page): void {
    this.search.paging.page = page.pageIndex + 1;
    this.search.paging.pageSize = page.pageSize;
    this.loadRatingsBySearch(this.search);
  }

  deleteSelectedOperations() {
    if (this.operationsTable.selection.hasValue()) {
      this.operationsTable.selection.selected.forEach(selection => this.deleteReview(selection as ReviewDto, false))
    }
    this.operationsTable.selection.clear();
  }
}
