import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import {
  AdminAccountService,
  AdminAclService,
  AdminContentService,
  AdminDmsService,
  AdminNotificationTypeService,
  AdminProductGroupService,
  AdminProductService,
  AdminPromotionService,
  AdminStepTemplateService,
  AdminStickerService,
  AdminStoreService,
  AppBlockerService,
  CompareType,
  CurrentLocaleService,
  Search,
  ServiceUtils,
} from '@btl/btl-fe-wc-common';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { GroupsSelectModalComponent } from '@components/groups-select-modal/groups-select-modal.component';
import { ProductsSelectModalComponent } from '@components/products-select-modal/products-select-modal.component';
import { StickersSelectModalComponent } from '@components/stickers-select-modal/stickers-select-modal.component';
import { PromotionsSelectModalComponent } from '@components/promotions-select-modal/promotions-select-modal.component';
import {
  RulesSelectModalComponent,
} from '@components/acl/components/acl-rules-select-modal/rules-select-modal.component';
import {
  StepTemplateSelectModalComponent,
} from '@components/step-template-select-modal/step-template-select-modal.component';
import { AccountsSelectModalComponent } from '@components/account-select-modal/accounts-select-modal.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { CmsSelectModalComponent } from '@components/cms-select-modal/cms-select-modal.component';
import {
  NotificationTypeSelectModalComponent,
} from '@components/notification-type-select-modal/notification-type-select-modal.component';
import { FilesAddModalComponent } from '@components/files-add-modal/files-add-modal.component';
import { DmsFileDto } from '@btl/admin-bff';
import { StoresSelectModalComponent } from '@components/stores-select-modal/stores-select-modal.component';
import { TranslateService } from '@ngx-translate/core';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-input-picker-form-field',
  templateUrl: './input-picker-form-field.component.html',
  styleUrls: ['./input-picker-form-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPickerFormFieldComponent),
      multi: true,
    },
  ],
})
export class InputPickerFormFieldComponent implements OnInit, OnDestroy, OnChanges {
  PickerInputType = PickerInputType;

  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  parentGroup: FormGroup;

  @Input()
  pickerType: PickerInputType;

  @Input()
  uploadType;

  @Input()
  controlName;

  @Input()
  label: string;

  @Input()
  labelOption: string;

  @Input()
  options;

  @Input()
  labelField;

  @Input()
  valueField;

  @Input()
  suffix: string = '';

  @Input()
  translationPrefix: string;

  @Input()
  modalTypeComponent;

  @Input()
  selectMode: boolean = true;

  @Input()
  singleSelect: boolean = true;

  showValue = '';

  list: Array<any> = [];
  removable: boolean = true;

  @Input()
  showDescription = false;

  @Output()
  readonly customEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  hints: any[] = [];
  lastValue = null;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private adminProductService: AdminProductService,
    private adminProductGroupService: AdminProductGroupService,
    private adminStickerService: AdminStickerService,
    private adminPromotionService: AdminPromotionService,
    private adminAccountService: AdminAccountService,
    private adminStepTemplateService: AdminStepTemplateService,
    private appBlockerService: AppBlockerService,
    private adminDmsService: AdminDmsService,
    private adminAclService: AdminAclService,
    private adminStoreService: AdminStoreService,
    private adminNotificationTypeService: AdminNotificationTypeService,
    private adminContentService: AdminContentService,
    private translateService: TranslateService,
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit(): void {
    if (!this.labelField) {
      this.labelField = this.valueField;
    }
    this.initInputs();
    if (this.parentGroup && this.parentGroup.controls[this.controlName]) {
      this.parentGroup.controls[this.controlName].valueChanges.subscribe(value => {
        if (!value) {
          this.showValue = null;
        } else if (value != this.lastValue) {
          this.lastValue = value;
          this.initInputs();
        }
      });
    }
  }

  initInputs() {
    const value = this.parentGroup.getRawValue()[this.controlName];
    if (this.valueField !== this.labelField) {
      switch (this.pickerType) {
        case 'product':
          if (value && this.valueField === 'id') {
            this.getProductById(value);
          }
          if (value && this.valueField === 'productCode') {
            this.getProductByFilter(value);
          }
          break;
        case 'group':
          if (value && this.valueField === 'id') {
            this.getGroupById(value);
          }
          break;
        case 'sticker':
          if (value && this.valueField === 'id') {
            this.getStickerById(value);
          }
          if (value && this.valueField === 'code') {
            this.getStickersByFilter(value);
          }
          break;
        case 'promotion':
          if (value && this.valueField === 'id') {
            this.getPromotionById(value);
          }
          if (value && this.valueField === 'code') {
            this.getPromotionsByFilter(value);
          }
          break;
        case 'rule':
          if (value && this.valueField === 'id') {
            this.getRule(value);
          }
          if (value && this.valueField === 'code') {
            this.filterRules(value);
          }
          break;
        case 'stepTemplate':
          if (value && this.valueField === 'id') {
            this.getStepTemplateById(value);
          }
          if (value && this.valueField === 'code') {
            this.filterStepTemplates(value);
          }
          break;
        case 'account':
          if (value && this.valueField === 'id') {
            this.getAccountById(value);
          }
          break;
        case 'store':
          if (value && this.valueField === 'id') {
            this.getStoreById(value);
          }
          break;
      }
    } else {
      this.showValue = value;

      if (value) {
        const hint = {};
        hint[this.valueField] = value;
        this.hints = [hint];
        this.parentGroup.controls[this.controlName].patchValue(this.parentGroup.controls[this.controlName].value);
      }
    }
  }

  setShowValue(result, value) {
    this.showValue = value;
    if (this.labelField && result && result[this.labelField]) {
      this.showValue = result[this.labelField];
    } else {
      this.showValue = value;
    }

    this.hints = [result];
    this.parentGroup.controls[this.controlName].patchValue(this.parentGroup.controls[this.controlName].value);
  }

  getControlValidationErrors(): Array<string> {
    const errors: Array<string> = [];

    const controlErrors: ValidationErrors = this.parentGroup.controls[this.controlName].errors;
    if (controlErrors) {
      Object.keys(controlErrors).forEach(keyError => {
        errors.push(keyError);
      });
    }

    return errors;
  }

  private select = (): void => {
    let modalTypeComponent;
    if (this.parentGroup.controls[this.controlName].status === 'DISABLED') {
      return;
    }
    if (this.pickerType) {
      switch (this.pickerType) {
        case 'store':
          modalTypeComponent = StoresSelectModalComponent;
          break;
        case 'product':
          modalTypeComponent = ProductsSelectModalComponent;
          break;
        case 'group':
          modalTypeComponent = GroupsSelectModalComponent;
          break;
        case 'sticker':
          modalTypeComponent = StickersSelectModalComponent;
          break;
        case 'promotion':
          modalTypeComponent = PromotionsSelectModalComponent;
          break;
        case 'rule':
          modalTypeComponent = RulesSelectModalComponent;
          break;
        case 'stepTemplate':
          modalTypeComponent = StepTemplateSelectModalComponent;
          break;
        case 'account':
          modalTypeComponent = AccountsSelectModalComponent;
          break;
        case 'cms':
          modalTypeComponent = CmsSelectModalComponent;
          break;
        case 'notificationType':
          modalTypeComponent = NotificationTypeSelectModalComponent;
          break;
        case 'upload':
          modalTypeComponent = FilesAddModalComponent;
          break;
      }
      const modalRef = this.dialog.open(modalTypeComponent, {
        disableClose: this.pickerType === PickerInputType.UPLOAD,
      });
      const modalSelectComponent = modalRef.componentInstance as typeof modalTypeComponent;
      modalSelectComponent.dialogRef = modalRef;
      modalSelectComponent.isModal = true;
      modalSelectComponent.selectMode = this.selectMode;
      modalSelectComponent.disableActions = true;
      modalSelectComponent.disableListingActions = true;
      modalSelectComponent.disableExpand = true;
      modalSelectComponent.toggleArrow = false;
      //Upload
      modalSelectComponent.uploadStep = false;
      modalSelectComponent.multiple = false;

      if (this.list?.length > 0) {
        modalSelectComponent.selectedProducts = this.list;
      }
      if (this.pickerType === PickerInputType.UPLOAD) {
        // Handle uploading
        modalSelectComponent.uploadedHandler = (newFile: DmsFileDto) => {
          modalSelectComponent.isUploading = true;
          this.appBlockerService.block();
          newFile.contentHref = null;
          switch (this.uploadType) {
            case 'cbFile': {
              newFile.type = 'CODEBOOK_IMPORT';
              break;
            }
            case 'aclFile': {
              newFile.type = 'ACL_IMPORT';
              break;
            }
            default: {
              newFile.type = 'PC_IMPORT';
              break;
            }
          }
          newFile.extId = newFile.type + this.getFileExtIdHashCode(newFile);
          this.adminDmsService
            .createFile(newFile)
            .pipe(finalize(this.appBlockerService.unblock))
            .subscribe(
              file => {
                modalSelectComponent.isUploading = false;
                this.selectionChanged.emit(file);
                this.showValue = file[this.labelField];
                this.parentGroup.controls[this.controlName].patchValue(file);
                modalRef.close();
              },
              error => modalRef.close()
            );
        };
      } else {
        // Default selectHandler
        modalSelectComponent.selectHandler = result => {
          this.selectItem(result);
        };
      }
    }
  }

  selectItem(selectedItem) {
    if (!this.selectMode) {
      if (this.labelField) {
        if (selectedItem && selectedItem[this.labelField]) {
          this.showValue = selectedItem[this.labelField];
        } else {
          this.showValue = null;
        }
      }
      this.selectionChanged.emit(selectedItem);
      this.hints = [selectedItem];
      this.parentGroup.controls[this.controlName].patchValue(selectedItem ? selectedItem[this.valueField] : selectedItem);
    } else {
      if (selectedItem) {
        this.list = selectedItem;
        this.parentGroup.controls[this.controlName].patchValue(
          this.list?.map(product => product[this.valueField]),
        );
      } else {
        this.list = null;
        this.parentGroup.controls[this.controlName].patchValue([]);
      }
      this.selectionChanged.emit(selectedItem);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    if (value) {
      this.list.push(value);
      if (!this.parentGroup.controls[this.controlName].value) {
        this.parentGroup.controls[this.controlName].patchValue([]);
      }
      this.parentGroup.controls[this.controlName].value.push(value);
    }

    // Clear the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item): void {
    const index = this.list.indexOf(item);
    if (index >= 0) {
      this.list.splice(index, 1);
      (this.parentGroup.controls[this.controlName]?.value as [])?.splice(index, 1);
      if ((this.parentGroup.controls[this.controlName].value as []).length === 0) {
        this.parentGroup.controls[this.controlName].patchValue(null);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.parentGroup && this.parentGroup.controls[this.controlName].value instanceof Array) {
      this.list = [
        {
          [this.valueField]: this.parentGroup.controls[this.controlName].value,
        },
      ];
    }
  }

  getProductById(value: any) {
    this.adminProductService
      .getProductById(value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setShowValue(result, value);
      });
  }

  getProductByFilter(value: any) {
    const search: Search = {
      filtering: [
        {
          column: 'productCode',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: value,
        },
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: -1,
      },
    };
    this.adminProductService
      .getProductsByFilter(search)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result.data.length > 0) {
          this.setShowValue(result.data[0], value);
        }
      });
  }

  getGroupById(value: any) {
    this.adminProductGroupService
      .getGroupById(value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setShowValue(result, value);
      });
  }

  getStickerById(value: any) {
    this.adminStickerService
      .getStickerById(value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setShowValue(result, value);
      });
  }

  getStickersByFilter(value: any) {
    const search: Search = {
      filtering: [
        {
          column: 'code',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: value,
        },
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: -1,
      },
    };
    this.adminStickerService
      .getStickersByFilter(search)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result.data.length > 0) {
          this.setShowValue(result.data[0], value);
        }
      });
  }

  getPromotionById(value: any) {
    this.adminPromotionService
      .getPromotionById(value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setShowValue(result, value);
      });
  }

  private getPromotionsByFilter(value: any) {
    const search: Search = {
      filtering: [
        {
          column: 'code',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: value,
        },
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: -1,
      },
    };
    this.adminPromotionService
      .getPromotionsByFilter(search)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result.data.length > 0) {
          this.setShowValue(result.data[0], value);
        }
      });
  }

  getRule(value: any) {
    this.adminAclService
      .getRule(value, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setShowValue(result, value);
      });
  }

  private filterRules(value: any) {
    const search: Search = {
      filtering: [
        {
          column: 'code',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: value,
        },
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: -1,
      },
    };
    this.adminAclService
      .filterRules(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result.data.length > 0) {
          this.setShowValue(result.data[0], value);
        }
      });
  }

  getStepTemplateById(value: any) {
    this.adminStepTemplateService
      .getStepTemplateById(value, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.setShowValue(result, value);
      });
  }

  filterStepTemplates(value: any) {
    const search: Search = {
      filtering: [
        {
          column: 'code',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: value,
        },
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: -1,
      },
    };
    this.adminStepTemplateService
      .filterStepTemplates(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        if (result.data.length > 0) {
          this.setShowValue(result.data[0], value);
        }
      });
  }

  getAccountById(value: any) {
    this.appBlockerService.block();
    this.adminAccountService
      .getAccountById(value)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.customEvent.emit(result);
        this.setShowValue(result, value);
      });
  }

  getStoreById(value: string) {
    this.appBlockerService.block();
    this.adminStoreService
      .getStore(value)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.customEvent.emit(result);
        this.setShowValue(result, value);
      });
  }

  uploaded(type) {
    const modalRef = this.dialog.open(FilesAddModalComponent, { width: '1000px' });
    const pictureAddModalComponent = modalRef.componentInstance;
    pictureAddModalComponent.dialogRef = modalRef;
    pictureAddModalComponent.uploadStep = false;
    pictureAddModalComponent.multiple = false;
    pictureAddModalComponent.uploadedHandler = (newFile: DmsFileDto) => {
      pictureAddModalComponent.isUploading = true;
      this.appBlockerService.block();
      newFile.contentHref = null;
      switch (type) {
        case 'cbFile': {
          newFile.type = 'CODEBOOK_IMPORT';
          break;
        }
        case 'aclFile': {
          newFile.type = 'ACL_IMPORT';
          break;
        }
        default: {
          newFile.type = 'PC_IMPORT';
          break;
        }
      }
      newFile.extId = newFile.type + this.getFileExtIdHashCode(newFile);
      this.adminDmsService
        .createFile(newFile)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(
          file => {
            pictureAddModalComponent.isUploading = false;
            modalRef.close();
          },
          error => modalRef.close()
        );
    };
  }

  private getFileExtIdHashCode(file) {
    return `_${this.getHashCode(new Date().getTime() + file.name)
      .toString()
      .substr(0, 10)}`;
  }

  private getHashCode(value: string) {
    let hash = 0,
      i,
      chr;
    if (value.length === 0) return hash;
    for (i = 0; i < value.length; i++) {
      chr = value.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  clearInput() {
    this.showValue = null;
    this.parentGroup.controls[this.controlName].patchValue(null);
  }

  private getHintsByPickerType = (searchTerm: string): Observable<any> => {
    const search = ServiceUtils.getUnlimitedSearch();
    const searchColumn = this.labelField ? this.labelField : this.valueField;
    search.filtering.push({ column: searchColumn, value: `*${searchTerm}*`, compareType: 'LIKE' });
    search.paging.pageSize = 10;
    search.fields = ['data.' + searchColumn];

    let filterRequest;

    if (this.pickerType) {
      switch (this.pickerType) {
        case 'store':
          filterRequest = this.adminStoreService.filterStores(search);
          break;
        case 'product':
          filterRequest = this.adminProductService.getProductsByFilter(search);
          break;
        case 'group':
          filterRequest = this.adminProductGroupService.getGroupsByFilter(search);
          break;
        case 'sticker':
          filterRequest = this.adminStickerService.getStickersByFilter(search);
          break;
        case 'promotion':
          filterRequest = this.adminPromotionService.getPromotionsByFilter(search);
          break;
        case 'rule':
          filterRequest = this.adminAclService.filterRules(search, null);
          break;
        case 'stepTemplate':
          filterRequest = this.adminStepTemplateService.filterStepTemplates(search, null);
          break;
        case 'account':
          filterRequest = this.adminAccountService.filterAccounts(search, null);
          break;
        case 'cms':
          search.fields = ['data.contentMaster.' + searchColumn];
          filterRequest = this.adminContentService.getContentsByFilter(search)
            .pipe(map(result => {
              let newResult = {};
              newResult['data'] = result['data']?.map(content => content.contentMaster);
              return newResult;
            }));
          break;
        case 'notificationType':
          filterRequest = this.adminNotificationTypeService.filterNotificationTypes(search);
          break;
      }
    }
    return filterRequest.pipe(takeUntil(this.onDestroy$))
      .pipe(map(result => result['data']));
  }

  getLtModulePrefix(): string {
    let ltModulePrefix = this.pickerType.toString();
    switch (this.pickerType) {
      case PickerInputType.PRODUCT:
        ltModulePrefix = 'products.product';
        break;
      case PickerInputType.GROUP:
        ltModulePrefix = 'productGroups';
        break;
      case PickerInputType.NOTIFICATION_TYPE:
        ltModulePrefix = 'notification.type';
        break;
      case PickerInputType.CMS:
        ltModulePrefix = 'cms.content.list';
        break;
    }
    return ltModulePrefix;
  }

  getFieldTranslation() {
    const field: string = this.translateService.instant(('wc.admin.' + this.getLtModulePrefix() + '.' + this.labelField));
    return field.toLowerCase();
  }

}

export enum PickerInputType {
  STORE = 'store',
  PRODUCT = 'product',
  GROUP = 'group',
  STICKER = 'sticker',
  PROMOTION = 'promotion',
  RULE = 'rule',
  STEP_TEMPLATE = 'stepTemplate',
  ACCOUNT = 'account',
  CMS = 'cms',
  NOTIFICATION_TYPE = 'notificationType',
  UPLOAD = 'upload',
}
