import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AclService,
  AdminCommandService,
  AdminReleaseService,
  AppBlockerService,
  CompareType,
  DmsService,
  EnableDynamicLoading,
  FormUtils,
  ModulePreferencesService,
  Search,
  ServiceUtils,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import {
  PagedProcessInfosDto,
  ProcessDefinitionDto,
  ProcessInfoDto,
  ProductStateDto,
  ReleaseDto,
} from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import moment_ from 'moment';
import { SortDto } from '@btl/order-bff';
import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';

import { Observable } from 'rxjs';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
@EnableDynamicLoading({ customName: ExportComponent.PAGE_ID })
export class ExportComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'ExportComponent';

  pageId(): string {
    return ExportComponent.PAGE_ID;
  }

  moment = moment_;

  routerOutlet: any;

  processDefinitionDto: ProcessDefinitionDto;

  createdProcessIds: string[] = [];

  processInfo: ProcessInfoDto = null;

  PRODUCT_CATALOG: String = 'PRODUCT-CATALOG';

  serviceIds = ['ACL', 'CODEBOOK', this.PRODUCT_CATALOG];
  productStates = Object.values(ProductStateDto);
  releaseCodes = [];
  releases: ReleaseDto[] = [];
  serviceIdsContainsPC = true;

  exportForm: FormGroup = this.formBuilder.group({
    serviceIds: [[this.PRODUCT_CATALOG], [Validators.required]],
    productStates: [],
    releaseCode: [],
  });

  pagedProcessInfosDto: PagedProcessInfosDto;

  constantFilter = [{ column: 'processKey', compareType: CompareTypeDtoEnum.EQUAL, value: 'EXPORT' }];
  initSorting = [{ column: 'created', sortOrder: SortDto.SortOrderDtoEnum.Desc }];

  constructor(
    private formBuilder: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    private adminCommandService: AdminCommandService,
    private adminReleaseService: AdminReleaseService,
    protected stickyMessageService: StickyMessageService,
    protected confirmationDialogService: ConfirmationDialogService,
    public aclService: AclService,
    private httpClient: HttpClient,
    private dmsService: DmsService,
    protected appBlockerService: AppBlockerService,
    protected modulePreferencesService: ModulePreferencesService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
    const search: Search = { filtering: [], sorting: [], paging: { page: 1, pageSize: -1 } };
    this.adminReleaseService
      .filterReleases(search, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.releases = result.data;
        this.releaseCodes = this.releases.map(release => release.releaseCode);
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
  }

  public onRouterOutletActivate(event: any) {
    this.routerOutlet = event;
  }

  export() {
    FormUtils.validateAllFormFields(this.exportForm);
    if (this.exportForm.valid) {
      this.appBlockerService.block();
      this.processDefinitionDto = {
        processKey: 'EXPORT',
        parameters: {},
      };
      this.processDefinitionDto.parameters.serviceId = this.getServiceIds(this.exportForm.controls.serviceIds.value);
      if (this.containsPC(this.exportForm.controls.serviceIds.value)) {
        this.processDefinitionDto.parameters.productStates = this.getProductStates(
          this.exportForm.controls.productStates.value
        );
        this.processDefinitionDto.parameters.releaseId = this.getReleaseId(this.exportForm.controls.releaseCode.value);
        this.processDefinitionDto.parameters.releaseCode = this.exportForm.controls.releaseCode.value;
      } else {
        this.processDefinitionDto.parameters.productStates = new Array<string>();
        this.processDefinitionDto.parameters.releaseId = undefined;
        this.processDefinitionDto.parameters.releaseCode = undefined;
      }
      this.adminCommandService
        .createProcess(this.processDefinitionDto)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(createdProcess => {
          this.createdProcessIds.push(createdProcess.instanceId);
          this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
          this.loadData();
        });
    }
  }

  getProcess(processId) {
    this.adminCommandService.getProcessById(processId).subscribe(proc => {
      this.processInfo = proc;
    });
  }

  setServiceIds(serviceId) {
    this.serviceIdsContainsPC = this.containsPC(serviceId);
  }

  /**
   * Returns release ID according to release code. Uses loaded releases
   * @param releaseCode
   */
  private getReleaseId(releaseCode: String): String {
    const release = this.releases.find(release => release.releaseCode === releaseCode);
    if (release) {
      return release.id;
    } else {
      return undefined;
    }
  }

  private getServiceIds(selectedValues: any): any {
    if (selectedValues instanceof Array) {
      return (selectedValues as Array<string>).map(v => v.toLowerCase());
    } else {
      return undefined;
    }
  }

  private getProductStates(selectedValues: any): any {
    if (selectedValues instanceof Array) {
      return (selectedValues as Array<string>).map(v => v.trim());
    } else {
      return new Array<string>();
    }
  }

  containsPC(val): boolean {
    /*if (val === this.PRODUCT_CATALOG) {
      return true;
    }*/
    if (val instanceof Array && (val as Array<string>).find(v => v === this.PRODUCT_CATALOG)) {
      return true;
    }
    return false;
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.pagedProcessInfosDto = null;
      this.adminCommandService
        .filterProcesses(this.search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            result.data.forEach(process => {
              ExportComponent.addServicesColumn(process);
              process['exportState'] = process.state;
              process['releaseId'] = process.parameters['releaseId'];
              process['releaseCode'] = process.parameters['releaseCode'];
            });

            this.pagedProcessInfosDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  public static addServicesColumn(process) {
    let services: [] = process.parameters['serviceId'];
    if (!services) {
      services = [];
    }

    process['services'] = services.map((service: string) => service.toUpperCase()).join(', ');
  }

  download(row) {
    const search = ServiceUtils.getUnlimitedSearch();
    const dmsIds = Object.keys(row.parameters)
      .filter(key => key.indexOf('DmsFileId') > -1)
      .map(key => {
        return row.parameters[key];
      });
    if (dmsIds && dmsIds.length > 0) {
      this.appBlockerService.block();
      search.filtering.push({ column: 'id', compareType: CompareTypeDtoEnum.IN, value: dmsIds });
      this.dmsService
        .filterFiles(search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          result.data.forEach(file => {
            const httpOptions = {
              responseType: 'blob' as 'json',
            };
            this.httpClient
              .get(file.contentHref, httpOptions)
              .pipe(finalize(this.appBlockerService.unblock))
              .subscribe((data: any) => {
                const blob = new Blob([data]);

                const downloadURL = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = downloadURL;
                link.download = file.name;
                link.click();
              });
          });
        });
    }
  }

  public showCustomButtonCallback = (row: any): boolean => {
    return row['state'] === 'COMPLETED';
  };

  public static stateToolTipCallback = (row: any): string => {
    return row['state'] === 'FAILED' ? ExportComponent.getError(row) : row['state'];
  };

  public static getError(row: any) {
    let error = null;
    const failures = row['failures'];

    if (failures) {
      if (failures.failures) {
        if (failures.failures.length > 0) {
          const failure = failures.failures[failures.failures.length - 1];
          error += failure?.stackTrace;
        }
      }
    } else {
      error = row['errorMessage'] + row['stackTrace'];
    }

    return error;
  }

  public static getColumnsToolTipCallBacks() {
    const columnToolTipCallBack: Map<string, any> = new Map<string, any>();
    columnToolTipCallBack.set('exportState', this.stateToolTipCallback);
    return columnToolTipCallBack;
  }

  public getColumnsToolTipCallBacks() {
    return ExportComponent.getColumnsToolTipCallBacks();
  }
}
