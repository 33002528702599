<div class="main__header">
  <h1>{{ 'wc.admin.dataTypeEnumItem.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="001_data_type_enum_item_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor">
      <mat-icon svgIcon="save"></mat-icon>
      {{ 'wc.common.save' | translate }}</a
    >
    <a
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="002_data_type_enum_item_edit" mat-menu-item (click)="duplicate()" *ngIf="!isNew" [routerLink]="">
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="003_data_type_enum_item_edit" mat-menu-item (click)="reset()" [routerLink]="">
        <mat-icon svgIcon="reset"></mat-icon>
        {{ 'wc.common.reset' | translate }}</a
      >
      <a id="004_data_type_enum_item_edit" mat-menu-item (click)="delete()" *ngIf="!isNew" [routerLink]="">
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
  </div>
</div>

<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

<div class="card__wrapper">
  <!-- card with main dataTypeEnumItem attributes -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.dataTypeEnumItem.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="getEditDataTypeEnumItem()" [formGroup]="dataTypeEnumItemForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="dataTypeEnumForm"
                  [controlName]="'name'"
                  [inputType]="'text'"
                  id="005_data_type_enum_item_edit"
                  [translationPrefix]="'wc.admin.dataTypeEnum'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="dataTypeEnumItemForm"
                  [controlName]="'item'"
                  [inputType]="'text'"
                  id="006_data_type_enum_item_edit"
                  [translationPrefix]="'wc.admin.dataTypeEnumItem'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="dataTypeEnumItemForm"
                  [controlName]="'seq'"
                  id="007_data_type_enum_item_edit"
                  [inputType]="'number'"
                  [translationPrefix]="'wc.admin.dataTypeEnumItem'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card__wrapper" [formGroup]="dataTypeEnumItemForm">
    <app-form-localized-texts
      class="card -full"
      headerTranslationKey="wc.admin.dataTypeEnumItem.labels"
      textTypeId="itemName"
      formControlName="localizedTexts">
    </app-form-localized-texts>
  </div>

  <div class="card__wrapper" [formGroup]="dataTypeEnumItemForm">
    <app-form-parameters
      class="card -full"
      [control]="dataTypeEnumItemForm.get('parameters')"
      formControlName="parameters">
    </app-form-parameters>
  </div>
</div>
