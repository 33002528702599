import { Component, Input, ViewChild } from '@angular/core';
import { Search } from '@btl/btl-fe-wc-common';
import { StickerListComponent } from '../product-catalogue/stickers/list/sticker-list.component';

@Component({
  selector: 'app-stickers-select-modal',
  templateUrl: './stickers-select-modal.component.html',
  styleUrls: ['./stickers-select-modal.component.scss'],
})
export class StickersSelectModalComponent {
  @ViewChild(StickerListComponent, { static: true })
  stickerListingComponent: StickerListComponent;

  @Input()
  dialogRef;

  @Input()
  selectHandler;

  multiSelectMode = false;

  singleSelectMode = true;

  excludeStickerIds = [];

  disableFilter = false;

  @Input()
  disableActions = false;

  @Input()
  disableTableActions = false;

  activeMultiSelectMode() {
    this.multiSelectMode = true;
    this.singleSelectMode = false;
  }

  selectEvent() {
    if (this.singleSelectMode) {
      const stickerDto = this.stickerListingComponent.getSelectedData()[0];
      if (stickerDto) {
        this.selectHandler(stickerDto);
        this.dialogRef.close();
      }
    } else {
      const stickerDtos = this.stickerListingComponent.getSelectedData();
      if (stickerDtos && stickerDtos.length > 0) {
        this.selectHandler(stickerDtos);
        this.dialogRef.close();
      }
    }
  }

  singleSelect(selectedDto) {
    this.selectHandler(selectedDto);
    this.dialogRef.close();
  }

  getSearch(): Search {
    return this.stickerListingComponent.search;
  }

  removeSelection() {
    if (this.singleSelectMode) {
      this.selectHandler(null);
    } else {
      this.selectHandler([]);
    }
    this.dialogRef.close();
  }
}
