import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { DmsFileBulkOperationsRequestDto, DmsFileDto } from '@btl/admin-bff';
import {
  AdminDmsService,
  AdminDynamicEnumService,
  AppBlockerService,
  CompareType,
  CurrentLocaleService,
  DmsService,
  FormUtils,
  ServiceUtils
} from '@btl/btl-fe-wc-common';
import { finalize, takeUntil } from 'rxjs/operators';
import { DmsFilesSelectModalComponent } from '@components/dms-file-select-modal/dms-files-select-modal.component';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { FilesAddModalComponent } from '@components/files-add-modal/files-add-modal.component';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { EnumerationsService } from '@service/enumerations.service';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-form-attachments',
  templateUrl: './form-attachments.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormAttachmentsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormAttachmentsComponent),
    },
  ],
})
export class FormAttachmentsComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();
  locales = environment.localization.locales;

  displayedColumns: string[] = ['fileName', 'name', 'priority', 'id', 'uploadData', 'actions'];

  ngOnDestroy() {
    let bulkOperations: DmsFileBulkOperationsRequestDto = {
      bulkCreateList: [],
      bulkUpdateList: [],
      bulkPatchList: [],
      bulkDeleteList: []
    };
    if (this.entityType && this.entityId) {
      this.form.value.attachments?.forEach((attachment: Attachment) => {
        if (attachment.dmsFile?.id && (!attachment.dmsFile.references || attachment.dmsFile.references.length === 0)) {
          bulkOperations.bulkDeleteList.push({
            id: attachment.dmsFile?.id
          });
        }
      });
    }

    if (bulkOperations.bulkDeleteList.length > 0) {
      this.adminDmsService.changeFiles(bulkOperations).subscribe(() => {
      });
    }

    this.onDestroy$.next();
  }

  @Input()
  value: Array<Attachment>;

  @Input()
  dmsType: string;

  @Input()
  extIdPrefix: string;

  @Input()
  collapsed = false;

  @Input()
  headerTranslationKey: any;

  @Input()
  getFromGalleryEnabled = false;

  @Input()
  control: AbstractControl;

  @Input()
  types: Array<any>;

  @Input()
  textTypes: Array<any>;

  @Input()
  languageChange = false;

  @Input()
  noteChange = false;

  @Input()
  nameChange = false;

  @Input()
  typeChange = false;

  @Input()
  priorityChange = true;

  @Input()
  textsChange = false;

  @Input()
  disabled = false;

  @Input()
  entityType: string;

  @Input()
  entityId: string;

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  new: boolean;

  uploadedImage = null;

  currentLocale;

  form: FormGroup = this.formBuilder.group({
    attachments: this.formBuilder.array([]),
  });

  editPictureForm;
  attachmentForm;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private adminDmsService: AdminDmsService,
    private dmsService: DmsService,
    private currentLocaleService: CurrentLocaleService,
    private appBlockerService: AppBlockerService,
    private httpClient: HttpClient,
    private adminDynamicEnumService: AdminDynamicEnumService
  ) {
    this.currentLocaleService.currentLocaleChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.setAndSortCurrentLanguage();
    });
  }

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  discloseValidatorChange: any = () => {};

  ngOnInit(): void {
    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.DMS, 'com.emeldi.ecc.be.dms.enums.TextType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.textTypes = [];
        result.data.forEach(textType => {
          this.textTypes.push({
            id: textType.name,
            entityType: '',
            contentType: textType.fields.contentType,
            priority: textType.fields.priority,
          });
        });
        this.textTypes.sort((a, b) => (a.priority > b.priority ? 1 : -1));
      });
    this.setAndSortCurrentLanguage();
    if (!this.textsChange) {
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'name');
    }
    if (!this.priorityChange) {
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'priority');
    }
    if (this.control) {
      const self = this;
      const origFunc = this.control.markAsTouched;
      this.control.markAsTouched = function () {
        FormUtils.validateAllFormFields(self.form);
        if (!self.form.valid) {
          self.control.setErrors(self.form.errors);
          self.discloseValidatorChange();
        } else {
          self.control.setErrors(null);
        }
        origFunc.apply(this, arguments);
      };
    }

    this.valueChanged();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.new) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return { invalid: true };
      }
    }
    this.new = false;
    return null;
  }

  valueChanged() {
    this.getFormArray().clear();
    if (this.entityType && this.entityId) {
      const search = ServiceUtils.getUnlimitedSearch();
      search.filtering.push({
        column: 'references',
        compareType: null,
        value: [
          {
            entityType: this.entityType,
            entityId: this.entityId,
          },
        ],
      });
      this.appBlockerService.block();
      this.adminDmsService
        .filterFiles(search, null)
        .pipe(takeUntil(this.onDestroy$))
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(result => {
          this.value = [];
          this.getFormArray().clear();
          result.data.forEach(file => {
            const attachment = {
              content: file.content,
              delete: null,
              extId: file.extId,
              href: file.contentHref,
              type: file.type,
              dmsFile: file,
            };
            attachment.dmsFile['language'] = attachment.dmsFile?.params['language'];
            this.addToFormArray(attachment);
          });
        });
    } else if (!this.value) {
      this.value = [];
    } else {
      const extIds = [];
      this.value.forEach(attachment => {
        if (attachment.extId) {
          extIds.push(attachment.extId);
        }
        if (attachment['documentType']) {
          attachment.type = attachment['documentType'];
          delete attachment['documentType'];
        }
      });

      if (extIds.length > 0) {
        const search = ServiceUtils.getUnlimitedSearch();
        search.filtering.push({
          column: 'extId',
          compareType: CompareTypeDtoEnum.IN,
          value: extIds,
        });

        this.appBlockerService.block();
        this.adminDmsService
          .filterFiles(search, null)
          .pipe(takeUntil(this.onDestroy$))
          .pipe(finalize(this.appBlockerService.unblock))
          .subscribe(result => {
            this.value.forEach(attachment => {
              const foundDmsFile = result.data.find(file => file.extId === attachment.extId);
              if (foundDmsFile) {
                attachment.dmsFile = foundDmsFile;
                attachment.dmsFile['language'] = attachment.dmsFile?.params['language'];
              }
            });
            if (this.value) {
              this.value.forEach(param => {
                this.addToFormArray(param);
              });
            }
          });
      }
    }
  }

  setAndSortCurrentLanguage() {
    this.currentLocale = this.currentLocaleService.getCurrentLanguage();
    this.locales = this.locales.filter(locale => locale !== this.currentLocale);
    this.locales.unshift(this.currentLocale);
  }

  public static getSubFormControlConfig(formBuilder, type, locale) {
    return {
      id: [null],
      href: [null],
      extId: [null, Validators.required],
      priority: [0, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
      type: [],
      delete: [],
      dmsFile: formBuilder.group({
        id: [null],
        contentHref: [null],
        extId: [null, Validators.required],
        note: [null],
        type: [type],
        code: [],
        name: [null, Validators.required],
        language: [locale],
        mediaType: [],
        size: [],
        extension: [],
        params: [],
        content: [],
        texts: [],
        created: [],
        recordVersion: [],
        references: [],
      }),
    };
  }

  addToFormArray(value) {
    const newDataFormGroup = this.formBuilder.group(
      FormAttachmentsComponent.getSubFormControlConfig(this.formBuilder, this.dmsType, this.locales[0])
    );
    if (!value.dmsFile) {
      value.dmsFile = {};
    }
    newDataFormGroup.patchValue(value);
    newDataFormGroup.get('href').patchValue(value.dmsFile.contentHref);
    this.getFormArray().push(newDataFormGroup);
    this.new = false;
  }

  getFormArray() {
    return this.form.get('attachments') as FormArray;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    if (this.new) {
      return null;
    }
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      return this.form.get('attachments').value;
    }
    return null;
  }

  add(uploadImages: any) {
    const modalRef = this.dialog.open(FilesAddModalComponent, { width: '1000px' });
    const pictureAddModalComponent = modalRef.componentInstance;
    pictureAddModalComponent.dialogRef = modalRef;
    pictureAddModalComponent.uploadStep = false;
    pictureAddModalComponent.multiple = false;
    pictureAddModalComponent.uploadedHandler = (newFile: DmsFileDto) => {
      pictureAddModalComponent.isUploading = true;
      this.appBlockerService.block();
      newFile.contentHref = null;
      newFile.type = this.dmsType;
      this.adminDmsService
        .createFile(newFile)
        .pipe(finalize(this.appBlockerService.unblock))
        .subscribe(
          file => {
            pictureAddModalComponent.isUploading = false;
            this.newFile(file, uploadImages);
            modalRef.close();
          },
          error => modalRef.close()
        );
    };
  }

  private newFile(newFile: DmsFileDto, uploadImages: any) {
    newFile.extId = this.extIdPrefix + ServiceUtils.getRandomId();
    const attachment = {
      extId: newFile.extId,
      href: null,
      type: this.types.length > 0 ? this.types[0] : null,
      dmsFile: newFile,
    };
    this.addToFormArray(attachment);
    this.edit(
      uploadImages,
      this.getFormArray().controls.find(control => control.get('extId').value === attachment.extId)
    );
  }

  uploaded(subForm: AbstractControl) {
    const modalRef = this.dialog.open(FilesAddModalComponent, { width: '1000px' });
    const pictureAddModalComponent = modalRef.componentInstance;
    pictureAddModalComponent.dialogRef = modalRef;
    pictureAddModalComponent.uploadStep = false;
    pictureAddModalComponent.multiple = false;
    pictureAddModalComponent.uploadedHandler = (newFile: DmsFileDto) => {
      newFile.contentHref = null;
      newFile.type = this.dmsType;
      if (!newFile.params) {
        newFile.params = {};
      }
      newFile.params['language'] = subForm.value.dmsFile.language;
      newFile.texts = subForm.value.dmsFile.texts;
      this.adminDmsService.createFile(newFile).subscribe(
        file => {
          this.updateFile(subForm, file);
          modalRef.close();
        },
        error => modalRef.close()
      );
      modalRef.close();
    };
  }

  getDataSource() {
    return new MatTableDataSource(
      this.getFormArray().controls.filter(control => !(control as FormGroup).get('delete').value)
    );
  }

  remove(formToRemove: AbstractControl) {
    formToRemove.get('delete').patchValue(1);
  }

  isCorrectFileFormat(fileName: string) {
    return true;
  }

  chooseFromGallery(subForm, uploadImages) {
    const modalRef = this.dialog.open(DmsFilesSelectModalComponent);
    const dmsFilesSelectModalComponent = modalRef.componentInstance;
    dmsFilesSelectModalComponent.dialogRef = modalRef;
    dmsFilesSelectModalComponent.addToConstantFilter({
      column: 'type',
      compareType: CompareTypeDtoEnum.EQUAL,
      value: this.dmsType,
    });

    dmsFilesSelectModalComponent.selectHandler = dmsFile => {
      if (subForm) {
        if (!dmsFile.params) {
          dmsFile.params = {};
        }
        if (!dmsFile.params['language']) {
          dmsFile.params['language'] = subForm.value.dmsFile.language;
        }
        if (!dmsFile.texts) {
          dmsFile.texts = subForm.value.dmsFile.texts;
        }
        this.updateFile(subForm, dmsFile);
      } else {
        this.newFile(dmsFile, uploadImages);
      }
      modalRef.close();
    };
  }

  private updateFile(subForm, dmsFile) {
    if (!dmsFile.extId) {
      dmsFile.extId = this.extIdPrefix + ServiceUtils.getRandomId();
    }
    subForm.get('extId').patchValue(dmsFile.extId);
    subForm.get('href').patchValue(dmsFile.contentHref);
    subForm.get('dmsFile').patchValue(dmsFile);
  }

  download(subForm: AbstractControl) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    this.httpClient.get(subForm.get('href').value, httpOptions).subscribe((data: any) => {
      const blob = new Blob([data]);

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = subForm.value.dmsFile.name;
      link.click();
    });
  }

  cancel() {
    this.editPictureForm = null;
    this.dialog.closeAll();
  }

  saveChanges() {
    this.attachmentForm.patchValue(this.editPictureForm.getRawValue());
    this.attachmentForm = null;
    this.editPictureForm = null;
    this.dialog.closeAll();
  }

  edit(uploadImages: any, subForm: AbstractControl) {
    this.attachmentForm = subForm as FormGroup;
    this.editPictureForm = this.formBuilder.group(
      FormAttachmentsComponent.getSubFormControlConfig(
        this.formBuilder,
        this.attachmentForm.value.type,
        this.attachmentForm.value.locale
      )
    );
    this.editPictureForm.patchValue(this.attachmentForm.getRawValue());
    this.dialog.open(uploadImages);
  }

  getElementName(element: Attachment) {
    return element?.dmsFile?.texts?.find(
      text => text.locale === this.currentLocaleService.getCurrentLanguage() && text.textType === 'NAME'
    )?.message;
  }

  public static addLanguageParam(dmsFile) {
    if (!dmsFile.params) {
      dmsFile.params = {};
    }
    dmsFile.params['language'] = dmsFile['language'];
    delete dmsFile['language'];
  }

  public static handleDmsReference(file, entityType, entityId) {
    if (file && entityType && entityId) {
      let references = file.references || [];
      if (!references.find(reference => reference.entityType === entityType && reference.entityId === entityId)) {
        references.push({
          entityType: entityType,
          entityId: entityId,
          refType: 'GENERAL',
        });
      } else if (file.delete) {
        references = references.filter(
          reference => reference.entityType !== entityType && reference.entityId != entityId
        );
      }
      file.references = references;
    }
    delete file.delete;
  }
}

export interface Attachment {
  id?: number;
  extId: string;
  href: string;
  type: string;
  content: any;
  delete: any;
  dmsFile: DmsFileDto;
}
