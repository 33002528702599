<div *ngIf="editable" class="main__header">
  <div class="header-box">
    <div class="icon-box PC">
      <mat-icon svgIcon="cartEvent"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.cartEvents' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      id="002_cart_events_listing"
      (click)="newCartEvent()"
      [routerLink]=""
      *ngIf="aclService.pageEditAccess"
      color="primary"
      mat-raised-button
      class="btn -primary -xs"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>
<div class="card__wrapper">
  <div class="card__inner" [hidden]="hideFilter">
    <app-cartEvents-filter
      (filterChanged)="handleFilterChange()"
      (hideEvent)="toggleFilter()"
      [filtering]="search.filtering">
    </app-cartEvents-filter>
  </div>
  <!-- card -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.cartEvents.cartEventListing' | translate }}</span>
          <div class="paginator__entries" *ngIf="pagedCartEventsDto?.currentPage > 0">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedCartEventsDto?.totalItems
                          ? pagedCartEventsDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedCartEventsDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action" *ngIf="!disableListingActions">
          <button
            id="003_cart_events_listing"
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedData()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="[
            'fromTypeId',
            'fromId',
            'eventType',
            'toProductMasterId',
            'priority',
            'validFor.startDateTime',
            'validFor.endDateTime',
            'created',
            'modified'
          ]"
          [columnToTruncate]="['type', 'modifiedBy']"
          [dontShowOnMobile]="['validFor.startDateTime', 'validFor.endDateTime', 'created', 'modified']"
          [fillIdBy]="'id'"
          (editButtonEvent)="editCartEvent($event)"
          (editInNewTabButtonEvent)="editCartEvent($event, true)"
          (doubleClickEvent)="editCartEvent($event)"
          (deleteButtonEvent)="deleteData($event)"
          (pageSizeEvent)="pageSizeChanged($event)"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [selectMode]="selectMode"
          [disableActions]="disableActions"
          [pagedDto]="pagedCartEventsDto"
          [editable]="aclService.pageEditAccess"
          [translationPrefix]="'wc.admin.cartEvents'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
