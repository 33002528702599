import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AdminAclService, CompareType, Search, ServiceUtils } from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { PagedPrivilegesDto, PagedRolesDto, RuleDto } from '@btl/admin-bff';
import { Subject } from 'rxjs';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-acl-table',
  templateUrl: './acl-table.component.html',
  styleUrls: ['./acl-table.component.scss'],
})
export class AclTableComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  columns;

  @Input()
  aclRules;

  @Input()
  parentAclRules: Array<RuleDto>;

  @Input()
  parentForm;

  @Input()
  context;

  @Input()
  resourceType;

  @Input()
  headerTranslateKey;

  @Input()
  collapsed = false;

  @Input()
  editable = true;

  @Input()
  parameters: Array<any>;

  @Input()
  category = false;

  @Output()
  readonly grantAll: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly revokeAll: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  readonly parameterChanged: EventEmitter<string> = new EventEmitter<string>();

  aclPrivileges: PagedPrivilegesDto;
  aclRoles: PagedRolesDto;

  @Input()
  parameterName;

  constructor(private adminAclService: AdminAclService) {
    this.adminAclService
      .filterRoles(ServiceUtils.getUnlimitedSearch(), null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(roles => {
        this.aclRoles = roles;
      });
  }

  ngOnInit(): void {
    const filter: Search = {
      filtering: [
        {
          column: 'resourceType',
          compareType: CompareTypeDtoEnum.EQUAL,
          value: this.resourceType,
        },
      ],
      sorting: [],
      paging: { page: 1, pageSize: -1 },
    };
    this.adminAclService
      .filterPrivileges(filter, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(privileges => {
        this.aclPrivileges = privileges;
      });
  }
}
