import { Component, Input, ViewChild, ViewChildren } from '@angular/core';
import { StepTemplateConfigDto } from '@btl/admin-bff/model/stepTemplateConfigDto';
import {
  EditStepModalComponent,
} from '@components/tickets/ticket-step-template/edit/steps/edit-step-modal/edit-step-modal.component';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  TicketTypeStepsListItemComponent,
} from '@components/tickets/ticket-step-template/edit/steps/list/item/ticket-type-steps-list-item.component';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-ticket-step-template-steps-list',
  templateUrl: './ticket-step-template-steps-list.component.html',
})
export class TicketStepTemplateStepsListComponent {
  @ViewChild('stepList') stepList;

  @ViewChildren(TicketTypeStepsListItemComponent)
  ticketTypeStepsListItemComponent: Array<TicketTypeStepsListItemComponent>;

  @Input()
  parentGroup: FormGroup;

  @Input()
  readOnly: boolean = true;

  createStepsForm(): FormGroup {
    return this.formBuilder.group({
      stepSeqNo: [],
      code: [],
      description: [],
    });
  }

  get stepsFormArray(): FormArray {
    return <FormArray>this.parentGroup.get('steps');
  }

  constructor(private dialog: MatDialog, private formBuilder: FormBuilder) {}

  editStep(step?: StepTemplateConfigDto, position?) {
    const modalRef = this.dialog.open(EditStepModalComponent);
    const editStepModalComponent = modalRef.componentInstance;
    if (step) {
      editStepModalComponent.editStepForm.patchValue(step);
    }
    editStepModalComponent.dialogRef = modalRef;
    editStepModalComponent.selectHandler = (stepForm: FormGroup) => {
      if (position !== undefined) {
        this.stepsFormArray.at(position).patchValue(stepForm.value);
      } else {
        stepForm.controls['stepSeqNo'].patchValue(this.stepsFormArray.length + 1);
        const newStepForm = this.createStepsForm();
        newStepForm.patchValue(stepForm);
        this.stepsFormArray.push(stepForm);
      }
    };
  }

  deleteStep(step: StepTemplateConfigDto, position?) {
    this.stepsFormArray.removeAt(position);
    this.stepSeqNoReload(position);
  }

  addStep() {
    this.editStep();
  }

  deleteSteps() {
    this.getSelectedTicketTypeSteps().forEach(selectedStep => {
      this.deleteStep(selectedStep);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    const stepList = this.stepsFormArray.getRawValue();
    moveItemInArray(stepList, event.previousIndex, event.currentIndex);
    let sequence = 1;
    for (const step of stepList) {
      step.stepSeqNo = sequence++;
    }
    this.stepsFormArray.patchValue(stepList);
  }

  stepSeqNoReload(startingPosition?) {
    let newSeq = 1;
    const stepList = this.stepsFormArray.getRawValue();
    stepList.forEach(step => {
      if (startingPosition) {
        if (step.stepSeqNo >= startingPosition + 1) {
          step.stepSeqNo -= 1;
        }
      } else {
        step.stepSeqNo = newSeq;
        newSeq++;
      }
    });
    this.stepsFormArray.patchValue(stepList);
  }

  getSelectedTicketTypeSteps(): Array<any> {
    const selectedSteps = [];
    this.ticketTypeStepsListItemComponent.forEach(ticketTypeStepsListItem => {
      if (ticketTypeStepsListItem.selected) {
        selectedSteps.push(ticketTypeStepsListItem.step);
      }
    });
    return selectedSteps;
  }
}
