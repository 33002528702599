import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { AdminDynamicEnumService, AdminMetaProfileService, FormUtils } from '@btl/btl-fe-wc-common';
import { MatDialog } from '@angular/material/dialog';
import {
  FormParametersEditModalComponent
} from '@components/form-parameters-edit-modal/form-parameters-edit-modal.component';
import {
  MetaProfilesSelectModalComponent
} from '@components/meta-profiles-select-modal/meta-profiles-select-modal.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-form-parameters',
  templateUrl: './form-parameters.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormParametersComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormParametersComponent),
    },
  ],
})
export class FormParametersComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  private onDestroy$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  @Input()
  module: string;

  @Input()
  value: Array<Parameter>;

  @Input()
  dictionaryValue = false;

  @Input()
  collapsed = false;

  @Input()
  headerTranslationKey: any;

  @Input()
  private control: AbstractControl;

  @Input()
  disabled;

  @Input()
  paramNameEnumSourceName: string;

  @Input()
  paramNameEnumName: string;

  @Input()
  paramNames: string[];

  @Input()
  excludeParams: string[];

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  newParameter: boolean;

  form: FormGroup = this.formBuilder.group({
    parameters: this.formBuilder.array([]),
  });

  discloseValidatorChange = () => {};

  constructor(
    private adminMetaProfileService: AdminMetaProfileService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.newParameter) {
      FormUtils.validateAllFormFields(this.form);
      if (!this.form.valid) {
        return { invalid: true };
      }
    }

    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  ngOnInit() {
    if (this.showParamNameSelectbox()) {
      this.adminDynamicEnumService
        .getEnumEntries(this.paramNameEnumSourceName, this.paramNameEnumName)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => (this.paramNames = result.data.map(enumData => enumData.name)));
    }
    const self = this;
    const origFunc = this.control.markAsTouched;
    this.control.markAsTouched = function () {
      self.newParameter = false;
      FormUtils.validateAllFormFields(self.form);
      if (!self.form.valid) {
        self.control.setErrors(self.form.errors);
        self.discloseValidatorChange();
      } else {
        self.control.setErrors(null);
      }
      origFunc.apply(this, arguments);
    };
    this.valueChanged();
    this.form.valueChanges.subscribe(value => this.propagateChange(this.getValue()));
  }

  showParamNameSelectbox(): boolean {
    return !!this.paramNameEnumSourceName && !!this.paramNameEnumName && !this.paramNames;
  }

  valueChanged() {
    if (!this.value) {
      this.value = [];
    }

    this.getFormArray().clear();

    if (this.value) {
      this.value.forEach(param => {
        this.addParameterToFormArray(param);
      });
    }
  }

  getFormArray() {
    return this.form.get('parameters') as FormArray;
  }

  containsParameter(parameterName: string): boolean {
    return (this.form.get('parameters') as FormArray).controls.find(control => {
      const formGroup = control as FormGroup;
      if ((control as FormGroup).controls.name.value === parameterName) {
        return control;
      }
    })
      ? true
      : false;
  }

  public static getParameterFormControlConfig() {
    return {
      name: [null, Validators.required],
      value: [null, Validators.required],
    };
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  writeValue(value: any): void {
    if (this.dictionaryValue) {
      this.value = [];
      if (value) {
        Object.entries(value).forEach(([key, loopValue]) =>
          this.value.push({
            name: key,
            value: loopValue?.toString(),
          })
        );
      }
    } else {
      this.value = value;
    }

    this.valueChanged();
  }

  getValue() {
    if (this.form.valid) {
      const ret = this.form.get('parameters').value;
      if (this.dictionaryValue) {
        const dictionary = {};
        ret.forEach(param => (dictionary[param.name] = param.value));
        return dictionary;
      } else {
        return ret;
      }
    }
    return null;
  }

  getNotUsedParameterNames(exceptName?: string) {
    return this.paramNames?.filter(paramName =>
      !this.form.getRawValue().parameters.find(groupParam => groupParam.name === paramName && groupParam.name != exceptName))
  }

  addParam() {
    const modalRef = this.dialog.open(FormParametersEditModalComponent, {width: '520px', height: '320px'});
    const formParametersEditModalComponent = modalRef.componentInstance;
    formParametersEditModalComponent.dialogRef = modalRef;
    formParametersEditModalComponent.paramNames = this.getNotUsedParameterNames();
    formParametersEditModalComponent.selectHandler = parameter => {
      this.collapsed = false;
      this.newParameter = true;
      this.addParameterToFormArray(parameter.getRawValue());
    };
  }

  addParameterToFormArray(parameter) {
    const parameterDataFormGroup = this.formBuilder.group(FormParametersComponent.getParameterFormControlConfig());
    if (this.hideParameter(parameter.name)) {
      parameterDataFormGroup.controls['value'].setValidators(null);
    }
    parameterDataFormGroup.patchValue(parameter);
    this.getFormArray().push(parameterDataFormGroup);
  }

  edit(parameterForm) {
    const modalRef = this.dialog.open(FormParametersEditModalComponent, {width: '520px', height: '320px'});
    const parameter = parameterForm.getRawValue();
    const formParametersEditModalComponent = modalRef.componentInstance;
    formParametersEditModalComponent.dialogRef = modalRef;
    formParametersEditModalComponent.parameter = parameter;
    formParametersEditModalComponent.paramNames = this.getNotUsedParameterNames(parameter.name);
    formParametersEditModalComponent.selectHandler = parameter => {
      const index = this.getFormArray().value.findIndex(param => param.name === parameterForm.get('name').value);
      if (index !== -1) {
        this.getFormArray().removeAt(index);
        this.getFormArray().insert(index, parameter);
      }
    };
  }

  remove(parameterForm: AbstractControl) {
    if (!this.disabled) {
      let index = 1;
      let foundIndex = null;
      this.getFormArray().controls.forEach(control => {
        if (control === parameterForm) {
          foundIndex = index;
        }
        index++;
      });

      if (foundIndex) {
        this.getFormArray().removeAt(foundIndex - 1);
      }
    }
  }

  addProfileMetaParameters() {
    const modalRef = this.dialog.open(MetaProfilesSelectModalComponent);
    const profilesSelectModalComponent = modalRef.componentInstance;
    profilesSelectModalComponent.dialogRef = modalRef;
    profilesSelectModalComponent.disableListingActions = true;
    profilesSelectModalComponent.disableActions = true;
    profilesSelectModalComponent.selectMode = true;
    profilesSelectModalComponent.module = this.module;
    profilesSelectModalComponent.selectHandler = selectedProfile => {
      this.adminMetaProfileService.getMetaProfileById(this.module, selectedProfile.id).subscribe(profile => {
        profile.metaParameters.forEach(metaParameter => {
          if (!this.containsParameter(metaParameter.name)) {
            this.addParameterToFormArray(metaParameter);
          }
        });
      });
    };
  }

  hideParameter(paramName) {
    return this.excludeParams && this.excludeParams.find(param => param === paramName);
  }
}

export interface Parameter {
  name: string;
  value: string;
}
