import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AbstractPageComponent,
  AclService,
  AdminDynamicEnumService,
  AdminProductCartEventService,
  AdminProductGroupService,
  AdminProductService,
  AppBlockerService,
  BusinessCategoryService,
  CurrentLocaleService,
  EnableDynamicLoading,
  FormUtils,
  Search,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { CartEventDto } from '@btl/admin-bff';
import { finalize, takeUntil } from 'rxjs/operators';
import { PickerInputType } from '@components/input-form/input-picker-form-field/input-picker-form-field.component';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import {
  CartEventsListingComponent,
} from '@components/product-catalogue/cart-events/cart-events-listing/cart-events-listing.component';

@Component({
  selector: 'app-cartEvent-edit',
  templateUrl: './cart-event-edit.component.html',
  styleUrls: ['./cart-event-edit.component.scss'],
  animations: [Animations.dropDownArrow],
})
@EnableDynamicLoading({ customName: CartEventEditComponent.PAGE_ID })
export class CartEventEditComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'CartEventEditComponent';

  pageId(): string {
    return CartEventEditComponent.PAGE_ID;
  }

  PickerInputType = PickerInputType;
  savingProductCartEventId;
  cartEvent: CartEventDto;
  validFor: FormGroup = this.formBuilder.group({
    startDateTime: [null, Validators.required],
    endDateTime: [],
  });

  eventTypes = [];
  productConnectionDirections = [];
  productConnectionTypes = [];
  productEntityTypes = [];
  productLinkType = [];
  scopes = [];

  cartEventForm: FormGroup = this.formBuilder.group({
    id: [null],
    code: [null, Validators.required],
    eventType: [this.eventTypes[0], Validators.required],
    connectionType: [null],
    connectionDirectionType: [null],
    priority: [0, [Validators.required, Validators.min(-99999), Validators.max(99999)]],
    validFor: this.validFor,
    scopeType: [this.productConnectionTypes[0], Validators.required],
    note: [null],
    fromTypeId: [this.productEntityTypes[0], Validators.required],
    fromId: [null, Validators.required],
    toProductMasterId: [null, Validators.required],
    connectionLinkType: [null],
    ruleSetExpression: [null],
  });

  techCategories: Array<string> = [];
  businessCategories: Array<string> = [];
  search: Search = {
    filtering: [],
    sorting: [],
    paging: {
      page: 1,
      pageSize: -1,
    },
  };

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private adminProductCartEventService: AdminProductCartEventService,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    private enumerationsService: EnumerationsService,
    private businessCategoryService: BusinessCategoryService,
    private adminProductGroupService: AdminProductGroupService,
    private stickyMessageService: StickyMessageService,
    private adminProductService: AdminProductService,
    private currentLocaleService: CurrentLocaleService,
    private adminDynamicEnumService: AdminDynamicEnumService,
    public aclService: AclService
  ) {
    super(router, route);

    this.enumerationsService
      .getProductTechnicalCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.techCategories = result;
      });

    this.businessCategoryService
      .getBusinessCategories()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        result.forEach(businessCategory => {
          this.businessCategories.push(businessCategory.id);
        });
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductCartEventScopeType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.scopes = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductCartEventType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.eventTypes = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductLinkType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.productLinkType = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductConnectionDirection')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.productConnectionDirections = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductConnectionType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.productConnectionTypes = result.data.map(value => value.name).sort();
      });

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.PC, 'com.emeldi.ecc.be.pc.enums.ProductEntityType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.productEntityTypes = result.data.map(value => value.name)
          .filter(name => '[ProdCode, CatTech, ProdGroup, CatBsns]'.indexOf(name) > -1)
          .sort();
      });
  }

  navigationSubscription(navigation: NavigationEnd) {
    if (this.isValidUrlByPattern()) {
      const cartEventId = this.params.id;
      if (cartEventId && cartEventId !== 'newProductCartEvent') {
        this.loadCartEvent(cartEventId);
      } else {
        this.cartEvent = {
          validFor: {
            startDateTime: null,
            endDateTime: null,
          },
        };
        this.cartEventForm.controls['id'].setValue('newCartEvent');
      }
    } else {
      this.cartEvent = undefined;
    }
  }

  loadCartEvent(cartEventId) {
    this.adminProductCartEventService
      .getCartEventById(cartEventId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.reloadCartEvent(result);
      });
  }

  reloadCartEvent(cartEventDto: CartEventDto) {
    this.cartEvent = cartEventDto;
    this.cartEventForm.reset(this.cartEvent);
  }

  public getProductCartEventHandler = (cartEventDto: CartEventDto): void => {
    if (cartEventDto) {
      if (cartEventDto.id === this.savingProductCartEventId) {
        this.reloadCartEvent(cartEventDto);
      } else {
        this.reloadCartEvent(cartEventDto);
        this.navigateSelf({ id: cartEventDto.id });
      }
      this.stickyMessageService.addStickySuccessMessage('wc.admin.messages.sticky.ok');
    }
  };

  reset() {
    if (this.cartEvent && this.cartEvent.recordVersion) {
      this.loadCartEvent(this.cartEvent.id);
    } else {
      this.reloadCartEvent(this.cartEvent);
    }
  }

  delete() {
    this.appBlockerService.block();
    this.adminProductCartEventService
      .deleteCartEvent(this.cartEvent.id)
      .pipe(finalize(this.appBlockerService.unblock))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.navigateSibling(CartEventsListingComponent.PAGE_ID);
        this.stickyMessageService.addStickySuccessDeleteMessage('wc.admin.messages.sticky.delete.ok');
      });
  }

  duplicate() {
    this.cartEvent.id = null;
    this.cartEventForm.controls.code.patchValue(`${this.cartEvent.code}copy`);
    this.cartEvent.recordVersion = null;
  }

  save() {
    if (this.saveCartEventAttributes()) {
      this.appBlockerService.block();
      if (this.cartEvent.recordVersion) {
        this.savingProductCartEventId = this.cartEvent.id;
        this.adminProductCartEventService
          .updateCartEvent(this.savingProductCartEventId, this.cartEvent)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getProductCartEventHandler);
      } else {
        this.savingProductCartEventId = null;
        this.cartEvent.id = null;
        this.adminProductCartEventService
          .createCartEvent(this.cartEvent)
          .pipe(finalize(this.appBlockerService.unblock))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(this.getProductCartEventHandler);
      }
    }
  }

  saveCartEventAttributes(): boolean {
    FormUtils.validateAllFormFields(this.cartEventForm);
    if (this.cartEventForm.valid) {
      if (this.cartEvent) {
        Object.keys(this.cartEventForm.controls).forEach(field => {
          const control = this.cartEventForm.get(field);
          this.cartEvent[field] = control.value;
        });
      }
      return true;
    }

    return false;
  }

  clearField(field: string) {
    this.cartEventForm.controls[field].patchValue(null);
  }

  cancel() {
    this.navigateSibling(CartEventsListingComponent.PAGE_ID);
  }
}
