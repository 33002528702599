<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'rcptAddress'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.notification'"
            [parentGroup]="filterForm"
            (keydown.enter)="handleChange()">
          </app-custom-input>

          <app-custom-input
            [controlName]="'partyRefNo'"
            [inputType]="'text'"
            [translationPrefix]="'wc.admin.notification'"
            [parentGroup]="filterForm"
            (keydown.enter)="handleChange()">
          </app-custom-input>

          <app-input-picker-form-field
            [parentGroup]="filterForm"
            [translationPrefix]="'wc.admin.notification'"
            [pickerType]="PickerInputType.NOTIFICATION_TYPE"
            [selectMode]="false"
            [valueField]="'notificationType'"
            [controlName]="'notificationType'"
            (keydown.enter)="handleChange()">
          </app-input-picker-form-field>

          <app-input-select-form-field
            [formControlName]="'gateway'"
            [label]="'notifGatewayId'"
            [options]="gatewayTypes"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.notification'"
            (keydown.enter)="handleChange()">
          </app-input-select-form-field>

          <app-input-select-form-field
            [formControlName]="'channelTypeId'"
            [options]="channelTypes"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.notification'"
            (keydown.enter)="handleChange()">
          </app-input-select-form-field>

          <app-input-select-form-field
            [formControlName]="'state'"
            [label]="'notifStateId'"
            [options]="notificationStates"
            [showEmptyOption]="true"
            [translationPrefix]="'wc.admin.notification'"
            (keydown.enter)="handleChange()">
          </app-input-select-form-field>

          <app-input-date-form-field
            [parentGroup]="filterForm"
            [translationPrefix]="'wc.admin.notification'"
            [label]="'sentFrom'"
            (keydown.enter)="handleChange()"
            [controlName]="'sent'">
          </app-input-date-form-field>

          <app-input-date-form-field
            [parentGroup]="filterForm"
            [translationPrefix]="'wc.admin.notification'"
            [label]="'sentTo'"
            (keydown.enter)="handleChange()"
            [controlName]="'toBeSent'">
          </app-input-date-form-field>

          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</form>
