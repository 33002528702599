import { Component } from '@angular/core';
import { AbstractFilterComponent, CompareType } from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-cms-content-banner-modal-filter',
  templateUrl: './cms-content-banner-filter.component.html',
  styleUrls: ['./cms-content-banner-filter.component.scss'],
})
export class CmsContentBannerFilterComponent extends AbstractFilterComponent {
  contentKeyIds = [
    { labelKey: 'wc.admin.cms.content.seoUrl', value: 'seoUrl' },
    { labelKey: 'wc.admin.cms.content.targetArea', value: 'targetArea' },
  ];
  contentStates = [
    { labelKey: 'wc.admin.cms.content.state.ACTIVE', value: 'ACTIVE' },
    { labelKey: 'wc.admin.cms.content.state.INACTIVE', value: 'INACTIVE' },
  ];
  internals = [
    { labelKey: 'wc.common.yes.button', value: true },
    { labelKey: 'wc.common.no.button', value: false },
  ];
  mapFieldWithCompareType = new Map([
    ['code', CompareTypeDtoEnum.LIKE],
    ['keys.keyId', CompareTypeDtoEnum.EQUAL],
    ['keys.keyValue', CompareTypeDtoEnum.EQUAL],
    ['name', CompareTypeDtoEnum.LIKE],
    ['state', CompareTypeDtoEnum.EQUAL],
    ['isInternal', CompareTypeDtoEnum.EQUAL],
  ]);
  mapFieldWithColumnName = new Map([
    ['keyId', 'keys.keyId'],
    ['keyValue', 'keys.keyValue'],
  ]);

  constructor(private formBuilder: FormBuilder) {
    super();

    this.filterForm = this.formBuilder.group({
      code: [],
      keyId: [],
      keyValue: [],
      name: [],
      state: [],
      isInternal: [],
    });
  }
}
