import { Component } from '@angular/core';
import { DataTypeEnumDto, DataTypeEnumItemDto, LocalizedTextPairDto } from '@btl/admin-bff';
import { DataTypeEnumItemParamDto } from '@btl/admin-bff/model/dataTypeEnumItemParamDto';
import { Sort } from '@btl/btl-fe-wc-common/lib/models/search';

@Component({
  selector: 'app-data-type-enum',
  template: '<router-outlet></router-outlet>',
})
export class DataTypeEnumComponent {
  static paramFromUrl(param: string, url: string): string {
    const match = url?.match(`^(.*\\/)${param}:(\\w*)($|\\/.*)`);
    const value = match && match[2] ? match[2] : null;
    return value;
  }

  public static sortData(data: any[], sorting: Sort[]): any[] {
    const sortedData = [];
    if (data) {
      sortedData.push(...data);
      if (sorting && sorting.length === 1) {
        const col = sorting[0].column;
        const ord = sorting[0].sortOrder === 'asc' ? 1 : -1;
        sortedData.sort((a, b) => this.compare(a[col as keyof typeof a], b[col as keyof typeof b]) * ord);
      }
    }
    return sortedData;
  }

  private static compare(a: any, b: any): number {
    const aNull = !a && a !== false ? 0 : 1;
    const bNull = !b && b !== false ? 0 : 1;
    if (aNull === 0 || bNull === 0) {
      return aNull - bNull;
    }
    if (typeof a === typeof b) {
      switch (typeof a) {
        case 'string':
          return a.localeCompare(b);
        case 'number':
          return a - b;
        case 'boolean':
          return (a === false ? 0 : 1) - (b === false ? 0 : 1);
        case 'object':
          if (a instanceof Date && b instanceof Date) {
            return a.getTime() - b.getTime();
          } else {
            return 0;
          }
        default:
          return 0;
      }
    }
    return (typeof a).localeCompare(typeof b);
  }

  public static duplicateDataTypeEnum(dataTypeEnum: DataTypeEnumDto, nameSuffix = ''): DataTypeEnumDto {
    const newDataTypeEnum: DataTypeEnumDto = {
      recordVersion: null,
      name: dataTypeEnum.name + nameSuffix,
      items: this.duplicateItems(dataTypeEnum.items),
    };
    return newDataTypeEnum;
  }

  public static duplicateDataTypeEnumItem(item: DataTypeEnumItemDto, itemSuffix = ''): DataTypeEnumItemDto {
    const newItem: DataTypeEnumItemDto = {
      recordVersion: null,
      item: item.item + itemSuffix,
      seq: item.seq,
      parameters: this.duplicateParameters(item.parameters),
      localizedTexts: this.duplicateLocalizedTexts(item.localizedTexts),
    };
    return newItem;
  }

  private static duplicateItems(items: DataTypeEnumItemDto[]): DataTypeEnumItemDto[] {
    if (!items) {
      return null;
    }
    const newItems = [];
    items.forEach(item => {
      const newItem = this.duplicateDataTypeEnumItem(item);
      newItems.push(newItem);
    });
    return newItems;
  }

  private static duplicateParameters(parameters: DataTypeEnumItemParamDto[]): DataTypeEnumItemParamDto[] {
    if (!parameters) {
      return null;
    }
    const newParameters = [];
    parameters.forEach(parameter => {
      const newParameter: DataTypeEnumItemParamDto = {
        name: parameter.name,
        value: parameter.value,
      };
      newParameters.push(newParameter);
    });
    return newParameters;
  }

  private static duplicateLocalizedTexts(localizedTexts: LocalizedTextPairDto[]): LocalizedTextPairDto[] {
    if (!localizedTexts) {
      return null;
    }
    const newLocalizedTexts = [];
    localizedTexts.forEach(localizedText => {
      const newLocalizedText: LocalizedTextPairDto = {
        locale: localizedText.locale,
        text: localizedText.text,
      };
      newLocalizedTexts.push(newLocalizedText);
    });
    return newLocalizedTexts;
  }
}
