import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AclService, AppBlockerService, FormUtils, } from '@btl/btl-fe-wc-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ReviewDto } from '@btl/admin-bff';
import { CanComponentDeactivate } from '@helpers/can-component-deactivate-guard.service';

@Component({
  selector: 'app-product-ratings-review-edit',
  templateUrl: './product-ratings-review-edit.component.html',
  styleUrls: ['./product-ratings-review-edit.component.scss'],
})
export class ProductRatingsReviewEditComponent implements OnInit, CanComponentDeactivate {
  private onDestroy$: Subject<void> = new Subject<void>();
  saveDataHandler: (review: ReviewDto) => void;
  deleteDataHandler: (review: ReviewDto) => void;
  dialogRef;

  review: ReviewDto = new class implements ReviewDto {
  };
  isCanceling = false;

  reviewForm: FormGroup = this.formBuilder.group({
    id: [{value: null, disabled: true}],
    productId: [null],
    extId: [null],
    reviewedOn: [null, Validators.required],
    rating: [0, [Validators.required, Validators.pattern('^[0-9]*$')]],
    recommended: [null],
    reviewSummary: [null],
    reviewPros: [null],
    reviewCons: [null],
    created: [{ value: null, disabled: true }],
    createdBy: [{ value: null, disabled: true }],
    modified: [{ value: null, disabled: true }],
    modifiedBy: [{ value: null, disabled: true }],
  });

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private appBlockerService: AppBlockerService,
    private formBuilder: FormBuilder,
    public aclService: AclService
  ) {}

  ngOnInit() {
    this.reloadReview(this.review);
  }

  reloadReview(review: ReviewDto) {
    if (review) {
      this.review = review;
      this.reviewForm.patchValue(this.review);
    }
  }

  reset() {
    this.reloadReview(this.review);
  }

  delete() {
    this.deleteDataHandler(this.review);
    this.dialogRef.close();
  }

  save() {
    if (this.saveReviewAttributes()) {
      this.saveDataHandler(this.review);
      this.dialogRef.close();
    }
  }

  cancel() {
    this.isCanceling = true;
    this.dialogRef.close();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.isCanceling) {
      this.isCanceling = false;
      return true;
    }
    return !!this.saveData();
  }

  saveData(): Observable<boolean> | boolean {
    return false;
  }

  saveReviewAttributes(): boolean {
    FormUtils.validateAllFormFields(this.reviewForm);
    if (this.reviewForm.valid) {
      Object.keys(this.reviewForm.controls).forEach(field => {
        this.review[field] = this.reviewForm.get(field).value;
      });
      return true;
    }
    return false;
  }
}
