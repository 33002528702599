import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractFilterComponent,
  AdminProductService,
  AppBlockerService,
  CompareType,
  FormUtils,
  ServiceUtils,
} from '@btl/btl-fe-wc-common';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { PagedProductsDto } from '@btl/admin-bff';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-duplicate-ticket-modal',
  templateUrl: './duplicate-product-modal.component.html',
})
export class DuplicateProductModalComponent extends AbstractFilterComponent {

  @Input()
  dialogRef;

  @Input()
  parentGroup: FormGroup;

  @Input()
  duplicatedProductCode: string;

  @Input()
  duplicatedSeoUrl: string;

  @Input()
  selectHandler;

  @Output()
  readonly duplicateEvent: EventEmitter<any> = new EventEmitter<any>();

  public static readonly RELATIONSHIPS = 'relationships';
  public static readonly CART_EVENTS = 'cartEvents';
  public static readonly GROUPS = 'groups';
  public static readonly RATINGS = 'ratings';
  public static readonly ENTITY_ACL = 'entityAcl';

  items = [
    DuplicateProductModalComponent.RELATIONSHIPS,
    DuplicateProductModalComponent.CART_EVENTS,
    DuplicateProductModalComponent.GROUPS,
    DuplicateProductModalComponent.RATINGS,
    DuplicateProductModalComponent.ENTITY_ACL,
  ];
  preselectedItems = [
    DuplicateProductModalComponent.RELATIONSHIPS,
    DuplicateProductModalComponent.CART_EVENTS,
    DuplicateProductModalComponent.GROUPS,
  ];

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private adminProductService: AdminProductService,
    private appBlockerService: AppBlockerService
  ) {
    super();
  }

  get formArray() {
    return this.form.get('duplicateOptions') as FormArray;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      productCode: [
        this.duplicatedProductCode,
        [Validators.required, Validators.pattern('[A-Za-z0-9_-]+'), notEqualOrNull(this.duplicatedProductCode)],
      ],
      seoUrl: [this.duplicatedSeoUrl, [Validators.pattern('[A-Za-z0-9_-]+'), notEqualOrNull(this.duplicatedSeoUrl)]],
      duplicateOptions: new FormArray([]),
    });

    if (this.duplicatedSeoUrl) {
      this.form
        .get('seoUrl')
        .setValidators([
          Validators.required,
          Validators.pattern('[A-Za-z0-9_-]+'),
          notEqualOrNull(this.duplicatedSeoUrl),
        ]);
    }
    this.items.forEach(item => this.formArray.push(new FormControl(this.preselectedItems.indexOf(item) > -1)));
  }

  continue() {
    this.appBlockerService.block();
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      const productCode = this.form.value.productCode;
      const search = ServiceUtils.getUnlimitedSearch();
      search.filtering.push({
        column: 'productCode',
        compareType: CompareTypeDtoEnum.EQUAL,
        value: productCode,
      });
      this.adminProductService
        .getProductsByFilter(search)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res: PagedProductsDto) => {
          if (res && res.totalItems > 0) {
            this.form.get('productCode').setErrors({ productCodedExists: true });
            this.appBlockerService.unblock();
          } else {
            this.form.get('productCode').setErrors(null);

            const seoUrl = this.form.value.seoUrl;
            if (seoUrl) {
              search.filtering = [
                {
                  column: 'seoUrl',
                  compareType: CompareTypeDtoEnum.EQUAL,
                  value: seoUrl,
                },
              ];
              this.adminProductService
                .getProductsByFilter(search)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((res: PagedProductsDto) => {
                  if (res && res.totalItems > 0) {
                    this.form.get('seoUrl').setErrors({ seoUrlExists: true });
                    this.appBlockerService.unblock();
                  } else {
                    this.finaliseDuplicateEvent(productCode, seoUrl);
                  }
                });
            } else {
              this.finaliseDuplicateEvent(productCode, seoUrl);
            }
          }
        });
    } else {
      this.appBlockerService.unblock();
    }
  }

  finaliseDuplicateEvent(productCode, seoUrl) {
    this.form.get('seoUrl').setErrors(null);

    const selectedItems = [];

    for (let i = 0; i < this.form.getRawValue().duplicateOptions.length; i++) {
      if (this.form.getRawValue().duplicateOptions[i]) {
        selectedItems.push(this.items[i]);
      }
    }
    this.duplicateEvent.emit({
      productCode: productCode,
      seoUrl: seoUrl,
      duplicateOptions: selectedItems,
    });
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}

export const notEqualOrNull = (val: any): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: any = control.value;

    return !val || val !== v ? null : { notEqual: true };
  };
};
