import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AbstractPageComponent, CurrentLocaleService,
  DatePickerComponent,
  EventService,
  OrderingService,
  Search,
} from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import moment_ from 'moment';
import { AbstractDashboardWidgetComponent } from '@components/dashboard/abstract-dashboard-widget.component';

@Component({
  selector: 'app-total-confirmed-orders',
  templateUrl: './total-confirmed-orders.component.html',
  styleUrls: ['./total-confirmed-orders.component.scss'],
})
export class TotalConfirmedOrdersComponent extends AbstractDashboardWidgetComponent implements OnInit {
  confirmedOrders = 0;
  createdOrders = 0;

  percentageChange = 0;

  collapsed: boolean = false;
  moment = moment_;
  constructor(protected formBuilder: FormBuilder,
              protected currentLocaleService: CurrentLocaleService,
              private orderingService: OrderingService,
              private eventService: EventService) {
    super(formBuilder, currentLocaleService);
  }

  ngOnInit(): void {
    this.filterForm.controls.date.patchValue(this.dates[0].value);
    this.periodChange(this.filterForm.controls.date.value);
  }

  periodChange(value) {
    super.periodChange(value);

    const confirmedSearch: Search = {
      filtering: [{ column: 'type.code', compareType: 'EQUAL', value: 'ORDER_PROCESSING_INIT' },
        { column: 'happened', compareType: 'BIGGEREQ', value:  this.moment.utc(this.startDate).format(DatePickerComponent.VALUE_DATE_TIME_FORMAT) },
        { column: 'happened', compareType: 'SMALLEREQ', value:  this.moment.utc(this.endDate).format(DatePickerComponent.VALUE_DATE_TIME_FORMAT) }
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: 1,
      },
    };

    this.eventService.filterEvents(confirmedSearch)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(events => {
          this.confirmedOrders = events.totalItems;
          this.getPreviousPeriodOrders(value);
      });

    const createdSearch: Search = {
      filtering: [
        {
          column: 'created',
          compareType: 'BIGGEREQ',
          value: this.moment.utc(this.startDate).format(DatePickerComponent.VALUE_DATE_TIME_FORMAT)
        },
        {
          column: 'created',
          compareType: 'SMALLEREQ',
          value: this.moment.utc(this.endDate).format(DatePickerComponent.VALUE_DATE_TIME_FORMAT)
        }
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: 1
      }
    };

    this.orderingService.filterOrders(createdSearch, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(orders => {
        this.createdOrders = orders.totalItems;
      });
  }

  getPreviousPeriodOrders(value) {
    const confirmedSearch: Search = {
      filtering: [{ column: 'type.code', compareType: 'EQUAL', value: 'ORDER_PROCESSING_INIT' },
        { column: 'happened', compareType: 'BIGGEREQ', value:  this.moment.utc(this.previousStartDate).format(DatePickerComponent.VALUE_DATE_TIME_FORMAT) },
        { column: 'happened', compareType: 'SMALLEREQ', value:  this.moment.utc(this.previousEndDate).format(DatePickerComponent.VALUE_DATE_TIME_FORMAT) }
      ],
      sorting: [],
      paging: {
        page: 1,
        pageSize: 1,
      },
    };

    this.eventService.filterEvents(confirmedSearch)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(events => {
        this.calculatePercentageChange(events.totalItems);
      });
  }

  calculatePercentageChange(previousOrdersCount) {
    if (previousOrdersCount === 0) {
      this.percentageChange = 0; // Handle case where there are no previous orders
    } else {
      const change = this.confirmedOrders - previousOrdersCount;
      this.percentageChange = Math.round((change / previousOrdersCount) * 100);
    }
  }
}
