<div class="main__header">
  <h1>{{ 'wc.admin.ticket.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      *ngIf="aclService.pageEditAccess"
      id="001_ticket_type_edit"
      color="primary"
      mat-raised-button
      class="btn -primary -xs icon stroke currentColor"
      [routerLink]=""
      (click)="save()"
      ><mat-icon svgIcon="save" />{{ 'wc.common.save' | translate }}</a
    >
    <a
      *ngIf="aclService.pageEditAccess"
      #t="matMenuTrigger"
      id="002_ticket_type_edit"
      color="primary"
      mat-stroked-button
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [routerLink]=""
      [matMenuTriggerFor]="menu"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'" />
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a *ngIf="ticketTypeDto?.id" id="003_ticket_type_edit" mat-menu-item [routerLink]="" (click)="duplicate()">
        <mat-icon svgIcon="duplicate" />
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="004_ticket_type_edit" mat-menu-item [routerLink]="" (click)="reset()"
        ><mat-icon svgIcon="reset" />{{ 'wc.common.reset' | translate }}</a
      >
      <a *ngIf="ticketTypeDto.id" id="005_ticket_type_edit" mat-menu-item [routerLink]="" (click)="delete()">
        <mat-icon svgIcon="delete" />
        {{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a id="006_ticket_type_edit" class="btn cancel-button" (click)="cancel()">
      {{ 'wc.common.cancel.button' | translate }}
    </a>
  </div>
</div>

<router-outlet />

<div class="card__wrapper">
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.ticketType.coreAttributes.header' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="ticketTypeForm" class="form" [formGroup]="ticketTypeForm">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-input-select-form-field
                  id="007_ticket_type_edit"
                  [formControlName]="'areaType'"
                  [labelOption]="'name'"
                  [valueOption]="'name'"
                  [options]="ticketTypeAreaTypes"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  id="008_ticket_type_edit"
                  [formControlName]="'defaultAsgnState'"
                  [labelOption]="'name'"
                  [valueOption]="'name'"
                  [options]="defaultAssignStates"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <div>
                  <app-custom-input
                    [id]="'009_ticket_type_edit'"
                    [inputType]="'checkbox'"
                    [parentGroup]="ticketTypeForm"
                    [controlName]="'system'"
                    [label]="'wc.admin.ticketType.system.value'" />
                  <app-custom-input
                    [id]="'010_ticket_type_edit'"
                    [inputType]="'checkbox'"
                    [parentGroup]="ticketTypeForm"
                    [controlName]="'valid'"
                    [label]="'wc.admin.ticketType.valid.value'" />
                </div>
              </div>
              <div class="form__row">
                <app-custom-input
                  id="011_ticket_type_edit"
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'code'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-custom-input
                  id="012_ticket_type_edit"
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'defaultAsgnStateReason'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-custom-input
                  id="013_ticket_type_edit"
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'defaultFollowUp'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>

              <div class="form__row">
                <app-custom-input
                  id="014_ticket_type_edit"
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'defaultSubject'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-custom-input
                  id="015_ticket_type_edit"
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'defaultAsgnTo'"
                  [inputType]="'text'"
                  [label]="'defaultAssignTo'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-custom-input
                  id="016_ticket_type_edit"
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'defaultSla'"
                  [inputType]="'number'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  id="017_ticket_type_edit"
                  [formControlName]="'defaultNoteType'"
                  [options]="defaultNoteTypes"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  id="018_ticket_type_edit"
                  [formControlName]="'defaultBsnsState'"
                  [options]="defaultBussinesStates"
                  [labelOption]="'name'"
                  [valueOption]="'name'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-custom-input
                  id="019_ticket_type_edit"
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'slaNotify'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row"></div>
              <div class="form__row">
                <app-custom-input
                  id="020_ticket_type_edit"
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'defaultBsnsStateReason'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-custom-input
                  id="021_ticket_type_edit"
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'autoClose'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'minPriority'"
                  [inputType]="'number'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'maxPriority'"
                  [inputType]="'number'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'defaultPriority'"
                  [inputType]="'number'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <app-custom-input
                  id="022_ticket_type_edit"
                  [parentGroup]="ticketTypeForm"
                  [controlName]="'defaultNote'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.ticketType'" />
              </div>
              <div class="form__row">
                <mat-form-field class="form__field" appearance="outline" floatLabel="always">
                  <mat-label>{{ 'wc.admin.ticketType.remark.value' | translate }}</mat-label>
                  <textarea
                    id="023_ticket_type_edit"
                    class="form__input"
                    matInput
                    type="text"
                    formControlName="remark"></textarea>
                </mat-form-field>
              </div>
              <div class="form__row"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card -full">
    <form class="form">
      <app-ticket-type-parameters-list
        [parentForm]="ticketTypeForm"
        [ticketTypeDto]="ticketTypeDto"
        (createParameter)="createTicketTypeParameter($event)"
        (updateParameter)="updateTicketTypeParameter($event)" />
    </form>
  </div>

  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.ticketType.steps.header' | translate }}
      </div>
      <div class="card__body">
        <form class="form" [formGroup]="ticketTypeForm">
          <div class="form__groups">
            <div class="form__group -flex" formGroupName="stepTemplate">
              <div class="form__row">
                <app-input-picker-form-field
                  id="024_ticket_type_edit"
                  labelField="code"
                  [parentGroup]="ticketTypeForm.get('stepTemplate')"
                  [controlName]="'code'"
                  [valueField]="'code'"
                  [pickerType]="PickerInputType.STEP_TEMPLATE"
                  [selectMode]="false"
                  [translationPrefix]="'wc.admin.ticket'"
                  (selectionChanged)="setStepTemplate($event)" />
              </div>
              <div class="form__row">
                <app-custom-input
                  id="025_ticket_type_edit"
                  [parentGroup]="ticketTypeForm.controls['stepTemplate']"
                  [controlName]="'description'"
                  [inputType]="'text'"
                  [label]="'value'"
                  [translationPrefix]="'wc.admin.ticketType.stepTemplateNote'" />
              </div>
              <div class="form__row"></div>
            </div>
            <div *ngIf="stepTemplateUsedAt.length > 0" class="form__group -flex">
              <div class="form__row">
                <mat-form-field class="form__field" appearance="outline" floatLabel="always">
                  <mat-label>{{ 'wc.admin.ticketType.stepTemplateUsed.value' | translate }}</mat-label>
                  <textarea
                    id="026_ticket_type_edit"
                    class="form__input"
                    matInput
                    type="text"
                    disabled
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="stepTemplateUsedAt"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
          <app-ticket-step-template-steps-list
            *ngIf="stepTemplate"
            [parentGroup]="ticketTypeForm.controls['stepTemplate']"
            [readOnly]="true" />
        </form>
      </div>
    </div>
  </div>

  <div class="card -full">
    <div class="card__inner">
      <ng-container *ngIf="aclLoaded()">
        <app-acl-table
          resourceType="TICKET"
          headerTranslateKey="wc.admin.ticketType.acl.header"
          [aclRules]="ticketTypeAclRules"
          [parentForm]="ticketTypeForm"
          [context]="resourceIdentificationContext" />
      </ng-container>
    </div>
  </div>
</div>
