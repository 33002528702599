<div *ngIf="!singleSelectMode && !multiSelectMode && editable" class="main__header">
  <div class="header-box">
    <div class="icon-box PC">
      <mat-icon svgIcon="promotions"></mat-icon>
    </div>
    <div class="header-label">{{ 'wc.admin.promotions' | translate }}</div>
  </div>
  <div class="page__menu">
    <a
      id="002_promotions_list"
      (click)="newPromotion()"
      [routerLink]=""
      [title]="'wc.admin.promotions.new.title' | translate"
      *ngIf="aclService.pageEditAccess"
      color="primary"
      mat-raised-button
      class="btn -primary -xs"
      >{{ 'wc.admin.new' | translate }}</a
    >
  </div>
</div>
<div class="card__wrapper">
  <div class="card -full">
    <div class="card__inner">
      <app-promotion-filter
        (filterChanged)="handleFilterChange()"
        [constantFilter]="constantFilter"
        [disableClearFilterButton]="disableClearFilterButton"
        [filtering]="search.filtering">
      </app-promotion-filter>
    </div>
    <div class="card__inner">
      <div class="card__header border-unset" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.promotions.form.list' | translate }}</span>
          <div class="paginator__entries">
            {{
              'wc.common.pagination.showing'
                | translate
                  : {
                      from: search.paging.pageSize * search.paging.page - search.paging.pageSize + 1,
                      to:
                        search.paging.pageSize * search.paging.page > pagedPromotionsDto?.totalItems
                          ? pagedPromotionsDto?.totalItems
                          : search.paging.pageSize * search.paging.page,
                      of: pagedPromotionsDto?.totalItems
                    }
            }}
          </div>
        </div>
        <div fxLayoutGap="10px" class="listing-action" *ngIf="!disableListingActions">
          <button
            id="003_promotions_list"
            color="primary"
            mat-stroked-button
            [disabled]="getSelectedData().length === 0"
            (click)="deleteCheckedData()"
            *ngIf="aclService.pageEditAccess"
            [routerLink]=""
            class="btn -primary -xs icon delete currentColor">
            <mat-icon svgIcon="delete"></mat-icon>
            {{ 'wc.admin.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <app-table-listing-form
          [columnName]="[
            'code',
            'name',
            'validFor.startDateTime',
            'validFor.endDateTime',
            'created',
            'modified',
            'modifiedBy'
          ]"
          [pagedDto]="pagedPromotionsDto"
          [columnToTruncate]="['code', 'name', 'modifiedBy']"
          [dontShowOnMobile]="['startDateTime', 'endDateTime', 'created', 'modified', 'modifiedBy']"
          [fillIdBy]="'id'"
          (editButtonEvent)="editPromotion($event)"
          [selectMode]="selectMode"
          [disableActions]="disableActions"
          (editInNewTabButtonEvent)="editPromotion($event, true)"
          (deleteButtonEvent)="deleteData($event)"
          (pageSizeEvent)="pageSizeChanged($event)"
          (doubleClickEvent)="singleSelectMode || multiSelectMode ? singleSelect($event) : editPromotion($event)"
          [editable]="aclService.pageEditAccess"
          (sortChangeEvent)="onSortChange($event)"
          [cacheFilter]="this.search"
          [translationPrefix]="'wc.admin.promotions'">
        </app-table-listing-form>
      </div>
    </div>
  </div>
</div>
