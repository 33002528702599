<td class="content-desktop">{{ contentMasterDto.code }}</td>
<td class="text-truncate">{{ contentMasterDto.name }}</td>
<td class="text-truncate">{{ contentMasterDto.keys[0].keyValue }}</td>
<td class="text-truncate">
  {{ 'wc.admin.cms.content.templateType.' + contentMasterDto.contentTemplateType | translate }}
</td>
<td class="text-truncate">{{ 'wc.admin.cms.content.state.' + contentMasterDto.state | translate }}</td>
<td class="text-truncate">{{ getInternalText(contentMasterDto.isInternal) | translate }}</td>
<td class="text-truncate content-desktop">{{ contentMasterDto.created | date: 'dd.MM.yyyy HH:mm' }}</td>
<td class="text-truncate content-desktop">{{ contentMasterDto.modified | date: 'dd.MM.yyyy HH:mm' }}</td>
<td class="text-truncate content-desktop">{{ contentMasterDto.modifiedBy }}</td>
<td class="table__action">
  <button
    aria-expanded="false"
    aria-haspopup="true"
    class="btn -empty table__action-button"
    data-toggle="dropdown"
    id="{{ parentId }} + dropdownUserInfo"
    type="button">
    <i class="fas fa-ellipsis-v"></i>
  </button>
  <div aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-right">
    <a
      (click)="selectAndClose()"
      href="javascript:void(0);"
      class="dropdown-item"
      title="{{ 'wc.admin.cms.content.banner.selectAndClose' | translate }}"
      >{{ 'wc.admin.cms.content.banner.selectAndClose' | translate }}</a
    >
  </div>
</td>
