import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EnableDynamicLoading } from '@btl/btl-fe-wc-common';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
@EnableDynamicLoading({customName: CanComponentDeactivateGuard.PAGE_ID})
export class CanComponentDeactivateGuard {

  public static readonly PAGE_ID = 'CanComponentDeactivateGuard';

  constructor(protected router: Router) {
  }

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    const url = nextState.url;
    if (!url.startsWith('/pc/products') || url === '/pc/products') {
      return true;
    }
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
