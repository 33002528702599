import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AbstractDashboardWidgetComponent } from '@components/dashboard/abstract-dashboard-widget.component';
import {
  AdminProductService,
  CurrentLocaleService,
  SalesRecordService,
  Search,
} from '@btl/btl-fe-wc-common';
import { takeUntil } from 'rxjs/operators';
import { SalesRecordDataDto } from '@btl/order-bff';
import CollectionTypeDtoEnum = SalesRecordDataDto.CollectionTypeDtoEnum;
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-top-products',
  templateUrl: './top-products.component.html',
  styleUrls: ['./top-products.component.scss'],
})
export class TopProductsComponent extends AbstractDashboardWidgetComponent implements OnInit {
  topProductCounts = [];

  @ViewChild('doughnutCanvas', { static: true })
  doughnutCanvas: ElementRef<HTMLCanvasElement>;
  doughnutChart: any;

  constructor(
    protected formBuilder: FormBuilder,
    protected currentLocaleService: CurrentLocaleService,
    private salesRecordService: SalesRecordService,
    private adminProductService: AdminProductService
  ) {
    super(formBuilder, currentLocaleService);
  }

  ngOnInit(): void {
    this.filterForm.controls.date.patchValue(this.dates[0].value);
    this.periodChange(this.filterForm.controls.date.value);
    Chart.register(...registerables);
  }

  periodChange(value) {
    super.periodChange(value);
    this.topProductCounts.length = 0;

    this.salesRecordService
      .getAggregatedSalesRecords(null, CollectionTypeDtoEnum.SOLD, this.startDate, this.endDate, '{topProductCount: "true"}', 1, 3, [
        'count:desc',
      ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        const productIds = result.data.map(salesCount => salesCount.productId);

        if (productIds.length > 0) {
          const search: Search = {
            filtering: [{ column: 'productCode', compareType: 'IN', value: productIds }],
            sorting: [],
            paging: {
              page: 1,
              pageSize: 3,
            },
          };

          this.adminProductService
            .getProductsByFilter(search)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(products => {
              result.data.forEach(salesCount => {
                salesCount['product'] = products.data.find(product => product.productCode === salesCount.productId);
                this.topProductCounts.push(salesCount);
                if (this.doughnutChart) {
                  this.doughnutChart.destroy();
                }
                this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
                  type: 'doughnut',
                  data: {
                    labels: this.topProductCounts.map(productCount => this.getProductName(productCount.product)),
                    datasets: [
                      {
                        data: this.topProductCounts.map(productCount => productCount.count),
                        backgroundColor: [
                          this.getColor(0),
                          this.getColor(1),
                          this.getColor(2),
                        ],
                      },
                    ],
                  },
                  options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  },
                });
              });
            });
        }
      });
  }

  getProductName(product): string {
    return product?.texts.find(
      text => text.textType === 'PRODUCT_NAME' && text.locale === this.currentLocaleService.getCurrentLanguage()
    )?.message;
  }
}
