import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ProductsSelectModalComponent } from '@components/products-select-modal/products-select-modal.component';
import { GroupProductDto, ProductDto } from '@btl/admin-bff';
import { MatDialog } from '@angular/material/dialog';
import {
  GroupSubsetProductsComponent,
} from '@components/product-catalogue/product-group/product-group-edit/group-products-listing/group-subset-products/group-subset-products.component';

@Component({
  selector: 'app-group-products-listing-item, [app-group-products-listing-item]',
  templateUrl: './group-products-listing-item.component.html',
})
export class GroupProductsListingItemComponent implements OnInit {
  groupProduct: GroupProductDto;

  @Input()
  product;

  @Input()
  visible = true;

  @Input()
  groupProductForm: FormGroup;

  @Input()
  parentId = '';

  @Output()
  readonly productChangedEvent: EventEmitter<ProductDto> = new EventEmitter<ProductDto>();

  selected = false;

  constructor(private dialog: MatDialog) {}

  public static getFormControlConfig(defaultValues?: GroupProductDto) {
    const defaultSeq = defaultValues ? defaultValues.seq : GroupSubsetProductsComponent.GROUP_PRODUCT_UNSET_PRIORITY;
    const defaultProductMasterId = defaultValues ? defaultValues.productMasterId : null;
    const defaultProductLabel = defaultValues ? defaultValues.productLabel : null;
    return {
      seq: [defaultSeq, Validators.required],
      productMasterId: [defaultProductMasterId, Validators.required],
      productLabel: [defaultProductLabel],
    };
  }

  ngOnInit() {
    this.groupProduct = this.groupProductForm.getRawValue();
  }

  changeProduct() {
    const modalRef = this.dialog.open(ProductsSelectModalComponent);
    const productsSelectModalComponent = modalRef.componentInstance;
    productsSelectModalComponent.dialogRef = modalRef;
    productsSelectModalComponent.isModal = true;
    productsSelectModalComponent.disableActions = true;
    productsSelectModalComponent.disableListingActions = true;
    productsSelectModalComponent.disableExpand = true;
    productsSelectModalComponent.nestedTable = false;
    productsSelectModalComponent.selectMode = false;
    productsSelectModalComponent.selectHandler = product => {
      this.groupProductForm.controls.productMasterId.patchValue(product.productCode);
      this.groupProduct.productMasterId = product.productCode;
      this.productChangedEvent.emit(product);
    };
  }
}
