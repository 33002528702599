import { Component } from '@angular/core';
import { AbstractFilterComponent, AdminDynamicEnumService, CompareType } from '@btl/btl-fe-wc-common';
import { FormBuilder } from '@angular/forms';
import { EnumEntriesDto } from '@btl/admin-bff';
import { takeUntil } from 'rxjs/operators';
import { EnumerationsService } from '@service/enumerations.service';
import { Animations } from '@helpers/animations';
import CompareTypeDtoEnum = CompareType.CompareTypeDtoEnum;

@Component({
  selector: 'app-dms-files-filter',
  templateUrl: './dms-files-filter.component.html',
  styleUrls: ['./dms-files-filter.component.scss'],
  animations: [Animations.advancedFilterArrow],
})
export class DmsFilesFilterComponent extends AbstractFilterComponent {
  types: EnumEntriesDto;

  panelOpenState: boolean = false;

  mapFieldWithCompareType = new Map([
    ['code', CompareTypeDtoEnum.LIKE],
    ['name', CompareTypeDtoEnum.LIKE],
    ['extId', CompareTypeDtoEnum.LIKE],
    ['note', CompareTypeDtoEnum.LIKE],
  ]);

  constructor(private formBuilder: FormBuilder, private adminDynamicEnumService: AdminDynamicEnumService) {
    super();

    this.adminDynamicEnumService
      .getEnumEntries(EnumerationsService.DMS, 'com.emeldi.ecc.be.dms.enums.FileType')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(result => {
        this.types = result;
      });

    this.filterForm = this.formBuilder.group({
      id: [],
      code: [],
      name: [],
      extId: [],
      type: [],
      mediaType: [],
      note: [],
    });
  }

  clearField(field: string) {
    this.filterForm.controls[field].patchValue(null);
  }

  toggleAdvanced() {
    this.panelOpenState = !this.panelOpenState;
  }

  clearFilter() {
    this.filterForm.reset();
  }
}
