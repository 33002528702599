import { Directive } from '@angular/core';
import {
  OperationType,
  RulesListingComponent
} from '@components/acl/roles/role-edit/rules-listing/rules-listing.component';
import { AbstractPageComponent, ServiceUtils } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';
import { RelationshipDto } from '@btl/admin-bff';

@Directive({
  selector: '[app-change-operations]'
})
export abstract class AbstractChangeOperationsComponent extends AbstractPageComponent {

  operations: Array<any>;

  protected constructor(protected router: Router,
                        protected route: ActivatedRoute,) {
    super(router, route);
  }

  addRow(row: any) {
    row[RulesListingComponent.OPERATION_TYPE] = OperationType.CREATE;
    row.id = ServiceUtils.getRandomId();
    this.operations = [...this.operations];
    this.operations.push(row);
  }

  editRow(row: any, rowInChange: any) {
    row[RulesListingComponent.OPERATION_TYPE] = OperationType.UPDATE;
    if (rowInChange) {
      rowInChange = row;
    } else {
      this.operations = [...this.operations];
      this.operations.push(row);
    }
  }

  deleteRow(row: any) {
    const foundRow = this.operations.find(operation => operation.id === row.id);
    if (foundRow) {
      this.operations = [...this.operations];
      this.operations.splice(this.operations.indexOf(row), 1);
    } else {
      row[RulesListingComponent.OPERATION_TYPE] = OperationType.DELETE;
      this.operations = [...this.operations];
      this.operations.push(row);
    }
  }

  public deleteRowCallback = (relationship: RelationshipDto): void => {
    this.deleteRow(relationship);
  };

  public getRowClassCallback = (row: any): string => {
    return this.getOperationTypeClass(this.operations, row.id);
  };

  getOperationTypeClass(where, id) {
    const ret = where.find(operation => operation.id === id);
    if (ret) {
      if (ret[RulesListingComponent.OPERATION_TYPE] === OperationType.DELETE) {
        return 'for_delete';
      } else if (ret[RulesListingComponent.OPERATION_TYPE] === OperationType.UPDATE) {
        return 'for_update';
      }
    }
    return '';
  }

  pageId(): string {
    return '';
  }
}
