import { Component } from '@angular/core';
import {
  AclService,
  AdminRuleSetService,
  AppBlockerService,
  EnableDynamicLoading,
  ModulePreferencesService,
  StickyMessageService,
} from '@btl/btl-fe-wc-common';
import { PagedRuleSetsDto } from '@btl/admin-bff';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { RuleSetEditComponent } from '@components/product-catalogue/rule-sets/rule-set-edit/rule-set-edit.component';

import { AbstractListingPageComponent } from '@helpers/abstract-listing-page-component';
import { ConfirmationDialogService } from '@service/confirmation-dialog.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-rule-sets-listing',
  templateUrl: './rule-sets-listing.component.html',
  styleUrls: ['./rule-sets-listing.component.scss'],
})
@EnableDynamicLoading({ customName: RuleSetsListingComponent.PAGE_ID })
export class RuleSetsListingComponent extends AbstractListingPageComponent {
  public static readonly PAGE_ID = 'RuleSetsListingComponent';

  pageId(): string {
    return RuleSetsListingComponent.PAGE_ID;
  }

  pagedRuleSetsDto: PagedRuleSetsDto;

  constructor(
    private adminRuleSetService: AdminRuleSetService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected appBlockerService: AppBlockerService,
    protected stickyMessageService: StickyMessageService,
    public aclService: AclService,
    protected modulePreferencesService: ModulePreferencesService,
    protected confirmationDialogService: ConfirmationDialogService,
  ) {
    super(router, route, modulePreferencesService, appBlockerService, stickyMessageService, confirmationDialogService);
  }

  navigationSubscription(navigation: NavigationEnd) {}

  editRuleSet(ruleSetDto, inNewTab: boolean = false) {
    this.navigateSibling(RuleSetEditComponent.PAGE_ID, { id: ruleSetDto.id }, inNewTab);
  }

  newRuleSet() {
    this.editRuleSet({
      id: 'newRuleSet',
    });
  }

  public filterData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.adminRuleSetService
        .filterRuleSets(this.search, null)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            this.pagedRuleSetsDto = result;
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });
  }

  protected deleteSingleRow(rowDataId: string): Observable<any> {
    return this.adminRuleSetService.deleteRuleSet(rowDataId);
  }
}
