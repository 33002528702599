import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { LocalizedTextPairDto } from '@btl/admin-bff/model/localizedTextPairDto';
import { FormUtils } from '@btl/btl-fe-wc-common';

@Component({
  selector: 'app-form-localized-texts, [app-form-localized-texts]',
  templateUrl: './form-localized-texts.component.html',
  styleUrls: ['./form-localized-texts.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormLocalizedTextsComponent),
      multi: true,
    },
  ],
})
export class FormLocalizedTextsComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input()
  headerTranslationKey = 'wc.admin.labels';

  @Input()
  contentType = 'PLAINTEXT_SINGLE_LINE';

  @Input()
  textTypeId = 'localized-text';

  @Input()
  disabled = false;

  locales = environment.localization.locales;

  @Input()
  value: LocalizedTextPairDto[];

  @Output()
  readonly onChange: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup = this.formBuilder.group({});

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  propagateChange: any = () => {};

  propagateOnTouched: any = () => {};

  ngOnInit() {
    this.valueChanged();
  }

  valueChanged() {
    if (!this.value) {
      this.value = [];
    }

    this.form = this.formBuilder.group({});
    this.form.valueChanges.subscribe(() => this.propagateChange(this.getValue()));
    this.locales.forEach(locale => {
      const formControl = new FormControl();
      const localizedTextPair = this.getLocalizedTextPair(locale);
      if (localizedTextPair) {
        formControl.patchValue(localizedTextPair.text);
      }
      this.form.addControl(locale, formControl);
    });
  }

  getLocalizedTextPair(locale: string): LocalizedTextPairDto {
    let localizedTextPair: LocalizedTextPairDto;
    this.value.forEach(textPair => {
      if (textPair.locale === locale) {
        localizedTextPair = textPair;
      }
    });
    return localizedTextPair;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
    this.valueChanged();
  }

  getValue() {
    FormUtils.validateAllFormFields(this.form);
    if (this.form.valid) {
      const localizedTexts = [];
      Object.keys(this.form.controls).forEach(field => {
        const textVal = this.form.get(field).value;
        if (textVal && textVal !== '') {
          const localizedText: LocalizedTextPairDto = {
            locale: field,
            text: textVal,
          };
          localizedTexts.push(localizedText);
        }
      });
      return localizedTexts;
    }
    return null;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
