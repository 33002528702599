<div class="page__wrapper">
  <div class="main__header">
    <h1>{{ 'wc.admin.export.form.edit' | translate }}</h1>
    <div class="page__menu">
      <a
        id="001_export_edit"
        color="primary"
        mat-raised-button
        (click)="export()"
        *ngIf="aclService.pageEditAccess"
        [routerLink]=""
        class="btn -primary -xs"
        >{{ 'wc.admin.export' | translate }}</a
      >
    </div>
  </div>

  <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

  <div class="card__wrapper">
    <!-- card with main codebook attributes -->
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.codebookType.attributes' | translate }}
        </div>
        <div class="card__body">
          <form [formGroup]="exportForm" class="form">
            <div class="form__groups">
              <div class="form__group -flex">
                <div class="form__row">
                  <app-input-select-form-field
                    (selectionChangeEmit)="setServiceIds($event)"
                    id="002_export_edit"
                    [formControlName]="'serviceIds'"
                    [options]="serviceIds"
                    [multipleSelect]="true"
                    [translationPrefix]="'wc.admin.export'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    *ngIf="serviceIdsContainsPC"
                    id="003_export_edit"
                    [formControlName]="'productStates'"
                    [options]="productStates"
                    [multipleSelect]="true"
                    [translationPrefix]="'wc.admin.export'">
                  </app-input-select-form-field>
                </div>
                <div class="form__row">
                  <app-input-select-form-field
                    *ngIf="serviceIdsContainsPC"
                    [formControlName]="'releaseCode'"
                    id="004_export_edit"
                    [options]="releaseCodes"
                    [translationPrefix]="'wc.admin.export'">
                  </app-input-select-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="card__wrapper">
    <div class="card -full">
      <div class="card__inner">
        <div class="card__header">
          {{ 'wc.admin.export.exportList' | translate }}
        </div>
        <div class="card__body">
          <app-exports-filter (filterChanged)="handleFilterChange()"
                              [cacheFilterUsed]="cacheFilterUsed"
                              [constantFilter]="constantFilter"
                              [filtering]="search.filtering">
          </app-exports-filter>
          <app-table-listing-form
            [columnName]="['instanceId', 'created', 'finished', 'services', 'exportState', 'createdBy', 'releaseCode']"
            [columnToTruncate]="['created', 'finished']"
            [fillIdBy]="'id'"
            module="export"
            [selectMode]="false"
            (customButtonEvent)="download($event)"
            [showCustomButtonCallback]="showCustomButtonCallback"
            [columnToolTipCallBack]="getColumnsToolTipCallBacks()"
            [pagedDto]="pagedProcessInfosDto"
            (pageSizeEvent)="pageSizeChanged($event)"
            (sortChangeEvent)="onSortChange($event)"
            [cacheFilter]="this.search"
            [editable]="aclService.pageEditAccess"
            [translationPrefix]="'wc.admin.export'">
          </app-table-listing-form>
        </div>
      </div>
    </div>
  </div>
</div>
