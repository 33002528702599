<div class="main__header">
  <h1>{{ 'wc.admin.codebooks.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
            *ngIf="aclService.pageEditAccess"
      id="001_product_group_edit"
      color="primary"
      mat-raised-button
            class="btn -primary -xs icon stroke currentColor"
            [routerLink]=""
      (click)="save()"
    >
      <mat-icon svgIcon="save"></mat-icon>
      {{ 'wc.common.save' | translate }}</a
    >
    <a
      *ngIf="aclService.pageEditAccess"
      #t="matMenuTrigger"
      color="primary"
      mat-stroked-button
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [routerLink]=""
      [matMenuTriggerFor]="menu"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a *ngIf="!isNew" id="002_product_group_edit" mat-menu-item [routerLink]="" (click)="duplicate()">
        <mat-icon svgIcon="duplicate"></mat-icon>
        {{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="003_product_group_edit" mat-menu-item [routerLink]="" (click)="reset()">
        <mat-icon svgIcon="reset"></mat-icon>
        {{ 'wc.common.reset' | translate }}</a
      >
      <a *ngIf="!isNew" id="004_product_group_edit" mat-menu-item [routerLink]="" (click)="delete()">
        <mat-icon svgIcon="delete"></mat-icon>
        {{ 'wc.common.delete' | translate }}
      </a>
    </mat-menu>
    <a class="btn cancel-button" (click)="cancel()">{{ 'wc.common.cancel.button' | translate }}</a>
  </div>
</div>

<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

<div class="card__wrapper">
  <!-- card with main codebook attributes -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.codebooks.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="getEditCodebook()" class="form" [formGroup]="codebookForm">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <ng-container *ngIf="codebookForm.controls['id'].value !== null; else chooseType">
                  <app-custom-input
                          id="005_product_group_edit"
                    [parentGroup]="codebookForm"
                    [controlName]="'codebookType'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.codebook'">
                  </app-custom-input>
                </ng-container>
                <ng-template #chooseType>
                  <app-input-select-form-field
                    *ngIf="types"
                    id="006_product_group_edit"
                    [formControlName]="'codebookType'"
                    [options]="types"
                    [translationPrefix]="'wc.admin.codebook'">
                  </app-input-select-form-field>
                </ng-template>
              </div>
              <div class="form__row">
                <app-custom-input
                        id="007_product_group_edit"
                  [parentGroup]="codebookForm"
                  [controlName]="'code'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.codebook'">
                </app-custom-input>
              </div>
              <div class="form__row" [formGroup]="codebookForm.controls.parent">
                <app-input-select-form-field
                        formControlName="id"
                  id="008_product_group_edit"
                  [options]="parents"
                  [labelOption]="'code'"
                  [showEmptyOption]="true"
                  valueOption="id"
                  [label]="'parent'"
                  [translationPrefix]="'wc.admin.codebook'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                        id="010_product_group_edit"
                  [parentGroup]="codebookForm"
                  [controlName]="'codeAlt'"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.codebook'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                        id="011_product_group_edit"
                  [parentGroup]="codebookForm"
                  [controlName]="'priority'"
                  [inputType]="'number'"
                  [translationPrefix]="'wc.admin.codebook'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [id]="'012_product_group_edit'"
                  [parentGroup]="codebookForm"
                  [inputType]="'checkbox'"
                  [controlName]="'visible'"
                  [label]="'wc.admin.codebook.visible'">
                </app-custom-input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- card with codebook localized texts -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.codebook.labels' | translate }}
      </div>
      <div class="card__body">
        <form class="form" [formGroup]="localizedTextsFormGroup">
          <div class="form__groups">
            <div class="form__group -flex">
              <div *ngFor="let codebookText of codebookTexts.values()" class="form__row">
                <mat-form-field class='form__field' appearance='outline' floatLabel='always'>
                  <mat-label>{{ 'wc.admin.locale.' + codebookText.locale | translate }}</mat-label>
                  <textarea
                    class="form__input"
                    matInput
                    type="text"
                    [id]="codebookText.text"
                    [formControlName]="'label-' + codebookText.locale"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.codebook.parameters.header' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            id="013_product_group_edit"
            color="primary"
            mat-raised-button
            class="btn -primary -xs edit icon stroke"
            [routerLink]=""
            (click)="addParameter()">
            <mat-icon svgIcon="plus"></mat-icon>
            {{ 'wc.common.add' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <!-- generated cards with codebook specific parameters -->
        <div *ngFor="let parameterFormGroup of parameterForms; let i = index" class="card -full">
          <div class="card__inner">
            <form
              *ngIf="getEditParameters()"
              class="form"
              [formGroup]="parameterFormGroup"
              [id]="parameterFormGroup.controls['name'].value">
              <div class="card__header" fxLayout="row" fxLayoutAlign="start center">
                {{ 'wc.admin.codebook.parameter' | translate }} -
                {{ parameterFormGroup.controls['name'].value }}
                <button mat-icon-button class="dropdown-item" [routerLink]="" (click)="removeParameter(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div class="card__body">
                <div class="form__groups">
                  <div class="form__group -flex">
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="parameterFormGroup"
                        [controlName]="'name'"
                        [id]="parameterFormGroup.controls['name'].value"
                        [inputType]="'text'"
                        [options]="parameters"
                        [translationPrefix]="'wc.admin.codebook.parameter'">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="parameterFormGroup"
                        [controlName]="'value'"
                        [id]="parameterFormGroup.controls['value'].value"
                        [inputType]="'text'"
                        [translationPrefix]="'wc.admin.codebook.parameter'">
                      </app-custom-input>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
