import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NotificationTypeGatewayAttachmentDto, TextTypeDto } from '@btl/admin-bff';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AdminDmsService, AppBlockerService } from '@btl/btl-fe-wc-common';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotificationComponent } from '@components/notification/notification.component';
import { MatDialog } from '@angular/material/dialog';
import {
  NotificationTypeAttachment,
  NotificationTypeAttachmentModalComponent
} from '@components/notification/types/edit/attachment-modal/notification-type-attachment-modal.component';
import { HttpClient } from '@angular/common/http';
import { NotificationAttachmentService } from '@service/notification-attachment.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-notification-type-template',
  templateUrl: './notification-type-template.component.html',
  styleUrls: ['./notification-type-template.component.scss'],
})
export class NotificationTypeTemplateComponent implements OnInit, OnChanges, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  @Input()
  index: number;

  @Input()
  indexGroup: number;

  @Input()
  typeGatewayFormGroup: FormGroup;

  @Input()
  textTypes: Array<TextTypeDto>;

  get parametersFormArray(): FormArray {
    return this.typeGatewayFormGroup.get('parameters') as FormArray;
  }

  get attachmentsFormArray(): FormArray {
    return this.typeGatewayFormGroup.get('attachments') as FormArray;
  }

  get templateBodyFormControl(): AbstractControl {
    return this.typeGatewayFormGroup.get('templateBody');
  }

  attachments: any[] = [];
  editorConfig: AngularEditorConfig = NotificationComponent.getEditorConfig();

  static getFileType(fileName: string): string {
    if (!fileName) {
      return '';
    }
    const type = fileName.substr(fileName.lastIndexOf('.') + 1).toUpperCase();
    let fileType = '';

    if (type) {
      switch (type.toUpperCase()) {
        case 'JPEG':
        case 'JPG':
        case 'PNG':
        case 'TIF':
        case 'TIFF':
        case 'ICO':
        case 'BMP':
        case 'GIF':
          fileType = 'IMG';
          break;
        case 'PDF':
          fileType = 'PDF';
          break;
        case 'TXT':
          fileType = 'TXT';
          break;
        case 'DOC':
        case 'DOCX':
        case 'ODT':
          fileType = 'DOC';
          break;
        case 'XLS':
        case 'XLSX':
        case 'ODS':
        case 'CSV':
          fileType = 'XSL';
          break;
        case 'ZIP':
        case 'RAR':
        case '7Z':
        case 'GZIP':
        case 'TAR':
          fileType = 'ZIP';
          break;
        default:
          fileType = '';
          break;
      }
    }
    return fileType;
  }

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private adminDmsService: AdminDmsService,
    private httpClient: HttpClient,
    private appBlockerService: AppBlockerService,
    private notificationAttachmentService: NotificationAttachmentService
  ) {}

  ngOnInit(): void {
    this.initAttachments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.index && !changes.index.firstChange) || (changes.indexGroup && !changes.indexGroup.firstChange)) {
      this.initAttachments();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  addParameter(): void {
    this.parametersFormArray.push(
      this.formBuilder.group({
        name: [null, Validators.required],
        seq: [this.parametersFormArray.length - 1],
        value: [null],
        indexedValue: [null],
      })
    );
  }

  deleteParameter(index: number): void {
    this.parametersFormArray.removeAt(index);
  }

  addAttachment(attachment?: any): void {
    const modalRef = this.dialog.open(NotificationTypeAttachmentModalComponent, { width: '1000px' });
    const attachmentModalComponent = modalRef.componentInstance;
    attachmentModalComponent.dialogRef = modalRef;
    attachmentModalComponent.attachment = attachment;

    attachmentModalComponent.saveEvent.subscribe((attachmentData: NotificationTypeAttachment) => {
      const attachments = this.attachmentsFormArray.getRawValue();
      const attachmentIndex = attachments.findIndex(i => i.extId === attachmentData.attachmentDto.extId);
      const newAttachment = attachmentData.attachmentDto as any;
      newAttachment.fileType = NotificationTypeTemplateComponent.getFileType(attachmentData.attachmentDto.name);

      if (attachmentIndex > -1) {
        this.attachmentsFormArray.at(attachmentIndex).patchValue(attachmentData.attachmentDto);
        this.attachments[attachmentIndex] = newAttachment;
      } else {
        this.attachmentsFormArray.push(
          this.formBuilder.group({
            name: [attachmentData.attachmentDto.name],
            inline: [attachmentData.attachmentDto.inline],
            contentType: [attachmentData.attachmentDto.contentType],
            extId: [attachmentData.attachmentDto.extId],
          })
        );
        this.attachments.push(newAttachment);
      }

      if (attachmentData.attachmentDto.inline) {
        this.addImageToBody(attachmentData.attachmentDto);
      }

      modalRef.close();
    });
  }

  editAttachment(attachment: NotificationTypeGatewayAttachmentDto): void {
    this.addAttachment(attachment);
  }

  deleteAttachment(index: number): void {
    this.attachmentsFormArray.removeAt(index);
    this.attachments.splice(index, 1);
  }

  downloadAttachment(attachment: NotificationTypeGatewayAttachmentDto): void {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };

    this.appBlockerService.block();
    this.notificationAttachmentService
      .getDmsFileById(attachment.extId)
      .pipe(finalize(this.appBlockerService.unblock))
      .subscribe(result => {
        this.httpClient.get(result.contentHref, httpOptions).subscribe((data: any) => {
          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = result.name;
          link.click();
        });
      });
  }

  private initAttachments(): void {
    this.attachments = [];
    if (this.attachmentsFormArray.controls.length) {
      this.attachmentsFormArray.controls.forEach((attachmentForm: FormGroup) => {
        this.attachments.push({
          name: attachmentForm.get('name').value,
          inline: attachmentForm.get('inline').value,
          contentType: attachmentForm.get('contentType').value,
          extId: attachmentForm.get('extId').value,
          fileType: NotificationTypeTemplateComponent.getFileType(attachmentForm.get('name').value),
        });
      });
    }
  }

  private addImageToBody(attachment: NotificationTypeGatewayAttachmentDto): void {
    if (
      (attachment.contentType && !attachment.contentType.toLowerCase().includes('image')) ||
      (!attachment.contentType && NotificationTypeTemplateComponent.getFileType(attachment.name) === 'IMG')
    ) {
      return;
    }

    const img = `<img src="cid:${attachment.name}" id="${attachment.extId}" alt="${attachment.name}"/>`;
    this.templateBodyFormControl.setValue(`${this.templateBodyFormControl.value || ''}<br><br>${img}\n`);
  }
}
