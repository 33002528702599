<div class="main__header">
  <h1 id="001_socket_edit">{{ 'wc.admin.sockets.form.edit' | translate }}</h1>
  <div class="page__menu">
    <a
      id="002_socket_edit"
      color="primary"
      mat-raised-button
      (click)="save()"
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      class="btn -primary -xs icon stroke currentColor"
      ><mat-icon svgIcon="save"></mat-icon>{{ 'wc.common.save' | translate }}</a
    >
    <a
      color="primary"
      mat-stroked-button
      *ngIf="aclService.pageEditAccess"
      [routerLink]=""
      id="003_socket_edit"
      class="btn -primary -xs more-actions icon dropDownIcon currentColor"
      [matMenuTriggerFor]="menu"
      #t="matMenuTrigger"
      >{{ 'wc.common.more.actions.button' | translate }}
      <mat-icon svgIcon="dropdownArrow" [@indicatorRotate]="t.menuOpen ? 'expanded' : 'collapsed'"> </mat-icon>
    </a>
    <mat-menu #menu="matMenu" class="module-actions">
      <a id="004_socket_edit" mat-menu-item (click)="duplicate()" [routerLink]=""
        ><mat-icon svgIcon="duplicate"></mat-icon>{{ 'wc.common.duplicate' | translate }}</a
      >
      <a id="005_socket_edit" mat-menu-item (click)="reset()" [routerLink]=""
        ><mat-icon svgIcon="reset"></mat-icon>{{ 'wc.common.reset' | translate }}</a
      >
      <a id="006_socket_edit" mat-menu-item (click)="delete()" *ngIf="!isNewSocket()" [routerLink]=""
        ><mat-icon svgIcon="delete"></mat-icon>{{ 'wc.common.delete' | translate }}</a
      >
    </mat-menu>
    <a (click)="cancel()" class="btn cancel-button">{{ 'wc.common.cancel.button' | translate }}</a>
  </div>
</div>

<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>

<div class="card__wrapper">
  <!-- card with main socket attributes -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header">
        {{ 'wc.admin.sockets.attributes' | translate }}
      </div>
      <div class="card__body">
        <form *ngIf="getEditSocket()" [formGroup]="socketForm" class="form">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="socketForm"
                  [controlName]="'code'"
                  id="007_socket_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.socket'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="socketForm"
                  [controlName]="'name'"
                  id="008_socket_edit"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.socket'">
                </app-custom-input>
              </div>

              <div class="form__row">
                <app-input-select-form-field
                  [formControlName]="'socketCategory'"
                  id="009_socket_edit"
                  [options]="categories"
                  [translationPrefix]="'wc.admin.socket'">
                </app-input-select-form-field>
              </div>

              <div class="form__row">
                <app-custom-input
                  [parentGroup]="socketForm.controls['contentQty']"
                  [controlName]="'min'"
                  inputType="number"
                  id="010_socket_edit"
                  [label]="'cardMin'"
                  [translationPrefix]="'wc.admin.socket'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <app-custom-input
                  [parentGroup]="socketForm.controls['contentQty']"
                  [controlName]="'max'"
                  inputType="number"
                  [label]="'cardMax'"
                  id="011_socket_edit"
                  [translationPrefix]="'wc.admin.socket'">
                </app-custom-input>
              </div>
              <div class="form__row"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- generated cards with contents items -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.socket.contents' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            color="primary"
            mat-raised-button
            (click)="addContentsItem()"
            [routerLink]=""
            id="012_socket_edit"
            class="btn -primary -xs edit icon stroke">
            <mat-icon svgIcon="plus"></mat-icon>
            {{ 'wc.common.add' | translate }}
          </button>
        </div>
      </div>

      <div class="card__body">
        <div class="card -full" *ngFor="let contentsItemFormGroup of contentsItemEditForms; let i = index">
          <div class="card__inner">
            <form
              *ngIf="getEditContentsItems()"
              [formGroup]="contentsItemFormGroup"
              class="form"
              [id]="contentsItemFormGroup.controls['entityType'].value">
              <div class="card__header" fxLayout="row">
                {{ 'wc.admin.socket.contents.item' | translate }} -
                {{ contentsItemFormGroup.controls['entityType'].value }}
                [ {{ contentsItemFormGroup.controls['entityId'].value }} ]
                <div *ngIf="editable" (click)="removeContentsItem(i)">
                  <mat-icon style="cursor: pointer">delete</mat-icon>
                </div>
              </div>
              <div class="card__body">
                <div class="form__groups">
                  <div class="form__group -flex">
                    <div class="form__row">
                      <app-input-select-form-field
                        [formControlName]="'entityType'"
                        id="013_socket_edit"
                        [options]="itemTypes"
                        (selectionChangeEmit)="contentsItemFormGroup.controls['entityId'].reset()"
                        [translationPrefix]="'wc.admin.socket.contents.item'">
                      </app-input-select-form-field>
                    </div>

                    <div class="form__row" *ngIf="contentsItemFormGroup.controls['entityType'].value === null"></div>
                    <div class="form__row" *ngIf="contentsItemFormGroup.controls['entityType'].value === 'ProdCode'">
                      <app-input-picker-form-field
                        [parentGroup]="contentsItemFormGroup"
                        [controlName]="'entityId'"
                        [valueField]="'productCode'"
                        id="014_socket_edit"
                        [pickerType]="PickerInputType.PRODUCT"
                        [selectMode]="false"
                        [translationPrefix]="'wc.admin.socket.contents.item'">
                      </app-input-picker-form-field>
                    </div>
                    <div class="form__row" *ngIf="contentsItemFormGroup.controls['entityType'].value === 'CatTech'">
                      <app-input-select-form-field
                        [formControlName]="'entityId'"
                        [options]="techCats"
                        id="015_socket_edit"
                        [translationPrefix]="'wc.admin.socket.contents.item'">
                      </app-input-select-form-field>
                    </div>
                    <div class="form__row"></div>
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="contentsItemFormGroup.controls['contentQty']"
                        [controlName]="'min'"
                        id="016_socket_edit"
                        inputType="number"
                        [translationPrefix]="'wc.admin.socket.contents.item'">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="contentsItemFormGroup.controls['contentQty']"
                        [controlName]="'max'"
                        inputType="number"
                        id="017_socket_edit"
                        [translationPrefix]="'wc.admin.socket.contents.item'">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-custom-input
                        [parentGroup]="contentsItemFormGroup.controls['contentQty']"
                        [controlName]="'def'"
                        inputType="number"
                        id="018_socket_edit"
                        [translationPrefix]="'wc.admin.socket.contents.item'">
                      </app-custom-input>
                    </div>
                    <div class="form__row">
                      <app-input-date-form-field
                        [parentGroup]="contentsItemFormGroup.controls['validFor']"
                        [controlName]="'startDateTime'"
                        [label]="'validFrom'"
                        id="019_socket_edit"
                        [translationPrefix]="'wc.admin.socket.contents.item'">
                      </app-input-date-form-field>
                    </div>
                    <div class="form__row">
                      <app-input-date-form-field
                        [parentGroup]="contentsItemFormGroup.controls['validFor']"
                        [controlName]="'endDateTime'"
                        [label]="'validTo'"
                        id="020_socket_edit"
                        [translationPrefix]="'wc.admin.socket.contents.item'">
                      </app-input-date-form-field>
                    </div>
                    <div class="form__row"></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- generated cards with parameters -->
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <span class="listing-header">{{ 'wc.admin.socket.parameters' | translate }}</span>
        </div>
        <div fxLayoutGap="10px" class="listing-action">
          <button
            color="primary"
            mat-raised-button
            (click)="addParam()"
            [routerLink]=""
            id="021_socket_edit"
            class="btn -primary -xs edit icon stroke">
            <mat-icon svgIcon="plus"></mat-icon>
            {{ 'wc.common.add' | translate }}
          </button>
        </div>
      </div>
      <div class="card__body">
        <div class="card -full" *ngFor="let parameterFormGroup of paramForms; let i = index">
          <div>
            <form *ngIf="getEditParams()" [formGroup]="parameterFormGroup" class="form">
              <div
                fxLayout="row"
                style="column-gap: 20px; padding: 15px; border: 1px solid #2028301a; border-radius: 5px">
                <div style="display: flex; flex: 1 1 auto; column-gap: 30px">
                  <app-custom-input
                    [parentGroup]="parameterFormGroup"
                    [controlName]="'name'"
                    [inputType]="'text'"
                    [id]="parameterFormGroup.controls['name'].value"
                    [translationPrefix]="'wc.admin.socket.parameter'">
                  </app-custom-input>

                  <app-custom-input
                    [parentGroup]="parameterFormGroup"
                    [controlName]="'value'"
                    [inputType]="'text'"
                    [id]="parameterFormGroup.controls['value'].value"
                    [translationPrefix]="'wc.admin.socket.parameter'">
                  </app-custom-input>
                </div>
                <div style="display: flex; align-items: center">
                  <mat-icon (click)="removeParam(i)" class="default-icon">delete</mat-icon>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="socketForm" class="card__wrapper">
    <app-form-texts
      class="card -full"
      [pcEntityType]="'Socket'"
      [textTypeTranslationPrefix]="'wc.admin.socket'"
      formControlName="texts">
    </app-form-texts>
  </form>

  <div class="card__wrapper" *ngIf="socketForm.controls.id.value">
    <div class="card -full">
      <div class="card__inner">
        <div
          [editable]="false"
          [constantFilter]="[{ column: 'socketId', compareType: 'EQUAL', value: socketForm.controls.id.value }]"
          [isImported]="true"
          [disableListingActions]="true"
          [selectMode]="false"
          [disableActions]="true"
          [disableClearFilterButton]="true"
          [cacheFilter]="false"
          app-promotion-list></div>
      </div>
    </div>
  </div>
</div>
