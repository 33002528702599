<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav-content>
    <mat-sidenav-content class="main_content">
      <div class="main__inner">
        <app-cms-content-edit
          [dialogRef]="dialogRef"
          [isBannerContent]="isBannerContent"
          (saveBannerEvent)="saveBannerEvent.emit($event)">
        </app-cms-content-edit>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-content>
</mat-sidenav-container>
