<div class="card__wrapper">
  <div class="card -full">
    <div class="card__inner">
      <div class="card__body">
        <form *ngIf="parameter" [formGroup]="parameterForm" class="form" id="001_ticket_parameter">
          <div class="form__groups">
            <div class="form__group -flex">
              <div class="form__row">
                <app-custom-input
                  [control]="parameterForm.controls.name"
                  id="002_ticket_parameter"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.ticketType.parametersName'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <mat-checkbox
                  formControlName="staticType"
                  (change)="staticTypeChanged($event.checked)"
                  id="003_ticket_parameter"
                  >{{ 'wc.admin.productParameters.static' | translate }}</mat-checkbox
                >
              </div>
              <div class="form__row">
                <app-input-select-form-field
                  style="width: 100%"
                  [formControlName]="'dataType'"
                  id="006_ticket_parameter"
                  [options]="ticketParamDataTypes"
                  [showEmptyOption]="false"
                  [labelOption]="'name'"
                  [valueOption]="'name'"
                  [label]="'value'"
                  (selectionChangeEmit)="dataTypeChanged()"
                  [translationPrefix]="'wc.admin.ticketType.paramDataType'">
                </app-input-select-form-field>
              </div>
              <div class="form__row">
                <app-custom-input
                  [control]="parameterForm.controls.validationRegex"
                  [inputType]="'text'"
                  id="005_ticket_parameter"
                  [translationPrefix]="'wc.admin.ticketType.parametersName'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <!--                BTL-3478 skip
                                <mat-checkbox formControlName="isLocalized" id="009_ticket_parameter">{{'wc.admin.productParameters.isLocalized' | translate}}</mat-checkbox>-->
              </div>
              <div class="form__row">
                <ng-container [ngSwitch]="parameterForm.controls['dataType'].value">
                  <app-custom-input
                    [control]="parameterForm.controls.dataTypeDetail"
                    [inputType]="'text'"
                    *ngSwitchDefault
                    [id]="'007_ticket_parameter'"
                    [translationPrefix]="'wc.admin.ticketType.parametersName'">
                  </app-custom-input>
                  <app-input-select-form-field
                    *ngSwitchCase="'CODEBOOK'"
                    [id]="'007_ticket_parameter'"
                    [formControlName]="'dataTypeDetail'"
                    [options]="codebooksTypes"
                    (focusout)="codeBookChanged($event.target.value)"
                    [translationPrefix]="'wc.admin.ticketType.parametersName'">
                  </app-input-select-form-field>
                  <app-input-select-form-field
                    *ngSwitchCase="'ENUM'"
                    [id]="'007_ticket_parameter'"
                    [formControlName]="'dataTypeDetail'"
                    [options]="enums"
                    (focusout)="enumChanged($event.target.value)"
                    [translationPrefix]="'wc.admin.ticketType.parametersName'">
                  </app-input-select-form-field>
                </ng-container>
              </div>
              <div class="form__row">
                <app-custom-input
                  [control]="parameterForm.controls.integrationName"
                  id="008_ticket_parameter"
                  [inputType]="'text'"
                  [translationPrefix]="'wc.admin.ticketType.parametersName'">
                </app-custom-input>
              </div>
              <div class="form__row">
                <ng-container [ngSwitch]="parameterForm.controls['dataType'].value">
                  <app-custom-input
                    *ngSwitchDefault
                    [control]="parameterForm.controls.defaultValue"
                    [id]="'004_ticket_parameter'"
                    [inputType]="'text'"
                    [translationPrefix]="'wc.admin.ticketType.parametersName'">
                  </app-custom-input>
                  <app-custom-input
                    *ngSwitchCase="'INTEGER'"
                    [control]="parameterForm.controls.defaultValue"
                    [id]="'004_ticket_parameter'"
                    [inputType]="'number'"
                    [translationPrefix]="'wc.admin.ticketType.parametersName'">
                  </app-custom-input>
                  <app-custom-input
                    *ngSwitchCase="'DECIMAL'"
                    [control]="parameterForm.controls.defaultValue"
                    [id]="'004_ticket_parameter'"
                    [inputType]="'number'"
                    [translationPrefix]="'wc.admin.ticketType.parametersName'">
                  </app-custom-input>
                  <app-input-select-form-field
                    [formControlName]="'defaultValue'"
                    *ngSwitchCase="'BOOLEAN'"
                    [id]="'004_ticket_parameter'"
                    [options]="inputBoolean"
                    [translationPrefix]="'wc.admin.ticketType.parametersName'">
                  </app-input-select-form-field>
                  <app-input-date-form-field
                    [parentGroup]="parameterForm"
                    *ngSwitchCase="'DATE'"
                    [id]="'004_ticket_parameter'"
                    [translationPrefix]="'wc.admin.ticketType.parametersName'"
                    [controlName]="'defaultValue'">
                  </app-input-date-form-field>
                  <ng-container *ngSwitchCase="'CODEBOOK'">
                    <app-input-select-form-field
                      *ngIf="codebooks"
                      [id]="'004_ticket_parameter'"
                      [formControlName]="'defaultValue'"
                      (focusin)="loadCodebookHints()"
                      [options]="codebooks"
                      [loading]="codebooksLoading"
                      [translationPrefix]="'wc.admin.ticketType.parametersName'">
                    </app-input-select-form-field>
                  </ng-container>
                  <ng-container *ngSwitchCase="'ENUM'">
                    <app-input-select-form-field
                      *ngIf="enumItems"
                      [id]="'004_ticket_parameter'"
                      [formControlName]="'defaultValue'"
                      (focusin)="loadEnumItems()"
                      [options]="enumItems"
                      [loading]="enumItemsLoading"
                      [showEmptyOption]="true"
                      [translationPrefix]="'wc.admin.ticketType.parametersName'">
                    </app-input-select-form-field>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<form class="card__wrapper" [formGroup]="parameterForm">
  <div class="card -full">
    <div class="card__inner">
      <div class="card__header" fxLayout="row" fxLayoutAlign="space-between">
        {{ 'wc.admin.ticketType.parametersName.localizedNames.header' | translate }}
        <mat-icon
          (click)="textsVisible = !textsVisible"
          color="primary"
          svgIcon="{{ !textsVisible ? 'hide' : 'show' }}"
          class="default-icon"></mat-icon>
      </div>
      <div *ngIf="textsVisible && locales" class="card__body">
        <div formGroupName="localizedNames" class="localizedNames">
          <div *ngFor="let locale of locales" class="form__row">
            <app-custom-input
              [control]="localizedNamesFormGroup.controls[locale]"
              id="009_ticket_parameter_{{ locale }}"
              [inputType]="'text'"
              [translationPrefix]="'wc.admin.ticketType.parametersName.localizedName'">
            </app-custom-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="card__wrapper" [formGroup]="parameterForm">
  <app-form-parameters
    class="card -full"
    [collapsed]="false"
    [control]="parameterForm.get('paramMetas')"
    paramNameEnumName="com.emeldi.ecc.be.ticket.enums.TicketParamMeta"
    [paramNameEnumSourceName]="ticketEnumeration"
    formControlName="paramMetas">
  </app-form-parameters>
</div>
<div class="card -full">
  <div class="card__inner">
    <app-acl-table
      *ngIf="aclLoaded()"
      resourceType="TICKET_PARAMETER"
      headerTranslateKey="wc.admin.ticketType.parameter-acl.header"
      [aclRules]="ticketTypeParameterAclRules"
      [parentForm]="parameterForm"
      [context]="context">
    </app-acl-table>
  </div>
</div>
