import { Component, OnInit } from '@angular/core';
import { CodebookDto, CodebookParamDto, LocalizedTextPairDto } from '@btl/admin-bff';
import { AbstractPageComponent, EnableDynamicLoading } from '@btl/btl-fe-wc-common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-codebook',
  template: '<router-outlet></router-outlet>',
})
@EnableDynamicLoading({ customName: CodebookComponent.PAGE_ID })
export class CodebookComponent extends AbstractPageComponent {
  public static readonly PAGE_ID = 'CodebookComponent';

  pageId(): string {
    return CodebookComponent.PAGE_ID;
  }

  constructor(protected router: Router, protected route: ActivatedRoute) {
    super(router, route);
  }

  public static duplicateCodebook(codebook: CodebookDto): CodebookDto {
    const newCodebook: CodebookDto = {
      id: null, //'&',
      recordVersion: null, //codebook.recordVersion,
      code: `${codebook.code}-copy`,
      codeAlt: codebook.codeAlt,
      codebookType: codebook.codebookType,
      priority: codebook.priority,
      parent: codebook.parent,
      visible: codebook.visible,
      parameters: this.duplicateParameters(codebook.parameters),
      localizedTexts: this.duplicateLocalizedTexts(codebook.localizedTexts),
    };
    return newCodebook;
  }

  private static duplicateParameters(parameters: CodebookParamDto[]): CodebookParamDto[] {
    if (!parameters) {
      return null;
    }
    const newParameters = [];
    parameters.forEach(parameter => {
      const newParameter: CodebookParamDto = {
        name: parameter.name,
        value: parameter.value,
      };
      newParameters.push(newParameter);
    });
    return newParameters;
  }

  private static duplicateLocalizedTexts(localizedTexts: LocalizedTextPairDto[]): LocalizedTextPairDto[] {
    if (!localizedTexts) {
      return null;
    }
    const newLocalizedTexts = [];
    localizedTexts.forEach(localizedText => {
      const newLocalizedText: LocalizedTextPairDto = {
        locale: localizedText.locale,
        text: localizedText.text,
      };
      newLocalizedTexts.push(newLocalizedText);
    });
    return newLocalizedTexts;
  }
}
