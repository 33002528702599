<div class="card__wrapper" [formGroup]="form">
  <app-form-pictures
    class="card -full"
    *ngIf="product && pictureTypes"
    formControlName="pictures"
    [control]="form.controls['pictures']"
    [types]="pictureTypes"
    [getFromGalleryEnabled]="false"
    [disabled]="!this.productEditService.enableEditProduct"
    [disableCollapseButton]="true"
    extIdPrefix="PC_PICTURE"
    id="001_product_pictures"
    dmsType="GENERAL"
    headerTranslationKey="wc.admin.pictures">
  </app-form-pictures>
</div>
