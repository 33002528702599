<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="isAdvancedFilterOpen" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'name'"
            [inputType]="'text'"
            id="001_promotions_filter"
            [translationPrefix]="'wc.admin.promotion'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'code'"
            [inputType]="'text'"
            id="002_promotions_filter"
            [translationPrefix]="'wc.admin.promotion'"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-input-picker-form-field
            [parentGroup]="filterForm"
            [controlName]="'productCode'"
            [valueField]="'productCode'"
            id="003_promotions_filter"
            [pickerType]="PickerInputType.PRODUCT"
            [selectMode]="false"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.promotion'">
          </app-input-picker-form-field>
          <app-input-date-form-field
            [controlName]="'validAt'"
            [translationPrefix]="'wc.admin.promotion'"
            id="004_promotions_filter"
            (keydown.enter)="handleChange()"
            [parentGroup]="filterForm">
          </app-input-date-form-field>
          <div class="form__field-actions advanced"
               app-filter-buttons
               [complexFilter]="this"
               (clearFilter)="resetFilterFields()">
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-input-select-form-field
          id="007_promotions_filter"
          [formControlName]="'productCategory'"
          [options]="techCategories"
          [showEmptyOption]="true"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.promotion'">
        </app-input-select-form-field>
        <app-custom-input
          [controlName]="'socketId'"
          [inputType]="'text'"
          id="008_promotions_filter"
          (keydown.enter)="handleChange()"
          [hidden]="presentInConstantFilter('socketId')"
          [translationPrefix]="'wc.admin.promotion'"
          [parentGroup]="filterForm">
        </app-custom-input>
      </div>
    </div>
  </mat-expansion-panel>
</form>
