<form [formGroup]="filterForm" class="form">
  <mat-expansion-panel disabled [expanded]="panelOpenState" hideToggle>
    <mat-expansion-panel-header>
      <div class="form__row">
        <div class="input__group">
          <app-custom-input
            [controlName]="'id'"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.files'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'code'"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.files'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'name'"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.files'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <app-custom-input
            [controlName]="'extId'"
            [inputType]="'text'"
            (keydown.enter)="handleChange()"
            [translationPrefix]="'wc.admin.files'"
            [parentGroup]="filterForm">
          </app-custom-input>
          <div class="form__field-actions advanced">
            <a color="primary" mat-stroked-button (click)="toggleAdvanced()" class="btn-advanced" [routerLink]="">
              <mat-icon
                [@indicatorRotate]="panelOpenState ? 'open' : 'close'"
                class="icon-advanced"
                svgIcon="doubleArrowDown"></mat-icon>
            </a>
            <a color="primary" mat-raised-button (click)="handleChange()" class="btn -primary -xs" [routerLink]="">{{
              'wc.common.search.button' | translate
            }}</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="form__row">
      <div class="input__group">
        <app-input-select-form-field
          [formControlName]="'type'"
          [options]="types?.data"
          [labelOption]="'name'"
          [valueOption]="'name'"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.files'">
        </app-input-select-form-field>
        <app-custom-input
          [controlName]="'mediaType'"
          [inputType]="'text'"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.files'"
          [parentGroup]="filterForm">
        </app-custom-input>
        <app-custom-input
          [controlName]="'note'"
          [inputType]="'text'"
          (keydown.enter)="handleChange()"
          [translationPrefix]="'wc.admin.files'"
          [parentGroup]="filterForm">
        </app-custom-input>
      </div>
    </div>
    <div class="clear-filter">
      <a (click)="clearFilter()">{{ 'wc.admin.filter.clearFilter' | translate }}</a>
    </div>
  </mat-expansion-panel>
</form>
